import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { ContractType } from '~/constants/enums/contractType'
import { State as AttendanceContractState } from '~/store/Contracts/Attendance/Main/state'
import { State as CleaningContractState } from '~/store/Contracts/Cleaning/Main/state'
import { State as PricesState } from '~/store/PriceTable/state'

/** 1: 成人式, 2: 卒業式, 7: 単品 3: 参列 10: クリーニング */
export type ContractTypeId =
  | typeof ContractType.comingOfAgeCeremony
  | typeof ContractType.graduationCeremony
  | typeof ContractType.attendance
  | typeof ContractType.singleItemService
  | typeof ContractType.cleaning
  | typeof ContractType.singleItemService

/** 1: 見積書, 2: 契約書 */
export type DocumentTypeId = 1 | 2

/**
 * @property {number} contractType 1: 成人式, 2: 卒業式, 3: 参列
 * @property {number} documentType
 */
type SaveParams = {
  /** 1: 成人式, 2: 卒業式, 3: 参列 */
  contractType: ContractTypeId
  /** 1: 見積書, 2: 契約書 */
  documentType: DocumentTypeId
  shopId: number
  body: Record<string, any>
  documentId?: number
}

type UpdateParams = SaveParams & {
  frontStoreId: number
}

type GetAllParams = {
  contractTypes: ContractTypeId[]
  /** 1: 見積書, 2: 契約書 */
  documentType: DocumentTypeId
  shopId?: number
  id?: number
  documentId?: number
}

type GetByDocIdParams = {
  contractTypes: ContractTypeId[]
  /** 1: 見積書, 2: 契約書 */
  documentType: DocumentTypeId
  shopId?: number
  documentId: number
}

type GetByIdParams = {
  contractTypes: ContractTypeId[]
  /** 1: 見積書, 2: 契約書 */
  documentType: DocumentTypeId
  shopId?: number
  id: number
}

export type FrontStore = {
  id: number
  body: string
  saved_at: string
  contract_type: ContractTypeId
}
export type GetAllResponseData = { data: FrontStore[] }

export type FrontStoreEstimateState = {
  Estimate?: {
    ComingOfAgeCeremony?: {
      Betsueri: Record<string, any>
      Customers: Record<string, any>
      EmployeeDiscount: Record<string, any>
      EstimateList: Record<string, any>
      Furisodes: Record<string, any>
      JkPhotoInAdvance: Record<string, any>
      Kamikazari: Record<string, any>
      Kamikazari2: Record<string, any>
      Kamikazari3: Record<string, any>
      Kasaneeri: Record<string, any>
      Kasaneeri2: Record<string, any>
      Kasaneeri3: Record<string, any>
      Memo: Record<string, any>
      MotherAndDaughterPlan: Record<string, any>
      Obi: Record<string, any>
      Obiage: Record<string, any>
      Obiage2: Record<string, any>
      Obiage3: Record<string, any>
      Obijime: Record<string, any>
      Photos: Record<string, any>
      Products: Record<string, any>
      Remarks: Record<string, any>
      ServicePlans: Record<string, any>
      Shawl: Record<string, any>
      SisterDiscount: Record<string, any>
      Tashinuno: Record<string, any>
      TwinsDiscount: Record<string, any>
      OtherItem: Record<string, any>
      Discount: Record<string, any>
      WphotoPlan: Record<string, any>
      Zori2: Record<string, any>
      Zori3: Record<string, any>
      Bag2: Record<string, any>
      Bag3: Record<string, any>
      ZoriBag: Record<string, any>
    }
    GraduationCeremony?: {
      memo: Record<string, any>
      tabNavi: Record<string, any>
      tabお写真プラン: Record<string, any>
      tabお名前: Record<string, any>
      tabお客様検索: Record<string, any>
      tabプラン: Record<string, any>
      tab備考: Record<string, any>
      tab別衿_着物持込: Record<string, any>
      tab別衿_着物レンタル: Record<string, any>
      tab割引: Record<string, any>
      tab半巾帯_袴持込: Record<string, any>
      tab半巾帯_袴レンタル: Record<string, any>
      tab商品設定: Record<string, any>
      tab巾着バッグ_着物レンタル: Record<string, any>
      tab巾着バッグ_着物持込: Record<string, any>
      tab紹介者特典: Record<string, any>
      tab草履ブーツ_着物レンタル: Record<string, any>
      tab草履ブーツ_着物持込: Record<string, any>
      tab袴: Record<string, any>
      tab試着写真: Record<string, any>
      tab重ね衿_着物持込1: Record<string, any>
      tab重ね衿_着物持込2: Record<string, any>
      tab重ね衿_着物持込3: Record<string, any>
      tab重ね衿_着物レンタル1: Record<string, any>
      tab重ね衿_着物レンタル2: Record<string, any>
      tab重ね衿_着物レンタル3: Record<string, any>
      tab髪飾り1: Record<string, any>
      tab髪飾り2: Record<string, any>
      tab髪飾り3: Record<string, any>
      tabその他商品: Record<string, any>
      types: Record<string, any>
    }
    Attendance?: {
      Main?: {
        data?: {
          baseSettings: Record<string, any>
          tryOnPhotos: Record<string, any>
          betsueri: Record<string, any>
          kamikazari: Record<string, any>
          kamikazari2: Record<string, any>
          kamikazari3: Record<string, any>
          shawl: Record<string, any>
          tashinuno: Record<string, any>
          discount: Record<string, any>
          furisode: Record<string, any>
          houmongi: Record<string, any>
          obi: Record<string, any>
          obijime: Record<string, any>
          obiage: Record<string, any>
          obiage2: Record<string, any>
          obiage3: Record<string, any>
          kasaneeri: Record<string, any>
          kasaneeri2: Record<string, any>
          kasaneeri3: Record<string, any>
          tomesode: Record<string, any>
          zori: Record<string, any>
          bag: Record<string, any>
          zoriBag: Record<string, any>
          remarks: Record<string, any>
          ubugi: Record<string, any>
          morning: Record<string, any>
          otherItem: Record<string, any>
          partyDress: Record<string, any>
        }
        tabList: Record<string, any>
        finalPageList: Record<string, any>
      }
    }
    SingleItemService?: {
      Main?: {
        data?: {
          accessories: Record<string, any>
          discount: Record<string, any>
          payment: Record<string, any>
          productSetting: Record<string, any>
          remarks: Record<string, any>
          signature: Record<string, any>
        }
        tabList: Record<string, any>
        finalPageList: Record<string, any>
      }
    }
  }
  PriceTable: PricesState['prices']
}

// NOTE: 必要な部分だけ定義しているので適宜増やす
export type FrontStoreContractState = {
  BaseEstimate: Record<string, any>
  ImportantMatter: Record<string, any>
  Attendance: {
    Main: AttendanceContractState
  }
  Cleaning: { Main: CleaningContractState }
  PriceTable: PricesState['prices']
  SingleItemService: {
    Main: Record<string, any>
    MasterData: Record<string, any>
  }
}

export type FrontStoreRepository = {
  /**
   * フロント状態保存
   *
   * @param {SaveParams} params - 保存する際のパラメーター
   */
  save: (params: SaveParams) => Promise<{ id: number }>
  update: (params: UpdateParams) => Promise<{ id: number }>
  /**
   * フロント状態一覧取得
   */
  getAll: (params: GetAllParams) => Promise<GetAllResponseData>
  getByDocId: (params: GetByDocIdParams) => Promise<GetAllResponseData>
  getById: (params: GetByIdParams) => Promise<GetAllResponseData>
  delete: (id: number, documentType: DocumentTypeId) => Promise<void>
}

export default (client: NuxtAxiosInstance): FrontStoreRepository => {
  const endpoint = 'front_store'
  return {
    save: (params) =>
      client.$post(endpoint, {
        contract_type: params.contractType,
        document_type: params.documentType,
        shop_id: params.shopId,
        document_id: params.documentId,
        body: JSON.stringify(params.body),
      }),
    update: (params) =>
      client.$post(endpoint, {
        contract_type: params.contractType,
        document_type: params.documentType,
        shop_id: params.shopId,
        document_id: params.documentId,
        body: JSON.stringify(params.body),
        front_store_id: params.frontStoreId,
      }),
    getAll: (params) =>
      client.$get(endpoint, {
        params: {
          contract_type: params.contractTypes,
          document_type: params.documentType,
          shop_id: params.shopId,
          document_id: params.documentId,
          id: params.id,
        },
      }),
    getByDocId: (params) => {
      return client.$get(endpoint, {
        params: {
          contract_type: params.contractTypes,
          document_type: params.documentType,
          shop_id: params.shopId,
          document_id: params.documentId,
        },
      })
    },
    getById: (params) => {
      return client.$get(endpoint, {
        params: {
          contract_type: params.contractTypes,
          document_type: params.documentType,
          shop_id: params.shopId,
          id: params.id,
        },
      })
    },
    delete: (id, documentType) =>
      client.$delete(`${endpoint}/${id}/${documentType}`),
  }
}
