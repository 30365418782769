import { computed, Ref } from '@nuxtjs/composition-api'
import { usePlanTypeFlags } from '~/composable/estimate/domain/ComingOfAge/PlanTypeFlags'
import { useScreenTypeFlags } from '~/composable/estimate/domain/ComingOfAge/ScreenTypeFlags'
import { EstimateState } from '~/composable/estimate/repository/comingOfAge/EstimateState'
import { PlanFlags } from '~/composable/estimate/repository/comingOfAge/states/types'
import ProductClassification from '~/constants/enums/productClassification'
import { PriceItem } from '../types'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import {
  FurisodeState,
  AccessoryPlanState,
  CustomerState,
  ObiState,
} from '~/store/types'
import { State as PricesState } from '~/store/PriceTable/state'

export const useListItem長襦袢Formatter = (state: Ref<EstimateState>) => {
  const { showTypeフルセット, showType写真のみレンタル, showType持ち込み } =
    useScreenTypeFlags()

  const priceTable = useComingStore<FurisodeState>('Furisodes').getPrices() // Store価格テーブルの取得

  return computed(() => {
    const flags = {
      isフルセット: showTypeフルセット.value,
      is写のみレンタル: showType写真のみレンタル.value,
      is持ち込み: showType持ち込み.value,
    }
    return getListItem長襦袢(state.value.見積一覧, flags, priceTable)
  })
}
export const getListItem長襦袢 = (
  state見積一覧: EstimateState['見積一覧'],
  planFlags: PlanFlags,
  priceTable: PricesState['prices']
) => {
  if (!planFlags.isフルセット) return null
  return {
    isNagajuban: true,
    item: {
      name: '長襦袢',
      product_type_id: ProductClassification.nagajyuban,
      addition: true,
    },
    column1: {
      price: 0,
    },
    column2: {
      price: 0,
    },
    column3: {
      price: state見積一覧.price長襦袢,
      highTech: 0,
      handSewn: priceTable.estimate.正絹長襦袢,
    },
    column4: {
      price: state見積一覧.price長襦袢,
      highTech: 0,
      handSewn: priceTable.estimate.正絹長襦袢,
    },
  }
}

export const useListItem仕立てFormatter = (state: Ref<EstimateState>) => {
  const { showTypeフルセット, showType写真のみレンタル, showType持ち込み } =
    useScreenTypeFlags()

  const priceTable = useComingStore<FurisodeState>('Furisodes').getPrices() // Store価格テーブルの取得

  return computed(() => {
    const flags = {
      isフルセット: showTypeフルセット.value,
      is写のみレンタル: showType写真のみレンタル.value,
      is持ち込み: showType持ち込み.value,
    }
    return getListItem仕立て(
      state.value.足し布,
      state.value.見積一覧,
      flags,
      priceTable
    )
  })
}
export const getListItem仕立て = (
  state足し布: EstimateState['足し布'],
  state見積一覧: EstimateState['見積一覧'],
  planFlags: PlanFlags,
  priceTable: PricesState['prices']
) => {
  if (state足し布.is適用 || !planFlags.isフルセット) return null

  return {
    isTailoring: true,
    item: {
      name: '仕立て',
      addition: true,
    },
    column1: {
      price: 0,
    },
    column2: {
      price: 0,
    },
    column3: {
      price: state足し布.is適用 ? 0 : state見積一覧.price手縫い,
      highTech: 0,
      handSewn: priceTable.estimate.高級手縫い,
    },
    column4: {
      price: state足し布.is適用 ? 0 : state見積一覧.price手縫い,
      highTech: 0,
      handSewn: priceTable.estimate.高級手縫い,
    },
  }
}

export const useListItem足し布Formatter = (state: EstimateState['足し布']) => {
  const { showTypeフルセット, showType写真のみレンタル, showType持ち込み } =
    useScreenTypeFlags()

  return computed(() => {
    const flags = {
      isフルセット: showTypeフルセット.value,
      is写のみレンタル: showType写真のみレンタル.value,
      is持ち込み: showType持ち込み.value,
    }
    return getListItem足し布(state, flags)
  })
}
export const getListItem足し布 = (
  state足し布: EstimateState['足し布'],
  planFlags: PlanFlags
) => {
  if (!state足し布.is適用 || planFlags.is持ち込み) return null

  return {
    item: {
      name: '足し布',
      addition: true,
    },
    column1: {
      price: state足し布.price,
    },
    column2: {
      price: state足し布.price,
    },
    column3: {
      price: state足し布.price,
    },
    column4: {
      price: state足し布.price,
    },
  }
}

export const useListItem補正Formatter = (
  state: EstimateState['振袖'] | EstimateState['Wフォト']['振袖']
) => {
  const priceTable = useComingStore<FurisodeState>('Furisodes').getPrices()
  return computed(() => {
    return getListItem補正(state, priceTable)
  })
}
export const getListItem補正 = (
  state振袖: EstimateState['振袖'] | EstimateState['Wフォト']['振袖'],
  priceTable: PricesState['prices']
) => {
  const 裄補正 = {
    item: {
      name: '裄補正',
      addition: true,
    },
    column1: {
      price: priceTable.estimate.裄補正,
    },
    column2: {
      price: priceTable.estimate.裄補正,
    },
    column3: {
      price: priceTable.estimate.裄補正,
    },
    column4: {
      price: priceTable.estimate.裄補正,
    },
  }
  const 袖補正 = {
    item: {
      name: '袖補正',
      addition: true,
    },
    column1: {
      price: priceTable.estimate.袖補正,
    },
    column2: {
      price: priceTable.estimate.袖補正,
    },
    column3: {
      price: priceTable.estimate.袖補正,
    },
    column4: {
      price: priceTable.estimate.袖補正,
    },
  }

  return {
    裄補正: state振袖.flags.is裄補正 ? 裄補正 : null,
    袖補正: state振袖.flags.is袖補正 ? 袖補正 : null,
  }
}

export const useListItem帯仕立て代Formatter = (
  state: EstimateState['帯'] | EstimateState['Wフォト']['帯']
) => {
  return computed(() => {
    if (!state.flags.is帯仕立て代) return null

    return {
      item: {
        name: '帯仕立て代',
        addition: true,
      },
      column1: {
        price: state.帯仕立て代,
      },
      column2: {
        price: state.帯仕立て代,
      },
      column3: {
        price: state.帯仕立て代,
      },
      column4: {
        price: state.帯仕立て代,
      },
    }
  })
}

export const useListItem別衿加工Formatter = (
  state: EstimateState['別衿'] | EstimateState['Wフォト']['別衿']
) => {
  const priceTable = useComingStore<AccessoryPlanState>('Betsueri').getPrices()
  return computed(() => {
    return getListItem別衿加工(state, priceTable)
  })
}
export const getListItem別衿加工 = (
  state別衿: EstimateState['別衿'] | EstimateState['Wフォト']['別衿'],
  priceTable: PricesState['prices']
) => {
  if (!state別衿.別衿加工必要) return null

  return {
    item: {
      name: '別衿加工代',
      addition: true,
    },
    column1: {
      price: priceTable.estimate.別衿加工代,
    },
    column2: {
      price: priceTable.estimate.別衿加工代,
    },
    column3: {
      price: priceTable.estimate.別衿加工代,
    },
    column4: {
      price: priceTable.estimate.別衿加工代,
    },
  }
}

export const useListItem写真アルバム代Formatter = () => {
  const {
    isフルセット,
    is写真のみレンタル,
    is当日のみBコース,
    is振袖持込Not当日のみBコース,
  } = usePlanTypeFlags()
  const priceTable = useComingStore<CustomerState>('Customers').getPrices() // Store価格テーブルの取得

  return computed(() => {
    const flags = {
      isフルセット: isフルセット.value,
      is写のみレンタル: is写真のみレンタル.value,
      is持ち込み: is振袖持込Not当日のみBコース.value,
      is当日Bコース: is当日のみBコース.value,
    }
    return getListItem写真アルバム代(flags, priceTable)
  })
}

export const getListItem写真アルバム代 = (
  planFlags: PlanFlags,
  priceTable: PricesState['prices']
) => {
  // NOTE: 初期値はフルセット(全部0円)の場合とする
  const column1: PriceItem = {
    text: '15P×1冊',
    price: priceTable.estimate.写真アルバム代フルセット,
  }
  const column2: PriceItem = {
    text: '15P×1冊',
    price: priceTable.estimate.写真アルバム代フルセット,
  }
  const column3: PriceItem = {
    text: '15P×2冊',
    price: priceTable.estimate.写真アルバム代フルセット,
  }
  const column4: PriceItem = {
    text: '15P×2冊',
    price: priceTable.estimate.写真アルバム代フルセット,
  }
  let isInsertDailyReport: boolean = false

  if (planFlags.is写のみレンタル) {
    column1.text = '5P×1冊'
    column1.price = priceTable.estimate.写真アルバム代スタンダード5P
    column2.text = '5P×1冊'
    column2.price = priceTable.estimate.写真アルバム代スタンダード5P
    column3.text = '15P×1冊'
    column3.price = priceTable.estimate.写真アルバム代プレミアム15P
    column4.text = '15P×1冊'
    column4.price = priceTable.estimate.写真アルバム代プレミアム15P
    isInsertDailyReport = true
  } else if (planFlags.is持ち込み) {
    column1.text = '5P×1冊'
    column1.price = priceTable.estimate.写真アルバム代スタンダード5P
    column2.text = '15P×1冊'
    column2.price = priceTable.estimate.写真アルバム代プレミアム15P
    isInsertDailyReport = true
  } else if (planFlags.is当日Bコース) {
    // NOTE: 当日Bコースには写真撮影がないのでアルバムは付かない
    return null
  }

  return {
    item: {
      name: '写真アルバム代',
      addition: true,
      isInsertDailyReport,
    },
    column1,
    column2,
    column3,
    column4,
  }
}

export const useListItemプレミアムパックFormatter = () => {
  const { showTypeフルセット, showType写真のみレンタル, showType持ち込み } =
    useScreenTypeFlags()

  const priceTable = useComingStore<ObiState>(
    'WphotoPlan/WphotoObijimeRental'
  ).getPrices() // Store料金情報を取得

  return computed(() => {
    const flags = {
      isフルセット: showTypeフルセット.value,
      is写のみレンタル: showType写真のみレンタル.value,
      is持ち込み: showType持ち込み.value,
    }
    return getListItemプレミアムパック(flags, priceTable)
  })
}
export const getListItemプレミアムパック = (
  planFlags: PlanFlags,
  priceTable: PricesState['prices']
) => {
  let price1: number = 0
  let price2: number = 0
  let price3: number = 0
  let price4: number = 0

  if (planFlags.isフルセット) {
    price2 = priceTable.estimate.プレミアムパックレンタル
    price4 = priceTable.estimate.プレミアムパック購入
  } else if (planFlags.is写のみレンタル) {
    price2 = priceTable.estimate.プレミアムパックレンタル
    price4 = priceTable.estimate.プレミアムパックレンタル
  } else if (planFlags.is持ち込み) {
    // NOTE: 振袖持込系にはプラン内の小物は存在しないため
    // プレミアムパックは適用されない
    return null
  }

  return {
    item: {
      name: 'プレミアムパック',
      addition2: true,
    },
    column1: {
      price: price1,
    },
    column2: {
      price: price2,
    },
    column3: {
      price: price3,
    },
    column4: {
      price: price4,
    },
  }
}

export const useListItemWPhotoプレミアムパックFormatter = (
  is振袖レンタル: boolean
) => {
  const priceTable = useComingStore<ObiState>(
    'WphotoPlan/WphotoObijimeRental'
  ).getPrices() // Store料金情報を取得
  return computed(() => {
    return getListItemWPhotoプレミアムパック(is振袖レンタル, priceTable)
  })
}

export const getListItemWPhotoプレミアムパック = (
  is振袖レンタル: boolean,
  priceTable: PricesState['prices']
) => {
  if (!is振袖レンタル) return null
  return {
    item: {
      name: 'プレミアムパック',
      addition2: true,
    },
    column1: {
      price: 0,
    },
    column2: {
      price: priceTable.estimate.プレミアムパックWフォト,
    },
  }
}
