import { render, staticRenderFns } from "./comingOfAgeCeremony.vue?vue&type=template&id=2387b5b6&"
import script from "./comingOfAgeCeremony.vue?vue&type=script&lang=ts&"
export * from "./comingOfAgeCeremony.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppMenu: require('/home/ar-admin/development/weddingbox-client/src/components/AppMenu.vue').default,AppEstimateHeaderComingOfAgeCeremony: require('/home/ar-admin/development/weddingbox-client/src/components/AppEstimateHeaderComingOfAgeCeremony.vue').default,EstimateTempSaveAndMemo: require('/home/ar-admin/development/weddingbox-client/src/components/EstimateTempSaveAndMemo.vue').default})
