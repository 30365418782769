import { computed } from '@nuxtjs/composition-api'
import {
  getBadgeText,
  getItemPlan,
  getItemPlanFlags,
  getPriceTypeText,
  getWPhotoItemPrices,
} from '~/composable/estimate/repository/comingOfAge/states/utilities'
import { ObiState } from '~/store/types'
import ProductClassification from '~/constants/enums/productClassification'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { WPhotoBaseItemState } from '../types'
import { useState振袖 } from './State振袖'
import { useState商品設定 } from './StateOtherTabs'

type Kasaneeri = WPhotoBaseItemState & {
  品種区分: 5
}

export const useState重ね衿 = () => {
  const valuesお持込 = useComingStore<ObiState>(
    'WphotoPlan/WphotoKasaneeriBring'
  ).getAll()
  const valuesレンタル = useComingStore<ObiState>(
    'WphotoPlan/WphotoKasaneeriRental'
  ).getAll()
  const values重ね衿2 = useComingStore<ObiState>(
    'WphotoPlan/WphotoKasaneeri2'
  ).getAll()
  const values重ね衿3 = useComingStore<ObiState>(
    'WphotoPlan/WphotoKasaneeri3'
  ).getAll()

  const state振袖 = useState振袖()
  const state商品設定 = useState商品設定()

  return computed(() => {
    const count = state商品設定.value.重ね衿
    if (state振袖.value.flags.isレンタル) {
      const values重ね衿 = [
        valuesレンタル.value,
        values重ね衿2.value,
        values重ね衿3.value,
      ]
      return getState重ね衿(values重ね衿, count, true)
    } else {
      const values重ね衿 = [
        valuesお持込.value,
        values重ね衿2.value,
        values重ね衿3.value,
      ]
      return getState重ね衿(values重ね衿, count, false)
    }
  })
}

export const getState重ね衿 = (
  values重ね衿: ObiState[],
  count重ね衿: number,
  is振袖レンタル: boolean
) => {
  const filledStoreValues = values重ね衿.slice(0, count重ね衿)
  return filledStoreValues.map((value, i) => {
    const { selections, input } = value
    const itemFlags = getItemPlanFlags(selections)
    const itemPlan = getItemPlan(selections)
    const numberText = i > 0 ? ' - ' + (i + 1) : ''

    const kasaneeri: Kasaneeri = {
      品目名:
        ProductClassification.toWord(ProductClassification.kasaneeri) +
        numberText,
      品種区分: ProductClassification.kasaneeri,
      商品名: '',
      小物プラン: itemPlan,
      レンタル価格: 0,
      購入価格: 0,
      プレミアムレンタル価格: input.premiumRentalPrice,
      見積表示: null,
      表示価格: null,
      label: getBadgeText(itemPlan),
      cellLabel: getPriceTypeText(selections),
      flags: {
        isランクアップ:
          itemFlags.isランクアップPP内 || itemFlags.isランクアップPP外,
      },
    }

    const updatedPrices = getWPhotoItemPrices(
      value,
      kasaneeri,
      is振袖レンタル,
      i
    )
    kasaneeri.レンタル価格 = updatedPrices.レンタル価格
    kasaneeri.購入価格 = updatedPrices.購入価格
    kasaneeri.プレミアムレンタル価格 = updatedPrices.プレミアムレンタル価格
    kasaneeri.見積表示 = updatedPrices.見積表示
    kasaneeri.表示価格 = updatedPrices.表示価格

    return kasaneeri
  })
}
