import {
  GRADUATION_ZORI as PLAN,
  PRODUCT_BAG,
} from '~/constants/estimate/comingOfAge'

export type Product = {
  id: number
  post_number_code: string
  product_name: string
  disabled?: boolean
  product?: {
    rental: {
      label: string
      text: string
    }
    buy: {
      label: string
      text: string
    }
  }
  price: {
    rental: number
    buy: number
    single_purchase?: number
    set_purchase?: number
    set_rental?: number
  }
  isItemInSet?: boolean
  setId?: number
}

// セット内商品の価格
const inTheSetPrice = {
  rental: 0,
  buy: 0,
  set_purchase: 30800,
}

// ランクアップ（内）草履の価格
const zoriPrice = {
  rental: 16500,
  buy: 11000,
  single_purchase: 22000,
}

// ランクアップ（内）バッグの価格
const bagPrice = {
  rental: 16500,
  buy: 16500,
  single_purchase: 22000,
}

// 単品レンタル・単品購入価格（草履2つ目以降も同じ金額）
const singlePriceBringIn = {
  rental: 16500,
  buy: 22000,
  set_purchase: 30800,
}

export const SET_IN_ZORI_PRODUCTS: Product[] = [
  {
    id: PLAN.U_1,
    post_number_code: 'U-1',
    product_name: '草履（ご購入）',
    disabled: false,
    price: inTheSetPrice,
  },
  {
    id: PLAN.U_2,
    post_number_code: 'U-2',
    product_name: '草履（ご購入）',
    disabled: false,
    price: inTheSetPrice,
  },
  {
    id: PLAN.U_4,
    post_number_code: 'U-4',
    product_name: '草履（ご購入）',
    disabled: false,
    price: inTheSetPrice,
  },
  {
    id: PLAN.U_5,
    post_number_code: 'U-5',
    product_name: '草履（ご購入）',
    disabled: false,
    price: inTheSetPrice,
  },
  {
    id: PLAN.U_7,
    post_number_code: 'U-7',
    product_name: '草履（ご購入）',
    disabled: false,
    price: inTheSetPrice,
  },
  {
    id: PLAN.U_10,
    post_number_code: 'U-10',
    product_name: '草履（ご購入）',
    disabled: false,
    price: inTheSetPrice,
  },
  {
    id: PLAN.ACTUAL_PRODUCT,
    post_number_code: '',
    product_name: '現品',
    disabled: false,
    price: {
      rental: 0,
      buy: 0,
    },
  },
  {
    id: PLAN.TO_BE_DECIDED,
    post_number_code: '',
    product_name: '',
    product: {
      rental: {
        label: 'レンタル',
        text: 'こちらでお選びいたします',
      },
      buy: {
        label: '購入',
        text: '未定',
      },
    },
    disabled: false,
    price: {
      rental: 0,
      buy: 0,
    },
  },
]

export const SET_IN_BAG_PRODUCT: Product[] = [
  {
    id: PLAN.U_1,
    post_number_code: 'U-1',
    product_name: 'バッグ（ご購入）',
    disabled: false,
    price: inTheSetPrice,
  },
  {
    id: PLAN.U_2,
    post_number_code: 'U-2',
    product_name: 'バッグ（ご購入）',
    disabled: false,
    price: inTheSetPrice,
  },
  {
    id: PLAN.U_4,
    post_number_code: 'U-4',
    product_name: 'バッグ（ご購入）',
    disabled: false,
    price: inTheSetPrice,
  },
  {
    id: PLAN.U_5,
    post_number_code: 'U-5',
    product_name: 'バッグ（ご購入）',
    disabled: false,
    price: inTheSetPrice,
  },
  {
    id: PLAN.U_7,
    post_number_code: 'U-7',
    product_name: 'バッグ（ご購入）',
    disabled: false,
    price: inTheSetPrice,
  },
  {
    id: PLAN.U_10,
    post_number_code: 'U-10',
    product_name: 'バッグ（ご購入）',
    disabled: false,
    price: inTheSetPrice,
  },
  {
    id: PLAN.ACTUAL_PRODUCT,
    product_name: '現品',
    post_number_code: '',
    disabled: false,
    price: {
      rental: 0,
      buy: 0,
    },
  },
  {
    id: PLAN.TO_BE_DECIDED,
    post_number_code: '',
    product_name: '',
    product: {
      rental: {
        label: 'レンタル',
        text: 'こちらでお選びいたします',
      },
      buy: {
        label: '購入',
        text: '未定',
      },
    },
    disabled: false,
    price: {
      rental: 0,
      buy: 0,
    },
  },
]

export const RANK_UP_IN_PREMIUM_ZORI_PRODUCTS: Product[] = [
  {
    id: PLAN.RZ_01,
    post_number_code: 'RZ-01',
    product_name: '桜刺繍ヒール草履 細太鼻緒（赤）',
    price: zoriPrice,
  },
  {
    id: PLAN.RZ_02,
    post_number_code: 'RZ-02',
    product_name: '椿刺繍草履（黒）',
    price: zoriPrice,
  },
  {
    id: PLAN.RZ_03,
    post_number_code: 'RZ-03',
    product_name: 'ボタン刺繍 シール草履（薄ピ）',
    price: zoriPrice,
  },
  {
    id: PLAN.RZ_04,
    post_number_code: 'RZ-04',
    product_name: 'くす玉刺繍コイルスプ リングヒール草履（赤台）',
    price: zoriPrice,
  },
  {
    id: PLAN.RZ_05,
    post_number_code: 'RZ-05',
    product_name: 'くす玉刺繍コイルスプ リングヒール草履（イエロー台）',
    price: zoriPrice,
  },
  {
    id: PLAN.RZ_06,
    post_number_code: 'RZ-06',
    product_name: '椿刺繍ストライプ草履（赤台）',
    price: zoriPrice,
  },
  {
    id: PLAN.RZ_07,
    post_number_code: 'RZ-07',
    product_name: '菊刺繍金草履（黒緑）',
    price: zoriPrice,
  },
  {
    id: PLAN.RZ_08,
    post_number_code: 'RZ-08',
    product_name: '別珍鼻緒パール草履（ネイビー）',
    price: zoriPrice,
  },
  {
    id: PLAN.RZ_09,
    post_number_code: 'RZ-09',
    product_name: '別珍鼻緒パール草履 （ボルドー）',
    price: zoriPrice,
  },
  {
    id: PLAN.RZ_10,
    post_number_code: 'RZ-10',
    product_name: '万寿菊刺繍草履（白）',
    price: zoriPrice,
  },
  {
    id: PLAN.UZ_1,
    post_number_code: '',
    product_name: '新商品UZ-1',
    price: zoriPrice,
  },
  {
    id: PLAN.UZ_2,
    post_number_code: '',
    product_name: '新商品UZ-2',
    price: zoriPrice,
  },
  {
    id: PLAN.ACTUAL_PRODUCT,
    post_number_code: '',
    product_name: '現品',
    price: {
      rental: 0,
      buy: 0,
    },
  },
]

export const RANK_UP_IN_PREMIUM_BAG_PRODUCTS: Product[] = [
  {
    id: PRODUCT_BAG.RB_01,
    post_number_code: 'RB-01',
    product_name: '大椿刺繍 ガマグチバッグ（黒）',
    price: bagPrice,
  },
  {
    id: PRODUCT_BAG.RB_02,
    post_number_code: 'RB-02',
    product_name: '大椿刺繍 ガマグチバッグ（ターコイズ）',
    price: bagPrice,
  },
  {
    id: PRODUCT_BAG.RB_03,
    post_number_code: 'RB-03',
    product_name: '桃刺繍 ガマグチバッグ（黄）',
    price: bagPrice,
  },
  {
    id: PRODUCT_BAG.RB_04,
    post_number_code: 'RB-04',
    product_name: 'くす玉刺繍 ガマグチバッグ（赤）',
    price: bagPrice,
  },
  {
    id: PRODUCT_BAG.RB_05,
    post_number_code: 'RB-05',
    product_name: 'くす玉刺繍 ガマグチバッグ（黒）',
    price: bagPrice,
  },
  {
    id: PRODUCT_BAG.RB_06,
    post_number_code: 'RB-06',
    product_name: 'ツマミ付き ガマグチバッグ（赤）',
    price: bagPrice,
  },
  {
    id: PRODUCT_BAG.RB_07,
    post_number_code: 'RB-07',
    product_name: 'ツマミ付き ガマグチバッグ（紺）',
    price: bagPrice,
  },
  {
    id: PRODUCT_BAG.MB_08,
    post_number_code: 'MB-08',
    product_name: 'ねじり梅に蝶 （E161-W5-4）',
    price: bagPrice,
  },
  {
    id: PRODUCT_BAG.MB_09,
    post_number_code: 'MB-09',
    product_name: 'ねじり梅に蝶 （E161-W5-5）',
    price: bagPrice,
  },
  {
    id: PRODUCT_BAG.MB_10,
    post_number_code: 'MB-10',
    product_name: 'ねじり梅に蝶 （E161-W5-6）',
    price: bagPrice,
  },
  {
    id: PRODUCT_BAG.UB_1,
    post_number_code: '',
    product_name: '新商品UB-1',
    price: bagPrice,
  },
  {
    id: PRODUCT_BAG.UB_2,
    post_number_code: '',
    product_name: '新商品UB-2',
    price: bagPrice,
  },
  {
    id: PLAN.ACTUAL_PRODUCT,
    post_number_code: '',
    product_name: '現品',
    price: {
      rental: 0,
      buy: 0,
    },
  },
]

export const RANK_UP_OUT_PREMIUM_PRODUCT: Product[] = [
  {
    id: PLAN.ACTUAL_PRODUCT,
    post_number_code: '',
    product_name: '現品',
    price: {
      rental: 0,
      buy: 0,
    },
  },
]

export const SINGLE_ZORI_PRODUCTS: Product[] = [
  {
    id: PLAN.U_1,
    post_number_code: 'U-1',
    product_name: '草履（ご購入）',
    disabled: false,
    price: singlePriceBringIn,
  },
  {
    id: PLAN.U_2,
    post_number_code: 'U-2',
    product_name: '草履（ご購入）',
    disabled: false,
    price: singlePriceBringIn,
  },
  {
    id: PLAN.U_4,
    post_number_code: 'U-4',
    product_name: '草履（ご購入）',
    disabled: false,
    price: singlePriceBringIn,
  },
  {
    id: PLAN.U_5,
    post_number_code: 'U-5',
    product_name: '草履（ご購入）',
    disabled: false,
    price: singlePriceBringIn,
  },
  {
    id: PLAN.U_7,
    post_number_code: 'U-7',
    product_name: '草履（ご購入）',
    disabled: false,
    price: singlePriceBringIn,
  },
  {
    id: PLAN.U_10,
    post_number_code: 'U-10',
    product_name: '草履（ご購入）',
    disabled: false,
    price: singlePriceBringIn,
  },
  {
    id: PLAN.RZ_01,
    post_number_code: 'RZ-01',
    product_name: '桜刺繍ヒール草履 細太鼻緒（赤）',
    price: singlePriceBringIn,
  },
  {
    id: PLAN.RZ_02,
    post_number_code: 'RZ-02',
    product_name: '椿刺繍草履（黒）',
    price: singlePriceBringIn,
  },
  {
    id: PLAN.RZ_03,
    post_number_code: 'RZ-03',
    product_name: 'ボタン刺繍 シール草履（薄ピ）',
    price: singlePriceBringIn,
  },
  {
    id: PLAN.RZ_04,
    post_number_code: 'RZ-04',
    product_name: 'くす玉刺繍コイルスプ リングヒール草履（赤台）',
    price: singlePriceBringIn,
  },
  {
    id: PLAN.RZ_05,
    post_number_code: 'RZ-05',
    product_name: 'くす玉刺繍コイルスプ リングヒール草履（イエロー台）',
    price: singlePriceBringIn,
  },
  {
    id: PLAN.RZ_06,
    post_number_code: 'RZ-06',
    product_name: '椿刺繍ストライプ草履（赤台）',
    price: singlePriceBringIn,
  },
  {
    id: PLAN.RZ_07,
    post_number_code: 'RZ-07',
    product_name: '菊刺繍金草履（黒緑）',
    price: zoriPrice,
  },
  {
    id: PLAN.RZ_08,
    post_number_code: 'RZ-08',
    product_name: '別珍鼻緒パール草履（ネイビー）',
    price: singlePriceBringIn,
  },
  {
    id: PLAN.RZ_09,
    post_number_code: 'RZ-09 ',
    product_name: '別珍鼻緒パール草履 （ボルドー）',
    price: singlePriceBringIn,
  },
  {
    id: PLAN.RZ_10,
    post_number_code: 'RZ-10',
    product_name: '万寿菊刺繍草履（白）',
    price: singlePriceBringIn,
  },
  {
    id: PLAN.UZ_1,
    post_number_code: '',
    product_name: '新商品UZ-1',
    price: singlePriceBringIn,
  },
  {
    id: PLAN.UZ_2,
    post_number_code: '',
    product_name: '新商品UZ-2',
    price: singlePriceBringIn,
  },
  {
    id: PLAN.ACTUAL_PRODUCT,
    post_number_code: '',
    product_name: '現品',
    price: {
      rental: 0,
      buy: 0,
    },
  },
]

export const SINGLE_BAG_PRODUCTS: Product[] = [
  {
    id: PLAN.U_1,
    post_number_code: 'U-1',
    product_name: 'バッグ（ご購入）',
    disabled: false,
    price: inTheSetPrice,
  },
  {
    id: PLAN.U_2,
    post_number_code: 'U-2',
    product_name: 'バッグ（ご購入）',
    disabled: false,
    price: inTheSetPrice,
  },
  {
    id: PLAN.U_4,
    post_number_code: 'U-4',
    product_name: 'バッグ（ご購入）',
    disabled: false,
    price: inTheSetPrice,
  },
  {
    id: PLAN.U_5,
    post_number_code: 'U-5',
    product_name: 'バッグ（ご購入）',
    disabled: false,
    price: inTheSetPrice,
  },
  {
    id: PLAN.U_7,
    post_number_code: 'U-7',
    product_name: 'バッグ（ご購入）',
    disabled: false,
    price: inTheSetPrice,
  },
  {
    id: PLAN.U_10,
    post_number_code: 'U-10',
    product_name: 'バッグ（ご購入）',
    disabled: false,
    price: inTheSetPrice,
  },
  {
    id: PRODUCT_BAG.RB_01,
    post_number_code: 'RB-01',
    product_name: '大椿刺繍 ガマグチバッグ（黒）',
    price: singlePriceBringIn,
  },
  {
    id: PRODUCT_BAG.RB_02,
    post_number_code: 'RB-02',
    product_name: '大椿刺繍 ガマグチバッグ（ターコイズ）',
    price: singlePriceBringIn,
  },
  {
    id: PRODUCT_BAG.RB_03,
    post_number_code: 'RB-03',
    product_name: '桃刺繍 ガマグチバッグ（黄）',
    price: singlePriceBringIn,
  },
  {
    id: PRODUCT_BAG.RB_04,
    post_number_code: 'RB-04',
    product_name: 'くす玉刺繍 ガマグチバッグ（赤）',
    price: singlePriceBringIn,
  },
  {
    id: PRODUCT_BAG.RB_05,
    post_number_code: 'RB-05',
    product_name: 'くす玉刺繍 ガマグチバッグ（黒）',
    price: singlePriceBringIn,
  },
  {
    id: PRODUCT_BAG.RB_06,
    post_number_code: 'RB-06',
    product_name: 'ツマミ付き ガマグチバッグ（赤）',
    price: singlePriceBringIn,
  },
  {
    id: PRODUCT_BAG.RB_07,
    post_number_code: 'RB-07',
    product_name: 'ツマミ付き ガマグチバッグ（紺）',
    price: singlePriceBringIn,
  },
  {
    id: PRODUCT_BAG.MB_08,
    post_number_code: 'MB-08',
    product_name: 'ねじり梅に蝶 （E161-W5-4）',
    price: singlePriceBringIn,
  },
  {
    id: PRODUCT_BAG.MB_09,
    post_number_code: 'MB-09',
    product_name: 'ねじり梅に蝶 （E161-W5-5）',
    price: singlePriceBringIn,
  },
  {
    id: PRODUCT_BAG.MB_10,
    post_number_code: 'MB-10',
    product_name: 'ねじり梅に蝶 （E161-W5-6）',
    price: singlePriceBringIn,
  },
  {
    id: PRODUCT_BAG.UB_1,
    post_number_code: '',
    product_name: '新商品UB-1',
    price: singlePriceBringIn,
  },
  {
    id: PRODUCT_BAG.UB_2,
    post_number_code: '',
    product_name: '新商品UB-2',
    price: singlePriceBringIn,
  },
  {
    id: PRODUCT_BAG.ACTUAL_PRODUCT,
    post_number_code: '',
    product_name: '現品',
    price: {
      rental: 0,
      buy: 0,
    },
  },
]

// PP適用時の単品レンタル価格
export const PP_SINGLE_RENTAL_PRICE = 0

// 四色帯復元時に使用
export const YONSHOKUOBI_PRODUCT_ID = 500326952

// 商品名「現品」
export const GENPIN = '現品'
