import { FormValuesお客様検索 } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsお客様検索'

//不明な点がある場合、READMEをご覧ください
export const getInitialStore = (): FormValuesお客様検索 => {
  return {
    okyakusama検索: null,
    okyakusama来店動機: [],
    guidanceEmail: null, // メールアドレス
  }
}

const state = (): { fields: FormValuesお客様検索 } => ({
  fields: getInitialStore(),
})

export default state
