/*
 * 金額選択を持つ項目において表示する金額情報
 * 草履・バッグは商品選択に伴う加算金額。
 * それ以外の項目は「フリー入力」ボタンと並べて表示する金額選択コンポーネント用配列
 * notionまとめページ：https://www.notion.so/arsagajp/localDB-8d748f35a53d4c7786a168d7fd1c6ee7
 */

export const priceTable = {
  卒業式: {
    振袖: {
      仕立て上がりレンタル: [44000],
      友の会割引: [13200],
    },
    二尺袖: {
      レンタル: [27500, 33000, 38500],
    },
    袴: {
      レンタル: [11000, 16500, 22000, 27500],
    },
    半巾帯: {
      現品_ランクアップレンタル: [3300],
      現品_単品レンタル: [3300],
      現品_単品購入: [5500],
    },
    重ね衿: {
      ランクアップレンタル_着物レンタル1品目: [7700, 9900, 13200],
      単品レンタル_着物レンタル２品目以降or単品プラン: [7700, 9900, 13200],
      単品購入_着物レンタル２品目以降or単品プラン: [11000, 13200, 16500],
    },
    別衿: {
      ランクアップレンタル: [5500, 13200],
      単品購入_着物持込: [5500, 16500],
      単品レンタル_着物持込: [5500, 13200],
    },
    草履: {
      ランクアップレンタル: [16500],
      単品購入: [22000],
      単品レンタル_２品目: [16500],
      単品購入_２品目: [22000],
    },
    バッグ: {
      現品以外_ランクアップレンタル: [16500],
      現品_ランクアップレンタル: [5500],
      単品レンタル_２品目: [16500],
      単品購入_２品目: [22000],
    },
    //* NOTE: https://docs.google.com/document/d/1NSOyI6kdD8uF1x1FpFK9fgmsdVhwgiDlHcEOQ1VZrBE/edit */
    お写真プラン: {
      デザインアルバム: {
        スタンダード: 55000,
        プレミアム: 165000,
        追加ポーズ: 5500,
        追加冊: 55000,
      },
      六つ切り: {
        台紙あり: {
          ポーズ1: 11000,
          ポーズ2以降: 5500,
        },
        台紙なし: 5500,
      },
      キャビネ: {
        台紙あり: {
          ポーズ1: 5500,
          ポーズ2以降: 2750,
        },
        台紙なし: 2750,
      },
      アクリルスタンド: {
        A5: 7700,
        A4: 13200,
      },
      USB: 5500,
    },
    袴定額割引: 11000,
    裄補正: 11000,
    袖補正: 11000,
    別衿加工代: 5500,
    当日着付代: 11000,
    商品券割引: 3000,
    契約書お送り代: 2200,
    プレミアムパック: 33000,
    卒業式長期レンタル値引き: 5000,
  },
  成人式: {
    振袖: {
      仕立上り: {
        購入: [198000, 261800, 272800, 283800, 294800, 305800, 316800, 327800],
        レンタル: [
          99000, 151800, 162800, 173800, 184800, 195800, 206800, 217800,
        ],
      },
      // NOTE: 購入547800円以降はレンタル327800円に対応
      プロパー: {
        購入: [198000, 272800, 382800, 437800, 547800, 657800, 767800, 877800],
        レンタル: [99000, 217800, 272800, 294800, 327800],
      },
    },
    足し布: {
      安い方: 99000,
      高い方: 165000,
    },
    髪飾り: {
      フルセット割引: 5500,
    },
    帯揚げ: {
      レンタル: 5500,
      購入: 8800,
    },
    草履バッグ: {
      セットレンタル: 27500,
    },
    正絹長襦袢: 33000,
    高級手縫い: 77000,
    当日ヘアメイク着付代: {
      Aコース: 55000,
      Bコース: 16500, // お姉様の過去購入あり
    },
    前撮りヘアメイク着付代: 0,
    母娘プラン訪問着レンタル: 22000,
    写真アルバム: {
      スタンダード5P: 55000,
      プレミアム15P: 165000,
      フルセット: 0, // フルセットの場合は全パターン無料
    },
    商品券割引: 3000,
    従業員割引: 10000,
    双子割引率: 10,
    妹様割引率: 10,
    振袖割引率: 50,
    着付けなし割引: 10000,
    裄補正: 11000,
    袖補正: 11000,
    別衿加工代: 5500,
    Wフォトプラン着付代: 11000,
    プレミアムパック: {
      レンタル: 99000,
      購入: 110000,
    },
    JK前撮り着付代: 0,
    JK前撮り振袖代: 0,
    卒業式見込金額: 55000, // 見積一覧ページで差額表示に使用
  },
  参列: {
    振袖: {
      友の会割引適用金額: 13200,
    },
    訪問着: {
      初期値レンタル価格: 33000,
    },
    草履バッグセット: {
      単品レンタル価格: 27500,
    },
    モーニング: {
      商品金額: 27500,
    },
    初着: {
      商品金額: 11000,
    },
    裄補正: 11000,
    袖補正: 11000,
    別衿加工代: 5500,
    正絹長襦袢: 33000,
    商品券割引: 3000,
    足し布: 165000,
    当日着付代: 11000,
    内金: 10000,
    プレミアムパック: 99000,
    お送り代: 2200,
  },
  クリーニング: {
    商品情報: {
      別衿加工代: 5500,
    },
    お持込品: 33000,
    送料保険料: 5500,
  },
  単品サービス: {
    内金: 10000,
  },
} as const
