import { FurisodeState } from '../../../types'

const state = (): FurisodeState => ({
  /**
   * NOTE: selections = patternLine
   * ↓保持される選択肢のindex
   * [0]商品の指定方法
   * [1]裄補正するか
   * [2]どちらのレンタルか || 袖補正するか
   */
  selections: [0, 0, 0],
  product: null,
  input: {
    productName: '',
    // ↓Wフォトで使用している
    rentalPrice: 0,
  },
  price: {
    buy: 0,
    rental: 0,
  },
  isFree: false,
  isFurisodeOnHold: false, // 振袖を保留にするか否か
  isValidate: null,
  catalogType: null, // オーダー、 お誂え、仕立て上がり
  isOrderOutsideTargetYear: false, // 対象年度外のオーダーレンタルか
  contractId: 0, // 予約済み着物が変更中の契約で選ばれているものか判定するために保持
  properProductId: null,
})

export default state
