import { MutationTree } from 'vuex'

import { initialState } from './state'

// ** 型 **
import type { State, ProductNameList } from './state'
import type { SingleItem } from '~/api/singleItemRepository'

type SetSingleItemArg = { items: SingleItem[]; productName: ProductNameList }

const mutations: MutationTree<State> = {
  setSingleItems: (state, { items, productName }: SetSingleItemArg) => {
    state[productName] = items
  },

  delete: (state) => {
    Object.assign(state, initialState)
  },
}

export default mutations
