import { GetterTree } from 'vuex'
import { RootState, AccessoriesFullsetState } from '../../types'

const getters: GetterTree<AccessoriesFullsetState, RootState> = {
  getResultSelectionsJK: (state: AccessoriesFullsetState) => {
    return state.resultSelectionsJK
  },

  getResultSelectionsWphoto: (state: AccessoriesFullsetState) => {
    return state.resultSelectionsWphoto
  },

  getResultSelectionsMotherAndDaughterPlan: (
    state: AccessoriesFullsetState
  ) => {
    return state.resultSelectionsMotherAndDaughterPlan
  },

  getSelectedColor: (state: AccessoriesFullsetState) => {
    return state.tabiColor
  },
}

export default getters
