import { ActionTree } from 'vuex'
import { RootState, SisterDiscountState } from '../../../types'

const actions: ActionTree<SisterDiscountState, RootState> = {
  setSelection: ({ commit }, selection) => {
    commit('setSelection', selection)
  },

  setCustomer: ({ commit }, customer) => {
    commit('setCustomer', customer)
  },

  setIsValidate: ({ commit }, isValidate: boolean) => {
    commit('setIsValidate', isValidate)
  },

  deleteCustomer: ({ commit }) => {
    commit('deleteCustomer')
  },
}

export default actions
