/** type */
import { ClothingSizeType } from './enums/clothingSizeType'

export const BODY_TYPE_SIZE = {
  N: 0,
  B: 1,
  BB: 2,
} as const

/**
 * @note LLL=3L
 */
export const SIZE_TYPE = {
  S: 0,
  M: 1,
  L: 2,
  LL: 3,
  /**
   * @note 3L
   */
  LLL: 4,
} as const

export const bodyTypeSizeSection = [
  {
    label: 'N',
    value: BODY_TYPE_SIZE.N,
  },
  {
    label: 'B',
    value: BODY_TYPE_SIZE.B,
  },
  {
    label: 'BB',
    value: BODY_TYPE_SIZE.BB,
  },
] as const

/**
 * お渡し済み
 */
export const PASS_STATUS = {
  /** 未 */
  PENDING: 0,
  /** 済 */
  DONE: 1,
} as const

export type BodyData = {
  bust: number
  hip: number
  footSize: number
}
type BodySizes = {
  bust: string | number
  hip: string | number
}

export type TypeBodySize = typeof BODY_TYPE_SIZE[keyof typeof BODY_TYPE_SIZE]
export type TypeSize = typeof SIZE_TYPE[keyof typeof SIZE_TYPE]
type BodyPartsSize = {
  body: TypeBodySize
  nagajuban: TypeBodySize
  datejime: TypeBodySize
  waist_string: TypeBodySize
  colin_belt: TypeBodySize
  zouri: TypeSize
  underwear: TypeSize
  tabi: TypeSize
  obimakura: TypeBodySize
}

export default class DressingItem {
  static readonly bodyTypeSize = BODY_TYPE_SIZE
  static readonly sizeType = SIZE_TYPE
  static readonly passType = PASS_STATUS

  static toSizeString = (
    size: number,
    type: 'footSize' | 'bodySize' = 'footSize'
  ) => {
    const sizeTypeObj =
      type === 'footSize' ? DressingItem.sizeType : DressingItem.bodyTypeSize
    const result = Object.entries(sizeTypeObj).find(([_k, v]) => v === size)

    if (!result) {
      return ''
    }
    if (result[0] === 'LLL') return '3L'

    return result[0]
  }

  /**
   * 契約書作成、更新APIリクエスト&レスポンス時は6: N, 7: B, 8: BBが返ってくる為
   * indexに変換してして表示させる
   * @param {number | undefined | null} sizeId - 6,7,8 または 1,2,3,4,5
   * @param {string} type - bodySizeの場合 長襦袢・腰紐・コーリンベルト・伊達〆・帯枕、sizeの場合 草履・肌着・足袋
   * @return {string} - bodySizeの場合：N,B,BB、sizeの場合：S,M,L,LL,LLL
   */
  static toSizeStringFromId = (
    sizeId: number | undefined | null,
    type: 'footSize' | 'bodySize'
  ) => {
    if (typeof sizeId !== 'number') return ''

    const sizeIndex =
      type === 'footSize'
        ? ClothingSizeType.toShoesSizeIndex(sizeId)
        : ClothingSizeType.toClothingSizeIndex(sizeId)
    return DressingItem.toSizeString(sizeIndex, type)
  }

  static calculateBodyType = ({ bust, hip }: BodySizes) => {
    const bodyType = bodyTypeSizeSection.find((bodyType) => {
      const caluculatedResult = DressingItem.measurement({
        bust: Number(bust),
        hip: Number(hip),
        footSize: 0,
      })
      return bodyType.value === caluculatedResult.body
    })
    return bodyType?.label ?? '-'
  }

  /**
   * バスト、ヒップ、足のサイズをもとに体型やサイズを算出する
   * NOTE: https://docs.google.com/spreadsheets/d/1qzZmRRovlKL1WzWWpZAiDIdgfAKYlnXdJ4B32lAZjUI/edit#gid=1342284710
   */
  static measurement = ({ bust, hip, footSize }: BodyData) => {
    const defaultSize: BodyPartsSize = {
      body: BODY_TYPE_SIZE.N,
      nagajuban: BODY_TYPE_SIZE.N,
      datejime: BODY_TYPE_SIZE.N,
      waist_string: BODY_TYPE_SIZE.N,
      colin_belt: BODY_TYPE_SIZE.N,
      zouri: SIZE_TYPE.S,
      underwear: SIZE_TYPE.M,
      tabi: SIZE_TYPE.S,
      obimakura: BODY_TYPE_SIZE.N,
    }

    const largerParts = bust > hip ? bust : hip
    // 体型
    if (largerParts >= 105 && largerParts < 120) {
      defaultSize.body = BODY_TYPE_SIZE.B
    } else if (largerParts >= 120) {
      defaultSize.body = BODY_TYPE_SIZE.BB
    }

    // 伊達〆
    if (largerParts >= 96 && largerParts < 120) {
      defaultSize.datejime = BODY_TYPE_SIZE.B
      defaultSize.obimakura = BODY_TYPE_SIZE.B
    } else if (largerParts >= 120) {
      defaultSize.datejime = BODY_TYPE_SIZE.BB
      defaultSize.obimakura = BODY_TYPE_SIZE.BB
    }

    // 腰紐
    // コーリンベルト
    if (largerParts >= 105) {
      defaultSize.colin_belt = BODY_TYPE_SIZE.B
      defaultSize.waist_string = BODY_TYPE_SIZE.B
    }

    // 長襦袢
    // NOTE:体系と一致する
    defaultSize.nagajuban = defaultSize.body

    // ショール
    if (largerParts >= 100 && largerParts <= 110) {
      defaultSize.underwear = SIZE_TYPE.L
    } else if (largerParts >= 111) {
      defaultSize.underwear = SIZE_TYPE.LL
    }

    // NOTE: 足のサイズは24.0や25.5など0.5刻みなのでそれ以外は例外としたい
    const strlen = footSize.toString().split('.')
    if (strlen.length > 1 && !(strlen[1] === '0' || strlen[1] === '5')) {
      console.error("小数点第一は'5'のみ指定可能です")
    }

    // 草履
    if (footSize >= 22.5 && footSize <= 23.5) {
      defaultSize.zouri = SIZE_TYPE.M
    } else if (footSize >= 24.0 && footSize <= 24.5) {
      defaultSize.zouri = SIZE_TYPE.L
    } else if (footSize >= 25.0 && footSize <= 25.5) {
      defaultSize.zouri = SIZE_TYPE.LL
    } else if (footSize >= 26.0) {
      defaultSize.zouri = SIZE_TYPE.LLL
    }

    // 足袋
    if (footSize >= 22.5 && footSize <= 23.0) {
      defaultSize.tabi = SIZE_TYPE.M
    } else if (footSize >= 23.5 && footSize <= 24.0) {
      defaultSize.tabi = SIZE_TYPE.L
    } else if (footSize >= 24.5) {
      defaultSize.tabi = SIZE_TYPE.LL
    }

    return defaultSize
  }
}
