import { ActionTree } from 'vuex'
import { RootState, ServicePlanState } from '../../../types'

const actions: ActionTree<ServicePlanState, RootState> = {
  setSelection: ({ commit }, selection) => {
    commit('setSelection', selection)
  },

  setCustomer: ({ commit }, customer) => {
    commit('setCustomer', customer)
  },

  setDate: ({ commit }, date) => {
    commit('setDate', date)
  },

  setIsValidate: ({ commit }, isValidate: boolean) => {
    commit('setIsValidate', isValidate)
  },

  deletePlan: ({ commit }) => {
    commit('delete')
  },

  deleteCustomer: ({ commit }) => {
    commit('deleteCustomer')
  },

  deleteDate: ({ commit }) => {
    commit('deleteDate')
  },
}

export default actions
