export type OldData = {
  contractId: number | undefined
  serviceType: number | undefined
  isDigitCorrection: boolean | undefined
  isSleeveCorrection: boolean | undefined
  furisodeCategory: number | undefined
  furisodeCatalogCode: string | undefined
}

const state = (): OldData => ({
  contractId: undefined,
  serviceType: undefined,
  isDigitCorrection: undefined,
  isSleeveCorrection: undefined,
  furisodeCategory: undefined,
  furisodeCatalogCode: undefined,
})

export default state
