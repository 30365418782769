import { computed, useRoute } from '@nuxtjs/composition-api'

/*
 * 今いる見積ページの見積種類を判定するフラグを提供する関数
 */
export const useEstimateTypeFlags = () => {
  const route = useRoute()

  const in卒業式見積 = computed(() => {
    return route.value.path.includes('estimate/graduationCeremony')
  })

  const in成人式見積 = computed(() => {
    return route.value.path.includes('estimate/comingOfAgeCeremony')
  })

  const in参列見積 = computed(() => {
    return route.value.path.includes('estimate/attendance')
  })

  const in単品サービス見積 = computed(() => {
    return route.value.path.includes('estimate/singleItemService')
  })

  return {
    in卒業式見積,
    in成人式見積,
    in参列見積,
    in単品サービス見積,
  }
}
