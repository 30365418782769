import { MemoStateGraduate } from '~/store/types'

export const getInitialStore = (): MemoStateGraduate => {
  return {
    text: '',
    is_chance: false,
    estimate_id: 0,
    memo_id: null,
    customer_id: null,
  }
}

const state = (): MemoStateGraduate => getInitialStore()

export default state
