export const PAYMENT_METHOD = {
  CASH: 1, // 現金
  GIFT_CERTIFICATES: 2, // 商品券
  TRANSFER: 3, // 振込
  POINT: 4, // 友の会ポイント
  CARD: 5, // カード
  LOAN: 6, // ローン
  CASH_ON_DELIVERY: 7, // 代引き
  E_MONEY: 8, // 電子マネー
  FAMILY_POINT: 9, // 友の会ポイント（ご家族保有）
  RECEIPT_DISCOUNT: 10, // 入金値引き
  POINT_ROUND_OFF: 50, // ポイント端数処理
} as const
