import { computed, Ref } from '@nuxtjs/composition-api'
import { EstimateDetail } from '~/api/estimateRepository'
import ProductId from '~/constants/enums/productClassification'
import { getSortedItemsByOrder } from '~/utils/utils'
import { OTHER_ITEMS } from '~/constants/estimate/config'
import { EstimateSinglePriceType } from '~/constants/enums/estimateSinglePriceType'
import { getPlanFlags } from '~/composable/estimate/service/OldData/restoreOldData/RestateOldDataPlan'

export const useRestateその他商品 = (
  estimate: Ref<EstimateDetail | undefined>
) => {
  return computed(() => {
    if (!estimate.value?.items) return []

    const { isフルセットプラン } = getPlanFlags(estimate.value.plan)

    const otherItems = getSortedItemsByOrder(
      estimate.value.items,
      ProductId.otherItem
    )

    return otherItems.map((value) => {
      const isHakamaNoUseInFullset = isフルセットプラン && !value.is_hakama
      const isSingleRental =
        value.price_type === EstimateSinglePriceType.singleRentalPrice
      return {
        product_name: value.product_name || '',
        price_type: isSingleRental
          ? OTHER_ITEMS.PRICE_TYPE.単品レンタル
          : OTHER_ITEMS.PRICE_TYPE.単品購入,
        price: isHakamaNoUseInFullset
          ? null
          : (isSingleRental ? value.rental_price : value.purchase_price) || 0,
        is_hakama_used: value.is_hakama
          ? OTHER_ITEMS.袴利用.する
          : OTHER_ITEMS.袴利用.しない,
        single_rental_price: isHakamaNoUseInFullset
          ? value.rental_price ?? 0
          : null,
        single_purchase_price: isHakamaNoUseInFullset
          ? value.purchase_price ?? 0
          : null,
      }
    })
  })
}
