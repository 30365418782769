import { isAxiosError, CustomError, HttpCode } from '@/utils/exception'
import axios from 'axios'
import DateTime from '~/utils/dateTime'

const url = process.env.NUXT_ENV_SLACK_WEBHOOK_URL
const baseUrl = process.env.BASE_URL || 'http://localhost'
const basePath = process.env.BASE_PATH || ''
const isProduction = process.env.NODE_ENV === 'production'
const userName = 'Error Notification'

const createField = (params: Record<string, string[]>) =>
  Object.entries(params).map(([key, value]) => ({
    title: key,
    value: JSON.stringify(value),
  }))

/**
 * ログ設計指針　https://docs.google.com/spreadsheets/d/1bmTteobFFNqhfI-TYvRkX962iscyE8kB-m0ITLXshgs/edit#gid=0
 */
const slack = {
  notifyApiError: async (
    errorCode: number | string,
    name: string,
    message: string,
    errors: Record<string, string[]>,
    title: string,
    params: unknown
  ) => {
    if (!url || !isProduction) {
      return
    }

    const fields = createField(errors)
    const now = new Date()

    const data = {
      username: userName,
      attachments: [
        {
          pretext: baseUrl + basePath,
          title: `:rotating_light: Api Error Notification　(${title})\n ${new DateTime().toDateTimeString()}`,
          text: `*response*\n\`\`\`error_code: ${errorCode}\nname: ${name}\nmessage: ${message}\n\`\`\` *log*\n \`\`\`{\n"level": "error",\n"message":${message},\n"timestamp":${now.toTimeString()}\n}\`\`\` *params*\n \`\`\`${JSON.stringify(
            params,
            null,
            '\t'
          )}\n\`\`\``,
          color: 'warning',
          fields,
          footer: `${new Date()}_ERROR_${name}`,
          ts: new Date().getTime().toString(),
        },
      ],
    }

    await axios.post(url, JSON.stringify(data), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-type': 'application/x-www-form-urlencoded',
      },
      data,
    })
  },

  notifyFatalError: async (
    name: string,
    message: string,
    errors: Record<string, string[]>,
    title: string
  ) => {
    if (!url || !isProduction) {
      return
    }

    const fields = createField(errors)
    const now = new Date()
    const data = {
      username: userName,
      attachments: [
        {
          pretext: baseUrl + basePath,
          title: `:skull_and_crossbones: Fatal Error Notification　(${title})\n ${new DateTime().toDateTimeString()}`,
          text: `*log*\n \`\`\`{\n"level": "fatal",\n"message":${message},\n"timestamp":${now.toTimeString()}\n}\`\`\``,
          color: 'danger',
          fields,
          footer: `${new Date()}_FATAL_ERROR_${name}`,
          ts: new Date().getTime().toString(),
        },
      ],
    }
    await axios.post(url, JSON.stringify(data), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-type': 'application/x-www-form-urlencoded',
      },
      data,
    })
  },
}

const createErrors = (code: number, errors?: Record<string, string[]>) => {
  if (errors && Object.keys(errors).length > 0) {
    return errors
  }
  // NOTE: errorsが空or存在しない場合はアカウント関係のエラー
  switch (code) {
    case 400:
    case 401:
    case 403:
    case 404:
      return {
        token: ['アクセストークンが無効です'],
      }
  }

  // NOTE: 通過しない想定
  return {}
}

export const notifyError = async (
  error: unknown,
  title: string,
  params: any, //TODO: ジェネリクスに変更したい
  isUpdateContract?: boolean
) => {
  if (isAxiosError(error)) {
    const errorCode = error.response?.data?.error_code ?? 500
    const errorMessage =
      errorCode === HttpCode.UNAUTHORIZED
        ? 'invalid token'
        : error.response?.data.message
    const customError = new CustomError(
      errorCode,
      errorMessage ?? 'Internal Server Error [Custom]',
      createErrors(errorCode, error.response?.data.errors)
    )

    const errorStr = Object.values(customError.errors).join('\r')
    /**
     * NOTE: バックエンドからerrorsは下記のように返却されていたため、propertyにfetched_atが存在するかで判定
     * { fetched_at: ["マイページからデータが更新されています。最新のデータを取得して更新してください。"] }
     */
    const hasFetchedAt = customError.errors.hasOwnProperty('fetched_at')
    const fetchedAtExclusiveControlError =
      hasFetchedAt && errorCode === HttpCode.EXCLUSIVE_CONTROL

    //NOTE: fetched_atがリクエスト時にない場合、fetched atという文言を含んでバックエンドからバリデーションエラーで返却される
    const fetchedAtUnprocessableContentError =
      isUpdateContract &&
      errorCode === HttpCode.UNPROCESSABLE_CONTENT &&
      (errorStr.includes('fetched_at') || errorStr.includes('fetched at'))

    let message =
      'APIでエラーが発生しました。下記はエラー内容です。\r' + errorStr
    if (fetchedAtUnprocessableContentError) {
      message =
        '別システムで契約内容が変更された可能性があるため、変更を完了できません。\r契約詳細画面に遷移し、契約変更を最初からやり直してください。'
    } else if (fetchedAtExclusiveControlError) {
      message =
        'マイページからデータが更新されたため、契約書を保存できませんでした。\r再度、見積り反映またはそのまま契約変更からやり直してください。'
    }

    alert(message)

    await slack
      .notifyApiError(
        errorCode,
        customError.name,
        customError.message,
        customError.errors,
        title,
        params
      )
      .catch(console.log)
    return customError
  }

  if (error instanceof Error) {
    console.log('Error: ', error)
    const customError = new CustomError(500, error.message, {
      stack: error.stack ? [error.stack] : [],
    })

    alert(
      'エラーが発生しました。下記はエラー内容です。\r' +
        Object.entries(customError.errors)
          .map((item) => {
            return item.join(':')
          })
          .join('\r')
    )

    await slack
      .notifyFatalError(
        customError.name,
        customError.message,
        customError.errors,
        title
      )
      .catch(console.log)

    return customError
  }

  console.log('Internal Server Error: ', error)
  const customError = new CustomError(500, 'Internal Server Error [Custom]', {})

  alert('予期しないエラーが発生しました。システム担当者にお問い合わせください')

  await slack
    .notifyFatalError(
      customError.name,
      customError.message,
      customError.errors,
      title
    )
    .catch(console.log)
  return customError
}
