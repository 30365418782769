import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { ServicePlanState } from '~/store/types'

export const useRestoreプラン = () => {
  const store = useComingStore<ServicePlanState>('ServicePlans')

  const restoreプラン = (restate: Restate) => {
    if (!restate.plan) return
    const { plan } = restate

    /* 初期値を設定 ※必ず入力される値はここで設置 */
    /**
     * NOTE: selections = patternLine
     * ↓保持される選択肢のindex (「フルセット・持込B・持込A・写のみ」の4列)
     * [0]プラン選択
     * [1]着付け区分 || お姉様購入か || お姉様購入か ||  振袖の利用方法
     * [2]         || 当日のみか   ||  着付け区分 || お姉様購入か
     * [3]         ||  着付け区分  ||
     */
    const formValues: ServicePlanState = {
      selections: [plan.optionIdプラン, 0, 0, 0],
      customer: null,
      date: plan.成人式使用日 ?? '',
      isValidate: false,
    }

    /* 以下で入力内容の条件に基づき、必要な項目を埋めていく */

    if (plan.flags.isフルセットプラン) {
      formValues.selections[1] = plan.optionId着付け区分
    }

    if (plan.flags.isお持込プラン) {
      formValues.selections[1] = plan.optionIdお姉様購入

      if (plan.flags.isお姉様購入) {
        formValues.selections[2] = plan.optionId当日のみか
        formValues.selections[3] = plan.optionId着付け区分
        formValues.customer = plan.お姉様検索結果.value
      } else {
        formValues.selections[2] = plan.optionId着付け区分
      }
    }

    if (plan.flags.isお写真のみプラン) {
      formValues.selections[1] = plan.optionId利用方法
      if (plan.flags.is写のみ持込) {
        formValues.selections[2] = plan.optionIdお姉様購入
      }
      if (plan.flags.is写のみ持込 && plan.flags.isお姉様購入) {
        formValues.customer = plan.お姉様検索結果.value
      }
    }

    /* ストアに内容を保存する */
    store.setOf<ServicePlanState['selections']>(
      'setSelection',
      formValues.selections
    )
    store.setOf<ServicePlanState['customer']>(
      'setCustomer',
      formValues.customer
    )
    store.setOf<ServicePlanState['date']>('setDate', formValues.date)
    store.setIsValidate(false)
  }

  return { restoreプラン }
}
