import { ActionTree } from 'vuex'
import { RootState, ZoriBagState } from '../../../types'

const actions: ActionTree<ZoriBagState, RootState> = {
  setSelectionZori: ({ commit }, selection) => {
    commit('setSelectionZori', selection)
  },

  setSelectionBag: ({ commit }, selection) => {
    commit('setSelectionBag', selection)
  },

  setProductZori: ({ commit }, product) => {
    commit('setProductZori', product)
  },

  setProductBag: ({ commit }, product) => {
    commit('setProductBag', product)
  },

  setPriceZori: ({ commit }, price) => {
    commit('setPriceZori', price)
  },

  setPriceBag: ({ commit }, price) => {
    commit('setPriceBag', price)
  },

  setInputZori: ({ commit }, inputZori) => {
    commit('setInputZori', inputZori)
  },

  setInputBag: ({ commit }, inputBag) => {
    commit('setInputBag', inputBag)
  },

  setIsValidate: ({ commit }, isValidate: boolean) => {
    commit('setIsValidate', isValidate)
  },

  delete: ({ commit }) => {
    commit('delete')
  },
}

export default actions
