import { computed } from '@nuxtjs/composition-api'
import { usePlanFlags } from '~/composable/estimate/domain/Graduation/PlanFlags'
import {
  Enum重ね衿_着物持込,
  Enum重ね衿_着物レンタル1個目,
  Enum重ね衿_着物レンタル2個以上,
} from '~/constants/estimate/graduationOptions'
import { FormValues重ね衿_着物レンタル1個目 } from '~/composable/estimate/viewModel/graduation/Fields/Fields重ね衿_着物レンタル1個目'
import { FormValues重ね衿_着物レンタル2個以上 } from '~/composable/estimate/viewModel/graduation/Fields/Fields重ね衿_着物レンタル2個以上'
import { FormValues重ね衿_着物持込 } from '~/composable/estimate/viewModel/graduation/Fields/Fields重ね衿_着物持込'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import {
  getPriceFromEditPrice,
  getPriceFromSelectPriceWithFreeInput,
  getShowPrice,
} from '~/composable/estimate/repository/graduation/states/utilities'
import { useState商品設定 } from '~/composable/estimate/repository/graduation/states/StateOtherTabs'
import { ShowPriceType } from '~/composable/estimate/repository/graduation/states/types'
import { WithFreeInput } from '~/store/Estimate/GraduationCeremony/types'
import { ItemPlan } from '~/constants/enums/itemPlan'

export type Kasaneeri = {
  品種区分: 5
  商品名: string | null
  小物プラン: number | null
  レンタル価格: number | null
  購入価格: number | null
  見積表示: ShowPriceType | null
  表示価格: number | null
  isランクアップ: boolean
  isセット内: boolean
  label: string
  PP適用時フリー入力?: number | null
}

export const useState重ね衿 = () => {
  const store重ね衿_着物レンタル1 =
    useGraduateStore<FormValues重ね衿_着物レンタル1個目>(
      'tab重ね衿_着物レンタル1'
    )
  const store重ね衿_着物レンタル2 =
    useGraduateStore<FormValues重ね衿_着物レンタル2個以上>(
      'tab重ね衿_着物レンタル2'
    )
  const store重ね衿_着物レンタル3 =
    useGraduateStore<FormValues重ね衿_着物レンタル2個以上>(
      'tab重ね衿_着物レンタル3'
    )
  const store重ね衿_着物持込1 =
    useGraduateStore<FormValues重ね衿_着物持込>('tab重ね衿_着物持込1')
  const store重ね衿_着物持込2 =
    useGraduateStore<FormValues重ね衿_着物持込>('tab重ね衿_着物持込2')
  const store重ね衿_着物持込3 =
    useGraduateStore<FormValues重ね衿_着物持込>('tab重ね衿_着物持込3')
  const { hasKimonoRental } = usePlanFlags()
  const state商品設定 = useState商品設定()

  const values重ね衿_着物レンタル1 = store重ね衿_着物レンタル1.getAll()
  const values重ね衿_着物レンタル2 = store重ね衿_着物レンタル2.getAll()
  const values重ね衿_着物レンタル3 = store重ね衿_着物レンタル3.getAll()
  const values重ね衿_着物持込1 = store重ね衿_着物持込1.getAll()
  const values重ね衿_着物持込2 = store重ね衿_着物持込2.getAll()
  const values重ね衿_着物持込3 = store重ね衿_着物持込3.getAll()

  return computed(() => {
    const kasaneeris: Kasaneeri[] = []
    const number = state商品設定.value.重ね衿

    if (hasKimonoRental.value) {
      /*
       * プランで着物レンタルを選んだ際の重ね衿選択内容(1個目と２個目以降の中身が違う)
       */
      if (number < 1) return kasaneeris
      const kasaneeri = {
        種別: values重ね衿_着物レンタル1.value.kasaneeri_one種別,
        形態: values重ね衿_着物レンタル1.value.kasaneeri_one形態,
        セット内価格: values重ね衿_着物レンタル1.value.kasaneeri_oneセット内,
        ランクアップ価格選択:
          values重ね衿_着物レンタル1.value.kasaneeri_oneランクアップ,
        ランクアップ価格入力:
          values重ね衿_着物レンタル1.value.kasaneeri_oneフリー入力,
      }
      kasaneeris.push(
        getプラン内重ね衿(kasaneeri, Enum重ね衿_着物レンタル1個目)
      )

      const more着物レンタルValues = [
        values重ね衿_着物レンタル2,
        values重ね衿_着物レンタル3,
      ]
      const addedValues = more着物レンタルValues.slice(0, number - 1)
      if (!addedValues.length) return kasaneeris

      const addedKasaneeris = addedValues.map((val) => {
        const kasaneeri = {
          種別: val.value.kasaneeri_multi種別,
          単品レンタル選択: val.value.kasaneeri_multi単品レンタル,
          単品購入選択: val.value.kasaneeri_multi単品購入,
          単品レンタル入力: val.value.kasaneeri_multi単品レンタルフリー入力,
          単品購入入力: val.value.kasaneeri_multi単品購入フリー入力,
          見積表示: val.value.kasaneeri_multi見積表示,
          形態: val.value.kasaneeri_multi形態,
        }
        return getプラン外重ね衿(
          kasaneeri,
          Enum重ね衿_着物レンタル2個以上,
          hasKimonoRental.value
        )
      })
      kasaneeris.push(...addedKasaneeris)
      return kasaneeris
    } else {
      /*
       * プランで着物持込を選んだ際の重ね衿選択内容
       */
      const all着物持込Values = [
        values重ね衿_着物持込1,
        values重ね衿_着物持込2,
        values重ね衿_着物持込3,
      ]
      const selectedValues = all着物持込Values.slice(0, number)
      return selectedValues.map((val) => {
        const kasaneeri = {
          種別: val.value.kasaneeri種別,
          単品レンタル選択: val.value.kasaneeri単品レンタル,
          単品購入選択: val.value.kasaneeri単品購入,
          単品レンタル入力: val.value.kasaneeri単品レンタルフリー入力,
          単品購入入力: val.value.kasaneeri単品購入フリー入力,
          見積表示: val.value.kasaneeri見積表示,
        }
        return getプラン外重ね衿(kasaneeri, Enum重ね衿_着物持込)
      })
    }
  })
}

const getプラン内重ね衿 = (
  kasaneeri: {
    種別: number | null
    形態: number | null
    セット内価格: string | null
    ランクアップ価格選択: WithFreeInput | null
    ランクアップ価格入力: string | null
  },
  Enum: typeof Enum重ね衿_着物レンタル1個目
) => {
  const firstKasaneeri: Kasaneeri = {
    品種区分: 5,
    商品名: null,
    小物プラン: null,
    レンタル価格: null,
    購入価格: null,
    見積表示: null,
    表示価格: null,
    isランクアップ: false,
    isセット内: false,
    label: '',
    PP適用時フリー入力: null,
  }
  const 種別選択 = kasaneeri.種別
  const 形態選択 = kasaneeri.形態
  const お持込を選択 = 種別選択 === Enum.種別.お持込
  const セット内を選択 = 形態選択 === Enum.形態.セット内
  firstKasaneeri.小物プラン = お持込を選択 ? Enum.種別.お持込 : 形態選択

  firstKasaneeri.label = お持込を選択 ? 'お持込' : ''

  if (セット内を選択 && !お持込を選択) {
    firstKasaneeri.レンタル価格 = getPriceFromEditPrice(kasaneeri.セット内価格)
    firstKasaneeri.表示価格 = firstKasaneeri.レンタル価格
    firstKasaneeri.label = 'セット内'
    firstKasaneeri.isセット内 = true
    // PP適用でフリー価格を見積一覧から設定した場合
    if (kasaneeri.ランクアップ価格入力)
      firstKasaneeri.PP適用時フリー入力 = getPriceFromEditPrice(
        kasaneeri.ランクアップ価格入力
      )
  } else if (!セット内を選択 && !お持込を選択) {
    firstKasaneeri.レンタル価格 = getPriceFromSelectPriceWithFreeInput(
      kasaneeri.ランクアップ価格選択,
      kasaneeri.ランクアップ価格入力
    )
    firstKasaneeri.表示価格 = firstKasaneeri.レンタル価格
    firstKasaneeri.isランクアップ = true
    firstKasaneeri.label = 'ランクアップ'
  }
  return firstKasaneeri
}

const getプラン外重ね衿 = (
  kasaneeri: {
    種別: number | null
    単品レンタル選択: WithFreeInput | null
    単品購入選択: WithFreeInput | null
    単品レンタル入力: string | null
    単品購入入力: string | null
    見積表示: number | null
    形態?: number | null
  },
  Enum: {
    種別: typeof Enum重ね衿_着物持込['種別']
    見積表示: typeof Enum重ね衿_着物持込['見積表示']
  },
  hasKimonoRental?: boolean
) => {
  const moreKasaneeri: Kasaneeri = {
    品種区分: 5,
    商品名: null,
    小物プラン: null,
    レンタル価格: null,
    購入価格: null,
    見積表示: null,
    表示価格: null,
    isランクアップ: false,
    isセット内: false,
    label: '',
  }
  const 種別選択 = kasaneeri.種別
  const お持込を選択 = 種別選択 === Enum.種別.お持込

  if (お持込を選択) {
    moreKasaneeri.label = 'お持込'
    moreKasaneeri.小物プラン = Enum.種別.お持込
    return moreKasaneeri
  }

  // 以下、レンタル・単品購入選択時
  moreKasaneeri.小物プラン = kasaneeri.種別
  moreKasaneeri.レンタル価格 = getPriceFromSelectPriceWithFreeInput(
    kasaneeri.単品レンタル選択,
    kasaneeri.単品レンタル入力
  )
  moreKasaneeri.購入価格 = getPriceFromSelectPriceWithFreeInput(
    kasaneeri.単品購入選択,
    kasaneeri.単品購入入力
  )
  const { showType, showPrice } = getShowPrice(
    kasaneeri.見積表示,
    moreKasaneeri.レンタル価格,
    moreKasaneeri.購入価格,
    Enum.見積表示,
    hasKimonoRental
  )
  moreKasaneeri.見積表示 = showType
  moreKasaneeri.表示価格 = showPrice
  moreKasaneeri.isランクアップ = kasaneeri.形態 === ItemPlan.rankUpInPremium
  return moreKasaneeri
}
