import { GetterTree } from 'vuex'
import { RootState, AccessoryPlanState } from '../../../types'

const getters: GetterTree<AccessoryPlanState, RootState> = {
  getState: (state: AccessoryPlanState) => {
    return state
  },

  // NOTE: useComingStore用に追加
  getAllState: (state) => {
    return state
  },

  getSelections: (state: AccessoryPlanState) => {
    return state.selections
  },

  getInput: (state: AccessoryPlanState) => {
    return state.input
  },

  getPrice: (state: AccessoryPlanState) => {
    return state.price
  },

  getIsValidate: (state: AccessoryPlanState) => {
    return state.isValidate
  },
}

export default getters
