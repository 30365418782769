import { useContext, ref } from '@nuxtjs/composition-api'
import { dateToStr } from '~/utils/utils'

/** classes */
import { notifyError } from '~/composable/general/SlackErrorLog'

// TODO: src/composable/api/useMemo.tsとマージしたい

export type Memo = {
  customer_id?: number
  memo_id: number
  estimate_id: number | null
  contract_id: number | null
  receipt_id: number | null
  shop_name: string
  last_name: string
  first_name: string
  is_chance: boolean
  body: string
  created_at: string
  updated_at: string
}

export type CreateMemoInput = {
  estimate_id: number | null
  contract_id: number | null
  receipt_id: number | null
  customer_id: number
  staff_id: number
  shop_id: number
  is_chance: boolean
  body: string
}

export type UpdateMemoInput = {
  memo_id: number
  customer_id: number
  staff_id: number
  shop_id: number
  is_chance: boolean
  body: string
}

export type DeleteMemoParam = {
  customer_id: number
  memo_id: number
}

type FetchMemoParam =
  | {
      type: 'estimate'
      estimate_id: number
    }
  | {
      type: 'contract'
      contract_id: number
    }
  | {
      type: 'custody'
      receipt_id: number
    }

export default function UseMemo() {
  const context = useContext()
  const memos = ref<Memo[]>([])
  const loading = ref(false)
  const error = ref(false)

  const createMemo = async (input: CreateMemoInput, title: string) => {
    loading.value = true
    error.value = false
    try {
      const response = await context.$axios.$post<Memo>(
        `customers/${input.customer_id}/memos`,
        input
      )
      // FIXME: 一部の入れ替えの方が良い場合は変更する
      memos.value = [
        ...memos.value,
        {
          ...response,
          customer_id: input.customer_id,
          created_at: response.created_at ? dateToStr(response.created_at) : '',
          updated_at: response.updated_at ? dateToStr(response.updated_at) : '',
        },
      ]
    } catch (err) {
      await notifyError(
        err,
        `${title}メモ作成、顧客番号: ${input.customer_id}、見積番号: ${
          input.estimate_id ?? '-'
        }、契約番号: ${input.contract_id ?? '-'}、お預かり書番号: ${
          input.receipt_id ?? '-'
        }`,
        input
      )
      alert('メモ作成に失敗しました。')
      error.value = true
    } finally {
      loading.value = false
    }
  }

  const updateMemo = async (input: UpdateMemoInput) => {
    loading.value = true
    error.value = false
    try {
      const response = await context.$axios.$patch<Memo>(
        `customers/${input.customer_id}/memos/${input.memo_id}`,
        input
      )
      // FIXME: 一部の入れ替えの方が良い場合は変更する
      memos.value = memos.value.map((memo) => {
        if (memo.memo_id === response.memo_id) {
          return {
            ...response,
            customer_id: input.customer_id,
            created_at: response.created_at
              ? dateToStr(response.created_at)
              : '',
            updated_at: response.updated_at
              ? dateToStr(response.updated_at)
              : '',
          }
        }

        return memo
      })
    } catch (err) {
      alert(err)
      error.value = true
    } finally {
      loading.value = false
    }
  }

  const deleteMemo = async (param: DeleteMemoParam) => {
    loading.value = true
    error.value = false
    try {
      await context.$axios.$delete<void>(
        `customers/${param.customer_id}/memos/${param.memo_id}`
      )
      // FIXME: 一部の入れ替えの方が良い場合は変更する
      memos.value = memos.value.filter((memo) => memo.memo_id !== param.memo_id)
    } catch (err) {
      await notifyError(
        err,
        `メモ削除、顧客番号: ${param.customer_id}、メモID: ${param.memo_id}`,
        param
      )
      alert(err)
      error.value = true
    } finally {
      loading.value = false
    }
  }

  const fetchMemos = async (customerId: number) => {
    loading.value = true
    error.value = false
    try {
      const response = await context.$axios.$get<{ memos: Memo[] }>(
        `customers/${customerId}/memos`
      )
      memos.value = response.memos.map((memo) => ({
        ...memo,
        customer_id: customerId,
        created_at: memo.created_at ? dateToStr(memo.created_at) : '',
        updated_at: memo.updated_at ? dateToStr(memo.updated_at) : '',
      }))

      return memos.value
    } catch (error) {
      alert(error)
    } finally {
      loading.value = false
      error.value = true
    }
  }

  /**
   * @note estimate_idとcontract_idを同時にクエリとして投げることができないので下記のような条件を入れている
   * @param {Number} customerId
   * @param {FetchMemoParam} param
   * @return {Memo[]}
   */
  const fetchMemosById = async (customerId: number, param: FetchMemoParam) => {
    loading.value = true
    error.value = false
    let path: string

    switch (param.type) {
      case 'estimate':
        path = `customers/${customerId}/memos?estimate_id=${param.estimate_id}`
        break
      case 'contract':
        path = `customers/${customerId}/memos?contract_id=${param.contract_id}`
        break
      case 'custody':
        path = `customers/${customerId}/memos?receipt_id=${param.receipt_id}`
        break
      default:
        path = `customers/${customerId}/memos`
    }
    try {
      const response = await context.$axios.$get<{ memos: Memo[] }>(path)
      return response.memos.map((memo) => ({
        ...memo,
        customer_id: customerId,
        created_at: memo.created_at ? dateToStr(memo.created_at) : '',
        updated_at: memo.updated_at ? dateToStr(memo.updated_at) : '',
      }))
    } catch (error) {
      alert(error)
    } finally {
      loading.value = false
      error.value = true
    }
  }

  return {
    loading,
    error,
    memos,
    fetchMemos,
    updateMemo,
    createMemo,
    deleteMemo,
    fetchMemosById,
  }
}
