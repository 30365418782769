













/* eslint-disable indent */
import {
  SetupContext,
  defineComponent,
  reactive,
  computed,
  toRefs,
  onMounted,
} from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    x: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    y: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    width: {
      type: [Number, String],
      required: false,
      default: null,
    },
    height: {
      type: [Number, String],
      required: false,
      default: null,
    },
    viewBox: {
      type: String,
      required: false,
      default: null,
    },
    color: {
      type: String,
      required: false,
      default: 'currentColor',
    },
  },
  setup(props, { slots }: SetupContext) {
    const { x, y, width, height, viewBox } = toRefs(props)

    type IconData = {
      width?: number
      height?: number
    }

    const state = reactive<IconData>({
      width: 0,
      height: 0,
    })

    const getIconNode = (nodes: any): any => {
      if (nodes === null) return null

      switch (typeof nodes[0].child) {
        case 'object':
          return nodes[0]
        case 'symbol':
          return getIconNode(nodes[0].child)
        default:
          return null
      }
    }

    const w = computed(() => width.value ?? state.width)
    const h = computed(() => height.value ?? state.height)

    const viewBoxRef = computed(
      () => viewBox.value ?? `${x.value} ${y.value} ${w.value} ${h.value}`
    )

    onMounted(() => {
      const iconNode = getIconNode((slots.default as any)())
      const iconData = iconNode ? (iconNode.child.$data as IconData) : {}
      const { width, height } = iconData
      if (width && height) {
        Object.assign(state, { width, height })
      }
    })

    return { w, h, viewBoxRef }
  },
})
