import { computed } from '@nuxtjs/composition-api'
import { FormValues備考 } from '~/composable/estimate/viewModel/graduation/Fields/Fields備考'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import {
  useState割引,
  useStateお客様検索,
  useStateお名前,
  useState試着写真,
  useStateプラン,
  useState商品設定,
  useState髪飾り,
  useState見積一覧,
} from '~/composable/estimate/repository/graduation/states/StateOtherTabs'
import { useStateお写真プラン } from '~/composable/estimate/repository/graduation/states/Stateお写真プラン'
import { useState別衿 } from '~/composable/estimate/repository/graduation/states/State別衿'
import { useState半巾帯 } from '~/composable/estimate/repository/graduation/states/State半巾帯'
import { useState巾着バッグ } from '~/composable/estimate/repository/graduation/states/State巾着バッグ'
import { useState振袖二尺袖 } from '~/composable/estimate/repository/graduation/states/State振袖二尺袖'
import { useState草履ブーツ } from '~/composable/estimate/repository/graduation/states/State草履ブーツ'
import { useState袴 } from '~/composable/estimate/repository/graduation/states/State袴'
import { useState重ね衿 } from '~/composable/estimate/repository/graduation/states/State重ね衿'
import { useStateその他商品 } from './states/Stateその他商品'

export type EstimateState = ReturnType<typeof useEstimateState>
/*
 * 選ばれたプランや選択肢に従って各項目の内容を整理した卒業式見積もりの入力内容をComputedRefで返す
 *
 * 例えば選択プランが着物種別がレンタルであれば半巾帯_着物レンタルの内容が、
 * 持込のプランなら半巾帯_着物持込の内容が、
 * 「半巾帯」プロパティから参照できる
 */
export const useEstimateState = () => {
  const stateお名前 = useStateお名前()
  const stateプラン = useStateプラン()
  const state試着写真 = useState試着写真()
  const state振袖二尺袖 = useState振袖二尺袖()
  const state商品設定 = useState商品設定()
  const state袴 = useState袴()
  const state半巾帯 = useState半巾帯()
  const state重ね衿 = useState重ね衿()
  const state別衿 = useState別衿()
  const state髪飾り = useState髪飾り()
  const state草履ブーツ = useState草履ブーツ()
  const state巾着バッグ = useState巾着バッグ()
  const stateお写真プラン = useStateお写真プラン()
  const stateその他商品 = useStateその他商品()
  const state割引 = useState割引()
  const store備考 = useGraduateStore<FormValues備考>('tab備考')
  const stateお客様検索 = useStateお客様検索()
  const state見積一覧 = useState見積一覧()

  return computed(() => {
    return {
      お名前: stateお名前.value,
      プラン: stateプラン.value,
      試着写真: state試着写真.value,
      商品設定: state商品設定.value,
      振袖二尺袖: state振袖二尺袖.value,
      袴: state袴.value,
      半巾帯: state半巾帯.value,
      重ね衿: state重ね衿.value,
      別衿: state別衿.value,
      髪飾り: state髪飾り.value,
      草履ブーツ: state草履ブーツ.value,
      巾着バッグ: state巾着バッグ.value,
      お写真プラン: stateお写真プラン.value,
      その他商品: stateその他商品.value,
      割引: state割引.value,
      備考: store備考.get<'bikou備考'>('bikou備考').value,
      お客様検索: stateお客様検索.value,
      見積一覧: state見積一覧.value,
    }
  })
}
