/**
 * @note 参列における着物関連の形態(レンタルか持ち込みか) (2022年10月25日時点での下記APIURLに準拠)
 * https://docs.google.com/spreadsheets/d/1Go2iCyMfVkvc22ez4fjjsB0JSBeMGnfHY-vFDtrcjdg/edit#gid=619142079&range=E49:F49
 */
export class AttendanceClothingPlanType {
  static readonly レンタル = 1
  static readonly 持込 = 3
  static readonly 持込親族以外 = 17

  static toWord = (val: number) => {
    switch (val) {
      case AttendanceClothingPlanType.レンタル:
        return 'レンタル'
      case AttendanceClothingPlanType.持込:
        return '持込'
      case AttendanceClothingPlanType.持込親族以外:
        return '持込（二親等以内の親族以外）'
    }
    return '該当なし'
  }

  static toId = (label: string) => {
    switch (label) {
      case 'レンタル':
        return AttendanceClothingPlanType.レンタル
      case '持込':
        return AttendanceClothingPlanType.持込
      case '持込親族以外':
        return AttendanceClothingPlanType.持込親族以外
    }

    return 0
  }
}
