
























import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    fill: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['click'],
  data() {
    return {
      width: 50,
      height: 50,
    }
  },
})
