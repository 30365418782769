import { computed } from '@nuxtjs/composition-api'
import {
  use重ね衿見積,
  use髪飾り見積,
  use当日着付け代見積,
  useその他商品見積,
} from './other見積'
import { use着物見積 } from './着物見積'
import { use袴見積 } from './袴見積'
import { ConclusionItem } from '.'
import { useEstimateState } from '~/composable/estimate/repository/graduation/EstimateState'
import { useUrlNavigator } from '~/composable/estimate/service/graduation/UrlNavigator'
import ProductClassification from '../../../../../../constants/enums/productClassification'
import { 卒業式見積一覧 } from '~/store/Estimate/GraduationCeremony/tab見積一覧/state'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { priceTable as priceConst } from '~/constants/priceTable'
import { 卒業式長期レンタル値引き表示名 } from '~/constants/estimate/graduationOptions'
import { SINGLE_SERVICE_TYPE } from '~/constants/enums/itemPlan'
import { PP_SINGLE_RENTAL_PRICE } from '~/constants/productData'

export type Fields見積もり内容確認 = {
  着物: ConclusionItem
  成人式ご利用振袖割引欄: ConclusionItem
  袴: ConclusionItem
  成人式のご利用袴割引: ConclusionItem
  半巾帯: ConclusionItem
  重ね衿: ConclusionItem[]
  別衿: ConclusionItem
  髪飾り: ConclusionItem[]
  草履ブーツ: ConclusionItem
  巾着バッグ: ConclusionItem
  その他商品: ConclusionItem[]
  裄補正: ConclusionItem
  袖補正: ConclusionItem
  別衿加工代: ConclusionItem
  当日着付代: ConclusionItem
  当日へアメイク代: ConclusionItem
  割引: ConclusionItem
  卒業式長期レンタル値引き: ConclusionItem
  プレミアムパック: ConclusionItem
  見積一覧: 卒業式見積一覧
}

export const use卒業式全体見積 = () => {
  const priceTable = useGraduateStore('').getPrices()
  const state = useEstimateState()
  const paths = useUrlNavigator()
  const 着物 = use着物見積()
  const { 袴割引, 袴 } = use袴見積()
  const 重ね衿見積 = use重ね衿見積()
  const 髪飾り見積 = use髪飾り見積()
  const その他商品見積 = useその他商品見積()
  const 当日着付け代 = use当日着付け代見積()

  return computed<Fields見積もり内容確認>(() => {
    const items = {
      着物: 着物.振袖二尺袖.value.着物,
      成人式ご利用振袖割引欄: 着物.振袖二尺袖.value.振袖割引,
      袴: 袴.value,
      成人式のご利用袴割引: 袴割引.value,
      半巾帯: {
        title: '半巾帯',
        productTypeId: state.value.半巾帯.品種区分,
        rankUpId: state.value.半巾帯.小物プラン,
        isRankUp: state.value.半巾帯.isランクアップ,
        product: state.value.半巾帯.商品名,
        price: state.value.半巾帯.表示価格,
        priceWithPP:
          state.value.半巾帯.isセット内 || state.value.半巾帯.isランクアップ
            ? 0
            : null,
        label: state.value.半巾帯.label,
        priceNote: state.value.半巾帯.見積表示?.value,
        url: paths.tab半幅帯,
      },
      重ね衿: 重ね衿見積.value,
      別衿: {
        title: '別衿',
        productTypeId: state.value.別衿.品種区分,
        rankUpId: state.value.別衿.小物プラン,
        isRankUp: state.value.別衿.isランクアップ,
        price: state.value.別衿.表示価格,
        priceWithPP:
          state.value.別衿.isセット内 ||
          state.value.別衿.isランクアップ ||
          (state.value.プラン.flags.isBringInDiscount &&
            state.value.別衿.見積表示?.value === SINGLE_SERVICE_TYPE.RENTAL)
            ? PP_SINGLE_RENTAL_PRICE
            : null,
        label: state.value.別衿.label,
        priceNote: state.value.別衿.見積表示?.value,
      },
      髪飾り: 髪飾り見積.value,
      草履ブーツ: {
        title: ProductClassification.toWord(state.value.草履ブーツ.品種区分),
        productTypeId: state.value.草履ブーツ.品種区分,
        rankUpId: state.value.草履ブーツ.小物プラン,
        isRankUp: state.value.草履ブーツ.isランクアップ,
        product: state.value.草履ブーツ.商品名,
        price: state.value.草履ブーツ.表示価格,
        priceWithPP:
          state.value.草履ブーツ.isセット内 ||
          state.value.草履ブーツ.isランクアップ ||
          (state.value.プラン.flags.isBringInDiscount &&
            state.value.草履ブーツ.見積表示?.value ===
              SINGLE_SERVICE_TYPE.RENTAL)
            ? PP_SINGLE_RENTAL_PRICE
            : null,
        label: state.value.草履ブーツ.label,
        priceNote: state.value.草履ブーツ.見積表示?.value,
        url: paths.tab草履ブーツ,
      },
      巾着バッグ: {
        title: ProductClassification.toWord(state.value.巾着バッグ.品種区分),
        productTypeId: state.value.巾着バッグ.品種区分,
        rankUpId: state.value.巾着バッグ.小物プラン,
        isRankUp: state.value.巾着バッグ.isランクアップ,
        product: state.value.巾着バッグ.商品名,
        price: state.value.巾着バッグ.表示価格,
        priceWithPP:
          state.value.巾着バッグ.isセット内 ||
          state.value.巾着バッグ.isランクアップ ||
          (state.value.プラン.flags.isBringInDiscount &&
            state.value.巾着バッグ.見積表示?.value ===
              SINGLE_SERVICE_TYPE.RENTAL)
            ? PP_SINGLE_RENTAL_PRICE
            : null,
        label: state.value.巾着バッグ.label,
        priceNote: state.value.巾着バッグ.見積表示?.value,
        url: paths.tab巾着バッグ,
      },
      その他商品: その他商品見積.value,
      裄補正: {
        title: '裄補正',
        price: state.value.振袖二尺袖.flags.is裄補正
          ? priceTable.estimate.裄補正
          : null,
        productTypeId: null,
        addition: true,
      },
      袖補正: {
        title: '袖補正',
        price: state.value.振袖二尺袖.flags.is袖補正
          ? priceTable.estimate.袖補正
          : null,
        productTypeId: null,
        addition: true,
      },
      別衿加工代: {
        title: '別衿加工代',
        price: state.value.別衿.別衿加工必要
          ? priceTable.estimate.別衿加工代
          : null,
        productTypeId: null,
        addition: true,
      },
      当日着付代: {
        title: '当日着付代',
        price: 当日着付け代.value,
        productTypeId: null,
        addition: true,
      },
      割引: {
        title: 'その他割引',
        price: state.value.割引.金額,
        discount: true,
        productTypeId: null,
      },
      卒業式長期レンタル値引き: {
        title: 卒業式長期レンタル値引き表示名,
        price: state.value.プラン.卒業式長期レンタル値引き
          ? priceConst.卒業式.卒業式長期レンタル値引き
          : 0,
        discount: true,
        productTypeId: null,
        isInsertDailyReport: true,
      },
      当日へアメイク代: {
        title: '当日へアメイク代',
        priceNote: 'お客様ご自身でご準備ください',
        noPrice: true,
        productTypeId: null,
        addition: true,
      },
      プレミアムパック: {
        title: 'プレミアムパック',
        productTypeId: null,
        addition: true,
        price: 0,
        priceWithPP: priceTable.estimate.卒業式プレミアムパック,
      },
      見積一覧: state.value.見積一覧,
    }

    return items
  })
}
