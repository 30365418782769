import { GetterTree } from 'vuex'
import { RootState, CustomerState } from '../../../types'

const getters: GetterTree<CustomerState, RootState> = {
  // NOTE: useComingStore用に追加
  getAllState: (state) => {
    return state
  },

  getName: (state: CustomerState) => {
    return state.name
  },

  getCustomer: (state: CustomerState) => {
    return state.customer
  },

  getIsValidate: (state: CustomerState) => {
    return state.isValidate
  },

  // 来店動機
  getVisitMotives: (state: CustomerState) => {
    return state.visit_motives
  },

  // メールアドレス
  getGuidanceEmail: (state: CustomerState) => {
    return state.guidanceEmail
  },
}

export default getters
