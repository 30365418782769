import { SisterDiscountState } from '../../../types'

const state = (): SisterDiscountState => ({
  /**
   * NOTE: selections = patternLine
   * ↓保持される選択肢のindex
   * [0]弊社利用のお姉様
   * [1]適用するか
   */
  selections: [0, 0],
  customer: null,
  isValidate: null,
})

export default state
