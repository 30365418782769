import { render, staticRenderFns } from "./AppEstimateHeaderComingOfAgeCeremony.vue?vue&type=template&id=3b12d009&"
import script from "./AppEstimateHeaderComingOfAgeCeremony.vue?vue&type=script&lang=ts&"
export * from "./AppEstimateHeaderComingOfAgeCeremony.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppIcon: require('/home/ar-admin/development/weddingbox-client/src/components/AppIcon.vue').default})
