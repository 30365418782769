import { computed, useStore } from '@nuxtjs/composition-api'
import { State as PricesState } from '~/store/PriceTable/state'
import { inputs } from '~/store/types'

type IsValidate = boolean | null
type isPPDiscount = boolean
export type selectionPlan = number[]
/**
 * 成人式見積もり用のstoreを操作するComposition関数
 * @interface R 指定したストアの中身の型（「FormValuesページ名」系のやつ）
 * これを指定すると引数や返り値に型がつくようになる
 *
 * WARNING: 足し布以外のタブは最初に対応していないので、個々に必要なgetter,setterを足し布に倣って追加の必要性あり。
 * NOTE:get/set/getAll/setAllでハンドリングできるのはfields配下のみ。
 * isValidateはget/setIsValidateを用いて操作する。
 */
export const useComingStore = <R extends Record<string, unknown>>(
  storeName: string
) => {
  /*
   * constructor
   */
  const store = useStore()
  const storePath = `Estimate/ComingOfAgeCeremony/${storeName}`

  /*
   * public getters and methods
   */
  /**
   * @interface S 指定したstate名の文字列を指定すると返り値に型がつく
   */
  const get = <S extends string>(fieldId: keyof R) => {
    const storeFullPath = `${storePath}/getState`
    const state = computed(() => store.getters[storeFullPath](fieldId) as R[S])
    if (state.value === undefined) {
      const errorMsg =
        'storeから返された値がundefinedでした。\npath:' + storeFullPath
      console.error(errorMsg)
    }
    return state
  }

  const getIsValidate = () => {
    const storeFullPath = `${storePath}/getIsValidate`
    const isValidate = computed<IsValidate>(() => store.getters[storeFullPath])
    return isValidate
  }

  const getIsPPDiscount = () => {
    const storeFullPath = `${storePath}/getIsPPDiscount`
    const state = computed<isPPDiscount>(() => store.getters[storeFullPath])
    return state
  }

  const getIsZoriBagPriceCalculated = () => {
    const storeFullPath = `${storePath}/getIsZoriBagPriceCalculated`
    const state = computed<isPPDiscount>(() => store.getters[storeFullPath])
    return state
  }

  const getSelections = () => {
    const storeFullPath = `${storePath}/getSelections`
    const state: selectionPlan = store.getters[storeFullPath]
    return state
  }

  const getInput = () => {
    const storeFullPath = `${storePath}/getInput`
    const state: inputs = store.getters[storeFullPath]
    return state
  }

  const getSelectionZori = () => {
    const storeFullPath = `${storePath}/getSelectionZori`
    const state: selectionPlan = store.getters[storeFullPath]
    return state
  }

  const getSelectionBag = () => {
    const storeFullPath = `${storePath}/getSelectionBag`
    const state: selectionPlan = store.getters[storeFullPath]
    return state
  }

  const getAll = () => {
    const storeFullPath = `${storePath}/getAllState`
    const state = computed(() => store.getters[storeFullPath] as R)
    return state
  }

  // 価格テーブルの取得
  const getPrices = () => {
    const path = 'PriceTable/getPrices'
    const state: PricesState['prices'] = store.getters[path]
    return state
  }

  // 価格テーブルの更新
  const setPrices = <P>(setter: string, value: P) => {
    const path = `PriceTable/${setter}`
    store.commit(path, value)
  }

  /**
   * @interface S 指定したstate名の文字列を指定すると渡す値に型チェックが入る
   */
  const set = <S extends keyof R>(fieldId: keyof R, value: R[S]) => {
    const storeFullPath = `${storePath}/setState`
    store.commit(storeFullPath, { stateName: fieldId, value })
  }

  /**
   * fieldsステート全体を更新する
   */
  const setAll = (values: R) => {
    const storeFullPath = `${storePath}/setAllState`
    store.commit(storeFullPath, values)
  }

  /**
   * @param setter mutation名
   * @interface P valueの型
   */
  const setOf = <P>(setter: string, value: P) => {
    const storeFullPath = `${storePath}/${setter}`
    store.commit(storeFullPath, value)
  }

  const setIsValidate = (valid: boolean) => {
    const storeFullPath = `${storePath}/setIsValidate`
    store.commit(storeFullPath, valid)
  }

  const init = (fieldId: keyof R) => {
    const storeFullPath = `${storePath}/initState`
    store.commit(storeFullPath, fieldId)
  }
  const initAll = () => {
    const storeFullPath = `${storePath}/initAllState`
    store.commit(storeFullPath)
  }
  const initIsZoribagPriceCalculated = () => {
    const storeFullPath = `${storePath}/initIsZoribagPriceCalculated`
    store.commit(storeFullPath)
  }

  const initIsZoriBagSetPrice = () => {
    const storeFullPath = `${storePath}/initIsZoriBagSetPrice`
    store.commit(storeFullPath)
  }

  return {
    get,
    getIsValidate,
    getAll,
    getPrices,
    getIsPPDiscount,
    getSelections,
    getInput,
    getSelectionZori,
    getSelectionBag,
    getIsZoriBagPriceCalculated,
    setPrices,
    set,
    setAll,
    setOf,
    setIsValidate,
    init,
    initAll,
    initIsZoribagPriceCalculated,
    initIsZoriBagSetPrice,
  }
}
