import { GetterTree } from 'vuex'
import { RootState, EstimateListState } from '../../../types'

const getters: GetterTree<EstimateListState, RootState> = {
  // NOTE: useComingStore用に追加
  getAllState: (state) => {
    return state.fields
  },
  getIsZoriBagPriceCalculated: (state: EstimateListState) => {
    return state.fields.isZoriBagPriceCalculated
  },
  getIsZoriBagSetPrice: (state: EstimateListState) => {
    return state.fields.isZoriBagSetPrice
  },
}

export default getters
