import { ObiState } from '../../../../types'

/*
  振袖で「レンタル」が選択された時の帯ストア
*/
const state = (): ObiState => ({
  /**
   * NOTE: selections = patternLine
   * ↓保持される選択肢のindex
   * [0]種別
   * [1]形態
   * [2]商品id
   */
  selections: [0, 0, 0],
  product: null,
  input: {
    productName: '',
    rentalPrice: 0,
    rankUpRentalPrice: 0,
    premiumRentalPrice: 0,
    buyPrice: 0, // 多分使ってない
    rankUpBuyPrice: 0, // 多分使ってない
    premiumBuyPrice: 0, // 多分使ってない
  },
  price: {
    buy: 0, // 多分使ってない
    rental: 0,
  },
  isFree: false,
  isValidate: null,
})

export default state
