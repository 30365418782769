import { FormValues草履ブーツ_着物レンタル } from '~/composable/estimate/viewModel/graduation/Fields/Fields草履ブーツ_着物レンタル'

//不明な点がある場合、READMEをご覧ください
export const getInitialStore = (): FormValues草履ブーツ_着物レンタル => {
  return {
    zoriboots商品: null,
    zoriboots種別: null,
    zoriboots形態: null,
    zoribootsセット内レンタル価格: null,
    zoribootsランクアップ: null,
    zoriboots規定商品購入方法: null,
    zoriboots規定商品: null,
    zoriboots規定商品単品購入: null,
    zoriboots現品レンタル価格: null,
    zoriboots現品レンタル価格フリー入力: null,
    zoriboots現品単品購入: null,
    zoriboots現品単品購入フリー入力: null,
  }
}

const state = (): { fields: FormValues草履ブーツ_着物レンタル } => ({
  fields: getInitialStore(),
})

export default state
