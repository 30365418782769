// ** types **
import type { MayBe, Staff, SettingShop } from '~/store/types'
import type { Customer } from '~/composable/api/useCustomer'
import type {
  PlanIdList,
  ProductTypeIdList,
  ProductSizeIdList,
} from '~/api/singleItemRepository'
import type { KimonoSearchResult } from '@/composable/api/useSearchProduct'
import type { Product } from '@/components/_graduationCeremony/atoms/AppSelectionProduct.vue'

// ** enum **
import { DISCOUNT } from '~/features/singleItemService/discount/enums'
import { PAYMENT_METHOD } from '~/features/contracts/paymentEnums'
import { ContractAddition } from '~/api/contractRepository'
import { PriceTypeListBySingleItem } from '~/constants/enums/itemPlan'
import { AccessoriesOption } from '~/features/singleItemService/accessories/enums'
import { PRODUCT_SETTING } from '~/features/singleItemService/productSetting/enums'

type valueOf<T> = T[keyof T]

export type Price = {
  id: number | null
  rental: string // ¥付き金額文字列
  purchase: string // ¥付き金額文字列
}

// ** タブ **
export type Tab = {
  text: string
  isComplete: boolean | null
  isShow: boolean
  isCurrent: boolean
}

//  ** その他割引 **
export type Discount = {
  apply: number //割引適用
  price: string //割引額
}
export type DiscountKeys = keyof Discount
export type DiscountValues = valueOf<Discount>

// ** 備考 **
export type Remarks = {
  text: string
}
export type RemarksKeys = keyof Remarks
export type RemarksValues = valueOf<Remarks>

//** お支払い */
export type Payment = {
  dueDate: string | null
  staff: Staff | null
  category: number | null
  details: PaymentDetail[]
  total: number | null
  paymentDeadline: string // 残高支払い期限
  payee: number | null
  payeeInfo: string
  bankName: string | null
  branchName: string | null
  notes: string | null
  isPayment: number | null
  balance: number
  paidAmount: number
}
export type PaymentDetail = {
  isActive: boolean
  methods: typeof PAYMENT_METHOD[keyof typeof PAYMENT_METHOD]
  tomonokai_points?: number
  amount: number
}
export type PaymentKeys = keyof Payment
export type PaymentValues = valueOf<Payment>

// ** 商品設定 **
export type ProductSetting = {
  singleItems: SingleItem[]
  photos: {
    remoteSrc: string
    isSelected: boolean
  }[] // NOTE: コンポーネントの都合上配列にしている
  delivery: {
    date: string | null
    shop: SettingShop | null
  }
  returnInfo: {
    date: string | null
    shop: SettingShop | null
  }
  formType: number | null // 発行する帳票
  isTomonokaiApplied: // 友の会ポイントの使用有無
  | typeof PRODUCT_SETTING.TOMONOKAI_POINT_APPLY.はい
    | typeof PRODUCT_SETTING.TOMONOKAI_POINT_APPLY.いいえ
    | null
  customer: Customer | null // 契約者
  contractor: Customer | null // 利用者
  email: string | null
}

export type SingleItem = {
  planId: PlanIdList | null
  productTypeId: ProductTypeIdList
  checkListBranchNumber: number | null // checklistの採番branch_number（チェックリスト変更検知の際に必要）
  orderNumber: number
  kimonoSearchResult: KimonoSearchResult | null
  product: Product
  productName: string | null
  deliveryPeriod: number | null
  price: Price
  singleRentalPrice: string | null
  singlePurchasePrice: string | null
  priceType: PriceTypeListBySingleItem | null
  remarks: string | null
  deliveryStatus:
    | typeof PRODUCT_SETTING.DELIVERY_STATUS.お渡し済み
    | typeof PRODUCT_SETTING.DELIVERY_STATUS.未
    | null
  productSize: ProductSizeIdList | null
  is帯仕立て代必要: number | null
  帯仕立て代: string | null
  is別衿縫い付け必要: number | null
  別衿加工代: string | null
  footSize: string | null
  bust: string | null
  hip: string | null
  height: string | null
  shoulder: {
    sun: string | null
    bu: string | null
  }
  sleeveWidth: {
    sun: string | null
    bu: string | null
  }
  sleeveHeight: {
    shaku: string | null
    sun: string | null
    bu: string | null
  }
  isValid: boolean
}

export type ProductSettingKeys = keyof ProductSetting | keyof SingleItem
export type ProductSettingValues = valueOf<ProductSetting> | valueOf<SingleItem>

export const initialSingleItemData = {
  planId: null,
  productTypeId: null,
  orderNumber: 1,
  kimonoSearchResult: null,
  product: {
    id: 0,
    text: '',
    value: '',
  },
  productName: null,
  deliveryPeriod: null,
  price: {
    id: null,
    rental: '',
    purchase: '',
  },
  singleRentalPrice: null,
  singlePurchasePrice: null,
  priceType: null,
  remarks: null,
  deliveryStatus: null,
  productSize: null,
  is帯仕立て代必要: null,
  帯仕立て代: '¥11,000',
  is別衿縫い付け必要: null,
  別衿加工代: '¥5,500',
  footSize: null,
  bust: null,
  hip: null,
  height: null,
  shoulder: {
    sun: null,
    bu: null,
  },
  sleeveWidth: {
    sun: null,
    bu: null,
  },
  sleeveHeight: {
    shaku: null,
    sun: null,
    bu: null,
  },
  isValid: false,
}

// ** 商品情報 **
export type Products = {
  product_type_id: ProductTypeIdList // 品種ID
  plan_id: MayBe<PlanIdList> // 1: セット内 2: ランクアップ 3: 仕立て上がり 4: 新品
  product_id: MayBe<number> // 商品番号
  number: number // 商品番号
  product_name: MayBe<string> // 商品名
  check_list_branch_number: number | null // checklistの採番branch_number（チェックリスト変更検知の際に必要）
  product_size_id: MayBe<ProductSizeIdList> // サイズ
  has_delivery: MayBe<1 | 2> // お送りするか否か
  delivery_period: MayBe<number> // 納期までの日数（当日を含む）
  price_type: PriceTypeListBySingleItem //　5: 単品レンタル, 6: 単品購入
  single_rental_price: MayBe<number> // 単品レンタル価格
  single_purchase_price: MayBe<number> // 単品購入価格
  remarks: MayBe<string> // 備考
  additions: MayBe<ContractAddition[]> // 追加金額のオプション項目
  measurement: MayBe<{
    foot_size: MayBe<string>
    bust: MayBe<string>
    hip: MayBe<string>
  }> // 採寸情報
  corrections?: MayBe<
    {
      type: number
      shaku: number | null
      sun: number
      bu: number
    }[]
  > // お持ちの振袖の採寸情報
}

export type Accessories = {
  products: Products[]
  accessoriesType: AccessoriesOption | null
  isValidateAccessories: { [key: string]: boolean }[]
  hasOldTypeTabi: boolean
}
export type AccessoriesKeys = keyof Accessories
export type AccessoriesValues = valueOf<Accessories>

// ** 署名 **
export type Signature = {
  checklist: {
    first: boolean
    second: boolean
    third: boolean
    fourth: boolean
  }
  signUrl: string
}
export type SignatureKeys = keyof Signature
export type SignatureValues = valueOf<Signature>

export type State = {
  data: {
    contractDate: string
    productSetting: ProductSetting
    accessories: Accessories
    discount: Discount
    remarks: Remarks
    payment: Payment
    signature: Signature
    contractTotalPrice: number | null // 一時保存時に合計金額を保持する必要があるため追加
    estimateId: number | null
    contractId: number | null
    pdfUrl: string | null
  }
  tabList: Tab[]
  finalPageList: Tab[]
}

export const getInitialStore = (): State => {
  return {
    data: {
      contractDate: '',
      // ** 商品設定 **
      productSetting: {
        singleItems: [],
        photos: [],
        delivery: {
          date: null,
          shop: null,
        },
        returnInfo: {
          date: null,
          shop: null,
        },
        formType: null,
        isTomonokaiApplied: null,
        // ** お客様情報 **
        //NOTE: 下記の２つのparameterはお客様情報でも使います
        customer: null,
        contractor: null,
        email: null,
      },
      // ** その他割引 **
      discount: {
        apply: DISCOUNT.APPLY.いいえ, //割引適用
        price: '', //割引額
      },
      // ** 備考 **
      remarks: {
        text: '',
      },
      // ** 着付小物 **
      accessories: {
        products: [],
        accessoriesType: null,
        isValidateAccessories: [],
        hasOldTypeTabi: false,
      },
      // ** お支払い **
      payment: {
        dueDate: null,
        staff: null,
        category: null,
        total: null,
        notes: null,
        details: [
          {
            isActive: false,
            methods: PAYMENT_METHOD.CASH,
            amount: 0,
          },
          {
            isActive: false,
            methods: PAYMENT_METHOD.CARD,
            amount: 0,
          },
          {
            isActive: false,
            methods: PAYMENT_METHOD.GIFT_CERTIFICATES,
            amount: 0,
          },
          {
            isActive: false,
            methods: PAYMENT_METHOD.POINT,
            amount: 0,
          },
          {
            isActive: false,
            methods: PAYMENT_METHOD.FAMILY_POINT,
            amount: 0,
          },
          {
            isActive: false,
            methods: PAYMENT_METHOD.E_MONEY,
            amount: 0,
          },
          {
            isActive: false,
            methods: PAYMENT_METHOD.TRANSFER,
            amount: 0,
          },
          {
            isActive: false,
            methods: PAYMENT_METHOD.POINT_ROUND_OFF,
            amount: 0,
          },
        ],
        paymentDeadline: '',
        payee: null,
        payeeInfo: '',
        bankName: null,
        branchName: null,
        isPayment: null, // 支払い処理を行うかどうか（契約変更時に使用）
        balance: 0, // 支払い残高（契約変更時に使用）
        paidAmount: 0, // 支払い済み金額（契約変更時に使用）,
      },
      signature: {
        checklist: {
          first: false,
          second: false,
          third: false,
          fourth: false,
        },
        signUrl: '',
      },
      contractTotalPrice: null,
      estimateId: null,
      contractId: null,
      pdfUrl: null,
    },
    // ** タブ **
    tabList: [
      {
        text: 'お客様情報',
        isComplete: null,
        isShow: false,
        isCurrent: false,
      },
      {
        text: '商品設定',
        isComplete: null,
        isShow: true,
        isCurrent: true,
      },
      {
        text: '着付け小物',
        isComplete: null,
        isShow: true,
        isCurrent: false,
      },
      {
        text: 'その他割引',
        isComplete: null,
        isShow: true,
        isCurrent: false,
      },
      {
        text: 'お支払',
        isComplete: null,
        isShow: true,
        isCurrent: false,
      },
      {
        text: '備考',
        isComplete: null,
        isShow: true,
        isCurrent: false,
      },
    ],
    finalPageList: [
      {
        text: '入力内容確認',
        isComplete: null,
        isShow: true,
        isCurrent: true,
      },
      {
        text: '署名',
        isComplete: null,
        isShow: false,
        isCurrent: false,
      },
      {
        text: '見積一覧',
        isComplete: null,
        isShow: false,
        isCurrent: false,
      },
      {
        text: '契約作成完了',
        isComplete: null,
        isShow: false,
        isCurrent: false,
      },
      {
        text: '見積作成完了',
        isComplete: null,
        isShow: false,
        isCurrent: false,
      },
    ],
  }
}

const state = (): State => getInitialStore()

export default state
