



















import { PropType } from '@nuxtjs/composition-api'

export default {
  props: {
    estimateId: {
      type: Number as PropType<Number | null>,
      required: false,
    },
    contractId: {
      type: Number as PropType<Number | null>,
      required: false,
    },
    receiptId: {
      type: Number as PropType<Number | null>,
      required: false,
      default: null,
    },
    text: {
      type: String,
      default: '',
    },
    shopName: {
      type: String,
      default: '',
    },
    customerName: {
      type: String,
      default: '',
    },
    updatedAt: {
      type: String,
      required: false,
    },
  },
}
