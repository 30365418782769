import { BaseItemState, PlanFlags } from './types'
import ProductClassification from '~/constants/enums/productClassification'
import {
  getBadgeText,
  getItemPlan,
  getItemPlanFlags,
  getPriceTypeText,
} from './utilities'
import { useScreenTypeFlags } from '~/composable/estimate/domain/ComingOfAge/ScreenTypeFlags'
import { EstimateSinglePriceType } from '~/constants/enums/estimateSinglePriceType'
import { computed } from '@nuxtjs/composition-api'
import { OnlyInputPlanState } from '~/store/types'
import { useComingStore } from '../../ComingOfAgeStore'
import { useState商品設定 } from './StateOtherTabs'
import { ShowPriceType } from '../../graduation/states/types'
import { State as PricesState } from '~/store/PriceTable/state'

export type Kamikazari = BaseItemState & {
  品種区分: 25
}

export const useState髪飾り = () => {
  const state商品設定 = useState商品設定()
  const comingStore = useComingStore<OnlyInputPlanState>('Kamikazari')
  const priceTable = comingStore.getPrices() // Storeの料金情報を取得
  const values髪飾り1 = comingStore.getAll()
  const values髪飾り2 =
    useComingStore<OnlyInputPlanState>('Kamikazari2').getAll()
  const values髪飾り3 =
    useComingStore<OnlyInputPlanState>('Kamikazari3').getAll()
  const { showTypeフルセット, showType写真のみレンタル, showType持ち込み } =
    useScreenTypeFlags()

  return computed<Kamikazari[]>(() => {
    const allValues = [
      values髪飾り1.value,
      values髪飾り2.value,
      values髪飾り3.value,
    ]
    const flags = {
      isフルセット: showTypeフルセット.value,
      is写のみレンタル: showType写真のみレンタル.value,
      is持ち込み: showType持ち込み.value,
    }
    const count = state商品設定.value.髪飾り
    const selectedValues = allValues.slice(0, count)
    return getState髪飾り(selectedValues, count, flags, priceTable)
  })
}

// NOTE: 髪飾りはプラン外なので単品購入しかない
export const getState髪飾り = (
  values髪飾り: OnlyInputPlanState[],
  count: number,
  planFlags: PlanFlags,
  priceTable: PricesState['prices']
): Kamikazari[] => {
  const selectedValues = values髪飾り.slice(0, count)

  return selectedValues.map((values, i) => {
    const { selections, input } = values
    const itemFlags = getItemPlanFlags(selections)
    const itemPlan = getItemPlan(selections)
    const numberText = i > 0 ? ' - ' + (i + 1) : ''

    // フルセットで単品購入の時は1つ目の髪飾りに割引適用を記載
    const is割引適用 =
      planFlags.isフルセット && Number(numberText) === 0 && input.buyPrice

    const itemName =
      ProductClassification.toWord(ProductClassification.kamikazari) +
      (is割引適用 ? '(成人式フルセット値引)' : numberText)

    const 見積表示: ShowPriceType = {
      id: EstimateSinglePriceType.singlePurchasePrice,
      value: '単品購入',
    }
    let 購入価格 = input.buyPrice

    /** NOTE: フルセット1個目の値引き処理  */
    if (planFlags.isフルセット && i === 0) {
      const over割引価格 =
        input.buyPrice > priceTable.estimate.髪飾りフルセット割引
      購入価格 = over割引価格
        ? input.buyPrice - priceTable.estimate.髪飾りフルセット割引
        : 0
    }

    return {
      品目名: itemName,
      品種区分: ProductClassification.kamikazari,
      商品名: '',
      小物プラン: itemPlan,
      購入価格,
      表示価格: 購入価格,
      レンタル価格: 0,
      プレミアムレンタル価格: 0,
      プレミアム購入価格: 0,
      見積表示: itemFlags.isお持込 ? null : 見積表示,
      label: getBadgeText(itemPlan),
      cellLabel: getPriceTypeText(selections),
      flags: {
        isランクアップ: false,
      },
    }
  })
}
