import type { ProductPrice } from '~/api/productRepository'
import type { Product, SetOfProduct } from '~/api/productRepository'

export type ProductNameList = '草履' | 'バッグ' | 'ショール' | '帯揚げ'

export type State = {
  itemPrices: ProductPrice[]
  kimonoPrices: ProductPrice[]
  products: { [K in ProductNameList]: Product[] }
  setOfProducts: SetOfProduct[]
}

const state = (): State => ({
  itemPrices: [],
  kimonoPrices: [],
  products: {
    草履: [],
    バッグ: [],
    ショール: [],
    帯揚げ: [],
  },
  setOfProducts: [],
})

export default state
