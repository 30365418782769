export class EstimatePlan {
  /**
   * フルセット（レンタルか購入か未定状態）
   * 詳細は仕様書の定数参照
   */
  static readonly fullSetプラン未決定 = 0
  /** フルセット（レンタル） */
  static readonly fullSet = 1
  /** 成人式A＋写真プラン(持込) */
  static readonly planAAndPhoto = 2
  /** 成人式B＋写真プラン(持込) */
  static readonly planBAndPhoto = 3
  /** 写のみ(レンタル) */
  static readonly onlyPhoto = 4
  /** 写のみ(持込) */
  static readonly bringInOnlyPhoto = 5
  /** フルセット購入 */
  static readonly fullSetPurchase = 6
  /** 成人式B(持込) */
  static readonly planB = 7
  /** 袴単品レンタル */
  static readonly hakamaSingleRental = 8
  /** 二尺袖単品レンタル */
  static readonly twoShakuSleeveSingleRental = 9
  /** 着物+袴レンタル */
  static readonly kimonoHakamaRental = 10
  /** 振袖単品レンタル */
  static readonly furisodeRental = 11

  static toWord = (val: number) => {
    switch (val) {
      case EstimatePlan.fullSet:
        return 'フルセット（レンタル）'
      case EstimatePlan.planAAndPhoto:
        return '成人式A＋写真'
      case EstimatePlan.planBAndPhoto:
        return '成人式B＋写真'
      case EstimatePlan.onlyPhoto:
        return '写真のみ（レンタル）'
      case EstimatePlan.bringInOnlyPhoto:
        return '写真のみ（持込）'
      case EstimatePlan.fullSetPurchase:
        return 'フルセット（購入）'
      case EstimatePlan.planB:
        return '成人式B'
      case EstimatePlan.hakamaSingleRental:
        return '袴単品レンタル'
      case EstimatePlan.twoShakuSleeveSingleRental:
        return '二尺袖単品レンタル'
      case EstimatePlan.kimonoHakamaRental:
        return '着物＋袴レンタル'
      case EstimatePlan.furisodeRental:
        return '振袖レンタル' // NOTE: WB内での呼称
      default:
        return 'ー'
    }
  }

  static allPlanIds = [
    EstimatePlan.fullSet,
    EstimatePlan.planAAndPhoto,
    EstimatePlan.planBAndPhoto,
    EstimatePlan.onlyPhoto,
    EstimatePlan.bringInOnlyPhoto,
    EstimatePlan.fullSetPurchase,
    EstimatePlan.planB,
    EstimatePlan.hakamaSingleRental,
    EstimatePlan.twoShakuSleeveSingleRental,
    EstimatePlan.kimonoHakamaRental,
    EstimatePlan.furisodeRental,
  ]

  static 成人式PlanIds = [
    EstimatePlan.fullSet,
    EstimatePlan.planAAndPhoto,
    EstimatePlan.planBAndPhoto,
    EstimatePlan.onlyPhoto,
    EstimatePlan.bringInOnlyPhoto,
    EstimatePlan.fullSetPurchase,
    EstimatePlan.planB,
  ]

  static isGraduationCelemony = (plan: number) => {
    return [
      EstimatePlan.hakamaSingleRental,
      EstimatePlan.twoShakuSleeveSingleRental,
      EstimatePlan.kimonoHakamaRental,
      EstimatePlan.furisodeRental,
    ].includes(plan)
  }

  static is着物レンタル = (plan: number) => {
    return [
      EstimatePlan.fullSet,
      EstimatePlan.onlyPhoto,
      EstimatePlan.twoShakuSleeveSingleRental,
      EstimatePlan.kimonoHakamaRental,
      EstimatePlan.furisodeRental,
    ].includes(plan)
  }

  static isフルセット = (plan?: number) => {
    if (!plan) return false
    return [
      EstimatePlan.fullSet,
      EstimatePlan.fullSetPurchase,
      EstimatePlan.fullSetプラン未決定,
    ].includes(plan)
  }

  static is持込み = (plan: number) => {
    return [
      EstimatePlan.planAAndPhoto,
      EstimatePlan.planBAndPhoto,
      EstimatePlan.planB,
    ].includes(plan)
  }

  static is写真のみ = (plan: number) => {
    return [EstimatePlan.onlyPhoto, EstimatePlan.bringInOnlyPhoto].includes(
      plan
    )
  }
}
