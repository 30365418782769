import { useStore } from '@nuxtjs/composition-api'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { useExtendsEstimateStore } from '~/composable/estimate/repository/ExtendsEstimateStore'
import { useInitAllGraduateStore } from '~/composable/estimate/repository/graduation/InitAllGraduateStore'

export const useInitializeState = () => {
  const store = useStore()
  const { initAllGraduateStore } = useInitAllGraduateStore()
  const store足し布 = useComingStore('Tashinuno')
  const store割引 = useComingStore('Discount')
  const store見積一覧 = useComingStore('EstimateList')
  const storeコピー元見積 = useComingStore('CopiedEstimate')
  const store見積継承 = useExtendsEstimateStore()

  /**
   * 見積書・契約書関連ストアを初期値にリセット
   */
  const initAllDocumentStore = () => {
    initFrontStoreInfo()
    initContractStore()
    initEstimateStore()
  }

  const initFrontStoreInfo = () => {
    store.commit(`FrontStoreInfo/delete`)
  }

  //*　チェックリストストアのクリア
  const initCheckListStoreInfo = () => {
    store.commit('Checklists/Main/initAll')
    store.commit(`Checklists/Memo/initAll`)
  }

  const initContractStore = () => {
    // Contracts
    store.commit(`Contracts/Accessories/delete`)
    store.commit(`Contracts/AccessoriesFullset/delete`)
    store.commit(`Contracts/BaseEstimate/delete`)
    store.commit(`Contracts/BaseInformation/delete`)
    store.commit(`Contracts/ComingOfAgeCeremony/delete`)
    store.commit(`Contracts/CustomerReady/delete`)
    store.commit(`Contracts/DeliveryDate/delete`)
    store.commit(`Contracts/DoublePhoto/delete`)
    store.commit(`Contracts/FamilyInformation/delete`)
    store.commit(`Contracts/GraduationCeremony/delete`)
    store.commit(`Contracts/ImportantMatter/delete`)
    store.commit(`Contracts/JkPhotoInAdvance/delete`)
    store.commit(`Contracts/Measuring/delete`)
    store.commit(`Contracts/Memo/delete`)
    store.commit(`Contracts/Payment/delete`)
    store.commit(`Contracts/PhotoInAdvance/delete`)
    store.commit(`Contracts/Photos/delete`)
    store.dispatch(`Contracts/ProductPhoto/mutationRestore`)
    store.commit(`Contracts/Option/delete`)
    store.commit(`Contracts/Remarks/delete`)
    store.commit(`Contracts/Signature/delete`)
    store.commit(`Contracts/MotherAndDaughterPlan/delete`)
    store.commit(`Contracts/ReferralBonus/delete`)
    store.commit(`Staff/delete`)
    store.commit(`PdfStoreInfo/delete`)
    store見積継承.initAll()

    //*　参列見契約ストアのクリア
    store.commit(`Contracts/Attendance/Main/initAll`)

    //*　単品サービス契約ストアのクリア
    store.commit(`SingleItemService/Main/initAll`)
    store.commit(`SingleItemService/MasterData/delete`)
    store.commit(`SingleItemService/Memo/initAll`)

    store.commit(`Contracts/Attendance/Memo/initAll`)

    //*　クリーニング見積ストアのクリア
    store.commit(`Contracts/Cleaning/Main/initAll`)
    store.commit(`Contracts/Cleaning/Memo/initAll`)
    store.commit('PriceTable/initAll')
  }

  const initEstimateStore = () => {
    // Estimate/COAC
    const ESTIMATE_COAC = 'Estimate/ComingOfAgeCeremony/'
    store.commit(`${ESTIMATE_COAC}Betsueri/delete`)
    store.commit(`${ESTIMATE_COAC}Customers/delete`)
    store.commit(`${ESTIMATE_COAC}EmployeeDiscount/delete`)
    store.commit(`${ESTIMATE_COAC}Furisodes/delete`)
    store.commit(`${ESTIMATE_COAC}Kamikazari/delete`)
    store.commit(`${ESTIMATE_COAC}Kamikazari2/delete`)
    store.commit(`${ESTIMATE_COAC}Kamikazari3/delete`)
    store.commit(`${ESTIMATE_COAC}Kasaneeri/delete`)
    store.commit(`${ESTIMATE_COAC}Kasaneeri2/delete`)
    store.commit(`${ESTIMATE_COAC}Kasaneeri3/delete`)
    store.commit(`${ESTIMATE_COAC}ZoriBag/delete`)
    store.commit(`${ESTIMATE_COAC}Zori2/delete`)
    store.commit(`${ESTIMATE_COAC}Zori3/delete`)
    store.commit(`${ESTIMATE_COAC}Bag2/delete`)
    store.commit(`${ESTIMATE_COAC}Bag3/delete`)
    store.commit(`${ESTIMATE_COAC}Memo/delete`)
    store.commit(`${ESTIMATE_COAC}MotherAndDaughterPlan/delete`)
    store.commit(`${ESTIMATE_COAC}Obi/delete`)
    store.commit(`${ESTIMATE_COAC}Obiage/delete`)
    store.commit(`${ESTIMATE_COAC}Obiage2/delete`)
    store.commit(`${ESTIMATE_COAC}Obiage3/delete`)
    store.commit(`${ESTIMATE_COAC}Obijime/delete`)
    store.commit(`${ESTIMATE_COAC}Photos/delete`)
    store.commit(`${ESTIMATE_COAC}Products/delete`)
    store.commit(`${ESTIMATE_COAC}Option/delete`)
    store.commit(`${ESTIMATE_COAC}Remarks/delete`)
    store.commit(`${ESTIMATE_COAC}OtherItem/delete`)
    store.commit(`${ESTIMATE_COAC}ServicePlans/delete`)
    store.commit(`${ESTIMATE_COAC}Shawl/delete`)
    store.commit(`${ESTIMATE_COAC}JkPhotoInAdvance/delete`)
    store.commit(`${ESTIMATE_COAC}SisterDiscount/delete`)
    store.commit(`${ESTIMATE_COAC}TwinsDiscount/delete`)
    store.commit(`${ESTIMATE_COAC}WphotoPlan/delete`)
    store.commit(`${ESTIMATE_COAC}MasterData/delete`)
    store足し布.initAll()
    store割引.initAll()
    store見積一覧.initAll()
    storeコピー元見積.initAll()

    // Estimate/COAC/Wphoto
    const ESTIMATE_COAC_W_PHOTO = 'Estimate/ComingOfAgeCeremony/WphotoPlan/'
    store.commit(`${ESTIMATE_COAC_W_PHOTO}WphotoBetsueriBring/delete`)
    store.commit(`${ESTIMATE_COAC_W_PHOTO}WphotoBetsueriRental/delete`)
    store.commit(`${ESTIMATE_COAC_W_PHOTO}WphotoFurisodes/delete`)
    store.commit(`${ESTIMATE_COAC_W_PHOTO}WphotoSetting/delete`)
    store.commit(`${ESTIMATE_COAC_W_PHOTO}WphotoKasaneeriBring/delete`)
    store.commit(`${ESTIMATE_COAC_W_PHOTO}WphotoKasaneeriRental/delete`)
    store.commit(`${ESTIMATE_COAC_W_PHOTO}WphotoKasaneeri2/delete`)
    store.commit(`${ESTIMATE_COAC_W_PHOTO}WphotoKasaneeri3/delete`)
    store.commit(`${ESTIMATE_COAC_W_PHOTO}WphotoObiageBring/delete`)
    store.commit(`${ESTIMATE_COAC_W_PHOTO}WphotoObiageRental/delete`)
    store.commit(`${ESTIMATE_COAC_W_PHOTO}WphotoObiage2/delete`)
    store.commit(`${ESTIMATE_COAC_W_PHOTO}WphotoObiage3/delete`)
    store.commit(`${ESTIMATE_COAC_W_PHOTO}WphotoObiBring/delete`)
    store.commit(`${ESTIMATE_COAC_W_PHOTO}WphotoObijimeBring/delete`)
    store.commit(`${ESTIMATE_COAC_W_PHOTO}WphotoObijimeRental/delete`)
    store.commit(`${ESTIMATE_COAC_W_PHOTO}WphotoObiRental/delete`)
    store.commit(`${ESTIMATE_COAC_W_PHOTO}WphotoProducts/delete`)
    store.commit(`Estimate/OldData/delete`)

    //* 卒業式側のStoreをリセットする
    initAllGraduateStore()

    //*　参列見積ストアのクリア
    store.commit(`Estimate/Attendance/Main/initAll`)
    store.commit(`Estimate/Attendance/CopiedEstimate/initAllState`)
    store.commit(`Estimate/Attendance/Memo/initAll`)

    //*　単品サービス見積ストアのクリア
    store.commit(`SingleItemService/Main/initAll`)
    store.commit(`SingleItemService/MasterData/delete`)
    store.commit(`SingleItemService/Memo/initAll`)

    //*　価格ストアのクリア
    store.commit('PriceTable/initAll')
  }

  return {
    initAllDocumentStore,
    initContractStore,
    initEstimateStore,
    initFrontStoreInfo,
    initCheckListStoreInfo,
  }
}
