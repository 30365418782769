import { Product } from '~/constants/productData'
import { EstimateSinglePriceType } from '~/constants/enums/estimateSinglePriceType'
import { ItemPlan } from '~/constants/enums/itemPlan'
import { ZoriBagInput, price } from '~/store/types'
import {
  GRADUATION_ZORI,
  PLAN,
  PRODUCT_BAG,
} from '~/constants/estimate/comingOfAge'
import { ShowPriceType } from '../../graduation/states/types'
import { BaseItemState, PlanFlags } from './types'
import { getShowPriceInfo, ShowPriceInfo } from './utilities'

/**
 * *1 草履バッグ(フルセット時)のみ、単品購入とプラン内購入を両方見積作成時に投げるパターンがあるため購入価格パラメータとは別に用意している。
 * 他の小物では"購入価格"にプラン内の購入金額と単品購入価格どちらも入れるが、草履バッグのみ上記の理由で
 * 単品購入価格は"単品購入価格"に格納して、"購入価格"にはプラン内購入金額のみを格納する
 * 理由：草履・バッグはレンタルすることはできないので（一回履いたら汚いからなのかな…）
 * 振袖レンタルプラン選択時は「単品購入」・振袖購入プラン選択時は「（プラン内での）購入」と言う形になるため。
 */

export type ZoriBag = BaseItemState & {
  商品: {
    id: number | null
    本体名: string // postNumberCodeを含まない商品名
    postNumberCode: string
  }
  単品購入価格: number // *1
  商品名: string // postNumberCodeを含む商品名
  // 基本的に見積一覧に表示する商品名だが、お任せの時だけ商品名ではない文字列を表示するので別に用意する
  linkText: string
  flags: {
    is振袖レンタル時単品購入: boolean
    is振袖持込: boolean
    selectedレンタルこちらでお選びボタン?: boolean
    selectedU系商品?: boolean
    is持込?: boolean
  }
}
export type Zori = ZoriBag & {
  品種区分: 27
}

export type Bag = ZoriBag & {
  品種区分: 28
}

// 草履バッグ用　1:セット内 3:ランクアップ（プレミアム内） 4:ランクアップ（プレミアム外） 5:未定
// FIXME: 6:お持ち込み（一時対応）草履バッグ画面もPLAN統合して、utilsのsetPlan に統一したい
export const getZoriBagPlan = (selections: number[], product: Product) => {
  const flags = getZoriBagPlanFlags(selections, product)
  if (flags.isセット内) return ItemPlan.inSet
  else if (flags.isランクアップPP内) return ItemPlan.rankUpInPremium
  else if (flags.isランクアップPP外) return ItemPlan.rankUpOutPremium
  else if (flags.isお持込) return ItemPlan.bringIn
  else return ItemPlan.toBeDecided
}

export const getZoriBagBadgeText = (selections: number[], product: Product) => {
  const flags = getZoriBagPlanFlags(selections, product)
  if (flags.isセット内) return 'セット内'
  else if (flags.isランクアップPP内) return 'ランクアップ｜プレミアム内'
  else if (flags.isランクアップPP外) return 'ランクアップ｜プレミアム外'
  else if (flags.isお持込) return 'お持込'
  else return null
}

export const getZoriBagPriceTypeText = (showPriceInfo?: ShowPriceInfo) => {
  if (!showPriceInfo) return null
  if (showPriceInfo.flags.is単品レンタル) {
    return '単品レンタル'
  } else if (showPriceInfo.flags.is単品購入) {
    return '単品購入'
  } else {
    return null
  }
}

export const getZoriBagPlanFlags = (selections: number[], product: Product) => {
  // NOTE: 草履バッグタブ・振袖持込プランにて、商品番号が２択の選択肢IDと被っている
  // それによる誤判定を防ぐため、商品IDを取り除いてから判定ロジックに渡す
  const 被り商品番号: number[] = [
    GRADUATION_ZORI.IN_THE_SET,
    GRADUATION_ZORI.BRING_IN,
  ]
  let newSelections = [...selections]
  if (被り商品番号.includes(product.id)) {
    const i = selections.findIndex((o) => 被り商品番号.includes(o))
    newSelections.splice(i, 1)
  }
  const isセット内 = newSelections.includes(GRADUATION_ZORI.IN_THE_SET)
  const isランクアップPP内 = newSelections.includes(
    GRADUATION_ZORI.RANK_UP_IN_PREMIUM
  )
  const isランクアップPP外 = newSelections.includes(
    GRADUATION_ZORI.RANK_UP_OUT_PREMIUM
  )

  const isお持込 =
    newSelections.includes(GRADUATION_ZORI.BRING_IN) ||
    newSelections.includes(GRADUATION_ZORI.BRING_IN_in振袖持込プラン)

  return {
    isセット内,
    isランクアップPP内,
    isランクアップPP外,
    isお持込,
    is単品:
      !isセット内 && !isランクアップPP内 && !isランクアップPP外 && isお持込,
  }
}

export type StoreValues = {
  input: ZoriBagInput
  price: price
  selections: number[]
  product: Product
}

/** 草履バッグの特有の条件を当ててpriceType（単品購入・単品レンタルの場合の値）情報を取得する */
export const getZoriBagPriceType = (
  storeValues: StoreValues,
  planFlags: PlanFlags
) => {
  const { product, selections, input } = storeValues
  const isU系商品 = product.post_number_code.includes('U-')

  // NOTE: 微妙にkey名が他のタブと違うため、ここでその差を解消する
  const store = {
    ...storeValues,
    isFree: false,
    input: {
      productName: input.productName,
      buyPrice: input.buyPrice,
      rentalPrice: input.rentalPrice,
      rankUpRentalPrice: input.rankupRentalPrice,
      rankUpBuyPrice: input.rankupBuyPrice,
      premiumRentalPrice: input.rentalPremiumPrice,
      premiumBuyPrice: input.buyPremiumPrice,
    },
  }

  // NOTE: フルセット以外のプランでU系を選ぶと、選択肢なしで自動的に単品購入選択になる
  if (isU系商品 && !planFlags.isフルセット) {
    const item単品購入 = {
      showType: {
        id: EstimateSinglePriceType.singlePurchasePrice,
        value: '単品購入',
      } as ShowPriceType,
      showPrice: input.singleBuy,
      flags: {
        is単品レンタル: false,
        is単品購入: true,
      },
    }
    return item単品購入
  }

  // NOTE: 持込で現品以外を選んだ際はsingleRental&singleBuyのみに値が入るので、
  // 共有ロジックでも判定できるようにrentalPrice/buyPriceに移し替える
  if (planFlags.is持ち込み && storeValues.input.singleRental) {
    store.input.rentalPrice = storeValues.input.singleRental
  }
  if (planFlags.is持ち込み && storeValues.input.singleBuy) {
    store.input.buyPrice = storeValues.input.singleBuy
  }

  // NOTE: 草履バッグタブにて、商品選択IDの一つがが単品購入=9と単品レンタル=8と被っている
  // それによる誤判定を防ぐため、商品IDが8か9の場合はそれを取り除いてから価格判定ロジックに渡す
  const 被り商品番号: number[] = [
    PLAN.SINGLE_RENTAL_PRICE,
    PLAN.SINGLE_BUY_PRICE,
  ]
  if (被り商品番号.includes(product.id)) {
    const i = selections.findIndex((o) => 被り商品番号.includes(o))
    const newSelections = [...selections]
    newSelections.splice(i, 1)
    const newStore = { ...store, selections: newSelections }
    return getShowPriceInfo(newStore)
  }
  return getShowPriceInfo(store)
}

export type ItemPrices = {
  レンタル価格: number
  購入価格: number
  単品購入価格: number
  プレミアムレンタル価格: number
  プレミアム購入価格: number
}

export const getZoriBagPrices = (
  storeValues: StoreValues,
  originalState: ItemPrices,
  planFlags: PlanFlags
) => {
  const { selections, price, input, product } = storeValues
  const itemState = { ...originalState }
  const itemFlags = getZoriBagPlanFlags(selections, product)
  const isU系商品 = product.post_number_code.includes('U-')

  const is現品 = selections.includes(PRODUCT_BAG.ACTUAL_PRODUCT)
  const is同じ商品を単品購入 = selections.includes(
    GRADUATION_ZORI.BUY_THE_SAME_PRODUCT
  )
  if (planFlags.is持ち込み) {
    if (is現品) {
      itemState.レンタル価格 = input.singleRental
      itemState.単品購入価格 = input.singleBuy
    } else {
      itemState.レンタル価格 = price.rental || input.singleRental
      itemState.単品購入価格 = price.buy || input.singleBuy
    }

    // NOTE: U系の場合は単品購入のみの選択になるためレンタル価格は0円になる
    if (isU系商品) {
      itemState.レンタル価格 = 0
    }
    return itemState
  }

  // フルセット || 写のみレンタルの場合
  itemState.レンタル価格 = input.rentalPrice
  itemState.単品購入価格 = input.singleBuy

  // NOTE: 「同じ商品を単品購入」が選ばれた時はレンタル用金額は保持不要
  if (itemFlags.isランクアップPP外 && !is同じ商品を単品購入) {
    // NOTE: PP内もランクアップなのに何故かそちらは.rankupRentalPrice/.rankupBuyPriceを使っていない...
    itemState.レンタル価格 = input.rankupRentalPrice
    itemState.プレミアムレンタル価格 = input.rentalPremiumPrice
  }

  if (planFlags.isフルセット) {
    itemState.購入価格 = input.buyPrice
    if (itemFlags.isランクアップPP外) {
      itemState.購入価格 = input.rankupBuyPrice
      itemState.プレミアム購入価格 = input.buyPremiumPrice
    }
  }

  return itemState
}
