import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { ProductState } from '~/store/types'

export const useRestoreWPhoto商品設定 = () => {
  const store = useComingStore<ProductState>('WphotoPlan/WphotoProducts')

  const restoreWPhoto商品設定 = (
    items: Restate['w_photo_plan']['商品設定'] | undefined
  ) => {
    if (!items) return

    /* 初期値を設定 ※必ず入力される値はここで設置 */
    const formValues: ProductState = {
      products: [
        {
          id: 1,
          name: '帯揚げ',
          count: items.count帯揚げ,
        },
        {
          id: 2,
          name: '重ね衿',
          count: items.count重ね衿,
        },
      ],
      isValidate: false,
    }
    /* ストアに内容を保存する */
    store.setIsValidate(false)
    store.setAll(formValues)
  }
  return { restoreWPhoto商品設定 }
}
