import { GetterTree } from 'vuex'
import { RootState } from '../types'
import { State } from './state'

const getters: GetterTree<State, RootState> = {
  getPrices: (state: State) => {
    return state.prices
  },
}

export default getters
