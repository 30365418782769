import { ComingOfAgeCeremonyState } from '../../types'

const state = (): ComingOfAgeCeremonyState => ({
  patternLine: [0, 4, 7],

  input: {
    ceremonyDate: '', // 成人式使用日
    shopOfDressing: '', // 着付場所
    placeOfAttending: '', // 出席地域
    timeOfEnteringDressPlace: '', // 着付場所入場時間
    timeOfEnteringSalon: '', // 美容室入場時間
    remark: '', // 備考
    prefecture: '',
    nameOfSalon: '', // 美容室名
    telOfSalon: '', // 美容室TEL
    salonId: 0, // 美容室ID
    shopId: null, // 美容室の店舗ID
    giveOption: 0, // お渡し方法
    dateOfGivingCOAC: '', // 成人式お渡し日
    placeOfGivingCOAC: '', // 成人式お渡し場所
    passStoreId: 0, // お渡し場所ID
    dateOfReturningCOAC: '', // 成人式ご返却日
    placeOfReturningCOAC: '', // 成人式ご返却場所
    returnStoreId: 0, // ご返却場所ID
    needMakeup: true, // メイク有無
  },
  shop: {
    shopId: 0,
    shopName: '',
  },
  dressingPlaces: {
    id: null, // 着付け会場ID
    name: '', // 着付け会場名
    dressingAddressInfo: null, // 着付会場住所
  },
  isValidate: null,
})

export default state
