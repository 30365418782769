import { ContractType } from '../enums/contractType'

// 契約書作成検索
export const SEARCH = {
  NONE: { id: 0 },
  FROM_CUSTOMERS: { id: 1 },
  FROM_ESTIMATE_NUMBER: { id: 2 },
} as const

// 見積書タイプ（1:成人式 2:卒業式 3:参列 4:結納 5:その他 6:写真のみプラン 7:単品 9:未契約 10:クリーニング）
export const ESTIMATE = {
  NONE: { id: 0 },
  成人式: { id: 1 },
  卒業式: { id: 2 },
  参列: { id: 3 },
  単品: { id: ContractType.singleItemService },
  クリーニング: { id: ContractType.cleaning }, // クリーニングは契約のみだが、契約書用のENUMがない為、ここに記載
} as const

export const DOCUMENT = {
  見積書: { id: 1 },
  契約書: { id: 2 },
  領収書: { id: 3 },
  お預かり書: { id: 4 },
  お預かり書前撮りのみ: { id: 6 }, // ５はCRM側で既に使用されている為
} as const

export type decumentType = typeof DOCUMENT[keyof typeof DOCUMENT]['id']

export const DISCOUNT_TYPE = {
  NONE: { id: 0 },
  SISTER: { id: 1 },
  TWINS: { id: 2 },
  EMPLOYEE: { id: 3 },
  GENERAL: { id: 4 },
} as const

export const DISCOUNT_PRICE_TYPE = {
  NONE: { id: 0 },
  RATE: { id: 1 },
  AMOUNT: { id: 2 },
} as const

// 小物検索API用
/*
  NOTE:
  似たようなやつ多いかも？
  ~/utils/utils.ts の newProductIdToStr とか、
  ~/constants/enums/productClassification.ts とか
  使ってるとこ多そうなので一旦残してる
*/
export const PRODUCT_TYPE = {
  FURISODE: { id: 1, text: '振袖' },
  OBI: { id: 2, text: '帯' },
  OBIJIME: { id: 3, text: '帯締め' },
  OBIAGE: { id: 4, text: '帯揚げ' },
  KASANEERI: { id: 5, text: '重ね衿' },
  BETSUERI: { id: 6, text: '別衿' },
  SHAWL: { id: 7, text: 'ショール' },
  NISHAKUSODE: { id: 8, text: '二尺袖' },
  HAKAMA: { id: 9, text: '袴' },
  HOUMONGI: { id: 10, text: '訪問着' },
  TOMESODE: { id: 11, text: '留袖' },
  PARTY_DRESS: { id: 12, text: 'パーティドレス' },
  PHOTO: { id: 13, text: '写真' },
  // NOTE: 14~16はなし
  SERVICE: { id: 17, text: 'サービス' },
  HANHABAOBI: { id: 18, text: '半巾帯' },
  GOHUKU: { id: 19, text: '呉服' },
  MORNING: { id: 20, text: 'モーニング' },
  CLEANING: { id: 21, text: 'クリーニング' },
  WEDDING_DRESS: { id: 22, text: 'ウェディングドレス' },
  TUXEDO: { id: 23, text: 'タキシード' },
  ACCESSORIES: { id: 24, text: 'アクセサリー' },
  KAMIKAZARI: { id: 25, text: '髪飾り' },
  ZORIANDBAG: { id: 26, text: '草履・バッグ' },
  ZORI: { id: 27, text: '草履' },
  BAG: { id: 28, text: 'バッグ' },
  TAX_ADJUSTMENT: { id: 29, text: '税調整' },
  JK_PHOTO_ADVANCE: { id: 30, text: 'JK前々撮り' },
  KINTYAKU: { id: 31, text: '巾着' },
  NAGAJYUBAN: { id: 32, text: '長襦袢' },
  OBIITA: { id: 33, text: '帯板' },
  DATEJIME: { id: 34, text: '伊達〆' },
  KOSIHIMO: { id: 35, text: '腰紐' },
  ERISIN: { id: 36, text: '衿芯' },
  SANJUHIMO_AND_MAKURA: { id: 37, text: '三重紐・枕' },
  KORINBELT: { id: 38, text: 'コーリンベルト' },
  HADAGI: { id: 39, text: '肌着' },
  TOWEL: { id: 40, text: 'タオル' },
  TABI: { id: 41, text: '足袋' },
  BOOTS: { id: 42, text: 'ブーツ' },
} as const

// 着物検索API用
export const PRODUCT_TYPE_KIMONO = {
  FURISODE: { id: 1, text: '振袖' },
  NISHAKUSODE: { id: 8, text: '二尺袖' },
  HAKAMA: { id: 9, text: '袴' },
} as const

// 裄丈
export const DIGIT_OPTIONS = [
  { id: '1,6,5', value: '1尺6寸5分' },
  { id: '1,7,0', value: '1尺7寸0分' },
  { id: '1,7,5', value: '1尺7寸5分' },
  { id: '1,8,0', value: '1尺8寸0分' },
  { id: '1,8,5', value: '1尺8寸5分' },
  { id: '1,9,0', value: '1尺9寸0分' },
  { id: '1,9,5', value: '1尺9寸5分' },
  { id: '2,0,0', value: '2尺0寸0分' },
] as const

// 袖丈
export const SLEEVE_OPTIONS = [
  // 150cm~170cm
  { id: '2,5,0', value: '2尺5寸0分' },
  { id: '2,5,5', value: '2尺5寸5分' },
  { id: '2,6,0', value: '2尺6寸0分' },
  { id: '2,6,5', value: '2尺6寸5分' },
  { id: '2,7,0', value: '2尺7寸0分' },
  { id: '2,7,5', value: '2尺7寸5分' },
  { id: '2,8,0', value: '2尺8寸0分' },
  { id: '2,8,5', value: '2尺8寸5分' },
  { id: '2,9,0', value: '2尺9寸0分' },
  { id: '2,9,5', value: '2尺9寸5分' },
  { id: '3,0,0', value: '3尺0寸0分' },
  { id: '3,0,5', value: '3尺0寸5分' },
] as const

/** 2サイズのみ商品（180と185）しか存在しない */
export const お誂えITEMS = [
  'E624',
  'G821',
  'G822',
  'I923',
  'I924',
  'I925',
  'I926',
] as const

export const COORDINATE_PLAN = {
  UN_SET: 0,
  RENTAL: 1, // 振袖レンタル
  RENTAL_PREMIUM: 2, // 振袖レンタル+プレミアムパック
  BUY: 3, // 振袖購入
  BUY_PREMIUM: 4, // 振袖購入+プレミアムパック
} as const

export const RUNK = {
  UN_SET: 0,
  STANDARD: 1, // スタンダード
  RUNK_UP: 2, // ランクアップ
} as const

export const W_PHOTO_COORDINATE_PLAN = {
  UN_SET: 0,
  RENTAL: COORDINATE_PLAN.RENTAL, // 振袖レンタル
  RENTAL_PREMIUM: COORDINATE_PLAN.RENTAL_PREMIUM, // 振袖レンタル+プレミアムパック
} as const

/**
 * JK前撮りカット数選択肢
 *
 * [JK前撮り写真カット数]
 */
export const JK_CUTS_PLAN_OPTIONS = [
  {
    id: 1,
    value: 'JK前撮りアルバム 5ポーズ × 1冊',
    poseCount: 5,
    bookCount: 1,
  },
]

/*
 * ===================
 * 前撮り写真カット数
 * ===================
 */

/**
 * [前撮り写真カット数]
 *  - ids      => PHOTO_ALBUM
 *  - sections => CUTS_PLAN_OPTIONS
 *  - prices   => PHOTO_ALBUM_PRICE
 */
export const PHOTO_ALBUM = {
  /**
   * フルセットプラン（ご購入）
   * 15P×2冊
   */
  FULL_SET_BUY: 1,
  /**
   * フルセットプラン（レンタル）
   * 15P×1冊：¥0
   */
  FULL_SET_RENTAL: 2,
  /**
   * 写真のみプラン（持込／プレミアム）
   * 15P×1冊
   */
  ONLY_PHOTO_BRING_IN_PREMIUM: 3,
  /**
   * 写真のみプラン（持込／スタンダード）
   * 5P×1冊
   */
  ONLY_PHOTO_BRING_IN_STANDARD: 4,
  /**
   * 写真のみプラン（レンタル／プレミアム）
   * 15P×1冊
   */
  ONLY_PHOTO_RENTAL_PREMIUM: 5,
  /**
   * 写真のみプラン（レンタル／スタンダード）
   * 5P×1冊
   */
  ONLY_PHOTO_RENTAL_STANDARD: 6,
  /**
   * お写真撮影プラン（持込／プレミアム）+お持込プラン（成人式Aコース）
   * 15P×1冊
   */
  PLAN_A_IN_PREMIUM: 7,
  /**
   * お写真撮影プラン（持込／スタンダード）+お持込プラン（成人式Aコース）
   * 5P×1冊
   */
  PLAN_A_IN_STANDARD: 8,
  /**
   * お写真撮影プラン（持込／プレミアム）+お持込プラン（成人式Bコース）
   * 15P×1冊
   */
  PLAN_B_IN_PREMIUM: 9,
  /**
   * お写真撮影プラン（持込／スタンダード）+お持込プラン（成人式Bコース）
   * 5P×1冊
   */
  PLAN_B_IN_STANDARD: 10,
} as const

/**
 * 前撮りカット数選択肢
 *
 * [前撮り写真カット数]
 *  - ids      => PHOTO_ALBUM
 *  - sections => CUTS_PLAN_OPTIONS
 *  - prices   => PHOTO_ALBUM_PRICE
 */
export const CUTS_PLAN_OPTIONS = [
  {
    id: PHOTO_ALBUM.FULL_SET_RENTAL,
    value: '前撮りアルバム 15ポーズ × 1冊',
    poseCount: 15,
    bookCount: 1,
  },
  {
    id: PHOTO_ALBUM.FULL_SET_BUY,
    value: '前撮りアルバム 15ポーズ × 2冊',
    poseCount: 15,
    bookCount: 2,
  },
  {
    id: PHOTO_ALBUM.PLAN_A_IN_PREMIUM,
    value: '前撮りアルバム 15ポーズ × 1冊',
    poseCount: 15,
    bookCount: 1,
  },
  {
    id: PHOTO_ALBUM.PLAN_B_IN_PREMIUM,
    value: '前撮りアルバム 15ポーズ × 1冊',
    poseCount: 15,
    bookCount: 1,
  },
  {
    id: PHOTO_ALBUM.PLAN_A_IN_STANDARD,
    value: '前撮りアルバム 5ポーズ × 1冊',
    poseCount: 5,
    bookCount: 1,
  },
  {
    id: PHOTO_ALBUM.PLAN_B_IN_STANDARD,
    value: '前撮りアルバム 5ポーズ × 1冊',
    poseCount: 5,
    bookCount: 1,
  },
  {
    id: PHOTO_ALBUM.ONLY_PHOTO_RENTAL_PREMIUM,
    value: '前撮りアルバム 15ポーズ × 1冊',
    poseCount: 15,
    bookCount: 1,
  },
  {
    id: PHOTO_ALBUM.ONLY_PHOTO_RENTAL_STANDARD,
    value: '前撮りアルバム 5ポーズ × 1冊',
    poseCount: 5,
    bookCount: 1,
  },
  {
    id: PHOTO_ALBUM.ONLY_PHOTO_BRING_IN_PREMIUM,
    value: '前撮りアルバム 15ポーズ × 1冊',
    poseCount: 15,
    bookCount: 1,
  },
  {
    id: PHOTO_ALBUM.ONLY_PHOTO_BRING_IN_STANDARD,
    value: '前撮りアルバム 5ポーズ × 1冊',
    poseCount: 5,
    bookCount: 1,
  },
]

/**
 * 写真アルバム代（金額）
 * NOTE: https://docs.google.com/spreadsheets/d/1Gez6ri13cz206M9gYOSfpYwE7mX8uiLT0SFiHPrEtn8/edit#gid=294254135&range=13:23
 *
 * [前撮り写真カット数]
 *  - ids      => PHOTO_ALBUM
 *  - sections => CUTS_PLAN_OPTIONS
 *  - prices   => PHOTO_ALBUM_PRICE
 */
export const PHOTO_ALBUM_PRICE = {
  /**
   * フルセットプラン（ご購入）
   * 15P×2冊
   */
  FULL_SET_BUY: 0,
  /**
   * フルセットプラン（レンタル）
   * 15P×1冊：¥0
   */
  FULL_SET_RENTAL: 0,
  /**
   * 写真のみプラン（持込／プレミアム）
   * 15P×1冊
   */
  ONLY_PHOTO_BRING_IN_PREMIUM: 165000,
  /**
   * 写真のみプラン（持込／スタンダード）
   * 5P×1冊
   */
  ONLY_PHOTO_BRING_IN_STANDARD: 55000,
  /**
   * 写真のみプラン（レンタル／プレミアム）
   * 15P×1冊
   */
  ONLY_PHOTO_RENTAL_PREMIUM: 165000,
  /**
   * 写真のみプラン（レンタル／スタンダード）
   * 5P×1冊
   */
  ONLY_PHOTO_RENTAL_STANDARD: 55000,
  /**
   * お写真撮影プラン（持込／プレミアム）+お持込プラン（成人式Aコース）
   * 15P×1冊
   */
  PLAN_A_IN_PREMIUM: 165000,
  /**
   * お写真撮影プラン（持込／スタンダード）+お持込プラン（成人式Aコース）
   * 5P×1冊
   */
  PLAN_A_IN_STANDARD: 55000,
  /**
   * お写真撮影プラン（持込／プレミアム）+お持込プラン（成人式Bコース）
   * 15P×1冊
   */
  PLAN_B_IN_PREMIUM: 165000,
  /**
   * お写真撮影プラン（持込／スタンダード）+お持込プラン（成人式Bコース）
   * 5P×1冊
   */
  PLAN_B_IN_STANDARD: 55000,
} as const

/*
 * ===================
 * 着付けなし割引
 * ===================
 */
export const DRESSING_DISCOUNT = 1000

/*
 * ===================
 * 割引
 * ===================
 */
// [割引の種類]
export const DISCOUNT = {
  /** 妹割り */
  SISTER: 1,
  /** 双子割 */
  TWINS: 2,
  /** 従業員割 */
  STAFF: 3,
}

/*
 * ===================
 * 紹介者特典
 * ===================
 */
// [割引の種類]
export const REFERRAL_BONUS = {
  /** 浴衣 */
  YUKATA: 1,
  /** QUO */
  QUO: 2,
  /** 写真 */
  PHOTO: 3,
  /** 割引券 */
  COUPON: 4,
  /** ふりそでMODE商品券 */
  GIFT: 5,
  /** 未定 */
  TO_BE_DECIDED: 6,
} as const

// NOTE:
export const ITEM_PRICE = {
  /** 裄補正 */
  DIGIT: 11000,
  /** 袖補正 */
  SLEEVE: 11000,
  /** 足し布 */
  ADD_CLOTH_LOW: 90000,
  ADD_CLOTH_HIGH: 150000,
} as const

/**
 * 仕立て
 */
export const SEWING = {
  HIGH_TECH: 1,
  HIGH_GRADE: 2,
} as const

/**
 * 体型
 */
export const BODY_TYPE = {
  N: 0,
  B: 1,
  BB: 2,
} as const

/**
 * サイズ
 */
export const SIZE = {
  S: 0,
  M: 1,
  L: 2,
  LL: 3,
  /** 3L */
  LLL: 4,
} as const

/**
 * お渡し済み
 */
export const PASS_STATUS = {
  /** 未 */
  PENDING: 0,
  /** 済 */
  DONE: 1,
} as const

/**
 * 前撮りカット数の選択項目
 */
export const ITEM_SELECTION_OPTIONS = [
  { id: 1, value: '前撮りアルバム 15ポーズ × 1冊' }, // フルセット／レンタル
  { id: 2, value: '前撮りアルバム 15ポーズ × 2冊' }, // フルセット／購入
  { id: 3, value: '前撮りアルバム 15ポーズ × 1冊' }, // プレミアムコース + Aコース
  {
    id: 4,
    value: '前撮りアルバム 15ポーズ × 1冊', // プレミアムコース + Bコース（姉販）
  },
  {
    id: 5,
    value: '前撮りアルバム 5ポーズ × 1冊', // スタンダードコース + Aコース
  },
  {
    id: 6,
    value: '前撮りアルバム 5ポーズ × 1冊', // スタンダードコース + Bコース
  },
  {
    id: 7,
    value: '前撮りアルバム 15ポーズ × 1冊', // プレミアムコース（写のみ／前撮りのみ利用）
  },
  {
    id: 8,
    value: '前撮りアルバム 5ポーズ × 1冊', // スタンダードコース（写のみ／前撮りのみ利用）
  },
  // { id: 9, value: 'Bコースのみ（姉販／成人式のみ利用）' }, // FIXME: このときは表示しない
]

/**
 * 小物のプラン
 */
export const ITEMS_PLAN = {
  /** セット内 */
  IN_SET: 1,
  /** レンタルのみランクアップ（購入はセット内） */
  ONLY_RENTAL_RANK_UP: 2,
  /** ランクアップ（プレミアム内） */
  RANK_UP_IN_PREMIUM: 3,
  /** ランクアップ（プレミアム外） */
  RANK_UP_OUT_PREMIUM: 4,
  /** 未定 */
  TO_BE_DECIDED: 6,
}

/**
 * ご返却 来店・お送り区分
 */
export const RETURN_PROCESSING_TYPE = {
  /** ご来店 */
  VISIT: 1,
  /** お送り */
  DELIVERY: 2,
}

/**
 * ご返却 来店・お送り区分
 */
export const DELIVERY_PROCESSING_TYPE = {
  /** ご来店 */
  VISIT: 1,
  /** お送り */
  PASS_ON_PHOTO_IN_ADVANCE_DAY: 2,
}

/**
 * [予約区分]
 */
export const RESERVATION_CLASSIFICATION = {
  COMING_OF_AGE: 1, //成人式
  GRADUATION_CEREMONY: 2, //卒業式
  PHOTO_IN_ADVANCE: 3, //前撮り/JK前撮り/Wフォト
  // JK_PHOTO_IN_ADVANCE: 4, JK前撮り
  // NOTE: 前撮りと同じ枠で予約確認の必要があると判明したため使用しない
  ELSE: 5, //その他(母娘プラン、参列)
}

/**
 * 友の会会員 (API用)
 */
export const IS_TOMONOKAI_MEMBER = {
  /** 非会員 */
  NO: 0,
  /** 会員 */
  YES: 1,
} as const

/**
 * 支払い必要かどうか
 */
export const IS_PAYMENT = {
  /** 支払い不要 */
  NO: 1,
  /** 支払い必要 */
  YES: 2,
} as const

/**
 * その他商品
 */
export class OTHER_ITEMS {
  //その他の商品を追加しますか？
  static readonly 種別 = {
    する: 3,
    しない: 4,
  }

  // どちらを選びますか？
  static readonly PRICE_TYPE = {
    単品レンタル: 5,
    単品購入: 6,
  }

  //その他の商品を追加しますか？
  static readonly 袴利用 = {
    する: 1,
    しない: 2,
  }
}

export const IS_SECOND_PP = '※こちらがPPに含まれます。'
