import { FormValues巾着バッグ_着物持込 } from '~/composable/estimate/viewModel/graduation/Fields/Fields巾着バッグ_着物持込'

//不明な点がある場合、READMEをご覧ください
export const getInitialStore = (): FormValues巾着バッグ_着物持込 => {
  return {
    kinchakubag種別: null,
    kinchakubag商品選択: null,
    kinchakubag単品レンタル: null,
    kinchakubag単品購入: null,
    kinchakubag現品単品レンタル: null,
    kinchakubag現品単品レンタルフリー入力: null,
    kinchakubag現品単品購入: null,
    kinchakubag現品単品購入フリー入力: null,
    kinchakubag見積選択: null,
  }
}

const state = (): { fields: FormValues巾着バッグ_着物持込 } => ({
  fields: getInitialStore(),
})

export default state
