import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import {
  AmountAdjustments,
  AmountAdjustmentsPrice,
  EstimateListState,
} from '~/store/types'
import { AdjustmentType } from '~/constants/enums/AdjustmentType'

export const useRestore見積一覧 = () => {
  const store = useComingStore<EstimateListState['fields']>('EstimateList')

  const restore見積一覧 = (restate: Restate) => {
    const {
      hurisode,
      selected_plan,
      w_photo_plan,
      additions,
      contract_total_price,
      amount_adjustments,
      items,
    } = restate

    const isZoriBagPPDiscount = items.草履1個目?.flags.isPPDiscount ?? false
    const isZoriBagPPSetPrice =
      (isZoriBagPPDiscount &&
        items.草履1個目?.flags.isセット内 &&
        items.バッグ1個目?.flags.isセット内) ??
      false
    const isObiageSecondRankUp =
      !!items.帯揚げ &&
      (items.帯揚げ?.length ?? 0) > 1 &&
      !!items.帯揚げ[1]?.flags.isPPRankUp
    const isKasaneeriSecondRankUp =
      !!items.重ね衿 &&
      (items.重ね衿?.length ?? 0) > 1 &&
      !!items.重ね衿[1]?.flags.isPPRankUp

    if (!additions || !hurisode || !selected_plan || !w_photo_plan) return

    const { selected手縫い } = hurisode.flags
    const { 長襦袢 } = additions

    const wPhotoType = [
      AdjustmentType.Wフォトレンタル,
      AdjustmentType.WフォトレンタルPP,
      AdjustmentType.Wフォト持込,
    ]
    const amountAdjustmentValidation: boolean[][] = []
    const amountAdjustmentValidationForWPhoto: boolean[][] = []
    const amountAdjustment: AmountAdjustments[] = []
    const amountAdjustmentForWPhoto: AmountAdjustments[] = []
    if (amount_adjustments?.length) {
      amount_adjustments.forEach((a) => {
        const validations = [true]
        const validationsForWPhoto = [true]
        const wPhotoItemNum = a.prices.filter((p: AmountAdjustmentsPrice) =>
          wPhotoType.includes(p.type)
        ).length

        const itemNum = a.prices.filter(
          (p: AmountAdjustmentsPrice) => !wPhotoType.includes(p.type)
        ).length

        if (wPhotoItemNum) {
          for (let i = 0; i < wPhotoItemNum; i++) {
            validationsForWPhoto.push(true)
          }
          amountAdjustmentForWPhoto.push(a)
          amountAdjustmentValidationForWPhoto.push(validationsForWPhoto)
        }
        if (itemNum) {
          for (let i = 0; i < itemNum; i++) {
            validations.push(true)
          }

          amountAdjustment.push(a)
          amountAdjustmentValidation.push(validations)
        }
      })
    }

    /* 初期値を設定 ※必ず入力される値はここで設置 */
    const oldValues = store.getAll()
    const formValues: EstimateListState['fields'] = {
      // NOTE: 一部のステートはコンポーネントで計算されるので、現状の値を取得して上書きする
      ...oldValues.value,
      selectedPlan: selected_plan,
      selectedSewings: [selected手縫い, selected手縫い],
      selectedNagajubans: [長襦袢.selectedOption, 長襦袢.selectedOption],
      totalPrice: contract_total_price,
      selectedWPPlan: w_photo_plan.selected_plan,
      amount_adjustments: amountAdjustment,
      amount_adjustment_validation: amountAdjustmentValidation,
      amount_adjustments_for_wphoto: amountAdjustmentForWPhoto,
      amount_adjustment_validation_for_wphoto:
        amountAdjustmentValidationForWPhoto,
      isObiageSecondRankUp: isObiageSecondRankUp,
      isKasaneeriSecondRankUp: isKasaneeriSecondRankUp,
      isZoriBagPriceCalculated: isZoriBagPPDiscount,
      isZoriBagSetPrice: isZoriBagPPSetPrice,
    }

    /* ストアに内容を保存する */
    store.setAll(formValues)
  }
  return { restore見積一覧 }
}
