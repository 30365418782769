

































































import {
  defineComponent,
  useStore,
  useMeta,
  ref,
  watch,
  useRoute,
  useRouter,
  onMounted,
  onBeforeUnmount,
  nextTick,
  computed,
} from '@nuxtjs/composition-api'
import IconArrowLeft from '~/components/icons/IconArrowLeft.vue'
import IconValidationSuccess from '~/components/icons/IconValidationSuccess.vue'
import IconValidationError from '~/components/icons/IconValidationError.vue'
import { useEstimateTabList } from '~/composable/headerTab/useEstimateTabList'
import { useEstimateTypeFlags } from '~/composable/estimate/domain/EstimateTypeFlags'

type TabList = {
  text: string
  url: string
  isValidate: boolean | null
}

export default defineComponent({
  components: {
    IconArrowLeft,
    IconValidationError,
    IconValidationSuccess,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'お名前',
    },
    onlyHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const selectedTab = ref(props.title)
    const { comingOfAgeCeremonyTabList, deleteHiddenTabStore } =
      useEstimateTabList()
    const isChange = ref(false)
    const estimateId = route.value.query.id
    const contractId = route.value.query.contract_id
    const { in卒業式見積, in成人式見積 } = useEstimateTypeFlags()
    const scrollYAmount = ref(0)

    const handleScroll = () => {
      scrollYAmount.value = window.scrollY
    }
    const is見積一覧 = props.title === '見積一覧'

    /** 確認画面から遷移したときに「入力内容確認」のフッターを表示 */
    const handleFromChangeButton = () => {
      if (estimateId) {
        isChange.value = true
      }
    }

    const clickEvent = (tab: TabList) => {
      selectedTab.value = tab.text

      if (contractId) {
        router.push({
          path: tab.url,
          query: {
            contract_id: contractId,
          },
        })
      } else {
        router.push(tab.url)
      }
    }

    const historyBack = () => {
      isChange.value = false
      history.back()
      window.addEventListener('popstate', () => {
        filterTabList()
      })
    }

    const customerName = computed(
      () => store.getters['Estimate/ComingOfAgeCeremony/Customers/getName']
    )

    const selectedCustomerName = computed(() => {
      const selectedCustomer =
        store.getters['Estimate/ComingOfAgeCeremony/Customers/getCustomer']
      return selectedCustomer?.last_name + selectedCustomer?.first_name
    })

    const customerNameDisplay = computed(
      () => selectedCustomerName.value || customerName.value
    )

    onMounted(() => {
      filterTabList()
      handleFromChangeButton()
    })

    nextTick(() => {
      scrollTab()
    })

    // タブを横スクロールする
    const scrollTab = () => {
      const currentTabElement = document.getElementsByClassName('-current')[0]
      currentTabElement.scrollIntoView({
        block: 'nearest',
        inline: 'start',
        behavior: 'smooth',
      })
    }

    // タブ表示の切り替え
    const filterTabList = () => {
      comingOfAgeCeremonyTabList.value.filter((list: any) => {
        if (list.url === route.value.path) {
          selectedTab.value = list.text
        }
      })
    }

    //今いる見積ページが卒業式なのか成人式か判定
    const eventName = computed(() => {
      if (in卒業式見積.value) {
        return '卒業式'
      } else if (in成人式見積.value) {
        return '成人式'
      }
    })

    // titleを動的に変更
    useMeta(() => ({
      title: customerNameDisplay.value
        ? `furisode mode | ${customerNameDisplay.value} 様`
        : 'furisode mode',
    }))

    // urlを監視して画面遷移後の文字色を変更する
    watch(
      () => route.value.path,
      () => {
        filterTabList()
        deleteHiddenTabStore()
      }
    )

    onMounted(() => {
      window.addEventListener('scroll', handleScroll)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll)
    })

    return {
      comingOfAgeCeremonyTabList,
      scrollYAmount,
      clickEvent,
      selectedTab,
      customerNameDisplay,
      historyBack,
      isChange,
      eventName,
      is見積一覧,
    }
  },
  head: {},
})
