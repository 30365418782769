import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b3e93df8 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _392fa70a = () => interopDefault(import('../src/pages/new.vue' /* webpackChunkName: "pages/new" */))
const _9a2774b8 = () => interopDefault(import('../src/pages/reselect.vue' /* webpackChunkName: "pages/reselect" */))
const _1f255440 = () => interopDefault(import('../src/pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _35bb6087 = () => interopDefault(import('../src/pages/viewer.vue' /* webpackChunkName: "pages/viewer" */))
const _573ddedd = () => interopDefault(import('../src/pages/contracts/accessories.vue' /* webpackChunkName: "pages/contracts/accessories" */))
const _f9ae5570 = () => interopDefault(import('../src/pages/contracts/baseInformation.vue' /* webpackChunkName: "pages/contracts/baseInformation" */))
const _8b10d194 = () => interopDefault(import('../src/pages/contracts/comingOfAgeCeremony.vue' /* webpackChunkName: "pages/contracts/comingOfAgeCeremony" */))
const _5e4cc450 = () => interopDefault(import('../src/pages/contracts/confirmation.vue' /* webpackChunkName: "pages/contracts/confirmation" */))
const _c8c927ce = () => interopDefault(import('../src/pages/contracts/creationCompleted.vue' /* webpackChunkName: "pages/contracts/creationCompleted" */))
const _6f414c52 = () => interopDefault(import('../src/pages/contracts/customerReady.vue' /* webpackChunkName: "pages/contracts/customerReady" */))
const _c2f02e36 = () => interopDefault(import('../src/pages/contracts/deliveryDate.vue' /* webpackChunkName: "pages/contracts/deliveryDate" */))
const _e27aefe4 = () => interopDefault(import('../src/pages/contracts/doublePhoto.vue' /* webpackChunkName: "pages/contracts/doublePhoto" */))
const _59451715 = () => interopDefault(import('../src/pages/contracts/familyInformation.vue' /* webpackChunkName: "pages/contracts/familyInformation" */))
const _3513981a = () => interopDefault(import('../src/pages/contracts/graduationCeremony.vue' /* webpackChunkName: "pages/contracts/graduationCeremony" */))
const _0c1ad930 = () => interopDefault(import('../src/pages/contracts/importantMatter.vue' /* webpackChunkName: "pages/contracts/importantMatter" */))
const _2559ddcf = () => interopDefault(import('../src/pages/contracts/jkPhotoInAdvance.vue' /* webpackChunkName: "pages/contracts/jkPhotoInAdvance" */))
const _0d1bb208 = () => interopDefault(import('../src/pages/contracts/measuring.vue' /* webpackChunkName: "pages/contracts/measuring" */))
const _5bf7b11c = () => interopDefault(import('../src/pages/contracts/motherAndDaughterPlan.vue' /* webpackChunkName: "pages/contracts/motherAndDaughterPlan" */))
const _b6b78350 = () => interopDefault(import('../src/pages/contracts/option.vue' /* webpackChunkName: "pages/contracts/option" */))
const _a7de359a = () => interopDefault(import('../src/pages/contracts/payment.vue' /* webpackChunkName: "pages/contracts/payment" */))
const _0484096e = () => interopDefault(import('../src/pages/contracts/photoInAdvance.vue' /* webpackChunkName: "pages/contracts/photoInAdvance" */))
const _377d7926 = () => interopDefault(import('../src/pages/contracts/productPhoto.vue' /* webpackChunkName: "pages/contracts/productPhoto" */))
const _80e48022 = () => interopDefault(import('../src/pages/contracts/referralBonus.vue' /* webpackChunkName: "pages/contracts/referralBonus" */))
const _adac083a = () => interopDefault(import('../src/pages/contracts/remark.vue' /* webpackChunkName: "pages/contracts/remark" */))
const _42a93bde = () => interopDefault(import('../src/pages/contracts/saveList.vue' /* webpackChunkName: "pages/contracts/saveList" */))
const _36cd11a5 = () => interopDefault(import('../src/pages/contracts/signature.vue' /* webpackChunkName: "pages/contracts/signature" */))
const _301f403b = () => interopDefault(import('../src/pages/contracts/tryOnPhoto.vue' /* webpackChunkName: "pages/contracts/tryOnPhoto" */))
const _dd4da6c4 = () => interopDefault(import('../src/pages/estimate/create.vue' /* webpackChunkName: "pages/estimate/create" */))
const _60ed4d46 = () => interopDefault(import('../src/pages/estimate/saveList.vue' /* webpackChunkName: "pages/estimate/saveList" */))
const _fbf86c98 = () => interopDefault(import('../src/pages/search/checklist.vue' /* webpackChunkName: "pages/search/checklist" */))
const _41aa5d0e = () => interopDefault(import('../src/pages/search/ChecklistContractSearch.vue' /* webpackChunkName: "pages/search/ChecklistContractSearch" */))
const _5f6eb9bc = () => interopDefault(import('../src/pages/search/searchContract.vue' /* webpackChunkName: "pages/search/searchContract" */))
const _2ea23af0 = () => interopDefault(import('../src/pages/search/searchCustomer.vue' /* webpackChunkName: "pages/search/searchCustomer" */))
const _31f5d2d2 = () => interopDefault(import('../src/pages/search/searchEstimate.vue' /* webpackChunkName: "pages/search/searchEstimate" */))
const _5ee00ad1 = () => interopDefault(import('../src/pages/search/searchFurisodeProducts.vue' /* webpackChunkName: "pages/search/searchFurisodeProducts" */))
const _0def2adc = () => interopDefault(import('../src/pages/search/searchOrderRentalProducts.vue' /* webpackChunkName: "pages/search/searchOrderRentalProducts" */))
const _e11f6104 = () => interopDefault(import('../src/pages/search/searchReceipt.vue' /* webpackChunkName: "pages/search/searchReceipt" */))
const _0e433a8b = () => interopDefault(import('../src/pages/search/searchReferralBonusUndecided.vue' /* webpackChunkName: "pages/search/searchReferralBonusUndecided" */))
const _e04b977a = () => interopDefault(import('../src/pages/search/searchUserInfo.vue' /* webpackChunkName: "pages/search/searchUserInfo" */))
const _ef5a7b48 = () => interopDefault(import('../src/pages/contracts/attendance/_contract_id/main.vue' /* webpackChunkName: "pages/contracts/attendance/_contract_id/main" */))
const _184aa9a8 = () => interopDefault(import('../src/pages/contracts/cleaning/_contract_id/main.vue' /* webpackChunkName: "pages/contracts/cleaning/_contract_id/main" */))
const _3b40bc12 = () => interopDefault(import('../src/pages/contracts/singleItemService/_contract_id/main.vue' /* webpackChunkName: "pages/contracts/singleItemService/_contract_id/main" */))
const _4123cd71 = () => interopDefault(import('../src/pages/estimate/attendance/_estimate_id/main.vue' /* webpackChunkName: "pages/estimate/attendance/_estimate_id/main" */))
const _9d9fcf8e = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/confirmation.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/confirmation" */))
const _55c74658 = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/creationCompleted.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/creationCompleted" */))
const _0dbe7d2c = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/estimateList.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/estimateList" */))
const _fdc07550 = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/complete.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/complete" */))
const _6efae6d4 = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/confirmation.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/confirmation" */))
const _9228dff6 = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/estimateList.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/estimateList" */))
const _3747b02e = () => interopDefault(import('../src/pages/estimate/singleItemService/_estimate_id/main.vue' /* webpackChunkName: "pages/estimate/singleItemService/_estimate_id/main" */))
const _d2318430 = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/bag2.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/bag2" */))
const _d215552e = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/bag3.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/bag3" */))
const _486467f2 = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/betsueri.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/betsueri" */))
const _58988a72 = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/customerName.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/customerName" */))
const _09d85385 = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/customerNumber.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/customerNumber" */))
const _41d07102 = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/discount.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/discount" */))
const _83283166 = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/employeeDiscount.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/employeeDiscount" */))
const _827fd53e = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/furisode.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/furisode" */))
const _5728f00a = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/JkPhotoInAdvance.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/JkPhotoInAdvance" */))
const _7e9a2824 = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/kamikazari.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/kamikazari" */))
const _54c285be = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/kamikazari2.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/kamikazari2" */))
const _54d09d3f = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/kamikazari3.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/kamikazari3" */))
const _749bfb53 = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/kasaneeri.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/kasaneeri" */))
const _1efb186f = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/kasaneeri2.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/kasaneeri2" */))
const _1f092ff0 = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/kasaneeri3.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/kasaneeri3" */))
const _0b87e237 = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/motherAndDaughterPlan.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/motherAndDaughterPlan" */))
const _63cacca8 = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/obi.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/obi" */))
const _5c4a8007 = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/obiage.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/obiage" */))
const _2d1d2a3b = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/obiage2.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/obiage2" */))
const _2d2b41bc = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/obiage3.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/obiage3" */))
const _4e49a83f = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/obijime.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/obijime" */))
const _b7e61b9a = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/option.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/option" */))
const _24211bf5 = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/otherItem.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/otherItem" */))
const _542bc164 = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/photo.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/photo" */))
const _f7a6a732 = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/plan.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/plan" */))
const _084b4bbc = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/products.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/products" */))
const _6d6ee925 = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/remarks.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/remarks" */))
const _31561433 = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/shawl.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/shawl" */))
const _55da697a = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/sisterDiscount.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/sisterDiscount" */))
const _e29ad5be = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/tashinuno.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/tashinuno" */))
const _4c13d11e = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/twinsDiscount.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/twinsDiscount" */))
const _65ba7102 = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/wPhotoPlan.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/wPhotoPlan" */))
const _7e921ab8 = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/zori2.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/zori2" */))
const _7ea03239 = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/zori3.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/zori3" */))
const _72246024 = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/tab/zoriBag.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/tab/zoriBag" */))
const _a2171f3e = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/tab/bikou.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/tab/bikou" */))
const _7c8ae3fc = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/tab/furisode.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/tab/furisode" */))
const _579ec1fc = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/tab/hakama.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/tab/hakama" */))
const _5af2ae62 = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/tab/nishakusode.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/tab/nishakusode" */))
const _7befec9a = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/tab/okyakusamaKensaku.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/tab/okyakusamaKensaku" */))
const _07e0ab8e = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/tab/option.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/tab/option" */))
const _2adce239 = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/tab/oshashinPlan.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/tab/oshashinPlan" */))
const _1fb020ba = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/tab/otherItem.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/tab/otherItem" */))
const _a675fefc = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/tab/plan.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/tab/plan" */))
const _8b03172a = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/tab/shichakuShashin.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/tab/shichakuShashin" */))
const _12beea3f = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/tab/shouhinSettei.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/tab/shouhinSettei" */))
const _4649d43f = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/tab/waribiki.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/tab/waribiki" */))
const _29e6a1d4 = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/tab/betsueri/bringin-kimono.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/tab/betsueri/bringin-kimono" */))
const _568935dd = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/tab/betsueri/rental-kimono.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/tab/betsueri/rental-kimono" */))
const _6ef20e94 = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/tab/hanhabaobi/bringin-hakama.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/tab/hanhabaobi/bringin-hakama" */))
const _668d2382 = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/tab/hanhabaobi/rental-hakama.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/tab/hanhabaobi/rental-hakama" */))
const _b2b18f26 = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/tab/kinchakuBag/bringin-kimono.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/tab/kinchakuBag/bringin-kimono" */))
const _187ee824 = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/tab/kinchakuBag/rental-kimono.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/tab/kinchakuBag/rental-kimono" */))
const _04cb5a6c = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/tab/zoriBoots/bringin-kimono.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/tab/zoriBoots/bringin-kimono" */))
const _2c7ee3b2 = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/tab/zoriBoots/rental-kimono.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/tab/zoriBoots/rental-kimono" */))
const _7c5076f5 = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/tab/kasaneeri/rental-kimono/1.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/tab/kasaneeri/rental-kimono/1" */))
const _666d8712 = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/tab/kasaneeri/bringin-kimono/_number.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/tab/kasaneeri/bringin-kimono/_number" */))
const _0d53b770 = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/tab/kasaneeri/rental-kimono/_moreThan1.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/tab/kasaneeri/rental-kimono/_moreThan1" */))
const _1b61b450 = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/tab/kamikazari/_number.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/tab/kamikazari/_number" */))
const _dd75d8d4 = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/confirmationContract/_id.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/confirmationContract/_id" */))
const _d0d70972 = () => interopDefault(import('../src/pages/estimate/comingOfAgeCeremony/_estimate_id/estimateListContract/_id.vue' /* webpackChunkName: "pages/estimate/comingOfAgeCeremony/_estimate_id/estimateListContract/_id" */))
const _6ec05c9e = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/confirmationContract/_id.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/confirmationContract/_id" */))
const _62218d3c = () => interopDefault(import('../src/pages/estimate/graduationCeremony/_estimate_id/estimateListContract/_id.vue' /* webpackChunkName: "pages/estimate/graduationCeremony/_estimate_id/estimateListContract/_id" */))
const _12f4753c = () => interopDefault(import('../src/pages/userInfo/_id/index.vue' /* webpackChunkName: "pages/userInfo/_id/index" */))
const _282e7c73 = () => interopDefault(import('../src/pages/userInfo/_id/contract/_contract_id/index.vue' /* webpackChunkName: "pages/userInfo/_id/contract/_contract_id/index" */))
const _56336878 = () => interopDefault(import('../src/pages/userInfo/_id/estimate/_estimate_id.vue' /* webpackChunkName: "pages/userInfo/_id/estimate/_estimate_id" */))
const _244afb29 = () => interopDefault(import('../src/pages/userInfo/_id/contract/_contract_id/cancel.vue' /* webpackChunkName: "pages/userInfo/_id/contract/_contract_id/cancel" */))
const _47388426 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/login",
    component: _b3e93df8,
    name: "login"
  }, {
    path: "/new",
    component: _392fa70a,
    name: "new"
  }, {
    path: "/reselect",
    component: _9a2774b8,
    name: "reselect"
  }, {
    path: "/search",
    component: _1f255440,
    name: "search"
  }, {
    path: "/viewer",
    component: _35bb6087,
    name: "viewer"
  }, {
    path: "/contracts/accessories",
    component: _573ddedd,
    name: "contracts-accessories"
  }, {
    path: "/contracts/baseInformation",
    component: _f9ae5570,
    name: "contracts-baseInformation"
  }, {
    path: "/contracts/comingOfAgeCeremony",
    component: _8b10d194,
    name: "contracts-comingOfAgeCeremony"
  }, {
    path: "/contracts/confirmation",
    component: _5e4cc450,
    name: "contracts-confirmation"
  }, {
    path: "/contracts/creationCompleted",
    component: _c8c927ce,
    name: "contracts-creationCompleted"
  }, {
    path: "/contracts/customerReady",
    component: _6f414c52,
    name: "contracts-customerReady"
  }, {
    path: "/contracts/deliveryDate",
    component: _c2f02e36,
    name: "contracts-deliveryDate"
  }, {
    path: "/contracts/doublePhoto",
    component: _e27aefe4,
    name: "contracts-doublePhoto"
  }, {
    path: "/contracts/familyInformation",
    component: _59451715,
    name: "contracts-familyInformation"
  }, {
    path: "/contracts/graduationCeremony",
    component: _3513981a,
    name: "contracts-graduationCeremony"
  }, {
    path: "/contracts/importantMatter",
    component: _0c1ad930,
    name: "contracts-importantMatter"
  }, {
    path: "/contracts/jkPhotoInAdvance",
    component: _2559ddcf,
    name: "contracts-jkPhotoInAdvance"
  }, {
    path: "/contracts/measuring",
    component: _0d1bb208,
    name: "contracts-measuring"
  }, {
    path: "/contracts/motherAndDaughterPlan",
    component: _5bf7b11c,
    name: "contracts-motherAndDaughterPlan"
  }, {
    path: "/contracts/option",
    component: _b6b78350,
    name: "contracts-option"
  }, {
    path: "/contracts/payment",
    component: _a7de359a,
    name: "contracts-payment"
  }, {
    path: "/contracts/photoInAdvance",
    component: _0484096e,
    name: "contracts-photoInAdvance"
  }, {
    path: "/contracts/productPhoto",
    component: _377d7926,
    name: "contracts-productPhoto"
  }, {
    path: "/contracts/referralBonus",
    component: _80e48022,
    name: "contracts-referralBonus"
  }, {
    path: "/contracts/remark",
    component: _adac083a,
    name: "contracts-remark"
  }, {
    path: "/contracts/saveList",
    component: _42a93bde,
    name: "contracts-saveList"
  }, {
    path: "/contracts/signature",
    component: _36cd11a5,
    name: "contracts-signature"
  }, {
    path: "/contracts/tryOnPhoto",
    component: _301f403b,
    name: "contracts-tryOnPhoto"
  }, {
    path: "/estimate/create",
    component: _dd4da6c4,
    name: "estimate-create"
  }, {
    path: "/estimate/saveList",
    component: _60ed4d46,
    name: "estimate-saveList"
  }, {
    path: "/search/checklist",
    component: _fbf86c98,
    name: "search-checklist"
  }, {
    path: "/search/ChecklistContractSearch",
    component: _41aa5d0e,
    name: "search-ChecklistContractSearch"
  }, {
    path: "/search/searchContract",
    component: _5f6eb9bc,
    name: "search-searchContract"
  }, {
    path: "/search/searchCustomer",
    component: _2ea23af0,
    name: "search-searchCustomer"
  }, {
    path: "/search/searchEstimate",
    component: _31f5d2d2,
    name: "search-searchEstimate"
  }, {
    path: "/search/searchFurisodeProducts",
    component: _5ee00ad1,
    name: "search-searchFurisodeProducts"
  }, {
    path: "/search/searchOrderRentalProducts",
    component: _0def2adc,
    name: "search-searchOrderRentalProducts"
  }, {
    path: "/search/searchReceipt",
    component: _e11f6104,
    name: "search-searchReceipt"
  }, {
    path: "/search/searchReferralBonusUndecided",
    component: _0e433a8b,
    name: "search-searchReferralBonusUndecided"
  }, {
    path: "/search/searchUserInfo",
    component: _e04b977a,
    name: "search-searchUserInfo"
  }, {
    path: "/contracts/attendance/:contract_id?/main",
    component: _ef5a7b48,
    name: "contracts-attendance-contract_id-main"
  }, {
    path: "/contracts/cleaning/:contract_id?/main",
    component: _184aa9a8,
    name: "contracts-cleaning-contract_id-main"
  }, {
    path: "/contracts/singleItemService/:contract_id?/main",
    component: _3b40bc12,
    name: "contracts-singleItemService-contract_id-main"
  }, {
    path: "/estimate/attendance/:estimate_id?/main",
    component: _4123cd71,
    name: "estimate-attendance-estimate_id-main"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/confirmation",
    component: _9d9fcf8e,
    name: "estimate-comingOfAgeCeremony-estimate_id-confirmation"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/creationCompleted",
    component: _55c74658,
    name: "estimate-comingOfAgeCeremony-estimate_id-creationCompleted"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/estimateList",
    component: _0dbe7d2c,
    name: "estimate-comingOfAgeCeremony-estimate_id-estimateList"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/complete",
    component: _fdc07550,
    name: "estimate-graduationCeremony-estimate_id-complete"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/confirmation",
    component: _6efae6d4,
    name: "estimate-graduationCeremony-estimate_id-confirmation"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/estimateList",
    component: _9228dff6,
    name: "estimate-graduationCeremony-estimate_id-estimateList"
  }, {
    path: "/estimate/singleItemService/:estimate_id?/main",
    component: _3747b02e,
    name: "estimate-singleItemService-estimate_id-main"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/bag2",
    component: _d2318430,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-bag2"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/bag3",
    component: _d215552e,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-bag3"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/betsueri",
    component: _486467f2,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-betsueri"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/customerName",
    component: _58988a72,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-customerName"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/customerNumber",
    component: _09d85385,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-customerNumber"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/discount",
    component: _41d07102,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-discount"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/employeeDiscount",
    component: _83283166,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-employeeDiscount"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/furisode",
    component: _827fd53e,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-furisode"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/JkPhotoInAdvance",
    component: _5728f00a,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-JkPhotoInAdvance"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/kamikazari",
    component: _7e9a2824,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-kamikazari"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/kamikazari2",
    component: _54c285be,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-kamikazari2"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/kamikazari3",
    component: _54d09d3f,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-kamikazari3"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/kasaneeri",
    component: _749bfb53,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-kasaneeri"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/kasaneeri2",
    component: _1efb186f,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-kasaneeri2"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/kasaneeri3",
    component: _1f092ff0,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-kasaneeri3"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/motherAndDaughterPlan",
    component: _0b87e237,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-motherAndDaughterPlan"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/obi",
    component: _63cacca8,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-obi"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/obiage",
    component: _5c4a8007,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-obiage"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/obiage2",
    component: _2d1d2a3b,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-obiage2"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/obiage3",
    component: _2d2b41bc,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-obiage3"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/obijime",
    component: _4e49a83f,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-obijime"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/option",
    component: _b7e61b9a,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-option"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/otherItem",
    component: _24211bf5,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-otherItem"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/photo",
    component: _542bc164,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-photo"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/plan",
    component: _f7a6a732,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-plan"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/products",
    component: _084b4bbc,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-products"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/remarks",
    component: _6d6ee925,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-remarks"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/shawl",
    component: _31561433,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-shawl"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/sisterDiscount",
    component: _55da697a,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-sisterDiscount"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/tashinuno",
    component: _e29ad5be,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-tashinuno"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/twinsDiscount",
    component: _4c13d11e,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-twinsDiscount"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/wPhotoPlan",
    component: _65ba7102,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-wPhotoPlan"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/zori2",
    component: _7e921ab8,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-zori2"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/zori3",
    component: _7ea03239,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-zori3"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/tab/zoriBag",
    component: _72246024,
    name: "estimate-comingOfAgeCeremony-estimate_id-tab-zoriBag"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/tab/bikou",
    component: _a2171f3e,
    name: "estimate-graduationCeremony-estimate_id-tab-bikou"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/tab/furisode",
    component: _7c8ae3fc,
    name: "estimate-graduationCeremony-estimate_id-tab-furisode"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/tab/hakama",
    component: _579ec1fc,
    name: "estimate-graduationCeremony-estimate_id-tab-hakama"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/tab/nishakusode",
    component: _5af2ae62,
    name: "estimate-graduationCeremony-estimate_id-tab-nishakusode"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/tab/okyakusamaKensaku",
    component: _7befec9a,
    name: "estimate-graduationCeremony-estimate_id-tab-okyakusamaKensaku"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/tab/option",
    component: _07e0ab8e,
    name: "estimate-graduationCeremony-estimate_id-tab-option"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/tab/oshashinPlan",
    component: _2adce239,
    name: "estimate-graduationCeremony-estimate_id-tab-oshashinPlan"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/tab/otherItem",
    component: _1fb020ba,
    name: "estimate-graduationCeremony-estimate_id-tab-otherItem"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/tab/plan",
    component: _a675fefc,
    name: "estimate-graduationCeremony-estimate_id-tab-plan"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/tab/shichakuShashin",
    component: _8b03172a,
    name: "estimate-graduationCeremony-estimate_id-tab-shichakuShashin"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/tab/shouhinSettei",
    component: _12beea3f,
    name: "estimate-graduationCeremony-estimate_id-tab-shouhinSettei"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/tab/waribiki",
    component: _4649d43f,
    name: "estimate-graduationCeremony-estimate_id-tab-waribiki"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/tab/betsueri/bringin-kimono",
    component: _29e6a1d4,
    name: "estimate-graduationCeremony-estimate_id-tab-betsueri-bringin-kimono"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/tab/betsueri/rental-kimono",
    component: _568935dd,
    name: "estimate-graduationCeremony-estimate_id-tab-betsueri-rental-kimono"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/tab/hanhabaobi/bringin-hakama",
    component: _6ef20e94,
    name: "estimate-graduationCeremony-estimate_id-tab-hanhabaobi-bringin-hakama"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/tab/hanhabaobi/rental-hakama",
    component: _668d2382,
    name: "estimate-graduationCeremony-estimate_id-tab-hanhabaobi-rental-hakama"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/tab/kinchakuBag/bringin-kimono",
    component: _b2b18f26,
    name: "estimate-graduationCeremony-estimate_id-tab-kinchakuBag-bringin-kimono"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/tab/kinchakuBag/rental-kimono",
    component: _187ee824,
    name: "estimate-graduationCeremony-estimate_id-tab-kinchakuBag-rental-kimono"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/tab/zoriBoots/bringin-kimono",
    component: _04cb5a6c,
    name: "estimate-graduationCeremony-estimate_id-tab-zoriBoots-bringin-kimono"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/tab/zoriBoots/rental-kimono",
    component: _2c7ee3b2,
    name: "estimate-graduationCeremony-estimate_id-tab-zoriBoots-rental-kimono"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/tab/kasaneeri/rental-kimono/1",
    component: _7c5076f5,
    name: "estimate-graduationCeremony-estimate_id-tab-kasaneeri-rental-kimono-1"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/tab/kasaneeri/bringin-kimono/:number?",
    component: _666d8712,
    name: "estimate-graduationCeremony-estimate_id-tab-kasaneeri-bringin-kimono-number"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/tab/kasaneeri/rental-kimono/:moreThan1?",
    component: _0d53b770,
    name: "estimate-graduationCeremony-estimate_id-tab-kasaneeri-rental-kimono-moreThan1"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/tab/kamikazari/:number?",
    component: _1b61b450,
    name: "estimate-graduationCeremony-estimate_id-tab-kamikazari-number"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/confirmationContract/:id?",
    component: _dd75d8d4,
    name: "estimate-comingOfAgeCeremony-estimate_id-confirmationContract-id"
  }, {
    path: "/estimate/comingOfAgeCeremony/:estimate_id?/estimateListContract/:id?",
    component: _d0d70972,
    name: "estimate-comingOfAgeCeremony-estimate_id-estimateListContract-id"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/confirmationContract/:id?",
    component: _6ec05c9e,
    name: "estimate-graduationCeremony-estimate_id-confirmationContract-id"
  }, {
    path: "/estimate/graduationCeremony/:estimate_id?/estimateListContract/:id?",
    component: _62218d3c,
    name: "estimate-graduationCeremony-estimate_id-estimateListContract-id"
  }, {
    path: "/userInfo/:id",
    component: _12f4753c,
    name: "userInfo-id"
  }, {
    path: "/userInfo/:id?/contract/:contract_id",
    component: _282e7c73,
    name: "userInfo-id-contract-contract_id"
  }, {
    path: "/userInfo/:id?/estimate/:estimate_id?",
    component: _56336878,
    name: "userInfo-id-estimate-estimate_id"
  }, {
    path: "/userInfo/:id?/contract/:contract_id?/cancel",
    component: _244afb29,
    name: "userInfo-id-contract-contract_id-cancel"
  }, {
    path: "/",
    component: _47388426,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
