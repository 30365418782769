

















import {
  computed,
  defineComponent,
  ref,
  useRouter,
  watch,
} from '@nuxtjs/composition-api'
import BasicModal from '~/components/_graduationCeremony/molecules/modals/BasicModal.vue'

import { useContractTypeFlags } from '~/composable/contracts/ContractTypeFlags'

export default defineComponent({
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['save', 'move', 'moveWithoutSave'],
  components: { BasicModal },
  setup(props, { emit }) {
    const router = useRouter()

    // 契約更新中かどうかの判定に使用
    const { in卒業式成人式契約 } = useContractTypeFlags()
    const path = router.currentRoute.fullPath

    // 契約更新フロー中かどうか
    const isEditMode = computed(() => {
      return in卒業式成人式契約.value
        ? path.includes('contract_id')
        : !path.includes('/0/') && path.includes('contract')
    })

    const isVisible = ref(props.showModal)
    watch(props, () => {
      isVisible.value = props.showModal
    })

    watch(isVisible, (newVal) => {
      if (newVal) return
      emit('closeModal')
    })

    const openModal = () => {
      isVisible.value = true
    }

    const clickExecuteButton = () => {
      emit(isEditMode.value ? 'move' : 'save')
      isVisible.value = false
    }

    const moveWithoutSave = () => {
      emit('moveWithoutSave')
      isVisible.value = false
    }

    return {
      openModal,
      moveWithoutSave,
      isVisible,
      clickExecuteButton,
      isEditMode,
    }
  },
})
