import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { AccessoryPlanState } from '~/store/types'

export const useRestore帯締め = () => {
  const store = useComingStore<AccessoryPlanState>('Obijime')

  const restore帯締め = (
    obijime: Restate['items']['帯締め'],
    plan: Restate['plan']
  ) => {
    if (!plan || !obijime) return

    /* 初期値を設定 ※必ず入力される値はここで設置 */
    const formValues: AccessoryPlanState = {
      /**
       * NOTE: selections = patternLine
       * ↓保持される選択肢のindex
       * [0]種別
       * [1]形態 || 見積表示
       */
      selections: [obijime.optionId種別, 0],
      input: {
        productName: '',
        buyPrice: 0,
        rentalPrice: 0,
        rankUpRentalPrice: 0,
        rankUpBuyPrice: 0,
        premiumRentalPrice: 0,
        premiumBuyPrice: 0,
      },
      // NOTE: フリー入力された値はpricesではなくinputの方に格納される
      price: {
        buy: 0,
        rental: 0,
        id: 0, // フリー入力を選択
      },
      isFree: false,
      isValidate: null,
    }

    /* 以下で入力内容の条件に基づき、必要な項目を埋めていく */
    if (plan.flags.is振袖持込 && !obijime.flags.is持込) {
      formValues.selections[1] = obijime.optionId見積選択
      formValues.input.rentalPrice = obijime.レンタル価格
      formValues.input.buyPrice = obijime.購入価格
    } else if (!plan.flags.is振袖持込 && !obijime.flags.is持込) {
      formValues.selections[1] = obijime.optionId形態
    }

    if (obijime.flags.isセット内) {
      formValues.price.rental = obijime.レンタル価格
      formValues.input.rentalPrice = obijime.レンタル価格
      if (plan.flags.isフルセットプラン) {
        formValues.price.buy = obijime.購入価格
        formValues.input.buyPrice = obijime.購入価格
      }
    }

    if (obijime.flags.isランクアップPP内) {
      formValues.isFree = true
      formValues.input.rankUpRentalPrice = obijime.レンタル価格
      if (plan.flags.isフルセットプラン) {
        formValues.input.rankUpBuyPrice = obijime.購入価格
      }
    }

    if (obijime.flags.isランクアップPP外) {
      formValues.isFree = true
      formValues.input.rankUpRentalPrice = obijime.レンタル価格
      formValues.input.premiumRentalPrice = obijime.プレミアムレンタル価格
      if (plan.flags.isフルセットプラン) {
        formValues.input.rankUpBuyPrice = obijime.購入価格
        formValues.input.premiumBuyPrice = obijime.プレミアム購入価格
      }
    }

    /* ストアに内容を保存する */
    store.setOf<AccessoryPlanState['selections']>(
      'setSelection',
      formValues.selections
    )
    store.setOf<AccessoryPlanState['input']>('setInput', formValues.input)
    store.setOf<AccessoryPlanState['price']>('setPrice', formValues.price)
    store.setOf<AccessoryPlanState['isFree']>('setIsFree', formValues.isFree)
    store.setIsValidate(false)
  }

  return { restore帯締め }
}
