import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber'

// 生年月日を「yyyy年mm月dd日(○歳)」に整形
export const birthday = (birthday: string) => {
  // NOTE：旧見積APIには存在せず
  if (!birthday) {
    return ''
  }
  const birth = birthday.split('-')
  const yy = birth[0]
  const mm = birth[1]
  const dd = birth[2]

  const age = ageCalculation(new Date(`${yy}/${mm}/${dd}`), new Date())
  return `${yy}年${mm}月${dd}日（${age}歳）`
}

/**
 * yyyymmdd形式の生年月日を「yyyy年mm月dd日(○歳)」に整形
 */
export const formatBirthdayByOnlyNumber = (date: string) => {
  if (date.length !== 8) {
    // lengthが8以下の場合、表示できない
    return ''
  }
  const yy = date.substring(0, 4)
  const mm = date.substring(4, 6)
  const dd = date.substring(6)

  const age = ageCalculation(new Date(`${yy}/${mm}/${dd}`), new Date())
  return `${yy}年${mm}月${dd}日（${age}歳）`
}

// 生年月日から年齢を計算する
export const ageCalculation = (birthDate: Date, nowDate: Date) => {
  const age = nowDate.getFullYear() - birthDate.getFullYear()
  const thisYearsBirthday = new Date(
    nowDate.getFullYear(),
    birthDate.getMonth(),
    birthDate.getDate()
  )
  return age + (thisYearsBirthday.getTime() > nowDate.getTime() ? -1 : 0)
}

// 氏名を整形
export const customerName = (customer: any) => {
  return `${customer.last_name ?? ''}${customer.first_name ?? ''}（${
    customer.last_name_kana ?? ''
  }${customer.first_name_kana ?? ''}）`
}
// 氏名を漢字のみ表示
export const customerNameKanji = (customer: any) => {
  return `${customer.last_name} ${customer.first_name}`
}
// 氏名をカタカナのみ表示
export const customerNameKana = (customer: any) => {
  return `${customer.last_name_kana} ${customer.first_name_kana}`
}
// 住所①を整形
export const customerHomeAddress = (customer: any) => {
  return `〒${customer.home_zip_code ?? ''} ${customer.home_prefectures ?? ''}${
    customer.home_address1 ?? ''
  }${customer.home_address2 ?? ''}`
}

//郵便番号にハイフンを自動挿入するメソッド
const insertStr = (input: string) => {
  return input.slice(0, 3) + '-' + input.slice(3, input.length)
}

/**
 * 住所②を整形
 * @param customer.zip_code
 * @param customer.prefectures
 * @param customer.address1
 * @param customer.address2
 */
export const customerAddress = (customer: any) => {
  const zipCode = customer.zip_code ? '〒' + insertStr(customer.zip_code) : ''
  return `${zipCode} ${customer.prefectures ?? ''}${customer.address1 ?? ''}${
    customer.address2 ?? ''
  }`
}

// 続柄を文字列に整形
export const familyRole = (familyRole: string) => {
  if (familyRole === '1') return '父'
  if (familyRole === '2') return '母'
  if (familyRole === '11') return '長女'
  if (familyRole === '12') return '二女'
  if (familyRole === '13') return '三女'
  if (familyRole === '14') return '四女'
  if (familyRole === '15') return '五女'
  if (familyRole === '16') return '六女以降'
  if (familyRole === '21') return '長男'
  if (familyRole === '22') return '二男'
  if (familyRole === '23') return '三男'
  if (familyRole === '24') return '四男'
  if (familyRole === '25') return '五男'
  if (familyRole === '26') return '六男以降'
  if (familyRole === '31') return '夫'
  if (familyRole === '32') return '娘'
  if (familyRole === '33') return '息子'
  if (familyRole === '98') return 'その他（女性）'
  if (familyRole === '99') return 'その他（男性）'
}

export default class CustomerSummaryUtil {
  public static formatTelNumber(value: string) {
    try {
      const region = 'JP'
      const util = PhoneNumberUtil.getInstance()

      // 番号と地域を設定
      const number = util.parseAndKeepRawInput(value, region)

      // 電話番号の有効性チェック
      if (!util.isValidNumberForRegion(number, region)) {
        return null
      }

      // ハイフン付きの形式で返却
      return util.format(number, PhoneNumberFormat.NATIONAL)
    } catch (error) {
      return null
    }
  }
}
