import { Hakama } from '~/api/estimateRepository'
import { EstimateState } from '~/composable/estimate/repository/graduation/EstimateState'

export const useHakamaFormatter = (estimateState: EstimateState) => {
  const getFormattedHakama = () => {
    const { レンタル価格, 商品検索, 商品分類, 品種区分, 袴割引, flags } =
      estimateState.value.袴

    if (!商品分類 && !flags.is袴保留) {
      throw new Error('hakama.商品の分類（レンタルやお持込）がありません')
    }

    const hakama: Hakama = {
      product_id: 商品検索?.product_id ?? undefined,
      catalog_code: 商品検索?.catalog_code ?? undefined,
      product_name: 商品検索?.product_name || '-',
      rental_price: レンタル価格 ?? 0,
      // NOTE:保留時のみnullで来るので型エラーを防ぐためこうしているが、0で見積作成されることはない想定
      category: 商品分類 ?? 0,
      product_type_id: 品種区分,
      discount_type: 袴割引 ?? undefined,
    }
    return hakama
  }

  return { getFormattedHakama }
}
