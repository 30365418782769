import { Context } from '@nuxt/types'
import createPersistedState from 'vuex-persistedstate'

export default ({ store }: Context) => {
  createPersistedState({
    key: 'vuex-persistedstate',
    paths: [
      'LoginUser',
      'SettingShop',
      'Contracts',
      'SingleItemService',
      'Estimate',
      'FrontStoreInfo',
      'PriceTable',
      'Checklists',
      'CustomerStoreInfo',
    ],
    storage: window.sessionStorage,
  })(store)

  // 別タブで開いた場合のためshopデータだけlocalStorageで保持
  createPersistedState({
    key: 'vuex-persistedstate',
    paths: ['SettingShop'],
    storage: localStorage,
  })(store)
}
