import { GetterTree } from 'vuex'
import { FormValues割引 } from '~/composable/estimate/viewModel/comingOfAge/Fields/Fields割引'
import { RootState } from '~/store/Estimate/GraduationCeremony/types'
import { State } from './state'

const getters: GetterTree<State, RootState> = {
  getState: (state) => (stateName: keyof FormValues割引) => {
    return state.fields[stateName]
  },

  getIsValidate: (state) => {
    return state.isValidate
  },

  getAllState: (state) => {
    return state.fields
  },
}
export default getters
