import { ref, computed, useStore } from '@nuxtjs/composition-api'
import { usePrice } from '~/composable/general/Price'
import { useTomonokaiFlag } from '~/composable/estimate/domain/Graduation/TomonokaiFlag'
import { useEstimateState } from '~/composable/estimate/repository/graduation/EstimateState'
import { useUrlNavigator } from '~/composable/estimate/service/graduation/UrlNavigator'
import { State as PriceTableState } from '@/store/PriceTable/state'

export const use着物見積 = () => {
  const state = useEstimateState()
  const store = useStore()
  const storePriceTable: PriceTableState['prices'] =
    store.getters['PriceTable/getPrices']
  const { isTomonokai } = useTomonokaiFlag()
  const { getDiscounted } = usePrice()
  const paths = useUrlNavigator()

  const 振袖二尺袖 = computed(() => {
    let discountingPrice: number | null = null
    let price: number | null = null
    let priceNote: string = ''

    if (
      isTomonokai.value &&
      state.value.振袖二尺袖.選択着物名 === '振袖' &&
      !state.value.振袖二尺袖.flags.isお持込
    ) {
      priceNote = '友の会振袖割引'
      price = state.value.振袖二尺袖.レンタル価格
    } else if (state.value.振袖二尺袖.flags.is80Off) {
      priceNote = '本体価格'
      price = state.value.振袖二尺袖.振袖本体価格 || 0
      discountingPrice = getDiscounted(
        price,
        storePriceTable.estimate.割引率.成人式ご利用割引振袖
      )
    } else {
      price = state.value.振袖二尺袖.レンタル価格
    }

    const productName = () => {
      if (state.value.振袖二尺袖.flags.isお持込) return ''
      if (state.value.振袖二尺袖.flags.is振袖) {
        if (state.value.振袖二尺袖.flags.is振袖保留) return '仮設定中'
        return state.value.振袖二尺袖.着物検索?.product_name ?? '-'
      } else if (state.value.振袖二尺袖.flags.is二尺袖) {
        if (state.value.振袖二尺袖.flags.is二尺袖保留) return '仮設定中'
        return state.value.振袖二尺袖.着物検索?.product_name ?? '-'
      }
    }

    return {
      振袖割引: {
        title: '成人式ご利用振袖割引欄',
        price: discountingPrice,
        discount: !!discountingPrice,
        productTypeId: null,
        isInsertDailyReport: true,
      },
      着物: {
        title: state.value.振袖二尺袖.選択着物名,
        productTypeId: state.value.振袖二尺袖.品種区分,
        product: productName(),
        price,
        priceNote,
        label: state.value.振袖二尺袖.ラベル,
        url: paths.tab振袖二尺袖,
      },
    }
  })

  return { 振袖二尺袖 }
}
