import { computed, Ref, watch } from '@nuxtjs/composition-api'
import { EstimateDetail, WPhotoHurisode } from '~/api/estimateRepository'
import {
  KimonoSearchQueryParams,
  useSearchProduct,
} from '~/composable/api/useSearchProduct'
import ProductClassification from '~/constants/enums/productClassification'
import { WPHOTO_FURISODE_PLAN } from '~/constants/estimate/comingOfAge'

export const useRestateWPhoto振袖 = (
  estimate: Ref<EstimateDetail | undefined>
) => {
  const { fetchProducts, finishedKimonoList } = useSearchedProducts()

  // NOTE: computed内で取得処理をしようとすると無限ループが起きる
  watch(estimate, () => {
    if (!estimate.value?.w_photo_plan?.hurisode) return
    fetchProducts(estimate.value.w_photo_plan.hurisode)
  })

  return computed(() => {
    const hurisode = estimate.value?.w_photo_plan?.hurisode
    if (!hurisode) return
    const { product_id, rental_price, product_name } = hurisode
    const isお持込 = !product_id

    return {
      optionsIdレンタルorお持込: isお持込
        ? WPHOTO_FURISODE_PLAN.BRING_IN
        : WPHOTO_FURISODE_PLAN.RENTAL,
      optionId裄補正: getOptionIdsOf補正(hurisode).裄補正,
      optionId袖補正: getOptionIdsOf補正(hurisode).袖補正,
      商品分類: ProductClassification.hurisode,
      商品名: product_name,
      仕立て上り検索結果: finishedKimonoList.value,
      レンタル価格: Number(rental_price),
      isお持込,
    }
  })
}

const getOptionIdsOf補正 = (hurisode: WPhotoHurisode) => {
  return {
    裄補正: hurisode.is_correct_for_yukitake
      ? WPHOTO_FURISODE_PLAN.YUKI_CORRECTION
      : WPHOTO_FURISODE_PLAN.NOT_YUKI_CORRECTION,
    袖補正: hurisode.is_correct_for_sodetake
      ? WPHOTO_FURISODE_PLAN.SODE_CORRECTION
      : WPHOTO_FURISODE_PLAN.NOT_SODE_CORRECTION,
  }
}
/**
 * 振袖と二尺袖の商品検索結果を取得する
 * NOTE: ひとまずパラメータにある値から仕立て上りとカタログ検索両方叩いて、
 * どちらを使うかはrestorer側で判定する。
 */
const useSearchedProducts = () => {
  const {
    fetchCatalogFurisode,
    catalogFurisode,
    fetchFinishedKimonoList,
    finishedKimonoList,
  } = useSearchProduct()

  const fetchProducts = (hurisode: WPhotoHurisode) => {
    if (hurisode.catalog_code) {
      const params = {
        catalog_code: hurisode.catalog_code,
      } as const
      fetchCatalogFurisode(params)
    }

    if (hurisode.product_id) {
      const params: KimonoSearchQueryParams = {
        keyword: hurisode.product_id,
        keyword_types: [0],
        product_type_id: ProductClassification.hurisode,
        has_purchased: 1,
      }
      fetchFinishedKimonoList(params)
    }
  }

  return { fetchProducts, catalogFurisode, finishedKimonoList }
}
