import { GetterTree } from 'vuex'
import { RootState } from '~/store/Estimate/GraduationCeremony/types'

// ** 型 **
import type { State, ProductNameList } from './state'

const getters: GetterTree<State, RootState> = {
  getItemPrices: (state) => {
    return state.itemPrices
  },

  getKimonoPrices: (state) => {
    return state.kimonoPrices
  },

  getProducts: (state) => (productName: ProductNameList) => {
    return state.products[productName]
  },

  getSetOfProducts: (state) => {
    return state.setOfProducts
  },
}
export default getters
