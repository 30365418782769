import { PhotoInAdvanceState } from '../../types'

const state = (): PhotoInAdvanceState => ({
  patternLine: [0],
  input: {
    dateOfTakingPhotoInAdvance: '', // 前撮り使用日
    placeOfDressing: '', // 着付場所
    timeOfEnteringDressPlace: '', // 着付場所入場時間
    timeOfEnteringSalon: '', // 美容室入場時間
    remark: '', // 備考
    prefecture: '',
    nameOfSalon: '', // 美容室名
    telOfSalon: '', // 美容室TEL
    salonId: 0,
    shopId: null,
    dateOfGivingPhotoInAdvance: '', // 前撮りお渡し日
    placeOfGivingPhotoInAdvance: '', // 前撮りお渡し場所
    itemSelection: '',
    poseCount: 0, //ポーズ数
    bookCount: 0, //冊数
    selectedPlanType: null,
    needMakeup: true, // 初期値は「はい」
  },
  shop: {
    shopId: 0,
    shopName: '',
  },
  dressingPlaces: {
    id: null, // 着付け会場ID
    name: null, // 着付け会場名
    dressingAddressInfo: null, // 着付会場住所
  },
  isValidate: null,
})

export default state
