import { MutationTree } from 'vuex'
import { OnlyTextState } from '../../../types'

const mutations: MutationTree<OnlyTextState> = {
  setText: (state, text) => {
    state.text = text
  },

  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },

  delete: (state) => {
    state.text = ''
    state.isValidate = null
  },
}

export default mutations
