















































import {
  defineComponent,
  ref,
  useRouter,
  useRoute,
  computed,
  useStore,
  useContext,
} from '@nuxtjs/composition-api'
import IconHeaderLogo from '~/components/icons/IconHeaderLogo.vue'
import TestEnvironmentNotice from '~/components/TestEnvironmentNotice.vue'
import TheDialogBeforeMoveConfirmation from '~/components/graduationCeremony/molecules/TheDialogBeforeMoveConfirmation.vue'
import TheDialogTempSave from '@/components/TheDialogTempSave.vue'
import HeaderMenuItem from '@/components/graduationCeremony/organisms/HeaderMenuItem.vue'
import { useEstimateTypeFlags } from '~/composable/estimate/domain/EstimateTypeFlags'
import { useTemporarilySaved } from '~/composable/api/useTemporarilySaved'
import { ESTIMATE } from '~/constants/estimate/config'
import { BaseEstimateState } from '~/store/Contracts/types'
import { useContractTypeFlags } from '~/composable/contracts/ContractTypeFlags'
import { Tab } from '~/store/Contracts/Attendance/Main/state'
import { usePageFlags } from '~/composable/estimate/domain/PageFlags'
import { getEnv } from '~/utils/utils'

const MODAL_ID = {
  LOGO: 1,
  MENU: 2,
}

export default defineComponent({
  components: {
    IconHeaderLogo,
    TestEnvironmentNotice,
    TheDialogBeforeMoveConfirmation,
    HeaderMenuItem,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const activeMenu = ref(false)
    const url = route.value.path
    const { saveEstimateTemporarily, saveContractTemporarily } =
      useTemporarilySaved()
    const { in参列見積 } = useEstimateTypeFlags()
    const { in参列契約 } = useContractTypeFlags()
    const { inCompletePage: in成人式卒業式見積完了画面 } = usePageFlags()
    const { $config } = useContext()
    const environmentType = getEnv($config.BASE_URL)

    // ロゴ or メニューどちらが押下されたかの判定
    const modalId = ref(0)

    const dialogTempSave = ref<InstanceType<typeof TheDialogTempSave> | null>()

    const estimateType: BaseEstimateState['estimate']['type'] =
      store.getters['Contracts/BaseEstimate/getEstimateType']

    // 参列、クリーニング用
    const getIsCompletedTab = (
      createType: 'Estimate' | 'Contracts',
      serviceType: 'Attendance' | 'Cleaning'
    ) => {
      const lastTabList: Tab[] =
        store.getters[`${createType}/${serviceType}/Main/getFinalPageList`]
      const selectedText =
        createType === 'Estimate' ? '見積作成完了' : '契約作成完了'
      const isCompletedTab = !!lastTabList.find(
        ({ text }) => text === selectedText
      )?.isCurrent

      return isCompletedTab
    }

    const getIs参列クリーニング契約作成完了画面 = () => {
      // 参列契約の完了画面かどうか
      const isAttendanceCompleted = getIsCompletedTab('Contracts', 'Attendance')

      // クリーニング契約の完了画面かどうか
      const isCleaningCompleted = getIsCompletedTab('Contracts', 'Cleaning')

      return in参列契約.value ? isAttendanceCompleted : isCleaningCompleted
    }

    const getIs見積完了画面 = () => {
      // 参列見積の完了画面かどうか
      const isAttendanceCompleted = getIsCompletedTab('Estimate', 'Attendance')
      return (
        (in参列見積.value && isAttendanceCompleted) ||
        in成人式卒業式見積完了画面.value
      )
    }

    // 確認モーダルを表示するかの判定（見積作成画面・契約書作成画面であれば表示）
    const isShowConfirmation = computed(() => {
      const show = /\/estimate\/|\/contracts\//

      // 見積一覧・完了画面では表示させない
      const notShow = /saveList|Completed|complete/
      return show.test(url) && !notShow.test(url)
    })

    // NOTE: 確認モーダルの表示の切り替えフラグ
    const showConfirmModal = ref(false)

    // ロゴ押下時の処理
    const handleClickedLogo = (e: Event) => {
      // NOTE: aタグとしてのリンクの機能はいらないため止めている。
      e.preventDefault()

      if (
        isShowConfirmation.value &&
        !getIs参列クリーニング契約作成完了画面() &&
        !getIs見積完了画面()
      ) {
        modalId.value = MODAL_ID.LOGO
        showConfirmModal.value = true
      } else {
        router.push('/')
      }
    }

    // メニュー押下時の処理
    const toggleMenu = () => {
      // メニューが開いている場合は閉じる
      if (activeMenu.value) {
        activeMenu.value = false
        return
      }

      if (
        isShowConfirmation.value &&
        !getIs参列クリーニング契約作成完了画面() &&
        !getIs見積完了画面()
      ) {
        modalId.value = MODAL_ID.MENU
        showConfirmModal.value = true
      } else {
        activeMenu.value = !activeMenu.value
      }
    }

    // モーダル内「移動」押下時の処理
    const move = () => {
      router.push('/')
    }

    // モーダル内「保存」押下時の処理
    // TODO ここにサービスを追加した際にはTheDialogBeforeMoveConfirmationにも追加必要
    const save = () => {
      // 見積書仮保存
      if (url.match('/estimate/comingOfAgeCeremony/')) {
        saveEstimateTemporarily(ESTIMATE.成人式.id)
      }

      if (url.match('/estimate/graduationCeremony/')) {
        saveEstimateTemporarily(ESTIMATE.卒業式.id)
      }

      //参列 見積書
      if (url.match('/estimate/attendance/')) {
        saveEstimateTemporarily(ESTIMATE.参列.id)
      }

      //単品　見積書
      if (url.match('/estimate/singleItemService/')) {
        saveEstimateTemporarily(ESTIMATE.単品.id)
      }

      // 契約書仮保存
      // NOTE: 成人式と卒業式のみ仮保存するようにしている
      if (
        (url.match(/contracts/) && estimateType === 1) ||
        estimateType === 2
      ) {
        saveContractTemporarily(estimateType)
      }

      //参列 契約書
      if (url.match('/contracts/attendance/')) {
        saveContractTemporarily(ESTIMATE.参列.id)
      }

      // クリーニング 契約書
      if (url.match('/contracts/cleaning/')) {
        saveContractTemporarily(ESTIMATE.クリーニング.id)
      }

      // 単品 契約書
      if (url.match('/contracts/singleItemService/')) {
        saveContractTemporarily(ESTIMATE.単品.id)
      }

      if (modalId.value === MODAL_ID.MENU) {
        activeMenu.value = true
      } else {
        dialogTempSave.value?.openModal()
      }
    }

    // モーダル内「保存せずに移動」押下時の処理
    const moveWithoutSave = () => {
      // 仮保存処理を行わない
      store.commit(`FrontStoreInfo/setFrontStoreFlg`, false)

      if (modalId.value === MODAL_ID.LOGO) {
        router.push('/')
      }
      if (modalId.value === MODAL_ID.MENU) {
        activeMenu.value = true
      }
    }

    return {
      activeMenu,
      toggleMenu,
      handleClickedLogo,
      save,
      move,
      moveWithoutSave,
      dialogTempSave,
      showConfirmModal,
      environmentType,
    }
  },
})
