import { MutationTree } from 'vuex'
import { BaseEstimateState } from '../types'

const mutations: MutationTree<BaseEstimateState> = {
  setEstimate: (state, estimate) => {
    state.estimate = estimate
  },

  setIsNewEstimate: (state, isNewEstimate) => {
    state.isNewEstimate = isNewEstimate
  },

  setCustomerProfile: (state, profile) => {
    state.customerProfile = profile
  },

  setContractId: (state, contractId) => {
    state.contractId = contractId
  },

  setBeforeContractDate: (state, beforeContractDate) => {
    state.beforeContractDate = beforeContractDate
  },

  delete: (state) => {
    state.estimate = {}
    state.isNewEstimate = false
    state.customerProfile = null
    state.contractId = null
    state.beforeContractDate = null
  },
}

export default mutations
