export const initialState = {
  data: { changePriceType: 2, isValidate: false },
}

export type State = typeof initialState

export const getInitialStore = (): State => initialState

const state = () => getInitialStore()

export default state
