





















import { defineComponent, ref, onMounted } from '@nuxtjs/composition-api'
import IconX from './icons/IconX.vue'

export default defineComponent({
  components: {
    IconX,
  },
  props: {
    defaultOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['open', 'close'],
  setup(props, { emit }) {
    const isVisible = ref(false)
    // 背後をスクロールできないようにする
    // https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
    let scrollY: number
    const fixBody = () => {
      document.body.style.overflow = 'hidden'
      document.body.style.width = '100%'
      scrollY = window.scrollY
    }

    // 背後をスクロール可能な状態に戻す
    const unfixBody = () => {
      document.body.style.overflow = 'auto'
      document.body.style.width = ''
      window.scrollTo(0, scrollY)
    }

    const open = () => {
      fixBody()
      isVisible.value = true
      emit('open')
    }

    const close = () => {
      unfixBody()
      isVisible.value = false
      emit('close')
    }

    const toggle = () => (isVisible.value ? close() : open())

    onMounted(() => {
      scrollY = window.scrollY
      if (props.defaultOpen) open()
    })

    return { isVisible, open, close, toggle }
  },
})
