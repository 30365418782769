import { GetterTree } from 'vuex'
import { RootState, MeasuringState } from '../../types'

const getters: GetterTree<MeasuringState, RootState> = {
  getInput: (state: MeasuringState) => {
    return state.input
  },

  getReasonInput: (state: MeasuringState) => {
    // WORNING: 契約書復元時に`reasonInput`が存在しない契約書がある
    return state.reasonInput ?? ''
  },

  getInputGraduation: (state: MeasuringState) => {
    return state.inputGraduation
  },

  getCustomerInfo: (state: MeasuringState) => {
    return state.customerInfo
  },

  getGraduationHurisodeInfo: (state: MeasuringState) => {
    return state.graduationHurisodeInfo
  },

  getWPhotoHurisodeInfo: (state: MeasuringState) => {
    return state.wPhotoHurisodeInfo
  },

  getIsValidate: (state: MeasuringState) => {
    return state.isValidate
  },
}

export default getters
