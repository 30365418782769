import { WphotoState } from '../../../types'

const state = (): WphotoState => ({
  /**
   * NOTE: selections = patternLine
   * ↓保持される選択肢のindex
   * [0]Wフォトプランを適用するか
   */
  selections: [0],
  isValidate: null,
  isSubValidate: null,
})

export default state
