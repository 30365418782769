import { computed, useStore } from '@nuxtjs/composition-api'
import {
  FrontStoreContractState,
  FrontStoreEstimateState,
  FrontStoreRepository,
  ContractTypeId,
} from '~/api/frontStoreRepository'

// WORNING: このtypeを変更する際は「仮保存リスト」と「仮保存復元処理」に影響するため注意が必要
export type FrontState = {
  Contracts: Record<string, unknown>
  Estimate: {
    ComingOfAgeCeremony: Record<string, unknown>
    GraduationCeremony: Record<string, unknown>
    Attendance: Record<string, unknown>
  }
  SingleItemService: Record<string, unknown>
}

const frontStoreService = (repo: FrontStoreRepository) => {
  const store = useStore()
  const frontStoreId = computed(() =>
    store.getters['FrontStoreInfo/getState']('frontStoreId')
  )

  /**
   * 見積書の一次保存
   * @param contractType
   * @param body
   * @param shopId
   * @returns
   */
  const saveEstimateTemporarily = async (
    contractType: 1 | 2 | 3 | 7,
    body: FrontStoreEstimateState,
    shopId: number
  ) => {
    try {
      const response = await repo.update({
        contractType,
        documentType: 1,
        shopId,
        body,
        frontStoreId: frontStoreId.value.estimate,
      })

      return response.id
    } catch (error) {
      console.error(error)
      return new Error('仮保存に失敗しました。')
    }
  }

  const saveContractTemporarily = async (
    contractType: 1 | 2 | 3 | 7 | 10,
    body: FrontStoreContractState,
    shopId: number
  ) => {
    try {
      const response = await repo.update({
        contractType,
        documentType: 2,
        shopId,
        body,
        frontStoreId: frontStoreId.value.contract,
      })

      return response.id
    } catch (error) {
      console.error(error)
      return new Error('仮保存に失敗しました。')
    }
  }

  return {
    saveEstimateTemporarily,
    saveContractTemporarily,
  }
}

export default frontStoreService
