import { FormValues袴 } from '~/composable/estimate/viewModel/graduation/Fields/Fields袴'

//不明な点がある場合、READMEをご覧ください
export const getInitialStore = (): FormValues袴 => {
  return {
    hakamaIsカタログ商品: null,
    hakama身長: null,
    hakama商品検索: null,
    hakama商品情報_価格: null,
    hakama商品情報_価格入力: null,
    hakamaカタログレンタル価格: null,
    hakamaカタログ検索: null,
    hakama割引適用: null,
    hakama保留: null,
  }
}

const state = (): { fields: FormValues袴 } => ({
  fields: getInitialStore(),
})

export default state
