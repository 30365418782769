import {
  KimonoSearchQueryParams,
  useSearchProduct,
} from '~/composable/api/useSearchProduct'

/*
 * 仕立上り振袖検索の結果にあるproduct_idを元に、予約情報を配列で取得する
 */
export const useHurisodeReserveList = () => {
  const { fetchFinishedKimonoList, finishedKimonoList: reserveList } =
    useSearchProduct()

  const fetchHurisodeReserveList = async (productId: number | undefined) => {
    if (!productId) {
      console.error('productIdがある商品が選択されていないので検索できません')
      return
    }
    const params: KimonoSearchQueryParams = {
      keyword: productId,
      keyword_types: [0],
      product_type_id: 1,
      has_purchased: 1,
    }
    await fetchFinishedKimonoList(params)
  }

  return { fetchHurisodeReserveList, reserveList }
}
