import { FormValues巾着バッグ_着物レンタル } from '~/composable/estimate/viewModel/graduation/Fields/Fields巾着バッグ_着物レンタル'

//不明な点がある場合、READMEをご覧ください
export const getInitialStore = (): FormValues巾着バッグ_着物レンタル => {
  return {
    kinchakubag種別: null,
    kinchakubag形態: null,
    kinchakubag商品選択: null,
    kinchakubag規定商品レンタル価格: null,
    kinchakubagレンタル価格: null,
    kinchakubag現品レンタル価格: null,
    kinchakubag現品レンタル価格フリー入力: null,
  }
}

const state = (): { fields: FormValues巾着バッグ_着物レンタル } => ({
  fields: getInitialStore(),
})

export default state
