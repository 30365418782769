export class PRODUCT_SETTING {
  // 発行する帳票をご選択ください
  static readonly FORM_TYPE = {
    契約書: 1,
    領収書のみ: 2,
    //EXTRA: 見積時のみ使用
    見積書: 3,
  } as const

  // お渡し状況
  static readonly DELIVERY_STATUS = {
    未: 1,
    お渡し済み: 2,
  } as const

  // 商品選択
  static readonly PRODUCTS = {
    現品: 1,
    こちらでご準備いたします: 2,
  } as const

  // 帯仕立て代が必要ですか
  // 別衿の縫い付けが必要ですか
  static readonly YES_OR_NO = {
    はい: 1,
    いいえ: 2,
  } as const

  // サイズを選択してください
  static readonly SIZES = {
    未設定: 1,
  } as const

  static readonly CORRECTIONS_TYPE = {
    肩幅: 3,
    袖幅: 4,
    袖丈: 5,
  } as const

  static readonly TOMONOKAI_POINT_APPLY = {
    はい: 1,
    いいえ: 2,
  }

  static readonly PRICES = {
    単品レンタル価格のみ: '¥5,500',
  } as const

  static readonly PRODUCT_ID = {
    重ね衿: 5,
  } as const

  static readonly PRICE_TYPE = {
    単品購入: 6,
  } as const
}
