import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { GRADUATION_ZORI } from '~/constants/estimate/comingOfAge'
import { BagState, ZoriBagState } from '~/store/types'

export const useRestoreバッグ1個目 = () => {
  const store = useComingStore<ZoriBagState>('ZoriBag')

  const restoreバッグ1個目 = (
    bag: Restate['items']['バッグ1個目'],
    plan: Restate['plan']
  ) => {
    if (!plan || !bag) return
    const isPPDiscount = bag.flags.isPPDiscount
    const isPPAddingPrice = bag.flags.isPPAddingPrice

    /* 初期値を設定 ※必ず入力される値はここで設置 */
    const formValues: BagState = {
      /**
       * NOTE: planLineBagはバッグにおけるpatternLineと同様で、選択肢のidを保持する
       * ↓保持される選択肢のindex
       * [0]種別
       * [1]形態 || 商品
       * [2]商品 || 単品レンタルor単品購入
       * [3]振袖レンタルだった場合 ||
       */
      planLineBag: [bag.optionId種別, 0, 0, 0],
      productBag: {
        id: 0,
        post_number_code: '',
        product_name: '',
        disabled: false,
        price: {
          rental: 0,
          buy: 0,
          set_purchase: 0,
        },
      },
      inputBag: {
        productName: bag.商品名,
        buyPrice: 0,
        rentalPrice: 0,
        rankupBuyPrice: 0,
        rankupRentalPrice: 0,
        singleBuy: 0,
        singleRental: 0,
        rentalPremiumPrice: 0,
        buyPremiumPrice: 0,
      },
      priceBag: {
        buy: 0,
        rental: 0,
      },
    }

    /* 以下で入力内容の条件に基づき、必要な項目を埋めていく */
    if (!bag.flags.is持込 && !plan.flags.is振袖持込) {
      formValues.planLineBag[1] = bag.optionId形態
      // NOTE:未定状態では見積作成できないため、「未定」選択時の状態復帰は考慮不要
      if (bag.selected商品) {
        formValues.planLineBag[2] = bag.optionId商品
        formValues.productBag = bag.selected商品
      }
    } else if (!bag.flags.is持込 && plan.flags.is振袖持込) {
      if (bag.selected商品) {
        formValues.planLineBag[1] = bag.optionId商品
        formValues.productBag = bag.selected商品
      }
    }

    /**
     * 【セット内】U系は草履の価格の方でまとめて反映するためバッグ側では金額等復元不要
     *           planLineBag[3]: 振袖レンタルだった場合の値のみ復元
     * 「こちらでお選びします」を選択時も復元不要
     * 【ランクアップPP内】inputBag.rankupBuy/RentalPriceを使っていないためセット内と同じ処理でOK...
     */
    if (
      plan.flags.isフルセットプラン &&
      bag.flags.isセット内 &&
      bag.flags.isU系商品
    )
      formValues.planLineBag[3] = bag.optionId振袖レンタルの場合

    if (bag.flags.isセット内現品 || bag.flags.isランクアップPP内) {
      formValues.planLineBag[3] = bag.optionId振袖レンタルの場合

      if (!plan.flags.is写のみレンタル) {
        formValues.inputBag.buyPrice = bag.購入価格
        formValues.priceBag.buy = bag.購入価格
      }
      if (bag.flags.is同じ商品単品購入) {
        formValues.inputBag.singleBuy = bag.単品購入価格
      } else {
        formValues.inputBag.rentalPrice = bag.レンタル価格
        formValues.priceBag.rental = bag.レンタル価格
      }
    }

    if (bag.flags.isランクアップPP外) {
      formValues.planLineBag[3] = bag.optionId振袖レンタルの場合

      if (!plan.flags.is写のみレンタル) {
        formValues.inputBag.rankupBuyPrice = bag.購入価格
        formValues.inputBag.buyPremiumPrice = bag.プレミアム購入価格
      }
      if (bag.flags.is同じ商品単品購入) {
        formValues.inputBag.singleBuy = bag.単品購入価格
      } else {
        formValues.inputBag.rankupRentalPrice = bag.レンタル価格
        formValues.inputBag.rentalPremiumPrice = bag.プレミアムレンタル価格
      }
    }

    if (plan.flags.is振袖持込 && bag.flags.isU系商品) {
      formValues.inputBag.singleBuy = bag.単品購入価格
    }
    if (plan.flags.is振袖持込 && !bag.flags.isU系商品) {
      formValues.planLineBag[2] = bag.optionId単品選択
      formValues.inputBag.singleRental = bag.レンタル価格
      formValues.inputBag.singleBuy = bag.単品購入価格

      if (!bag.flags.is現品) {
        formValues.priceBag.rental = bag.レンタル価格
        formValues.priceBag.buy = bag.購入価格
      }
    }

    // PP適用時の判定
    if (isPPDiscount) {
      if (isPPAddingPrice) {
        formValues.inputBag.buyPremiumPrice = bag.プレミアム購入価格
      } else {
        if (bag.flags.isランクアップPP内) {
          formValues.planLineBag[3] = GRADUATION_ZORI.BUY_THE_SAME_PRODUCT
        }
      }
    }

    /* ストアに内容を保存する */
    store.setOf<ZoriBagState['planLineBag']>(
      'setSelectionBag',
      formValues.planLineBag
    )
    store.setOf<ZoriBagState['inputBag']>('setInputBag', formValues.inputBag)
    store.setOf<ZoriBagState['productBag']>(
      'setProductBag',
      formValues.productBag
    )
    store.setOf<ZoriBagState['priceBag']>('setPriceBag', formValues.priceBag)
    store.setOf<ZoriBagState['isPPDiscount']>('setIsPPDiscount', isPPDiscount)
    store.setOf<ZoriBagState['isPPAddingPriceBag']>(
      'setIsPPAddingPriceBag',
      isPPAddingPrice
    )
    store.setIsValidate(false)
  }

  return { restoreバッグ1個目 }
}
