import { GetterTree } from 'vuex'
import { FormValuesお客様検索 } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsお客様検索'
import { RootState } from '~/store/Estimate/GraduationCeremony/types'

const getters: GetterTree<{ fields: FormValuesお客様検索 }, RootState> = {
  getState: (state) => (stateName: keyof FormValuesお客様検索) => {
    return state.fields[stateName]
  },

  getAllState: (state) => {
    return state.fields
  },
}
export default getters
