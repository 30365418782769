import { GetterTree } from 'vuex'
import { RootState, DeliveryDateState } from '../../types'

const getters: GetterTree<DeliveryDateState, RootState> = {
  getSelectedDeliveryType: (state: DeliveryDateState) => {
    return state.selectedDeliveryType
  },

  getInput: (state: DeliveryDateState) => {
    return state.input
  },

  getIsValidate: (state: DeliveryDateState) => {
    return state.isValidate
  },
}

export default getters
