import { Hurisode } from '~/api/estimateRepository'
import { EstimateState } from '~/composable/estimate/repository/graduation/EstimateState'

/*
 * WARNING: hurisodeという名称だが実際には二尺袖も含む「着物」情報を送るパラメータ
 */
export const useHurisodeFormatter = (estimateState: EstimateState) => {
  const getFormattedHurisode = () => {
    const {
      着物検索,
      レンタル価格,
      商品分類,
      品種区分,
      discountType,
      振袖本体価格,
      flags,
    } = estimateState.value.振袖二尺袖
    if (!商品分類 && !flags.is二尺袖保留) {
      throw new Error('hurisode.商品の分類（レンタルやお持込）がありません')
    }
    if (!品種区分) throw new Error('hurisode.品種の選択がありません')

    // NOTE: 80%OFFを選択した場合は実際レンタルする金額ではなく値引き前の金額を送って、
    // 既存の見積を再表示する際に別途値引き項目を作成することで金額を合わせる
    const price = flags.is80Off ? 振袖本体価格 : レンタル価格

    const hurisode: Hurisode = {
      product_id: 着物検索?.product_id ?? undefined,
      catalog_code: 着物検索?.catalog_code ?? undefined,
      product_name: 着物検索?.product_name || '-',
      is_correct_for_yukitake: flags.is裄補正,
      is_correct_for_sodetake: flags.is袖補正,
      rental_price: price ?? 0,
      purchase_price: 0,
      // NOTE:保留時のみnullで来るので型エラーを防ぐためこうしているが、0で見積作成されることはない想定
      category: 商品分類 ?? 0,
      product_type_id: 品種区分,
      discount_type: discountType,
    }
    return hurisode
  }
  return { getFormattedHurisode }
}
