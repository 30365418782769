import { computed, ref, Ref, watch } from '@nuxtjs/composition-api'
import { useRestatePlan } from '../RestatePlan'
import { EstimateDetail, Items } from '~/api/estimateRepository'
import { usePrice } from '~/composable/general/Price'
import {
  Enum半巾帯_袴レンタル,
  Enum半巾帯_袴持込,
} from '~/constants/estimate/graduationOptions'
import { ItemPlan } from '~/constants/enums/itemPlan'
import ProductId from '~/constants/enums/productClassification'
import {
  CatalogOtherKimono,
  useSearchProduct,
} from '~/composable/api/useSearchProduct'
import ProductClassification from '~/constants/enums/productClassification'
import { YONSHOKUOBI_PRODUCT_ID } from '~/constants/productData'

export const useRestate半巾帯 = (estimate: Ref<EstimateDetail | undefined>) => {
  const { paramToString } = usePrice()
  const restatePlan = useRestatePlan(estimate)
  const { fetchCatalogOtherKimono, catalogOtherKimono } = useSearchProduct()

  watch(estimate, () => {
    if (!estimate.value?.items) {
      return
    }

    const hanhabaobi = estimate.value.items.find(
      (item) => item.product_type_id === ProductId.hanhabaobi
    )

    if (hanhabaobi?.catalog_code) {
      fetchCatalogOtherKimono({
        catalog_code: hanhabaobi.catalog_code,
        product_type_id: ProductClassification.hanhabaobi,
        use_date: estimate.value.use_date ?? '',
      })
    }
  })

  const formattedCatalogHanhabaobi = ref<CatalogOtherKimono | null>(null)
  watch(catalogOtherKimono, () => {
    if (!catalogOtherKimono.value) {
      return
    }
    formattedCatalogHanhabaobi.value = {
      ...catalogOtherKimono.value,
      product_id: undefined,
    }
  })

  return computed(() => {
    if (!estimate.value?.items) {
      return null
    }

    const hanhabaobi = estimate.value.items.find(
      (item) => item.product_type_id === ProductId.hanhabaobi
    )

    if (!hanhabaobi) {
      return
    }

    const { rental_price, purchase_price, plan, price_type } = hanhabaobi

    const product = () => {
      if (hanhabaobi.product_name === '現品') {
        return {
          id: Enum半巾帯_袴持込.商品選択.現品,
          name: hanhabaobi.product_name,
        }
      } else if (hanhabaobi.product_name === '四色帯') {
        return { id: YONSHOKUOBI_PRODUCT_ID, name: hanhabaobi.product_name }
      } else if (!hanhabaobi.product_id) {
        // 選択方式実装前のカタログ商品の場合、商品選択をnullにする
        return null
      } else if (
        hanhabaobi.product_id !== undefined &&
        hanhabaobi.product_name !== undefined
      ) {
        return { id: hanhabaobi.product_id, name: hanhabaobi.product_name }
      } else {
        return null
      }
    }

    return {
      optionId種別: getOptionIdOf種別(
        hanhabaobi,
        !!restatePlan.value?.flags.has袴レンタル
      ),
      optionId見積選択: price_type ?? null,
      flags: {
        ...getConditionalFlags(hanhabaobi),
        hasHakamaRental: restatePlan.value?.flags.has袴レンタル,
      },
      商品: product(),
      レンタル価格: paramToString(rental_price),
      購入価格: purchase_price ? paramToString(purchase_price) : null,
      // 四色帯はセット内にしか存在しない
      形態:
        hanhabaobi.product_name === '四色帯'
          ? Enum半巾帯_袴レンタル.形態.セット内
          : plan === Enum半巾帯_袴持込.形態.未定 // 選択方式実装前の袴持込現品の場合、形態をnullにする
          ? null
          : plan,
      カタログ検索結果: formattedCatalogHanhabaobi,
    }
  })
}

/**
 * 選択肢系の項目の初期値(選択されたoptionのid)を再計算する関数群
 */
const getOptionIdOf種別 = (hanhabaobi: Items, has袴レンタル: boolean) => {
  const isレンタル = has袴レンタル && hanhabaobi.plan !== ItemPlan.bringIn
  const is単品レンタル単品購入 = !has袴レンタル && hanhabaobi.product_type_id

  if (hanhabaobi.plan === Enum半巾帯_袴レンタル.種別.お持込) {
    return Enum半巾帯_袴レンタル.種別.お持込
  } else if (isレンタル) {
    return Enum半巾帯_袴レンタル.種別.レンタル
  } else if (is単品レンタル単品購入) {
    return Enum半巾帯_袴持込.種別.単品レンタル単品購入
  }
}

/**
 * 復元条件に関わる条件式を定義する
 */
const getConditionalFlags = (item: Items) => {
  const is持込 = item.plan === Enum半巾帯_袴持込.種別.お持込
  const is四色帯 = item.product_name === '四色帯'
  const is現品 = item.product_name === '現品'
  const isカタログ商品 = !!item.product_name && !is四色帯 && !is現品 && !is持込

  const isセット内 = item.plan === ItemPlan.inSet
  // NOTE
  // おそらく4（PP外ランクアップ）は使ってないが
  // 見積作成時の不具合を受容するために条件に含めている
  const isランクアップ = [
    ItemPlan.rankUpInPremium,
    ItemPlan.rankUpOutPremium,
  ].includes(item.plan)

  return {
    is持込,
    isカタログ商品,
    is四色帯,
    is現品,
    isセット内,
    isランクアップ,
  }
}
