import { GetterTree } from 'vuex'
import { RootState } from '../../../types'
import { State } from './state'

const getters: GetterTree<State, RootState> = {
  // ** All Input Data **
  getState: (state: State) => {
    return state.data
  },

  // ** 見積選択 **
  getSelectEstimate: (state: State) => {
    return state.selectEstimate
  },

  // ** タブ **
  getTabList: (state: State) => {
    return state.tabList
  },

  // ** ユーザーID **
  getCustomereId: (state: State) => {
    return state.data.baseSettings.selectedEstimate?.customer_id ?? null
  },

  // ** ユーザータブ **
  getUserTabList: (state: State) => {
    return state.userTabList
  },

  // ** ご利用者のindex **
  getCurrentIndex: (state: State) => {
    return state.data.currentUserIndex
  },

  // ** 友の会割引（2） **
  getDiscountList: (state) => {
    return state.discountList
  },

  // ** 契約書に紐づいている見積書ID **
  getEstimateIdList: (state) => {
    return state.estimateIdList
  },

  // ** 最終ページ **
  getFinalPageList: (state) => {
    return state.finalPageList
  },

  // ** 最終ページかどうか **
  getIsFinalPages: (state) => {
    // タブのisCurrentが全てfalseになると最終ページが表示される
    return state.tabList.every((tab) => !tab.isCurrent)
  },
  getContractId: (state) => {
    return state.contractId
  },

  // 支払い情報
  getPayment: (state) => {
    return state.data.payment
  },

  getContractTotalPrice: (state) => {
    return state.contractTotalPrice
  },
}

export default getters
