import { MutationTree } from 'vuex'
import { JkPhotoInAdvanceState } from '../../types'

const mutations: MutationTree<JkPhotoInAdvanceState> = {
  setPatternLine: (state, patternLine) => {
    state.patternLine = patternLine
  },

  setInput: (state, { ...input }) => {
    state.input.jkPhotoInAdvance = input.jkPhotoInAdvance
    state.input.dateOfTakingJkPhotoInAdvance =
      input.dateOfTakingJkPhotoInAdvance
    state.input.placeOfDressing = input.placeOfDressing
    state.input.timeOfEnteringDressPlace = input.timeOfEnteringDressPlace
    state.input.timeOfEnteringSalon = input.timeOfEnteringSalon
    state.input.remark = input.remark
    state.input.itemSelection = input.itemSelection
    state.input.nameOfSalon = input.nameOfSalon
    state.input.telOfSalon = input.telOfSalon
    state.input.salonId = input.salonId
    state.input.shopId = input.shopId
    state.input.address = input.address
    state.input.prefecture = input.prefecture
    state.input.shubetsu = input.shubetsu
    if (typeof input.selectedPlanType !== 'undefined') {
      state.input.selectedPlanType = input.selectedPlanType
    }
    state.input.jk_hurisode_price_for_cancellation_fee =
      input.jk_hurisode_price_for_cancellation_fee
    state.input.needMakeup = input.needMakeup
  },

  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },

  setShop: (state, shop) => {
    state.shop = shop
  },

  setDressingPlaces: (state, dressingPlaces) => {
    state.dressingPlaces = dressingPlaces
  },

  setFurisode: (state, furisode) => {
    state.furisode = furisode
  },

  setPhotoInfo: (state, photoInfo) => {
    state.photoInfo = photoInfo
  },

  delete: (state) => {
    state.patternLine = [0]
    state.input.jkPhotoInAdvance = 0
    state.input.dateOfTakingJkPhotoInAdvance = ''
    state.input.placeOfDressing = ''
    state.input.timeOfEnteringDressPlace = ''
    state.input.timeOfEnteringSalon = ''
    state.input.remark = ''
    state.input.itemSelection = 0
    state.input.prefecture = ''
    state.input.nameOfSalon = ''
    state.input.telOfSalon = ''
    state.input.salonId = 0
    state.input.shopId = null
    state.input.selectedPlanType = null
    state.input.shubetsu = 0
    state.input.jk_hurisode_price_for_cancellation_fee = 0
    state.isValidate = null
    state.shop.shopId = 0
    state.shop.shopName = ''
    state.furisode = null
    state.photoInfo = null
    state.dressingPlaces.id = null
    state.dressingPlaces.name = null
    state.dressingPlaces.dressingAddressInfo = null
    state.input.needMakeup = true
  },
}

export default mutations
