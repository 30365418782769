import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { AxiosResponse, AxiosInstance } from 'axios'

// ** enums **
import ProductClassification from '../constants/enums/productClassification'
import { ClothingSizeType } from '../constants/enums/clothingSizeType'
import { SingleItemPlan } from '../constants/enums/singleItemPlan'
import { PriceTypeListBySingleItem } from '../constants/enums/itemPlan'
import { ContractAddition } from '../api/contractRepository'
import { CheckboxRecord } from '~/api/checklistRepository'

export type PlanIdList =
  | typeof SingleItemPlan.セット内
  | typeof SingleItemPlan.ランクアップ
  | typeof SingleItemPlan.仕立て上がり
  | typeof SingleItemPlan.新品

export type ProductTypeIdList =
  | typeof ProductClassification.obi
  | typeof ProductClassification.obijime
  | typeof ProductClassification.obiage
  | typeof ProductClassification.kasaneeri
  | typeof ProductClassification.betueri
  | typeof ProductClassification.shawl
  | typeof ProductClassification.zoriAndBag
  | typeof ProductClassification.zori
  | typeof ProductClassification.bag
  | typeof ProductClassification.kamikazari
  | typeof ProductClassification.nagajyubanPurchase
  | typeof ProductClassification.nagajyubanRental
  | typeof ProductClassification.gohukubako
  | typeof ProductClassification.tomesode
  | typeof ProductClassification.mohuku
  | typeof ProductClassification.otherItem
  | typeof ProductClassification.obimakura
  | typeof ProductClassification.obiita
  | typeof ProductClassification.erisin
  | typeof ProductClassification.datejime
  | typeof ProductClassification.korinBelt
  | typeof ProductClassification.sanjyuhimo
  | typeof ProductClassification.kosihimo
  | typeof ProductClassification.hadagi
  | typeof ProductClassification.tabi
  | typeof ProductClassification.towel

export type AccessoryTypeIdList = [
  typeof ProductClassification.obiita,
  typeof ProductClassification.datejime,
  typeof ProductClassification.kosihimo,
  typeof ProductClassification.erisin,
  typeof ProductClassification.korinBelt,
  typeof ProductClassification.hadagi,
  typeof ProductClassification.towel,
  typeof ProductClassification.tabi,
  typeof ProductClassification.obimakura,
  typeof ProductClassification.sanjyuhimo
]

export type ProductSizeIdList =
  | typeof ClothingSizeType.S
  | typeof ClothingSizeType.M
  | typeof ClothingSizeType.L
  | typeof ClothingSizeType.LL
  | typeof ClothingSizeType.LLL
  | typeof ClothingSizeType.N
  | typeof ClothingSizeType.B
  | typeof ClothingSizeType.BB

export type SingleItem = {
  plan_id: PlanIdList | null
  product_type_id: ProductTypeIdList
  products: {
    product_id: number | null
    product_name: string | null
    enable_rental: boolean
    enable_purchase: boolean
    delivery_period: number | null
    price_informations: {
      product_size_id: ProductSizeIdList | null
      prices: {
        order_number: number
        rental_price: number | null
        purchase_price: number | null
      }[]
    }[]
  }[]
}

export type SingleItemDeliveryAndReturn = {
  date: string
  shop_id: number
}

export type SingleItemDiscounts = {
  discount_type: number
  type: number
  rate: number
  amount: number
}

// 採寸情報
export type SingleItemProductMeasurement = {
  foot_size: number | null
  bust: number | null
  hip: number | null
  height: number | null
}

// お持ちの振袖の採寸情報
export type SingleItemProductCorrections = {
  type: number
  shaku: number | null
  sun: number
  bu: number
}

export type SingleItemProducts = {
  product_type_id: ProductTypeIdList // 品種ID
  plan_id: PlanIdList | null // 1: セット内 2: ランクアップ 3: 仕立て上がり 4: 新品
  product_id: number | null // 商品番号
  number: number // 商品番号
  product_name: string // 商品名
  product_size_id: ProductSizeIdList | null // サイズ
  delivery_period: number | null // 納期までの日数（当日を含む）
  price_type: PriceTypeListBySingleItem //　5: 単品レンタル, 6: 単品購入
  single_rental_price: number | null // 単品レンタル価格
  single_purchase_price: number | null // 単品購入価格
  remarks: string | null // 備考
  additions: ContractAddition[] // 追加金額のオプション項目
  measurement: SingleItemProductMeasurement | null
  corrections?: SingleItemProductCorrections[]
  check_list_branch_number?: number | null
  has_delivery?: 1 | 2 | null // お送りするか否か
  // チェック機能項目
  check_list?: {
    product_type_id?: number // 品種区分
    branch_number: number // DBのデータ特定に必要な値
    lists: CheckboxRecord[] | null // チェックリスト
  } | null
}

export type CreateSingleItemServiceEstimateParams = {
  staff_id: number
  shop_id: number
  customer_id: number
  memo: string | null
  is_chance: number | null
  total_price: number
  notes: string | null
  item_photo_url: string | null
  products: SingleItemProducts[]
  discounts: SingleItemDiscounts[]
}

export type SingleItemServiceEstimateDetail = {
  staff_id: number
  shop_id: number
  customer_id: number
  estimate_date: string
  total_price: number
  notes: string | null
  item_photo_url: string | null
  products: SingleItemProducts[]
  discounts: SingleItemDiscounts[]
  pdf_url: string
}

export type SingleItemServiceContractDetail = {
  staff_id: number
  shop_id: number
  customer_id: number
  contractor_id: number
  tomonokai_customer_id: number | null // 領収書発行時、友の会ポイントを利用する場合に使用するお客様ID
  estimate_id: number
  receipt_id: number
  contract_date: string
  signature_url: string
  contract_total_price: number
  balance_due_date: string
  account_payable_id: number
  item_photo_url: string
  notes: string
  cancel_date: string
  cancel_amount: number | null
  delivery: SingleItemDeliveryAndReturn
  return: SingleItemDeliveryAndReturn
  products: SingleItemProducts[]
  discounts: SingleItemDiscounts[]
}

export type SingleItemMasterProducts = SingleItem['products'][number]
export type FetchSingleItemsParams = {
  productTypeIds: ProductTypeIdList[]
}

export type CreateSingleItemServiceContractParams = {
  staff_id: number
  shop_id: number
  customer_id: number | null
  contractor_id: number | null
  tomonokai_customer_id: number | null // 領収書発行時、友の会ポイントを利用する場合に使用するお客様ID
  estimate_id: number | null
  memo: string | null
  is_chance: number | null
  contract_date: string
  signature_url: string | null
  contract_total_price: number
  balance_due_date: string | null
  account_payable_id: number | null
  notes: string | null
  item_photo_url: string | null
  payments: {
    staff_id: number
    due_date: string
    details: {
      methods: number
      tomonokai_points?: number
      amount: number
    }[]
    total: number
    notes: string | null
  } | null
  delivery: {
    date: string
    shop_id: number
  }
  return: {
    date: string
    shop_id: number
  } | null
  products: SingleItemProducts[]
  discounts: SingleItemDiscounts[]
}

export type UpdateSingleItemServiceContractParams = Omit<
  CreateSingleItemServiceContractParams,
  'payments'
>

export type CreatePdfParams = {
  id: number
  type: number
}

export type SingleItemRepository = {
  fetchSingleItems: (
    params: FetchSingleItemsParams
  ) => Promise<AxiosResponse<SingleItem[]>>
  showSingleItemServiceContract: (
    id: number
  ) => Promise<AxiosResponse<SingleItemServiceContractDetail>>
  createSingleItemServiceContract: (
    params: CreateSingleItemServiceContractParams
  ) => Promise<AxiosResponse<{ contract_id: number }>>
  updateSingleItemServiceContract: (
    id: number,
    params: UpdateSingleItemServiceContractParams
  ) => Promise<AxiosResponse<{ contract_id: number }>>
  showSingleItemServiceEstimate: (
    id: number
  ) => Promise<AxiosResponse<SingleItemServiceEstimateDetail>>
  createSingleItemServiceEstimate: (
    params: CreateSingleItemServiceEstimateParams
  ) => Promise<AxiosResponse<{ estimate_id: number }>>
  createPDF: <R>(params: CreatePdfParams) => Promise<AxiosResponse<R>>
  createReceiptPDF: <R>(params: CreatePdfParams) => Promise<AxiosResponse<R>>
}

export const singleItemRepository = (
  client: NuxtAxiosInstance | AxiosInstance
): SingleItemRepository => {
  return {
    // TODO: まだurl決まっていないので修正必要かも
    fetchSingleItems: (params) =>
      client.get('single-items', {
        params,
      }),
    showSingleItemServiceContract: (id) =>
      client.get(`contracts/single-item/${id}`),
    createSingleItemServiceContract: (params) =>
      client.post('contracts/single-item', params),
    updateSingleItemServiceContract: (id, params) =>
      client.patch(`contracts/single-item/${id}`, params),
    showSingleItemServiceEstimate: (id) =>
      client.get(`estimates/single-item/${id}`),
    createSingleItemServiceEstimate: (params) =>
      client.post('estimates/single-item', params),
    createPDF: (params) => client.post('api/single-item/pdf', params),
    createReceiptPDF: (params) => client.post('api/pdf', params),
  }
}
