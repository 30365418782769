import { computed, Ref } from '@nuxtjs/composition-api'
import { EstimateState } from '~/composable/estimate/repository/comingOfAge/EstimateState'

export const useSistersFormatter = (estimateState: Ref<EstimateState>) => {
  return computed(() => {
    const { プラン, 割引 } = estimateState.value
    return getSisters(プラン, 割引)
  })
}
export const getSisters = (
  valuesプラン: EstimateState['プラン'],
  values割引: EstimateState['割引']
) => {
  const resultInプランタブ = valuesプラン.お姉様情報?.customer_id
  const resultIn妹様割 = values割引.妹様割.お姉様情報?.customer_id

  if (!resultInプランタブ && !resultIn妹様割) return null

  return {
    customer_id: (resultInプランタブ || resultIn妹様割) as number,
  }
}
