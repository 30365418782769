


















import {
  computed,
  defineComponent,
  onMounted,
  useStore,
} from '@nuxtjs/composition-api'
import { useTemporarilySaved } from '~/composable/api/useTemporarilySaved'
import { ESTIMATE } from '~/constants/estimate/config'
import { useEstimateTypeFlags } from '~/composable/estimate/domain/EstimateTypeFlags'
import { useOnline } from '~/composable/general/useOnline'
import { useAuth } from '~/composable/api/useAuth'

/** types */
import { State, Tab } from '~/store/Contracts/Attendance/Main/state'
import {
  State as SingleItemServiceState,
  Tab as SingleItemServiceTab,
} from '~/store/SingleItemService/Main/state'

/**
 * 参列見積用のレイアウト。契約書側はcontractレイアウトを使っている
 */
export default defineComponent({
  name: 'estimate',
  setup() {
    const online = useOnline()
    const { validateSession } = useAuth()
    const { saveEstimateTemporarily } = useTemporarilySaved()
    const { in参列見積, in単品サービス見積 } = useEstimateTypeFlags()

    const estimateType = computed(() => {
      switch (true) {
        case in参列見積.value:
          return ESTIMATE.参列.id
        case in単品サービス見積.value:
          return ESTIMATE.単品.id
      }
    })

    const store = useStore()
    const storedPageListData = computed<Tab[]>(
      () => store.getters['Estimate/Attendance/Main/getFinalPageList']
    )
    const storeTabListData = computed<State['tabList']>(
      () => store.getters['Estimate/Attendance/Main/getTabList']
    )

    const storeSingleItemServiceTabList = computed<
      SingleItemServiceState['tabList']
    >(() => store.getters['SingleItemService/Main/getTabList'])

    const storedSingleItemSeervicePageList = computed<SingleItemServiceTab[]>(
      () => store.getters['SingleItemService/Main/getFinalPageList']
    )
    const in完了画面 = computed(() =>
      storedPageListData.value.some((item) => {
        return item.text === '見積作成完了' && item.isCurrent
      })
    )
    const in見積一覧 = computed(() =>
      storedPageListData.value.some((item) => {
        return item.text === '見積一覧' && item.isCurrent
      })
    )

    // 仮保存ボタン押下時に発火
    const save = () => {
      if (!estimateType.value) return
      saveEstimateTemporarily(estimateType.value)
    }

    onMounted(() => {
      validateSession()
    })

    return {
      save,
      in完了画面,
      in見積一覧,
      estimateType,
      storeTabListData,
      storedPageListData,
      storedSingleItemSeervicePageList,
      storeSingleItemServiceTabList,
    }
  },
})
