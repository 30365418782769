import { FamilyAddressType } from './../../types'
import { ActionTree } from 'vuex'
import { FAMILY_INFORMATION } from '~/constants/estimate/comingOfAge'
import {
  RootState,
  FamilyInformationSetting,
  IsClickByUsedPastType,
} from '../../types'

const actions: ActionTree<FamilyInformationSetting, RootState> = {
  addFamilyInfo: ({ commit }, payload) => {
    commit('addFamilyInfo', payload)
  },
  removeFamilyInfo: ({ commit }, { settingId, familyInfo }) => {
    commit('removeFamilyInfo', { settingId, familyInfo })
  },
  setAddress: ({ commit }, inputs) => {
    commit('setAddress', inputs)
  },
  setIsValidate: ({ commit }, isValidate: boolean) => {
    commit('setIsValidate', isValidate)
  },
  setIsOpend: ({ commit }) => {
    commit('setIsOpend')
  },
  setIsClickByUsedPast: (
    { commit },
    isClickByUsedPast: IsClickByUsedPastType
  ) => {
    commit('setIsClickByUsedPast', isClickByUsedPast)
  },
  setFamilyAddressType: ({ commit }, familyAddressType: FamilyAddressType) => {
    commit('setFamilyAddressType', familyAddressType)
  },
}

export default actions
