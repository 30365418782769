import { GetterTree } from 'vuex'
import { RootState, BaseInformationState, Staff } from '../../types'

const getters: GetterTree<BaseInformationState, RootState> = {
  getPatternLine: (state: BaseInformationState) => {
    return state.patternLine
  },

  getComingStoreType: (state: BaseInformationState) => {
    return state.comingStoreType
  },

  getSubStaff: (state: BaseInformationState): Staff | null => {
    return (
      state.subStaff ?? {
        staff_id: null,
        last_name: '',
        first_name: '',
        shop_id: 0,
        shop_name: '',
      }
    )
  },

  getSubStaffName: (state: BaseInformationState): string => {
    return state.subStaff
      ? `${state.subStaff.last_name}${state.subStaff.first_name}`
      : ''
  },

  getIsValidate: (state: BaseInformationState) => {
    return state.isValidate
  },

  // 契約動機
  getContractReason: (state: BaseInformationState) => {
    return state.contractReason
  },
}

export default getters
