import { computed } from '@nuxtjs/composition-api'
import {
  getBadgeText,
  getItemPrices,
  getPriceTypeText,
} from '~/composable/estimate/repository/comingOfAge/states/utilities'
import { AccessoryPlanState } from '~/store/types'
import { useComingStore } from '../../ComingOfAgeStore'
import { useScreenTypeFlags } from '~/composable/estimate/domain/ComingOfAge/ScreenTypeFlags'
import { BaseItemState, PlanFlags } from './types'
import ProductClassification from '~/constants/enums/productClassification'
import { PLAN } from '~/constants/estimate/comingOfAge'
import { ItemPlan } from '~/constants/enums/itemPlan'

type Shawl = BaseItemState & {
  品種区分: 7
  flags: {
    is写のみレンタル: boolean
  }
}

export const useStateショール = () => {
  const valuesショール = useComingStore<AccessoryPlanState>('Shawl').getAll()
  const { showTypeフルセット, showType写真のみレンタル, showType持ち込み } =
    useScreenTypeFlags()

  return computed(() => {
    const flags = {
      isフルセット: showTypeフルセット.value,
      is写のみレンタル: showType写真のみレンタル.value,
      is持ち込み: showType持ち込み.value,
    }
    return getStateショール(valuesショール.value, flags)
  })
}

export const getStateショール = (
  valuesショール: AccessoryPlanState,
  planFlags: PlanFlags
) => {
  const { selections, input, price } = valuesショール
  const itemPlan = getShawlPlan(selections, planFlags)

  const shawl: Shawl = {
    品目名: 'ショール',
    品種区分: ProductClassification.shawl,
    商品名: getShawlName(selections, input.productName),
    商品id: !!selections[1]
      ? selections[1] === PLAN.ACTUAL_PRODUCT
        ? null
        : selections[1]
      : null,
    小物プラン: itemPlan,
    レンタル価格: 0,
    購入価格: 0,
    プレミアムレンタル価格: input.premiumRentalPrice,
    プレミアム購入価格: input.premiumBuyPrice,
    見積表示: null,
    表示価格: null,
    label: getBadgeText(itemPlan),
    cellLabel: getPriceTypeText(selections),
    flags: {
      isランクアップ: false,
      is写のみレンタル: planFlags.is写のみレンタル,
    },
  }

  const is現品 = selections.includes(PLAN.ACTUAL_PRODUCT)
  if (planFlags.isフルセット) {
    if (is現品) {
      shawl.レンタル価格 = input.rankUpRentalPrice
      shawl.購入価格 = input.rankUpBuyPrice
    } else {
      shawl.レンタル価格 = price.rental
      shawl.購入価格 = price.buy
    }
  }

  // NOTE:フルセット時の金額算出は他の小物と違うが、振袖持ち込み時のロジックは同じのため
  // getItemPricesを流用可能
  if (planFlags.is持ち込み) {
    // NOTE: ショールだけ形態選択なしだが、商品自体に形態が紐付いているという特殊なタブのため、
    // 他のタブで必ずinputに入力される値がpriceの方にしかないパターンがあるのでここで調整している
    valuesショール.input.rentalPrice = input.rentalPrice || price.rental
    valuesショール.input.buyPrice = input.buyPrice || price.buy
    const updatedPrices = getItemPrices(valuesショール, shawl, planFlags)
    shawl.レンタル価格 = updatedPrices.レンタル価格
    shawl.購入価格 = updatedPrices.購入価格
    shawl.見積表示 = updatedPrices.見積表示
    shawl.表示価格 = updatedPrices.表示価格
  }

  return shawl
}

const getShawlPlan = (selections: number[], planFlags: PlanFlags) => {
  if (!selections[0]) return 0
  if (selections.includes(PLAN.BRING_IN)) return ItemPlan.bringIn
  // 振袖持込パターンの時は持込以外、全て単品購入・レンタル
  if (planFlags.is持ち込み) return ItemPlan.toBeDecided
  // 以下は全てフルセットの場合の出しわけ
  if (selections[2] === PLAN.IN_THE_SET) return ItemPlan.inSet
  if (selections[2] === PLAN.RANK_UP_IN_PREMIUM) return ItemPlan.rankUpInPremium
  if (selections[2] === PLAN.RANK_UP_OUT_PREMIUM)
    return ItemPlan.rankUpOutPremium
  console.error('一致するショールの小物プランがありません')
  return 0
}

const getShawlName = (selections: number[], productName: string) => {
  if (!selections[0]) return 'ー'
  if (selections.includes(PLAN.BRING_IN)) return ''
  if (selections[1] !== PLAN.ACTUAL_PRODUCT) return productName
  if (selections.includes(PLAN.ACTUAL_PRODUCT)) return '現品'
  console.error('一致するショール商品がありません')
  return 'ー'
}
