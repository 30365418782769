























import { defineComponent } from '@nuxtjs/composition-api'
import IconArrowRight from '~/components/icons/IconArrowRight.vue'

export default defineComponent({
  components: {
    IconArrowRight,
  },
  props: {
    isPinkButton: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  setup(_, { emit }) {
    const handleClick = (e: Event) => emit('click', e)

    return { handleClick }
  },
})
