import { ActionTree } from 'vuex'
import { RootState, ObiState } from '../../../types'

const actions: ActionTree<ObiState, RootState> = {
  setSelection: ({ commit }, selection) => {
    commit('setSelection', selection)
  },

  setProduct: ({ commit }, product) => {
    commit('setProduct', product)
  },

  setInput: ({ commit }, input) => {
    commit('setInput', input)
  },

  setPrice: ({ commit }, price) => {
    commit('setPrice', price)
  },

  setIsFree: ({ commit }, isFree) => {
    commit('setIsFree', isFree)
  },

  setIsValidate: ({ commit }, isValidate: boolean) => {
    commit('setIsValidate', isValidate)
  },

  setInit: ({ commit }) => {
    commit('deleteProduct')
    commit('deleteInput')
    commit('deletePrice')
    commit('deleteIsFree')
  },
}

export default actions
