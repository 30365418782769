import { computed, Ref } from '@nuxtjs/composition-api'
import { EstimateDetail, Hakama, Hurisode } from '~/api/estimateRepository'
import { Enumプラン } from '~/constants/estimate/graduationOptions'
import { ProductCategory } from '~/constants/enums/productCategory'

export const useRestatePlan = (estimate: Ref<EstimateDetail | undefined>) => {
  return computed(() => {
    if (!estimate.value) return null

    const has成人式契約 = estimate.value.coming_of_age_ceremony_contract_id
    const 着物種別 = getOptionId着物種別(estimate.value.hurisode?.category)
    const 袴種別 = getOptionIdOf種別(estimate.value.hakama?.category)

    const isTomonokai =
      estimate.value.is_tomonokai_member === Enumプラン.友の会会員.はい
        ? Enumプラン.友の会会員.はい
        : Enumプラン.友の会会員.いいえ

    return {
      optionId成人式利用: has成人式契約
        ? Enumプラン.弊社成人式.はい
        : Enumプラン.弊社成人式.いいえ,
      optionId着付け区分: estimate.value.contract_service_type ?? null,
      optionId着物種類: estimate.value.hurisode?.product_type_id ?? null,
      optionId着物種別: 着物種別,
      optionId袴種別: 袴種別,
      卒業式使用日: estimate.value.use_date ?? null,
      selectedプラン: estimate.value.plan,
      flags: {
        is成人式利用: has成人式契約,
        is友の会: isTomonokai,
        has着物レンタル: 着物種別 === Enumプラン.着物種別.レンタル,
        has袴レンタル: 袴種別 === Enumプラン.袴種別.レンタル,
      },
    }
  })
}

/**
 * 選択肢系の項目の初期値(選択されたoptionのid)を再計算する関数群
 */
const getOptionId着物種別 = (category?: Hurisode['category']) => {
  if (!category) return null
  if (category === Enumプラン.着物種別.姉販持込) {
    return Enumプラン.着物種別.姉販持込
  } else if (category === Enumプラン.着物種別.お持込) {
    return Enumプラン.着物種別.お持込
  } else {
    return Enumプラン.着物種別.レンタル
  }
}

const getOptionIdOf種別 = (category?: Hakama['category']) => {
  if (!category) return null
  const is持込 = [ProductCategory.bringIn].includes(category)
  return is持込 ? Enumプラン.袴種別.お持込 : Enumプラン.袴種別.レンタル
}
