import { MutationTree } from 'vuex'
import {
  FAMILY_INFORMATION,
  FAMILY_INFORMATION_INPUT,
} from '~/constants/estimate/comingOfAge'
import {
  FamilyInformationSetting,
  IsClickByUsedPastType,
  FamilyAddressType,
} from '../../types'

const mutations: MutationTree<FamilyInformationSetting> = {
  //住所用patternLineの変更
  setPatternLine: (state, patternLine) => {
    state.patternLine = patternLine
  },

  //ご家族情報(空情報)の追加
  addFamilyInfo: ({ family }, { settingId, familyInfo }) => {
    family[settingId].profile = familyInfo
  },

  //ご家族情報の削除
  removeFamilyInfo: ({ family }, { settingId, familyInfo }) => {
    family[settingId].profile = familyInfo
  },

  // (ご利用者様)住所の変更
  setAddress: ({ address }, inputs) => {
    address.zip_code = inputs.zip_code
    address.prefectures = inputs.prefectures
    address.address1 = inputs.address1
    address.address2 = inputs.address2
    address.phone_number = inputs.phone_number
  },

  // タブのバリエーションの変更
  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },
  // 一度でも変更ボタンを開いた時のログをとる
  setIsOpend: (state) => {
    state.family[0].isOpened = true
    state.family[1].isOpened = true
    state.isOpenedAddress = true
  },
  // 家族の中でご利用されたかがいるかどうかを押したかどうか
  setIsClickByUsedPast: (state, isClickByUsedPast: IsClickByUsedPastType) => {
    state.isClickByUsedPast = isClickByUsedPast
  },
  // ご実家住所と現住所が同じか？
  setFamilyAddressType: (state, familyAddressType: FamilyAddressType) => {
    state.familyAddressType = familyAddressType
  },
  setMypageMailAddress: (
    state: FamilyInformationSetting,
    mailAddress: string
  ) => {
    state.mypageMailAddress = mailAddress
  },
  // 画面の種類
  setScreenType: (state, screenType) => {
    state.screenType = screenType
  },
  // 保護者情報
  setParents: (state, parents) => {
    state.family[0].profile = parents
  },
  // 姉妹兄弟情報
  setSisterBrother: (state, sisterBrother) => {
    state.family[1].profile = sisterBrother
  },
  // その他ご家族情報
  setOtherCustomer: (state, otherCustomer) => {
    state.family[2].profile = otherCustomer
  },
  // 弊社をご利用されたお客様情報
  setCustomerProfile: (state, profile) => {
    state.customerProfile = profile
  },
  // 弊社をご利用された双子のお姉様(妹様)情報
  setTwinsProfile: (state, profile) => {
    state.twinsProfile = profile
  },
  delete: (state) => {
    state.patternLine = [0, 0]
    state.family = [
      {
        id: 1,
        title: '保護者様',
        isOpened: false,
        profile: [],
      },
      {
        id: 2,
        title: 'ご姉妹兄弟様',
        isOpened: false,
        profile: [],
      },
      {
        id: 3,
        title: 'その他ご家族様',
        isOpened: false,
        profile: [],
      },
    ]
    state.address = {
      zip_code: '',
      prefectures: '',
      address1: '',
      address2: '',
      phone_number: '',
    }
    state.isOpenedAddress = false
    state.isValidate = null
    state.isClickByUsedPast = FAMILY_INFORMATION.UN_SET // ご家族がいるかいないか NOTE: 既にデータが入っているかどうかで確認？
    state.familyAddressType = FAMILY_INFORMATION_INPUT.UN_SET // 住所が同じが違うか
    state.screenType = 1 // 1: 初期画面, 2: 入力画面, 3: 確認画面
    state.customerProfile = null // 弊社ご利用のお客様、お姉様の情報が入る
    state.twinsProfile = null // 双子のお姉様(妹様)の情報が入る
    state.mypageMailAddress = ''
  },
  deleteFamily: (state) => {
    state.family = [
      {
        id: 1,
        title: '保護者様',
        isOpened: false,
        profile: [],
      },
      {
        id: 2,
        title: 'ご姉妹兄弟様',
        isOpened: false,
        profile: [],
      },
      {
        id: 3,
        title: 'その他ご家族様',
        isOpened: false,
        profile: [],
      },
    ]
  },
}

export default mutations
