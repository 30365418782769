import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { OTHER_ITEMS } from '~/constants/estimate/config'
import { OtherItems } from '~/store/types'

export const useRestoreその他商品 = () => {
  const store = useComingStore<OtherItems>('OtherItem')

  const restoreその他商品 = (otherItems: Restate['items']['その他商品']) => {
    store.setOf<{ apply: OtherItems['apply']; items: OtherItems['items'] }>(
      'setInput',
      {
        apply: otherItems.length
          ? OTHER_ITEMS.種別.する
          : OTHER_ITEMS.種別.しない,
        items: otherItems,
      }
    )

    store.setIsValidate(false)
  }

  return {
    restoreその他商品,
  }
}
