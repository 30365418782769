import { computed } from '@nuxtjs/composition-api'
import { ZoriBagState } from '~/store/types'
import { useComingStore } from '../../ComingOfAgeStore'
import { useScreenTypeFlags } from '~/composable/estimate/domain/ComingOfAge/ScreenTypeFlags'
import { PlanFlags } from './types'
import { GRADUATION_ZORI } from '~/constants/estimate/comingOfAge'
import ProductClassification from '~/constants/enums/productClassification'
import {
  Bag,
  getZoriBagBadgeText,
  getZoriBagPlan,
  getZoriBagPlanFlags,
  getZoriBagPrices,
  getZoriBagPriceType,
  getZoriBagPriceTypeText,
  StoreValues,
} from './zoriBagUtils'
import {
  GEINPIN,
  UNDECIDED,
} from '~/composable/estimate/viewModel/comingOfAge/FieldProps/FieldProps草履バッグ'

export const useStateバッグ1個目 = () => {
  const valuesバッグ = useComingStore<ZoriBagState>('ZoriBag').getAll()
  const isPPDiscount = valuesバッグ.value.isPPDiscount
  const isPPAddingPrice = valuesバッグ.value.isPPAddingPriceBag
  const { showTypeフルセット, showType写真のみレンタル, showType持ち込み } =
    useScreenTypeFlags()

  return computed(() => {
    const flags = {
      isフルセット: showTypeフルセット.value,
      is写のみレンタル: showType写真のみレンタル.value,
      is持ち込み: showType持ち込み.value,
    }
    return getStateバッグ1個目(
      valuesバッグ.value,
      flags,
      isPPDiscount,
      isPPAddingPrice
    )
  })
}

export const getStateバッグ1個目 = (
  values草履バッグ: ZoriBagState,
  planFlags: PlanFlags,
  isPPDiscount: boolean,
  isPPAddingPrice: boolean
) => {
  const { planLineBag, inputBag, productBag, priceBag } = values草履バッグ
  const itemFlags = getZoriBagPlanFlags(planLineBag, productBag)
  const itemPlan = getZoriBagPlan(planLineBag, productBag)

  const store: StoreValues = {
    price: priceBag,
    input: inputBag,
    product: productBag,
    selections: planLineBag,
  }
  const priceTypeInfo = getZoriBagPriceType(store, planFlags)
  const selected同じ商品を単品購入 = planLineBag.includes(
    GRADUATION_ZORI.BUY_THE_SAME_PRODUCT
  )

  const is持込 =
    planLineBag.includes(GRADUATION_ZORI.BRING_IN) ||
    planLineBag.includes(GRADUATION_ZORI.BRING_IN_in振袖持込プラン)
  const isU系商品 = productBag.post_number_code.includes('U-')
  const is振袖レンタル時単品購入 =
    selected同じ商品を単品購入 || (!planFlags.isフルセット && isU系商品)
  const selectedレンタルこちらでお選びボタン =
    !inputBag.productName ||
    inputBag.productName === '-' ||
    inputBag.productName === 'こちらでお選びいたします。'
  let linkText = inputBag.productName
  let productName = inputBag.productName

  if (selectedレンタルこちらでお選びボタン && !is持込) {
    linkText = 'こちらでお選びいたします（レンタル）'
    productName = 'こちらでお選びいたします。'
  }

  const formatted商品id = () => {
    if (productBag.id === GEINPIN.id) return null
    if (productBag.id === UNDECIDED.id) return null
    if (itemFlags.isお持込) return null
    return productBag.id
  }

  const bag: Bag = {
    品目名: ProductClassification.toWord(ProductClassification.bag),
    品種区分: ProductClassification.bag,
    商品: {
      id: formatted商品id(),
      本体名: productBag.product_name || '',
      postNumberCode: productBag.post_number_code || '',
    },
    商品名: productName,
    小物プラン: itemPlan,
    レンタル価格: 0,
    購入価格: 0,
    単品購入価格: 0,
    プレミアムレンタル価格: 0,
    プレミアム購入価格: inputBag.buyPremiumPrice || 0,
    見積表示: priceTypeInfo?.showType ?? null,
    表示価格: priceTypeInfo?.showPrice ?? 0,
    label: getZoriBagBadgeText(planLineBag, productBag),
    cellLabel: getZoriBagPriceTypeText(priceTypeInfo),
    linkText,
    flags: {
      isランクアップ:
        itemFlags.isランクアップPP内 || itemFlags.isランクアップPP外,
      is振袖レンタル時単品購入,
      is振袖持込: planFlags.is持ち込み,
      selectedレンタルこちらでお選びボタン,
      selectedU系商品: isU系商品,
      is持込,
      isPPDiscount: isPPDiscount,
      isPPAddingPrice: isPPAddingPrice,
    },
  }

  const updatedPrices = getZoriBagPrices(store, bag, planFlags)
  bag.レンタル価格 = updatedPrices.レンタル価格
  bag.購入価格 = updatedPrices.購入価格
  bag.単品購入価格 = updatedPrices.単品購入価格
  bag.プレミアムレンタル価格 = updatedPrices.プレミアムレンタル価格
  bag.プレミアム購入価格 = updatedPrices.プレミアム購入価格
  return bag
}
