import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { FormValues備考 } from '~/composable/estimate/viewModel/graduation/Fields/Fields備考'

export const useRestore備考 = () => {
  const store = useGraduateStore<FormValues備考>('tab備考')

  const restore備考 = (bikou: string) => {
    store.setAll({
      bikou備考: bikou,
    })
  }
  return { restore備考 }
}
