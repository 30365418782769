import { TRYON_PHOTOS } from './enums/tryonPhotoType'

// 紹介者特典の写真タイプについて1: 前撮り、2: JK前撮り
export const REFERRAL_USER_PHOTO_TYPE = {
  PHOTO_BEFORE: 1,
  JK_PHOTO_BEFORE: 2,
} as const

export const 卒業式_お渡し方法 = {
  未選択: 0,
  来店: 1,
  お送り: 2,
} as const

// 契約書で母娘プランを適用する/適用しない（1: 適用する, 2: 適用しない）
export const MOTHER_AND_DAUGHTER_PLAN_CONTRACT = {
  APPLY: 1,
  DISAPPLY: 2,
} as const

// どのプランを母娘プランに適用するか（前撮り・JK前撮り）
export const MOTHER_AND_DAUGHTER_PLAN_USING_EVENT = {
  前撮り: 1,
  JK前撮り: 2,
}

export const JK前撮り_適用 = {
  適用する: 1,
  適用しない: 2,
} as const

export const JK前撮り_振袖種別 = {
  レンタル: 1,
  お持込: 2,
} as const

// 契約書 写真をアップロードするしないの選択
export const photosUploadOptions = {
  photoUpload: [
    {
      id: TRYON_PHOTOS.PHOTOUPLOADする,
      text: 'する',
    },
    {
      id: TRYON_PHOTOS.PHOTOUPLOADしない,
      text: 'しない',
    },
  ],
}

export const 希望のお届け時間帯 = {
  UNSPECIFIED: 1,
  FROM_AM_EIGHT_TO_TWELVE: 2,
  FROM_PM_TWO_TO_FOUR: 3,
  FROM_PM_FOUR_TO_SIX: 4,
  FROM_PM_SIX_TO_EIGHT: 5,
  FROM_PM_SEVEN_TO_NINE: 6,
} as const

export const MAKEUP_TITLE = 'で美容室でのメイクを希望しますか？'
export const MAKEUP_ID = {
  YES: 1, //  はい（初期値）
  NO: 2, // いいえ
} as const

export const needMakeUpSelections = [
  {
    id: MAKEUP_ID.YES,
    text: 'はい',
  },
  {
    id: MAKEUP_ID.NO,
    text: 'いいえ',
    subText1: '※ご自身でメイク',
  },
]

export const MAKEUP_LABEL = 'メイク'

export const MAKEUP_METHOD = {
  SALON: '美容室',
  SELF: 'ご自身',
}

// 希望のお届け時間帯
export const desiredTimeOption = [
  { id: 希望のお届け時間帯.UNSPECIFIED, name: '指定なし' },
  {
    id: 希望のお届け時間帯.FROM_AM_EIGHT_TO_TWELVE,
    name: '(午前中) 8時〜12時',
  },
  { id: 希望のお届け時間帯.FROM_PM_TWO_TO_FOUR, name: '14時～16時' },
  { id: 希望のお届け時間帯.FROM_PM_FOUR_TO_SIX, name: '16時～18時' },
  { id: 希望のお届け時間帯.FROM_PM_SIX_TO_EIGHT, name: '18時～20時' },
]

// 希望のお届け時間帯（復元用）
export const desiredTimeOptionForRestore = [
  ...desiredTimeOption,
  { id: 希望のお届け時間帯.FROM_PM_SEVEN_TO_NINE, name: '19時～21時' },
]

// 契約変更時の検索かどうか
export const IS_CHANGE_CONTRACT = {
  新規契約: 0,
  契約変更: 1,
} as const

export const 現品PRICE = {
  ZERO: '¥0',
  RENTAL_PRICE: '¥3,300',
  SINGLE_RENTAL_PRICE: '¥6,600',
  SINGLE_BUY_PRICE_INSET: '¥8,800',
  SINGLE_BUY_PRICE_RANKUP: '¥5,500',
}

// 紹介者特典
export const REFERRAL_BOUNS = {
  ご紹介者: 1,
  ご利用者: 2,
} as const

/**
 * 参列お渡し方法（API用）
 * NOTE: タブレット上の値とAPIで渡す値が異なるため
 */
export const DELIVERY_PROCESSING_TYPES = {
  ご来店にてお渡し: 0,
  お送り: 1,
} as const

export const 母娘プラン訪問着レンタル前撮り表示名 =
  '母娘プラン（前撮り）訪問着レンタル(着付代込み)'

export const 母娘プラン訪問着レンタルJK前撮り表示名 =
  '母娘プラン（JK前撮り）訪問着レンタル(着付代込み)'

/** アラートテキスト */
export const ALERT_TEXT = {
  DIFFERENT_SHOPID_MSG:
    '見積を作成した店舗がログイン店舗と異なるため、見積コピーから行ってください。',
  DIFFERENT_SHOPID_MSG_NOTE: '該当の見積番号は下記になります。',
  NO_SIGNATURE_URL_MSG:
    '署名が保存できませんでした。\n再度署名を書き直してください。',
  NO_BALANCE_REFUND_MSG: '残金や返金が発生しないため支払金額の入力は不要です',
  NO_PAYMENT_DUE_MSG: '支払金額が0円のため支払金額の入力は不要です',
  SAME_CUSOMTER:
    '検索された方は利用者として登録されています。顧客として選択することはできません。',
  SELECTED_FAMILY_ID:
    'この顧客は既に選択済みです。別の顧客を選択してください。',
}

export const WARRANTY_SERVICE_START_DATE = '2024-09-11'
