import { MutationTree } from 'vuex'
import { getInitialStore, State } from './state'

const mutations: MutationTree<State> = {
  // ** All Input Data **
  setData: (state, data) => {
    state.data = data
  },

  // ** タブ **
  setTabList: (state, tabList) => {
    state.tabList = tabList
  },

  // ** 初期化 **
  initAll: (state) => {
    const initial = getInitialStore()
    state.data = initial.data
    state.tabList = initial.tabList
    state.finalPageList = initial.finalPageList
    state.contractTotalPrice = initial.contractTotalPrice
    state.contractId = initial.contractId
  },
  setFinalPageList: (state, finalPageList) => {
    state.finalPageList = finalPageList
  },
  setContractId: (state, contractId) => {
    state.contractId = contractId
  },
  setContractTotalPrice: (state, contractTotalPrice) => {
    state.contractTotalPrice = contractTotalPrice
  },

  setPaymentDeadline: (state, paymentDeadline) => {
    state.data.payment.paymentDeadline = paymentDeadline
  },

  // ** 契約日 **
  setContractDate: (state, contractDate) => {
    state.data.contractDate = contractDate
  },
}

export default mutations
