import { usePrice } from '~/composable/general/Price'
import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { FormValues割引 } from '~/composable/estimate/viewModel/comingOfAge/Fields/Fields割引'

export const useRestore割引 = () => {
  const store = useComingStore<FormValues割引>('Discount')
  const { toString } = usePrice()

  const restore割引 = (state: Restate['discounts']['割引']) => {
    store.setAll({
      waribiki適用: state.optionID適用,
      waribiki金額入力: toString(state.値引額),
    })
    store.setIsValidate(false)
  }

  return {
    restore割引,
  }
}
