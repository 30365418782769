import { Ref, useStore } from '@nuxtjs/composition-api'
import { usePlanTypeFlags } from '~/composable/estimate/domain/ComingOfAge/PlanTypeFlags'
import { useSelectedPlanListType } from '~/composable/estimate/domain/ComingOfAge/SelectedPlanListType'
import { EstimateState } from '~/composable/estimate/repository/comingOfAge/EstimateState'
import { useState割引 } from '~/composable/estimate/repository/comingOfAge/states/StateOtherTabs'
import { useStateWPhoto } from '~/composable/estimate/repository/comingOfAge/states/StateWフォト'
import { useState振袖 } from '~/composable/estimate/repository/comingOfAge/states/State振袖'
import { useState見積一覧 } from '~/composable/estimate/repository/comingOfAge/states/State見積一覧'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { useExtendsEstimateStore } from '~/composable/estimate/repository/ExtendsEstimateStore'
import { EstimateAddition } from '~/constants/enums/estimateAddition'
import { EstimateDiscount } from '~/constants/enums/estimateDiscount'
import { EstimateSinglePriceType } from '~/constants/enums/estimateSinglePriceType'
import ProductClassification from '~/constants/enums/productClassification'
import {
  DiscountItem,
  ExtendsEstimateState,
  ProductItem,
} from '~/store/Contracts/ExtendsEstimate/state'
import { EstimateListState, FurisodeState, ObiState } from '~/store/types'
import { useCalculator } from './Calculators'
import { HeaderItem, Item, ItemWPhoto, PriceItem } from './types'
import { State as PricesState } from '~/store/PriceTable/state'
import { useState帯 } from '~/composable/estimate/repository/comingOfAge/states/State帯'
import { BaseEstimateState, GeneralItem } from '~/store/Contracts/types'
import { ItemPlan } from '~/constants/enums/itemPlan'

export const useStoreSetter = (
  listItems: Ref<Item[]>,
  wPhotolistItems: Ref<ItemWPhoto[]>
) => {
  const store = useStore()
  const calculator = useCalculator(listItems, wPhotolistItems)
  const store見積一覧 =
    useComingStore<EstimateListState['fields']>('EstimateList')
  const comingStore = useComingStore<FurisodeState>('Furisodes')
  const store見積継承 = useExtendsEstimateStore()
  const state見積一覧 = useState見積一覧()
  const state振袖 = useState振袖()
  const state帯 = useState帯()
  const stateWフォト = useStateWPhoto()
  const state割引 = useState割引()
  const { isフルセット } = usePlanTypeFlags()
  const { is購入プラン } = useSelectedPlanListType()
  const baseEstimateItems: BaseEstimateState['estimate']['items'] =
    store.getters['Contracts/BaseEstimate/getItems'] ?? []

  /**
   * 本体の見積の選択されたプラン列を元に内容をストアに保存する
   */
  const storeSelected = (selectedPlan: number) => {
    if (!selectedPlan) return
    const planOption = isフルセット.value
      ? {
          is正絹長襦袢: state見積一覧.value.is正絹長襦袢,
          is高級手縫い: state見積一覧.value.is高級手縫い,
          is購入プラン: is購入プラン.value,
        }
      : undefined

    store見積一覧.set<'totalPrice'>(
      'totalPrice',
      calculator.totalEstimateAmount.value
    )
    store見積一覧.set<'selectedPlan'>('selectedPlan', selectedPlan)

    const priceTable = comingStore.getPrices()

    /** 暫定追加：見積継承ストアへの保存処理 */
    const data2 = makeExtendsEstimateData(
      selectedPlan,
      listItems.value,
      priceTable,
      {
        furisode: state振袖.value.着物検索,
        obi: state帯.value.仕立て上り商品,
      },
      state割引.value,
      planOption,
      baseEstimateItems
    )
    store見積継承.setAll(data2)
  }

  /**
   *  Wフォトの見積の選択されたプラン列を元に内容をストアに保存する
   */
  const storeSelectedWPhoto = (selectedPlan: number) => {
    if (!stateWフォト.value.firstPage.isWPhoto適用 || !selectedPlan) return

    store見積一覧.set<'photoTotalPrice'>(
      'photoTotalPrice',
      calculator.wPhoto.selectedTotalAmount.value
    )
    store見積一覧.set<'selectedWPPlan'>('selectedWPPlan', selectedPlan)

    const priceTable = comingStore.getPrices()

    /** 暫定追加：見積継承ストアへの保存処理  */
    const allState = store見積継承.getAll()
    const data2 = makeExtendsEstimateData(
      selectedPlan,
      wPhotolistItems.value,
      priceTable,
      {
        furisode: stateWフォト.value.振袖.着物検索,
        obi: stateWフォト.value.帯.仕立て上り商品,
      },
      undefined,
      undefined,
      undefined,
      true
    )
    store見積継承.setAll({
      ...allState.value,
      wPhoto: {
        products: data2.products,
        optionalPrices: data2.optionalPrices,
        discounts: data2.discounts,
      },
    })
  }

  /**
   * 見積一覧ページ表示時状態を元に関連ストアに情報を保存する
   */
  const storeToContract = () => {
    const selectedPlan = state見積一覧.value.selectedプラン列
    storeSelected(selectedPlan)

    if (stateWフォト.value.firstPage.isWPhoto適用) {
      const selectedWPhotoPlan = state見積一覧.value.selectedWフォトプラン列
      storeSelectedWPhoto(selectedWPhotoPlan)
    }
  }

  return { storeSelected, storeSelectedWPhoto, storeToContract }
}

type Column = 'column1' | 'column2' | 'column3' | 'column4'
export type PlanOption = {
  is正絹長襦袢: boolean
  is購入プラン: boolean
  is高級手縫い: boolean
}
const getColumn = (selectedColumn: number) => {
  switch (selectedColumn) {
    case 1:
      return 'column1'
    case 2:
      return 'column2'
    case 3:
      return 'column3'
    case 4:
      return 'column4'
    default:
      throw new Error('想定していないプラン列番号が指定されました')
  }
}

/**
 * 見積一覧から選択した見積情報を契約書側で参照する値にフォーマットする関数
 * @param selectedColumn 選択されたカラムの行数名
 * @param listItems 見積一覧に表示されている内容の配列
 * @param products 検索して取得した着物などの情報
 * @param planOption フルセットの場合に必要な条件判定フラグ
 */
export const makeExtendsEstimateData = (
  selectedColumn: number,
  listItems: Item[] | ItemWPhoto[],
  priceTable: PricesState['prices'],
  products: {
    furisode?: FurisodeState['product']
    obi?: ObiState['product']
  },
  state割引?: EstimateState['割引'],
  planOption?: PlanOption,
  baseEstimateItems?:
    | (GeneralItem & {
        catalog_code?: string | undefined
      })[]
    | null
    | undefined,
  isWフォト?: boolean
) => {
  // 純粋に見積一覧から取得された情報を整形する
  const formattedListItems = formatListItems(
    selectedColumn,
    listItems,
    priceTable,
    planOption,
    baseEstimateItems
  )

  // NOTE: 項目名で並べ替える
  formattedListItems.optionalPrices.sort((a, b) => {
    if (a.label === b.label) return 0
    return a.label > b.label ? 1 : -1
  })
  formattedListItems.discounts.sort((a, b) => {
    if (a.label === b.label) return 0
    return a.label > b.label ? 1 : -1
  })

  // NOTE: 着物や袴を先頭のまま、小物は種別IDが小さい順にソートする並べ替えの処理
  // その他商品もrankUpIdがnullのため、sortから除外
  const kimonoProds = formattedListItems.products.filter(
    (item) =>
      !item.rankUpId && item.productTypeId !== ProductClassification.otherItem
  )
  const itemsProds = formattedListItems.products.filter((item) => item.rankUpId)
  itemsProds.sort((a, b) => {
    if (a.productTypeId === b.productTypeId) return 0
    if (!a.productTypeId || !b.productTypeId) return 0
    return a.productTypeId > b.productTypeId ? 1 : -1
  })

  /** その他商品 */
  const otherItem = formattedListItems.products.filter(
    (item) => item.productTypeId === ProductClassification.otherItem
  )
  formattedListItems.products = [...kimonoProds, ...itemsProds, ...otherItem]

  // 他のストアからの情報を追加する
  formattedListItems.products = formattedListItems.products.map<ProductItem>(
    (item) => {
      switch (item.productTypeId) {
        case ProductClassification.hurisode: {
          if (!products.furisode) return item
          if (products.furisode.type === 'finished') {
            return {
              ...item,
              product: {
                type: 'finished',
                result: products.furisode,
              },
            }
          }
          if (products.furisode.type === 'catalog_furisode') {
            return {
              ...item,
              product: {
                type: 'catalogHurisode',
                result: products.furisode,
              },
            }
          }
          return item
        }
        case ProductClassification.obi: {
          if (products.obi) {
            return {
              ...item,
              product: {
                type: 'finished',
                result: {
                  ...products.obi,
                  type: 'finished',
                },
              },
            }
          }
          return item
        }
        default:
          return item
      }
    }
  )

  formattedListItems.discounts = formattedListItems.discounts.map<DiscountItem>(
    (item) => {
      if (!state割引) return item
      let discountType = undefined
      let apiProps: DiscountItem['apiProps'] = undefined
      if (item.label.includes('妹様')) {
        discountType = EstimateDiscount.sisterdiscount
        apiProps = {
          type: state割引.妹様割.割引.種別,
          rate: state割引.妹様割.割引.率,
        }
      } else if (item.label.includes('双子')) {
        discountType = EstimateDiscount.twinsDiscount
        apiProps = {
          type: state割引.双子割.割引.種別,
          rate: state割引.双子割.割引.率,
        }
      } else if (item.label.includes('従業員')) {
        discountType = EstimateDiscount.employeeDiscount
        apiProps = {
          type: state割引.従業員割.割引.種別,
          amount: state割引.従業員割.割引.金額,
        }
      } else if (item.label === 'その他割引') {
        discountType = EstimateDiscount.noType
        apiProps = {
          type: state割引.割引.割引.種別,
          amount: state割引.割引.割引.金額,
        }
      }
      return {
        ...item,
        discountType,
        apiProps,
      }
    }
  )

  formattedListItems.optionalPrices = formattedListItems.optionalPrices.map(
    (item) => {
      const additionType = (() => {
        switch (true) {
          case item.label.includes('帯仕立て代') && !isWフォト:
            return EstimateAddition.obiShitate
          case item.label.includes('別衿加工') && !isWフォト:
            return EstimateAddition.separateCollarProcessing
          case item.label.includes('帯仕立て代') && isWフォト:
            return EstimateAddition.wPhotoObiShitate
          case item.label.includes('別衿加工') && isWフォト:
            return EstimateAddition.wPhotoBetsueriKakou
          case item.label.includes('長襦袢'):
            return EstimateAddition.nagajuban
          case item.label.includes('足し布'):
            return EstimateAddition.tashinuno
        }
      })()
      return {
        ...item,
        additionType,
      }
    }
  )

  return formattedListItems
}

const formatListItems = (
  selectedColumn: number,
  listItems: Item[] | ItemWPhoto[],
  priceTable: PricesState['prices'],
  planOption?: PlanOption,
  baseEstimateItems?:
    | (GeneralItem & {
        catalog_code?: string | undefined
      })[]
    | null
    | undefined
) => {
  const column: Column = getColumn(selectedColumn)
  // WPhoto側の値も同じように受け取って処理するためにここで Item[]の形に合わせる
  const list: Item[] = listItems.map((item: Item | ItemWPhoto) => {
    const empty = { price: 0 }
    return {
      ...item,
      column3: 'column3' in item ? item.column3 : empty,
      column4: 'column4' in item ? item.column4 : empty,
    }
  })

  // NOTE: 以下のreduceでこの形式にlistItemsの配列を仕分けていく
  const initialData: ExtendsEstimateState = {
    products: [], // 振袖や小物などの商品
    discounts: [], // 割引系
    optionalPrices: [], // 着付けや補正などの追加金額
    photoPlan: [],
    wPhoto: {
      products: [],
      discounts: [],
      optionalPrices: [],
    },
  }

  let count = 0
  /**
   * 草履・バッグPP適用時、未定・価格タイプなしの場合に渡すデータの変換を行う
   * @param item
   * @param columnValue
   * @returns
   */
  const convertedItem = (item: HeaderItem, columnValue: PriceItem) => {
    const isNoPPAddingPriceUndecided =
      (item.product_type_id === ProductClassification.zori ||
        item.product_type_id === ProductClassification.bag) &&
      item.rankType !== '単品購入' &&
      item.rankType !== '単品レンタル'
    item.isPPDiscount &&
      !item.isPPAddingPrice &&
      item.rankTypeId === ItemPlan.toBeDecided
    if (isNoPPAddingPriceUndecided) return { priceTypeName: '', rankType: '' }
    return { priceTypeName: columnValue.text, rankType: item.rankType }
  }

  return list.reduce<ExtendsEstimateState>((data, datum) => {
    const columnValue = datum[column]
    const { priceTypeName, rankType } = convertedItem(datum.item, columnValue)

    let detail = priceTypeName || '' // 単品購入・単品レンタル
    if (datum.item.linkText) {
      detail += detail ? '／' : ''
      detail += datum.item.linkText // 商品名
    }

    const item = {
      name: datum.item.name,
      detail: detail || 'ー',
      price: columnValue.price,
      isInsertDailyReport: datum.item.isInsertDailyReport ?? false,
      productTypeId: datum.item.product_type_id ?? null,
      rankType: rankType ?? null,
      rankTypeId: datum.item.rankTypeId ?? null,
    }

    /**
     * NOTE:【暫定対応】現状、新規契約時にその他商品の項目が契約変更時の表示と異なるため、
     * BaseEstimateを元に表示する
     */
    if (
      datum.item.product_type_id === ProductClassification.otherItem &&
      !detail &&
      baseEstimateItems
    ) {
      const baseItem = baseEstimateItems.find(
        (item) =>
          item.product_type_id === ProductClassification.otherItem &&
          item.order_number === count + 1
      )
      if (baseItem) {
        count++
        item.name = count === 1 ? 'その他商品' : `その他商品 - ${count}`
        item.detail =
          EstimateSinglePriceType.toWord(baseItem.price_type) +
          '／' +
          baseItem.product_name
      }
    }

    if (planOption) {
      // 長襦袢の処理
      if (datum.isNagajuban && planOption.is購入プラン) {
        item.detail = planOption.is正絹長襦袢 ? '正絹長襦袢' : '長襦袢'
        item.price = planOption.is正絹長襦袢
          ? priceTable.estimate.正絹長襦袢
          : 0
      }

      // 仕立ての処理
      if (datum.isTailoring && planOption.is購入プラン) {
        item.detail = planOption.is高級手縫い ? '高級手縫い' : 'ハイテク仕立て'
        item.price = planOption.is高級手縫い
          ? priceTable.estimate.高級手縫い
          : 0
      }
    }

    // お写真プランの処理
    if (datum.item.name === '写真アルバム代') {
      item.detail = priceTypeName ?? 'ー'
    }

    if (datum.item.addition || datum.item.addition2) {
      data.optionalPrices.push({
        label: item.name,
        price: item.price,
        detail: item.detail,
        isInsertDailyReport: item.isInsertDailyReport,
      })
      return data
    }

    if (datum.item.discount) {
      data.discounts.push({
        label: item.name,
        price: item.price,
        detail: item.detail,
        isInsertDailyReport: item.isInsertDailyReport,
      })
      return data
    }

    data.products.push({
      productTypeId: item.productTypeId,
      label: item.name,
      price: item.price ?? 0,
      rankUpName: item.rankType,
      rankUpId: item.rankTypeId,
      detail: item.detail,
      priceType: EstimateSinglePriceType.toId(priceTypeName),
      isInsertDailyReport: item.isInsertDailyReport,
    })

    return data
  }, initialData)
}
