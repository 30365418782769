




























import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  useRoute,
  watch,
  ref,
  computed,
} from '@nuxtjs/composition-api'
import EstimateHeaderGraduationCeremony from '~/components/graduationCeremony/organisms/EstimateHeaderGraduationCeremony.vue'
import NextButtonFooterWithValidation from '~/components/graduationCeremony/organisms/NextButtonFooterWithValidation.vue'
import GraduationEstimateResultFooter from '~/components/graduationCeremony/organisms/GraduationEstimateResultFooter.vue'

import { prohibitExpansion, reloadEvent } from '@/utils/screenControl'
import { ESTIMATE } from '~/constants/estimate/config'
import { useTemporarilySaved } from '~/composable/api/useTemporarilySaved'
import { usePageFlags } from '~/composable/estimate/domain/PageFlags'
import { useEstimateRestorer } from '~/composable/estimate/service/graduation/EstimateRestorer'
import { useAuth } from '~/composable/api/useAuth'

export default defineComponent({
  name: 'graduationCeremony',
  components: {
    NextButtonFooterWithValidation,
    EstimateHeaderGraduationCeremony,
    GraduationEstimateResultFooter,
  },
  setup() {
    const route = useRoute()
    const { inTabPage, inCompletePage, inEstimateListPage } = usePageFlags()
    const { restoreEstimate } = useEstimateRestorer()
    const { saveEstimateTemporarily } = useTemporarilySaved()
    const { validateSession } = useAuth()
    const typeId = ESTIMATE.卒業式.id
    const url = computed(() => route.value.path)

    // NOTE: フッター内で発火する見積作成ローディング中判定をこの層に受け渡すためのフラグ
    const isLoading = ref(false)

    // 保存ボタン押下時に発火
    const save = () => {
      saveEstimateTemporarily(typeId)
    }

    const { addReloadEvent, removeReloadEvent } = reloadEvent()

    // NOTE: 別見積を作成する時に見積を復元する処理
    // タブの中を移動している間は取得処理が破棄されることがないようにlayout層に設置している
    watch(inTabPage, () => {
      if (!inTabPage.value) return
      const query = route.value.query
      if (query.mode === 'copy' && query.estimateId && query.branch) {
        restoreEstimate(Number(query.estimateId), Number(query.branch))
      }
    })

    onMounted(() => {
      validateSession()
      prohibitExpansion()
      addReloadEvent()
    })

    onBeforeUnmount(() => {
      removeReloadEvent()
    })

    return {
      save,
      inTabPage,
      inCompletePage,
      inEstimateListPage,
      isLoading,
      typeId,
      url,
    }
  },
})
