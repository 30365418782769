import { EstimateState } from '~/composable/estimate/repository/graduation/EstimateState'

export const usePlanFormatter = (estimateState: EstimateState) => {
  const getPlan = () => {
    if (!estimateState.value.プラン.見積プラン)
      throw new Error('プランが選択されていません')
    return estimateState.value.プラン.見積プラン
  }

  const getUseDate = () => {
    if (!estimateState.value.プラン.卒業式使用日)
      throw new Error('使用日が選択されていません')
    return estimateState.value.プラン.卒業式使用日
  }

  const getContractServiceType = () => {
    if (!estimateState.value.プラン.サービス区分)
      throw new Error('着付け区分が選択されていません')
    return estimateState.value.プラン.サービス区分
  }

  const getComingOfAgeContractId = () => {
    return estimateState.value.プラン.成人式契約?.contract_id
  }

  return {
    getPlan,
    getUseDate,
    getContractServiceType,
    getComingOfAgeContractId,
  }
}
