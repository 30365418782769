/**
 * 見積作成&詳細APIにのみ登場するid
 * https://docs.google.com/spreadsheets/d/1p0MxMw2VO6PmJCs4UzyM4rqfO6BqBypM/edit#gid=1427553124
 * このシートのテーブルに対応しています。
 */

// 見積作成&詳細API hurisodeパラメーターのdiscount_type
export const Enum見積振袖DiscountType = {
  '50%引き': 1,
  '80%引き': 2,
  固定金額: 3,
  友の会割引: 4,
} as const

// 見積作成&詳細API hakamaパラメーターのdiscount_type
export const Enum見積袴DiscountType = {
  '50%引き': 1,
  '11,000円引': 2,
} as const

// 見積作成&詳細API アクリルスタンド
export const Enum見積アクリルスタンド = {
  A4: 1,
  A5: 2,
} as const

// 見積作成&詳細API 紹介者特典 is_passed
export const Enum見積紹介者特典お渡し済み = {
  未: 0,
  お渡し済み: 1,
} as const

// 見積作成&詳細API 紹介者特典 user.photo_type
export const Enum見積紹介者特典写真ポーズ反映先 = {
  前撮り: 1,
  JK前撮り: 2,
} as const

// 見積作成&詳細API 見積一覧 hurisode.sewing
export const Enum見積振袖仕立て = {
  ハイテク仕立て: 1,
  高級縫い: 2,
} as const

// 見積/契約作成&詳細API w_photo_plan.selected_plan_type
export const EnumWフォトplan_type = {
  レンタル: 0,
  持込: 1,
} as const

// 契約作成&詳細API w_photo_plan.selected_plan_type
export const Enum契約Plan_option = {
  スタンダード: 1,
  プレミアム: 2,
} as const
