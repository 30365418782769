import { GetterTree } from 'vuex'
import { RootState } from '../../../types'
import { State } from './state'

const getters: GetterTree<State, RootState> = {
  // ** All Input Data **
  getState: (state) => {
    return state.data
  },

  // ** タブ **
  getTabList: (state) => {
    return state.tabList
  },
  // ** 最終ページ **
  getFinalPageList: (state) => {
    return state.finalPageList
  },
  // ** 最終ページかどうか **
  getIsFinalPages: (state) => {
    // タブのisCurrentが全てfalseになると最終ページが表示される
    return state.tabList.every((tab) => !tab.isCurrent)
  },
  getContractId: (state) => {
    return state.contractId
  },

  // 支払い情報
  getPayment: (state) => {
    return state.data.payment
  },

  getContractTotalPrice: (state) => {
    return state.contractTotalPrice
  },
}

export default getters
