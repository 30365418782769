import { computed, Ref, watch } from '@nuxtjs/composition-api'
import { EstimateDetail, Items } from '~/api/estimateRepository'
import { ItemPlan } from '~/constants/enums/itemPlan'
import ProductId from '~/constants/enums/productClassification'
import {
  KimonoSearchQueryParams,
  useSearchProduct,
} from '~/composable/api/useSearchProduct'
import { PLAN } from '~/constants/estimate/comingOfAge'
import { EstimateSinglePriceType } from '~/constants/enums/estimateSinglePriceType'
import { useRestateWPhoto振袖 } from './Restate振袖'

export const useRestateWPhoto帯 = (
  estimate: Ref<EstimateDetail | undefined>
) => {
  const restateプラン = useRestateWPhoto振袖(estimate)
  const { fetchFinishedKimonoList, finishedKimonoList } = useSearchProduct()

  watch(estimate, () => {
    if (!estimate.value?.w_photo_plan?.items) {
      return
    }
    const obiItem = estimate.value.w_photo_plan.items.find(
      (item) => item.product_type_id === ProductId.obi
    )
    if (obiItem?.product_id) {
      const params: KimonoSearchQueryParams = {
        keyword: obiItem.product_id,
        keyword_types: [0],
        product_type_id: ProductId.obi,
        has_purchased: 1,
      }
      fetchFinishedKimonoList(params)
    }
  })

  return computed(() => {
    if (!estimate.value?.w_photo_plan?.items || !restateプラン.value) {
      return null
    }
    const is振袖持込 = restateプラン.value.isお持込
    const items = estimate.value.w_photo_plan.items
    const obiItem = items.find((item) => item.product_type_id === ProductId.obi)
    if (!obiItem) return

    const {
      rental_price,
      purchase_price,
      plan,
      price_type,
      product_name,
      premium_rental_price,
      product_id,
    } = obiItem

    return {
      optionId種別: getOptionIdOf種別(plan),
      optionId形態: getOptionIdOf形態(plan),
      optionId仕立て上りか: getOptionIdOf仕立て上りか(product_id),
      optionId見積選択: getOptionIdOf見積表示(price_type),
      仕立て上り検索結果: finishedKimonoList.value[0],
      商品名: product_name === '-' || !product_name ? '' : product_name,
      レンタル価格: Number(rental_price),
      プレミアムレンタル価格: Number(premium_rental_price ?? 0),
      購入価格: Number(purchase_price ?? 0),
      flags: getConditionalFlags(obiItem, is振袖持込),
    }
  })
}

/**
 * 復元条件に関わる条件式を定義する
 */
const getConditionalFlags = (item: Items, is振袖持込: boolean) => {
  const is持込 = item.plan === ItemPlan.bringIn

  const isセット内 = !is振袖持込 && !is持込 && item.plan === ItemPlan.inSet
  const isランクアップPP内 =
    !is振袖持込 && !is持込 && item.plan === ItemPlan.rankUpInPremium
  const isランクアップPP外 =
    !is振袖持込 && !is持込 && item.plan === ItemPlan.rankUpOutPremium
  const is仕立て上り = !is持込 && !!item.product_id
  return {
    is持込,
    isセット内,
    isランクアップPP内,
    isランクアップPP外,
    is仕立て上り: is仕立て上り,
    is振袖持込: is振袖持込,
  }
}

/**
 * 選択肢系の項目の初期値(選択されたoptionのid)を再計算する関数群
 */
const getOptionIdOf種別 = (itemPlan: Items['plan']) => {
  // お持込は全パターン共通ID、その他は振袖のプランによって変動する
  if (itemPlan === ItemPlan.bringIn) {
    return PLAN.BRING_IN
  } else if (itemPlan === ItemPlan.toBeDecided) {
    return PLAN.SINGLE_RENTAL
  } else if (
    itemPlan === ItemPlan.rankUpOutPremium ||
    itemPlan === ItemPlan.rankUpInPremium ||
    itemPlan === ItemPlan.inSet
  ) {
    return PLAN.RENTAL
  } else {
    return 0
  }
}

const getOptionIdOf形態 = (itemPlan: Items['plan']) => {
  switch (itemPlan) {
    case ItemPlan.inSet:
      return PLAN.IN_THE_SET
    case ItemPlan.onlyRentalRankUp:
      return PLAN.RANK_UP_ONLY_RENTAL
    case ItemPlan.rankUpInPremium:
      return PLAN.RANK_UP_IN_PREMIUM
    case ItemPlan.rankUpOutPremium:
      return PLAN.RANK_UP_OUT_PREMIUM
    default:
      return 0
  }
}

const getOptionIdOf仕立て上りか = (productId: Items['product_id']) => {
  return productId ? PLAN.YES : PLAN.NO
}

const getOptionIdOf見積表示 = (priceType: Items['price_type']) => {
  switch (priceType) {
    case EstimateSinglePriceType.singleRentalPrice:
      return PLAN.SINGLE_RENTAL_PRICE
    case EstimateSinglePriceType.singlePurchasePrice:
      return PLAN.SINGLE_BUY_PRICE
    default:
      return 0
  }
}
