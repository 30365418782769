export const CHECKBOX_TYPES = {
  MAINTENANCE_AND_FITTING: 1,
  ARRIVAL_CHECK: 2,
  PRE_PHOTOSHOT_HANDOVER: 3,
} as const
export type CheckboxType = typeof CHECKBOX_TYPES[keyof typeof CHECKBOX_TYPES]

export const CHECKBOX_STATUSES = {
  EMPTY: 1,
  CHECKED: 2,
  DISABLED: 3,
  NOT_CHECKABLE: 9,
} as const
export type CheckboxStatus =
  typeof CHECKBOX_STATUSES[keyof typeof CHECKBOX_STATUSES]

export const CHECKBOX_SWITCH_TABLE = {
  [CHECKBOX_STATUSES.EMPTY]: {
    checkMode: CHECKBOX_STATUSES.CHECKED,
    disableMode: CHECKBOX_STATUSES.DISABLED,
  },
  [CHECKBOX_STATUSES.CHECKED]: {
    checkMode: CHECKBOX_STATUSES.EMPTY,
    disableMode: CHECKBOX_STATUSES.CHECKED,
  },
  [CHECKBOX_STATUSES.DISABLED]: {
    checkMode: CHECKBOX_STATUSES.DISABLED,
    disableMode: CHECKBOX_STATUSES.EMPTY,
  },
  [CHECKBOX_STATUSES.NOT_CHECKABLE]: {
    checkMode: CHECKBOX_STATUSES.NOT_CHECKABLE,
    disableMode: CHECKBOX_STATUSES.NOT_CHECKABLE,
  },
}
