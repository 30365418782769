/**
 * [単品価格の種類]
 */
export class EstimateSinglePriceType {
  /** 単品レンタル価格 */
  static readonly singleRentalPrice = 1
  /** 単品購入価格 */
  static readonly singlePurchasePrice = 2

  // NOTE:　undefinedが入っても「お持込」を表示しない場合があるので第二引数を追加した
  static toWord = (val?: number, not持込: boolean = false) => {
    if (!val && !not持込) {
      return 'お持込'
    }

    switch (val) {
      case EstimateSinglePriceType.singleRentalPrice:
        return '単品レンタル'
      case EstimateSinglePriceType.singlePurchasePrice:
        return '単品購入'
      default:
        return ''
    }
  }

  static toId = (label?: string) => {
    if (!label) {
      return undefined
    }

    switch (label) {
      case '単品レンタル':
        return EstimateSinglePriceType.singleRentalPrice
      case '単品購入':
        return EstimateSinglePriceType.singlePurchasePrice
      default:
        return undefined
    }
  }
}
