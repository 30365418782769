import { GraduationCeremonyState } from '../../types'

const state = (): GraduationCeremonyState => ({
  customerId: 0, // 見積書のお客様ID
  dressing: 1, // 着付区分 (1: 弊社着付け, 4: 着付なし)
  ceremonyInfo: {
    date: '', // 卒業式使用日
    shopId: 0, // 着付場所id
    shopName: '', // 着付場所
    abbreviatedName: '', // 着付場所(略称)
    entranceTime: null, // 着付場所入場時間
    remarks: null, // 備考
  },
  pass: {
    option: null, // お渡し方法 (1: ご来店, 2: お送り)
    date: null, // 卒業式お渡し日
    shopId: 0, // お渡し場所id
    shopName: '', // 卒業式お渡し場所
    abbreviatedName: '', // 卒業式お渡し場所(略称)
    arrivalDate: null, // 到着日
  },
  delivery: {
    option: null, // お届け先住所 (1: ご利用者様現住所, 2: ご実家住所, 3: 別住所)
    // ご利用者様
    zip_code: null, // 郵便番号
    prefecture_id: 0, // 都道府県
    address1: null, // 市区町村
    address2: null, //番地以降(建物名など)
    phone_number: null, // 電話番号
    // ご実家
    home_zip_code: null, // 郵便番号
    home_prefecture_id: 0, // 都道府県
    home_address1: null, // 市区町村
    home_address2: null, //番地以降(建物名など)
    home_phone_number: null, // 電話番号
    // 別住所
    another_zip_code: null, // 郵便番号
    another_prefecture_id: 0, // 都道府県
    another_address1: null, // 市区町村
    another_address2: null, //番地以降(建物名など)
    another_phone_number: null, // 電話番号
  },
  returnInfo: {
    date: null, // 卒業式ご返却日
    shopId: 0, // 卒業式ご返却場所id
    shopName: '', // 卒業式ご返却場所
    abbreviatedName: '', // 卒業式ご返却場所(略称)
  },
  dressingPlaces: {
    id: null, // 着付け会場ID
    name: null, // 着付け会場名
    dressingAddressInfo: null, // 着付会場住所
  },
  isValidate: null, // タブのバリデーション
})

export default state
