import { FormValues半巾帯_袴持込 } from '~/composable/estimate/viewModel/graduation/Fields/Fields半巾帯_袴持込'

//不明な点がある場合、READMEをご覧ください
export const getInitialStore = (): FormValues半巾帯_袴持込 => {
  return {
    hanhabaobi種別: null,
    hanhabaobi商品選択: null,
    hanhabaobi四色帯単体レンタル: null,
    hanhabaobi四色帯単体購入: null,
    hanhabaobi見積選択: null,
    hanhabaobi現品単品レンタル: null,
    hanhabaobi現品単品レンタルフリー入力: null,
    hanhabaobi現品単品購入: null,
    hanhabaobi現品単品購入フリー入力: null,
    hanhabaobiカタログ単体レンタル: null,
    hanhabaobiカタログ単体購入: null,
    hanhabaobiカタログ検索: null,
  }
}

const state = (): { fields: FormValues半巾帯_袴持込 } => ({
  fields: getInitialStore(),
})

export default state
