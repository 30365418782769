import { MutationTree } from 'vuex'
import { CopiedEstimateState, getInitialFields } from './state'
import { AttendanceEstimateDetail } from '~/api/estimateRepository'

const mutations: MutationTree<CopiedEstimateState> = {
  setAllState: (state, values: AttendanceEstimateDetail | null) => {
    state.estimate = values
  },

  initAllState: (state) => {
    state.estimate = getInitialFields()
  },
}

export default mutations
