/* eslint-disable camelcase */
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { AxiosResponse, AxiosInstance } from 'axios'

export type Prefecture = {
  id: number
  name: string
}

export type Shop = {
  id: number
  name: string
  abbreviated_name: string | null
  code: string | null
  phone_number: string | null
  fax_number: string | null
  emergency_phone_number: string | null
  email: string | null
  prefecture: string | null
  zip_code: string | null
  address1: string | null
  address2: string | null
  remarks: string | null
  display_order: number | null
}

export type TransferPlaces = {
  id: number
  display_order: number
  bank_name: string
  branch_name: string
  account_type_name: string
  account_number: string
}

/**
 * NOTE:
 * 特記事項のidの割り当て
 * 1: 貧血, 2: 妊娠, 3:チャンス、4: B体
 */ export type SpecialMentions = {
  id: number
  name: string
  abbreviated_name1: string
}

// 契約動機
export type ContractReason = {
  id: number | null
  name: string
}

// 来店動機
export type VisitMotive = {
  id: number
  name: string
}

export const VisitMotiveDisplayType = {
  旧来店動機: 1,
  成人式: 2,
  卒業式: 3,
}

// 着付け会場
export type DressingPlaces = {
  id: number // 着付会場ID
  name: string // 着付会場名
  prefecture_id: number // 都道府県ID
  prefectures: string // 都道府県名
  shop_id: number // 店舗ID
  is_shop: boolean // 店舗であるか否か
  abbreviated_name: string // 会場名（略称）
  phone_number: string
  emergency_contact_number: string
  zip: string
  address1: string
  address2: string
  contract_start_date: Date // 契約開始日
  contract_end_date: Date // 契約終了日
  business_start_time: string // 営業開始時間
  business_end_time: string // 営業終了時間
  reservation_time_unit_id: number //当日着付時間単位（20分、30分）
  remarks: string
}

export type MasterRepository = {
  getShops: () => Promise<AxiosResponse<{ shops: Shop[] }>>
  fetchPrefectures: () => Promise<AxiosResponse<{ prefecture: Prefecture[] }>>
  getTransferPlaces: () => Promise<
    AxiosResponse<{ transfer_places: TransferPlaces[] }>
  >
  getContractReasons: () => Promise<
    AxiosResponse<{ contract_reasons: ContractReason[] }>
  >
  getSpecialMentions: () => Promise<AxiosResponse<{ data: SpecialMentions[] }>>
  getVisitMotives: (
    display_type?: number
  ) => Promise<AxiosResponse<{ visit_motives: VisitMotive[] }>>
  getDressingPlaces: (
    shop_id: number
  ) => Promise<AxiosResponse<{ dressing_places: DressingPlaces[] }>>
}

const masterRepository = (
  client: NuxtAxiosInstance | AxiosInstance
): MasterRepository => {
  return {
    fetchPrefectures: () => client.get('prefecture'),
    getShops: () => client.get('shops'),
    getTransferPlaces: () => client.get('transfer_places'),
    getContractReasons: () => client.get('contract_reasons'),
    getSpecialMentions: () => client.get('special_mentions'),
    getVisitMotives: (display_type?: number) =>
      client.get('visit_motives', { params: { display_type } }),
    getDressingPlaces: (shop_id: number) =>
      client.get('dressing_place', { params: { shop_id } }),
  }
}

export default masterRepository
