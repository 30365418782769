import { MutationTree } from 'vuex'
import { DoublePhotoSettings } from '../../types'

const mutations: MutationTree<DoublePhotoSettings> = {
  remove: ({ settings }, { settingId, photoId }) => {
    const setting = settings.find((s) => s.id === settingId)
    if (setting) {
      setting.photos = setting.photos.filter((p) => p.photoId !== photoId)
    }
  },

  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },

  setInput: (state, input) => {
    state.input = input
  },

  setMainPhoto: (state, photos) => {
    state.settings[0].photos = photos
  },

  setNeckPhoto: (state, photos) => {
    state.settings[1].photos = photos
  },

  setPhotoUpload: (state, photoUpload) => {
    state.photoUpload = photoUpload
  },

  delete: (state) => {
    state.photoUpload = null
    state.input = {
      timeOfEnteringDressPlace: '',
      remark: '',
    }
    state.settings = [
      {
        id: 1,
        title: '全体写真',
        limit: 3,
        isPossibleCheck: true,
        photos: [],
      },
      {
        id: 2,
        title: '衿元写真',
        limit: 1,
        isPossibleCheck: false,
        photos: [],
      },
    ]
    state.isValidate = null
  },
}

export default mutations
