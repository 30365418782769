










import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  useStore,
} from '@nuxtjs/composition-api'
import { prohibitExpansion, reloadEvent } from '@/utils/screenControl'
import { useCustomer } from '@/composable/api/useCustomer'
import { Store } from 'vuex'
import { useAuth } from '~/composable/api/useAuth'
import { useOnline } from '~/composable/general/useOnline'

export default defineComponent({
  setup() {
    const online = useOnline()
    const store = useStore() as Store<any>
    const { fetchCustomerList } = useCustomer()
    store.getters['Contracts/BaseEstimate/getEstimateType']
    const { validateSession } = useAuth()

    const { addReloadEvent, removeReloadEvent } = reloadEvent()

    onMounted(() => {
      validateSession()
      prohibitExpansion()
      addReloadEvent()

      // 見積番号から該当のお客様を取得
      const customerId = store.state.Contracts.BaseEstimate.estimate.customer_id
      fetchCustomerList({ customer_id: customerId })
    })

    onBeforeUnmount(() => {
      removeReloadEvent()
    })
  },
})
