/**
 * [サービス区分名]
 */
export class ContractServiceType {
  /** 弊社着付け */
  static readonly ourDressing = 1
  /** 長期レンタル */
  static readonly longTimeRental = 2
  /** 遠方提携美容室 */
  static readonly cosmetologySalon = 3
  /** 着付けなし */
  static readonly noDressing = 4

  static toWord = (val: number | null) => {
    switch (val) {
      case ContractServiceType.ourDressing:
        return '弊社着付け'
      case ContractServiceType.longTimeRental:
        return '長期レンタル'
      case ContractServiceType.cosmetologySalon:
        return '遠方提携美容室'
      case ContractServiceType.noDressing:
        return '着付けなし'
      default:
        return ''
    }
  }

  static isDressing = (type: number) => {
    return type !== ContractServiceType.noDressing
  }

  static isOurDressing = (type: number) => {
    return type === ContractServiceType.ourDressing
  }
}
