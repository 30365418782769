import { usePrice } from 'storybook/utils/utils'
import { DISCOUNT_TYPE } from '~/constants/estimate/config'
import { DISCOUNT } from '../../discount/enums'
import {
  SingleItemServiceContractDetail,
  SingleItemServiceEstimateDetail,
} from '~/api/singleItemRepository'
import { State } from '~/store/SingleItemService/Main/state'

/**
 * @note
 * 単品契約詳細or単品見積詳細APIから取得した場合にデータを復元する処理
 *
 * 詳しくはAPI仕様書(2023/05/24時点)をご確認ください
 * https://docs.google.com/spreadsheets/d/1squ0uolJXm0i2LS4-JMBqQCxZfoHEgEKgpd7Iec8SYw/edit#gid=721026106
 */
export const restateDiscount = (
  data:
    | SingleItemServiceContractDetail
    | SingleItemServiceEstimateDetail
    | undefined
): State['data']['discount'] => {
  const { paramToString } = usePrice()

  const discount = data?.discounts.find((d) => {
    return d.discount_type === DISCOUNT_TYPE.GENERAL.id
  })

  return {
    apply: discount ? DISCOUNT.APPLY.はい : DISCOUNT.APPLY.いいえ,
    price: discount ? paramToString(Number(discount.amount)) : '¥0',
  }
}
