



















import { defineComponent } from '@nuxtjs/composition-api'
import AppModalWindow from 'storybook/components/atoms/AppModalWindow.vue'
import AppPinkButton from 'storybook/components/atoms/AppPinkButton.vue'
import AppTextButton from 'storybook/components/atoms/AppTextButton.vue'

export default defineComponent({
  components: {
    AppModalWindow,
    AppPinkButton,
    AppTextButton,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    executeLabel: {
      type: String,
      required: true,
    },
    cancelLabel: {
      type: String,
      required: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
    isShowTextButton: {
      type: Boolean,
      default: true,
    },
  },
})
