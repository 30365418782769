















import { defineComponent } from '@nuxtjs/composition-api'
export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
  },
  setup() {},
})
