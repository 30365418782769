import { computed, Ref } from '@nuxtjs/composition-api'
import { Addition } from '~/api/estimateRepository'
import { EstimateState } from '~/composable/estimate/repository/comingOfAge/EstimateState'
import { EstimateAddition } from '~/constants/enums/estimateAddition'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { AccessoryPlanState } from '~/store/types'
import { State as PricesState } from '~/store/PriceTable/state'

export const useAdditionsFormatter = (estimateState: Ref<EstimateState>) => {
  const priceTable = useComingStore<AccessoryPlanState>('Betsueri').getPrices()
  return computed(() => {
    const { 足し布, 見積一覧, 別衿, Wフォト, 帯 } = estimateState.value
    return getAdditions(
      足し布,
      見積一覧,
      別衿,
      Wフォト.別衿,
      帯,
      Wフォト.帯,
      priceTable
    )
  })
}
export const getAdditions = (
  values足し布: EstimateState['足し布'],
  values見積一覧: EstimateState['見積一覧'],
  values別衿: EstimateState['別衿'],
  valuesWフォト別衿: EstimateState['Wフォト']['別衿'],
  values帯: EstimateState['帯'],
  valuesWフォト帯: EstimateState['Wフォト']['帯'],
  priceTable: PricesState['prices']
) => {
  const additions: Addition[] = []
  if (values帯.flags.is帯仕立て代) {
    additions.push({
      addition_type: EstimateAddition.obiShitate,
      price: priceTable.estimate.帯仕立て代,
    })
  }

  if (values別衿.別衿加工必要) {
    additions.push({
      addition_type: EstimateAddition.separateCollarProcessing,
      price: priceTable.estimate.別衿加工代,
    })
  }

  if (values見積一覧.is正絹長襦袢) {
    additions.push({
      addition_type: EstimateAddition.nagajuban,
      price: priceTable.estimate.正絹長襦袢,
    })
  }

  if (values足し布.is適用) {
    additions.push({
      addition_type: EstimateAddition.tashinuno,
      price: values足し布.price,
    })
  }

  if (valuesWフォト帯.flags.is帯仕立て代) {
    additions.push({
      addition_type: EstimateAddition.wPhotoObiShitate,
      price: priceTable.estimate.帯仕立て代,
    })
  }

  if (valuesWフォト別衿.別衿加工必要) {
    additions.push({
      addition_type: EstimateAddition.wPhotoBetsueriKakou,
      price: priceTable.estimate.別衿加工代,
    })
  }

  return additions
}
