import { MutationTree } from 'vuex'
import { getInitialStore, State } from './state'

const mutations: MutationTree<State> = {
  setState: (state, data) => {
    state.data = data
  },
  setIsValidate: (state, data) => {
    state.data.isValidate = data
  },

  delete: (state) => {
    const initialStore = getInitialStore()
    state.data = initialStore.data
  },
}

export default mutations
