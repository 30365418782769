import { GetterTree } from 'vuex'
import { RootState } from './types'

const getters: GetterTree<any, RootState> = {
  getEstimates: (state: {
    Estimate: {
      ComingOfAgeCeremony: Record<string, unknown>
      GraduationCeremony: Record<string, unknown>
    }
  }) => {
    return state.Estimate
  },

  getContracts: (state: { Contracts: Record<string, unknown> }) => {
    return state.Contracts
  },
}

export default getters
