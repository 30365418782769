import { useGraduateStore } from '../GraduationStore'
import { FormValuesお写真プラン } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsお写真プラン'
import { FormValuesお名前 } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsお名前'
import { FormValuesお客様検索 } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsお客様検索'
import { FormValuesプラン } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsプラン'
import { FormValues二尺袖 } from '~/composable/estimate/viewModel/graduation/Fields/Fields二尺袖'
import { FormValues備考 } from '~/composable/estimate/viewModel/graduation/Fields/Fields備考'
import { FormValues別衿_着物持込 } from '~/composable/estimate/viewModel/graduation/Fields/Fields別衿_着物持込'
import { FormValues別衿_着物レンタル } from '~/composable/estimate/viewModel/graduation/Fields/Fields別衿_着物レンタル'
import { FormValues割引 } from '~/composable/estimate/viewModel/graduation/Fields/Fields割引'
import { FormValues半巾帯_袴レンタル } from '~/composable/estimate/viewModel/graduation/Fields/Fields半巾帯_袴レンタル'
import { FormValues半巾帯_袴持込 } from '~/composable/estimate/viewModel/graduation/Fields/Fields半巾帯_袴持込'
import { FormValues商品設定 } from '~/composable/estimate/viewModel/graduation/Fields/Fields商品設定'
import { FormValues巾着バッグ_着物レンタル } from '~/composable/estimate/viewModel/graduation/Fields/Fields巾着バッグ_着物レンタル'
import { FormValues巾着バッグ_着物持込 } from '~/composable/estimate/viewModel/graduation/Fields/Fields巾着バッグ_着物持込'
import { FormValues振袖 } from '~/composable/estimate/viewModel/graduation/Fields/Fields振袖'
import { FormValues草履ブーツ_着物レンタル } from '~/composable/estimate/viewModel/graduation/Fields/Fields草履ブーツ_着物レンタル'
import { FormValues草履ブーツ_着物持込 } from '~/composable/estimate/viewModel/graduation/Fields/Fields草履ブーツ_着物持込'
import { FormValues袴 } from '~/composable/estimate/viewModel/graduation/Fields/Fields袴'
import { FormValues試着写真 } from '~/composable/estimate/viewModel/graduation/Fields/Fields試着写真'
import { FormValues重ね衿_着物持込 } from '~/composable/estimate/viewModel/graduation/Fields/Fields重ね衿_着物持込'
import { FormValues重ね衿_着物レンタル1個目 } from '~/composable/estimate/viewModel/graduation/Fields/Fields重ね衿_着物レンタル1個目'
import { FormValues重ね衿_着物レンタル2個以上 } from '~/composable/estimate/viewModel/graduation/Fields/Fields重ね衿_着物レンタル2個以上'
import { FormValues髪飾り } from '~/composable/estimate/viewModel/graduation/Fields/Fields髪飾り'
import { TabNaviState } from '~/store/Estimate/GraduationCeremony/types'
import { MemoStateGraduate } from '~/store/types'
import { 卒業式見積一覧 } from '~/store/Estimate/GraduationCeremony/tab見積一覧/state'
import { useStore } from '@nuxtjs/composition-api'
import { CopiedEstimateState } from '~/store/Estimate/GraduationCeremony/CopiedEstimate/state'
import { FormValuesその他商品 } from '../../viewModel/graduation/Fields/Fieldsその他商品'

export const useInitAllGraduateStore = () => {
  const storeお名前 = useGraduateStore<FormValuesお名前>('tabお名前')
  const storeプラン = useGraduateStore<FormValuesプラン>('tabプラン')
  const storeNavi = useGraduateStore<TabNaviState>('tabNavi')
  const storeMemo = useGraduateStore<MemoStateGraduate>('memo')
  const storeお客様検索 =
    useGraduateStore<FormValuesお客様検索>('tabお客様検索')
  const storeお写真プラン =
    useGraduateStore<FormValuesお写真プラン>('tabお写真プラン')
  const store割引 = useGraduateStore<FormValues割引>('tab割引')
  const store巾着バッグ_着物持込 =
    useGraduateStore<FormValues巾着バッグ_着物持込>('tab巾着バッグ_着物持込')
  const store巾着バッグ_着物レンタル =
    useGraduateStore<FormValues巾着バッグ_着物レンタル>(
      'tab巾着バッグ_着物レンタル'
    )
  const store袴 = useGraduateStore<FormValues袴>('tab袴')
  const store試着写真 = useGraduateStore<FormValues試着写真>('tab試着写真')
  const store重ね衿_着物レンタル1 =
    useGraduateStore<FormValues重ね衿_着物レンタル1個目>(
      'tab重ね衿_着物レンタル1'
    )
  const store重ね衿_着物レンタル2 =
    useGraduateStore<FormValues重ね衿_着物レンタル2個以上>(
      'tab重ね衿_着物レンタル2'
    )
  const store重ね衿_着物レンタル3 =
    useGraduateStore<FormValues重ね衿_着物レンタル2個以上>(
      'tab重ね衿_着物レンタル3'
    )
  const store重ね衿_着物持込1 =
    useGraduateStore<FormValues重ね衿_着物持込>('tab重ね衿_着物持込1')
  const store重ね衿_着物持込2 =
    useGraduateStore<FormValues重ね衿_着物持込>('tab重ね衿_着物持込2')
  const store重ね衿_着物持込3 =
    useGraduateStore<FormValues重ね衿_着物持込>('tab重ね衿_着物持込3')
  const store商品設定 = useGraduateStore<FormValues商品設定>('tab商品設定')
  const store振袖 = useGraduateStore<FormValues振袖>('tab振袖')
  const store二尺袖 = useGraduateStore<FormValues二尺袖>('tab二尺袖')
  const store草履ブーツ_着物持込 =
    useGraduateStore<FormValues草履ブーツ_着物持込>('tab草履ブーツ_着物持込')
  const store草履ブーツ_着物レンタル =
    useGraduateStore<FormValues草履ブーツ_着物レンタル>(
      'tab草履ブーツ_着物レンタル'
    )
  const store髪飾り1 = useGraduateStore<FormValues髪飾り>('tab髪飾り1')
  const store髪飾り2 = useGraduateStore<FormValues髪飾り>('tab髪飾り2')
  const store髪飾り3 = useGraduateStore<FormValues髪飾り>('tab髪飾り3')
  const storeその他商品 =
    useGraduateStore<FormValuesその他商品>('tabその他商品')
  const store半巾帯_袴レンタル =
    useGraduateStore<FormValues半巾帯_袴レンタル>('tab半巾帯_袴レンタル')
  const store半巾帯_袴持込 =
    useGraduateStore<FormValues半巾帯_袴持込>('tab半巾帯_袴持込')
  const store備考 = useGraduateStore<FormValues備考>('tab備考')
  const store別衿_着物レンタル =
    useGraduateStore<FormValues別衿_着物レンタル>('tab別衿_着物レンタル')
  const store別衿_着物持込 =
    useGraduateStore<FormValues別衿_着物持込>('tab別衿_着物持込')
  const store見積一覧 = useGraduateStore<卒業式見積一覧>('tab見積一覧')
  const storeコピー元見積 =
    useGraduateStore<CopiedEstimateState>('CopiedEstimate')
  const store = useStore()

  const initAllGraduateStore = () => {
    storeお名前.initAll()
    storeプラン.initAll()
    storeNavi.initAll()
    storeMemo.initAll()
    storeお客様検索.initAll()
    storeお写真プラン.initAll()
    store割引.initAll()
    store巾着バッグ_着物持込.initAll()
    store巾着バッグ_着物レンタル.initAll()
    store袴.initAll()
    store試着写真.initAll()
    store重ね衿_着物レンタル1.initAll()
    store重ね衿_着物レンタル2.initAll()
    store重ね衿_着物レンタル3.initAll()
    store重ね衿_着物持込1.initAll()
    store重ね衿_着物持込2.initAll()
    store重ね衿_着物持込3.initAll()
    store商品設定.initAll()
    store振袖.initAll()
    store二尺袖.initAll()
    store草履ブーツ_着物持込.initAll()
    store草履ブーツ_着物レンタル.initAll()
    store髪飾り1.initAll()
    store髪飾り2.initAll()
    store髪飾り3.initAll()
    storeその他商品.initAll()
    store半巾帯_袴レンタル.initAll()
    store半巾帯_袴持込.initAll()
    store備考.initAll()
    store別衿_着物レンタル.initAll()
    store別衿_着物持込.initAll()
    store見積一覧.initAll()
    storeコピー元見積.initAll()
    store.commit('Estimate/GraduationCeremony/tabオプション/delete')
    store.commit('Estimate/GraduationCeremony/masterData/delete')
  }

  return { initAllGraduateStore }
}
