import { PaymentState } from '../../types'

const state = (): PaymentState => ({
  contract_id: '',
  due_date: '',
  staff: null,
  category: null,
  details: [],
  total: 0,
  notes: '',
  isValidate: null,
  isPayment: null, // 支払い処理を行うかどうか（契約変更時に使用）
  balance: 0, // 支払い残高（契約変更時に使用）
  paidAmount: 0, // 支払い済み金額（契約変更時に使用）,
})

export default state
