import { computed } from '@nuxtjs/composition-api'
import { ConclusionItem } from '.'
import { useEstimateState } from '~/composable/estimate/repository/graduation/EstimateState'
import { usePlanFlags } from '~/composable/estimate/domain/Graduation/PlanFlags'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { SINGLE_SERVICE_TYPE } from '~/constants/enums/itemPlan'
import { PP_SINGLE_RENTAL_PRICE } from '~/constants/productData'
import ProductClassification from '~/constants/enums/productClassification'
import { OTHER_ITEMS } from '~/constants/estimate/config'
import { usePrice } from '~/composable/general/Price'
import { Kasaneeri } from '~/composable/estimate/repository/graduation/states/State重ね衿'
import { PP_DISCOUNT_PRODUCT } from '~/constants/estimate/comingOfAge'

export const use髪飾り見積 = () => {
  const state = useEstimateState()
  return computed<ConclusionItem[]>(() => {
    return state.value.髪飾り.map((item, i) => {
      return {
        title: i ? '髪飾り - ' + (i + 1) : '髪飾り',
        productTypeId: item.品種区分,
        rankUpId: item.小物プラン,
        price: item.購入価格,
        priceNote: item.購入価格 ? '単品購入' : '',
        label: item.購入価格 ? '' : 'お持込',
      }
    })
  })
}

export const useその他商品見積 = () => {
  const { toNumber } = usePrice()

  const state = useEstimateState()
  return computed<ConclusionItem[]>(() => {
    if (
      state.value.その他商品.その他の商品を追加しますか ===
      OTHER_ITEMS.種別.しない
    ) {
      return []
    }

    return state.value.その他商品.その他商品追加.map((item, i) => {
      return {
        title: item.product_name,
        productTypeId: ProductClassification.otherItem,
        price: toNumber(item.price || ''),
        priceWithPP: toNumber(item.price || ''),
        priceNote:
          item.price_type === OTHER_ITEMS.PRICE_TYPE.単品レンタル
            ? '単品レンタル'
            : '単品購入',
        label: '',
      }
    })
  })
}

export const use重ね衿見積 = () => {
  const state = useEstimateState()

  return computed<ConclusionItem[]>(() => {
    /**
     * NOTE: 復元時の見積一覧でPP適用でないプランを選択した場合も考慮
     * フリー入力があればランクアップとする
     */
    const 二品目重ね衿ランクアップ: boolean = state.value.重ね衿.some(
      (item, i) =>
        (item.isランクアップ && i === PP_DISCOUNT_PRODUCT.SECOND) ||
        (item.PP適用時フリー入力 &&
          i === PP_DISCOUNT_PRODUCT.FIRST &&
          item.isセット内)
    )
    const getKaseneEriPPPrice = (item: Kasaneeri, countItem: number) => {
      const お持ち帰りプラン割引 =
        state.value.プラン.flags.isBringInDiscount &&
        item.見積表示?.value === SINGLE_SERVICE_TYPE.RENTAL &&
        !countItem

      if (二品目重ね衿ランクアップ && countItem === 0) {
        return item.PP適用時フリー入力
      } else if (
        item.isセット内 ||
        item.isランクアップ ||
        お持ち帰りプラン割引 ||
        (二品目重ね衿ランクアップ && countItem === 1)
      ) {
        return PP_SINGLE_RENTAL_PRICE
      } else {
        return null
      }
    }

    return state.value.重ね衿.map((item, i) => {
      return {
        title: i ? '重ね衿 - ' + (i + 1) : '重ね衿',
        productTypeId: item.品種区分,
        rankUpId: item.小物プラン,
        isRankUp: item.isランクアップ,
        price: item.表示価格,
        priceForView: item.表示価格,
        priceWithPP: getKaseneEriPPPrice(item, i),
        priceNote:
          二品目重ね衿ランクアップ && i === 0
            ? '単品レンタル'
            : item.見積表示?.value,
        label: item.label,
      }
    })
  })
}

export const use当日着付け代見積 = () => {
  const priceTable = useGraduateStore('').getPrices()
  const state = useEstimateState()
  const {
    isFurisode,
    isNishakusode,
    isHakama,
    isKimonoHakama,
    isEstimateBeforeRelease,
  } = usePlanFlags()
  return computed(() => {
    const { プラン, 振袖二尺袖 } = state.value

    // 無料の場合はnull, 有料の場合は"卒業式.当日着付代"を返す
    if (!プラン.flags.is弊社着付け) {
      return null
    }

    // 二尺袖単品：二尺袖レンタル＋袴持込
    // 二尺袖レンタルが着付け無料になるのは対象機能リリース後のため、リリース前の見積もりは既存の動きをする
    if (isNishakusode.value) {
      return isEstimateBeforeRelease
        ? priceTable.estimate.参列卒業式当日着付代
        : null
    }

    // NOTE: 振袖OR二尺袖レンタル＋袴レンタルOR持込、WB購入品持込の場合は着付代無料
    if (isKimonoHakama.value || isFurisode.value) {
      return null
    }
    if (isHakama.value) {
      if (振袖二尺袖.flags.is振袖MODE購入品) {
        return null
      } else {
        return priceTable.estimate.参列卒業式当日着付代
      }
    }
    console.error('プランが選択されていません')
    return null
  })
}
