import { FormValues重ね衿_着物レンタル1個目 } from '~/composable/estimate/viewModel/graduation/Fields/Fields重ね衿_着物レンタル1個目'

// 不明な点がある場合、READMEをご覧ください
export const getInitialStore = (): FormValues重ね衿_着物レンタル1個目 => {
  return {
    kasaneeri_one種別: null,
    kasaneeri_one形態: null,
    kasaneeri_oneセット内: null,
    kasaneeri_oneランクアップ: null,
    kasaneeri_oneフリー入力: null,
  }
}

const state = (): { fields: FormValues重ね衿_着物レンタル1個目 } => ({
  fields: getInitialStore(),
})

export default state
