import { GetterTree } from 'vuex'
import { RootState } from '~/store/types'
import { State } from './state'

const getters: GetterTree<State, RootState> = {
  // ** All Input Data **
  getState: (state) => {
    return state.data
  },

  getContractDate: (state) => {
    return state.data.contractDate
  },

  getPaymentDeadline: (state) => {
    return state.data.payment.paymentDeadline
  },

  // ** タブ **
  getTabList: (state) => {
    return state.tabList
  },

  getFinalPageList: (state) => {
    return state.finalPageList
  },
  getIsFinalPages: (state) => {
    // タブのisCurrentが全てfalseになると最終ページが表示される
    return state.tabList.every((tab) => !tab.isCurrent)
  },

  // 支払い情報
  getPayment: (state) => {
    return state.data.payment
  },

  // 友の会ポイント
  getIsTomonokaiApplied: (state) => {
    return state.data.productSetting.isTomonokaiApplied
  },

  getContractTotalPrice: (state) => {
    return state.data.contractTotalPrice
  },

  // ** 撤廃した足袋が含まれているか **
  getHasOldTypeTabi: (state) => {
    return state.data.accessories.hasOldTypeTabi
  },
}

export default getters
