import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { WphotoState } from '~/store/types'

export const useRestoreWPhotoFirst = () => {
  const store = useComingStore<WphotoState>('WphotoPlan')

  const restoreWPhotoFirst = (wPhoto: Restate['w_photo_plan']['firstPage']) => {
    if (!wPhoto) return
    /* 初期値を設定 ※必ず入力される値はここで設置 */
    const formValues: WphotoState = {
      /**
       * NOTE: selections = patternLine
       * ↓保持される選択肢のindex
       * [0]Wフォトプランを適用するか
       */
      selections: [wPhoto.value.optionIds適用],
      isValidate: null,
      isSubValidate: null,
    }
    /* ストアに内容を保存する */
    store.setOf<WphotoState['selections']>(
      'setSelection',
      formValues.selections
    )
    store.setIsValidate(false)
  }

  return { restoreWPhotoFirst }
}
