




import { defineComponent } from '@nuxtjs/composition-api'
import { useOnline } from '~/composable/general/useOnline'

export default defineComponent({
  name: 'default',
  setup() {
    const online = useOnline()
  },
})
