




















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import IconArrowRight from 'storybook/components/icon/IconArrowRight.vue'
import AppIcon from 'storybook/components/atoms/AppIcon.vue'

export default defineComponent({
  components: {
    IconArrowRight,
    AppIcon,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    hasArrow: {
      type: String as PropType<'right' | 'left'>,
      default: undefined,
    },
    filled: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isPhoto: {
      type: Boolean,
      default: false,
    },
  },
})
