import { Restate } from '~/composable/estimate/repository/graduation/EstimateRestate'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { FormValues袴 } from '~/composable/estimate/viewModel/graduation/Fields/Fields袴'

/** enums */
import { Enum袴 } from '~/constants/estimate/graduationOptions'

export const useRestore袴 = () => {
  const store = useGraduateStore<FormValues袴>('tab袴')

  const restore袴 = (hakama: Restate['hakama']) => {
    if (!hakama || hakama.flags.is持込) {
      return
    }

    /* 初期値を設定 ※必ず入力される値はここで設置 */
    const formValues: FormValues袴 = {
      hakamaIsカタログ商品: null,
      hakama身長: '0', // 身長は復元する必要がないが入力されていないと選択済みのカタログが表示されないため0で復元する
      hakama商品検索: null,
      hakama商品情報_価格: { id: 0, value: '' }, // フリー入力を選択,
      hakama商品情報_価格入力: null,
      hakamaカタログレンタル価格: null,
      hakamaカタログ検索: null,
      hakama割引適用: null,
      hakama保留: false, // 保留状態では見積作成できない仕様のため、復元時においては振袖保留のFlagは常にfalse
    }

    /* 以下で入力内容の条件に基づき、必要な項目を埋めていく */

    // カタログ商品または現品を選択
    if (hakama.flags.isカタログ商品) {
      formValues.hakamaIsカタログ商品 = Enum袴.カタログ商品OR現品.カタログ商品
    } else {
      formValues.hakamaIsカタログ商品 = Enum袴.カタログ商品OR現品.現品
    }

    if (hakama.flags.isレンタル && hakama.仕立て上り検索結果) {
      formValues.hakama商品検索 = hakama.仕立て上り検索結果
      formValues.hakama商品情報_価格入力 = hakama.レンタル価格
    }

    if (hakama.flags.isカタログレンタル) {
      formValues.hakamaカタログ検索 = hakama.カタログ検索結果
      formValues.hakamaカタログレンタル価格 = hakama.レンタル価格
    }

    if (hakama.optionId割引適用) {
      formValues.hakama割引適用 = hakama.optionId割引適用
    }

    /* ストアに内容を保存する */
    store.setAll(formValues)
  }
  return { restore袴 }
}
