import { MutationTree } from 'vuex'
import { PhotoInAdvanceState } from '../../types'

const mutations: MutationTree<PhotoInAdvanceState> = {
  setPatternLine: (state, patternLine) => {
    state.patternLine = patternLine
  },

  setInput: (state, { ...input }) => {
    state.input.dateOfTakingPhotoInAdvance = input.dateOfTakingPhotoInAdvance
    state.input.placeOfDressing = input.placeOfDressing
    state.input.timeOfEnteringDressPlace = input.timeOfEnteringDressPlace
    state.input.timeOfEnteringSalon = input.timeOfEnteringSalon
    state.input.remark = input.remark
    state.input.telOfSalon = input.telOfSalon
    state.input.nameOfSalon = input.nameOfSalon
    state.input.salonId = input.salonId
    state.input.shopId = input.shopId
    state.input.address = input.address
    state.input.dateOfGivingPhotoInAdvance = input.dateOfGivingPhotoInAdvance
    state.input.placeOfGivingPhotoInAdvance = input.placeOfGivingPhotoInAdvance
    state.input.itemSelection = input.itemSelection
    state.input.poseCount = input.poseCount
    state.input.bookCount = input.bookCount
    if (typeof input.selectedPlanType !== 'undefined') {
      state.input.selectedPlanType = input.selectedPlanType
    }
    state.input.needMakeup = input.needMakeup
  },

  setShop: (state, shop) => {
    state.shop = shop
  },

  setDressingPlaces: (state, dressingPlaces) => {
    state.dressingPlaces = dressingPlaces
  },

  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },

  delete: (state) => {
    state.patternLine = [0]
    state.input.dateOfTakingPhotoInAdvance = ''
    state.input.placeOfDressing = ''
    state.input.timeOfEnteringDressPlace = ''
    state.input.timeOfEnteringSalon = ''
    state.input.remark = ''
    state.input.prefecture = ''
    state.input.nameOfSalon = ''
    state.input.telOfSalon = ''
    state.input.salonId = 0
    state.input.shopId = null
    state.input.dateOfGivingPhotoInAdvance = ''
    state.input.placeOfGivingPhotoInAdvance = ''
    state.isValidate = null
    state.shop.shopId = 0
    state.shop.shopName = ''
    state.input.itemSelection = ''
    state.input.poseCount = 0
    state.input.bookCount = 0
    state.dressingPlaces.id = null
    state.dressingPlaces.name = null
    state.dressingPlaces.dressingAddressInfo = null
    state.input.selectedPlanType = null
    state.isValidate = null
    state.input.needMakeup = true
  },
}

export default mutations
