/**
 * [契約モード区分]
 */
export class ContractType {
  /** 成人式 */
  static readonly comingOfAgeCeremony = 1
  /** 卒業式 */
  static readonly graduationCeremony = 2
  /** 参列 */
  static readonly attendance = 3
  /** 結納 */
  static readonly engagement = 4
  /** その他 */
  static readonly others = 5
  /** 単品サービス */
  static readonly singleItemService = 7
  /** クリーニング */
  static readonly cleaning = 10

  static toWord = (val: number) => {
    switch (val) {
      case ContractType.comingOfAgeCeremony:
        return '成人式'
      case ContractType.graduationCeremony:
        return '卒業式'
      case ContractType.attendance:
        return '参列'
      case ContractType.engagement:
        return '結納'
      case ContractType.others:
        return 'その他'
      case ContractType.singleItemService:
        return '単品サービス'
      case ContractType.cleaning:
        return 'クリーニング'
      default:
        return 'ー'
    }
  }
}
