import { ref, reactive, useContext, useStore } from '@nuxtjs/composition-api'
import { Store } from 'vuex'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { MemoStateGraduate } from '~/store/types'

/** classes */
import { notifyError } from '~/composable/general/SlackErrorLog'

export const useMemo = () => {
  const store = useStore() as Store<any>
  const { $axios } = useContext()
  const memoList = ref([])
  const shopId = ref(store.getters['SettingShop/getShop']?.id)
  const storeMemo = useGraduateStore<MemoStateGraduate>('memo')

  /**
   * メモ作成API
   * @param {path} customer_id
   * @param {number} staff_id
   * @param {number} shop_id
   * @param {boolean} is_chance
   * @param {string} body
   */
  const postMemo = async (
    customer_id: number,
    data: any,
    title?: string,
    memoType?: 'graduation' | 'comingOfAge' | 'contract'
  ) => {
    const postData = { ...data, customer_id }
    try {
      const res = await $axios.post(`/customers/${customer_id}/memos`, postData)
      if (memoType) {
        switchDispatchMemo(res.data?.memo_id, memoType)
      }
      console.info('--- メモ作成完了 ---')
      console.log('API success', res.data)
      return true
    } catch (error) {
      await notifyError(
        error,
        `${title}時のメモ作成、顧客番号: ${customer_id}、見積番号: ${
          data?.estimate_id ?? '-'
        }、契約番号: ${data?.contract_id ?? '-'}`,
        data
      )
      alert('メモ作成に失敗しました。')
      console.log('API error', error)
      return false
    }
  }

  /**
   * メモ更新API
   * @param {path} customer_id
   * @param {path} memo_id
   */
  const patchMemo = async (customer_id: number, memo_id: number, data: any) => {
    const postData = { ...data, customer_id }

    try {
      const res = await $axios.patch(
        `/customers/${customer_id}/memos/${memo_id}`,
        postData
      )
      console.log('API success', res.data)
    } catch (error) {
      console.log('API error', error)
    }
  }

  const switchDispatchMemo = (
    id: number,
    memoType: 'graduation' | 'comingOfAge' | 'contract'
  ) => {
    switch (memoType) {
      case 'graduation':
        storeMemo.set('memo_id', id)
        break
      case 'comingOfAge':
        store.dispatch('Estimate/ComingOfAgeCeremony/Memo/setMemoId', id)
        break
      case 'contract':
        store.dispatch('Contracts/Memo/setMemoId', id)
        break
    }
  }

  // storeのメモ情報
  const memoState = reactive({
    staff_id: store.getters['LoginUser/getUser']?.staff_id,
    shop_id: shopId.value,
    is_chance: store.getters['Estimate/ComingOfAgeCeremony/Memo/getChance'],
    body: store.getters['Estimate/ComingOfAgeCeremony/Memo/getText'],
  })

  //*卒業式側のstate
  const memoGraduationState = reactive({
    staff_id: store.getters['LoginUser/getUser']?.staff_id,
    shop_id: shopId.value,
    is_chance: storeMemo.get<'is_chance'>('is_chance').value,
    body: storeMemo.get<'text'>('text').value,
    estimate_id: storeMemo.get<'estimate_id'>('estimate_id').value,
  })

  return {
    memoList,
    postMemo,
    patchMemo,
    memoState,
    memoGraduationState,
  }
}
