import { computed } from '@nuxtjs/composition-api'
import { usePlanFlags } from '~/composable/estimate/domain/Graduation/PlanFlags'
import {
  Enum巾着バッグ_着物持込,
  Enum巾着バッグ_着物レンタル,
} from '~/constants/estimate/graduationOptions'
import { FormValues巾着バッグ_着物持込 } from '~/composable/estimate/viewModel/graduation/Fields/Fields巾着バッグ_着物持込'
import { FormValues巾着バッグ_着物レンタル } from '~/composable/estimate/viewModel/graduation/Fields/Fields巾着バッグ_着物レンタル'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import {
  getPriceFromEditPrice,
  getPriceFromSelectPriceWithFreeInput,
  getShowPrice,
} from '~/composable/estimate/repository/graduation/states/utilities'
import { ShowPriceType } from '~/composable/estimate/repository/graduation/states/types'
type KinchakuBag = {
  品種区分: 31 | 28 // 巾着/バッグの定数
  商品名: string | null
  商品id: number | null
  小物プラン: number | null
  レンタル価格: number | null
  購入価格: number | null
  見積表示: ShowPriceType | null
  表示価格: number | null
  isランクアップ: boolean
  isセット内: boolean
  label: string
  title: string
}
export const useState巾着バッグ = () => {
  const store巾着バッグ_着物持込 =
    useGraduateStore<FormValues巾着バッグ_着物持込>('tab巾着バッグ_着物持込')
  const store巾着バッグ_着物レンタル =
    useGraduateStore<FormValues巾着バッグ_着物レンタル>(
      'tab巾着バッグ_着物レンタル'
    )
  const values巾着バッグ_着物持込 = store巾着バッグ_着物持込.getAll()
  const values巾着バッグ_着物レンタル = store巾着バッグ_着物レンタル.getAll()
  const { hasKimonoRental } = usePlanFlags()

  return computed(() => {
    const kinchakuBag: KinchakuBag = {
      品種区分: 28,
      商品名: null,
      商品id: null,
      小物プラン: null,
      レンタル価格: null,
      購入価格: null,
      見積表示: null,
      表示価格: null,
      isランクアップ: false,
      isセット内: false,
      label: '',
      title: '巾着・バッグ',
    }

    if (!hasKimonoRental.value) {
      /*
       * プランで着物持込を選んだ際の巾着バッグタブ選択内容
       */
      kinchakuBag.title = 'バッグ'
      const 種別選択 = values巾着バッグ_着物持込.value.kinchakubag種別
      const 商品選択 = values巾着バッグ_着物持込.value.kinchakubag商品選択
      const お持込を選択 = 種別選択 === Enum巾着バッグ_着物持込.種別.お持込
      const 現品を選択 =
        商品選択?.id === Enum巾着バッグ_着物持込.バッグ商品.現品
      if (お持込を選択) {
        kinchakuBag.小物プラン = Enum巾着バッグ_着物持込.種別.お持込
        kinchakuBag.label = 'お持込'
        kinchakuBag.商品名 = null
        return kinchakuBag
      }

      if (現品を選択) {
        kinchakuBag.レンタル価格 = getPriceFromSelectPriceWithFreeInput(
          values巾着バッグ_着物持込.value.kinchakubag現品単品レンタル,
          values巾着バッグ_着物持込.value.kinchakubag現品単品レンタルフリー入力
        )
        kinchakuBag.購入価格 = getPriceFromSelectPriceWithFreeInput(
          values巾着バッグ_着物持込.value.kinchakubag現品単品購入,
          values巾着バッグ_着物持込.value.kinchakubag現品単品購入フリー入力
        )
        kinchakuBag.商品名 = '現品'
      } else {
        kinchakuBag.title = '巾着・バッグ'
        // 現品以外の商品を選択した場合
        kinchakuBag.レンタル価格 = getPriceFromEditPrice(
          values巾着バッグ_着物持込.value.kinchakubag単品レンタル
        )
        kinchakuBag.購入価格 = getPriceFromEditPrice(
          values巾着バッグ_着物持込.value.kinchakubag単品購入
        )
        kinchakuBag.商品名 = 商品選択?.name || null
        kinchakuBag.商品id = 商品選択?.id || null
      }

      kinchakuBag.小物プラン = Enum巾着バッグ_着物持込.形態.ランクアップ
      const { showType, showPrice } = getShowPrice(
        values巾着バッグ_着物持込.value.kinchakubag見積選択,
        kinchakuBag.レンタル価格,
        kinchakuBag.購入価格,
        Enum巾着バッグ_着物持込.見積表示
      )
      kinchakuBag.見積表示 = showType
      kinchakuBag.表示価格 = showPrice

      return kinchakuBag
    } else {
      /*
       * プランで着物レンタルを選んだ際の巾着バッグタブ選択内容
       */
      const 種別選択 = values巾着バッグ_着物レンタル.value.kinchakubag種別
      const 形態選択 = values巾着バッグ_着物レンタル.value.kinchakubag形態
      const 商品選択 = values巾着バッグ_着物レンタル.value.kinchakubag商品選択
      const お持込を選択 = 種別選択 === Enum巾着バッグ_着物レンタル.種別.お持込
      const セット内を選択 =
        形態選択 === Enum巾着バッグ_着物レンタル.形態.セット内
      const 現品を選択 =
        商品選択?.id === Enum巾着バッグ_着物レンタル.バッグ商品.現品
      kinchakuBag.商品名 = 商品選択?.name ?? null

      if (お持込を選択) {
        kinchakuBag.小物プラン = Enum巾着バッグ_着物レンタル.種別.お持込
        kinchakuBag.label = 'お持込'
        kinchakuBag.商品名 = null
        return kinchakuBag
      }
      kinchakuBag.label = 'ランクアップ'
      if (セット内を選択) {
        kinchakuBag.品種区分 = 31
        kinchakuBag.レンタル価格 = getPriceFromEditPrice(
          values巾着バッグ_着物レンタル.value.kinchakubag規定商品レンタル価格
        )
        kinchakuBag.表示価格 = kinchakuBag.レンタル価格
        kinchakuBag.小物プラン = Enum巾着バッグ_着物レンタル.形態.セット内
        kinchakuBag.label = 'セット内'
        kinchakuBag.商品名 = null
        kinchakuBag.isセット内 = true
        return kinchakuBag
      }

      if (現品を選択) {
        kinchakuBag.レンタル価格 = getPriceFromSelectPriceWithFreeInput(
          values巾着バッグ_着物レンタル.value.kinchakubag現品レンタル価格,
          values巾着バッグ_着物レンタル.value
            .kinchakubag現品レンタル価格フリー入力
        )
        kinchakuBag.商品名 = '現品'
      } else {
        // 現品以外の商品を選択した場合
        kinchakuBag.レンタル価格 = getPriceFromEditPrice(
          values巾着バッグ_着物レンタル.value.kinchakubagレンタル価格
        )
        kinchakuBag.商品id = 商品選択?.id ?? null
      }
      kinchakuBag.小物プラン = Enum巾着バッグ_着物持込.形態.ランクアップ
      kinchakuBag.isランクアップ = true
      kinchakuBag.表示価格 = kinchakuBag.レンタル価格
      return kinchakuBag
    }
  })
}
