import { MutationTree } from 'vuex'
import { EstimatePhotoSettings } from '../../../types'

const mutations: MutationTree<EstimatePhotoSettings> = {
  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },

  setAllState: (state, newState: EstimatePhotoSettings) => {
    state.settings = newState.settings
    state.selections = newState.selections
    state.isValidate = newState.isValidate
  },

  delete: (state) => {
    state.selections = [0]
    state.settings = [
      {
        id: 1,
        title: '全体写真',
        limit: 3,
        isPossibleCheck: true,
        photos: [],
      },
      {
        id: 2,
        title: '衿元写真',
        limit: 1,
        isPossibleCheck: false,
        photos: [],
      },
    ]
    state.isValidate = null
  },
}

export default mutations
