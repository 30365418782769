import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { OnlyTextState } from '~/store/types'
import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'

export const useRestore備考 = () => {
  const store = useComingStore<Omit<OnlyTextState, ''>>('Remarks')

  const restore備考 = (text: Restate['remarks']) => {
    store.setOf<OnlyTextState['text']>('setText', text)
    store.setOf<OnlyTextState['isValidate']>('setIsValidate', false)
  }

  return { restore備考 }
}
