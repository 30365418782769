import { computed } from '@nuxtjs/composition-api'
import { usePlanTypeFlags } from '~/composable/estimate/domain/ComingOfAge/PlanTypeFlags'
import { ContractServiceType } from '~/constants/enums/contractServiceType'
import { ServicePlanState } from '~/store/types'
import { SERVICE_PLAN } from '~/constants/estimate/comingOfAge'
import { useComingStore } from '../../ComingOfAgeStore'
import { State as PricesState } from '~/store/PriceTable/state'
import { PlanFlags } from './types'

export const useStateプラン = () => {
  const comingStore = useComingStore<ServicePlanState>('ServicePlans')
  const valuesプラン = comingStore.getAll()
  const priceTable = comingStore.getPrices() // Store料金情報を取得
  const {
    isフルセット,
    is写真のみレンタル,
    is当日のみBコース,
    is振袖持込Not当日のみBコース,
  } = usePlanTypeFlags()
  return computed(() => {
    const flags = {
      isフルセット: isフルセット.value,
      is写のみレンタル: is写真のみレンタル.value,
      is持ち込み: is振袖持込Not当日のみBコース.value,
      is当日Bコース: is当日のみBコース.value,
    }
    return getStateプラン(valuesプラン.value, flags, priceTable)
  })
}
export const getStateプラン = (
  valuesプラン: ServicePlanState,
  planFlags: PlanFlags,
  priceTable: PricesState['prices']
) => {
  const { selections, date, customer } = valuesプラン
  const isお姉様購入振袖 = selections.includes(SERVICE_PLAN.PURCHASE_AT_OUR)
  const お姉様情報 = isお姉様購入振袖 ? customer : null

  let サービス区分 = 0
  if (selections.includes(SERVICE_PLAN.OUR_DRESSIN)) {
    サービス区分 = ContractServiceType.ourDressing
  } else if (selections.includes(SERVICE_PLAN.COSMETOLOGY_SALON)) {
    サービス区分 = ContractServiceType.cosmetologySalon
  } else if (selections.includes(SERVICE_PLAN.NO_DRESSING)) {
    サービス区分 = ContractServiceType.noDressing
  } else {
    // NOTE: 写のみプラン時など、サービス区分の選択がない時は弊社着付けになる
    サービス区分 = ContractServiceType.ourDressing
  }

  return {
    サービス区分,
    成人式使用日: date,
    お姉様情報,
    当日ヘアメイク着付代: get当日ヘアメイク着付代(
      planFlags,
      isお姉様購入振袖,
      priceTable
    ),
    flags: {
      is弊社着付け: selections.includes(SERVICE_PLAN.OUR_DRESSIN),
      is着付けなし: selections.includes(SERVICE_PLAN.NO_DRESSING),
      isお持込: null,
      isお姉様購入振袖,
    },
  }
}

const get当日ヘアメイク着付代 = (
  planFlags: PlanFlags,
  isお姉様購入振袖: boolean,
  priceTable: PricesState['prices']
) => {
  const {
    当日ヘアメイク着付け代Aコース,
    当日ヘアメイク着付け代Bコース,
    当日ヘアメイク着付け代フルセット,
  } = priceTable.estimate
  // NOTE: 写のみプランには成人式当日へのオプションは存在しないのでnullを返す
  if (planFlags.is写のみレンタル) return null
  // NOTE: フルセットの時は無料
  if (planFlags.isフルセット) return 当日ヘアメイク着付け代フルセット ?? 0
  if (planFlags.is当日Bコース) {
    return 当日ヘアメイク着付け代Bコース
  }
  if (planFlags.is持ち込み) {
    return isお姉様購入振袖
      ? 当日ヘアメイク着付け代Bコース
      : 当日ヘアメイク着付け代Aコース
  }
  return null
}
