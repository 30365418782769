/** type */
import { CreateEstimateParams } from '~/api/estimateRepository'
import { State as PricesState } from '~/store/PriceTable/state'

/** enum */
import { PriceTableType } from '~/constants/enums/priceTableType'

export const usePriceTableFormatter = (priceTable: PricesState['prices']) => {
  const priceTables: CreateEstimateParams['reference_price_information'] = [] // 価格表

  // 見積
  Object.entries(priceTable.estimate).forEach(([key, value]) => {
    if (key === '割引率') {
      Object.entries(priceTable.estimate.割引率).forEach(([key, value]) => {
        priceTables.push({
          type: PriceTableType[key as keyof PriceTableType],
          rate: value,
          amount: null,
        })
      })
    } else {
      priceTables.push({
        type: PriceTableType[key as keyof PriceTableType],
        rate: null,
        amount: typeof value === 'number' ? value : null,
      })
    }
  })

  // 契約
  Object.entries(priceTable.contracts).forEach(([key, value]) => {
    const priceType: number = PriceTableType[key as keyof PriceTableType]
    priceTables.push({ type: priceType, rate: null, amount: value })
  })

  return { priceTables }
}
