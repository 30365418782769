/* eslint-disable camelcase */
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { AxiosResponse, AxiosInstance } from 'axios'
import { SelectedPlan } from '~/store/Contracts/types'
import { AmountAdjustments, MayBe } from '~/store/types'

export type Items = {
  catalog_code?: string
  product_id?: number
  product_type_id: number
  product_name?: string
  plan: number
  rental_price: number
  purchase_price?: number
  premium_rental_price?: number
  premium_purchase_price?: number
  price_type?: number
  order_number?: number
  is_hakama?: boolean
  estimate_item_id?: number // 契約と紐づく見積を取得した場合に付与されているDB用の値
}

export type PhotoState = {
  remoteSrc: string
  isSelected: boolean
}

export type PhotoSetting = {
  id: number
  title: string
  limit: number
  isPossibleCheck: boolean
  getEstimateThumbnail?: boolean
  photos: PhotoState[]
}

export type Photos = {
  url: string
  is_main_picture: boolean
}

export type Discounts = {
  discount_type: number
  type: number
  rate?: number
  amount?: number
}

export type Hurisode = {
  product_id?: number
  catalog_code?: string
  product_name: string
  is_correct_for_yukitake: boolean
  is_correct_for_sodetake: boolean
  rental_price: number
  purchase_price: number
  category: number
  product_type_id: number // 卒業式は振袖・二尺袖どちらか
  sewing?: number
  discount_type?: number // 1: 50%引　2: 80%引　3: 固定金額 4: 友の会割引
  estimate_hurisode_id?: number // 契約と紐づく見積を取得した場合に付与されているDB用の値
  is_order_outside_target_year?: boolean
}

export type WPhotoHurisode = Omit<Hurisode, 'category' | 'product_type_id'>

export type WPhotoPlan = {
  photos: Photos[]
  neck_photo_url: string
  selected_plan_type: number
  hurisode: MayBe<WPhotoHurisode>
  items: MayBe<Items[]>
  selected_plan: number
}

export type Hakama = {
  product_id?: number
  catalog_code?: string
  product_name: string
  rental_price: number
  category: number
  product_type_id: number
  discount_type?: number // 1: 50%引 2: 11,000円引
  estimate_hurisode_id?: number // 契約と紐づく見積を取得した場合に付与されているDB用の値
}

export type Addition = {
  addition_type: number
  price: number
}

export type DaishiAri = {
  pose_number: number | null
  number_of_books: number | null
}

export type DaishiNashi = {
  pose_number: number | null
}

export type Mutsugiri = DaishiAri

export type Cabinet = DaishiAri

export type acrylStand = {
  size: number | null
  number_of_pieces: number | null
}

export type photoPlan = {
  mutsugiri?: {
    with_daishi?: Mutsugiri[]
    no_daishi?: DaishiNashi[]
  }
  album?: {
    pose_number: number | null
    number_of_books: number | null
    referral_bonus_pose_number: number | null
  }
  acryl_stand?: acrylStand[]
  is_buying_final_photo_data?: boolean
  cabinet?: {
    with_daishi?: Cabinet[]
    no_daishi?: DaishiNashi[]
  }
}

export type CreateEstimateParams = {
  customer_id: number
  staff_id: number
  plan: number
  contract_service_type?: number
  type: number
  use_date?: string
  photos: Photos[]
  neck_photo_url?: string
  hurisode?: MayBe<Hurisode>
  items?: MayBe<Items[]>
  is_jk_photos_in_advance: boolean
  w_photo_plan?: MayBe<WPhotoPlan>
  mother_and_daughter_plan?: boolean
  sisters?: MayBe<{
    customer_id: number
  }>
  discounts: MayBe<Discounts[]>
  remarks?: string
  contract_total_price?: number
  selected_plan?: SelectedPlan
  coming_of_age_ceremony_contract_id?: number
  hakama?: MayBe<Hakama>
  photo_plan?: photoPlan
  pdf_url?: string
  estimate_date: string
  shop_id: number
  additions?: Addition[]
  front_store_id?: number
  reference_price_information?: {
    type: number
    rate: number | null
    amount: number | null
  }[]
  visiting_reason: number[]
  amount_adjustments: AmountAdjustments[]
  bust?: MayBe<number>
  hip?: MayBe<number>
  is_tomonokai_member?: MayBe<number>
}

//* ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓ 参列 ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓*/

export type AttendanceDiscounts = {
  discount_type: number
  type: number
  rate?: number
  amount?: number
}
export type AttendancePrices = {
  price: number
  price_type: number
}
//留袖 パーティードレス 初着 振袖 訪問着で着用する衣類のオブジェクト
export type Clothing = {
  product_id: number | null
  product_name: string | null
  correct_yukitake_price: number | null
  correct_sodetake_price: number | null
  product_type_id: number
  photo_url: string | null
  prices: AttendancePrices[]
  product_classification_id: number | null
}

export type AttendanceItems = {
  product_id: number | null
  product_type_id: number
  product_name: string | null
  // NOTE: planはフロント側では使っていないが、サーバー側で旧データとの整合性を取るために使われている
  plan: number | null // ItemPlan定数の1,3,4か、単品レンタル・購入の場合はnullを送る
  order_number: number | null
  prices: AttendancePrices[]
}

export type Child = {
  last_name: string
  first_name: string
  last_name_kana: string
  first_name_kana: string
  birthdate: string
  gender_id: number
}

export type PriceTableType = {
  type: number
  rate: number | null
  amount: number | null
}

export class ChildGender {
  /** 男の子 */
  static readonly boy = 1
  /** 女の子 */
  static readonly girl = 2

  static toWord = (val: number) => {
    switch (val) {
      case ChildGender.boy:
        return '男の子'
      case ChildGender.girl:
        return '女の子'
      default:
        return 'ー'
    }
  }
  static toId = (val: string) => {
    switch (val) {
      case '男の子':
        return ChildGender.boy
      case '女の子':
        return ChildGender.girl
      default:
        return 0
    }
  }
}

/**
 * @note 参列API (2022/10/24)の見積書作成に準拠した型
 * https://docs.google.com/spreadsheets/d/1Go2iCyMfVkvc22ez4fjjsB0JSBeMGnfHY-vFDtrcjdg/edit#gid=689358782
 */
export type CreateAttendanceEstimateParams = {
  customer_id: number //顧客ID
  is_tomonokai_member: number
  tomonokai_customer_id: number | null
  staff_id: number
  use_date: string
  shop_id: number
  neck_photo_url: string | null
  memo: string | null
  is_chance?: 0 | 1 | null
  remarks: string | null
  height: number | null
  foot: number | null
  bust: number | null
  hip: number | null
  waist: number | null
  contract_service_type: number | null
  premium_pack_total_price: number | null
  total_price: number | null
  additions: Addition[]
  photos: Photos[]
  discounts: AttendanceDiscounts[]
  clothing: Clothing | null
  items: AttendanceItems[]
  child: Child | null
  plan_option: number | null
  amount_adjustments: AmountAdjustments[]
  reference_price_information: PriceTableType[]
  coming_of_age_ceremony_contract_id: number | null
}

/**
 * @note 参列API (2022/10/24)の見積書詳細に準拠した型
 * https://docs.google.com/spreadsheets/d/1Go2iCyMfVkvc22ez4fjjsB0JSBeMGnfHY-vFDtrcjdg/edit#gid=619142079
 *
 * array型のparameterは存在しない場合空配列でくる想定
 * プリミティブ型は存在しない場合はnullで来る想定
 * オブジェクト型は存在しない場合はnullで来る想定
 */
export type AttendanceEstimateDetail = {
  customer_id: number //顧客ID
  is_tomonokai_member: number
  tomonokai_customer_id: number | null
  staff_id: number
  use_date: string
  total_price: number | null
  pdf_url: string | null
  shop_id: number
  neck_photo_url: string | null
  remarks: string | null
  height: number | null
  foot: number | null
  bust: number | null
  hip: number | null
  waist: number | null
  contract_service_type: number | null
  premium_pack_total_price: number | null
  created_at: string | null
  additions: Addition[]
  photos: Photos[]
  discounts: AttendanceDiscounts[]
  clothing: Clothing | null
  items: AttendanceItems[]
  child: Child | null
  plan_option: number | null
  amount_adjustments: AmountAdjustments[]
  reference_price_information: PriceTableType[]
  coming_of_age_ceremony_contract_id: number | null
}

//* ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑ 参列 ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑*/

export type EstimateDetail = {
  estimate_id: number
  shop_id?: number
  estimate_date?: string
  branch_number?: number
} & CreateEstimateParams

export type SearchEstimateData = {
  estimate_id: number
  branch_number: number
  event_name: string
  status: boolean
  customer_id: number
  use_date: string
  created_at: string
}

export type SearchEstimate = {
  customer_id: number
  branch_number: number
  estimate_id: number
  event_name: string
  status: boolean
  use_date: string
  created_at: string
  photo_url: string
  last_name: string
  first_name: string
  last_name_kana: string
  first_name_kana: string
}

export type SearchEstimateDataWithFileUrls = SearchEstimateData & {
  photo_url?: string
  pdf_url?: string
}
type EstimateListResponse<T = SearchEstimateData> = { estimates: T[] }

export type EstimateRepository = {
  create: (
    params: CreateEstimateParams
  ) => Promise<AxiosResponse<{ estimate_id: number }>>
  show: (
    estimateId: number,
    branchNumber: number
  ) => Promise<AxiosResponse<EstimateDetail>>
  createAttendance: (
    params: CreateAttendanceEstimateParams
  ) => Promise<AxiosResponse<{ estimate_id: number }>>
  showAtendance: (
    estimateId: number
  ) => Promise<AxiosResponse<AttendanceEstimateDetail>>
  update: (
    estimateId: number,
    data: EstimateDetail
  ) => Promise<AxiosResponse<{ estimate_id: number }>>
  getBranches: (params: {
    estimate_id: number
    type?: number
  }) => Promise<
    AxiosResponse<EstimateListResponse<SearchEstimateDataWithFileUrls>>
  >
  /**
   * NOTE: FileRepository.createPDFでPDF作成した場合は自動で見積データと紐付くのでこの処理は不要
   * 見積書にPDFを保存する際は、枝番は不要。PDF更新APIを叩いた場合履歴で最新のデータを対象に更新される（vv社APIの仕様）
   */
  updatePDFUrl: (
    estimateId: number,
    params: {
      pdf_url: string
    }
  ) => Promise<AxiosResponse<{ estimate_id: number }>>
  getEstimate: (params: {
    estimate_id: number
    type?: number
  }) => Promise<AxiosResponse<EstimateListResponse<SearchEstimate>>>
}

export default (
  client: NuxtAxiosInstance | AxiosInstance
): EstimateRepository => {
  return {
    create: (params) => client.post('estimates', params),
    show: (estimateId, branchNumber) =>
      client.get('estimates/' + estimateId + '/branch/' + branchNumber),
    update: (estimateId, data) => client.patch('estimates/' + estimateId, data),
    updatePDFUrl: (estimateId, params) =>
      client.patch('estimates/' + estimateId + '/pdf', params),
    getBranches: ({ estimate_id, type = 1 }) =>
      client.get('estimates', {
        params: { estimate_id, type },
      }),
    createAttendance: (params) => client.post('estimates/attendance', params),
    showAtendance: (estimateId) =>
      client.get<AttendanceEstimateDetail>(
        `estimates/attendance/${estimateId}`
      ),
    getEstimate: ({ estimate_id, type }) =>
      client.get('estimates', {
        params: { estimate_id, type },
      }),
  }
}
