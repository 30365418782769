import { GetterTree } from 'vuex'
import { FormValues巾着バッグ_着物レンタル } from '~/composable/estimate/viewModel/graduation/Fields/Fields巾着バッグ_着物レンタル'
import { RootState } from '~/store/Estimate/GraduationCeremony/types'

const getters: GetterTree<
  { fields: FormValues巾着バッグ_着物レンタル },
  RootState
> = {
  getState: (state) => (stateName: keyof FormValues巾着バッグ_着物レンタル) => {
    return state.fields[stateName]
  },

  getAllState: (state) => {
    return state.fields
  },
}
export default getters
