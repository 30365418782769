import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { AccessoryPlanState } from '~/store/types'

export const useRestore別衿 = () => {
  const store = useComingStore<AccessoryPlanState>('Betsueri')

  const restore別衿 = (
    betsueri: Restate['items']['別衿'],
    plan: Restate['plan']
  ) => {
    if (!plan || !betsueri) return

    /* 初期値を設定 ※必ず入力される値はここで設置 */
    const formValues: AccessoryPlanState = {
      /**
       * NOTE: selections = patternLine
       * ↓保持される選択肢のindex
       * [0]種別
       * [1]長襦袢
       * [2]形態 || 見積表示
       */
      selections: [betsueri.optionId種別, 0],
      input: {
        productName: '',
        buyPrice: 0,
        rentalPrice: 0,
        rankUpRentalPrice: 0,
        rankUpBuyPrice: 0,
        premiumRentalPrice: 0,
        premiumBuyPrice: 0,
      },
      // NOTE: フリー入力された値はpricesではなくinputの方に格納される
      price: {
        buy: 0,
        rental: 0,
        id: 0, // フリー入力を選択
      },
      isFree: false,
      isValidate: null,
    }

    /* 以下で入力内容の条件に基づき、必要な項目を埋めていく */
    if (plan.flags.is振袖持込 || betsueri.flags.is持込) {
      formValues.selections[1] = betsueri.optionId長襦袢
    }

    if (!betsueri.flags.is持込) {
      if (plan.flags.is振袖持込) {
        formValues.selections[2] = betsueri.optionId見積選択
        formValues.input.rentalPrice = betsueri.レンタル価格
        formValues.input.buyPrice = betsueri.購入価格
      } else {
        formValues.selections[2] = betsueri.optionId形態
      }
    }

    if (betsueri.flags.isセット内) {
      formValues.price.rental = betsueri.レンタル価格
      formValues.input.rentalPrice = betsueri.レンタル価格
      formValues.input.productName = betsueri.商品名
      if (plan.flags.isフルセットプラン) {
        formValues.price.buy = betsueri.購入価格
        formValues.input.buyPrice = betsueri.購入価格
      }
    }

    if (betsueri.flags.isレンタルのみランクアップ) {
      formValues.isFree = true
      formValues.input.rankUpRentalPrice = betsueri.レンタル価格
    }

    if (betsueri.flags.isランクアップPP内) {
      formValues.isFree = true
      formValues.input.rankUpRentalPrice = betsueri.レンタル価格
      if (plan.flags.isフルセットプラン) {
        formValues.input.rankUpBuyPrice = betsueri.購入価格
      }
    }

    if (betsueri.flags.isランクアップPP外) {
      formValues.input.rankUpRentalPrice = betsueri.レンタル価格
      formValues.input.premiumRentalPrice = betsueri.プレミアムレンタル価格
      if (plan.flags.isフルセットプラン) {
        formValues.input.rankUpBuyPrice = betsueri.購入価格
        formValues.input.premiumBuyPrice = betsueri.プレミアム購入価格
      }
    }

    /* ストアに内容を保存する */
    store.setOf<AccessoryPlanState['selections']>(
      'setSelection',
      formValues.selections
    )
    store.setOf<AccessoryPlanState['input']>('setInput', formValues.input)
    store.setOf<AccessoryPlanState['price']>('setPrice', formValues.price)
    store.setOf<AccessoryPlanState['isFree']>('setIsFree', formValues.isFree)
    store.setIsValidate(false)
  }

  return { restore別衿 }
}
