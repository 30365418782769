import { MutationTree } from 'vuex'
import { ExtendsEstimateState, getInitialStore } from './state'

const mutations: MutationTree<{ fields: ExtendsEstimateState }> = {
  setState: (
    state,
    payload: { stateName: keyof ExtendsEstimateState; value: any }
  ) => {
    state.fields[payload.stateName] = payload.value
  },

  setAllState: (state, values: ExtendsEstimateState) => {
    state.fields = values
  },

  initState: (state, fieldId: keyof ExtendsEstimateState) => {
    const initialStore = getInitialStore()
    state.fields[fieldId] = initialStore[fieldId] as any
  },

  initAllState: (state) => {
    state.fields = getInitialStore()
  },
}

export default mutations
