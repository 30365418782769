import { MutationTree } from 'vuex'
import { FrontStoreInfo } from '~/store/types'
import { getInitialStore } from './state'

/** ENUM */
import { ESTIMATE } from '~/constants/estimate/config'

const mutations: MutationTree<FrontStoreInfo> = {
  setState: (
    state,
    payload: { stateName: keyof FrontStoreInfo; value: never }
  ) => {
    state[payload.stateName] = payload.value
  },

  setEstimateId: (state, frontStoreId: number) => {
    state.frontStoreId.estimate = frontStoreId
  },

  setContractId: (state, frontStoreId: number) => {
    state.frontStoreId.contract = frontStoreId
  },

  setFrontStoreFlg: (state, frontStoreFlg: boolean) => {
    state.frontStoreFlg = frontStoreFlg
  },

  setFrontStoreType: (
    state,
    frontStoreType: typeof ESTIMATE[keyof typeof ESTIMATE]['id']
  ) => {
    state.frontStoreType = frontStoreType
  },

  delete: (state) => {
    const init = getInitialStore()
    state.frontStoreId = init.frontStoreId
    state.frontStoreFlg = init.frontStoreFlg
    state.frontStoreType = init.frontStoreType
  },
}

export default mutations
