import { computed } from '@nuxtjs/composition-api'
import { ConclusionItem } from '~/composable/estimate/viewModel/graduation/Fields/Fields見積一覧'
import { usePrice } from '~/composable/general/Price'
import { use卒業式全体見積 } from '~/composable/estimate/viewModel/graduation/Fields/Fields見積一覧/卒業式全体見積'
import { useお写真見積 } from '~/composable/estimate/viewModel/graduation/Fields/Fields見積一覧/お写真プラン見積'
import { 卒業式見積一覧 } from '~/store/Estimate/GraduationCeremony/tab見積一覧/state'
import { useGraduateStore } from '../../repository/GraduationStore'
import { AdjustmentType } from '~/constants/enums/AdjustmentType'

/*
 * 見積もり金額(含写真プラン）の総合計を算出する
 */
export const useEstimateTotalPrice = () => {
  const totalEstimate = use卒業式全体見積()
  const { photoTotal, photoTotalWithPP } = useEstimatePhotoTotalPrice()
  const { toString } = usePrice()
  const store見積一覧 = useGraduateStore<卒業式見積一覧>('tab見積一覧')

  const total = computed(() => {
    const totalAmount =
      sumTotalPrice(totalEstimate.value).number +
      photoTotal.value.number +
      calculateAdjustmentPrice(AdjustmentType.卒業式)

    return {
      number: totalAmount > 0 ? totalAmount : 0,
      string: toString(totalAmount > 0 ? totalAmount : 0),
    }
  })

  // PP適用時の合計金額
  const totalWithPP = computed(() => {
    const totalAmount =
      sumTotalPrice(totalEstimate.value, true).number +
      photoTotalWithPP.value.number +
      calculateAdjustmentPrice(AdjustmentType.卒業式PP)

    return {
      number: totalAmount > 0 ? totalAmount : 0,
      string: toString(totalAmount > 0 ? totalAmount : 0),
    }
  })

  // 金額調整によって追加された金額を合計金額にプラス(マイナス)する
  const calculateAdjustmentPrice = (
    columnType: typeof AdjustmentType.卒業式 | typeof AdjustmentType.卒業式PP
  ) => {
    const adjustmentPrices = store見積一覧
      .get<'amount_adjustments'>('amount_adjustments')
      .value.flatMap(({ prices }) => prices)

    return adjustmentPrices.reduce(
      (sum, { type, price }) => (type === columnType ? sum + price : sum),
      0
    )
  }

  return { total, totalWithPP }
}

/*
 * 見積もり金額の写真プランの総合計を算出する
 */
export const useEstimatePhotoTotalPrice = () => {
  const photoEstimate = useお写真見積()
  const photoTotal = computed(() => {
    return sumTotalPrice(photoEstimate.value)
  })

  const photoTotalWithPP = computed(() => {
    return sumTotalPrice(photoEstimate.value, true)
  })

  return { photoTotal, photoTotalWithPP }
}

type Items = { [k: string]: ConclusionItem | ConclusionItem[] }
const sumTotalPrice = (items: Items, isPP?: boolean) => {
  const { toString } = usePrice()

  const totalPrice = Object.values(items)
    .flat()
    .reduce((acc, item) => {
      if (typeof item.price !== 'number') {
        return acc
      }

      if (item?.discount) {
        return acc - item.price
      }

      const price =
        isPP && typeof item.priceWithPP === 'number'
          ? item.priceWithPP
          : item.price
      return acc + price
    }, 0)

  return {
    number: totalPrice,
    string: toString(totalPrice),
  }
}
