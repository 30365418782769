import { computed, Ref, watch } from '@nuxtjs/composition-api'
import { EstimateDetail } from '~/api/estimateRepository'
import usePersonal from '~/composable/api/usePersonal'
import DressingItem from '~/constants/dressingItem'
import { EstimateAddition } from '~/constants/enums/estimateAddition'
import { PLAN } from '~/constants/estimate/comingOfAge'
import { PriceTableType } from '~/constants/enums/priceTableType'

export const useRestateAdditions = (
  estimate: Ref<EstimateDetail | undefined>
) => {
  const { fetchPersonalData, data } = usePersonal()

  // NOTE: computed内で取得処理をしようとすると無限ループが起きる
  watch(estimate, () => {
    if (!estimate.value?.hurisode) return
    fetchPersonalData(estimate.value.customer_id)
  })

  return computed(() => {
    if (!estimate.value) return null
    const { additions, reference_price_information } = estimate.value
    if (!additions) return null

    // 足し布（普通）
    const 足し布安い方 = reference_price_information?.find(
      ({ type }) => type === PriceTableType.足し布N
    )?.amount

    // 足し布B
    const 足し布B = reference_price_information?.find(
      ({ type }) => type === PriceTableType.足し布B
    )?.amount

    const 足し布 = additions.find(
      (item) => item.addition_type === EstimateAddition.tashinuno
    )
    const 正絹長襦袢 = additions.find(
      (item) => item.addition_type === EstimateAddition.nagajuban
    )
    return {
      足し布: {
        is適用: !!足し布,
        optionId適用: 足し布 ? PLAN.APPLY : PLAN.NO_APPLY,
        採寸数値: {
          bust: estimate.value.bust ?? null,
          hip: estimate.value.hip ?? null,
          bodyType: DressingItem.calculateBodyType({
            bust: String(estimate.value.bust),
            hip: String(estimate.value.hip),
          }),
        },
        optionId追加金額:
          Number(足し布?.price) === 足し布安い方
            ? PLAN.TASHINUNO_99000yen
            : PLAN.TASHINUNO_16500yen,
      },
      長襦袢: {
        selectedOption: 正絹長襦袢 ? 2 : 1,
      },
    }
  })
}
