import { useContext, ref } from '@nuxtjs/composition-api'
import { MayBe } from '~/store/types'

export type Profile = {
  customer_id: number
  last_name: string
  first_name: string
  last_name_kana: string
  first_name_kana: string
  birthdate: string
  zip_code: string
  prefectures: string
  prefecture_id: number
  address1: string
  address2: string
  phone_number: string
  mobile_phone_number: string
  home_zip_code: string
  home_prefectures: string
  home_prefecture_id: number
  home_address1: string
  home_address2: string
  home_phone_number: string
  mail_address: string
  family_role: string
  occupation: string
  school_name: string
  graduate: string
  notification: number
  height: string
  foot_size: string
  bust: string
  hip: string
  special_mentions: { id: number; name: string }[]
  special_mentions_updated_at: string
  body_information_input_date: string
  is_send_mail: number
  remarks: {
    id: number
    body: string
    created_at: string
  }[]
  has_mypage: 0 | 1
  tomonokai_point: MayBe<number>
  family_tomonokai_point: number
  fetched_at: string
}

/**
 * NOTE:
 * special_mentions の id
 * 1: 貧血, 2: 妊娠, 3:チャンス、4: B体
 */
type UpdatePersonalReq = PersonalTypeForUpdate & {
  fetched_at: string
}
export type PersonalTypeForUpdate = {
  customer_id: number
  height?: number
  foot_size?: number
  bust?: number
  hip?: number
  special_mentions?: Number[] | null
}

export const initData: Profile = {
  customer_id: 0,
  last_name: '',
  first_name: '',
  last_name_kana: '',
  first_name_kana: '',
  birthdate: '',
  zip_code: '',
  prefectures: '',
  prefecture_id: 0,
  address1: '',
  address2: '',
  phone_number: '',
  mobile_phone_number: '',
  home_zip_code: '',
  home_prefectures: '',
  home_prefecture_id: 0,
  home_address1: '',
  home_address2: '',
  home_phone_number: '',
  mail_address: '',
  family_role: '',
  occupation: '',
  school_name: '',
  graduate: '',
  notification: 0,
  height: '',
  foot_size: '',
  bust: '',
  hip: '',
  special_mentions: [],
  special_mentions_updated_at: '',
  body_information_input_date: '',
  is_send_mail: 0,
  remarks: [],
  tomonokai_point: null,
  family_tomonokai_point: 0,
  has_mypage: 0,
  fetched_at: '',
}

export default function usePersonal() {
  const context = useContext()
  const data = ref<Profile>(initData)
  const loading = ref(false)
  const error = ref('')
  // fetched_atはサーバー側で更新の排他制御用のフラグ。取得したものを更新時にそのまま返す
  const fetchedAt = ref<string>('')

  const fetchPersonalData = async (customer_id: number) => {
    loading.value = true
    error.value = ''

    try {
      const res = await context.$axios.get<Profile>('customers/' + customer_id)

      data.value = {
        ...data.value,
        ...res.data,
      }
      fetchedAt.value = res.data.fetched_at
    } catch (err) {
      console.error(err)
      error.value = 'データが取得できませんでした。'
    }

    loading.value = false
  }

  /**
   * お客様情報更新API（採寸情報・特記事項のみ）
   */
  const updatePersonalData = async (params: PersonalTypeForUpdate) => {
    try {
      // TODO: 現状APIの仕様で全パラメータ送る必要があるので補完している。
      // 更新に必要な値だけ送れるよう修正依頼中。修正後この部分は削除する
      const res = await context.$axios.get<Profile>(
        'customers/' + params.customer_id
      )
      const baseParams = {
        customer_id: res.data.customer_id,
        height: Number(res.data.height),
        foot_size: Number(res.data.foot_size),
        bust: Number(res.data.bust),
        hip: Number(res.data.hip),
        special_mentions: res.data.special_mentions.map((m) => m.id),
      }

      // NOTE: fetched_atはサーバー側で更新の排他制御用のフラグ。
      // 取得APIに入ってきたものをそのまま渡すためusePersonal内部で完結する
      if (!fetchedAt.value) {
        fetchedAt.value = res.data.fetched_at
      }
      // 更新したい値だけ上書きする
      const updatedParams: UpdatePersonalReq = {
        ...baseParams,
        ...params,
        fetched_at: fetchedAt.value,
      }
      await context.$axios.patch(
        'customers/' + params.customer_id,
        updatedParams
      )
    } catch (err) {
      console.error('API error', err)
      error.value = 'お客様情報の更新に失敗しました'
    }
  }

  return {
    data,
    loading,
    error,
    fetchPersonalData,
    updatePersonalData,
    fetchedAt,
  }
}
