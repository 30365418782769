import { MutationTree } from 'vuex'
import { OldData } from './state'

const mutations: MutationTree<OldData> = {
  setContractId: (state, contractId) => {
    state.contractId = contractId
  },

  setServiceType: (state, serviceType) => {
    state.serviceType = serviceType
  },

  setIsDigitCorrection: (state, isDigitCorrection) => {
    state.isDigitCorrection = isDigitCorrection
  },

  setIsSleeveCorrection: (state, isSleeveCorrection) => {
    state.isSleeveCorrection = isSleeveCorrection
  },

  setFurisodeCategory: (state, furisodeCategory) => {
    state.furisodeCategory = furisodeCategory
  },

  setFurisodeCatalogCode: (state, furisodeCatalogCode) => {
    state.furisodeCatalogCode = furisodeCatalogCode
  },

  delete: (state) => {
    state.contractId = undefined
    state.serviceType = undefined
    state.isDigitCorrection = undefined
    state.isSleeveCorrection = undefined
    state.furisodeCategory = undefined
    state.furisodeCatalogCode = undefined
  },
}

export default mutations
