import { PlanFlags } from '~/composable/estimate/repository/comingOfAge/states/types'
import {
  Item,
  ItemBringIn,
  ItemFullset,
  ItemOnlyPhoto,
  ItemWPhoto,
  ItemWPhotoBringIn,
  ItemWPhotoRental,
} from '../types'

/**
 * プランごとに見積一覧ページに渡すオブジェクトのプロパティ名が違うので、
 * プランに合わせて変換する処理
 *
 * TODO: この変換はそもそも不要のはず。テストが通ったらコンポーネントの方もリファクタして
 * 全部の見積一覧コンポーネントでcolumn1,2...のプロパティ名にしたい
 */

export const convertParamsByPlan = (
  baseItem: Item | null,
  planFlags: PlanFlags // Bコースは区別しないため振袖持込に含めてOK
) => {
  if (!baseItem) return null
  // TODO: 後の作業で「金額0円」と「金額なし」の表示を分けたいのでnumber | null型で金額を渡しているが
  // 現状numberのみの受付なのでここで変換しておく.
  const column1 = { ...baseItem.column1, price: baseItem.column1.price ?? 0 }
  const column2 = { ...baseItem.column2, price: baseItem.column2.price ?? 0 }
  const column3 = { ...baseItem.column3, price: baseItem.column3.price ?? 0 }
  const column4 = { ...baseItem.column4, price: baseItem.column4.price ?? 0 }

  if (planFlags.isフルセット) {
    return <ItemFullset>{
      isTailoring: baseItem.isTailoring,
      isNagajuban: baseItem.isNagajuban,
      item: baseItem.item,
      rental: column1,
      rentalPremium: column2,
      buy: column3,
      buyPremium: column4,
    }
  } else if (planFlags.is写のみレンタル) {
    return <ItemOnlyPhoto>{
      isTailoring: baseItem.isTailoring,
      isNagajuban: baseItem.isNagajuban,
      item: baseItem.item,
      rental5pages: column1,
      rental5pagesPremium: column2,
      rental15pages: column3,
      rental15pagesPremium: column4,
    }
  } else if (planFlags.is持ち込み) {
    return <ItemBringIn>{
      isTailoring: baseItem.isTailoring,
      isNagajuban: baseItem.isNagajuban,
      item: baseItem.item,
      bringIn5pages: column1,
      bringIn15pages: column2,
    }
  }
  return null
}

export const convertWPhotoParamsByPlan = (
  baseItem: ItemWPhoto | Item | null,
  isRental: boolean
) => {
  if (!baseItem) return null
  // TODO: 後の作業で「金額0円」と「金額なし」の表示を分けたいのでnumber | null型で金額を渡しているが
  // 現状numberのみの受付なのでここで変換しておく.
  const column1 = { ...baseItem.column1, price: baseItem.column1.price ?? 0 }
  const column2 = { ...baseItem.column2, price: baseItem.column2.price ?? 0 }

  if (isRental) {
    return <ItemWPhotoRental>{
      item: baseItem.item,
      rental: column1,
      rentalPremium: column2,
    }
  } else {
    return <ItemWPhotoBringIn>{
      item: baseItem.item,
      rental: column1,
    }
  }
}
