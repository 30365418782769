import { computed, Ref } from '@nuxtjs/composition-api'
import { KimonoSearchResult } from '~/composable/api/useSearchProduct'
import DateTime from '~/utils/dateTime'

/**
 * 未来の予約があるか確認するフラグを返す
 * @param reserveList APIから取得した着物の予約リスト
 * @param targetDate 使用予定日
 * @param contractId 契約変更時に変更中の契約番号を渡す
 */
export const useHasFutureReserveFlag = () => {
  const getHasFutureReserveFlag = (
    reserveList: Ref<KimonoSearchResult[]> | undefined,
    targetDate: string | null,
    contractId?: number
  ) => {
    const date = new DateTime(targetDate)
    return computed(() => {
      if (!reserveList || !targetDate) {
        return false
      }
      return reserveList.value
        .filter((e) => e.end_date && e.start_date)
        .some((reserve) => {
          // NOTE: 変更中の契約に紐付いた予約は未来の予約として扱わない
          if (reserve.contract_id === contractId) return false
          return (
            date.isBefore(reserve.start_date!) ||
            date.isBefore(reserve.end_date!)
          )
        })
    })
  }
  return { getHasFutureReserveFlag }
}
