import { AttendanceEstimateDetail } from '~/api/estimateRepository'
export type CopiedEstimateState = { estimate: AttendanceEstimateDetail | null }

export const getInitialFields = (): AttendanceEstimateDetail | null => {
  return null
}

const state = (): CopiedEstimateState => ({
  // NOTE:見積コピーして見積作成を開始した時にコピー元の見積内容を保持しておくためのステート
  // 特定の商品に変更があった場合に前回の値を修正して送るために必要
  estimate: getInitialFields(),
})

export default state
