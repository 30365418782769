













import {
  defineComponent,
  onMounted,
  onUnmounted,
} from '@nuxtjs/composition-api'
import IconX from 'storybook/components/icon/IconX.vue'

export default defineComponent({
  components: {
    IconX,
  },
  props: {
    hasDelete: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: Number,
      default: 600,
    },
  },
  setup() {
    // 背後をスクロールできないようにする
    const fixBody = () => {
      document.body.style.overflow = 'hidden'
    }

    // 背後をスクロール可能な状態に戻す
    const unfixBody = () => {
      const scrollY = document.body.style.top
      document.body.style.overflow = 'auto'
      window.scrollTo(0, -parseInt(scrollY || '0'))
    }

    onMounted(() => {
      fixBody()
    })

    onUnmounted(() => {
      unfixBody()
    })
  },
})
