import { computed, Ref } from '@nuxtjs/composition-api'
import { EstimateDetail } from '~/api/estimateRepository'
import { Enumお写真プラン } from '~/constants/estimate/graduationOptions'

export const useResatePhotoPlan = (
  estimate: Ref<EstimateDetail | undefined>
) => {
  return computed(() => {
    if (!estimate.value) return null
    if (!estimate.value?.photo_plan) return null
    const {
      mutsugiri,
      cabinet,
      is_buying_final_photo_data,
      album,
      acryl_stand,
    } = estimate.value.photo_plan

    /** キャビネ */
    const キャビネ台紙なし = cabinet?.no_daishi?.find((i) => i)
    const キャビネ台紙あり = cabinet?.with_daishi?.map((i) => {
      return {
        book: i.number_of_books,
        pose: i.pose_number,
      }
    })

    /** 六つ切り */
    const 六切り台紙なし = mutsugiri?.no_daishi?.find((i) => i)
    const 六切り台紙あり = mutsugiri?.with_daishi?.map((i) => {
      return {
        book: i.number_of_books,
        pose: i.pose_number,
      }
    })

    /** USB */
    const USB = is_buying_final_photo_data
      ? Enumお写真プラン.USB.つける
      : Enumお写真プラン.USB.つけない

    /** デザインアルバム */
    const アルバム = album
    const デザインアルバムスタンダード =
      アルバム?.pose_number ===
      Enumお写真プラン.デザインアルバム詳細.スタンダード
        ? Enumお写真プラン.デザインアルバム詳細.スタンダード
        : Enumお写真プラン.デザインアルバム詳細.プレミアム

    /** アクリルスタンド */
    const アクリルスタンドA4 = acryl_stand?.find((i) => {
      return i.size === Enumお写真プラン.アクリルスタンド.A4
    })
    const アクリルスタンドA5 = acryl_stand?.find((i) => {
      return i.size === Enumお写真プラン.アクリルスタンド.A5
    })
    const isアクリルスタンド =
      !アクリルスタンドA4?.number_of_pieces &&
      !アクリルスタンドA5?.number_of_pieces

    //* 下記はページの条件分岐に必要なパーツ */
    const is撮影Arr = [
      !album?.pose_number,
      !六切り台紙あり?.[0],
      !六切り台紙なし,
      !キャビネ台紙あり?.[0],
      !キャビネ台紙なし,
      isアクリルスタンド,
    ]

    const is撮影 = is撮影Arr.every((i) => i)

    const 撮影 = is撮影
      ? Enumお写真プラン.写真撮影.しない
      : Enumお写真プラン.写真撮影.する

    //* 選択した商品の番号を配列で判定を行い挿入する
    const 商品optionIds = is撮影Arr
      .map((v, i) => {
        if (!v) return i + 1
      })
      .filter((v) => v) as number[]

    return {
      アクリルスタンドA4: アクリルスタンドA4?.number_of_pieces ?? 0,
      アクリルスタンドA5: アクリルスタンドA5?.number_of_pieces ?? 0,
      アルバム: アルバム?.referral_bonus_pose_number ?? 0,
      デザインアルバムスタンダード,
      キャビネ台紙なし: キャビネ台紙なし?.pose_number ?? 0,
      キャビネ台紙あり: !キャビネ台紙あり?.[0]
        ? [{ book: 1, pose: 1 }]
        : キャビネ台紙あり,
      六切り台紙なし: 六切り台紙なし?.pose_number ?? 0,
      六切り台紙あり: !六切り台紙あり?.[0]
        ? [{ book: 1, pose: 1 }]
        : 六切り台紙あり,
      USB,
      撮影,
      商品optionIds: 商品optionIds,
    }
  })
}
