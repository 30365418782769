import { TabNaviItem } from '~/composable/estimate/service/graduation/TabNavigationItem'

export interface RootState {
  version: string
}

export type Photo = {
  remoteSrc: string
  isSelected: boolean
  thumbnail?: string
}

export type WithFreeInput = {
  id: number
  value: string
}

export type WithFreeInput2Line = {
  id: number
  values: string[]
}

export type Price80Off = {
  disabled: boolean
  id: number
  label: string
  value: string
}

export type ValidatedTab = {
  url: string | null
  isValid: 'valid' | 'invalid' | 'noResult'
}

export type Product = {
  id: number
  name: string
  postNumberCode?: string //例：RB-01
}

// TODO:Tab以外のストアは直接型定義するとGraduateStoreの型推論でエラーが出るので
// とりあえずこうしてるがもう少しスマートに解決したい
const tabNaviState = {
  tabs: [] as TabNaviItem[],
  nextUrl: null as string | null,
  validatedTabs: [] as ValidatedTab[],
  isNextButton: false as boolean,
  isAllValidateTabs: false as boolean,
  isBeforeConfirmation: false as boolean, //* 入力内容確認ページから遷移した際にnextbuttonなどで必要なフラグ
}
export type TabNaviState = typeof tabNaviState
