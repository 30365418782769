import { GetterTree } from 'vuex'
import { RootState } from '~/store/Estimate/GraduationCeremony/types'
import { FormValues草履ブーツ_着物持込 } from '~/composable/estimate/viewModel/graduation/Fields/Fields草履ブーツ_着物持込'

const getters: GetterTree<
  { fields: FormValues草履ブーツ_着物持込 },
  RootState
> = {
  getState: (state) => (stateName: keyof FormValues草履ブーツ_着物持込) => {
    return state.fields[stateName]
  },

  getAllState: (state) => {
    return state.fields
  },
}
export default getters
