import { GetterTree } from 'vuex'
import {
  RootState,
  BaseEstimateState,
  GeneralHurisode,
  GeneralItem,
} from '../types'

const getters: GetterTree<BaseEstimateState, RootState> = {
  getEstimate: (state: any) => {
    return state.estimate
  },
  getIsNewEstimate: (state: BaseEstimateState) => {
    return state.isNewEstimate
  },
  getCustomerId: (
    state: BaseEstimateState
  ): BaseEstimateState['estimate']['customer_id'] => {
    return state.estimate.customer_id
  },
  getHurisode: (state: BaseEstimateState): GeneralHurisode | undefined => {
    return state.estimate?.hurisode
  },
  getItems: (state: BaseEstimateState): GeneralItem[] => {
    // WORNING: 配列型以外の場合がある
    if (Array.isArray(state?.estimate?.items)) {
      return state?.estimate?.items?.filter(Boolean) ?? []
    }

    return []
  },
  getWPhotoPlan: (
    state: BaseEstimateState
  ): BaseEstimateState['estimate']['w_photo_plan'] | undefined => {
    return state.estimate?.w_photo_plan
  },
  getWPhotoPlanHurisode: (state: any) => {
    return state.estimate?.w_photo_plan?.hurisode
  },
  getWPhotoPlanItems: (state: any) => {
    return state.estimate?.w_photo_plan?.items?.filter(Boolean) ?? []
  },
  getPlan: (
    state: BaseEstimateState
  ): BaseEstimateState['estimate']['plan'] => {
    return state.estimate.plan
  },
  getContractTotalPrice(state: BaseEstimateState): number | undefined {
    return state.estimate.contract_total_price
  },
  getIsJkPhotosInAdvance: (state: BaseEstimateState) => {
    return state.estimate?.is_jk_photos_in_advance
  },
  getEstimateType: (state: BaseEstimateState) => {
    return state.estimate.type
  },
  getUseDate: (state: BaseEstimateState): string => {
    return state.estimate.use_date
  },
  getPhotoPlan: (
    state: BaseEstimateState
  ): BaseEstimateState['estimate']['photo_plan'] | null => {
    return state.estimate?.photo_plan ?? null
  },
  getContractServiseType: (state: BaseEstimateState): number => {
    return state.estimate.contract_service_type
  },
  getSisterId: (state: BaseEstimateState): number | null => {
    return state.estimate.sisters?.customer_id ?? null
  },
  getDiscounts: (
    state: BaseEstimateState
  ): BaseEstimateState['estimate']['discounts'] => {
    return state.estimate.discounts
  },
  getCustomerProfile: (
    state: BaseEstimateState
  ): BaseEstimateState['customerProfile'] => {
    return state.customerProfile
  },
  getContractId: (
    state: BaseEstimateState
  ): BaseEstimateState['contractId'] => {
    return state.contractId
  },

  getBeforeContractDate: (
    state: BaseEstimateState
  ): BaseEstimateState['beforeContractDate'] => {
    return state.beforeContractDate
  },
}

export default getters
