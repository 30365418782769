import { MutationTree } from 'vuex'
import { MemoStateGraduate } from '~/store/types'
import { getInitialStore } from './state'

const mutations: MutationTree<MemoStateGraduate> = {
  setState: (
    state,
    payload: { stateName: keyof MemoStateGraduate; value: never }
  ) => {
    state[payload.stateName] = payload.value
  },

  initAllState: (state) => {
    const init = getInitialStore()
    state.text = init.text
    state.is_chance = init.is_chance
    state.estimate_id = init.estimate_id
    state.memo_id = init.memo_id
    state.customer_id = init.customer_id
  },
}

export default mutations
