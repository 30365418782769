import dayjs from 'dayjs'

/** enums */
import {
  PRODUCT_TYPE,
  PHOTO_ALBUM,
  PHOTO_ALBUM_PRICE,
  CUTS_PLAN_OPTIONS,
  DIGIT_OPTIONS,
  SLEEVE_OPTIONS,
} from '../constants/estimate/config'
import {
  SERVICE_PLAN,
  FURISODE_PLAN,
  PLAN,
  GRADUATION_ZORI,
  ADULT_AGE,
} from '../constants/estimate/comingOfAge'
import { PaymentCategory } from '../constants/enums/paymentCategory'
import { EstimateSinglePriceType } from '../constants/enums/estimateSinglePriceType'
import { AttendanceThingsToBring } from '../constants/enums/attendanceThingsToBring'

import { Items } from '../api/estimateRepository'
import { Discounts } from '../api/contractRepository'
import { KimonoSearchResult } from '../composable/api/useSearchProduct'
import DateTime from './dateTime'
import { VisitMotive } from '~/store/types'
import { Payment } from '~/api/paymentRepository'
import type { PriceOption } from '~/composable/estimate/class-types'
import { Enum紹介者特典 } from '../constants/estimate/graduationOptions'
import { GeneralItem } from '~/store/Contracts/types'
import { ItemPlan } from '../constants/enums/itemPlan'

// 割引を整形 例：80%引き
export const discountRateToStr = (rate?: number) => {
  const RateToNum = typeof rate === 'number' ? rate : 0
  return `${RateToNum}%引き`
}

// 金額を整形 例：￥000.0000
export const priceToStr = (price: number) => {
  const pricetoNum = price === undefined ? 0 : Number(price)
  return `￥${pricetoNum.toLocaleString()}`
}

/**
 * 日付を整形
 * @deprecated Ph1.3~ `utils/dateTime.ts`を使用してください
 * @param {Date} date
 * @returns yyyy-mm-dd
 */
export const formatDate = (date: Date) => {
  const yyyy = date.getFullYear()
  const mm = (date.getMonth() + 1).toString().padStart(2, '0')
  const dd = date.getDate().toString().padStart(2, '0')
  return `${yyyy}-${mm}-${dd}`
}

/**
 * 日付を整形
 * @deprecated Ph1.3~ `utils/dateTime.ts`を使用してください
 * @param {Date} date
 * @returns yyyy-mm-dd hh:mm
 */
export const formatDateTime = (date: Date) => {
  const localeString = date.toLocaleString()
  const [yyyymmddStr, hhmmssStr] = localeString.split(' ')
  const yyyymmdd = yyyymmddStr.replaceAll('/', '-')
  // hh:mm:ssの場合はssを取り除く
  if (hhmmssStr.match(/^[0-9]{2}:[0-9]{2}:[0-9]{2}$/)) {
    const [hh, mm, _ss] = hhmmssStr.split(':')
    return `${yyyymmdd} ${hh}:${mm}`
  }
  return `${yyyymmdd} 00:00`
}

/**
 * date型を整形
 * @deprecated Ph1.3~ `utils/dateTime.ts`を使用してください
 * @param {Date} date
 * @returns yyyy年mm月dd日（曜日）
 */
export const formatDateToString = (date: Date) => {
  const today = `${date.getFullYear()}-${1 + date.getMonth()}-${date.getDate()}`
  return dateToStr2(today)
}

/**
 * 日付を美容室入室時間用に整形 → 基準時間に1:30を足した時間
 */
export const formatEnteringSalonDateTime = (date: Date): string => {
  date.setHours(date.getHours() + 1)
  date.setMinutes(date.getMinutes() + 30)

  const year = date.getFullYear()
  const month = ('0' + (1 + date.getMonth())).slice(-2)
  const day = ('0' + date.getDate()).slice(-2)
  const hour = ('0' + date.getHours()).slice(-2)
  const minute = ('0' + date.getMinutes()).slice(-2)
  return `${year}-${month}-${day} ${hour}:${minute}`
}

/**
 * 日付を整形
 * @deprecated Ph1.3~ `utils/dateTime.ts`を使用してください
 * @param {string} date
 * @returns yyyy年mm月dd日
 */
export const dateToStr = (date: string) => {
  const dateArray = date.split('-')
  const yy = dateArray[0]
  const mm = dateArray[1]
  const dd = dateArray[2]
  if (dd === undefined || mm === undefined || dd === undefined) return 'ー'
  return `${yy}年${mm}月${dd}日`
}

/**
 * 日付を整形
 * @deprecated Ph1.3~ `utils/dateTime.ts`を使用してください
 * @param {string} date
 * @returns yyyy年mm月dd日（曜日）
 */
export const dateToStr2 = (date: string) => {
  const week = ['日', '月', '火', '水', '木', '金', '土']
  const toDate = new Date(date.replace(/-/g, '/'))
  const dayOfWeek = week[toDate.getDay()]
  if (toDate === undefined || dayOfWeek === undefined) return 'ー'
  return `${toDate.getFullYear()}年${
    toDate.getMonth() + 1
  }月${toDate.getDate()}日（${dayOfWeek}）`
}

/**
 * hh:mm のみにする
 * @deprecated Ph1.3~ `utils/dateTime.ts`を使用してください
 * @param dateTime
 * @returns
 */
export const formatOnlyTime = (dateTime?: string) => {
  if (!dateTime) {
    return ''
  }
  const dateArray = dateTime.split(' ')
  // hh:mm:ssの場合はssを取り除く
  if (dateArray[1].match(/^[0-9]{2}:[0-9]{2}:[0-9]{2}$/)) {
    const [hh, mm, _ss] = dateArray[1].split(':')
    return `${hh}:${mm}`
  }
  return dateArray[1]
}

// yyyy年mm月dd日（曜日）hh:mm に変換
export const formatDateAndTime = (dateTime: string) => {
  const dateArray = dateTime.split(' ')
  const date = dateArray[0]
  const time = dateArray[1]
  return `${dateToStr2(date)} ${time}`
}

// yyyy-mm-dd hh:mm に変換
export const formatDateAndTimeToDateTime = (dateTime: string) => {
  const dateArray = dateTime.split(' ')
  const date = dateArray[0]
  const time = dateArray[1]
  // hh:mm:ssの場合はssを取り除く
  if (time.match(/^[0-9]{2}:[0-9]{2}:[0-9]{2}$/)) {
    const [hh, mm, _ss] = time.split(':')
    return `${date} ${hh}:${mm}`
  }
  return `${date} ${time}`
}

// 使用日が契約日(今日の日付)の前だったらアラートを出す
export const alertIfContractAfterUseDate = (useDate: string | undefined) => {
  if (useDate) {
    const isUseDateAfterToday = new DateTime().isBefore(useDate, 'day')
    const isUseDateSameAsToday = new DateTime().isSameDate(useDate, 'day')

    if (isUseDateSameAsToday) return
    if (!isUseDateAfterToday) {
      alert('使用日に契約日より過去の日付を入力することはできません')
    }
  }
}

// 数値合計用（undefinedがあるとNanになるため数値に変換）
export const convertToNumber = (price?: number) => {
  if (!price) return 0
  return Number(price)
}

// Wフォト用のプラン
export const wPhotoPlanType = (id: 0 | 1) => {
  return id === 1 ? 'お持込' : '仕立て上がりレンタル'
}

// 契約区分を表示用に変換
export const contractTypeToStr = (id: number | null) => {
  if (id === 0) return '成人式'
  if (id === 1) return '卒業式'
}

// 当日か前撮りかの表示
export const kimonoPlanToStr = (id: number | null) => {
  if (id === 0) return '当日'
  if (id === 1) return '前撮り'
}

// ----- 以下「入力確認画面」「見積一覧画面」での表示用に使用 -----

// プラン選択関連
export const planType = (array: number[]) => {
  if (array.includes(SERVICE_PLAN.FULL_SET)) return 'フルセット'
  if (array.includes(SERVICE_PLAN.BRING_IN)) return '持ち込み'
  if (array.includes(SERVICE_PLAN.ONLY_PHOTO)) return '写真のみ'
}

export const serviceType = (array: number[]) => {
  if (array.includes(SERVICE_PLAN.OUR_DRESSIN)) return '弊社着付け'
  if (array.includes(SERVICE_PLAN.COSMETOLOGY_SALON)) return '遠方提携美容室'
  if (array.includes(SERVICE_PLAN.NO_DRESSING)) return '着付けなし'
  return 'ー'
}

export const usage = (array: number[]) => {
  if (array.includes(SERVICE_PLAN.FULL_SET)) return '前撮りと成人式'
  if (
    array.includes(SERVICE_PLAN.BRING_IN) &&
    array.includes(SERVICE_PLAN.ONLY_COMIMG_OF_AGE)
  )
    return '成人式のみ'
  if (
    array.includes(SERVICE_PLAN.BRING_IN) &&
    array.includes(SERVICE_PLAN.PRE_SHOOTING_AND_COMIMG_OF_AGE)
  )
    return '前撮りと成人式'
  if (
    array.includes(SERVICE_PLAN.BRING_IN) &&
    array.includes(SERVICE_PLAN.NOT_PURCHASE_AT_OUR)
  )
    return '前撮りと成人式'
  if (array.includes(SERVICE_PLAN.ONLY_PHOTO)) return '前撮りのみ'
}

export const zoriBagRunkType = (array: number[]) => {
  //* NOTE: 商品選択で4番の商品と判定が一緒になり間違った表記になってしまうため変更
  if (array[0] === GRADUATION_ZORI.BRING_IN) return 'お持込'
  if (array.includes(GRADUATION_ZORI.IN_THE_SET)) return 'セット内'
  if (array.includes(GRADUATION_ZORI.RANK_UP_IN_PREMIUM))
    return 'ランクアップ｜プレミアム内'
  if (array.includes(GRADUATION_ZORI.RANK_UP_OUT_PREMIUM))
    return 'ランクアップ｜プレミアム外'
}

export const zoriBagSetOrRunkUpType = (array: number[]) => {
  if (!array.includes(GRADUATION_ZORI.IN_THE_SET)) return 'ランクアップ'
  return ''
}

export const furisodeType = (array: number[]) => {
  if (array.includes(FURISODE_PLAN.ORDER_RENTAL)) return 'オーダーレンタル'
  if (array.includes(FURISODE_PLAN.CUSTOM_RENTAL)) return 'お誂えレンタル'
  return 'レンタル'
}

export const setOrRunkUpType = (array: number[]) => {
  if (!array.includes(PLAN.IN_THE_SET)) return 'ランクアップ'
  return ''
}

// 別衿、帯「レンタルのみ ランクアップ購入はセット内」の場合の表示制御
export const setOrRunkUpTypeObiAndBetueri = (array: number[]) => {
  if (
    !array.includes(PLAN.IN_THE_SET) &&
    !array.includes(PLAN.RANK_UP_ONLY_RENTAL)
  )
    return 'ランクアップ'

  return ''
}

// NOTE: 1:セット内 2:レンタルのみランクアップ（購入はセット内） 3:ランクアップ（プレミアム内） 4:ランクアップ（プレミアム外） 5:未定または1~4,6いづれでもない時（単品購入など） 6:お持ち込み  9:なし（帯・帯締め・帯揚げ用）
export const setPlan = (patternLine: number[]) => {
  if (patternLine.includes(PLAN.IN_THE_SET)) return ItemPlan.inSet
  else if (patternLine.includes(PLAN.RANK_UP_ONLY_RENTAL))
    return ItemPlan.onlyRentalRankUp
  else if (patternLine.includes(PLAN.RANK_UP_IN_PREMIUM))
    return ItemPlan.rankUpInPremium
  else if (patternLine.includes(PLAN.RANK_UP_OUT_PREMIUM))
    return ItemPlan.rankUpOutPremium
  else if (patternLine.includes(PLAN.BRING_IN)) return ItemPlan.bringIn
  else if (patternLine.includes(PLAN.NO_USE)) return ItemPlan.noUse
  else return ItemPlan.toBeDecided
}

// 1:単品レンタル価格 2:単品購入価格
export const setType = (patternLine: number[]) => {
  if (patternLine.includes(PLAN.SINGLE_RENTAL_PRICE)) {
    return EstimateSinglePriceType.singleRentalPrice
  } else if (
    patternLine.includes(PLAN.SINGLE_BUY_PRICE) ||
    patternLine.includes(GRADUATION_ZORI.BUY_THE_SAME_PRODUCT)
  ) {
    return EstimateSinglePriceType.singlePurchasePrice
  } else {
    return undefined
  }
}

// 単品レンタル・単品購入の金額表示の切り替えに使用
export const switchSinglePriceType = (item: Items) => {
  if (!item) return 0
  if (item.price_type === EstimateSinglePriceType.singleRentalPrice) {
    return Number(item.rental_price)
  }
  if (item.price_type === EstimateSinglePriceType.singlePurchasePrice) {
    return Number(item.purchase_price)
  }
  return 0
}

// 反映する価格を切り替える（inputの値を反映するかの判定）
export const isFreeInput = (
  selections: number[],
  isFree: boolean,
  isSelection?: boolean
) => {
  // 振袖・帯締めは例外（帯締めはプレミアムパック外でも金額選択があるため）
  if (isSelection && !isFree) return false

  return isFree || setPlan(selections) === 4
}

// 反映する価格を切り替える（フリー入力がある または 「ランクアップ（プレミアム外）」の場合にtrue）
export const isWPhotoPlanFreeInput = (
  selections: number[],
  isFree: boolean
) => {
  return isFree || setPlan(selections) === 4
}

// プレミアムパックの金額を表示するかのフラグ
export const isPremiumPack = (array: number[]) => {
  return array.includes(PLAN.RANK_UP_OUT_PREMIUM)
}

export const isPremiumZoriBag = (array: number[]) => {
  return array.includes(GRADUATION_ZORI.RANK_UP_OUT_PREMIUM)
}

// 割引等のフラグ
export const isAdapt = (array: number[]) => {
  if (!array) return false
  return !!array.includes(PLAN.APPLY)
}

// 紹介者特典関連
export const referralBonusType = (array: number[], index: number) => {
  if (array[index] === PLAN.INTRODUCER_YUKATA) return '浴衣'
  if (array[index] === PLAN.INTRODUCER_QUO) return 'QUO'
  if (array[index] === PLAN.INTRODUCER_PHOTO) return '写真'
  if (array[index] === PLAN.INTRODUCER_EXCHANGE_TICKET) return '引換券'
  if (array[index] === PLAN.INTRODUCER_HURISODE_MODE_TICKET)
    return 'ふりそでMODE商品券'
  if (array[index] === PLAN.INTRODUCER_TO_BE_DECIDED) return '未定'
  if (array[index] === PLAN.INTRODUCER_DONE) return 'お渡し済み'
  if (array[index] === PLAN.INTRODUCER_NOT_YET) return '未'

  if (array[index] === PLAN.USER_YUKATA) return '浴衣'
  if (array[index] === PLAN.USER_QUO) return 'QUO'
  if (array[index] === PLAN.USER_PHOTO) return '写真'
  if (array[index] === PLAN.USER_EXCHANGE_TICKET) return '引換券'
  if (array[index] === PLAN.USER_HURISODE_MODE_TICKET)
    return 'ふりそでMODE商品券'
  if (array[index] === PLAN.USER_DONE) return 'お渡し済み'
  if (array[index] === PLAN.USER_NOT_YET) return '未'
}

// 振袖持ち込み時、見積一覧画面の価格表示に使用
export const bringInType = (array: number[]) => {
  if (array.includes(PLAN.SINGLE_RENTAL_PRICE)) return '単品レンタル'
  if (array.includes(PLAN.SINGLE_BUY_PRICE)) return '単品購入'
  return undefined
}

// 振袖持ち込み時、レンタル価格と購入価格どちらを表示するか
export const isShowRentalPrice = (array: number[]) => {
  if (array.includes(PLAN.SINGLE_BUY_PRICE)) return false
  return true
}

// 各小物のプラン
export const rankType = (value: number) => {
  if (value === 1) return 'セット内'
  else if (value === 2) return 'レンタルのみランクアップ'
  else if (value === 3) return 'ランクアップ｜プレミアム内'
  else if (value === 4) return 'ランクアップ｜プレミアム外'
  else if (value === 6) return 'お持込'
  else return undefined // 5: 未定と単品購入・単品レンタルは表示しない
}

// 各小物のプラン(卒業式用)
export const graduationRankType = (item: GeneralItem) => {
  if (item.plan === 1) return 'セット内'
  else if (item.plan === 3) return 'ランクアップ'
  else if (item.plan === 4) return 'ランクアップ'
  else if (item.plan === 6) return 'お持込'
  else return '' // 単品購入は表示しない
}

// 紹介者特典お渡し状況
export const referralBonusStatusToStr = (value: number | null) => {
  if (value === Enum紹介者特典.ご利用者様お渡し.未) return '未'
  if (value === Enum紹介者特典.ご利用者様お渡し.済み) return 'お渡し済み'
  return 'ー'
}

// 基本情報
export const baseInformationType = (value: number) => {
  if (value === 1) return '新規来店'
  if (value === 2) return '再来店'
}

// 納品希望日
export const deliveryDateType = (value: number) => {
  if (value === 1) return '前撮りと同じ日にする'
  if (value === 2) return '前撮りや成人式より早い日程を設定する'
}

// 成人式
export const comingOfAgeCeremonyType = (value: number) => {
  if (value === 1) return '決まっている'
  if (value === 2) return '後日ご案内致します'
  if (value === 3) return 'ご来店'
  if (value === 4) return '前撮り日にお渡し'
  if (value === 5) return 'ご来店'
  if (value === 6) return 'お送り'
  if (value === 7) return '未定'
}

// 小物
export const accessoriesType = (value: number, selectionType: number) => {
  if (selectionType === 0) {
    if (value === 0) return 'N'
    if (value === 1) return 'B'
    if (value === 2) return 'BB'
  }
  if (selectionType === 1) {
    if (value === 0) return 'S'
    if (value === 1) return 'M'
    if (value === 2) return 'L'
    if (value === 3) return 'LL'
    if (value === 4) return '3L'
  }
  if (selectionType === 3) {
    if (value === 1) return 'M'
    if (value === 2) return 'L'
    if (value === 3) return 'LL'
  }
  if (selectionType === 2) {
    // booleanでの数値判定かもしれない
    if (value === 1) return '済'
    if (value === 0) return '未'
  }
  // if (selectionType === 4) {
  //   if (value === 1) return '白レース'
  //   if (value === 2) return 'こはぜ'
  //   if (value === 2) return '現品'
  // }
}

// お客様ご準備

export const customerReadyType = (customerReadyArray: number[]) => {
  return customerReadyArray
    .map((value) => {
      if (value === 0) return '肌着・裾除'
      if (value === 1) return '足袋'
      if (value === 2) return '薄いタオル５本'
      if (value === 3) return 'ブーツ・靴下'
      if (value === 4) return 'バッグ'
      if (value === 5) return 'キャミソール'
      if (value === 6) return '着付け小物'
    })
    .filter(Boolean)
}

export const formatReasons = (reasons: VisitMotive[]): string => {
  if (!reasons.length) return '未設定'
  return reasons.map((r) => r.name).join('・')
}

export const attendanceCustomerReadyType = (customerReadyArray: number[]) => {
  return customerReadyArray
    .map((value) => {
      if (value === AttendanceThingsToBring.肌着裾除) return '肌着・裾除'
      if (value === AttendanceThingsToBring.足袋) return '足袋'
      if (value === AttendanceThingsToBring.薄いタオル5本)
        return '薄いタオル５本'
      if (value === AttendanceThingsToBring.草履) return '草履'
      if (value === AttendanceThingsToBring.バッグ) return 'バッグ'
      if (value === AttendanceThingsToBring.髪飾り) return '髪飾り'
      if (value === AttendanceThingsToBring.着付小物)
        return '着付小物（腰ひも・帯枕・三重紐・コーリンベルト・帯板）'
      if (value === AttendanceThingsToBring.インナー) return 'インナー'
      if (value === AttendanceThingsToBring.Yシャツ) return 'Yシャツ'
      if (value === AttendanceThingsToBring.靴下) return '靴下'
      if (value === AttendanceThingsToBring.革靴) return '革靴（黒）'
    })
    .filter(Boolean)
}

// お支払
export const paymentType = (value: number) => {
  if (value === 1) return '内金'
  if (value === 2) return '全額支払'
  if (value === 3) return '現金'
  if (value === 4) return 'カード'
  if (value === 5) return '商品券'
  if (value === 6) return '友の会ポイント'
  if (value === 7) return '一部のポイントを使う'
  if (value === 7) return '利用可能なすべてのポイントを使う'
}

// お支払方法
export const paymentName = (value: number) => {
  if (value === 1) return '現金'
  if (value === 2) return '商品券'
  if (value === 3) return '振込'
  if (value === 4) return '友の会ポイント'
  if (value === 5) return 'カード'
  if (value === 6) return 'ローン'
  if (value === 7) return '代引き'
  if (value === 8) return '電子マネー'
  if (value === 9) return '友の会ポイント（ご家族保有）'
  if (value === 10) return '入金値引き'
  if (value === 50) return 'ポイント端数処理'

  return 'ー'
}

// 友の会ポイントの支払い方法
export const tomonokaiPayment = (value: number) => {
  if (value === 0) return '一部利用'
  if (value === 1) return '金額'

  return 'ー'
}

// 裄補正のフラグ
export const isYukitakeCorrectionFee = (array: number[]) => {
  return array.includes(FURISODE_PLAN.YUKI_CORRECTION)
}

// 袖補正のフラグ
export const isSodetakeCorrectionFee = (array: number[]) => {
  return array.includes(FURISODE_PLAN.SODE_CORRECTION)
}

// 着付けなし割引のフラグ
export const isNoDressingDiscount = (array: number[]) => {
  return array.includes(SERVICE_PLAN.NO_DRESSING)
}

// 別衿加工代
export const isSeparateCollarProcessingFee = (array: number[]) => {
  return array.includes(PLAN.BETSUERI_NO)
}

export const contractType = (id: number) => {
  switch (id) {
    case 1:
      return '成人式'
    case 2:
      return '卒業式'
    case 3:
      return '参列'
    case 4:
      return '結納'
    case 6:
      return '写真のみプラン'
    case 7:
      return '単品'
    case 9:
      return '未契約'
    default:
      return 'その他'
  }
}

/**
 * 各商品のidを文字列に変換する(new)
 * @param  {number} id
 */
export const newProductTypeIdToStr = (id: number) => {
  if (id === 1) return '振袖'
  if (id === 2) return '帯'
  if (id === 3) return '帯締め'
  if (id === 4) return '帯揚げ'
  if (id === 5) return '重ね衿'
  if (id === 6) return '別衿'
  if (id === 7) return 'ショール'
  if (id === 8) return '二尺袖'
  if (id === 9) return '袴'
  if (id === 10) return '訪問着'
  if (id === 11) return '留袖'
  if (id === 12) return 'パーティドレス'
  if (id === 13) return '写真'
  if (id === 17) return 'サービス'
  if (id === 18) return '半巾帯'
  if (id === 19) return '呉服'
  if (id === 20) return 'モーニング'
  if (id === 21) return 'クリーニング'
  if (id === 22) return 'ウェディングドレス'
  if (id === 23) return 'タキシード'
  if (id === 24) return 'アクセサリー'
  if (id === 25) return '髪飾り'
  if (id === 27) return '草履'
  if (id === 28) return 'バッグ'
  if (id === 29) return '税調整'
  if (id === 30) return 'JK前々撮り'
  if (id === 31) return '巾着'
  if (id === 32) return '長襦袢'
  if (id === 33) return '帯板'
  if (id === 34) return '伊達〆'
  if (id === 35) return '腰紐'
  if (id === 36) return '衿芯'
  if (id === 37) return '三重紐・枕'
  if (id === 38) return 'コーリンベルト'
  if (id === 39) return '肌着'
  if (id === 40) return 'タオル'
  if (id === 41) return '足袋'
  if (id === 42) return 'ブーツ'
  if (id === 43) return 'その他'
  return '未設定'
}

/**
 * 小物のproductTypeIdを品種区分IDに相互変換する関数
 */
export const transferProductTypeId = (
  id: number,
  { from = 'old' }: { from: 'old' | 'new' }
) => {
  if (from === 'old') {
    // (旧)小物ID → [品種区分]ID
    switch (id) {
      // 髪飾り
      case 8:
        return 41
      // 草履
      case 9:
        return 27
      // バッグ
      case 10:
        return 28
      // 巾着
      case 11:
        return 31
      // 半巾帯
      case 12:
        return 32
      // 振袖/二尺袖
      case 13:
        return 8
      // 袴
      case 14:
        return 9
      default:
        return id
    }
  } else {
    // [品種区分]ID → (旧)小物ID
    switch (id) {
      // 髪飾り
      case 41:
        return 8
      // 草履
      case 27:
        return 9
      // バッグ
      case 28:
        return 10
      // 巾着
      case 31:
        return 11
      // 半巾帯
      case 32:
        return 12
      // 振袖/二尺袖
      case 8:
        return 13
      // 袴
      case 9:
        return 14
      default:
        return id
    }
  }
}

/**
 * 母娘プランでJK前撮りや前撮りが選択されていれば表示する
 */
export const isShow母娘プランPHOTO_IN_ADVANCE = (selected: number): boolean => {
  return selected === PLAN.JK_PHOTO_IN_ADVANCE ||
    selected === PLAN.PHOTO_IN_ADVANCE
    ? true
    : false
}

/**
 * @note 引数の日付から年齢を計算して（〜歳）にし、引数がない場合は空欄を出力する関数
 */
export const formatAge = (date?: string | null) => {
  if (!date) return ''
  return `（${getAge(date)}歳）`
}

/**
 * 年齢をnumberで返却する関数
 * @param (string | null)  YYYY-MM-DD
 * @return  (number) 例: 23
 */
export const getAge = (date: string | null) => {
  if (!date) return 0

  const today = dayjs()
  const birthDate = dayjs(date)
  return today.diff(birthDate, 'year')
}

/**
 * 未成年かどうか
 * (今日 - ADULT_AGE年) が お客様誕生日 よりも前
 * @param birthDate
 */
export const isMinor = (birthDate: string) => {
  if (!birthDate) return false
  const regDateString = /\d{4}\-\d{1,2}\-\d{1,2}/
  if (!regDateString.test(birthDate)) return false

  return dayjs().subtract(ADULT_AGE, 'year').isBefore(dayjs(birthDate))
}

export const convertedKimonoSize = (value: Array<number>) => {
  const syaku = `${value[0]}尺`
  const sun = `${value[1]}寸`
  const bu = `${value[2]}分`
  return syaku + sun + bu
}

export type BodyData = {
  bust: number
  hip: number
  footSize: number
}

/**
 * 成人式小物一覧から持ち込みの小物名一覧を取得する
 * @param items 小物一覧
 * @returns 持ち込み小物名
 */
export const filterBringItems = (
  items: { product_type_id: number; plan: number }[]
) => {
  return items
    .filter((item) => item.plan === 6)
    .map(({ product_type_id }) => {
      const target = Object.entries(PRODUCT_TYPE).find(
        ([_, data]) => data.id === product_type_id
      )
      if (!target || !target[1]) {
        return ''
      }

      return target[1].text
    })
    .filter(Boolean)
}

export const suggestDigitSleeveFromHeight = (
  heightString: string,
  isトールサイズ商品: boolean,
  is2サイズのみお誂え商品?: boolean
): { digit: string; sleeve: string } => {
  const height: number = Math.round(Number(heightString))

  // TODO:
  // エラーハンドリング
  // (現状はアプリが止まらないように空文字列を返します)
  if (!Number.isFinite(height)) return { digit: '', sleeve: '' }

  /** 特定の商品は2サイズ（180と185）しか存在しないため、お誂え契約の際に身長に合わせて振袖のサイズが180か185で登録する */
  if (is2サイズのみお誂え商品) {
    if (height < 160) return { digit: '1,8,0', sleeve: '2,8,0' }
    return { digit: '1,8,5', sleeve: '2,9,0' }
  }

  // NOTE:
  // https://www.notion.so/arsagajp/68ec0bf12f0f4324938017d556895c03#f432fe0434624b3f88d4acf3fbc168be
  if (height <= 149) return { digit: '1,7,0', sleeve: '2,7,0' }
  if (height > 149 && height <= 154) return { digit: '1,7,5', sleeve: '2,7,0' }
  if (height > 154 && height <= 159) return { digit: '1,8,0', sleeve: '2,8,0' }
  if (height > 159 && height <= 164) return { digit: '1,8,5', sleeve: '2,9,0' }
  if (!isトールサイズ商品 && height >= 164)
    return { digit: '1,9,0', sleeve: '3,0,0' }
  if (height > 164 && height <= 169) return { digit: '1,9,0', sleeve: '3,0,0' }
  if (height > 169 && height <= 174) return { digit: '1,9,5', sleeve: '3,0,5' }
  return { digit: '2,0,0', sleeve: '3,0,5' }
}

export const suggestSleeveFromDigit = (digit: string) => {
  switch (digit) {
    case DIGIT_OPTIONS[0].id: // 1,6,5
      return SLEEVE_OPTIONS[4].id // 2,7,0
    case DIGIT_OPTIONS[1].id: // 1,7,0
      return SLEEVE_OPTIONS[4].id // 2,7,0
    case DIGIT_OPTIONS[2].id: // 1,7,5
      return SLEEVE_OPTIONS[4].id // 2,7,0
    case DIGIT_OPTIONS[3].id: // 1,8,0
      return SLEEVE_OPTIONS[6].id // 2,8,0
    case DIGIT_OPTIONS[4].id: // 1,8,5
      return SLEEVE_OPTIONS[8].id // 2,9,0
    case DIGIT_OPTIONS[5].id: // 1,9,0
      return SLEEVE_OPTIONS[10].id // 3,0,0
    case DIGIT_OPTIONS[6].id: // 1,9,5
      return SLEEVE_OPTIONS[11].id // 3,0,5
    case DIGIT_OPTIONS[7].id: // 2,0,0
      return SLEEVE_OPTIONS[11].id // 3,0,5
    default:
      return
  }
}

export const getFullName = (lastName: string, firstName: string): string =>
  `${lastName} ${firstName}`

/**
 * カット数の料金・プラン名・ID(key)を算出する
 */
export const CutsConverter = {
  calculatePrice: (
    plan: typeof PHOTO_ALBUM[keyof typeof PHOTO_ALBUM] | number
  ) => {
    const result = Object.entries(PHOTO_ALBUM).find(
      ([_, value]) => plan === value
    )
    if (!result) {
      return 0
    }
    const key = result[0] as keyof typeof PHOTO_ALBUM
    return PHOTO_ALBUM_PRICE[key]
  },

  toWord: (plan: typeof PHOTO_ALBUM[keyof typeof PHOTO_ALBUM] | number) => {
    const result = Object.entries(CUTS_PLAN_OPTIONS).find(
      ([_, { id }]) => id === plan
    )
    if (!result) {
      return 'ー'
    }

    return result[1].value
  },

  toId: (word: string) => {
    const result = Object.entries(CUTS_PLAN_OPTIONS).find(
      ([_, { value }]) => value === word
    )
    if (!result) {
      return null
    }

    return result[1].id
  },

  toCuts: (plan: typeof PHOTO_ALBUM[keyof typeof PHOTO_ALBUM] | number) => {
    switch (plan) {
      case PHOTO_ALBUM.FULL_SET_RENTAL:
      case PHOTO_ALBUM.ONLY_PHOTO_BRING_IN_PREMIUM:
      case PHOTO_ALBUM.ONLY_PHOTO_RENTAL_PREMIUM:
      case PHOTO_ALBUM.PLAN_A_IN_PREMIUM:
      case PHOTO_ALBUM.PLAN_B_IN_PREMIUM:
        return {
          pause: 15,
          book: 1,
        }
      case PHOTO_ALBUM.FULL_SET_BUY:
        return {
          pause: 15,
          book: 2,
        }
      case PHOTO_ALBUM.PLAN_B_IN_STANDARD:
      case PHOTO_ALBUM.ONLY_PHOTO_BRING_IN_STANDARD:
      case PHOTO_ALBUM.ONLY_PHOTO_RENTAL_STANDARD:
      case PHOTO_ALBUM.PLAN_A_IN_STANDARD:
        return {
          pause: 5,
          book: 1,
        }
      default:
        return {
          pause: 0,
          book: 0,
        }
    }
  },
}

export const sumCordinate = (list: any[]) => {
  return list.reduce((sum, { price }) => {
    if (typeof price !== 'number') {
      return sum
    }

    return sum + price
  }, 0)
}

export const afterDate = (dayNumber: number) => {
  const toDay = new Date()
  toDay.setDate(toDay.getDate() + dayNumber)
  return toDay
}

export const formatZipCord = (arg?: string | null) => {
  if (!arg) {
    return '-'
  }

  return '〒' + arg.slice(0, 3) + '-' + arg.slice(3, arg.length)
}

export const formatZipCodeOrDash = (arg?: string | null) => {
  if (!arg) {
    return 'ー'
  }

  return '〒' + arg.slice(0, 3) + '-' + arg.slice(3, arg.length)
}

/**
 * productTypeIdで指定した小物を検索し、結果を見積時の順番に並べ替えて返す
 * @param items 見積詳細APIに含まれる小物情報
 * @param productTypeId 小物の品種id
 * @returns {Items[]} order_numberの昇順に並べ変えた配列
 */
export const getSortedItemsByOrder = (
  items: Items[],
  productTypeId: number
) => {
  const filteredItems = items.filter(
    (item) => item.product_type_id === productTypeId
  )
  return filteredItems.sort((a, b) => {
    // NOTE: 順番情報がない場合は配列の最後に回す
    if (!a.order_number) return 1
    if (!b.order_number) return -1
    return a.order_number - b.order_number
  })
}

export const hasEnabledDiscount = (
  discounts: Discounts[],
  discountType: number
) =>
  Boolean(discounts.find(({ discount_type }) => discount_type === discountType))

export const getDiscountAppliedStatusByType = (
  discounts: Discounts[],
  discountType: number
) => {
  return hasEnabledDiscount(discounts, discountType)
    ? PLAN.APPLY
    : PLAN.NO_APPLY
}

/**
 * @note 仕立て上り着物検索APIのレスポンスにて、降順に並び替えを行う関数
 * @param {KimonoSearchResult[]} reserveList
 */
export const sortDescByReserveList = (reserveList: KimonoSearchResult[]) =>
  reserveList.sort(
    (first, second) =>
      Number(new Date(second.end_date!)) - Number(new Date(first.end_date!))
  )

/**
 * @note 引数として渡されたテキストが存在しない場合に別のテキストに置き換える関数
 */
export const displayText = (
  text?: string | null | undefined,
  replaceText = 'ー'
): string => {
  return text ?? replaceText
}

/**
 * @note 引数として渡された日付が存在しない場合に別のテキストに置き換える関数
 */
export const displayDateToJpStringWithWeek = (
  date?: string | null | undefined,
  replaceText = 'ー'
): string => {
  return date ? new DateTime(date).toJpStringWithWeek() : replaceText
}

/**
 * 寸法対応表 身長から寸法を割り出す
 * notion仕様書：https://www.notion.so/arsagajp/39a99efdc4464e1695275acb00f2a42e#f3d3a80d589f4d66bdee90fdeb9a741f
 * @param height
 * @returns 寸法
 */
export const convertHeightToSun = (height: number | null) => {
  if (!height) {
    return null
  }
  if (height <= 144) {
    return 2
  } else if (height <= 149) {
    return 3
  } else if (height <= 154) {
    return 4
  } else if (height <= 159) {
    return 5
  } else if (height <= 164) {
    return 6
  } else if (height <= 170) {
    return 7
  } else if (height <= 174) {
    return 8
  } else {
    return 9
  }
}

/**
 * @note 引数として渡された時間が存在しない場合に別のテキストに置き換える関数
 */
export const displayTimeToOnlyTime = (
  time?: string | null | undefined,
  replaceText = 'ー'
): string => {
  return time ? new DateTime(time).toOnlyTime() : replaceText
}

/**
 * @note 引数として渡された日付が存在しない場合にハイフンを出力する関数
 */
export const formatDateOrDash = (date?: string | null) => {
  if (!date) {
    return 'ー'
  }
  return new DateTime(date).toJpString()
}

/**
 * @note 引数があれば番号間のハイフンを無くし、引数がなければ'未設定'と出力する関数
 */
export const formatPhoneNumber = (phoneNumber?: string | null) => {
  if (!phoneNumber) {
    return '未設定'
  }

  return String(phoneNumber.replace(/-/g, ''))
}

/**
 * @note 配列をchunkして新たに配列を作成する関数
 */
export const chunk = <T>(
  array: T[],
  firstChunkSize: number,
  restChunkSize: number
): T[][] => {
  const chunks: T[][] = []
  let i = 0
  chunks.push(array.slice(i, i + firstChunkSize))
  i += firstChunkSize
  while (i < array.length) {
    chunks.push(array.slice(i, i + restChunkSize))
    i += restChunkSize
  }
  return chunks
}

export const divideArrayIntoEqualChunks = <T>(array: T[]) => {
  const midIndex = Math.ceil(array.length / 2)
  const firstHarf = array.slice(0, midIndex)
  const secondHarf = array.slice(midIndex)
  return [firstHarf, secondHarf]
}

/**
 * @note 支払い明細一覧APIのレスポンスを支払い合計と残金を計算してオブジェクトで返却する関数
 */
export const getPriceByPaymentRepo = (
  payments: Payment[] | undefined,
  totalPrice: null | undefined | number
) => {
  const amountPrice =
    payments?.reduce((sum, payment) => {
      return (
        sum +
        (payment.category === PaymentCategory.refund
          ? -Number(payment.amount)
          : Number(payment.amount))
      )
    }, 0) ?? 0

  const totalPriceNum = totalPrice ?? 0
  const balancePrice = totalPriceNum - amountPrice

  return { balancePrice, amountPrice }
}

/**
 * @note URLからどの環境に接続しているかを取得する関数
 *
 */
export const getEnv = (url: string | undefined): string => {
  if (url?.includes('manager-test')) {
    return 'テスト環境'
  } else if (url?.includes('uat-staging')) {
    return '練習環境'
  } else {
    return ''
  }
}

/**
 * 受注可能な締切日に基づいて受注可能かどうかを算出する
 * NOTE: https://docs.google.com/spreadsheets/d/1H9gzr7-3ozKB4ouceIEcMTLvgS_Jx5ghQw9g2l4ITe8/edit#gid=1183625805
 * @param orderQuantity 受注可能数
 * @param orderableDeadline 受注可能な締切日
 */
export const calculateAvailableOrderStatus = (
  orderQuantity: number | null | undefined,
  orderableDeadline: string | null | undefined,
  isCopyEstimateFlag: boolean
): { title: string; isAvailableOrder: boolean } => {
  const today = new Date()
  const overDue = orderableDeadline
    ? new DateTime(today).isAfter(orderableDeadline, 'day')
    : false
  const isOrderQuantityPositive = orderQuantity ? orderQuantity > 0 : false

  // 「見積コピー」で在庫数がない場合、在庫なしの場合でも受付中のものとして表示させる。
  if (isCopyEstimateFlag && !isOrderQuantityPositive) {
    return { title: '受付中 (在庫：○)', isAvailableOrder: true }
  }

  // 締切日設定なしの場合
  if (!orderableDeadline) {
    return isOrderQuantityPositive
      ? { title: `残数︎ ${orderQuantity}着`, isAvailableOrder: true }
      : { title: '受付終了', isAvailableOrder: false }
  }

  // 締切日が過ぎている場合
  if (overDue) {
    return isOrderQuantityPositive
      ? { title: `残数︎ ${orderQuantity}着`, isAvailableOrder: true }
      : { title: '受付終了', isAvailableOrder: false }
  }

  // 受注可能な場合
  return { title: '受付中 (在庫：○)', isAvailableOrder: true }
}

/**
 * @note 表示価格を純粋なnumberに変換する関数（"¥"や","含まれているため、数字のみを抽出）
 */
export const extractNumericValue = (value: string) =>
  parseInt(value.replace(/[^\d]/g, ''), 10)

/**
 * @note ソートを価格昇順にする関数
 */
export const sortByPrice = (options: PriceOption[]): PriceOption[] => {
  return options.sort(
    (a, b) => extractNumericValue(a.value) - extractNumericValue(b.value)
  )
}

/**
 * 重複顧客の削除
 * @note https://qiita.com/allJokin/items/28cd023335641e8796c5#map%E3%82%92%E4%BD%BF%E3%81%A3%E3%81%A6%E9%87%8D%E8%A4%87%E3%82%92%E5%89%8A%E9%99%A4%E3%81%99%E3%82%8B
 * @param items
 * @returns items
 */
export const deleteDuplicateIdItem = <
  T extends { customer_family_id: number | null }
>(
  items: T[]
): T[] => {
  return Array.from(
    new Map(items.map((item) => [item.customer_family_id, item])).values()
  )
}

/**
 * 一番早い日付（YYYY-MM-DD形式）を返却する
 * NOTE: 2024-01-01、2024/01/01とフォーマットが2種類あるため、
 * 区切り箇所を削除し数値比較で一番小さい値を返却
 * @param dates
 * @returns
 */
export const getEarliestDate = (dates: string[]): string => {
  const filteredDates = dates.filter((date) => date)
  if (filteredDates.length > 0) {
    const earliestDate = Math.min(
      ...filteredDates.map((date) => Number(date.replace(/[\/\-]/g, '')))
    )
    return dayjs(String(earliestDate)).format('YYYY-MM-DD')
  } else {
    return ''
  }
}
