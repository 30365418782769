import { GetterTree } from 'vuex'
import { RootState, ContractsPhotoSettings, PhotoSetting } from '../../types'

const getters: GetterTree<ContractsPhotoSettings, RootState> = {
  size: (state: ContractsPhotoSettings) => {
    return state.settings.length
  },
  getPhoto: (state: ContractsPhotoSettings): PhotoSetting[] => {
    return state.settings
  },

  getIsValidate: (state: ContractsPhotoSettings) => {
    return state.isValidate
  },

  getMainPhoto: (state: ContractsPhotoSettings) => {
    return state.settings[0].photos
  },

  getNeckPhoto: (state: ContractsPhotoSettings) => {
    return state.settings[1].photos
  },
}

export default getters
