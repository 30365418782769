import { Restate } from '~/composable/estimate/repository/graduation/EstimateRestate'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { FormValues髪飾り } from '~/composable/estimate/viewModel/graduation/Fields/Fields髪飾り'

export const useRestore髪飾り = () => {
  const store髪飾り1 = useGraduateStore<FormValues髪飾り>('tab髪飾り1')
  const store髪飾り2 = useGraduateStore<FormValues髪飾り>('tab髪飾り2')
  const store髪飾り3 = useGraduateStore<FormValues髪飾り>('tab髪飾り3')

  const restore髪飾り = (restate: Restate) => {
    if (!restate.plan || !restate.items.髪飾り) {
      return
    }
    restate.items.髪飾り.forEach((element, i) => {
      const formValues: FormValues髪飾り = {
        kamikazari種別: null,
        kamikazari購入価格: null,
      }
      formValues.kamikazari種別 = element.optionId種別
      formValues.kamikazari購入価格 = element.単品購入価格

      if (i === 0) store髪飾り1.setAll(formValues)
      if (i === 1) store髪飾り2.setAll(formValues)
      if (i === 2) store髪飾り3.setAll(formValues)
    })
  }
  return { restore髪飾り }
}
