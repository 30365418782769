import { ReferralBonusState } from '~/store/types'

export const state = (): ReferralBonusState => {
  return {
    is_referral_bonus: null,
    introducer_info: null,
    poseNumberByJkPhotoInAdvance: 0, //JK前撮り紹介特典追加ポーズ数
    poseNumberByPhotoInAdvance: 0, //前撮り紹介特典追加ポーズ数
    isValidate: null,
    introducer: {
      bonus_type: null,
      is_passed: null,
      passed_date: null,
      notes: null,
    },
    user: {
      bonus_type: null,
      is_passed: null,
      passed_date: null,
      photo_type: null, // JK前撮り or 前撮り
      notes: null,
    },
  }
}

export default state
