import { EstimateDetail } from '~/api/estimateRepository'

/**
 * 見積詳細のデータの不備（登録時nullの箇所がオブジェクトや配列になっている）を修正する関数
 * @param data
 * @returns
 */
export const estimateDetailEditFormatter = (
  data: EstimateDetail
): EstimateDetail => {
  return {
    ...data,
    hurisode: data.hurisode?.product_name === null ? null : data.hurisode,
    // WORNING: 以下設定されていない場合、振袖などの値が空配列で渡ってくる（APIがPHPのせい？）
    jk_photos_in_advance: Array.isArray(data.jk_photos_in_advance?.hurisode)
      ? null
      : data.jk_photos_in_advance,
    w_photo_plan: Array.isArray(data.w_photo_plan?.hurisode)
      ? null
      : data.w_photo_plan,
    hakama: Array.isArray(data.hakama) ? null : data.hakama,
    sisters: data.sisters?.customer_id === null ? null : data.sisters,
  }
}
