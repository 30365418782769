import { AdjustmentType } from '~/constants/enums/AdjustmentType'
import {
  AmountAdjustments,
  AmountAdjustmentsPrice,
  AmountAdjustmentValidation,
} from '~/store/types'

export type Inputkeys =
  | keyof Pick<AmountAdjustments, 'name'>
  | keyof Pick<AmountAdjustmentsPrice, 'price'>

type InputValues = AmountAdjustments['name'] | AmountAdjustmentsPrice['price']

export default class AdjustmentPrice {
  /**
   * @param amountAdjustment 金額調整用の配列
   * @param type AdjustmentTypeの固定値のみ
   * @return {number} 指定したtypeの金額を取得する
   */
  calculation = (
    amountAdjustment: AmountAdjustments[] = [],
    type: AdjustmentType
  ): number => {
    if (!amountAdjustment?.length) return 0
    return amountAdjustment.reduce((acc, cur) => {
      const sumPrice =
        cur.prices?.reduce((acc, cur) => {
          if (cur.type !== type) return acc
          return acc + cur.price
        }, 0) ?? 0

      return acc + sumPrice
    }, 0)
  }

  /**
   * @param amountAdjustment 更新する配列オブジェクト
   * @param key 更新するpropertyのkey
   * @param data 更新する値
   * @param index1 一層目のindex
   * @param index2 pricesのindex
   * @return {AmountAdjustments[]}
   */
  input = (
    amountAdjustment: AmountAdjustments[],
    key: Inputkeys,
    data: InputValues,
    index1: number,
    index2?: number
  ): AmountAdjustments[] => {
    return amountAdjustment.map((a, i1) => {
      if (i1 === index1) {
        const isTextInput = key === 'name' && typeof data === 'string'
        if (isTextInput) a.name = data as string
        const isPriceInput = key === 'price' && typeof data === 'number'
        if (isPriceInput) {
          a.prices = a.prices.map((p, i2) => {
            if (i2 === index2) p.price = data as number
            return p
          })
        }
      }
      return a
    })
  }

  /**
   * @param amountAdjustment
   * @param adjustmentTypeArr
   * @return {AmountAdjustments[]}
   */
  pushAdjustmentItem = (
    amountAdjustment: AmountAdjustments[] = [],
    adjustmentTypeArr: number[]
  ) => {
    const prices: AmountAdjustments['prices'] = []

    const isAdjustmentType = !adjustmentTypeArr.some(
      (n) => AdjustmentType.toWord(n) === '該当なし'
    )

    if (
      adjustmentTypeArr.length >= 1 &&
      adjustmentTypeArr.length <= 4 &&
      isAdjustmentType
    ) {
      adjustmentTypeArr.map((type) => {
        prices.push({ type, price: 0 })
      })

      amountAdjustment.push({ name: '', prices })
    } else {
      console.error('配列に要素を追加できませんでした')
    }

    return amountAdjustment
  }

  /**
   * @param validation バリデーションするbooleanの配列
   */
  isValid = (validation: AmountAdjustmentValidation) => {
    if (!validation?.length) return false
    return validation.some((b) => {
      if (b?.length) return b.some((s) => s === false) === true
      return true
    })
  }

  /**
   * @note プランごとに追加するフォームの数が違うため、フォームの数と同様のbooleanの配列を作成する
   * @param validation
   * @param number 追加する要素数 (項目名以外の金額入力フォームの個数)
   * @returns
   */
  pushValidation = (
    validation: AmountAdjustmentValidation = [],
    number: number
  ) => {
    const validationArr = [false]

    if (number >= 1 && number <= 4) {
      for (let i = 0; i < number; i++) {
        validationArr.push(false)
      }

      validation.push(validationArr)
    } else {
      console.error('配列に要素を追加できませんでした')
    }

    return validation
  }
}
