import { computed, Ref } from '@nuxtjs/composition-api'
import { EstimateDetail, Items } from '~/api/estimateRepository'
import { ItemPlan } from '~/constants/enums/itemPlan'
import ProductId from '~/constants/enums/productClassification'
import { PLAN } from '~/constants/estimate/comingOfAge'
import { useRestatePlan, PlanFlags } from '../RestatePlan'
import { EstimateSinglePriceType } from '~/constants/enums/estimateSinglePriceType'

export const useRestateショール = (
  estimate: Ref<EstimateDetail | undefined>
) => {
  const restateプラン = useRestatePlan(estimate)

  return computed(() => {
    if (!estimate.value?.items || !restateプラン.value) {
      return null
    }

    const shawlItem = estimate.value.items.find(
      (item) => item.product_type_id === ProductId.shawl
    )

    if (!shawlItem) {
      return null
    }

    const {
      rental_price,
      purchase_price,
      plan,
      price_type,
      premium_rental_price,
      premium_purchase_price,
      product_name,
      product_id,
    } = shawlItem
    const { flags: planFlags } = restateプラン.value
    const flags = getConditionalFlags(shawlItem, restateプラン.value.flags)

    return {
      optionId種別: getOptionIdOf種別(plan, planFlags),
      optionId形態: getOptionIdOf形態(plan),
      optionId商品選択: flags.is現品 ? PLAN.ACTUAL_PRODUCT : product_id ?? 0,
      optionId見積選択: getOptionIdOf見積表示(price_type),
      商品名: product_name ?? '',
      レンタル価格: Number(rental_price),
      プレミアムレンタル価格: Number(premium_rental_price ?? 0),
      購入価格: Number(purchase_price ?? 0),
      プレミアム購入価格: Number(premium_purchase_price ?? 0),
      flags,
    }
  })
}

/**
 * 復元条件に関わる条件式を定義する
 */
const getConditionalFlags = (item: Items, planFlags: PlanFlags) => {
  const { isフルセットプラン } = planFlags
  const is持込 = item.plan === ItemPlan.bringIn

  const isプラン内 = isフルセットプラン && !is持込
  const isセット内 = isプラン内 && item.plan === ItemPlan.inSet
  const isランクアップ = isプラン内 && item.plan === ItemPlan.rankUpInPremium
  // NOTE: ショールは現品以外の選択肢の場合に商品番号を持つことになるため、持込ではなく商品番号を持たない場合は現品と判断できる
  // NOTE: product_nameの現品だけでも判定は取れるものの、将来的な仕様変更も考慮して複数条件を元に判定を取るようにしている
  const is現品 = item.product_name === '現品' || (!is持込 && !item.product_id)
  const is現品PP外 = is現品 && item.plan === ItemPlan.rankUpOutPremium
  return {
    is持込,
    isセット内,
    isランクアップ,
    is現品,
    is現品PP内: is現品 && !is現品PP外,
    is現品PP外,
  }
}

/**
 * 選択肢系の項目の初期値(選択されたoptionのid)を再計算する関数群
 */
const getOptionIdOf種別 = (itemPlan: Items['plan'], planFlags: PlanFlags) => {
  // お持込は全パターン共通ID、その他は振袖のプランによって変動する
  if (itemPlan === ItemPlan.bringIn) {
    return PLAN.BRING_IN
  } else if (planFlags.is振袖持込) {
    return PLAN.SINGLE_RENTAL
  } else if (planFlags.isフルセットプラン) {
    return PLAN.SET_OR_RENTAL
  } else {
    return 0
  }
}

const getOptionIdOf形態 = (itemPlan: Items['plan']) => {
  switch (itemPlan) {
    case ItemPlan.inSet:
      return PLAN.IN_THE_SET
    case ItemPlan.rankUpInPremium:
      return PLAN.RANK_UP_IN_PREMIUM
    case ItemPlan.rankUpOutPremium:
      return PLAN.RANK_UP_OUT_PREMIUM
    default:
      return 0
  }
}

const getOptionIdOf見積表示 = (priceType: Items['price_type']) => {
  switch (priceType) {
    case EstimateSinglePriceType.singleRentalPrice:
      return PLAN.SINGLE_RENTAL_PRICE
    case EstimateSinglePriceType.singlePurchasePrice:
      return PLAN.SINGLE_BUY_PRICE
    default:
      return 0
  }
}
