import { computed, onMounted, useStore } from '@nuxtjs/composition-api'

// ** 型 **
import type { Product, SetOfProduct } from '~/api/productRepository'
import type { ProductNameList } from '~/store/Estimate/ComingOfAgeCeremony/MasterData/state'
import type { Product as ProductOption } from '~/constants/productData'

// ** enums **
import ProductClassification from '~/constants/enums/productClassification'
import { ItemPriceType } from '~/constants/enums/itemPlan'

import { useProductData } from '~/composable/api/useProduct'

// 小物の商品一覧取得
export const useOptions小物商品選択 = (
  productType:
    | typeof ProductClassification.zori
    | typeof ProductClassification.bag
    | typeof ProductClassification.shawl
    | typeof ProductClassification.obiage
) => {
  const { itemProducts, fetchItemProducts } = useProductData()

  // 草履、バッグ、ショールしか来ないためasで固定
  const productName = ProductClassification.toWord(
    productType
  ) as ProductNameList

  const store = useStore()
  const storedItemProducts: Product[] =
    store.getters[`Estimate/ComingOfAgeCeremony/MasterData/getProducts`](
      productName
    )

  onMounted(async () => {
    if (storedItemProducts.length) {
      itemProducts.value = storedItemProducts
    } else {
      await fetchItemProducts(productType)

      store.commit(`Estimate/ComingOfAgeCeremony/MasterData/setProducts`, {
        products: itemProducts.value,
        productName,
      })
    }
  })

  const itemOptions_ランクアップ = computed(() => {
    const itemOptions: ProductOption[] = []
    // NOTE: apiが１つの配列で返ってくるため
    itemProducts.value[0]?.items.forEach((item) => {
      // NOTE: nameの返り値が`U-1 バッグ(ご購入用)`のように返ってくるため
      const splittedItemName = item.name.split(/\s+/, 2)
      const post_number_code = splittedItemName[0]
      const product_name = splittedItemName[1]

      const findPrice = findPriceByPriceType(item.prices)
      const price = (() => {
        const rentalPrice = findPrice(ItemPriceType.rankUpInRental)
        if (rentalPrice === null) return

        const buyPrice = findPrice(ItemPriceType.rankUpPurchase)
        if (buyPrice === null) return

        const singlePurchasePrice = findPrice(ItemPriceType.buyTanpin)
        if (singlePurchasePrice === null) return

        return {
          rental: rentalPrice,
          buy: buyPrice,
          single_purchase: singlePurchasePrice,
        }
      })()

      if (!price) return

      itemOptions.push({
        id: item.product_id,
        post_number_code,
        product_name,
        price,
      })
    })

    return itemOptions
  })

  const itemOptions_単品 = computed(() => {
    const itemOptions: ProductOption[] = []
    // NOTE: apiが１つの配列で返ってくるため
    itemProducts.value[0]?.items.forEach((item) => {
      // NOTE: nameの返り値が`U-1 バッグ(ご購入用)`のように返ってくるため
      const splittedItemName = item.name.split(/\s+/, 2)
      const post_number_code = splittedItemName[0]
      const product_name = splittedItemName[1]

      const findPrice = findPriceByPriceType(item.prices)
      const price = (() => {
        const rentalPrice = findPrice(ItemPriceType.rentalTanpin)
        if (rentalPrice === null) return

        const buyPrice = findPrice(ItemPriceType.buyTanpin)
        if (buyPrice === null) return

        return {
          rental: rentalPrice,
          buy: buyPrice,
        }
      })()

      if (!price) return

      itemOptions.push({
        id: item.product_id,
        post_number_code,
        product_name,
        price,
      })
    })

    return itemOptions
  })

  const findPriceByPriceType =
    (prices: Product['items'][number]['prices']) => (priceType: number) => {
      const priceData = prices.find((price) => price.price_type === priceType)
      const price = Number(priceData?.price)
      return isNaN(price) ? null : price
    }

  return {
    itemProducts,
    itemOptions_ランクアップ,
    itemOptions_単品,
  }
}

// セット商品一覧取得
export const useOptionsセット商品選択 = (
  productType:
    | typeof ProductClassification.zori
    | typeof ProductClassification.bag
) => {
  const { setOfProducts, fetchSetOfProducts } = useProductData()

  const store = useStore()
  const storedSetOfProducts: SetOfProduct[] =
    store.getters[`Estimate/ComingOfAgeCeremony/MasterData/getSetOfProducts`]

  onMounted(async () => {
    if (storedSetOfProducts.length) {
      setOfProducts.value = storedSetOfProducts
    } else {
      await fetchSetOfProducts()

      store.commit(
        `Estimate/ComingOfAgeCeremony/MasterData/setSetOfProducts`,
        setOfProducts.value
      )
    }
  })

  const itemOptions = computed<ProductOption[]>(() => {
    const itemOptions: ProductOption[] = []
    setOfProducts.value.forEach((setOfProduct) => {
      const item = setOfProduct.set_items.find((setItem) => {
        return setItem.product_type_id === productType
      })
      if (!item) return

      // NOTE: nameの返り値が`U-1 バッグ(ご購入用)`のように返ってくるため
      const splittedItemName = item.name.split(/\s+/, 2)
      const post_number_code = splittedItemName[0]
      const product_name = splittedItemName[1]

      const set_purchase = (() => {
        const priceData = setOfProduct.prices.find(
          (price) => price.price_type === ItemPriceType.buyTanpin
        )
        const price = Number(priceData?.price)
        return isNaN(price) ? null : price
      })()
      if (set_purchase === null) return

      itemOptions.push({
        id: item.product_id,
        post_number_code,
        product_name,
        price: {
          rental: 0,
          buy: 0,
          set_purchase,
        },
        isItemInSet: true,
        setId: setOfProduct.set_id
      })
    })

    return itemOptions
  })

  return {
    setOfProducts,
    itemOptions,
  }
}
