/* eslint-disable camelcase */
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { AxiosResponse, AxiosInstance } from 'axios'

export type BuautySalon = {
  id: number
  name: string
  phone_number: string | null
  zip_code: string | null
  prefecture: string | null
  address1: string | null
  address2: string | null
  shop_id: number
}

export type MasterRepository = {
  getAll: (params: {
    shop_id: number | null
  }) => Promise<AxiosResponse<{ beauty_salons: BuautySalon[] }>>
  findById: (id: number) => Promise<AxiosResponse<BuautySalon>>
}

const beautySalonRepository = (
  client: NuxtAxiosInstance | AxiosInstance
): MasterRepository => {
  return {
    getAll: (params) => client.get('beauty_salons', { params }),
    findById: (id) => client.get(`beauty_salons/${id}`),
  }
}

export default beautySalonRepository
