import { FormValues足し布 } from '~/composable/estimate/viewModel/comingOfAge/Fields/Fields足し布'

/*
 * NOTE: 卒業式の作成方法を還元しています
 * isValidateだけはfieldsとは別のロジックなのでfield外で定義
 */
export const getInitialStore = (): FormValues足し布 => {
  return {
    tashinuno適用: null,
    tashinuno採寸数値: {
      bust: null,
      hip: null,
      bodyType: null, // N/B/BB のサイズ名
    },
    tashinuno追加金額: null,
  }
}

export type State = {
  fields: FormValues足し布
  isValidate: boolean | null
}

const state = (): State => ({
  fields: getInitialStore(),
  isValidate: null,
})

export default state
