import { MutationTree } from 'vuex'
import { DeliveryDateState } from '../../types'

const mutations: MutationTree<DeliveryDateState> = {
  setSelectedDeliveryType: (state, selectedDeliveryType) => {
    state.selectedDeliveryType = selectedDeliveryType
  },

  setInput: (state, input) => {
    state.input = input
  },

  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },

  delete: (state) => {
    state.selectedDeliveryType = 0
    state.input = {
      date: '',
      type: 'custom',
    }
    state.isValidate = null
  },
}

export default mutations
