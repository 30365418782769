import { MutationTree } from 'vuex'
import { ProductState, Product } from '../../../types'

const mutations: MutationTree<ProductState> = {
  setAllState: (state, values: ProductState) => {
    state.products = values.products
    state.isValidate = values.isValidate
  },

  add: (state, product: Product) => {
    state.products.push(product)
  },

  update: ({ products }, { id, count }) => {
    const product = products.find((item) => item.id === id)
    if (product) product.count = count
  },

  remove: (state, id: number) => {
    state.products = state.products.filter((p: Product) => p.id !== id)
  },

  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },
  delete: (state) => {
    state.products = [
      {
        id: 1,
        name: '帯揚げ',
        count: 1,
      },
      {
        id: 2,
        name: '重ね衿',
        count: 1,
      },
      {
        id: 3,
        name: '髪飾り',
        count: 1,
      },
      {
        id: 4,
        name: '草履',
        count: 1,
      },
      {
        id: 5,
        name: 'バッグ',
        count: 1,
      },
    ]
    state.isValidate = null
  },
}

export default mutations
