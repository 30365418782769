import { GetterTree } from 'vuex'
import { RootState, PaymentState } from '../../types'

const getters: GetterTree<PaymentState, RootState> = {
  getState: (state: PaymentState): PaymentState => {
    return state
  },

  getIsValidate: (state: PaymentState) => {
    return state.isValidate
  },
}

export default getters
