import { MutationTree } from 'vuex'
import { CopiedEstimateState, getInitialFields } from './state'
import { EstimateDetail } from '~/api/estimateRepository'

const mutations: MutationTree<CopiedEstimateState> = {
  // NOTE: useComingStore用に追加
  setAllState: (state, values: EstimateDetail | null) => {
    state.estimate = values
  },

  initAllState: (state) => {
    state.estimate = getInitialFields()
  },
}

export default mutations
