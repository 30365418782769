import {
  Ref,
  computed,
  onBeforeMount,
  onMounted,
  ref,
  useRoute,
  watch,
} from '@nuxtjs/composition-api'
import { FormValues商品設定 } from '../../viewModel/graduation/Fields/Fields商品設定'
import { usePlanFlags } from '../../domain/Graduation/PlanFlags'
import {
  getDefaultTabs,
  getTabsDefault別衿,
  getTabsDefault半巾帯,
  getTabsDefault巾着バッグ,
  getTabsDefault草履ブーツ,
  getTabsDefault重ね衿,
  getTabs着物袴,
  getTabs重ね衿,
  getTabs髪飾り,
} from './TabNavigationList'
import { useTabValidation } from './TabValidation'
import { TabNaviItem } from '~/composable/estimate/service/graduation/TabNavigationItem'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { TabNaviState } from '~/store/Estimate/GraduationCeremony/types'
import { useState振袖二尺袖 } from '~/composable/estimate/repository/graduation/states/State振袖二尺袖'
import { useState袴 } from '~/composable/estimate/repository/graduation/states/State袴'
import { FormValues半巾帯_袴持込 } from '../../viewModel/graduation/Fields/Fields半巾帯_袴持込'
import { FormValues半巾帯_袴レンタル } from '../../viewModel/graduation/Fields/Fields半巾帯_袴レンタル'
import { urls } from '~/constants/urls'
import { Enum半巾帯_袴持込 } from '~/constants/estimate/graduationOptions'

/*
 *タブの動的な増減を再現するロジック
 */
export const useDynamicTabNaviList = () => {
  /*
   * constructor
   */
  const tabNavi = useGraduateStore<TabNaviState>('tabNavi')
  const storedTabs = tabNavi.get<'tabs'>('tabs')
  // NOTE: 一時保存から復帰した場合は既存のタブがあるため、それを初期値にセットする
  const tabs = ref(
    storedTabs.value.length ? storedTabs.value : getDefaultTabs()
  )
  const {
    isFurisode,
    isHakama,
    isKimonoHakama,
    isNishakusode,
    hasHakamaRental,
    hasKimonoRental,
  } = usePlanFlags()
  const state振袖二尺袖 = useState振袖二尺袖()
  const state袴 = useState袴()
  const is振袖 = computed(() => state振袖二尺袖.value.flags.is振袖)
  const is二尺袖 = computed(() => state振袖二尺袖.value.flags.is二尺袖)
  const is袴レンタル = computed(() => state袴.value.flags.isプランレンタル)
  const route = useRoute()
  const defaultTabs = getDefaultTabs()
  const store商品設定 = useGraduateStore<FormValues商品設定>('tab商品設定')
  const 髪飾り個数 =
    store商品設定.get<'shohinSettei髪飾り'>('shohinSettei髪飾り')
  const 重ね衿個数 =
    store商品設定.get<'shohinSettei重ね衿'>('shohinSettei重ね衿')
  const tabs着物袴 = getTabs着物袴()
  const tabs重ね衿 = getTabs重ね衿()
  const tabs髪飾り = getTabs髪飾り()
  const defaultTab重ね衿 = getTabsDefault重ね衿()
  const defaultTab半巾帯 = getTabsDefault半巾帯()
  const defaultTab別衿 = getTabsDefault別衿()
  const defaultTab草履ブーツ = getTabsDefault草履ブーツ()
  const defaultTab巾着バッグ = getTabsDefault巾着バッグ()
  const validatedTabs = tabNavi.get<'validatedTabs'>('validatedTabs')
  const { setAllTabsValid, setTabValid } = useTabValidation()
  /** 半巾帯選択肢方式実装前の判定に使用 */
  const store半巾帯_袴持込 =
    useGraduateStore<FormValues半巾帯_袴持込>('tab半巾帯_袴持込')
  const store半巾帯_袴レンタル =
    useGraduateStore<FormValues半巾帯_袴レンタル>('tab半巾帯_袴レンタル')
  const values半巾帯_袴持込 = store半巾帯_袴持込.getAll()
  const values半巾帯_袴レンタル = store半巾帯_袴レンタル.getAll()
  const isHanhabaObiTabExist = (tabs: Ref<TabNaviItem[]>): boolean => {
    return tabs.value.some((tab) => tab.label === '半巾帯')
  }
  /*
   * lifeCycle hook & Watchers
   */
  //* NOTE: 下記の2パターンの場合、初回レンダリング時のみタブの個数が復元終了後に全てをvalidにする
  // 1. 契約フローにおいて、既存の見積を復元した時(mode=edit)
  // 2. 一度作った見積の完了画面で、「別の見積もりを作成」を選んだ時(mode=copy)
  onMounted(() => {
    updateKimonoHakamaTabs()
    const from見積詳細 = 'detail' === route.value.query.from
    const from契約フロー = 'estimateListContract' === route.value.query.from
    // NOTE: 見積詳細での復元時タブの復元が終わった後に全てのタブを合格にする
    // 見積コピー時はwatch([is振袖, is二尺袖, is袴レンタル]の方で行っている
    if (['edit', 'copy'].includes(route.value.query.mode as string)) {
      setAllTabsValid(tabs)
      /**
       * NOTE: 半巾帯選択肢方式実装前の判定に使用
       * 実装前現品orカタログの場合：Restate時に形態をnullとしているに変換しているため、
       * 商品選択があり形態がnullの場合、判定をinvalidとする
       */
      if (isHanhabaObiTabExist(tabs)) {
        // 袴持込
        if (
          // 現品
          (!!values半巾帯_袴持込.value.hanhabaobi商品選択 &&
            !values半巾帯_袴持込.value.hanhabaobi形態) ||
          // カタログ
          (!values半巾帯_袴持込.value.hanhabaobi商品選択 &&
            values半巾帯_袴持込.value.hanhabaobi種別 &&
            values半巾帯_袴持込.value.hanhabaobi種別 !==
              Enum半巾帯_袴持込.種別.お持込)
        ) {
          setTabValid(urls.見積書.卒業式.tab半巾帯_袴持込(0), false)
        }
        // 袴レンタル
        if (
          // 現品
          (!!values半巾帯_袴レンタル.value.hanhabaobi商品選択 &&
            !values半巾帯_袴レンタル.value.hanhabaobi形態) ||
          // カタログ
          (!values半巾帯_袴レンタル.value.hanhabaobi商品選択 &&
            values半巾帯_袴レンタル.value.hanhabaobi種別 &&
            values半巾帯_袴レンタル.value.hanhabaobi種別 !==
              Enum半巾帯_袴持込.種別.お持込)
        ) {
          setTabValid(urls.見積書.卒業式.tab半巾帯_袴レンタル(0), false)
        }
      }
    }
    if (!(from見積詳細 || from契約フロー)) return
    // NOTE:お客様情報の見積詳細から復元する時はすでにストア復元後に見積フローに遷移するので
    // onMountedでタブの復元を対応する
    if (storedTabs.value.length > defaultTabs.length) {
      insertMassTab()

      for (let i = 1; i <= 重ね衿個数.value; i++) {
        change重ね衿tabs(i, 1)
      }

      for (let i = 0; i <= 髪飾り個数.value; i++) {
        change髪飾りtabs(i, 0)
      }
    }
  })

  //* タブの変動時storeに保存する
  watch(
    tabs,
    () => {
      // 全てのバリデーションが通っているかをstoreに保存する
      const invalidTabs = tabs.value.filter((tab) => {
        return tab.isValid !== 'valid' || !('isValid' in tab)
      })
      tabNavi.set('isAllValidateTabs', invalidTabs.length === 0)
      if (route.value.path.includes('confirmation')) return
      tabNavi.set('tabs', tabs.value)
    },
    { deep: true, immediate: true }
  )

  /*
   *プランを変更した時に、動的にタブが増減するロジック
   */
  watch(
    [isFurisode, isHakama, isKimonoHakama, isNishakusode],
    (newVal, oldVal) => {
      if (newVal === oldVal) return
      if (['edit', 'copy'].includes(route.value.query.mode as string)) return
      //* プラン変更時に商品設定のタブも変動するため、初期値的に値を挿入しています。
      resetTab()
      store商品設定.set<'shohinSettei重ね衿'>('shohinSettei重ね衿', 1)
      store商品設定.set<'shohinSettei髪飾り'>('shohinSettei髪飾り', 0)
      insertMassTab()
      updateKimonoHakamaTabs()
      updateValidation()
    }
  )
  /*
   * プランタブで振袖/二尺袖/袴に関わる選択肢を変更した時に、動的にタブが増減するロジック
   */
  watch([is振袖, is二尺袖, is袴レンタル], (newVal, oldVal) => {
    if (JSON.stringify(newVal) === JSON.stringify(oldVal)) return
    updateKimonoHakamaTabs()
    insertMassTab()
    // NOTE: 復元時、プランタブで選ばれた内容でタブの復元が終わった後に全てのタブを合格にする
    if (['edit', 'copy'].includes(route.value.query.mode as string)) {
      // FIXME: EstimateRestorerが発火し終わってから復元しないとうまくいかないが、
      // そのタイミングを取得する方法が思いつかないためこうしている
      setTimeout(() => setAllTabsValid(tabs), 2500)
    }
  })

  /*
   * 商品選択で個数を変更した時に、動的にタブが増減するロジック
   */
  watch(重ね衿個数, (newVal, oldVal) => {
    change重ね衿tabs(newVal, oldVal)
  })

  watch(髪飾り個数, (newVal, oldVal) => {
    change髪飾りtabs(newVal, oldVal)
  })

  /*
   * Utility Methods - タブ操作の共有メソッド
   */
  /**
   * @note 挿入するタブを指定したURLの前後に追加する関数
   * @param {TabNaviItem} targetTab
   * @param {string} targetUrl
   * @return {void}
   */
  const insertTabAfter = (targetTab: TabNaviItem, targetUrl: string) => {
    insertTab(targetTab, targetUrl, 1)
  }
  const insertTabBefore = (targetTab: TabNaviItem, targetUrl: string) => {
    insertTab(targetTab, targetUrl, 0)
  }
  const insertTab = (
    targetTab: TabNaviItem,
    targetUrl: string,
    direction: 0 | 1 // 0:targetUrlの前に追加 1:後ろに追加
  ) => {
    const tabNumber = tabs.value.findIndex((e) => {
      return e.url.includes(targetUrl)
    })
    if (tabNumber === -1) return
    const alreadyExist = tabs.value.find((tab) => tab.url === targetTab.url)
    if (alreadyExist) return
    tabs.value.splice(tabNumber + direction, 0, targetTab)
  }

  /**
   * @note 指定したタブを削除する関数
   * @param {string} targetUrl
   * @return {void}
   */
  const removeTab = (targetUrl: string) => {
    const trimTargetUrl = targetUrl.slice(targetUrl.indexOf('tab/'))
    const tabNumber = tabs.value.findIndex((tab) => {
      const trimUrl = tab.url.slice(tab.url.indexOf('tab/'))
      return trimUrl.includes(trimTargetUrl)
    })

    if (tabNumber === -1) {
      return
    }
    tabs.value.splice(tabNumber, 1)
  }

  /**
   * @note 初期のタブに上書きする関数
   * @return {void}
   */
  const resetTab = () => {
    const copyDefaultTabs = defaultTabs.map((list) => ({ ...list }))
    tabs.value = copyDefaultTabs
  }

  /**
   * プラン選択時にプランによってタブが変動する要素を一度にinsertする関数
   */
  const insertMassTab = () => {
    // NOTE: 復元時に2回以上resetTabが走るのを防止する
    const isDefaultTabs = tabs.value.every((tab) => {
      return !tab.url.includes('betsueri')
    })
    if (!isDefaultTabs) return

    // NOTE: 半巾帯のみ「袴がある時・ない時」でプラン内かどうかが決定する
    // 半巾帯のみ卒業式固有小物=袴とのセットで使うもの？
    const 半巾帯Tab = hasHakamaRental.value
      ? defaultTab半巾帯.袴レンタル
      : defaultTab半巾帯.袴持込
    const 重ね衿Tab = hasKimonoRental.value
      ? defaultTab重ね衿.着物レンタル
      : defaultTab重ね衿.着物持込
    const 別衿Tab = hasKimonoRental.value
      ? defaultTab別衿.着物レンタル
      : defaultTab別衿.着物持込
    const 草履ブーツTab = hasKimonoRental.value
      ? defaultTab草履ブーツ.着物レンタル
      : defaultTab草履ブーツ.着物持込
    const 巾着バッグTab = hasKimonoRental.value
      ? defaultTab巾着バッグ.着物レンタル
      : defaultTab巾着バッグ.着物持込
    insertTabBefore(半巾帯Tab, 'shouhinSettei')
    insertTabAfter(重ね衿Tab, 'shouhinSettei')
    insertTabAfter(別衿Tab, 'kasaneeri')
    insertTabAfter(草履ブーツTab, 'betsueri')
    insertTabAfter(巾着バッグTab, 'zoriBoots')
  }

  /*
   *タブのバリデーションを監視するロジック
   */
  const updateValidation = () => {
    tabs.value.forEach((tab) => {
      validatedTabs.value.forEach((tabVali) => {
        const trimUrl = tab.url.slice(tab.url.indexOf('tab/'))
        if (tabVali.url?.includes(trimUrl)) {
          tab.isValid = tabVali.isValid
        }
      })
    })
  }
  watch(validatedTabs, () => updateValidation())
  onBeforeMount(() => updateValidation())

  /*
   * Specified Methods - 特定のタブを操作するメソッド
   */
  /*
   *重ね衿の個数を増やしたときに、タブが増減するロジック
   */
  const change重ね衿tabs = (newVal: number, oldVal: number) => {
    if (newVal === oldVal) return
    const newNumber = newVal - 1
    const oldNumber = oldVal - 1

    if (hasKimonoRental.value) {
      const targetUrl = `kasaneeri/rental-kimono/${newNumber}`
      if (newNumber > oldNumber) {
        // NOTE: 既存見積の復元の場合のみ1→3個の変更があるためそのパターンに対応する
        if (newVal === 3 && oldVal === 1) {
          insertTabAfter(
            tabs重ね衿.着物レンタル[1],
            'kasaneeri/rental-kimono/1'
          )
          insertTabAfter(
            tabs重ね衿.着物レンタル[2],
            'kasaneeri/rental-kimono/2'
          )
          return
        }

        insertTabAfter(tabs重ね衿.着物レンタル[newNumber], targetUrl)
      } else {
        removeTab(tabs重ね衿.着物レンタル[newNumber + 1].url)
      }
    } else {
      const targetUrl = `kasaneeri/bringin-kimono/${newNumber}`
      if (newNumber > oldNumber) {
        insertTabAfter(tabs重ね衿.着物持込[newNumber], targetUrl)
      } else {
        removeTab(tabs重ね衿.着物持込[oldNumber].url)
      }
    }
  }

  /*
   *髪飾りの個数を増やしたときに、タブが増減するロジック
   */
  const change髪飾りtabs = (newVal: number, oldVal: number) => {
    if (newVal === oldVal) return
    const newNumber = newVal - 1
    const oldNumber = oldVal - 1
    // 髪飾りのみ0個になる可能性があるので、プランに応じて後ろに追加するURLを変動させる
    const PreviousUrl = hasKimonoRental.value
      ? 'betsueri/rental-kimono'
      : 'betsueri/bringin-kimono'
    const targetUrl = newNumber === 0 ? PreviousUrl : `kamikazari/${newNumber}`

    if (newNumber > oldNumber) {
      // NOTE: 既存見積の復元の場合のみ0→1個以上の変更があるためそのパターンに対応する
      if (newVal > 1 && oldVal === 0) {
        tabs髪飾り.forEach((tab, index) => {
          const url = index === 0 ? PreviousUrl : `kamikazari/${index}`
          if (index < newVal) insertTabAfter(tab, url)
        })
        return
      }

      insertTabAfter(tabs髪飾り[newNumber], targetUrl)
    } else {
      removeTab(tabs髪飾り[newNumber + 1].url)
    }
  }
  /*
   * プランタブで振袖/二尺袖/袴に関わる選択肢を変更した時に、動的にタブが増減するロジック
   */
  const updateKimonoHakamaTabs = () => {
    const show振袖 = is振袖.value && !state振袖二尺袖.value.flags.isお持込
    const show二尺袖 = is二尺袖.value && !state振袖二尺袖.value.flags.isお持込
    const tabBefore袴 = show振袖
      ? 'furisode'
      : show二尺袖
      ? 'nishakusode'
      : 'plan'
    show振袖
      ? insertTabAfter(tabs着物袴.振袖, 'plan')
      : removeTab(tabs着物袴.振袖.url)
    show二尺袖
      ? insertTabAfter(tabs着物袴.二尺袖, 'plan')
      : removeTab(tabs着物袴.二尺袖.url)
    is袴レンタル.value
      ? insertTabAfter(tabs着物袴.袴, tabBefore袴)
      : removeTab(tabs着物袴.袴.url)
  }

  return tabs
}
