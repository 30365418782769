import { computed, useStore } from '@nuxtjs/composition-api'
import { ContractState } from '~/store/types'
import { EstimatePlan } from '~/constants/enums/estimatePlan'
import { ContractType } from '~/constants/enums/contractType'
import ProductClassification from '~/constants/enums/productClassification'
import { ItemPlan } from '~/constants/enums/itemPlan'

export interface ContractsTabListInterface {
  text: string
  url: string
  isValidate: boolean | null
  isHidden?: boolean
}

/* NOTE: 成人式の着付け小物表示ロジック */
const isHiddenProductPhoto = (
  estimateData: ContractState['Contracts']['BaseEstimate']['estimate'],
  isWPhoto: boolean
) => {
  /*
   **  ①まず帯以外の小物が全て持ち込みかどうかを最優先の判定とする
   */

  // itemsから帯を除く
  const removedObiItems = estimateData.items?.filter(
    (item) => item.product_type_id !== 2
  )
  const removedObiWPhotoItems = estimateData.w_photo_plan?.items?.filter(
    (item) => item.product_type_id !== 2
  )

  // WPhotoプランの小物が全て持ち込みかどうか
  const isWPhotoAllBring = !!removedObiWPhotoItems?.every(
    (item) => item.plan === 6
  )

  // 小物が全て持ち込みかどうか
  const isAllBring = !!removedObiItems?.every((item) => item.plan === 6)

  if (isWPhoto && isAllBring && isWPhotoAllBring) {
    return true
  }

  if (isAllBring) {
    return true
  }
}

// 表示条件: https://www.notion.so/arsagajp/c0c8a081031a44f289e384bd5c4d5772
const isHiddenAccessory = (
  estimateData: ContractState['Contracts']['BaseEstimate']['estimate']
) => {
  /*
   ** JK前撮り適用
   */
  const isApplyJkPhotoInAdvance = computed(
    () => !!estimateData.is_jk_photos_in_advance
  )

  /*
   ** wフォト適用
   */
  const isApplyWphoto = computed(
    () => !!estimateData.w_photo_plan.hurisode?.product_id
  )

  if (isApplyJkPhotoInAdvance.value || isApplyWphoto.value) {
    return false
  }

  const 草履バッグ髪飾りのいずれかがレンタルor購入 = computed(() =>
    estimateData.items?.some(
      ({ product_type_id, plan }) =>
        [
          ProductClassification.kamikazari,
          ProductClassification.zori,
          ProductClassification.bag,
        ].includes(product_type_id) && plan !== ItemPlan.bringIn
    )
  )

  /*
   ** 成人式Bコースと写真のみプラン（振袖持ち込み）の場合
   */
  if (
    [
      EstimatePlan.planB,
      EstimatePlan.planBAndPhoto,
      EstimatePlan.bringInOnlyPhoto,
    ].includes(estimateData.plan)
  ) {
    if (草履バッグ髪飾りのいずれかがレンタルor購入.value) {
      return false
    }
    return true
  }
  return false
}

/* NOTE: 卒業式の着付け小物表示ロジック */
const isHiddenAccessoryForGraduation = (
  estimateData: ContractState['Contracts']['BaseEstimate']['estimate']
) => {
  // 着物＋袴レンタルと二尺袖単品レンタルの場合
  if (
    [
      EstimatePlan.kimonoHakamaRental,
      EstimatePlan.twoShakuSleeveSingleRental,
    ].includes(estimateData.plan)
  ) {
    return false
  }
  // 袴単品レンタル且つ、草履、バッグ or 巾着、髪飾りのいずれかがレンタルor購入
  if ([EstimatePlan.hakamaSingleRental].includes(estimateData.plan)) {
    // 草履、バッグ、髪飾りのいずれかがレンタルor購入
    const 草履バッグ髪飾りのいずれかがレンタルor購入 = estimateData.items?.some(
      ({ product_type_id, plan }) =>
        [
          ProductClassification.kamikazari,
          ProductClassification.zori,
          ProductClassification.bag,
        ].includes(product_type_id) && plan !== ItemPlan.bringIn
    )

    if (草履バッグ髪飾りのいずれかがレンタルor購入) return false
    return true
  }
}

export const useContractsTabList = () => {
  const store = useStore<ContractState>()
  const contracts = store.state.Contracts
  const estimateData = contracts.BaseEstimate.estimate

  // FIXME: neck_photo_urlのエラーになるので、falseを返している
  // neck_photo_urlがあればWPhoto
  const isWPhoto = Boolean(
    estimateData?.w_photo_plan?.neck_photo_url ||
      estimateData?.w_photo_plan?.selected_plan ||
      estimateData?.w_photo_plan?.selected_plan_type ||
      estimateData?.w_photo_plan?.hurisode?.catalog_code ||
      estimateData?.w_photo_plan?.hurisode?.product_id
  )

  const getContractsTabList = (): ContractsTabListInterface[] => {
    if (estimateData.type === ContractType.graduationCeremony) {
      // 卒業式
      return [
        {
          text: '試着写真',
          url: '/contracts/tryOnPhoto',
          isValidate: store.getters['Contracts/Photos/getIsValidate'],
        },
        {
          text: '小物写真',
          url: '/contracts/productPhoto',
          isValidate: store.getters['Contracts/ProductPhoto/getIsValidate'],
          isHidden:
            estimateData.items?.every((item) => item.plan === 6) ?? false,
        },
        {
          text: '基本情報',
          url: '/contracts/baseInformation',
          isValidate: store.getters['Contracts/BaseInformation/getIsValidate'],
        },
        {
          text: '採寸',
          url: '/contracts/measuring',
          isValidate: store.getters['Contracts/Measuring/getIsValidate'],
        },
        {
          text: '卒業式',
          url: '/contracts/graduationCeremony',
          isValidate:
            store.getters['Contracts/GraduationCeremony/getIsValidate'],
        },
        {
          text: '着付け小物',
          url: '/contracts/accessories',
          isValidate: store.getters['Contracts/Accessories/getIsValidate'],
          // NOTE:
          // https://xd.adobe.com/view/8574bb5e-0de7-412f-b974-a9a8eeee58f9-d027/screen/79cbfb6c-75b8-4fd0-b637-29d72c7528f0/specs/
          //
          // DOCS:
          // https://www.notion.so/arsagajp/4c73b6e61b5440c2bdd96ab87e034f2f?v=7888163d5f1f415fb588bcc2af4a1252
          isHidden: isHiddenAccessoryForGraduation(estimateData),
        },
        {
          text: 'お客様ご準備',
          url: '/contracts/customerReady',
          isValidate: store.getters['Contracts/CustomerReady/getIsValidate'],
        },
        {
          text: 'ご家族情報',
          url: '/contracts/familyInformation',
          isValidate:
            store.getters['Contracts/FamilyInformation/getIsValidate'],
        },
        {
          text: '紹介者特典',
          url: '/contracts/referralBonus',
          isValidate: store.getters['Contracts/ReferralBonus/getIsValidate'],
        },
        {
          text: 'オプション',
          url: '/contracts/option',
          isValidate: store.getters['Contracts/Option/getIsValidate'],
        },

        {
          text: 'お支払',
          url: '/contracts/payment',
          isValidate: store.getters['Contracts/Payment/getIsValidate'],
        },
        {
          text: '重要事項',
          url: '/contracts/importantMatter',
          isValidate: store.getters['Contracts/ImportantMatter/getIsValidate'],
        },
        {
          text: '備考',
          url: '/contracts/remark',
          isValidate: store.getters['Contracts/Remarks/getIsValidate'],
        },
      ]
    }

    if (estimateData.type === ContractType.comingOfAgeCeremony) {
      // 成人式
      return [
        {
          text: '試着写真',
          url: '/contracts/tryOnPhoto',
          isValidate: store.getters['Contracts/Photos/getIsValidate'],
        },
        {
          text: '小物写真',
          url: '/contracts/productPhoto',
          isValidate: store.getters['Contracts/ProductPhoto/getIsValidate'],
          isHidden: isHiddenProductPhoto(estimateData, isWPhoto),
        },
        {
          text: '基本情報',
          url: '/contracts/baseInformation',
          isValidate: store.getters['Contracts/BaseInformation/getIsValidate'],
        },
        {
          text: '採寸',
          url: '/contracts/measuring',
          isValidate: store.getters['Contracts/Measuring/getIsValidate'],
        },
        {
          text: 'JK前撮り',
          url: '/contracts/jkPhotoInAdvance',
          isValidate: store.getters['Contracts/JkPhotoInAdvance/getIsValidate'],
          isHidden: !(
            (
              estimateData.plan === EstimatePlan.fullSetPurchase || // フルセット（購入）
              estimateData.plan === EstimatePlan.fullSet
            ) // フルセット（レンタル）
          ),
        },
        {
          text: '前撮り',
          url: '/contracts/photoInAdvance',
          isValidate: store.getters['Contracts/PhotoInAdvance/getIsValidate'],
          isHidden: estimateData.plan === EstimatePlan.planB,
        },
        {
          text: 'Wフォト',
          url: '/contracts/doublePhoto',
          isValidate: store.getters['Contracts/DoublePhoto/getIsValidate'],
          isHidden: estimateData.plan === EstimatePlan.planB || !isWPhoto,
        },
        {
          text: '母娘プラン',
          url: '/contracts/motherAndDaughterPlan',
          isValidate:
            store.getters['Contracts/MotherAndDaughterPlan/getIsValidate'],
          isHidden: estimateData.plan === 7,
        },
        {
          text: '納品希望日',
          url: '/contracts/deliveryDate',
          isValidate: store.getters['Contracts/DeliveryDate/getIsValidate'],
        },
        {
          text: '成人式',
          url: '/contracts/comingOfAgeCeremony',
          isValidate:
            store.getters['Contracts/ComingOfAgeCeremony/getIsValidate'],
          isHidden: [
            EstimatePlan.onlyPhoto,
            EstimatePlan.bringInOnlyPhoto,
          ].includes(estimateData.plan),
        },
        {
          text: '着付け小物',
          url: '/contracts/accessories',
          isValidate: store.getters['Contracts/Accessories/getIsValidate'],
          // NOTE:
          // https://xd.adobe.com/view/8574bb5e-0de7-412f-b974-a9a8eeee58f9-d027/screen/79cbfb6c-75b8-4fd0-b637-29d72c7528f0/specs/
          //
          // DOCS:
          // https://www.notion.so/arsagajp/4c73b6e61b5440c2bdd96ab87e034f2f?v=7888163d5f1f415fb588bcc2af4a1252
          isHidden: isHiddenAccessory(estimateData),
        },
        {
          text: 'お客様ご準備',
          url: '/contracts/customerReady',
          isValidate: store.getters['Contracts/CustomerReady/getIsValidate'],
        },
        {
          text: 'ご家族情報',
          url: '/contracts/familyInformation',
          isValidate:
            store.getters['Contracts/FamilyInformation/getIsValidate'],
        },
        {
          text: '紹介者特典',
          url: '/contracts/referralBonus',
          isValidate: store.getters['Contracts/ReferralBonus/getIsValidate'],
        },
        {
          text: 'オプション',
          url: '/contracts/option',
          isValidate: store.getters['Contracts/Option/getIsValidate'],
        },

        {
          text: 'お支払',
          url: '/contracts/payment',
          isValidate: store.getters['Contracts/Payment/getIsValidate'],
        },

        {
          text: '重要事項',
          url: '/contracts/importantMatter',
          isValidate: store.getters['Contracts/ImportantMatter/getIsValidate'],
        },
        {
          text: '備考',
          url: '/contracts/remark',
          isValidate: store.getters['Contracts/Remarks/getIsValidate'],
        },
      ]
    }

    // NOTE: 参列や結納は必要なタイミングで追加する
    return []
  }

  /**
   * isHiddenになっていない次のページのURLを返す処理
   * 使い方
   * 1. currentUrl（ドメイン以下）をstringで渡します。
   * 2. nextUrlが帰ってきます。
   * 3 - 1. nextUrlが存在していればそのURLをpushします。
   * 3 - 2. nextUrlが空文字の場合、次のURLはないため最後のページ？確認画面？へ飛ばします。
   * @param currentUrl
   */
  const getNextPageUrl = (currentUrl: string) => {
    const tabList = getContractsTabList()
    const index = tabList.findIndex((val) => val.url === currentUrl)
    let nextUrl = ''
    for (let i = index + 1; i < tabList.length; i++) {
      const tab = tabList[i]
      if (!tab.isHidden) {
        // isHiddenがfalseなら見れて良いページのためnextUrlへ入れる
        nextUrl = tab.url
        i = tabList.length
      }
    }
    return nextUrl
  }

  return { getContractsTabList, getNextPageUrl }
}
