/** [追加料金の種類] */
export class EstimateAddition {
  /** 別衿加工 */
  static readonly separateCollarProcessing = 1
  /** 正絹長襦袢 */
  static readonly nagajuban = 2
  /** 足し布 */
  static readonly tashinuno = 3
  /** Wフォト別衿加工 */
  static readonly wPhotoBetsueriKakou = 4
  /** 着付け代 */
  static readonly kitsuke = 5
  /** お送り代 */
  static readonly delibery = 6
  /** プレミアムパック（振袖レンタル） */
  static readonly premiumPackFurisodeRental = 7
  /** プレミアムパック（振袖購入） */
  static readonly premiumPackFurisodeBring = 8
  /** 帯仕立て代 */
  static readonly obiShitate = 9
  /** Wフォト帯仕立て代 */
  static readonly wPhotoObiShitate = 10

  static toWord = (val: number) => {
    switch (val) {
      case EstimateAddition.separateCollarProcessing:
        return '別衿加工'
      case EstimateAddition.nagajuban:
        return '正絹長襦袢'
      case EstimateAddition.tashinuno:
        return '足し布'
      case EstimateAddition.wPhotoBetsueriKakou:
        return '別衿加工'
      case EstimateAddition.kitsuke:
        return '着付け代'
      case EstimateAddition.delibery:
        return 'お送り代'
      case EstimateAddition.premiumPackFurisodeRental:
        return 'プレミアムパック（振袖レンタル）'
      case EstimateAddition.premiumPackFurisodeBring:
        return 'プレミアムパック（振袖購入）'
      case EstimateAddition.obiShitate:
        return '帯仕立て代'
      case EstimateAddition.wPhotoObiShitate:
        return '帯仕立て代'
      default:
        return ''
    }
  }
}

type Addition = {
  addition_type: number
  price: number
}

/**
 * 適用されている追加オプションを表示用に整形
 */
export const checkAddition = (additions: Addition[] = []) => {
  if (additions.length === 0) {
    return
  }

  const separateCollarProcessing = additions.find(
    (obj: Addition) =>
      obj.addition_type === EstimateAddition.separateCollarProcessing
  )
  const separateCollarProcessingPrice = separateCollarProcessing
    ? Number(separateCollarProcessing.price)
    : 0

  const nagajuban = additions.find(
    (obj: Addition) => obj.addition_type === EstimateAddition.nagajuban
  )
  const nagajubanPrice = nagajuban ? Number(nagajuban.price) : 0

  return {
    separateCollarProcessing: separateCollarProcessingPrice,
    nagajuban: nagajubanPrice,
  }
}
