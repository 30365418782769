import { ActionTree } from 'vuex'
import {
  RootState,
  MotherAndDaughterPlanState,
  IsClickByUsedPastType,
} from '../../types'

const actions: ActionTree<MotherAndDaughterPlanState, RootState> = {
  setPatternLine: ({ commit }, patternLine) => {
    commit('setPatternLine', patternLine)
  },

  setProduct: ({ commit }, product) => {
    commit('setProduct', product)
  },

  setInput: ({ commit }, input) => {
    commit('setInput', input)
  },

  setDate: ({ commit }, date) => {
    commit('setDate', date)
  },

  setIsInputFinish: ({ commit }, isInputFinish) => {
    commit('setIsInputFinish', isInputFinish)
  },

  setJkMotherAndDaughterPlan: ({ commit }, jkMotherAndDaughterPlan) => {
    commit('setJkMotherAndDaughterPlan', jkMotherAndDaughterPlan)
  },

  setJkProduct: ({ commit }, jkProduct) => {
    commit('setJkProduct', jkProduct)
  },

  setIsValidate: ({ commit }, isValidate: boolean) => {
    commit('setIsValidate', isValidate)
  },

  setIsClickByUsedPast: (
    { commit },
    isClickByUsedPast: IsClickByUsedPastType
  ) => {
    commit('setIsClickByUsedPast', isClickByUsedPast)
  },

  setSettings: ({ commit }, settings) => {
    commit('setSettings', settings)
  },

  setTabList: ({ commit }, tabList) => {
    commit('setTabList', tabList)
  },

  setInitTabList: ({ commit }) => {
    commit('setInitTabList')
  },

  deleteProduct: ({ commit }) => {
    commit('deleteProduct')
  },

  delete: ({ commit }) => {
    commit('delete')
  },
}

export default actions
