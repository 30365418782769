import { MutationTree } from 'vuex'
import { ZoriBagState } from '../../../types'

const mutations: MutationTree<ZoriBagState> = {
  setSelectionZori: (state, selection) => {
    state.planLineZori = selection
  },

  setSelectionBag: (state, selection) => {
    state.planLineBag = selection
  },

  setProductZori: (state, product) => {
    state.productZori = product
  },

  setProductBag: (state, product) => {
    state.productBag = product
  },

  setPriceZori: (state, price) => {
    state.priceZori = price
  },

  setPriceBag: (state, price) => {
    state.priceBag = price
  },

  setInputZori: (state, input) => {
    state.inputZori = input
  },

  setInputBag: (state, input) => {
    state.inputBag = input
  },

  setInputZoriBuyPremiumPrice: (state, price) => {
    state.inputZori.buyPremiumPrice = price
  },

  setInputBagBuyPremiumPrice: (state, price) => {
    state.inputBag.buyPremiumPrice = price
  },

  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },

  setIsPPDiscount: (state, isPPDiscount) => {
    state.isPPDiscount = isPPDiscount
  },

  setIsPPAddingPriceZori: (state, isPPAddingPriceZori) => {
    state.isPPAddingPriceZori = isPPAddingPriceZori
  },

  setIsPPAddingPriceBag: (state, isPPAddingPriceBag) => {
    state.isPPAddingPriceBag = isPPAddingPriceBag
  },

  setInitZori: (state) => {
    state.productZori = {
      id: 0,
      post_number_code: '',
      product_name: '',
      disabled: false,
      price: {
        rental: 0,
        buy: 0,
        set_purchase: 0,
      },
    }
    state.inputZori = {
      productName: '',
      buyPrice: 0,
      rentalPrice: 0,
      rankupBuyPrice: 0,
      rankupRentalPrice: 0,
      singleBuy: 0,
      singleRental: 0,
      rentalPremiumPrice: 0,
      buyPremiumPrice: 0,
    }
    state.priceZori = {
      buy: 0,
      rental: 0,
    }
  },

  setInitBag: (state) => {
    state.productBag = {
      id: 0,
      post_number_code: '',
      product_name: '',
      disabled: false,
      price: {
        rental: 0,
        buy: 0,
        set_purchase: 0,
      },
    }
    state.inputBag = {
      productName: '',
      buyPrice: 0,
      rentalPrice: 0,
      rankupBuyPrice: 0,
      rankupRentalPrice: 0,
      singleBuy: 0,
      singleRental: 0,
      rentalPremiumPrice: 0,
      buyPremiumPrice: 0,
    }
    state.priceBag = {
      buy: 0,
      rental: 0,
    }
  },

  delete: (state) => {
    state.planLineZori = [0, 0, 0, 0]
    state.planLineBag = [0, 0, 0, 0]
    state.productZori = {
      id: 0,
      post_number_code: '',
      product_name: '',
      disabled: false,
      price: {
        rental: 0,
        buy: 0,
        set_purchase: 0,
      },
    }
    state.productBag = {
      id: 0,
      post_number_code: '',
      product_name: '',
      disabled: false,
      price: {
        rental: 0,
        buy: 0,
        set_purchase: 0,
      },
    }
    state.inputZori = {
      productName: '',
      buyPrice: 0,
      rentalPrice: 0,
      rankupBuyPrice: 0,
      rankupRentalPrice: 0,
      singleBuy: 0,
      singleRental: 0,
      rentalPremiumPrice: 0,
      buyPremiumPrice: 0,
    }
    state.inputBag = {
      productName: '',
      buyPrice: 0,
      rentalPrice: 0,
      rankupBuyPrice: 0,
      rankupRentalPrice: 0,
      singleBuy: 0,
      singleRental: 0,
      rentalPremiumPrice: 0,
      buyPremiumPrice: 0,
    }
    state.priceZori = {
      buy: 0,
      rental: 0,
    }
    state.priceBag = {
      buy: 0,
      rental: 0,
    }
    state.isValidate = null
    state.isPPDiscount = false
    state.isPPAddingPriceZori = false
    state.isPPAddingPriceBag = false
  },
}

export default mutations
