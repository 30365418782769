import { ActionTree } from 'vuex'
import { RootState, AccessoryPlanState } from '../../../types'

const actions: ActionTree<AccessoryPlanState, RootState> = {
  setSelection: ({ commit }, selection) => {
    commit('setSelection', selection)
  },

  setInput: ({ commit }, input) => {
    commit('setInput', input)
  },

  setPrice: ({ commit }, price) => {
    commit('setPrice', price)
  },

  setIsFree: ({ commit }, isFree) => {
    commit('setIsFree', isFree)
  },

  setIsValidate: ({ commit }, isValidate: boolean) => {
    commit('setIsValidate', isValidate)
  },

  setIsPPDiscount: ({ commit }, isPPDiscount: boolean) => {
    commit('setIsPPDiscount', isPPDiscount)
  },

  setInit: ({ commit }) => {
    commit('setInit')
  },

  //【ランクアップ】レンタル・購入
  setRankUpRentalPrice: ({ commit }, rankUpRentalPrice) => {
    commit('setIsPPDiscount', rankUpRentalPrice)
  },
  setRankUpBuyPrice: ({ commit }, rankUpBuyPrice) => {
    commit('setIsPPDiscount', rankUpBuyPrice)
  },

  //【プレミアムパック】レンタル・購入
  setPremiumRentalPrice: ({ commit }, premiumRentalPrice) => {
    commit('setIsPPDiscount', premiumRentalPrice)
  },
  setPremiumBuyPrice: ({ commit }, premiumBuyPrice) => {
    commit('setIsPPDiscount', premiumBuyPrice)
  },
}

export default actions
