import { computed, Ref } from '@nuxtjs/composition-api'
import { useScreenTypeFlags } from '~/composable/estimate/domain/ComingOfAge/ScreenTypeFlags'
import { useSelectedPlan } from '~/composable/estimate/domain/ComingOfAge/useSelectedPlan'
import { useStateWPhoto } from '~/composable/estimate/repository/comingOfAge/states/StateWフォト'
import { useState見積一覧 } from '~/composable/estimate/repository/comingOfAge/states/State見積一覧'
import { PlanFlags } from '~/composable/estimate/repository/comingOfAge/states/types'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import AdjustmentPrice from '~/composable/general/AdjustmentPrice'
import { AmountAdjustments, EstimateListState } from '~/store/types'
import { Item, ItemWPhoto } from './types'

type EachPlanTotalAmounts = {
  column1: number
  column2: number
  column3: number
  column4: number
}

/**
 * 見積一覧ページにおける金額関連のロジックを取りまとめて、計算結果にアクセスするための関数
 */
export const useCalculator = (
  listItems: Ref<Item[]>,
  wPhotolistItems: Ref<ItemWPhoto[]>
) => {
  const wPhoto = useWPhotoCalculator(wPhotolistItems)
  const { getAdjustmentIds } = useSelectedPlan()
  const state見積一覧 = useState見積一覧()
  const { showTypeフルセット, showType写真のみレンタル, showType持ち込み } =
    useScreenTypeFlags()
  const stateWフォト = useStateWPhoto()

  const eachPlanTotalAmounts = computed(() => {
    return getEachPlanTotalAmounts(
      listItems.value,
      state見積一覧.value.amountAdjustments,
      getAdjustmentIds()
    )
  })

  // NOTE: 割引金額が合計金額が上回る場合があるため
  //       見積一覧やPDFの合計金額部分で使う
  const eachPlanActualTotalAmounts = computed(() => {
    return {
      column1:
        eachPlanTotalAmounts.value.column1 > 0
          ? eachPlanTotalAmounts.value.column1
          : 0,
      column2:
        eachPlanTotalAmounts.value.column2 > 0
          ? eachPlanTotalAmounts.value.column2
          : 0,
      column3:
        eachPlanTotalAmounts.value.column3 > 0
          ? eachPlanTotalAmounts.value.column3
          : 0,
      column4:
        eachPlanTotalAmounts.value.column4 > 0
          ? eachPlanTotalAmounts.value.column4
          : 0,
    }
  })

  const selectedTotalAmount = computed(() =>
    getSelectedTotalAmount(
      eachPlanActualTotalAmounts.value,
      state見積一覧.value.selectedプラン列
    )
  )

  const differenceAmount = computed(() => {
    const flags = {
      isフルセット: showTypeフルセット.value,
      is写のみレンタル: showType写真のみレンタル.value,
      is持ち込み: showType持ち込み.value,
    }
    return getDiffAmounts(eachPlanActualTotalAmounts.value, flags)
  })

  const totalEstimateAmount = computed(() => {
    if (stateWフォト.value.firstPage.isWPhoto適用) {
      return selectedTotalAmount.value + wPhoto.selectedTotalAmount.value
    }
    return selectedTotalAmount.value
  })

  return {
    totalEstimateAmount,
    main: {
      eachPlanTotalAmounts,
      eachPlanActualTotalAmounts,
      selectedTotalAmount,
      differenceAmount,
    },
    wPhoto: {
      eachPlanTotalAmounts: wPhoto.eachPlanTotalAmounts,
      selectedTotalAmount: wPhoto.selectedTotalAmount,
    },
  }
}

/**
 * 見積一覧ページにおけるWフォトの金額関連のロジックを取りまとめる関数
 */
const useWPhotoCalculator = (listItems: Ref<ItemWPhoto[]>) => {
  const values見積一覧 =
    useComingStore<EstimateListState['fields']>('EstimateList').getAll()
  const { getAdjustmentIdsForWPhoto } = useSelectedPlan()

  // 各見積の金額合計を算出する
  const eachPlanTotalAmounts = computed(() =>
    getEachPlanTotalAmounts(
      listItems.value,
      values見積一覧.value.amount_adjustments_for_wphoto,
      getAdjustmentIdsForWPhoto()
    )
  )

  const selectedTotalAmount = computed(() =>
    getSelectedTotalAmount(
      eachPlanTotalAmounts.value,
      values見積一覧.value.selectedWPPlan
    )
  )

  return { eachPlanTotalAmounts, selectedTotalAmount }
}

/**
 * 見積一覧に表示されている各プラン列ごとの合計金額を算出する
 */
export const getEachPlanTotalAmounts = (
  listItems: Item[] | ItemWPhoto[],
  amountAdjustments: AmountAdjustments[],
  adjustmentTypeArr: number[]
) => {
  // WPhoto側の値も同じように受け取って処理するためにここで Item[]の形に合わせる
  const list = listItems.map<EachPlanTotalAmounts>(
    (item: Item | ItemWPhoto) => {
      return {
        column1: item.column1.price ?? 0,
        column2: item.column2.price ?? 0,
        column3: 'column3' in item ? item.column3.price ?? 0 : 0,
        column4: 'column4' in item ? item.column4.price ?? 0 : 0,
      }
    }
  )

  if (amountAdjustments?.length) {
    const calculation = new AdjustmentPrice().calculation
    list.push({
      column1: adjustmentTypeArr?.[0]
        ? calculation(amountAdjustments, adjustmentTypeArr[0])
        : 0,
      column2: adjustmentTypeArr?.[1]
        ? calculation(amountAdjustments, adjustmentTypeArr[1])
        : 0,
      column3: adjustmentTypeArr?.[2]
        ? calculation(amountAdjustments, adjustmentTypeArr[2])
        : 0,
      column4: adjustmentTypeArr?.[3]
        ? calculation(amountAdjustments, adjustmentTypeArr[3])
        : 0,
    })
  }

  return list.reduce(
    (prev, cur) => {
      const nextVal = { ...prev }
      if (cur.column1) nextVal.column1 = prev.column1 + cur.column1
      if (cur.column2) nextVal.column2 = prev.column2 + cur.column2
      if (cur.column3) nextVal.column3 = prev.column3 + cur.column3
      if (cur.column4) nextVal.column4 = prev.column4 + cur.column4
      return nextVal
    },
    { column1: 0, column2: 0, column3: 0, column4: 0 }
  )
}

/**
 * 選択されている見積プランの番号に従って、選択されたプランの合計金額を取得する
 */
export const getSelectedTotalAmount = (
  amounts: EachPlanTotalAmounts,
  selectedPlan: number
) => {
  switch (selectedPlan) {
    case 1:
      return amounts.column1
    case 2:
      return amounts.column2
    case 3:
      return amounts.column3
    case 4:
      return amounts.column4
    default:
      return 0
  }
}

/**
 * 見積一覧最下部に表示する差額を算出する
 */
export const getDiffAmounts = (
  amounts: EachPlanTotalAmounts,
  planFlags: PlanFlags
) => {
  let better = 0
  // 卒業式を含めたレンタルと購入の差額(フルセットのみ)
  let with卒業式 = 0

  if (planFlags.isフルセット) {
    const レンタルお得 = calcDiff(amounts.column1, amounts.column2).cheap
    const 購入お得 = calcDiff(amounts.column3, amounts.column4).cheap
    // 「こちらがお得」のレンタルと購入の差額
    better = calcDiff(レンタルお得, 購入お得).diff
    // 算出方法: 購入のお得金額 - (レンタルのお得金額 + 卒業式の想定価格)
    with卒業式 = 購入お得 - (レンタルお得 + 66000)
    if (with卒業式 < 0) with卒業式 = 0
  } else if (planFlags.is写のみレンタル) {
    const アルバム5Pお得 = calcDiff(amounts.column1, amounts.column2).cheap
    const アルバム15Pお得 = calcDiff(amounts.column3, amounts.column4).cheap
    // 「こちらがお得」の5P×1冊と15P×1冊の差額
    better = calcDiff(アルバム5Pお得, アルバム15Pお得).diff
  } else if (planFlags.is持ち込み) {
    better = calcDiff(amounts.column1, amounts.column2).diff
  }

  return {
    better,
    with卒業式,
  }
}

const calcDiff = (price1: number, price2: number) => {
  const cheap = Math.min(price1, price2)
  const expensive = Math.max(price1, price2)
  return {
    cheap,
    expensive,
    diff: expensive - cheap,
  }
}
