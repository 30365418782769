import { GetterTree } from 'vuex'
import { RootState } from '~/store/Estimate/GraduationCeremony/types'
import { ExtendsEstimateState } from './state'

const getters: GetterTree<{ fields: ExtendsEstimateState }, RootState> = {
  getState: (state) => (stateName: keyof ExtendsEstimateState) => {
    return state.fields[stateName]
  },
  getAllState: (state) => {
    return state.fields
  },

  getWPhotoState:
    (state) => (stateName: keyof ExtendsEstimateState['wPhoto']) => {
      return state.fields.wPhoto[stateName]
    },
  getWPhotoAllState: (state) => {
    return state.fields.wPhoto
  },

  getProducts: (state) => (productTypeId: number) => {
    return state.fields.products.filter(
      (item) => item.productTypeId === productTypeId
    )
  },
  getWPhotoProducts: (state) => (productTypeId: number) => {
    return state.fields.wPhoto.products.filter(
      (item) => item.productTypeId === productTypeId
    )
  },
}
export default getters
