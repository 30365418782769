import { GetterTree } from 'vuex'
import { RootState, ComingOfAgeCeremonyState } from '../../types'

const getters: GetterTree<ComingOfAgeCeremonyState, RootState> = {
  getPatternLine: (state: ComingOfAgeCeremonyState) => {
    return state.patternLine
  },
  getInput: (state: ComingOfAgeCeremonyState) => {
    return state.input
  },
  getSalonInfo: (state: ComingOfAgeCeremonyState) => {
    return {
      nameOfSalon: state.input.nameOfSalon,
      telOfSalon: state.input.telOfSalon,
      prefecture: state.input.prefecture,
      salonId: state.input.salonId,
      address: state.input.address,
      shopId: state.input.shopId,
    }
  },
  getShop: (state: ComingOfAgeCeremonyState) => {
    return state.shop
  },
  getDressingPlacesInfo: (state: ComingOfAgeCeremonyState) => {
    return state.dressingPlaces
  },
  getIsValidate: (state: ComingOfAgeCeremonyState) => {
    return state.isValidate
  },
  getCeremonyDate: (state: ComingOfAgeCeremonyState) => {
    return state.input.ceremonyDate
  },
}

export default getters
