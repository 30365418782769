import { computed } from '@nuxtjs/composition-api'
import { usePlanFlags } from '~/composable/estimate/domain/Graduation/PlanFlags'
import {
  Enum別衿_着物レンタル,
  Enum別衿_着物持込,
} from '~/constants/estimate/graduationOptions'
import { FormValues別衿_着物レンタル } from '~/composable/estimate/viewModel/graduation/Fields/Fields別衿_着物レンタル'
import { FormValues別衿_着物持込 } from '~/composable/estimate/viewModel/graduation/Fields/Fields別衿_着物持込'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import {
  getPriceFromEditPrice,
  getPriceFromSelectPriceWithFreeInput,
  getShowPrice,
} from '~/composable/estimate/repository/graduation/states/utilities'
import { ShowPriceType } from '~/composable/estimate/repository/graduation/states/types'

type Betsueri = {
  品種区分: 6
  商品名: string | null
  別衿加工必要: boolean
  小物プラン: number | null
  レンタル価格: number | null
  購入価格: number | null
  見積表示: ShowPriceType | null
  表示価格: number | null
  isランクアップ: boolean
  isセット内: boolean
  label: string
}

export const useState別衿 = () => {
  const store別衿_着物レンタル =
    useGraduateStore<FormValues別衿_着物レンタル>('tab別衿_着物レンタル')
  const store別衿_着物持込 =
    useGraduateStore<FormValues別衿_着物持込>('tab別衿_着物持込')
  const values別衿_着物レンタル = store別衿_着物レンタル.getAll()
  const values別衿_着物持込 = store別衿_着物持込.getAll()
  const { hasKimonoRental } = usePlanFlags()

  return computed(() => {
    const betsueri: Betsueri = {
      品種区分: 6,
      商品名: null,
      別衿加工必要: false,
      小物プラン: null,
      レンタル価格: null,
      購入価格: null,
      見積表示: null,
      表示価格: null,
      isランクアップ: false,
      isセット内: false,
      label: '',
    }
    if (hasKimonoRental.value) {
      /*
       * プランで着物レンタルを選んだ際の別衿タブ選択内容
       */
      const 種別選択 = values別衿_着物レンタル.value.betsueri種別
      const 形態選択 = values別衿_着物レンタル.value.betsueri形態
      const セット内を選択 = 形態選択 === Enum別衿_着物レンタル.形態.セット内
      const ランクアップを選択 = !セット内を選択
      const お持込を選択 = 種別選択 === Enum別衿_着物レンタル.種別.お持込

      if (お持込を選択) {
        betsueri.小物プラン = Enum別衿_着物レンタル.種別.お持込
        betsueri.label = 'お持込'
        betsueri.別衿加工必要 = お持込を選択
        return betsueri
      }
      if (セット内を選択) {
        betsueri.小物プラン = Enum別衿_着物レンタル.形態.セット内
        betsueri.レンタル価格 = getPriceFromEditPrice(
          values別衿_着物レンタル.value.betsueriレンタル価格
        )
        betsueri.label = 'セット内'
        betsueri.isセット内 = true
      }
      if (ランクアップを選択) {
        betsueri.小物プラン = Enum別衿_着物レンタル.形態.ランクアップ
        betsueri.レンタル価格 = getPriceFromSelectPriceWithFreeInput(
          values別衿_着物レンタル.value.betsueri商品情報,
          values別衿_着物レンタル.value.betsueriフリー入力
        )
        betsueri.label = 'ランクアップ'
        betsueri.isランクアップ = true
      }
      betsueri.表示価格 = betsueri.レンタル価格
      return betsueri
    } else {
      /*
       * プランで着物持込を選んだ際の別衿タブ選択内容
       */
      const 種別選択 = values別衿_着物持込.value.betsueri種別
      const お持込を選択 = 種別選択 === Enum別衿_着物持込.種別.お持込
      const 白無地を選択 = 種別選択 === Enum別衿_着物持込.種別.白無地

      if (お持込を選択) {
        betsueri.小物プラン = Enum別衿_着物持込.種別.お持込
        betsueri.label = 'お持込'
        betsueri.別衿加工必要 =
          values別衿_着物持込.value.betsueri長襦袢 ===
          Enum別衿_着物レンタル.長襦袢.いいえ
        return betsueri
      }

      if (白無地を選択) {
        betsueri.小物プラン = Enum別衿_着物持込.種別.白無地
        betsueri.label = '白無地'
        return betsueri
      }

      betsueri.別衿加工必要 =
        values別衿_着物持込.value.betsueri長襦袢 ===
        Enum別衿_着物レンタル.長襦袢.いいえ
      betsueri.小物プラン = Enum別衿_着物持込.種別.単品レンタル単品購入
      betsueri.レンタル価格 = getPriceFromSelectPriceWithFreeInput(
        values別衿_着物持込.value.betsueri単品レンタル,
        values別衿_着物持込.value.betsueri単品レンタルフリー入力
      )
      betsueri.購入価格 = getPriceFromSelectPriceWithFreeInput(
        values別衿_着物持込.value.betsueri単品購入,
        values別衿_着物持込.value.betsueri単品購入フリー入力
      )

      const { showType, showPrice } = getShowPrice(
        values別衿_着物持込.value.betsueri見積選択,
        betsueri.レンタル価格,
        betsueri.購入価格,
        Enum別衿_着物持込.見積表示
      )
      betsueri.見積表示 = showType
      betsueri.表示価格 = showPrice

      return betsueri
    }
  })
}
