import { ActionTree } from 'vuex'
import { RootState, BaseEstimateState } from '../types'

const actions: ActionTree<BaseEstimateState, RootState> = {
  setEstimate: ({ commit }, estimate) => {
    commit('setEstimate', estimate)
  },

  delete: ({ commit }) => {
    commit('delete')
  },
}

export default actions
