import { MutationTree } from 'vuex'
import { MemoState } from '../../../types'

const mutations: MutationTree<MemoState> = {
  setText: (state, text) => {
    state.text = text
  },
  setChance: (state, isChance) => {
    state.isChance = isChance
  },

  setMemoId: (state, memoId) => {
    state.memoId = memoId
  },

  setCustomerId: (state, customerId) => {
    state.customerId = customerId
  },

  initAll: (state) => {
    state.text = ''
    state.isChance = false
    state.memoId = null
    state.customerId = null
  },
}

export default mutations
