import { SignatureState } from '../../types'

const state = (): SignatureState => ({
  checked: {
    first: false,
    second: false,
    third: false,
    fourth: false,
    fifth: false,
    sixth: false,
  },
  signature_url: '',
  parental_signature_url: '',
  fetchedAt: undefined,
  isValidate: null,
  contractTotalPrice: 0,
})

export default state
