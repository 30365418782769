import { MutationTree } from 'vuex'
import { CustomerState } from '../../../types'

const mutations: MutationTree<CustomerState> = {
  setName: (state, name: string) => {
    state.name = name
  },

  setCustomer: (state, customer) => {
    state.customer = customer
  },

  setIsValidateName: (state, isValidate) => {
    state.isValidate.name = isValidate
  },

  setIsValidateNumber: (state, isValidate) => {
    state.isValidate.number = isValidate
  },

  // 来店動機
  setVisitMotivese: (state, visitMotivese) => {
    state.visit_motives = visitMotivese
  },

  // メールアドレス
  setGuidanceEmail: (state, guidanceEmail) => {
    state.guidanceEmail = guidanceEmail
  },

  deleteName: (state) => {
    state.name = ''
  },

  deleteCustomer: (state) => {
    state.customer = null
  },

  deleteVisitMotivese: (state) => {
    state.visit_motives = []
  },

  delete: (state) => {
    state.name = ''
    state.customer = null
    state.visit_motives = []
    state.isValidate = {
      name: null,
      number: null,
    }
    state.guidanceEmail = null
  },
}

export default mutations
