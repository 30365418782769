import { nextTick } from '@nuxtjs/composition-api'
import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { ProductState } from '~/store/types'

export const useRestore商品設定 = () => {
  const store = useComingStore<ProductState>('Products')

  const restore商品設定 = (items: Restate['items']) => {
    // NOTE: 他の部分の計算によって変化する場所のためか？タブの増減に影響する部分だからか？
    // nextTickを使わないと正常に更新されない
    nextTick(() => {
      const { 帯揚げ, 重ね衿, 髪飾り, 草履複数個目, バッグ複数個目 } = items

      /* 初期値を設定 ※必ず入力される値はここで設置 */
      const formValues: ProductState = {
        products: [
          {
            id: 1,
            name: '帯揚げ',
            count: 帯揚げ ? 帯揚げ.length : 0,
          },
          {
            id: 2,
            name: '重ね衿',
            count: 重ね衿 ? 重ね衿.length : 0,
          },
          {
            id: 3,
            name: '髪飾り',
            count: 髪飾り ? 髪飾り.length : 0,
          },
          {
            id: 4,
            name: '草履',
            count: 草履複数個目 ? 草履複数個目.length + 1 : 1,
          },
          {
            id: 5,
            name: 'バッグ',
            count: バッグ複数個目 ? バッグ複数個目.length + 1 : 1,
          },
        ],
        isValidate: false,
      }

      /* ストアに内容を保存する */
      store.setAll(formValues)
    })
  }
  return { restore商品設定 }
}
