import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { FormValues足し布 } from '~/composable/estimate/viewModel/comingOfAge/Fields/Fields足し布'

export const useRestore足し布 = () => {
  const store = useComingStore<FormValues足し布>('Tashinuno')

  const restore足し布 = (additions: Restate['additions']) => {
    if (!additions) return
    const { 足し布 } = additions

    const formValues: FormValues足し布 = {
      tashinuno適用: 足し布.optionId適用,
      tashinuno採寸数値: {
        bust: null,
        hip: null,
        bodyType: null,
      },
      tashinuno追加金額: null,
    }
    if (足し布.is適用) {
      formValues.tashinuno採寸数値 = 足し布.採寸数値
      formValues.tashinuno追加金額 = 足し布.optionId追加金額 ?? null
    }
    store.setAll(formValues)
    store.setIsValidate(false)
  }
  return { restore足し布 }
}
