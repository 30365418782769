import { MutationTree } from 'vuex'
import { ServicePlanState } from '../../../types'

const mutations: MutationTree<ServicePlanState> = {
  setSelection: (state, selection) => {
    state.selections = selection
  },

  setCustomer: (state, customer) => {
    state.customer = customer
  },

  setDate: (state, date) => {
    state.date = date
  },

  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },

  delete: (state) => {
    state.selections = [0, 0, 0, 0]
    state.customer = null
    state.date = ''
    state.isValidate = null
  },

  deleteCustomer: (state) => {
    state.customer = null
  },

  deleteDate: (state) => {
    state.date = ''
  },
}

export default mutations
