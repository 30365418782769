import { MutationTree } from 'vuex'
import { PaymentMethod, PaymentState } from '../../types'

const mutations: MutationTree<PaymentState> = {
  setDueDate(state: PaymentState, dueDate: string): void {
    state.due_date = dueDate
  },

  setStaff(state: PaymentState, staff): void {
    state.staff = staff
  },

  setCategory(state: PaymentState, category: number): void {
    state.category = category
  },

  setDetails(state: PaymentState, details: PaymentMethod[]) {
    state.details = details
  },

  setTotal(state: PaymentState, total: number) {
    state.total = total
  },

  setNotes(state: PaymentState, notes: string) {
    state.notes = notes
  },

  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },

  setBalance: (state, balance) => {
    state.balance = balance
  },

  setPaidAmount: (state, paidAmount) => {
    state.paidAmount = paidAmount
  },

  setIsPayment: (state, isPayment) => {
    state.isPayment = isPayment
  },

  delete: (state) => {
    state.contract_id = ''
    state.due_date = ''
    state.staff = null
    state.category = null
    state.details = []
    state.total = 0
    state.notes = ''
    state.isValidate = null
    state.isPayment = null
    state.balance = 0
    state.paidAmount = 0
  },
}

export default mutations
