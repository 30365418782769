













import { defineComponent, computed } from '@nuxtjs/composition-api'

export default defineComponent({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: [Boolean, Array],
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    // MEMO: modelValueがArrayの場合は必須
    value: {
      type: [String, Number],
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const checked = computed({
      get: () => {
        return typeof props.modelValue === 'boolean'
          ? props.modelValue
          : props.modelValue.includes(props.value)
      },
      set: (value) => emit('update:modelValue', value),
    })

    return { checked }
  },
})
