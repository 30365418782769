import { EstimatePhotoSettings } from '../../../../types'

const state = (): EstimatePhotoSettings => ({
  /**
   * NOTE: selections = patternLine
   * ↓保持される選択肢のindex
   * [0]アップロードするか
   */
  selections: [0],
  settings: [
    {
      id: 1,
      title: '全体写真',
      limit: 3,
      isPossibleCheck: true,
      photos: [],
    },
    {
      id: 2,
      title: '衿元写真',
      limit: 1,
      isPossibleCheck: false,
      photos: [],
    },
  ],
  isValidate: null,
})

export default state
