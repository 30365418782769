import { ActionTree } from 'vuex'
import { RootState, CustomerState } from '../../../types'

const actions: ActionTree<CustomerState, RootState> = {
  setName: ({ commit }, name: string) => {
    commit('setName', name)
  },

  setCustomer: ({ commit }, customer: object) => {
    commit('setCustomer', customer)
  },

  // 来店動機
  setVisitMotivese: ({ commit }, visitMotivese: object[]) => {
    commit('setVisitMotivese', visitMotivese)
  },

  // お名前のバリデーション
  setIsValidateName: ({ commit }, isValidate: boolean) => {
    commit('setIsValidateName', isValidate)
  },

  // 顧客番号のバリデーション
  setIsValidateNumber: ({ commit }, isValidate: boolean) => {
    commit('setIsValidateNumber', isValidate)
  },

  deleteName: ({ commit }) => {
    commit('deleteName')
  },

  deleteCustomer: ({ commit }) => {
    commit('deleteCustomer')
  },

  deleteMotivese: ({ commit }) => {
    commit('deleteVisitMotivese')
  },
}

export default actions
