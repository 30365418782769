import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { EstimatePhotoSettings } from '~/store/types'

export const useRestoreWPhoto試着写真 = () => {
  const store = useComingStore<EstimatePhotoSettings>(
    'WphotoPlan/WphotoSetting'
  )

  const restoreWPhoto試着写真 = (photos: Restate['photos'] | undefined) => {
    if (!photos) return

    /* 初期値を設定 ※必ず入力される値はここで設置 */
    const formValues: EstimatePhotoSettings = {
      selections: [photos.OptionIdOfアップロード],
      settings: [
        {
          id: 1,
          title: '全体写真',
          limit: 3,
          isPossibleCheck: true,
          photos: photos.全体写真,
        },
        {
          id: 2,
          title: '衿元写真',
          limit: 1,
          isPossibleCheck: false,
          photos: photos.襟元写真,
        },
      ],
      isValidate: false,
    }
    store.setAll(formValues)
  }
  return { restoreWPhoto試着写真 }
}
