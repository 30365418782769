













































































import {
  defineComponent,
  ref,
  useStore,
  watch,
  onBeforeMount,
  computed,
  useRouter,
} from '@nuxtjs/composition-api'
import useMemo, { Memo } from '~/composable/general/useMemo'
import { useMemo as useApiMemo } from '~/composable/api/useMemo'
import AppCheckbox from './AppCheckbox.vue'
import AppIcon from './AppIcon.vue'
import IconX from './icons/IconX.vue'
import { useContractTypeFlags } from '~/composable/contracts/ContractTypeFlags'

import MemoBoxBody from '~/components/userInfo/MemoBoxBody.vue'

import { State } from '~/store/Contracts/Attendance/Main/state'

const TAB_ID = {
  ESTIMATE: 1,
  CONTRACT: 2,
}

export default defineComponent({
  components: {
    AppCheckbox,
    AppIcon,
    IconX,
    MemoBoxBody,
  },
  setup() {
    const store = useStore()
    const {
      in卒業式成人式契約,
      in参列契約,
      inクリーニング契約,
      in単品サービス契約,
    } = useContractTypeFlags()
    const { fetchMemos, memos } = useMemo()
    const { patchMemo, postMemo } = useApiMemo()
    const router = useRouter()
    const customerId = in参列契約.value
      ? store.getters['Contracts/Attendance/Main/getCustomereId']
      : in単品サービス契約.value
      ? store.getters['SingleItemService/Main/getState'].productSetting.customer
          ?.customer_id
      : store.getters['Contracts/BaseEstimate/getEstimate'].customer_id
    const estimateId = in単品サービス契約.value
      ? Number(router.currentRoute.query.estimate_id)
      : store.getters['Contracts/BaseEstimate/getEstimate'].estimate_id
    const contractId = in参列契約.value
      ? (router.currentRoute.params.contract_id as string)
      : (router.currentRoute.query.contract_id as string)
    const isVisible = ref(false)
    const checked = ref(false)
    const inputTextarea = ref('')
    const isContract = ref(true)

    // 参列用
    const attendanceEstimateMemos = ref<Memo[]>([])

    // 参列見積のメモ取得の一連処理
    const fetchAttendanceEstimateMemos = async () => {
      const selectEstimate: State['selectEstimate'] =
        store.getters['Contracts/Attendance/Main/getSelectEstimate']

      if (!selectEstimate.estimateDataList.length) {
        attendanceEstimateMemos.value = []
        return
      }

      try {
        const memos = await Promise.all(
          selectEstimate.estimateDataList.map(async (estimate) => {
            const fetchedMemos = await fetchMemos(estimate.customer_id)
            const fetchedMemosOnlySelectedEstimateId = fetchedMemos?.filter(
              (memo) => memo.estimate_id === estimate.estimate_id
            )
            return fetchedMemosOnlySelectedEstimateId ?? []
          })
        )

        attendanceEstimateMemos.value = memos.flat()
      } catch (error) {
        alert('見積作成時のメモを取得できませんでした。')
      }
    }

    const openModal = async () => {
      isVisible.value = true
      document.body.style.overflow = 'hidden'

      if (in卒業式成人式契約.value) {
        checked.value = store.getters['Contracts/Memo/getChance']
        inputTextarea.value = store.getters['Contracts/Memo/getText']
      } else if (in参列契約.value) {
        checked.value = store.getters['Contracts/Attendance/Memo/getChance']
        inputTextarea.value = store.getters['Contracts/Attendance/Memo/getText']

        await fetchAttendanceEstimateMemos()
      } else if (inクリーニング契約.value) {
        checked.value = store.getters['Contracts/Cleaning/Memo/getChance']
        inputTextarea.value = store.getters['Contracts/Cleaning/Memo/getText']
      } else if (in単品サービス契約.value) {
        checked.value = store.getters['SingleItemService/Memo/getChance']
        inputTextarea.value = store.getters['SingleItemService/Memo/getText']
      }
    }
    const closeModal = async () => {
      isVisible.value = false
      const scrollY = document.body.style.top
      document.body.style.overflow = 'auto'
      window.scrollTo(0, -parseInt(scrollY || '0'))
      if (router.currentRoute.path.includes('creationCompleted')) {
        await postingMemo()
      }
    }

    // タブの切り替え
    const clickTab = (id: number) => {
      if (id === TAB_ID.ESTIMATE) isContract.value = false
      if (id === TAB_ID.CONTRACT) isContract.value = true
    }

    //* メモを保存する処理 ※参列及びクリーニングは作成APIに乗せるのでここでは保存しない
    const postingMemo = async () => {
      const { contract_id, customer_id, memo_id } = router.currentRoute.query
      const customerId = Number(customer_id)
      const queryMemoId = Number(memo_id)
      const contractId = Number(contract_id)

      const memoId = store.getters['Contracts/Memo/getMemoId']
      const body = store.getters['Contracts/Memo/getText']

      if (!body) {
        return
      }
      const memoData = {
        staff_id: store.getters['LoginUser/getUser']?.staff_id,
        shop_id: store.getters['SettingShop/getShop']?.id,
        is_chance: store.getters['Contracts/Memo/getChance'],
        body: body,
        contract_id: contractId,
      }

      //* メモを作成していた場合の更新処理
      if (queryMemoId !== 0) {
        await patchMemo(customerId, memoId, memoData)
        return
      }
      //メモを作成してなかった場合の作成の処理
      if (customerId && queryMemoId === 0) {
        await postMemo(customerId, memoData, '契約書作成', 'contract')
        const memoId = await store.getters['Contracts/Memo/getMemoId']

        if (memoId) {
          router.push({
            query: {
              contract_id: contract_id,
              memo_id: memoId,
              customer_id: customer_id,
            },
          })
        }
        return
      }
    }

    watch([checked, inputTextarea], () => {
      if (in卒業式成人式契約.value) {
        store.dispatch('Contracts/Memo/setChance', checked.value)
        store.dispatch('Contracts/Memo/setText', inputTextarea.value)
      } else if (in参列契約.value) {
        store.commit('Contracts/Attendance/Memo/setChance', checked.value)
        store.commit('Contracts/Attendance/Memo/setText', inputTextarea.value)
      } else if (inクリーニング契約.value) {
        store.commit('Contracts/Cleaning/Memo/setChance', checked.value)
        store.commit('Contracts/Cleaning/Memo/setText', inputTextarea.value)
      } else if (in単品サービス契約.value) {
        store.commit('SingleItemService/Memo/setChance', checked.value)
        store.commit('SingleItemService/Memo/setText', inputTextarea.value)
      }
    })

    /** 見積時のメモ */
    const estimateMemos = computed(() => {
      return in参列契約.value
        ? attendanceEstimateMemos.value
        : memos.value.filter((memo: Memo) => memo.estimate_id === estimateId)
    })

    // 契約時のメモ(契約更新の際に表示する)
    const contractMemos = computed(() => {
      return memos.value.filter(
        (memo: Memo) => memo.contract_id === Number(contractId)
      )
    })

    onBeforeMount(async () => {
      if (customerId) await fetchMemos(customerId)
    })

    return {
      TAB_ID,
      isVisible,
      checked,
      openModal,
      closeModal,
      isContract,
      clickTab,
      estimateMemos,
      contractMemos,
      inputTextarea,
      inクリーニング契約,
    }
  },
})
