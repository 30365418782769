import {
  SingleItemServiceContractDetail,
  SingleItemServiceEstimateDetail,
} from '~/api/singleItemRepository'
import { State } from '~/store/SingleItemService/Main/state'

/**
 * @note
 * 単品契約詳細or単品見積詳細APIから取得した場合にデータを復元する処理
 *
 * 詳しくはAPI仕様書(2023/05/24時点)をご確認ください
 * https://docs.google.com/spreadsheets/d/1squ0uolJXm0i2LS4-JMBqQCxZfoHEgEKgpd7Iec8SYw/edit#gid=721026106
 */
export const restateRemarks = (
  data:
    | SingleItemServiceContractDetail
    | SingleItemServiceEstimateDetail
    | undefined
) => {
  const remarks: State['data']['remarks'] = { text: '' }
  remarks.text = data?.notes ?? ''

  return remarks
}
