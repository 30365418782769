import { MutationTree } from 'vuex'
import { ImportantMatterState } from '../../types'

const mutations: MutationTree<ImportantMatterState> = {
  setInput: (state, payload: ImportantMatterState['input']) => {
    state.input = payload
  },

  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },

  delete: (state) => {
    state.input.coolingOffPeriod = ''
    state.input.paymentDeadline = ''
    state.input.payee = null
    state.input.payeeInfo = ''
    state.input.loginShopTransferPlaceId = null
    state.isValidate = null
    state.input.loginShopTransferPlaceId = null
  },
}

export default mutations
