import { computed } from '@nuxtjs/composition-api'
import { ZoriState, ZoriBagState } from '~/store/types'
import { useComingStore } from '../../ComingOfAgeStore'
import { useScreenTypeFlags } from '~/composable/estimate/domain/ComingOfAge/ScreenTypeFlags'
import { PlanFlags } from './types'
import { GRADUATION_ZORI } from '~/constants/estimate/comingOfAge'
import ProductClassification from '~/constants/enums/productClassification'
import {
  getZoriBagBadgeText,
  getZoriBagPlan,
  getZoriBagPlanFlags,
  getZoriBagPrices,
  getZoriBagPriceType,
  getZoriBagPriceTypeText,
  StoreValues,
  Zori,
} from './zoriBagUtils'
import {
  GEINPIN,
  UNDECIDED,
} from '~/composable/estimate/viewModel/comingOfAge/FieldProps/FieldProps草履バッグ'

export const useState草履1個目 = () => {
  const values草履 = useComingStore<ZoriBagState>('ZoriBag').getAll()
  const isPPDiscount = values草履.value.isPPDiscount
  const isPPAddingPrice = values草履.value.isPPAddingPriceZori
  const { showTypeフルセット, showType写真のみレンタル, showType持ち込み } =
    useScreenTypeFlags()

  return computed(() => {
    const flags = {
      isフルセット: showTypeフルセット.value,
      is写のみレンタル: showType写真のみレンタル.value,
      is持ち込み: showType持ち込み.value,
    }
    return getState草履1個目(
      values草履.value,
      flags,
      isPPDiscount,
      isPPAddingPrice
    )
  })
}

export const getState草履1個目 = (
  values草履: ZoriState,
  planFlags: PlanFlags,
  isPPDiscount: boolean,
  isPPAddingPrice: boolean
) => {
  const { planLineZori, inputZori, productZori, priceZori } = values草履
  const itemFlags = getZoriBagPlanFlags(planLineZori, productZori)
  const itemPlan = getZoriBagPlan(planLineZori, productZori)
  const store: StoreValues = {
    price: priceZori,
    input: inputZori,
    product: productZori,
    selections: planLineZori,
  }

  const priceTypeInfo = getZoriBagPriceType(store, planFlags)
  const selected同じ商品を単品購入 = planLineZori.includes(
    GRADUATION_ZORI.BUY_THE_SAME_PRODUCT
  )

  const is持込 =
    planLineZori.includes(GRADUATION_ZORI.BRING_IN) ||
    planLineZori.includes(GRADUATION_ZORI.BRING_IN_in振袖持込プラン)

  const isU系商品 = productZori.post_number_code.includes('U-')
  const is振袖レンタル時単品購入 =
    selected同じ商品を単品購入 || (!planFlags.isフルセット && isU系商品)

  const selectedレンタルこちらでお選びボタン =
    !inputZori.productName ||
    inputZori.productName === '-' ||
    inputZori.productName === 'こちらでお選びいたします。'

  let linkText = inputZori.productName
  let productName = inputZori.productName

  if (selectedレンタルこちらでお選びボタン && !is持込) {
    linkText = 'こちらでお選びいたします（レンタル）'
    productName = 'こちらでお選びいたします。'
  }

  const formatted商品id = () => {
    if (productZori.id === GEINPIN.id) return null
    if (productZori.id === UNDECIDED.id) return null
    if (itemFlags.isお持込) return null
    return productZori.id
  }

  const zori: Zori = {
    品目名: ProductClassification.toWord(ProductClassification.zori),
    品種区分: ProductClassification.zori,
    商品: {
      id: formatted商品id(),
      本体名: productZori.product_name || '',
      postNumberCode: productZori.post_number_code || '',
    },
    商品名: productName,
    小物プラン: itemPlan,
    レンタル価格: 0,
    購入価格: 0,
    単品購入価格: 0,
    プレミアムレンタル価格: 0,
    プレミアム購入価格: inputZori.buyPremiumPrice || 0,
    見積表示: priceTypeInfo?.showType ?? null,
    表示価格: priceTypeInfo?.showPrice ?? 0,
    label: getZoriBagBadgeText(planLineZori, productZori),
    cellLabel: getZoriBagPriceTypeText(priceTypeInfo),
    linkText,
    flags: {
      isランクアップ:
        itemFlags.isランクアップPP内 || itemFlags.isランクアップPP外,
      is振袖レンタル時単品購入,
      is振袖持込: planFlags.is持ち込み,
      selectedレンタルこちらでお選びボタン,
      selectedU系商品: isU系商品,
      is持込,
      isPPDiscount: isPPDiscount,
      isPPAddingPrice: isPPAddingPrice,
    },
  }

  const updatedPrices = getZoriBagPrices(store, zori, planFlags)
  zori.レンタル価格 = updatedPrices.レンタル価格
  zori.購入価格 = updatedPrices.購入価格
  zori.単品購入価格 = updatedPrices.単品購入価格
  zori.プレミアムレンタル価格 = updatedPrices.プレミアムレンタル価格
  zori.プレミアム購入価格 = updatedPrices.プレミアム購入価格
  return zori
}
