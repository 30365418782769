import { computed } from '@nuxtjs/composition-api'
import { usePrice } from '~/composable/general/Price'
import { Enumプラン, Enum袴 } from '~/constants/estimate/graduationOptions'
import { FormValues袴 } from '~/composable/estimate/viewModel/graduation/Fields/Fields袴'
import { WithFreeInput } from '~/store/Estimate/GraduationCeremony/types'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { FormValuesプラン } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsプラン'

export const useState袴 = () => {
  const valuesプラン = useGraduateStore<FormValuesプラン>('tabプラン').getAll()
  const values袴 = useGraduateStore<FormValues袴>('tab袴').getAll()
  const { toNumber } = usePrice()

  return computed(() => {
    const isお持込 = valuesプラン.value.plan袴種別 === Enumプラン.袴種別.お持込
    const isプランレンタル =
      valuesプラン.value.plan袴種別 === Enumプラン.袴種別.レンタル
    const is袴割引適用 =
      !isお持込 &&
      valuesプラン.value.plan弊社成人式 === Enumプラン.弊社成人式.はい

    const 商品分類 = isお持込
      ? Enum袴.種別.お持込
      : values袴.value.hakamaIsカタログ商品 === Enum袴.カタログ商品OR現品.現品
      ? Enum袴.カタログ商品OR現品.現品
      : values袴.value.hakamaIsカタログ商品 ===
        Enum袴.カタログ商品OR現品.カタログ商品
      ? Enum袴.レンタルORカタログ.カタログレンタル
      : null

    const isレンタル = 商品分類 === Enum袴.カタログ商品OR現品.現品 && !isお持込
    const isカタログレンタル =
      商品分類 === Enum袴.カタログ商品OR現品.カタログ商品 && !isお持込
    const hasカタログ在庫 = isカタログレンタル
      ? values袴.value.hakamaカタログ検索?.available_order_quantity
      : null
    const is袴保留 = isレンタル && !!values袴.value.hakama保留 && !isお持込

    const レンタル価格 =
      isレンタル || is袴保留
        ? getPriceFromSelectPriceWithFreeInput(
            values袴.value.hakama商品情報_価格,
            values袴.value.hakama商品情報_価格入力
          )
        : isカタログレンタル
        ? toNumber(values袴.value.hakamaカタログレンタル価格 || '')
        : null

    const 袴検索 = isレンタル
      ? values袴.value.hakama商品検索
      : isカタログレンタル
      ? values袴.value.hakamaカタログ検索
      : null

    return {
      品種区分: 9,
      商品分類: 商品分類, //レンタル:1, お持ち込み:3, カタログレンタル:15
      商品検索: isお持込 ? null : 袴検索,
      レンタル価格: isお持込 ? null : レンタル価格,
      袴割引: is袴割引適用 ? values袴.value.hakama割引適用 : null,
      ラベル: isお持込 ? 'お持込' : '',
      // NOTE: 真偽値判定するフラグはここで定義してstateからアクセスできる様にする
      flags: {
        isお持込,
        isプランレンタル,
        isレンタル,
        isカタログレンタル,
        is袴保留,
        hasカタログ在庫,
        isパーセントOff:
          is袴割引適用 &&
          values袴.value.hakama割引適用 === Enum袴.袴割引.割引率,
        is固定金額Off:
          is袴割引適用 &&
          values袴.value.hakama割引適用 === Enum袴.袴割引.割引金額,
      },
    }
  })
}

const getPriceFromSelectPriceWithFreeInput = (
  selectedOption: WithFreeInput | null,
  inputPrice: string | null
) => {
  if (selectedOption === null) return null
  const { toNumber } = usePrice()
  const フリー入力選択 = selectedOption.id === 0

  if (フリー入力選択) {
    if (inputPrice === null) return null
    return toNumber(inputPrice)
  } else {
    return toNumber(selectedOption.value)
  }
}
