/*
 * 卒業式側の主にoptionIdに使用する固定値をEnumライクに定義するファイル
 * 固定値について：
 * APIに投げる数値はここを参照
 * https://docs.google.com/spreadsheets/d/1p0MxMw2VO6PmJCs4UzyM4rqfO6BqBypM/edit#gid=1427553124
 * それ以外は連番とする
 */
export type NumberByString = {
  [key: number]: string
}

export const Enumプラン = {
  弊社成人式: {
    はい: 1,
    いいえ: 2,
  },
  どちらのフルセットプラン: {
    フルセットレンタル: 1,
    フルセット購入: 6,
  },
  // [サービス区分名]
  着付け区分: {
    弊社着付け: 1,
    着付けなし: 4,
  },
  // [品種区分]
  着物を選択: {
    振袖: 1,
    二尺袖: 8,
  },
  着物種別: {
    // [商品分類]
    レンタル: 7,
    姉販持込: 4,
    お持込: 3,
  },
  袴種別: {
    // [商品分類]
    レンタル: 7,
    お持込: 3,
  },
  友の会会員: {
    はい: 1,
    いいえ: 2,
  },
} as const

export const 卒業式長期レンタル値引適用開始日 = '2024-04-01T00:00:00'
export const 卒業式二尺袖レンタル袴持ち込み値引適用開始日 =
  '2024-06-05T00:00:00'

export const 卒業式長期レンタル値引き表示名 = '卒業式着付けなし値引'

export const Enum試着写真 = {
  アップロード: {
    する: 1,
    しない: 2,
  },
} as const

export const Enum髪飾り = {
  // [プラン]小物
  種別: {
    単品購入: 5,
    お持込: 6,
  },
} as const

// NOTE: タブは分離したが、ここは特に分離する理由がないので同じにしておく
export const Enum振袖二尺袖 = {
  カタログ商品OR現品: {
    カタログ商品: 15,
    現品: 7,
  },
  裄補正: {
    する: 1,
    しない: 2,
  },
  袖補正: {
    する: 1,
    しない: 2,
  },
  価格選択: {
    二尺袖1: 1,
    二尺袖2: 2,
    二尺袖3: 3,
    振袖友の会成人式フル: 4,
    振袖成人式フル1: 5,
    振袖成人式フル2: 6,
    振袖初利用: 7,
  },
  // [商品分類]
  レンタルORカタログ: {
    レンタル: 7,
    カタログレンタル: 15,
  },
  誂え: {
    誂える: 1,
    別の商品に変更: 2,
  },
} as const

export const Enum割引 = {
  適用: {
    する: 1,
    しない: 2,
  },
} as const

export const Enum半巾帯_袴持込 = {
  種別: {
    // [プラン]小物
    単品レンタル単品購入: 5,
    お持込: 6,
  },
  形態: {
    セット内: 1,
    ランクアップ: 4,
    未定: 5,
  },
  商品選択: {
    四色帯: 1, // [プラン]小物 - セット内
    カタログ商品: 2,
    現品: 3,
  },
  // [単品価格の種類]
  見積表示: {
    単品レンタル価格: 1,
    単品購入価格: 2,
  },
} as const

export const Enum半巾帯_袴レンタル = {
  種別: {
    // [プラン]小物
    レンタル: 1,
    お持込: 6,
  },
  商品選択: {
    四色帯: 1,
    カタログ商品: 2,
    現品: 3,
  },
  // [プラン]小物
  形態: {
    セット内: 1,
    ランクアップ: 4,
  },
} as const

export const Enum袴 = {
  カタログ商品OR現品: {
    カタログ商品: 15,
    現品: 7,
  },
  種別: {
    // [商品分類]
    レンタル: 7,
    お持込: 3,
  },
  // [商品分類]
  レンタルORカタログ: {
    レンタル: 7,
    カタログレンタル: 15,
  },
  誂えますか: {
    誂える: 1,
    別の商品に変更する: 2,
  },
  価格選択: {
    レンタル1: 1,
    レンタル2: 2,
    レンタル3: 3,
    レンタル4: 4,
  },
  // [割引の種類] NOTE:"なし"はAPIには存在しないため、1 or 2をAPIに投げる
  袴割引: {
    割引金額: 2,
    割引率: 1,
    なし: 3,
  },
} as const

export const Enum重ね衿_着物持込 = {
  種別: {
    // //[プラン]小物
    単品レンタル単品購入: 5,
    お持込: 6,
  },
  商品情報単品レンタル: {
    レンタル1: 1,
    レンタル2: 2,
    レンタル3: 3,
  },
  商品情報単品購入: {
    購入1: 1,
    購入2: 2,
    購入3: 3,
  },
  見積表示: {
    単品レンタル価格: 1,
    単品購入価格: 2,
  },
} as const

export const Enum重ね衿_着物レンタル2個以上 = {
  種別: {
    // [プラン]小物
    単品レンタル単品購入: 5,
    お持込: 6,
  },
  形態: {
    // セット内+ランクアップ
    ランクアップ: 3,
    未定: 5,
  },
  商品情報単品レンタル: {
    レンタル1: 1,
    レンタル2: 2,
    レンタル3: 3,
  },
  商品情報単品購入: {
    購入1: 1,
    購入2: 2,
    購入3: 3,
  },
  // [単品価格の種類]
  見積表示: {
    単品レンタル価格: 1,
    単品購入価格: 2,
  },
} as const

export const Enum重ね衿_着物レンタル1個目 = {
  種別: {
    // [プラン]小物
    レンタル: 1,
    お持込: 6,
  },
  形態: {
    // [プラン]小物
    セット内: 1,
    ランクアップ: 4,
    未定: 5,
  },
  ランクアップレンタル: {
    レンタル1: 1,
    レンタル2: 2,
    レンタル3: 3,
  },
  // [単品価格の種類]
  見積表示: {
    単品レンタル価格: 1,
    単品購入価格: 2,
  },
} as const

export const Enum草履ブーツ_着物持込 = {
  // [品種区分]
  品目: {
    草履: 27,
    ブーツ: 42,
  },
  種別: {
    // [プラン]小物
    単品レンタル単品購入: 5,
    お持込: 6,
  },
  草履商品: {
    RZ1: 1,
    RZ2: 2,
    RZ3: 3,
    RZ4: 4,
    RZ5: 5,
    RZ6: 6,
    RZ7: 7,
    RZ8: 8,
    RZ9: 9,
    UZ1: 10,
    UZ2: 11,
    現品: 12,
  },
  // [単品価格の種類]
  見積表示: {
    単品レンタル価格: 1,
    単品購入価格: 2,
  },
  単品レンタル: {
    レンタル1: 1,
  },
  単品購入: {
    購入1: 1,
  },
} as const

export const Enum草履ブーツ_着物レンタル = {
  // [品種区分]
  品目: {
    草履: 27,
    ブーツ: 42,
  },
  種別: {
    // [プラン]小物
    レンタル単品購入: 1,
    お持込: 6,
  },
  形態: {
    // [プラン]小物
    セット内: 1,
    ランクアップ: 4,
  },
  草履商品: {
    RZ1: 1,
    RZ2: 2,
    RZ3: 3,
    RZ4: 4,
    RZ5: 5,
    RZ6: 6,
    RZ7: 7,
    RZ8: 8,
    RZ9: 9,
    UZ1: 10,
    UZ2: 11,
    現品: 12,
  },
  // [プラン]小物
  レンタルOR購入: {
    ランクアップレンタル: 4,
    単品購入: 5, // 購入は5:未定で送る
  },
  ランクアップレンタル: {
    レンタル1: 1,
  },
  単品購入: {
    購入1: 1,
  },
} as const

export const Enum別衿_着物レンタル = {
  種別: {
    // [プラン]小物
    レンタル: 1,
    お持込: 6,
  },
  形態: {
    // [プラン]小物
    セット内: 1,
    ランクアップ: 4,
  },
  // [追加料金の種類]
  長襦袢: {
    はい: 2,
    いいえ: 1,
  },
  ランクアップレンタル: {
    レンタル1: 1,
    レンタル2: 2,
  },
} as const

export const Enum別衿_着物持込 = {
  // [プラン]小物
  種別: {
    白無地: 1, // セット内
    単品レンタル単品購入: 3,
    お持込: 6,
  },
  // [追加料金の種類]
  長襦袢: {
    はい: 2,
    いいえ: 1,
  },
  レンタル: {
    レンタル1: 1,
    レンタル2: 2,
  },
  購入: {
    購入1: 1,
    購入2: 2,
  },
  // [単品価格の種類]
  見積表示: {
    単品レンタル価格: 1,
    単品購入価格: 2,
  },
} as const

export const Enum巾着バッグ_着物レンタル = {
  種別: {
    // [プラン]小物
    レンタル: 1,
    お持込: 6,
  },
  形態: {
    // [プラン]小物
    セット内: 1,
    ランクアップ: 4,
  },
  バッグ商品: {
    RB1: 1,
    RB2: 2,
    RB3: 3,
    RB4: 4,
    RB5: 5,
    RB6: 6,
    RB7: 7,
    MB8: 8,
    MB9: 9,
    MB10: 10,
    UB1: 11,
    UB2: 12,
    現品: 13,
  },
  ランクアップレンタル: {
    レンタル1: 1,
  },
} as const
export const Enum巾着バッグ_着物持込 = {
  種別: {
    // [プラン]小物
    単品レンタル単品購入: 1,
    お持込: 6,
  },
  形態: {
    // [プラン]小物
    セット内: 1,
    ランクアップ: 4,
  },
  バッグ商品: {
    RB1: 1,
    RB2: 2,
    RB3: 3,
    RB4: 4,
    RB5: 5,
    RB6: 6,
    RB7: 7,
    MB8: 8,
    MB9: 9,
    MB10: 10,
    UB1: 11,
    UB2: 12,
    現品: 13,
  },
  レンタル: {
    レンタル1: 1,
  },
  購入: {
    購入1: 1,
  },
  // [単品価格の種類]
  見積表示: {
    単品レンタル価格: 1,
    単品購入価格: 2,
  },
} as const

export const Enum紹介者特典 = {
  ご紹介者: {
    いる: 1,
    いない: 2,
  },
  //* [紹介者特典]
  ご紹介者様特典: {
    浴衣: 1,
    QUO: 2,
    写真: 4,
    引換券: 9,
    商品券: 5,
    未定: 6,
  },
  ご紹介者様お渡し: {
    済み: 1,
    未: 0,
  },
  //*  [紹介者特典]
  ご利用者様特典: {
    浴衣: 1,
    QUO: 2,
    写真: 4,
    引換券: 9,
    商品券: 5,
  },
  ご利用者様お渡し: {
    済み: 1,
    未: 0,
  },
} as const

export const Enumお写真プラン = {
  写真撮影: {
    する: 1,
    しない: 2,
  },
  商品選択: {
    デザインアルバム: 1,
    六切り台紙あり: 2,
    六切り台紙なし: 3,
    キャビネ台紙あり: 4,
    キャビネ台紙なし: 5,
    アクリルスタンド: 6,
  },
  // コースのデフォルトポーズ数
  デザインアルバム詳細: {
    スタンダード: 5,
    プレミアム: 15,
  },
  USB購入: {
    する: 1,
    しない: 2,
  },
  USB: {
    つける: 1,
    つけない: 2,
  },
  ポーズ数: {
    スタンダード: 5,
    プレミアム: 15,
  },
  冊数: 1,
  アクリルスタンド: {
    A4: 1,
    A5: 2,
  },
} as const

export const バッグ商品 = {
  RB01: 1,
  RB02: 2,
  RB03: 3,
  RB04: 4,
  RB05: 5,
  RB06: 6,
  RB07: 7,
  MB08: 8,
  MB09: 9,
  MB10: 10,
  UB1: 11,
  UB2: 12,
  現品: 13,
} as const

export const 草履商品 = {
  RZ01: 1,
  RZ02: 2,
  RZ03: 3,
  RZ04: 4,
  RZ05: 5,
  RZ06: 6,
  RZ07: 7,
  RZ08: 8,
  RZ09: 9,
  UZ1: 10,
  UZ2: 11,
  現品: 12,
} as const
