import { FormValues割引 } from '~/composable/estimate/viewModel/graduation/Fields/Fields割引'
import { Enum割引 } from '~/constants/estimate/graduationOptions'

//不明な点がある場合、READMEをご覧ください
export const getInitialStore = (): FormValues割引 => {
  return {
    waribiki適用: Enum割引.適用.しない,
    waribiki金額入力: null,
  }
}

const state = (): { fields: FormValues割引 } => ({
  fields: getInitialStore(),
})

export default state
