import { useStore, useContext, useRouter } from '@nuxtjs/composition-api'
import { AxiosResponse } from 'axios-jsonp-pro'
import { Store } from 'vuex'
import authRepository from '~/api/authRepository'

export const useAuth = () => {
  const store = useStore() as Store<any>
  const { $auth, $axios } = useContext()
  const router = useRouter()

  const authRepo = authRepository($axios)

  // ログインパラメータ確認
  const checkLogin = async (params: { email: string; password: string }) => {
    await authRepo.checkLogin(params)
  }

  // SMS認証コード確認
  const checkSmsCode = async (params: { code: string }) => {
    return await authRepo.checkSmsCode(params)
  }

  const validateOnVisibilitychange = () => {
    if (document.visibilityState === 'visible') {
      validateSession()
    }
  }

  // ログイン
  const login = async (user: object) => {
    const loginResponse = await $auth.loginWith('local', {
      data: user,
    })

    if (!loginResponse) {
      return
    }

    // NOTE: アプリのタブがactiveになった時にログイン状態出なければログアウトするeventListener
    window.addEventListener('visibilitychange', validateOnVisibilitychange)

    saveSessionLimitTime(loginResponse)

    fetchLoginUser()
  }

  // ログアウト
  const logout = async () => {
    window.removeEventListener('visibilitychange', validateOnVisibilitychange)
    $auth.logout()
    store.commit('LoginUser/delete')
    router.push('/login')
  }

  // ログイン情報をstoreに保持する
  const fetchLoginUser = () => {
    store.dispatch('LoginUser/setUser', $auth.user)
  }

  // ログインしているかどうかのチェック
  const validateSession = () => {
    const tokenExpiration = localStorage.getItem('token_expiration')
    if (!tokenExpiration) {
      logout()
      return
    }

    const expiration = Number(tokenExpiration)

    // 現在時刻をミリ秒単位で取得
    const now = Date.now()

    // 有効期限が現在時刻より前であればログアウト
    if (expiration && now > expiration) {
      logout()
      alert(
        'ログアウトされました。\nログインの有効期間は毎日AM3:00にリセットされます。\n再度ログインし直してください。'
      )
    }
  }

  // ログインの有効期限をlocalStorageへ保存
  const saveSessionLimitTime = (loginResponse: AxiosResponse) => {
    const expiresInMinutes = loginResponse?.data?.expires_in // サーバーから返されるexpires_in（分）
    if (!expiresInMinutes) {
      return
    }

    const expiresInMilliseconds = expiresInMinutes * 60 * 1000 // ミリ秒単位に変換

    // 現在時刻にexpires_inを加えて有効期限を計算
    const expiration = Date.now() + expiresInMilliseconds

    // localStorageに保存
    localStorage.setItem('token_expiration', expiration.toString())
  }

  return {
    checkLogin,
    checkSmsCode,
    login,
    logout,
    validateSession,
  }
}
