import { MutationTree } from 'vuex'
import { FurisodeState } from '../../../types'

const mutations: MutationTree<FurisodeState> = {
  setSelection: (state, selection) => {
    state.selections = selection
  },

  setProduct: (state, product) => {
    state.product = product
  },

  setInput: (state, input) => {
    state.input = input
  },

  setPrice: (state, price) => {
    state.price = price
  },

  setContractId: (state, contractId) => {
    state.contractId = contractId
  },

  setIsFree: (state, isFree) => {
    state.isFree = isFree
  },

  setIsFurisodeOnHold: (state, isFurisodeOnHold) => {
    state.isFurisodeOnHold = isFurisodeOnHold
  },

  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },

  setCatalogType: (state, catalogType) => {
    state.catalogType = catalogType
  },

  setIsOrderOutsideTargetYear: (state, isOrderOutsideTargetYear) => {
    state.isOrderOutsideTargetYear = isOrderOutsideTargetYear
  },

  setProperProductId: (state, properProductId) => {
    state.properProductId = properProductId
  },

  deleteProduct: (state) => {
    state.product = null
  },

  deleteInput: (state) => {
    state.input = {
      productName: '',
      rentalPrice: 0,
    }
    state.isFree = false
  },

  deletePrice: (state) => {
    state.price = {
      buy: 0,
      rental: 0,
    }
  },

  deleteProperProductId: (state) => {
    state.properProductId = null
  },

  delete: (state) => {
    state.selections = [0, 0, 0]
    state.product = null
    state.input = {
      productName: '',
      rentalPrice: 0,
    }
    state.price = {
      buy: 0,
      rental: 0,
    }
    state.isFree = false
    state.isValidate = null
    state.isFurisodeOnHold = false // 振袖を保留にするか否か
    state.catalogType = null
    state.contractId = 0
    state.isOrderOutsideTargetYear = false
    state.properProductId = null
  },
}

export default mutations
