import { GetterTree } from 'vuex'
import { RootState, settingShop } from '../types'

const getters: GetterTree<settingShop, RootState> = {
  getShop: (state: settingShop) => {
    return state.shop
  },
}

export default getters
