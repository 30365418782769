import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.locale('ja')
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

/**
 * 日付をフォーマットするクラス
 */
class DateTime {
  #date: Date
  constructor(date?: Date | string | null) {
    if (date instanceof Date) {
      this.#date = date
    } else if (typeof date === 'string') {
      const safariSupportDate = DateTime.formatSafariSupport(date)
      if (this.#isDate(safariSupportDate)) {
        this.#date = new Date(safariSupportDate)
      } else {
        this.#date = new Date(date)
      }
    } else {
      // NOTE: 無効なフォーマットであっても、日付を設定する。
      this.#date = new Date()
    }
  }

  #isDate = (v: string | number) => !isNaN(new Date(v).getTime())

  /**
   * @returns yyyy年mm月dd日
   */
  toJpString = () => {
    return `${this.#date.getFullYear()}年${
      this.#date.getMonth() + 1
    }月${this.#date.getDate()}日`
  }

  /**
   * @returns yyyy年mm月dd日（曜日）
   */
  toJpStringWithWeek = () => {
    const week = ['日', '月', '火', '水', '木', '金', '土']
    const dayOfWeek = week[this.#date.getDay()]
    if (this.#date === undefined || dayOfWeek === undefined) return 'ー'
    return `${this.toJpString()}（${dayOfWeek}）`
  }

  /**
   * 時間のみ所得する
   * @returns hh:mm
   */
  toOnlyTime = () => {
    const localeString = this.#date.toLocaleString('ja-JP').split(' ')
    // hh:mm:ssの場合はssを取り除く
    if (localeString[1].match(/^[0-9]{1,2}:[0-9]{2}:[0-9]{2}$/)) {
      const [hh, mm, _ss] = localeString[1].split(':')
      return hh.length === 1 ? `0${hh}:${mm}` : `${hh}:${mm}`
    }

    return localeString[1]
  }

  /**
   * @returns YYYY-MM-DD
   */
  toDateString = () => {
    return dayjs(this.#date).tz().format('YYYY-MM-DD')
  }

  /**
   * @returns YYYY-MM-DD HH:mm:ss
   */
  toDateStringWithTime = () => {
    return dayjs(this.#date).tz().format('YYYY-MM-DD HH:mm')
  }

  /**
   * @returns YYYY-MM-DD HH:mm:ss
   */
  toDateTimeString = () => {
    return dayjs(this.#date).tz().format('YYYY-MM-DD HH:mm:ss')
  }

  /**
   * 指定した日数を加算した日を返す
   * @returns YYYY-MM-DD
   */
  afterDay = (day: number) => {
    return dayjs(this.#date).tz().add(day, 'd').format('YYYY-MM-DD')
  }

  /**
   * @returns yyyymmdd_hhmmss
   */
  toDateName = () => {
    return dayjs(this.#date).tz().format('YYYYMMDD_HHmmss')
  }

  /**
   *
   * @returns YYYY/MM/DD HH:mm:ss
   */
  static formatSafariSupport = (v: string) => {
    return v.toString().replaceAll('-', '/')
  }
}

export default DateTime
