import { Ref, ref, useRoute } from '@nuxtjs/composition-api'
import {
  TabNaviState,
  ValidatedTab,
} from '~/store/Estimate/GraduationCeremony/types'
import { useGraduateStore } from '../../repository/GraduationStore'
import { TabNaviItem } from './TabNavigationItem'

export const useTabValidation = () => {
  /*
   *constractor
   */
  const tabNavi = useGraduateStore<TabNaviState>('tabNavi')
  const route = useRoute()
  const storedValidatedTabs = tabNavi.get<'validatedTabs'>('validatedTabs')
  const validatedTabs = ref<ValidatedTab[]>(storedValidatedTabs.value)

  /*
   * タブ移動でバリデーション情報をvalidatedTabsに更新していく関数
   */
  const updateTabValidate = (currentUrl?: string) => {
    const isNextButton = tabNavi.get<'isNextButton'>('isNextButton')
    const url = !!currentUrl ? currentUrl : route.value.path
    const trimUrl = url.slice(url.indexOf('tab/'))
    const resultValidate = isNextButton.value ? 'valid' : 'invalid'

    const isAlreadyValidatedTab = storedValidatedTabs.value.some((tab) =>
      tab.url?.includes(trimUrl)
    )
    if (isAlreadyValidatedTab) {
      validatedTabs.value = storedValidatedTabs.value.map((tab) => {
        if (tab.url?.includes(trimUrl)) {
          tab.isValid = resultValidate
        }
        return tab
      })
      tabNavi.set('validatedTabs', validatedTabs.value)
    } else {
      //* タブが存在しない場合の条件
      validatedTabs.value.push({
        url: trimUrl,
        isValid: resultValidate,
      })
      tabNavi.set('validatedTabs', validatedTabs.value)
    }
  }

  /**
   * コピーして別見積作成時や、既存見積の復元の際に
   * 一気に全てのタブを合格にする
   */
  const setAllTabsValid = (tabs: Ref<TabNaviItem[]>) => {
    tabs.value = tabs.value.map((tab) => {
      return {
        ...tab,
        isValid: 'valid',
      }
    })
    validatedTabs.value = tabs.value.map((tab) => {
      return {
        url: tab.url,
        isValid: 'valid' as 'valid',
      }
    })
    tabNavi.set<'validatedTabs'>('validatedTabs', validatedTabs.value)
    tabNavi.set('tabs', tabs.value)
    tabNavi.set<'isAllValidateTabs'>('isAllValidateTabs', true)
  }

  /**
   * 一つのタブのバリデーションを操作する
   * @param url 変更したいタブのURL。トリミングされるので見積番号の部分は0もでOK
   * @param valid 合格させたければtrue, 不合格にしたければfalseを渡す
   */
  const setTabValid = (url: string, valid: boolean) => {
    const trimUrl = url.slice(url.indexOf('tab/'))
    const resultValidate = valid ? 'valid' : 'invalid'
    validatedTabs.value = validatedTabs.value.map((tab) => {
      if (tab.url?.includes(trimUrl)) {
        return {
          ...tab,
          isValid: resultValidate as 'valid' | 'invalid',
        }
      }
      return tab
    })
    tabNavi.set<'validatedTabs'>('validatedTabs', validatedTabs.value)
  }

  return { updateTabValidate, setAllTabsValid, setTabValid }
}
