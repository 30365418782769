import { FormValuesお写真プラン } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsお写真プラン'

// 不明な点がある場合、READMEをご覧ください
export const getInitialStore = (): FormValuesお写真プラン => {
  return {
    oshashinPlan撮影: null,
    oshashinPlan商品選択: null,
    oshashinPlanデザインアルバム: null,
    oshashinPlan紹介者特典ポーズ数_アルバム: null,
    oshashinPlan六切り台紙あり: [{ pose: null, book: null }],
    oshashinPlan六切り台紙なし: null,
    oshashinPlanキャビネ台紙あり: [{ pose: null, book: null }],
    oshashinPlanキャビネ台紙なし: null,
    oshashinPlanアクリルスタンドA5: 0,
    oshashinPlanアクリルスタンドA4: 0,
    oshashinPlanUSB: null,
  }
}

const state = (): { fields: FormValuesお写真プラン } => ({
  fields: getInitialStore(),
})

export default state
