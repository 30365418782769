import {
  useContext,
  useRoute,
  useRouter,
  useStore,
} from '@nuxtjs/composition-api'
/** utils */
import { ESTIMATE } from '~/constants/estimate/config'
/** api */
import estimateRepository from '~/api/estimateRepository'
/** composable */
import { useEstimateTabList } from '@/composable/headerTab/useEstimateTabList'
import { useEstimateTypeFlags } from '~/composable/estimate/domain/EstimateTypeFlags'
import { useRestore } from '~/features/singleItemService/service/restore/useRestore'
import { ALERT_TEXT } from '~/constants/contract'

/**
 * 契約書フローからの見積変更をハンドリングするための関数
 * @param initEstimateId 「お客様詳細 → 契約書詳細」の場合に設定する　※暫定対応
 * @returns
 */
export const useEstimateEdit = (initEstimateId?: number) => {
  const { in成人式見積 } = useEstimateTypeFlags()
  const route = useRoute()
  const router = useRouter()
  const store = useStore()
  const { $axios } = useContext()
  const estimateRepo = estimateRepository($axios)
  const { findInvalidTab } = useEstimateTabList()
  const { restore: restore単品サービス } = useRestore('contractForEstimate')
  const loginShopId: number = store.getters['SettingShop/getShop']?.id

  const estimateId =
    initEstimateId ||
    Number(route.value.params?.estimate_id) ||
    Number(route.value.params?.id)
  const isEdit = route.value.query['mode'] === 'edit' || estimateId > 0
  const isEditMode = route.value.query['mode'] === 'edit'
  const contractId = route.value.query['contract_id']

  const query = {
    ...(estimateId > 0 || isEdit ? { mode: 'edit' } : {}),
    ...(contractId
      ? {
          contract_id: contractId,
        }
      : {}),
  }

  // 「変更」ボタン押下時
  const changeClickEvent = () => {
    const estimateTypePath = in成人式見積.value
      ? 'comingOfAgeCeremony'
      : 'graduationCeremony'
    router.push({
      path: `/estimate/${estimateTypePath}/${estimateId}/confirmationContract/${estimateId}`,
      query,
    })
  }

  /**
   * 「入力内容確認に戻る」処理
   * 編集中に未入力の項目がある場合は遷移させない
   * @returns
   */
  const handleClickNextButton = () => {
    const estimateTypePath = in成人式見積.value
      ? 'comingOfAgeCeremony'
      : 'graduationCeremony'
    const confirmationContractPath = `/estimate/${estimateTypePath}/${estimateId}/confirmationContract/${estimateId}`

    // 既に入力内容確認にいる場合は見積一覧ページに遷移する
    if (confirmationContractPath === route.value.path) {
      const estimateListContractPath = `/estimate/${estimateTypePath}/${estimateId}/estimateListContract/${estimateId}`
      router.push({
        path: estimateListContractPath,
        query: {
          mode: 'edit',
          contract_id: query.contract_id,
        },
      })
      return
    }

    if (!isEdit) {
      router.push({
        path: confirmationContractPath,
        query: {
          contract_id: query.contract_id,
        },
      })
      return
    }

    const tabs = findInvalidTab()
    if (
      tabs.length > 0 &&
      router.currentRoute.path.split('/').includes('tab')
    ) {
      const tabNames = tabs.map(({ text }) => text).join()
      alert(tabNames + 'に未入力の項目があります。')
      return
    }

    router.push({
      path: confirmationContractPath,
      query: {
        mode: 'edit',
        contract_id: query.contract_id,
      },
    })
  }

  /**
   * 見積確認に遷移
   * @param contractType 1: 成人式, 2: 卒業式
   */
  const goToConfirmationContract = (contractType: number) => {
    if (contractType === ESTIMATE.成人式.id) {
      router.push({
        path: `/estimate/comingOfAgeCeremony/${estimateId}/confirmationContract/${estimateId}`,
        query,
      })
    }

    const isEditMode = !Number.isNaN(estimateId)

    if (contractType === ESTIMATE.卒業式.id) {
      router.push({
        path: isEditMode
          ? `/estimate/graduationCeremony/${estimateId}/confirmationContract/${estimateId}`
          : '/estimate/graduationCeremony/0/confirmation',
        query: isEditMode ? query : {},
      })
    }
  }

  /**
   * 見積一覧に戻る
   * - 顧客詳細から
   * @param contractType 1: 成人式, 2: 卒業式
   */
  const goToEstimateListContract = (contractType: number) => {
    if (contractType === ESTIMATE.成人式.id) {
      router.push({
        path: `/estimate/comingOfAgeCeremony/${estimateId}/estimateListContract/${estimateId}`,
      })
    }

    if (contractType === ESTIMATE.卒業式.id) {
      router.push({
        path: `/estimate/graduationCeremony/${estimateId}/estimateListContract/${estimateId}`,
      })
    }
  }

  /**
   * 見積変更フローを始める
   */
  const doEstimateEditFlow = async (
    nextPageType: string,
    target: {
      estimateId: number
      branchNumber: number
    }
  ) => {
    const { estimateId, branchNumber: version } = target
    const { data: estimateInfo } = await estimateRepo.show(estimateId, version)

    if (!estimateInfo) {
      alert('見積データが存在しません')
      return
    }

    // ログイン店舗と見積作成した店舗が異なる場合は、アラートを表示
    if (estimateInfo.shop_id !== loginShopId) {
      alert(ALERT_TEXT.DIFFERENT_SHOPID_MSG)
      return
    }

    if (nextPageType === 'detail') {
      router.push({
        path: `/userInfo/${estimateInfo.customer_id}/estimate/${estimateInfo.estimate_id}`,
        query: {
          type: String(estimateInfo.type),
          from: 'search',
        },
      })
      return
    }

    if (estimateInfo.type === ESTIMATE.単品.id) {
      await restore単品サービス(estimateId)
      router.push(
        `/contracts/singleItemService/0/main?estimate_id=${estimateId}`
      )
      return
    }

    await store.dispatch('Contracts/BaseEstimate/setEstimate', estimateInfo)
    switch (estimateInfo.type) {
      case ESTIMATE.成人式.id:
        router.push({
          path: `/estimate/comingOfAgeCeremony/${estimateInfo.estimate_id}/estimateListContract/${estimateInfo.estimate_id}`,
          query: { branch: String(version) },
        })
        break
      case ESTIMATE.卒業式.id:
        router.push({
          path: `/estimate/graduationCeremony/${estimateInfo.estimate_id}/estimateListContract/${estimateInfo.estimate_id}`,
          query: { branch: String(version) },
        })
        break
      default:
        alert('見積書の取得に失敗しました')
    }
  }

  const goToEstimateTab = (path: string) => {
    router.push({
      path,
      query,
    })
  }

  const goToTab = (path: string) => {
    router.push(path)
  }

  const rewritePathWithQuery = (path: string) => {
    const queryString = path.split('?')[1]
    const existQuery = [...new URLSearchParams(queryString).entries()].reduce(
      (obj, e) => ({ ...obj, [e[0]]: e[1] }),
      {}
    )

    const margedQuery = {
      ...query,
      ...existQuery,
    }

    return (
      path.split('?')[0] +
      '?' +
      Object.entries(margedQuery)
        .map((e) => `${e[0]}=${e[1]}`)
        .join('&')
    )
  }

  return {
    rewritePathWithQuery,
    doEstimateEditFlow,
    changeClickEvent,
    handleClickNextButton,
    goToEstimateListContract,
    goToConfirmationContract,
    goToEstimateTab,
    goToTab,
    estimateId,
    isEdit,
    isEditMode,
    contractId,
  }
}
