import { MutationTree } from 'vuex'
import { settingShop } from '../types'

const mutations: MutationTree<settingShop> = {
  setShop: (state, shop) => {
    state.shop = shop
  },

  delete: (state) => {
    state.shop = null
  }
}

export default mutations
