// ** types **
import type { SingleItem } from '~/api/singleItemRepository'

export type ProductNameList =
  | '帯'
  | '帯締め'
  | '帯揚げ'
  | '重ね衿'
  | '別衿'
  | 'ショール'
  | '草履バッグ'
  | '草履'
  | 'バッグ'
  | '髪飾り'
  | '長襦袢購入お誂え正絹'
  | '長襦袢レンタル仕立て上がり交織'
  | '呉服箱'
  | '留袖'
  | '喪服'
  | 'その他商品'
  | '帯枕'
  | '帯板'
  | '衿芯'
  | '伊達〆'
  | 'コーリンベルト'
  | '三重紐'
  | '腰紐'
  | '肌着'
  | '足袋'
  | 'タオル'

export type State = {
  [K in ProductNameList]: SingleItem[]
}

export const initialState = {
  帯: [],
  帯締め: [],
  帯揚げ: [],
  重ね衿: [],
  別衿: [],
  ショール: [],
  草履バッグ: [],
  草履: [],
  バッグ: [],
  髪飾り: [],
  長襦袢購入お誂え正絹: [],
  長襦袢レンタル仕立て上がり交織: [],
  呉服箱: [],
  留袖: [],
  喪服: [],
  その他商品: [],
  帯枕: [],
  帯板: [],
  衿芯: [],
  伊達〆: [],
  コーリンベルト: [],
  三重紐: [],
  腰紐: [],
  肌着: [],
  足袋: [],
  タオル: [],
}

const state = (): State => ({ ...initialState })

export default state
