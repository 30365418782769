import { ActionTree } from 'vuex'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { RootState, ContractsPhotoSettings, PhotoSetting } from '../../types'
import { isAxiosError } from '~/utils/exception'

const actions: ActionTree<ContractsPhotoSettings, RootState> = {
  upload: async (
    { commit },
    {
      settings,
      $axios,
      customerId,
    }: {
      settings: PhotoSetting[]
      $axios: NuxtAxiosInstance
      customerId: string
    }
  ) => {
    // ②get photo url from VV server
    const fileUpload = async (
      formData: FormData,
      settingId: number,
      photoId: number
    ): Promise<string> => {
      try {
        const res = await $axios.post<Array<{ url: string }>>(
          'photo/upload',
          formData,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        const remoteSrc = res.data
        commit('addRemoteSrc', { remoteSrc, settingId, photoId })
        console.log('API success ', res.data)
        return remoteSrc[0].url
      } catch (error) {
        if (isAxiosError(error)) {
          console.error(error.response)
          const errorMsg = String(error.response?.status)
          return errorMsg
        }
        return ''
      }
    }
    // ①identify photo that is changed or uploaded
    return await new Promise<string>((resolve, reject) => {
      settings.forEach((setting) => {
        setting.photos.forEach((photo) => {
          if (photo.uploaded === false) {
            const settingId = setting.id
            const photoId = photo.photoId
            const value = true
            commit('updateUploaded', { settingId, photoId, value })
            const customer_id = String(customerId)
            const formData = new FormData()
            formData.append('files[]', photo.file)
            formData.append('customer_id', customer_id)
            fileUpload(formData, settingId, photoId).then(resolve).catch(reject)
          }
        })
      })
    })
  },

  setIsValidate: ({ commit }, isValidate: boolean) => {
    commit('setIsValidate', isValidate)
  },
}

export default actions
