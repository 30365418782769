import { computed, Ref } from '@nuxtjs/composition-api'

// ** enums **
import ProductClassification from '~/constants/enums/productClassification'
import { ClothingSizeType } from '~/constants/enums/clothingSizeType'
import { SingleItemPlan } from '~/constants/enums/singleItemPlan'
import { PRODUCT_SETTING } from '../enums'
import { ItemPriceType } from '~/constants/enums/itemPlan'

// ** types **
import type { SingleItem } from '~/api/singleItemRepository'
import type { SingleItem as SingleItemForInput } from '~/store/SingleItemService/Main/state'

// ** composable **
import { usePrice } from '~/composable/general/Price'

export const 現品 = {
  id: PRODUCT_SETTING.PRODUCTS.現品,
  text: '現品',
  value: '',
} as const

export const こちらでご準備いたします = {
  id: PRODUCT_SETTING.PRODUCTS.こちらでご準備いたします,
  text: 'こちらでご準備いたします（取り寄せ）',
  value: '',
} as const

const 未設定 = {
  id: PRODUCT_SETTING.SIZES.未設定,
  text: '未設定',
} as const

export const useSingleItemOption = (
  singleItemForInput: Ref<SingleItemForInput>,
  singleItems: Ref<SingleItem[]>,
  selectedProducts: Ref<SingleItem['products']>,
  selectedProduct: Ref<SingleItem['products'][number] | null>,
  selectedPrices: Ref<
    SingleItem['products'][number]['price_informations'][number]['prices']
  >
) => {
  const プランを選択してください = computed(() => {
    const planIdList = singleItems.value.map(({ plan_id }) => plan_id)
    if (planIdList.some((planId) => typeof planId !== 'number')) return []

    // NOTE: 上記でplanIdがあることが保証されているため
    return planIdList.map((planId) => ({
      id: planId!,
      text: SingleItemPlan.toWord(planId!),
    }))
  })

  const 商品選択 = computed(() => {
    const products = selectedProducts.value
      .filter(({ product_id, product_name }) => !!product_id && !!product_name)
      .map(({ product_id, product_name }) => ({
        id: product_id,
        text: product_name,
        value: '',
      }))

    if (
      !!selectedProducts.value.length &&
      selectedProducts.value.some(({ product_name }) => product_name === null)
    ) {
      const 現品id = Number(
        `${現品.id}${singleItemForInput.value.productTypeId}`
      )
      products.push({ ...現品, id: 現品id })
    }

    return singleItemForInput.value.productTypeId ===
      ProductClassification.zoriAndBag
      ? [...products, こちらでご準備いたします]
      : [...products]
  })

  const サイズを選択してください = computed(() => {
    if (singleItemForInput.value.productTypeId === ProductClassification.zori) {
      return Object.values(ClothingSizeType.shoesSizeLookupTable).map(
        (sizeType) => ({
          id: sizeType,
          text: ClothingSizeType.toWord(sizeType),
        })
      )
    } else {
      if (
        !selectedProduct.value?.price_informations?.length ||
        selectedProduct.value.price_informations.length < 2
      ) {
        return []
      }

      return selectedProduct.value.price_informations.map(
        ({ product_size_id }) => {
          return typeof product_size_id === 'number'
            ? {
                id: product_size_id,
                text: ClothingSizeType.toWord(product_size_id),
              }
            : 未設定
        }
      )
    }
  })

  const { paramToString } = usePrice()

  const 価格選択 = computed(() => {
    if (selectedPrices.value.length < 2) return []

    const sortedPrices = [...selectedPrices.value].sort(
      (a, b) => a.order_number - b.order_number
    )

    return sortedPrices.map(
      ({ order_number, rental_price, purchase_price }) => ({
        id: order_number,
        rental: paramToString(rental_price ?? 0),
        purchase: paramToString(purchase_price ?? 0),
      })
    )
  })

  const options = computed(() => ({
    プランを選択してください: プランを選択してください.value,
    商品選択: 商品選択.value,
    サイズを選択してください: サイズを選択してください.value,
    価格選択: 価格選択.value,
    帯仕立て代が必要ですか: [
      {
        id: PRODUCT_SETTING.YES_OR_NO.はい,
        text: 'はい',
        subText1: `帯仕立て代 ${singleItemForInput.value.帯仕立て代 ?? '¥0'}`,
      },
      {
        id: PRODUCT_SETTING.YES_OR_NO.いいえ,
        text: 'いいえ',
      },
    ],
    別衿の縫い付けが必要ですか: [
      {
        id: PRODUCT_SETTING.YES_OR_NO.はい,
        text: 'はい',
        subText1: `別衿加工代 ${singleItemForInput.value.別衿加工代 ?? '¥0'}`,
      },
      {
        id: PRODUCT_SETTING.YES_OR_NO.いいえ,
        text: 'いいえ',
      },
    ],
    どちらを選びますか: [
      {
        id: ItemPriceType.rentalTanpin,
        text: '単品レンタル',
      },
      {
        id: ItemPriceType.buyTanpin,
        text: '単品購入',
      },
    ],
    お渡し状況: [
      {
        id: PRODUCT_SETTING.DELIVERY_STATUS.お渡し済み,
        text: 'お渡し済み',
      },
      {
        id: PRODUCT_SETTING.DELIVERY_STATUS.未,
        text: '未',
      },
    ],
  }))

  return {
    options,
  }
}
