import { computed, Ref } from '@nuxtjs/composition-api'
import { Profile } from '~/composable/api/usePersonal'
import { EstimateDetail } from '~/api/estimateRepository'
import { VisitMotive } from '~/store/types'

export const useRestateCustomer = (
  profile: Ref<Profile>,
  estimateInfo: Ref<EstimateDetail | undefined>,
  visitMotives: Ref<{ id: number; name: string }[]>
) => {
  return computed(() => {
    if (!estimateInfo.value) return null

    // 見積詳細から来店動機を抽出
    let visitingReasons: VisitMotive[] = []
    if (!estimateInfo.value.visiting_reason) {
      visitingReasons = []
    } else if (!Array.isArray(estimateInfo.value.visiting_reason)) {
      // TODO: API修正前のオブジェクトが来ていた際の対応用。リリース後は消してOK
      const singleItem = estimateInfo.value.visiting_reason as string
      const selectedReason = visitMotives.value.find(
        (v) => v.id === Number(singleItem)
      )
      if (selectedReason) {
        visitingReasons = [selectedReason]
      }
    } else {
      visitingReasons = estimateInfo.value.visiting_reason.map((id) => {
        return (
          visitMotives.value.find((v) => v.id === id) ?? { id: null, name: '' }
        )
      })
    }

    return {
      profile: profile.value,
      fullName: `${profile.value.last_name}${profile.value.first_name}`,
      visiting_reason: visitingReasons,
    }
  })
}
