import { MutationTree } from 'vuex'
import { ref } from '@vue/composition-api'
import { getInitialStore, State, PaymentDetail } from './state'
import { PAYMENT_METHOD } from '~/features/contracts/paymentEnums'

const mutations: MutationTree<State> = {
  // ** All Input Data **
  setData: (state, data) => {
    state.data = data
  },

  // ** タブ **
  setTabList: (state, tabList) => {
    state.tabList = tabList
  },
  // ** 最終ページ **
  setFinalPageList: (state, finalPageList) => {
    state.finalPageList = finalPageList
  },
  // ** 契約金額合計 **
  setContractTotalPrice: (state, contractTotalPrice) => {
    state.data.contractTotalPrice = contractTotalPrice
  },
  // ** 撤廃した足袋が含まれているか **
  setHasOldTypeTabi: (state) => {
    state.data.accessories.hasOldTypeTabi
  },

  initAll: (state) => {
    const initial = getInitialStore()
    state.data = initial.data
    state.tabList = initial.tabList
    state.finalPageList = initial.finalPageList
  },
  // ** 支払い時友の会ポイント **
  initTomonokaiPoint: (_, paymentDetails: PaymentDetail[]) => {
    const point = paymentDetails.find(
      (detail) => detail.methods === PAYMENT_METHOD.POINT
    )
    const familyPoint = paymentDetails.find(
      (detail) => detail.methods === PAYMENT_METHOD.FAMILY_POINT
    )

    const initPoint = getInitialStore().data.payment.details.find(
      (detail) => detail.methods === PAYMENT_METHOD.POINT
    )
    const initFamilyPoint = getInitialStore().data.payment.details.find(
      (detail) => detail.methods === PAYMENT_METHOD.FAMILY_POINT
    )

    if (point && familyPoint && initPoint && initFamilyPoint) {
      const pointIndex = paymentDetails.indexOf(point)
      const familyPointIndex = paymentDetails.indexOf(familyPoint)

      paymentDetails[pointIndex] = initPoint
      paymentDetails[familyPointIndex] = initFamilyPoint
    }
  },
}

export default mutations
