



































































































import { defineComponent, PropType, watch } from '@nuxtjs/composition-api'
import { PRODUCT_SETTING } from '../enums'

export type Selection = {
  id: number
  rental: string
  purchase: string
}

export const FREE_INPUT = {
  id: 0,
  rental: '',
  purchase: '',
} as const

export default defineComponent({
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    selections: {
      type: Array as PropType<Selection[]>,
      required: true,
    },
    selectedId: {
      type: Number,
      default: null,
    },
    orderNumber: {
      type: Number,
      required: false,
      default: 1,
    },
    productTypeId: {
      type: Number,
      required: false,
      default: 0,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    // 重ね衿にて、単品レンタル価格（¥5,000）を選択している状態で単品レンタルに切り替えた場合はpriceの初期化を行う
    watch(
      () => props.isDisabled,
      (newVal) => {
        if (
          newVal &&
          props.productTypeId === PRODUCT_SETTING.PRODUCT_ID.重ね衿 &&
          props.selectedId &&
          props.selectedId === 1
        ) {
          emit('select', { id: null, purchase: '', rental: '' })
        }
      }
    )

    return {
      FREE_INPUT,
      PRODUCT_SETTING,
    }
  },
})
