import { DeliveryDateState } from '../../types'

const state = (): DeliveryDateState => ({
  selectedDeliveryType: 0,
  input: {
    date: '',
    type: 'custom',
  },
  isValidate: null,
})

export default state
