import { computed, ComputedRef, Ref } from '@nuxtjs/composition-api'
import { EstimateDetail, Items } from '~/api/estimateRepository'
import ProductId from '~/constants/enums/productClassification'
import { useRestatePlan } from '../RestatePlan'
import {
  getOptionIdOf単品選択,
  getOptionIdOf形態,
  getOptionIdOf振袖レンタルの場合,
  getOptionIdOf種別,
  getConditionalFlags,
} from './Restateバッグ1個目'
import {
  GEINPIN,
  UNDECIDED,
} from '~/composable/estimate/viewModel/comingOfAge/FieldProps/FieldProps草履バッグ'
import { usePPZoriBagPlan } from '~/composable/general/PPDiscount/usePPZoriBagPlan'

/**
 * 基本的な構造や、商品リスト以外の選択肢のidはバッグと同じため共有している
 */
export const useRestate草履1個目 = (
  estimate: Ref<EstimateDetail | undefined>,
  isPPDiscount: ComputedRef<boolean | undefined>
) => {
  const { getZoriBagPPAddingPrice } = usePPZoriBagPlan()
  const restateプラン = useRestatePlan(estimate)

  return computed(() => {
    if (!estimate.value?.items || !restateプラン.value) {
      return null
    }
    // NOTE:最初の一個だけをここでは処理する
    const zoriItem = estimate.value.items.find(
      (item) =>
        item.product_type_id === ProductId.zori && item.order_number === 1
    )

    if (!zoriItem) {
      return null
    }

    const { isPPAddingPrice } = getZoriBagPPAddingPrice(
      zoriItem,
      isPPDiscount.value
    )

    const {
      rental_price,
      purchase_price,
      plan,
      premium_rental_price,
      premium_purchase_price,
      price_type,
      product_name,
    } = zoriItem

    const { flags: planFlags } = restateプラン.value
    const flags = getConditionalFlags(
      zoriItem,
      planFlags,
      isPPDiscount.value ?? false,
      isPPAddingPrice
    )
    const selected商品 = getOptionOf商品選択(zoriItem, flags)

    return {
      optionId種別: getOptionIdOf種別(plan, planFlags),
      optionId形態: getOptionIdOf形態(plan),
      optionId商品: selected商品?.id ?? 0,
      selected商品,
      商品名: product_name ?? '',
      optionId振袖レンタルの場合: getOptionIdOf振袖レンタルの場合(
        zoriItem,
        planFlags
      ),
      optionId単品選択: getOptionIdOf単品選択(price_type),
      /**
       * WARNING! スーパーイレギュラー対応!!
       * フルセット時の草履・バッグで「振袖レンタルの場合」の質問に「同じ商品を単品購入」と答えた時、
       * 「プラン内の購入価格/PP購入価格/単品購入価格」全てを保持する必要が生じるが、
       * 購入金額のために用意されているパラメータが足りない、かつ根本的な解決が現状難しいため、
       * 現状は併用される可能性のない"rental_price"に「単品購入価格」を格納しています。。。
       */
      単品購入価格: flags.is同じ商品単品購入
        ? Number(rental_price)
        : Number(purchase_price ?? 0),
      レンタル価格: flags.is同じ商品単品購入 ? 0 : Number(rental_price),
      プレミアムレンタル価格: Number(premium_rental_price ?? 0),
      購入価格: Number(purchase_price ?? 0),
      プレミアム購入価格: Number(premium_purchase_price ?? 0),
      flags,
    }
  })
}

const getOptionOf商品選択 = (
  item: Items,
  flags: ReturnType<typeof getConditionalFlags>
) => {
  if (item.product_name === GEINPIN.product_name) return GEINPIN

  if (!flags.is持込 && item.product_name?.includes('こちらでお選び')) {
    return UNDECIDED
  }

  if (!item.product_id || !item.product_name) return null

  // NOTE: nameは`U-1 草履(ご購入用)`のようになっているため
  const splittedItemName = item.product_name.split(/\s+/, 2)
  return {
    id: item.product_id,
    post_number_code: splittedItemName[0],
    product_name: splittedItemName[1],
    price: {
      rental: item.rental_price,
      buy: item.purchase_price ?? 0,
      set_purchase: flags.isセット内 ? 0 : undefined,
    },
  }
}
