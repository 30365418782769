import { ActionTree } from 'vuex'
import { RootState, GraduationCeremonyState } from '../../types'

const actions: ActionTree<GraduationCeremonyState, RootState> = {
  delete: ({ commit }) => {
    commit('delete')
  },
  setState: (
    { commit },
    {
      customerId,
      dressing,
      ceremonyInfo,
      pass,
      delivery,
      returnInfo,
      dressingPlaces,
      isValidate,
    }
  ) => {
    commit('setCustomerId', customerId)
    commit('setDressing', dressing)
    commit('setCeremonyInfo', ceremonyInfo)
    commit('setPass', pass)
    commit('setDelivery', delivery)
    commit('setReturnInfo', returnInfo)
    commit('setDressingPlaces', dressingPlaces)
    commit('setIsValidate', isValidate)
  },
}

export default actions
