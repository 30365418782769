import { ServicePlanState } from '../../../types'

const state = (): ServicePlanState => ({
  /**
   * NOTE: selections = patternLine
   * ↓保持される選択肢のindex (「フルセット・持込B・持込A・写のみ」の4列)
   * [0]プラン選択
   * [1]着付け区分 || お姉様購入か || お姉様購入か ||  振袖の利用方法
   * [2]プラン選択 || 当日のみか   ||  着付け区分 || お姉様購入か
   * [3]         ||  着付け区分  ||
   */
  selections: [0, 0, 0, 0],
  customer: null,
  date: '',
  isValidate: null,
})

export default state
