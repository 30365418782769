import { FormValues重ね衿_着物レンタル2個以上 } from '~/composable/estimate/viewModel/graduation/Fields/Fields重ね衿_着物レンタル2個以上'

// 不明な点がある場合、READMEをご覧ください
export const getInitialStore = (): FormValues重ね衿_着物レンタル2個以上 => {
  return {
    kasaneeri_multi種別: null,
    kasaneeri_multi単品レンタル: null,
    kasaneeri_multi単品レンタルフリー入力: null,
    kasaneeri_multi単品購入: null,
    kasaneeri_multi単品購入フリー入力: null,
    kasaneeri_multi見積表示: null,
  }
}

const state = (): { fields: FormValues重ね衿_着物レンタル2個以上 } => ({
  fields: getInitialStore(),
})

export default state
