import { computed } from '@nuxtjs/composition-api'
import { FormValues足し布 } from '~/composable/estimate/viewModel/comingOfAge/Fields/Fields足し布'
import { PLAN } from '~/constants/estimate/comingOfAge'
import { useComingStore } from '../../ComingOfAgeStore'
import { State as PricesState } from '~/store/PriceTable/state'

export const useState足し布 = () => {
  const comingStore = useComingStore<FormValues足し布>('Tashinuno')
  const values足し布 = comingStore.getAll()
  const priceTable = comingStore.getPrices() // Store料金情報を取得

  return computed(() => getState足し布(values足し布.value, priceTable))
}

export const getState足し布 = (
  values足し布: FormValues足し布,
  priceTable: PricesState['prices']
) => {
  const 追加金額選択 = values足し布.tashinuno追加金額
  const 適用を選択 = values足し布.tashinuno適用 === PLAN.APPLY

  // 選ばれている足し布の金額を算出
  let price = 0
  if (適用を選択 && 追加金額選択 === PLAN.TASHINUNO_99000yen) {
    price = priceTable.estimate.足し布N
  } else if (適用を選択 && 追加金額選択 === PLAN.TASHINUNO_16500yen) {
    price = priceTable.estimate.足し布B
  }

  return {
    is適用: 適用を選択,
    price,
    bust: values足し布.tashinuno採寸数値.bust,
    hip: values足し布.tashinuno採寸数値.hip,
    bodyType: values足し布.tashinuno採寸数値.bodyType,
  }
}
