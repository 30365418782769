import { useStore } from '@nuxtjs/composition-api'
import { EstimateDetail } from '~/api/estimateRepository'
import { Profile } from '~/composable/api/usePersonal'

export const useRestoreBaseEstimate = () => {
  const store = useStore()

  const setBaseEstimate = (restate?: EstimateDetail, customer?: Profile) => {
    const pass = 'Contracts/BaseEstimate'
    restate && store.commit(`${pass}/setEstimate`, restate)
    customer && store.commit(`${pass}/setCustomerProfile`, customer)
  }

  return { setBaseEstimate }
}
