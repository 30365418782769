import { computed } from '@nuxtjs/composition-api'
import {
  getBadgeText,
  getItemPlan,
  getItemPlanFlags,
  getItemPrices,
  getPriceTypeText,
} from '~/composable/estimate/repository/comingOfAge/states/utilities'
import { AccessoryPlanState } from '~/store/types'
import { useComingStore } from '../../ComingOfAgeStore'
import { useScreenTypeFlags } from '~/composable/estimate/domain/ComingOfAge/ScreenTypeFlags'
import { BaseItemState, PlanFlags } from './types'
import ProductClassification from '~/constants/enums/productClassification'

type Obijime = BaseItemState & {
  品種区分: 3
}

export const useState帯締め = () => {
  const values帯締め = useComingStore<AccessoryPlanState>('Obijime').getAll()
  const { showTypeフルセット, showType写真のみレンタル, showType持ち込み } =
    useScreenTypeFlags()

  return computed(() => {
    const flags = {
      isフルセット: showTypeフルセット.value,
      is写のみレンタル: showType写真のみレンタル.value,
      is持ち込み: showType持ち込み.value,
    }
    return getState帯締め(values帯締め.value, flags)
  })
}

export const getState帯締め = (
  values帯締め: AccessoryPlanState,
  planFlags: PlanFlags
) => {
  const { selections, input } = values帯締め
  const itemFlags = getItemPlanFlags(selections)
  const itemPlan = getItemPlan(selections)

  const obijime: Obijime = {
    品目名: ProductClassification.toWord(ProductClassification.obijime),
    品種区分: ProductClassification.obijime,
    商品名: '',
    小物プラン: itemPlan,
    レンタル価格: 0,
    購入価格: 0,
    プレミアムレンタル価格: input.premiumRentalPrice,
    プレミアム購入価格: input.premiumBuyPrice,
    見積表示: null,
    表示価格: null,
    label: getBadgeText(itemPlan),
    cellLabel: getPriceTypeText(selections),
    flags: {
      isランクアップ:
        itemFlags.isランクアップPP内 || itemFlags.isランクアップPP外,
    },
  }

  const updatedPrices = getItemPrices(values帯締め, obijime, planFlags)
  obijime.レンタル価格 = updatedPrices.レンタル価格
  obijime.購入価格 = updatedPrices.購入価格
  obijime.プレミアムレンタル価格 = updatedPrices.プレミアムレンタル価格
  obijime.プレミアム購入価格 = updatedPrices.プレミアム購入価格
  obijime.見積表示 = updatedPrices.見積表示
  obijime.表示価格 = updatedPrices.表示価格

  return obijime
}
