import { Restate } from '~/composable/estimate/repository/graduation/EstimateRestate'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { FormValuesプラン } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsプラン'
import { Enumプラン } from '~/constants/estimate/graduationOptions'

export const useRestoreプラン = () => {
  const store = useGraduateStore<FormValuesプラン>('tabプラン')

  const restoreプラン = (restate: Restate) => {
    if (!restate.plan) return
    const { plan, customer, coming_of_age_ceremony_contract } = restate

    /* 初期値を設定 ※必ず入力される値はここで設置 */
    const formValues: FormValuesプラン = {
      plan弊社成人式: plan.optionId成人式利用,
      plan利用者を検索: null,
      plan成人式契約書: null,
      plan着付け区分: plan.optionId着付け区分,
      plan卒業式使用日: plan.卒業式使用日,
      plan着物種類: plan.optionId着物種類,
      plan着物種別: plan.optionId着物種別,
      plan袴種別: plan.optionId袴種別,
      plan友の会会員: null,
      plan未設定契約書リスト: [],
      plan未設定契約書: null,
      planフルセットプラン選択: null,
    }

    /* 以下で入力内容の条件に基づき、必要な項目を埋めていく */
    if (plan.flags.is成人式利用) {
      formValues.plan利用者を検索 = customer?.profile ?? null
      formValues.plan成人式契約書 = coming_of_age_ceremony_contract.value
    }
    if (plan.flags.has着物レンタル && plan.flags.has袴レンタル) {
      formValues.plan友の会会員 = plan.flags.is友の会
    }

    /* ストアに内容を保存する */
    store.setAll(formValues)
  }

  return { restoreプラン }
}
