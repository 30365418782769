import { MutationTree } from 'vuex'
import { FormValuesお名前 } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsお名前'
import { getInitialStore } from './state'

const mutations: MutationTree<{ fields: FormValuesお名前 }> = {
  setState: (
    state,
    payload: { stateName: keyof FormValuesお名前; value: any }
  ) => {
    state.fields[payload.stateName] = payload.value
  },

  setAllState: (state, values: FormValuesお名前) => {
    state.fields = values
  },

  initState: (state, fieldId: keyof FormValuesお名前) => {
    const initialStore = getInitialStore()
    state.fields[fieldId] = initialStore[fieldId] as any
  },
  initAllState: (state) => {
    state.fields = getInitialStore()
  },
}

export default mutations
