import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { FormValuesお名前 } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsお名前'

export const useRestoreお名前 = () => {
  const store = useGraduateStore<FormValuesお名前>('tabお名前')

  const restoreお名前 = (fullName: string | undefined) => {
    store.setAll({
      onamaeお名前: fullName ?? null,
    })
  }
  return { restoreお名前 }
}
