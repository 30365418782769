import { MutationTree } from 'vuex'
import { getInitialStore, State } from './state'

const mutations: MutationTree<State> = {
  setPrices: (state, data) => {
    state.prices = data
  },

  setEstimatePrices: (state, data) => {
    state.prices.estimate = data
  },

  initAll: (state) => {
    const initial = { ...getInitialStore() }
    state.prices = initial.prices
  },
}

export default mutations
