import { computed, ref, watch } from '@nuxtjs/composition-api'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import CoodinatePlan from '~/constants/enums/coodinatePlan'
import { EstimateListState, WphotoState } from '~/store/types'
import { PLAN } from '~/constants/estimate/comingOfAge'
import { useScreenTypeFlags } from './ScreenTypeFlags'

/**
 * @note 見積一覧ページで現在選択されている行が購入か、レンタルかのフラグを返す
 * 持込プランの場合はプラン列の選択自体が存在しない
 */
export const useSelectedPlanListType = () => {
  const store見積一覧 =
    useComingStore<EstimateListState['fields']>('EstimateList')
  const valuesWフォト = useComingStore<WphotoState>('WphotoPlan').getAll()
  const { showTypeフルセット, showType写真のみレンタル } = useScreenTypeFlags()
  const values = store見積一覧.getAll()

  // NOTE: フルセットで左2列を選択、または写真のみレンタルなら全部レンタル
  const isレンタルプラン = computed(() => {
    const selectedPlan = values.value.selectedPlan

    if (showTypeフルセット.value && CoodinatePlan.isRentalPlan(selectedPlan)) {
      return true
    } else if (showType写真のみレンタル.value) {
      return true
    }
    return false
  })

  // NOTE: フルセットで右2列を選んだ時だけ購入プラン
  const is購入プラン = computed(() => {
    const selectedPlan = values.value.selectedPlan
    return (
      showTypeフルセット.value && CoodinatePlan.isPurchasePlan(selectedPlan)
    )
  })

  // NOTE: プレミアムパック付きのプラン
  const isプレミアムプラン = computed(() => {
    const selectedPlan = values.value.selectedPlan
    const PPありプラン =
      showTypeフルセット.value || showType写真のみレンタル.value
    return PPありプラン && CoodinatePlan.isPremiumPlan(selectedPlan)
  })

  // NOTE: まだ何も選んでいない状態
  const is未決定 = computed(() => {
    return !values.value.selectedPlan
  })

  // NOTE: Wフォトのプランが表示されていて、まだ何も選んでいない状態
  //       `useSelectedPlanListType`が読み込まれた時点での値を初期値とする。
  const isWフォト未決定 = ref(
    valuesWフォト.value.selections.includes(PLAN.APPLY) &&
      values.value.selectedWPPlan === 0
  )
  // WARNING: `computed`だと発火しないため`watch`で対応
  watch(
    () => values.value.selectedWPPlan,
    (selectedWPPlan) => {
      const hasWPhoto = valuesWフォト.value.selections.includes(PLAN.APPLY)
      isWフォト未決定.value = hasWPhoto && !selectedWPPlan
    }
  )

  return {
    isレンタルプラン,
    is購入プラン,
    isプレミアムプラン,
    is未決定,
    isWフォト未決定,
  }
}
