import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { EstimateJkphotoInAdvanceState } from '~/store/types'
import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { PLAN } from '~/constants/estimate/comingOfAge'

type EstimateJkphotoInAdvanceStateType = Omit<EstimateJkphotoInAdvanceState, ''>

export const useRestoreJK前撮り = () => {
  const store =
    useComingStore<EstimateJkphotoInAdvanceStateType>('JkPhotoInAdvance')

  const restoreJK前撮り = (arg: Restate['jk_photos_in_advance'] | null) => {
    if (arg === null) {
      return
    }

    const formValues: EstimateJkphotoInAdvanceStateType = {
      selections: [arg.apply ? PLAN.APPLY : PLAN.NO_APPLY],
      isValidate: null,
    }

    store.setOf<EstimateJkphotoInAdvanceStateType['selections']>(
      'setSelection',
      formValues.selections
    )
    store.setOf<boolean>('setIsValidate', false)
  }

  return {
    restoreJK前撮り,
  }
}
