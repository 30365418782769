/** enums */
import { ContractDetail } from '~/api/contractRepository'
import { EstimatePlan } from '~/constants/enums/estimatePlan'
import { FURISODE_PLAN } from '~/constants/estimate/comingOfAge'
import { ProductCategory } from '~/constants/enums/productCategory'

/**
 * 成人式プラン判定ロジック
 * @param plan
 */
export const getPlanFlags = (plan: number) => {
  const plansフルセット = [
    EstimatePlan.fullSetプラン未決定,
    EstimatePlan.fullSet,
    EstimatePlan.fullSetPurchase,
  ]
  const plansお持込 = [
    EstimatePlan.planAAndPhoto,
    EstimatePlan.planBAndPhoto,
    EstimatePlan.planB,
  ]
  const plansお写真のみ = [
    EstimatePlan.bringInOnlyPhoto,
    EstimatePlan.onlyPhoto,
  ]

  const plans振袖持込 = [...plansお持込, EstimatePlan.bringInOnlyPhoto]

  return {
    isフルセットプラン: plansフルセット.includes(plan),
    isお持込プラン: plansお持込.includes(plan),
    isお写真のみプラン: plansお写真のみ.includes(plan),
    is写のみレンタル: plan === EstimatePlan.onlyPhoto,
    is写のみ持込: plan === EstimatePlan.bringInOnlyPhoto,
    is当日Bコース: plan === EstimatePlan.planB,
    is振袖持込: plans振袖持込.includes(plan),
  }
}

/**
 * 振袖商品判定ロジック
 * @param hurisode
 */
export const getHurisodeConditionalFlags = (
  hurisode: ContractDetail['hurisode']
) => {
  const isプロパー商品 = [
    ProductCategory.properPurchase,
    ProductCategory.properRental,
  ].includes(hurisode?.product_classification_id ?? 0)

  const selected仕立上がり = [
    ProductCategory.tailoredPurchase,
    ProductCategory.tailoredRental,
  ].includes(hurisode?.product_classification_id ?? 0)

  const selectedカタログ = [
    ProductCategory.customizeRental,
    ProductCategory.catalogPurchase,
    ProductCategory.orderRental,
  ].includes(hurisode?.product_classification_id ?? 0)

  const isカタログ検索結果 =
    selectedカタログ || (selected仕立上がり && !hurisode?.product_id)

  const is仕立て上り検索結果 = selected仕立上がり && hurisode?.product_id

  return {
    isプロパー商品,
    isカタログ検索結果,
    is仕立て上り検索結果,
  }
}

/**
 * カテゴリー確定ロジック
 * @param category
 */
export const getOptionIdOfどちらのレンタル = (
  hurisode: ContractDetail['hurisode']
) => {
  switch (hurisode?.product_classification_id) {
    /** オーダーレンタル */
    case ProductCategory.orderRental:
      return FURISODE_PLAN.ORDER_RENTAL
    /** お誂えレンタル */
    case ProductCategory.customizeRental:
    case ProductCategory.catalogPurchase:
    case ProductCategory.未確定:
      return FURISODE_PLAN.CUSTOM_RENTAL
    /** カタログ検索結果で仕立て上がりレンタルを選択 */
    case ProductCategory.tailoredRental:
      return FURISODE_PLAN.SHITATE_RENTAL
    default:
      return 0
  }
}
