import { computed, Ref } from '@nuxtjs/composition-api'
import { Items } from '~/api/estimateRepository'
import { EstimateState } from '~/composable/estimate/repository/comingOfAge/EstimateState'
import {
  BaseItemState,
  WPhotoBaseItemState,
} from '~/composable/estimate/repository/comingOfAge/states/types'
import { ZoriBag } from '~/composable/estimate/repository/comingOfAge/states/zoriBagUtils'
import { usePPZoriBagPlan } from '~/composable/general/PPDiscount/usePPZoriBagPlan'
import { ItemPlan, ItemPriceType } from '~/constants/enums/itemPlan'
import ProductClassification from '~/constants/enums/productClassification'
import { OtherItem, OtherItems } from '~/store/types'
import { COORDINATE_PLAN, OTHER_ITEMS } from '~/constants/estimate/config'

export const useItemsFormatter = (
  estimateState: Ref<EstimateState>,
  otherItems: OtherItems,
  selectedPlan: number,
  isFullSet: boolean
) => {
  return computed(() => {
    return getFormattedItems(
      estimateState.value,
      otherItems,
      selectedPlan,
      isFullSet
    )
  })
}

export const getFormattedItems = (
  estimateState: EstimateState,
  otherItems: OtherItems,
  selectedPlan: number,
  isFullSet: boolean
) => {
  const items: Items[] = []

  // 帯なしの場合は「未定」でitemsに含めない
  if (estimateState.帯.小物プラン !== ItemPlan.noUse) {
    items.push(getFormatted帯(estimateState.帯))
  }
  if (estimateState.帯締め.小物プラン !== ItemPlan.noUse) {
    items.push(getFormattedSingleItem(estimateState.帯締め))
  }
  if (estimateState.帯揚げ[0].小物プラン !== ItemPlan.noUse) {
    items.push(
      ...getFormattedMultiItems(estimateState.帯揚げ, estimateState, isFullSet)
    )
  }
  items.push(
    ...getFormattedMultiItems(estimateState.重ね衿, estimateState, isFullSet)
  )
  items.push(getFormattedSingleItem(estimateState.別衿))
  if (estimateState.ショール.小物プラン) {
    items.push(getFormattedSingleItem(estimateState.ショール))
  }
  items.push(...getFormattedMultiItems(estimateState.髪飾り))
  items.push(getFormatted草履バッグ(estimateState.草履1個目, selectedPlan))
  items.push(getFormatted草履バッグ(estimateState.バッグ1個目, selectedPlan))
  items.push(
    ...getFormatted草履複数個目(estimateState.草履複数個目, selectedPlan)
  )
  items.push(
    ...getFormattedバッグ複数個目(estimateState.バッグ複数個目, selectedPlan)
  )
  if (otherItems.apply === OTHER_ITEMS.種別.する)
    items.push(...getFormattedその他商品(otherItems.items, isFullSet))
  return items
}

type ObiState = EstimateState['帯'] | EstimateState['Wフォト']['帯']
export const getFormatted帯 = (帯: ObiState): Items => {
  if (!帯.小物プラン) throw new Error('帯.小物プランがありません')
  return {
    product_type_id: 帯.品種区分,
    catalog_code: 帯.仕立て上り商品?.catalog_code || undefined,
    product_id: 帯.仕立て上り商品?.product_id || undefined,
    plan: 帯.小物プラン,
    rental_price: 帯.レンタル価格,
    purchase_price: 帯.購入価格,
    premium_rental_price: 帯.プレミアムレンタル価格,
    // NOTE: Wフォトの方にはないため0円を送る
    premium_purchase_price:
      'プレミアム購入価格' in 帯 ? 帯.プレミアム購入価格 : 0,
    product_name: 帯.商品名 || '-',
    price_type: 帯.見積表示?.id || undefined,
  }
}

export const getFormatted草履複数個目 = (
  草履: ZoriBag[],
  selectedPlan: number
): Items[] => {
  return 草履.map((item, i) =>
    getFormatted草履バッグ(item, selectedPlan, i + 1)
  )
}

export const getFormattedバッグ複数個目 = (
  バッグ: ZoriBag[],
  selectedPlan: number
): Items[] => {
  return バッグ.map((item, i) =>
    getFormatted草履バッグ(item, selectedPlan, i + 1)
  )
}

export const getFormatted草履バッグ = (
  草履バッグ: ZoriBag,
  selectedPlan: number,
  index: number = 0
): Items => {
  if (!草履バッグ.小物プラン)
    throw new Error('草履バッグ.小物プランがありません')
  const {
    is振袖レンタル時単品購入,
    is振袖持込,
    isPPDiscount,
    isPPAddingPrice,
  } = 草履バッグ.flags
  const is1個目 = index === 0
  const { getZoriBagPPPriceType } = usePPZoriBagPlan()
  const { priceType } = getZoriBagPPPriceType(
    草履バッグ.小物プラン,
    草履バッグ.見積表示?.id || undefined,
    isPPDiscount,
    isPPAddingPrice,
    selectedPlan
  )

  return {
    product_type_id: 草履バッグ.品種区分,
    plan: 草履バッグ.小物プラン,
    /**
     * WARNING! スーパーイレギュラー対応!!
     * フルセット時の草履・バッグで「振袖レンタルの場合」の質問に「同じ商品を単品購入」と答えた時、
     * 「プラン内の購入価格/PP購入価格/単品購入価格」全てを保持する必要が生じるが、
     * 購入金額のために用意されているパラメータが足りない、かつ根本的な解決が現状難しいため、
     * 現状は併用される可能性のない"rental_price"に「単品購入価格」を格納しています。。。
     *
     * また、振袖持込プランもしくは複数個目の場合は「単品レンタル: rental_price」「単品購入: purchase_price」で送る
     */
    rental_price:
      is振袖レンタル時単品購入 && !is振袖持込
        ? 草履バッグ.単品購入価格
        : 草履バッグ.レンタル価格,
    purchase_price:
      is振袖持込 || !is1個目 ? 草履バッグ.単品購入価格 : 草履バッグ.購入価格,
    premium_rental_price: 草履バッグ.プレミアムレンタル価格,
    premium_purchase_price: 草履バッグ.プレミアム購入価格,
    product_name: 草履バッグ.商品名 || '-',
    price_type: priceType,
    order_number: index + 1,
    product_id: 草履バッグ.商品.id ?? undefined,
  }
}

export const getFormatted髪飾り = (
  item: BaseItemState | WPhotoBaseItemState,
  index?: number
): Items => {
  return getFormattedSingleItem(item, index)
}

export const getFormattedその他商品 = (
  otherItems: OtherItems['items'],
  isFullSet: boolean
): Items[] => {
  /**
   * その他商品でフルセットかつ袴なしを選んだ場合は、
   * 単品レンタル・単品購入両方の価格を返却する
   * @param isPlanFullSet
   * @param item
   * @param priceName
   * @returns
   */
  const getPrice = (
    isPlanFullSet: boolean,
    item: OtherItem,
    priceName: string
  ) => {
    if (isPlanFullSet && !(item.is_hakama_used === OTHER_ITEMS.袴利用.する)) {
      if (priceName === 'rental_price') {
        return item.single_rental_price || 0
      } else {
        return item.single_purchase_price || 0
      }
    } else {
      if (priceName === 'rental_price') {
        if (item.price_type === ItemPriceType.rentalTanpin)
          return item.price || 0
      } else {
        if (item.price_type === ItemPriceType.buyTanpin) return item.price || 0
      }
    }
    return 0
  }

  return otherItems.map((value, index) => ({
    product_type_id: ProductClassification.otherItem,
    plan: 5,
    rental_price: getPrice(isFullSet, value, 'rental_price'),
    purchase_price: getPrice(isFullSet, value, 'purchase_price'),
    premium_rental_price: 0,
    // NOTE: Wフォトの方にはないため0円を送る
    premium_purchase_price: 0,
    product_name: value.product_name,
    price_type: value.price_type === ItemPriceType.rentalTanpin ? 1 : 2,
    order_number: index + 1,
    product_id: undefined,
    is_hakama: value.is_hakama_used === OTHER_ITEMS.袴利用.する,
  }))
}
/**
 * NOTE: 商品検索がない&&複数個ないタイプの小物をフォーマットして返す
 */
export const getFormattedSingleItem = (
  item: BaseItemState | WPhotoBaseItemState,
  index?: number,
  estimateState?: EstimateState,
  isFullSet?: boolean
): Items => {
  if (!item.小物プラン)
    throw new Error('小物プランがありません 小物id :' + item.品種区分)
  let plan = item.小物プラン
  let price_type = item.見積表示?.id || undefined

  const isPremium =
    estimateState?.見積一覧.selectedプラン列 ===
      COORDINATE_PLAN.RENTAL_PREMIUM ||
    estimateState?.見積一覧.selectedプラン列 === COORDINATE_PLAN.BUY_PREMIUM

  const is重ね衿PPApplied =
    item.品種区分 === ProductClassification.kasaneeri &&
    isPremium &&
    estimateState?.見積一覧.is重ね衿RankUp

  const is帯揚げPPApplied =
    item.品種区分 === ProductClassification.obiage &&
    isPremium &&
    estimateState?.見積一覧.is帯揚げRankUp

  const isレンタルプレミアム =
    estimateState?.見積一覧.selectedプラン列 === COORDINATE_PLAN.RENTAL_PREMIUM
  const is購入プレミアム =
    estimateState?.見積一覧.selectedプラン列 === COORDINATE_PLAN.BUY_PREMIUM

  if (is重ね衿PPApplied || is帯揚げPPApplied) {
    if (index === 0) {
      if (isFullSet) {
        price_type = isレンタルプレミアム
          ? ItemPriceType.rental
          : ItemPriceType.purchase
      } else {
        if (isレンタルプレミアム || is購入プレミアム)
          price_type = ItemPriceType.rental
      }
    } else if (index === 1) {
      price_type = undefined
    }
  }

  return {
    product_type_id: item.品種区分,
    plan,
    rental_price: item.レンタル価格,
    purchase_price: item.購入価格,
    premium_rental_price: item.プレミアムレンタル価格,
    // NOTE: Wフォトの方にはないため0円を送る
    premium_purchase_price:
      'プレミアム購入価格' in item ? item.プレミアム購入価格 : 0,
    product_name: item.商品名 || '-',
    price_type,
    order_number: typeof index === 'number' ? index + 1 : undefined,
    product_id: '商品id' in item && item.商品id ? item.商品id : undefined,
  }
}

/**
 * 複数個あるタイプの小物を配列形式でフォーマットして返す
 */
export const getFormattedMultiItems = (
  items: BaseItemState[] | WPhotoBaseItemState[],
  estimateState?: EstimateState,
  isFullSet?: boolean
): Items[] => {
  // NOTE: jest内の型推論が失敗するため、itemとiにも型をつけている
  return items.map((item: BaseItemState | WPhotoBaseItemState, i: number) => {
    if (!item.小物プラン)
      throw new Error('小物プランがありません 小物id :' + item.品種区分)

    return getFormattedSingleItem(item, i, estimateState, isFullSet)
  })
}
