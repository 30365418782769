import { FormValues割引 } from '~/composable/estimate/viewModel/comingOfAge/Fields/Fields割引'
import { PLAN } from '~/constants/estimate/comingOfAge'

//不明な点がある場合、READMEをご覧ください
export const getInitialStore = (): FormValues割引 => {
  return {
    waribiki適用: PLAN.NO_APPLY,
    waribiki金額入力: null,
  }
}

export type State = {
  fields: FormValues割引
  isValidate: boolean | null
}

const state = (): State => ({
  fields: getInitialStore(),
  isValidate: false,
})

export default state
