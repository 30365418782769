import { ActionTree } from 'vuex'
import { RootState, OtherItems } from '~/store/types'

const actions: ActionTree<OtherItems, RootState> = {
  setInput: ({ commit }, input) => {
    commit('setInput', input)
  },
  setIsValidate: ({ commit }, isValidate: boolean) => {
    commit('setIsValidate', isValidate)
  },
}

export default actions
