import { MutationTree } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import { RootState } from './types'

const mutations: MutationTree<RootState> = {
  initialize: (state, initState) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state = initState
  },

  // 仮保存リストからstoreを置き換えて復元する
  setSaveEstimate: (
    state: any,
    payload: {
      Estimate: {
        ComingOfAgeCeremony: Record<string, unknown>
        GraduationCeremony: Record<string, unknown>
        Attendance: Record<string, unknown>
        SingleItemService: Record<string, unknown>
      }
      eventName: '成人式' | '卒業式' | '参列' | '単品'
      frontStoreId: number
    }
  ) => {
    // フロントストア更新時に使うfrontStoreIdもここで保存する
    state.FrontStoreInfo.frontStoreId.estimate = payload.frontStoreId

    // 成人式
    if (payload.eventName === '成人式') {
      state.Estimate.ComingOfAgeCeremony = cloneDeep(
        payload.Estimate.ComingOfAgeCeremony
      )
    }
    // 卒業式
    if (payload.eventName === '卒業式') {
      state.Estimate.GraduationCeremony = cloneDeep(
        payload.Estimate.GraduationCeremony
      )
    }

    // 参列
    if (payload.eventName === '参列') {
      state.Estimate.Attendance = cloneDeep(payload.Estimate.Attendance)
    }

    //単品
    if (payload.eventName === '単品') {
      state.SingleItemService = cloneDeep(payload.Estimate.SingleItemService)
    }
  },

  /*
   * NOTE: 契約書は契約詳細APIにフロントストアIDを乗せていないので、
   * お客様情報から更新する際に契約書に紐付いたフロントストアIDを取得する必要はない
   * ＝新規フロントストアを作成すれば良い
   */
  setSaveContract: (
    state: any,
    payload: {
      savedContract: Record<string, unknown>
      frontStoreId: number
      eventName: '成人式' | '卒業式' | '参列' | '単品'
    }
  ) => {
    // フロントストア更新時に使うfrontStoreIdもここで保存する
    state.FrontStoreInfo.frontStoreId.contract = payload.frontStoreId

    // 卒業式
    if (payload.eventName === '卒業式' || '成人式') {
      state.Contracts = cloneDeep(payload.savedContract)
    }

    // 参列
    if (payload.eventName === '参列') {
      state.Contracts.Attendance = cloneDeep(payload.savedContract.Attendance)
    }

    //単品
    if (payload.eventName === '単品') {
      state.SingleItemService = cloneDeep(
        payload.savedContract.SingleItemService
      )
    }
  },
}
export default mutations
