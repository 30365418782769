import { ActionTree } from 'vuex'
import { RootState, EstimateListState } from '../../../types'

const actions: ActionTree<EstimateListState, RootState> = {
  delete: ({ commit }) => {
    commit('delete')
  },

  setInitIsZoribagPriceCalculated: ({ commit }) => {
    commit('initIsZoribagPriceCalculated')
  },

  setInitIsZoriBagSetPrice: ({ commit }) => {
    commit('initIsZoriBagSetPrice')
  },
}

export default actions
