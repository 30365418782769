import { computed } from '@nuxtjs/composition-api'
import { useEstimateState } from '~/composable/estimate/repository/comingOfAge/EstimateState'
import {
  useListItemWPhotoプレミアムパックFormatter,
  useListItem別衿加工Formatter,
  useListItem補正Formatter,
  useListItem帯仕立て代Formatter,
} from './ListItemオプション'
import { useListItemWPhoto振袖割引Formatter } from './ListItem割引'
import {
  useWPhotoListItemFormatter,
  useWPhotoMultiListItemFormatter,
} from './ListItem小物'
import { useListItem振袖Formatter } from './ListItem振袖'
import { useListItemWPhoto着付代Formatter } from './ListItem着付代'
import { ItemWPhoto } from '../types'

export const useEstimateWPhotoListItems = () => {
  const estimateState = useEstimateState()
  const is振袖レンタル = estimateState.value.Wフォト.振袖.flags.isレンタル
  /**
  // NOTE: ここでestimateStateを展開しているので、ストア内の値へのリアクティブ性は失われている
  // 現状リアクティブである必要がないのでこうしているが、
  // もし見積一覧ページ内からの更新が必要になればestimateStateをtoRefsで展開して、
  // Ref値として各useListItemに引き渡す用に変更すれば良い
  */
  const item振袖 = useListItem振袖Formatter(estimateState.value.Wフォト.振袖)
  const item振袖割引 = useListItemWPhoto振袖割引Formatter(
    estimateState.value.Wフォト.振袖
  )
  const item帯 = useWPhotoListItemFormatter(estimateState.value.Wフォト.帯)
  const item帯締め = useWPhotoListItemFormatter(
    estimateState.value.Wフォト.帯締め
  )
  const item帯揚げ = useWPhotoMultiListItemFormatter(
    estimateState.value.Wフォト.帯揚げ,
    is振袖レンタル
  )
  const item重ね衿 = useWPhotoMultiListItemFormatter(
    estimateState.value.Wフォト.重ね衿,
    is振袖レンタル
  )
  const item別衿 = useWPhotoListItemFormatter(estimateState.value.Wフォト.別衿)
  const item補正 = useListItem補正Formatter(estimateState.value.Wフォト.振袖)
  const item帯仕立て代 = useListItem帯仕立て代Formatter(
    estimateState.value.Wフォト.帯
  )
  const item別衿加工 = useListItem別衿加工Formatter(
    estimateState.value.Wフォト.別衿
  )
  const item着付代 = useListItemWPhoto着付代Formatter(is振袖レンタル)
  const itemプレミアムパック =
    useListItemWPhotoプレミアムパックFormatter(is振袖レンタル)

  return computed<ItemWPhoto[]>(() => {
    const list: ItemWPhoto[] = []

    if (item振袖.value) list.push(item振袖.value)
    if (item振袖割引.value) list.push(item振袖割引.value)
    if (item帯.value) list.push(item帯.value)
    if (item帯締め.value) list.push(item帯締め.value)
    if (item帯揚げ.value.length) list.push(...item帯揚げ.value)
    if (item重ね衿.value.length) list.push(...item重ね衿.value)
    if (item別衿.value) list.push(item別衿.value)
    if (item補正.value.裄補正) list.push(item補正.value.裄補正)
    if (item補正.value.袖補正) list.push(item補正.value.袖補正)
    if (item帯仕立て代.value) list.push(item帯仕立て代.value)
    if (item別衿加工.value) list.push(item別衿加工.value)
    if (item着付代.value) list.push(item着付代.value)
    if (itemプレミアムパック.value) list.push(itemプレミアムパック.value)

    return list
  })
}
