import { FormValues別衿_着物レンタル } from '~/composable/estimate/viewModel/graduation/Fields/Fields別衿_着物レンタル'

// 不明な点がある場合、READMEをご覧ください
export const getInitialStore = (): FormValues別衿_着物レンタル => {
  return {
    betsueri種別: null,
    betsueri形態: null,
    betsueriレンタル価格: null,
    betsueri商品情報: null,
    betsueriフリー入力: null,
    betsueri長襦袢: null,
  }
}

const state = (): { fields: FormValues別衿_着物レンタル } => ({
  fields: getInitialStore(),
})

export default state
