import { loginUser } from '../types'

const state = (): loginUser => ({
  user: {
    staff_id: 0,
    last_name: '',
    first_name: '',
    shop_id: 0,
    shop_name: '',
    login_time: 0,
    login_date: 0,
  },
})

export default state
