import { computed, Ref } from '@nuxtjs/composition-api'
import { usePrice } from 'storybook/utils/utils'
import { useRestatePlan } from '../RestatePlan'
import { EstimateDetail, Items } from '~/api/estimateRepository'
import {
  Enum巾着バッグ_着物レンタル,
  Enum巾着バッグ_着物持込,
} from '~/constants/estimate/graduationOptions'
import { EstimateSinglePriceType } from '~/constants/enums/estimateSinglePriceType'
import { ItemPlan } from '~/constants/enums/itemPlan'
import ProductId from '~/constants/enums/productClassification'
import { Product } from '~/store/Estimate/GraduationCeremony/types'

type KinchakuBag = KimonoBringIn | KimonoRental

type KimonoBringIn = {
  type: '着物持込'
  optionId種別: number | null
  レンタル価格: string | null
  購入価格: string | null
  選択商品: Product
  見積表示: number | null
  flags: Flags
}

type KimonoRental = {
  type: '着物レンタル'
  optionId種別: number | null
  optionId形態: number | null
  レンタル価格: string | null
  選択商品: Product
  flags: Flags
}

type Flags = ReturnType<typeof getConditionalFlags>

export const useRestate巾着バッグ = (
  estimate: Ref<EstimateDetail | undefined>
) => {
  const restatePlan = useRestatePlan(estimate)

  return computed((): KinchakuBag | undefined => {
    if (!estimate.value?.items) return

    const kinchakuBag = estimate.value.items.filter((item) => {
      return (
        item.product_type_id === ProductId.bag ||
        item.product_type_id === ProductId.kintyaku
      )
    })

    //* 着物レンタル時のデータ作成関数
    if (restatePlan.value?.flags.has着物レンタル) {
      return formatKimonoRental(kinchakuBag[0])
    } else {
      //* 着物持込以外の時のデータ作成関数
      return formatKimonoBringIn(kinchakuBag[0])
    }
  })
}

/**
 * 復元条件に関わる条件式を定義する
 */
const getConditionalFlags = (item: Items) => {
  const isランクアップ = item.plan === ItemPlan.rankUpOutPremium
  const isセット内 = item.plan === ItemPlan.inSet
  const isお持込 = item.plan === ItemPlan.bringIn
  const is単品レンタル =
    item.price_type === EstimateSinglePriceType.singleRentalPrice
  const is単品購入 =
    item.price_type === EstimateSinglePriceType.singlePurchasePrice
  const is現品 = item.product_name === '現品'

  return {
    is持込: isお持込,
    is現品,
    isセット内,
    isランクアップ,
    is単品レンタル,
    is単品購入,
  }
}

const formatKimonoBringIn = (item: Items) => {
  const { paramToString } = usePrice()
  const flags = getConditionalFlags(item)

  const kinchakuBag: KimonoBringIn = {
    type: '着物持込',
    optionId種別: flags.is持込
      ? Enum巾着バッグ_着物持込.種別.お持込
      : Enum巾着バッグ_着物持込.種別.単品レンタル単品購入,
    選択商品: {
      id: 0,
      name: '',
    },
    見積表示: flags.is単品レンタル
      ? Enum巾着バッグ_着物持込.見積表示.単品レンタル価格
      : Enum巾着バッグ_着物持込.見積表示.単品購入価格,
    レンタル価格: paramToString(item.rental_price),
    購入価格: paramToString(String(item.purchase_price)),
    flags,
  }

  kinchakuBag.選択商品.name = item?.product_name ?? ''
  kinchakuBag.選択商品.id = getProductId(item.product_id, item.product_name)

  return kinchakuBag
}

const formatKimonoRental = (item: Items) => {
  const flags = getConditionalFlags(item)
  const { paramToString } = usePrice()

  const kinchakuBag: KimonoRental = {
    type: '着物レンタル',
    optionId種別: flags.is持込
      ? Enum巾着バッグ_着物レンタル.種別.お持込
      : Enum巾着バッグ_着物レンタル.種別.レンタル,
    optionId形態: flags.isセット内
      ? Enum巾着バッグ_着物レンタル.形態.セット内
      : Enum巾着バッグ_着物レンタル.形態.ランクアップ,
    選択商品: {
      id: 0,
      name: '',
    },
    レンタル価格: paramToString(item.rental_price),
    flags,
  }

  kinchakuBag.選択商品.name = item?.product_name ?? ''
  kinchakuBag.選択商品.id = getProductId(item.product_id, item.product_name)

  return kinchakuBag
}

const getProductId = (
  productId: number | undefined,
  productName: string | undefined
) => {
  if (productId) return productId
  if (productName === '現品') return Enum巾着バッグ_着物レンタル.バッグ商品.現品

  console.error('該当する商品の番号が存在しませんでした。')
  return 0
}
