import { SelectedPlan } from '~/store/Contracts/types'
import { EstimateList } from '~/store/types'

type valueOf<T> = T[keyof T]
export type 卒業式見積一覧 = {
  is_valid: boolean
  amount_adjustments: EstimateList['amount_adjustments']
  amount_adjustment_validation: EstimateList['amount_adjustment_validation']
  selectedPlan: SelectedPlan
}

export type 卒業式見積一覧Keys = keyof 卒業式見積一覧
export type 卒業式見積一覧Values = valueOf<卒業式見積一覧>

//不明な点がある場合、READMEをご覧ください
export const getInitialStore = (): 卒業式見積一覧 => {
  return {
    amount_adjustments: [], //金額調整
    amount_adjustment_validation: [], //見積一覧ページにて金額入力のバリデーションを保持するdata
    selectedPlan: 0, // こちらを選ぶ
    is_valid: false, //見積完了ボタンの制御フラグ
  }
}

const state = (): { fields: 卒業式見積一覧 } => ({
  fields: getInitialStore(),
})

export default state
