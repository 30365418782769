import { computed, Ref } from '@nuxtjs/composition-api'
import { EstimateState } from '~/composable/estimate/repository/comingOfAge/EstimateState'

export const useJkPhotosInAdvanceFormatter = (
  estimateState: Ref<EstimateState>
) => {
  return computed(() => {
    return getJkPhotosInAdvance(estimateState.value.JK前撮り)
  })
}
export const getJkPhotosInAdvance = (
  valuesJK前撮り: EstimateState['JK前撮り']
) => {
  return valuesJK前撮り.is適用
}
