import { MutationTree } from 'vuex'
import { ReferralBonusState } from '~/store/types'

const mutations: MutationTree<ReferralBonusState> = {
  setUserPassedDate(state, date: string) {
    state.user.passed_date = date
  },

  setUserIsPassed(state, isPassed: number) {
    state.user.is_passed = isPassed
  },

  setIntroducerPassedDate(state, date: string) {
    state.introducer.passed_date = date
  },

  setIntroducerIsPassed(state, isPassed: number) {
    state.introducer.is_passed = isPassed
  },

  setIsReferralBonus(state, isReferralBonus: number) {
    state.is_referral_bonus = isReferralBonus
  },

  setPoseNumberByJkPhotoInAdvance(state, addOfBenefits: number) {
    state.poseNumberByJkPhotoInAdvance = addOfBenefits
  },

  setPoseNumberByPhotoInAdvance(state, addOfBenefits: number) {
    state.poseNumberByPhotoInAdvance = addOfBenefits
  },

  setIsValidate(state, isValidate) {
    state.isValidate = isValidate
  },

  setAllState(state, value: ReferralBonusState) {
    state.introducer = value.introducer
    state.user = value.user
    state.poseNumberByJkPhotoInAdvance = value.poseNumberByJkPhotoInAdvance
    state.poseNumberByPhotoInAdvance = value.poseNumberByPhotoInAdvance
    state.is_referral_bonus = value.is_referral_bonus
    state.introducer_info = value.introducer_info
    state.isValidate = value.isValidate
  },

  delete: (state) => {
    state.is_referral_bonus = null
    state.introducer_info = null
    state.poseNumberByJkPhotoInAdvance = 0
    state.poseNumberByPhotoInAdvance = 0
    state.introducer = {
      bonus_type: null,
      is_passed: null,
      passed_date: null,
      notes: null,
    }
    state.user = {
      bonus_type: null,
      is_passed: null,
      passed_date: null,
      photo_type: null,
      notes: null,
    }
    state.isValidate = null
  },
}

export default mutations
