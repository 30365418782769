






import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    label: {
      type: String,
      default: '',
      required: true,
    },
    isPhoto: {
      type: Boolean,
      default: false,
    },
  },
})
