import { computed, Ref, watch } from '@nuxtjs/composition-api'
import { EstimateDetail } from '~/api/estimateRepository'

export const useRestateJkPhotoInAdvance = (
  estimate: Ref<EstimateDetail | undefined>
) => {
  // NOTE: computed内で取得処理をしようとすると無限ループが起きる
  watch(estimate, () => {
    if (!estimate.value?.is_jk_photos_in_advance) {
      return
    }
  })

  return computed(() => {
    if (!estimate.value?.is_jk_photos_in_advance) {
      return {
        apply: false,
      }
    }

    // JK前撮りを適用する場合
    return {
      apply: true,
    }
  })
}
