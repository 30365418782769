import { computed, useStore } from '@nuxtjs/composition-api'
import { Enumプラン } from '~/constants/estimate/graduationOptions'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { FormValuesプラン } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsプラン'
import { CopiedEstimateState } from '~/store/Estimate/GraduationCeremony/CopiedEstimate/state'

/**
 * @note プランタブでの選択結果がどのパターンかを返す
 */
export const usePlanFlags = () => {
  const store = useGraduateStore<FormValuesプラン>('tabプラン')
  const values = store.getAll()

  const contractStore = useStore()
  const copyEstimateFlag: CopiedEstimateState['isCopyEstimateFlag'] =
    contractStore.getters[
      'Estimate/GraduationCeremony/CopiedEstimate/getIsCopyEstimateFlagForKimono'
    ]

  const isEstimateBeforeRelease =
    copyEstimateFlag.isEstimateBeforeReleaseForNishakusode // 契約書フローにおいて二尺袖 + 袴持ち込みの割引適用かどうか

  // 袴単zori品：着物持込＋袴レンタル
  const isHakama = computed(() => {
    const 袴レンタル = values.value.plan袴種別 === Enumプラン.袴種別.レンタル
    const 着物持込 = [
      Enumプラン.着物種別.お持込,
      Enumプラン.着物種別.姉販持込,
    ].some((i) => i === values.value.plan着物種別)
    return 袴レンタル && 着物持込
  })

  // 二尺袖単品：二尺袖レンタル＋袴持込
  const isNishakusode = computed(() => {
    const 二尺袖選択 =
      values.value.plan着物種類 === Enumプラン.着物を選択.二尺袖
    const 着物レンタル =
      values.value.plan着物種別 === Enumプラン.着物種別.レンタル
    const 袴持込 = values.value.plan袴種別 === Enumプラン.袴種別.お持込
    return 二尺袖選択 && 着物レンタル && 袴持込
  })

  // 着物＋袴：振袖OR二尺袖レンタル＋袴レンタル
  const isKimonoHakama = computed(() => {
    const 着物レンタル =
      values.value.plan着物種別 === Enumプラン.着物種別.レンタル
    const 袴レンタル = values.value.plan袴種別 === Enumプラン.袴種別.レンタル
    return 着物レンタル && 袴レンタル
  })

  // 振袖単品：振袖レンタル＋袴持込
  const isFurisode = computed(() => {
    const 振袖選択 = values.value.plan着物種類 === Enumプラン.着物を選択.振袖
    const 着物レンタル =
      values.value.plan着物種別 === Enumプラン.着物種別.レンタル
    const 袴持込 = values.value.plan袴種別 === Enumプラン.袴種別.お持込
    return 振袖選択 && 着物レンタル && 袴持込
  })

  /**
   * 小物によって振袖・二尺袖・袴の3要素が持込かレンタルかを起点に判定してタブを出し分けるためのフラグ
   * 例えば「振袖を選ぶ着物+袴プラン」「振袖単品プラン」は両方hasFurisodeRental = true になる
   */
  const hasHakamaRental = computed(() => {
    return isHakama.value || isKimonoHakama.value
  })
  const hasKimonoRental = computed(() => {
    return isKimonoHakama.value || isFurisode.value || isNishakusode.value
  })

  return {
    isHakama,
    isNishakusode,
    isKimonoHakama,
    isFurisode,
    isEstimateBeforeRelease,
    hasHakamaRental,
    hasKimonoRental,
  }
}
