import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import 'dayjs/locale/ja'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

/** enum */
import ComingOfAgeCeremonyYear from '../constants/enums/comingOfAgeCeremonyYear'

dayjs.locale('ja')
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')
dayjs.extend(isBetween) // https://day.js.org/docs/en/plugin/is-between

/**
 * 美容室入場時間で使用するタイプ
 */
export type salonDateTimeType = {
  baseDate: string
  hours: string[]
  minutes: string[]
}

/**
 * 日付をフォーマットするクラス
 */
class DateTime {
  #date: Date
  constructor(date?: Date | string | null) {
    if (date instanceof Date) {
      this.#date = date
    } else if (typeof date === 'string') {
      const safariSupportDate = DateTime.formatSafariSupport(date)
      if (DateTime.isDate(safariSupportDate)) {
        this.#date = new Date(safariSupportDate)
      } else {
        // NOTE: 無効なフォーマットであっても、日付を設定する。
        this.#date = new Date()
      }
    } else {
      // NOTE: 無効なフォーマットであっても、日付を設定する。
      this.#date = new Date()
    }
  }

  get date() {
    return this.#date
  }

  /**
   * Date型のフォーマットをSafariがサポートする形式に変換する関数
   * DOC: https://qiita.com/pearmaster8293/items/b5b0df28147eb049f1ea
   *
   * @returns yyyy/mm/dd
   */
  static formatSafariSupport = (v: string) => {
    return v.toString().replace(/-/g, '/')
  }

  static isDate = (v: string | number) => !isNaN(new Date(v).getTime())

  /**
   * @returns yyyy年mm月dd日
   */
  toJpString = () => {
    return `${this.#date.getFullYear()}年${
      this.#date.getMonth() + 1
    }月${this.#date.getDate()}日`
  }

  /**
   * @returns yyyy年mm月dd日（曜日）
   */
  toJpStringWithWeek = () => {
    const week = ['日', '月', '火', '水', '木', '金', '土']
    const dayOfWeek = week[this.#date.getDay()]
    if (this.#date === undefined || dayOfWeek === undefined) return 'ー'
    return `${this.toJpString()}（${dayOfWeek}）`
  }

  /**
   * 時間のみ所得する
   * @returns hh:mm
   */
  toOnlyTime = () => {
    const localeString = this.#date.toLocaleString('ja-JP').split(' ')
    // hh:mm:ssの場合はssを取り除く
    if (localeString[1].match(/^[0-9]{1,2}:[0-9]{2}:[0-9]{2}$/)) {
      const [hh, mm, _ss] = localeString[1].split(':')
      return hh.length === 1 ? `0${hh}:${mm}` : `${hh}:${mm}`
    }

    return localeString[1]
  }

  /**
   * 対象月の初日を取得する
   * @returns YYYY-MM-DD（初日）
   */
  firstDateOfMonth = () => {
    return this.toDateString().substring(0, 8) + '01'
  }

  /**
   * 対象日の月の日数を取得する
   * @returns 日数
   */
  daysInMonth = () => {
    return dayjs(this.#date).daysInMonth()
  }

  /**
   * @returns YYYY-MM-DD
   */
  toDateString = () => {
    return dayjs(this.#date).tz().format('YYYY-MM-DD')
  }

  /**
   * @returns YYYY年MM月DD日
   */
  toDateJpString = () => {
    return dayjs(this.#date).tz().format('YYYY年MM月DD日')
  }

  /**
   * @returns YYYY-MM-DD HH:mm
   */
  toDateStringWithTime = () => {
    return dayjs(this.#date).tz().format('YYYY-MM-DD HH:mm')
  }

  /**
   * @returns YYYY年MM月DD日 HH時mm分
   */
  toDateStringWithFormatTime = () => {
    return dayjs(this.#date).tz().format('YYYY年MM月DD日 HH時mm分')
  }

  /**
   * @returns YYYY-MM-DD HH:mm:ss
   */
  toDateTimeString = () => {
    return dayjs(this.#date).tz().format('YYYY-MM-DD HH:mm:ss')
  }

  /**
   * @params template
   */
  format = (template?: string) => {
    return dayjs(this.#date).tz().format(template)
  }

  /**
   * 指定した日数を加算した日を返す
   * @returns YYYY-MM-DD
   */
  afterDay = (day: number) => {
    return dayjs(this.#date).tz().add(day, 'd').format('YYYY-MM-DD')
  }

  /**
   * 指定した日数を加算した日(DateTime型)を返す
   * @returns DateTime
   */
  addDate = (day: number) => {
    return new DateTime(dayjs(this.#date).tz().add(day, 'd').toDate())
  }

  /**
   * 指定した日数を加算した日(DateTime型)を返す
   * @returns DateTime
   */
  addMonth = (month: number) => {
    return new DateTime(dayjs(this.#date).tz().add(month, 'M').toDate())
  }

  /**
   * 指定した日数を減算した日(DateTime型)を返す
   * @param unit（
   * @returns DateTime
   */
  subtractDate = (day: number, unit: dayjs.ManipulateType = 'd') => {
    return new DateTime(dayjs(this.#date).tz().subtract(day, unit).toDate())
  }

  /**
   * 指定した年数後の1月1日を返す
   * @returns YYYY-MM-DD
   */
  yearsLaterNewYearsDate = (year: number) => {
    return dayjs().add(year, 'y').startOf('year').format('YYYY-MM-DD')
  }

  /**
   * 指定した日数を減算した日を返す
   * @returns YYYY-MM-DD
   */
  beforeDay = (day: number) => {
    return dayjs(this.#date).tz().subtract(day, 'd').format('YYYY-MM-DD')
  }

  /**
   * @returns yyyymmdd_hhmmss
   */
  toDateName = () => {
    return dayjs(this.#date).tz().format('YYYYMMDD_HHmmss')
  }

  /**
   * @param unit 指定した単位で判定する
   * @return {@boolean} 指定した日付がdateTimeの後に存在するかの真偽値
   */
  isBefore = (date: string, unit?: 'day' | 'week') => {
    if (unit) {
      return dayjs(this.#date).isBefore(date, unit)
    } else {
      return dayjs(this.#date).isBefore(date)
    }
  }

  /**
   * @param unit 指定した単位で判定する
   * @return {@boolean} 指定した日付がdateTimeの以前に存在するかの真偽値
   */
  isAfter = (date: string, unit?: 'day' | 'week') => {
    if (unit) {
      return dayjs(this.#date).isAfter(date, unit)
    } else {
      return dayjs(this.#date).isAfter(date)
    }
  }

  /**
   * @param unit 指定した単位で判定する
   * @return {@boolean} 指定した日付がdateTimeの以前（当日含む）に存在するかの真偽値
   */
  isSameOrAfter = (date: string, unit?: 'day' | 'week') => {
    if (unit) {
      return dayjs(this.#date).isSameOrAfter(date, unit)
    } else {
      return dayjs(this.#date).isSameOrAfter(date)
    }
  }

  /**
   * @number 指定した日付とdateTimeの日数差を算出
   */
  DaysDiff = (date: string) => {
    return dayjs(this.#date).diff(date, 'day')
  }

  /**
   * @param unit 指定した単位で判定する
   * @boolean 指定した日付と判定したい日が同日か
   */
  isSameDate = (date: string | Date, unit?: 'day') => {
    if (unit) {
      return dayjs(this.#date).isSame(new DateTime(date).#date, unit)
    } else {
      return dayjs(this.#date).isSame(new DateTime(date).#date)
    }
  }

  /**
   * 成人式の開催年を算出する
   * 例）
   * 生年月日：2005年1月1日 ~ 2005年4月1日 20年後の2025年が成人式開催年
   * 生年月日：2005年4月2日 ~ 2005年12月31日 21年後の2026年が成人式開催年
   * @returns {Number} 成人式開催年
   */
  成人式開催年 = () => {
    const year = this.#date.getFullYear()
    let comingOfAgeYear: number = 0

    // 指定した日付が4月2日 ~ 12月31日の範囲内かどうか
    const isBornLate = dayjs(this.#date).isBetween(
      `${year}-04-01`,
      `${year}-12-32`
    )

    if (isBornLate) {
      comingOfAgeYear = year + ComingOfAgeCeremonyYear.ADULTHOOD_AGE_OFFSET
    } else {
      comingOfAgeYear = year + ComingOfAgeCeremonyYear.ADULTHOOD_AGE_STANDARD
    }
    return comingOfAgeYear
  }

  /**
   * 商品の利用年度に該当するかどうかを算出する
   * 指定した日付が前年度4月1日 ~ 対象年度3月31日の範囲内かどうか
   * @param {availableYear} 商品の利用年度
   */
  is商品利用年度 = (availableYear: number): boolean => {
    return dayjs(this.#date).isBetween(
      `${availableYear - 1}-04-01`,
      `${availableYear}-03-31`,
      'day',
      '[]'
    )
  }

  /**
   * JK前撮り適用対象かどうかを算出する
   * 基準：見積／契約の作成日が高校3年生の9月1日より前
   * 対象期間の開始タイミングについては条件なし（極端に言えば0歳からOK）
   * 例）
   * 基準日が2024/09/01 → 2006/04/02以降に生まれた学生が対象
   * 2024年度の高校3年生は以下が対象
   * 2006/04/02-2006/12/31：遅生まれ +18年の9/1 → 2024/09/01を基準日とする
   * 2007/01/01-2007/04/01：早生まれ +17年の9/1 → 2024/09/01を基準日とする
   * @returns {Boolean} JK前撮り適用対象か
   */
  isJK前撮り適用対象 = (contractDate: string) => {
    const 契約日 = new DateTime(contractDate).#date
    const birthYear = this.#date.getFullYear()
    let HSgraduationYear = 0

    // 遅生まれ（指定した日付が4月2日 ~ 12月31日の範囲内かどうか）
    const isBornLate = dayjs(this.#date).isBetween(
      `${birthYear}-04-01`,
      `${birthYear}-12-32`
    )
    if (isBornLate) {
      HSgraduationYear = birthYear + 18
    } else {
      HSgraduationYear = birthYear + 17
    }

    const isJK前撮りApplicable = dayjs(契約日).isBetween(
      this.#date,
      `${HSgraduationYear}-09-01`
    )

    return isJK前撮りApplicable
  }

  /**
   * 小学生かどうかを算出する
   * 基準：契約作成時の使用日が小学校を卒業する年の3月31日までの方
   * 対象期間の開始タイミングについては条件なし（極端に言えば0歳からOK）
   * 例）
   * 生年月日：2011年1月1日 ~ 2011年4月1日(早生まれ) 12年後の2024年3/31まで小学生
   * 生年月日：2011年4月2日 ~ 2012年12月31日（遅生まれ） 13年後の2015年3/31まで小学生
   * @returns {Boolean} 小学生かどうか
   */
  is小学生 = (useDate: string) => {
    const 使用日 = new DateTime(useDate).#date
    const birthYear = this.#date.getFullYear()
    let ESgraduationYear = 0

    // 指定した日付が4月2日 ~ 12月31日の範囲内かどうか
    const isBornLate = dayjs(this.#date).isBetween(
      `${birthYear}-04-01`,
      `${birthYear}-12-32`
    )
    if (isBornLate) {
      ESgraduationYear = birthYear + 13
    } else {
      ESgraduationYear = birthYear + 12
    }

    return dayjs(使用日).isBetween(this.#date, `${ESgraduationYear}-03-32`)
  }

  /**
   * 美容室入場時間を取得する(範囲：00-23時、00-55分)
   * 1時間毎、5分毎で取得
   */
  美容室入場時間 = (date: string) => {
    const salonDateTime: salonDateTimeType = {
      baseDate: '',
      hours: [],
      minutes: [],
    }

    salonDateTime.baseDate = date
    for (let i = 0; i < 24; i++) {
      salonDateTime.hours.push(i.toString().padStart(2, '0'))
    }
    for (let i = 0; i < 60; i = i + 5) {
      salonDateTime.minutes.push(i.toString().padStart(2, '0'))
    }
    return salonDateTime
  }

  updateSalonDateTime = (date: string, key: string, value: string) => {
    const 年月日時分秒 = 'YYYY-MM-DD HH:mm:ss'
    if (date) {
      if (key === 'hour') {
        return dayjs(new DateTime(date).#date)
          .hour(parseInt(value))
          .format(年月日時分秒)
      } else {
        return dayjs(new DateTime(date).#date)
          .minute(parseInt(value))
          .format(年月日時分秒)
      }
    } else {
      if (key === 'hour') {
        return dayjs(new DateTime(date).#date)
          .hour(parseInt(value))
          .minute(0)
          .second(0)
          .format(年月日時分秒)
      } else {
        return dayjs(new DateTime(date).#date)
          .hour(0)
          .minute(parseInt(value))
          .second(0)
          .format(年月日時分秒)
      }
    }
  }

  /**
   * 一番早い日付を返却する
   * NOTE: 2024-01-01、2024/01/01とフォーマットが2種類あるため、
   * 区切り箇所を削除し数値比較で一番小さい値を返却
   * @param dates
   * @returns
   */
  getEarliestDate = (dates: string[]): string => {
    if (dates.length > 0) {
      const earliestDate = Math.min(
        ...dates.map((date) => Number(date.replace(/[\/\-]/g, '')))
      )
      return dayjs(String(earliestDate)).format()
    } else {
      return ''
    }
  }
}

export default DateTime
