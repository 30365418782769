import { ZoriBagState } from '../../../types'

const state = (): ZoriBagState => ({
  /**
   * NOTE: planLineZoriは草履におけるpatternLineと同様で、選択肢のidを保持する
   * NOTE: planLineBagはバッグにおけるpatternLineと同様で、選択肢のidを保持する
   * ↓保持される選択肢のindex
   * [0]種別
   * [1]形態 || 商品
   * [2]商品 || 単品レンタルor単品購入
   * [3]振袖レンタルだった場合 ||
   */
  planLineZori: [0, 0, 0, 0],
  planLineBag: [0, 0, 0, 0],
  productZori: {
    id: 0,
    post_number_code: '',
    product_name: '',
    disabled: false,
    price: {
      rental: 0,
      buy: 0,
      set_purchase: 0,
    },
  },
  productBag: {
    id: 0,
    post_number_code: '',
    product_name: '',
    disabled: false,
    price: {
      rental: 0,
      buy: 0,
      set_purchase: 0,
    },
  },
  inputZori: {
    productName: '',
    buyPrice: 0,
    rentalPrice: 0,
    rankupBuyPrice: 0,
    rankupRentalPrice: 0,
    singleBuy: 0,
    singleRental: 0,
    rentalPremiumPrice: 0,
    buyPremiumPrice: 0,
  },
  inputBag: {
    productName: '',
    buyPrice: 0,
    rentalPrice: 0,
    rankupBuyPrice: 0,
    rankupRentalPrice: 0,
    singleBuy: 0,
    singleRental: 0,
    rentalPremiumPrice: 0,
    buyPremiumPrice: 0,
  },
  priceZori: {
    buy: 0,
    rental: 0,
  },
  priceBag: {
    buy: 0,
    rental: 0,
  },
  isValidate: null,
  isPPDiscount: false,
  isPPAddingPriceZori: false,
  isPPAddingPriceBag: false,
})

export default state
