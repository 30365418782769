import { Restate } from '~/composable/estimate/repository/graduation/EstimateRestate'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { FormValues別衿_着物持込 } from '~/composable/estimate/viewModel/graduation/Fields/Fields別衿_着物持込'
import { FormValues別衿_着物レンタル } from '~/composable/estimate/viewModel/graduation/Fields/Fields別衿_着物レンタル'

export const useRestore別衿 = () => {
  const store着物レンタル =
    useGraduateStore<FormValues別衿_着物レンタル>('tab別衿_着物レンタル')
  const store着物持込 =
    useGraduateStore<FormValues別衿_着物持込>('tab別衿_着物持込')

  const restore別衿 = (restate: Restate) => {
    if (!restate.items.別衿) {
      return
    }

    const betsueri = restate.items.別衿

    if (betsueri.type === '着物持込') {
      const formValues: FormValues別衿_着物持込 = {
        betsueri種別: betsueri.optionId種別,
        betsueri長襦袢: betsueri.optionId長襦袢,
        betsueri単品レンタル: { id: 0, value: '' },
        betsueri単品レンタルフリー入力: null,
        betsueri単品購入: { id: 0, value: '' },
        betsueri単品購入フリー入力: null,
        betsueri見積選択: betsueri.optionId見積表示,
      }

      if (!betsueri.flags.is持込) {
        formValues.betsueri単品レンタルフリー入力 = betsueri.レンタル価格
        formValues.betsueri単品購入フリー入力 = betsueri.購入価格
      }

      store着物持込.setAll(formValues)
    }

    if (betsueri.type === '着物レンタル') {
      const formValues: FormValues別衿_着物レンタル = {
        betsueri種別: betsueri.optionId種別,
        betsueri形態: betsueri.optionId形態,
        betsueri長襦袢: betsueri.optionId長襦袢,
        betsueriレンタル価格: null,
        betsueri商品情報: { id: 0, value: '' },
        betsueriフリー入力: null,
      }

      if (betsueri.flags.isランクアップ) {
        formValues.betsueriフリー入力 = betsueri.レンタル価格
      }

      if (betsueri.flags.isセット内) {
        formValues.betsueriレンタル価格 = betsueri.レンタル価格
      }

      store着物レンタル.setAll(formValues)
    }
  }
  return { restore別衿 }
}
