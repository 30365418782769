import { GetterTree } from 'vuex'
import { RootState, DoublePhotoSettings } from '../../types'

const getters: GetterTree<DoublePhotoSettings, RootState> = {
  size: (state: DoublePhotoSettings) => {
    return state.settings.length
  },

  getPhotos: (state: DoublePhotoSettings) => {
    return state.settings
  },

  getIsValidate: (state: DoublePhotoSettings) => {
    return state.isValidate
  },

  getInput: (state: DoublePhotoSettings) => {
    return state.input
  },

  // 全体写真
  getWPhoto: (state: DoublePhotoSettings) => {
    return state.settings[0].photos
  },

  // 衿元写真
  getNeckPhoto: (state: DoublePhotoSettings) => {
    return state.settings[1].photos
  },
  // 写真をアップロードするか否か
  getPhotoUpload: (state: DoublePhotoSettings) => {
    return state.photoUpload
  },
}

export default getters
