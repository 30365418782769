import { computed, Ref } from '@nuxtjs/composition-api'
import { EstimateDetail, Items } from '~/api/estimateRepository'
import { usePrice } from '~/composable/general/Price'
import { Enum髪飾り } from '~/constants/estimate/graduationOptions'
import ProductId from '~/constants/enums/productClassification'
import { getSortedItemsByOrder } from '~/utils/utils'

type Kamikazari = { optionId種別: number | null; 単品購入価格: string | null }

export const useRestate髪飾り = (estimate: Ref<EstimateDetail | undefined>) => {
  return computed(() => {
    if (!estimate.value?.items) {
      return null
    }
    const kamikazaris: Kamikazari[] = []

    const kamikazariItems = getSortedItemsByOrder(
      estimate.value.items,
      ProductId.kamikazari
    )

    if (!kamikazariItems.length) {
      return null
    }
    kamikazariItems.forEach((item) => {
      kamikazaris.push(formatKamikazari(item))
    })
    return kamikazaris
  })
}

const formatKamikazari = (item: Items): Kamikazari => {
  const { 種別 } = Enum髪飾り
  const { paramToString } = usePrice()
  const Kamikazari: Kamikazari = {
    optionId種別: null,
    単品購入価格: null,
  }

  if (item.plan === 種別.お持込) {
    Kamikazari.optionId種別 = 種別.お持込
  } else if (item.plan === 種別.単品購入) {
    Kamikazari.optionId種別 = 種別.単品購入
  }

  if (item?.purchase_price) {
    Kamikazari.単品購入価格 = paramToString(item.purchase_price)
  }

  return Kamikazari
}
