import { FormValues二尺袖 } from '~/composable/estimate/viewModel/graduation/Fields/Fields二尺袖'

//不明な点がある場合、READMEをご覧ください
export const getInitialStore = (): FormValues二尺袖 => {
  return {
    nishakuIsカタログ商品: null,
    nishaku仕立上り検索: null,
    nishaku商品情報_価格選択: null,
    nishaku商品情報_価格入力: null,
    nishakuカタログ検索: null,
    nishakuカタログ_価格編集: null,
    nishaku保留: null,
  }
}

const state = (): { fields: FormValues二尺袖 } => ({
  fields: getInitialStore(),
})

export default state
