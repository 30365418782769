import { computed } from '@nuxtjs/composition-api'
import { EstimatePhotoSettings, ProductState } from '~/store/types'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { getState試着写真 } from '../StateOtherTabs'

export const useState試着写真 = () => {
  const values試着写真 = useComingStore<EstimatePhotoSettings>(
    'WphotoPlan/WphotoSetting'
  ).getAll()
  return computed(() => getState試着写真(values試着写真.value))
}

export const useState商品設定 = () => {
  const values商品設定 = useComingStore<ProductState>(
    'WphotoPlan/WphotoProducts'
  ).getAll()

  return computed(() => {
    const { products } = values商品設定.value
    const prod帯揚げ = products.find((p) => p.id === 1)
    const prod重ね衿 = products.find((p) => p.id === 2)

    return {
      帯揚げ: prod帯揚げ?.count ?? 0,
      重ね衿: prod重ね衿?.count ?? 0,
    }
  })
}
