

























import {
  defineComponent,
  watch,
  ref,
  useStore,
  useRouter,
} from '@nuxtjs/composition-api'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { useMemo } from '~/composable/api/useMemo'
import { MemoStateGraduate } from '~/store/types'
import AppCheckbox from './AppCheckbox.vue'
import AppIcon from './AppIcon.vue'
import IconX from './icons/IconX.vue'
import { useEstimateTypeFlags } from '~/composable/estimate/domain/EstimateTypeFlags'

export default defineComponent({
  components: {
    AppCheckbox,
    AppIcon,
    IconX,
  },
  setup() {
    const { in卒業式見積, in成人式見積, in参列見積, in単品サービス見積 } =
      useEstimateTypeFlags()
    const storeMemo卒業式 = useGraduateStore<MemoStateGraduate>('memo')
    const store = useStore()
    const isVisible = ref(false)
    const checked = ref(false)
    const inputTextarea = ref('')
    const router = useRouter()
    const { patchMemo, postMemo } = useMemo()
    const coaMemoStorePath = 'Estimate/ComingOfAgeCeremony/Memo/'
    const attendanceMemoStorePath = 'Estimate/Attendance/Memo/'
    const loginStorePath = 'LoginUser/getUser'
    const shopStorePath = 'SettingShop/getShop'
    const singleItemServiceStorePath = 'SingleItemService/Memo/'

    const openModal = () => {
      isVisible.value = true
      document.body.style.overflow = 'hidden'
    }

    const closeModal = async () => {
      isVisible.value = false
      const scrollY = document.body.style.top
      document.body.style.overflow = 'auto'
      window.scrollTo(0, -parseInt(scrollY || '0'))

      //* メモを保存する処理 ※参列は見積作成APIに乗せるのでここでは保存しない
      if (in卒業式見積.value) {
        await graduationPostMemo()
      } else if (in成人式見積.value) {
        await comingOfAgePostMemo()
      }
    }

    const graduationPostMemo = async () => {
      const { customer_id, memo_id } = router.currentRoute.query
      const customerId = Number(customer_id)
      const memoId = Number(memo_id)

      if (!storeMemo卒業式.get<'text'>('text').value) {
        return
      }
      const memoData = {
        staff_id: store.getters[loginStorePath]?.staff_id,
        shop_id: store.getters[shopStorePath]?.id,
        is_chance: storeMemo卒業式.get<'is_chance'>('is_chance').value,
        body: storeMemo卒業式.get<'text'>('text').value,
        estimate_id: router.currentRoute.params?.estimate_id,
      }

      const storeMemoId = storeMemo卒業式.get<'memo_id'>('memo_id').value ?? 0

      //メモを作成していた場合の更新処理
      if (customerId && memoId) {
        const patchMemoId = memoId ? memoId : storeMemoId
        await patchMemo(customerId, patchMemoId, memoData)
        return
      }

      //メモを作成してなかった場合の作成の処理
      if (customerId && memoId === 0) {
        await postMemo(customerId, memoData, '卒業式見積作成', 'graduation')
        const id = storeMemo卒業式.get<'memo_id'>('memo_id').value ?? 0

        //* FIXME パスを更新してリロード対策してます
        router.push({
          query: {
            memo_id: String(id),
            customer_id: customer_id,
          },
        })
        return
      }
    }

    const comingOfAgePostMemo = async () => {
      const { customer_id, memo_id, estimate_id, branch } =
        router.currentRoute.query
      const customerId = Number(customer_id)
      const memoId = Number(memo_id)
      const storeMemoId = store.getters[`${coaMemoStorePath}getMemoId`] ?? 0

      if (!store.getters[`${coaMemoStorePath}getText`]) {
        return
      }

      const memoData = {
        staff_id: store.getters[loginStorePath]?.staff_id,
        shop_id: store.getters[shopStorePath]?.id,
        is_chance: store.getters[`${coaMemoStorePath}getChance`],
        body: store.getters[`${coaMemoStorePath}getText`],
        estimate_id: router.currentRoute.params?.estimate_id,
      }

      //メモを作成していた場合の更新処理
      if (customerId && memoId) {
        const patchMemoId = memoId ? memoId : storeMemoId
        await patchMemo(customerId, patchMemoId, memoData)
        return
      }

      //メモを作成してなかった場合の作成の処理
      if (customerId && memoId === 0) {
        await postMemo(customerId, memoData, '成人式見積作成', 'comingOfAge')

        //* FIXME パスを更新してリロード対策してます
        router.push({
          query: {
            estimate_id: estimate_id,
            branch: branch,
            memo_id: store.getters[`${coaMemoStorePath}getMemoId`],
            customer_id: customer_id,
          },
        })
        return
      }
    }

    watch(isVisible, () => {
      if (isVisible.value) {
        if (in卒業式見積.value) {
          checked.value = storeMemo卒業式.get<'is_chance'>('is_chance').value
          inputTextarea.value = storeMemo卒業式.get<'text'>('text').value
        } else if (in成人式見積.value) {
          checked.value = store.getters[`${coaMemoStorePath}getChance`]
          inputTextarea.value = store.getters[`${coaMemoStorePath}getText`]
        } else if (in参列見積.value) {
          checked.value = store.getters[`${attendanceMemoStorePath}getChance`]
          inputTextarea.value =
            store.getters[`${attendanceMemoStorePath}getText`]
        } else if (in単品サービス見積.value) {
          checked.value =
            store.getters[`${singleItemServiceStorePath}getChance`]
          inputTextarea.value =
            store.getters[`${singleItemServiceStorePath}getText`]
        }
      }
    })

    watch([checked, inputTextarea], () => {
      if (in卒業式見積.value) {
        storeMemo卒業式.set('is_chance', checked.value)
        storeMemo卒業式.set('text', inputTextarea.value)
      } else if (in成人式見積.value) {
        store.dispatch(`${coaMemoStorePath}setChance`, checked.value)
        store.dispatch(`${coaMemoStorePath}setText`, inputTextarea.value)
      } else if (in参列見積.value) {
        store.commit(`${attendanceMemoStorePath}setChance`, checked.value)
        store.commit(`${attendanceMemoStorePath}setText`, inputTextarea.value)
      } else if (in単品サービス見積.value) {
        store.commit(`${singleItemServiceStorePath}setChance`, checked.value)
        store.commit(
          `${singleItemServiceStorePath}setText`,
          inputTextarea.value
        )
      }
    })

    return {
      isVisible,
      checked,
      openModal,
      closeModal,
      inputTextarea,
    }
  },
})
