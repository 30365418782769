import { TabNaviState } from '~/store/Estimate/GraduationCeremony/types'

export const getInitialStore = (): TabNaviState => {
  return {
    tabs: [],
    nextUrl: null,
    validatedTabs: [],
    isNextButton: false,
    isAllValidateTabs: false,
    isBeforeConfirmation: false,
  }
}

const state = (): TabNaviState => getInitialStore()

export default state
