import { CustomerState } from '../../../types'

const state = (): CustomerState => ({
  name: '',
  customer: null,
  isValidate: {
    name: null,
    number: null,
  },
  visit_motives: [], // 来店動機
  guidanceEmail: null, // メールアドレス
})

export default state
