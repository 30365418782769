import { computed, Ref } from '@nuxtjs/composition-api'
import { EstimateDetail, Items } from '~/api/estimateRepository'
import { ItemPlan } from '~/constants/enums/itemPlan'
import ProductId from '~/constants/enums/productClassification'
import { PLAN } from '~/constants/estimate/comingOfAge'
import { getSortedItemsByOrder } from '~/utils/utils'
import { useRestatePlan } from '../RestatePlan'
import { PriceTableType } from '~/constants/enums/priceTableType'

type Kamikazari = { optionId種別: number; 単品購入価格: number }

export const useRestate髪飾り = (estimate: Ref<EstimateDetail | undefined>) => {
  const restateプラン = useRestatePlan(estimate)

  return computed(() => {
    if (!estimate.value?.items || !restateプラン.value) {
      return null
    }

    // 髪飾り フルセット割引
    const 髪飾りフルセット割引 =
      estimate.value?.reference_price_information?.find(
        ({ type }) => type === PriceTableType.髪飾りフルセット割引
      )?.amount

    const kamikazaris: Kamikazari[] = []
    const kamikazariItems = getSortedItemsByOrder(
      estimate.value.items,
      ProductId.kamikazari
    )
    if (!kamikazariItems.length) {
      // レスポンスに髪飾りが含まれていなければ空配列を返す
      return kamikazaris
    }

    if (restateプラン.value.flags.isフルセットプラン) {
      // フルセットの1個目だけMAX5500円引きが発生する
      kamikazariItems.forEach((item, i) => {
        if (i === 0) {
          kamikazaris.push(formatKamikazari(item, true, 髪飾りフルセット割引))
        } else {
          kamikazaris.push(formatKamikazari(item, false))
        }
      })
    } else {
      kamikazariItems.forEach((item) => {
        kamikazaris.push(formatKamikazari(item))
      })
    }

    return kamikazaris
  })
}

const formatKamikazari = (
  item: Items,
  discounted: boolean = false,
  kamikazariDiscount?: number | null
): Kamikazari => {
  const purchasePrice = Number(item.purchase_price) ?? 0
  const is持込 = item.plan === ItemPlan.bringIn

  const 単品購入価格 =
    discounted && kamikazariDiscount && !is持込
      ? purchasePrice + kamikazariDiscount
      : purchasePrice
  return {
    optionId種別: is持込 ? PLAN.BRING_IN : PLAN.SINGLE_BUY_PRICE,
    単品購入価格,
  }
}
