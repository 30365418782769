/** constants */
import {
  CHECKBOX_TYPES,
  CHECKBOX_STATUSES,
  CheckboxType,
} from '~/constants/checklist/checkbox'
import {
  CHECKLIST_CONTRACT_TYPES,
  CONTRACT_CHECKLIST_TYPES,
  ContractChecklistType,
} from '~/constants/checklist/contract'

/** types */
import type { CompletionCheckbox } from '~/api/checklistRepository'
import type { ChecklistCompletion } from '~/store/Checklists/Main/state'
import type { ChecklistRow, State } from '~/store/Checklists/Main/state'

/** utils */
import DateTime from '~/utils/dateTime'
import { ContractType } from '~/constants/enums/contractType'

const CHECKBOX_TYPE_COMPLETION_MAP: Record<
  CheckboxType,
  keyof ChecklistCompletion
> = {
  [CHECKBOX_TYPES.MAINTENANCE_AND_FITTING]: 'maintenanceTailoring',
  [CHECKBOX_TYPES.ARRIVAL_CHECK]: 'arrival',
  [CHECKBOX_TYPES.PRE_PHOTOSHOT_HANDOVER]: 'prePhotosInAdvance',
}

/**
 * 古いチェック完了と新しいチェック完了の状態をマージ処理
 * 例えば、「到着チェック」変更がなければ、古いチェック完了の状態から日付を使います
 * NOTE:
 * oldCompletion: { maintenanceTailoring: {status: 2, checkCompletedDate: "2024-02-08"}, arrival: {status: 1}, prePhotosInAdvance: {status: 1} },
 * newCompletion: { maintenanceTailoring: {status: 2, checkCompletedDate: "2024-02-09"}, arrival: {status: 2, checkCompletedDate: "2024-02-09"}, prePhotosInAdvance: {status: 1} }
 *        返却値:  { maintenanceTailoring: {status: 2, checkCompletedDate: "2024-02-08"}, arrival: {status: 2, checkCompletedDate: "2024-02-09"}, prePhotosInAdvance: {type: 3, status: 1}}
 */
const _mergeCompletionRecords = (
  oldCompletion: ChecklistCompletion,
  newCompletion: ChecklistCompletion
): ChecklistCompletion => {
  const entries = Object.entries(oldCompletion).map(([key, oldRecord]) => {
    const completionKey = key as keyof ChecklistCompletion
    const newRecord = newCompletion[completionKey]

    if (
      oldRecord.status === CHECKBOX_STATUSES.CHECKED &&
      newRecord.status === CHECKBOX_STATUSES.CHECKED
    ) {
      return [
        completionKey,
        { ...newRecord, checkCompletedDate: oldRecord.checkCompletedDate },
      ]
    } else {
      return [completionKey, { ...newRecord }]
    }
  })

  return Object.fromEntries(entries)
}

/**
 * サーバーのチェック完了の状態とフロント側で使えるチェック完了の状態をマージ処理
 * NOTE:
 * list:       [{type: 1, check_completed_date: "2024-02-08"}]
 * completion: {maintenanceTailoring: {status: 2, checkCompletedDate: "2024-02-09"}, arrival: {status: 1}, prePhotosInAdvance: {status: 1}}
 * 返却値:      {maintenanceTailoring: {status: 2, checkCompletedDate: "2024-02-08"}, arrival: {status: 1}, prePhotosInAdvance: {status: 1}}
 */
const _mergeCompletionStatusWithList = (
  list: CompletionCheckbox[],
  completion: ChecklistCompletion
) => {
  const copy = Object.fromEntries(
    Object.entries(completion).map(([key, value]) => [key, { ...value }])
  )

  list.forEach((checkbox) => {
    const key = CHECKBOX_TYPE_COMPLETION_MAP[checkbox.type]
    copy[key].checkCompletedDate = checkbox.check_completed_date ?? undefined
  })

  return copy as ChecklistCompletion
}

/**
 * check_completed_listの形式からChecklistCompletionへ変換する関数 (typeごとのステータス)
 */
export const recalculateCompletionCheck = (
  products: ChecklistRow[],
  mergeWith: ChecklistCompletion | CompletionCheckbox[]
): ChecklistCompletion => {
  const isArray = Array.isArray(mergeWith)
  const isNotEmpty = isArray
    ? mergeWith.length > 0
    : Object.keys(mergeWith).length > 0

  const entries = Object.values(CHECKBOX_TYPE_COMPLETION_MAP).map((key) => {
    const staffId = isNotEmpty
      ? isArray
        ? (mergeWith as CompletionCheckbox[]).find(
            (item) => key === CHECKBOX_TYPE_COMPLETION_MAP[item.type]
          )?.staff_id
        : (mergeWith as ChecklistCompletion)[key]?.staffId
      : null
    return [
      key,
      {
        status: CHECKBOX_STATUSES.NOT_CHECKABLE,
        staffId: staffId,
      },
    ]
  })

  const initialValues: ChecklistCompletion = Object.fromEntries(entries)

  // productsの情報を反復して、チェックボックスごとのステータスを更新する
  const updatedCompletion = products.reduce(
    (accum: ChecklistCompletion, next) => {
      next.checkboxInfo.forEach((checkbox) => {
        const key = CHECKBOX_TYPE_COMPLETION_MAP[checkbox.checkType]
        accum[key].staffId = accum[key].staffId
        switch (checkbox.checkStatus) {
          case CHECKBOX_STATUSES.EMPTY:
            accum[key].status = checkbox.checkStatus
            break
          case CHECKBOX_STATUSES.DISABLED:
            if (accum[key].status === CHECKBOX_STATUSES.NOT_CHECKABLE)
              accum[key].status = checkbox.checkStatus
            break
          case CHECKBOX_STATUSES.CHECKED:
            if (accum[key].status !== CHECKBOX_STATUSES.EMPTY) {
              accum[key].status = CHECKBOX_STATUSES.CHECKED
              accum[key].checkCompletedDate = new DateTime().toDateString()
            } else {
              accum[key] = {
                status: accum[key].status,
                staffId: accum[key].staffId,
              }
            }
        }
      })
      return accum
    },
    initialValues
  )

  if (Array.isArray(mergeWith)) {
    // 復元時
    if (mergeWith.length) {
      return _mergeCompletionStatusWithList(
        mergeWith as CompletionCheckbox[],
        updatedCompletion
      )
    } else {
      return updatedCompletion
    }
  } else {
    return _mergeCompletionRecords(
      mergeWith as ChecklistCompletion,
      updatedCompletion
    )
  }
}

export const toCheckCompletedList = (completion: ChecklistCompletion) => {
  const checkboxes = [
    CHECKBOX_TYPES.MAINTENANCE_AND_FITTING,
    CHECKBOX_TYPES.ARRIVAL_CHECK,
    CHECKBOX_TYPES.PRE_PHOTOSHOT_HANDOVER,
  ]

  return checkboxes
    .map((type) => {
      const key = CHECKBOX_TYPE_COMPLETION_MAP[type]
      const { checkCompletedDate, status, staffId } = completion[key]
      return {
        type,
        check_completed_date:
          status === CHECKBOX_STATUSES.CHECKED ? checkCompletedDate : undefined,
        staff_id: staffId,
      }
    })
    .filter((record) => record.check_completed_date) as CompletionCheckbox[]
}

/**
 * 選択されたタブのチェックリスト記録をステート情報で探すヘルパー
 * 参列の場合checklistIdはestimateId、他の場合checklistIdはcontractType
 */
export const selectChecklist = (state: State) => {
  if (state.data) {
    if (state.data.type === CHECKLIST_CONTRACT_TYPES.ATTENDANCE) {
      const selectedEstimateId = state.selectedChecklistId
      return state.data.checklistSetting.find(
        (checklist) => checklist.estimateId === selectedEstimateId
      )
    } else {
      const selectedContractType = state.selectedChecklistId
      return state.data.checklistSetting.find(
        (checklist) => checklist.contractType === selectedContractType
      )
    }
  } else return null
}

/**
 * ContractChecklistTypeを元に契約モード区分を返却する
 */
export const getContractType = (contractType: ContractChecklistType) => {
  switch (contractType) {
    case CONTRACT_CHECKLIST_TYPES.GRADUATION:
      return ContractType.graduationCeremony
    case CONTRACT_CHECKLIST_TYPES.ATTENDANCE:
      return ContractType.attendance
    case CONTRACT_CHECKLIST_TYPES.SINGLE_ITEM:
      return ContractType.singleItemService
    case CONTRACT_CHECKLIST_TYPES.CLEANING:
      return ContractType.cleaning
    default:
      return ContractType.comingOfAgeCeremony
  }
}
