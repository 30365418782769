import { MutationTree } from 'vuex'
import { CustomerReadyState } from '../../types'

const mutations: MutationTree<CustomerReadyState> = {
  setSelectedContents: (state, selectedContents) => {
    state.selectedContents = selectedContents
  },

  setInput: (state, inputs) => {
    state.input = inputs
  },

  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },

  delete: (state) => {
    state.selectedContents = []
    state.input = {
      otherItems: [],
    }
    state.isValidate = null
  },
}

export default mutations
