import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { FURISODE_PLAN } from '~/constants/estimate/comingOfAge'
import { FurisodeState } from '~/store/types'

export const useRestore振袖 = () => {
  const store = useComingStore<FurisodeState>('Furisodes')

  const restore振袖 = (
    hurisode: Restate['hurisode'],
    plan: Restate['plan'],
    contractId: number = 0
  ) => {
    if (!hurisode || !plan) {
      return
    }
    const {
      is仕立て上り検索結果,
      isプロパー商品,
      isカタログ検索結果,
      is対象年度外オーダーレンタル,
    } = hurisode.flags
    const { isお写真のみプラン, is振袖持込 } = plan.flags

    if (is振袖持込) {
      return // NOTE: 振袖持込の時は振袖タブ非表示
    }

    /* 初期値を設定 ※必ず入力される値はここで設置 */
    const formValues: FurisodeState = {
      /**
       * NOTE: selections = patternLine
       * ↓保持される選択肢のindex
       * [0]商品の指定方法
       * [1]裄補正するか
       * [2]どちらのレンタルか || 袖補正するか
       */
      selections: [0, 0, 0],
      product: null,
      input: {
        productName: '',
        rentalPrice: 0,
      },
      price: {
        buy: 0,
        rental: 0,
        id: 0, // フリー入力を選択
      },
      isFree: true, // フリー入力を選択
      isFurisodeOnHold: false, // 保留状態では見積作成できない仕様のため、復元時においては振袖保留のFlagは常にfalse
      isValidate: null,
      catalogType: null,
      contractId,
      isOrderOutsideTargetYear: false,
      properProductId: null,
    }

    formValues.catalogType = (() => {
      switch (hurisode.optionIdどちらのレンタル) {
        case FURISODE_PLAN.ORDER_RENTAL:
          return 'オーダー'
        case FURISODE_PLAN.SHITATE_RENTAL:
          return '仕立て上がり'
        case FURISODE_PLAN.CUSTOM_RENTAL:
          return 'お誂え'
        default:
          return null
      }
    })()

    /* 以下で入力内容の条件に基づき、必要な項目を埋めていく */
    formValues.price.rental = hurisode.レンタル価格
    if (!isお写真のみプラン) {
      formValues.selections[0] = hurisode.optionIdOf商品指定方法選択
      formValues.price.buy = hurisode.購入価格
    }

    if (is仕立て上り検索結果 && hurisode.仕立て上り検索結果[0]) {
      formValues.product = {
        type: 'finished',
        restored: true,
        ...hurisode.仕立て上り検索結果[0],
      }
      formValues.selections[1] = hurisode.optionId裄補正
      formValues.selections[2] = hurisode.optionId袖補正
    }

    if (isカタログ検索結果 && hurisode.カタログ検索結果) {
      formValues.product = {
        type: 'catalog_furisode',
        restored: true,
        ...hurisode.カタログ検索結果,
      }
      formValues.selections[2] = hurisode.optionIdどちらのレンタル
    }

    if (isプロパー商品) {
      formValues.input.productName = hurisode.商品名
      formValues.properProductId = hurisode.プロパー商品ID ?? null
    }

    formValues.isOrderOutsideTargetYear = is対象年度外オーダーレンタル || false

    /* ストアに内容を保存する */
    store.setOf<FurisodeState['selections']>(
      'setSelection',
      formValues.selections
    )
    store.setOf<FurisodeState['product']>('setProduct', formValues.product)
    store.setOf<FurisodeState['input']>('setInput', formValues.input)
    store.setOf<FurisodeState['price']>('setPrice', formValues.price)
    store.setOf<FurisodeState['catalogType']>(
      'setCatalogType',
      formValues.catalogType
    )
    store.setOf<FurisodeState['isFree']>('setIsFree', formValues.isFree)
    store.setOf<FurisodeState['contractId']>('setContractId', contractId)
    store.setOf<FurisodeState['isOrderOutsideTargetYear']>(
      'setIsOrderOutsideTargetYear',
      formValues.isOrderOutsideTargetYear
    )
    store.setOf<FurisodeState['properProductId']>(
      'setProperProductId',
      formValues.properProductId
    )
    store.setIsValidate(false)
  }
  return { restore振袖 }
}
