import { Ref, ref, computed } from '@nuxtjs/composition-api'

// ** types **
import type {
  SingleItemServiceContractDetail,
  ProductTypeIdList,
  SingleItemServiceEstimateDetail,
} from '~/api/singleItemRepository'
import type { State } from '~/store/SingleItemService/Main/state'
import type { KimonoSearchResult } from '~/composable/api/useSearchProduct'

type HasDelivery =
  SingleItemServiceContractDetail['products'][number]['has_delivery']

type SingleItemServiceDetailUnion =
  | SingleItemServiceContractDetail
  | SingleItemServiceEstimateDetail

// ** enums **
import ProductClassification from '~/constants/enums/productClassification'
import { PRODUCT_SETTING } from '~/features/singleItemService/productSetting/enums'
import { KEYWORD_TYPES } from '~/composable/api/useSearchProduct'
import { EstimateAddition } from '~/constants/enums/estimateAddition'
import { SingleItemPlan } from '~/constants/enums/singleItemPlan'
import { SINGLE_ITEMS } from '~/features/singleItemService/masterData'

// ** utils **
import DateTime from '~/utils/dateTime'
import { usePrice } from '~/composable/general/Price'

// ** composable **
import { useMasterData } from '~/composable/api/useMasterData'
import { useCustomer } from '~/composable/api/useCustomer'
import { useSearchProduct } from '~/composable/api/useSearchProduct'

// ** constants **
import { FREE_INPUT } from '~/features/singleItemService/productSetting/components/AppSelectionPrice.vue'
import {
  現品,
  こちらでご準備いたします,
} from '~/features/singleItemService/productSetting/composable/useSingleItemOption'
import { getAge } from '~/utils/utils'
import { RestoreType } from '../restore/useRestore'

/**
 * @note
 * 単品契約詳細or単品見積詳細APIから取得した場合にデータを復元する処理
 * 単品契約書にあり単品見積には存在しないものリスト ↓↓↓↓
 * products.corrections
 * products.has_delivery
 * return
 * delivery
 *
 * 詳しくはAPI仕様書(2023/05/24時点)をご確認ください
 * https://docs.google.com/spreadsheets/d/1squ0uolJXm0i2LS4-JMBqQCxZfoHEgEKgpd7Iec8SYw/edit#gid=721026106
 */
export const useRestateProductSetting = (
  data: Ref<SingleItemServiceDetailUnion | undefined>,
  restoreType: RestoreType //復元するサービス
) => {
  const isContractForEstimateRestore = 'contractForEstimate' === restoreType
  const { shopList, fetchShopList } = useMasterData()
  const { customerList, fetchCustomerList } = useCustomer()
  const {
    customerList: contractorList,
    fetchCustomerList: fetchContractorList,
  } = useCustomer()
  const { fetchFinishedKimonoList } = useSearchProduct()
  const { toString } = usePrice()

  const isOnlyRecipt = computed(() => {
    if (data?.value && 'signature_url' in data.value) {
      return !data.value.signature_url
    }
    return false
  })

  const restateProductSetting = (): State['data']['productSetting'] => {
    const contractor = getContractorById()
    const customer = getCustomerById()
    const age = getAge(customer?.birthdate ?? null)
    const customers = (() => {
      switch (restoreType) {
        case 'estimate':
          return { customer: customer, contractor: null }
        case 'contractForEstimate':
          return { customer, contractor: age >= 20 ? customer : null }
        case 'contract':
          return { customer, contractor }
        default:
          return { customer: null, contractor: null }
      }
    })()
    return {
      singleItems: formatSingleItems(data.value?.products),
      photos: data.value?.item_photo_url
        ? [
            {
              remoteSrc: data.value.item_photo_url,
              isSelected: false,
            },
          ]
        : [],
      delivery: formatDelivery(data.value),
      returnInfo: formatReturnInfo(data.value),
      formType: isOnlyRecipt.value
        ? PRODUCT_SETTING.FORM_TYPE.領収書のみ
        : PRODUCT_SETTING.FORM_TYPE.契約書,
      ...customers,
      email: null,
      isTomonokaiApplied: null, // 友の会ポイントを利用する場合に使用するフラグ、領収書発行時のみの為nullで復元
    }
  }

  const formatSingleItems = (
    products: SingleItemServiceContractDetail['products'] | undefined
  ): State['data']['productSetting']['singleItems'] => {
    const singleItems =
      products?.filter(
        ({ product_type_id }) =>
          !ProductClassification.getSingleItemAccessories().includes(
            product_type_id
          )
      ) ?? []

    productTypeIds.value = []

    return singleItems.map((items) => {
      const {
        plan_id,
        product_type_id,
        product_id,
        product_name,
        delivery_period,
        single_rental_price,
        single_purchase_price,
        price_type,
        remarks,
        product_size_id,
        additions,
        measurement,
        check_list_branch_number,
      } = items

      const deliveryStatus =
        'has_delivery' in items
          ? (items.has_delivery as HasDelivery) ?? null
          : null

      const product = (() => {
        if (product_name === こちらでご準備いたします.text) {
          return こちらでご準備いたします
        }

        // NOTE: 契約書作成エラー回避の為、留袖はproduct_idをnullでリクエストしており、product_nameの一致でproduct_idを格納させる
        if (!product_id && product_type_id === ProductClassification.tomesode) {
          let tomesodeInfo = {
            id: 0,
            text: '',
            value: '',
          }

          const 留袖 = SINGLE_ITEMS.find(
            ({ product_type_id }) =>
              product_type_id === ProductClassification.tomesode
          )

          留袖?.products.forEach((product) => {
            if (product.product_name === product_name) {
              tomesodeInfo = {
                id: product.product_id ?? 0,
                text: product_name,
                value: '',
              }
            }
          })

          return tomesodeInfo
        }

        // NOTE: 契約書作成エラー回避の為、喪服もproduct_idをnullでリクエストしており、product_nameの一致でproduct_idを格納させる
        if (!product_id && product_type_id === ProductClassification.mohuku) {
          let mohukuInfo = {
            id: 0,
            text: '',
            value: '',
          }

          const 喪服 = SINGLE_ITEMS.find(
            ({ product_type_id }) =>
              product_type_id === ProductClassification.mohuku
          )

          喪服?.products.forEach((product) => {
            if (product.product_name === product_name) {
              mohukuInfo = {
                id: product.product_id ?? 0,
                text: product_name,
                value: '',
              }
            }
          })

          return mohukuInfo
        }

        const 現品id = Number(
          `${PRODUCT_SETTING.PRODUCTS.現品}${product_type_id}`
        )
        if (!product_id && product_name) {
          return {
            ...現品,
            id: 現品id,
          }
        }

        return product_type_id !== ProductClassification.obi
          ? {
              id: product_id ?? 0,
              text: product_name && product_id ? product_name : '',
              value: '',
            }
          : {
              id: 0,
              text: '',
              value: '',
            }
      })()

      const { 帯仕立て, 別衿加工 } = formatAdditions(additions, plan_id)
      const { shoulder, sleeveWidth, sleeveHeight } = formatCorrections(items)

      return {
        planId: plan_id ?? null,
        productTypeId: product_type_id,
        orderNumber: computeOrderNumber(product_type_id),
        kimonoSearchResult:
          product_type_id === ProductClassification.obi
            ? findObiProductById(product_id) ?? null
            : null,
        product,
        productName:
          !product_id &&
          product_name &&
          product_name !== こちらでご準備いたします.text
            ? product_name
            : null,
        deliveryPeriod: delivery_period ?? null,
        checkListBranchNumber: check_list_branch_number,
        price: FREE_INPUT,
        singleRentalPrice:
          single_rental_price || single_rental_price === 0
            ? toString(single_rental_price)
            : null,
        singlePurchasePrice:
          single_purchase_price || single_purchase_price === 0
            ? toString(single_purchase_price)
            : null,
        priceType:
          (single_rental_price && single_purchase_price) ||
          !(single_rental_price === 0 && single_purchase_price === 0)
            ? price_type
            : null,
        remarks: remarks ?? null,
        deliveryStatus,
        productSize: product_size_id ?? null,
        is帯仕立て代必要: 帯仕立て?.isNeed ?? null,
        帯仕立て代: 帯仕立て?.price ?? '¥11,000',
        is別衿縫い付け必要: 別衿加工?.isNeed ?? null,
        別衿加工代: 別衿加工?.price ?? '¥5,500',
        footSize: measurement?.foot_size ? String(measurement.foot_size) : null,
        bust: measurement?.bust ? String(measurement.bust) : null,
        hip: measurement?.hip ? String(measurement.hip) : null,
        height: measurement?.height ? String(measurement.height) : null,
        shoulder,
        sleeveWidth,
        sleeveHeight,
        isValid: !isContractForEstimateRestore,
      }
    })
  }

  const findShopById = (shopId: number | undefined) => {
    const shop = shopList.value.find(({ id }) => id === shopId)
    return shop ?? null
  }
  // orderNumber算出処理
  const productTypeIds = ref<ProductTypeIdList[]>([])
  const computeOrderNumber = (productTypeId: ProductTypeIdList) => {
    productTypeIds.value.push(productTypeId)
    return productTypeIds.value.filter((id) => id === productTypeId).length
  }

  // ***** 帯の商品データ取得関連 *****
  const obiSingleItemsWithProductId = computed(
    () =>
      data.value?.products.filter(
        ({ product_type_id, product_id }) =>
          product_type_id === ProductClassification.obi && product_id
      ) ?? []
  )

  const obiProductList = ref<KimonoSearchResult[]>([])

  const fetchObiProducts = async () => {
    obiProductList.value = []

    const promises = obiSingleItemsWithProductId.value.map(({ product_id }) => {
      return new Promise<void>(async (resolve) => {
        const finishedKimonoList = await fetchFinishedKimonoList({
          // NOTE: obiSingleItemsWithProductIdの処理内でproduct_idでフィルターしているため
          keyword: product_id!,
          keyword_types: [KEYWORD_TYPES.PRODUCT_ID],
          product_type_id: ProductClassification.obi,
        })
        if (finishedKimonoList?.length)
          obiProductList.value.push(...finishedKimonoList)

        resolve()
      })
    })

    await Promise.all(promises)
  }

  const findObiProductById = (productId: number | null) => {
    if (!productId) return null

    const obiProduct = obiProductList.value.find(
      ({ product_id }) => product_id === productId
    )
    return obiProduct ?? null
  }
  // ***** end *****

  const formatAdditions = (
    additions:
      | SingleItemServiceContractDetail['products'][number]['additions']
      | SingleItemServiceEstimateDetail['products'][number]['additions'],
    planId: SingleItemServiceContractDetail['products'][number]['plan_id']
  ) => {
    const 帯仕立て = additions.find(
      ({ addition_type }) => addition_type === EstimateAddition.obiShitate
    )

    const 別衿加工 = additions.find(
      ({ addition_type }) =>
        addition_type === EstimateAddition.separateCollarProcessing
    )

    return {
      帯仕立て: {
        isNeed: 帯仕立て
          ? PRODUCT_SETTING.YES_OR_NO.はい
          : planId === SingleItemPlan.新品
          ? PRODUCT_SETTING.YES_OR_NO.いいえ
          : null,
        price: 帯仕立て?.price ? toString(帯仕立て.price) : null,
      },
      別衿加工: {
        isNeed: 別衿加工
          ? PRODUCT_SETTING.YES_OR_NO.はい
          : PRODUCT_SETTING.YES_OR_NO.いいえ,
        price: 別衿加工?.price ? toString(別衿加工.price) : null,
      },
    }
  }

  const formatCorrections = (
    products: SingleItemServiceContractDetail['products'][number]
  ) => {
    // NOTE: 契約書詳細APIから復元する場合
    if ('corrections' in products) {
      const shoulder = products?.corrections?.find(
        ({ type }) => type === PRODUCT_SETTING.CORRECTIONS_TYPE.肩幅
      )
      const sleeveWidth = products?.corrections?.find(
        ({ type }) => type === PRODUCT_SETTING.CORRECTIONS_TYPE.袖幅
      )
      const sleeveHeight = products?.corrections?.find(
        ({ type }) => type === PRODUCT_SETTING.CORRECTIONS_TYPE.袖丈
      )
      return {
        shoulder: {
          sun: typeof shoulder?.sun === 'number' ? String(shoulder.sun) : null,
          bu: typeof shoulder?.bu === 'number' ? String(shoulder.bu) : null,
        },
        sleeveWidth: {
          sun:
            typeof sleeveWidth?.sun === 'number'
              ? String(sleeveWidth.sun)
              : null,
          bu:
            typeof sleeveWidth?.bu === 'number' ? String(sleeveWidth.bu) : null,
        },
        sleeveHeight: {
          shaku:
            typeof sleeveHeight?.shaku === 'number'
              ? String(sleeveHeight.shaku)
              : null,
          sun:
            typeof sleeveHeight?.sun === 'number'
              ? String(sleeveHeight.sun)
              : null,
          bu:
            typeof sleeveHeight?.bu === 'number'
              ? String(sleeveHeight.bu)
              : null,
        },
      }
    } else {
      // NOTE: 見積書詳細APIから復元する場合
      return {
        shoulder: {
          sun: null,
          bu: null,
        },
        sleeveWidth: {
          sun: null,
          bu: null,
        },
        sleeveHeight: {
          shaku: null,
          sun: null,
          bu: null,
        },
      }
    }
  }

  const formatDelivery = (data: SingleItemServiceDetailUnion | undefined) => {
    // NOTE: 契約書詳細APIから復元する場合
    if (data && 'delivery' in data) {
      return {
        date: data.delivery?.date ?? new DateTime().toDateString(),
        shop: findShopById(data.delivery.shop_id) ?? null,
      }
    } else {
      // NOTE: 見積書詳細APIから復元する場合
      return {
        date: null,
        shop: null,
      }
    }
  }

  const formatReturnInfo = (data: SingleItemServiceDetailUnion | undefined) => {
    // NOTE: 契約書詳細APIから復元する場合
    if (data && 'return' in data) {
      return {
        date: data.return?.date ?? null,
        shop: findShopById(data.return.shop_id) ?? null,
      }
    } else {
      // NOTE: 見積書詳細APIから復元する場合
      return {
        date: null,
        shop: null,
      }
    }
  }

  const fetchContractorListById = async () => {
    if (data?.value && 'contractor_id' in data?.value) {
      await fetchContractorList({
        customer_id: data.value.contractor_id,
      })
    }
  }

  const getContractorById = () => {
    const customer = contractorList.value.find(
      ({ customer_id }) =>
        data?.value &&
        'contractor_id' in data?.value &&
        customer_id === data.value?.contractor_id
    )
    return customer ?? null
  }

  const fetchCustomerListById = async () => {
    if (data.value?.customer_id) {
      await fetchCustomerList({
        customer_id: data.value.customer_id,
      })
    }
  }

  const getCustomerById = () => {
    const customer = customerList.value.find(
      ({ customer_id }) => customer_id === data.value?.customer_id
    )
    return customer ?? null
  }

  return {
    restateProductSetting,
    fetchShopList,
    fetchObiProducts,
    fetchCustomerListById,
    fetchContractorListById,
    getCustomerById,
    getContractorById,
  }
}
