import { FormValuesプラン } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsプラン'

//不明な点がある場合、READMEをご覧ください
export const getInitialStore = (): FormValuesプラン => {
  return {
    plan弊社成人式: null,
    plan利用者を検索: null,
    plan成人式契約書: null,
    plan未設定契約書リスト: [],
    plan未設定契約書: null,
    planフルセットプラン選択: null,
    plan着付け区分: null,
    plan卒業式使用日: null,
    plan着物種類: null,
    plan着物種別: null,
    plan袴種別: null,
    plan友の会会員: null,
  }
}

const state = (): { fields: FormValuesプラン } => ({
  fields: getInitialStore(),
})

export default state
