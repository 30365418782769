import { MutationTree } from 'vuex'

// ** 型 **
import type { State, ProductNameList } from './state'
import type { ProductPrice, Product } from '~/api/productRepository'

type SetProductsArg = { products: Product[]; productName: ProductNameList }

const mutations: MutationTree<State> = {
  setItemPrices: (state, itemPrices: ProductPrice[]) => {
    state.itemPrices = itemPrices
  },

  setKimonoPrices: (state, kimonoPrices: ProductPrice[]) => {
    state.kimonoPrices = kimonoPrices
  },

  setProducts: (state, { products, productName }: SetProductsArg) => {
    state.products[productName] = products
  },

  delete: (state) => {
    state.itemPrices = []
    state.kimonoPrices = []
    state.products = {
      草履: [],
      バッグ: [],
    }
  },
}

export default mutations
