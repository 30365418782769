/**
 * @note 参列契約書におけるお客様ご準備のitem. (2022/11/14時点でのAPI仕様書に準拠)
 * https://docs.google.com/spreadsheets/d/1Go2iCyMfVkvc22ez4fjjsB0JSBeMGnfHY-vFDtrcjdg/edit#gid=1474009449&range=69:69
 */
export class AttendanceThingsToBring {
  static readonly 肌着裾除 = 1
  static readonly 足袋 = 2
  static readonly 薄いタオル5本 = 3
  static readonly 草履 = 4
  static readonly バッグ = 5
  static readonly 髪飾り = 6
  static readonly 着付小物 = 7
  static readonly インナー = 8
  static readonly Yシャツ = 9
  static readonly 靴下 = 10
  static readonly 革靴 = 11

  static toWord = (val: number) => {
    switch (val) {
      case AttendanceThingsToBring.肌着裾除:
        return '肌着裾除'
      case AttendanceThingsToBring.足袋:
        return '足袋'
      case AttendanceThingsToBring.薄いタオル5本:
        return '薄いタオル5本'
      case AttendanceThingsToBring.草履:
        return '草履'
      case AttendanceThingsToBring.バッグ:
        return 'バッグ'
      case AttendanceThingsToBring.髪飾り:
        return '髪飾り'
      case AttendanceThingsToBring.着付小物:
        return '着付小物'
      case AttendanceThingsToBring.インナー:
        return 'インナー'
      case AttendanceThingsToBring.Yシャツ:
        return 'Yシャツ'
      case AttendanceThingsToBring.靴下:
        return '靴下'
      case AttendanceThingsToBring.革靴:
        return '革靴'
    }
    return '該当なし'
  }

  static toId = (label: string) => {
    switch (label) {
      case '肌着裾除':
        return AttendanceThingsToBring.肌着裾除
      case '足袋':
        return AttendanceThingsToBring.足袋
      case '薄いタオル5本':
        return AttendanceThingsToBring.薄いタオル5本
      case '草履':
        return AttendanceThingsToBring.草履
      case 'バッグ':
        return AttendanceThingsToBring.バッグ
      case '髪飾り':
        return AttendanceThingsToBring.髪飾り
      case '着付小物':
        return AttendanceThingsToBring.着付小物
      case 'インナー':
        return AttendanceThingsToBring.インナー
      case 'Yシャツ':
        return AttendanceThingsToBring.Yシャツ
      case '靴下':
        return AttendanceThingsToBring.靴下
      case '革靴':
        return AttendanceThingsToBring.革靴
    }

    return 0
  }
}
