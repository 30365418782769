import { computed, Ref } from '@nuxtjs/composition-api'
import { Items, WPhotoPlan } from '~/api/estimateRepository'
import { EstimateState } from '~/composable/estimate/repository/comingOfAge/EstimateState'
import { getFormattedPhotos } from '~/composable/estimate/service/EstimateFormatter/photos'
import {
  getFormattedSingleItem,
  getFormatted帯,
  getFormattedMultiItems,
} from './items'

export const useWPhotoFormatter = (estimateState: Ref<EstimateState>) => {
  return computed(() => {
    const { Wフォト, 見積一覧 } = estimateState.value
    return getWPhoto(Wフォト, 見積一覧.selectedWフォトプラン列)
  })
}

export const getWPhoto = (
  valuesWフォト: EstimateState['Wフォト'],
  Wフォトプラン列: number
): WPhotoPlan | null => {
  // NOTE: Wフォトを適用しない場合はnullを返す
  if (!valuesWフォト.firstPage.isWPhoto適用) return null
  const { 試着写真, 振袖 } = valuesWフォト
  const formattedPhotos = getFormattedPhotos(試着写真)

  const items: Items[] = []
  items.push(getFormatted帯(valuesWフォト.帯))
  items.push(getFormattedSingleItem(valuesWフォト.帯締め))
  items.push(...getFormattedMultiItems(valuesWフォト.帯揚げ))
  items.push(...getFormattedMultiItems(valuesWフォト.重ね衿))
  items.push(getFormattedSingleItem(valuesWフォト.別衿))

  return {
    selected_plan: Wフォトプラン列,
    selected_plan_type: 振袖.プラン ?? 0,
    photos: formattedPhotos.formatted全体写真,
    neck_photo_url: formattedPhotos.formatted衿元写真,
    hurisode: getWPhotoFurisode(振袖),
    items,
  }
}

export const getWPhotoFurisode = (振袖: EstimateState['Wフォト']['振袖']) => {
  const searchDiscountType = () => {
    switch (振袖.割引.rate) {
      case 50:
        return 1
      case 80:
        return 2
      default:
        return undefined
    }
  }
  return {
    product_id: 振袖.着物検索?.product_id,
    catalog_code: 振袖.着物検索?.catalog_code ?? undefined,
    product_name: 振袖.商品名 || '-',
    is_correct_for_yukitake: 振袖.flags.is裄補正,
    is_correct_for_sodetake: 振袖.flags.is袖補正,
    rental_price: 振袖.レンタル価格 ?? 0,
    purchase_price: 0, // NOTE:Wフォト、購入価格の入力無いため0で固定
    discount_type: searchDiscountType(),
  }
}
