import { computed } from '@nuxtjs/composition-api'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { WphotoState } from '~/store/types'
import { PLAN } from '~/constants/estimate/comingOfAge'
import { useState試着写真, useState商品設定 } from './StateOtherTabs'
import { useState別衿 } from './State別衿'
import { useState帯 } from './State帯'
import { useState帯揚げ } from './State帯揚げ'
import { useState帯締め } from './State帯締め'
import { useState振袖 } from './State振袖'
import { useState重ね衿 } from './State重ね衿'

export const useStateWPhoto = () => {
  const valuesWフォト = useComingStore<WphotoState>('WphotoPlan').getAll()
  const 試着写真 = useState試着写真()
  const 振袖 = useState振袖()
  const 重ね衿 = useState重ね衿()
  const 帯 = useState帯()
  const 帯締め = useState帯締め()
  const 商品設定 = useState商品設定()
  const 帯揚げ = useState帯揚げ()
  const 別衿 = useState別衿()

  return computed(() => {
    return {
      firstPage: {
        isWPhoto適用: valuesWフォト.value.selections.includes(PLAN.APPLY),
      },
      帯: 帯.value,
      試着写真: 試着写真.value,
      振袖: 振袖.value,
      重ね衿: 重ね衿.value,
      帯締め: 帯締め.value,
      商品設定: 商品設定.value,
      帯揚げ: 帯揚げ.value,
      別衿: 別衿.value,
    }
  })
}
