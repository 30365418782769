import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { CustomerState } from '~/store/types'

export const useRestoreお名前 = () => {
  const store = useComingStore<CustomerState>('Customers')

  const restoreお名前 = (fullName: string) => {
    store.setOf<CustomerState['name']>('setName', fullName)
    store.setOf<CustomerState['isValidate']['name']>('setIsValidateName', false)
  }
  return { restoreお名前 }
}
