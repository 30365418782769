import { MutationTree } from 'vuex'
import { Photo, ContractsPhotoSettings } from '../../types'

const mutations: MutationTree<ContractsPhotoSettings> = {
  add: ({ settings }, { settingId, photo }) => {
    const setting = settings.find((s) => s.id === settingId)
    if (setting) {
      if (photo.localSrc) {
        photo.localSrc = ''
        console.log('clear localSrc defore push')
      }
      setting.photos.push(photo)
    }
  },

  updatePublish: ({ settings }, { settingId, photoId, value }) => {
    const setting = settings.find((s) => s.id === settingId)
    if (setting) {
      setting.photos.forEach((p: Photo): void => {
        if (p.photoId === photoId) {
          p.publish = value
        } else {
          p.publish = false
        }
      })
    }
  },

  updateUploaded: ({ settings }, { settingId, photoId, value }) => {
    const setting = settings.find((s) => s.id === settingId)
    if (setting) {
      const photo = setting.photos.find((p) => p.photoId === photoId)
      if (photo) {
        photo.uploaded = value
      }
    }
  },

  addRemoteSrc: ({ settings }, { settingId, photoId, remoteSrc }) => {
    const setting = settings.find((s) => s.id === settingId)
    if (setting) {
      const photo = setting.photos.find((p) => p.photoId === photoId)
      if (photo) {
        console.log(photo)
        photo.thumbnail = remoteSrc[0].url
      }
    }
  },

  remove: ({ settings }, { settingId, photoId }) => {
    const setting = settings.find((s) => s.id === settingId)
    if (setting) {
      setting.photos = setting.photos.filter((p) => p.photoId !== photoId)
    }
  },

  setEstimatePhotos: ({ settings }, estimatePhotos) => {
    settings[0].photos = estimatePhotos.map(
      (photo: { is_main_picture: number; url: string }) => {
        return {
          photoId: Math.random(),
          file: '',
          publish: Boolean(photo.is_main_picture),
          uploaded: true,
          thumbnail: photo.url,
        }
      }
    )
  },

  setEstimateNeckPhoto: ({ settings }, estimateNeckphotoUrl) => {
    const photoArray: Array<any> = [estimateNeckphotoUrl]
    settings[1].photos = photoArray.map((url: string) => {
      return {
        photoId: Math.random(),
        file: '',
        publish: false,
        uploaded: true,
        thumbnail: url,
      }
    })
  },

  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },

  setMainPhoto: (state, photos) => {
    state.settings[0].photos = photos
  },

  setNeckPhoto: (state, photos) => {
    state.settings[1].photos = photos
  },

  delete: (state) => {
    state.settings = [
      {
        id: 1,
        title: '全体写真',
        limit: 3,
        isPossibleCheck: true,
        photos: [],
      },
      {
        id: 2,
        title: '衿元写真',
        limit: 1,
        isPossibleCheck: false,
        photos: [],
      },
    ]
    state.isValidate = null
  },
}

export default mutations
