import { Discounts } from '~/api/estimateRepository'
import { EstimateState } from '~/composable/estimate/repository/graduation/EstimateState'
import {
  EstimateDiscount,
  DiscountType,
} from '~/constants/enums/estimateDiscount'

export const useDiscountesFormatter = (estimateState: EstimateState) => {
  const getFormattedDiscounts = () => {
    const discounts: Discounts[] = []
    const { 割引あり, 金額 } = estimateState.value.割引
    if (割引あり) {
      discounts.push({
        discount_type: EstimateDiscount.noType,
        type: DiscountType.amount,
        amount: 金額,
      })
    }

    return discounts
  }

  return { getFormattedDiscounts }
}
