import { readonly, ref, useContext } from '@nuxtjs/composition-api'
import { isObject } from 'lodash'
import beautySalonRepository, { BuautySalon } from '~/api/beautySalonRepository'
import masterRepository, {
  Prefecture,
  Shop,
  VisitMotive,
  DressingPlaces,
  TransferPlaces as transferPlaces,
  SpecialMentions,
  ContractReason,
} from '~/api/masterRepository'
import paymentRepository, { Payment } from '~/api/paymentRepository'
import DressingPlacesType from '~/constants/enums/dressingPlacesType'

export type TransferPlaces = transferPlaces

export type PlacesList = {
  id: number
  name: string
  dressingAddressInfo: {
    zip_code: string
    prefectures: string
    address1: string
    address2: string
    phone_number: string
  } | null
}

export const useMasterData = () => {
  const { $axios } = useContext()
  const shopList = ref<Shop[]>([])
  const extendedShopList = ref<Shop[]>([]) // 未選択を追加したshopList
  const prefectures = ref<Prefecture[]>([])
  const visitMotives = ref<VisitMotive[]>([])
  const dressingPlaces = ref<DressingPlaces[]>([])
  const dressingPlacesList = ref<DressingPlaces[]>([])
  const formattedDressingPlaces = ref<PlacesList[]>([])
  const transferPlaces = ref<TransferPlaces[]>([])
  const filteredTransferPlaces = ref<TransferPlaces[]>([]) // 契約上使用されない銀行
  const beautySalons = ref<BuautySalon[]>([])
  const payments = ref<Payment[]>([])
  const specialMentions = ref<SpecialMentions[]>([])
  const contractReasons = ref<ContractReason[]>([])
  const loading = ref(false)
  const errorMsg = ref('')
  const EXCLUDED_BANK_IDS = [2, 3, 4] // 2: 福岡銀行・3: 大分銀行・4: 鹿児島銀行

  const paymentRepo = paymentRepository($axios)
  const masterRepo = masterRepository($axios)
  const beautySalonRepo = beautySalonRepository($axios)

  // 店舗一覧API
  const fetchShopList = async () => {
    loading.value = true
    try {
      const { data } = await masterRepo.getShops()
      shopList.value = data.shops

      const unselected: Shop = {
        id: -1,
        name: '選択しない',
        abbreviated_name: null,
        code: null,
        phone_number: null,
        fax_number: null,
        emergency_phone_number: null,
        email: null,
        prefecture: null,
        zip_code: null,
        address1: null,
        address2: null,
        remarks: null,
        display_order: null,
      }

      extendedShopList.value = [unselected, ...data.shops]
    } catch (error) {
      console.log('API error', error)
    } finally {
      loading.value = false
    }
  }

  // 都道府県マスタ取得API
  const fetchPrefectures = async () => {
    loading.value = true
    try {
      const { data } = await masterRepo.fetchPrefectures()
      prefectures.value = data.prefecture
    } catch (error) {
      console.log('API error', error)
    } finally {
      loading.value = false
    }
  }

  /**
   * 来店動機マスタ取得API
   * @param display_type 1: 旧来店動機 2: 成人式 3: 卒業式 null|指定なし: 全件
   */
  const fetchVisitMotives = async (display_type?: number) => {
    loading.value = true
    try {
      const { data } = await masterRepo.getVisitMotives(display_type)
      visitMotives.value = data.visit_motives
    } catch (error) {
      console.log('API error', error)
    } finally {
      loading.value = false
    }
  }

  const isIncludesTransferId = (id: number | null): boolean => {
    if (!id) return false

    return EXCLUDED_BANK_IDS.includes(id)
  }

  // 振込先情報一覧取得API
  const fetchTransferPlaces = async () => {
    loading.value = true
    try {
      const { data } = await masterRepo.getTransferPlaces()
      transferPlaces.value = data.transfer_places

      /** 使用されない銀行を除外 */
      filteredTransferPlaces.value = data.transfer_places.filter(
        (tp) => !isIncludesTransferId(tp.id)
      )
    } catch (error) {
      console.log('API error', error)
    } finally {
      loading.value = false
    }
  }

  type BeautySalonParams = {
    [key: string]: unknown
    shop_id: number | null
  }

  // 美容室一覧取得API
  const fetchBeautySalons = async (params: BeautySalonParams) => {
    loading.value = true
    errorMsg.value = ''
    // NOTE: 複数の箇所で使用されているので、リファクタリングが完了するまでは、不正な引数は弾く
    const isRegularParams = (params: unknown) =>
      isObject(params) && 'shop_id' in params

    if (!isRegularParams(params)) {
      errorMsg.value = '不正なパラメーターです'
      return
    }

    try {
      const { data } = await beautySalonRepo.getAll(params)
      beautySalons.value = data.beauty_salons
    } catch (error) {
      console.log('API error', error)
    } finally {
      loading.value = false
    }
  }

  // 来店動機一覧取得API
  const fetchContractReasons = async () => {
    loading.value = true
    try {
      const { data } = await masterRepo.getContractReasons()
      contractReasons.value = data.contract_reasons
    } catch (error) {
      console.log('API error', error)
    } finally {
      loading.value = false
    }
  }

  // 着付会場情報取得API
  const fetchDressingPlaces = async (shop_id: number) => {
    loading.value = true
    try {
      const { data } = await masterRepo.getDressingPlaces(shop_id)

      dressingPlaces.value = data.dressing_places

      // 着付会場情報を整形
      if (dressingPlaces.value) {
        formattedDressingPlaces.value = dressingPlaces.value.map(
          (dressingPlace) => {
            return {
              id: dressingPlace.id,
              name: dressingPlace.name,
              dressingAddressInfo: {
                zip_code: dressingPlace.zip,
                prefectures: dressingPlace.prefectures,
                address1: dressingPlace.address1,
                address2: dressingPlace.address2,
                phone_number: dressingPlace.phone_number,
              },
            }
          }
        )
        formattedDressingPlaces.value.unshift({
          id: DressingPlacesType.未定,
          name: '未定',
          dressingAddressInfo: null,
        })
      }
    } catch (error) {
      console.error('API error', error)
    } finally {
      loading.value = false
    }
  }

  // 支払い情報取得
  const fetchPayments = async (
    contractId: number,
    option?: { refresh: boolean }
  ) => {
    loading.value = true
    if (option?.refresh) {
      payments.value = []
    }

    try {
      const { data } = await paymentRepo.get(contractId)
      payments.value = data.payments
    } catch (error) {
      console.error('API error', error)
    } finally {
      loading.value = false
    }
  }

  // 特記事項
  const fetchSpecialMentions = async () => {
    try {
      const { data } = await masterRepo.getSpecialMentions()
      specialMentions.value = data.data
    } catch (error) {
      console.log('API error', error)
    } finally {
      loading.value = false
    }
  }

  return {
    fetchShopList,
    shopList: readonly(shopList),
    extendedShopList: readonly(extendedShopList),
    prefectures: readonly(prefectures),
    transferPlaces: readonly(transferPlaces),
    filteredTransferPlaces: readonly(filteredTransferPlaces),
    beautySalons: readonly(beautySalons),
    payments: readonly(payments),
    specialMentions: readonly(specialMentions),
    dressingPlaces: readonly(dressingPlaces),
    formattedDressingPlaces: formattedDressingPlaces,
    dressingPlacesList,
    contractReasons: contractReasons,
    visitMotives: visitMotives,
    isIncludesTransferId,
    fetchDressingPlaces,
    fetchPrefectures,
    fetchTransferPlaces,
    fetchVisitMotives,
    fetchBeautySalons,
    fetchPayments,
    fetchContractReasons,
    fetchSpecialMentions,
  }
}
