import { ActionTree } from 'vuex'
import { RootState, MemoState } from '../../../types'

const actions: ActionTree<MemoState, RootState> = {
  setText: ({ commit }, text) => {
    commit('setText', text)
  },

  setChance: ({ commit }, isChance) => {
    commit('setChance', isChance)
  },

  setMemoId: ({ commit }, memoId) => {
    commit('setMemoId', memoId)
  },

  setCustomerId: ({ commit }, customerId) => {
    commit('setCustomerId', customerId)
  },

  delete: ({ commit }) => {
    commit('delete')
  },
}

export default actions
