import { MutationTree } from 'vuex'
import { MotherAndDaughterPlanState, IsClickByUsedPastType } from '../../types'
import { getInitialStoreTabList } from '@/store/Contracts/MotherAndDaughterPlan/state'

const mutations: MutationTree<MotherAndDaughterPlanState> = {
  setPatternLine: (state, patternLine) => {
    state.patternLine = patternLine
  },

  setProduct: (state, product) => {
    state.product = product
  },

  setInput: (state, input) => {
    state.input = input
  },

  setDate: (state, date) => {
    state.date = date
  },

  setJkMotherAndDaughterPlan: (state, JkMotherAndDaughterPlan) => {
    state.jkMotherAndDaughterPlan = JkMotherAndDaughterPlan
  },

  setIsInputFinish: (state, isInputFinish) => {
    state.isInputFinish = isInputFinish
  },

  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },
  // 母娘プランを適用する/適用しない
  setIsClickByUsedPast: (state, isClickByUsedPast: IsClickByUsedPastType) => {
    state.isClickByUsedPast = isClickByUsedPast
  },

  deleteProduct: (state) => {
    state.product = null
  },

  setSettings: (state, Settings) => {
    state.settings = Settings
  },

  setJkProduct: (state, product) => {
    state.jkMotherAndDaughterPlan.product = product
  },

  setTabList: (state, tabList) => {
    state.motherAndDaughterTabList = tabList
  },

  setInitTabList: (state) => {
    const initTabList = getInitialStoreTabList()
    state.motherAndDaughterTabList = initTabList
  },

  delete: (state) => {
    state.patternLine = [0, 0, 0]
    state.product = null
    state.date = ''
    state.isInputFinish = false
    state.input = {
      timeOfEnteringDressPlace: '',
      remark: '',
      height: '',
      foot_size: '',
      bust: '',
      hip: '',
    }
    state.settings = [
      {
        id: 1,
        title: '現品商品の写真をアップロードしてください。',
        limit: 1,
        isPossibleCheck: true,
        photos: [],
      },
    ]
    state.jkMotherAndDaughterPlan = {
      patternLine: [0], // patternLine[0] JK前撮り 49: 「写真から選ぶ」、40: 「現品を選ぶ」
      product: {
        product_id: 0,
        product_name: '',
        catalog_code: null,
        shop_name: '',
        start_date: '',
        end_date: '',
        photo_url: '',
      }, // JK前撮り訪問着情報,
      timeOfEnteringDressPlace: '', // JK前撮り 着付場所入場時間
      remark: '', // JK前撮り 備考欄
      date: '',
      photos: [],
    }
    state.isValidate = null
    state.motherAndDaughterTabList = [
      {
        text: '採寸',
        isComplete: null,
        isCurrent: true,
        isShow: true,
      },
      {
        text: 'JK前撮り',
        isComplete: null,
        isCurrent: false,
        isShow: false,
      },
      {
        text: '前撮り',
        isComplete: null,
        isCurrent: false,
        isShow: false,
      },
    ]
    state.isClickByUsedPast = 0
  },
}

export default mutations
