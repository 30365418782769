import { computed, Ref } from '@nuxtjs/composition-api'
import { EstimateDetail } from '~/api/estimateRepository'
import { PLAN } from '~/constants/estimate/comingOfAge'
type PickedPlanType = Pick<typeof PLAN, 'APPLY' | 'NO_APPLY'>
const PickedPlan: PickedPlanType = PLAN

export const useRestateWPhotoFirst = (
  estimate: Ref<EstimateDetail | undefined>
) => {
  return computed(() => {
    const isWPhoto = estimate.value?.w_photo_plan?.items?.length === 0
    return {
      optionIds適用: !isWPhoto ? PickedPlan.APPLY : PickedPlan.NO_APPLY,
    }
  })
}
