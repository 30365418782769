import { computed } from '@nuxtjs/composition-api'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { ServicePlanState } from '~/store/types'
import { SERVICE_PLAN } from '~/constants/estimate/comingOfAge'
import { usePlanTypeFlags } from './PlanTypeFlags'

/**
 * @note プランタブで選択されたプランに合わせて他のタブの表示項目を切り替えるためのフラグ
 */
export const useScreenTypeFlags = () => {
  const storeプラン = useComingStore<ServicePlanState>('ServicePlans')
  const values = storeプラン.getAll()
  const {
    is当日のみBコース,
    is写真のみ持込,
    is写真のみレンタル,
    isフルセット,
  } = usePlanTypeFlags()
  const { BRING_IN } = SERVICE_PLAN

  const showTypeフルセット = computed(() => {
    return isフルセット.value
  })

  const showType写真のみレンタル = computed(() => {
    return is写真のみレンタル.value
  })

  // NOTE: 写真のみプランの持込選択時も含む
  const showType持ち込み = computed(() => {
    const 持込選択 = values.value.selections.includes(BRING_IN)
    return 持込選択 || is写真のみ持込.value
  })

  // NOTE: お姉様が購入した振袖を持ち込む場合のみに選択できる着付けだけのコース
  const showType当日のみBコース = computed(() => {
    return is当日のみBコース.value
  })

  return {
    showTypeフルセット,
    showType写真のみレンタル,
    showType持ち込み,
    showType当日のみBコース,
  }
}
