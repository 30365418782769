import { MutationTree } from 'vuex'
import { AccessoriesState } from '../../types'

const mutations: MutationTree<AccessoriesState> = {
  setResultSelections: (state, resultSelections) => {
    state.resultSelections = resultSelections
  },

  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },

  delete: (state) => {
    state.resultSelections = []
    state.isValidate = null
  },
}

export default mutations
