import { ref, useContext, useStore } from '@nuxtjs/composition-api'
import axios from 'axios'

import contractRepository, {
  CreateContractParams,
  ContractDetail,
  Contract,
  AttendanceContractsDetail,
  PatchAttendanceContractParams,
  CleaningContractForDetail,
  CreateCleaningContractParams,
  UpdateCleaningContractParams,
  ContractSearchQueryParams,
} from '~/api/contractRepository'
import fileRepository, { ContractPdf } from '~/api/fileRepository'
import { DOCUMENT } from '~/constants/estimate/config'

import { CreateAttendanceContractParams } from '~/api/contractRepository'
import { Payment } from '~/store/Contracts/Attendance/Main/state'
import { Payment as cleaningPayment } from '~/store/Contracts/Cleaning/Main/state'
import paymentRepository, { PAYMENT_METHOD } from '~/api/paymentRepository'
import { IS_PAYMENT, PAYMENT_CATEGORY } from '~/features/contracts/paymentEnums'
import paymentService from './PaymentService'
import { notifyError } from '../general/SlackErrorLog'

export const useContract = () => {
  const { $axios, $config } = useContext()
  const store = useStore()
  const payment: Payment = store.getters['Contracts/Attendance/Main/getPayment'] // 参列支払い情報の取得
  const cleaningPayment: cleaningPayment =
    store.getters['Contracts/Cleaning/Main/getPayment'] // クリーニング支払い情報の取得
  const contractList = ref<Contract[]>([])
  const contractInfo = ref<ContractDetail>()
  const attendanceContractsInfo = ref<AttendanceContractsDetail>()
  const cleaningContractsInfo = ref<CleaningContractForDetail>()
  const loading = ref(false)
  const error = ref('')
  const fileRepo = fileRepository(
    axios.create({
      headers: $axios.defaults.headers,
      baseURL: $config.BASE_URL,
    })
  )
  const contractRepo = contractRepository($axios)
  const paymentRepo = paymentRepository($axios)
  const paymentServ = paymentService(fileRepo, paymentRepo)

  /**
   * 契約書検索API
   */
  const fetchContractList = async (params: ContractSearchQueryParams) => {
    loading.value = true
    try {
      const { data } = await contractRepo.findAll(params)

      return data.contracts
    } catch (error) {
      console.log('API error', error)
      return []
    } finally {
      loading.value = false
    }
  }

  /**
   * 契約書詳細API
   * @param {path} contract_id
   * @param {path} branch_number
   */
  const fetchContractInfo = async (contract_id: number) => {
    try {
      const response = await contractRepo.show(contract_id)
      if (Array.isArray(response.data.photos)) {
        contractInfo.value = response.data
        return
      }

      contractInfo.value = {
        ...response.data,
        photos: [response.data.photos].filter(Boolean),
      }
    } catch (error) {
      console.log('API error', error)
    }
  }

  /**
   * 参列契約詳細API
   * @param {path} estimate_id
   */
  const fetchAttendanceContractsInfo = async (contract_id: number) => {
    try {
      const res = await $axios.get<AttendanceContractsDetail>(
        `/contracts/attendance/${contract_id}`
      )

      // NOTE: 存在しない場合は空配列で返ってくるため必要
      if (Array.isArray(res.data)) {
        throw new Error('指定された契約番号の契約書は存在しません。')
      }

      attendanceContractsInfo.value = res.data
    } catch (err) {
      if (axios.isAxiosError(err)) {
        throw new Error('参列契約の詳細が取得できませんでした。')
      }

      if (err instanceof Error) {
        throw new Error(err.message)
      }
    }
  }

  /**
   * 契約書新規作成API
   */
  const postContract = async (data: CreateContractParams) => {
    loading.value = true
    try {
      const response = await contractRepo.create(data)
      console.log('API success', response.data)
    } catch (err) {
      console.log('API error', err)
      error.value = '契約書の作成に失敗しました'
    } finally {
      loading.value = false
    }
  }

  /**
   * 契約書更新API
   */
  const patchContract = async (contract_id: number, data: any) => {
    await $axios
      .patch(`contracts/${contract_id}`, data)
      .then((res) => {
        console.log('API success', res.data)
      })
      .catch((error) => {
        console.log('API error', error)
      })
  }

  /**
   * クリーニング契約書更新API
   */

  const patchCleaningContractInfo = async (
    contract_id: number,
    params: UpdateCleaningContractParams
  ) => {
    const isPayment = cleaningPayment.isPayment === IS_PAYMENT.YES
    let resId: number | undefined

    try {
      /** 契約書更新処理 */
      const res = await contractRepo
        .updateCleaning(contract_id, params)
        .catch(async (error) => {
          console.log(error)
          await notifyError(error, 'クリーニング契約書更新', params)
          throw new Error(error)
        })
      resId = res.data.id
      console.log('res :>> ', res.data.id)
      console.log('契約書作成完了')

      /** 支払いをする場合は、支払い更新処理を行う */
      if (isPayment) {
        await updatePayment(resId, cleaningPayment)
      }
    } catch (err) {
      loading.value = false
      if (axios.isAxiosError(error)) {
        console.log('API error: ', error.message)
        throw new Error('契約書更新API失敗')
      }
      if (err instanceof Error) {
        throw new Error(err.message)
      }
    }

    if (resId) {
      try {
        /** PDF生成&アップロード */
        const { data } = await fileRepo.createCleaningPDF<ContractPdf>({
          id: resId,
          type: DOCUMENT.契約書.id,
        })

        console.log('PDF生成&アップロード完了', data)

        /** 支払いをする場合は、領収書を発行する */
        if (isPayment) {
          await updateReceipt(resId)
        }

        console.info('--- 領収書PDF完了 ---')
      } catch {
        changePDFFailureFlgStatus()
      } finally {
        loading.value = false
      }
    }
    return resId
  }

  /**
   * クリーニング契約書新規作成API
   */
  const postCleaningContractsInfo = async (
    params: CreateCleaningContractParams
  ) => {
    let resId: number | undefined

    try {
      const res = await contractRepo
        .createCleaning(params)
        .catch(async (error) => {
          console.log(error)
          await notifyError(error, 'クリーニング契約書作成', params)
          throw new Error(error)
        })
      resId = res.data.id

      console.log('契約書作成完了')
    } catch (err) {
      loading.value = false
      if (axios.isAxiosError(error)) {
        console.log('API error: ', error.message)
        throw new Error('契約書作成API失敗')
      }
      if (err instanceof Error) {
        throw new Error(err.message)
      }
    }

    if (resId) {
      try {
        const { data } = await fileRepo.createCleaningPDF<ContractPdf>({
          id: resId,
          type: DOCUMENT.契約書.id,
        })

        console.log('PDF生成&アップロード完了', data)
      } catch {
        changePDFFailureFlgStatus()
      } finally {
        loading.value = false
      }
    }

    return resId
  }

  /**
   * クリーニング契約詳細API
   * @param {path} estimate_id
   */
  const fetchCleaningContractsInfo = async (contract_id: number) => {
    loading.value = true
    try {
      const res = await $axios.get<CleaningContractForDetail>(
        `/contracts/cleaning/${contract_id}`
      )

      if (Array.isArray(res.data)) {
        throw new Error('指定された契約番号の契約書は存在しません。')
      }

      cleaningContractsInfo.value = res.data
    } catch (err) {
      if (axios.isAxiosError(err)) {
        throw new Error('クリーニング契約の詳細が取得できませんでした。')
      }

      if (err instanceof Error) {
        throw new Error(err.message)
      }
    } finally {
      loading.value = false
    }
  }

  /**
   * 参列契約書新規作成API
   */

  const postAttendanceContract = async (
    params: CreateAttendanceContractParams
  ) => {
    let resId: number | undefined
    try {
      const res = await contractRepo
        .createAttendance(params)
        .catch(async (error) => {
          console.log(error)
          await notifyError(error, '参列契約書作成', params)
          throw new Error(error)
        })
      resId = res.data.id
    } catch (error) {
      loading.value = false
      if (axios.isAxiosError(error)) {
        console.log('API error: ', error.message)
      }
      throw new Error('契約書作成API失敗')
    }

    if (resId) {
      try {
        const { data } = await fileRepo.createAttendancePDF<ContractPdf>({
          id: resId,
          type: DOCUMENT.契約書.id,
        })

        console.log('PDF生成&アップロード完了', data)
      } catch {
        changePDFFailureFlgStatus()
      } finally {
        loading.value = false
      }
    }

    return resId
  }

  /**
   * 参列契約書更新API
   */
  const patchAttendanceContract = async (
    contract_id: number,
    params: PatchAttendanceContractParams
  ) => {
    let resId: number | undefined
    const isPayment = payment.isPayment === IS_PAYMENT.YES

    try {
      /** 契約書更新処理 */
      const res = await contractRepo
        .patchAttendance(contract_id, params)
        .catch(async (error) => {
          console.log(error)
          await notifyError(error, '参列契約書更新', params)
          throw new Error(error)
        })
      resId = res.data.id
      console.log('res :>> ', res.data.id)

      /** 支払いをする場合は、支払い更新処理を行う */
      if (isPayment) {
        await updatePayment(resId, payment)
      }
    } catch (err) {
      loading.value = false
      if (axios.isAxiosError(err)) {
        if (err.response) {
          const {
            data: { errors },
            status,
          } = err.response
          const errorboxes = Object.keys(errors).map((key) => errors[key])
          throw new Error(`${errorboxes.join('\n')}（${status}）`)
        }
      }
      throw new Error('契約書作成API失敗')
    }

    if (resId) {
      try {
        /** PDF生成&アップロード */
        const { data } = await fileRepo.createAttendancePDF<ContractPdf>({
          id: resId,
          type: DOCUMENT.契約書.id,
        })

        console.log('PDF生成&アップロード完了', data)

        /** 支払いをする場合は、領収書を発行する */
        if (isPayment) {
          await updateReceipt(resId)
        }

        console.info('--- 領収書PDF完了 ---')
      } catch {
        changePDFFailureFlgStatus()
      } finally {
        loading.value = false
      }
    }

    return resId
  }

  /**
   * 支払い情報更新処理API
   * @param contractId
   * @param payment
   */
  const updatePayment = async (
    contractId: number,
    payment: Payment | cleaningPayment
  ) => {
    const paymentDetails = payment.details
      .filter(({ isActive }) => isActive)
      .map((detail) => {
        if (
          detail.methods === PAYMENT_METHOD.POINT ||
          detail.methods === PAYMENT_METHOD.FAMILY_POINT
        ) {
          // 友の会ポイント選択時
          return {
            methods: detail.methods,
            tomonokai_points: detail.amount,
            amount: detail.amount * 1000,
          }
        }

        return {
          methods: detail.methods,
          tomonokai_points: 0,
          amount: detail.amount,
        }
      })

    const category = payment.category || 1

    // TODO: 一時的に契約更新時の内金を支払に変更してリクエストしている。UI修正とともに本実装予定。
    const { data } = await paymentRepository($axios).register({
      contract_id: contractId,
      due_date: payment.dueDate ?? '',
      staff_id: payment.staff?.staff_id ?? 0,
      category:
        category === PAYMENT_CATEGORY.PART_PAYMENT
          ? PAYMENT_CATEGORY.PAYMENT
          : category,
      details: paymentDetails,
      total: payment.total ?? 0,
      notes: payment.notes || '-',
    })
    return data
  }

  const updateReceipt = async (contractId: number) => {
    try {
      const { data: payData } = await paymentServ.fetchPayments(contractId)
      const latestBranch = paymentServ.latestBranchNumber(payData.payments)
      await paymentServ.createRecipentPdf(contractId, latestBranch)

      const beforeLatestPaymentBranchNumber = latestBranch - 1
      if (beforeLatestPaymentBranchNumber) {
        await paymentServ.createRecipentPdf(
          contractId,
          beforeLatestPaymentBranchNumber,
          true
        )
      }
    } catch (error) {
      if (error instanceof Error) {
        console.log('--- 領収書のPDF生成失敗 ---', error.message)
      }
    }
  }

  const fetchContractManageData = async (params: { customer_id: number }) => {
    loading.value = true
    try {
      const response = await contractRepo.findAll(params)
      contractList.value = response.data.contracts
    } catch (error) {
      console.log('API error', error)
    } finally {
      loading.value = false
    }
  }

  /**
   * 旧契約書のプラン更新API
   */
  const patchPlanForOldData = async (params: {
    contract_id: number
    plan: number
  }) => {
    loading.value = true
    const { contract_id, plan } = params
    await $axios
      .patch(`contracts/${contract_id}/plan`, { plan })
      .then((res) => {
        console.log('API success', res.data)
      })
      .catch((error) => {
        console.log('API error', error)
        if (axios.isAxiosError(error)) {
          throw new Error(
            '契約書のプランを更新できませんでした。ご指定の契約書は旧システムからのデータ移行分ではない可能性があります'
          )
        }

        if (error instanceof Error) {
          throw new Error(error.message)
        }
      })
      .finally(() => {
        loading.value = false
      })
  }

  /** PDF生成に失敗した場合はPDFFailureFlgを立てて完了画面でアラートを表示させる */
  const changePDFFailureFlgStatus = () => {
    store.commit(`PdfStoreInfo/setPDFFailureFlg`, true)
  }

  return {
    loading,
    error,
    fetchContractList,
    contractList,
    fetchContractInfo,
    contractInfo,
    postContract,
    patchContract,
    fetchContractManageData,
    attendanceContractsInfo,
    fetchAttendanceContractsInfo,
    postAttendanceContract,
    patchAttendanceContract,
    fetchCleaningContractsInfo,
    cleaningContractsInfo,
    postCleaningContractsInfo,
    patchCleaningContractInfo,
    patchPlanForOldData,
  }
}
