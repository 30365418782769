import { MotherAndDaughterPlanState } from '../../types'

const state = (): MotherAndDaughterPlanState => ({
  patternLine: [0, 0, 0],
  product: {
    // 写真から選ぶ場合の商品情報が入る(訪問着写真・前撮り)
    product_id: 0,
    product_name: '',
    catalog_code: '',
    shop_name: '',
    start_date: '',
    end_date: '',
    photo_url: '',
  },
  input: {
    timeOfEnteringDressPlace: '', // 前撮り 着付場所入場時間
    remark: '', // 前撮り 備考欄
    height: '', // 身長
    foot_size: '', // 足サイズ
    bust: '', // バスト
    hip: '', // ヒップ
  },
  date: '', // 母娘プラン使用日
  isInputFinish: false, // 採寸情報入力後、商品写真を設定したかどうか
  // 前撮り 現品写真
  settings: [
    {
      id: 1,
      title: '試着時の写真をアップロードしてください。',
      limit: 1,
      isPossibleCheck: true,
      photos: [],
    },
  ],
  jkMotherAndDaughterPlan: {
    // JK前撮り(母娘プラン)情報
    patternLine: [0], // patternLine[0] JK前撮り 49: 「写真から選ぶ」、40: 「現品を選ぶ」
    product: {
      product_id: 0,
      product_name: '',
      catalog_code: null,
      shop_name: '',
      start_date: '',
      end_date: '',
      photo_url: '',
    }, // JK前撮り訪問着情報,
    timeOfEnteringDressPlace: '', // JK前撮り 着付場所入場時間
    remark: '', // JK前撮り 備考欄
    date: '',
    photos: [],
  },
  motherAndDaughterTabList: [
    {
      text: '採寸',
      isComplete: null,
      isCurrent: true,
      isShow: true,
    },
    {
      text: 'JK前撮り',
      isComplete: null,
      isCurrent: false,
      isShow: false,
    },
    {
      text: '前撮り',
      isComplete: null,
      isCurrent: false,
      isShow: false,
    },
  ],
  isValidate: null, // 採寸・JK前撮り(母娘プラン)・前撮り(母娘プラン)が全て入力されたかどうか
  isClickByUsedPast: 0,
})

export default state

export const getInitialStoreTabList =
  (): MotherAndDaughterPlanState['motherAndDaughterTabList'] => {
    return [
      {
        text: '採寸',
        isComplete: null,
        isCurrent: true,
        isShow: true,
      },
      {
        text: 'JK前撮り',
        isComplete: null,
        isCurrent: false,
        isShow: false,
      },
      {
        text: '前撮り',
        isComplete: null,
        isCurrent: false,
        isShow: false,
      },
    ]
  }
