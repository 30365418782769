import { computed, Ref } from '@nuxtjs/composition-api'
import { usePrice } from 'storybook/utils/utils'
import { useRestatePlan } from '../RestatePlan'
import { EstimateDetail, Items } from '~/api/estimateRepository'
import {
  Enum草履ブーツ_着物レンタル,
  Enum草履ブーツ_着物持込,
} from '~/constants/estimate/graduationOptions'
import { EstimateSinglePriceType } from '~/constants/enums/estimateSinglePriceType'
import { ItemPlan } from '~/constants/enums/itemPlan'
import ProductId from '~/constants/enums/productClassification'
import { Product } from '~/store/Estimate/GraduationCeremony/types'

type ZoriBoots = KimonoBringIn | KimonoRental

type KimonoBringIn = {
  type: '着物持込'
  optionId品目: number | null
  optionId種別: number | null
  optionId購入方法: number | null
  レンタル価格: string | null
  購入価格: string | null
  選択商品: Product
  見積表示: number | null
  flags: Flags
}

type KimonoRental = {
  type: '着物レンタル'
  optionId品目: number | null
  optionId種別: number | null
  optionId形態: number | null
  optionId購入方法: number | null
  レンタル価格: string | null
  購入価格: string | null
  選択商品: Product
  flags: Flags
}

type Flags = ReturnType<typeof getConditionalFlags>

export const useRestate草履ブーツ = (
  estimate: Ref<EstimateDetail | undefined>
) => {
  const restatePlan = useRestatePlan(estimate)

  return computed((): ZoriBoots | undefined => {
    if (!estimate.value?.items) return

    const zoriBoots = estimate.value.items.filter((item) => {
      return (
        item.product_type_id === ProductId.zori ||
        item.product_type_id === ProductId.boots
      )
    })

    if (restatePlan.value?.flags.has着物レンタル) {
      //* 振袖レンタル時のデータ作成関数
      return formatKimonoRental(zoriBoots[0])
    } else {
      //* 振袖持込時のデータ作成関数
      return formatKimonoBringIn(zoriBoots[0])
    }
  })
}

/**
 * 復元条件に関わる条件式を定義する
 */
const getConditionalFlags = (item: Items) => {
  const isブーツ = item.product_name === 'ブーツ'
  const isランクアップ =
    item.plan === ItemPlan.rankUpOutPremium ||
    item.plan === ItemPlan.toBeDecided
  const is持込 = item.plan === ItemPlan.bringIn
  const isセット内 = item.plan === ItemPlan.inSet
  const isレンタル =
    item.plan ===
    Enum草履ブーツ_着物レンタル.レンタルOR購入.ランクアップレンタル

  const is見積表示レンタル =
    item.price_type === EstimateSinglePriceType.singleRentalPrice
  const is現品 = item.product_name === '現品'

  return {
    is草履: !isブーツ,
    isブーツ,
    is持込,
    is現品,
    is単品レンタル購入: !is持込,
    isセット内,
    isランクアップ,
    isレンタル,
    is見積表示レンタル,
  }
}

const formatKimonoBringIn = (item: Items) => {
  const { paramToString } = usePrice()
  const flags = getConditionalFlags(item)
  const zoriBoots: KimonoBringIn = {
    type: '着物持込',
    optionId品目: null,
    optionId種別: null,
    optionId購入方法: null,
    選択商品: {
      id: 0,
      name: '',
    },
    見積表示: null,
    レンタル価格: paramToString(item.rental_price),
    購入価格: paramToString(String(item.purchase_price)),
    flags,
  }

  zoriBoots.optionId品目 = flags.isブーツ
    ? Enum草履ブーツ_着物持込.品目.ブーツ
    : Enum草履ブーツ_着物レンタル.品目.草履

  zoriBoots.optionId種別 = flags.is持込
    ? Enum草履ブーツ_着物持込.種別.お持込
    : Enum草履ブーツ_着物持込.種別.単品レンタル単品購入

  zoriBoots.optionId購入方法 = flags.is見積表示レンタル
    ? Enum草履ブーツ_着物持込.見積表示.単品レンタル価格
    : Enum草履ブーツ_着物持込.見積表示.単品購入価格

  zoriBoots.選択商品.name = item?.product_name ?? ''
  zoriBoots.選択商品.id = getProductId(item.product_id, item.product_name)

  return zoriBoots
}

const formatKimonoRental = (item: Items) => {
  const flags = getConditionalFlags(item)
  const { paramToString } = usePrice()

  const zoriBoots: KimonoRental = {
    type: '着物レンタル',
    optionId品目: null,
    optionId種別: null,
    optionId形態: null,
    optionId購入方法: null,
    選択商品: {
      id: 0,
      name: '',
    },
    レンタル価格: paramToString(item.rental_price),
    購入価格: paramToString(String(item.purchase_price)),
    flags,
  }
  zoriBoots.optionId品目 = flags.isブーツ
    ? Enum草履ブーツ_着物レンタル.品目.ブーツ
    : Enum草履ブーツ_着物レンタル.品目.草履

  zoriBoots.optionId種別 = flags.is持込
    ? Enum草履ブーツ_着物レンタル.種別.お持込
    : Enum草履ブーツ_着物レンタル.種別.レンタル単品購入

  zoriBoots.optionId形態 = flags.isセット内
    ? Enum草履ブーツ_着物レンタル.形態.セット内
    : Enum草履ブーツ_着物レンタル.形態.ランクアップ

  zoriBoots.optionId購入方法 = flags.isレンタル
    ? Enum草履ブーツ_着物レンタル.レンタルOR購入.ランクアップレンタル
    : Enum草履ブーツ_着物レンタル.レンタルOR購入.単品購入

  zoriBoots.選択商品.name = item?.product_name ?? ''
  zoriBoots.選択商品.id = getProductId(item.product_id, item.product_name)

  return zoriBoots
}

const getProductId = (
  productId: number | undefined,
  productName: string | undefined
) => {
  if (productId) return productId
  if (productName === '現品') return Enum草履ブーツ_着物レンタル.草履商品.現品

  console.error('該当する商品の番号が存在しませんでした。')
  return 0
}
