import { computed, useRoute } from '@nuxtjs/composition-api'
import { urls, Urls } from '~/constants/urls'
/**
 * @interface UrlNameUnion - 卒業式URLのkey名のどれかが入る型
 * @interface TabNaviItem - タブのリンク情報classの型
 */
export type UrlNameUnion = keyof typeof urls.見積書.卒業式

export type ValidateType = 'valid' | 'invalid' | 'noResult'
/**
 * 入力フロー内の各タブのリンク情報を受け持つクラス
 */
export interface TabNaviItem {
  label: string
  url: string
  number: 0 | 1 | 2 | 3 | undefined
  isValid: ValidateType | undefined
}

export const useTavNaviItem = (
  label: string,
  urlName: UrlNameUnion,
  number?: 0 | 1 | 2 | 3,
  isValid?: ValidateType | undefined
): TabNaviItem => {
  const route = useRoute()

  const url = computed(() => {
    const { estimate_id } = route.value.params
    if (!estimate_id) {
      throw new Error('estimate_idが存在しません in Tab Class')
    }
    if (label === 'オプション') {
      isValid = 'valid'
    }
    const assertedUrls = urls as Urls
    const getUrl = assertedUrls.見積書.卒業式[urlName]
    return getUrl(parseInt(estimate_id), number)
  })

  return { url: url.value, label, number, isValid }
}
