import { ActionTree } from 'vuex'
import { RootState, settingShop } from '../types'

const actions: ActionTree<settingShop, RootState> = {
  setShop: ({ commit }, shop) => {
    commit('setShop', shop)
  },

  delete: ({ commit }) => {
    commit('delete')
  },
}

export default actions
