import { OnlySelectionPlanState } from '../../../types'

const state = (): OnlySelectionPlanState => ({
  /**
   * NOTE: selections = patternLine
   * ↓保持される選択肢のindex
   * [0]適用するか
   */
  selections: [0],
  isValidate: null,
})

export default state
