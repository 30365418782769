// eslint-disable-next-line no-use-before-define
type UrlNameUnion = keyof typeof urls.見積書.卒業式
type 卒業式 = {
  // eslint-disable-next-line no-unused-vars
  [urlName in UrlNameUnion]: (estimateId: number, number?: number) => string
}
type 成人式 = {
  // eslint-disable-next-line no-unused-vars
  [urlName in UrlNameUnion]: (estimateId: number) => string
}
export type Urls = {
  見積書: {
    卒業式: 卒業式
    成人式: 成人式
  }
}

export const urls = {
  見積書: {
    卒業式: {
      tabお名前: (estimateId: number) =>
        `/estimate/graduationCeremony/${estimateId}/tab/onamae`,
      tabプラン: (estimateId: number) =>
        `/estimate/graduationCeremony/${estimateId}/tab/plan`,
      tab試着写真: (estimateId: number) =>
        `/estimate/graduationCeremony/${estimateId}/tab/shichakuShashin`,
      tab商品設定: (estimateId: number) =>
        `/estimate/graduationCeremony/${estimateId}/tab/shouhinSettei`,
      tab振袖: (estimateId: number) =>
        `/estimate/graduationCeremony/${estimateId}/tab/furisode`,
      tab二尺袖: (estimateId: number) =>
        `/estimate/graduationCeremony/${estimateId}/tab/nishakusode`,
      tab袴: (estimateId: number) =>
        `/estimate/graduationCeremony/${estimateId}/tab/hakama`,
      tab半巾帯_袴持込: (estimateId: number) =>
        `/estimate/graduationCeremony/${estimateId}/tab/hanhabaobi/bringin-hakama`,
      tab半巾帯_袴レンタル: (estimateId: number) =>
        `/estimate/graduationCeremony/${estimateId}/tab/hanhabaobi/rental-hakama`,
      tab重ね衿_着物レンタル_1個目: (estimateId: number) =>
        `/estimate/graduationCeremony/${estimateId}/tab/kasaneeri/rental-kimono/1`,
      tab重ね衿_着物レンタル_複数個目: (estimateId: number, number: 2 | 3) =>
        `/estimate/graduationCeremony/${estimateId}/tab/kasaneeri/rental-kimono/${number}`,
      tab重ね衿_着物持込: (estimateId: number, number: 1 | 2 | 3) =>
        `/estimate/graduationCeremony/${estimateId}/tab/kasaneeri/bringin-kimono/${number}`,
      tab別衿_着物レンタル: (estimateId: number) =>
        `/estimate/graduationCeremony/${estimateId}/tab/betsueri/rental-kimono`,
      tab別衿_着物持込: (estimateId: number) =>
        `/estimate/graduationCeremony/${estimateId}/tab/betsueri/bringin-kimono`,
      tab髪飾り: (estimateId: number, number: 1 | 2 | 3) =>
        `/estimate/graduationCeremony/${estimateId}/tab/kamikazari/${number}`,
      tab草履ブーツ_着物持込: (estimateId: number) =>
        `/estimate/graduationCeremony/${estimateId}/tab/zoriBoots/bringin-kimono`,
      tab草履ブーツ_着物レンタル: (estimateId: number) =>
        `/estimate/graduationCeremony/${estimateId}/tab/zoriBoots/rental-kimono`,
      tab巾着バッグ_着物持込: (estimateId: number) =>
        `/estimate/graduationCeremony/${estimateId}/tab/kinchakuBag/bringin-kimono`,
      tab巾着バッグ_着物レンタル: (estimateId: number) =>
        `/estimate/graduationCeremony/${estimateId}/tab/kinchakuBag/rental-kimono`,
      tabお写真プラン: (estimateId: number) =>
        `/estimate/graduationCeremony/${estimateId}/tab/oshashinPlan`,
      tabその他商品: (estimateId: number) =>
        `/estimate/graduationCeremony/${estimateId}/tab/otherItem`,
      tab割引: (estimateId: number) =>
        `/estimate/graduationCeremony/${estimateId}/tab/waribiki`,
      tabオプション: (estimateId: number) =>
        `/estimate/graduationCeremony/${estimateId}/tab/option`,
      tab備考: (estimateId: number) =>
        `/estimate/graduationCeremony/${estimateId}/tab/bikou`,
      tabお客様検索: (estimateId: number) =>
        `/estimate/graduationCeremony/${estimateId}/tab/okyakusamaKensaku`,
      入力内容確認: (estimateId: number) =>
        `/estimate/graduationCeremony/${estimateId}/confirmation`,
      見積一覧: (estimateId: number) =>
        `/estimate/graduationCeremony/${estimateId}/estimateList`,
      見積作成完了: (estimateId: number) =>
        `/estimate/graduationCeremony/${estimateId}/complete`,
    },
    成人式: {
      tabお名前: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/customerName`,
      tabプラン: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/plan`,
      tab試着写真: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/photo`,
      tab商品設定: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/products`,
      tab振袖: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/furisode`,
      tab帯: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/obi`,
      tab帯締め: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/obijime`,
      tab帯揚げ: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/obiage`,
      tab帯揚げ2: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/obiage2`,
      tab帯揚げ3: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/obiage3`,
      tab重ね衿: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/kasaneeri`,
      tab重ね衿2: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/kasaneeri2`,
      tab重ね衿3: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/kasaneeri3`,
      tab別衿: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/betsueri`,
      tabショール: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/shawl`,
      tab髪飾り: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/kamikazari`,
      tab髪飾り2: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/kamikazari2`,
      tab髪飾り3: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/kamikazari3`,
      tab草履バッグ: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/zoriBag`,
      tab草履2: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/zori2`,
      tab草履3: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/zori3`,
      tabバッグ2: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/bag2`,
      tabバッグ3: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/bag3`,
      tab母娘プラン: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/motherAndDaughterPlan`,
      tab足し布: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/tashinuno`,
      tabJK前撮り: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/jkPhotoInAdvance`,
      tabWフォト: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/wPhotoPlan`,
      tab従業員割: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/employeeDiscount`,
      tab双子割: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/twinsDiscount`,
      tab妹様割: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/sisterDiscount`,
      tabその他商品: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/otherItem`,
      tab割引: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/discount`,
      tabオプション: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/option`,
      tab備考: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/remarks`,
      tabお客様検索: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/tab/customerNumber`,
      入力内容確認: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/confirmation`,
      見積一覧: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/estimateList`,
      見積作成完了: (estimateId: number) =>
        `/estimate/comingOfAgeCeremony/${estimateId}/creationCompleted`,
    },
    参列: {
      main: (estimateId: number) => `/estimate/attendance/${estimateId}/main`,
    },
    単品: {
      main: (estimateId: number) =>
        `/estimate/singleItemService/${estimateId}/main`,
    },
  },
  契約書: {
    参列: {
      main: (contractId: number) => `/contracts/attendance/${contractId}/main`,
    },
    クリーニング: {
      main: (contractId: number) => `/contracts/cleaning/${contractId}/main`,
    },
    単品: {
      main: (contractId: number) =>
        `/contracts/singleItemService/${contractId}/main`,
    },
  },
}
