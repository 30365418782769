import { GetterTree } from 'vuex'
import { RootState, StaffState } from '../types'

const getters: GetterTree<StaffState, RootState> = {
  getStaffs: (state: StaffState) => {
    return state.staffs
  },
}

export default getters
