import { AxiosError } from 'axios'

export type ErrorResponse = {
  error_code: number
  message: string
  errors: Record<string, string[]>
  success?: string
}

export enum HttpCode {
  OK = 200,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  EXCLUSIVE_CONTROL = 409, // 排他制御
  UNPROCESSABLE_CONTENT = 422,
  INTERNAL_SERVER_ERROR = 500,
}

export function isAxiosError(error: any): error is AxiosError<ErrorResponse> {
  return !!('isAxiosError' in error)
}

// 400
export function badRequestException(
  message = 'bad request [Custom]',
  errors: Record<string, string[]> = { custom: ['400 bad request'] }
): CustomError {
  return new CustomError(HttpCode.UNPROCESSABLE_CONTENT, message, errors)
}

// 422
export function unprocessableContentException(
  message = 'Unprocessable Content [Custom]',
  errors: Record<string, string[]> = { custom: ['422 Unprocessable Content'] }
): CustomError {
  return new CustomError(HttpCode.UNPROCESSABLE_CONTENT, message, errors)
}

// 500
export function internalServerErrorException(
  message = 'Internal Server Error [Custom]',
  errors: Record<string, string[]> = { custom: ['500 Internal Server Error'] }
): CustomError {
  return new CustomError(HttpCode.INTERNAL_SERVER_ERROR, message, errors)
}

export class CustomError extends Error {
  constructor(
    public error_code: number,
    public message: string,
    public errors: Record<string, string[]>,
    e?: string
  ) {
    super(e)
    this.name = new.target.name

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor)
    }

    // https://future-architect.github.io/typescript-guide/exception.html
    // 下記の行はTypeScriptの出力ターゲットがES2015より古い場合(ES3, ES5)のみ必要
    Object.setPrototypeOf(this, new.target.prototype)
  }
}
