import { ShowPriceOption, ShowPriceType } from './types'
import { usePrice } from '~/composable/general/Price'
import { WithFreeInput } from '~/store/Estimate/GraduationCeremony/types'

/*
 * 編集付き金額表示コンポーネントから表示されている金額を数字で返す
 */
export const getPriceFromEditPrice = (editPrice: string | null) => {
  const { toNumber } = usePrice()
  if (!editPrice) return 0
  return toNumber(editPrice)
}

/*
 * フリー入力付き価格選択系のコンポーネントの取得した値を元に、
 * 実際入力あるいは選択されている値を数字で返す（未選択の場合はnull）
 */
export const getPriceFromSelectPriceWithFreeInput = (
  selectedOption: WithFreeInput | null,
  inputPrice: string | null
) => {
  if (selectedOption === null) return null
  const { toNumber } = usePrice()
  const フリー入力選択 = selectedOption.id === 0

  if (フリー入力選択) {
    if (inputPrice === null) return null
    return toNumber(inputPrice)
  } else {
    return toNumber(selectedOption.value)
  }
}

export const getShowPrice = (
  見積選択: number | null,
  レンタル価格: number | null,
  購入価格: number | null,
  optionEnum: ShowPriceOption,
  hasKimonoRental?: boolean | undefined
) => {
  // 着物レンタル時は単品レンタルのみ
  if (hasKimonoRental) {
    const showType: ShowPriceType = {
      id: optionEnum.単品レンタル価格, //着物レンタル時は見積選択がnullで来るのでidを代入
      value: '単品レンタル',
    }
    const showPrice = レンタル価格
    return { showType, showPrice }
  } else {
    const isTanpinRental = 見積選択 === optionEnum.単品レンタル価格
    const showType: ShowPriceType = {
      id: 見積選択,
      value: isTanpinRental ? '単品レンタル' : '単品購入',
    }
    const showPrice = isTanpinRental ? レンタル価格 : 購入価格
    return { showType, showPrice }
  }
}
