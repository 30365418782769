import { computed } from '@nuxtjs/composition-api'
import { usePlanFlags } from '~/composable/estimate/domain/Graduation/PlanFlags'
import {
  Enum半巾帯_袴レンタル,
  Enum半巾帯_袴持込,
} from '~/constants/estimate/graduationOptions'
import { FormValues半巾帯_袴レンタル } from '~/composable/estimate/viewModel/graduation/Fields/Fields半巾帯_袴レンタル'
import { FormValues半巾帯_袴持込 } from '~/composable/estimate/viewModel/graduation/Fields/Fields半巾帯_袴持込'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import {
  getPriceFromEditPrice,
  getShowPrice,
} from '~/composable/estimate/repository/graduation/states/utilities'
import { ShowPriceType } from '~/composable/estimate/repository/graduation/states/types'
import { GENPIN } from '~/constants/productData'

type Hanhabaobi = {
  品種区分: 18
  商品ID: number | null
  商品名: string | null
  小物プラン: number | null
  レンタル価格: number | null
  購入価格: number | null
  見積表示: ShowPriceType | null
  表示価格: number | null
  isランクアップ: boolean
  isセット内: boolean
  label: string
}

export const useState半巾帯 = () => {
  const store半巾帯_袴持込 =
    useGraduateStore<FormValues半巾帯_袴持込>('tab半巾帯_袴持込')
  const store半巾帯_袴レンタル =
    useGraduateStore<FormValues半巾帯_袴レンタル>('tab半巾帯_袴レンタル')
  const values半巾帯_袴持込 = store半巾帯_袴持込.getAll()
  const values半巾帯_袴レンタル = store半巾帯_袴レンタル.getAll()
  const { hasHakamaRental } = usePlanFlags()

  return computed(() => {
    const hanhabaobi: Hanhabaobi = {
      品種区分: 18,
      商品ID: null,
      商品名: null,
      小物プラン: null,
      レンタル価格: null,
      購入価格: null,
      見積表示: null,
      表示価格: null,
      isランクアップ: false,
      isセット内: false,
      label: '',
    }

    if (hasHakamaRental.value) {
      /*
       * プランで袴レンタルを選んだ際の半巾帯タブ選択内容
       */
      const 種別選択 = values半巾帯_袴レンタル.value.hanhabaobi種別
      const 形態選択 = values半巾帯_袴レンタル.value.hanhabaobi形態
      const セット内を選択 = 形態選択 === Enum半巾帯_袴レンタル.形態.セット内
      const お持込を選択 = 種別選択 === Enum半巾帯_袴レンタル.種別.お持込

      if (お持込を選択) {
        hanhabaobi.小物プラン = Enum半巾帯_袴レンタル.種別.お持込
        hanhabaobi.label = 'お持込'
        hanhabaobi.商品名 = null
      } else {
        /** 現品かどうか */
        const is現品 =
          values半巾帯_袴レンタル.value.hanhabaobi商品選択?.name === GENPIN

        hanhabaobi.レンタル価格 = getPriceFromEditPrice(
          values半巾帯_袴レンタル.value.hanhabaobiレンタル価格
        )
        hanhabaobi.表示価格 = hanhabaobi.レンタル価格
        hanhabaobi.小物プラン = values半巾帯_袴レンタル.value.hanhabaobi形態
        hanhabaobi.商品ID = !is現品
          ? values半巾帯_袴レンタル.value.hanhabaobi商品選択?.id ?? null
          : null
        hanhabaobi.商品名 =
          values半巾帯_袴レンタル.value.hanhabaobi商品選択?.name ?? null

        hanhabaobi.label =
          values半巾帯_袴レンタル.value.hanhabaobi形態 ===
          Enum半巾帯_袴持込.形態.セット内
            ? 'セット内'
            : 'ランクアップ'
        hanhabaobi.isセット内 = セット内を選択
        hanhabaobi.isランクアップ =
          values半巾帯_袴レンタル.value.hanhabaobi形態 ===
          Enum半巾帯_袴持込.形態.ランクアップ
      }
    } else {
      /*
       * プランで袴持込の半巾帯タブ選択内容
       */
      const 種別選択 = values半巾帯_袴持込.value.hanhabaobi種別
      const お持込を選択 = 種別選択 === Enum半巾帯_袴持込.種別.お持込

      /**
       * 現品かどうか
       */
      const is現品 =
        values半巾帯_袴持込.value.hanhabaobi商品選択?.name === GENPIN

      if (お持込を選択) {
        hanhabaobi.小物プラン = Enum半巾帯_袴持込.種別.お持込
        hanhabaobi.label = 'お持込'
      } else {
        hanhabaobi.小物プラン = values半巾帯_袴持込.value.hanhabaobi形態
        hanhabaobi.レンタル価格 = getPriceFromEditPrice(
          values半巾帯_袴持込.value.hanhabaobi単品レンタル価格
        )
        hanhabaobi.購入価格 = getPriceFromEditPrice(
          values半巾帯_袴持込.value.hanhabaobi単品購入価格
        )
        hanhabaobi.商品ID = !is現品
          ? values半巾帯_袴持込.value.hanhabaobi商品選択?.id ?? null
          : null
        hanhabaobi.商品名 =
          values半巾帯_袴持込.value.hanhabaobi商品選択?.name ?? null

        hanhabaobi.label =
          values半巾帯_袴持込.value.hanhabaobi形態 ===
          Enum半巾帯_袴持込.形態.セット内
            ? 'セット内'
            : 'ランクアップ'
      }

      const { showType, showPrice } = getShowPrice(
        values半巾帯_袴持込.value.hanhabaobi見積選択,
        hanhabaobi.レンタル価格,
        hanhabaobi.購入価格,
        Enum半巾帯_袴持込.見積表示
      )
      hanhabaobi.見積表示 = showType
      hanhabaobi.表示価格 = showPrice
    }

    return hanhabaobi
  })
}
