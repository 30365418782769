import { MutationTree } from 'vuex'
import { getInitialStore, 卒業式見積一覧 } from './state'

const mutations: MutationTree<{ fields: 卒業式見積一覧 }> = {
  setState: (
    state,
    payload: { stateName: keyof 卒業式見積一覧; value: any }
  ) => {
    state.fields[payload.stateName] = payload.value
  },

  setAllState: (state, values: 卒業式見積一覧) => {
    state.fields = values
  },

  initState: (state, fieldId: keyof 卒業式見積一覧) => {
    const initialStore = getInitialStore()
    state.fields[fieldId] = initialStore[fieldId] as any
  },

  initAllState: (state) => {
    state.fields = getInitialStore()
  },
}

export default mutations
