import { FormValues別衿_着物持込 } from '~/composable/estimate/viewModel/graduation/Fields/Fields別衿_着物持込'

// 不明な点がある場合、READMEをご覧ください
export const getInitialStore = (): FormValues別衿_着物持込 => {
  return {
    betsueri種別: null,
    betsueri長襦袢: null,
    betsueri単品レンタル: null,
    betsueri単品レンタルフリー入力: null,
    betsueri単品購入: null,
    betsueri単品購入フリー入力: null,
    betsueri見積選択: null,
  }
}

const state = (): { fields: FormValues別衿_着物持込 } => ({
  fields: getInitialStore(),
})

export default state
