/**
 * [サイズ区分]
 * @note 訪問着・留袖等、衣類のサイズ項目
 * https://docs.google.com/spreadsheets/d/1okfA12p-AGYDweXD812tOul3Hp2Zqom3/edit#gid=1427553124
 */
export class ClothingSizeType {
  static readonly S = 1
  static readonly M = 2
  static readonly L = 3
  static readonly LL = 4
  static readonly LLL = 5 // 3L
  static readonly N = 6
  static readonly B = 7
  static readonly BB = 8
  static readonly Y1 = 9
  static readonly Y2 = 10
  static readonly Y3 = 11
  static readonly Y4 = 12
  static readonly Y5 = 13
  static readonly Y6 = 14
  static readonly Y7 = 15
  static readonly Y8 = 16
  static readonly Y9 = 17
  static readonly Y10 = 18
  static readonly A1 = 19
  static readonly A2 = 20
  static readonly A3 = 21
  static readonly A4 = 22
  static readonly A5 = 23
  static readonly A6 = 24
  static readonly A7 = 25
  static readonly A8 = 26
  static readonly A9 = 27
  static readonly A10 = 28
  static readonly AB1 = 29
  static readonly AB2 = 30
  static readonly AB3 = 31
  static readonly AB4 = 32
  static readonly AB5 = 33
  static readonly AB6 = 34
  static readonly AB7 = 35
  static readonly AB8 = 36
  static readonly AB9 = 37
  static readonly AB10 = 38
  static readonly B1 = 39
  static readonly B2 = 40
  static readonly B3 = 41
  static readonly B4 = 42
  static readonly B5 = 43
  static readonly B6 = 44
  static readonly B7 = 45
  static readonly B8 = 46
  static readonly B9 = 47
  static readonly B10 = 48
  static readonly O1 = 49
  static readonly O2 = 50
  static readonly O3 = 51
  static readonly O4 = 52
  static readonly O5 = 53
  static readonly O6 = 54
  static readonly O7 = 55
  static readonly O8 = 56
  static readonly O9 = 57
  static readonly O10 = 58
  static readonly E1 = 59
  static readonly E2 = 60
  static readonly E3 = 61
  static readonly E4 = 62
  static readonly E5 = 63
  static readonly E6 = 64
  static readonly E7 = 65
  static readonly E8 = 66
  static readonly E9 = 67
  static readonly E10 = 68
  static readonly K1 = 69
  static readonly K2 = 70
  static readonly K3 = 71
  static readonly K4 = 72
  static readonly K5 = 73
  static readonly K6 = 74
  static readonly K7 = 75
  static readonly K8 = 76
  static readonly K9 = 77
  static readonly K10 = 78

  /**
   * 訪問着、留袖、長襦袢など、衣類のenumとindex対応表
   * indexとsizeパラメータ変換に対応する為
   */
  static readonly clothingSizeLookupTable = {
    0: ClothingSizeType.N,
    1: ClothingSizeType.B,
    2: ClothingSizeType.BB,
  }

  /**
   * 足袋、草履などのenumとindex対応表
   */
  static readonly shoesSizeLookupTable = {
    0: ClothingSizeType.S,
    1: ClothingSizeType.M,
    2: ClothingSizeType.L,
    3: ClothingSizeType.LL,
    4: ClothingSizeType.LLL,
  }

  /**
   * [サイズ区分]6: N, 7: B, 8: BBをindexに変換
   * @param {number | null | undefined} sizeId - 6,7,8
   * @returns {number} 0,1,2
   */
  static toClothingSizeIndex = (sizeId: number | null | undefined) => {
    if (typeof sizeId !== 'number') return 0

    const [clothingSizeIndex] =
      Object.entries(ClothingSizeType.clothingSizeLookupTable).find(
        ([_, size]) => size === sizeId
      ) ?? []

    return Number(clothingSizeIndex)
  }

  /**
   * indexを[サイズ区分]6: N, 7: B, 8: BBに変換
   * @param {number | null} index - 0,1,2
   * @returns {number} 6,7,8
   */
  static toClothingSizeId = (index: number | null) => {
    let clothingSizeId
    Object.entries(ClothingSizeType.clothingSizeLookupTable).forEach(
      ([type, size]) => {
        if (Number(type) === index) {
          clothingSizeId = size
        }
      }
    )

    return Number(clothingSizeId) ?? null
  }

  /**
   * [サイズ区分]1:S, 2: M, 3: L, 4: LL, 5: LLLをindexに変換
   * @param {number | null | undefined} sizeId - 1,2,3,4,5
   * @returns {number} 0,1,2,3,4
   */
  static toShoesSizeIndex = (sizeId: number | null | undefined) => {
    if (typeof sizeId !== 'number') return 0

    const [ShoesSizeIndex] =
      Object.entries(ClothingSizeType.shoesSizeLookupTable).find(
        ([_, size]) => size === sizeId
      ) ?? []

    return Number(ShoesSizeIndex)
  }

  /**
   * indexを[サイズ区分]6: N, 7: B, 8: BBに変換
   * @param {number | null} index - 0,1,2,3,4
   * @returns {number} 1,2,3,4,5
   */
  static toShoesSizeSizeId = (index: number | null) => {
    let ShoesSizeType
    Object.entries(ClothingSizeType.shoesSizeLookupTable).forEach(
      ([type, size]) => {
        if (Number(type) === index) {
          ShoesSizeType = size
        }
      }
    )

    return Number(ShoesSizeType) ?? null
  }

  /**
   * サイズ項目をNumberに変換
   * @param {string | null} val - S ~ K10
   * @returns {number} 1 ~ 78
   */
  static toClothingSizeNumber = (val: string | null) => {
    let clothingType:
      | typeof ClothingSizeType[keyof typeof ClothingSizeType]
      | null = null
    if (val === '3L') val = 'LLL'

    Object.entries(ClothingSizeType).forEach(([v, k]) => {
      if (v === val) {
        clothingType = k
      }
    })
    return clothingType
  }

  /**
   * Numberをサイズ項目に変換
   * @param {number | null} val - 1 ~ 78
   * @returns {string} S ~ K10
   */
  static toClothingSizeWord = (val: number | null) => {
    let clothingSize: keyof typeof ClothingSizeType | string = 'ー'

    Object.entries(ClothingSizeType).forEach(([v, k]) => {
      if (k === val) {
        clothingSize = val === ClothingSizeType.LLL ? '3L' : v
      }
    })
    return clothingSize
  }

  static toWord = (val: number) => {
    switch (val) {
      case ClothingSizeType.S:
        return 'S'
      case ClothingSizeType.M:
        return 'M'
      case ClothingSizeType.L:
        return 'L'
      case ClothingSizeType.LL:
        return 'LL'
      case ClothingSizeType.LLL:
        return '3L'
      case ClothingSizeType.N:
        return 'N'
      case ClothingSizeType.B:
        return 'B'
      case ClothingSizeType.BB:
        return 'BB'
      default:
        return 'ー'
    }
  }
}
