import { MutationTree } from 'vuex'
import { getInitialStore } from './state'
import { FormValuesその他商品 } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsその他商品'

const mutations: MutationTree<{ fields: FormValuesその他商品 }> = {
  setState: (
    state,
    payload: { otherItems: keyof FormValuesその他商品; value: any }
  ) => {
    state.fields[payload.otherItems] = payload.value
  },

  setAllState: (state, values: FormValuesその他商品) => {
    state.fields = values
  },

  initState: (state, fieldId: keyof FormValuesその他商品) => {
    const initialStore = getInitialStore()
    state.fields[fieldId] = initialStore[fieldId] as any
  },

  initAllState: (state) => {
    state.fields = getInitialStore()
  },
}

export default mutations
