import { BODY_TYPE_SIZE, PASS_STATUS, SIZE_TYPE } from '../dressingItem'

export const SCREEN_TYPE = {
  FULL_SET: 1, // フルセットプラン
  BRING_IN: 2, // お持ち込みプラン、写真のみプラン（お持込）
  ONLY_PHOTO_RENTAL: 3, // 写真のみプラン（レンタル）
} as const

export const SERVICE_PLAN = {
  UN_SET: 0, // 初期値としても扱う
  FULL_SET: 1, // フルセット
  BRING_IN: 2, // 持ち込み
  ONLY_PHOTO: 3, // 写真のみ
  OUR_DRESSIN: 4, // 弊社着付け
  COSMETOLOGY_SALON: 5, // 遠方提携美容室
  NO_DRESSING: 6, // 着付けなし
  PURCHASE_AT_OUR: 7, // はい（弊社購入か）
  NOT_PURCHASE_AT_OUR: 8, // いいえ（弊社購入か）
  RENTAL: 9, // レンタル
  BRING_IN_ONLY_PHOTO: 10, // 持ち込み
  PRE_SHOOTING_AND_COMIMG_OF_AGE: 11, // 前撮りと成人式
  ONLY_COMIMG_OF_AGE: 12, // 成人式のみ
} as const

export const FURISODE_PLAN = {
  UN_SET: 0, // 初期値としても扱う
  FINISHED: 1, // はい（仕立て上がりか）
  NOT_FINISHED: 2, // いいえ
  CATALOG: 3, // はい（カタログ商品か）
  NOT_CATALOG: 4, // いいえ
  YUKI_CORRECTION: 5, // する（裄補正）
  NOT_YUKI_CORRECTION: 6, // しない
  SODE_CORRECTION: 7, // する（袖補正）
  NOT_SODE_CORRECTION: 8, // しない
  ORDER_RENTAL: 9, // オーダーレンタル
  CUSTOM_RENTAL: 10, // お誂レンタル
  SEARCH: 11, // 横断検索する
  PROPER: 12, //プロパー商品
  SHITATE_RENTAL: 13, // カタログ検索結果の仕立て上りレンタル
} as const

/*
 * NOTE：タブのラジオボタンの選択肢のIDはこちらに追記していく。
 * すでに同じ選択肢のIDが定義されていれば既存のIDを使用する。
 */
export const PLAN = {
  UN_SET: 0, // 未選択時の初期値
  SET_OR_RENTAL: 1, // レンタル・購入
  BRING_IN: 2, // 持ち込み
  IN_THE_SET: 3, // セット内
  RANK_UP_IN_PREMIUM: 4, // ランクアップ（内）
  RANK_UP_OUT_PREMIUM: 5, // ランクアップ（外）
  RENTAL: 6, // レンタル
  SINGLE_RENTAL: 7, // 単品レンタル・単品購入
  SINGLE_RENTAL_PRICE: 8, // 単品レンタル価格
  SINGLE_BUY_PRICE: 9, // 単品購入価格
  YES: 10, // はい
  NO: 11, // いいえ
  // MEMO: 以下随時追加しているので、定数化できたら順番整理したい
  RANK_UP_ONLY_RENTAL: 12, // レンタルのみランクアップ
  WHITE: 13, // ショール：白
  PINK: 14, // ショール：ピンク
  FIVE_FOX: 15, // ショール：5連FOXショール
  BLUE_FOX: 16, // ショール：ブルーFOX
  ACTUAL_PRODUCT: 17, // 現品
  APPLY: 18, // 適用する
  NO_APPLY: 19, // 適用しない
  INTRODUCER_YUKATA: 20, // ご紹介者様：浴衣
  INTRODUCER_QUO: 21, // ご紹介者様：QUO
  INTRODUCER_PHOTO: 22, // ご紹介者様：写真
  INTRODUCER_EXCHANGE_TICKET: 23, // ご紹介者様：引換券
  INTRODUCER_HURISODE_MODE_TICKET: 24, // ご紹介者様：ふりそでMODE商品券
  INTRODUCER_TO_BE_DECIDED: 25, // ご紹介者様：未定
  INTRODUCER_DONE: 26, // ご紹介者様：お渡し済み
  INTRODUCER_NOT_YET: 27, // ご紹介者様：未
  USER_YUKATA: 28, // ご利用者様：浴衣
  USER_QUO: 29, // ご利用者様：QUO
  USER_PHOTO: 30, // ご利用者様：写真
  USER_EXCHANGE_TICKET: 31, // ご利用者様：引換券
  USER_HURISODE_MODE_TICKET: 32, // ご利用者様：ふりそでMODE商品券
  USER_DONE: 33, // ご利用者様：お渡し済み
  USER_NOT_YET: 34, // ご利用者様：未
  USER_TAKE_PHOTO_BEFORE: 35, // ご利用者様：前撮り
  USER_TAKE_JK_PHOTO_BEFORE: 36, // ご利用者様：JK前撮り
  PHOTO_IN_ADVANCE: 37, // 前撮り
  JK_PHOTO_IN_ADVANCE: 38, // JK前撮り
  CHOOSE_PHOTOS: 39, // 前撮り
  CHOOSE_ACTUAL_PRODUCT: 40, // JK前撮り
  BETSUERI_YES: 41, // 別衿が縫い付けられているか：はい
  BETSUERI_NO: 42, // 別衿が縫い付けられているか：いいえ
  TASHINUNO_99000yen: 43, // 足し布追加料金１
  TASHINUNO_16500yen: 44, // 足し布追加料金2
  NO_USE: 45, // なし
} as const

export const CUSTOMER_READY = {
  UN_SET: 0, // 初期値
  HADAGI: 1, // 肌着・裾除
  TABI: 2, // 足袋
  TOWEL: 3, // 薄いタオル５本
  BOOTS: 4, // ブーツ・靴下
  BAG: 5, // バッグ
  CAMISOLE: 6, // キャミソール
  KOMONO: 7, // 着付け小物
}

export const PHOTO_IN_ADVANCE = {
  UN_SET: 0, // 初期値
  YES: 1, // 決まっている
  NO: 2, // 後日ご案内致します
} as const

export const JK_PHOTO_IN_ADVANCE = {
  UN_SET: 0, // 初期値
  YES: 1, // 決まっている
  NO: 2, // 後日ご案内致します
} as const

export const COMING_OF_AGE_CEREMONY = {
  UN_SET: 0, // 初期値
  YES: 1, // 決まっている
  NO: 2, // 後日ご案内致します
  COMING_FOR_GIVING: 3, // ご来店
  GIVING_PHOTO_IN_ADVANCE_DAY: 4, // 前撮り日にお渡し
  COMING_FOR_RETURNING: 5, // ご来店
  SENDING_FOR_RETURNING: 6, // お送り
  TO_BE_DECIDED: 7, // 未定
} as const

export const COMING_OF_AGE_CEREMONY_GIVE_OPTION_TEXT = [
  { id: 3, text: 'ご来店' },
  { id: 4, text: '前撮り日にお渡し' },
]

// お客様の来店区分
export const COMING_STORE_TYPE_TEXT = [
  { id: 0, text: '新規来店' },
  { id: 1, text: '再来店' },
]

// 支払区分
export const PAYMENT_CATEGORY = {
  NONE: 0, // 未選択
  PART_PAYMENT: 1, // 内金
  PAYMENT: 2, // 支払
  REFUND: 3, // 返金
} as const

// 支払方法
export const PAYMENT_METHOD = {
  CASH: 1, // 現金
  GIFT_CERTIFICATES: 2, // 商品券
  TRANSFER: 3, // 振込
  POINT: 4, // 友の会ポイント
  CARD: 5, // カード
  LOAN: 6, // ローン
  CASH_ON_DELIVERY: 7, // 代引き
  E_MONEY: 8, // 電子マネー
  FAMILY_POINT: 9, // 友の会ポイント（ご家族保有）
  RECEIPT_DISCOUNT: 10, // 入金値引き
  POINT_ROUND_OFF: 50, // ポイント端数処理
} as const

// 合計金額を出す際に入力値をポイントかどうか判断のフラグに使っているenum
// NOTE: POINTとFAMILY_POINTの時だけ値を1000倍する処理のために使っているが、他の種類は現状何も判定には使っていない
export const PAYMENT_METHOD_INDEX = {
  CASH: 0, // 現金
  GIFT_CERTIFICATES: 1, // 商品券
  CARD: 2, // カード
  POINT: 3, // 友の会ポイント
  FAMILY_POINT: 4, // 友の会ポイント（ご家族保有）
  E_MONEY: 5, // 電子マネー
  LOAN: 6, // ローン
  TRANSFER: 7, //振込
  RECEIPT_DISCOUNT: 8, // 入金値引き
  POINT_ROUND_OFF: 9, // ポイント端数処理
  CASH_ON_DELIVERY: 10, // 代引き
} as const

export const PAYMENT_METHOD_TEXT = [
  { id: 1, text: '現金' },
  { id: 2, text: '商品券' },
  { id: 3, text: '振込' },
  { id: 4, text: '友の会ポイント' },
  { id: 5, text: 'カード' },
  { id: 6, text: 'ローン' },
  { id: 7, text: '代引き' },
  { id: 8, text: '電子マネー' },
  { id: 9, text: '友の会ポイント（ご家族保有）' },
  { id: 10, text: '入金値引き' },
  { id: 50, text: 'ポイント端数処理' },
]

export const DELIVERY_DATE = {
  UN_SET: 0, // 初期値
  SAME_DAY: 1, // 前撮りと同じ日にする
  THE_DAY_BEFORE: 2, // 前撮りや成人式より早い日程を設定する
  YES: 3, // 納品希望日を設定しますか?
  NO: 4, // 納品希望日を設定しますか?
} as const

export const DELIVERY_DATE_TEXT = [
  {
    id: 1,
    text: '前撮りと同じ日にする (成人式のみの場合、成人式と同じ日にする)',
  },
  { id: 2, text: '前撮りや成人式より早い日程を設定する' },
]

/** 家族情報 通常入力 */
export const FAMILY_INFORMATION = {
  UN_SET: 0, // 初期値
  YES: 1, // いる
  NO: 2, // いない
} as const

/** 家族情報 双子割 */
export const FAMILY_INFORMATION_TWINDS = {
  UN_SET: 0, // 初期値
  YES: 1, // いる
  NO: 2, // いない
  OLD_SISTER: 3, // お姉様
  YOUNG_SISTER: 4, // 妹様
} as const

/** 家族情報 入力画面 */
export const FAMILY_INFORMATION_INPUT = {
  UN_SET: 0, // 初期値
  SAME: 1, // 同じ
  DIFFERENT: 2, // 異なる
} as const

/** 家族情報 画面切り替え */
export const FAMILY_SCREEN_TYPE = {
  FRIST_SCREEN: 1, // 初期画面
  INPUT_SCREEN: 2, // 入力画面
  CONFIRM_SCREEN: 3, // 確認画面
} as const

export const WPHOTO_FURISODE_PLAN = {
  UN_SET: 0, // 初期値としても扱う
  RENTAL: 1, // レンタル
  BRING_IN: 2, // お持込
  YUKI_CORRECTION: 3, // する（裄補正）
  NOT_YUKI_CORRECTION: 4, // しない
  SODE_CORRECTION: 5, // する（袖補正）
  NOT_SODE_CORRECTION: 6, // しない
} as const

export const WPHOTO_OBI_RENTAL = {
  UN_SET: 0, // 初期値としても扱う
  RENTAL: 1, // レンタル
  BRING_IN: 2, // お持込
  IN_THE_SET: 3, // セット内
  RANK_UP_IN_PREMIUM: 4, // ランクアップ（内）
  RANK_UP_OUT_PREMIUM: 5, // ランクアップ（外）
  YES: 6, // はい
  NO: 7, // いいえ
} as const

export const WPHOTO_OBI_BRING = {
  UN_SET: 0, // 初期値としても扱う
  SET_OR_RENTAL: 1, // レンタル・購入
  BRING_IN: 2, // お持込
  YES: 3, // はい
  NO: 4, // いいえ
  RENTAL_PRICE: 5, // 単品レンタル価格
  BUY_PRICE: 6, // 単品購入価格
} as const

export const WPHOTO_OBIJIME_RENTAL = {
  UN_SET: 0, // 初期値としても扱う
  RENTAL: 1, // レンタル
  BRING_IN: 2, // お持込
  IN_THE_SET: 3, // セット内
  RANK_UP_IN_PREMIUM: 4, // ランクアップ（内）
  RANK_UP_OUT_PREMIUM: 5, // ランクアップ（外）
} as const

export const WPHOTO_OBIJIME_BRING = {
  UN_SET: 0, // 初期値としても扱う
  RENTAL: 1, // レンタル
  BRING_IN: 2, // お持込
  RENTAL_PRICE: 3, // 単品レンタル価格
  BUY_PRICE: 4, // 単品購入価格
} as const

export const WPHOTO_OBIAGE_RENTAL = {
  UN_SET: 0, // 初期値としても扱う
  RENTAL: 1, // レンタル
  BRING_IN: 2, // お持込
  IN_THE_SET: 3, // セット内
  RANK_UP_IN_PREMIUM: 4, // ランクアップ（内）
  RANK_UP_OUT_PREMIUM: 5, // ランクアップ（外）
} as const

export const WPHOTO_OBIAGE_BRING = {
  UN_SET: 0, // 初期値としても扱う
  RENTAL: 1, // レンタル
  BRING_IN: 2, // お持込
  RENTAL_PRICE: 3, // 単品レンタル価格
  BUY_PRICE: 4, // 単品購入価格
} as const

export const WPHOTO_KASANEERI_RENTAL = {
  UN_SET: 0, // 初期値としても扱う
  RENTAL: 1, // レンタル
  BRING_IN: 2, // お持込
  IN_THE_SET: 3, // セット内
  RANK_UP_IN_PREMIUM: 4, // ランクアップ（内）
  RANK_UP_OUT_PREMIUM: 5, // ランクアップ（外）
} as const

export const WPHOTO_KASANEERI_BRING = {
  UN_SET: 0, // 初期値としても扱う
  RENTAL: 1, // レンタル
  BRING_IN: 2, // お持込
  RENTAL_PRICE: 3, // 単品レンタル価格
  BUY_PRICE: 4, // 単品購入価格
} as const

export const WPHOTO_BETSUERI_RENTAL = {
  UN_SET: 0, // 初期値としても扱う
  RENTAL: 1, // レンタル
  BRING_IN: 2, // お持込
  IN_THE_SET: 3, // セット内
  RANK_UP_IN_PREMIUM: 4, // ランクアップ（内）
  RANK_UP_OUT_PREMIUM: 5, // ランクアップ（外）
} as const

export const WPHOTO_BETSUERI_BRING = {
  UN_SET: 0, // 初期値としても扱う
  RENTAL: 1, // レンタル
  BRING_IN: 2, // お持込
  RENTAL_PRICE: 3, // 単品レンタル価格
  BUY_PRICE: 4, // 単品購入価格
} as const

export const ADDRESS_OPTION_TEXT = [
  { id: 1, text: '同じ' },
  { id: 2, text: '異なる' },
]

/* 卒業式 */
export const GRADUATION_BETSUERI = {
  UN_SET: 0, // 初期値としても扱う
  RENTAL: 1, // レンタル
  BRING_IN: 2, // お持込
  IN_THE_SET: 3, // セット内
  RANK_UP: 4, // ランクアップ
} as const

export const GRADUATION_BETSUERI_TANPIN = {
  UN_SET: 0, // 初期値としても扱う
  SET_OR_RENTAL: 1, // 単品レンタル・単品購入
  BRING_IN: 2, // お持込
  RENTAL_PRICE: 3, // 単品レンタル購入
  BUY_PRICE: 4, // 単品購入価格
} as const

// WARNING: 実際には卒業式ではなく成人式の草履の一覧
export const GRADUATION_ZORI = {
  UN_SET: 0, // 初期値としても扱う
  ZORI: 1, // 草履
  // WARNING: 元々ブーツだったが不要かつ、お持込パターンだけ通常のPLAN定数を参照しているために
  // お持込を選ぶとid:2になってしまうため、お持込用に変更した（超苦肉の策）
  BRING_IN_in振袖持込プラン: 2,
  RENTAL_OR_SINGLEBUY: 3, // レンタル・単品購入
  BRING_IN: 4, // お持ち込み
  IN_THE_SET: 5, // セット内
  RANK_UP: 6, // ランクアップ
  RZ_01: 7, // RZ-01桜刺繍ヒール草履 細太鼻緒（赤）
  RZ_02: 8, // RZ-02 椿刺繍草履（黒）
  RZ_03: 9, // RZ-03 ボタン刺繍 シール草履（薄ピ）
  RZ_04: 10, // RZ-04 くす玉刺繍コイルスプ リングヒール草履（赤台）
  RZ_05: 11, // RZ-05 くす玉刺繍コイルスプ リングヒール草履（イエロー台）
  RZ_06: 12, // RZ-06 椿刺繍ストライプ草履（赤台）
  RZ_07: 33, // RZ-07 菊刺繍金草履（黒緑）
  RZ_08: 13, // RZ-08 別珍鼻緒パール草履 （ネイビー）
  RZ_09: 14, // RZ-09 別珍鼻緒パール草履 （ボルドー）
  RZ_10: 15, // RZ-10 万寿菊刺繍草履（白）
  UZ_1: 16, // 新商品UZ-1
  UZ_2: 17, // 新商品UZ-2
  ACTUAL_PRODUCT: 18, // 現品
  RENTAL: 19, // レンタル
  SINGLEBUY: 20, // 単品購入,
  U_1: 21, // U-1 草履（ご購入）
  U_2: 22, // U-2 草履（ご購入）
  U_4: 23, // U-4 草履（ご購入）
  U_5: 24, // U-5 草履（ご購入）
  U_7: 25, // U-7 草履（ご購入）
  U_10: 26, // U-10 草履（ご購入）
  RANK_UP_IN_PREMIUM: 27, // ランクアップ（内）
  RANK_UP_OUT_PREMIUM: 28, // ランクアップ（外）
  TO_BE_DECIDED: 29, // 未定
  RENTAL_THE_SAME_PRODUCT: 30, // 同じ商品をレンタルする
  BUY_THE_SAME_PRODUCT: 31, // 同じ商品を単品購入する
  OMAKASE: 32, // 「セット内（おまかせ）」を適用する
} as const

export const U_PRODUCTS = [
  GRADUATION_ZORI.U_1,
  GRADUATION_ZORI.U_2,
  GRADUATION_ZORI.U_4,
  GRADUATION_ZORI.U_5,
  GRADUATION_ZORI.U_7,
  GRADUATION_ZORI.U_10,
]

export const PRODUCT_BAG = {
  UN_SET: 0,
  RB_01: 1,
  RB_02: 2,
  RB_03: 3,
  RB_04: 4,
  RB_05: 5,
  RB_06: 6,
  RB_07: 7,
  MB_08: 8,
  MB_09: 9,
  MB_10: 10,
  UB_1: 11,
  UB_2: 12,
  ACTUAL_PRODUCT: 18, // 現品 ※GRADUATION_ZORIでの現品のidと合わせて18にしている
  TO_BE_DECIDED: 29,
} as const

export const RENTAL_PRODUCT_TYPE = {
  RZ_TYPE: 'RZ', // 草履レンタル商品
  RB_TYPE: 'RB', // バッグレンタル商品
} as const

export const CUSTOMER_READY_SECTIONS = [
  { id: 0, text: '肌着・裾除' },
  { id: 1, text: '足袋' },
  { id: 2, text: '薄いタオル５本' },
  { id: 3, text: 'ブーツ・靴下' },
  { id: 4, text: 'バッグ' },
  { id: 5, text: 'キャミソール' },
  {
    id: 6,
    text: '着付け小物',
    subText1: '（腰ひも・帯枕・三重紐・コーリンベルト・帯板）',
  },
]

export const CUSTOMER_READY_SECTIONS_ANOTHER_COMING_OF_AGE = [
  { id: 0, text: '交織長襦袢', subText1: '¥66,000' },
  { id: 1, text: 'その他' },
]

export const CUSTOMER_READY_SECTIONS_ANOTHER_GRADUATION = [
  { id: 0, text: '肌着', subText1: '¥3,300' },
  { id: 1, text: '足袋', subText1: '¥2,200' },
  { id: 2, text: '薄いタオル5本', subText1: '¥1,100' },
  { id: 3, text: 'その他' },
]

export const YES_NO_ANSWERS = {
  YES: 1,
  NO: 2,
} as const

// 入力確認画面（inputConfirmation）で使用している紹介者特典の定数
export const REFERRAL_BONUS_INDEX = {
  INTRODUCER_BONUS_TYPE: 1,
  INTRODUCER_IS_DONE: 2,
  USER_BONUS_TYPE: 3,
  USER_IS_DONE: 4,
}

export const bodyTypeSizeSection = [
  {
    label: 'N',
    value: BODY_TYPE_SIZE.N,
  },
  {
    label: 'B',
    value: BODY_TYPE_SIZE.B,
  },
  {
    label: 'BB',
    value: BODY_TYPE_SIZE.BB,
  },
]

export const SizeTypeSection = [
  {
    label: 'S',
    value: SIZE_TYPE.S,
  },
  { label: 'M', value: SIZE_TYPE.M },
  { label: 'L', value: SIZE_TYPE.L },
  { label: 'LL', value: SIZE_TYPE.LL },
  { label: '3L', value: SIZE_TYPE.LLL },
]

// NOTE: サイズMから始まるものはこちらを使用
export const SizeTypeSection_fromM = [
  { label: 'M', value: SIZE_TYPE.M },
  { label: 'L', value: SIZE_TYPE.L },
  { label: 'LL', value: SIZE_TYPE.LL },
  { label: '3L', value: SIZE_TYPE.LLL },
]

export const passStatusSection = [
  {
    label: '未',
    value: PASS_STATUS.PENDING,
  },
  {
    label: '済',
    value: PASS_STATUS.DONE,
  },
]

/*
 * 着付け予約可能時間取得APIのtype
 * https://docs.google.com/spreadsheets/d/1p0MxMw2VO6PmJCs4UzyM4rqfO6BqBypM/edit#gid=1427553124&range=E31:F37
 */
export const RESERVABLE_TIME_TYPE = {
  COMIMG_OF_AGE_CEREMONY: 1, // 成人式
  GRADUATION_CEREMONY: 2, // 卒業式
  PHOTO_IN_ADVANCE: 3, // 前撮り
  JK_PHOTO_IN_ADVANCE: 4, // JK前撮り
  OTHER: 5, // その他
}

/**
 * 成人年齢
 * 民法改正で18歳となったが、WBの運用上の都合で20歳とする
 */
export const ADULT_AGE = 20

export const SEARCH_CONDITIONS_FOR_CUSTOMER = {
  CLIENT: 1, // 顧客番号
  NAME: 2, // 名前
  NAME_FURIGANA: 3, // 名前ふりがな
  PHONE: 4, // 電話番号
}

export const SEARCH_CONDITIONS_FOR_PRODUCT = {
  PRODUCT_ID: 1,
  CATALOG_CODE: 2,
  PRODUCT_NAME: 3,
}

export const PP_DISCOUNT_PRICE = {
  NONE: 0,
  BOTH_SET: 30800,
  RANK_UP: 22000,
  BAG_SET: 20900,
  ZORI_SET: 14300,
}

export const PP_OBIAGE_KASANEERI_PRICE = {
  SINGLE_RENTAL: 5500,
  SINGLE_PURCHASE: 8800,
}

export const ZERO_PRICE = 0

/**
 * PP適用時の金額反映に使用（1品目セット内で、2品目PP適用時の金額が変化する）
 * 帯揚げ/重ね衿/草履／バッグ
 */
export const PP_DISCOUNT_PRODUCT = {
  FIRST: 0, // 一品目
  SECOND: 1, // 二品目
}
