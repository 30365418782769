import { GetterTree } from 'vuex'
import { RootState, ServicePlanState } from '../../../types'

const getters: GetterTree<ServicePlanState, RootState> = {
  // NOTE: useComingStore用に追加
  getAllState: (state) => {
    return state
  },

  getSelections: (state: ServicePlanState) => {
    return state.selections
  },

  getCustomer: (state: ServicePlanState) => {
    return state.customer
  },

  getDate: (state: ServicePlanState) => {
    return state.date
  },

  getIsValidate: (state: ServicePlanState) => {
    return state.isValidate
  },
}

export default getters
