import { Restate } from '~/composable/estimate/repository/graduation/EstimateRestate'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { FormValues割引 } from '~/composable/estimate/viewModel/graduation/Fields/Fields割引'

export const useRestore割引 = () => {
  const store = useGraduateStore<FormValues割引>('tab割引')

  const restore割引 = (discounts: Restate['discounts']) => {
    if (!discounts) {
      return
    }

    store.setAll({
      waribiki適用: discounts.optionId割引,
      waribiki金額入力: discounts.割引額,
    })
  }
  return { restore割引 }
}
