import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { GRADUATION_ZORI } from '~/constants/estimate/comingOfAge'
import { ZoriBagState, ZoriState } from '~/store/types'

export const useRestore草履1個目 = () => {
  const store = useComingStore<ZoriBagState>('ZoriBag')

  const restore草履1個目 = (
    zori: Restate['items']['草履1個目'],
    plan: Restate['plan']
  ) => {
    if (!plan || !zori) return
    const isPPDiscount = zori.flags.isPPDiscount
    const isPPAddingPrice = zori.flags.isPPAddingPrice

    /* 初期値を設定 ※必ず入力される値はここで設置 */
    const formValues: ZoriState = {
      /**
       * NOTE: planLineZoriは草履1個目におけるpatternLineと同様で、選択肢のidを保持する
       * ↓保持される選択肢のindex
       * [0]種別
       * [1]形態 || 商品
       * [2]商品 || 単品レンタルor単品購入
       * [3]振袖レンタルだった場合 ||
       */
      planLineZori: [zori.optionId種別, 0, 0, 0],
      productZori: {
        id: 0,
        post_number_code: '',
        product_name: '',
        disabled: false,
        price: {
          rental: 0,
          buy: 0,
          set_purchase: 0,
        },
      },
      inputZori: {
        productName: zori.商品名,
        buyPrice: 0,
        rentalPrice: 0,
        rankupBuyPrice: 0,
        rankupRentalPrice: 0,
        singleBuy: 0,
        singleRental: 0,
        rentalPremiumPrice: 0,
        buyPremiumPrice: 0,
      },
      priceZori: {
        buy: 0,
        rental: 0,
      },
    }

    /* 以下で入力内容の条件に基づき、必要な項目を埋めていく */
    if (!zori.flags.is持込 && !plan.flags.is振袖持込) {
      formValues.planLineZori[1] = zori.optionId形態
      // NOTE:未定状態では見積作成できないため、「未定」選択時の状態復帰は考慮不要
      if (zori.selected商品) {
        formValues.planLineZori[2] = zori.optionId商品
        formValues.productZori = zori.selected商品
      }
    } else if (!zori.flags.is持込 && plan.flags.is振袖持込) {
      if (zori.selected商品) {
        formValues.planLineZori[1] = zori.optionId商品
        formValues.productZori = zori.selected商品
      }
    }

    if (!plan.flags.is振袖持込 && zori.flags.isU系商品) {
      if (!plan.flags.is写のみレンタル) {
        formValues.planLineZori[3] = zori.optionId振袖レンタルの場合
        formValues.inputZori.buyPrice = zori.購入価格
        formValues.priceZori.buy = zori.購入価格
      } else {
        formValues.inputZori.singleBuy = zori.単品購入価格
      }
      if (zori.flags.is同じ商品単品購入) {
        formValues.inputZori.singleBuy = zori.単品購入価格
      } else {
        formValues.inputZori.rentalPrice = zori.レンタル価格
        formValues.priceZori.rental = zori.レンタル価格
      }
    }

    /**
     * 【セット内】「こちらでお選びします」を選択時復元不要
     * 【ランクアップPP内】inputZori.rankupBuy/RentalPriceを使っていないためセット内と同じ処理でOK...
     */
    if (zori.flags.isセット内現品 || zori.flags.isランクアップPP内) {
      formValues.planLineZori[3] = zori.optionId振袖レンタルの場合

      if (!plan.flags.is写のみレンタル) {
        formValues.inputZori.buyPrice = zori.購入価格
        formValues.priceZori.buy = zori.購入価格
      }
      if (zori.flags.is同じ商品単品購入) {
        formValues.inputZori.singleBuy = zori.単品購入価格
      } else {
        formValues.inputZori.rentalPrice = zori.レンタル価格
        formValues.priceZori.rental = zori.レンタル価格
      }
    }

    if (zori.flags.isランクアップPP外) {
      formValues.planLineZori[3] = zori.optionId振袖レンタルの場合

      if (!plan.flags.is写のみレンタル) {
        formValues.inputZori.rankupBuyPrice = zori.購入価格
        formValues.inputZori.buyPremiumPrice = zori.プレミアム購入価格
      }
      if (zori.flags.is同じ商品単品購入) {
        formValues.inputZori.singleBuy = zori.単品購入価格
      } else {
        formValues.inputZori.rankupRentalPrice = zori.レンタル価格
        formValues.inputZori.rentalPremiumPrice = zori.プレミアムレンタル価格
      }
    }

    if (plan.flags.is振袖持込 && zori.flags.isU系商品) {
      formValues.inputZori.rentalPrice = zori.レンタル価格
      formValues.priceZori.rental = zori.レンタル価格
      formValues.priceZori.buy = zori.単品購入価格
      formValues.inputZori.singleBuy = zori.単品購入価格
    }
    if (plan.flags.is振袖持込 && !zori.flags.isU系商品) {
      formValues.planLineZori[2] = zori.optionId単品選択
      formValues.inputZori.singleRental = zori.レンタル価格
      formValues.inputZori.singleBuy = zori.単品購入価格

      if (!zori.flags.is現品) {
        formValues.priceZori.rental = zori.レンタル価格
        formValues.priceZori.buy = zori.購入価格
      }
    }

    // PP適用時の判定
    if (isPPDiscount) {
      if (isPPAddingPrice) {
        formValues.inputZori.buyPremiumPrice = zori.プレミアム購入価格
      } else {
        if (zori.flags.isランクアップPP内) {
          formValues.planLineZori[3] = GRADUATION_ZORI.BUY_THE_SAME_PRODUCT
        }
      }
    }

    /* ストアに内容を保存する */
    store.setOf<ZoriBagState['planLineZori']>(
      'setSelectionZori',
      formValues.planLineZori
    )
    store.setOf<ZoriBagState['inputZori']>('setInputZori', formValues.inputZori)
    store.setOf<ZoriBagState['productZori']>(
      'setProductZori',
      formValues.productZori
    )
    store.setOf<ZoriBagState['priceZori']>('setPriceZori', formValues.priceZori)
    store.setOf<ZoriBagState['isPPDiscount']>('setIsPPDiscount', isPPDiscount)
    store.setOf<ZoriBagState['isPPAddingPriceZori']>(
      'setIsPPAddingPriceZori',
      isPPAddingPrice
    )

    store.setIsValidate(false)
  }

  return { restore草履1個目 }
}
