import { CustomerReadyState } from '../../types'

const state = (): CustomerReadyState => ({
  selectedContents: [], // お客様ご自身でご準備いただくもの
  input: {
    otherItems: [], // 選択肢以外でお客様ご自身でご準備いただくもの
  },
  isValidate: null,
})

export default state
