import { computed, useStore } from '@nuxtjs/composition-api'
import ProductClassification from '~/constants/enums/productClassification'
import {
  ExtendsEstimateState,
  ProductItem,
} from '~/store/Contracts/ExtendsEstimate/state'
import { State as PricesState } from '~/store/PriceTable/state'
/**
 * 契約書のExtendsEstimate(見積側で入力した値を保持するストア)storeを操作するComposition関数
 */
export const useExtendsEstimateStore = () => {
  /*
   * constructor
   */
  const store = useStore()
  const storePath = `Contracts/ExtendsEstimate`
  /*
   * public getters and methods
   */
  /**
   * @interface S 指定したstate名の文字列を指定すると返り値に型がつく
   */
  const get = <S extends keyof ExtendsEstimateState>(
    fieldId: keyof ExtendsEstimateState
  ) => {
    const storeFullPath = `${storePath}/getState`
    const state = computed(
      () => store.getters[storeFullPath](fieldId) as ExtendsEstimateState[S]
    )
    if (state.value === undefined) {
      const errorMsg =
        'storeから返された値がundefinedでした。\npath:' + storeFullPath
      console.error(errorMsg)
    }
    return state
  }
  const getAll = () => {
    const storeFullPath = `${storePath}/getAllState`
    const state = computed(
      () => store.getters[storeFullPath] as ExtendsEstimateState
    )
    return state
  }
  const getWPhoto = <S extends keyof ExtendsEstimateState['wPhoto']>(
    fieldId: keyof ExtendsEstimateState['wPhoto']
  ) => {
    const storeFullPath = `${storePath}/getWPhotoState`
    const state = computed(
      () =>
        store.getters[storeFullPath](
          fieldId
        ) as ExtendsEstimateState['wPhoto'][S]
    )
    if (state.value === undefined) {
      const errorMsg =
        'storeから返された値がundefinedでした。\npath:' + storeFullPath
      console.error(errorMsg)
    }
    return state
  }
  const getAllWPhoto = () => {
    const storeFullPath = `${storePath}/getWPhotoAllState`
    const state = computed(
      () => store.getters[storeFullPath] as ExtendsEstimateState['wPhoto']
    )
    return state
  }
  /**
   * 指定した小物や着物のオブジェクトを取得する
   * @param id 品種区分番号のid ex: '~/constants/enums/productClassification'
   */
  const getProductBy = (id: number) => {
    const storeFullPath = `${storePath}/getProducts`
    const state = computed(
      () => store.getters[storeFullPath](id) as ProductItem[] | undefined
    )
    return state
  }
  const getPrices = () => {
    const path = 'PriceTable/getPrices'
    const state: PricesState['prices'] = store.getters[path]
    return state
  }

  /**
   * メインの着物の情報を取得する
   */
  const getMainKimono = () => {
    const storeFullPath = `${storePath}/getProducts`
    const state振袖 = computed(
      () =>
        store.getters[storeFullPath](ProductClassification.hurisode) as
          | ProductItem[]
          | undefined
    )
    const state二尺袖 = computed(
      () =>
        store.getters[storeFullPath](ProductClassification.nisyakusode) as
          | ProductItem[]
          | undefined
    )
    const selectedKimono = computed(() => {
      const furisode = state振袖.value && state振袖.value[0]
      const nisyaku = state二尺袖.value && state二尺袖.value[0]
      return furisode ?? nisyaku
    })
    return selectedKimono
  }
  const getWPhotoProductBy = (id: number) => {
    const storeFullPath = `${storePath}/getWPhotoProducts`
    const state = computed(
      () => store.getters[storeFullPath](id) as ProductItem[] | undefined
    )
    return state
  }
  /**
   * @interface S 指定したstate名の文字列を指定すると渡す値に型チェックが入る
   */
  const set = <S extends keyof ExtendsEstimateState>(
    fieldId: keyof ExtendsEstimateState,
    value: ExtendsEstimateState[S]
  ) => {
    const storeFullPath = `${storePath}/setState`
    store.commit(storeFullPath, { stateName: fieldId, value })
  }
  const setAll = (values: ExtendsEstimateState) => {
    const storeFullPath = `${storePath}/setAllState`
    store.commit(storeFullPath, values)
  }

  const init = (fieldId: keyof ExtendsEstimateState) => {
    const storeFullPath = `${storePath}/initState`
    store.commit(storeFullPath, fieldId)
  }
  const initAll = () => {
    const storeFullPath = `${storePath}/initAllState`
    store.commit(storeFullPath)
  }

  return {
    get,
    getAll,
    set,
    setAll,
    init,
    initAll,
    getProductBy,
    getPrices,
    getWPhotoProductBy,
    getWPhoto,
    getAllWPhoto,
    getMainKimono,
  }
}
