import { computed } from '@nuxtjs/composition-api'
import { useComingStore } from '../../ComingOfAgeStore'
import { EstimateListState, FurisodeState } from '~/store/types'
import { Enum見積振袖仕立て } from '~/constants/enums/estimateDetail'
import { State as PricesState } from '~/store/PriceTable/state'

export const useState見積一覧 = () => {
  const values見積一覧 =
    useComingStore<EstimateListState['fields']>('EstimateList').getAll()
  const priceTable = useComingStore<FurisodeState>('Furisodes').getPrices() // Store価格テーブルの取得

  return computed(() => getState見積一覧(values見積一覧.value, priceTable))
}

export const getState見積一覧 = (
  values見積一覧: EstimateListState['fields'],
  priceTable: PricesState['prices']
) => {
  const selected仕立て = values見積一覧.selectedSewings[0]
  const selected長襦袢 = values見積一覧.selectedNagajubans[0]
  const is高級手縫い = selected仕立て === Enum見積振袖仕立て.高級縫い
  const is正絹長襦袢 = selected長襦袢 === 2
  const amount_adjustments_for_wphoto =
    values見積一覧?.amount_adjustments_for_wphoto ?? []
  const amount_adjustments = values見積一覧?.amount_adjustments ?? []
  const amountAdjustments = amount_adjustments.concat(
    amount_adjustments_for_wphoto
  )
  return {
    selected仕立て,
    is高級手縫い,
    price手縫い: is高級手縫い ? priceTable.estimate.高級手縫い : 0,
    selected長襦袢,
    is正絹長襦袢,
    price長襦袢: is正絹長襦袢 ? priceTable.estimate.正絹長襦袢 : 0,
    selectedプラン列: values見積一覧.selectedPlan,
    selectedWフォトプラン列: values見積一覧.selectedWPPlan,
    amountAdjustments,
    is重ね衿RankUp: values見積一覧.isKasaneeriSecondRankUp,
    is帯揚げRankUp: values見積一覧.isObiageSecondRankUp,
    金額: {
      本体合計: values見積一覧.totalPrice - values見積一覧.photoTotalPrice,
      Wフォト合計: values見積一覧.photoTotalPrice,
      総合計: values見積一覧.totalPrice,
    },
  }
}
