import { Addition } from '~/api/estimateRepository'
import { EstimateState } from '~/composable/estimate/repository/graduation/EstimateState'
import { EstimateAddition } from '~/constants/enums/estimateAddition'
import { State as PricesState } from '~/store/PriceTable/state'

export const useAdditionsFormatter = (
  estimateState: EstimateState,
  priceTable: PricesState['prices']
) => {
  const getFormattedAdditions = () => {
    const additions: Addition[] = []
    const 別衿縫い付けあり = estimateState.value.別衿.別衿加工必要
    if (別衿縫い付けあり) {
      additions.push({
        addition_type: EstimateAddition.separateCollarProcessing,
        price: priceTable.estimate.別衿加工代,
      })
    }
    return additions
  }
  return { getFormattedAdditions }
}
