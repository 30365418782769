import { Ref } from '@nuxtjs/composition-api'
import { State, Tab } from '~/store/SingleItemService/Main/state'

/** api */
import { useStaff } from '~/composable/api/useStaff'
import { SingleItemServiceContractDetail } from '~/api/singleItemRepository'

/** utile */
import DateTime from '~/utils/dateTime'
import { getPriceByPaymentRepo } from '~/utils/utils'

import { useMasterData } from '~/composable/api/useMasterData'

export const useRestatePayment = (
  contractDetail: Ref<SingleItemServiceContractDetail | undefined>
) => {
  const { staff, fetchStaff } = useStaff() // スタッフ情報
  const {
    fetchPayments,
    payments,
    fetchTransferPlaces,
    filteredTransferPlaces,
  } = useMasterData() // 支払い情報取得

  // staff_idを元にsubStaffのデータ取得
  const getPaymentStaff = async () => {
    const paymentList = [...payments.value]
    const paymentStaffId = paymentList.reverse()[0]?.staff_id ?? 0

    if (!paymentStaffId) return

    await fetchStaff(paymentStaffId)
  }

  // 振込先情報
  const getTransferPlacesinfo = async () => {
    await fetchTransferPlaces()
  }

  const restatePayment = (initialStoreData: State) => {
    const paymentList = [...payments.value]
    const { balancePrice, amountPrice } = getPriceByPaymentRepo(
      paymentList,
      contractDetail.value?.contract_total_price
    )

    const target = filteredTransferPlaces.value.find(
      ({ id }) => id === contractDetail.value?.account_payable_id
    )
    const payeeInfo = target
      ? `${target.bank_name} ${target.branch_name} ${target.account_type_name} No.${target.account_number}`
      : ''

    const paymentNote = paymentList.reverse()[0]?.notes

    const state: State['data']['payment'] = {
      dueDate: new DateTime().toDateString(),
      staff: staff.value ?? null,
      category: null,
      payee: target ? target.id : null,
      bankName: target ? target.bank_name : null, // 銀行名
      branchName: target ? target.branch_name : null, // 支店名
      payeeInfo: payeeInfo, // 銀行情報
      paymentDeadline: contractDetail.value?.balance_due_date
        ? contractDetail.value?.balance_due_date
        : '',
      details: initialStoreData.data.payment.details,
      total: null,
      notes: paymentNote,
      isPayment: null, // 支払いが必要かどうか
      balance: balancePrice, // 残高
      paidAmount: amountPrice, // 支払い分
    }

    const tab: Tab = {
      text: 'お支払',
      isComplete: false,
      isShow: true,
      isCurrent: false,
    }
    return {
      state,
      tab,
    }
  }
  return {
    restatePayment,
    getPaymentStaff,
    getTransferPlacesinfo,
    fetchPayments,
  }
}
