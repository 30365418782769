
















import {
  defineComponent,
  ref,
  useStore,
  useRouter,
} from '@nuxtjs/composition-api'
import TheDialogBeforeLogout from './TheDialogBeforeLogout.vue'
import { Store } from 'vuex'

export default defineComponent({
  components: {
    TheDialogBeforeLogout,
  },
  setup() {
    const store = useStore() as Store<any>
    const router = useRouter()
    const user = ref(store.getters['LoginUser/getUser'])
    const settingShopName = ref(store.getters['SettingShop/getShop']?.name)
    const dialogBeforeLogout = ref<InstanceType<
      typeof TheDialogBeforeLogout
    > | null>()

    const openDialogBeforeEstimate = () => {
      dialogBeforeLogout.value?.openModal()
    }

    return {
      user,
      dialogBeforeLogout,
      openDialogBeforeEstimate,
      settingShopName,
    }
  },
})
