import { computed } from '@nuxtjs/composition-api'
import {
  SelectedPlanListType,
  getBadgeText,
  getItemPlan,
  getItemPlanFlags,
  getItemPrices,
  getPriceTypeText,
} from '~/composable/estimate/repository/comingOfAge/states/utilities'
import { AccessoryPlanState, EstimateListState } from '~/store/types'
import { useComingStore } from '../../ComingOfAgeStore'
import { useScreenTypeFlags } from '~/composable/estimate/domain/ComingOfAge/ScreenTypeFlags'
import { BaseItemState, PlanFlags } from './types'
import ProductClassification from '~/constants/enums/productClassification'
import { useState商品設定 } from './StateOtherTabs'
import { useSelectedPlanListType } from '~/composable/estimate/domain/ComingOfAge/SelectedPlanListType'

type Kasaneeri = BaseItemState & {
  品種区分: 5
}

export const useState重ね衿 = () => {
  const values重ね衿1 = useComingStore<AccessoryPlanState>('Kasaneeri').getAll()
  const values重ね衿2 =
    useComingStore<AccessoryPlanState>('Kasaneeri2').getAll()
  const values重ね衿3 =
    useComingStore<AccessoryPlanState>('Kasaneeri3').getAll()
  const values見積一覧 =
    useComingStore<EstimateListState['fields']>('EstimateList').getAll()
  const state商品設定 = useState商品設定()
  const { showTypeフルセット, showType写真のみレンタル, showType持ち込み } =
    useScreenTypeFlags()
  const selectedPlanListType = useSelectedPlanListType()

  return computed(() => {
    const flags = {
      isフルセット: showTypeフルセット.value,
      is写のみレンタル: showType写真のみレンタル.value,
      is持ち込み: showType持ち込み.value,
    }
    const values重ね衿 = [
      values重ね衿1.value,
      values重ね衿2.value,
      values重ね衿3.value,
    ]

    /**
     * NOTE: isKasaneeriSecondRankUpはラジオボタンの値であり、初期値がtrueのため
     * isPPDiscountが有効かどうかも判定している
     */
    const isRankUp =
      !!values重ね衿2.value.isPPDiscount &&
      values見積一覧.value.isKasaneeriSecondRankUp

    const selectedPlanListTypeFlags = {
      isレンタルプラン: selectedPlanListType.isレンタルプラン.value,
      is購入プラン: selectedPlanListType.is購入プラン.value,
      is未決定: selectedPlanListType.is未決定.value,
    }

    return getState重ね衿(
      values重ね衿,
      state商品設定.value.重ね衿,
      flags,
      selectedPlanListTypeFlags,
      isRankUp
    )
  })
}

export const getState重ね衿 = (
  values重ね衿: AccessoryPlanState[],
  count重ね衿: number,
  planFlags: PlanFlags,
  selectedPlanListType: SelectedPlanListType,
  isRankUp: boolean
) => {
  const filledStoreValues = values重ね衿.slice(0, count重ね衿)
  return filledStoreValues.map((value, i) => {
    const { selections, isPPDiscount } = value
    const itemFlags = getItemPlanFlags(selections)
    const itemPlan = getItemPlan(selections)
    const isRankupApplied = i === 1 && isRankUp
    const numberText = i > 0 ? ' - ' + (i + 1) : ''
    const kasaneeri: Kasaneeri = {
      品目名:
        ProductClassification.toWord(ProductClassification.kasaneeri) +
        numberText,
      品種区分: ProductClassification.kasaneeri,
      商品名: '',
      小物プラン: isRankupApplied ? 3 : itemPlan,
      レンタル価格: 0,
      購入価格: 0,
      プレミアムレンタル価格: 0,
      プレミアム購入価格: 0,
      見積表示: null,
      表示価格: null,
      label: getBadgeText(itemPlan),
      cellLabel: getPriceTypeText(selections),
      flags: {
        isランクアップ:
          itemFlags.isランクアップPP内 || itemFlags.isランクアップPP外,
        isPPDiscount: isPPDiscount,
      },
    }
    const updatedPrices = getItemPrices(
      value,
      kasaneeri,
      planFlags,
      i,
      planFlags.is持ち込み ? undefined : selectedPlanListType
    )

    kasaneeri.レンタル価格 = updatedPrices.レンタル価格
    kasaneeri.購入価格 = updatedPrices.購入価格
    kasaneeri.プレミアムレンタル価格 = updatedPrices.プレミアムレンタル価格
    kasaneeri.プレミアム購入価格 = updatedPrices.プレミアム購入価格
    kasaneeri.見積表示 = updatedPrices.見積表示
    kasaneeri.表示価格 = updatedPrices.表示価格
    return kasaneeri
  })
}
