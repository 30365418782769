import { Ref, computed } from '@nuxtjs/composition-api'

/** types */
import { PriceTableType } from '~/constants/enums/priceTableType'
import { State as PricesState, getInitialStore } from '~/store/PriceTable/state'
import { EstimateDetail } from '~/api/estimateRepository'

export const useRestatePriceTable = (
  estimate: Ref<EstimateDetail | undefined>
) => {
  const computePriceTable = (
    referencePrices: EstimateDetail['reference_price_information'],
    state: PricesState['prices']
  ): PricesState['prices'] => {
    if (!referencePrices) {
      return state
    }
    let { 割引率: discountState, ...estimateState } = state.estimate // stateから割引率と割引率以外を抽出
    let { ...contractsState } = state.contracts

    // typeに応じて価格と割引率を算出して整形
    Object.entries(referencePrices).forEach(([_, value]) => {
      // typeからWordを算出
      const priceTableToWord = PriceTableType.toWord(value.type)

      const estimateStateKeys = Object.keys(estimateState)
      const isEstimateKey = estimateStateKeys.includes(priceTableToWord)

      if (value.amount !== null && isEstimateKey) {
        estimateState = { ...estimateState, [priceTableToWord]: value.amount } // 金額(estimate)
      } else if (value.amount !== null) {
        contractsState = { ...contractsState, [priceTableToWord]: value.amount } // 金額(contracts)
      } else if (value.rate !== null) {
        discountState = { ...discountState, [priceTableToWord]: value.rate } // 割引(%)
      }
    })

    return {
      estimate: { ...estimateState, 割引率: { ...discountState } },
      contracts: { ...contractsState },
    }
  }

  const store = getInitialStore().prices // Storeの初期値を取得

  const referencePrices = estimate.value?.reference_price_information
  const priceTable = computePriceTable(referencePrices, store) // 価格と割引率を算出
  return computed(() => {
    return priceTable
  })
}
