











import { defineComponent } from '@nuxtjs/composition-api'
import AppIcon from './AppIcon.vue'
import IconCircle from './icons/IconCircle.vue'

export default defineComponent({
  components: {
    AppIcon,
    IconCircle,
  },
  props: {
    fill: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['click'],
  setup(_, { emit }) {
    const handleClick = (event: Event) => emit('click', event)

    return { handleClick }
  },
})
