import { MutationTree } from 'vuex'
import { GraduationCeremonyState } from '../../types'

const mutations: MutationTree<GraduationCeremonyState> = {
  setCustomerId: (state, customerId) => {
    state.customerId = customerId
  },
  setDressing: (state, dressing) => {
    state.dressing = dressing
  },
  setCeremonyInfo: (state, ceremonyInfo) => {
    state.ceremonyInfo = ceremonyInfo
  },
  setPass: (state, pass) => {
    state.pass = pass
  },
  setDelivery: (state, delivery) => {
    state.delivery = delivery
  },
  setReturnInfo: (state, returnInfo) => {
    state.returnInfo = returnInfo
  },
  setDressingPlaces: (state, dressingPlaces) => {
    state.dressingPlaces = dressingPlaces
  },
  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },

  delete: (state) => {
    state.customerId = 0
    state.dressing = 1
    state.ceremonyInfo = {
      date: '', // 卒業式使用日
      shopId: 0, // 着付場所id
      shopName: '', // 着付場所
      entranceTime: null, // 着付場所入場時間
      remarks: null, // 備考
      abbreviatedName: '',
    }
    state.pass = {
      option: null, // お渡し方法 (1: ご来店, 2: お送り)
      date: null, // 卒業式お渡し日
      shopId: 0, // お渡し場所id
      shopName: '', // 卒業式お渡し場所
      arrivalDate: null, // 到着日
      abbreviatedName: '',
    }
    state.delivery = {
      option: null, // お届け先住所 (1: ご利用者様現住所, 2: ご実家住所, 3: 別住所)
      // ご利用者様
      zip_code: null, // 郵便番号
      prefecture_id: 0, // 都道府県
      address1: null, // 市区町村
      address2: null, //番地以降(建物名など)
      phone_number: null, // 電話番号
      // ご実家
      home_zip_code: null, // 郵便番号
      home_prefecture_id: 0, // 都道府県
      home_address1: null, // 市区町村
      home_address2: null, //番地以降(建物名など)
      home_phone_number: null, // 電話番号
      // 別住所
      another_zip_code: null, // 郵便番号
      another_prefecture_id: 0, // 都道府県
      another_address1: null, // 市区町村
      another_address2: null, //番地以降(建物名など)
      another_phone_number: null, // 電話番号
    }
    state.returnInfo = {
      date: null, // 卒業式ご返却日
      shopId: 0, // 卒業式ご返却場所id
      shopName: '', // 卒業式ご返却場所
      abbreviatedName: '',
    }
    state.dressingPlaces = {
      id: null, // 着付け会場ID
      name: null, // 着付け会場名
      dressingAddressInfo: null, // 着付会場住所
    }
    state.isValidate = null
  },
}

export default mutations
