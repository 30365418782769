import { GetterTree } from 'vuex'
import { FormValuesその他商品 } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsその他商品'
import { RootState } from '~/store/Estimate/GraduationCeremony/types'

const getters: GetterTree<{ fields: FormValuesその他商品 }, RootState> = {
  getState: (state) => (stateName: keyof FormValuesその他商品) => {
    return state.fields[stateName]
  },

  getAllState: (state) => {
    return state.fields
  },
}
export default getters
