import { MutationTree } from 'vuex'
import { getInitialStore } from './state'
import { FormValues重ね衿_着物持込 } from '~/composable/estimate/viewModel/graduation/Fields/Fields重ね衿_着物持込'

const mutations: MutationTree<{ fields: FormValues重ね衿_着物持込 }> = {
  setState: (
    state,
    payload: { stateName: keyof FormValues重ね衿_着物持込; value: any }
  ) => {
    state.fields[payload.stateName] = payload.value
  },

  setAllState: (state, values: FormValues重ね衿_着物持込) => {
    state.fields = values
  },

  initState: (state, fieldId: keyof FormValues重ね衿_着物持込) => {
    const initialStore = getInitialStore()
    state.fields[fieldId] = initialStore[fieldId] as any
  },

  initAllState: (state) => {
    state.fields = getInitialStore()
  },
}

export default mutations
