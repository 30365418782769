import { computed } from '@nuxtjs/composition-api'
import { useScreenTypeFlags } from '~/composable/estimate/domain/ComingOfAge/ScreenTypeFlags'
import { EstimateState } from '~/composable/estimate/repository/comingOfAge/EstimateState'
import { PlanFlags } from '~/composable/estimate/repository/comingOfAge/states/types'
import { Item } from '../types'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { EstimateJkphotoInAdvanceState } from '~/store/types'
import { State as PricesState } from '~/store/PriceTable/state'

export const useListItem振袖Formatter = (
  state: EstimateState['振袖'] | EstimateState['Wフォト']['振袖']
) => {
  const { showTypeフルセット, showType写真のみレンタル, showType持ち込み } =
    useScreenTypeFlags()

  return computed(() => {
    const flags = {
      isフルセット: showTypeフルセット.value,
      is写のみレンタル: showType写真のみレンタル.value,
      is持ち込み: showType持ち込み.value,
    }
    return getListItem振袖(state, flags)
  })
}
export const getListItem振袖 = (
  values振袖: EstimateState['振袖'] | EstimateState['Wフォト']['振袖'],
  // NOTE: Wフォトの場合は使わないので全てfalseで判定する
  { is写のみレンタル }: PlanFlags = {
    isフルセット: false,
    is写のみレンタル: false,
    is持ち込み: false,
  }
): Item | null => {
  return {
    item: {
      name: values振袖.品目名,
      rankType: values振袖.label || undefined,
      linkText: values振袖.flags.is振袖保留
        ? '仮設定中'
        : values振袖.商品名 || undefined,
      product_type_id: values振袖.品種区分,
    },
    column1: {
      price: values振袖.レンタル価格,
    },
    column2: {
      price: values振袖.レンタル価格,
    },
    column3: {
      price: is写のみレンタル ? values振袖.レンタル価格 : values振袖.購入価格,
    },
    column4: {
      price: is写のみレンタル ? values振袖.レンタル価格 : values振袖.購入価格,
    },
  }
}

export const useListItemJK前撮り振袖Formatter = (
  stateJK前撮り: EstimateState['JK前撮り']
) => {
  const { showTypeフルセット, showType写真のみレンタル, showType持ち込み } =
    useScreenTypeFlags()
  const priceTable =
    useComingStore<EstimateJkphotoInAdvanceState>(
      'JkPhotoInAdvance'
    ).getPrices() // Store価格テーブルの取得

  return computed(() => {
    const flags = {
      isフルセット: showTypeフルセット.value,
      is写のみレンタル: showType写真のみレンタル.value,
      is持ち込み: showType持ち込み.value,
    }
    return getListItemJK前撮り振袖(stateJK前撮り, flags, priceTable)
  })
}
export const getListItemJK前撮り振袖 = (
  stateJK前撮り: EstimateState['JK前撮り'],
  { isフルセット }: PlanFlags,
  priceTable: PricesState['prices']
): Item[] | null => {
  if (!isフルセット || !stateJK前撮り.is適用) return null

  const JK前撮り振袖代 = {
    item: {
      name: 'JK前撮り振袖代',
      addition: true,
    },
    column1: {
      price: priceTable.estimate.JK前撮り振袖代 ?? 0,
    },
    column2: {
      price: priceTable.estimate.JK前撮り振袖代 ?? 0,
    },
    column3: {
      price: priceTable.estimate.JK前撮り振袖代 ?? 0,
    },
    column4: {
      price: priceTable.estimate.JK前撮り振袖代 ?? 0,
    },
  }

  const JK前撮りヘアメイク着付代 = {
    item: {
      name: 'JK前撮りヘアメイク着付代',
      addition: true,
    },
    column1: {
      price: priceTable.estimate.JK前撮りヘアメイク着付け代 ?? 0,
    },
    column2: {
      price: priceTable.estimate.JK前撮りヘアメイク着付け代 ?? 0,
    },
    column3: {
      price: priceTable.estimate.JK前撮りヘアメイク着付け代 ?? 0,
    },
    column4: {
      price: priceTable.estimate.JK前撮りヘアメイク着付け代 ?? 0,
    },
  }

  const JK前撮りアルバム代 = {
    item: {
      name: 'JK前撮りアルバム代',
      addition: true,
    },
    column1: {
      text: '5P×1冊',
      price: 0,
    },
    column2: {
      text: '5P×1冊',
      price: 0,
    },
    column3: {
      text: '5P×1冊',
      price: 0,
    },
    column4: {
      text: '5P×1冊',
      price: 0,
    },
  }

  return [JK前撮りヘアメイク着付代, JK前撮り振袖代, JK前撮りアルバム代]
}
