import { COORDINATE_PLAN } from '~/constants/estimate/config'
import { ItemPlan } from '~/constants/enums/itemPlan'
import { EstimateSinglePriceType } from '~/constants/enums/estimateSinglePriceType'
import { ComputedRef, Ref, computed } from '@nuxtjs/composition-api'
import { EstimateDetail, Items } from '~/api/estimateRepository'
import ProductClassification from '~/constants/enums/productClassification'
import { useRestatePlan } from '~/composable/estimate/repository/comingOfAge/restates/RestatePlan'

export const usePPZoriBagPlan = () => {
  type priceType = {
    priceType: number | undefined
  }
  type isPPAddingPrice = {
    isPPAddingPrice: boolean
  }
  /**
   * NOTE: 【復元時】PP適用した草履・バッグの、元の価格タイプを返す処理
   * セット内・PP追加価格ありの場合：単品購入 に変換
   * ランクアップ内・PP追加価格ありの場合：単品購入 に変換
   * 単品購入・PP追加価格なしの場合：undefined に変換
   */
  const getZoriBagPPPriceType = (
    itemPlan: number,
    priceType: number | undefined,
    isPPDiscount: boolean | undefined,
    isPPAddingPrice: boolean | undefined,
    selectedPlan: number
  ): priceType => {
    if (isPPDiscount && selectedPlan === COORDINATE_PLAN.BUY_PREMIUM) {
      if (isPPAddingPrice && itemPlan === ItemPlan.inSet)
        return {
          priceType: EstimateSinglePriceType.singlePurchasePrice,
        }
      // isPPDiscountがtrueの場合、isPPAddingPriceはboolean値のみ
      if (
        !isPPAddingPrice &&
        (itemPlan === ItemPlan.toBeDecided ||
          itemPlan === ItemPlan.rankUpInPremium)
      )
        return {
          priceType: undefined,
        }
    }
    return { priceType: priceType }
  }

  /**
   * NOTE:【復元時】草履・バッグがPP適用されているかの判定処理
   * @param estimate
   * @param isFullSet
   * @returns
   */
  const getZoriBagPPDiscount = (
    estimate: Ref<EstimateDetail | undefined>
  ): ComputedRef<boolean> => {
    const restateプラン = useRestatePlan(estimate)
    return computed(() => {
      if (
        restateプラン.value?.flags.isフルセットプラン &&
        estimate.value?.selected_plan === COORDINATE_PLAN.BUY_PREMIUM &&
        estimate.value?.items
      )
        return estimate.value?.items.some((item) => {
          let premiumPrice = 0
          /**
           * NOTE: item.premium_purchase_priceがstring型で返ってくるためNumberに変換
           */
          if (typeof item.premium_purchase_price === 'string') {
            premiumPrice = parseInt(item.premium_purchase_price)
          }
          const is草履バッグ = [
            ProductClassification.zori,
            ProductClassification.bag,
          ].includes(item.product_type_id)

          return is草履バッグ && !!premiumPrice
        })

      return false
    })
  }

  /**
   * NOTE:【復元時】草履・バッグでPP適用時の追加価格が発生しているかの判定処理
   * @param item
   * @param isPPDiscount
   * @returns
   */
  const getZoriBagPPAddingPrice = (
    item: Items,
    isPPDiscount: boolean | undefined
  ): isPPAddingPrice => {
    if (isPPDiscount) {
      let premiumPrice = 0
      /**
       * NOTE: item.premium_purchase_priceがstring型で返ってくるためNumberに変換
       */
      if (typeof item.premium_purchase_price === 'string') {
        premiumPrice = parseInt(item.premium_purchase_price)
      }

      return { isPPAddingPrice: !!premiumPrice }
    }
    return {
      isPPAddingPrice: false,
    }
  }
  return {
    getZoriBagPPPriceType,
    getZoriBagPPDiscount,
    getZoriBagPPAddingPrice,
  }
}
