import { MutationTree } from 'vuex'
import { StaffState } from '../types'

const mutations: MutationTree<StaffState> = {
  setStaffs: (state, staffs) => {
    state.staffs = staffs
  },

  delete: (state) => {
    state.staffs = null
  },
}

export default mutations
