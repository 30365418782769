import { FormValues振袖 } from '~/composable/estimate/viewModel/graduation/Fields/Fields振袖'

//不明な点がある場合、READMEをご覧ください
export const getInitialStore = (): FormValues振袖 => {
  return {
    furisode振袖検索: null,
    furisode裄補正: null,
    furisode袖補正: null,
    furisode商品情報_振袖本体: null,
    furisode商品情報_価格選択: null,
    furisode商品情報_価格入力: null,
    furisode保留: false,
    furisode契約番号: null, // 予約済み着物が変更中の契約で選ばれているものか判定するために保持
  }
}

const state = (): { fields: FormValues振袖 } => ({
  fields: getInitialStore(),
})

export default state
