import { computed } from '@nuxtjs/composition-api'
import { EstimateState } from '~/composable/estimate/repository/comingOfAge/EstimateState'
import { Item } from '../types'
import { useScreenTypeFlags } from '~/composable/estimate/domain/ComingOfAge/ScreenTypeFlags'
import { ItemPriceType } from '~/constants/enums/itemPlan'

export const useListItemその他商品Formatter = (
  state: EstimateState['その他商品']
) => {
  const { showTypeフルセット } = useScreenTypeFlags()

  return computed(() => {
    return getListItemその他商品(state, showTypeフルセット.value)
  })
}

export const getListItemその他商品 = (
  valueその他商品: EstimateState['その他商品'],
  isFullSet: boolean
): Item[] => {
  if (valueその他商品.is適用) {
    return []
  }
  return valueその他商品.その他商品.map((item) => {
    let singleRentalPrice: number | null = 0
    let singlePurchasePrice: number | null = 0
    let text1 = ''
    let text2 = ''
    let text3 = ''
    let text4 = ''
    const isHakamaNoUseInFullset = isFullSet && !item.袴利用あり
    // フルセットで袴利用なしの場合は振袖レンタル・購入に合わせて表示
    if (isHakamaNoUseInFullset) {
      singleRentalPrice = item.単品レンタル価格
      singlePurchasePrice = item.単品購入価格
      text1 = text2 = '単品レンタル'
      text3 = text4 = '単品購入'
      // 上記以外は選択した価格タイプを表示
    } else {
      singleRentalPrice = item.価格
      singlePurchasePrice = item.価格
      text1 = text2 = text3 = text4 = ItemPriceType.toWord(item.価格タイプ)
    }
    return {
      item: {
        name: item.品目,
        product_type_id: item.品種区分,
      },
      column1: { price: singleRentalPrice, text: text1 },
      column2: { price: singleRentalPrice, text: text2 },
      column3: { price: singlePurchasePrice, text: text3 },
      column4: { price: singlePurchasePrice, text: text4 },
    }
  })
}
