import { computed, ref, useRoute } from '@nuxtjs/composition-api'
import { useDynamicTabNaviList } from './DynamicTabNaviList'
import {
  TabNaviItem,
  useTavNaviItem,
  UrlNameUnion,
  ValidateType,
} from '~/composable/estimate/service/graduation/TabNavigationItem'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { urls } from '~/constants/urls'
import { TabNaviState } from '~/store/Estimate/GraduationCeremony/types'

/**
 * 見積入力フロー内の各タブのリンク情報の配列を受け持つcomposition関数
 * @return tabs - 各タブのリンク情報の配列
 * @return currentTab - 現在いるタブの情報
 * @return setNextUrl - 次のタブ（または確認ページ）のURLをstoreに格納する関数
 */
export const useTabNaviList = () => {
  /*
   * constructor
   */
  const route = useRoute()
  const tabs = useDynamicTabNaviList()
  const tabNavi = useGraduateStore<TabNaviState>('tabNavi')
  /*
   * public getter and methods
   */
  const currentTab = computed(() => {
    const current = getTabBy(route.value.path)
    return current
  })
  const setNextUrl = () => {
    tabNavi.set('nextUrl', getNextUrl())
  }

  /*
   * private getter and methods
   */
  const getTabBy = (path: string) => {
    const storeTabs = tabNavi.get<'tabs'>('tabs').value
    // 初回アクセス時にタブの個数がstoreに保存されていない時のハンドリング
    if (tabs.value.length < storeTabs.length) {
      //* 呼び出し側でonMouted時に発火する関数に指定しているため、ここにMount時の取得の処理を書いている
      tabs.value = storeTabs
    }

    const trimPath = path.slice(path.indexOf('tab/'))
    const targetTab = tabs.value.find((tab) => {
      const trimUrl = tab.url.slice(tab.url.indexOf('tab/'))
      return trimUrl === trimPath
    })
    // 空の場合エラーではなくから文字を投げる
    if (path.includes('confirmation') || !targetTab) {
      return {
        label: '',
        url: '',
      }
    }
    return targetTab
  }
  const getNextUrl = () => {
    const currentIdx = tabs.value.findIndex((tab) => {
      return tab.url === currentTab.value.url
    })
    const { estimate_id } = route.value.params
    const nextTab = tabs.value[currentIdx + 1]

    switch (currentIdx) {
      case -1:
        return ''
      // tabs配列の最後の要素だった時は入力内容確認ページへ遷移
      case tabs.value.length - 1:
        return urls.見積書.卒業式.入力内容確認(Number(estimate_id))
      // それ以外なら配列の次のタブのurlを返す
      default:
        return nextTab.url
    }
  }

  //* 指定したタブのURLを取得する処理
  const getTabUrl = (keyword: string) => {
    const tabs = tabNavi.get<'tabs'>('tabs').value
    if (!tabs) {
      throw new Error('タブが存在しません')
    }
    const tabNumber = tabs.findIndex((e) => {
      return e.url.includes(keyword)
    })
    if (tabNumber === -1) {
      return ''
    }
    return tabs[tabNumber].url
  }
  return { tabs, currentTab, setNextUrl, getTabUrl }
}

export type TabNaviArgs =
  | [string, UrlNameUnion]
  | [string, UrlNameUnion, 0 | 1 | 2 | 3 | undefined, ValidateType | undefined]
/**
 * 見積フロー開始時に表示されるタブリンク情報のリストを生成する
 */
export const getDefaultTabs = () => {
  const itemArgs: TabNaviArgs[] = [
    ['お客様情報', 'tabお客様検索'],
    ['プラン', 'tabプラン'],
    ['商品設定', 'tab商品設定'],
    ['その他商品', 'tabその他商品', undefined, 'valid'],
    ['試着写真', 'tab試着写真'],
    ['お写真プラン', 'tabお写真プラン'],
    ['その他割引', 'tab割引', undefined, 'valid'],
    ['オプション', 'tabオプション'],
    ['備考', 'tab備考'],
  ]
  const defaultTabs: TabNaviItem[] = itemArgs.map((arg) => {
    return useTavNaviItem(arg[0], arg[1], arg[2], arg[3])
  })
  return defaultTabs
}

/*
 * プランタブのロジックで追加するタブ
 */
export const getTabs着物袴 = () => {
  return {
    振袖: useTavNaviItem('振袖', 'tab振袖'),
    二尺袖: useTavNaviItem('二尺袖', 'tab二尺袖'),
    袴: useTavNaviItem('袴', 'tab袴'),
  }
}

/*
 * 追加する髪飾りのタブ
 */
export const getTabs髪飾り = () => {
  const itemArgs: TabNaviArgs[] = [
    ['髪飾り', 'tab髪飾り', 1, 'noResult'],
    ['髪飾り-2', 'tab髪飾り', 2, 'noResult'],
    ['髪飾り-3', 'tab髪飾り', 3, 'noResult'],
  ]
  return itemArgs.map((arg) => {
    return useTavNaviItem(arg[0], arg[1], arg[2])
  })
}
/*
 * 追加する重ね衿の着物袴のタブ
 */
export const getTabs重ね衿 = () => {
  return {
    着物レンタル: [
      useTavNaviItem('重ね衿', 'tab重ね衿_着物レンタル_1個目', 1),
      useTavNaviItem('重ね衿 -2', 'tab重ね衿_着物レンタル_複数個目', 2),
      useTavNaviItem('重ね衿 -3', 'tab重ね衿_着物レンタル_複数個目', 3),
    ],
    着物持込: [
      useTavNaviItem('重ね衿', 'tab重ね衿_着物持込', 1),
      useTavNaviItem('重ね衿 -2', 'tab重ね衿_着物持込', 2),
      useTavNaviItem('重ね衿 -3', 'tab重ね衿_着物持込', 3),
    ],
  }
}

/*
 * 追加する重ね衿のその他のタブ
 */
export const getTabsDefault重ね衿 = () => {
  return {
    着物持込: useTavNaviItem('重ね衿', 'tab重ね衿_着物持込', 1),
    着物レンタル: useTavNaviItem('重ね衿', 'tab重ね衿_着物レンタル_1個目', 1),
  }
}

/*
 * 追加する半巾帯のその他のタブ
 */
export const getTabsDefault半巾帯 = () => {
  return {
    袴持込: useTavNaviItem('半巾帯', 'tab半巾帯_袴持込'),
    袴レンタル: useTavNaviItem('半巾帯', 'tab半巾帯_袴レンタル'),
  }
}

/*
 * 追加する別衿のその他のタブ
 */
export const getTabsDefault別衿 = () => {
  return {
    着物持込: useTavNaviItem('別衿', 'tab別衿_着物持込'),
    着物レンタル: useTavNaviItem('別衿', 'tab別衿_着物レンタル'),
  }
}

/*
 * 追加する草履ブーツのその他のタブ
 */
export const getTabsDefault草履ブーツ = () => {
  return {
    着物持込: useTavNaviItem('草履・ブーツ', 'tab草履ブーツ_着物持込'),
    着物レンタル: useTavNaviItem('草履・ブーツ', 'tab草履ブーツ_着物レンタル'),
  }
}

/*
 * 追加する巾着バッグのその他のタブ
 */
export const getTabsDefault巾着バッグ = () => {
  return {
    着物持込: useTavNaviItem('バッグ', 'tab巾着バッグ_着物持込'),
    着物レンタル: useTavNaviItem('巾着・バッグ', 'tab巾着バッグ_着物レンタル'),
  }
}
