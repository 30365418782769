import { ref, useContext } from '@nuxtjs/composition-api'
import { Staff } from '@/store/types'

export const useStaff = () => {
  const { $axios } = useContext()
  const staffList = ref<Staff[]>([])
  const staff = ref<Staff>()

  // 社員検索API
  const fetchStaffs = async () => {
    try {
      const res = await $axios.get<{ staffs: Staff[] }>('staffs')
      staffList.value = res.data.staffs
    } catch (error) {
      console.log('API error', error)
    }
  }

  /** スタッフid から名前を取得 */
  const getStaffName = (staffId: Number) => {
    const staff = staffList.value.find((o) => o.staff_id === staffId)
    if (!staff) {
      return '-'
    }
    const { first_name = '', last_name = '' } = staff
    return last_name + first_name
  }

  // NOTE: staff_idから直接検索できるAPIがないため一覧から取得している
  // staff_idから特定の社員を検索
  const fetchStaff = async (staffId: number) => {
    try {
      const { data: { staffs } } = await $axios.get<{ staffs: Staff[] }>('staffs')
      staff.value = staffs.find(staff => staff.staff_id === staffId)
    } catch (error) {
      console.log('API error', error)
    }
  }

  return {
    getStaffName,
    fetchStaffs,
    fetchStaff,
    staffList,
    staff,
  }
}
