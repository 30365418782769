import { Restate } from '~/composable/estimate/repository/graduation/EstimateRestate'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { FormValues巾着バッグ_着物レンタル } from '~/composable/estimate/viewModel/graduation/Fields/Fields巾着バッグ_着物レンタル'
import { FormValues巾着バッグ_着物持込 } from '~/composable/estimate/viewModel/graduation/Fields/Fields巾着バッグ_着物持込'

export const useRestore巾着バッグ = () => {
  const store着物レンタル =
    useGraduateStore<FormValues巾着バッグ_着物レンタル>(
      'tab巾着バッグ_着物レンタル'
    )
  const store着物持込 =
    useGraduateStore<FormValues巾着バッグ_着物持込>('tab巾着バッグ_着物持込')

  const restore巾着バッグ = (restate: Restate) => {
    const item = restate.items.巾着バッグ
    if (!item) {
      return
    }

    /* 初期値を設定 ※必ず入力される値はここで設置 */
    if (item.type === '着物持込') {
      /* 以下で入力内容の条件に基づき、必要な項目を埋めていく */
      const formValues: FormValues巾着バッグ_着物持込 = {
        kinchakubag種別: item.optionId種別,
        kinchakubag商品選択: item.選択商品,
        kinchakubag単品レンタル: null,
        kinchakubag単品購入: null,
        kinchakubag現品単品レンタル: { id: 0, value: '' },
        kinchakubag現品単品レンタルフリー入力: null,
        kinchakubag現品単品購入: { id: 0, value: '' },
        kinchakubag現品単品購入フリー入力: null,
        kinchakubag見積選択: item.見積表示,
      }

      if (!item.flags.is現品) {
        formValues.kinchakubag単品レンタル = item.レンタル価格
        formValues.kinchakubag単品購入 = item.購入価格
      }

      if (item.flags.is現品) {
        formValues.kinchakubag現品単品レンタルフリー入力 = item.レンタル価格
        formValues.kinchakubag現品単品購入フリー入力 = item.購入価格
      }

      /* ストアに内容を保存する */
      store着物持込.setAll(formValues)
    }

    /* 初期値を設定 ※必ず入力される値はここで設置 */
    if (item.type === '着物レンタル') {
      /* 以下で入力内容の条件に基づき、必要な項目を埋めていく */
      const formValues: FormValues巾着バッグ_着物レンタル = {
        kinchakubag種別: item.optionId種別,
        kinchakubag形態: item.optionId形態,
        kinchakubag商品選択: item.選択商品,
        kinchakubag規定商品レンタル価格: null,
        kinchakubagレンタル価格: null,
        kinchakubag現品レンタル価格: { id: 0, value: '' },
        kinchakubag現品レンタル価格フリー入力: null,
      }

      if (item.flags.isセット内) {
        formValues.kinchakubag規定商品レンタル価格 = item.レンタル価格
      }

      if (item.flags.isランクアップ) {
        formValues.kinchakubagレンタル価格 = item.レンタル価格
      }

      if (item.flags.is現品) {
        formValues.kinchakubag現品レンタル価格フリー入力 = item.レンタル価格
      }

      /* ストアに内容を保存する */
      store着物レンタル.setAll(formValues)
    }
  }
  return { restore巾着バッグ }
}
