import { GetterTree } from 'vuex'
import { RootState } from '~/store/Estimate/GraduationCeremony/types'
import { MemoStateGraduate } from '~/store/types'

const getters: GetterTree<MemoStateGraduate, RootState> = {
  getState: (state) => (fieldId: keyof MemoStateGraduate) => {
    return state[fieldId]
  },
}

export default getters
