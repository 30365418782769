import { Module } from 'vuex'
import { RootState, ComingOfAgeCeremonyState } from '../../types'
import state from './state'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

export const ComingOfAgeCeremony: Module<ComingOfAgeCeremonyState, RootState> =
  {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  }
