import { JkPhotoInAdvanceState } from '../../types'

const state = (): JkPhotoInAdvanceState => ({
  patternLine: [0],
  input: {
    jkPhotoInAdvance: 0, // JK前撮り(1: 適用する, 2: 適用しない)
    dateOfTakingJkPhotoInAdvance: '', // JK前撮り使用日
    placeOfDressing: '', // 着付場所
    timeOfEnteringDressPlace: '', // 着付場所入場時間
    timeOfEnteringSalon: '', // 美容室入場時間
    remark: '', // 備考
    itemSelection: 0,
    nameOfSalon: '', // 美容室名
    telOfSalon: '', // 美容室TEL
    salonId: 0, // 美容室ID
    shopId: null, // 美容室の店舗ID
    prefecture: '', // 都道府県
    selectedPlanType: 0,
    shubetsu: 0, // 振袖種別
    jk_hurisode_price_for_cancellation_fee: 0, // JK前撮り振袖レンタル代
    needMakeup: true, // 初期値は「はい」
  },
  shop: {
    shopId: 0,
    shopName: '',
  },
  furisode: null, // 振袖情報
  photoInfo: null, // 試着写真
  dressingPlaces: {
    id: null, // 着付け会場ID
    name: null, // 着付け会場名
    dressingAddressInfo: null, // 着付会場住所
  },
  isValidate: null,
})

export default state
