import { GetterTree } from 'vuex'
import {
  TabNaviState,
  RootState,
} from '~/store/Estimate/GraduationCeremony/types'

const getters: GetterTree<TabNaviState, RootState> = {
  getState: (state) => (fieldId: keyof TabNaviState) => {
    return state[fieldId]
  },

  getAllState: (state) => {
    return state
  },
}

export default getters
