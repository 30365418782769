import { BaseEstimateState } from '../types'

const state = (): BaseEstimateState => ({
  estimate: {
    // TODO: types.tsのBaseEstimateStateに合わせて初期値定義する
  },
  isNewEstimate: false,
  customerProfile: null,
  contractId: null, // 契約更新中のみ値が入る
  beforeContractDate: null,
})

export default state
