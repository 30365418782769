import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'

export const useRestoreOption = () => {
  const store = useComingStore('Option')
  const restoreOption = () => {
    store.setIsValidate(false)
  }

  return { restoreOption }
}
