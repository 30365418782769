import { GetterTree } from 'vuex'
import { RootState, CustomerReadyState } from '../../types'

const getters: GetterTree<CustomerReadyState, RootState> = {
  getSelectedContents: (state: CustomerReadyState) => {
    return state.selectedContents
  },

  getInput: (state: CustomerReadyState) => {
    return state.input
  },

  getIsValidate: (state: CustomerReadyState) => {
    return state.isValidate
  },
}

export default getters
