
























































import {
  defineComponent,
  useStore,
  useMeta,
  onMounted,
  ref,
  watch,
  useRoute,
  useRouter,
  computed,
} from '@nuxtjs/composition-api'
import IconArrowLeft from '~/components/icons/IconArrowLeft.vue'
import IconValidationSuccess from '~/components/icons/IconValidationSuccess.vue'
import IconValidationError from '~/components/icons/IconValidationError.vue'
import { useContractsTabList } from '~/composable/headerTab/useContractsTabList'
import useContractEdit from '~/composable/contracts/service/useContractEdit'
import { ESTIMATE } from '~/constants/estimate/config'

export default defineComponent({
  components: {
    IconArrowLeft,
    IconValidationSuccess,
    IconValidationError,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '試着写真',
    },
    onlyHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const { getContractsTabList } = useContractsTabList()
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const selectedTab = ref(props.title)
    const customerName = ref('')
    const tabList = ref(getContractsTabList())
    const scrollYAmount = ref(0)
    const { isEditMode, goToTab, getContractIdFromQuery } = useContractEdit()

    const clickEvent = (tab: any) => {
      selectedTab.value = tab.text

      if (isEditMode) {
        goToTab(tab.url, getContractIdFromQuery())
        return
      }

      router.push(tab.url)
    }

    const historyBack = () => {
      history.back()
    }

    const getCustomerName = () => {
      customerName.value = store.getters['Customers/get']
    }

    const handleScroll = () => {
      scrollYAmount.value = window.scrollY
    }

    onMounted(() => {
      filterTabList()
      getCustomerName()
      if (props.onlyHeader) {
        return
      }
      window.addEventListener('scroll', handleScroll)
    })

    // タブ表示の切り替え
    const filterTabList = () => {
      tabList.value.filter((list: any) => {
        if (list.url === route.value.path) selectedTab.value = list.text
      })
    }

    //今いる契約ページが卒業式なのか成人式か判定
    const eventName = computed(() => {
      const estimateType =
        store.getters['Contracts/BaseEstimate/getEstimateType']
      if (estimateType === ESTIMATE.卒業式.id) {
        return '(卒業式)'
      } else if (estimateType === ESTIMATE.成人式.id) {
        return '(成人式)'
      } else if (estimateType === undefined) {
        return ''
      }
    })

    // titleを動的に変更
    useMeta(() => ({
      title: customerName.value
        ? `furisode mode | ${customerName.value} 様`
        : 'furisode mode',
    }))

    // urlを監視して画面遷移後の文字色を変更する
    watch(
      () => route.value.path,
      () => {
        filterTabList()
        getCustomerName()
      }
    )

    // バリデーションの検知
    watch(getContractsTabList, () => {
      tabList.value = getContractsTabList()
    })

    return {
      ESTIMATE,
      eventName,
      tabList,
      clickEvent,
      selectedTab,
      customerName,
      historyBack,
      scrollYAmount,
    }
  },
  head: {},
})
