import { computed } from '@nuxtjs/composition-api'
import { useScreenTypeFlags } from '~/composable/estimate/domain/ComingOfAge/ScreenTypeFlags'
import {
  getBadgeText,
  getItemPlan,
  getItemPlanFlags,
  getItemPrices,
  getPriceTypeText,
} from '~/composable/estimate/repository/comingOfAge/states/utilities'
import ProductClassification from '~/constants/enums/productClassification'
import { PLAN } from '~/constants/estimate/comingOfAge'
import { AccessoryPlanState } from '~/store/types'
import { useComingStore } from '../../ComingOfAgeStore'
import { BaseItemState, PlanFlags } from './types'

type Betsueri = BaseItemState & {
  品種区分: 6
  別衿加工必要: boolean
}

export const useState別衿 = () => {
  const values別衿 = useComingStore<AccessoryPlanState>('Betsueri').getAll()
  const { showTypeフルセット, showType写真のみレンタル, showType持ち込み } =
    useScreenTypeFlags()

  return computed(() => {
    const flags = {
      isフルセット: showTypeフルセット.value,
      is写のみレンタル: showType写真のみレンタル.value,
      is持ち込み: showType持ち込み.value,
    }
    return getState別衿(values別衿.value, flags)
  })
}

export const getState別衿 = (
  values別衿: AccessoryPlanState,
  planFlags: PlanFlags
) => {
  const { selections, input } = values別衿
  const itemFlags = getItemPlanFlags(selections)
  const itemPlan = getItemPlan(selections)

  /**
   * 着物レンタルまたは購入の場合 & 別衿持ち込みの場合、別衿加工代が必ず発生
   * 着物持込の場合は、長襦袢に別衿が縫い付けられていない場合に別衿加工代が発生
   */
  const is別衿加工代必要 =
    planFlags.isフルセット || planFlags.is写のみレンタル
      ? selections.includes(PLAN.BRING_IN)
      : selections.includes(PLAN.BETSUERI_NO)

  const betsueri: Betsueri = {
    品目名: ProductClassification.toWord(ProductClassification.betueri),
    品種区分: ProductClassification.betueri,
    商品名: input.productName,
    別衿加工必要: is別衿加工代必要,
    小物プラン: itemPlan,
    レンタル価格: 0,
    購入価格: 0,
    プレミアムレンタル価格: input.premiumRentalPrice,
    プレミアム購入価格: input.premiumBuyPrice,
    見積表示: null,
    表示価格: null,
    label: getBadgeText(itemPlan),
    cellLabel: getPriceTypeText(selections),
    flags: {
      isランクアップ:
        itemFlags.isランクアップPP内 || itemFlags.isランクアップPP外,
    },
  }

  const updatedPrices = getItemPrices(values別衿, betsueri, planFlags)
  betsueri.レンタル価格 = updatedPrices.レンタル価格
  betsueri.購入価格 = updatedPrices.購入価格
  betsueri.プレミアムレンタル価格 = updatedPrices.プレミアムレンタル価格
  betsueri.プレミアム購入価格 = updatedPrices.プレミアム購入価格
  betsueri.見積表示 = updatedPrices.見積表示
  betsueri.表示価格 = updatedPrices.表示価格

  return betsueri
}
