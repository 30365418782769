import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { OnlySelectionPlanState } from '~/store/types'

export const useRestore従業員割 = () => {
  const store =
    useComingStore<Omit<OnlySelectionPlanState, ''>>('EmployeeDiscount')

  const restore従業員割 = (status: Restate['discounts']['従業員割']) => {
    store.setOf<OnlySelectionPlanState['selections']>('setSelection', [status])
    store.setIsValidate(false)
  }

  return {
    restore従業員割,
  }
}
