export class PriceTableType {
  static readonly 裄補正 = 1
  static readonly 袖補正 = 2
  static readonly 別衿加工代 = 3
  static readonly 母娘プラン訪問着レンタル = 4
  static readonly 足し布N = 5
  static readonly 足し布B = 6
  static readonly 双子割 = 7
  static readonly 妹様割 = 8
  static readonly 従業員割 = 9
  static readonly プレミアムパックレンタル = 10
  static readonly プレミアムパック購入 = 11
  static readonly プレミアムパックWフォト = 12
  static readonly 当日ヘアメイク着付け代Aコース = 13
  static readonly 当日ヘアメイク着付け代Bコース = 14
  static readonly 前撮りヘアメイク着付け代 = 15
  static readonly Wフォトヘアメイク着付け代 = 16
  static readonly JK前撮りヘアメイク着付け代 = 17
  static readonly JK前撮り振袖代 = 18
  static readonly 写真アルバム代スタンダード5P = 19
  static readonly 写真アルバム代プレミアム15P = 20
  static readonly 写真アルバム代フルセット = 21
  static readonly 正絹長襦袢 = 22
  static readonly 高級手縫い = 23
  static readonly 振袖割引 = 24
  static readonly Wフォト振袖割引フルセット = 25
  static readonly Wフォト振袖割引フルセット以外 = 26
  static readonly 髪飾りフルセット割引 = 27
  static readonly 着付けなし割引 = 28
  static readonly お送り代 = 29
  static readonly 延滞料 = 30
  static readonly 商品券 = 31
  static readonly Wフォト着付け代 = 32
  static readonly 帯仕立て代 = 33
  static readonly 当日ヘアメイク着付け代フルセット = 34
  //ここから新規追加
  static readonly お写真プランデザインアルバムスタンダード = 35
  static readonly お写真プランデザインアルバムプレミアム = 36
  static readonly お写真プランデザインアルバム追加ポーズ数 = 37
  static readonly お写真プランデザインアルバム追加冊数 = 38
  static readonly お写真プラン六つ切り台紙ありポーズ1 = 39
  static readonly お写真プラン六つ切り台紙ありポーズ2以降 = 40
  static readonly お写真プラン六つ切り台紙なし = 41
  static readonly お写真プランキャビネ台紙ありポーズ1 = 42
  static readonly お写真プランキャビネ台紙ありポーズ2以降 = 43
  static readonly お写真プランキャビネ台紙なし = 44
  static readonly お写真プランアクリルスタンドA5 = 45
  static readonly お写真プランアクリルスタンドA4 = 46
  static readonly お写真プランUSBデータ = 47
  static readonly 袴定額割引 = 48
  static readonly 参列卒業式当日着付代 = 49
  static readonly 参列プレミアムパック = 50
  static readonly 成人式ご利用割引振袖 = 51
  static readonly 成人式ご利用割引袴 = 52
  static readonly 初回利用割引 = 53
  static readonly 参列友の会割引振袖 = 54
  static readonly 参列友の会割引訪問着留袖モーニングレンタル = 55
  static readonly 卒業式プレミアムパック = 56
  static readonly 卒業式長期レンタル値引き = 57

  static toWord = (val: number) => {
    switch (val) {
      case PriceTableType.裄補正:
        return '裄補正'
      case PriceTableType.袖補正:
        return '袖補正'
      case PriceTableType.別衿加工代:
        return '別衿加工代'
      case PriceTableType.母娘プラン訪問着レンタル:
        return '母娘プラン訪問着レンタル'
      case PriceTableType.足し布N:
        return '足し布N'
      case PriceTableType.足し布B:
        return '足し布B'
      case PriceTableType.双子割:
        return '双子割'
      case PriceTableType.妹様割:
        return '妹様割'
      case PriceTableType.従業員割:
        return '従業員割'
      case PriceTableType.プレミアムパックレンタル:
        return 'プレミアムパックレンタル'
      case PriceTableType.プレミアムパック購入:
        return 'プレミアムパック購入'
      case PriceTableType.プレミアムパックWフォト:
        return 'プレミアムパックWフォト'
      case PriceTableType.当日ヘアメイク着付け代Aコース:
        return '当日ヘアメイク着付け代Aコース'
      case PriceTableType.当日ヘアメイク着付け代Bコース:
        return '当日ヘアメイク着付け代Bコース'
      case PriceTableType.前撮りヘアメイク着付け代:
        return '前撮りヘアメイク着付け代'
      case PriceTableType.Wフォトヘアメイク着付け代:
        return 'Wフォトヘアメイク着付け代'
      case PriceTableType.JK前撮りヘアメイク着付け代:
        return 'JK前撮りヘアメイク着付け代'
      case PriceTableType.JK前撮り振袖代:
        return 'JK前撮り振袖代'
      case PriceTableType.写真アルバム代スタンダード5P:
        return '写真アルバム代スタンダード5P'
      case PriceTableType.写真アルバム代プレミアム15P:
        return '写真アルバム代プレミアム15P'
      case PriceTableType.写真アルバム代フルセット:
        return '写真アルバム代フルセット'
      case PriceTableType.正絹長襦袢:
        return '正絹長襦袢'
      case PriceTableType.高級手縫い:
        return '高級手縫い'
      case PriceTableType.振袖割引:
        return '振袖割引'
      case PriceTableType.Wフォト振袖割引フルセット:
        return 'Wフォト振袖割引フルセット'
      case PriceTableType.Wフォト振袖割引フルセット以外:
        return 'Wフォト振袖割引フルセット以外'
      case PriceTableType.髪飾りフルセット割引:
        return '髪飾りフルセット割引'
      case PriceTableType.着付けなし割引:
        return '着付けなし割引'
      case PriceTableType.お送り代:
        return 'お送り代'
      case PriceTableType.延滞料:
        return '延滞料'
      case PriceTableType.商品券:
        return '商品券'
      case PriceTableType.Wフォト着付け代:
        return 'Wフォト着付け代'
      case PriceTableType.帯仕立て代:
        return '帯仕立て代'
      case PriceTableType.当日ヘアメイク着付け代フルセット:
        return '当日ヘアメイク着付け代フルセット'
      case PriceTableType.お写真プランデザインアルバムスタンダード:
        return 'お写真プランデザインアルバムスタンダード'
      case PriceTableType.お写真プランデザインアルバムプレミアム:
        return 'お写真プランデザインアルバムプレミアム'
      case PriceTableType.お写真プランデザインアルバム追加ポーズ数:
        return 'お写真プランデザインアルバム追加ポーズ数'
      case PriceTableType.お写真プランデザインアルバム追加冊数:
        return 'お写真プランデザインアルバム追加冊数'
      case PriceTableType.お写真プラン六つ切り台紙ありポーズ1:
        return 'お写真プラン六つ切り台紙ありポーズ1'
      case PriceTableType.お写真プラン六つ切り台紙ありポーズ2以降:
        return 'お写真プラン六つ切り台紙ありポーズ2以降'
      case PriceTableType.お写真プラン六つ切り台紙なし:
        return 'お写真プラン六つ切り台紙なし'
      case PriceTableType.お写真プランキャビネ台紙ありポーズ1:
        return 'お写真プランキャビネ台紙ありポーズ1'
      case PriceTableType.お写真プランキャビネ台紙ありポーズ2以降:
        return 'お写真プランキャビネ台紙ありポーズ2以降'
      case PriceTableType.お写真プランキャビネ台紙なし:
        return 'お写真プランキャビネ台紙なし'
      case PriceTableType.お写真プランアクリルスタンドA5:
        return 'お写真プランアクリルスタンドA5'
      case PriceTableType.お写真プランアクリルスタンドA4:
        return 'お写真プランアクリルスタンドA4'
      case PriceTableType.お写真プランUSBデータ:
        return 'お写真プランUSBデータ'
      case PriceTableType.袴定額割引:
        return '袴定額割引'
      case PriceTableType.参列卒業式当日着付代:
        return '参列卒業式当日着付代'
      case PriceTableType.参列プレミアムパック:
        return '参列プレミアムパック'
      case PriceTableType.成人式ご利用割引振袖:
        return '成人式ご利用割引振袖'
      case PriceTableType.成人式ご利用割引袴:
        return '成人式ご利用割引袴'
      case PriceTableType.初回利用割引:
        return '初回利用割引'
      case PriceTableType.参列友の会割引振袖:
        return '参列友の会割引振袖'
      case PriceTableType.参列友の会割引訪問着留袖モーニングレンタル:
        return '参列友の会割引訪問着留袖モーニングレンタル'
      case PriceTableType.卒業式プレミアムパック:
        return '卒業式プレミアムパック'
      case PriceTableType.卒業式長期レンタル値引き:
        return '卒業式長期レンタル値引き'
      default:
        return ''
    }
  }
}

// 成人式用
export const priceTypesForComingOfAge = [
  PriceTableType.裄補正,
  PriceTableType.袖補正,
  PriceTableType.別衿加工代,
  PriceTableType.母娘プラン訪問着レンタル,
  PriceTableType.足し布N,
  PriceTableType.足し布B,
  PriceTableType.双子割,
  PriceTableType.妹様割,
  PriceTableType.従業員割,
  PriceTableType.プレミアムパックレンタル,
  PriceTableType.プレミアムパック購入,
  PriceTableType.プレミアムパックWフォト,
  PriceTableType.当日ヘアメイク着付け代Aコース,
  PriceTableType.当日ヘアメイク着付け代Bコース,
  PriceTableType.前撮りヘアメイク着付け代,
  PriceTableType.Wフォトヘアメイク着付け代,
  PriceTableType.JK前撮りヘアメイク着付け代,
  PriceTableType.JK前撮り振袖代,
  PriceTableType.写真アルバム代スタンダード5P,
  PriceTableType.写真アルバム代プレミアム15P,
  PriceTableType.写真アルバム代フルセット,
  PriceTableType.正絹長襦袢,
  PriceTableType.高級手縫い,
  PriceTableType.振袖割引,
  PriceTableType.Wフォト振袖割引フルセット,
  PriceTableType.Wフォト振袖割引フルセット以外,
  PriceTableType.髪飾りフルセット割引,
  PriceTableType.着付けなし割引,
  PriceTableType.お送り代,
  PriceTableType.延滞料,
  PriceTableType.商品券,
  PriceTableType.Wフォト着付け代,
  PriceTableType.帯仕立て代,
  PriceTableType.当日ヘアメイク着付け代フルセット,
].map((price) => PriceTableType.toWord(price)) as string[]

// 卒業式用
export const priceTypesForGraduation = [
  PriceTableType.お写真プランデザインアルバムスタンダード,
  PriceTableType.お写真プランデザインアルバムプレミアム,
  PriceTableType.お写真プラン六つ切り台紙ありポーズ1,
  PriceTableType.お写真プラン六つ切り台紙ありポーズ2以降,
  PriceTableType.お写真プラン六つ切り台紙なし,
  PriceTableType.お写真プランキャビネ台紙ありポーズ1,
  PriceTableType.お写真プランキャビネ台紙ありポーズ2以降,
  PriceTableType.お写真プランキャビネ台紙なし,
  PriceTableType.お写真プランアクリルスタンドA5,
  PriceTableType.お写真プランアクリルスタンドA4,
  PriceTableType.お写真プランUSBデータ,
  PriceTableType.袴定額割引,
  PriceTableType.商品券,
  PriceTableType.参列卒業式当日着付代,
  PriceTableType.裄補正,
  PriceTableType.袖補正,
  PriceTableType.別衿加工代,
  PriceTableType.お送り代,
  PriceTableType.成人式ご利用割引振袖,
  PriceTableType.成人式ご利用割引袴,
  PriceTableType.卒業式プレミアムパック,
  PriceTableType.卒業式長期レンタル値引き,
].map((price) => PriceTableType.toWord(price)) as string[]

// 参列用
export const priceTypesForAttendance = [
  PriceTableType.裄補正,
  PriceTableType.袖補正,
  PriceTableType.別衿加工代,
  PriceTableType.商品券,
  PriceTableType.足し布B,
  PriceTableType.参列卒業式当日着付代,
  PriceTableType.参列プレミアムパック,
  PriceTableType.お送り代,
  PriceTableType.成人式ご利用割引振袖,
  PriceTableType.初回利用割引,
  PriceTableType.参列友の会割引振袖,
  PriceTableType.参列友の会割引訪問着留袖モーニングレンタル,
].map((price) => PriceTableType.toWord(price)) as string[]
