import { MutationTree } from 'vuex'
import { PhotoSettings } from '../../types'

const mutations: MutationTree<PhotoSettings> = {
  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },

  setPhotoUrl: (state, { settingId = 1, url }) => {
    if (settingId === 1) {
      state.photoSetting.src = url
    } else {
      state.wPhotoSetting.src = url
    }
  },

  removePhotoUrl: (state, { settingId = 1 }) => {
    if (settingId === 1) {
      state.photoSetting.src = ''
    } else {
      state.wPhotoSetting.src = ''
    }
  },

  delete: (state) => {
    state.photoSetting = {
      id: 1,
      title: '小物写真',
      src: '',
    }
    state.wPhotoSetting = {
      id: 2,
      title: 'Wフォトプラン小物写真',
      src: '',
    }
    state.isValidate = null
  },
}

export default mutations
