import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { OnlySelectionPlanState } from '~/store/types'
import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { PLAN } from '~/constants/estimate/comingOfAge'

type MotherAndDaughterPlanStateType = Omit<OnlySelectionPlanState, ''>

export const useRestore母娘プラン = () => {
  const store = useComingStore<MotherAndDaughterPlanStateType>(
    'MotherAndDaughterPlan'
  )

  /**
   * @param optionId 0: 適用しない 1: 適用する
   */
  const restore母娘プラン = (optionId: Restate['mother_and_daughter_plan']) => {
    const section = optionId === 1 ? PLAN.APPLY : PLAN.NO_APPLY
    store.setOf<MotherAndDaughterPlanStateType['selections']>('setSelection', [
      section,
    ])
    store.setIsValidate(false)
  }

  return {
    restore母娘プラン,
  }
}
