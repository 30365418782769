import { GetterTree } from 'vuex'
import { FormValues重ね衿_着物持込 } from '~/composable/estimate/viewModel/graduation/Fields/Fields重ね衿_着物持込'
import { RootState } from '~/store/Estimate/GraduationCeremony/types'

const getters: GetterTree<{ fields: FormValues重ね衿_着物持込 }, RootState> = {
  getState: (state) => (stateName: keyof FormValues重ね衿_着物持込) => {
    return state.fields[stateName]
  },

  getAllState: (state) => {
    return state.fields
  },
}
export default getters
