import { render, staticRenderFns } from "./AppPinkButton.vue?vue&type=template&id=66d9a8bd&scoped=true&"
import script from "./AppPinkButton.vue?vue&type=script&lang=ts&"
export * from "./AppPinkButton.vue?vue&type=script&lang=ts&"
import style0 from "./AppPinkButton.vue?vue&type=style&index=0&id=66d9a8bd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66d9a8bd",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppIcon: require('/home/ar-admin/development/weddingbox-client/src/components/AppIcon.vue').default})
