import { computed, useRoute } from '@nuxtjs/composition-api'

/*
 * 今いるページがタブなのか、
 * 入力内容確認ページ(confirmation)なのか
 * 見積もり内容確認ページ(estimateList)なのか
 * 見積もり完了ページな(complete)なのか
 * を判定するフラグを提供する関数
 */
export const usePageFlags = () => {
  const route = useRoute()

  const inConfirmationPage = computed(() => {
    return route.value.path.includes('confirmation')
  })

  const inEstimateListPage = computed(() => {
    return route.value.path.includes('estimateList')
  })

  const inCompletePage = computed(() => {
    return route.value.path.includes('complete')
  })

  const inTabPage = computed(() => {
    return route.value.path.includes('tab')
  })

  return { inTabPage, inConfirmationPage, inEstimateListPage, inCompletePage }
}
