/**
 * 単品サービスのitemPlan
 */
export class SingleItemPlan {
  static readonly セット内 = 1
  static readonly ランクアップ = 2
  static readonly 仕立て上がり = 3
  static readonly 新品 = 4

  static toWord = (val: number) => {
    switch (val) {
      case SingleItemPlan.セット内:
        return 'セット内'
      case SingleItemPlan.ランクアップ:
        return 'ランクアップ'
      case SingleItemPlan.仕立て上がり:
        return '仕立て上がり'
      case SingleItemPlan.新品:
        return '新品'
      default:
        return 'ー'
    }
  }
}
