import { GetterTree } from 'vuex'
import { RootState } from '~/store/Estimate/GraduationCeremony/types'
import { 卒業式見積一覧 } from './state'

const getters: GetterTree<{ fields: 卒業式見積一覧 }, RootState> = {
  getState: (state) => (stateName: keyof 卒業式見積一覧) => {
    return state.fields[stateName]
  },

  getAllState: (state) => {
    return state.fields
  },
}
export default getters
