import { computed, Ref } from '@nuxtjs/composition-api'
import { Discounts } from '~/api/estimateRepository'
import { EstimateState } from '~/composable/estimate/repository/comingOfAge/EstimateState'

export const useDiscountsFormatter = (estimateState: Ref<EstimateState>) => {
  return computed(() => {
    return getDiscounts(estimateState.value.割引)
  })
}
export const getDiscounts = (values割引: EstimateState['割引']) => {
  const discounts: Discounts[] = []

  if (values割引.妹様割.is適用) {
    discounts.push({
      discount_type: values割引.妹様割.typeId,
      type: values割引.妹様割.割引.種別,
      rate: values割引.妹様割.割引.率, // 振袖本体価格の10%を割引
      amount: 0,
    })
  }

  if (values割引.双子割.is適用) {
    discounts.push({
      discount_type: values割引.双子割.typeId,
      type: values割引.双子割.割引.種別,
      rate: values割引.双子割.割引.率, // 振袖本体価格の10%を割引
      amount: 0,
    })
  }

  if (values割引.従業員割.is適用) {
    discounts.push({
      discount_type: values割引.従業員割.typeId,
      type: values割引.従業員割.割引.種別,
      rate: 0,
      amount: values割引.従業員割.割引.金額,
    })
  }

  if (values割引.割引.is適用) {
    discounts.push({
      discount_type: values割引.割引.typeId,
      type: values割引.割引.割引.種別,
      rate: 0,
      amount: values割引.割引.割引.金額,
    })
  }

  return discounts.length ? discounts : null
}
