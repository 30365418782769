import { FamilyInformationSetting } from '../../types'
import {
  FAMILY_INFORMATION,
  FAMILY_INFORMATION_INPUT,
} from '~/constants/estimate/comingOfAge'

const state = (): FamilyInformationSetting => ({
  patternLine: [0, 0],
  family: [
    {
      id: 1,
      title: '保護者様',
      isOpened: false,
      profile: [],
    },
    {
      id: 2,
      title: 'ご姉妹兄弟様',
      isOpened: false,
      profile: [],
    },
    {
      id: 3,
      title: 'その他ご家族様',
      isOpened: false,
      profile: [],
    },
  ],
  mypageMailAddress: '', // マイページ用メールアドレス
  address: {
    zip_code: '',
    prefectures: '',
    address1: '',
    address2: '',
    phone_number: '',
  },
  isOpenedAddress: false,
  isValidate: null,
  isClickByUsedPast: FAMILY_INFORMATION.UN_SET, // ご家族がいるかいないか NOTE: 既にデータが入っているかどうかで確認？
  familyAddressType: FAMILY_INFORMATION_INPUT.UN_SET, // 住所が同じが違うか
  screenType: 1, // 1: 初期画面, 2: 入力画面, 3: 確認画面
  customerProfile: null, // 弊社ご利用のお客様、お姉様の情報が入る
  twinsProfile: null, // 双子のお姉様(妹様)の情報が入る
})

export default state
