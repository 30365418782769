import { MutationTree } from 'vuex'
import { FormValues割引 } from '~/composable/estimate/viewModel/graduation/Fields/Fields割引'
import { getInitialStore } from './state'

const mutations: MutationTree<{ fields: FormValues割引 }> = {
  setState: (
    state,
    payload: { stateName: keyof FormValues割引; value: any }
  ) => {
    state.fields[payload.stateName] = payload.value
  },

  setAllState: (state, values: FormValues割引) => {
    state.fields = values
  },

  initState: (state, fieldId: keyof FormValues割引) => {
    const initialStore = getInitialStore()
    state.fields[fieldId] = initialStore[fieldId] as any
  },

  initAllState: (state) => {
    state.fields = getInitialStore()
  },
}

export default mutations
