import { usePrice } from '~/composable/general/Price'
import { EstimateSinglePriceType } from '~/constants/enums/estimateSinglePriceType'
import { ItemPlan } from '~/constants/enums/itemPlan'
import { inputs, price } from '~/store/types'
import { GRADUATION_ZORI, PLAN } from '~/constants/estimate/comingOfAge'
import { ShowPriceType } from '../../graduation/states/types'
import { PlanFlags } from './types'

export type SelectedPlanListType = {
  isレンタルプラン: boolean
  is購入プラン: boolean
  is未決定: boolean
}

/*
 * 編集付き金額表示コンポーネントから表示されている金額を数字で返す
 */
export const getPriceFromEditPrice = (editPrice: string | null) => {
  const { toNumber } = usePrice()
  if (!editPrice) return 0
  return toNumber(editPrice)
}

/*
 * フリー入力付き価格選択系のコンポーネントの取得した値を元に、
 * 実際入力あるいは選択されている値を数字で返す
 */
type StoreValues = {
  isFree: boolean
  input: inputs
  price: price
  selections: number[]
}
export const getPriceFromSelectPriceWithFreeInput = (
  { input, isFree, price, selections }: StoreValues,
  priceType: 'rental' | 'buy' | 'rankUpRental' | 'rankUpBuy'
) => {
  const inputPropName =
    priceType === 'rental'
      ? 'rentalPrice'
      : priceType === 'buy'
      ? 'buyPrice'
      : priceType === 'rankUpRental'
      ? 'rankUpRentalPrice'
      : 'rankUpBuyPrice'

  const selectedPropName =
    priceType === 'rental' || priceType === 'rankUpRental' ? 'rental' : 'buy'

  // NOTE: isFreeは金額選択で「フリー入力」を選んだ時のフラグ。よって基本的にランクアップPP内にしかない。
  //「セット内」にも「フリー入力」は存在しないため、本来はここでランクアップPP外と共に弾くべきだと思うが、現状なぜかセット内で金額を編集した場合にはinputとprice両方に金額を入れることで解決しているので、元の実装と同じロジックにしている
  if (getItemPlanFlags(selections).isランクアップPP外) {
    return input[inputPropName]
  }

  return isFree ? input[inputPropName] : price[selectedPropName]
}

/**
 * 選択肢idによって小物プランのidを判定する
 * 1:セット内 2:レンタルのみランクアップ（購入はセット内）
 * 3:ランクアップ（プレミアム内） 4:ランクアップ（プレミアム外）
 * 5:未定または1~4,6いずれでもない時（単品購入など） 6:お持ち込み
 */
export const getItemPlan = (patternLine: number[]) => {
  const flags = getItemPlanFlags(patternLine)
  if (flags.isセット内) return ItemPlan.inSet
  else if (flags.isレンタルのみランクアップ) return ItemPlan.onlyRentalRankUp
  else if (flags.isランクアップPP内) return ItemPlan.rankUpInPremium
  else if (flags.isランクアップPP外) return ItemPlan.rankUpOutPremium
  else if (flags.isお持込) return ItemPlan.bringIn
  else if (flags.is商品なし) return ItemPlan.noUse
  else return ItemPlan.toBeDecided
}

export const getItemPlanFlags = (patternLine: number[]) => {
  const isセット内 = patternLine.includes(PLAN.IN_THE_SET)
  const isレンタルのみランクアップ = patternLine.includes(
    PLAN.RANK_UP_ONLY_RENTAL
  )
  const isランクアップPP内 = patternLine.includes(PLAN.RANK_UP_IN_PREMIUM)
  const isランクアップPP外 = patternLine.includes(PLAN.RANK_UP_OUT_PREMIUM)
  const isお持込 = patternLine.includes(PLAN.BRING_IN)
  const is商品なし = patternLine.includes(PLAN.NO_USE)

  return {
    isセット内,
    isレンタルのみランクアップ,
    isランクアップPP内,
    isランクアップPP外,
    isお持込,
    is商品なし,
    is単品:
      !isセット内 &&
      !isレンタルのみランクアップ &&
      !isランクアップPP内 &&
      !isランクアップPP外 &&
      isお持込,
  }
}

/**
 * 単品購入・レンタルを選択している場合にはその情報を返す
 * 小物がプラン内の場合はundefinedを返す
 */
export type ShowPriceInfo = ReturnType<typeof getShowPriceInfo>
export const getShowPriceInfo = ({ selections, input }: StoreValues) => {
  const flags = getPriceTypeFlags(selections)
  const priceType = getPriceType(selections)
  const showType: ShowPriceType = {
    id: priceType ?? null,
    value: flags.is単品レンタル ? '単品レンタル' : '単品購入',
  }
  const showPrice = flags.is単品レンタル ? input.rentalPrice : input.buyPrice
  const showPriceInfo = {
    showType,
    showPrice,
    flags: {
      is単品レンタル: flags.is単品レンタル,
      is単品購入: flags.is単品購入,
    },
  }
  return priceType ? showPriceInfo : undefined
}

/**
 * 複数個目の帯揚げと重ね衿は見積一覧で決まる振袖購入・レンタルによってprice_typeが決まるため、
 * 他の判定とは切り分ける。
 * 詳細仕様：振袖購入の場合は単品購入に、振袖レンタルの場合は単品レンタルに自動的に定まる。
 */
export const getShowPriceInfoOfAddition = (
  { selections, input }: StoreValues,
  planFlags: SelectedPlanListType
) => {
  const is持込 = selections.includes(PLAN.BRING_IN)
  if (is持込 || planFlags.is未決定) return undefined
  const showPriceInfo = {
    showType: {
      id: planFlags.isレンタルプラン
        ? EstimateSinglePriceType.singleRentalPrice
        : EstimateSinglePriceType.singlePurchasePrice,
      value: planFlags.isレンタルプラン ? '単品レンタル' : '単品購入',
    } as ShowPriceType,
    showPrice: planFlags.isレンタルプラン ? input.rentalPrice : input.buyPrice,
    flags: {
      is単品レンタル: planFlags.isレンタルプラン,
      is単品購入: !planFlags.isレンタルプラン,
    },
  }
  return showPriceInfo
}

// 1:単品レンタル価格 2:単品購入価格
export const getPriceType = (patternLine: number[]) => {
  const flags = getPriceTypeFlags(patternLine)
  if (flags.is単品レンタル) {
    return EstimateSinglePriceType.singleRentalPrice
  } else if (flags.is単品購入) {
    return EstimateSinglePriceType.singlePurchasePrice
  } else {
    return undefined
  }
}

export const getPriceTypeText = (patternLine: number[]) => {
  const flags = getPriceTypeFlags(patternLine)
  if (flags.is単品レンタル) {
    return '単品レンタル'
  } else if (flags.is単品購入) {
    return '単品購入'
  } else {
    return null
  }
}

export const getPriceTypeFlags = (patternLine: number[]) => {
  const is単品購入 =
    patternLine.includes(PLAN.SINGLE_BUY_PRICE) ||
    patternLine.includes(GRADUATION_ZORI.BUY_THE_SAME_PRODUCT)

  return {
    is単品レンタル: patternLine.includes(PLAN.SINGLE_RENTAL_PRICE),
    is単品購入,
  }
}

/**
 * 小物の選択された種別や形態に従って、ストアのどの値をレンタルや購入価格として扱うか、
 * という部分の共通処理を切り出した関数
 * @param index 複数選べるタイプの小物において、何個目かのindex(0~)
 * @param selectedPlanListType 帯揚げ・重ね衿の複数個目は見積一覧の選択によって単品購入かレンタルか決まるのでその場合に渡す
 */
type ItemPrices = {
  レンタル価格: number
  購入価格: number
  プレミアムレンタル価格: number
  // NOTE: Wphotoには存在しないのでoptionalにしている
  プレミアム購入価格?: number
  見積表示: ShowPriceType | null
  表示価格: number | null
}
type ReturnPrices = Required<ItemPrices>
export const getItemPrices = (
  storeValues: StoreValues,
  originalState: ItemPrices,
  planFlags: PlanFlags,
  index: number = 0,
  selectedPlanListType?: SelectedPlanListType
): ReturnPrices => {
  const { input, selections } = storeValues
  const is複数個目 = index > 0
  const showPriceInfo =
    is複数個目 && selectedPlanListType
      ? getShowPriceInfoOfAddition(storeValues, selectedPlanListType)
      : getShowPriceInfo(storeValues)

  const itemFlags = getItemPlanFlags(selections)
  const itemState = { ...originalState }

  const is形態セット内以外 =
    itemFlags.isランクアップPP内 ||
    itemFlags.isランクアップPP外 ||
    itemFlags.isレンタルのみランクアップ
  const 振袖プラン内 = planFlags.isフルセット || planFlags.is写のみレンタル

  if (振袖プラン内 && !is複数個目) {
    itemState.プレミアムレンタル価格 = input.premiumRentalPrice
    itemState.プレミアム購入価格 = input.premiumBuyPrice

    if (itemFlags.isセット内) {
      itemState.レンタル価格 = input.rentalPrice
    } else if (is形態セット内以外) {
      itemState.レンタル価格 = getPriceFromSelectPriceWithFreeInput(
        storeValues,
        'rankUpRental'
      )
    }
    if (planFlags.isフルセット) {
      if (itemFlags.isセット内) {
        itemState.購入価格 = input.buyPrice
      } else if (is形態セット内以外) {
        itemState.購入価格 = getPriceFromSelectPriceWithFreeInput(
          storeValues,
          'rankUpBuy'
        )
      }
    }
  } else if (planFlags.is持ち込み || is複数個目) {
    itemState.レンタル価格 = input.rentalPrice
    itemState.購入価格 = input.buyPrice

    if (showPriceInfo) {
      itemState.見積表示 = showPriceInfo.showType
      itemState.表示価格 = showPriceInfo.showPrice
    }
  }

  return {
    レンタル価格: itemState.レンタル価格,
    購入価格: itemState.購入価格,
    プレミアムレンタル価格: itemState.プレミアムレンタル価格,
    プレミアム購入価格: itemState.プレミアム購入価格 || 0,
    見積表示: itemState.見積表示,
    表示価格: itemState.表示価格,
  }
}

export const getWPhotoItemPrices = (
  storeValues: StoreValues,
  originalState: ItemPrices,
  is振袖レンタル: boolean,
  index?: number
) => {
  const { input, selections } = storeValues
  const showPriceInfo = getShowPriceInfo(storeValues)
  const itemFlags = getItemPlanFlags(selections)
  const itemState = { ...originalState }

  const is形態セット内以外 =
    itemFlags.isランクアップPP内 || itemFlags.isランクアップPP外
  const isMoreThanOne = index && index > 0

  if (is振袖レンタル && !isMoreThanOne) {
    itemState.プレミアムレンタル価格 = input.premiumRentalPrice

    if (itemFlags.isセット内) {
      itemState.レンタル価格 = input.rentalPrice
    } else if (is形態セット内以外) {
      itemState.レンタル価格 = getPriceFromSelectPriceWithFreeInput(
        storeValues,
        'rankUpRental'
      )
    }
  } else if (is振袖レンタル && isMoreThanOne) {
    // 振袖レンタル時はレンタル価格のみ表示
    itemState.レンタル価格 = input.rentalPrice
    itemState.見積表示 = {
      id: EstimateSinglePriceType.singleRentalPrice,
      value: '単品レンタル',
    }
    itemState.表示価格 = input.rentalPrice
  } else {
    itemState.レンタル価格 = input.rentalPrice
    itemState.購入価格 = input.buyPrice

    if (showPriceInfo) {
      itemState.見積表示 = showPriceInfo.showType
      itemState.表示価格 = showPriceInfo.showPrice
    }
  }

  return itemState
}

/**
 * 小物プランのidから見積一覧や入力内容確認で表示する緑バッジの文字列を返す
 */
export const getBadgeText = (value?: number) => {
  switch (value) {
    case ItemPlan.inSet:
      return 'セット内'
    case ItemPlan.onlyRentalRankUp:
      return 'レンタルのみランクアップ'
    case ItemPlan.rankUpInPremium:
      return 'ランクアップ \n プレミアム内'
    case ItemPlan.rankUpOutPremium:
      return 'ランクアップ \n プレミアム外'
    case ItemPlan.bringIn:
      return 'お持込'
    default:
      return null // 5: 未定と単品購入・単品レンタルは表示しない
  }
}

/**
 * 小物プランのidから見積一覧や入力内容確認で表示する緑バッジの文字列を返す
 */
export const getBadgeTextForGraduationSginature = (value?: number) => {
  switch (value) {
    case ItemPlan.inSet:
      return 'セット内'
    case ItemPlan.onlyRentalRankUp:
      return 'レンタルのみランクアップ'
    case ItemPlan.rankUpInPremium:
    case ItemPlan.rankUpOutPremium:
      return 'ランクアップ｜プレミアムパック内'
    case ItemPlan.bringIn:
      return 'お持込'
    default:
      return null // 5: 未定と単品購入・単品レンタルは表示しない
  }
}

// 振袖金額の割引額を算出 ※適用していない場合は0円を返す
export const kimonoDiscountAmount = (
  price: number,
  rate: number,
  is適用: boolean = true
) => {
  if (!is適用) return 0
  const discountPrice = price * (rate / 100)
  return Math.ceil(discountPrice)
}
