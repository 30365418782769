import { GetterTree } from 'vuex'
import { RootState, MemoState } from '../../../types'

const getters: GetterTree<MemoState, RootState> = {
  // NOTE: useComingStore用に追加
  getAllState: (state) => {
    return state
  },

  getText: (state: MemoState) => {
    return state.text
  },
  getChance: (state: MemoState) => {
    return state.isChance
  },
  getMemoId: (state: MemoState) => {
    return state.memoId
  },
  getCustomerId: (state: MemoState) => {
    return state.customerId
  },
}

export default getters
