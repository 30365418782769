import { FormValues重ね衿_着物持込 } from '~/composable/estimate/viewModel/graduation/Fields/Fields重ね衿_着物持込'

// 不明な点がある場合、READMEをご覧ください
export const getInitialStore = (): FormValues重ね衿_着物持込 => {
  return {
    kasaneeri種別: null,
    kasaneeri単品レンタル: null,
    kasaneeri単品レンタルフリー入力: null,
    kasaneeri単品購入: null,
    kasaneeri単品購入フリー入力: null,
    kasaneeri見積表示: null,
  }
}

const state = (): { fields: FormValues重ね衿_着物持込 } => ({
  fields: getInitialStore(),
})

export default state
