import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { OnlyInputPlanState } from '~/store/types'

export const useRestore髪飾り = () => {
  const store = useComingStore<OnlyInputPlanState>('Kamikazari')
  const store2 = useComingStore<OnlyInputPlanState>('Kamikazari2')
  const store3 = useComingStore<OnlyInputPlanState>('Kamikazari3')

  const setStore = (target: typeof store, formValues: OnlyInputPlanState) => {
    target.setOf<OnlyInputPlanState['selections']>(
      'setSelection',
      formValues.selections
    )
    target.setOf<OnlyInputPlanState['input']>('setInput', formValues.input)
    target.setIsValidate(false)
  }

  const restore髪飾り = (kamikazari: Restate['items']['髪飾り']) => {
    if (!kamikazari) {
      return
    }
    kamikazari.forEach((item, i) => {
      const formValues: OnlyInputPlanState = {
        /**
         * NOTE: selections = patternLine
         * ↓保持される選択肢のindex
         * [0]種別
         */
        selections: [item.optionId種別],
        input: {
          productName: '',
          buyPrice: item.単品購入価格,
          rentalPrice: 0,
          rankUpRentalPrice: 0,
          rankUpBuyPrice: 0,
          premiumRentalPrice: 0,
          premiumBuyPrice: 0,
        },
        isValidate: null,
      }

      if (i === 0) setStore(store, formValues)
      if (i === 1) setStore(store2, formValues)
      if (i === 2) setStore(store3, formValues)
    })
  }
  return { restore髪飾り }
}
