import { ActionTree } from 'vuex'
import { RootState, OnlySelectionPlanState } from '../../../types'

const actions: ActionTree<OnlySelectionPlanState, RootState> = {
  setSelection: ({ commit }, selection) => {
    commit('setSelection', selection)
  },

  setIsValidate: ({ commit }, isValidate: boolean) => {
    commit('setIsValidate', isValidate)
  },

  delete: ({ commit }) => {
    commit('delete')
  },
}

export default actions
