import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { AccessoryPlanState } from '~/store/types'

export const useRestoreショール = () => {
  const store = useComingStore<AccessoryPlanState>('Shawl')

  const restoreショール = (
    shawl: Restate['items']['ショール'],
    plan: Restate['plan']
  ) => {
    if (!plan || !shawl) return

    /* 初期値を設定 ※必ず入力される値はここで設置 */
    const formValues: AccessoryPlanState = {
      /**
       * NOTE: selections = patternLine
       * ↓保持される選択肢のindex
       * [0]種別
       * [1]商品
       * [2]形態
       */
      selections: [shawl.optionId種別, 0, 0],
      input: {
        productName: '',
        buyPrice: 0,
        rentalPrice: 0,
        rankUpRentalPrice: 0,
        rankUpBuyPrice: 0,
        premiumRentalPrice: 0,
        premiumBuyPrice: 0,
      },
      price: {
        buy: 0,
        rental: 0,
      },
      isFree: false,
      isValidate: null,
    }

    /* 以下で入力内容の条件に基づき、必要な項目を埋めていく */
    if (!shawl.flags.is持込) {
      formValues.selections[1] = shawl.optionId商品選択
      formValues.selections[2] = shawl.optionId形態
      formValues.input.productName = shawl.商品名
    }
    if (plan.flags.is振袖持込 && !shawl.flags.is持込) {
      formValues.selections[2] = shawl.optionId見積選択
      if (shawl.flags.is現品) {
        formValues.input.rentalPrice = shawl.レンタル価格
        formValues.input.buyPrice = shawl.購入価格
      } else {
        formValues.price.rental = shawl.レンタル価格
        formValues.price.buy = shawl.購入価格
      }
    }

    if (shawl.flags.isセット内 || shawl.flags.isランクアップ) {
      formValues.price.rental = shawl.レンタル価格
      formValues.price.buy = shawl.購入価格
    }

    if (plan.flags.isフルセットプラン && shawl.flags.is現品) {
      formValues.selections[2] = shawl.optionId形態
      formValues.input.rankUpRentalPrice = shawl.レンタル価格
      formValues.input.rankUpBuyPrice = shawl.購入価格
    }

    if (shawl.flags.is現品PP外) {
      formValues.input.premiumRentalPrice = shawl.プレミアムレンタル価格
      formValues.input.premiumBuyPrice = shawl.プレミアム購入価格
    }

    /* ストアに内容を保存する */
    store.setOf<AccessoryPlanState['selections']>(
      'setSelection',
      formValues.selections
    )
    store.setOf<AccessoryPlanState['input']>('setInput', formValues.input)
    store.setOf<AccessoryPlanState['price']>('setPrice', formValues.price)
    store.setOf<AccessoryPlanState['isFree']>('setIsFree', formValues.isFree)
    store.setIsValidate(false)
  }

  return { restoreショール }
}
