import { computed } from '@nuxtjs/composition-api'
import { FURISODE_PLAN } from '~/constants/estimate/comingOfAge'
import { useComingStore } from '../../ComingOfAgeStore'
import { FurisodeState } from '~/store/types'
import { useHurisodeTypeFlags } from '~/composable/estimate/domain/ComingOfAge/HurisodeTypeFlags'
import { usePlanTypeFlags } from '~/composable/estimate/domain/ComingOfAge/PlanTypeFlags'
import { ProductCategory } from '~/constants/enums/productCategory'
import { useSelectedPlanListType } from '~/composable/estimate/domain/ComingOfAge/SelectedPlanListType'
import { useScreenTypeFlags } from '~/composable/estimate/domain/ComingOfAge/ScreenTypeFlags'
import ProductClassification from '~/constants/enums/productClassification'
import { useElderSisterFlag } from '~/composable/estimate/domain/ComingOfAge/ElderSisterFlag'
import { State as PricesState } from '~/store/PriceTable/state'

export type State振袖 = ReturnType<typeof useState振袖>['value']
export const useState振袖 = () => {
  // NOTE: storeにアクセスする関数は単体テストのために　getStateの外で呼び出す
  const comingStore = useComingStore<FurisodeState>('Furisodes')
  const values振袖 = comingStore.getAll()
  const priceTable = comingStore.getPrices()
  const { showType持ち込み } = useScreenTypeFlags()
  const category = getHurisodeCategory()
  const { is購入プラン } = useSelectedPlanListType()

  return computed(() => {
    return getState振袖(
      values振袖.value,
      category.value,
      showType持ち込み.value,
      priceTable,
      is購入プラン.value
    )
  })
}

export const getState振袖 = (
  values振袖: FurisodeState,
  category: number,
  showType持ち込み: boolean,
  priceTable: PricesState['prices'],
  is購入プラン: boolean
) => {
  const isお持込 = showType持ち込み
  const is裄補正 = values振袖.selections.includes(FURISODE_PLAN.YUKI_CORRECTION)
  const is袖補正 = values振袖.selections.includes(FURISODE_PLAN.SODE_CORRECTION)
  const is振袖保留 = values振袖.isFurisodeOnHold
  const is対象年度外オーダーレンタル = values振袖.isOrderOutsideTargetYear

  return {
    品目名: ProductClassification.toWord(ProductClassification.hurisode),
    商品分類: category,
    レンタル価格: isお持込 ? null : values振袖.price.rental,
    購入価格: isお持込 ? null : values振袖.price.buy,
    品種区分: ProductClassification.hurisode,
    price裄補正: priceTable.estimate.裄補正,
    price袖補正: priceTable.estimate.袖補正,
    着物検索: isお持込 ? null : values振袖.product,
    商品名: values振袖.product?.product_name || values振袖.input.productName,
    プロパー商品ID: values振袖.properProductId,
    label: isお持込 ? 'お持込' : '',
    flags: {
      isお持込,
      is振袖保留,
      is裄補正,
      is袖補正,
      isカタログ購入:
        is購入プラン && category === ProductCategory.catalogPurchase,
      isお誂えレンタル: category === ProductCategory.customizeRental,
      is対象年度外オーダーレンタル,
    },
  }
}

const getHurisodeCategory = () => {
  const {
    isカタログ,
    isプロパー,
    is仕立上り,
    isオーダーレンタル,
    isお誂えレンタル,
    isカタログ仕立て,
    is仕立上り保留,
  } = useHurisodeTypeFlags()
  const {
    is当日のみBコース,
    is持込みAコース,
    is持込みBコース,
    is写真のみ持込,
  } = usePlanTypeFlags()
  const { isレンタルプラン, is購入プラン, is未決定 } = useSelectedPlanListType()
  const { isお姉様購入, isNOTお姉様購入 } = useElderSisterFlag()
  return computed(() => {
    const is写のみ姉販持込 = isお姉様購入.value && is写真のみ持込.value
    const is写のみNOT姉販持込 = isNOTお姉様購入.value && is写真のみ持込.value

    // NOTE: プラン未確定でマイページに反映した場合は復元ロジックでcategoryを参照するため仮の値でレンタルの場合を送る
    const isレンタルOR未確定 = isレンタルプラン.value || is未決定.value

    if (is持込みAコース.value || is写のみNOT姉販持込) {
      return ProductCategory.bringIn
    }
    if (is持込みBコース.value || is当日のみBコース.value || is写のみ姉販持込) {
      return ProductCategory.purchasedElderSister
    }

    // NOTE: 振袖タブの検索で仕立て上り着物を選んだ時と、
    // カタログ着物を選び、その後の選択肢で仕立て上りレンタルを選択した時の2パターン両方、仕立て上りレンタルとして送る
    const is仕立て上りパターン =
      is仕立上り.value || isカタログ仕立て.value || is仕立上り保留.value
    if (is仕立て上りパターン && isレンタルOR未確定) {
      return ProductCategory.tailoredRental
    }
    if ((is仕立上り.value || is仕立上り保留.value) && is購入プラン.value) {
      return ProductCategory.tailoredPurchase
    }
    if (isオーダーレンタル.value && isレンタルOR未確定) {
      return ProductCategory.orderRental
    }
    if (isお誂えレンタル.value && isレンタルOR未確定) {
      return ProductCategory.customizeRental
    }
    if (isカタログ.value && is購入プラン.value) {
      return ProductCategory.catalogPurchase
    }
    if (isプロパー.value && isレンタルOR未確定) {
      return ProductCategory.properRental
    }
    if (isプロパー.value && is購入プラン.value) {
      return ProductCategory.properPurchase
    }
    // 未確定とみなして0を設置する
    return 0
  })
}
