



















import { defineComponent } from '@nuxtjs/composition-api'
import IconArrowRight from '~/components/icons/IconArrowRight.vue'

export default defineComponent({
  components: {
    IconArrowRight,
  },
  props: {
    buttonText: {
      type: String,
      required: false,
      default: '次へ',
    },
    isChecked: {
      type: Boolean,
      required: false,
      default: true,
    },
    isArrowShown: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['clickNextButton'],
  setup(props, { emit }) {
    const clickEvent = () => {
      if (!props.isChecked) {
        return
      }
      emit('clickNextButton')
    }
    return { clickEvent }
  },
})
