import { ref, useContext } from '@nuxtjs/composition-api'
import { isAxiosError } from '~/utils/exception'
import axios from 'axios'
/** api */
import estimateRepository, {
  CreateEstimateParams,
  EstimateDetail,
  AttendanceEstimateDetail,
  CreateAttendanceEstimateParams,
} from '~/api/estimateRepository'
import fileRepository, { EstimatePdf } from '~/api/fileRepository'
import { DOCUMENT, ESTIMATE } from '~/constants/estimate/config'
import { useEstimateTypeFlags } from '~/composable/estimate/domain/EstimateTypeFlags'
import { estimateDetailEditFormatter } from '~/composable/estimate/service/graduation/EstimateRequest/formatters/estimateDetailEdit'

/** classes */
import { notifyError } from '~/composable/general/SlackErrorLog'
import { useTemporarilySaved } from './useTemporarilySaved'

import type { AttendanceEstimateDetailWithId } from '~/features/contracts/attendance/useEstimateData'
import { EstimateStatus } from '~/constants/enums/estimateStatus'

export type Estimate = {
  branch_number: number
  created_at: string
  customer_id: number
  estimate_id: number
  event_name: string
  first_name: string
  first_name_kana: string
  last_name: string
  last_name_kana: string
  photo_url: string
  status: number
  use_date: string
  plan: number | null
}

type SearchByEstimateId = {
  estimate_id?: number
  estimateType?: number
  date?: string
}
type SearchByCustomerId = {
  customer_id: number
  estimateType?: number
}
export type SearchCondition = SearchByEstimateId | SearchByCustomerId

export const useEstimate = () => {
  const { in成人式見積 } = useEstimateTypeFlags()
  const { $axios, $sentry, $config } = useContext()
  const { saveEstimateTemporarilyWithAlert } = useTemporarilySaved()
  const estimateRepo = estimateRepository($axios)
  const estimateList = ref<Estimate[]>([])
  const estimateInfo = ref<EstimateDetail>()
  const attendanceEstimateInfo = ref<AttendanceEstimateDetail>()
  const attendanceEstimateInfoList = ref<AttendanceEstimateDetailWithId[]>([])
  const latestEstimate = ref<EstimateDetail>()
  const error = ref('')
  const loading = ref(false)
  const fileRepo = fileRepository(
    axios.create({
      headers: $axios.defaults.headers,
      baseURL: $config.BASE_URL,
    })
  )

  const descByEstimateId = (a: Estimate, b: Estimate) => {
    if (a.estimate_id > b.estimate_id) return -1
    if (a.estimate_id < b.estimate_id) return 1
    return 0
  }

  /**
   * 見積検索API（customer_idで見積履歴）
   * @param {number} estimate_id
   * @param {number} customer_id
   * @param {number} [estimateType] 指定しない場合は成・卒両方検索
   * @param {string} date
   * @param {object} is_not_contracted:  契約済みの見積書を排除するフラグ
   */
  const fetchEstimateList = async (
    params: SearchCondition,
    option?: { is_not_contracted: boolean }
  ) => {
    try {
      loading.value = true
      estimateList.value = []
      const reqParams = {
        customer_id: 'customer_id' in params ? params.customer_id : undefined,
        estimate_id: 'estimate_id' in params ? params.estimate_id : undefined,
        type: params.estimateType || undefined,
        date: 'date' in params ? params.date : undefined,
      }
      const res = await $axios.get('estimates', {
        params: reqParams,
      })

      estimateList.value.push(...res.data.estimates)
      estimateList.value = estimateList.value.sort(descByEstimateId)

      if (option?.is_not_contracted) {
        estimateList.value = estimateList.value.filter(
          (estimate) => estimate.status !== EstimateStatus.done
        )
      }
    } catch (error) {
      console.error('API error', error)
    } finally {
      loading.value = false
    }
  }

  /**
   * 参列の見積検索API（estimate_idで参列の見積情報取得）
   */
  const fetchAttendanceEstimate = async (estimateId: number) => {
    try {
      loading.value = true
      const res = await $axios.get<{ estimates: Estimate[] }>('estimates', {
        params: {
          estimate_id: estimateId,
          type: ESTIMATE.参列.id,
        },
      })

      // NOTE: estimate_idとtype指定すると必ず1つになる
      estimateList.value = res.data.estimates
      return res.data.estimates[0]
    } catch (e) {
      estimateList.value = []
      console.error('API error', e)
    } finally {
      loading.value = false
    }
  }

  /**
   * 見積履歴を取得する関数
   * @param {number} customer_id
   * @param {number} [type]  指定しない場合は成・卒両方検索
   * @param {boolean}  is_not_contracted 契約済みは表示しない
   */
  const fetchEstimateManageData = async (params: {
    customer_id: number
    type?: number
    is_not_contracted?: boolean
  }) => {
    loading.value = true
    try {
      await fetchEstimateList(
        {
          customer_id: params.customer_id,
          estimateType: params.type,
        },
        { is_not_contracted: !!params?.is_not_contracted }
      )
    } catch (err) {
      console.error('API error', err)
      error.value = 'データの取得に失敗しました。'
    } finally {
      loading.value = false
    }
  }

  /**
   * 見積書詳細API
   * @param {path} estimate_id
   * @param {path} branch_number
   */
  const fetchEstimateInfo = async (
    estimate_id: number,
    branch_number: number
  ) => {
    try {
      const res = await $axios.get<EstimateDetail>(
        `/estimates/${estimate_id}/branch/${branch_number}`
      )
      estimateInfo.value = res.data
    } catch (err) {
      console.log('API error', err)
      error.value = '見積の詳細が取得できませんでした'
    }
  }

  /**
   * 参列見積書詳細API
   * @param {path} estimate_id
   */
  const fetchAttendanceEstimateInfo = async (estimate_id: number) => {
    try {
      const res = await $axios.get<AttendanceEstimateDetail>(
        `/estimates/attendance/${estimate_id}`
      )

      // NOTE: 存在しない場合は空配列で返ってくるため必要
      if (Array.isArray(res.data)) {
        throw new Error('指定された見積番号の見積書は存在しません。')
      }

      attendanceEstimateInfo.value = res.data
      return res.data
    } catch (err) {
      if (axios.isAxiosError(err)) {
        throw new Error('参列見積の詳細が取得できませんでした。')
      }

      if (err instanceof Error) {
        throw new Error(err.message)
      }
    }
  }

  /**
   * 見積書新規作成API
   */
  const postEstimate = async (param: CreateEstimateParams) => {
    const isComingOfAge = param.type === ESTIMATE.成人式.id

    try {
      const response = await estimateRepo.create(param).catch(async (error) => {
        await notifyError(
          error,
          `${isComingOfAge ? '成人式' : '卒業式'}見積書作成`,
          param
        )
        return undefined
      })

      if (!response) {
        throw new Error('見積書の作成に失敗しました。')
      }

      console.log('作成された見積ID', response.data.estimate_id)

      const { data } = await fileRepo.createPDF<EstimatePdf>({
        id: response.data.estimate_id,
        type: DOCUMENT.見積書.id,
        branch: 1,
      })

      if ('error_code' in data) {
        throw data
      }
      return response.data
    } catch (err: any) {
      console.log('API error', err)
      if (isAxiosError(err)) {
        $sentry.captureException(err.response?.data, {
          tags: {
            category: 'estimate',
          },
          extra: {
            category1: isComingOfAge ? '成人式' : '卒業式',
            category2: '見積書作成',
          },
        })
      }
      await saveEstimateTemporarilyWithAlert(
        isComingOfAge ? 1 : 2,
        '作成',
        err.message
      )
    }
  }

  /**
   * 参列見積書新規作成API
   */
  const postAttendanceEstimate = async (
    param: CreateAttendanceEstimateParams
  ) => {
    try {
      const response = await estimateRepo
        .createAttendance(param)
        .catch(async (error) => {
          await notifyError(error, `参列見積書作成`, param)
          return undefined
        })

      if (!response) {
        error.value = '見積書の保存に失敗しました。'
        return
      }

      console.log('作成された見積ID', response.data.estimate_id)

      const { data } = await fileRepo.createAttendancePDF<EstimatePdf>({
        id: response.data.estimate_id,
        type: DOCUMENT.見積書.id,
      })

      console.log('PDF生成&アップロード完了', data)

      if ('error_code' in data) {
        throw data
      }
      return response.data
    } catch (err) {
      console.log('API error', err)
      if (isAxiosError(err)) {
        $sentry.captureException(err.response?.data, {
          tags: {
            category: 'estimate',
          },
          extra: {
            category1: '参列',
            category2: '見積書作成',
          },
        })
      }
      error.value = '見積書の保存に失敗しました。'
    }
  }

  /**
   * 見積書更新API
   */
  const patchEstimate = async (estimate_id: number, data: EstimateDetail) => {
    const formattedData = estimateDetailEditFormatter(data)
    const isComingOfAge = formattedData.type === ESTIMATE.成人式.id

    try {
      const response = await estimateRepo
        .update(estimate_id, formattedData)
        .catch(async (error) => {
          await notifyError(
            error,
            `${
              isComingOfAge ? '成人式' : '卒業式'
            }見積書更新、見積番号: ${estimate_id}`,
            formattedData
          )
          return undefined
        })

      if (!response) {
        error.value = '見積書の更新に失敗しました。'
        return
      }

      //最新の枝番を取得する
      await fetchLatestEstimate(response.data.estimate_id)

      await fileRepo.createPDF({
        id: response.data.estimate_id,
        type: DOCUMENT.見積書.id,
        branch: estimateInfo.value?.branch_number,
      })
      return response.data
    } catch (err) {
      console.log('API error', err)
      if (isAxiosError(err)) {
        $sentry.captureException(err.response?.data, {
          tags: {
            category: 'estimate',
          },
          extra: {
            category1: isComingOfAge ? '成人式' : '卒業式',
            category2: '見積書更新',
          },
        })
      }
      error.value = '見積書の更新に失敗しました。'
    }
  }

  /**
   * 見積書削除API
   * @param {path} estimate_id
   */
  const deleteEstimate = async (estimate_id: number | string) => {
    try {
      await $axios.delete(`/estimates/${estimate_id}`)
    } catch (error) {
      await notifyError(error, `見積書削除、見積番号: ${estimate_id}`, {
        estimate_id,
      })
      console.log('API error', error)
    }
  }

  /** 最新の枝番の見積詳細を取得する */
  const fetchLatestEstimate = async (estimate_id: number) => {
    try {
      const type = in成人式見積.value ? ESTIMATE.成人式.id : ESTIMATE.卒業式.id
      // 最新の枝番の見積を取得
      await fetchEstimateList({
        estimate_id: estimate_id,
        estimateType: type,
      })
      // 枝番から最新の見積詳細を取得
      const branch = estimateList.value[0].branch_number
      await fetchEstimateInfo(estimate_id, branch)
    } catch (error) {
      console.log(error)
    }
  }

  /** 最新の枝番を取得する */
  const latesteBranchNumber = async (estimateId: number, type?: number) => {
    try {
      const res = await estimateRepo.getBranches({
        estimate_id: estimateId,
        type,
      })

      if (res.data.estimates.length === 0) {
        return null
      }

      return res.data.estimates[0].branch_number
    } catch (error) {
      console.log(error)
    }
  }

  return {
    estimateList,
    estimateInfo,
    attendanceEstimateInfo,
    attendanceEstimateInfoList,
    error,
    loading,
    fetchEstimateList,
    fetchAttendanceEstimate,
    fetchEstimateInfo,
    fetchAttendanceEstimateInfo,
    postEstimate,
    patchEstimate,
    deleteEstimate,
    fetchEstimateManageData,
    fetchLatestEstimate,
    latestEstimate,
    latesteBranchNumber,
    postAttendanceEstimate,
  }
}
