






import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    environmentType: {
      type: String,
      default: '',
    },
  },
})
