import { MutationTree } from 'vuex'
import { CustomerStoreInfo } from '../types'

const mutations: MutationTree<CustomerStoreInfo> = {
  setIsReselecting: (state, isReselecting) => {
    state.isReselecting = isReselecting
  },

  delete: (state) => {
    state.isReselecting = false
  },
}

export default mutations
