/**
 * @note 追加金額の種類 (2022年12月2日現在のAPI仕様書に準拠している)
 * https://docs.google.com/spreadsheets/d/1swY_PzVvCiLSJcf6aXvllio3azHYXIbjZjzDiRRH2j4/edit#gid=1518800674&range=Q40
 */
export class AdjustmentType {
  static readonly フルセットレンタル = 1
  static readonly フルセットレンタルPP = 2
  static readonly フルセット購入 = 3
  static readonly フルセット購入PP = 4
  static readonly 持込プラン5P1冊 = 5
  static readonly 持込プラン15P1冊 = 6
  static readonly 持込プランアルバムなし = 7
  static readonly お写真プラン5P1冊 = 8
  static readonly お写真プランPP5P1冊 = 9
  static readonly お写真プラン15P1冊 = 10
  static readonly お写真プランPP15P1冊 = 11
  static readonly Wフォトレンタル = 12
  static readonly WフォトレンタルPP = 13
  static readonly Wフォト持込 = 14
  static readonly 卒業式 = 15
  static readonly 参列レンタル = 16
  static readonly 参列レンタルPP = 17
  static readonly 参列持込 = 18
  static readonly 卒業式PP = 19

  static toWord = (val: number) => {
    switch (val) {
      case AdjustmentType.フルセットレンタル:
        return 'フルセットレンタル'
      case AdjustmentType.フルセットレンタルPP:
        return 'フルセットレンタルPP'
      case AdjustmentType.フルセット購入:
        return 'フルセット購入'
      case AdjustmentType.フルセット購入PP:
        return 'フルセット購入PP'
      case AdjustmentType.持込プラン5P1冊:
        return '持込プラン+5P×1冊'
      case AdjustmentType.持込プラン15P1冊:
        return '持込プラン+15P×1冊'
      case AdjustmentType.持込プランアルバムなし:
        return '持込プラン（アルバムなし）'
      case AdjustmentType.お写真プラン5P1冊:
        return 'お写真プラン+5P×1冊'
      case AdjustmentType.お写真プランPP5P1冊:
        return 'お写真プラン+PP+5P×1冊'
      case AdjustmentType.お写真プラン15P1冊:
        return 'お写真プラン+15P×1冊'
      case AdjustmentType.お写真プランPP15P1冊:
        return 'お写真プラン+PP+15P×1冊'
      case AdjustmentType.Wフォトレンタル:
        return 'Wフォト レンタル'
      case AdjustmentType.WフォトレンタルPP:
        return 'Wフォト レンタル+PP'
      case AdjustmentType.Wフォト持込:
        return 'Wフォト 持込'
      case AdjustmentType.卒業式:
        return '卒業式'
      case AdjustmentType.参列レンタル:
        return '参列レンタル'
      case AdjustmentType.参列レンタルPP:
        return '参列レンタルPP'
      case AdjustmentType.参列持込:
        return '参列持込'
      case AdjustmentType.卒業式PP:
        return '卒業式PP'
      default:
        return '該当なし'
    }
  }
  static toId = (label: string) => {
    switch (label) {
      case 'フルセットレンタル':
        return AdjustmentType.フルセットレンタル
      case 'フルセットレンタルPP':
        return AdjustmentType.フルセットレンタルPP
      case 'フルセット購入':
        return AdjustmentType.フルセット購入
      case 'フルセット購入PP':
        return AdjustmentType.フルセット購入PP
      case '持込プラン+5P×1冊':
        return AdjustmentType.持込プラン5P1冊
      case '持込プラン+15P×1冊':
        return AdjustmentType.持込プラン15P1冊
      case '持込プラン（アルバムなし）':
        return AdjustmentType.持込プランアルバムなし
      case 'お写真プラン+5P×1冊':
        return AdjustmentType.お写真プラン5P1冊
      case 'お写真プラン+PP+5P×1冊':
        return AdjustmentType.お写真プランPP5P1冊
      case 'お写真プラン+15P×1冊':
        return AdjustmentType.お写真プラン15P1冊
      case 'お写真プラン+PP+15P×1冊':
        return AdjustmentType.お写真プランPP15P1冊
      case 'Wフォト レンタル':
        return AdjustmentType.Wフォトレンタル
      case 'Wフォト レンタル+PP':
        return AdjustmentType.WフォトレンタルPP
      case 'Wフォト 持込':
        return AdjustmentType.Wフォト持込
      case '卒業式':
        return AdjustmentType.卒業式
      case '参列レンタル':
        return AdjustmentType.参列レンタル
      case '参列レンタルPP':
        return AdjustmentType.参列レンタルPP
      case '参列持込':
        return AdjustmentType.参列持込
      case '卒業式PP':
        return AdjustmentType.卒業式PP
      default:
        return 0
    }
  }

  static getTypes = (val: string) => {
    switch (val) {
      case 'フルセット':
        return [
          AdjustmentType.フルセットレンタル,
          AdjustmentType.フルセットレンタルPP,
          AdjustmentType.フルセット購入,
          AdjustmentType.フルセット購入PP,
        ]
      case '当日Bコース':
        return [AdjustmentType.持込プランアルバムなし]
      case '振袖持込':
        return [AdjustmentType.持込プラン5P1冊, AdjustmentType.持込プラン15P1冊]
      case 'お写真プラン':
        return [
          AdjustmentType.お写真プラン5P1冊,
          AdjustmentType.お写真プランPP5P1冊,
          AdjustmentType.お写真プラン15P1冊,
          AdjustmentType.お写真プランPP15P1冊,
        ]
      case 'Wフォト':
        return [
          AdjustmentType.Wフォトレンタル,
          AdjustmentType.WフォトレンタルPP,
          AdjustmentType.Wフォト持込,
        ]
      case 'Wフォトレンタル':
        return [
          AdjustmentType.Wフォトレンタル,
          AdjustmentType.WフォトレンタルPP,
        ]
      case 'Wフォト持込':
        return [AdjustmentType.Wフォト持込]
      default:
        return []
    }
  }
}
