import { computed } from '@nuxtjs/composition-api'
import { useScreenTypeFlags } from '~/composable/estimate/domain/ComingOfAge/ScreenTypeFlags'
import { EstimateState } from '~/composable/estimate/repository/comingOfAge/EstimateState'
import {
  BaseItemState,
  PlanFlags,
  WPhotoBaseItemState,
} from '~/composable/estimate/repository/comingOfAge/states/types'
import { ZoriBag } from '~/composable/estimate/repository/comingOfAge/states/zoriBagUtils'
import ProductClassification from '~/constants/enums/productClassification'
import { Item, ItemWPhoto } from '../types'
import { ItemPlan } from '~/constants/enums/itemPlan'

/**
 * 小物系の見積一覧表示内容を加工する共通部分を切り出した関数
 */
export const getListItem = (
  itemState: BaseItemState,
  planFlags: PlanFlags,
  index: number = 0
) => {
  const zero_price = 0
  const 一品目 = index === 0
  const 複数個目 = index > 0
  const { isフルセット, is写のみレンタル, is持ち込み, is当日Bコース } =
    planFlags
  const is振袖持込 = is持ち込み || is当日Bコース

  let isPPDiscount = !!itemState.flags.isPPDiscount
  let isPPAddingPrice = !!itemState.flags.isPPAddingPrice

  // defaultはフルセットプラン
  let price1: number | null = itemState.レンタル価格
  let price2: number | null = itemState.プレミアムレンタル価格
  let price3: number | null = itemState.購入価格
  let price4: number | null = itemState.プレミアム購入価格

  // NOTE: 草履バックのみレンタル列のみに単品購入を表示する必要があるので分けている
  let cellTextRental = itemState.cellLabel
  let cellTextBuy = itemState.cellLabel
  let cellLabel = itemState.cellLabel
  let cellTextRentalPremium = ''
  let cellTextBuyPremium = ''
  let linkText = itemState.商品名

  if (is写のみレンタル) {
    price3 = itemState.レンタル価格
    price4 = itemState.プレミアムレンタル価格
  } else if (is振袖持込) {
    price1 = itemState.表示価格
    price2 = itemState.表示価格
  }

  // NOTE: 草履バックは単品購入を保持する場所を分けているのと、
  // 振袖レンタル時単品購入には単品購入になるという特殊なロジックがあるため別ロジックを作っている
  // 経緯・理由等はnotion参照
  // https://www.notion.so/arsagajp/223a46b9e24a493a96be4f5c68770a81#402fbc7d3bf04ce29163dd71ec27fcc5
  const is草履 = itemState.品種区分 === ProductClassification.zori
  const isバッグ = itemState.品種区分 === ProductClassification.bag
  if (is草履 || isバッグ) {
    const zoriBagState = itemState as ZoriBag
    const { is振袖レンタル時単品購入 } = zoriBagState.flags

    linkText = zoriBagState.linkText
    cellLabel = zoriBagState.cellLabel

    if (isPPDiscount) {
      if (is振袖レンタル時単品購入) {
        price1 = zoriBagState.単品購入価格
        price2 = zoriBagState.単品購入価格
        cellTextBuy = ''
      }
      if (index === 1) {
        price1 = zoriBagState.表示価格
        price2 = zoriBagState.表示価格
        price3 = zoriBagState.表示価格
      }
    } else {
      if (isフルセット && is振袖レンタル時単品購入) {
        price1 = zoriBagState.単品購入価格
        price2 = zoriBagState.単品購入価格
        cellTextBuy = ''
      } else if (is写のみレンタル && is振袖レンタル時単品購入) {
        price1 = zoriBagState.単品購入価格
        price2 = zoriBagState.単品購入価格
        price3 = zoriBagState.単品購入価格
        price4 = zoriBagState.単品購入価格
      }
      // 持込プランと複数個目は同じ料金形態
      if (is振袖持込 || 複数個目) {
        price1 = zoriBagState.表示価格
        price2 = zoriBagState.表示価格
        price3 = zoriBagState.表示価格
        price4 = zoriBagState.表示価格
      }
    }
  }

  const is髪飾り = itemState.品種区分 === ProductClassification.kamikazari
  // NOTE: ２個目以降は基本的に単品レンタルOR購入のうち、表示する価格として選ばれた方
  // 草履・バッグについては上で判定を行っている
  if (is髪飾り || (複数個目 && !is草履 && !isバッグ)) {
    price1 = itemState.表示価格
    price2 = itemState.表示価格
    price3 = itemState.表示価格
    price4 = itemState.表示価格
  }
  if (is髪飾り && 一品目) {
    // NOTE: 髪飾りは1個目かつプレミアムパック適用時髪飾りは0円になる
    const hasPP = isフルセット || is写のみレンタル
    price1 = itemState.表示価格
    price2 = hasPP ? zero_price : itemState.表示価格
    price3 = itemState.表示価格
    price4 = hasPP ? zero_price : itemState.表示価格
  }

  const is帯揚げ = itemState.品種区分 === ProductClassification.obiage
  const is重ね衿 = itemState.品種区分 === ProductClassification.kasaneeri
  const isセット内帯揚げ = itemState.小物プラン === ItemPlan.inSet && is帯揚げ
  const isセット内重ね衿 = itemState.小物プラン === ItemPlan.inSet && is重ね衿
  // NOTE: 複数個目の帯揚げ、重ね衿は振袖レンタル時には単品レンタル、振袖購入時には単品購入になる
  const isItemお持込 = itemState.小物プラン === ItemPlan.bringIn
  // NOTE: PP適用時、ランクアップでプレミアム価格を「¥0」として扱う
  const is二品目ランクアップ =
    itemState.小物プラン === ItemPlan.rankUpInPremium && index === 1

  if ((is帯揚げ || is重ね衿) && 複数個目 && isフルセット) {
    price1 = itemState.レンタル価格
    price2 = itemState.レンタル価格
    price3 = itemState.購入価格
    price4 = itemState.購入価格
    cellTextRental = isItemお持込 ? '' : '単品レンタル'
    cellTextBuy = isItemお持込 ? '' : '単品購入'
    if (is二品目ランクアップ) {
      /** 二品目ランクアップの場合、価格は¥0円 */
      price2 = zero_price
      price4 = zero_price
      cellTextRentalPremium = 'ランクアップ'
      cellTextBuyPremium = 'ランクアップ'
    }
  }

  if ((is帯揚げ || is重ね衿) && is写のみレンタル) {
    /** 一品目 セット内 */
    if (一品目 && (isセット内帯揚げ || isセット内重ね衿)) {
      if (isPPDiscount) {
        price2 = itemState.プレミアムレンタル価格
        price4 = itemState.プレミアム購入価格
      } else {
        price2 = zero_price
        price4 = zero_price
      }
    } else if (複数個目) {
      /** 二品目 */
      price1 = itemState.レンタル価格
      price2 = itemState.レンタル価格
      price3 = itemState.レンタル価格
      price4 = itemState.レンタル価格
      cellTextRental = isItemお持込 ? '' : '単品レンタル'
      cellTextBuy = isItemお持込 ? '' : '単品レンタル'
      if (is二品目ランクアップ) {
        /** 二品目ランクアップの場合、価格は¥0円 */
        price2 = zero_price
        price4 = zero_price
        cellTextRentalPremium = 'ランクアップ'
        cellTextBuyPremium = 'ランクアップ'
      }
    }
  }

  return {
    item: {
      // NOTE: 不要な値は表示データに含めないという挙動に変更するため、isHiddenは不要
      name: itemState.品目名,
      rankType: itemState.label || cellLabel || undefined,
      linkText: linkText || undefined,
      product_type_id: itemState.品種区分,
      rankTypeId: itemState.小物プラン || undefined,
      isPPDiscount: isPPDiscount,
      isPPAddingPrice: isPPAddingPrice,
    },
    column1: {
      price: price1,
      text: cellTextRental || undefined,
    },
    column2: {
      price: price2,
      text: cellTextRentalPremium || cellTextRental || undefined,
    },
    column3: {
      price: price3,
      text: cellTextBuy || undefined,
    },
    column4: {
      price: price4,
      text: cellTextBuyPremium || cellTextBuy || undefined,
    },
  }
}

export const getWPhotoListItem = (
  itemState: WPhotoBaseItemState,
  isWPhotoFurisodeRental?: boolean
) => {
  let price1: number | null = itemState.レンタル価格
  let price2: number | null = itemState.プレミアムレンタル価格
  let cellLabel: string | null = itemState.cellLabel

  if (itemState.見積表示) {
    price1 = itemState.表示価格
    price2 = itemState.表示価格
  }
  if (
    isWPhotoFurisodeRental &&
    (itemState.品種区分 === ProductClassification.obiage ||
      itemState.品種区分 === ProductClassification.kasaneeri) &&
    itemState.小物プラン === ItemPlan.toBeDecided
  ) {
    cellLabel = '単品レンタル'
  }
  return {
    item: {
      // NOTE: 不要な値は表示データに含めないという挙動に変更するため、isHiddenは不要
      name: itemState.品目名,
      rankType: itemState.label || cellLabel || undefined,
      linkText: itemState.商品名 || undefined,
      product_type_id: itemState.品種区分,
      rankTypeId: itemState.小物プラン || undefined,
    },
    column1: {
      price: price1,
      text: cellLabel || undefined,
    },
    column2: {
      price: price2,
      text: cellLabel || undefined,
    },
  }
}

/**
 * 複数個タイプの小物を見積一覧用に加工する
 * @param startNum 何個目の小物から渡しているか指定する。草履複数個目のために使用
 */
export const getMultiListItems = (
  values: BaseItemState[],
  planFlags: PlanFlags,
  startNum: number = 0
): Item[] => {
  return values.map((value, i) => getListItem(value, planFlags, i + startNum))
}

export const getMultiWPhotoListItems = (
  values: WPhotoBaseItemState[],
  isWPhotoFurisodeRental: boolean
): ItemWPhoto[] => {
  return values.map((value) => getWPhotoListItem(value, isWPhotoFurisodeRental))
}

/**
 * 以下、各小物のストアを使って加工した内容を返す関数群
 */
export const useListItemFormatter = (state: BaseItemState) => {
  const { showTypeフルセット, showType写真のみレンタル, showType持ち込み } =
    useScreenTypeFlags()

  return computed(() => {
    const flags = {
      isフルセット: showTypeフルセット.value,
      is写のみレンタル: showType写真のみレンタル.value,
      is持ち込み: showType持ち込み.value,
    }
    return getListItem(state, flags)
  })
}

export const useMultiListItemFormatter = (state: BaseItemState[]) => {
  const { showTypeフルセット, showType写真のみレンタル, showType持ち込み } =
    useScreenTypeFlags()

  return computed(() => {
    const flags = {
      isフルセット: showTypeフルセット.value,
      is写のみレンタル: showType写真のみレンタル.value,
      is持ち込み: showType持ち込み.value,
    }
    return getMultiListItems(state, flags)
  })
}

export const useListItemショールFormatter = (
  state: EstimateState['ショール']
) => {
  const { showTypeフルセット, showType写真のみレンタル, showType持ち込み } =
    useScreenTypeFlags()

  return computed(() => {
    const flags = {
      isフルセット: showTypeフルセット.value,
      is写のみレンタル: showType写真のみレンタル.value,
      is持ち込み: showType持ち込み.value,
    }
    // NOTE: 写のみレンタルプランの時はショールは表示不要
    if (flags.is写のみレンタル) return null
    return getListItem(state, flags)
  })
}

export const useWPhotoListItemFormatter = (state: WPhotoBaseItemState) => {
  return computed(() => {
    return getWPhotoListItem(state)
  })
}

export const useWPhotoMultiListItemFormatter = (
  state: WPhotoBaseItemState[],
  isWPhotoFurisodeRental: boolean
) => {
  return computed(() => {
    return getMultiWPhotoListItems(state, isWPhotoFurisodeRental)
  })
}
