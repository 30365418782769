import { computed, Ref } from '@nuxtjs/composition-api'
import { useRestatePlan } from '../RestatePlan'
import { EstimateDetail, Items } from '~/api/estimateRepository'
import { usePrice } from '~/composable/general/Price'
import {
  Enum重ね衿_着物レンタル1個目,
  Enum重ね衿_着物レンタル2個以上,
} from '~/constants/estimate/graduationOptions'
import { EstimateSinglePriceType } from '~/constants/enums/estimateSinglePriceType'
import { ItemPlan } from '~/constants/enums/itemPlan'
import ProductId from '~/constants/enums/productClassification'
import { getSortedItemsByOrder } from '~/utils/utils'
import { useKasaneeriCondition } from '@/composable/general/PPDiscount/useKasaneeriCondition'

type Kasaneeri = FirstOf着物レンタル | 着物持込OR複数

type FirstOf着物レンタル = {
  type: 'firstOf着物レンタル'
  optionId種別: number | null
  optionId形態: number | null
  レンタル価格: string | null
  PP適用レンタル価格?: string | null
  flags: Flags
}

type 着物持込OR複数 = {
  type: '着物持込OR複数'
  optionId種別: number | null
  optionId見積表示: number | null
  レンタル価格: string | null
  購入価格: string | null
  optionId形態: number | null
  flags: Flags
}

type Flags = ReturnType<typeof getConditionalFlags>

export const useRestate重ね衿 = (estimate: Ref<EstimateDetail | undefined>) => {
  const restatePlan = useRestatePlan(estimate)
  const { getSecondKasaneeriRankUp } = useKasaneeriCondition()
  return computed(() => {
    if (!estimate.value?.items) return null

    const kasaneeriItems = getSortedItemsByOrder(
      estimate.value.items,
      ProductId.kasaneeri
    )
    const isSecondKasaneeriRankUp = getSecondKasaneeriRankUp(estimate.value)

    const kasaneeris: Kasaneeri[] = []
    // レスポンスに重ね衿が含まれていなければから配列を返す
    if (!kasaneeriItems.length) return kasaneeris

    if (restatePlan.value?.flags.has着物レンタル) {
      // 着物レンタルプランの場合,1個目の重ね襟はプラン内に入るので選択項目が違うので個別に処理する
      const first = kasaneeriItems.shift()
      const restItems = kasaneeriItems
      if (first) {
        kasaneeris.push(formatFirstKasaneeri(first, isSecondKasaneeriRankUp))
      }
      restItems.forEach((item) => {
        kasaneeris.push(formatOtherKasaneeri(item, isSecondKasaneeriRankUp))
      })
    } else {
      // 着物レンタル以外のプランの場合,全てプラン外の小物なので同じ処理
      kasaneeriItems.forEach((item) => {
        kasaneeris.push(formatOtherKasaneeri(item, isSecondKasaneeriRankUp))
      })
    }

    return kasaneeris
  })
}

/**
 * 復元条件に関わる条件式を定義する
 */
const getConditionalFlags = (item: Items) => {
  const rankUpType = [ItemPlan.rankUpInPremium, ItemPlan.rankUpOutPremium]

  const is持込 = item.plan === Enum重ね衿_着物レンタル1個目.種別.お持込
  const is単品レンタル =
    !is持込 && item.price_type === EstimateSinglePriceType.singleRentalPrice
  const is単品購入 =
    !is持込 && item.price_type === EstimateSinglePriceType.singlePurchasePrice

  return {
    is持込,
    isレンタル: !is持込,
    is単品レンタル購入: !is持込,
    isセット内:
      !is持込 && item.plan === Enum重ね衿_着物レンタル1個目.形態.セット内,
    isランクアップ: !is持込 && rankUpType.includes(item.plan),
    is単品レンタル,
    is単品購入,
  }
}

/**
 * セット内 + プレミアムの場合に変換処理を行う
 * [１品目]
 */
const firstKasaneeriForPP = (item: Items): Kasaneeri => {
  const { 種別, 形態 } = Enum重ね衿_着物レンタル1個目
  const { paramToString } = usePrice()
  return {
    type: 'firstOf着物レンタル',
    optionId種別: 種別.レンタル,
    optionId形態: 形態.セット内,
    レンタル価格: paramToString(0),
    PP適用レンタル価格: item.premium_rental_price
      ? paramToString(item.premium_rental_price)
      : null,
    flags: {
      is持込: false,
      isレンタル: true,
      is単品レンタル購入: false,
      isセット内: true,
      isランクアップ: false,
      is単品レンタル: false,
      is単品購入: false,
    },
  }
}

/**
 * セット内 + プレミアムの場合に変換処理を行う
 * [２品目]
 * （通常形態はnullだが、見積一覧の復元条件として使用）
 */
const secondKasaneeriForPP = (item: Items): Kasaneeri => {
  const { 種別, 形態, 見積表示 } = Enum重ね衿_着物レンタル2個以上
  const { paramToString } = usePrice()
  return {
    type: '着物持込OR複数',
    optionId種別: 種別.単品レンタル単品購入,
    optionId見積表示: 見積表示.単品レンタル価格,
    レンタル価格: paramToString(item.rental_price),
    購入価格: paramToString(item.rental_price),
    optionId形態: 形態.ランクアップ,
    flags: {
      is持込: false,
      isレンタル: true,
      is単品レンタル購入: true,
      isセット内: false,
      isランクアップ: false,
      is単品レンタル: true,
      is単品購入: false,
    },
  }
}

/**
 * NOTE: 着物レンタルプラン1個目のみプラン内のアイテムになる
 */
const formatFirstKasaneeri = (
  item: Items,
  isSecondKasaneeriRankUp: boolean
): Kasaneeri => {
  const { paramToString } = usePrice()
  const rankUpType = [ItemPlan.rankUpInPremium, ItemPlan.rankUpOutPremium]
  const { 種別, 形態 } = Enum重ね衿_着物レンタル1個目

  if (isSecondKasaneeriRankUp) {
    return firstKasaneeriForPP(item)
  }

  const kasaneeri: Kasaneeri = {
    type: 'firstOf着物レンタル',
    optionId種別: null,
    optionId形態: null,
    レンタル価格: null,
    flags: getConditionalFlags(item),
    PP適用レンタル価格: null,
  }
  kasaneeri.optionId種別 =
    item.plan === 種別.お持込 ? 種別.お持込 : 種別.レンタル

  if (item.plan === 形態.セット内) {
    kasaneeri.optionId形態 = 形態.セット内
    kasaneeri.PP適用レンタル価格 = item.premium_rental_price
      ? paramToString(item.premium_rental_price)
      : null
  } else if (rankUpType.includes(item.plan)) {
    kasaneeri.optionId形態 = 形態.ランクアップ
  }

  kasaneeri.レンタル価格 = paramToString(item.rental_price)

  return kasaneeri
}

/**
 * NOTE: 着物レンタルプラン1個目以外はプラン外のアイテムになるので、単品購入か単品レンタルのみ
 */
const formatOtherKasaneeri = (
  item: Items,
  isSecondKasaneeriRankUp: boolean
): Kasaneeri => {
  const { paramToString } = usePrice()
  // NOTE: 着物レンタル2個目以降と着物持込全部は同じ項目のためEnumは前者用のを使う
  const { 種別, 見積表示 } = Enum重ね衿_着物レンタル2個以上
  // PP適用時の復元処理（２品目のみ適用）
  if (isSecondKasaneeriRankUp && item.order_number === 2) {
    return secondKasaneeriForPP(item)
  }

  const flags = getConditionalFlags(item)

  const kasaneeri: Kasaneeri = {
    type: '着物持込OR複数',
    optionId種別: null,
    optionId見積表示: null,
    レンタル価格: null,
    購入価格: null,
    optionId形態: null,
    flags,
  }
  kasaneeri.optionId種別 = flags.is持込
    ? 種別.お持込
    : 種別.単品レンタル単品購入

  if (flags.is単品レンタル) {
    kasaneeri.optionId見積表示 = 見積表示.単品レンタル価格
  } else if (flags.is単品購入) {
    kasaneeri.optionId見積表示 = 見積表示.単品購入価格
  }

  kasaneeri.レンタル価格 = paramToString(item.rental_price)
  if (item.purchase_price) {
    kasaneeri.購入価格 = paramToString(item.purchase_price)
  }

  kasaneeri.optionId形態 = !isSecondKasaneeriRankUp
    ? ItemPlan.toBeDecided
    : null

  return kasaneeri
}
