import { GetterTree } from 'vuex'
import { RootState, EstimatePhotoSettings } from '../../../../types'

const getters: GetterTree<EstimatePhotoSettings, RootState> = {
  // NOTE: useComingStore用に追加
  getAllState: (state) => {
    return state
  },
  size: (state: EstimatePhotoSettings) => {
    return state.settings.length
  },

  settings: (state: EstimatePhotoSettings) => {
    return state.settings
  },

  getIsValidate: (state: EstimatePhotoSettings) => {
    return state.isValidate
  },
}

export default getters
