import {
  AttendanceEstimateDetail,
  AttendanceItems,
  EstimateDetail,
  Hurisode,
  Items,
  WPhotoPlan,
} from '~/api/estimateRepository'
import { ProductCategory } from '~/constants/enums/productCategory'
import ProductClassification from '~/constants/enums/productClassification'

// TODO: カタログやプロパーは契約済みの場合仕立て上がりとして商品番号が発行されるため、そもそもこの処理全体的に不要の可能性がある。
// 余裕のある時に精査して削除したい

// NOTE: 契約済みの見積コピーした場合かつ商品に変更があった場合、product_idを送らない必要あるパターンがあるためここでそのハンドリングを行う
// 対象範囲・詳細についてはチケット参照
// https://vitgear.backlog.com/view/WB-3216
export const modifyCopyingProducts成人式 = (
  copiedEstimate: EstimateDetail | null,
  formattedHurisode: Hurisode,
  formattedItems: Items[],
  formattedWphoto: WPhotoPlan | null
) => {
  let hurisode = formattedHurisode
  let items = formattedItems
  let w_photo_plan = formattedWphoto
  if (copiedEstimate) {
    if (copiedEstimate.hurisode) {
      hurisode = modifyCopyingHurisode(
        formattedHurisode,
        copiedEstimate.hurisode
      )
    }
    if (copiedEstimate.items) {
      items = modifyCopying帯(formattedItems, copiedEstimate.items) as Items[]
    }
    if (w_photo_plan) {
      // NOTE: Wフォト振袖は仕立て上り検索しかないためここでの調整は不要
      if (formattedWphoto?.items && copiedEstimate.w_photo_plan?.items) {
        w_photo_plan.items = modifyCopying帯(
          formattedWphoto.items,
          copiedEstimate.w_photo_plan?.items
        ) as Items[]
      }
    }
  }
  return { hurisode, items, w_photo_plan }
}

/**
 * 卒業式の場合振袖・二尺袖・袴がestimate_hurisode_idの対応範囲だが、
 * 現状はプロパーはなく、商品変更時には必ずproduct_idが上書きされるためハンドリングは不要
 */
export const modifyCopyingProducts卒業式 = (
  copiedEstimate: EstimateDetail | null,
  formattedHurisode: Hurisode
) => {
  let hurisode = formattedHurisode
  if (copiedEstimate) {
    if (copiedEstimate.hurisode) {
      hurisode = modifyCopyingHurisode(
        formattedHurisode,
        copiedEstimate.hurisode
      )
    }
  }
  return { hurisode }
}

/**
 * 参列の場合帯のみが対応範囲
 * 振袖は仕立て上りレンタルしかなく、留袖・訪問着については考慮不要のため
 */
export const modifyCopyingProducts参列 = (
  copiedEstimate: AttendanceEstimateDetail | null,
  formattedItems: AttendanceItems[]
) => {
  let items = formattedItems
  if (copiedEstimate) {
    if (copiedEstimate.items) {
      items = modifyCopying帯(
        formattedItems,
        copiedEstimate.items
      ) as AttendanceItems[]
    }
  }
  return { items }
}

const modifyCopyingHurisode = (
  current: Hurisode,
  original: Hurisode
): Hurisode => {
  // NOTE: プロパー商品からプロパー商品への変更の場合のみ、商品名が変わるのみで別の商品になったことがidなどで判断できないため
  // product_idをundefinedに戻して、別商品として商品番号を再発行できるようにする。
  const isプロパー商品 =
    ProductCategory.isProper(current.category) &&
    ProductCategory.isProper(original.category)
  const same商品名 = original.product_name === current.product_name
  if (isプロパー商品 && !same商品名) {
    current.product_id = undefined
  }
  return current
}

type ItemBase = {
  product_type_id: Items['product_type_id']
  product_id?: Items['product_id'] | null
  product_name?: Items['product_name'] | null
}
const modifyCopying帯 = (current: ItemBase[], original: ItemBase[]) => {
  // NOTE: プロパー商品からプロパー商品への変更の場合のみ、商品名が変わるのみで別の商品になったことがidなどで判断できないため
  // product_idをundefinedに戻して、別商品として商品番号を再発行できるようにする。
  // 成人式でプロパー商品があるのはitemは帯のみ
  const original帯 = original.find(
    (item) => item.product_type_id === ProductClassification.obi
  )
  if (original帯) {
    current = current.map((currentItem) => {
      if (currentItem.product_type_id === ProductClassification.obi) {
        // NOTE: 仕立て上り商品から、もしくは仕立て上り商品に、商品を切り替えた場合はproduct_idが変わるのでその場合は調整の必要がない
        const sameProductid = original帯.product_id === currentItem.product_id
        const same商品名 = original帯.product_name === currentItem.product_name
        // NOTE: 契約後はproduct_idが発行されるため、何かのパラメータから「プロパーかどうか」を判断することはできないため、
        // 事実上プロパー→プロパーの変更時となる条件として「商品番号が変わらず、商品名だけ変わった時」という条件を置いている
        if (sameProductid && !same商品名) {
          currentItem.product_id = undefined
        }
      }
      return currentItem
    })
  }
  return current
}
