import { Items } from '~/api/estimateRepository'
import { EstimateState } from '~/composable/estimate/repository/graduation/EstimateState'
import { PP_SINGLE_RENTAL_PRICE } from '~/constants/productData'
import { usePrice } from '~/composable/general/Price'
import { Kasaneeri } from '~/composable/estimate/repository/graduation/states/State重ね衿'
import { EstimateSinglePriceType } from '~/constants/enums/estimateSinglePriceType'
import { ItemPlan } from '~/constants/enums/itemPlan'
import ProductClassification from '~/constants/enums/productClassification'
import { COORDINATE_PLAN, OTHER_ITEMS } from '~/constants/estimate/config'
import { PP_DISCOUNT_PRODUCT } from '~/constants/estimate/comingOfAge'

export const useItemsFormatter = (estimateState: EstimateState) => {
  const { toNumber } = usePrice()
  const getFormattedItems = () => {
    const { 半巾帯, 重ね衿, 別衿, 髪飾り, 草履ブーツ, 巾着バッグ, その他商品 } =
      estimateState.value

    /**
     * 重ね衿PP適用時のpricetype変換処理
     * NOTE:
     * 二品目ランクアップの場合：一品目単品レンタル
     * 二品目単品レンタルの場合：一品目セット内
     */
    const getKasaneeriItemPriceType = (
      is二品目ランクアップ: boolean,
      item: Kasaneeri,
      sequenceNumber: number
    ): number | null | undefined => {
      if (
        is二品目ランクアップ &&
        sequenceNumber === PP_DISCOUNT_PRODUCT.FIRST
      ) {
        return EstimateSinglePriceType.singleRentalPrice
      }
      if (is二品目ランクアップ) {
        return undefined
      }

      return item.見積表示?.id
    }

    const items: Items[] = []

    if (!半巾帯.小物プラン) throw new Error('半巾帯.小物プランがありません')

    items.push({
      product_id: 半巾帯.商品ID ?? undefined,
      product_type_id: 半巾帯.品種区分,
      plan: 半巾帯.小物プラン,
      rental_price: 半巾帯.レンタル価格 || 0,
      purchase_price: 半巾帯.購入価格 || 0,
      product_name: 半巾帯.商品名 ?? undefined,
      price_type: 半巾帯.見積表示?.id || undefined,
      premium_rental_price: 0,
    })

    重ね衿.forEach((item, i) => {
      if (!item.小物プラン) throw new Error('重ね衿.小物プランがありません')
      const is二品目ランクアップ = 重ね衿.some(
        (item, i) => i === PP_DISCOUNT_PRODUCT.SECOND && item.isランクアップ
      )

      const plan =
        is二品目ランクアップ && i === PP_DISCOUNT_PRODUCT.SECOND
          ? ItemPlan.rankUpInPremium
          : item.小物プラン!
      const priceType = getKasaneeriItemPriceType(is二品目ランクアップ, item, i)

      items.push({
        product_type_id: item.品種区分,
        plan: plan ?? undefined,
        rental_price: item.レンタル価格 || 0,
        purchase_price: item.購入価格 || 0,
        product_name: item.商品名 ?? undefined,
        price_type: priceType || undefined,
        order_number: i + 1,
        premium_rental_price: item.PP適用時フリー入力
          ? item.PP適用時フリー入力
          : PP_SINGLE_RENTAL_PRICE,
      })
    })

    髪飾り.forEach((item, i) => {
      if (!item.小物プラン) throw new Error('髪飾り.小物プランがありません')
      items.push({
        product_type_id: item.品種区分,
        plan: item.小物プラン,
        rental_price: 0,
        purchase_price: item.購入価格 ?? 0,
        product_name: undefined,
        price_type: item.priceType,
        order_number: i + 1,
      })
    })

    if (!別衿.小物プラン) throw new Error('別衿.小物プランがありません')
    items.push({
      product_type_id: 別衿.品種区分,
      plan: 別衿.小物プラン,
      rental_price: 別衿.レンタル価格 || 0,
      purchase_price: 別衿.購入価格 || 0,
      product_name: 別衿.商品名 ?? undefined,
      price_type: 別衿.見積表示?.id || undefined,
      premium_rental_price: PP_SINGLE_RENTAL_PRICE,
    })

    if (!草履ブーツ.小物プラン)
      throw new Error('草履ブーツ.小物プランがありません')
    items.push({
      product_type_id: 草履ブーツ.品種区分,
      plan: 草履ブーツ.小物プラン,
      rental_price: 草履ブーツ.レンタル価格 || 0,
      purchase_price: 草履ブーツ.購入価格 || 0,
      product_name: 草履ブーツ.商品名 ?? undefined,
      price_type: 草履ブーツ.見積表示?.id || undefined,
      product_id: 草履ブーツ.商品id ?? undefined,
      premium_rental_price: PP_SINGLE_RENTAL_PRICE,
    })

    if (!巾着バッグ.小物プラン)
      throw new Error('巾着バッグ.小物プランがありません')
    items.push({
      product_type_id: 巾着バッグ.品種区分,
      plan: 巾着バッグ.小物プラン,
      rental_price: 巾着バッグ.レンタル価格 || 0,
      purchase_price: 巾着バッグ.購入価格 || 0,
      product_name: 巾着バッグ.商品名 ?? undefined,
      price_type: 巾着バッグ.見積表示?.id || undefined,
      product_id: 巾着バッグ.商品id ?? undefined,
      premium_rental_price: PP_SINGLE_RENTAL_PRICE,
    })
    if (その他商品.その他の商品を追加しますか === OTHER_ITEMS.種別.する) {
      items.push(
        ...その他商品.その他商品追加.map((item, i) => {
          return {
            product_type_id: ProductClassification.otherItem,
            plan: ItemPlan.toBeDecided,
            rental_price:
              item.price_type === OTHER_ITEMS.PRICE_TYPE.単品レンタル
                ? toNumber(item.price || '')
                : 0,
            purchase_price:
              item.price_type === OTHER_ITEMS.PRICE_TYPE.単品購入
                ? toNumber(item.price || '')
                : 0,
            product_name: item.product_name,
            price_type:
              item.price_type === OTHER_ITEMS.PRICE_TYPE.単品レンタル
                ? EstimateSinglePriceType.singleRentalPrice
                : EstimateSinglePriceType.singlePurchasePrice,
            product_id: undefined,
            order_number: i + 1,
          }
        })
      )
    }

    return items
  }
  return { getFormattedItems }
}
