import { Customer } from '~/composable/api/useCustomer'
import { AddressInputs, valueOf } from '../../Attendance/Main/state'
import { PAYMENT_METHOD } from '~/features/contracts/cleaning/payment/enums'
import { Staff } from '~/store/types'
import { DISCOUNT_DATA } from '~/features/contracts/cleaning/discount/enums'
import { Profile } from '~/composable/api/usePersonal'
import type {
  CheckboxStatus,
  CheckboxType,
} from '~/constants/checklist/checkbox'

// ** タブ **
export type Tab = {
  text: string
  isComplete: boolean | null
  isShow: boolean
  isCurrent: boolean
}

// ** 商品情報 **
export type ProductInformation = {
  hinsyu: number | null
  betsueri: number | null
  feature: string
  checkListBranchNumber: number | null
  checklist?: {
    branchNumber?: number
    lists: {
      checkListId?: number
      type: CheckboxType
      checkStatus: CheckboxStatus
      checkDate: string
      staffId?: number
    }[]
  } | null
}

export type ProductInformations = {
  informations: ProductInformation[]
  productPhoto: string
}

export type ProductInfoKeys = keyof ProductInformation
export type ProductInfoValues = valueOf<ProductInformation>
export type ProductInformationsKeys = keyof ProductInformations
export type ProductInformationsValues = valueOf<ProductInformations>

//  ** その他割引 **
export type DiscountData = {
  discount: number | null //割引適用
  price: string //割引額
}

export type DiscountKeys = keyof DiscountData
export type DiscountValues = valueOf<DiscountData>

//  ** お支払 **
export type PaymentDetail = {
  isActive: boolean
  methods: typeof PAYMENT_METHOD[keyof typeof PAYMENT_METHOD]
  tomonokai_points?: number
  amount: number
}

export type Payment = {
  dueDate: string | null
  staff: Staff | null
  details: PaymentDetail[]
  total: number | null
  notes: string | null
  paymentDeadline: string // 残高支払い期限
  payee: number | null
  payeeInfo: string // 振込先
  loginShopTransferPlaceId: number | null // ログイン時店舗の振込先ID
  category: number | null
  isPayment: number | null
  balance: number
  paidAmount: number
}

export type PaymentKeys = keyof Payment
export type PaymentValues = valueOf<Payment>

// ** 備考 **
export type RemarksData = {
  text: string
}

// ** 基本設定 **
export type BaseSettings = {
  purchasedFrom: number | null //ふりそでMODE購入品か否か
  buyer: Customer | null //購入者情報
  contractor: Customer | null //契約者情報
  contractorProfile: Profile | null
  deliveryDate: string | null //納品予定日
  contractorType: number | null //契約される方 (本人orご家族様)
  desiredTimeOption: { id: number; name: string } | null //ご希望のお届け時間
  guidanceEmail: string | null //通知用メールアドレス
  deliveryType: number | null // 現住所 or ご実家住所 or 別住所
  deliveryInfo: AddressInputs //お送り先情報
}

export type DeliveryInfo = BaseSettings['deliveryInfo']
export type BaseSettingsKeys = keyof BaseSettings
export type BaseSettingsValues = valueOf<BaseSettings>

// ** 署名 **
export type Signature = {
  checked: {
    first: boolean
    second: boolean
    third: boolean
  }
  signUrl: string
}

export type SignatureKeys = keyof Signature
export type SignatureValues = valueOf<Signature>

export type State = {
  data: {
    contractDate: string
    productInformations: ProductInformations
    discount: DiscountData
    baseSettings: BaseSettings
    remarks: RemarksData
    payment: Payment
    signature: Signature
  }
  tabList: Tab[]
  finalPageList: Tab[]
  contractId: number | null
  contractTotalPrice: number | null // 一時保存時に合計金額を保持する必要があるため追加
}

export const getInitialStore = (): State => {
  return {
    data: {
      contractDate: '',
      // ** 商品情報 **
      productInformations: {
        informations: [],
        productPhoto: '',
      },
      // ** その他割引 **
      discount: {
        discount: DISCOUNT_DATA.APPLY.いいえ, //割引適用
        price: '', //割引額
      },
      // ** お支払 **
      payment: {
        dueDate: null,
        staff: null,
        total: null,
        notes: null,
        category: null,
        details: [
          {
            isActive: false,
            methods: PAYMENT_METHOD.CASH,
            amount: 0,
          },
          {
            isActive: false,
            methods: PAYMENT_METHOD.CARD,
            amount: 0,
          },
          {
            isActive: false,
            methods: PAYMENT_METHOD.GIFT_CERTIFICATES,
            amount: 0,
          },
          {
            isActive: false,
            methods: PAYMENT_METHOD.POINT,
            amount: 0,
          },
          {
            isActive: false,
            methods: PAYMENT_METHOD.FAMILY_POINT,
            amount: 0,
          },
          {
            isActive: false,
            methods: PAYMENT_METHOD.E_MONEY,
            amount: 0,
          },
          {
            isActive: false,
            methods: PAYMENT_METHOD.TRANSFER,
            amount: 0,
          },
          {
            isActive: false,
            methods: PAYMENT_METHOD.POINT_ROUND_OFF,
            amount: 0,
          },
        ],
        paymentDeadline: '',
        payee: null,
        payeeInfo: '',
        loginShopTransferPlaceId: null, // ログイン時店舗の振込先ID
        isPayment: null, // 支払い処理を行うかどうか（契約変更時に使用）
        balance: 0, // 支払い残高（契約変更時に使用）
        paidAmount: 0, // 支払い済み金額（契約変更時に使用）,
      },
      // ** 備考 **
      remarks: {
        text: '',
      },
      baseSettings: {
        purchasedFrom: null,
        buyer: null,
        deliveryDate: null,
        contractor: null,
        contractorProfile: null,
        desiredTimeOption: null,
        guidanceEmail: null,
        contractorType: null,
        deliveryType: null,
        deliveryInfo: {
          phone_number: '',
          zip_code: '',
          prefectures: '',
          address1: '',
          address2: '',
        },
      },
      signature: {
        checked: {
          first: false,
          second: false,
          third: false,
        },
        signUrl: '',
      },
    },
    // ** タブ **
    tabList: [
      {
        text: '基本設定',
        isComplete: null,
        isShow: true,
        isCurrent: true,
      },
      {
        text: '商品情報',
        isComplete: null,
        isShow: true,
        isCurrent: false,
      },
      {
        text: 'その他割引',
        isComplete: true,
        isShow: true,
        isCurrent: false,
      },
      {
        text: 'お支払',
        isComplete: null,
        isShow: true,
        isCurrent: false,
      },
      {
        text: '備考',
        isComplete: null,
        isShow: true,
        isCurrent: false,
      },
    ],
    // ** 最終ページ **
    finalPageList: [
      {
        text: '入力内容確認',
        isComplete: null,
        isShow: false,
        isCurrent: true,
      },
      {
        text: '署名',
        isComplete: null,
        isShow: true,
        isCurrent: false,
      },
      {
        text: '契約作成完了',
        isComplete: null,
        isShow: true,
        isCurrent: false,
      },
    ],
    contractId: null,
    contractTotalPrice: null,
  }
}

const state = (): State => getInitialStore()

export default state
