import { FrontStoreInfo } from '~/store/types'

export const getInitialStore = (): FrontStoreInfo => {
  return {
    frontStoreId: {
      estimate: undefined,
      contract: undefined,
    },
    frontStoreFlg: false, // 見積書、契約書を仮保存したかどうか
    frontStoreType: null, // 仮保存した場合の契約区分を格納（1:成人式 2:卒業式 3:参列 10:クリーニング）
  }
}

const state = (): FrontStoreInfo => getInitialStore()

export default state
