import { computed, Ref } from '@nuxtjs/composition-api'
import { EstimateDetail } from '~/api/estimateRepository'
import { DISCOUNT_TYPE } from '~/constants/estimate/config'
import { getDiscountAppliedStatusByType } from '~/utils/utils'

export const useRestateDiscount = (
  estimate: Ref<EstimateDetail | undefined>
) => {
  return computed(() => {
    const discounts = estimate.value?.discounts ?? []

    const waribikiItem = discounts.find(
      ({ discount_type }) => discount_type === DISCOUNT_TYPE.GENERAL.id
    )
    return {
      双子割: getDiscountAppliedStatusByType(discounts, DISCOUNT_TYPE.TWINS.id),
      従業員割: getDiscountAppliedStatusByType(
        discounts,
        DISCOUNT_TYPE.EMPLOYEE.id
      ),
      割引: {
        optionID適用: getDiscountAppliedStatusByType(
          discounts,
          DISCOUNT_TYPE.GENERAL.id
        ),
        値引額: waribikiItem?.amount ?? 0,
      },
    }
  })
}
