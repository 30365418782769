import { MutationTree } from 'vuex'
import { getInitialStore, State } from '~/store/Checklists/Main/state'

/** types */
import type {
  CheckboxState,
  ChecklistCompletion,
} from '~/store/Checklists/Main/state'

/** constants */
import { CHECKLIST_CONTRACT_TYPES } from '~/constants/checklist/contract'

/** helpers */
import {
  recalculateCompletionCheck,
  selectChecklist,
} from '~/features/checklist/helpers'

const mutations: MutationTree<State> = {
  // ** All Input Data **
  setData: (state, data) => {
    state.data = data
  },

  // ** タブ **
  setTabList: (state, tabList) => {
    state.tabList = tabList
  },

  // ** 選択されたタブを設定する **
  setSelectedChecklist: (state, checklistId: number) => {
    const isAttendance = state.data.type === CHECKLIST_CONTRACT_TYPES.ATTENDANCE
    const checklistIndex = state.data.checklistSetting.findIndex(
      (list) =>
        list[isAttendance ? 'estimateId' : 'contractType'] === checklistId
    )

    if (checklistIndex >= 0) {
      // 選択idの更新
      state.selectedChecklistId = checklistId

      const selectedChecklist = state.data.checklistSetting[checklistIndex]
      // チェック完了の計算
      selectedChecklist.checklistCompletion = recalculateCompletionCheck(
        selectedChecklist.checklist,
        selectedChecklist.checklistCompletion
      )
    }
  },

  // ** チェックボックス状態の更新 **
  updateChecklist: (
    state,
    {
      productIndex,
      payload,
    }: { productIndex: number; payload: CheckboxState[] }
  ) => {
    const selectedChecklist = selectChecklist(state)
    if (selectedChecklist) {
      const recordToUpdate = selectedChecklist.checklist[productIndex]

      if (recordToUpdate) {
        recordToUpdate.checkboxInfo = payload.map((state) => ({
          ...state,
        }))

        selectedChecklist.checklistCompletion = recalculateCompletionCheck(
          selectedChecklist.checklist,
          selectedChecklist.checklistCompletion
        )
      }
    }
  },

  updateStaff: (
    state,
    { key, staffId }: { key: keyof ChecklistCompletion; staffId: number | null }
  ) => {
    const selectedChecklist = selectChecklist(state)
    if (selectedChecklist) {
      selectedChecklist.checklistCompletion[key].staffId = staffId
    }
  },

  /** 初期化 */
  initAll: (state) => {
    const initial = getInitialStore()
    state.data = initial.data
    state.tabList = initial.tabList
  },
}

export default mutations
