import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { AxiosInstance, AxiosResponse } from 'axios'
import { ErrorResponse } from '../utils/exception'

export type CheckLoginParams = {
  email: string
  password: string
}

export type CheckSmsCodeParams = {
  code: string
}

export type AuthRepository = {
  checkLogin: (
    params: CheckLoginParams
  ) => Promise<AxiosResponse<{ message: string } | ErrorResponse>>
  checkSmsCode: (
    params: CheckSmsCodeParams
  ) => Promise<AxiosResponse<{ success: string } | ErrorResponse>>
}

const authRepository = (
  client: NuxtAxiosInstance | AxiosInstance
): AuthRepository => {
  return {
    checkLogin: (params) => client.post('login/param/check', params),
    checkSmsCode: (params) => client.post('login/code/check', params),
  }
}

export default authRepository
