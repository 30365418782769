import { GetterTree } from 'vuex'
import { RootState } from '../../../types'
import { CopiedEstimateState } from './state'

const getters: GetterTree<CopiedEstimateState, RootState> = {
  // NOTE: useComingStore用に追加
  getAllState: (state) => {
    return state.estimate
  },

  getIsCopyEstimateFlagForKimono: (state) => {
    return state.isCopyEstimateFlag
  },
}

export default getters
