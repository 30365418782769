import { GetterTree } from 'vuex'
import { RootState, ZoriBagState } from '../../../types'

const getters: GetterTree<ZoriBagState, RootState> = {
  getState: (state: ZoriBagState) => {
    return state
  },
  // NOTE: useComingStore用に追加
  getAllState: (state) => {
    return state
  },
  getSelectionZori: (state: ZoriBagState) => {
    return state.planLineZori
  },
  getSelectionBag: (state: ZoriBagState) => {
    return state.planLineBag
  },
  getProductZori: (state: ZoriBagState) => {
    return state.productZori
  },
  getProductBag: (state: ZoriBagState) => {
    return state.productBag
  },
  getPriceZori: (state: ZoriBagState) => {
    return state.priceZori
  },
  getPriceBag: (state: ZoriBagState) => {
    return state.priceBag
  },
  getInputZori: (state: ZoriBagState) => {
    return state.inputZori
  },
  getInputBag: (state: ZoriBagState) => {
    return state.inputBag
  },
  getIsValidate: (state: ZoriBagState) => {
    return state.isValidate
  },
  getIsPPDiscount: (state: ZoriBagState) => {
    return state.isPPDiscount
  },

  getAllBagState: (state) => {
    const { inputBag, priceBag, productBag, planLineBag } = state
    return { inputBag, priceBag, productBag, planLineBag }
  },
  getAllZoriState: (state) => {
    const { inputZori, priceZori, productZori, planLineZori } = state
    return { inputZori, priceZori, productZori, planLineZori }
  },
}

export default getters
