/**
 * [商品分類]（振袖・二尺袖・袴）
 * NOTE: レンタル・ご購入・ランクアップレンタル・ランクアップご購入は
 * 小物にしか無い概念であり不要のため載せていない
 */
export class ProductCategory {
  /** レンタルか購入か未定状態 */
  static readonly 未確定 = 0
  /** レンタル */
  static readonly レンタル = 1
  /** 購入 */
  static readonly 購入 = 2
  /** お持ち込み */
  static readonly bringIn = 3
  /** お姉様御買上 */
  static readonly purchasedElderSister = 4
  /** ランクアップレンタル */
  static readonly rankupRental = 5
  /** ランクアップ購入 */
  static readonly rankupPurchase = 6
  /** 仕立て上がりレンタル */
  static readonly tailoredRental = 7
  /** オーダーレンタル ※店頭見本として用意されたカタログ商品 */
  static readonly orderRental = 8
  /** お誂えレンタル ※成人式の振袖カタログレンタル */
  static readonly customizeRental = 9
  /** カタログ購入 */
  static readonly catalogPurchase = 10
  /** 仕立上がり購入 */
  static readonly tailoredPurchase = 11
  /** プロパー購入 */
  static readonly properPurchase = 12
  /** プロパーレンタル */
  static readonly properRental = 13
  /** カタログレンタル ※卒業式のその他着物カタログレンタル */
  static readonly catalogRental = 15
  /** JK前々撮り */
  static readonly jkPhotoInAdvance = 16
  /** お持ち込み（二親等以内の親族以外） */
  static readonly purchasedDistantRelative = 17

  /**
   * 商品分類の値から商品分類名を返却する
   * @param val 商品分類の値
   * @returns 商品分類名
   */
  static toWord = (val: number | null) => {
    switch (val) {
      case ProductCategory.レンタル:
        return 'レンタル'
      case ProductCategory.購入:
        return '購入'
      case ProductCategory.bringIn:
        return 'お持込'
      case ProductCategory.purchasedElderSister:
        return 'お姉様御買上'
      case ProductCategory.rankupRental:
        return 'ランクアップレンタル'
      case ProductCategory.rankupPurchase:
        return 'ランクアップ購入'
      case ProductCategory.tailoredRental:
        return '仕立て上がりレンタル'
      case ProductCategory.orderRental:
        return 'オーダーレンタル'
      case ProductCategory.customizeRental:
        return 'お誂えレンタル'
      case ProductCategory.catalogPurchase:
        return 'カタログ購入'
      case ProductCategory.tailoredPurchase:
        return '仕立上がり購入'
      case ProductCategory.properPurchase:
        return 'プロパー購入'
      case ProductCategory.properRental:
        return 'プロパーレンタル'
      case ProductCategory.catalogRental:
        return 'カタログレンタル'
      case ProductCategory.jkPhotoInAdvance:
        return 'JK前々撮り'
      case ProductCategory.purchasedDistantRelative:
        return 'お持ち込み（二親等以内の親族以外）'
      default:
        return 'ー'
    }
  }

  /**
   * 振袖が購入されているかを判定する
   * @param val 商品分類の値
   * @returns 購入かどうか
   */
  static isPurchase = (val: number) =>
    [
      ProductCategory.購入,
      ProductCategory.rankupPurchase,
      ProductCategory.tailoredPurchase,
      ProductCategory.catalogPurchase,
      ProductCategory.properPurchase,
    ].includes(val)

  static isBringin = (val?: number) => {
    if (!val) return false

    return [
      ProductCategory.bringIn,
      ProductCategory.purchasedElderSister,
      ProductCategory.purchasedDistantRelative,
    ].includes(val)
  }

  // カタログ商品かの真偽値
  static isCatalog = (val?: number) => {
    if (!val) return false

    return [
      ProductCategory.orderRental,
      ProductCategory.customizeRental,
      ProductCategory.catalogPurchase,
      ProductCategory.catalogRental,
    ].includes(val)
  }

  // 仕立て上り商品かの真偽値
  static isFinished = (val?: number) => {
    if (!val) return false

    return [
      ProductCategory.tailoredRental,
      ProductCategory.tailoredPurchase,
    ].includes(val)
  }

  // プロパー商品かの真偽値
  static isProper = (val?: number) => {
    if (!val) return false

    return [
      ProductCategory.properPurchase,
      ProductCategory.properRental,
    ].includes(val)
  }

  /**
   * 振袖がレンタルされているかを判定する
   * @param val 商品分類の値
   * @returns レンタルかどうか
   */
  static isRental = (val?: number) => {
    return [
      ProductCategory.レンタル,
      ProductCategory.rankupRental,
      ProductCategory.tailoredRental,
      ProductCategory.orderRental,
      ProductCategory.customizeRental,
      ProductCategory.properRental,
      ProductCategory.catalogRental,
    ].includes(val ?? 0)
  }
}

export type Category =
  | typeof ProductCategory.bringIn
  | typeof ProductCategory.purchasedElderSister
  | typeof ProductCategory.tailoredRental
  | typeof ProductCategory.orderRental
  | typeof ProductCategory.customizeRental
  | typeof ProductCategory.catalogPurchase
  | typeof ProductCategory.tailoredPurchase
  | typeof ProductCategory.properPurchase
  | typeof ProductCategory.properRental
  | typeof ProductCategory.catalogRental
  | typeof ProductCategory.jkPhotoInAdvance

/**
 * 着物商品価格一覧取得APIで使用
 */
export class KimonoProductType {
  /** レンタルか購入か未定状態 */
  static readonly shitateagari = 1
  /** お持ち込み */
  static readonly proper = 2
}

export class KimonoPriceType {
  /** レンタル */
  static readonly rental = 1
  /** 購入 */
  static readonly purchase = 2
}
