







import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  data() {
    return {
      width: 16,
      height: 16,
    }
  },
})
