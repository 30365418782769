import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { ObiState } from '~/store/types'

export const useRestoreWPhoto別衿 = () => {
  const storeお持込 = useComingStore<ObiState>('WphotoPlan/WphotoBetsueriBring')
  const storeレンタル = useComingStore<ObiState>(
    'WphotoPlan/WphotoBetsueriRental'
  )

  const restoreWPhoto別衿 = (betsueri: Restate['w_photo_plan']['別衿']) => {
    if (!betsueri) return
    /* 初期値を設定 ※必ず入力される値はここで設置 */
    const formValues: ObiState = {
      /**
       * NOTE: selections = patternLine
       * ↓保持される選択肢のindex
       * [0]種別
       * [1]長襦袢
       * [2]形態 || 見積表示
       */
      product: null,
      selections: [betsueri.optionId種別, 0, 0],
      input: {
        productName: '',
        buyPrice: 0,
        rentalPrice: 0,
        rankUpRentalPrice: 0,
        rankUpBuyPrice: 0,
        premiumRentalPrice: 0,
        premiumBuyPrice: 0,
      },
      // NOTE: フリー入力された値はpricesではなくinputの方に格納される
      price: {
        buy: 0,
        rental: 0,
        id: 0,
      },
      isFree: false,
      isValidate: null,
    }

    /* 以下で入力内容の条件に基づき、必要な項目を埋めていく */
    if (
      (!betsueri.flags.is振袖持込 && betsueri.flags.is持込) ||
      betsueri.flags.is振袖持込
    ) {
      formValues.selections[1] = betsueri.optionId長襦袢
    } else {
      formValues.selections[1] = betsueri.optionId形態
    }

    if (!betsueri.flags.is持込 && betsueri.flags.is振袖持込) {
      formValues.selections[1] = betsueri.optionId長襦袢
      formValues.selections[2] = betsueri.optionId見積選択
      formValues.input.rentalPrice = betsueri.レンタル価格
      formValues.input.buyPrice = betsueri.購入価格
    }

    if (betsueri.flags.isセット内) {
      formValues.price.rental = betsueri.レンタル価格
      formValues.input.rentalPrice = betsueri.レンタル価格
    }

    if (betsueri.flags.isランクアップPP内) {
      formValues.isFree = true
      formValues.input.rankUpRentalPrice = betsueri.レンタル価格
    }

    if (betsueri.flags.isランクアップPP外) {
      formValues.input.rankUpRentalPrice = betsueri.レンタル価格
      formValues.input.premiumRentalPrice = betsueri.プレミアムレンタル価格
    }

    /* ストアに内容を保存する */
    if (betsueri.flags.is振袖持込) {
      storeお持込.setOf<ObiState['selections']>(
        'setSelection',
        formValues.selections
      )
      storeお持込.setOf<ObiState['input']>('setInput', formValues.input)
      storeお持込.setOf<ObiState['price']>('setPrice', formValues.price)
      storeお持込.setOf<ObiState['isFree']>('setIsFree', formValues.isFree)
    } else {
      storeレンタル.setOf<ObiState['selections']>(
        'setSelection',
        formValues.selections
      )
      storeレンタル.setOf<ObiState['input']>('setInput', formValues.input)
      storeレンタル.setOf<ObiState['price']>('setPrice', formValues.price)
      storeレンタル.setOf<ObiState['isFree']>('setIsFree', formValues.isFree)
    }
    storeお持込.setIsValidate(false)
    storeレンタル.setIsValidate(false)
  }

  return { restoreWPhoto別衿 }
}
