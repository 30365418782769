import { computed, Ref } from '@nuxtjs/composition-api'
import { EstimateState } from '~/composable/estimate/repository/comingOfAge/EstimateState'
import { useSelectedPlan } from '~/composable/estimate/domain/ComingOfAge/useSelectedPlan'

export const usePlanFormatter = (estimateState: Ref<EstimateState>) => {
  const { selectedPlan } = useSelectedPlan()

  return computed(() => {
    const { プラン, 見積一覧 } = estimateState.value
    const plan = selectedPlan()
    return getPlan(プラン, 見積一覧, plan)
  })
}
export const getPlan = (
  valuesプラン: EstimateState['プラン'],
  values見積一覧: EstimateState['見積一覧'],
  plan: number | null
) => {
  return {
    plan,
    useDate: valuesプラン.成人式使用日 || null,
    contractServiceType: valuesプラン.サービス区分,
    selectedPlan: values見積一覧.selectedプラン列,
    contractTotalPrice: values見積一覧.金額.総合計,
  }
}
