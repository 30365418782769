// TODO: API出来次第ファイルごと削除
import ProductClassification from '~/constants/enums/productClassification'
import { ClothingSizeType } from '~/constants/enums/clothingSizeType'
import { SingleItemPlan } from '~/constants/enums/singleItemPlan'

const 帯 = [
  {
    plan_id: SingleItemPlan.仕立て上がり, // 検索フォームとして表示
    product_type_id: ProductClassification.obi,
    products: [
      {
        product_id: null,
        product_name: null,
        enable_rental: true,
        enable_purchase: false,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 51700,
                purchase_price: null,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    plan_id: SingleItemPlan.新品,
    product_type_id: ProductClassification.obi,
    products: [
      {
        product_id: null,
        product_name: null, // フリー入力
        enable_rental: true,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              // 複数件存在するので、価格ボタン（選択肢）として表示（フリー入力ボタンも表示）
              {
                order_number: 1,
                rental_price: 51700,
                purchase_price: 55000,
              },
              {
                order_number: 2,
                rental_price: 62700,
                purchase_price: 66000,
              },
              {
                order_number: 3,
                rental_price: 73700,
                purchase_price: 77000,
              },
              {
                order_number: 4,
                rental_price: 84700,
                purchase_price: 88000,
              },
              {
                order_number: 5,
                rental_price: 95700,
                purchase_price: 99000,
              },
              {
                order_number: 6,
                rental_price: 106700,
                purchase_price: 110000,
              },
            ],
          },
        ],
      },
    ],
  },
] as const

const 帯締め = [
  {
    plan_id: SingleItemPlan.セット内,
    product_type_id: ProductClassification.obijime,
    products: [
      {
        product_id: null,
        product_name: null, // フリー入力
        enable_rental: true,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 5500,
                purchase_price: 8800,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    plan_id: SingleItemPlan.ランクアップ,
    product_type_id: ProductClassification.obijime,
    products: [
      {
        product_id: null,
        product_name: null, // フリー入力
        enable_rental: true,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              // 複数件存在するので、価格ボタン（選択肢）として表示（フリー入力ボタンも表示）
              {
                order_number: 1,
                rental_price: 7700,
                purchase_price: 11000,
              },
              {
                order_number: 2,
                rental_price: 13200,
                purchase_price: 16500,
              },
            ],
          },
        ],
      },
    ],
  },
] as const

const 帯揚げ = [
  {
    plan_id: SingleItemPlan.セット内,
    product_type_id: ProductClassification.obiage,
    products: [
      {
        product_id: 500209603,
        product_name: 'No.1 黒',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 5500,
                purchase_price: 8800,
              },
            ],
          },
        ],
      },
      {
        product_id: 500209604,
        product_name: 'No.2 白', // データ上存在しない（2023年4月25日現在）
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 5500,
                purchase_price: 8800,
              },
            ],
          },
        ],
      },
      {
        product_id: 500209605,
        product_name: 'No.3 赤',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 5500,
                purchase_price: 8800,
              },
            ],
          },
        ],
      },
      {
        product_id: 500209606,
        product_name: 'No.7 エンジ',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 5500,
                purchase_price: 8800,
              },
            ],
          },
        ],
      },
      {
        product_id: 500209607,
        product_name: 'No.8 緑',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 5500,
                purchase_price: 8800,
              },
            ],
          },
        ],
      },
      {
        product_id: 500209608,
        product_name: 'No.9 オフホワイト',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 5500,
                purchase_price: 8800,
              },
            ],
          },
        ],
      },
      {
        product_id: 500209609,
        product_name: 'No.10 くすみピンク',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 5500,
                purchase_price: 8800,
              },
            ],
          },
        ],
      },
      {
        product_id: 500209610,
        product_name: 'No.11 辛子',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 5500,
                purchase_price: 8800,
              },
            ],
          },
        ],
      },
      {
        product_id: 500209611,
        product_name: 'No.12 赤紫',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 5500,
                purchase_price: 8800,
              },
            ],
          },
        ],
      },
      {
        product_id: 500209612,
        product_name: 'No.13 抹茶',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 5500,
                purchase_price: 8800,
              },
            ],
          },
        ],
      },
      {
        product_id: 500343694,
        product_name: 'No.14 茶色',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 5500,
                purchase_price: 8800,
              },
            ],
          },
        ],
      },
      {
        product_id: 500343695,
        product_name: 'No.15 ベージュ',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 5500,
                purchase_price: 8800,
              },
            ],
          },
        ],
      },
      {
        product_id: 500265087,
        product_name: 'No.101 赤',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 5500,
                purchase_price: 8800,
              },
            ],
          },
        ],
      },
      {
        product_id: 500265088,
        product_name: 'No.102 エンジ',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 5500,
                purchase_price: 8800,
              },
            ],
          },
        ],
      },
      {
        product_id: 500265089,
        product_name: 'No.103 くすみピンク',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 5500,
                purchase_price: 8800,
              },
            ],
          },
        ],
      },
      {
        product_id: 500265090,
        product_name: 'No.104 黄色',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 5500,
                purchase_price: 8800,
              },
            ],
          },
        ],
      },
      {
        product_id: 500265091,
        product_name: 'No.105 抹茶',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 5500,
                purchase_price: 8800,
              },
            ],
          },
        ],
      },
      {
        product_id: 500265092,
        product_name: 'No.106 緑',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 5500,
                purchase_price: 8800,
              },
            ],
          },
        ],
      },
      {
        product_id: 500265093,
        product_name: 'No.107 紺',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 5500,
                purchase_price: 8800,
              },
            ],
          },
        ],
      },
      {
        product_id: 500265094,
        product_name: 'No.108 グレー',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 5500,
                purchase_price: 8800,
              },
            ],
          },
        ],
      },
      {
        product_id: 500265095,
        product_name: 'No.109 モカ',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 5500,
                purchase_price: 8800,
              },
            ],
          },
        ],
      },
      {
        product_id: 500265096,
        product_name: 'No.110 黒',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 5500,
                purchase_price: 8800,
              },
            ],
          },
        ],
      },
      {
        product_id: null,
        product_name: null, // 現品
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 5500,
                purchase_price: 8800,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    plan_id: SingleItemPlan.ランクアップ,
    product_type_id: ProductClassification.obiage,
    products: [
      {
        product_id: null,
        product_name: null, // フリー入力
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              // 複数件存在するので、価格ボタン（選択肢）として表示（フリー入力ボタンも表示）
              {
                order_number: 1,
                rental_price: 7700,
                purchase_price: 11000,
              },
              {
                order_number: 2,
                rental_price: 13200,
                purchase_price: 16500,
              },
            ],
          },
        ],
      },
    ],
  },
] as const

const 重ね衿 = [
  {
    plan_id: SingleItemPlan.セット内,
    product_type_id: ProductClassification.kasaneeri,
    products: [
      {
        product_id: null,
        product_name: null, // フリー入力
        enable_rental: true,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 5500,
                purchase_price: 8800,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    plan_id: SingleItemPlan.ランクアップ,
    product_type_id: ProductClassification.kasaneeri,
    products: [
      {
        product_id: null,
        product_name: null, // フリー入力
        enable_rental: true,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              // 複数件存在するので、価格ボタン（選択肢）として表示（フリー入力ボタンも表示）
              {
                order_number: 1,
                rental_price: 5500,
                purchase_price: 0,
              },
              {
                order_number: 2,
                rental_price: 7700,
                purchase_price: 11000,
              },
              {
                order_number: 3,
                rental_price: 13200,
                purchase_price: 16500,
              },
            ],
          },
        ],
      },
    ],
  },
] as const

const 別衿 = [
  {
    plan_id: null,
    product_type_id: ProductClassification.betueri,
    products: [
      {
        product_id: null,
        product_name: null, // フリー入力
        enable_rental: true,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 7700,
                purchase_price: 11000,
              },
              {
                order_number: 2,
                rental_price: 13200,
                purchase_price: 16500,
              },
            ],
          },
        ],
      },
    ],
  },
] as const

const ショール = [
  {
    plan_id: null,
    product_type_id: ProductClassification.shawl,
    products: [
      {
        product_id: 500085494,
        product_name: '白',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 7700,
                purchase_price: 11000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500018281,
        product_name: 'ピンク',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 7700,
                purchase_price: 11000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500050359,
        product_name: '5連FOX',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 51700,
                purchase_price: 55000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500085562,
        product_name: 'ブルーFOX',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 51700,
                purchase_price: 55000,
              },
            ],
          },
        ],
      },
      {
        product_id: null,
        product_name: null, // 現品
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 51700,
                purchase_price: 55000,
              },
            ],
          },
        ],
      },
    ],
  },
] as const

// 全ての商品でproduct_idはDB上存在しない（2023年4月25日現在）
// 仮でproduct_idを入れる（2023年5月1日現在）
const 草履バッグ = [
  {
    plan_id: null,
    product_type_id: ProductClassification.zoriAndBag,
    products: [
      {
        product_id: 500000001,
        product_name: 'U-1',
        enable_rental: false,
        enable_purchase: true,
        delivery_period: 60,
        price_informations: [
          {
            product_size_id: ClothingSizeType.S,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.M,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.L,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.LL,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.LLL,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
        ],
      },
      {
        product_id: 500000002,
        product_name: 'U-2',
        enable_rental: false,
        enable_purchase: true,
        delivery_period: 60,
        price_informations: [
          {
            product_size_id: ClothingSizeType.S,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.M,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.L,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.LL,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.LLL,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
        ],
      },
      {
        product_id: 500000003,
        product_name: 'U-4',
        enable_rental: false,
        enable_purchase: true,
        delivery_period: 60,
        price_informations: [
          {
            product_size_id: ClothingSizeType.S,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.M,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.L,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.LL,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.LLL,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
        ],
      },
      {
        product_id: 500000004,
        product_name: 'U-7',
        enable_rental: false,
        enable_purchase: true,
        delivery_period: 60,
        price_informations: [
          {
            product_size_id: ClothingSizeType.S,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.M,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.L,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.LL,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.LLL,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
        ],
      },
      {
        product_id: 500000005,
        product_name: 'U-10',
        enable_rental: false,
        enable_purchase: true,
        delivery_period: 60,
        price_informations: [
          {
            product_size_id: ClothingSizeType.S,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.M,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.L,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.LL,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.LLL,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
        ],
      },
      {
        product_id: 500000006,
        product_name: 'U-11',
        enable_rental: false,
        enable_purchase: true,
        delivery_period: 60,
        price_informations: [
          {
            product_size_id: ClothingSizeType.S,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.M,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.L,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.LL,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.LLL,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 30800,
              },
            ],
          },
        ],
      },
      {
        product_id: null,
        product_name: 'こちらでご準備いたします（取り寄せ）',
        enable_rental: true,
        enable_purchase: false,
        delivery_period: 60,
        price_informations: [
          {
            product_size_id: ClothingSizeType.S,
            prices: [
              {
                order_number: 1,
                rental_price: 27500,
                purchase_price: null,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.M,
            prices: [
              {
                order_number: 1,
                rental_price: 27500,
                purchase_price: null,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.L,
            prices: [
              {
                order_number: 1,
                rental_price: 27500,
                purchase_price: null,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.LL,
            prices: [
              {
                order_number: 1,
                rental_price: 27500,
                purchase_price: null,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.LLL,
            prices: [
              {
                order_number: 1,
                rental_price: 27500,
                purchase_price: null,
              },
            ],
          },
        ],
      },
      {
        product_id: null,
        product_name: null,
        enable_rental: true,
        enable_purchase: false,
        delivery_period: 60,
        price_informations: [
          {
            product_size_id: ClothingSizeType.S,
            prices: [
              {
                order_number: 1,
                rental_price: 27500,
                purchase_price: null,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.M,
            prices: [
              {
                order_number: 1,
                rental_price: 27500,
                purchase_price: null,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.L,
            prices: [
              {
                order_number: 1,
                rental_price: 27500,
                purchase_price: null,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.LL,
            prices: [
              {
                order_number: 1,
                rental_price: 27500,
                purchase_price: null,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.LLL,
            prices: [
              {
                order_number: 1,
                rental_price: 27500,
                purchase_price: null,
              },
            ],
          },
        ],
      },
    ],
  },
] as const

const 草履 = [
  {
    plan_id: null,
    product_type_id: ProductClassification.zori,
    products: [
      {
        product_id: 500083073,
        product_name: 'RZ-01 桜刺繍ヒール草履細太鼻緒(赤)',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500083074,
        product_name: 'RZ-02 椿刺繍草履(黒)',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500083075,
        product_name: 'RZ-03 ボタン刺繍シール草履(薄ピ)',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500083078,
        product_name: 'RZ-06 椿刺繍ストライプ草履(赤台)',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500083081,
        product_name: 'RZ-09 別珍鼻緒パール草履(ボルドー)',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500166522,
        product_name: 'UZ-1 グレーレース',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500166523,
        product_name: 'UZ-2 ベージュレース',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500170013,
        product_name: 'RZ-11 鼻緒白刺繍ベロアボーダー（赤）',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500170014,
        product_name: 'RZ-12 鼻緒白刺繍ベロアボーダー（黒）',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500258285,
        product_name: 'RZ-13 松竹梅草履（赤）',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500258286,
        product_name: 'RZ-14 松竹梅草履（白）',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500258287,
        product_name: 'RZ-15 ビジュー草履',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
      {
        product_id: null,
        product_name: null, // フリー入力
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
    ],
  },
] as const

const バッグ = [
  {
    plan_id: null,
    product_type_id: ProductClassification.bag,
    products: [
      {
        product_id: 500083086,
        product_name: 'RB-01 大椿刺繍ガマグチバッグ(黒)',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500083087,
        product_name: 'RB-02 大椿刺繍ガマグチバッグ(ターコイズ)',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500083095,
        product_name: 'RB-06 ツマミ付きガマグチバッグ(赤)',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500168632,
        product_name: 'UB-1 グレーレース×ブルー取手',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500168633,
        product_name: 'UB-2 ベージュレース×ピンク取手',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500204248,
        product_name: 'RB-08 白黒椿刺繍',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500204249,
        product_name: 'RB-09 梅刺繍バック（赤）',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500204250,
        product_name: 'RB-10 梅刺繡バック（白）',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500204251,
        product_name: 'RB-11 七宝桜刺繍バック（白）',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500204252,
        product_name: 'RB-12 七宝桜刺繍バック（黒）',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500334439,
        product_name: 'RB-13 ジャガードバック（白）',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500334440,
        product_name: 'RB-14 ジャガードバック（黒）',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500334441,
        product_name: 'RB-15 ジャガードバック（ベージュ）',
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
      {
        product_id: null,
        product_name: null,
        enable_rental: true,
        enable_purchase: true,
        delivery_period: 15,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 16500,
                purchase_price: 22000,
              },
            ],
          },
        ],
      },
    ],
  },
] as const

const 髪飾り = [
  {
    plan_id: null,
    product_type_id: ProductClassification.kamikazari,
    products: [
      {
        product_id: null,
        product_name: null, // フリー入力
        enable_rental: false,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: null, // フリー入力
              },
            ],
          },
        ],
      },
    ],
  },
] as const

// 仮でproduct_idを入れる（2023年5月9日現在）
const 長襦袢購入お誂え正絹 = [
  {
    plan_id: null,
    product_type_id: ProductClassification.nagajyubanPurchase,
    products: [
      {
        product_id: 500001001,
        product_name: '振袖用（ピンク）',
        enable_rental: false,
        enable_purchase: true,
        delivery_period: 50,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 99000,
              },
            ],
          },
        ],
      },
      {
        product_id: 500001002,
        product_name: '留袖用（白）',
        enable_rental: false,
        enable_purchase: true,
        delivery_period: 50,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 99000,
              },
            ],
          },
        ],
      },
    ],
  },
] as const

// 仮でproduct_idを入れる（2023年5月9日現在）
const 長襦袢レンタル仕立て上がり交織 = [
  {
    plan_id: null,
    product_type_id: ProductClassification.nagajyubanRental,
    products: [
      {
        product_id: 500001003,
        product_name: '振袖用（ピンク）',
        enable_rental: true,
        enable_purchase: false,
        delivery_period: 20,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 22000,
                purchase_price: null,
              },
            ],
          },
        ],
      },
      {
        product_id: 500001004,
        product_name: '二尺袖用（ピンク）',
        enable_rental: true,
        enable_purchase: false,
        delivery_period: 20,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 11000,
                purchase_price: null,
              },
            ],
          },
        ],
      },
      {
        product_id: 500001005,
        product_name: '訪問着用（ピンク）',
        enable_rental: true,
        enable_purchase: false,
        delivery_period: 20,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 22000,
                purchase_price: null,
              },
            ],
          },
        ],
      },
      {
        product_id: 500001006,
        product_name: '留袖用（白）',
        enable_rental: true,
        enable_purchase: false,
        delivery_period: 20,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: 22000,
                purchase_price: null,
              },
            ],
          },
        ],
      },
    ],
  },
] as const

const 呉服箱 = [
  {
    plan_id: null,
    product_type_id: ProductClassification.gohukubako,
    products: [
      {
        product_id: null,
        product_name: null,
        enable_rental: false,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 1100,
              },
            ],
          },
        ],
      },
    ],
  },
] as const

// 仮でproduct_idを入れる（2023年5月9日現在）
const 留袖 = [
  {
    plan_id: null,
    product_type_id: ProductClassification.tomesode,
    products: [
      {
        product_id: 50001001,
        product_name: '一式（着物/長襦袢/帯揚げ/帯締め/草履/バック/末広)',
        enable_rental: false,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: null, // フリー入力
              },
            ],
          },
        ],
      },
      {
        product_id: 50001002,
        product_name: '草履バックセット',
        enable_rental: true,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: ClothingSizeType.M,
            prices: [
              {
                order_number: 1,
                rental_price: 38500,
                purchase_price: 41800,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.L,
            prices: [
              {
                order_number: 1,
                rental_price: 38500,
                purchase_price: 41800,
              },
            ],
          },
        ],
      },
      {
        product_id: 50001003,
        product_name: '3点セット（帯締め/帯揚げ/末広）',
        enable_rental: false,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 8800,
              },
            ],
          },
        ],
      },
    ],
  },
] as const

// 仮でproduct_idを入れる（2023年5月9日現在）
const 喪服 = [
  {
    plan_id: null,
    product_type_id: ProductClassification.mohuku,
    products: [
      {
        product_id: 50010001,
        product_name:
          '一式(着物/長襦袢/帯/帯揚げ/帯締め/草履バッグ/着付け小物)',
        enable_rental: false,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 173800,
              },
            ],
          },
        ],
      },
    ],
  },
] as const

const その他 = [
  {
    plan_id: null,
    product_type_id: ProductClassification.otherItem,
    products: [
      {
        product_id: null,
        product_name: null, // フリー入力
        enable_rental: true,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: null, // フリー入力
              },
            ],
          },
        ],
      },
    ],
  },
] as const

// NOTE: ここから着付小物

const 帯枕 = [
  {
    plan_id: null,
    product_type_id: ProductClassification.obimakura,
    products: [
      {
        product_id: null,
        product_name: '帯枕',
        enable_rental: false,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              { order_number: 1, rental_price: null, purchase_price: 1100 },
            ],
          },
        ],
      },
    ],
  },
] as const

const 帯板 = [
  {
    plan_id: null,
    product_type_id: ProductClassification.obiita,
    products: [
      {
        product_id: null,
        product_name: '帯板（大サイズ）',
        enable_rental: false,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              { order_number: 1, rental_price: null, purchase_price: 1100 },
            ],
          },
        ],
      },
      {
        product_id: null,
        product_name: '帯板（中サイズ）',
        enable_rental: false,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              { order_number: 1, rental_price: null, purchase_price: 1100 },
            ],
          },
        ],
      },
      {
        product_id: null,
        product_name: '帯板（小サイズ）',
        enable_rental: false,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              { order_number: 1, rental_price: null, purchase_price: 1100 },
            ],
          },
        ],
      },
    ],
  },
] as const

const 衿芯 = [
  {
    plan_id: null,
    product_type_id: ProductClassification.erisin,
    products: [
      {
        product_id: null,
        product_name: '衿芯',
        enable_rental: false,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              { order_number: 1, rental_price: null, purchase_price: 1100 },
            ],
          },
        ],
      },
    ],
  },
] as const

const 伊達〆 = [
  {
    plan_id: null,
    product_type_id: ProductClassification.datejime,
    products: [
      {
        product_id: null,
        product_name: '伊達〆',
        enable_rental: false,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: ClothingSizeType.N,
            prices: [
              { order_number: 1, rental_price: null, purchase_price: 1100 },
            ],
          },
          {
            product_size_id: ClothingSizeType.B,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 1100,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.BB,
            prices: [
              { order_number: 1, rental_price: null, purchase_price: 1100 },
            ],
          },
        ],
      },
    ],
  },
] as const

const コーリンベルト = [
  {
    plan_id: null,
    product_type_id: ProductClassification.korinBelt,
    products: [
      {
        product_id: null,
        product_name: 'コーリンベルト',
        enable_rental: false,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: ClothingSizeType.N,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 1100,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.B,
            prices: [
              { order_number: 1, rental_price: null, purchase_price: 1100 },
            ],
          },
        ],
      },
    ],
  },
] as const

const 三重紐 = [
  {
    plan_id: null,
    product_type_id: ProductClassification.sanjyuhimo,
    products: [
      {
        product_id: null,
        product_name: '三重紐',
        enable_rental: false,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              { order_number: 1, rental_price: null, purchase_price: 1100 },
            ],
          },
        ],
      },
    ],
  },
] as const

const 腰紐 = [
  {
    plan_id: null,
    product_type_id: ProductClassification.kosihimo,
    products: [
      {
        product_id: null,
        product_name: '腰紐（5本セット）',
        enable_rental: false,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: ClothingSizeType.N,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 1100,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.B,
            prices: [
              { order_number: 1, rental_price: null, purchase_price: 3300 },
            ],
          },
        ],
      },
    ],
  },
] as const

const 肌着 = [
  {
    plan_id: null,
    product_type_id: ProductClassification.hadagi,
    products: [
      {
        product_id: null,
        product_name: '肌着',
        enable_rental: false,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: ClothingSizeType.M,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 3300,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.L,
            prices: [
              { order_number: 1, rental_price: null, purchase_price: 3300 },
            ],
          },
          {
            product_size_id: ClothingSizeType.LL,
            prices: [
              { order_number: 1, rental_price: null, purchase_price: 3300 },
            ],
          },
        ],
      },
    ],
  },
] as const

const 足袋 = [
  {
    plan_id: null,
    product_type_id: ProductClassification.tabi,
    products: [
      {
        product_id: null,
        product_name: '足袋（白レース）',
        enable_rental: false,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: ClothingSizeType.S,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 2200,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.M,
            prices: [
              { order_number: 1, rental_price: null, purchase_price: 2200 },
            ],
          },
          {
            product_size_id: ClothingSizeType.L,
            prices: [
              { order_number: 1, rental_price: null, purchase_price: 2200 },
            ],
          },
          {
            product_size_id: ClothingSizeType.LL,
            prices: [
              {
                order_number: 1,
                rental_price: null,
                purchase_price: 2200,
              },
            ],
          },
          {
            product_size_id: ClothingSizeType.LLL,
            prices: [
              { order_number: 1, rental_price: null, purchase_price: 2200 },
            ],
          },
        ],
      },
      {
        product_id: null,
        product_name: '足袋（コハゼ付き）',
        enable_rental: false,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              { order_number: 1, rental_price: null, purchase_price: 2200 },
            ],
          },
        ],
      },
      {
        product_id: null,
        product_name: '足袋（白ストレッチ）',
        enable_rental: false,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              { order_number: 1, rental_price: null, purchase_price: 2200 },
            ],
          },
        ],
      },
    ],
  },
] as const

const タオル = [
  {
    plan_id: null,
    product_type_id: ProductClassification.towel,
    products: [
      {
        product_id: null,
        product_name: 'タオル(5枚セット）',
        enable_rental: false,
        enable_purchase: true,
        price_informations: [
          {
            product_size_id: null,
            prices: [
              { order_number: 1, rental_price: null, purchase_price: 1100 },
            ],
          },
        ],
      },
    ],
  },
] as const

export const 着付小物 = [
  ...帯板,
  ...伊達〆,
  ...腰紐,
  ...衿芯,
  ...コーリンベルト,
  ...肌着,
  ...タオル,
  ...足袋,
  ...帯枕,
  ...三重紐,
] as const

export const SINGLE_ITEMS = [
  ...帯,
  ...帯締め,
  ...帯揚げ,
  ...重ね衿,
  ...別衿,
  ...ショール,
  ...草履バッグ,
  ...草履,
  ...バッグ,
  ...髪飾り,
  ...長襦袢購入お誂え正絹,
  ...長襦袢レンタル仕立て上がり交織,
  ...呉服箱,
  ...留袖,
  ...喪服,
  ...その他,
  ...着付小物,
] as const
