















import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  useRoute,
} from '@nuxtjs/composition-api'
import { prohibitExpansion, reloadEvent } from '@/utils/screenControl'
import { useTemporarilySaved } from '~/composable/api/useTemporarilySaved'
import { ESTIMATE } from '~/constants/estimate/config'
import { useEstimateRestorer } from '~/composable/estimate/service/comingOfAge/EstimateRestorer'
import { useAuth } from '~/composable/api/useAuth'
import { useOnline } from '~/composable/general/useOnline'

export default defineComponent({
  name: 'comingOfAgeCeremony',
  setup() {
    const online = useOnline()
    const route = useRoute()
    const { saveEstimateTemporarily } = useTemporarilySaved()
    const { restoreEstimate } = useEstimateRestorer()
    const { validateSession } = useAuth()
    const url = computed(() => route.value.path)

    const typeId = ESTIMATE.成人式.id

    // 保存ボタン押下時に発火
    const save = () => {
      saveEstimateTemporarily(typeId)
    }

    const { addReloadEvent, removeReloadEvent } = reloadEvent()

    onMounted(() => {
      validateSession()
      prohibitExpansion()
      addReloadEvent()

      // NOTE: 別見積を作成する時に見積を復元する処理
      // タブの中を移動している間は取得処理が破棄されることがないようにlayout層に設置している
      const query = route.value.query
      if (query.mode === 'copy' && query.estimateId && query.branch) {
        restoreEstimate(
          Number(query.estimateId),
          Number(query.branch),
          undefined,
          Number(query.contract_id)
        )
      }
    })

    onBeforeUnmount(() => {
      removeReloadEvent()
    })

    return { save, typeId, url }
  },
})
