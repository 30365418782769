import { computed, ref, Ref, watch } from '@nuxtjs/composition-api'
import { EstimateDetail, Hurisode } from '~/api/estimateRepository'
import { usePrice } from '~/composable/general/Price'
import { Enum見積振袖DiscountType } from '~/constants/enums/estimateDetail'
import {
  Enumプラン,
  Enum振袖二尺袖,
} from '~/constants/estimate/graduationOptions'
import {
  CatalogOtherKimono,
  KimonoSearchQueryParams,
  useSearchProduct,
} from '~/composable/api/useSearchProduct'
import { ProductCategory } from '~/constants/enums/productCategory'
import ProductClassification from '~/constants/enums/productClassification'
import { CatalogOtherTypeResult, FinishedTypeResult } from '~/store/types'

export const useRestateHurisode = (
  estimate: Ref<EstimateDetail | undefined>
) => {
  const { paramToString, getDiscounted, toString } = usePrice()
  const { fetchProducts, catalogOtherKimono, finishedKimonoList } =
    useSearchedProducts(estimate)

  // NOTE: computed内で取得処理をしようとすると無限ループが起きる
  watch(estimate, () => {
    if (!estimate.value?.hurisode) return
    fetchProducts(estimate.value.hurisode)
  })

  /*
   * NOTE: カタログ商品のproduct_idはカタログ商品の検索結果に載っているものではなく、
   * 契約時に仕立て上がりとして発番されたidを受渡する必要があるため、
   * 復元時には見積詳細に載っている方のidで上書きする。(未契約の見積詳細にはnullが返ってくる)
   */
  const formattedCatalogKimono = ref<CatalogOtherKimono | null>(null)
  watch(catalogOtherKimono, () => {
    if (!catalogOtherKimono.value) {
      return
    }
    formattedCatalogKimono.value = {
      ...catalogOtherKimono.value,
      product_id: estimate.value?.hurisode?.product_id,
    }
  })

  return computed(() => {
    if (!estimate.value?.hurisode) return null
    const { hurisode } = estimate.value

    const { product_type_id, rental_price } = hurisode
    const discountedby80Off = getDiscounted(paramToString(rental_price), 20)

    return {
      optionId裄補正: getOptionIdOf補正s(hurisode).裄補正,
      optionId袖補正: getOptionIdOf補正s(hurisode).袖補正,
      optionId誂えるか: getOptionIdOf誂えるか(catalogOtherKimono),
      商品分類: product_type_id,
      仕立て上り検索結果: {
        type: 'finished',
        ...finishedKimonoList.value[0],
      } as FinishedTypeResult,
      カタログ検索結果: formattedCatalogKimono.value
        ? ({
            type: 'catalog_other',
            ...formattedCatalogKimono.value,
          } as CatalogOtherTypeResult)
        : null,
      レンタル価格: paramToString(rental_price),
      値引き後価格: toString(discountedby80Off),
      flags: getConditionalFlags(hurisode),
    }
  })
}

/**
 * 復元条件に関わる条件式を定義する
 */
const getConditionalFlags = (hurisode: Hurisode) => {
  const { category } = hurisode
  const is振袖 = hurisode.product_type_id === Enumプラン.着物を選択.振袖
  const is持込 = getOptionIdOf種別(category) === Enumプラン.着物種別.お持込
  const isカタログ商品 =
    category === Enum振袖二尺袖.レンタルORカタログ.カタログレンタル
  const isカタログレンタル =
    getOptionIdOf二尺袖レンタル(hurisode.category) ===
    Enum振袖二尺袖.レンタルORカタログ.カタログレンタル
  return {
    is振袖,
    isカタログ商品,
    is二尺袖: !is振袖,
    is仕立て上り振袖: !is持込 && is振袖,
    is仕立上り二尺袖: !is持込 && !is振袖 && !isカタログレンタル,
    isカタログ二尺袖: !is持込 && !is振袖 && isカタログレンタル,
    is80Off: hurisode.discount_type === Enum見積振袖DiscountType['80%引き'],
    is持込,
    isレンタル: !is持込,
  }
}

/**
 * 選択肢系の項目の初期値(選択されたoptionのid)を再計算する関数群
 */
const getOptionIdOf種別 = (category: Hurisode['category']) => {
  const is持込 = [
    ProductCategory.bringIn,
    ProductCategory.purchasedElderSister,
  ].includes(category)

  return is持込 ? Enumプラン.着物種別.お持込 : Enumプラン.着物種別.レンタル
}

const getOptionIdOf補正s = (hurisode: Hurisode) => {
  const { 袖補正, 裄補正 } = Enum振袖二尺袖
  return {
    裄補正: hurisode.is_correct_for_yukitake ? 裄補正.する : 裄補正.しない,
    袖補正: hurisode.is_correct_for_sodetake ? 袖補正.する : 袖補正.しない,
  }
}

const getOptionIdOf二尺袖レンタル = (category: Hurisode['category']) => {
  const isカタログレンタル = [ProductCategory.catalogRental].includes(category)
  const { カタログレンタル, レンタル } = Enum振袖二尺袖.レンタルORカタログ
  return isカタログレンタル ? カタログレンタル : レンタル
}

const getOptionIdOf誂えるか = (
  catalogOtherKimono: Ref<CatalogOtherKimono | null>
) => {
  const { 誂える } = Enum振袖二尺袖.誂え
  return computed(() => {
    if (!catalogOtherKimono) return null
    const has在庫 = catalogOtherKimono.value?.available_order_quantity
    if (catalogOtherKimono.value?.available_order_quantity) return 誂える
    return has在庫 ? null : 誂える
  })
}

/**
 * 振袖と二尺袖の商品検索結果を取得する
 * NOTE: ひとまずパラメータにある値から仕立て上りとカタログ検索両方叩いて、
 * どちらを使うかはrestorer側で判定する。
 */
const useSearchedProducts = (estimate: Ref<EstimateDetail | undefined>) => {
  const {
    fetchCatalogOtherKimono,
    catalogOtherKimono,
    fetchFinishedKimonoList,
    finishedKimonoList,
  } = useSearchProduct()

  const fetchProducts = (hurisode: Hurisode) => {
    if (hurisode.catalog_code) {
      const params = {
        catalog_code: hurisode.catalog_code,
        product_type_id: ProductClassification.nisyakusode,
        use_date: estimate.value?.use_date ?? '',
      } as const
      fetchCatalogOtherKimono(params)
    }

    if (hurisode.product_id) {
      const params: KimonoSearchQueryParams = {
        keyword: hurisode.product_id,
        keyword_types: [0],
        product_type_id: hurisode.product_type_id as 1 | 8,
        has_purchased: 1,
      }
      fetchFinishedKimonoList(params)
    }
  }

  return { fetchProducts, catalogOtherKimono, finishedKimonoList }
}
