import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { OnlySelectionPlanState } from '~/store/types'

export const useRestore双子割 = () => {
  const store =
    useComingStore<Omit<OnlySelectionPlanState, ''>>('TwinsDiscount')

  const restore双子割 = (status: Restate['discounts']['双子割']) => {
    store.setOf<OnlySelectionPlanState['selections']>('setSelection', [status])
    store.setIsValidate(false)
  }

  return {
    restore双子割,
  }
}
