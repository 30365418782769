import { nextTick } from '@nuxtjs/composition-api'
import { Restate } from '~/composable/estimate/repository/graduation/EstimateRestate'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { FormValues商品設定 } from '~/composable/estimate/viewModel/graduation/Fields/Fields商品設定'

/**
 * TODO: 髪飾りのrestate関数を作成したらここにも追加する
 */
export const useRestore商品設定 = () => {
  const store = useGraduateStore<FormValues商品設定>('tab商品設定')

  const restore商品設定 = (items: Restate['items']) => {
    // NOTE: 他の部分の計算によって変化する場所のためか？タブの増減に影響する部分だからか？
    // nextTickを使わないと正常に更新されない

    nextTick(() => {
      /* 初期値を設定 ※必ず入力される値はここで設置 */
      const formValues: FormValues商品設定 = {
        shohinSettei重ね衿: items.重ね衿 ? items.重ね衿.length : 0,
        shohinSettei髪飾り: items.髪飾り ? items.髪飾り.length : 0,
      }

      /* ストアに内容を保存する */
      store.setAll(formValues)
    })
  }
  return { restore商品設定 }
}
