export const PAYMENT_METHOD = {
  CASH: 1, // 現金
  GIFT_CERTIFICATES: 2, // 商品券
  TRANSFER: 3, // 振込
  POINT: 4, // 友の会ポイント
  CARD: 5, // カード
  LOAN: 6, // ローン
  CASH_ON_DELIVERY: 7, // 代引き
  E_MONEY: 8, // 電子マネー
  FAMILY_POINT: 9, // 友の会ポイント（ご家族保有）
  RECEIPT_DISCOUNT: 10, // 入金値引き
  POINT_ROUND_OFF: 50, // ポイント端数処理
} as const

export const PAYMENT_CATEGORY = {
  NONE: 0, // 未選択
  PART_PAYMENT: 1, // 内金
  PAYMENT: 2, // 支払
  REFUND: 3, // 返金
} as const

export const PAYMENT_CATEGORY_OPTIONS = [
  { id: PAYMENT_CATEGORY.PART_PAYMENT, text: '内金' },
  { id: PAYMENT_CATEGORY.PAYMENT, text: '全額支払' },
] as const

/**
 * 支払い必要かどうか
 */
export const IS_PAYMENT = {
  /** 支払い不要 */
  NO: 1,
  /** 支払い必要 */
  YES: 2,
} as const
