import { MutationTree } from 'vuex'
import { AccessoryPlanState } from '../../../types'

const mutations: MutationTree<AccessoryPlanState> = {
  setSelection: (state, selection) => {
    state.selections = selection
  },

  setInput: (state, input) => {
    state.input = input
  },

  setPrice: (state, price) => {
    state.price = price
  },

  setIsFree: (state, isFree) => {
    state.isFree = isFree
  },

  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },

  delete: (state) => {
    state.selections = [0, 0, 0]
    state.input = {
      productName: '',
      buyPrice: 0,
      rentalPrice: 0,
      rankUpRentalPrice: 0,
      rankUpBuyPrice: 0,
      premiumRentalPrice: 0,
      premiumBuyPrice: 0,
    }
    state.price = {
      buy: 0,
      rental: 0,
    }
    state.isFree = false
    state.isValidate = null
    state.isPPDiscount = false
    state.isPPAddingPrice = false
  },
}

export default mutations
