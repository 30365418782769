/**
 * 見積一覧でのプラン
 */
export default class CoodinatePlan {
  /** 振袖レンタル */
  static readonly rental = 1
  /** 振袖レンタル+プレミアムパック */
  static readonly rentalPremium = 2
  /** フルセット：振袖購入/ 写真のみレンタル：15P×1冊 */
  static readonly purchase = 3
  /** 振袖購入+プレミアムパック/ 写真のみレンタル：プレミアムパック+15P×1冊 */
  static readonly purchasePremium = 4

  static isRentalPlan = (val: number) => {
    return [CoodinatePlan.rental, CoodinatePlan.rentalPremium].includes(val)
  }

  static isPurchasePlan = (val: number) => {
    return [CoodinatePlan.purchase, CoodinatePlan.purchasePremium].includes(val)
  }

  static isPremiumPlan = (val: number) => {
    return [
      CoodinatePlan.rentalPremium,
      CoodinatePlan.purchasePremium,
    ].includes(val)
  }
}
