import { EstimateDetail } from '~/api/estimateRepository'

type Flag = {
  flagForNishakusode: boolean
  flagForHakama: boolean
  isEstimateBeforeReleaseForNishakusode: boolean
}

export type CopiedEstimateState = { estimate: EstimateDetail | null } & {
  isCopyEstimateFlag: Flag
}

export const getInitialFields = (): EstimateDetail | null => {
  return null
}

const state = (): CopiedEstimateState => ({
  // NOTE:見積コピーして見積作成を開始した時にコピー元の見積内容を保持しておくためのステート
  // 特定の商品に変更があった場合に前回の値を修正して送るために必要
  // isCopyEstimateFlag:「見積コピー」選択時、二尺袖・袴 カタログ商品に残数が0の場合でも同じ商品の場合はバリデーションを合格させるためのFlag
  estimate: getInitialFields(),
  isCopyEstimateFlag: {
    flagForNishakusode: false,
    flagForHakama: false,
    isEstimateBeforeReleaseForNishakusode: false, // 契約書フローにおいて二尺袖 + 袴持ち込みの割引適用かどうか
  },
})

export default state
