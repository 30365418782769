



















import {
  computed,
  defineComponent,
  ref,
  useRoute,
  useRouter,
  watch,
} from '@nuxtjs/composition-api'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { urls } from '~/constants/urls'
import { useTabValidation } from '~/composable/estimate/service/graduation/TabValidation'
import { useEstimateEdit } from '~/composable/estimate/service/useEstimateEdit'
import { TabNaviState } from '~/store/Estimate/GraduationCeremony/types'

export default defineComponent({
  layout: 'graduationCeremony',
  setup() {
    const root = ref<HTMLElement | null>(null)
    const isValid = ref(false)
    const tabNavi = useGraduateStore<TabNaviState>('tabNavi')
    const { updateTabValidate } = useTabValidation()
    const { goToConfirmationContract, isEditMode } = useEstimateEdit()
    const route = useRoute()
    const router = useRouter()
    const { estimate_id } = route.value.params
    const nextTabUrl = tabNavi.get<'nextUrl'>('nextUrl')
    const isAllValidateTabs =
      tabNavi.get<'isAllValidateTabs'>('isAllValidateTabs')
    const isBeforeConfirmation = tabNavi.get<'isBeforeConfirmation'>(
      'isBeforeConfirmation'
    )

    //* ボタンをdisabledにする関数
    const isDisabled = computed(() => {
      if (route.value.path.includes('bikou')) {
        return isAllValidateTabs.value
      }
      return true
    })

    //* ボタンテキストを変更する関数
    const buttonText = computed(() => {
      return route.value.path.includes('bikou') || isBeforeConfirmation.value
        ? '入力内容確認'
        : '次へ'
    })

    //* 次へボタンが表示されるタイミングでタブのバリデーションのオブジェクトをstoreに追加or編集するロジック
    const handleClickNextButton = (): void => {
      //* 入力内容確認ページに遷移させる処理
      if (isBeforeConfirmation.value) {
        router.push(urls.見積書.卒業式.入力内容確認(Number(estimate_id)))
        return
      }

      if (nextTabUrl.value) {
        router.push(nextTabUrl.value)
        updateTabValidate()
      }
    }

    const handleClickGoToConfirmation = () => {
      goToConfirmationContract(2)
    }

    watch(route, (_, oldVal) => {
      //*　通常状態にフラグを戻す処理
      tabNavi.set('isBeforeConfirmation', false)
      tabNavi.set('isNextButton', !!root.value && isValid.value)
      updateTabValidate(oldVal.path)
    })

    return {
      root,
      buttonText,
      handleClickNextButton,
      isAllValidateTabs,
      isDisabled,
      isEditMode,
      handleClickGoToConfirmation,
      isValid,
    }
  },
})
