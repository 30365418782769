import { Restate } from '~/composable/estimate/repository/graduation/EstimateRestate'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { FormValues草履ブーツ_着物レンタル } from '~/composable/estimate/viewModel/graduation/Fields/Fields草履ブーツ_着物レンタル'
import { FormValues草履ブーツ_着物持込 } from '~/composable/estimate/viewModel/graduation/Fields/Fields草履ブーツ_着物持込'

export const useRestore草履ブーツ = () => {
  const store着物レンタル =
    useGraduateStore<FormValues草履ブーツ_着物レンタル>(
      'tab草履ブーツ_着物レンタル'
    )
  const store着物持込 =
    useGraduateStore<FormValues草履ブーツ_着物持込>('tab草履ブーツ_着物持込')

  const restore草履ブーツ = (restate: Restate) => {
    const zoriBoots = restate.items.草履ブーツ
    if (!zoriBoots) {
      return
    }

    /* 初期値を設定 ※必ず入力される値はここで設置 */
    if (zoriBoots.type === '着物レンタル') {
      /* 以下で入力内容の条件に基づき、必要な項目を埋めていく */
      const formValues: FormValues草履ブーツ_着物レンタル = {
        zoriboots商品: zoriBoots.optionId品目,
        zoriboots種別: zoriBoots.optionId種別,
        zoriboots形態: zoriBoots.optionId形態,
        zoribootsセット内レンタル価格: null,
        zoribootsランクアップ: zoriBoots.選択商品,
        zoriboots規定商品購入方法: zoriBoots.optionId購入方法,
        zoriboots規定商品: null,
        zoriboots規定商品単品購入: null,
        zoriboots現品レンタル価格: { id: 0, value: '' },
        zoriboots現品レンタル価格フリー入力: null,
        zoriboots現品単品購入: { id: 0, value: '' },
        zoriboots現品単品購入フリー入力: null,
      }

      if (zoriBoots.flags.isセット内) {
        formValues.zoribootsセット内レンタル価格 = zoriBoots.レンタル価格
      }

      if (zoriBoots.flags.isランクアップ && !zoriBoots.flags.is現品) {
        formValues.zoriboots規定商品 = zoriBoots.レンタル価格
        formValues.zoriboots規定商品単品購入 = zoriBoots.購入価格
      }

      if (zoriBoots.flags.isランクアップ && zoriBoots.flags.is現品) {
        formValues.zoriboots現品レンタル価格フリー入力 = zoriBoots.レンタル価格
        formValues.zoriboots現品単品購入フリー入力 = zoriBoots.購入価格
      }

      /* ストアに内容を保存する */
      store着物レンタル.setAll(formValues)
      return
    }

    /* 初期値を設定 ※必ず入力される値はここで設置 */
    if (zoriBoots.type === '着物持込') {
      /* 以下で入力内容の条件に基づき、必要な項目を埋めていく */
      const formValues: FormValues草履ブーツ_着物持込 = {
        zoriboots商品: zoriBoots.optionId品目,
        zoriboots種別: zoriBoots.optionId種別,
        zoriboots商品選択: zoriBoots.選択商品,
        zoriboots見積選択: zoriBoots.optionId購入方法,
        zoriboots単品レンタル: null,
        zoriboots単品購入: null,
        zoriboots現品単品レンタル: { id: 0, value: '' },
        zoriboots現品単品レンタルフリー入力: null,
        zoriboots現品単品購入: { id: 0, value: '' },
        zoriboots現品単品購入フリー入力: null,
      }

      //* 現品を選択した場合
      if (!zoriBoots.flags.is持込 && zoriBoots.flags.is現品) {
        formValues.zoriboots現品単品レンタルフリー入力 = zoriBoots.レンタル価格
        formValues.zoriboots現品単品購入フリー入力 = zoriBoots.購入価格
      }

      if (!zoriBoots.flags.is持込 && !zoriBoots.flags.is現品) {
        formValues.zoriboots単品購入 = zoriBoots.購入価格
        formValues.zoriboots単品レンタル = zoriBoots.レンタル価格
      }

      /* ストアに内容を保存する */
      store着物持込.setAll(formValues)
    }
  }
  return { restore草履ブーツ }
}
