import { computed } from '@nuxtjs/composition-api'
import {
  CustomerState,
  EstimateJkphotoInAdvanceState,
  EstimatePhotoSettings,
  OnlySelectionPlanState,
  ProductState,
  SisterDiscountState,
} from '~/store/types'
import { useComingStore } from '../../ComingOfAgeStore'
import { PLAN } from '~/constants/estimate/comingOfAge'
import { DISCOUNT_PRICE_TYPE, DISCOUNT_TYPE } from '~/constants/estimate/config'
import { State振袖, useState振袖 } from './State振袖'
import { useScreenTypeFlags } from '~/composable/estimate/domain/ComingOfAge/ScreenTypeFlags'
import { PlanFlags } from './types'
import { FormValues割引 } from '~/composable/estimate/viewModel/graduation/Fields/Fields割引'
import { usePrice } from '~/composable/general/Price'
import { kimonoDiscountAmount } from './utilities'
import { State as PricesState } from '~/store/PriceTable/state'

export const useStateお客様検索 = () => {
  const valuesCustomer = useComingStore<CustomerState>('Customers').getAll()

  return computed(() => {
    if (!valuesCustomer.value.customer) return null
    const { first_name, last_name } = valuesCustomer.value.customer
    const visitingReasons = valuesCustomer.value.visit_motives.map((v) => v.id)
    return {
      ...valuesCustomer.value.customer,
      fullName: `${last_name} ${first_name}`,
      visiting_reason: visitingReasons,
    }
  })
}

export const useStateお名前 = () => {
  const valuesCustomer = useComingStore<CustomerState>('Customers').getAll()

  return computed(() => {
    return valuesCustomer.value.name || ''
  })
}

export const useState試着写真 = () => {
  const values試着写真 =
    useComingStore<EstimatePhotoSettings>('Photos').getAll()
  return computed(() => getState試着写真(values試着写真.value))
}

export const getState試着写真 = (values試着写真: EstimatePhotoSettings) => {
  const photos全体写真 = values試着写真.settings[0].photos
  const photos衿元写真 = values試着写真.settings[1].photos
  return {
    アップロードあり: values試着写真.selections.includes(PLAN.YES),
    全体写真: photos全体写真,
    衿元写真: photos衿元写真,
  }
}

export const useState商品設定 = () => {
  const values商品設定 = useComingStore<ProductState>('Products').getAll()

  return computed(() => {
    const { products } = values商品設定.value
    const prod帯揚げ = products.find((p) => p.id === 1)
    const prod重ね衿 = products.find((p) => p.id === 2)
    const prod髪飾り = products.find((p) => p.id === 3)
    const prod草履 = products.find((p) => p.id === 4)
    const prodバッグ = products.find((p) => p.id === 5)

    return {
      帯揚げ: prod帯揚げ?.count ?? 0,
      重ね衿: prod重ね衿?.count ?? 0,
      髪飾り: prod髪飾り?.count ?? 0,
      草履: prod草履?.count ?? 0,
      バッグ: prodバッグ?.count ?? 0,
    }
  })
}

export const useState母娘プラン = () => {
  const values母娘プラン = useComingStore<OnlySelectionPlanState>(
    'MotherAndDaughterPlan'
  ).getAll()

  return computed(() => {
    return {
      is適用: values母娘プラン.value.selections.includes(PLAN.APPLY),
    }
  })
}

export const useStateJK前撮り = () => {
  const valuesJK前撮り =
    useComingStore<EstimateJkphotoInAdvanceState>('JkPhotoInAdvance').getAll()
  return computed(() => getStateJK前撮り(valuesJK前撮り.value))
}

export const getStateJK前撮り = (
  valuesJK前撮り: EstimateJkphotoInAdvanceState
) => {
  return {
    is適用: valuesJK前撮り.selections.includes(PLAN.APPLY),
  }
}

export const useState割引 = () => {
  const comingStore双子割 =
    useComingStore<OnlySelectionPlanState>('TwinsDiscount')
  const priceTable = comingStore双子割.getPrices() // Store料金情報を取得
  const values双子割 = comingStore双子割.getAll()

  const comingStore姉妹割 =
    useComingStore<SisterDiscountState>('SisterDiscount')
  const values妹様割 = comingStore姉妹割.getAll()

  const comingStore従業員割 =
    useComingStore<OnlySelectionPlanState>('EmployeeDiscount')
  const values従業員割 = comingStore従業員割.getAll()

  const values割引 = useComingStore<FormValues割引>('Discount').getAll()
  const hurisode = useState振袖()
  const { showTypeフルセット, showType写真のみレンタル, showType持ち込み } =
    useScreenTypeFlags()

  return computed(() => {
    const flags = {
      isフルセット: showTypeフルセット.value,
      is写のみレンタル: showType写真のみレンタル.value,
      is持ち込み: showType持ち込み.value,
    }
    return getState割引(
      values妹様割.value,
      values双子割.value,
      values従業員割.value,
      values割引.value,
      hurisode.value,
      flags,
      priceTable
    )
  })
}

export const getState割引 = (
  values妹様割: SisterDiscountState,
  values双子割: OnlySelectionPlanState,
  values従業員割: OnlySelectionPlanState,
  values割引: FormValues割引,
  state振袖: State振袖,
  planFlags: PlanFlags,
  priceTable: PricesState['prices']
) => {
  const { toNumber } = usePrice()
  return {
    妹様割: {
      typeId: DISCOUNT_TYPE.SISTER.id,
      is適用: values妹様割.selections.includes(PLAN.APPLY),
      お姉様情報: values妹様割.customer,
      割引: {
        種別: DISCOUNT_PRICE_TYPE.RATE.id,
        率: priceTable.estimate.割引率.妹様割,
      },
      着物割引額: {
        レンタル: kimonoDiscountAmount(
          state振袖.レンタル価格 ?? 0,
          priceTable.estimate.割引率.妹様割,
          values妹様割.selections.includes(PLAN.APPLY)
        ),
        購入: kimonoDiscountAmount(
          state振袖.購入価格 ?? 0,
          priceTable.estimate.割引率.妹様割,
          values妹様割.selections.includes(PLAN.APPLY)
        ),
      },
    },
    双子割: {
      typeId: DISCOUNT_TYPE.TWINS.id,
      is適用: values双子割.selections.includes(PLAN.APPLY),
      割引: {
        種別: DISCOUNT_PRICE_TYPE.RATE.id,
        率: priceTable.estimate.割引率.双子割,
      },
      着物割引額: {
        レンタル: kimonoDiscountAmount(
          state振袖.レンタル価格 ?? 0,
          priceTable.estimate.割引率.双子割,
          values双子割.selections.includes(PLAN.APPLY)
        ),
        購入: kimonoDiscountAmount(
          state振袖.購入価格 ?? 0,
          priceTable.estimate.割引率.双子割,
          values双子割.selections.includes(PLAN.APPLY)
        ),
      },
    },
    従業員割: {
      typeId: DISCOUNT_TYPE.EMPLOYEE.id,
      is適用: values従業員割.selections.includes(PLAN.APPLY),
      割引: {
        種別: DISCOUNT_PRICE_TYPE.AMOUNT.id,
        金額: priceTable.estimate.従業員割,
      },
    },
    // NOTE：復元の都合上作成した、ジェネラルに使える割引項目
    割引: {
      typeId: DISCOUNT_TYPE.GENERAL.id,
      is適用: values割引.waribiki適用 === PLAN.APPLY,
      割引: {
        種別: DISCOUNT_PRICE_TYPE.AMOUNT.id,
        金額:
          values割引.waribiki適用 === PLAN.APPLY
            ? toNumber(values割引.waribiki金額入力 ?? '¥0')
            : 0,
      },
    },
    振袖割引: {
      割引: {
        種別: DISCOUNT_PRICE_TYPE.RATE.id,
        率: priceTable.estimate.割引率.振袖割引,
      },
      着物割引額: {
        レンタル: kimonoDiscountAmount(
          state振袖.レンタル価格 ?? 0,
          priceTable.estimate.割引率.振袖割引,
          planFlags.is写のみレンタル
        ),
      },
    },
  }
}
