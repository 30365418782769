import { OtherItems } from '~/store/types'
import { useComingStore } from '../../ComingOfAgeStore'
import { computed } from '@nuxtjs/composition-api'

/** enums */
import ProductClassification from '~/constants/enums/productClassification'
import { OTHER_ITEMS } from '~/constants/estimate/config'

export const useStateその他商品 = () => {
  const valuesその他商品 = useComingStore<OtherItems>('OtherItem').getAll()

  return computed(() => {
    const is適用 = valuesその他商品.value.apply === OTHER_ITEMS.種別.しない

    const その他商品 = valuesその他商品.value.items.map((item) => ({
      品目: item.product_name,
      価格: typeof item.price === 'string' ? Number(item.price) : item.price,
      品種区分: ProductClassification.otherItem,
      価格タイプ: item.price_type,
      袴利用あり: item.is_hakama_used === OTHER_ITEMS.袴利用.する,
      // フルセット・その他商品袴なしの場合に使用
      単品レンタル価格:
        typeof item.single_rental_price === 'string'
          ? Number(item.single_rental_price)
          : item.single_rental_price,
      // フルセット・その他商品袴なしの場合に使用
      単品購入価格:
        typeof item.single_purchase_price === 'string'
          ? Number(item.single_purchase_price)
          : item.single_purchase_price,
    }))

    return { is適用, その他商品 }
  })
}
