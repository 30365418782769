import { ContractsPhotoSettings } from '../../types'

// Waning: src/store/types.tsのPhotoSettingsの型を使用していたが、型の内容が変わっていたため
// 応急処置で型を変更
const state = (): ContractsPhotoSettings => ({
  settings: [
    {
      id: 1,
      title: '全体写真',
      limit: 3,
      isPossibleCheck: true,
      getEstimateThumbnail: false,
      photos: [],
    },
    {
      id: 2,
      title: '衿元写真',
      limit: 1,
      isPossibleCheck: false,
      getEstimateThumbnail: false,
      photos: [],
    },
  ],
  isValidate: null,
})

export default state
