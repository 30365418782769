import { MutationTree } from 'vuex'
import { ComingOfAgeCeremonyState } from '../../types'

const mutations: MutationTree<ComingOfAgeCeremonyState> = {
  setPatternLine: (state, patternLine) => {
    state.patternLine = patternLine
  },

  setInput: (state, input) => {
    state.input = input
  },

  setShop: (state, shop) => {
    state.shop = shop
  },

  setDressingPlaces: (state, dressingPlaces) => {
    state.dressingPlaces = dressingPlaces
  },

  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },

  delete: (state) => {
    state.patternLine = [0, 0, 0]
    state.input = {
      ceremonyDate: '', // 成人式使用日
      shopOfDressing: '', // 着付場所
      placeOfAttending: '', // 出席地域
      timeOfEnteringDressPlace: '', // 着付場所入場時間
      timeOfEnteringSalon: '', // 美容室入場時間
      remark: '', // 備考
      prefecture: '',
      nameOfSalon: '', // 美容室名
      telOfSalon: '', // 美容室TEL
      salonId: 0, // 美容室ID
      address: '', // 美容室住所
      giveOption: 0, // お渡し方法
      dateOfGivingCOAC: '', // 成人式お渡し日
      placeOfGivingCOAC: '', // 成人式お渡し場所
      passStoreId: 0, // お渡し場所ID
      dateOfReturningCOAC: '', // 成人式ご返却日
      placeOfReturningCOAC: '', // 成人式ご返却場所
      returnStoreId: 0, // ご返却場所ID
      shopId: null,
      needMakeup: true, // メイク有無
    }
    state.shop = {
      shopId: 0,
      shopName: '',
    }
    state.dressingPlaces = {
      id: null, // 着付け会場ID
      name: '', // 着付け会場名
      dressingAddressInfo: null, // 着付会場住所
    }
    state.isValidate = null
  },
}

export default mutations
