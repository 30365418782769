import { computed, ComputedRef, Ref } from '@nuxtjs/composition-api'
import { EstimateDetail, Items } from '~/api/estimateRepository'
import { Product } from '~/constants/productData'
import { ItemPlan } from '~/constants/enums/itemPlan'
import ProductId from '~/constants/enums/productClassification'
import { getSortedItemsByOrder } from '~/utils/utils'
import { useRestatePlan } from '../RestatePlan'
import { GEINPIN } from '~/composable/estimate/viewModel/comingOfAge/FieldProps/FieldProps草履バッグ'
import { usePPZoriBagPlan } from '~/composable/general/PPDiscount/usePPZoriBagPlan'

type Bag = {
  optionId商品: number
  selected商品: Product | null
  単品購入価格: number
  単品レンタル価格: number
  プレミアム購入価格: number
  商品名: string
  flags: {
    is持込: boolean
    isPPDiscount: boolean
    isPPAddingPrice: boolean
  }
}

export const useRestateバッグ複数個目 = (
  estimate: Ref<EstimateDetail | undefined>,
  isPPDiscount: ComputedRef<boolean | undefined>
) => {
  const { getZoriBagPPAddingPrice } = usePPZoriBagPlan()
  const restateプラン = useRestatePlan(estimate)

  return computed(() => {
    if (!estimate.value?.items || !restateプラン.value) {
      return null
    }

    const bags: Bag[] = []
    const bagItems = getSortedItemsByOrder(estimate.value.items, ProductId.bag)
    if (bagItems.length < 2) {
      // NOTE:レスポンスにバッグが2個以上含まれていなければ空配列を返す
      return bags
    }
    // NOTE:1個目はRestateバッグ1個目.tsの方で処理するので除く
    bagItems.shift()

    bagItems.forEach((item) => {
      const { isPPAddingPrice } = getZoriBagPPAddingPrice(
        item,
        isPPDiscount.value
      )
      bags.push(formatBag(item, isPPDiscount.value ?? false, isPPAddingPrice))
    })

    return bags
  })
}

const formatBag = (
  item: Items,
  isPPDiscount: boolean,
  isPPAddingPrice: boolean
): Bag => {
  const is持込 = item.plan === ItemPlan.bringIn
  const selectedOption = getOptionOf商品選択(item)

  return {
    selected商品: selectedOption,
    optionId商品: selectedOption?.id ?? 0,
    単品購入価格: Number(item.purchase_price) ?? 0,
    単品レンタル価格: Number(item.rental_price),
    プレミアム購入価格: Number(item.premium_purchase_price ?? 0),
    商品名: item.product_name ?? '',
    flags: {
      is持込,
      isPPDiscount,
      isPPAddingPrice,
    },
  }
}

const getOptionOf商品選択 = (item: Items) => {
  if (item.product_name === GEINPIN.product_name) return GEINPIN

  if (!item.product_id || !item.product_name) return null

  // NOTE: nameは`U-1 バッグ(ご購入用)`のようになっているため
  const splittedItemName = item.product_name.split(/\s+/, 2)
  return {
    id: item.product_id,
    post_number_code: splittedItemName[0],
    product_name: splittedItemName[1],
    price: {
      rental: item.rental_price,
      buy: item.purchase_price ?? 0,
    },
  }
}
