import { GetterTree } from 'vuex'
import { RootState } from '../types'
import { State } from './state'

const getters: GetterTree<State, RootState> = {
  getState: (state: State) => {
    return state.data
  },
  getIsValidate: (state: State) => {
    return state.data.isValidate
  },
}

export default getters
