import { GetterTree } from 'vuex'
import { RootState, loginUser } from '../types'

const getters: GetterTree<loginUser, RootState> = {
  getUser: (state: loginUser): loginUser['user'] => {
    return state.user
  },
  getLoginDate: (state: loginUser) => {
    return state.user.login_date
  },
}

export default getters
