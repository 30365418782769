import { Enumお写真プラン } from '~/constants/estimate/graduationOptions'
import { Restate } from '~/composable/estimate/repository/graduation/EstimateRestate'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { FormValuesお写真プラン } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsお写真プラン'

export const useRestoreお写真プラン = () => {
  const store = useGraduateStore<FormValuesお写真プラン>('tabお写真プラン')

  const restoreお写真プラン = (restate: Restate) => {
    if (!restate.photo_plan) {
      store.set('oshashinPlan撮影', Enumお写真プラン.写真撮影.しない)
      return
    }
    const items = restate.photo_plan

    /* 初期値を設定 ※必ず入力される値はここで設置 */
    const formValues: FormValuesお写真プラン = {
      oshashinPlan撮影: items.撮影,
      oshashinPlan商品選択: items.商品optionIds,
      oshashinPlanデザインアルバム: items.デザインアルバムスタンダード,
      oshashinPlan紹介者特典ポーズ数_アルバム: items.アルバム,
      oshashinPlan六切り台紙あり: items.六切り台紙あり,
      oshashinPlan六切り台紙なし: items.六切り台紙なし,
      oshashinPlanキャビネ台紙あり: items.キャビネ台紙あり,
      oshashinPlanキャビネ台紙なし: items.キャビネ台紙なし,
      oshashinPlanアクリルスタンドA5: items.アクリルスタンドA5,
      oshashinPlanアクリルスタンドA4: items.アクリルスタンドA4,
      oshashinPlanUSB: items.USB,
    }

    /* ストアに内容を保存する */
    store.setAll(formValues)
  }

  return { restoreお写真プラン }
}
