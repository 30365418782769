import { Restate } from '~/composable/estimate/repository/graduation/EstimateRestate'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { FormValues重ね衿_着物持込 } from '~/composable/estimate/viewModel/graduation/Fields/Fields重ね衿_着物持込'
import { FormValues重ね衿_着物レンタル1個目 } from '~/composable/estimate/viewModel/graduation/Fields/Fields重ね衿_着物レンタル1個目'
import { FormValues重ね衿_着物レンタル2個以上 } from '~/composable/estimate/viewModel/graduation/Fields/Fields重ね衿_着物レンタル2個以上'

export const useRestore重ね衿 = () => {
  const store重ね衿_着物レンタル1 =
    useGraduateStore<FormValues重ね衿_着物レンタル1個目>(
      'tab重ね衿_着物レンタル1'
    )
  const store重ね衿_着物レンタル2 =
    useGraduateStore<FormValues重ね衿_着物レンタル2個以上>(
      'tab重ね衿_着物レンタル2'
    )
  const store重ね衿_着物レンタル3 =
    useGraduateStore<FormValues重ね衿_着物レンタル2個以上>(
      'tab重ね衿_着物レンタル3'
    )
  const store重ね衿_着物持込1 =
    useGraduateStore<FormValues重ね衿_着物持込>('tab重ね衿_着物持込1')
  const store重ね衿_着物持込2 =
    useGraduateStore<FormValues重ね衿_着物持込>('tab重ね衿_着物持込2')
  const store重ね衿_着物持込3 =
    useGraduateStore<FormValues重ね衿_着物持込>('tab重ね衿_着物持込3')

  const restore重ね衿 = (restate: Restate) => {
    if (!restate.items.重ね衿) return
    if (!restate.plan) return

    if (restate.plan.flags.has着物レンタル) {
      /*
       * プランで着物レンタルを選んだ際の重ね衿選択内容(1個目と２個目以降の中身が違う)
       */
      restate.items.重ね衿.forEach((item, i) => {
        if (item.type === 'firstOf着物レンタル') {
          /* 1個目の初期値を設定 ※必ず入力される値はここで設置 */
          const formValues: FormValues重ね衿_着物レンタル1個目 = {
            kasaneeri_one種別: item.optionId種別,
            kasaneeri_one形態: null,
            kasaneeri_oneセット内: null,
            kasaneeri_oneランクアップ: { id: 0, value: '' }, // フリー入力を選択
            kasaneeri_oneフリー入力: null,
          }
          /* 以下で入力内容の条件に基づき、必要な項目を埋めていく */
          if (item.flags.isレンタル) {
            formValues.kasaneeri_one形態 = item.optionId形態
          }
          if (item.flags.isセット内) {
            formValues.kasaneeri_oneセット内 = item.レンタル価格
            if (item.PP適用レンタル価格) {
              formValues.kasaneeri_oneフリー入力 = item.PP適用レンタル価格
            }
          }
          if (item.flags.isランクアップ) {
            formValues.kasaneeri_oneフリー入力 = item.レンタル価格
          }
          /* ストアに内容を保存する */
          store重ね衿_着物レンタル1.setAll(formValues)
        } else {
          /* 2個目以降の初期値を設定 ※必ず入力される値はここで設置 */
          const formValues: FormValues重ね衿_着物レンタル2個以上 = {
            kasaneeri_multi種別: item.optionId種別,
            kasaneeri_multi単品レンタル: { id: 0, value: '' }, // フリー入力を選択
            kasaneeri_multi単品レンタルフリー入力: null,
            kasaneeri_multi単品購入: { id: 0, value: '' }, // フリー入力を選択
            kasaneeri_multi単品購入フリー入力: null,
            kasaneeri_multi見積表示: null,
            kasaneeri_multi形態: item.optionId形態 || null,
          }

          // /* 以下で入力内容の条件に基づき、必要な項目を埋めていく */
          if (item.flags.is単品レンタル購入) {
            formValues.kasaneeri_multi単品レンタルフリー入力 = item.レンタル価格
            formValues.kasaneeri_multi単品購入フリー入力 = item.購入価格
            formValues.kasaneeri_multi見積表示 = item.optionId見積表示
          }

          /* ストアに内容を保存する */
          if (i === 1) store重ね衿_着物レンタル2.setAll(formValues)
          if (i === 2) store重ね衿_着物レンタル3.setAll(formValues)
        }
      })
    } else {
      /*
       * プランで着物持込を選んだ際の重ね衿選択内容
       */
      restate.items.重ね衿.forEach((item, i) => {
        if (item.type !== '着物持込OR複数') return
        /* 初期値を設定 ※必ず入力される値はここで設置 */
        const formValues: FormValues重ね衿_着物持込 = {
          kasaneeri種別: item.optionId種別,
          kasaneeri単品レンタル: { id: 0, value: '' }, // フリー入力を選択
          kasaneeri単品レンタルフリー入力: null,
          kasaneeri単品購入: { id: 0, value: '' }, // フリー入力を選択
          kasaneeri単品購入フリー入力: null,
          kasaneeri見積表示: null,
        }
        /* 以下で入力内容の条件に基づき、必要な項目を埋めていく */
        if (item.flags.is単品レンタル購入) {
          formValues.kasaneeri単品レンタルフリー入力 = item.レンタル価格
          formValues.kasaneeri単品購入フリー入力 = item.購入価格
          formValues.kasaneeri見積表示 = item.optionId見積表示
        }
        /* ストアに内容を保存する */
        if (i === 0) store重ね衿_着物持込1.setAll(formValues)
        if (i === 1) store重ね衿_着物持込2.setAll(formValues)
        if (i === 2) store重ね衿_着物持込3.setAll(formValues)
      })
    }
  }

  return { restore重ね衿 }
}
