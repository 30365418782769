import { useRouter, useStore } from '@nuxtjs/composition-api'
import { ContractsTabListInterface } from '~/composable/headerTab/useContractsTabList'
import { BaseEstimateState } from '~/store/Contracts/types'

const contractStorePath = {
  試着写真: 'Photos',
  小物写真: 'ProductPhoto',
  基本情報: 'BaseInformation',
  納品希望日: 'DeliveryDate',
  JK前撮り: 'JkPhotoInAdvance',
  前撮り: 'PhotoInAdvance',
  Wフォト: 'DoublePhoto',
  母娘プラン: 'MotherAndDaughterPlan',
  成人式: 'ComingOfAgeCeremony',
  卒業式: 'GraduationCeremony',
  採寸: 'Measuring',
  着付け小物: 'Accessories',
  お客様ご準備: 'CustomerReady',
  ご家族情報: 'FamilyInformation',
  紹介者特典: 'ReferralBonus',
  お支払: 'Payment',
  重要事項: 'ImportantMatter',
  備考: 'Remarks',
}

type ContractStorePath = keyof typeof contractStorePath

const useContractEdit = () => {
  const router = useRouter()
  const store = useStore()
  /** 契約書更新の編集中かどうか */
  const isEditMode = Boolean(Number(router.currentRoute.query.contract_id))

  // 契約更新時に新規で作成された見積かどうか
  const isNewEstimate: BaseEstimateState['isNewEstimate'] =
    store.getters['Contracts/BaseEstimate/getIsNewEstimate']

  /**
   * 契約書入力確認画面へ遷移する。
   * 契約書更新フローへ入る際に使用する
   * @param id 契約書ID
   */
  const goToConfirmation = (id: number) => {
    router.push({
      path: '/contracts/confirmation',
      query: {
        contract_id: id.toString(),
      },
    })
  }

  /**
   * 変更タブでの遷移に編集中であることを示す`contract_id`を付与する
   * @param targetUrl 各タブへのリンク
   */
  const goToTab = (targetUrl: string, id: number) => {
    router.push({
      path: targetUrl,
      query: {
        contract_id: id.toString(),
      },
    })
  }

  /**
   * 変更タブでの遷移に編集中であることを示す`contract_id`を付与する
   * @param targetUrl 各タブへのリンク
   */
  const goToEstimateTab = (targetUrl: string, contract_id: number) => {
    router.push({
      path: targetUrl,
      query: {
        contract_id: contract_id.toString(),
      },
    })
  }

  /**
   * クエリーから契約書IDを取得する
   * @returns
   */
  const getContractIdFromQuery = () =>
    Number(router.currentRoute.query.contract_id)

  /**
   * `contract_id`のクエリが付与されたタブへのパスを生成する
   * @param url
   */
  const generateEditQueryPath = (url: string) => {
    return url + `?contract_id=${getContractIdFromQuery()}`
  }

  /**
   * 契約更新フロー開始前に、不要な(非表示タブの)store情報を削除する
   * @param tabList
   */
  const deleteHiddenTabStoreData = (tabList: ContractsTabListInterface[]) => {
    // 表示されていないタブの配列
    const hiddenTabList = tabList.filter((tab) => tab.isHidden)

    // storeデータの初期化
    hiddenTabList.forEach((tab) => {
      const text = tab.text as ContractStorePath
      store.commit(`Contracts/${contractStorePath[text]}/delete`)
    })

    tabList.forEach((tab) => {
      const text = tab.text as ContractStorePath

      // storeのタブvalidateを全てnullへ
      store.commit(`Contracts/${contractStorePath[text]}/setIsValidate`, null)
    })
  }

  /**
   * 新規の見積情報を使用するかどうかの判定をする
   * @param tabText
   */
  const getIsUseNewEstimateInfo = (tabText: ContractStorePath) => {
    // 契約更新時に新規で作成された見積かどうか
    const isNewEstimate: BaseEstimateState['isNewEstimate'] =
      store.getters['Contracts/BaseEstimate/getIsNewEstimate']

    // タブの入力が完了しているかどうか
    const isTabValidate: boolean | null =
      store.getters[`Contracts/${contractStorePath[tabText]}/getIsValidate`]

    return isNewEstimate && isTabValidate === null
  }

  return {
    isEditMode,
    isNewEstimate,
    goToConfirmation,
    goToTab,
    getContractIdFromQuery,
    generateEditQueryPath,
    goToEstimateTab,
    deleteHiddenTabStoreData,
    getIsUseNewEstimateInfo,
  }
}

export default useContractEdit
