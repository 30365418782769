import { computed, Ref } from '@nuxtjs/composition-api'
import { EstimateDetail } from '~/api/estimateRepository'
import ProductId from '~/constants/enums/productClassification'

export const useRestateWPhoto商品設定 = (
  estimate: Ref<EstimateDetail | undefined>
) => {
  return computed(() => {
    const items = estimate.value?.w_photo_plan?.items
    if (!items) return

    const obiageCount = items.filter(
      (item) => item.product_type_id === ProductId.obiage
    ).length

    const kamikazariCount = items.filter(
      (item) => item.product_type_id === ProductId.kasaneeri
    ).length

    return {
      count帯揚げ: obiageCount,
      count重ね衿: kamikazariCount,
    }
  })
}
