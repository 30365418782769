import { ActionTree } from 'vuex'
import { RootState, ProductState, Product } from '../../../types'

const actions: ActionTree<ProductState, RootState> = {
  foo: async ({ commit }, product: Product) => {
    if (await new Promise(() => 'bar')) {
      commit('foo', product)
      return true // 成功
    }
    return false // 失敗
  },

  setIsValidate: ({ commit }, isValidate: boolean) => {
    commit('setIsValidate', isValidate)
  },
}

export default actions
