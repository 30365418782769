























import {
  defineComponent,
  ref,
  useRouter,
  useRoute,
  useStore,
} from '@nuxtjs/composition-api'
import AppModalWindow from './AppModalWindow.vue'
import TheDialogTempSave from './TheDialogTempSave.vue'
import { ESTIMATE } from '@/constants/estimate/config'
import { Store } from 'vuex'
import { useTemporarilySaved } from '~/composable/api/useTemporarilySaved'
import { useInitializeState } from '~/composable/general/useInitializeState'
import { useGraduateEstimateRequest } from '~/composable/estimate/service/graduation/EstimateRequest'
import AppPinkButton from 'storybook/components/atoms/AppPinkButton.vue'
import { useEstimateList } from '~/composable/estimate/service/comingOfAge/EstimateList'

export default defineComponent({
  components: {
    AppPinkButton,
  },
  setup(_, { emit }) {
    const store = useStore() as Store<any>
    const router = useRouter()
    const route = useRoute()
    const modal = ref<InstanceType<typeof AppModalWindow> | null>()
    const dialogTempSave = ref<InstanceType<typeof TheDialogTempSave> | null>()
    const { clickCompleted } = useEstimateList()

    const { saveEstimateTemporarilyWithAlert, saveEstimateTemporarily } =
      useTemporarilySaved()
    const { initEstimateStore } = useInitializeState()
    const { postGraduateEstimate } = useGraduateEstimateRequest()

    const openModal = () => modal.value?.open()
    const closeModal = () => modal.value?.close()

    // 「反映」ボタン押下時
    const reflectEstimate = async () => {
      try {
        emit('changeLoading', true)

        const isGraduation = getTypeId() === ESTIMATE.卒業式.id
        if (isGraduation) {
          const res = await postGraduateEstimate()

          if (typeof res === 'boolean') {
            closeModal()
            return
          }

          if (!res.estimateId) {
            throw new Error('estimateIdが取得できませんでした')
          }
          const branchNumber = 1 // MEMO:新規作成なので１にしている

          const url = `/estimate/graduationCeremony/${res.estimateId}/complete?estimate_id=${res.estimateId}&branch=${branchNumber}`

          // 作成後、storeをリセット
          initEstimateStore()
          router.push(url)
        } else {
          // 成人式の見積作成
          await clickCompleted(true)
        }
      } catch (error) {
        // UAT環境でのバグの原因突き止め時間を短縮するため、失敗時は仮保存する
        saveEstimateTemporarilyWithAlert(ESTIMATE.成人式.id, '作成')
      } finally {
        emit('changeLoading', false)
      }

      closeModal()
    }

    // 現在のpathから保存する見積の種類を判定
    const getTypeId = () => {
      const url = route.value.path

      if (url.match(/comingOfAgeCeremony/)) {
        return ESTIMATE.成人式.id
      }
      if (url.match(/graduationCeremony/)) {
        return ESTIMATE.卒業式.id
      }
      return ESTIMATE.NONE.id
    }

    // 仮保存処理
    const tempSave = () => {
      const typeId = getTypeId()
      typeId && saveEstimateTemporarily(typeId)
      closeModal()
      dialogTempSave.value?.openModal()
    }

    return {
      modal,
      openModal,
      closeModal,
      dialogTempSave,
      tempSave,
      reflectEstimate,
    }
  },
})
