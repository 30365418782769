/* eslint-disable camelcase */
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { AxiosResponse, AxiosInstance } from 'axios'
import { AmountAdjustments, MayBe } from '~/store/types'

/** types */
import type {
  CheckboxRecord,
  ChecklistProduct,
  ProductTypeIdList,
} from '~/api/checklistRepository'

export type Photo = {
  url: string
  is_main_picture: boolean
}

export type Hurisode = {
  product_id?: MayBe<number>
  catalog_code?: MayBe<string>
  product_name: string
  product_type_id: number
  digit_shaku: MayBe<number>
  digit_sun: MayBe<number>
  digit_bu: MayBe<number>
  sleeve_shaku: MayBe<number>
  sleeve_sun: MayBe<number>
  sleeve_bu: MayBe<number>
  discount_type?: number
  estimate_hurisode_id?: number // 契約と紐づく見積を取得した場合に付与されているDB用の値
}

export type Item = {
  product_id?: MayBe<number>
  product_type_id: number
  product_name: string
  plan: number
  price_type?: number | null
  product_classification_id: number
  order_number?: number
  catalog_code?: string
  estimate_item_id?: number // 契約と紐づく見積を取得した場合に付与されているDB用の値
}

export type Discounts = {
  discount_type: number
  type: number
  rate?: number
  amount?: number
}

export type Bonus = {
  bonus_type: MayBe<number>
  is_passed: MayBe<number>
  passed_date: MayBe<string>
  photo_type?: MayBe<number>
  notes: MayBe<string>
}

export type ReferralBonus = {
  introducer_id: number
  introducer: MayBe<Bonus>
  user: MayBe<Bonus>
}

export type Family = {
  customer_family_id?: MayBe<number>
  customer_id: MayBe<number>
  last_name: MayBe<string>
  first_name: MayBe<string>
  last_name_kana: MayBe<string>
  first_name_kana: MayBe<string>
  family_role: MayBe<number>
  birthdate: string
  mobile_phone_number: MayBe<string>
  is_parent: MayBe<boolean>
  is_contractor: MayBe<boolean>
}

type Salon = {
  beauty_salon_id?: MayBe<number>
  beauty_salon_abbreviated_name?: MayBe<string>
  beauty_salon_entrance_time: string
  beauty_salon_phone_number?: MayBe<string>
}

type Shop = {
  shop_id: number
  shop_abbreviated_name?: MayBe<string>
  shop_entrance_time: string
}

type PhotosInAdvance = {
  date: string
  photo_plan_id: number
  photo_plan_name?: MayBe<string>
  pose_number: number
  number_of_books: number
  other?: MayBe<string>
  referral_bonus_pose_number?: MayBe<number>
  remarks?: MayBe<string>
  need_makeup: boolean | null
} & Salon &
  Shop

export type JkPhotoInAdvanceRequestParams = MayBe<
  {
    hurisode: {
      product_id?: MayBe<number>
      catalog_code?: MayBe<string>
      product_name: string
      product_classification_id: number
      product_type_id: number
      digit_shaku: MayBe<number>
      digit_sun: MayBe<number>
      digit_bu: MayBe<number>
      sleeve_shaku: MayBe<number>
      sleeve_sun: MayBe<number>
      sleeve_bu: MayBe<number>
      other?: MayBe<string>
      price: number
      check_list_branch_number?: MayBe<number> // チェックリスト変更検知用
    }
    jk_hurisode_price_for_cancellation_fee: MayBe<number>
    photo_url: string
    nagajuban: {
      number: number
      size?: MayBe<number>
    }
    waist_string: {
      number: number
      size?: MayBe<number>
    }
    colin_belt?: MayBe<{
      number: number
      size?: MayBe<number>
    }>
    datejime: {
      number: number
      size?: MayBe<number>
    }
    triple_string_pillow_number?: MayBe<number>
    band_plate_number?: MayBe<number>
    collar_number?: MayBe<number>
    towel?: MayBe<number>
    hair_ornament?: MayBe<number>
    tabi?: MayBe<{
      color?: string
      passed?: number
      size?: MayBe<number>
    }>
    underwear?: MayBe<{
      passed: number
      size?: MayBe<number>
    }>
    zouri?: MayBe<{
      number: number
      size?: MayBe<number>
    }>
    dressing_place_id?: MayBe<number>
  } & PhotosInAdvance
>

export type PhotoPlan = {
  // デザインアルバム
  album?: {
    pose_number?: number // ポーズ数
    number_of_books?: number // 冊数
    referral_bonus_pose_number?: number // 紹介者特典ポーズ数
  }
  // 六つ切り
  mutsugiri?: {
    // 台紙あり
    with_daishi?: WithDaishi[]
    // 台紙なし
    no_daishi?: {
      pose_number?: number | null // 写真の番号
    }[]
  }
  cabinet?: {
    // 台紙あり
    with_daishi?: WithDaishi[]
    // 台紙なし
    no_daishi?: {
      pose_number?: number | null // 写真の番号
    }[]
  }
  // アクリルスタンド
  acryl_stand?: {
    branch_number?: number // 同じデザインタイプを複数購入した場合のインデックス
    size?: 1 | 2 // サイズ 1: A4 2: A5
    number_of_pieces?: number // 個数
  }[]
  is_buying_final_photo_data?: boolean // USB
  color_comment?: string | null // 着物の色補正指示
}
export type WithDaishi = {
  branch_number?: number // 同じデザインタイプを複数購入した場合のインデックス
  pose_number?: number // ポーズ数
  memo?: MayBe<string> // メモ
  number_of_books?: number // 購入する個数
}

export type MultipleContractPdf = {
  contract_pdf_type: 1 | 2 | 3
  pdf_url: string
}

export type ContractAddition = {
  addition_type: number
  price: number
}

/** 日報 */
export type dailyReportdetails = {
  name: string
  price: number
}

export type CreateContractParams = {
  staff_id: number
  sub_staff_id: number | null
  coming_store_type: number
  contract_service_type: number
  estimate_id: number
  shop_id: number
  login_shop_id?: MayBe<number> // ログイン店舗ID（契約書更新時、「ログイン店舗ID」が契約書作成時と同一かどうかを確認するパラメータ）
  customer_id: number
  mail_address?: MayBe<string>
  amount_adjustments?: AmountAdjustments[]
  home_zip_code?: MayBe<string>
  home_prefecture_id?: MayBe<number>
  home_prefectures?: MayBe<string>
  home_address1?: MayBe<string>
  home_address2?: MayBe<string>
  school_name?: MayBe<string>
  office_name?: MayBe<string>
  graduation_year?: MayBe<string>
  referral_bonus?: MayBe<ReferralBonus>
  notes?: MayBe<string>
  type?: number | null
  hurisode?: {
    other?: MayBe<string>
    price: number
    /** 1: 高級手縫い 2: ハイテク仕立て */
    sewing?: MayBe<1 | 2>
    product_classification_id: number
    discount_type?: number
    check_list_branch_number?: MayBe<number> // チェックリスト変更検知用
  } & Hurisode
  items?: MayBe<
    ({
      price: number
      check_list_branch_number?: MayBe<number> // チェックリスト変更検知用
    } & Item)[]
  >
  // 成人式・卒業式
  check_list?:
    | {
        product_type_id?: number // 品種区分
        branch_number: number // DBのデータ特定（バックエンド用）
        lists: CheckboxRecord[] | null // チェックリスト
      }[]
    | null
  photos: Photo[]
  neck_photo_url: string
  item_photo_url: string
  second_item_photo_url: string
  photos_in_advance?: MayBe<
    {
      delivery_date: string
      delivery_preferred_date: string
      place_id: number
      place_abbreviated_name?: MayBe<string>
      return_processing_types: number
      return_date: string
      return_shop_id: number
      return_shop_abbreviated_name?: MayBe<string>
      dressing_place_id?: MayBe<number>
    } & PhotosInAdvance
  >
  jk_photos_in_advance?: JkPhotoInAdvanceRequestParams
  that_day?: MayBe<
    {
      attendance_area: string
      use_date: string
      /** 0: ご来店 1: 前撮り日にお渡し 2: お送り */
      delivery_processing_types: 0 | 1 | 2 | null
      delivery_date?: MayBe<string>
      delivery_arrival_date?: MayBe<string>
      delivery_shipping_date?: MayBe<string>
      delivery_place_id?: MayBe<number>
      delivery_place_abbreviated_name?: MayBe<string>
      return_processing_type: MayBe<number>
      delivery_info?: MayBe<{
        phone_number: string
        zip_code: string
        prefecture_id: number
        address1: string
        address2: string
      }>
      return_date: MayBe<string>
      return_place_id: MayBe<number>
      return_abbreviated_name?: MayBe<string>
      dressing_place_abbreviated_name?: MayBe<string>
      dressing_charge_shop_id?: MayBe<number>
      dressing_charge_shop_abbreviated_name?: MayBe<string>
      dressing_place_entrance_time?: MayBe<string>
      dressing_place_id?: MayBe<number>
      remarks?: MayBe<string>
      plan_option: MayBe<0 | 1>
      need_makeup?: boolean | null
    } & Omit<Salon, 'beauty_salon_entrance_time'> & {
        // NOTE: 成人式の場合は任意
        beauty_salon_entrance_time?: MayBe<string>
      }
  >
  w_photo_plan?: MayBe<{
    shop_entrance_time: string
    photos?: MayBe<Photo[]>
    neck_photo_url: string
    selected_plan_type: number
    hurisode: {
      product_classification_id: number
      price: number
      check_list_branch_number?: MayBe<number> // チェックリスト変更検知用
      /** 1: 高級手縫い 2: ハイテク仕立て */
      sewing?: MayBe<1 | 2>
    } & Hurisode
    items?: MayBe<
      ({
        rental_price: number
        purchase_price: number
        premium_rental_price?: MayBe<number>
        premium_purchase_price?: MayBe<number>
        check_list_branch_number?: MayBe<number> // チェックリスト変更検知用
        price: number
      } & Item)[]
    >
    // チェック機能用商品チェックリスト（Wフォト）
    check_list?:
      | {
          product_type_id?: number // 品種区分
          branch_number: number // DBのデータ特定（バックエンド用）
          lists: CheckboxRecord[] | null // チェックリスト
        }[]
      | null
    plan_option: MayBe<0 | 1>
    nagajuban?: {
      number: number
      size?: MayBe<number>
    }
    collar_number?: number
    remarks?: MayBe<string>
    total_price?: number
  }>
  mother_and_daughter_plan?: MayBe<{
    using_date_event: number
    shop_entrance_time: string
    beauty_salon_entrance_time: string
    photo_url: string
    remarks?: MayBe<string>
    product?: MayBe<{
      product_id?: MayBe<number>
      product_name: string
      catalog_code?: MayBe<string>
      check_list_branch_number?: MayBe<number> // チェックリスト変更検知用
    }>
    measurement?: MayBe<{
      height: number
      foot_size: number
      bust: number
      hip: number
    }>
    nagajuban: {
      number: number
      size?: MayBe<number>
    }
    band_plate_number?: MayBe<number>
    datejime: {
      number: number
      size?: MayBe<number>
    }
    waist_string: {
      number: number
      size?: MayBe<number>
    }
    collar_number?: MayBe<number>
    obimakura?: MayBe<{
      number: number
      size?: MayBe<number>
    }>
    colin_belt?: MayBe<{
      number: number
      size?: MayBe<number>
    }>
    zouri?: MayBe<{
      number: number
      size?: MayBe<number>
    }>
    things_to_bring?: MayBe<{
      list: number[]
      other_list: string[]
    }>
    // チェック機能用商品チェックリスト（母娘プラン前撮りまたはJK前撮り）
    check_list?:
      | {
          product_type_id?: number // 品種区分
          branch_number: number // DBのデータ特定（バックエンド用）
          lists: CheckboxRecord[] | null // チェックリスト
        }[]
      | null
  }>
  jk_mother_and_daughter_plan?: MayBe<{
    shop_entrance_time: string
    photo_url: string
    remarks?: MayBe<string>
    product?: MayBe<{
      product_id?: MayBe<number>
      product_name: string
      catalog_code?: MayBe<string>
      check_list_branch_number?: MayBe<number> // チェックリスト変更検知用
    }>
    measurement?: MayBe<{
      height: number
      foot_size: number
      bust: number
      hip: number
    }>
    // チェック機能用商品チェックリスト（母娘プランJK前撮り）
    check_list?:
      | {
          product_type_id?: number // 品種区分
          branch_number: number // DBのデータ特定（バックエンド用）
          lists: CheckboxRecord[] | null // チェックリスト
        }[]
      | null
  }>
  measurement?: {
    height?: MayBe<number>
    foot_size?: MayBe<number>
    bust?: MayBe<number>
    hip?: MayBe<number>
    digit_shaku?: MayBe<number>
    digit_sun?: MayBe<number>
    digit_bu?: MayBe<number>
    sleeve_shaku?: MayBe<number>
    sleeve_sun?: MayBe<number>
    sleeve_bu?: MayBe<number>
    digit_correction_after_shaku?: MayBe<number>
    digit_correction_after_sun?: MayBe<number>
    digit_correction_after_bu?: MayBe<number>
    sleeve_correction_after_shaku?: MayBe<number>
    sleeve_correction_after_sun?: MayBe<number>
    sleeve_correction_after_bu?: MayBe<number>
    size_correction_reason?: MayBe<string>
    w_photo_plan_digit_correction_after_shaku?: MayBe<number>
    w_photo_plan_digit_correction_after_sun?: MayBe<number>
    w_photo_plan_digit_correction_after_bu?: MayBe<number>
    w_photo_plan_sleeve_correction_after_shaku?: MayBe<number>
    w_photo_plan_sleeve_correction_after_sun?: MayBe<number>
    w_photo_plan_sleeve_correction_after_bu?: MayBe<number>
  }
  nagajuban: {
    number: number
    size?: MayBe<number>
  }
  waist_string: {
    number: number
    size?: MayBe<number>
  }
  colin_belt?: MayBe<{
    number: number
    size?: MayBe<number>
  }>
  datejime: {
    number: number
    size?: MayBe<number>
  }
  triple_string_pillow_number?: MayBe<number>
  band_plate_number?: MayBe<number>
  collar_number?: MayBe<number>
  towel?: MayBe<number>
  hair_ornament?: MayBe<number>
  tabi?: MayBe<{
    color?: MayBe<string>
    passed?: MayBe<number>
    size?: MayBe<number>
  }>
  underwear?: MayBe<{
    passed: MayBe<number>
    size?: MayBe<number>
  }>
  zouri?: MayBe<{
    number: number
    size?: MayBe<number>
  }>
  things_to_bring?: MayBe<{
    list: number[]
    other_list: string[]
  }>
  families?: MayBe<Family[]>
  mypage_mail_address?: MayBe<string>
  discounts?: MayBe<Discounts[]>
  cooling_off_limit: MayBe<string>
  account_payable_id: MayBe<number>
  product_classification_id?: MayBe<number>
  contract_date: MayBe<string>
  contract_total_price: MayBe<number>
  home_tel: string
  balance_due_date: string
  pdf_url?: string
  estimate_branch_number: MayBe<number>
  contract_status?: MayBe<number>
  plan: number
  plan_option: MayBe<0 | 1>
  multiple_contract_pdfs?: MayBe<(MultipleContractPdf & { id: number })[]>
  additions?: ContractAddition[]
  signature_url: string
  parental_signature_url?: MayBe<string>
  front_store_id?: MayBe<number>
  photo_plan?: MayBe<PhotoPlan>
  hakama?: MayBe<{
    product_id: MayBe<number>
    catalog_code: MayBe<string>
    product_name: string
    rental_price: number
    category: number
    product_type_id: number
    discount_type?: number // 1: 50%引 2: 11,000円引
    estimate_hurisode_id?: number // 契約と紐づく見積を取得した場合に付与されているDB用の値
    check_list_branch_number?: MayBe<number> // チェックリスト変更検知用
  }>
  reference_price_information?: ReferencePriceInformation[]
  contract_reason_id: number[]
  fetched_at?: string // データ取得日時 ※取得時に乗ってきて、更新時のみ送る
  delivery_preferred_date?: string // 納品希望日
  other_details: MayBe<dailyReportdetails[]> //日報に表示する用のその他明細（割引・母娘プラン訪問着レンタル・写真アルバム代等、ARで判定している料金をリクエストする為の明細）
}

export type ContractDetail = {
  contract_id: number
  cancel_date?: string
  that_day_receipt_id?: number
  photos_in_advance_receipt_id?: number
  staff_id: number
  sub_staff_id: number | null
  coming_store_type: number
  contract_service_type: number
  estimate_id: number
  shop_id: number
  customer_id: number
  mail_address?: MayBe<string>
  amount_adjustments?: AmountAdjustments[]
  home_zip_code?: MayBe<string>
  home_prefecture_id?: MayBe<number>
  home_prefectures?: MayBe<string>
  home_address1?: MayBe<string>
  home_address2?: MayBe<string>
  school_name?: MayBe<string>
  office_name?: MayBe<string>
  graduation_year?: MayBe<string>
  referral_bonus?: MayBe<ReferralBonus>
  notes?: MayBe<string>
  type?: MayBe<number>
  hurisode?: {
    other?: MayBe<string>
    price: number
    /** 1: 高級手縫い 2: ハイテク仕立て */
    sewing?: MayBe<1 | 2>
    product_classification_id: number
    discount_type?: number
    check_list_branch_number?: MayBe<number> // チェックリスト変更検知用
  } & Hurisode
  items?: MayBe<
    ({
      price: number
      check_list_branch_number?: MayBe<number> // チェックリスト変更検知用
    } & Item)[]
  >
  // 成人式・卒業式
  check_list_products?: {
    order_number: number
    product_type_id: ProductTypeIdList // 品種区分
    check_list: {
      branch_number: number // DBのデータ特定（バックエンド用）
      lists: CheckboxRecord[] | null // チェックリスト
    }
  }[]
  photos: Photo[]
  neck_photo_url: string
  item_photo_url: string
  second_item_photo_url: string
  cancel_amount: number | null
  photos_in_advance?: MayBe<
    {
      delivery_date: string
      delivery_preferred_date: string
      place_id: number
      place_abbreviated_name?: MayBe<string>
      return_processing_types: number
      return_date: string
      return_shop_id: number
      return_shop_abbreviated_name?: MayBe<string>
      dressing_place_id?: MayBe<number>
    } & PhotosInAdvance
  >
  jk_photos_in_advance?: JkPhotoInAdvanceRequestParams
  that_day?: MayBe<
    {
      attendance_area: string
      use_date: string
      /** 0: ご来店 1: 前撮り日にお渡し 2: お送り */
      delivery_processing_types: 0 | 1 | 2 | null
      delivery_date?: MayBe<string>
      delivery_arrival_date?: MayBe<string>
      delivery_shipping_date?: MayBe<string>
      delivery_place_id?: MayBe<number>
      delivery_place_abbreviated_name?: MayBe<string>
      return_processing_type: MayBe<number>
      delivery_info?: MayBe<{
        phone_number: string
        zip_code: string
        prefecture_id: number
        address1: string
        address2: string
      }>
      return_date: MayBe<string>
      return_place_id: MayBe<number>
      return_abbreviated_name?: MayBe<string>
      dressing_place_abbreviated_name?: MayBe<string>
      dressing_charge_shop_id?: MayBe<number>
      dressing_charge_shop_abbreviated_name?: MayBe<string>
      dressing_place_entrance_time?: MayBe<string>
      dressing_place_id?: MayBe<number>
      remarks?: MayBe<string>
      plan_option: MayBe<0 | 1>
      need_makeup?: boolean | null
    } & Omit<Salon, 'beauty_salon_entrance_time'> & {
        // NOTE: 成人式の場合は任意
        beauty_salon_entrance_time?: MayBe<string>
      }
  >
  w_photo_plan?: MayBe<{
    shop_entrance_time: string
    photos?: MayBe<Photo[]>
    neck_photo_url: string
    selected_plan_type: number
    hurisode: {
      product_classification_id: number
      price: number
      /** 1: 高級手縫い 2: ハイテク仕立て */
      sewing?: MayBe<1 | 2>
      check_list_branch_number?: MayBe<number> // チェックリスト変更検知用
    } & Hurisode
    items?: MayBe<
      ({
        rental_price: number
        purchase_price: number
        premium_rental_price?: MayBe<number>
        premium_purchase_price?: MayBe<number>
        check_list_branch_number?: MayBe<number> // チェックリスト変更検知用
        price: number
      } & Item)[]
    >
    // チェック機能用商品チェックリスト（Wフォト）
    check_list_products?: {
      order_number: number
      product_type_id: ProductTypeIdList // 品種区分
      check_list: {
        branch_number: number // DBのデータ特定（バックエンド用）
        lists: CheckboxRecord[] | null // チェックリスト
      }
    }[]
    plan_option: MayBe<0 | 1>
    nagajuban?: {
      number: number
      size?: MayBe<number>
    }
    collar_number?: number
    remarks?: MayBe<string>
    total_price?: number
  }>
  mother_and_daughter_plan?: MayBe<{
    using_date_event: number
    shop_entrance_time: string
    beauty_salon_entrance_time: string
    photo_url: string
    remarks?: MayBe<string>
    product?: MayBe<{
      product_id?: MayBe<number>
      product_name: string
      catalog_code?: MayBe<string>
      check_list_branch_number?: MayBe<number> // チェックリスト変更検知用
    }>
    measurement?: MayBe<{
      height: number
      foot_size: number
      bust: number
      hip: number
    }>
    nagajuban: {
      number: number
      size?: MayBe<number>
    }
    band_plate_number?: MayBe<number>
    datejime: {
      number: number
      size?: MayBe<number>
    }
    waist_string: {
      number: number
      size?: MayBe<number>
    }
    collar_number?: MayBe<number>
    obimakura?: MayBe<{
      number: number
      size?: MayBe<number>
    }>
    colin_belt?: MayBe<{
      number: number
      size?: MayBe<number>
    }>
    zouri?: MayBe<{
      number: number
      size?: MayBe<number>
    }>
    things_to_bring?: MayBe<{
      list: number[]
      other_list: string[]
    }>
    // チェック機能用商品チェックリスト（母娘プラン前撮りまたはJK前撮り）
    check_list_products?: {
      order_number: number
      product_type_id: ProductTypeIdList // 品種区分
      check_list: {
        branch_number: number // DBのデータ特定（バックエンド用）
        lists: CheckboxRecord[] | null // チェックリスト
      }
    }[]
  }>
  jk_mother_and_daughter_plan?: MayBe<{
    shop_entrance_time: string
    photo_url: string
    remarks?: MayBe<string>
    product?: MayBe<{
      product_id?: MayBe<number>
      product_name: string
      catalog_code?: MayBe<string>
      check_list_branch_number?: MayBe<number> // チェックリスト変更検知用
    }>
    measurement?: MayBe<{
      height: number
      foot_size: number
      bust: number
      hip: number
    }>
    // チェック機能用商品チェックリスト（母娘プランJK前撮り）
    check_list_products?: {
      order_number: number
      product_type_id: ProductTypeIdList // 品種区分
      check_list: {
        branch_number: number // DBのデータ特定（バックエンド用）
        lists: CheckboxRecord[] | null // チェックリスト
      }
    }[]
  }>
  measurement?: {
    height?: MayBe<number>
    foot_size?: MayBe<number>
    bust?: MayBe<number>
    hip?: MayBe<number>
    digit_shaku?: MayBe<number>
    digit_sun?: MayBe<number>
    digit_bu?: MayBe<number>
    sleeve_shaku?: MayBe<number>
    sleeve_sun?: MayBe<number>
    sleeve_bu?: MayBe<number>
    digit_correction_after_shaku?: MayBe<number>
    digit_correction_after_sun?: MayBe<number>
    digit_correction_after_bu?: MayBe<number>
    sleeve_correction_after_shaku?: MayBe<number>
    sleeve_correction_after_sun?: MayBe<number>
    sleeve_correction_after_bu?: MayBe<number>
    size_correction_reason?: MayBe<string>
    w_photo_plan_digit_correction_after_shaku?: MayBe<number>
    w_photo_plan_digit_correction_after_sun?: MayBe<number>
    w_photo_plan_digit_correction_after_bu?: MayBe<number>
    w_photo_plan_sleeve_correction_after_shaku?: MayBe<number>
    w_photo_plan_sleeve_correction_after_sun?: MayBe<number>
    w_photo_plan_sleeve_correction_after_bu?: MayBe<number>
  }
  nagajuban: {
    number: number
    size?: MayBe<number>
  }
  waist_string: {
    number: number
    size?: MayBe<number>
  }
  colin_belt?: MayBe<{
    number: number
    size?: MayBe<number>
  }>
  datejime: {
    number: number
    size?: MayBe<number>
  }
  triple_string_pillow_number?: MayBe<number>
  band_plate_number?: MayBe<number>
  collar_number?: MayBe<number>
  towel?: MayBe<number>
  hair_ornament?: MayBe<number>
  tabi?: MayBe<{
    color?: string
    passed?: number
    size?: MayBe<number>
  }>
  underwear?: MayBe<{
    passed: number
    size?: MayBe<number>
  }>
  zouri?: MayBe<{
    number: number
    size?: MayBe<number>
  }>
  things_to_bring?: MayBe<{
    list: number[]
    other_list: string[]
  }>
  families?: MayBe<Family[]>
  discounts?: MayBe<Discounts[]>
  cooling_off_limit: MayBe<string>
  account_payable_id: MayBe<number>
  product_classification_id?: MayBe<number>
  contract_date: MayBe<string>
  contract_total_price: MayBe<number>
  home_tel: string
  balance_due_date: string
  pdf_url?: string
  estimate_branch_number: MayBe<number>
  contract_status?: MayBe<number>
  plan: number
  plan_option: MayBe<0 | 1>
  multiple_contract_pdfs?: MayBe<(MultipleContractPdf & { id: number })[]>
  additions?: ContractAddition[]
  signature_url: string
  parental_signature_url?: MayBe<string>
  front_store_id?: MayBe<number>
  photo_plan?: MayBe<PhotoPlan>
  hakama?: MayBe<{
    product_id: MayBe<number>
    catalog_code: MayBe<string>
    product_name: string
    rental_price: number
    category: number
    product_type_id: number
    discount_type?: number // 1: 50%引 2: 11,000円引
    estimate_hurisode_id?: number // 契約と紐づく見積を取得した場合に付与されているDB用の値
    check_list_branch_number?: MayBe<number> // チェックリスト変更検知用
  }>
  reference_price_information?: ReferencePriceInformation[]
  contract_reason_id: number[]
  fetched_at?: string // データ取得日時 ※取得時に乗ってきて、更新時のみ送る
  delivery_preferred_date?: string // 納品希望日
  contract_updated_date?: ContractUpdatedDate[] // 成人式フルセット購入プランに変更したかどうか
}
/** 1: 最新の契約書更新日 2: プラン変更日 */
export type ContractUpdatedDate = {
  type: 1 | 2
  date: string | null
}

export type Contract = {
  id: number
  contract_id: number
  cancel_date: string | null
  event_type: number
  contract_service_name: string
  jk_photos_in_advance_date: string | null
  photos_in_advance_date: string | null
  use_date: string | null
  contract_total_price: string
  deposit: number | null
  balance: number | null
  contract_date: string
  state_contract_name: string
  photo_url: string | null
  pdf_url: string | null
  plan: number | null
}

// ** 契約書検索 **
export type ContractSearchQueryParams = {
  customer_id?: number
  contract_id?: number
  start_date?: string
  end_date?: string
  shop_ids?: number[]
  event_type?: number
}

export type WPhoto = Exclude<
  CreateContractParams['w_photo_plan'],
  undefined | null
>
export type MotherAndDaughterPlan = Exclude<
  CreateContractParams['mother_and_daughter_plan'],
  undefined | null
>
export type JKmotherAndDaughterPlan = Exclude<
  CreateContractParams['jk_mother_and_daughter_plan'],
  undefined | null
>

//* ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓ 参列 ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓*/

export type AttendanceContractCustomer = {
  estimate_id: number
  customer_id: number
  is_delegate: number
  use_date: string
  dressing: {
    shop_id: number
    shop_entrance_time: string
    dressing_place_id: number | null
  } | null
  delivery: {
    delivery_processing_types: number | null
    date: string
    shop_id: number | null
    delivery_info: {
      phone_number: string
      zip_code: string
      prefecture_id: number
      address1: string
      address2: string | null
      recipient_name: string | null
      contents: string | null
      desired_time: number | null
    } | null
  } | null
  return: {
    return_processing_type: number | null
    date: string
    shop_id: number
  }
  corrections:
    | {
        type: number
        shaku: number
        sun: number
        bu: number
      }[]
    | null
  product_reservation: { product_id: number; product_type_id: number }[] | null
  dressing_items?: {
    size_name?: string | null
    number?: number | null
    has_delivery?: number | null
    color?: string | null
    type: number
  }[]
  check_list_products?: {
    product_type_id: number
    order_number: number | null
    check_list: {
      product_type_id?: number
      branch_number: number | null
      estimate_hurisode_id: number | null
      estimate_item_id: number | null
      lists: CheckboxRecord[] | null
    } | null
  }[]
  things_to_bring?: {
    list: number[]
    other_list: string[]
  }
  additions: ContractAddition[] | null
  relatives: number
}

export type AttendanceReferralBonus = {
  introducer_id: number
  introducer: {
    bonus_type: number | null
    is_passed: number | null
    passed_date: string | null
    notes: string | null
  } | null
  user: {
    bonus_type: number | null
    is_passed: number | null
    passed_date: string | null
    notes: string | null
  } | null
}

export type PriceTableType = {
  type: number
  rate: number | null
  amount: number | null
}

export type AttendanceContractsDetail = {
  staff_id: number
  sub_staff_id: number | null
  shop_id: number
  receipt_id: number
  signature_url: string
  contract_total_price: number
  balance_due_date: string | null
  cooling_off_limit: string | null
  account_payable_id: number | null
  notes: string | null
  contract_date: string
  cancel_date: string
  cancel_amount: number | null
  contract_customers: AttendanceContractCustomer[]
  referral_bonus: AttendanceReferralBonus | null
  reference_price_information: PriceTableType[]
}

// ** 契約書作成 **
export type CreateAttendanceContractParams = {
  staff_id: number
  sub_staff_id: number | null
  shop_id: number
  memo: string | null
  is_chance?: 0 | 1 | null
  contract_date: string
  signature_url: string
  contract_total_price: number
  balance_due_date: string | null
  cooling_off_limit: string | null
  account_payable_id: number | null
  notes: string | null
  payments: MayBe<Payments>
  contract_customers: AttendanceContractCustomerForCreate[]
  referral_bonus: ReferralBonus | null
  reference_price_information: PriceTableType[]
}

type ReferencePriceInformation = {
  type: number
  rate: number | null
  amount: number | null
}

// ** 契約書更新 **
export type PatchAttendanceContractParams = {
  staff_id: number
  sub_staff_id: number | null
  shop_id: number
  login_shop_id: number
  memo: string | null
  is_chance?: 0 | 1 | null
  contract_date: string
  signature_url: string
  contract_total_price: number
  balance_due_date: string | null
  cooling_off_limit: string | null
  account_payable_id: number | null
  notes: string | null
  contract_customers: AttendanceContractCustomerForCreate[]
  referral_bonus: ReferralBonus | null
  reference_price_information?: ReferencePriceInformation[]
}

type PaymentMethod = {
  methods: number
  tomonokai_points: number | null
  amount: number
}

export type Payments = {
  category: number
  staff_id: number
  due_date: string
  details: MayBe<PaymentMethod[]>
  total: number
  notes: string | null
}

export type AttendanceContractCustomerForCreate = {
  estimate_id: number
  customer_id: number
  is_delegate: number | null
  relatives: number
  use_date: string
  dressing: {
    shop_id: number
    shop_entrance_time: string
    dressing_place_id: number | null
  } | null
  delivery: {
    date: string
    shop_id: number | null
    delivery_info: {
      phone_number: string
      zip_code: string
      prefecture_id: number
      address1: string
      address2: string | null
      recipient_name: string | null
      contents: string | null
    } | null
  } | null
  return: {
    date: string
    shop_id: number
  }
  corrections:
    | {
        type: number
        shaku: number | null
        sun: number | null
        bu: number | null
      }[]
    | null
  product_reservation: null
  dressing_items:
    | {
        size_name: string | null
        number: number | null
        has_delivery: number | null
        color: string | null
        type: number
      }[]
    | null
  check_list?:
    | {
        branch_number: number
        estimate_item_id: number
        estimate_hurisode_id: number | null
        lists: CheckboxRecord[] | null
      }[]
    | null
  things_to_bring: {
    list: number[] | null
    other_list: string[] | null
  }
  additions: { addition_type: number; price: number }[] | null
}

//* ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑ 参列 ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑*/
//* ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓ クリーニング ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓*/
export type CleaningDelivery = {
  date: string
  desired_time: number
  delivery_info: {
    type: number
    phone_number: string
    zip_code: string
    prefecture_id: number
    address1: string
    address2: string | null
  }
}
export type CleaningProducts = {
  product_type_id: number
  features: string
  additions?: ContractAddition[]
  check_list_branch_number: number | null
  check_list?: ChecklistProduct['check_list'] | null
}
type UpdateCleaningOptional = 'payments'

export type CreateCleaningContractParams = {
  staff_id: number
  balance_due_date: string
  account_payable_id: number | null
  signature_url: string
  shop_id: number
  item_photo_url: string
  contract_total_price: number
  notes: string | null
  contractor_id: number
  memo: string | null
  is_chance?: 0 | 1 | null
  purchaser_id: MayBe<number>
  payments: MayBe<Payments>
  delivery: CleaningDelivery
  products: CleaningProducts[]
  discounts?: Discounts[]
  contract_date: string
}

export type UpdateCleaningContractParams = Omit<
  CreateCleaningContractParams,
  UpdateCleaningOptional
>

export type CleaningContractForDetail = {
  staff_id: number
  balance_due_date: string
  account_payable_id: number
  signature_url: string
  shop_id: number
  contract_date: string
  receipt_id: number
  item_photo_url: string
  contract_total_price: number
  notes: string | null
  cancel_date: string | null
  cancel_amount: number | null
  contractor_id: number
  purchaser_id: number | null
  delivery: CleaningDelivery
  products: CleaningProducts[]
  discounts: Discounts[]
}

//* ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑ クリーニング ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑*/
export type ContractRepository = {
  create: (
    params: CreateContractParams
  ) => Promise<AxiosResponse<{ contract_id: number; id?: number }>>
  createAttendance: (
    params: CreateAttendanceContractParams
  ) => Promise<AxiosResponse<{ id: number }>>
  patchAttendance: (
    contractId: number,
    params: PatchAttendanceContractParams
  ) => Promise<AxiosResponse<{ id: number }>>
  show: (contractId: number) => Promise<AxiosResponse<ContractDetail>>
  showAttendance: (
    contractId: number
  ) => Promise<AxiosResponse<AttendanceContractsDetail>>
  createCleaning: (
    params: CreateCleaningContractParams
  ) => Promise<AxiosResponse<{ id: number }>>
  updateCleaning: (
    id: number,
    params: UpdateCleaningContractParams
  ) => Promise<AxiosResponse<{ id: number }>>
  showCleaning: (
    contractId: number
  ) => Promise<AxiosResponse<CleaningContractForDetail>>
  findAll: (params: {
    customer_id?: number
    contract_id?: number
    type?: number
  }) => Promise<AxiosResponse<{ contracts: Contract[] }>>
  update: (
    id: number,
    data: CreateContractParams
  ) => Promise<AxiosResponse<{ contract_id: number }>>
  updatePDFUrl: (
    contractId: number,
    params: {
      multiple_contract_pdfs: MultipleContractPdf[]
    }
  ) => Promise<AxiosResponse<{ contract_id: number }>>
}

export default (
  client: NuxtAxiosInstance | AxiosInstance
): ContractRepository => {
  return {
    create: (params) => client.post('contracts', params),
    createAttendance: (params) => client.post('contracts/attendance', params),
    patchAttendance: (contractId, params) =>
      client.patch('contracts/attendance/' + contractId, params),
    show: (contractId) => client.get('contracts/' + contractId),
    findAll: (params) => client.get('contracts', { params }),
    update: (id, data) => client.patch('contracts/' + id, data),
    updatePDFUrl: (id, params) =>
      client.patch('contracts/' + id + '/pdf', params),
    showAttendance: (contractId) =>
      client.get<AttendanceContractsDetail>(
        `contracts/attendance/${contractId}`
      ),
    createCleaning: (params) => client.post('contracts/cleaning', params),
    updateCleaning: (contractId, params) =>
      client.patch(`contracts/cleaning/${contractId}`, params),
    showCleaning: (contractId) =>
      client.get<CleaningContractForDetail>(`contracts/cleaning/${contractId}`),
  }
}
