export const usePrice = () => {
  /*
   * 金額を"¥0,000"の形式にフォーマットする関数
   */
  const toString = (price: number) => {
    if (!price) return '¥0'
    return '¥' + Number(price).toLocaleString()
  }
  /*
   * 金額をnumberにする関数
   */
  const convertPriceToNumber = (price: string | null): number => {
    if (!price) return 0
    const convertedPrice = price.replace(/¥|,/g, '')
    return Number(convertedPrice)
  }

  /*
   * 金額をnumberにする関数
   */
  const toNumber = (price: string) => {
    const isParamString = Number(price) || Number(price) === 0
    if (!price.match(/¥/) && !isParamString)
      throw new Error('正しい値を引数にしてください')
    const convertPrice = price.replace(/¥|,/g, '')
    return Number(convertPrice)
  }
  /*
   * 値引き後の金額を計算して返す。例：80%引きならrateに80を渡す
   * 浮動小数点対策で先にpriceとrateを計算して、その後に100で割る。
   */
  const getDiscounted = (price: number | string, rate: number): number => {
    const priceNum = typeof price === 'number' ? price : toNumber(price)
    return Math.ceil((priceNum * rate) / 100)
  }

  /*
   * レスポンスで受け取る金額intまたは"0000"形式のstringどちらも"¥0,000"にフォーマットする
   */
  const paramToString = (price: string | number): string => {
    if (typeof price === 'number') return toString(price)
    return toString(toNumber(price))
  }

  /**
   * 同一商品の合計金額を返却する関数
   */
  const calculateSameProductTotalPrice = (price: number, number: number) => {
    return price * number
  }

  return {
    toString,
    convertPriceToNumber,
    toNumber,
    getDiscounted,
    paramToString,
    calculateSameProductTotalPrice,
  }
}
