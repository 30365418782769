








import { defineComponent, onMounted } from '@nuxtjs/composition-api'
import { useAuth } from '~/composable/api/useAuth'
import { useOnline } from '~/composable/general/useOnline'

export default defineComponent({
  setup() {
    const online = useOnline()
    const { validateSession } = useAuth()

    onMounted(() => {
      validateSession()
    })
  },
})
