/**
 * 続柄
 */
export default class FamilyRole {
  /** 父 */
  static readonly Father = 1
  /** 母 */
  static readonly Mother = 2
  /** 姉 */
  public static readonly OLDER_SISTER = 3
  /** 妹 */
  public static readonly YOUNGER_SISTER = 4
  /** 兄 */
  public static readonly OLDER_BROTHER = 5
  /** 弟 */
  public static readonly YOUNGER_BROTHER = 6
  /** 長女 */
  static readonly EldestDaughter = 11
  /** 二女 */
  static readonly SecondDaughter = 12
  /** 三女 */
  static readonly ThirdDaughter = 13
  /** 四女*/
  static readonly FourthDaughter = 14
  /** 五女 */
  static readonly FifthDaughter = 15
  /** 六女以降 */
  static readonly SixthDaughter = 16
  /** 長男 */
  static readonly EldestBrother = 21
  /** 二男 */
  static readonly SecondBrother = 22
  /** 三男 */
  static readonly ThirdBrother = 23
  /** 四男 */
  static readonly FourthBrother = 24
  /** 五男 */
  static readonly FifthBrother = 25
  /** 六男以降 */
  static readonly SixthBrother = 26
  /** 夫 */
  static readonly Husband = 31
  /** 娘 */
  static readonly Daughter = 32
  /** 息子 */
  static readonly Son = 33
  /** その他（女性） */
  static readonly OtherWoman = 98
  /** その他（男性） */
  static readonly OtherMan = 99

  static toWord = (val: number) => {
    switch (val) {
      case FamilyRole.Father:
        return '父'
      case FamilyRole.Mother:
        return '母'
      case FamilyRole.EldestDaughter:
        return '長女'
      case FamilyRole.SecondDaughter:
        return '二女'
      case FamilyRole.ThirdDaughter:
        return '三女'
      case FamilyRole.FourthDaughter:
        return '四女'
      case FamilyRole.FifthDaughter:
        return '五女'
      case FamilyRole.SixthDaughter:
        return '六女'
      case FamilyRole.EldestBrother:
        return '長男'
      case FamilyRole.SecondBrother:
        return '二男'
      case FamilyRole.ThirdBrother:
        return '三男'
      case FamilyRole.FourthBrother:
        return '四男'
      case FamilyRole.FifthBrother:
        return '五男'
      case FamilyRole.SixthBrother:
        return '六男'
      case FamilyRole.Husband:
        return '夫'
      case FamilyRole.Daughter:
        return '娘'
      case FamilyRole.Son:
        return '息子'
      case FamilyRole.OtherWoman:
        return 'その他（女性）'
      case FamilyRole.OtherMan:
        return 'その他（男性）'
      default:
        return 'ー'
    }
  }

  static toID = (val: string) => {
    switch (val) {
      case '父':
        return FamilyRole.Father
      case '母':
        return FamilyRole.Mother
      case '長女':
        return FamilyRole.EldestDaughter
      case '二女':
        return FamilyRole.SecondDaughter
      case '三女':
        return FamilyRole.ThirdDaughter
      case '四女':
        return FamilyRole.FourthDaughter
      case '五女':
        return FamilyRole.FifthDaughter
      case '六女':
        return FamilyRole.SixthDaughter
      case '長男':
        return FamilyRole.EldestBrother
      case '二男':
        return FamilyRole.SecondBrother
      case '三男':
        return FamilyRole.ThirdBrother
      case '四男':
        return FamilyRole.FourthBrother
      case '五男':
        return FamilyRole.FifthBrother
      case '六男':
        return FamilyRole.SixthBrother
      case '夫':
        return FamilyRole.Husband
      case '娘':
        return FamilyRole.Daughter
      case '息子':
        return FamilyRole.Son
      case 'その他（女性）':
        return FamilyRole.OtherWoman
      case 'その他（男性）':
        return FamilyRole.OtherMan
      default:
        return 0
    }
  }

  /** ご兄弟姉妹 */
  static SisBroAndDauSan = [
    FamilyRole.EldestDaughter,
    FamilyRole.SecondDaughter,
    FamilyRole.ThirdDaughter,
    FamilyRole.FourthDaughter,
    FamilyRole.FifthDaughter,
    FamilyRole.SixthDaughter,
    FamilyRole.EldestBrother,
    FamilyRole.SecondBrother,
    FamilyRole.ThirdBrother,
    FamilyRole.FourthBrother,
    FamilyRole.FifthBrother,
    FamilyRole.SixthBrother,
  ]

  /** 保護者 */
  static Guardian = [
    FamilyRole.Father,
    FamilyRole.Mother,
    FamilyRole.OtherWoman,
    FamilyRole.OtherMan,
    FamilyRole.Husband,
  ]

  /** その他家族 */
  static OtherCustomer = [
    FamilyRole.OLDER_SISTER,
    FamilyRole.YOUNGER_SISTER,
    FamilyRole.OLDER_BROTHER,
    FamilyRole.YOUNGER_BROTHER,
    FamilyRole.Son,
    FamilyRole.Daughter,
  ]
}
