import { GetterTree } from 'vuex'
import { RootState } from '~/store/types'
import { PDFStoreInfo } from '~/store/types'

const getters: GetterTree<PDFStoreInfo, RootState> = {
  getPDFFailureFlg: (state): PDFStoreInfo['createPDFFailureFlg'] => {
    return state.createPDFFailureFlg
  },
}

export default getters
