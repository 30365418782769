import { GetterTree } from 'vuex'
import { CustomerStoreInfo, RootState } from '../types'

const getters: GetterTree<CustomerStoreInfo, RootState> = {
  getIsReselecting: (state): CustomerStoreInfo['isReselecting'] => {
    return state.isReselecting
  },
}

export default getters
