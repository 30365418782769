import { computed } from '@nuxtjs/composition-api'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { FormValuesプラン } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsプラン'
import { Enumプラン } from '~/constants/estimate/graduationOptions'
import { usePlanFlags } from './PlanFlags'

/**
 * @note 友の会入会かの真偽値を返す
 */
export const useTomonokaiFlag = () => {
  const store = useGraduateStore<FormValuesプラン>('tabプラン')
  const state = store.get<'plan友の会会員'>('plan友の会会員')
  const flags = usePlanFlags()

  return {
    isTomonokai: computed(() => {
      const selecete友の会 = state.value === Enumプラン.友の会会員.はい
      return flags.isKimonoHakama.value && selecete友の会
    }),
  }
}
