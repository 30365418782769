import { Restate } from '~/composable/estimate/repository/graduation/EstimateRestate'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { FormValues半巾帯_袴持込 } from '~/composable/estimate/viewModel/graduation/Fields/Fields半巾帯_袴持込'
import { FormValues半巾帯_袴レンタル } from '~/composable/estimate/viewModel/graduation/Fields/Fields半巾帯_袴レンタル'

export const useRestore半巾帯 = () => {
  const store半巾帯_袴持込 =
    useGraduateStore<FormValues半巾帯_袴持込>('tab半巾帯_袴持込')
  const store半巾帯_袴レンタル =
    useGraduateStore<FormValues半巾帯_袴レンタル>('tab半巾帯_袴レンタル')

  const restore半巾帯 = (restate: Restate) => {
    if (!restate.items.半巾帯) {
      return
    }
    const hanhabaobi = restate.items.半巾帯

    if (!hanhabaobi.flags.hasHakamaRental) {
      /* 初期値を設定 ※必ず入力される値はここで設置 */
      const formValues: FormValues半巾帯_袴持込 = {
        hanhabaobi種別: hanhabaobi.optionId種別 ?? null,
        hanhabaobi形態: hanhabaobi.形態,
        hanhabaobi商品選択: hanhabaobi.商品,
        hanhabaobi単品レンタル価格: hanhabaobi.レンタル価格,
        hanhabaobi単品購入価格: hanhabaobi.購入価格,
        hanhabaobi見積選択: hanhabaobi.optionId見積選択,
      }

      /* ストアに内容を保存する */
      store半巾帯_袴持込.setAll(formValues)
    } else {
      /* 初期値を設定 ※必ず入力される値はここで設置 */
      const formValues: FormValues半巾帯_袴レンタル = {
        hanhabaobi種別: hanhabaobi.optionId種別 ?? null,
        hanhabaobi商品選択: hanhabaobi.商品,
        hanhabaobi形態: hanhabaobi.形態,
        hanhabaobiレンタル価格: hanhabaobi.レンタル価格,
      }

      /* ストアに内容を保存する */
      store半巾帯_袴レンタル.setAll(formValues)
    }
  }

  return { restore半巾帯 }
}
