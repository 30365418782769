import { ActionTree } from 'vuex'
import { RootState, loginUser } from '../types'

const actions: ActionTree<loginUser, RootState> = {
  setUser: ({ commit }, user) => {
    commit('setUser', user)
  },

  delete: ({ commit }) => {
    commit('delete')
  },
}

export default actions
