/* ダブルタップによる拡大を禁止 */
export const prohibitExpansion = () => {
  let t = 0
  document.documentElement.addEventListener(
    'touchend',
    function (e) {
      let now = new Date().getTime()
      if (now - t < 400) {
        e.preventDefault()
      }
      t = now
    },
    false
  )
}

// リロード時にアラートを出すための処理
export const reloadEvent = () => {
  // MEMO: alert()が呼べないっぽいのでデフォルトの文言
  const handleBeforeUnload = (e: Event) => {
    e.preventDefault()
    e.returnValue = true
    return
  }

  const addReloadEvent = () =>
    window.addEventListener('beforeunload', handleBeforeUnload)
  const removeReloadEvent = () =>
    window.removeEventListener('beforeunload', handleBeforeUnload)

  return { addReloadEvent, removeReloadEvent }
}
