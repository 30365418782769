import { MeasuringState } from '../../types'

const state = (): MeasuringState => ({
  input: {
    height: '', // 身長(cm)
    footSize: '', // 足のサイズ(cm)
    bust: '', // バスト(cm)
    hip: '', // ヒップ(cm)
    // 補正前/補正なしの仕立て上がり振袖のサイズ またはプロパー・カタログ時に指定されたサイズ
    digit_shaku: '', // 裄(尺)
    digit_sun: '', // 裄(寸)
    digit_bu: '', // 裄(分)
    sleeve_shaku: '', // 袖(尺)
    sleeve_sun: '', // 袖(寸)
    sleeve_bu: '', // 袖(分)
    // 成人式振袖の補正後の値
    digit_correction_after_shaku: '', // 裄補正後(尺)
    digit_correction_after_sun: '', // 裄補正後(寸)
    digit_correction_after_bu: '', // 裄補正後(分)
    sleeve_correction_after_shaku: '', // 袖補正後(尺)
    sleeve_correction_after_sun: '', // 袖補正後(寸)
    sleeve_correction_after_bu: '', // 袖補正後(分)
    w_photo_plan_digit_correction_after_shaku: '', // 二着目　裄補正後(尺)
    w_photo_plan_digit_correction_after_sun: '', // 二着目　裄補正後(寸)
    w_photo_plan_digit_correction_after_bu: '', // 二着目　裄補正後(分)
    w_photo_plan_sleeve_correction_after_shaku: '', // 二着目　袖補正後(尺)
    w_photo_plan_sleeve_correction_after_sun: '', // 二着目　袖補正後(寸)
    w_photo_plan_sleeve_correction_after_bu: '', // 二着目　袖補正後(分)
  },
  reasonInput: '', // 寸法変更理由
  // 卒業式用
  inputGraduation: {
    height: '', // 身長(cm)
    footSize: '', // 足のサイズ(cm)
    bust: '', // バスト(cm)
    hip: '', // ヒップ(cm)
    // 卒業式振袖の補正後の値
    digit_sun: '', // 裄補正後(寸)
    digit_bu: '', // 裄補正後(分)
    sleeve_sun: '', // 袖補正後(寸)
    sleeve_bu: '', // 袖補正後(分)
  },
  // お客様情報
  customerInfo: {
    height: '', // 身長
    foot_size: '', // 足のサイズ
    bodyType: '', // 体型
  },
  // 仕立て上がりの検索結果から取得した振袖情報
  // WARNING: graduationHurisodeInfoという名前だが卒業式と成人式兼用で使用している
  graduationHurisodeInfo: {
    id: '',
    catalog_code: '',
    product_name: '',
    yuki: '', // 裄
    sode: '', // 袖
    bodyType: '', // 体型
    other: '', // その他
  },
  // Wフォト振袖情報
  wPhotoHurisodeInfo: {
    id: '',
    catalog_code: '',
    product_name: '',
    yuki: '', // 裄
    sode: '', // 袖
    bodyType: '', // 体型
    other: '', // その他
  },
  isValidate: null,
})

export default state
