import { ActionTree } from 'vuex'
import { RootState, OnlyTextState } from '../../../types'

const actions: ActionTree<OnlyTextState, RootState> = {
  setText: ({ commit }, text) => {
    commit('setText', text)
  },

  setIsValidate: ({ commit }, isValidate: boolean) => {
    commit('setIsValidate', isValidate)
  },

  delete: ({ commit }) => {
    commit('delete')
  },
}

export default actions
