import { computed, Ref } from '@nuxtjs/composition-api'
import { EstimateDetail, Items } from '~/api/estimateRepository'
import { ItemPlan } from '~/constants/enums/itemPlan'
import ProductId from '~/constants/enums/productClassification'
import { PLAN } from '~/constants/estimate/comingOfAge'
import { EstimateSinglePriceType } from '~/constants/enums/estimateSinglePriceType'
import { useRestateWPhoto振袖 } from './Restate振袖'

type Kasaneeri = FirstOf振袖レンタル購入 | Others

// 振袖持込ではないプランの1個目とそれ以外で項目が異なるため別の型を用意
type FirstOf振袖レンタル購入 = {
  type: 'firstOf振袖レンタル購入'
  optionId種別: number
  optionId形態: number
  flags: Flags
} & Prices

type Others = {
  type: 'others'
  optionId種別: number
  optionId見積表示: number
  flags: Flags
} & Prices

type Prices = {
  レンタル価格: number
  プレミアムレンタル価格: number
  購入価格: number
  プレミアム購入価格: number
}

type Flags = ReturnType<typeof getConditionalFlags>

export const useRestateWPhoto重ね衿 = (
  estimate: Ref<EstimateDetail | undefined>
) => {
  const restateプラン = useRestateWPhoto振袖(estimate)

  return computed(() => {
    if (!estimate.value?.w_photo_plan?.items || !restateプラン.value) {
      return null
    }

    const is振袖持込 = restateプラン.value.isお持込
    const items = estimate.value.w_photo_plan.items
    const kasaneeriItems = items.filter(
      (item) => item.product_type_id === ProductId.kasaneeri
    )
    const kasaneeris: Kasaneeri[] = []

    if (!kasaneeriItems.length) {
      // レスポンスに重ね衿が含まれていなければ空配列を返す
      return kasaneeris
    }

    if (is振袖持込) {
      // 振袖持込プランの場合,全てプラン外の小物なので同じ処理
      kasaneeriItems.forEach((item) => {
        kasaneeris.push(formatOtherKasaneeri(item, is振袖持込))
      })
    } else {
      // 振袖持込ではないプランの場合,1個目の重ね衿はプラン内に入るので選択項目が違うので個別に処理する
      const first = kasaneeriItems.shift()
      const restItems = kasaneeriItems
      if (first) {
        kasaneeris.push(formatFirstKasaneeri(first, is振袖持込))
      }
      restItems.forEach((item) => {
        kasaneeris.push(formatOtherKasaneeri(item, is振袖持込))
      })
    }

    return kasaneeris
  })
}

const formatFirstKasaneeri = (
  item: Items,
  is振袖持込: boolean
): FirstOf振袖レンタル購入 => {
  const flags = getConditionalFlags(item, is振袖持込)
  return {
    type: 'firstOf振袖レンタル購入',
    optionId種別: getOptionIdOf種別(item.plan),
    optionId形態: getOptionIdOf形態(item.plan),
    レンタル価格: Number(item.rental_price),
    プレミアムレンタル価格: Number(item.premium_rental_price ?? 0),
    購入価格: Number(item.purchase_price ?? 0),
    プレミアム購入価格: Number(item.premium_purchase_price ?? 0),
    flags,
  }
}
const formatOtherKasaneeri = (item: Items, is振袖持込: boolean): Others => {
  const flags = getConditionalFlags(item, is振袖持込)
  return {
    type: 'others',
    optionId種別: getOptionIdOf種別(item.plan),
    optionId見積表示: getOptionIdOf見積表示(item.price_type),
    レンタル価格: Number(item.rental_price),
    プレミアムレンタル価格: Number(item.premium_rental_price ?? 0),
    購入価格: Number(item.purchase_price ?? 0),
    プレミアム購入価格: Number(item.premium_purchase_price ?? 0),
    flags,
  }
}

/**
 * 復元条件に関わる条件式を定義する
 */
const getConditionalFlags = (item: Items, is振袖持込: boolean) => {
  const is持込 = item.plan === ItemPlan.bringIn
  const isセット内 = !is振袖持込 && !is持込 && item.plan === ItemPlan.inSet
  const isランクアップPP内 =
    !is振袖持込 && !is持込 && item.plan === ItemPlan.rankUpInPremium
  const isランクアップPP外 =
    !is振袖持込 && !is持込 && item.plan === ItemPlan.rankUpOutPremium
  return {
    is持込,
    isセット内,
    isランクアップPP内,
    isランクアップPP外,
  }
}

/**
 * 選択肢系の項目の初期値(選択されたoptionのid)を再計算する関数群
 */
const getOptionIdOf種別 = (itemPlan: Items['plan']) => {
  // お持込は全パターン共通ID、その他は振袖のプランによって変動する
  if (itemPlan === ItemPlan.bringIn) {
    return PLAN.BRING_IN
  } else if (itemPlan === ItemPlan.toBeDecided) {
    return PLAN.SINGLE_RENTAL
  } else if (
    itemPlan === ItemPlan.rankUpOutPremium ||
    itemPlan === ItemPlan.rankUpInPremium ||
    itemPlan === ItemPlan.inSet
  ) {
    return PLAN.RENTAL
  } else {
    return 0
  }
}

const getOptionIdOf形態 = (itemPlan: Items['plan']) => {
  switch (itemPlan) {
    case ItemPlan.inSet:
      return PLAN.IN_THE_SET
    case ItemPlan.rankUpInPremium:
      return PLAN.RANK_UP_IN_PREMIUM
    case ItemPlan.rankUpOutPremium:
      return PLAN.RANK_UP_OUT_PREMIUM
    default:
      return 0
  }
}

const getOptionIdOf見積表示 = (priceType: Items['price_type']) => {
  switch (priceType) {
    case EstimateSinglePriceType.singleRentalPrice:
      return PLAN.SINGLE_RENTAL_PRICE
    case EstimateSinglePriceType.singlePurchasePrice:
      return PLAN.SINGLE_BUY_PRICE
    default:
      return 0
  }
}
