export const CHECKLIST_CONTRACT_TYPES = {
  COMING_OF_AGE: 1,
  GRADUATION: 2,
  ATTENDANCE: 3,
  SINGLE_ITEM: 4,
  CLEANING: 10,
} as const
export type ChecklistContractType =
  typeof CHECKLIST_CONTRACT_TYPES[keyof typeof CHECKLIST_CONTRACT_TYPES]

export const CONTRACT_CHECKLIST_TYPES = {
  COA_PHOTO_IN_ADVANCE: 1, // 成人式（前撮り）
  COA_DAY_ONLY: 2, // 成人式（前撮りなし）
  W_PHOTO: 3, // Wフォト
  MOTHER_AND_DAUGHTER_PLAN: 4, // 母娘プラン（前撮り）
  JK_MOTHER_AND_DAUGHTER_PLAN: 5, // 母娘プラン（JK前撮り）
  GRADUATION: 6, // 卒業式
  ATTENDANCE: 7, // 参列
  SINGLE_ITEM: 8, // 単品
  CLEANING: 9, // クリーニング
} as const
export type ContractChecklistType =
  typeof CONTRACT_CHECKLIST_TYPES[keyof typeof CONTRACT_CHECKLIST_TYPES]

export const PDF_HISTORY_TYPE = {
  COA: 1, // 当日
  PHOTO_IN_ADVANCE: 2, // 前撮り
} as const
export type PdfHistoryType =
  typeof PDF_HISTORY_TYPE[keyof typeof PDF_HISTORY_TYPE]
