import { computed } from '@nuxtjs/composition-api'

import {
  useOptionsセット商品選択,
  useOptions小物商品選択,
} from '~/composable/estimate/viewModel/comingOfAge/FieldProps/common/itemOptions'

import ProductClassification from '~/constants/enums/productClassification'
import { GRADUATION_ZORI as PLAN } from '~/constants/estimate/comingOfAge'
import { priceTable } from '~/constants/priceTable'

export const GEINPIN = {
  id: PLAN.ACTUAL_PRODUCT,
  post_number_code: '',
  product_name: '現品',
  price: {
    rental: 0,
    buy: 0,
  },
} as const

export const UNDECIDED = {
  id: PLAN.TO_BE_DECIDED,
  post_number_code: '',
  product_name: '',
  product: {
    rental: {
      label: 'レンタル',
      text: 'こちらでお選びいたします',
    },
    buy: {
      label: '',
      text: '',
    },
  },
  disabled: false,
  price: {
    rental: 0,
    buy: 0,
  },
} as const

export const UNDECIDEDSET = {
  id: PLAN.TO_BE_DECIDED,
  post_number_code: '',
  product_name: '',
  product: {
    rental: {
      label: 'レンタル',
      text: 'こちらでお選びいたします',
    },
    buy: {
      label: '',
      text: '',
    },
  },
  disabled: false,
  price: {
    rental: 0,
    buy: 0,
    set_rental: priceTable.成人式.草履バッグ.セットレンタル,
  },
  isItemInSet: true,
} as const

export const useFieldProps草履 = () => {
  const { itemOptions: itemOptions_セット商品 } = useOptionsセット商品選択(
    ProductClassification.zori
  )
  const { itemOptions_ランクアップ, itemOptions_単品 } = useOptions小物商品選択(
    ProductClassification.zori
  )

  const products_セット商品 = computed(() => {
    return [...itemOptions_セット商品.value, GEINPIN, UNDECIDED]
  })

  const products_ランクアップ = computed(() => {
    return [...itemOptions_ランクアップ.value, GEINPIN]
  })

  const products_持込 = computed(() => {
    return [
      ...itemOptions_セット商品.value,
      ...itemOptions_単品.value,
      GEINPIN,
      UNDECIDEDSET,
    ]
  })

  const products_ランクアップPP外 = [GEINPIN]

  return {
    products_セット商品,
    products_ランクアップ,
    products_持込,
    products_ランクアップPP外,
  }
}

export const useFieldPropsバッグ = () => {
  const { itemOptions: itemOptions_セット商品 } = useOptionsセット商品選択(
    ProductClassification.bag
  )
  const { itemOptions_ランクアップ, itemOptions_単品 } = useOptions小物商品選択(
    ProductClassification.bag
  )

  const products_セット商品 = computed(() => {
    return [...itemOptions_セット商品.value, GEINPIN, UNDECIDED]
  })

  const products_ランクアップ = computed(() => {
    return [...itemOptions_ランクアップ.value, GEINPIN]
  })

  const products_持込 = computed(() => {
    return [
      ...itemOptions_セット商品.value,
      ...itemOptions_単品.value,
      GEINPIN,
      UNDECIDEDSET,
    ]
  })

  const products_ランクアップPP外 = [GEINPIN]

  return {
    products_セット商品,
    products_ランクアップ,
    products_持込,
    products_ランクアップPP外,
  }
}
