import { computed } from '@nuxtjs/composition-api'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { ServicePlanState } from '~/store/types'
import { SERVICE_PLAN } from '~/constants/estimate/comingOfAge'
import { useElderSisterFlag } from './ElderSisterFlag'

/**
 * @note プランタブで選択されたプランから該当idを算出して汎用的に扱うためのフラッグ
 */
export const usePlanTypeFlags = () => {
  const storeプラン = useComingStore<ServicePlanState>('ServicePlans')
  const values = storeプラン.getAll()
  const { isお姉様購入, isNOTお姉様購入 } = useElderSisterFlag()
  const {
    FULL_SET,
    RENTAL,
    BRING_IN,
    BRING_IN_ONLY_PHOTO,
    ONLY_COMIMG_OF_AGE,
  } = SERVICE_PLAN

  const isフルセット = computed(() => {
    return values.value.selections.includes(FULL_SET)
  })

  const is写真のみレンタル = computed(() => {
    return values.value.selections.includes(RENTAL)
  })

  const is写真のみ持込 = computed(() => {
    return values.value.selections.includes(BRING_IN_ONLY_PHOTO)
  })

  // NOTE: 自前の振袖を持ち込み、写真撮影付きのプラン
  const is持込みAコース = computed(() => {
    const 持込プラン選択 = values.value.selections.includes(BRING_IN)
    return 持込プラン選択 && isNOTお姉様購入.value
  })

  // NOTE: お姉様が購入した振袖を持ち込み、当日＋写真撮影付きのプラン
  // 写真のみの場合はお姉様購入振袖でもBコースにはならない
  const is持込みBコース = computed(() => {
    const 当日のみ選択 = values.value.selections.includes(ONLY_COMIMG_OF_AGE)
    return !当日のみ選択 && isお姉様購入.value && !is写真のみ持込.value
  })

  // NOTE: お姉様が購入した振袖を持ち込む場合のみに選択できる着付けだけのコース
  const is当日のみBコース = computed(() => {
    const 当日のみ選択 = values.value.selections.includes(ONLY_COMIMG_OF_AGE)
    return 当日のみ選択 && isお姉様購入.value
  })

  // NOTE: 当日のみBコースではない持込プラン
  const is振袖持込Not当日のみBコース = computed(() => {
    return (
      !is当日のみBコース.value &&
      (is持込みBコース.value || is持込みAコース.value || is写真のみ持込.value)
    )
  })

  const is振袖持込プラン = computed(() => {
    return (
      is持込みBコース.value || is持込みAコース.value || is写真のみ持込.value
    )
  })

  return {
    isフルセット,
    is写真のみレンタル,
    is持込みAコース,
    is持込みBコース,
    is当日のみBコース,
    is写真のみ持込,
    is振袖持込Not当日のみBコース,
    is振袖持込プラン,
  }
}
