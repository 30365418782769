import Vue from 'vue'
import Zendesk from '@dansmaculotte/vue-zendesk'

const KEY = 'fc9ca3d8-f5a1-4803-9c3d-37150aa503d7'
const DISABLED = true
const HIDE_ON_LOAD = false
const SETTINGS = {}

export default function (context) {
  Vue.use(Zendesk, {
    key: KEY,
    disabled: DISABLED,
    hideOnLoad: HIDE_ON_LOAD,
    settings: SETTINGS
  })
}
