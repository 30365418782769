import type { ProductPrice } from '~/api/productRepository'
import type { Product } from '~/api/productRepository'

export type ProductNameList = '草履' | 'バッグ' | '半巾帯'

export type State = {
  itemPrices: ProductPrice[]
  kimonoPrices: ProductPrice[]
  products: { [K in ProductNameList]: Product[] }
}

const state = (): State => ({
  itemPrices: [],
  kimonoPrices: [],
  products: {
    草履: [],
    バッグ: [],
    半巾帯: [],
  },
})

export default state
