import { useStore } from '@nuxtjs/composition-api'
import { Store } from 'vuex'
import {
  SERVICE_PLAN,
  GRADUATION_ZORI,
  SCREEN_TYPE,
  WPHOTO_FURISODE_PLAN,
} from '~/constants/estimate/comingOfAge'
import { ContractServiceType } from '~/constants/enums/contractServiceType'
import { useHurisodeTypeFlags } from '~/composable/estimate/domain/ComingOfAge/HurisodeTypeFlags'
import { useSelectedPlanListType } from '~/composable/estimate/domain/ComingOfAge/SelectedPlanListType'
import { ProductCategory } from '~/constants/enums/productCategory'
import { EstimatePlan } from '~/constants/enums/estimatePlan'
import { usePlanTypeFlags } from '~/composable/estimate/domain/ComingOfAge/PlanTypeFlags'
import { useElderSisterFlag } from '~/composable/estimate/domain/ComingOfAge/ElderSisterFlag'
import { AdjustmentType } from '~/constants/enums/AdjustmentType'
import { useStateWPhoto } from '../../repository/comingOfAge/states/StateWフォト'

type UseSelectedPlanType = ReturnType<typeof useSelectedPlan>
export type ScreenTypeIdReturnType = ReturnType<
  UseSelectedPlanType['screenTypeId']
>

export const useSelectedPlan = () => {
  const store = useStore() as Store<any>
  const state = store.state.Estimate.ComingOfAgeCeremony
  const stateWPhoto = useStateWPhoto()

  const {
    isカタログ,
    isプロパー,
    is仕立上り,
    isオーダーレンタル,
    isお誂えレンタル,
    is仕立上り保留,
  } = useHurisodeTypeFlags()
  const {
    isフルセット,
    is当日のみBコース,
    is写真のみレンタル,
    is持込みAコース,
    is持込みBコース,
    is写真のみ持込,
  } = usePlanTypeFlags()
  const { isお姉様購入 } = useElderSisterFlag()

  const { isレンタルプラン, is購入プラン } = useSelectedPlanListType()

  const isBringIn = state.ServicePlans.selections.includes(
    SERVICE_PLAN.BRING_IN
  )

  const isOnlyPhotoRental = state.ServicePlans.selections.includes(
    SERVICE_PLAN.RENTAL
  )
  const isOnlyPhotoBringIn = state.ServicePlans.selections.includes(
    SERVICE_PLAN.BRING_IN_ONLY_PHOTO
  )

  // 成人式当日B
  // 条件：振袖が持ち込み ＆「成人式のみ」を選択 ＆ Wフォトはなし
  const isBCourseOnlyComingOfAgeCeremony = is当日のみBコース.value

  // Wフォト振袖レンタルか持ち込みかの判定
  const isWPhotoFurisodeBringIn =
    state.WphotoPlan.WphotoFurisodes.selections.includes(
      WPHOTO_FURISODE_PLAN.BRING_IN
    )

  // 見積作成時に送るplan番号
  const selectedPlan = () => {
    if (isフルセット.value && isレンタルプラン.value)
      return EstimatePlan.fullSet

    if (isフルセット.value && is購入プラン.value)
      return EstimatePlan.fullSetPurchase

    // 成人式A＋写真プラン
    if (is持込みAコース.value) return EstimatePlan.planAAndPhoto

    // 成人式B＋写真プラン
    if (is持込みBコース.value) return EstimatePlan.planBAndPhoto

    // 成人式B(写真なし)
    if (is当日のみBコース.value) return EstimatePlan.planB

    if (is写真のみレンタル.value) return EstimatePlan.onlyPhoto

    if (is写真のみ持込.value) return EstimatePlan.bringInOnlyPhoto

    return null
  }

  // 画面切り替え用
  const screenTypeId = () => {
    if (isOnlyPhotoRental) return SCREEN_TYPE.ONLY_PHOTO_RENTAL
    if (isBringIn || isOnlyPhotoBringIn) return SCREEN_TYPE.BRING_IN
    return SCREEN_TYPE.FULL_SET
  }

  // サービス区分
  const serviceType = () => {
    if (state.ServicePlans.selections.includes(SERVICE_PLAN.OUR_DRESSIN))
      return ContractServiceType.ourDressing
    if (state.ServicePlans.selections.includes(SERVICE_PLAN.COSMETOLOGY_SALON))
      return ContractServiceType.cosmetologySalon
    if (state.ServicePlans.selections.includes(SERVICE_PLAN.NO_DRESSING))
      return ContractServiceType.noDressing
    return
  }

  const getHurisodeCategory = () => {
    if (isお姉様購入.value) {
      return ProductCategory.purchasedElderSister
    }
    if (is持込みAコース.value || is写真のみ持込.value) {
      return ProductCategory.bringIn
    }
    if ((is仕立上り.value || is仕立上り保留.value) && isレンタルプラン.value) {
      return ProductCategory.tailoredRental
    }
    if ((is仕立上り.value || is仕立上り保留.value) && is購入プラン.value) {
      return ProductCategory.tailoredPurchase
    }
    if (isオーダーレンタル.value && isレンタルプラン.value) {
      return ProductCategory.orderRental
    }
    if (isお誂えレンタル.value && isレンタルプラン.value) {
      return ProductCategory.customizeRental
    }
    if (isカタログ.value && is購入プラン.value) {
      return ProductCategory.catalogPurchase
    }
    if (isプロパー.value && isレンタルプラン.value) {
      return ProductCategory.properRental
    }
    if (isプロパー.value && is購入プラン.value) {
      return ProductCategory.properPurchase
    }
    // 未確定とみなして0を設置する
    return 0
  }

  // 草履バッグ用　1:セット内 3:ランクアップ（プレミアム内） 4:ランクアップ（プレミアム外） 5:未定
  // FIXME: 6:お持ち込み（一時対応）草履バッグ画面もPLAN統合して、utilsのsetPlan に統一したい
  const zoriBagSetPlan = (selections: number[]) => {
    if (selections.includes(GRADUATION_ZORI.IN_THE_SET)) return 1
    else if (selections.includes(GRADUATION_ZORI.RANK_UP_IN_PREMIUM)) return 3
    else if (selections.includes(GRADUATION_ZORI.RANK_UP_OUT_PREMIUM)) return 4
    else if (selections.includes(GRADUATION_ZORI.BRING_IN)) return 6
    else if (selections.includes(GRADUATION_ZORI.BRING_IN_in振袖持込プラン))
      return 6
    else return 5
  }

  // Wフォトプラン（0:レンタル 1:持ち込み）
  const setWPhotoFurisodePlan = () => {
    if (
      state.WphotoPlan.WphotoFurisodes.selections.includes(
        WPHOTO_FURISODE_PLAN.RENTAL
      )
    )
      return 0
    return 1
  }

  /**
   * @note 見積一覧のプランごとにコンポーネントを出し分けしている条件と一緒
   * 返却する配列はそれに伴った金額調整用のtype ids
   */
  const getAdjustmentIds = () => {
    const isフルセット = screenTypeId() === SCREEN_TYPE.FULL_SET
    const isBコース成人式当日のみ =
      screenTypeId() === SCREEN_TYPE.BRING_IN &&
      isBCourseOnlyComingOfAgeCeremony
    const isお持込 = screenTypeId() === SCREEN_TYPE.BRING_IN
    stateWPhoto.value.振袖.flags.isレンタル

    if (isフルセット) {
      return [
        AdjustmentType.フルセットレンタル,
        AdjustmentType.フルセットレンタルPP,
        AdjustmentType.フルセット購入,
        AdjustmentType.フルセット購入PP,
      ]
    } else if (isBコース成人式当日のみ) {
      return [AdjustmentType.持込プランアルバムなし]
    } else if (isお持込) {
      return [AdjustmentType.持込プラン5P1冊, AdjustmentType.持込プラン15P1冊]
    } else {
      return [
        AdjustmentType.お写真プラン5P1冊,
        AdjustmentType.お写真プランPP5P1冊,
        AdjustmentType.お写真プラン15P1冊,
        AdjustmentType.お写真プランPP15P1冊,
      ]
    }
  }

  /**
   * @note 見積一覧のプランごとにコンポーネントを出し分けしている条件と一緒
   * 返却する配列はそれに伴った金額調整用のtype ids
   */
  const getAdjustmentIdsForWPhoto = () => {
    const isWPhoto振袖レンタル =
      stateWPhoto.value.firstPage.isWPhoto適用 &&
      stateWPhoto.value.振袖.flags.isレンタル

    const isWPhoto振袖お持込 =
      stateWPhoto.value.firstPage.isWPhoto適用 &&
      stateWPhoto.value.振袖.flags.isお持込

    if (isWPhoto振袖レンタル) {
      return [AdjustmentType.Wフォトレンタル, AdjustmentType.WフォトレンタルPP]
    } else if (isWPhoto振袖お持込) {
      return [AdjustmentType.Wフォト持込]
    }
    return []
  }

  return {
    selectedPlan,
    screenTypeId,
    isWPhotoFurisodeBringIn,
    isBCourseOnlyComingOfAgeCeremony,
    serviceType,
    getHurisodeCategory,
    zoriBagSetPlan,
    setWPhotoFurisodePlan,
    getAdjustmentIds,
    getAdjustmentIdsForWPhoto,
  }
}
