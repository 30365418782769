import { computed } from '@nuxtjs/composition-api'
import { FormValuesお写真プラン } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsお写真プラン'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { Enumお写真プラン } from '~/constants/estimate/graduationOptions'
import { EstimatePhotoPlan } from '~/constants/enums/estimatePhotoPlan'

export const useStateお写真プラン = () => {
  const storeお写真プラン =
    useGraduateStore<FormValuesお写真プラン>('tabお写真プラン')
  const valuesお写真プラン = storeお写真プラン.getAll()

  return computed(() => {
    const PlanIds = EstimatePhotoPlan
    const 写真撮影あり =
      valuesお写真プラン.value.oshashinPlan撮影 ===
      Enumお写真プラン.写真撮影.する

    const hasUSB =
      写真撮影あり &&
      valuesお写真プラン.value.oshashinPlanUSB === Enumお写真プラン.USB.つける

    const 選択商品Ids = valuesお写真プラン.value.oshashinPlan商品選択 ?? []
    const 選択商品商品名 = 選択商品Ids?.map((id) => {
      return PlanIds.toWord(id)
    })

    const selectedデザインアルバム =
      選択商品Ids.includes(PlanIds.デザインアルバム) && 写真撮影あり
    const selected六つ切り台紙あり =
      選択商品Ids.includes(PlanIds.六切り台紙あり) && 写真撮影あり
    const selected六つ切り台紙なし =
      選択商品Ids.includes(PlanIds.六切り台紙なし) && 写真撮影あり
    const selectedキャビネ台紙あり =
      選択商品Ids.includes(PlanIds.キャビネ台紙あり) && 写真撮影あり
    const selectedキャビネ台紙なし =
      選択商品Ids.includes(PlanIds.キャビネ台紙なし) && 写真撮影あり
    const selectedアクリルスタンド =
      選択商品Ids.includes(PlanIds.アクリルスタンド) && 写真撮影あり

    const 基本ポーズ数 =
      valuesお写真プラン.value.oshashinPlanデザインアルバム ===
      Enumお写真プラン.デザインアルバム詳細.スタンダード
        ? Enumお写真プラン.ポーズ数.スタンダード
        : Enumお写真プラン.ポーズ数.プレミアム

    const 紹介者特典ポーズ数 =
      valuesお写真プラン.value.oshashinPlan紹介者特典ポーズ数_アルバム ?? 0

    const 合計ポーズ数 = 基本ポーズ数 + 紹介者特典ポーズ数

    const 基本冊数 = 1

    const 合計冊数 = 基本冊数

    const デザインアルバム = {
      スタンダードORプレミアム:
        valuesお写真プラン.value.oshashinPlanデザインアルバム,
      基本ポーズ数,
      基本冊数,
      紹介者特典ポーズ数,
      合計ポーズ数,
      合計冊数,
    }

    const アクリルスタンド = {
      A5: valuesお写真プラン.value.oshashinPlanアクリルスタンドA5,
      A4: valuesお写真プラン.value.oshashinPlanアクリルスタンドA4,
    }

    return {
      写真撮影あり,
      選択商品名: 写真撮影あり ? 選択商品商品名 : [],
      選択商品番号: 写真撮影あり ? 選択商品Ids : [],
      デザインアルバム: selectedデザインアルバム ? デザインアルバム : null,
      アクリルスタンド: selectedアクリルスタンド ? アクリルスタンド : null,
      六つ切り台紙あり: selected六つ切り台紙あり
        ? valuesお写真プラン.value.oshashinPlan六切り台紙あり
        : [],
      六つ切り台紙なし: selected六つ切り台紙なし
        ? valuesお写真プラン.value.oshashinPlan六切り台紙なし ?? 0
        : 0,
      キャビネ台紙あり: selectedキャビネ台紙あり
        ? valuesお写真プラン.value.oshashinPlanキャビネ台紙あり
        : [],
      キャビネ台紙なし: selectedキャビネ台紙なし
        ? valuesお写真プラン.value.oshashinPlanキャビネ台紙なし ?? 0
        : 0,
      hasUSB,
    }
  })
}
