import { MutationTree } from 'vuex'
import { loginUser } from '../types'

const mutations: MutationTree<loginUser> = {
  setUser: (state, user) => {
    state.user = {
      ...user,
      login_date: state.user.login_date,
    }
  },

  setLoginDateAndTime: (state, { login_date }) => {
    state.user.login_date = login_date
  },

  delete: (state) => {
    state.user = {
      staff_id: 0,
      last_name: '',
      first_name: '',
      shop_id: 0,
      shop_name: '',
      login_date: 0,
      login_time: 0,
    }
  },
}

export default mutations
