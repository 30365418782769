






















import { defineComponent, ref, useRouter } from '@nuxtjs/composition-api'
import AppModalWindow from './AppModalWindow.vue'
import AppIconCircle from './AppIconCircle.vue'
import IconCheck from './icons/IconCheck.vue'
import AppButton from './AppButton.vue'
import { useInitializeState } from '~/composable/general/useInitializeState'

export default defineComponent({
  components: {
    AppModalWindow,
    AppIconCircle,
    IconCheck,
    AppButton,
  },
  setup() {
    const router = useRouter()
    const modal = ref<InstanceType<typeof AppModalWindow> | null>()

    const openModal = () => modal.value?.open()
    const closeModal = () => modal.value?.close()

    const backHome = () => {
      closeModal()
      router.push('/')
    }

    return { modal, openModal, closeModal, backHome }
  },
})
