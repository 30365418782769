import { MutationTree } from 'vuex'
import { MeasuringState } from '../../types'

type OriginalSizeInput = {
  digit_shaku: string
  digit_sun: string
  digit_bu: string
  sleeve_shaku: string
  sleeve_sun: string
  sleeve_bu: string
}

const mutations: MutationTree<MeasuringState> = {
  setInput: (state, input: MeasuringState['input']) => {
    state.input = input
  },
  setOriginalSizeInInput: (state, input: OriginalSizeInput) => {
    state.input = {
      ...state.input,
      digit_shaku: input.digit_shaku,
      digit_sun: input.digit_sun,
      digit_bu: input.digit_bu,
      sleeve_shaku: input.sleeve_shaku,
      sleeve_sun: input.sleeve_sun,
      sleeve_bu: input.sleeve_bu,
    }
  },

  setReasonInput: (state, input: MeasuringState['reasonInput']) => {
    state.reasonInput = input
  },

  setInputGraduation: (state, input: MeasuringState['inputGraduation']) => {
    state.inputGraduation = input
  },

  setCustomerInfo: (state, input: MeasuringState['customerInfo']) => {
    state.customerInfo = input
  },

  setGraduationHurisodeInfo: (
    state,
    input: MeasuringState['graduationHurisodeInfo']
  ) => {
    state.graduationHurisodeInfo = input
  },

  setWPhotoHurisodeInfo: (
    state,
    input: MeasuringState['wPhotoHurisodeInfo']
  ) => {
    state.wPhotoHurisodeInfo = input
  },

  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },

  delete: (state) => {
    state.input = {
      height: '', // 身長(cm)
      footSize: '', // 足のサイズ(cm)
      bust: '',
      hip: '',
      digit_shaku: '',
      digit_sun: '',
      digit_bu: '',
      sleeve_shaku: '',
      sleeve_sun: '',
      sleeve_bu: '',
      digit_correction_after_shaku: '',
      digit_correction_after_sun: '',
      digit_correction_after_bu: '',
      sleeve_correction_after_shaku: '',
      sleeve_correction_after_sun: '',
      sleeve_correction_after_bu: '',
      w_photo_plan_digit_correction_after_shaku: '', // 二着目修正後 裄(尺)
      w_photo_plan_digit_correction_after_sun: '', // 二着目修正後 裄(寸)
      w_photo_plan_digit_correction_after_bu: '', // 二着目修正後 裄(分)
      w_photo_plan_sleeve_correction_after_shaku: '', // 二着目修正後 袖(尺)
      w_photo_plan_sleeve_correction_after_sun: '', // 二着目修正後 袖(寸)
      w_photo_plan_sleeve_correction_after_bu: '', // 二着目修正後 袖(分)
    }
    state.inputGraduation = {
      height: '', // 身長(cm)
      footSize: '', // 足のサイズ(cm)
      bust: '', // バスト(cm)
      hip: '', // ヒップ(cm)
      digit_sun: '', // 裄補正(寸)
      digit_bu: '', // 裄補正(分)
      sleeve_sun: '', // 袖補正(寸)
      sleeve_bu: '', // 袖補正(分)
    }
    state.customerInfo = {
      height: '', // 身長
      foot_size: '', // 足のサイズ
      bodyType: '', // 体型
    }
    state.graduationHurisodeInfo = {
      id: '',
      catalog_code: '',
      product_name: '',
      yuki: '', // 裄
      sode: '', // 袖
      bodyType: '', // 体型
      other: '', // その他
    }
    state.wPhotoHurisodeInfo = {
      id: '',
      catalog_code: '',
      product_name: '',
      yuki: '', // 裄
      sode: '', // 袖
      bodyType: '', // 体型
      other: '', // その他
    }
    state.reasonInput = ''
    state.isValidate = null
  },
}

export default mutations
