import { computed, Ref } from '@nuxtjs/composition-api'
import { EstimateDetail, Items } from '~/api/estimateRepository'
import { EstimateSinglePriceType } from '~/constants/enums/estimateSinglePriceType'
import ProductId from '~/constants/enums/productClassification'
import { FormValuesその他商品 } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsその他商品'
import { OTHER_ITEMS } from '~/constants/estimate/config'
import { usePrice } from '~/composable/general/Price'

export const useRestateその他商品 = (
  estimate: Ref<EstimateDetail | undefined>
) => {
  const { paramToString } = usePrice()

  return computed((): FormValuesその他商品 | undefined => {
    if (!estimate.value?.items) return

    const otherItems = estimate.value.items.filter((item) => {
      return item.product_type_id === ProductId.otherItem
    })

    return {
      otherItemFlg: otherItems.length
        ? OTHER_ITEMS.種別.する
        : OTHER_ITEMS.種別.しない,
      otherItems: otherItems.map((value) => {
        return {
          product_name: value.product_name || '',
          price_type:
            value.price_type === EstimateSinglePriceType.singleRentalPrice
              ? OTHER_ITEMS.PRICE_TYPE.単品レンタル
              : OTHER_ITEMS.PRICE_TYPE.単品購入,
          price: String(
            paramToString(
              (value.price_type === EstimateSinglePriceType.singleRentalPrice
                ? value.rental_price
                : value.purchase_price) || 0
            )
          ),
        }
      }),
    }
  })
}
