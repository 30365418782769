import { computed, ComputedRef, Ref } from '@nuxtjs/composition-api'
import { EstimateDetail, Items } from '~/api/estimateRepository'
import { ItemPlan } from '~/constants/enums/itemPlan'
import ProductId from '~/constants/enums/productClassification'
import { PLAN, GRADUATION_ZORI } from '~/constants/estimate/comingOfAge'
import { useRestatePlan, PlanFlags } from '../RestatePlan'
import { EstimateSinglePriceType } from '~/constants/enums/estimateSinglePriceType'
import {
  GEINPIN,
  UNDECIDED,
} from '~/composable/estimate/viewModel/comingOfAge/FieldProps/FieldProps草履バッグ'
import { usePPZoriBagPlan } from '~/composable/general/PPDiscount/usePPZoriBagPlan'

export const useRestateバッグ1個目 = (
  estimate: Ref<EstimateDetail | undefined>,
  isPPDiscount: ComputedRef<boolean | undefined>
) => {
  const { getZoriBagPPAddingPrice } = usePPZoriBagPlan()
  const restateプラン = useRestatePlan(estimate)
  return computed(() => {
    if (!estimate.value?.items || !restateプラン.value) {
      return null
    }
    const bagItem = estimate.value.items.find(
      (item: Items) => item.product_type_id === ProductId.bag
    )

    if (!bagItem) {
      return null
    }

    const { isPPAddingPrice } = getZoriBagPPAddingPrice(
      bagItem,
      isPPDiscount.value
    )

    const {
      rental_price,
      purchase_price,
      plan,
      premium_rental_price,
      premium_purchase_price,
      price_type,
      product_name,
    } = bagItem

    const { flags: planFlags } = restateプラン.value
    const flags = getConditionalFlags(
      bagItem,
      planFlags,
      isPPDiscount.value ?? false,
      isPPAddingPrice
    )
    const selected商品 = getOptionOf商品選択(bagItem, flags)

    return {
      optionId種別: getOptionIdOf種別(plan, planFlags),
      optionId形態: getOptionIdOf形態(plan),
      optionId商品: selected商品?.id ?? 0,
      selected商品,
      商品名: product_name ?? '',
      optionId振袖レンタルの場合: getOptionIdOf振袖レンタルの場合(
        bagItem,
        planFlags
      ),
      optionId単品選択: getOptionIdOf単品選択(price_type),
      /**
       * WARNING! スーパーイレギュラー対応!!
       * フルセット時の草履・バッグで「振袖レンタルの場合」の質問に「同じ商品を単品購入」と答えた時、
       * 「プラン内の購入価格/PP購入価格/単品購入価格」全てを保持する必要が生じるが、
       * 購入金額のために用意されているパラメータが足りない、かつ根本的な解決が現状難しいため、
       * 現状は併用される可能性のない"rental_price"に「単品購入価格」を格納しています。。。
       */
      単品購入価格: flags.is同じ商品単品購入
        ? Number(rental_price)
        : Number(purchase_price ?? 0),
      レンタル価格: flags.is同じ商品単品購入 ? 0 : Number(rental_price),
      プレミアムレンタル価格: Number(premium_rental_price ?? 0),
      購入価格: Number(purchase_price ?? 0),
      プレミアム購入価格: Number(premium_purchase_price ?? 0),
      flags,
    }
  })
}

/**
 * 復元条件に関わる条件式を定義する
 */
export const getConditionalFlags = (
  item: Items,
  planFlags: PlanFlags,
  isPPDiscount: boolean,
  isPPAddingPrice: boolean
) => {
  const { is振袖持込 } = planFlags
  const is持込 = item.plan === ItemPlan.bringIn
  const レンタルの場合 = getOptionIdOf振袖レンタルの場合(item, planFlags)

  const isセット内 = !is振袖持込 && !is持込 && item.plan === ItemPlan.inSet
  const isランクアップPP内 =
    !is振袖持込 && !is持込 && item.plan === ItemPlan.rankUpInPremium
  const isランクアップPP外 =
    !is振袖持込 && !is持込 && item.plan === ItemPlan.rankUpOutPremium
  const isU系商品 = !!item.product_name && item.product_name.includes('U-')
  const isこちらでお選びします = isセット内 && item.product_name === '-'
  const is現品 = item.product_name === '現品'
  const isセット内現品 = isセット内 && is現品

  return {
    is持込,
    isセット内,
    isこちらでお選びします,
    isランクアップPP内,
    isランクアップPP外,
    is仕立て上り: !!item.product_id,
    isセット内現品,
    isU系商品,
    is現品,
    is同じ商品単品購入: レンタルの場合 === GRADUATION_ZORI.BUY_THE_SAME_PRODUCT,
    isPPDiscount,
    isPPAddingPrice,
  }
}

/**
 * 選択肢系の項目の初期値(選択されたoptionのid)を再計算する関数群
 */
export const getOptionIdOf種別 = (
  itemPlan: Items['plan'],
  planFlags: PlanFlags
) => {
  // お持込は全パターン共通ID、その他は振袖のプランによって変動する
  if (planFlags.is振袖持込) {
    if (itemPlan === ItemPlan.bringIn) {
      return PLAN.BRING_IN
    } else {
      return PLAN.SINGLE_RENTAL
    }
  } else {
    if (itemPlan === ItemPlan.bringIn) {
      return GRADUATION_ZORI.BRING_IN
    } else {
      return GRADUATION_ZORI.RENTAL
    }
  }
}

export const getOptionIdOf形態 = (itemPlan: Items['plan']) => {
  switch (itemPlan) {
    case ItemPlan.inSet:
      return GRADUATION_ZORI.IN_THE_SET
    case ItemPlan.rankUpInPremium:
      return GRADUATION_ZORI.RANK_UP_IN_PREMIUM
    case ItemPlan.rankUpOutPremium:
      return GRADUATION_ZORI.RANK_UP_OUT_PREMIUM
    default:
      return 0
  }
}

const getOptionOf商品選択 = (
  item: Items,
  flags: ReturnType<typeof getConditionalFlags>
) => {
  if (item.product_name === GEINPIN.product_name) return GEINPIN

  if (!flags.is持込 && item.product_name?.includes('こちらでお選び')) {
    return UNDECIDED
  }

  if (!item.product_id || !item.product_name) return null

  // NOTE: nameは`U-1 バッグ(ご購入用)`のようになっているため
  const splittedItemName = item.product_name.split(/\s+/, 2)
  return {
    id: item.product_id,
    post_number_code: splittedItemName[0],
    product_name: splittedItemName[1],
    price: {
      rental: 0,
      buy: 0,
      set_purchase: 0,
    },
  }
}

export const removePostNumberCode = (wholeName: string | undefined) => {
  if (!wholeName) return ''
  const dividedNames = wholeName.split(' ')
  const regExpPostNumberCode = /^[A-Z]{1,2}-[0-9]{1,2}/

  if (regExpPostNumberCode.test(dividedNames[0])) {
    // NOTE: postNumberCode（例：RZ-03）がproduct_nameの先頭にある場合は取り除く
    return dividedNames.slice(1).join(' ')
  } else {
    return dividedNames.join(' ')
  }
}

// 振袖購入・レンタルの時：レンタルプランを選ぶか単品購入を選ぶと
export const getOptionIdOf振袖レンタルの場合 = (
  item: Items,
  planFlags: PlanFlags
) => {
  const isU系商品 = !!item.product_name && item.product_name.includes('U-')

  if (planFlags.is振袖持込) {
    return 0
  }

  if (item.price_type === EstimateSinglePriceType.singlePurchasePrice) {
    return GRADUATION_ZORI.BUY_THE_SAME_PRODUCT
  } else if (!planFlags.is振袖持込 && isU系商品) {
    return 0
  } else {
    return GRADUATION_ZORI.RENTAL_THE_SAME_PRODUCT
  }
}

// 振袖持込の時：単品購入・単品レンタルどちらを選んでいるか
export const getOptionIdOf単品選択 = (priceType: Items['price_type']) => {
  switch (priceType) {
    case EstimateSinglePriceType.singleRentalPrice:
      return PLAN.SINGLE_RENTAL_PRICE
    case EstimateSinglePriceType.singlePurchasePrice:
      return PLAN.SINGLE_BUY_PRICE
    default:
      return 0
  }
}
