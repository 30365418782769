/** composable */
import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'

/** types */
import { PLAN } from '~/constants/estimate/comingOfAge'
import { AccessoryPlanState, OnlyInputPlanState } from '~/store/types'

export const useRestore重ね衿 = () => {
  const store = useComingStore<AccessoryPlanState>('Kasaneeri')
  const store2 = useComingStore<OnlyInputPlanState>('Kasaneeri2')
  const store3 = useComingStore<OnlyInputPlanState>('Kasaneeri3')
  const setFirstStore = (
    target: typeof store,
    formValues: AccessoryPlanState
  ) => {
    target.setOf<AccessoryPlanState['selections']>(
      'setSelection',
      formValues.selections
    )
    target.setOf<AccessoryPlanState['input']>('setInput', formValues.input)
    target.setOf<AccessoryPlanState['price']>('setPrice', formValues.price)
    target.setOf<AccessoryPlanState['isFree']>('setIsFree', formValues.isFree)
    target.setIsValidate(false)
    if (formValues)
      target.setOf<AccessoryPlanState['isPPDiscount']>(
        'setIsPPDiscount',
        formValues.isPPDiscount
      )
  }
  const setAdditionalStore = (
    target: typeof store2,
    formValues: OnlyInputPlanState,
    index: number
  ) => {
    target.setOf<OnlyInputPlanState['selections']>(
      'setSelection',
      formValues.selections
    )
    target.setOf<OnlyInputPlanState['input']>('setInput', formValues.input)
    target.setIsValidate(false)
    if (index < 2) {
      target.setOf<AccessoryPlanState['isPPDiscount']>(
        'setIsPPDiscount',
        formValues.isPPDiscount
      )
    }
  }

  const restore重ね衿 = (
    kasaneeris: Restate['items']['重ね衿'],
    plan: Restate['plan']
  ) => {
    if (!plan || !kasaneeris) return

    kasaneeris.forEach((item, i) => {
      /* 初期値を設定 ※必ず入力される値はここで設置 */
      const formValues: AccessoryPlanState = {
        /**
         * NOTE: selections = patternLine
         * ↓保持される選択肢のindex
         * [0]種別
         * [1]形態 || 見積表示
         */
        selections: [item.optionId種別, 0],
        input: {
          productName: '',
          buyPrice: 0,
          rentalPrice: 0,
          rankUpRentalPrice: 0,
          rankUpBuyPrice: 0,
          premiumRentalPrice: 0,
          premiumBuyPrice: 0,
        },
        // NOTE: フリー入力された値はpricesではなくinputの方に格納される
        price: {
          buy: 0,
          rental: 0,
          id: 0, // フリー入力を選択
        },
        isFree: false,
        isValidate: null,
        isPPDiscount: false,
      }

      /* 以下で入力内容の条件に基づき、必要な項目を埋めていく */
      if (item.type === 'firstOf振袖レンタル購入') {
        if (!item.flags.is持込) {
          formValues.selections[1] = item.optionId形態
        }

        if (item.flags.isセット内) {
          formValues.price.rental = item.レンタル価格
          formValues.input.rentalPrice = item.レンタル価格
          if (plan.flags.isフルセットプラン) {
            formValues.price.buy = item.購入価格
            formValues.input.buyPrice = item.購入価格
          }
          // １品目がセット内でPP適用割引されている場合は、２品目をランクアップとしている
          if (item.プレミアムレンタル価格 || item.プレミアム購入価格) {
            formValues.input.premiumRentalPrice = item.プレミアムレンタル価格
            formValues.input.premiumBuyPrice = item.プレミアム購入価格
          }
        }

        if (item.flags.isランクアップPP内) {
          formValues.isFree = true
          formValues.input.rankUpRentalPrice = item.レンタル価格
          if (plan.flags.isフルセットプラン) {
            formValues.input.rankUpBuyPrice = item.購入価格
          }
        }

        if (item.flags.isランクアップPP外) {
          formValues.input.rankUpRentalPrice = item.レンタル価格
          formValues.input.premiumRentalPrice = item.プレミアムレンタル価格
          if (plan.flags.isフルセットプラン) {
            formValues.input.rankUpBuyPrice = item.購入価格
            formValues.input.premiumBuyPrice = item.プレミアム購入価格
          }
        }

        formValues.isPPDiscount = item.flags.isPPDiscount

        /* ストアに内容を保存する */
        setFirstStore(store, formValues)
      } else if (item.type === 'others') {
        if (!item.flags.is持込) {
          /**
           * NOTE: PP未適用の場合、getOptionIdOf種別で２品目はPLAN.SINGLE_RENTALとなるため、
           * PP適用時の復元はタブにて種別が「単品レンタル」を選択するようにする
           */
          if (
            i === 1 &&
            (item.optionId種別 === PLAN.SET_OR_RENTAL ||
              item.optionId種別 === PLAN.RENTAL)
          ) {
            formValues.selections[0] = 7
            formValues.input.premiumRentalPrice = 0
            formValues.input.premiumBuyPrice = 0
          }
          formValues.selections[1] = item.optionId見積表示
          formValues.input.rentalPrice = item.レンタル価格
          formValues.input.buyPrice = item.購入価格
        }

        formValues.isPPDiscount = item.flags.isPPDiscount

        /* ストアに内容を保存する */
        if (i === 0) setFirstStore(store, formValues)
        if (i === 1) setAdditionalStore(store2, formValues, i)
        if (i === 2) setAdditionalStore(store3, formValues, i)
      }
    })
  }

  return { restore重ね衿 }
}
