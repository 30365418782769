import { MutationTree } from 'vuex'
import { CopiedEstimateState, getInitialFields } from './state'

const mutations: MutationTree<CopiedEstimateState> = {
  // NOTE: useComingStore用に追加
  setAllState: (state, values: CopiedEstimateState) => {
    state.estimate = values.estimate
    state.isCopyEstimateFlag = values.isCopyEstimateFlag
  },

  setIsCopyEstimateFlagForKimono: (
    state,
    values: CopiedEstimateState['isCopyEstimateFlag']
  ) => {
    state.isCopyEstimateFlag = values
  },

  initAllState: (state) => {
    state.estimate = getInitialFields()
    state.isCopyEstimateFlag = {
      flagForNishakusode: false,
      flagForHakama: false,
      isEstimateBeforeReleaseForNishakusode: false,
    }
  },
}

export default mutations
