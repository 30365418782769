import { MutationTree } from 'vuex'
import { OtherItems } from '~/store/types'
import { OTHER_ITEMS } from '~/constants/estimate/config'

const mutations: MutationTree<OtherItems> = {
  setInput: (state, input) => {
    state.apply = input.apply
    state.items = input.items
  },

  setIsValidate: (state, data) => {
    state.isValidate = data
  },

  delete: (state) => {
    state.apply = OTHER_ITEMS.種別.しない
    state.items = []
    state.isValidate = false
  },
}

export default mutations
