import { computed } from '@nuxtjs/composition-api'
import { useCustomer } from '~/composable/api/useCustomer'
import { useEstimate } from '~/composable/api/useEstimate'
import { useMasterData } from '~/composable/api/useMasterData'
import { useRestateCustomer } from './restates/RestateCustomer'
import { useRestateHakama } from './restates/RestateHakama'
import { useRestateHurisode } from './restates/RestateHurisode'
import { useRestateItems } from './restates/RestateItems'
import { useRestatePhotos } from './restates/RestatePhotos'
import { useResatePhotoPlan } from './restates/ResatePhotoPlan'
import { useRestateDiscount } from './restates/RestateDiscount'
import { useRestatePriceTable } from './restates/RestatePriceTable'
import { useRestatePlan } from './restates/RestatePlan'
import { useComingOfAgeRecord } from '../../domain/Graduation/ComingOfAgeRecord'

type EstimateRestate = ReturnType<typeof useEstimateRestate>
export type Restate = EstimateRestate['restate']['value']

/**
 * 作成済みの見積書詳細APIから取得したパラメータを再びストアに保存するEstimateRestorerで使いやすい値や形式に加工する
 */
export const useEstimateRestate = () => {
  const { fetchEstimateInfo, estimateInfo } = useEstimate()
  const { fetchCustomerProfile, customerProfile } = useCustomer()
  const { fetchVisitMotives, visitMotives } = useMasterData()
  const { getComingOfAgeRecord, comingOfAgeRecord } = useComingOfAgeRecord()

  /* restates */
  const restateCustomer = useRestateCustomer(
    customerProfile,
    estimateInfo,
    visitMotives
  )
  const restatePlan = useRestatePlan(estimateInfo)
  const restatePhotoPlan = useResatePhotoPlan(estimateInfo)
  const restateHurisode = useRestateHurisode(estimateInfo)
  const restateHakama = useRestateHakama(estimateInfo)
  const restateItems = useRestateItems(estimateInfo)
  const restatePhotos = useRestatePhotos(estimateInfo)
  const restateDiscount = useRestateDiscount(estimateInfo)

  /**
   * 整形前の情報をAPIから取得する。取得された情報は加工されてrestateに自動で反映される
   */
  const fetchInfoForEstimateRestate = async (
    estimateId: number,
    branch: number
  ) => {
    await fetchEstimateInfo(estimateId, branch)

    if (!estimateInfo.value) {
      const text = `以下の見積を取得できませんでした 見積番号:${estimateId}, 枝番号:${branch} `
      alert(text)
      return
    }
    await Promise.all([
      fetchCustomerProfile({
        customer_id: estimateInfo.value.customer_id,
      }),
      fetchVisitMotives(),
      getComingOfAgeRecord(
        estimateInfo.value.customer_id,
        true,
        estimateInfo.value.coming_of_age_ceremony_contract_id
      ),
    ])
  }

  /**
   * 加工後の情報を束ねた変数
   */
  const restate = computed(() => {
    return {
      customer: restateCustomer.value,
      plan: restatePlan.value,
      contract_service_type: null,
      type: null,
      use_date: null,
      photos: restatePhotos.value,
      neck_photo_url: null,
      hurisode: restateHurisode.value,
      items: restateItems.value,
      discounts: restateDiscount.value,
      remarks: estimateInfo.value?.remarks ?? '',
      contract_total_price: null,
      coming_of_age_ceremony_contract: comingOfAgeRecord,
      hakama: restateHakama.value,
      photo_plan: restatePhotoPlan.value,
      additions: null,
      memo: null,
      price_information: useRestatePriceTable(estimateInfo).value,
      estimateInfo: estimateInfo.value,
      amount_adjustments: estimateInfo.value?.amount_adjustments ?? [],
      selectedPlan: estimateInfo.value?.selected_plan ?? 0,
    }
  })

  return { fetchInfoForEstimateRestate, restate, originalRes: estimateInfo }
}
