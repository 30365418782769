import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { AccessoryPlanState } from '~/store/types'

export const useRestoreバッグ複数個目 = () => {
  const store2 = useComingStore<AccessoryPlanState>('Bag2')
  const store3 = useComingStore<AccessoryPlanState>('Bag3')

  const setStore = (target: typeof store2, formValues: AccessoryPlanState) => {
    target.setOf<AccessoryPlanState['selections']>(
      'setSelection',
      formValues.selections
    )
    target.setOf<AccessoryPlanState['input']>('setInput', formValues.input)
    target.setOf<AccessoryPlanState['price']>('setPrice', formValues.price)
    target.setIsValidate(false)
    store2.setOf<AccessoryPlanState['isPPDiscount']>(
      'setIsPPDiscount',
      formValues.isPPDiscount
    )
    store2.setOf<AccessoryPlanState['isPPAddingPrice']>(
      'setIsPPAddingPrice',
      formValues.isPPAddingPrice
    )
  }

  const restoreバッグ複数個目 = (
    additionalBags: Restate['items']['バッグ複数個目']
  ) => {
    if (!additionalBags) {
      return
    }

    /* 初期値を設定 ※必ず入力される値はここで設置 */
    additionalBags.forEach((item, i) => {
      const isPPDiscount = item.flags.isPPDiscount
      const isPPAddingPrice = item.flags.isPPAddingPrice
      const formValues: AccessoryPlanState = {
        /**
         * NOTE: selections = patternLine
         * ↓保持される選択肢のindex
         * [0]商品
         */
        selections: [item.optionId商品],
        input: {
          productName: item.商品名,
          buyPrice: item.単品購入価格,
          rentalPrice: item.単品レンタル価格,
          rankUpRentalPrice: 0,
          rankUpBuyPrice: 0,
          premiumRentalPrice: 0,
          premiumBuyPrice: isPPAddingPrice ? item.プレミアム購入価格 : 0,
        },
        price: {
          buy: item.単品購入価格,
          rental: item.単品レンタル価格,
        },
        isFree: false,
        isValidate: null,
        isPPDiscount: isPPDiscount,
        isPPAddingPrice: isPPAddingPrice,
      }

      /* ストアに内容を保存する ※バッグ1個目は草履バッグの方で処理しているので配列に含まれない */
      if (i === 0) setStore(store2, formValues)
      if (i === 1) setStore(store3, formValues)
    })
  }
  return { restoreバッグ複数個目 }
}
