import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { FormValuesお客様検索 } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsお客様検索'
import { CustomerProfileAndReason } from '~/api/customerRepository'

export const useRestoreお客様検索 = () => {
  const store = useGraduateStore<FormValuesお客様検索>('tabお客様検索')

  const restoreお客様検索 = (customer: CustomerProfileAndReason) => {
    store.setAll({
      okyakusama検索: customer ? customer.profile : null,
      okyakusama来店動機: customer?.visiting_reason ?? [],
      guidanceEmail: customer ? customer?.guidanceEmail : null,
    })
  }
  return { restoreお客様検索 }
}
