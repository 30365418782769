import { FormValues試着写真 } from '~/composable/estimate/viewModel/graduation/Fields/Fields試着写真'

//不明な点がある場合、READMEをご覧ください
export const getInitialStore = (): FormValues試着写真 => {
  return {
    shichakuアップロード: null,
    shichaku全体写真: [],
    shichaku衿元写真: [],
  }
}

const state = (): { fields: FormValues試着写真 } => ({
  fields: getInitialStore(),
})

export default state
