import { ActionTree } from 'vuex'
import { RootState, AccessoriesState, Accessory } from '../../types'

const actions: ActionTree<AccessoriesState, RootState> = {
  setResultSelections: ({ commit }, resultSelections) => {
    // NOTE: 直接mutationでも良いかも
    commit('setResultSelections', resultSelections)
  },

  setIsValidate: ({ commit }, isValidate: boolean) => {
    commit('setIsValidate', isValidate)
  },

  delete: ({ commit }) => {
    commit('delete')
  },

  setTabiColor: ({ commit }, selectedColor) => {
    commit('setTabiColor', selectedColor)
  },
}

export default actions
