import { Estimate } from '@/composable/api/useEstimate'
import { AttendanceClothingPlanType } from '~/constants/enums/attendanceClothingPlanType'
import ProductClassification from '~/constants/enums/productClassification'
import { PAYMENT_METHOD } from '~/features/contracts/paymentEnums'
import { Customer } from '~/composable/api/useCustomer'
import { SettingShop, Staff } from '~/store/types'
import type { AttendanceEstimateDetailWithId } from '~/features/contracts/attendance/useEstimateData'

export type valueOf<T> = T[keyof T]

// ** 見積選択 **
export type SelectEstimate = {
  estimateDataList: Estimate[] // 選択された見積書のリスト
  isSelectingEstimate: boolean // 見積選択中かどうか
}
export type SelectEstimateKeys = keyof SelectEstimate
export type SelectEstimateValues = valueOf<SelectEstimate>

export type DiscountList = {
  estimate_id: number | null
  price: number
}

// ** 利用者タブ **
export type User = {
  usageinfo: UsageInfo
  measuring: Measuring
  accessories: Accessories[]
  customerReady: CustomerReady
  customerId: number | null
  estimateId: number | null
}

// ** 着付け会場情報 **
export type DressingPlaces = {
  id: number
  name: string
  dressingAddressInfo: DressingAddressInputs | null
}
export type DressingAddressInputs = {
  zip_code: string // 郵便番号
  prefectures: string // 都道府県
  address1: string // 住所1：市区町村
  address2: string // 住所2：番地以降（建物名など）
  phone_number: string // 電話番号
}

// ** 利用者/ご利用情報 **
export type AddressInputs = {
  zip_code: string // 郵便番号
  prefectures: string // 都道府県
  address1: string // 市区町村
  address2: string // 番地以降（建物名など）
  phone_number: string // 電話番号
  recipientName?: string | null // 宛名
  contents?: string | null // 品名
}
export type UsageInfo = {
  relation: number | null // 代表者との関係性
  useDate: string // 使用日
  placeOfDressing: SettingShop | null // 着付け場所
  timeOfEnteringDressPlace: string // 着付場所入場時間
  passOption: number | null // お渡し方法
  passDate: string // お渡し日
  placeOfPass: SettingShop | null // お渡し場所
  scheduledDeliveryDate: string // お届け予定日
  selectedAddressId: number | null // お送り先を選択
  selectedAddressInfo: AddressInputs | null // 新しい送付先を追加
  returnOption: number | null // ご返却方法
  dateOfReturn: string // ご返却日
  placeOfReturn: SettingShop | null // ご返却場所
  dressingPlaces: DressingPlaces | null // 着付け会場
  desiredTime: number | null //ご希望のお届け時間
}
export type UsageInfoKeys = keyof UsageInfo
export type UsageInfoValues = valueOf<UsageInfo>

// ** 利用者/補正 **
export type Measuring = {
  yuki: {
    shaku: number
    sun: number | null
    bu: number | null
  }
  sode: {
    shaku: number
    sun: number | null
    bu: number | null
  }
}
export type MeasuringKeys = keyof Measuring
export type MeasuringValues = valueOf<Measuring>

// ** 利用者/着付小物 **
export type Accessories = {
  size_name: string | null
  type: number
  title: string
  number?: number | null
  has_delivery?: number | null
  color?: string | null
  selection?: number | null
  selections?: { label: string; value: number }[]
}
// ** 利用者/お客様ご準備 **
export type CustomerReady = {
  selectedContents: number[]
  selectedOtherContents: string[]
}
export type CustomerReadyKeys = keyof CustomerReady
export type CustomerReadyValues = valueOf<CustomerReady>

// ** 基本設定 **
export type BaseSettings = {
  selectedEstimate: Estimate | null // 代表者(の見積書)
  subStaff: Staff | null // 副担当者
}
export type BaseSettingsKeys = keyof BaseSettings
export type BaseSettingsValues = valueOf<BaseSettings>

// ** 重要事項 **
export type ImportantMatter = {
  coolingOffPeriod: string // クーリングオフ期限
  paymentDeadline: string // 残高支払い期限
  payee: number | null
  payeeInfo: string // 振込先
  loginShopTransferPlaceId: number | null // ログイン時店舗の振込先ID
}
export type ImportantMatterKeys = keyof ImportantMatter
export type ImportantMatterValues = valueOf<ImportantMatter>

// ** オプション **
export type OptionData = {
  apply: number
}

// ** 備考 **
export type RemarksData = {
  text: string
}
//** お支払い */
export type PaymentDetail = {
  isActive: boolean
  methods: typeof PAYMENT_METHOD[keyof typeof PAYMENT_METHOD]
  tomonokai_points?: number
  amount: number
}

export type Payment = {
  dueDate: string | null
  staff: Staff | null
  category: number | null
  details: PaymentDetail[]
  total: number | null
  notes: string | null
  isPayment: number | null
  balance: number
  paidAmount: number
}

export type PaymentKeys = keyof Payment
export type PaymentValues = valueOf<Payment>

// ** 紹介者特典 **
type Bonus = {
  bonusType: number | null
  isPassed: number | null
  passedDate: string | null
  notes: string | null
}

export type ReferralBonus = {
  isReferralBonus: number | null
  introducerInfo: Customer | null
  introducer: Bonus
  user: Bonus
}

export type BunusTypeKeys = keyof Bonus
export type BunusTypeValues = valueOf<Bonus>
export type ReferralBonusKeys = keyof ReferralBonus
export type ReferralBonusValues = valueOf<ReferralBonus>

// ** 署名 **
export type Signature = {
  checked: {
    first: boolean
    second: boolean
    third: boolean
    fourth: boolean
  }
  signUrl: string
}
export type SignatureKeys = keyof Signature
export type SignatureValues = valueOf<Signature>

// ** タブ **
export type Tab = {
  text: string
  isComplete: boolean | null
  isShow: boolean
  isCurrent: boolean
  estimateId?: number
}

export type State = {
  data: {
    contractDate: string
    option: OptionData
    remarks: RemarksData
    user: User[]
    baseSettings: BaseSettings
    currentUserIndex: number
    importantMatter: ImportantMatter
    payment: Payment
    referralBonus: ReferralBonus
    signature: Signature
  }
  selectEstimate: SelectEstimate
  tabList: Tab[]
  userTabList: Tab[][]
  finalPageList: Tab[]
  discountList: DiscountList[]
  estimateIdList: number[]
  contractId: number | null
  contractTotalPrice: number | null // 一時保存時に合計金額を保持する必要があるため追加
}

export const getInitialUserTabState = (
  estimate: AttendanceEstimateDetailWithId
): Tab[] => {
  // 裄補正と裄補正のどちらかが存在する場合補正タブを表示する
  const isShow補正 =
    typeof estimate.clothing?.correct_sodetake_price === 'number' ||
    typeof estimate.clothing?.correct_yukitake_price === 'number'

  // 着物が振袖か留袖か訪問着でレンタルの場合に着付小物タブを表示する
  const isShow着付小物 =
    estimate.clothing?.product_type_id &&
    estimate.clothing?.product_classification_id
      ? [
          ProductClassification.hurisode,
          ProductClassification.tomesode,
          ProductClassification.haumongi,
        ].includes(estimate.clothing.product_type_id) &&
        estimate.clothing.product_classification_id ===
          AttendanceClothingPlanType.レンタル
      : false

  // 着物が振袖か留袖か訪問着かモーニングの場合にお客様ご準備タブを表示する
  const isShowお客様ご準備 = estimate.clothing?.product_type_id
    ? [
        ProductClassification.hurisode,
        ProductClassification.tomesode,
        ProductClassification.haumongi,
        ProductClassification.morning,
      ].includes(estimate.clothing.product_type_id)
    : false

  return [
    {
      text: 'ご利用情報',
      isComplete: null,
      isShow: true,
      isCurrent: false,
    },
    {
      text: '補正',
      isComplete: null,
      isShow: isShow補正,
      isCurrent: false,
    },
    {
      text: '着付小物',
      isComplete: null,
      isShow: isShow着付小物,
      isCurrent: false,
    },
    {
      text: 'お客様ご準備',
      isComplete: null,
      isShow: isShowお客様ご準備,
      isCurrent: false,
    },
  ]
}

export const getInitialStore = (): State => {
  return {
    data: {
      contractDate: '',
      // ** オプション **
      option: {
        apply: 4,
      },
      // ** 備考 **
      remarks: {
        text: '',
      },
      // ** ご利用者 **
      user: [],
      // ** 基本設定 **
      baseSettings: {
        selectedEstimate: null,
        subStaff: null,
      },
      // ** 重要事項 **
      currentUserIndex: 0,
      importantMatter: {
        coolingOffPeriod: '',
        paymentDeadline: '',
        payee: null,
        payeeInfo: '',
        loginShopTransferPlaceId: null, // ログイン時店舗の振込先ID
      },
      // ** お支払い **
      payment: {
        dueDate: null,
        staff: null,
        category: null,
        total: null,
        notes: null,
        details: [
          {
            isActive: false,
            methods: PAYMENT_METHOD.CASH,
            amount: 0,
          },
          {
            isActive: false,
            methods: PAYMENT_METHOD.CARD,
            amount: 0,
          },
          {
            isActive: false,
            methods: PAYMENT_METHOD.GIFT_CERTIFICATES,
            amount: 0,
          },
          {
            isActive: false,
            methods: PAYMENT_METHOD.POINT,
            amount: 0,
          },
          {
            isActive: false,
            methods: PAYMENT_METHOD.E_MONEY,
            amount: 0,
          },
          {
            isActive: false,
            methods: PAYMENT_METHOD.FAMILY_POINT,
            amount: 0,
          },
          {
            isActive: false,
            methods: PAYMENT_METHOD.POINT_ROUND_OFF,
            amount: 0,
          },
        ],
        isPayment: null, // 支払い処理を行うかどうか（契約変更時に使用）
        balance: 0, // 支払い残高（契約変更時に使用）
        paidAmount: 0, // 支払い済み金額（契約変更時に使用）,
      },
      //* 紹介者特典 */
      referralBonus: {
        isReferralBonus: null,
        introducerInfo: null,
        introducer: {
          bonusType: null,
          isPassed: null,
          passedDate: null,
          notes: null,
        },
        user: {
          bonusType: null,
          isPassed: null,
          passedDate: null,
          notes: null,
        },
      },
      signature: {
        checked: {
          first: false,
          second: false,
          third: false,
          fourth: false,
        },
        signUrl: '',
      },
    },
    // ** 見積選択 **
    selectEstimate: {
      estimateDataList: [], // 選択された見積書のリスト
      isSelectingEstimate: true, // 見積選択中かどうか
    },
    // ** タブ **
    tabList: [
      {
        text: '見積選択',
        isComplete: null,
        isShow: true,
        isCurrent: true,
      },
      {
        text: '基本設定',
        isComplete: null,
        isShow: true,
        isCurrent: false,
      },
      // {
      //   text: '紹介者特典',
      //   isComplete: null,
      //   isShow: true,
      //   isCurrent: false,
      // },
      {
        text: 'お支払',
        isComplete: null,
        isShow: true,
        isCurrent: false,
      },
      {
        text: '重要事項',
        isComplete: null,
        isShow: true,
        isCurrent: false,
      },
      {
        text: 'オプション',
        isComplete: true,
        isShow: true,
        isCurrent: false,
      },
      {
        text: '備考',
        isComplete: null,
        isShow: true,
        isCurrent: false,
      },
    ],
    // ** ご利用者タブ内サブタブ **
    userTabList: [],
    // ** 最終ページ **
    finalPageList: [
      {
        text: '入力内容確認',
        isComplete: null,
        isShow: false,
        isCurrent: true,
      },
      {
        text: '署名',
        isComplete: null,
        isShow: true,
        isCurrent: false,
      },
      {
        text: '契約作成完了',
        isComplete: null,
        isShow: true,
        isCurrent: false,
      },
    ],
    discountList: [],
    estimateIdList: [], // 契約変更時の見積制御判定時に使用
    contractId: null,
    contractTotalPrice: null,
  }
}

export const getInitialUserState = (): User => {
  return {
    // ** ご利用情報 **
    usageinfo: {
      relation: null,
      useDate: '',
      placeOfDressing: null,
      timeOfEnteringDressPlace: '',
      passOption: null,
      passDate: '',
      placeOfPass: null,
      scheduledDeliveryDate: '',
      selectedAddressId: null,
      selectedAddressInfo: null,
      returnOption: null,
      dateOfReturn: '',
      placeOfReturn: null,
      dressingPlaces: null, //着付け会場情報
      desiredTime: null,
    },
    // ** 補正 **
    measuring: {
      yuki: {
        shaku: 1, // 固定値
        sun: null,
        bu: null,
      },
      sode: {
        shaku: 2, // 固定値
        sun: null,
        bu: null,
      },
    },
    accessories: [],
    //* お客様ご準備 */
    customerReady: {
      selectedContents: [],
      selectedOtherContents: [],
    },
    customerId: null,
    estimateId: null,
  }
}

const state = (): State => getInitialStore()

export default state
