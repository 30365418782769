import { computed } from '@nuxtjs/composition-api'
import {
  useStateお客様検索,
  useStateお名前,
  useState試着写真,
  useState商品設定,
  useState割引,
  useState母娘プラン,
  useStateJK前撮り,
} from '~/composable/estimate/repository/comingOfAge/states/StateOtherTabs'
import { useComingStore } from '../ComingOfAgeStore'
import { OnlyTextState } from '~/store/types'
import { useState振袖 } from './states/State振袖'
import { useState見積一覧 } from './states/State見積一覧'
import { useState足し布 } from './states/State足し布'
import { useStateプラン } from './states/Stateプラン'
import { useState別衿 } from './states/State別衿'
import { useState帯 } from './states/State帯'
import { useState帯締め } from './states/State帯締め'
import { useState帯揚げ } from './states/State帯揚げ'
import { useState重ね衿 } from './states/State重ね衿'
import { useState髪飾り } from './states/State髪飾り'
import { useStateショール } from './states/Stateショール'
import { useState草履1個目 } from './states/State草履1個目'
import { useState草履複数個目 } from './states/State草履複数個目'
import { useStateバッグ1個目 } from './states/Stateバッグ1個目'
import { useStateバッグ複数個目 } from './states/Stateバッグ複数個目'
import { useStateWPhoto } from './states/StateWフォト'
import { useStateその他商品 } from './states/Stateその他商品'

export type EstimateState = ReturnType<typeof useEstimateState>['value']

/*
 * 選ばれたプランや選択肢に従って各項目の内容を整理した成人式見積もりの入力内容をComputedRefで返す
 */
export const useEstimateState = () => {
  const stateお名前 = useStateお名前()
  const stateプラン = useStateプラン()
  const state試着写真 = useState試着写真()
  const state振袖 = useState振袖()
  const state帯 = useState帯()
  const state帯締め = useState帯締め()
  const state重ね衿 = useState重ね衿()
  const state帯揚げ = useState帯揚げ()
  const state別衿 = useState別衿()
  const state髪飾り = useState髪飾り()
  const state草履1個目 = useState草履1個目()
  const state草履複数個目 = useState草履複数個目()
  const stateバッグ1個目 = useStateバッグ1個目()
  const stateバッグ複数個目 = useStateバッグ複数個目()
  const stateショール = useStateショール()
  const state商品設定 = useState商品設定()
  const state足し布 = useState足し布()
  const state母娘プラン = useState母娘プラン()
  const stateJK前撮り = useStateJK前撮り()
  const stateWフォト = useStateWPhoto()
  const values備考 = useComingStore<OnlyTextState>('Remarks').getAll()
  const stateお客様検索 = useStateお客様検索()
  const state割引 = useState割引()
  const state見積一覧 = useState見積一覧()
  const stateその他商品 = useStateその他商品()

  return computed(() => {
    return {
      お名前: stateお名前.value,
      プラン: stateプラン.value,
      試着写真: state試着写真.value,
      商品設定: state商品設定.value,
      振袖: state振袖.value,
      帯: state帯.value,
      帯締め: state帯締め.value,
      帯揚げ: state帯揚げ.value,
      重ね衿: state重ね衿.value,
      別衿: state別衿.value,
      髪飾り: state髪飾り.value,
      草履1個目: state草履1個目.value,
      草履複数個目: state草履複数個目.value,
      バッグ1個目: stateバッグ1個目.value,
      バッグ複数個目: stateバッグ複数個目.value,
      ショール: stateショール.value,
      足し布: state足し布.value,
      母娘プラン: state母娘プラン.value,
      JK前撮り: stateJK前撮り.value,
      Wフォト: stateWフォト.value,
      割引: state割引.value,
      備考: values備考.value.text,
      お客様検索: stateお客様検索.value,
      見積一覧: state見積一覧.value,
      その他商品: stateその他商品.value,
    }
  })
}
