import { ComputedRef, Ref, computed } from '@nuxtjs/composition-api'
import { EstimateDetail } from '~/api/estimateRepository'
import { useRestatePlan } from '~/composable/estimate/repository/comingOfAge/restates/RestatePlan'
import { ItemPlan } from '~/constants/enums/itemPlan'
import ProductClassification from '~/constants/enums/productClassification'

export type isObiageKasaneeriPPDiscount = {
  isPPObiage: boolean
  isPPKasaneeri: boolean
}

export const usePPObiageKasaneeriPlan = () => {
  const getObiageKasaneeriPPDiscount = (
    estimate: Ref<EstimateDetail | undefined>
  ): ComputedRef<isObiageKasaneeriPPDiscount> => {
    const restateプラン = useRestatePlan(estimate)
    return computed(() => {
      let isPPObiage = false
      let isPPKasaneeri = false

      if (
        (restateプラン.value?.flags.isフルセットプラン ||
          restateプラン.value?.flags.is写のみレンタル) &&
        estimate.value?.items
      ) {
        if (
          estimate.value?.items.filter(
            (item) => item.product_type_id === ProductClassification.obiage
          ).length > 1
        )
          isPPObiage = true

        if (
          estimate.value?.items.filter(
            (item) => item.product_type_id === ProductClassification.kasaneeri
          ).length > 1
        )
          isPPKasaneeri = true
      }
      return { isPPObiage, isPPKasaneeri }
    })
  }

  /**
   * NOTE: 重ね衿・帯揚げの復元時に使用
   * プレミアムパックを選択している場合に、２品目のplanでランクアップかどうかを判定していたが
   * プレミアムパックを選択してない場合は、２品目のplanは未定のため見積一覧でプレミアムパックで
   * ランクアップを選択しているかの判別は不可
   * → １品目がセット内でプレミアム価格に金額が入力されていれば、２品目をランクアップとするよう判定を修正
   */
  const getSecondProductRankUp = (
    estimate: EstimateDetail | undefined
  ): boolean => {
    if (!estimate) {
      return false
    }

    const isPPApplied = estimate.items?.some(
      (item) =>
        [
          ProductClassification.obiage,
          ProductClassification.kasaneeri,
        ].includes(item.product_type_id) &&
        item.plan === ItemPlan.inSet &&
        item.order_number === 1 &&
        (typeof item.premium_rental_price === 'string'
          ? parseInt(item.premium_rental_price) || false
          : item.premium_rental_price)
    )

    return isPPApplied ?? false
  }

  return { getObiageKasaneeriPPDiscount, getSecondProductRankUp }
}
