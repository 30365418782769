import { computed, Ref, watch } from '@nuxtjs/composition-api'
import { EstimateDetail } from '~/api/estimateRepository'
import { useCustomer } from '~/composable/api/useCustomer'
import { ContractServiceType } from '~/constants/enums/contractServiceType'
import { EstimatePlan } from '~/constants/enums/estimatePlan'
import { ProductCategory } from '~/constants/enums/productCategory'
import { SERVICE_PLAN } from '~/constants/estimate/comingOfAge'

export type PlanFlags = ReturnType<typeof getConditionalFlags>

export const useRestatePlan = (estimate: Ref<EstimateDetail | undefined>) => {
  const { fetchCustomerProfile, customerProfile } = useCustomer()

  // NOTE: computed内で取得処理をしようとすると無限ループが起きる
  watch(estimate, () => {
    if (!estimate.value?.sisters?.customer_id) return
    const sisterId = estimate.value.sisters.customer_id
    fetchCustomerProfile({ customer_id: sisterId })
  })

  return computed(() => {
    if (!estimate.value) return null

    const { contract_service_type } = estimate.value

    return {
      optionIdプラン: getOptionIdOfプラン(estimate.value),
      optionId着付け区分: getOptionIdOf着付け区分(contract_service_type),
      optionId利用方法: getOptionIdOf利用方法(estimate.value),
      optionIdお姉様購入: getOptionIdOfお姉様購入(estimate.value),
      optionId当日のみか: getOptionIdOf当日のみか(estimate.value),
      // NOTE: 何故かcustomerProfileの取得による更新が検知・再計算されないので、
      // リアクティブな状態のまま値を渡している。
      お姉様検索結果: customerProfile,
      成人式使用日: estimate.value.use_date ?? null,
      flags: getConditionalFlags(estimate.value),
    }
  })
}

/**
 * 復元条件に関わる条件式を定義する
 */
const getConditionalFlags = (estimate: EstimateDetail) => {
  const plansフルセット = [
    EstimatePlan.fullSetプラン未決定,
    EstimatePlan.fullSet,
    EstimatePlan.fullSetPurchase,
  ]
  const plansお持込 = [
    EstimatePlan.planAAndPhoto,
    EstimatePlan.planBAndPhoto,
    EstimatePlan.planB,
  ]
  const plansお写真のみ = [
    EstimatePlan.bringInOnlyPhoto,
    EstimatePlan.onlyPhoto,
  ]

  const plans振袖持込 = [...plansお持込, EstimatePlan.bringInOnlyPhoto]

  const isお姉様購入 =
    estimate.hurisode?.category === ProductCategory.purchasedElderSister

  return {
    isフルセットプラン: plansフルセット.includes(estimate.plan),
    isお持込プラン: plansお持込.includes(estimate.plan),
    isお写真のみプラン: plansお写真のみ.includes(estimate.plan),
    is写のみレンタル: estimate.plan === EstimatePlan.onlyPhoto,
    is写のみ持込: estimate.plan === EstimatePlan.bringInOnlyPhoto,
    is当日Bコース: estimate.plan === EstimatePlan.planB,
    is振袖持込: plans振袖持込.includes(estimate.plan),
    isお姉様購入,
  }
}

/**
 * 選択肢系の項目の初期値(選択されたoptionのid)を再計算する関数群
 */
const getOptionIdOfプラン = (estimate: EstimateDetail) => {
  const flags = getConditionalFlags(estimate)

  if (flags.isフルセットプラン) return SERVICE_PLAN.FULL_SET
  if (flags.isお持込プラン) return SERVICE_PLAN.BRING_IN
  if (flags.isお写真のみプラン) return SERVICE_PLAN.ONLY_PHOTO

  return 0
}

const getOptionIdOf着付け区分 = (
  type: EstimateDetail['contract_service_type']
) => {
  const is弊社着付け = type === ContractServiceType.ourDressing
  const is遠方提携美容室 = type === ContractServiceType.cosmetologySalon
  const is着付けなし = type === ContractServiceType.noDressing
  if (is弊社着付け) return SERVICE_PLAN.OUR_DRESSIN
  if (is遠方提携美容室) return SERVICE_PLAN.COSMETOLOGY_SALON
  if (is着付けなし) return SERVICE_PLAN.NO_DRESSING
  return 0
}

const getOptionIdOf利用方法 = (estimate: EstimateDetail) => {
  const flags = getConditionalFlags(estimate)

  if (flags.is写のみレンタル) return SERVICE_PLAN.RENTAL
  if (flags.is写のみ持込) return SERVICE_PLAN.BRING_IN_ONLY_PHOTO
  return 0
}

const getOptionIdOfお姉様購入 = (estimate: EstimateDetail) => {
  const flags = getConditionalFlags(estimate)
  if (flags.isお姉様購入) return SERVICE_PLAN.PURCHASE_AT_OUR
  return SERVICE_PLAN.NOT_PURCHASE_AT_OUR
}

const getOptionIdOf当日のみか = (estimate: EstimateDetail) => {
  const flags = getConditionalFlags(estimate)
  if (flags.is当日Bコース) return SERVICE_PLAN.ONLY_COMIMG_OF_AGE
  return SERVICE_PLAN.PRE_SHOOTING_AND_COMIMG_OF_AGE
}
