



































import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  ref,
  watch,
  useRoute,
  useRouter,
  nextTick,
} from '@nuxtjs/composition-api'
import IconArrowLeft from '~/components/icons/IconArrowLeft.vue'
import IconValidationSuccess from '~/components/icons/IconValidationSuccess.vue'
import IconValidationError from '~/components/icons/IconValidationError.vue'
import { useTabNaviList } from '~/composable/estimate/service/graduation/TabNavigationList'
import { TabNaviItem } from '~/composable/estimate/service/graduation/TabNavigationItem'
import { useEstimateEdit } from '~/composable/estimate/service/useEstimateEdit'

export default defineComponent({
  components: {
    IconArrowLeft,
    IconValidationError,
    IconValidationSuccess,
  },
  setup(_, { emit }) {
    const route = useRoute()
    const router = useRouter()

    const { tabs, currentTab, setNextUrl } = useTabNaviList()
    const { rewritePathWithQuery } = useEstimateEdit()

    const selectedTab = ref(currentTab.value.label)

    const scrollYAmount = ref(0)

    const handleScroll = () => {
      scrollYAmount.value = window.scrollY
    }

    const clickEvent = (tab: TabNaviItem) => {
      selectedTab.value = tab.label

      const { estimate_id } = route.value.params
      if (Number(estimate_id) > 0) {
        // NOTE: 本来であれば`useTabView入力確認`ないのestimateIdを変更するべきだが、
        //       変更点多すぎて影響範囲広すぎるため見積変更のパスに無理やり置き換える
        router.push(
          rewritePathWithQuery(tab.url.replace('/0/', `/${estimate_id}/`))
        )
      } else {
        router.push(tab.url)
      }

      setNextUrl()
      scrollTab()
    }

    const historyBack = () => {
      history.back()
      window.addEventListener('popstate', () => {})
    }

    nextTick(() => {
      // コンソールエラー出るので記述（document is not defined）
      if (!process.browser) return

      // リロード時の横スクロール
      const scrollElement = document.getElementsByClassName(
        'estimate-header-navi-list'
      )[0]
      const currentTabRect = document
        .getElementsByClassName('-current')[0]
        ?.getBoundingClientRect()
      if (!scrollElement) return
      scrollElement.scrollTo({
        top: 0,
        left: currentTabRect?.left,
        behavior: 'smooth',
      })
    })

    // タブを横スクロールする
    const scrollTab = () => {
      const currentTabElement = document.getElementsByClassName('-current')[0]
      currentTabElement.scrollIntoView({
        block: 'nearest',
        inline: 'start',
        behavior: 'smooth',
      })
    }

    onMounted(() => {
      window.addEventListener('scroll', handleScroll)

      selectedTab.value = currentTab.value.label
      emit('selectTab', currentTab.value.label)
      setNextUrl()
    })

    watch(route, () => {
      selectedTab.value = currentTab.value.label
      emit('selectTab', currentTab.value.label)
      setNextUrl()
    })

    // NOTE: タブの並びが変更になったら隣のタブ情報も更新する
    watch(tabs, () => {
      setNextUrl()
    })

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll)
    })

    return {
      clickEvent,
      scrollYAmount,
      selectedTab,
      historyBack,
      tabs,
    }
  },

  head: {},
})
