import { computed } from '@nuxtjs/composition-api'
import { usePlanTypeFlags } from '~/composable/estimate/domain/ComingOfAge/PlanTypeFlags'
import { EstimateState } from '~/composable/estimate/repository/comingOfAge/EstimateState'
import { PlanFlags } from '~/composable/estimate/repository/comingOfAge/states/types'
import { Item, ItemWPhoto } from '../types'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { State as PricesState } from '~/store/PriceTable/state'
import {
  EstimateJkphotoInAdvanceState,
  OnlySelectionPlanState,
  WphotoState,
} from '~/store/types'

export const useListItem母娘プラン訪問着Formatter = (
  state: EstimateState['母娘プラン']
) => {
  const priceTable = useComingStore<OnlySelectionPlanState>(
    'MotherAndDaughterPlan'
  ).getPrices() // Store料金情報を取得
  const {
    isフルセット,
    is写真のみレンタル,
    is当日のみBコース,
    is振袖持込Not当日のみBコース,
  } = usePlanTypeFlags()
  return computed(() => {
    const flags = {
      isフルセット: isフルセット.value,
      is写のみレンタル: is写真のみレンタル.value,
      is持ち込み: is振袖持込Not当日のみBコース.value,
      is当日Bコース: is当日のみBコース.value,
    }
    return getListItem母娘プラン訪問着(state, flags, priceTable)
  })
}
export const getListItem母娘プラン訪問着 = (
  values母娘プラン: EstimateState['母娘プラン'],
  { is当日Bコース }: PlanFlags,
  priceTable: PricesState['prices']
): Item | null => {
  if (!values母娘プラン.is適用 || is当日Bコース) return null

  return {
    item: {
      name: '母娘プラン訪問着レンタル(着付代込み)',
      addition: true,
    },
    column1: {
      price: priceTable.estimate.母娘プラン訪問着レンタル,
    },
    column2: {
      price: priceTable.estimate.母娘プラン訪問着レンタル,
    },
    column3: {
      price: priceTable.estimate.母娘プラン訪問着レンタル,
    },
    column4: {
      price: priceTable.estimate.母娘プラン訪問着レンタル,
    },
  }
}

export const useListItem着付代Formatter = (
  stateプラン: EstimateState['プラン']
) => {
  const {
    isフルセット,
    is写真のみレンタル,
    is当日のみBコース,
    is振袖持込Not当日のみBコース,
    is写真のみ持込,
  } = usePlanTypeFlags()
  const priceTable =
    useComingStore<EstimateJkphotoInAdvanceState>(
      'JkPhotoInAdvance'
    ).getPrices() // Store価格テーブルの取得

  return computed(() => {
    const flags = {
      isフルセット: isフルセット.value,
      is写のみレンタル: is写真のみレンタル.value,
      is持ち込み: is振袖持込Not当日のみBコース.value,
      is当日Bコース: is当日のみBコース.value,
      is写のみ持込: is写真のみ持込.value,
    }
    return getListItem着付代(stateプラン, flags, priceTable)
  })
}
export const getListItem着付代 = (
  stateプラン: EstimateState['プラン'],
  { is写のみレンタル, is当日Bコース, is写のみ持込 }: PlanFlags,
  priceTable: PricesState['prices']
) => {
  const 当日: Item = {
    item: {
      name: '当日ヘアメイク着付代',
      addition: true,
    },
    column1: {
      price: stateプラン.当日ヘアメイク着付代 ?? 0,
    },
    column2: {
      price: stateプラン.当日ヘアメイク着付代 ?? 0,
    },
    column3: {
      price: stateプラン.当日ヘアメイク着付代 ?? 0,
    },
    column4: {
      price: stateプラン.当日ヘアメイク着付代 ?? 0,
    },
  }

  const 前撮り: Item = {
    item: {
      name: '前撮りヘアメイク着付代',
      addition: true,
    },
    column1: {
      price: priceTable.estimate.前撮りヘアメイク着付け代,
    },
    column2: {
      price: priceTable.estimate.前撮りヘアメイク着付け代,
    },
    column3: {
      price: priceTable.estimate.前撮りヘアメイク着付け代,
    },
    column4: {
      price: priceTable.estimate.前撮りヘアメイク着付け代,
    },
  }

  return {
    // NOTE: 写のみプランには成人式当日へのオプションは存在しない
    当日: is写のみレンタル || is写のみ持込 ? null : 当日,
    // NOTE: 当日Bコースプランは当日のみなので前撮り関連のオプションは存在しない
    前撮り: is当日Bコース ? null : 前撮り,
  }
}

export const useListItemWPhoto着付代Formatter = (is振袖レンタル: boolean) => {
  const priceTable = useComingStore<WphotoState>('WphotoPlan').getPrices() // Store料金情報を取得

  return computed(() => {
    return getListItemWPhoto着付代(is振袖レンタル, priceTable)
  })
}

export const getListItemWPhoto着付代 = (
  is振袖レンタル: boolean,
  priceTable: PricesState['prices']
): ItemWPhoto => {
  if (is振袖レンタル) {
    // 振袖レンタルは着付代￥0
    return {
      item: {
        name: 'Wフォトプラン着付代',
        addition: true,
      },
      column1: {
        price: 0,
      },
      column2: {
        price: 0,
      },
    }
  } else {
    return {
      item: {
        name: 'Wフォトプラン着付代',
        addition: true,
      },
      column1: {
        price: priceTable.estimate.Wフォト着付け代,
      },
      column2: {
        price: priceTable.estimate.Wフォト着付け代,
      },
    }
  }
}
