import { computed } from '@nuxtjs/composition-api'
import { AccessoryPlanState } from '~/store/types'
import { useComingStore } from '../../ComingOfAgeStore'
import { useScreenTypeFlags } from '~/composable/estimate/domain/ComingOfAge/ScreenTypeFlags'
import { PlanFlags } from './types'
import ProductClassification from '~/constants/enums/productClassification'
import { Zori } from './zoriBagUtils'
import { useState商品設定 } from './StateOtherTabs'
import { ItemPlan } from '~/constants/enums/itemPlan'
import { EstimateSinglePriceType } from '~/constants/enums/estimateSinglePriceType'
import { GEINPIN } from '~/composable/estimate/viewModel/comingOfAge/FieldProps/FieldProps草履バッグ'

export const useState草履複数個目 = () => {
  const values草履2 = useComingStore<AccessoryPlanState>('Zori2').getAll()
  const values草履3 = useComingStore<AccessoryPlanState>('Zori3').getAll()
  const isPPDiscount = values草履2.value.isPPDiscount
  const isPPAddingPrice = values草履2.value.isPPAddingPrice
  const state商品設定 = useState商品設定()
  const { showTypeフルセット, showType写真のみレンタル, showType持ち込み } =
    useScreenTypeFlags()
  return computed(() => {
    const flags = {
      isフルセット: showTypeフルセット.value,
      is写のみレンタル: showType写真のみレンタル.value,
      is持ち込み: showType持ち込み.value,
    }
    const values草履複数個目 = [values草履2.value, values草履3.value]
    return getState草履複数個目(
      values草履複数個目,
      state商品設定.value.草履,
      flags,
      !!isPPDiscount,
      !!isPPAddingPrice
    )
  })
}

export const getState草履複数個目 = (
  values草履複数個目: AccessoryPlanState[],
  count草履: number,
  planFlags: PlanFlags,
  isPPDiscount: boolean,
  isPPAddingPrice: boolean
) => {
  // NOTE: 2個目以降のみを加工するので、草履1個しかない場合は空配列を返して終了
  if (count草履 <= 1) return []
  const filledStoreValues = values草履複数個目.slice(0, count草履 - 1)

  return filledStoreValues.map((value, i) => {
    const { selections, input } = value
    const dividedNames = devidePostNumberCode(input.productName)
    // NOTE: １個目は別で処理しているため、index=0を2個目にするために2を足す
    const numberText = i >= 0 ? ' - ' + (i + 2) : ''

    const zori: Zori = {
      品目名:
        ProductClassification.toWord(ProductClassification.zori) + numberText,
      品種区分: ProductClassification.zori,
      商品: {
        id: selections[0] === GEINPIN.id ? null : selections[0],
        本体名: dividedNames.productName || '',
        postNumberCode: dividedNames.postNumberCode || '',
      },
      商品名: input.productName,
      小物プラン: ItemPlan.toBeDecided, // 単品購入しかないため固定
      レンタル価格: 0,
      購入価格: 0,
      単品購入価格: input.buyPrice,
      プレミアムレンタル価格: 0,
      プレミアム購入価格: input.premiumBuyPrice || 0,
      見積表示: {
        id: EstimateSinglePriceType.singlePurchasePrice,
        value: '単品購入',
      },
      表示価格: input.buyPrice,
      label: null,
      cellLabel: '単品購入', // 単品購入しかないため固定
      linkText: input.productName,
      flags: {
        isランクアップ: false,
        is振袖レンタル時単品購入: false,
        is振袖持込: planFlags.is持ち込み,
        ...(i === 0 ? { isPPDiscount: isPPDiscount } : {}), // 商品２つ目のみ適用
        ...(i === 0 ? { isPPAddingPrice: isPPAddingPrice } : {}), // 商品２つ目のみ適用
      },
    }

    return zori
  })
}

/**
 * 商品名本体とポストナンバーコードに分離する
 */
export const devidePostNumberCode = (wholeName: string | undefined) => {
  const dividedNames = {
    postNumberCode: '',
    productName: '',
  }
  if (!wholeName) return dividedNames
  const splittedNames = wholeName.split(' ')
  const regExpPostNumberCode = /[A-Z]{1,2}-[0-9]{1,2}/

  if (regExpPostNumberCode.test(splittedNames[0]) && splittedNames.length > 1) {
    dividedNames.postNumberCode = splittedNames[0]
    dividedNames.productName = splittedNames[1]
  } else {
    dividedNames.postNumberCode = ''
    dividedNames.productName = splittedNames[0]
  }
  return dividedNames
}
