import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'

/** types */
import { Restate } from '~/composable/estimate/repository/graduation/EstimateRestate'
import { State as PricesState } from '~/store/PriceTable/state'

export const useRestorePriceTable = () => {
  const store = useGraduateStore<PricesState>('')

  const restorePriceTable = (priceTable: Restate['price_information']) => {
    if (!priceTable) return
    store.setPrices<PricesState['prices']>('setPrices', priceTable)
  }
  return { restorePriceTable }
}
