import { Ref, computed, useStore } from '@nuxtjs/composition-api'
import { Store } from 'vuex'
import { useGraduateStore } from '../../../repository/GraduationStore'
import { useEstimateState } from '../../../repository/graduation/EstimateState'
import { useEstimateTotalPrice } from '../EstimatePrice'
import { useAdditionsFormatter } from './formatters/additions'
import { useDiscountesFormatter } from './formatters/discounts'
import { useHakamaFormatter } from './formatters/hakama'
import { useHurisodeFormatter } from './formatters/hurisode'
import { useItemsFormatter } from './formatters/items'
import { usePhotoPlanFormatter } from './formatters/photoPlan'
import { usePhotoFormatter } from './formatters/photos'
import { usePlanFormatter } from './formatters/plan'
import {
  DOCUMENT,
  COORDINATE_PLAN,
  IS_TOMONOKAI_MEMBER,
} from '~/constants/estimate/config'
import DateTime from '~/utils/dateTime'
import { FrontStoreInfo, MemoStateGraduate } from '~/store/types'
import { useMemo } from '~/composable/api/useMemo'
import { useEstimate } from '~/composable/api/useEstimate'
import { CreateEstimateParams, EstimateDetail } from '~/api/estimateRepository'
import { useTemporarilySaved } from '~/composable/api/useTemporarilySaved'
import { useEstimateTypeFlags } from '~/composable/estimate/domain/EstimateTypeFlags'
import { 卒業式見積一覧 } from '~/store/Estimate/GraduationCeremony/tab見積一覧/state'
import { usePriceTableFormatter } from '../../comingOfAge/EstimateRequest/formatters/priceTable'
import { CopiedEstimateState } from '~/store/Estimate/GraduationCeremony/CopiedEstimate/state'
import { modifyCopyingProducts卒業式 } from '../../EstimateFormatter/copyingProducts'
import { useTomonokaiFlag } from '~/composable/estimate/domain/Graduation/TomonokaiFlag'
import { useState袴 } from '~/composable/estimate/repository/graduation/states/State袴'
import { useState振袖二尺袖 } from '~/composable/estimate/repository/graduation/states/State振袖二尺袖'

export const useGraduateEstimateRequest = () => {
  const estimateState = useEstimateState()
  const { deleteItem } = useTemporarilySaved()
  const store = useStore() as Store<any>
  const storeMemo = useGraduateStore<MemoStateGraduate>('memo')
  const storedfrontStoreId = computed<FrontStoreInfo['frontStoreId']>(() =>
    store.getters['FrontStoreInfo/getState']('frontStoreId')
  )
  const priceTable = useGraduateStore('').getPrices() // Store価格テーブルの取得
  const store見積一覧 = useGraduateStore<卒業式見積一覧>('tab見積一覧')
  const copiedEstimate =
    useGraduateStore<CopiedEstimateState>('CopiedEstimate').getAll().value
      ?.estimate // 見積コピー時には元の見積詳細情報が入っている
  const { postEstimate, patchEstimate, error } = useEstimate()
  const {
    getPlan,
    getUseDate,
    getContractServiceType,
    getComingOfAgeContractId,
  } = usePlanFormatter(estimateState)
  const formattedPhotos = usePhotoFormatter(estimateState)
  const { getFormattedHurisode } = useHurisodeFormatter(estimateState)
  const { getFormattedDiscounts } = useDiscountesFormatter(estimateState)
  const { getFormattedHakama } = useHakamaFormatter(estimateState)
  const { getFormattedAdditions } = useAdditionsFormatter(
    estimateState,
    priceTable
  )
  const { getFormattedItems } = useItemsFormatter(estimateState)
  const { getFormattedPhotoPlan } = usePhotoPlanFormatter(estimateState)
  const { postMemo, memoGraduationState } = useMemo()
  const { total, totalWithPP } = useEstimateTotalPrice()
  const formattedPriceTable = usePriceTableFormatter(priceTable)
  const { in卒業式見積 } = useEstimateTypeFlags()
  const state振袖二尺袖 = useState振袖二尺袖()
  const state袴 = useState袴()
  const is振袖保留 = computed(() => state振袖二尺袖.value.flags.is振袖保留)
  const is二尺袖保留 = computed(() => state振袖二尺袖.value.flags.is二尺袖保留)
  const is袴保留 = computed(() => state袴.value.flags.is袴保留)
  const amount_adjustments = computed(
    () => store見積一覧.get<'amount_adjustments'>('amount_adjustments').value
  )
  const selectedPlan = computed(
    () => store見積一覧.get<'selectedPlan'>('selectedPlan').value
  )
  const { isTomonokai } = useTomonokaiFlag()

  const totalPrice = computed(() => {
    switch (selectedPlan.value) {
      case COORDINATE_PLAN.RENTAL:
        return total.value.number
      case COORDINATE_PLAN.RENTAL_PREMIUM:
        return totalWithPP.value.number
      default:
        return 0
    }
  })

  const chackOnHoldAlert = (is着物保留: Ref<boolean>, item: string) => {
    if (is着物保留.value) {
      let errorText = `${item}が仮設定中です。商品選択は${item}タブから行ってください。`
      alert(errorText)
      return false
    }
    return true
  }

  // 新規作成のために送信するリクエストを作成する関数
  const createEstimateReqBody = (): CreateEstimateParams | undefined => {
    try {
      const customerInfo = estimateState.value.お客様検索
      if (!customerInfo) throw new Error('お客様情報が取得されていません')

      // NOTE: 見積コピーした場合かつ商品に変更があった場合、product_idを送らない必要あるパターンがあるためここで行う
      // 対象範囲・詳細についてはチケット参照
      // https://vitgear.backlog.com/view/WB-3216
      const formattedHurisode = getFormattedHurisode()
      const { hurisode } = modifyCopyingProducts卒業式(
        copiedEstimate,
        formattedHurisode
      )

      const estimateReqBody = {
        customer_id: customerInfo.customer_id,
        staff_id: store.state.LoginUser.user?.staff_id,
        plan: getPlan(),
        contract_service_type: getContractServiceType(),
        type: 2 as const, // 卒業式の定数,
        use_date: getUseDate(),
        photos: formattedPhotos.value.formatted全体写真,
        neck_photo_url: formattedPhotos.value.formatted衿元写真,
        // ↓hurisodeという名称だが実際には二尺袖も含む「着物」情報を送るパラメータ
        hurisode,
        items: getFormattedItems(),
        discounts: getFormattedDiscounts(),
        remarks: estimateState.value.備考 ?? '',
        contract_total_price: totalPrice.value,
        coming_of_age_ceremony_contract_id: getComingOfAgeContractId(),
        selected_plan: selectedPlan.value,
        hakama: getFormattedHakama(),
        photo_plan: getFormattedPhotoPlan(),
        estimate_date: new DateTime().toDateString(),
        shop_id: store.state.SettingShop.shop.id,
        additions: getFormattedAdditions(),
        is_jk_photos_in_advance: false, // 卒業式にはないので常にfalse
        amount_adjustments: amount_adjustments.value,
        visiting_reason: customerInfo.visiting_reasonsIds,
        reference_price_information: formattedPriceTable.priceTables,
        is_tomonokai_member: isTomonokai.value
          ? IS_TOMONOKAI_MEMBER.YES
          : IS_TOMONOKAI_MEMBER.NO,
      }
      console.log('estimateReqBody :>> ', estimateReqBody)

      return estimateReqBody
    } catch (error) {
      alert('卒業式見積作成に必要な入力が足りていません \n' + error)
    }
  }

  // NOTE: console用の呼び出し(↓契約書側で成人式の時やストアが空の時呼び出しを避ける条件式)
  if (in卒業式見積.value && estimateState.value.お客様検索) {
    createEstimateReqBody()
  }

  const updateEstimateReqBody = (
    estimateId: number
  ): EstimateDetail | undefined => {
    const estimateReqBody = createEstimateReqBody()
    if (!estimateReqBody) {
      return undefined
    }

    return {
      ...estimateReqBody,
      estimate_id: estimateId,
    }
  }

  const postGraduateEstimate = async () => {
    // 振袖・袴・二尺袖を保留選択している場合、アラートを出して選択を促す
    if (!chackOnHoldAlert(is振袖保留, '振袖')) return false
    if (!chackOnHoldAlert(is二尺袖保留, '二尺袖')) return false
    if (!chackOnHoldAlert(is袴保留, '袴')) return false

    const estimateReqBody = createEstimateReqBody()

    if (!estimateReqBody) {
      throw new Error('見積書作成時のデータの成形に失敗しました。')
    }

    // NOTE: 一時保存データの場合は見積作成と同時に一時保存データの方を削除する
    if (storedfrontStoreId.value.estimate) {
      deleteItem(storedfrontStoreId.value.estimate, DOCUMENT.見積書.id)
    }

    const res = await postEstimate(estimateReqBody)

    if (!res?.estimate_id) {
      console.error('見積作成のレスポンスからestimate_idを取得できませんでした')
      throw new Error('見積書の作成に失敗しました。')
    }

    memoGraduationState.estimate_id = res?.estimate_id
    storeMemo.set('estimate_id', res.estimate_id)
    storeMemo.set('customer_id', estimateReqBody.customer_id)

    if (memoGraduationState.body) {
      memoGraduationState.estimate_id = res.estimate_id
      await postMemo(
        estimateReqBody.customer_id,
        memoGraduationState,
        '卒業式見積書作成',
        'graduation'
      )
    }
    return { error, estimateId: res.estimate_id }
  }

  const updateGraduateEstimate = async (estimateId: number) => {
    try {
      if (!estimateId) {
        error.value = '無効な契約番号です'
        return { error }
      }

      const body = updateEstimateReqBody(estimateId)
      if (!body) {
        error.value = 'estimateReqBodyが取得できませんでした'
        return { error }
      }

      storeMemo.set('estimate_id', estimateId)

      await patchEstimate(estimateId, body)

      if (memoGraduationState.body) {
        memoGraduationState.estimate_id = estimateId
        await postMemo(
          body.customer_id,
          memoGraduationState,
          '卒業式見積書更新',
          'graduation'
        )
      }
      return { error }
    } catch (err) {
      error.value = '見積書の更新が失敗しました'
      if (err instanceof Error) {
        error.value = err.message
      }

      return { error }
    }
  }

  return { createEstimateReqBody, postGraduateEstimate, updateGraduateEstimate }
}
