import { computed } from '@nuxtjs/composition-api'
import {
  getBadgeText,
  getItemPlan,
  getItemPlanFlags,
  getPriceTypeText,
  getWPhotoItemPrices,
} from '~/composable/estimate/repository/comingOfAge/states/utilities'
import { ObiState } from '~/store/types'
import ProductClassification from '~/constants/enums/productClassification'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { WPhotoBaseItemState } from '../types'
import { useState振袖 } from './State振袖'
import { useState商品設定 } from './StateOtherTabs'
import { PLAN } from '~/constants/estimate/comingOfAge'

type Obiage = WPhotoBaseItemState & {
  品種区分: 4
}

export const useState帯揚げ = () => {
  const valuesお持込 = useComingStore<ObiState>(
    'WphotoPlan/WphotoObiageBring'
  ).getAll()
  const valuesレンタル = useComingStore<ObiState>(
    'WphotoPlan/WphotoObiageRental'
  ).getAll()
  const values帯揚げ2 = useComingStore<ObiState>(
    'WphotoPlan/WphotoObiage2'
  ).getAll()
  const values帯揚げ3 = useComingStore<ObiState>(
    'WphotoPlan/WphotoObiage3'
  ).getAll()

  const state振袖 = useState振袖()
  const state商品設定 = useState商品設定()

  return computed(() => {
    const count = state商品設定.value.帯揚げ
    if (state振袖.value.flags.isレンタル) {
      const values帯揚げ = [
        valuesレンタル.value,
        values帯揚げ2.value,
        values帯揚げ3.value,
      ]
      return getState帯揚げ(values帯揚げ, count, true)
    } else {
      const values帯揚げ = [
        valuesお持込.value,
        values帯揚げ2.value,
        values帯揚げ3.value,
      ]
      return getState帯揚げ(values帯揚げ, count, false)
    }
  })
}

export const getState帯揚げ = (
  values帯揚げ: ObiState[],
  count帯揚げ: number,
  is振袖レンタル: boolean
) => {
  const filledStoreValues = values帯揚げ.slice(0, count帯揚げ)
  return filledStoreValues.map((value, i) => {
    const { selections, input } = value
    const itemFlags = getItemPlanFlags(selections)
    const itemPlan = getItemPlan(selections)
    const numberText = i > 0 ? ' - ' + (i + 1) : ''

    const obiage: Obiage = {
      品目名:
        ProductClassification.toWord(ProductClassification.obiage) + numberText,
      品種区分: ProductClassification.obiage,
      商品名: input.productName ?? '',
      商品id:
        !selections[2] || selections[2] === PLAN.ACTUAL_PRODUCT
          ? null
          : selections[2],
      小物プラン: itemPlan,
      レンタル価格: 0,
      購入価格: 0,
      プレミアムレンタル価格: input.premiumRentalPrice,
      見積表示: null,
      表示価格: null,
      label: getBadgeText(itemPlan),
      cellLabel: getPriceTypeText(selections),
      flags: {
        isランクアップ:
          itemFlags.isランクアップPP内 || itemFlags.isランクアップPP外,
      },
    }

    const updatedPrices = getWPhotoItemPrices(value, obiage, is振袖レンタル, i)
    obiage.レンタル価格 = updatedPrices.レンタル価格
    obiage.購入価格 = updatedPrices.購入価格
    obiage.プレミアムレンタル価格 = updatedPrices.プレミアムレンタル価格
    obiage.見積表示 = updatedPrices.見積表示
    obiage.表示価格 = updatedPrices.表示価格

    return obiage
  })
}
