import { computed } from '@nuxtjs/composition-api'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { FurisodeState } from '~/store/types'
import { FURISODE_PLAN } from '~/constants/estimate/comingOfAge'

/**
 * @note 振袖タブで選択した項目の内容によって振袖の種類を該当種かどうか真偽値で返す
 */
export const useHurisodeTypeFlags = () => {
  const store = useComingStore<FurisodeState>('Furisodes')
  const values = store.getAll()

  const is仕立上り = computed(() => {
    return values.value.product?.type === 'finished'
  })

  const is仕立上り保留 = computed(() => {
    return !!values.value.isFurisodeOnHold
  })

  const isカタログ = computed(() => {
    if (!values.value.product?.type) return false
    return ['catalog_furisode', 'custom_order'].includes(
      values.value.product.type
    )
  })

  // NOTE: カタログ検索した結果、仕立て上りレンタルが選ばれている場合
  const isカタログ仕立て = computed(() => {
    return (
      values.value.selections.includes(FURISODE_PLAN.SHITATE_RENTAL) ||
      values.value.catalogType === '仕立て上がり'
    )
  })

  const isお誂えレンタル = computed(() => {
    return values.value.selections.includes(FURISODE_PLAN.CUSTOM_RENTAL)
  })

  const isオーダーレンタル = computed(() => {
    return values.value.selections.includes(FURISODE_PLAN.ORDER_RENTAL)
  })

  const isプロパー = computed(() => {
    return values.value.selections.includes(FURISODE_PLAN.PROPER)
  })

  return {
    is仕立上り,
    is仕立上り保留,
    isカタログ,
    isプロパー,
    isお誂えレンタル,
    isオーダーレンタル,
    isカタログ仕立て,
  }
}
