import { GetterTree } from 'vuex'
import { RootState, SignatureState } from '../../types'

const getters: GetterTree<SignatureState, RootState> = {
  getChecked: (state: SignatureState) => {
    return state.checked
  },

  getSignatureUrl: (state: SignatureState) => {
    return state.signature_url
  },

  getParentalSignatureUrl: (state: SignatureState) => {
    return state.parental_signature_url
  },

  getFetchedAt: (state: SignatureState) => {
    return state.fetchedAt
  },

  getIsValidate: (state: SignatureState) => {
    return state.isValidate
  },

  getContractTotalPrice: (state: SignatureState) => {
    return state.contractTotalPrice
  }
}

export default getters
