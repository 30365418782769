import { GetterTree } from 'vuex'
import { RootState, ContractRemarkState } from '../../types'

const getters: GetterTree<ContractRemarkState, RootState> = {
  getInput: (state: ContractRemarkState) => {
    return state.input
  },

  getIsValidate: (state: ContractRemarkState) => {
    return state.isValidate
  },
}

export default getters
