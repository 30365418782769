






















import {
  defineComponent,
  useMeta,
  onMounted,
  ref,
  watch,
  useRoute,
  computed,
} from '@nuxtjs/composition-api'
import IconArrowLeft from '~/components/icons/IconArrowLeft.vue'
import IconValidationSuccess from '~/components/icons/IconValidationSuccess.vue'
import IconValidationError from '~/components/icons/IconValidationError.vue'
import EstimateHeaderNavList from '~/components/graduationCeremony/molecules/EstimateHeaderNavList.vue'
import { urls } from '~/constants/urls'
import { usePageFlags } from '~/composable/estimate/domain/PageFlags'
import { FormValuesお名前 } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsお名前'
import { FormValuesお客様検索 } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsお客様検索'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { useTabNaviList } from '~/composable/estimate/service/graduation/TabNavigationList'
import { useEstimateTypeFlags } from '~/composable/estimate/domain/EstimateTypeFlags'

export default defineComponent({
  components: {
    IconArrowLeft,
    IconValidationError,
    IconValidationSuccess,
    EstimateHeaderNavList,
  },
  setup() {
    const route = useRoute()
    const { inTabPage } = usePageFlags()
    const selectedPage = ref('')
    const storeお名前 = useGraduateStore<FormValuesお名前>('tabお名前')
    const storeお客様検索 =
      useGraduateStore<FormValuesお客様検索>('tabお客様検索')
    const { currentTab } = useTabNaviList()
    const { 入力内容確認, 見積作成完了, 見積一覧 } = urls.見積書.卒業式
    const { estimate_id } = route.value.params
    const { in卒業式見積, in成人式見積 } = useEstimateTypeFlags()
    const is見積一覧 = computed(() => selectedPage.value === '見積一覧')

    const customerName = computed(() => {
      return storeお名前.get<'onamaeお名前'>('onamaeお名前').value
    })

    const selectedCustomerName = computed(() => {
      const selectedCustomer =
        storeお客様検索.get<'okyakusama検索'>('okyakusama検索').value
      return (
        (selectedCustomer?.last_name ?? '') +
        (selectedCustomer?.first_name ?? '')
      )
    })

    const customerNameDisplay = computed(
      () => selectedCustomerName.value || customerName.value
    )

    //NOTE: タブではない下記の３ページはEstimateHeaderNavListから選択中ページのラベルが渡ってこないためここでラベルを設置する
    const setSelectedPage = (label: string = '') => {
      if (label && !currentTab.value.label) {
        selectedPage.value = label
        return
      } else if (currentTab.value.label) {
        selectedPage.value = currentTab.value.label
        return
      }
      const labels = ['入力内容確認', '見積作成完了', '見積一覧']
      const urlGetters = [入力内容確認, 見積作成完了, 見積一覧]
      urlGetters.forEach((urlGetter, i) => {
        const url = urlGetter(Number(estimate_id))
        if (route.value.path === url) {
          selectedPage.value = labels[i]
        }
      })
    }

    const historyBack = () => {
      history.back()
    }

    //今いる見積ページが卒業式なのか成人式か判定
    const eventName = computed(() => {
      if (in卒業式見積.value) {
        return '卒業式'
      } else if (in成人式見積.value) {
        return '成人式'
      }
    })

    onMounted(() => {
      setSelectedPage()
    })

    watch(route, () => {
      setSelectedPage()
    })

    // titleを動的に変更
    useMeta(() => ({
      title: customerNameDisplay.value
        ? `furisode mode | ${customerNameDisplay.value} 様`
        : 'furisode mode',
    }))

    return {
      setSelectedPage,
      selectedPage,
      customerNameDisplay,
      historyBack,
      inTabPage,
      eventName,
      is見積一覧,
    }
  },

  head: {},
})
