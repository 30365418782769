import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { ObiState } from '~/store/types'

export const useRestoreWPhoto帯揚げ = () => {
  const storeお持込 = useComingStore<ObiState>('WphotoPlan/WphotoObiageBring')
  const storeレンタル = useComingStore<ObiState>(
    'WphotoPlan/WphotoObiageRental'
  )
  const store2 = useComingStore<ObiState>('WphotoPlan/WphotoObiage2')
  const store3 = useComingStore<ObiState>('WphotoPlan/WphotoObiage3')

  const setStore = (target: typeof storeお持込, formValues: ObiState) => {
    target.setOf<ObiState['selections']>('setSelection', formValues.selections)
    target.setOf<ObiState['input']>('setInput', formValues.input)
    target.setOf<ObiState['price']>('setPrice', formValues.price)
    target.setOf<ObiState['isFree']>('setIsFree', formValues.isFree)
    storeお持込.setIsValidate(false)
    storeレンタル.setIsValidate(false)
    store2.setIsValidate(false)
    store3.setIsValidate(false)
  }

  const restoreWPhoto帯揚げ = (obiages: Restate['w_photo_plan']['帯揚げ']) => {
    if (!obiages) return
    obiages.forEach((item, i) => {
      /* 初期値を設定 ※必ず入力される値はここで設置 */
      const formValues: ObiState = {
        /**
         * NOTE: selections = patternLine
         * ↓保持される選択肢のindex
         * [0]種別
         * [1]形態
         */
        selections: [item.optionId種別, 0],
        product: null,
        input: {
          productName: '',
          rentalPrice: 0,
          rankUpRentalPrice: 0,
          premiumRentalPrice: 0,
          buyPrice: 0,
          rankUpBuyPrice: 0,
          premiumBuyPrice: 0,
        },
        price: {
          buy: 0,
          rental: 0,
        },
        isFree: false,
        isValidate: null,
      }

      /* 以下で入力内容の条件に基づき、必要な項目を埋めていく */
      if (item.type === 'firstOf振袖レンタル') {
        if (!item.flags.is持込) {
          formValues.selections[1] = item.optionId形態
        }

        if (item.flags.isセット内) {
          formValues.price.rental = item.レンタル価格
          formValues.input.rentalPrice = item.レンタル価格
          formValues.input.productName = item.商品名
          formValues.selections[2] = item.optionId商品選択
        }

        if (item.flags.isランクアップPP内) {
          formValues.isFree = true
          formValues.input.rankUpRentalPrice = item.レンタル価格
        }

        if (item.flags.isランクアップPP外) {
          formValues.input.rankUpRentalPrice = item.レンタル価格
          formValues.input.premiumRentalPrice = item.プレミアムレンタル価格
        }

        /* ストアに内容を保存する */
        setStore(storeレンタル, formValues)
      } else if (item.type === 'others') {
        if (!item.flags.is持込) {
          formValues.selections[1] = item.optionId見積表示
          formValues.input.rentalPrice = item.レンタル価格
          formValues.input.buyPrice = item.購入価格
        }

        /* ストアに内容を保存する */
        if (i === 0) setStore(storeお持込, formValues)
        if (i === 1) setStore(store2, formValues)
        if (i === 2) setStore(store3, formValues)
      }
    })
  }

  return { restoreWPhoto帯揚げ }
}
