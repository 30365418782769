import { Restate } from '~/composable/estimate/repository/graduation/EstimateRestate'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { FormValues二尺袖 } from '~/composable/estimate/viewModel/graduation/Fields/Fields二尺袖'

/** enums */
import { Enum振袖二尺袖 } from '~/constants/estimate/graduationOptions'

export const useRestore二尺袖 = () => {
  const store = useGraduateStore<FormValues二尺袖>('tab二尺袖')

  const restore二尺袖 = (hurisode: Restate['hurisode']) => {
    if (!hurisode || !hurisode.flags.is二尺袖 || hurisode.flags.is持込) {
      return
    }

    /* 初期値を設定 ※必ず入力される値はここで設置 */
    const formValues: FormValues二尺袖 = {
      nishakuIsカタログ商品: null,
      nishaku仕立上り検索: null,
      nishaku商品情報_価格選択: { id: 0, value: '' }, // フリー入力を選択
      nishaku商品情報_価格入力: null,
      nishakuカタログ検索: null,
      nishakuカタログ_価格編集: null,
      nishaku保留: false, // 保留状態では見積作成できない仕様のため、復元時においては振袖保留のFlagは常にfalse
    }

    /* 以下で入力内容の条件に基づき、必要な項目を埋めていく */

    // カタログ商品または現品を選択
    if (hurisode.flags.isカタログ商品) {
      formValues.nishakuIsカタログ商品 =
        Enum振袖二尺袖.カタログ商品OR現品.カタログ商品
    } else {
      formValues.nishakuIsカタログ商品 = Enum振袖二尺袖.カタログ商品OR現品.現品
    }

    if (hurisode.flags.is仕立上り二尺袖 && hurisode.仕立て上り検索結果) {
      formValues.nishaku仕立上り検索 = hurisode.仕立て上り検索結果
      formValues.nishaku商品情報_価格入力 = hurisode.レンタル価格
    }

    if (hurisode.flags.isカタログ二尺袖) {
      formValues.nishakuカタログ検索 = hurisode.カタログ検索結果
      formValues.nishakuカタログ_価格編集 = hurisode.レンタル価格
    }

    /* ストアに内容を保存する */
    store.setAll(formValues)
  }
  return { restore二尺袖 }
}
