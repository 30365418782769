/* eslint-disable camelcase */
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { AxiosResponse, AxiosInstance } from 'axios'
import { MayBe } from '~/store/types'

export type Payment = {
  contract_id: number
  branch_number: number
  due_date: string
  staff_id: number
  category: number
  methods: number
  tomonokai_type: number
  tomonokai_points: number
  payment_branch_number: MayBe<number>
  amount: number
  balance: number
  notes: string
  pdf_url: string
}

export const PAYMENT_METHOD = {
  CASH: 1, // 現金
  GIFT_CERTIFICATES: 2, // 商品券
  TRANSFER: 3, // 振込
  POINT: 4, // 友の会ポイント
  CARD: 5, // カード
  LOAN: 6, // ローン
  CASH_ON_DELIVERY: 7, // 代引き
  E_MONEY: 8, // 電子マネー
  FAMILY_POINT: 9, // 友の会ポイント（ご家族保有）
  RECEIPT_DISCOUNT: 10, // 入金値引き
  POINT_ROUND_OFF: 50, // ポイント端数処理
} as const

export type PaymentDetail = {
  methods: typeof PAYMENT_METHOD[keyof typeof PAYMENT_METHOD]
  tomonokai_type?: number
  tomonokai_points?: number
  amount: number
}

export type CreatePaymentInputParams = MayBe<{
  contract_id: number
  due_date: string
  staff_id: number
  category: number
  details: MayBe<PaymentDetail[]>
  total: number
  notes?: MayBe<string>
}>

export type EditPaymentInputParams = MayBe<{
  branch_number: number
  due_date: string
  staff_id: number
  category: number
  methods: number
  tomonokai_type?: number
  tomonokai_points?: number
  amount: number
  total: number
  notes?: string
  pdf_url?: string
}>

type CancelRequestParams = {
  contract_id: number
  cancel_date: string
  staff_id: number
  category: number
  details: {
    methods: number
    tomonokai_type?: number
    tomonokai_points?: number
    amount: number
  }[]
  total: number
  cancel_amount: number
  contract_total_price: number
  notes?: string
  canceled_reason?: string
  multiple_contract_pdfs?: {
    contract_pdf_type: number
    pdf_url: string
  }[]
  parental_signature_url: string
}

export type PaymentRepository = {
  get: (contractId: number) => Promise<AxiosResponse<{ payments: Payment[] }>>
  register: (
    params: CreatePaymentInputParams
  ) => Promise<AxiosResponse<{ branch_number: number; contract_id: number }[]>>
  edit: (
    contractId: number,
    params: EditPaymentInputParams
  ) => Promise<AxiosResponse<{ branch_number: number }[]>>
  cancel: (
    params: CancelRequestParams
  ) => Promise<AxiosResponse<{ branch_number: number }>>
}

const paymentRepository = (
  client: NuxtAxiosInstance | AxiosInstance
): PaymentRepository => {
  return {
    get: (contractId) =>
      client.get('payment', { params: { contract_id: contractId } }),
    register: (params) => client.post('payment', params),
    edit: (contractId, params) => client.patch('payment/' + contractId, params),
    cancel: (params) =>
      client.patch(`contracts/${params.contract_id}/cancel`, params),
  }
}

export default paymentRepository
