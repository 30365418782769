




















import { defineComponent, ref } from '@nuxtjs/composition-api'
import AppModalWindow from './AppModalWindow.vue'
import IconArrowRight from '~/components/icons/IconArrowRight.vue'
import { useAuth } from '@/composable/api/useAuth'

export default defineComponent({
  components: {
    IconArrowRight,
  },
  setup() {
    const modal = ref<InstanceType<typeof AppModalWindow> | null>()

    const openModal = () => modal.value?.open()
    const closeModal = () => modal.value?.close()

    const { logout } = useAuth()

    return { modal, openModal, closeModal, logout }
  },
})
