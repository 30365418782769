import { MutationTree } from 'vuex'
import { ContractRemarkState } from '../../types'

const mutations: MutationTree<ContractRemarkState> = {
  setInput: (state, { text }) => {
    state.input.text = text
  },

  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },

  delete: (state) => {
    state.input.text = ''
    state.isValidate = null
  },
}

export default mutations
