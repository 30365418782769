import { MemoState } from '../../../types'

const state = (): MemoState => ({
  text: '',
  isChance: false,
  memoId: null,
  customerId: null,
})

export default state
