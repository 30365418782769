import { Store } from 'vuex'
import { ref, useStore, watch } from '@nuxtjs/composition-api'

declare global {
  interface Window {
    zESettings: any
    zE: any
  }
}

export default function ({ store }: { store: any }) {
  const user = ref(store.getters['LoginUser/getUser'])
  const shopName = ref(store.getters['SettingShop/getShop'])

  if (process.browser) {
    window.zESettings = {
      webWidget: {
        offset: {
          vertical: '90px',
        },
        contactForm: {
          attachments: true,
          fields: [
            // NOTE:アカウントが変わるごとに id は変化するのでその都度変更する必要有り
            { id: 1900001906848, prefill: { '*': user.value.staff_id } },
            { id: 900013143946, prefill: { '*': shopName.value?.name } },
          ],
        },
      },
    }
  }

  // NOTE
  // ログイン、ログアウト時にリダイレクトの処理を入れることで
  // 値が上書きされるようにしています
  // FIXME
  // 本当は watch で変更を検知したタイミングで
  // 値を上書きしたいが、うまくいかないため、
  // 下記コードは一時的にコメントアウトしています
  // 参考: https://developer.zendesk.com/api-reference/widget/core/#updatesettings

  // watch(user.value, () => {
  //   if (process.browser) {
  //     console.log('id変更')
  //     window.zE('webWidget', 'updateSettings', {
  //       webWidget: {
  //         offset: {
  //           vertical: '90px',
  //         },
  //         contactForm: {
  //           attachments: true,
  //           fields: [
  //             // NOTE:アカウントが変わるごとに id は変化するのでその都度変更する必要有り
  //             { id: 1900001614828, prefill: { '*': user.value.staff_id } },
  //             { id: 1900001614808, prefill: { '*': shopName.value.name } },
  //           ],
  //         },
  //       },
  //     })
  //   }
  // })
}
