import { computed } from '@nuxtjs/composition-api'
import { WPHOTO_FURISODE_PLAN } from '~/constants/estimate/comingOfAge'
import { FurisodeState, ObiState } from '~/store/types'
import ProductClassification from '~/constants/enums/productClassification'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { kimonoDiscountAmount } from '../utilities'
import { usePlanTypeFlags } from '~/composable/estimate/domain/ComingOfAge/PlanTypeFlags'
import { State as PricesState } from '~/store/PriceTable/state'

export const useState振袖 = () => {
  const comingStore = useComingStore<ObiState>('WphotoPlan/WphotoFurisodes')
  const values振袖 = comingStore.getAll()
  const priceTable = comingStore.getPrices()
  const { isフルセット } = usePlanTypeFlags()

  return computed(() =>
    getState振袖(values振袖.value, isフルセット.value, priceTable)
  )
}

export const getState振袖 = (
  values振袖: ObiState,
  isフルセット: boolean,
  priceTable: PricesState['prices']
) => {
  const { selections, isFree, input, price, product } = values振袖
  const flags = getWPhotoFurisodePlanFlags(selections)
  const isお持込 = flags.is振袖持込
  const is裄補正 = selections.includes(WPHOTO_FURISODE_PLAN.YUKI_CORRECTION)
  const is袖補正 = selections.includes(WPHOTO_FURISODE_PLAN.SODE_CORRECTION)
  const is振袖保留 = values振袖.isFurisodeOnHold

  // TODO:振袖にはランクアップやプレミアムパックという概念がないため、本来金額の保持にinputsは不要
  // pagesの方で本体の方の振袖と合わせて使わない実装にしたい
  const レンタル価格 = isFree ? input.rentalPrice : price.rental

  return {
    品目名: ProductClassification.toWord(ProductClassification.hurisode),
    レンタル価格: isお持込 ? null : レンタル価格,
    購入価格: null, // NOTE:Wフォト、購入価格の入力無いため未入力で固定
    品種区分: ProductClassification.hurisode,
    price裄補正: priceTable.estimate.裄補正,
    price袖補正: priceTable.estimate.袖補正,
    着物検索: isお持込
      ? null
      : ({ type: 'finished', ...product } as FurisodeState['product']),
    商品名: product?.product_name ?? '',
    プラン: getWPhotoFurisodePlan(selections),
    label: isお持込 ? 'お持込' : '',
    割引: wPhotoFurisodeDiscount(レンタル価格, isフルセット, priceTable),
    flags: {
      isレンタル: flags.is振袖レンタル,
      isお持込,
      is振袖保留,
      is裄補正,
      is袖補正,
    },
  }
}

const getWPhotoFurisodePlanFlags = (selections: number[]) => {
  return {
    is振袖レンタル: selections.includes(WPHOTO_FURISODE_PLAN.RENTAL),
    is振袖持込: selections.includes(WPHOTO_FURISODE_PLAN.BRING_IN),
  }
}

const getWPhotoFurisodePlan = (selections: number[]) => {
  const flags = getWPhotoFurisodePlanFlags(selections)
  if (flags.is振袖レンタル) return 0
  if (flags.is振袖持込) return 1
  return undefined
}

// Wフォトの振袖割引
export const wPhotoFurisodeDiscount = (
  price: number,
  isフルセット: boolean,
  priceTable: PricesState['prices']
) => {
  const { Wフォト振袖割引フルセット, Wフォト振袖割引フルセット以外 } =
    priceTable.estimate.割引率
  if (isフルセット) {
    // NOTE:フルセットの場合（80%引き）
    return {
      text: '※表示価格より80%引きになります',
      rate: Wフォト振袖割引フルセット,
      price: kimonoDiscountAmount(price, Wフォト振袖割引フルセット, true),
    }
  } else {
    // NOTE:前撮りのみや当日のプランに関わらず、フルセット以外の場合（50%引き）
    return {
      text: '※表示価格より50%引きになります',
      rate: Wフォト振袖割引フルセット以外,
      price: kimonoDiscountAmount(price, Wフォト振袖割引フルセット以外, true),
    }
  }
}
