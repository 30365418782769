import { computed, ComputedRef, Ref } from '@nuxtjs/composition-api'
import { EstimateDetail, Items } from '~/api/estimateRepository'
import { ItemPlan } from '~/constants/enums/itemPlan'
import ProductId from '~/constants/enums/productClassification'
import { PLAN } from '~/constants/estimate/comingOfAge'
import { useRestatePlan, PlanFlags } from '../RestatePlan'
import { EstimateSinglePriceType } from '~/constants/enums/estimateSinglePriceType'
import { getSortedItemsByOrder } from '~/utils/utils'
import {
  isObiageKasaneeriPPDiscount,
  usePPObiageKasaneeriPlan,
} from '~/composable/general/PPDiscount/usePPObiageKasaneeriPlan'

type Obiage = FirstOf振袖レンタル購入 | Others

// 振袖持込ではないプランの1個目とそれ以外で項目が異なるため別の型を用意
type FirstOf振袖レンタル購入 = {
  type: 'firstOf振袖レンタル購入'
  optionId種別: number
  optionId形態: number
  optionId商品選択: number
  商品名: string
  flags: Flags
} & Prices

type Others = {
  type: 'others'
  optionId種別: number
  optionId見積表示: number
  optionId商品選択: number
  商品名: string
  flags: Flags
} & Prices

type Prices = {
  レンタル価格: number
  プレミアムレンタル価格: number
  購入価格: number
  プレミアム購入価格: number
}

type Flags = ReturnType<typeof getConditionalFlags>

export const useRestate帯揚げ = (
  estimate: Ref<EstimateDetail | undefined>,
  isPPDiscount: ComputedRef<isObiageKasaneeriPPDiscount>
) => {
  const { getSecondProductRankUp } = usePPObiageKasaneeriPlan()
  const restateプラン = useRestatePlan(estimate)

  return computed(() => {
    if (!estimate.value?.items || !restateプラン.value) {
      return null
    }
    const isPPRankUp = getSecondProductRankUp(estimate.value)
    const obiageItems = getSortedItemsByOrder(
      estimate.value.items,
      ProductId.obiage
    )

    const obiages: Obiage[] = []

    if (!obiageItems.length) {
      // レスポンスに帯揚げが含まれていなければ空配列を返す
      obiages.push({
        type: 'firstOf振袖レンタル購入',
        optionId種別: getOptionIdOf種別(
          ItemPlan.noUse,
          restateプラン.value.flags
        ),
        optionId形態: getOptionIdOf形態(ItemPlan.noUse),
        optionId商品選択: 0,
        商品名: '',
        レンタル価格: 0,
        プレミアムレンタル価格: 0,
        購入価格: 0,
        プレミアム購入価格: 0,
        flags: {
          is持込: true, // 帯揚げの種別のみ表示するため、is持込をtrueとする
          is現品: false,
          isセット内: false,
          isランクアップPP内: false,
          isランクアップPP外: false,
          is仕立て上り: false,
          isPPDiscount: false,
          isPPRankUp: false,
        },
      })
      return obiages
    }

    // NOTE: 既に型ガードしており、restatePlan.valueが存在するのは確定しているため
    // 一部にNonNullAssertion('!')を使っています。
    if (restateプラン.value.flags.is振袖持込) {
      // 振袖持込プランの場合,全てプラン外の小物なので同じ処理
      obiageItems.forEach((item) => {
        obiages.push(formatOtherObiage(item, restateプラン.value!.flags))
      })
    } else {
      // 振袖持込ではないプランの場合,1個目の帯揚げはプラン内に入るので選択項目が違うので個別に処理する
      const first = obiageItems.shift()
      const restItems = obiageItems
      if (first) {
        obiages.push(
          formatFirstObiage(
            first,
            restateプラン.value!.flags,
            isPPDiscount.value.isPPObiage
          )
        )
      }
      restItems.forEach((item) => {
        obiages.push(
          formatOtherObiage(
            item,
            restateプラン.value!.flags,
            isPPDiscount.value.isPPObiage,
            isPPRankUp
          )
        )
      })
    }

    return obiages
  })
}

const formatFirstObiage = (
  item: Items,
  planFlags: PlanFlags,
  isPPDiscount: boolean
): FirstOf振袖レンタル購入 => {
  const flags = getConditionalFlags(item, planFlags, isPPDiscount)
  return {
    type: 'firstOf振袖レンタル購入',
    optionId種別: getOptionIdOf種別(item.plan, planFlags),
    optionId形態: getOptionIdOf形態(item.plan),
    optionId商品選択:
      item.product_name === '現品' ? PLAN.ACTUAL_PRODUCT : item.product_id ?? 0,
    商品名: item.product_name ?? '',
    レンタル価格: Number(item.rental_price),
    プレミアムレンタル価格: Number(item.premium_rental_price ?? 0),
    購入価格: Number(item.purchase_price ?? 0),
    プレミアム購入価格: Number(item.premium_purchase_price ?? 0),
    flags,
  }
}
const formatOtherObiage = (
  item: Items,
  planFlags: PlanFlags,
  isPPDiscount?: boolean,
  isPPRankUp?: boolean
): Others => {
  const flags = getConditionalFlags(item, planFlags, isPPDiscount, isPPRankUp)
  return {
    type: 'others',
    optionId種別: getOptionIdOf種別(item.plan, planFlags),
    optionId見積表示: getOptionIdOf見積表示(item.price_type),
    optionId商品選択:
      item.product_name === '現品' ? PLAN.ACTUAL_PRODUCT : item.product_id ?? 0,
    商品名: item.product_name ?? '',
    レンタル価格: Number(item.rental_price),
    プレミアムレンタル価格: Number(item.premium_rental_price ?? 0),
    購入価格: Number(item.purchase_price ?? 0),
    プレミアム購入価格: Number(item.premium_purchase_price ?? 0),
    flags,
  }
}

/**
 * 復元条件に関わる条件式を定義する
 */
const getConditionalFlags = (
  item: Items,
  planFlags: PlanFlags,
  isPPDiscount?: boolean,
  isPPRankUp?: boolean
) => {
  const { is振袖持込 } = planFlags
  const is持込 = item.plan === ItemPlan.bringIn
  const is現品 = item.product_name === '現品'

  const isセット内 = !is振袖持込 && !is持込 && item.plan === ItemPlan.inSet
  const isランクアップPP内 =
    !is振袖持込 && !is持込 && item.plan === ItemPlan.rankUpInPremium
  const isランクアップPP外 =
    !is振袖持込 && !is持込 && item.plan === ItemPlan.rankUpOutPremium
  return {
    is持込,
    is現品,
    isセット内,
    isランクアップPP内,
    isランクアップPP外,
    is仕立て上り: !!item.product_id,
    isPPDiscount,
    isPPRankUp,
  }
}

/**
 * 選択肢系の項目の初期値(選択されたoptionのid)を再計算する関数群
 */
const getOptionIdOf種別 = (itemPlan: Items['plan'], planFlags: PlanFlags) => {
  // お持込は全パターン共通ID、その他は振袖のプランによって変動する
  if (itemPlan === ItemPlan.bringIn) {
    return PLAN.BRING_IN
  } else if (itemPlan === ItemPlan.toBeDecided) {
    return PLAN.SINGLE_RENTAL
  } else if (itemPlan === ItemPlan.noUse) {
    return PLAN.NO_USE
  } else if (planFlags.isフルセットプラン) {
    return PLAN.SET_OR_RENTAL
  } else if (planFlags.is振袖持込) {
    return PLAN.SINGLE_RENTAL
  } else if (planFlags.is写のみレンタル) {
    return PLAN.RENTAL
  } else {
    return 0
  }
}

const getOptionIdOf形態 = (itemPlan: Items['plan']) => {
  switch (itemPlan) {
    case ItemPlan.inSet:
      return PLAN.IN_THE_SET
    case ItemPlan.rankUpInPremium:
      return PLAN.RANK_UP_IN_PREMIUM
    case ItemPlan.rankUpOutPremium:
      return PLAN.RANK_UP_OUT_PREMIUM
    default:
      return 0
  }
}

const getOptionIdOf見積表示 = (priceType: Items['price_type']) => {
  switch (priceType) {
    case EstimateSinglePriceType.singleRentalPrice:
      return PLAN.SINGLE_RENTAL_PRICE
    case EstimateSinglePriceType.singlePurchasePrice:
      return PLAN.SINGLE_BUY_PRICE
    default:
      return 0
  }
}
