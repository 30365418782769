import { MutationTree } from 'vuex'
import { AccessoriesFullsetState } from '../../types'

const mutations: MutationTree<AccessoriesFullsetState> = {
  setResultSelectionsJK: (state, resultSelectionsJK) => {
    state.resultSelectionsJK = resultSelectionsJK
  },

  setResultSelectionsWphoto: (state, resultSelectionsWphoto) => {
    state.resultSelectionsWphoto = resultSelectionsWphoto
  },
  setResultSelectionsMotherAndDaughterPlan: (
    state,
    resultSelectionsMotherAndDaughterPlan
  ) => {
    state.resultSelectionsMotherAndDaughterPlan =
      resultSelectionsMotherAndDaughterPlan
  },

  setTabiColor: (state, selectedColor) => {
    state.tabiColor = selectedColor
  },
  delete: (state) => {
    state.resultSelectionsJK = []
    state.resultSelectionsWphoto = []
    state.resultSelectionsComing = []
    state.resultSelectionsMotherAndDaughterPlan = []
    state.tabiColor = ''
  },
}

export default mutations
