import { computed, Ref } from '@nuxtjs/composition-api'
import { EstimateDetail } from '~/api/estimateRepository'
import { useRestateWPhoto帯締め } from './Restate帯締め'
import { useRestateWPhotoFirst } from './RestateFirst'
import { useRestateWPhoto帯 } from './Restate帯'
import { useRestateWPhoto帯揚げ } from './Restate帯揚げ'
import { useRestateWPhoto振袖 } from './Restate振袖'
import { useRestateWPhoto試着写真 } from './Restate試着写真'
import { useRestateWPhoto重ね衿 } from './Restate重ね衿'
import { useRestateWPhoto商品設定 } from './Restate商品設定'
import { useRestateWPhoto別衿 } from './Restate別衿'

export const useRestateWPhoto = (estimate: Ref<EstimateDetail | undefined>) => {
  const firstPage = useRestateWPhotoFirst(estimate)
  const 試着写真 = useRestateWPhoto試着写真(estimate)
  const 振袖 = useRestateWPhoto振袖(estimate)
  const 重ね衿 = useRestateWPhoto重ね衿(estimate)
  const 帯 = useRestateWPhoto帯(estimate)
  const 帯締め = useRestateWPhoto帯締め(estimate)
  const 商品設定 = useRestateWPhoto商品設定(estimate)
  const 帯揚げ = useRestateWPhoto帯揚げ(estimate)
  const 別衿 = useRestateWPhoto別衿(estimate)

  return computed(() => {
    return {
      selected_plan: estimate.value?.w_photo_plan?.selected_plan ?? 0,
      帯: 帯.value,
      firstPage: firstPage,
      試着写真: 試着写真.value,
      振袖: 振袖.value,
      重ね衿: 重ね衿.value,
      帯締め: 帯締め.value,
      商品設定: 商品設定.value,
      帯揚げ: 帯揚げ.value,
      別衿: 別衿.value,
    }
  })
}
