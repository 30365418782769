import { MutationTree } from 'vuex'
import { EstimateJkphotoInAdvanceState } from '../../../types'

const mutations: MutationTree<EstimateJkphotoInAdvanceState> = {
  setSelection: (state, selection) => {
    state.selections = selection
  },

  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },

  setPhoto: (state, url) => {
    state.settings[0].photos = [
      {
        isSelected: false,
        remoteSrc: url,
      },
    ]
  },

  delete: (state) => {
    state.selections = [0]
    state.product = null
    state.settings = [
      {
        id: 1,
        title: '試着時の写真をアップロードしてください。',
        limit: 1,
        isPossibleCheck: false,
        photos: [],
      },
    ]
    state.isValidate = null
  },
}

export default mutations
