import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../src/store/index.ts'), 'store/index.ts')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../src/store/getters.ts'), 'getters.ts')
  resolveStoreModules(require('../src/store/mutations.ts'), 'mutations.ts')
  resolveStoreModules(require('../src/store/types.ts'), 'types.ts')
  resolveStoreModules(require('../src/store/SettingShop/index.ts'), 'SettingShop/index.ts')
  resolveStoreModules(require('../src/store/LoginUser/index.ts'), 'LoginUser/index.ts')
  resolveStoreModules(require('../src/store/Contracts/types.ts'), 'Contracts/types.ts')
  resolveStoreModules(require('../src/store/CustomerStoreInfo/getters.ts'), 'CustomerStoreInfo/getters.ts')
  resolveStoreModules(require('../src/store/CustomerStoreInfo/mutations.ts'), 'CustomerStoreInfo/mutations.ts')
  resolveStoreModules(require('../src/store/CustomerStoreInfo/state.ts'), 'CustomerStoreInfo/state.ts')
  resolveStoreModules(require('../src/store/FrontStoreInfo/getters.ts'), 'FrontStoreInfo/getters.ts')
  resolveStoreModules(require('../src/store/FrontStoreInfo/mutations.ts'), 'FrontStoreInfo/mutations.ts')
  resolveStoreModules(require('../src/store/FrontStoreInfo/state.ts'), 'FrontStoreInfo/state.ts')
  resolveStoreModules(require('../src/store/LoginUser/actions.ts'), 'LoginUser/actions.ts')
  resolveStoreModules(require('../src/store/LoginUser/getters.ts'), 'LoginUser/getters.ts')
  resolveStoreModules(require('../src/store/LoginUser/mutations.ts'), 'LoginUser/mutations.ts')
  resolveStoreModules(require('../src/store/LoginUser/state.ts'), 'LoginUser/state.ts')
  resolveStoreModules(require('../src/store/PdfStoreInfo/getters.ts'), 'PdfStoreInfo/getters.ts')
  resolveStoreModules(require('../src/store/PdfStoreInfo/mutations.ts'), 'PdfStoreInfo/mutations.ts')
  resolveStoreModules(require('../src/store/PdfStoreInfo/state.ts'), 'PdfStoreInfo/state.ts')
  resolveStoreModules(require('../src/store/PriceTable/getters.ts'), 'PriceTable/getters.ts')
  resolveStoreModules(require('../src/store/PriceTable/mutations.ts'), 'PriceTable/mutations.ts')
  resolveStoreModules(require('../src/store/PriceTable/state.ts'), 'PriceTable/state.ts')
  resolveStoreModules(require('../src/store/SettingShop/actions.ts'), 'SettingShop/actions.ts')
  resolveStoreModules(require('../src/store/SettingShop/getters.ts'), 'SettingShop/getters.ts')
  resolveStoreModules(require('../src/store/SettingShop/mutations.ts'), 'SettingShop/mutations.ts')
  resolveStoreModules(require('../src/store/SettingShop/state.ts'), 'SettingShop/state.ts')
  resolveStoreModules(require('../src/store/Staff/getters.ts'), 'Staff/getters.ts')
  resolveStoreModules(require('../src/store/Staff/mutations.ts'), 'Staff/mutations.ts')
  resolveStoreModules(require('../src/store/Staff/state.ts'), 'Staff/state.ts')
  resolveStoreModules(require('../src/store/Contracts/Signature/index.ts'), 'Contracts/Signature/index.ts')
  resolveStoreModules(require('../src/store/Contracts/Remarks/index.ts'), 'Contracts/Remarks/index.ts')
  resolveStoreModules(require('../src/store/Contracts/ProductPhoto/index.ts'), 'Contracts/ProductPhoto/index.ts')
  resolveStoreModules(require('../src/store/Contracts/Photos/index.ts'), 'Contracts/Photos/index.ts')
  resolveStoreModules(require('../src/store/Contracts/PhotoInAdvance/index.ts'), 'Contracts/PhotoInAdvance/index.ts')
  resolveStoreModules(require('../src/store/Contracts/Payment/index.ts'), 'Contracts/Payment/index.ts')
  resolveStoreModules(require('../src/store/Contracts/MotherAndDaughterPlan/index.ts'), 'Contracts/MotherAndDaughterPlan/index.ts')
  resolveStoreModules(require('../src/store/Contracts/Memo/index.ts'), 'Contracts/Memo/index.ts')
  resolveStoreModules(require('../src/store/Contracts/Measuring/index.ts'), 'Contracts/Measuring/index.ts')
  resolveStoreModules(require('../src/store/Contracts/JkPhotoInAdvance/index.ts'), 'Contracts/JkPhotoInAdvance/index.ts')
  resolveStoreModules(require('../src/store/Contracts/ImportantMatter/index.ts'), 'Contracts/ImportantMatter/index.ts')
  resolveStoreModules(require('../src/store/Contracts/GraduationCeremony/index.ts'), 'Contracts/GraduationCeremony/index.ts')
  resolveStoreModules(require('../src/store/Contracts/FamilyInformation/index.ts'), 'Contracts/FamilyInformation/index.ts')
  resolveStoreModules(require('../src/store/Contracts/DoublePhoto/index.ts'), 'Contracts/DoublePhoto/index.ts')
  resolveStoreModules(require('../src/store/Contracts/DeliveryDate/index.ts'), 'Contracts/DeliveryDate/index.ts')
  resolveStoreModules(require('../src/store/Contracts/CustomerReady/index.ts'), 'Contracts/CustomerReady/index.ts')
  resolveStoreModules(require('../src/store/Contracts/ComingOfAgeCeremony/index.ts'), 'Contracts/ComingOfAgeCeremony/index.ts')
  resolveStoreModules(require('../src/store/Contracts/BaseInformation/index.ts'), 'Contracts/BaseInformation/index.ts')
  resolveStoreModules(require('../src/store/Contracts/BaseEstimate/index.ts'), 'Contracts/BaseEstimate/index.ts')
  resolveStoreModules(require('../src/store/Contracts/AccessoriesFullset/index.ts'), 'Contracts/AccessoriesFullset/index.ts')
  resolveStoreModules(require('../src/store/Contracts/Accessories/index.ts'), 'Contracts/Accessories/index.ts')
  resolveStoreModules(require('../src/store/Checklists/Main/getters.ts'), 'Checklists/Main/getters.ts')
  resolveStoreModules(require('../src/store/Checklists/Main/mutations.ts'), 'Checklists/Main/mutations.ts')
  resolveStoreModules(require('../src/store/Checklists/Main/state.ts'), 'Checklists/Main/state.ts')
  resolveStoreModules(require('../src/store/Checklists/Memo/getters.ts'), 'Checklists/Memo/getters.ts')
  resolveStoreModules(require('../src/store/Checklists/Memo/mutations.ts'), 'Checklists/Memo/mutations.ts')
  resolveStoreModules(require('../src/store/Checklists/Memo/state.ts'), 'Checklists/Memo/state.ts')
  resolveStoreModules(require('../src/store/Contracts/Accessories/actions.ts'), 'Contracts/Accessories/actions.ts')
  resolveStoreModules(require('../src/store/Contracts/Accessories/getters.ts'), 'Contracts/Accessories/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/Accessories/mutations.ts'), 'Contracts/Accessories/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/Accessories/state.ts'), 'Contracts/Accessories/state.ts')
  resolveStoreModules(require('../src/store/Contracts/AccessoriesFullset/actions.ts'), 'Contracts/AccessoriesFullset/actions.ts')
  resolveStoreModules(require('../src/store/Contracts/AccessoriesFullset/getters.ts'), 'Contracts/AccessoriesFullset/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/AccessoriesFullset/mutations.ts'), 'Contracts/AccessoriesFullset/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/AccessoriesFullset/state.ts'), 'Contracts/AccessoriesFullset/state.ts')
  resolveStoreModules(require('../src/store/Contracts/BaseEstimate/actions.ts'), 'Contracts/BaseEstimate/actions.ts')
  resolveStoreModules(require('../src/store/Contracts/BaseEstimate/getters.ts'), 'Contracts/BaseEstimate/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/BaseEstimate/mutations.ts'), 'Contracts/BaseEstimate/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/BaseEstimate/state.ts'), 'Contracts/BaseEstimate/state.ts')
  resolveStoreModules(require('../src/store/Contracts/BaseInformation/actions.ts'), 'Contracts/BaseInformation/actions.ts')
  resolveStoreModules(require('../src/store/Contracts/BaseInformation/getters.ts'), 'Contracts/BaseInformation/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/BaseInformation/mutations.ts'), 'Contracts/BaseInformation/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/BaseInformation/state.ts'), 'Contracts/BaseInformation/state.ts')
  resolveStoreModules(require('../src/store/Contracts/ComingOfAgeCeremony/actions.ts'), 'Contracts/ComingOfAgeCeremony/actions.ts')
  resolveStoreModules(require('../src/store/Contracts/ComingOfAgeCeremony/getters.ts'), 'Contracts/ComingOfAgeCeremony/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/ComingOfAgeCeremony/mutations.ts'), 'Contracts/ComingOfAgeCeremony/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/ComingOfAgeCeremony/state.ts'), 'Contracts/ComingOfAgeCeremony/state.ts')
  resolveStoreModules(require('../src/store/Contracts/CustomerReady/actions.ts'), 'Contracts/CustomerReady/actions.ts')
  resolveStoreModules(require('../src/store/Contracts/CustomerReady/getters.ts'), 'Contracts/CustomerReady/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/CustomerReady/mutations.ts'), 'Contracts/CustomerReady/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/CustomerReady/state.ts'), 'Contracts/CustomerReady/state.ts')
  resolveStoreModules(require('../src/store/Contracts/DeliveryDate/actions.ts'), 'Contracts/DeliveryDate/actions.ts')
  resolveStoreModules(require('../src/store/Contracts/DeliveryDate/getters.ts'), 'Contracts/DeliveryDate/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/DeliveryDate/mutations.ts'), 'Contracts/DeliveryDate/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/DeliveryDate/state.ts'), 'Contracts/DeliveryDate/state.ts')
  resolveStoreModules(require('../src/store/Contracts/DoublePhoto/actions.ts'), 'Contracts/DoublePhoto/actions.ts')
  resolveStoreModules(require('../src/store/Contracts/DoublePhoto/getters.ts'), 'Contracts/DoublePhoto/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/DoublePhoto/mutations.ts'), 'Contracts/DoublePhoto/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/DoublePhoto/state.ts'), 'Contracts/DoublePhoto/state.ts')
  resolveStoreModules(require('../src/store/Contracts/ExtendsEstimate/getters.ts'), 'Contracts/ExtendsEstimate/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/ExtendsEstimate/mutations.ts'), 'Contracts/ExtendsEstimate/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/ExtendsEstimate/state.ts'), 'Contracts/ExtendsEstimate/state.ts')
  resolveStoreModules(require('../src/store/Contracts/FamilyInformation/actions.ts'), 'Contracts/FamilyInformation/actions.ts')
  resolveStoreModules(require('../src/store/Contracts/FamilyInformation/getters.ts'), 'Contracts/FamilyInformation/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/FamilyInformation/mutations.ts'), 'Contracts/FamilyInformation/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/FamilyInformation/state.ts'), 'Contracts/FamilyInformation/state.ts')
  resolveStoreModules(require('../src/store/Contracts/GraduationCeremony/actions.ts'), 'Contracts/GraduationCeremony/actions.ts')
  resolveStoreModules(require('../src/store/Contracts/GraduationCeremony/getters.ts'), 'Contracts/GraduationCeremony/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/GraduationCeremony/mutations.ts'), 'Contracts/GraduationCeremony/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/GraduationCeremony/state.ts'), 'Contracts/GraduationCeremony/state.ts')
  resolveStoreModules(require('../src/store/Contracts/ImportantMatter/actions.ts'), 'Contracts/ImportantMatter/actions.ts')
  resolveStoreModules(require('../src/store/Contracts/ImportantMatter/getters.ts'), 'Contracts/ImportantMatter/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/ImportantMatter/mutations.ts'), 'Contracts/ImportantMatter/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/ImportantMatter/state.ts'), 'Contracts/ImportantMatter/state.ts')
  resolveStoreModules(require('../src/store/Contracts/JkPhotoInAdvance/actions.ts'), 'Contracts/JkPhotoInAdvance/actions.ts')
  resolveStoreModules(require('../src/store/Contracts/JkPhotoInAdvance/getters.ts'), 'Contracts/JkPhotoInAdvance/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/JkPhotoInAdvance/mutations.ts'), 'Contracts/JkPhotoInAdvance/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/JkPhotoInAdvance/state.ts'), 'Contracts/JkPhotoInAdvance/state.ts')
  resolveStoreModules(require('../src/store/Contracts/Measuring/actions.ts'), 'Contracts/Measuring/actions.ts')
  resolveStoreModules(require('../src/store/Contracts/Measuring/getters.ts'), 'Contracts/Measuring/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/Measuring/mutations.ts'), 'Contracts/Measuring/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/Measuring/state.ts'), 'Contracts/Measuring/state.ts')
  resolveStoreModules(require('../src/store/Contracts/Memo/actions.ts'), 'Contracts/Memo/actions.ts')
  resolveStoreModules(require('../src/store/Contracts/Memo/getters.ts'), 'Contracts/Memo/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/Memo/mutations.ts'), 'Contracts/Memo/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/Memo/state.ts'), 'Contracts/Memo/state.ts')
  resolveStoreModules(require('../src/store/Contracts/MotherAndDaughterPlan/actions.ts'), 'Contracts/MotherAndDaughterPlan/actions.ts')
  resolveStoreModules(require('../src/store/Contracts/MotherAndDaughterPlan/getters.ts'), 'Contracts/MotherAndDaughterPlan/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/MotherAndDaughterPlan/mutations.ts'), 'Contracts/MotherAndDaughterPlan/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/MotherAndDaughterPlan/state.ts'), 'Contracts/MotherAndDaughterPlan/state.ts')
  resolveStoreModules(require('../src/store/Contracts/Option/getters.ts'), 'Contracts/Option/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/Option/mutations.ts'), 'Contracts/Option/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/Option/state.ts'), 'Contracts/Option/state.ts')
  resolveStoreModules(require('../src/store/Contracts/Payment/actions.ts'), 'Contracts/Payment/actions.ts')
  resolveStoreModules(require('../src/store/Contracts/Payment/getters.ts'), 'Contracts/Payment/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/Payment/mutations.ts'), 'Contracts/Payment/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/Payment/state.ts'), 'Contracts/Payment/state.ts')
  resolveStoreModules(require('../src/store/Contracts/PhotoInAdvance/actions.ts'), 'Contracts/PhotoInAdvance/actions.ts')
  resolveStoreModules(require('../src/store/Contracts/PhotoInAdvance/getters.ts'), 'Contracts/PhotoInAdvance/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/PhotoInAdvance/mutations.ts'), 'Contracts/PhotoInAdvance/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/PhotoInAdvance/state.ts'), 'Contracts/PhotoInAdvance/state.ts')
  resolveStoreModules(require('../src/store/Contracts/Photos/actions.ts'), 'Contracts/Photos/actions.ts')
  resolveStoreModules(require('../src/store/Contracts/Photos/getters.ts'), 'Contracts/Photos/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/Photos/mutations.ts'), 'Contracts/Photos/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/Photos/state.ts'), 'Contracts/Photos/state.ts')
  resolveStoreModules(require('../src/store/Contracts/ProductPhoto/actions.ts'), 'Contracts/ProductPhoto/actions.ts')
  resolveStoreModules(require('../src/store/Contracts/ProductPhoto/getters.ts'), 'Contracts/ProductPhoto/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/ProductPhoto/mutations.ts'), 'Contracts/ProductPhoto/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/ProductPhoto/state.ts'), 'Contracts/ProductPhoto/state.ts')
  resolveStoreModules(require('../src/store/Contracts/ReferralBonus/getters.ts'), 'Contracts/ReferralBonus/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/ReferralBonus/mutations.ts'), 'Contracts/ReferralBonus/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/ReferralBonus/state.ts'), 'Contracts/ReferralBonus/state.ts')
  resolveStoreModules(require('../src/store/Contracts/Remarks/actions.ts'), 'Contracts/Remarks/actions.ts')
  resolveStoreModules(require('../src/store/Contracts/Remarks/getters.ts'), 'Contracts/Remarks/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/Remarks/mutations.ts'), 'Contracts/Remarks/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/Remarks/state.ts'), 'Contracts/Remarks/state.ts')
  resolveStoreModules(require('../src/store/Contracts/Signature/actions.ts'), 'Contracts/Signature/actions.ts')
  resolveStoreModules(require('../src/store/Contracts/Signature/getters.ts'), 'Contracts/Signature/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/Signature/mutations.ts'), 'Contracts/Signature/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/Signature/state.ts'), 'Contracts/Signature/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/types.ts'), 'Estimate/GraduationCeremony/types.ts')
  resolveStoreModules(require('../src/store/Estimate/OldData/getters.ts'), 'Estimate/OldData/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/OldData/mutations.ts'), 'Estimate/OldData/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/OldData/state.ts'), 'Estimate/OldData/state.ts')
  resolveStoreModules(require('../src/store/SingleItemService/Main/getters.ts'), 'SingleItemService/Main/getters.ts')
  resolveStoreModules(require('../src/store/SingleItemService/Main/mutations.ts'), 'SingleItemService/Main/mutations.ts')
  resolveStoreModules(require('../src/store/SingleItemService/Main/state.ts'), 'SingleItemService/Main/state.ts')
  resolveStoreModules(require('../src/store/SingleItemService/MasterData/getters.ts'), 'SingleItemService/MasterData/getters.ts')
  resolveStoreModules(require('../src/store/SingleItemService/MasterData/mutations.ts'), 'SingleItemService/MasterData/mutations.ts')
  resolveStoreModules(require('../src/store/SingleItemService/MasterData/state.ts'), 'SingleItemService/MasterData/state.ts')
  resolveStoreModules(require('../src/store/SingleItemService/Memo/getters.ts'), 'SingleItemService/Memo/getters.ts')
  resolveStoreModules(require('../src/store/SingleItemService/Memo/mutations.ts'), 'SingleItemService/Memo/mutations.ts')
  resolveStoreModules(require('../src/store/SingleItemService/Memo/state.ts'), 'SingleItemService/Memo/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/ZoriBag/index.ts'), 'Estimate/ComingOfAgeCeremony/ZoriBag/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Zori3/index.ts'), 'Estimate/ComingOfAgeCeremony/Zori3/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Zori2/index.ts'), 'Estimate/ComingOfAgeCeremony/Zori2/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/index.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/TwinsDiscount/index.ts'), 'Estimate/ComingOfAgeCeremony/TwinsDiscount/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/SisterDiscount/index.ts'), 'Estimate/ComingOfAgeCeremony/SisterDiscount/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Shawl/index.ts'), 'Estimate/ComingOfAgeCeremony/Shawl/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/ServicePlans/index.ts'), 'Estimate/ComingOfAgeCeremony/ServicePlans/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Remarks/index.ts'), 'Estimate/ComingOfAgeCeremony/Remarks/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Products/index.ts'), 'Estimate/ComingOfAgeCeremony/Products/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Photos/index.ts'), 'Estimate/ComingOfAgeCeremony/Photos/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/OtherItem/index.ts'), 'Estimate/ComingOfAgeCeremony/OtherItem/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Obijime/index.ts'), 'Estimate/ComingOfAgeCeremony/Obijime/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Obiage3/index.ts'), 'Estimate/ComingOfAgeCeremony/Obiage3/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Obiage2/index.ts'), 'Estimate/ComingOfAgeCeremony/Obiage2/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Obiage/index.ts'), 'Estimate/ComingOfAgeCeremony/Obiage/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Obi/index.ts'), 'Estimate/ComingOfAgeCeremony/Obi/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/MotherAndDaughterPlan/index.ts'), 'Estimate/ComingOfAgeCeremony/MotherAndDaughterPlan/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Memo/index.ts'), 'Estimate/ComingOfAgeCeremony/Memo/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kasaneeri3/index.ts'), 'Estimate/ComingOfAgeCeremony/Kasaneeri3/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kasaneeri2/index.ts'), 'Estimate/ComingOfAgeCeremony/Kasaneeri2/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kasaneeri/index.ts'), 'Estimate/ComingOfAgeCeremony/Kasaneeri/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kamikazari3/index.ts'), 'Estimate/ComingOfAgeCeremony/Kamikazari3/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kamikazari2/index.ts'), 'Estimate/ComingOfAgeCeremony/Kamikazari2/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kamikazari/index.ts'), 'Estimate/ComingOfAgeCeremony/Kamikazari/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/JkPhotoInAdvance/index.ts'), 'Estimate/ComingOfAgeCeremony/JkPhotoInAdvance/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Furisodes/index.ts'), 'Estimate/ComingOfAgeCeremony/Furisodes/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/EstimateList/index.ts'), 'Estimate/ComingOfAgeCeremony/EstimateList/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/EmployeeDiscount/index.ts'), 'Estimate/ComingOfAgeCeremony/EmployeeDiscount/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Customers/index.ts'), 'Estimate/ComingOfAgeCeremony/Customers/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Betsueri/index.ts'), 'Estimate/ComingOfAgeCeremony/Betsueri/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Bag3/index.ts'), 'Estimate/ComingOfAgeCeremony/Bag3/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Bag2/index.ts'), 'Estimate/ComingOfAgeCeremony/Bag2/index.ts')
  resolveStoreModules(require('../src/store/Contracts/Attendance/Main/getters.ts'), 'Contracts/Attendance/Main/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/Attendance/Main/mutations.ts'), 'Contracts/Attendance/Main/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/Attendance/Main/state.ts'), 'Contracts/Attendance/Main/state.ts')
  resolveStoreModules(require('../src/store/Contracts/Attendance/Memo/getters.ts'), 'Contracts/Attendance/Memo/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/Attendance/Memo/mutations.ts'), 'Contracts/Attendance/Memo/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/Attendance/Memo/state.ts'), 'Contracts/Attendance/Memo/state.ts')
  resolveStoreModules(require('../src/store/Contracts/Cleaning/Main/getters.ts'), 'Contracts/Cleaning/Main/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/Cleaning/Main/mutations.ts'), 'Contracts/Cleaning/Main/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/Cleaning/Main/state.ts'), 'Contracts/Cleaning/Main/state.ts')
  resolveStoreModules(require('../src/store/Contracts/Cleaning/Memo/getters.ts'), 'Contracts/Cleaning/Memo/getters.ts')
  resolveStoreModules(require('../src/store/Contracts/Cleaning/Memo/mutations.ts'), 'Contracts/Cleaning/Memo/mutations.ts')
  resolveStoreModules(require('../src/store/Contracts/Cleaning/Memo/state.ts'), 'Contracts/Cleaning/Memo/state.ts')
  resolveStoreModules(require('../src/store/Estimate/Attendance/CopiedEstimate/getters.ts'), 'Estimate/Attendance/CopiedEstimate/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/Attendance/CopiedEstimate/mutations.ts'), 'Estimate/Attendance/CopiedEstimate/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/Attendance/CopiedEstimate/state.ts'), 'Estimate/Attendance/CopiedEstimate/state.ts')
  resolveStoreModules(require('../src/store/Estimate/Attendance/Main/getters.ts'), 'Estimate/Attendance/Main/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/Attendance/Main/mutations.ts'), 'Estimate/Attendance/Main/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/Attendance/Main/state.ts'), 'Estimate/Attendance/Main/state.ts')
  resolveStoreModules(require('../src/store/Estimate/Attendance/Memo/getters.ts'), 'Estimate/Attendance/Memo/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/Attendance/Memo/mutations.ts'), 'Estimate/Attendance/Memo/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/Attendance/Memo/state.ts'), 'Estimate/Attendance/Memo/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Bag2/getters.ts'), 'Estimate/ComingOfAgeCeremony/Bag2/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Bag2/mutations.ts'), 'Estimate/ComingOfAgeCeremony/Bag2/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Bag2/state.ts'), 'Estimate/ComingOfAgeCeremony/Bag2/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Bag3/getters.ts'), 'Estimate/ComingOfAgeCeremony/Bag3/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Bag3/mutations.ts'), 'Estimate/ComingOfAgeCeremony/Bag3/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Bag3/state.ts'), 'Estimate/ComingOfAgeCeremony/Bag3/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Betsueri/actions.ts'), 'Estimate/ComingOfAgeCeremony/Betsueri/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Betsueri/getters.ts'), 'Estimate/ComingOfAgeCeremony/Betsueri/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Betsueri/mutations.ts'), 'Estimate/ComingOfAgeCeremony/Betsueri/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Betsueri/state.ts'), 'Estimate/ComingOfAgeCeremony/Betsueri/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/CopiedEstimate/getters.ts'), 'Estimate/ComingOfAgeCeremony/CopiedEstimate/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/CopiedEstimate/mutations.ts'), 'Estimate/ComingOfAgeCeremony/CopiedEstimate/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/CopiedEstimate/state.ts'), 'Estimate/ComingOfAgeCeremony/CopiedEstimate/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Customers/actions.ts'), 'Estimate/ComingOfAgeCeremony/Customers/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Customers/getters.ts'), 'Estimate/ComingOfAgeCeremony/Customers/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Customers/mutations.ts'), 'Estimate/ComingOfAgeCeremony/Customers/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Customers/state.ts'), 'Estimate/ComingOfAgeCeremony/Customers/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Discount/getters.ts'), 'Estimate/ComingOfAgeCeremony/Discount/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Discount/mutations.ts'), 'Estimate/ComingOfAgeCeremony/Discount/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Discount/state.ts'), 'Estimate/ComingOfAgeCeremony/Discount/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/EmployeeDiscount/actions.ts'), 'Estimate/ComingOfAgeCeremony/EmployeeDiscount/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/EmployeeDiscount/getters.ts'), 'Estimate/ComingOfAgeCeremony/EmployeeDiscount/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/EmployeeDiscount/mutations.ts'), 'Estimate/ComingOfAgeCeremony/EmployeeDiscount/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/EmployeeDiscount/state.ts'), 'Estimate/ComingOfAgeCeremony/EmployeeDiscount/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/EstimateList/actions.ts'), 'Estimate/ComingOfAgeCeremony/EstimateList/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/EstimateList/getters.ts'), 'Estimate/ComingOfAgeCeremony/EstimateList/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/EstimateList/mutations.ts'), 'Estimate/ComingOfAgeCeremony/EstimateList/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/EstimateList/state.ts'), 'Estimate/ComingOfAgeCeremony/EstimateList/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Furisodes/actions.ts'), 'Estimate/ComingOfAgeCeremony/Furisodes/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Furisodes/getters.ts'), 'Estimate/ComingOfAgeCeremony/Furisodes/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Furisodes/mutations.ts'), 'Estimate/ComingOfAgeCeremony/Furisodes/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Furisodes/state.ts'), 'Estimate/ComingOfAgeCeremony/Furisodes/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/JkPhotoInAdvance/actions.ts'), 'Estimate/ComingOfAgeCeremony/JkPhotoInAdvance/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/JkPhotoInAdvance/getters.ts'), 'Estimate/ComingOfAgeCeremony/JkPhotoInAdvance/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/JkPhotoInAdvance/mutations.ts'), 'Estimate/ComingOfAgeCeremony/JkPhotoInAdvance/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/JkPhotoInAdvance/state.ts'), 'Estimate/ComingOfAgeCeremony/JkPhotoInAdvance/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kamikazari/actions.ts'), 'Estimate/ComingOfAgeCeremony/Kamikazari/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kamikazari/getters.ts'), 'Estimate/ComingOfAgeCeremony/Kamikazari/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kamikazari/mutations.ts'), 'Estimate/ComingOfAgeCeremony/Kamikazari/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kamikazari/state.ts'), 'Estimate/ComingOfAgeCeremony/Kamikazari/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kamikazari2/actions.ts'), 'Estimate/ComingOfAgeCeremony/Kamikazari2/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kamikazari2/getters.ts'), 'Estimate/ComingOfAgeCeremony/Kamikazari2/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kamikazari2/mutations.ts'), 'Estimate/ComingOfAgeCeremony/Kamikazari2/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kamikazari2/state.ts'), 'Estimate/ComingOfAgeCeremony/Kamikazari2/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kamikazari3/actions.ts'), 'Estimate/ComingOfAgeCeremony/Kamikazari3/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kamikazari3/getters.ts'), 'Estimate/ComingOfAgeCeremony/Kamikazari3/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kamikazari3/mutations.ts'), 'Estimate/ComingOfAgeCeremony/Kamikazari3/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kamikazari3/state.ts'), 'Estimate/ComingOfAgeCeremony/Kamikazari3/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kasaneeri/actions.ts'), 'Estimate/ComingOfAgeCeremony/Kasaneeri/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kasaneeri/getters.ts'), 'Estimate/ComingOfAgeCeremony/Kasaneeri/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kasaneeri/mutations.ts'), 'Estimate/ComingOfAgeCeremony/Kasaneeri/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kasaneeri/state.ts'), 'Estimate/ComingOfAgeCeremony/Kasaneeri/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kasaneeri2/actions.ts'), 'Estimate/ComingOfAgeCeremony/Kasaneeri2/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kasaneeri2/getters.ts'), 'Estimate/ComingOfAgeCeremony/Kasaneeri2/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kasaneeri2/mutations.ts'), 'Estimate/ComingOfAgeCeremony/Kasaneeri2/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kasaneeri2/state.ts'), 'Estimate/ComingOfAgeCeremony/Kasaneeri2/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kasaneeri3/actions.ts'), 'Estimate/ComingOfAgeCeremony/Kasaneeri3/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kasaneeri3/getters.ts'), 'Estimate/ComingOfAgeCeremony/Kasaneeri3/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kasaneeri3/mutations.ts'), 'Estimate/ComingOfAgeCeremony/Kasaneeri3/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Kasaneeri3/state.ts'), 'Estimate/ComingOfAgeCeremony/Kasaneeri3/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/MasterData/getters.ts'), 'Estimate/ComingOfAgeCeremony/MasterData/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/MasterData/mutations.ts'), 'Estimate/ComingOfAgeCeremony/MasterData/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/MasterData/state.ts'), 'Estimate/ComingOfAgeCeremony/MasterData/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Memo/actions.ts'), 'Estimate/ComingOfAgeCeremony/Memo/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Memo/getters.ts'), 'Estimate/ComingOfAgeCeremony/Memo/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Memo/mutations.ts'), 'Estimate/ComingOfAgeCeremony/Memo/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Memo/state.ts'), 'Estimate/ComingOfAgeCeremony/Memo/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/MotherAndDaughterPlan/actions.ts'), 'Estimate/ComingOfAgeCeremony/MotherAndDaughterPlan/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/MotherAndDaughterPlan/getters.ts'), 'Estimate/ComingOfAgeCeremony/MotherAndDaughterPlan/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/MotherAndDaughterPlan/mutations.ts'), 'Estimate/ComingOfAgeCeremony/MotherAndDaughterPlan/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/MotherAndDaughterPlan/state.ts'), 'Estimate/ComingOfAgeCeremony/MotherAndDaughterPlan/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Obi/actions.ts'), 'Estimate/ComingOfAgeCeremony/Obi/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Obi/getters.ts'), 'Estimate/ComingOfAgeCeremony/Obi/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Obi/mutations.ts'), 'Estimate/ComingOfAgeCeremony/Obi/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Obi/state.ts'), 'Estimate/ComingOfAgeCeremony/Obi/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Obiage/actions.ts'), 'Estimate/ComingOfAgeCeremony/Obiage/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Obiage/getters.ts'), 'Estimate/ComingOfAgeCeremony/Obiage/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Obiage/mutations.ts'), 'Estimate/ComingOfAgeCeremony/Obiage/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Obiage/state.ts'), 'Estimate/ComingOfAgeCeremony/Obiage/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Obiage2/actions.ts'), 'Estimate/ComingOfAgeCeremony/Obiage2/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Obiage2/getters.ts'), 'Estimate/ComingOfAgeCeremony/Obiage2/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Obiage2/mutations.ts'), 'Estimate/ComingOfAgeCeremony/Obiage2/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Obiage2/state.ts'), 'Estimate/ComingOfAgeCeremony/Obiage2/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Obiage3/actions.ts'), 'Estimate/ComingOfAgeCeremony/Obiage3/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Obiage3/getters.ts'), 'Estimate/ComingOfAgeCeremony/Obiage3/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Obiage3/mutations.ts'), 'Estimate/ComingOfAgeCeremony/Obiage3/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Obiage3/state.ts'), 'Estimate/ComingOfAgeCeremony/Obiage3/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Obijime/actions.ts'), 'Estimate/ComingOfAgeCeremony/Obijime/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Obijime/getters.ts'), 'Estimate/ComingOfAgeCeremony/Obijime/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Obijime/mutations.ts'), 'Estimate/ComingOfAgeCeremony/Obijime/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Obijime/state.ts'), 'Estimate/ComingOfAgeCeremony/Obijime/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Option/getters.ts'), 'Estimate/ComingOfAgeCeremony/Option/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Option/mutations.ts'), 'Estimate/ComingOfAgeCeremony/Option/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Option/state.ts'), 'Estimate/ComingOfAgeCeremony/Option/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/OtherItem/actions.ts'), 'Estimate/ComingOfAgeCeremony/OtherItem/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/OtherItem/getters.ts'), 'Estimate/ComingOfAgeCeremony/OtherItem/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/OtherItem/mutations.ts'), 'Estimate/ComingOfAgeCeremony/OtherItem/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/OtherItem/state.ts'), 'Estimate/ComingOfAgeCeremony/OtherItem/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Photos/actions.ts'), 'Estimate/ComingOfAgeCeremony/Photos/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Photos/getters.ts'), 'Estimate/ComingOfAgeCeremony/Photos/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Photos/mutations.ts'), 'Estimate/ComingOfAgeCeremony/Photos/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Photos/state.ts'), 'Estimate/ComingOfAgeCeremony/Photos/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Products/actions.ts'), 'Estimate/ComingOfAgeCeremony/Products/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Products/getters.ts'), 'Estimate/ComingOfAgeCeremony/Products/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Products/mutations.ts'), 'Estimate/ComingOfAgeCeremony/Products/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Products/state.ts'), 'Estimate/ComingOfAgeCeremony/Products/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Remarks/actions.ts'), 'Estimate/ComingOfAgeCeremony/Remarks/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Remarks/getters.ts'), 'Estimate/ComingOfAgeCeremony/Remarks/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Remarks/mutations.ts'), 'Estimate/ComingOfAgeCeremony/Remarks/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Remarks/state.ts'), 'Estimate/ComingOfAgeCeremony/Remarks/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/ServicePlans/actions.ts'), 'Estimate/ComingOfAgeCeremony/ServicePlans/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/ServicePlans/getters.ts'), 'Estimate/ComingOfAgeCeremony/ServicePlans/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/ServicePlans/mutations.ts'), 'Estimate/ComingOfAgeCeremony/ServicePlans/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/ServicePlans/state.ts'), 'Estimate/ComingOfAgeCeremony/ServicePlans/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Shawl/actions.ts'), 'Estimate/ComingOfAgeCeremony/Shawl/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Shawl/getters.ts'), 'Estimate/ComingOfAgeCeremony/Shawl/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Shawl/mutations.ts'), 'Estimate/ComingOfAgeCeremony/Shawl/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Shawl/state.ts'), 'Estimate/ComingOfAgeCeremony/Shawl/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/SisterDiscount/actions.ts'), 'Estimate/ComingOfAgeCeremony/SisterDiscount/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/SisterDiscount/getters.ts'), 'Estimate/ComingOfAgeCeremony/SisterDiscount/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/SisterDiscount/mutations.ts'), 'Estimate/ComingOfAgeCeremony/SisterDiscount/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/SisterDiscount/state.ts'), 'Estimate/ComingOfAgeCeremony/SisterDiscount/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Tashinuno/getters.ts'), 'Estimate/ComingOfAgeCeremony/Tashinuno/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Tashinuno/mutations.ts'), 'Estimate/ComingOfAgeCeremony/Tashinuno/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Tashinuno/state.ts'), 'Estimate/ComingOfAgeCeremony/Tashinuno/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/TwinsDiscount/actions.ts'), 'Estimate/ComingOfAgeCeremony/TwinsDiscount/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/TwinsDiscount/getters.ts'), 'Estimate/ComingOfAgeCeremony/TwinsDiscount/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/TwinsDiscount/mutations.ts'), 'Estimate/ComingOfAgeCeremony/TwinsDiscount/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/TwinsDiscount/state.ts'), 'Estimate/ComingOfAgeCeremony/TwinsDiscount/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/actions.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/getters.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/mutations.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/state.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Zori2/actions.ts'), 'Estimate/ComingOfAgeCeremony/Zori2/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Zori2/getters.ts'), 'Estimate/ComingOfAgeCeremony/Zori2/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Zori2/mutations.ts'), 'Estimate/ComingOfAgeCeremony/Zori2/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Zori2/state.ts'), 'Estimate/ComingOfAgeCeremony/Zori2/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Zori3/actions.ts'), 'Estimate/ComingOfAgeCeremony/Zori3/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Zori3/getters.ts'), 'Estimate/ComingOfAgeCeremony/Zori3/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Zori3/mutations.ts'), 'Estimate/ComingOfAgeCeremony/Zori3/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/Zori3/state.ts'), 'Estimate/ComingOfAgeCeremony/Zori3/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/ZoriBag/actions.ts'), 'Estimate/ComingOfAgeCeremony/ZoriBag/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/ZoriBag/getters.ts'), 'Estimate/ComingOfAgeCeremony/ZoriBag/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/ZoriBag/mutations.ts'), 'Estimate/ComingOfAgeCeremony/ZoriBag/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/ZoriBag/state.ts'), 'Estimate/ComingOfAgeCeremony/ZoriBag/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/CopiedEstimate/getters.ts'), 'Estimate/GraduationCeremony/CopiedEstimate/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/CopiedEstimate/mutations.ts'), 'Estimate/GraduationCeremony/CopiedEstimate/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/CopiedEstimate/state.ts'), 'Estimate/GraduationCeremony/CopiedEstimate/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/masterData/getters.ts'), 'Estimate/GraduationCeremony/masterData/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/masterData/mutations.ts'), 'Estimate/GraduationCeremony/masterData/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/masterData/state.ts'), 'Estimate/GraduationCeremony/masterData/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/memo/getters.ts'), 'Estimate/GraduationCeremony/memo/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/memo/mutations.ts'), 'Estimate/GraduationCeremony/memo/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/memo/state.ts'), 'Estimate/GraduationCeremony/memo/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tabNavi/getters.ts'), 'Estimate/GraduationCeremony/tabNavi/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tabNavi/mutations.ts'), 'Estimate/GraduationCeremony/tabNavi/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tabNavi/state.ts'), 'Estimate/GraduationCeremony/tabNavi/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tabオプション/getters.ts'), 'Estimate/GraduationCeremony/tabオプション/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tabオプション/mutations.ts'), 'Estimate/GraduationCeremony/tabオプション/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tabオプション/state.ts'), 'Estimate/GraduationCeremony/tabオプション/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tabお写真プラン/getters.ts'), 'Estimate/GraduationCeremony/tabお写真プラン/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tabお写真プラン/mutations.ts'), 'Estimate/GraduationCeremony/tabお写真プラン/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tabお写真プラン/state.ts'), 'Estimate/GraduationCeremony/tabお写真プラン/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tabお名前/getters.ts'), 'Estimate/GraduationCeremony/tabお名前/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tabお名前/mutations.ts'), 'Estimate/GraduationCeremony/tabお名前/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tabお名前/state.ts'), 'Estimate/GraduationCeremony/tabお名前/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tabお客様検索/getters.ts'), 'Estimate/GraduationCeremony/tabお客様検索/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tabお客様検索/mutations.ts'), 'Estimate/GraduationCeremony/tabお客様検索/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tabお客様検索/state.ts'), 'Estimate/GraduationCeremony/tabお客様検索/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tabその他商品/getters.ts'), 'Estimate/GraduationCeremony/tabその他商品/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tabその他商品/mutations.ts'), 'Estimate/GraduationCeremony/tabその他商品/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tabその他商品/state.ts'), 'Estimate/GraduationCeremony/tabその他商品/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tabプラン/getters.ts'), 'Estimate/GraduationCeremony/tabプラン/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tabプラン/mutations.ts'), 'Estimate/GraduationCeremony/tabプラン/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tabプラン/state.ts'), 'Estimate/GraduationCeremony/tabプラン/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab二尺袖/getters.ts'), 'Estimate/GraduationCeremony/tab二尺袖/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab二尺袖/mutations.ts'), 'Estimate/GraduationCeremony/tab二尺袖/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab二尺袖/state.ts'), 'Estimate/GraduationCeremony/tab二尺袖/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab備考/getters.ts'), 'Estimate/GraduationCeremony/tab備考/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab備考/mutations.ts'), 'Estimate/GraduationCeremony/tab備考/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab備考/state.ts'), 'Estimate/GraduationCeremony/tab備考/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab別衿_着物レンタル/getters.ts'), 'Estimate/GraduationCeremony/tab別衿_着物レンタル/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab別衿_着物レンタル/mutations.ts'), 'Estimate/GraduationCeremony/tab別衿_着物レンタル/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab別衿_着物レンタル/state.ts'), 'Estimate/GraduationCeremony/tab別衿_着物レンタル/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab別衿_着物持込/getters.ts'), 'Estimate/GraduationCeremony/tab別衿_着物持込/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab別衿_着物持込/mutations.ts'), 'Estimate/GraduationCeremony/tab別衿_着物持込/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab別衿_着物持込/state.ts'), 'Estimate/GraduationCeremony/tab別衿_着物持込/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab割引/getters.ts'), 'Estimate/GraduationCeremony/tab割引/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab割引/mutations.ts'), 'Estimate/GraduationCeremony/tab割引/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab割引/state.ts'), 'Estimate/GraduationCeremony/tab割引/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab半巾帯_袴レンタル/getters.ts'), 'Estimate/GraduationCeremony/tab半巾帯_袴レンタル/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab半巾帯_袴レンタル/mutations.ts'), 'Estimate/GraduationCeremony/tab半巾帯_袴レンタル/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab半巾帯_袴レンタル/state.ts'), 'Estimate/GraduationCeremony/tab半巾帯_袴レンタル/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab半巾帯_袴持込/getters.ts'), 'Estimate/GraduationCeremony/tab半巾帯_袴持込/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab半巾帯_袴持込/mutations.ts'), 'Estimate/GraduationCeremony/tab半巾帯_袴持込/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab半巾帯_袴持込/state.ts'), 'Estimate/GraduationCeremony/tab半巾帯_袴持込/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab商品設定/getters.ts'), 'Estimate/GraduationCeremony/tab商品設定/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab商品設定/mutations.ts'), 'Estimate/GraduationCeremony/tab商品設定/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab商品設定/state.ts'), 'Estimate/GraduationCeremony/tab商品設定/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab巾着バッグ_着物レンタル/getters.ts'), 'Estimate/GraduationCeremony/tab巾着バッグ_着物レンタル/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab巾着バッグ_着物レンタル/mutations.ts'), 'Estimate/GraduationCeremony/tab巾着バッグ_着物レンタル/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab巾着バッグ_着物レンタル/state.ts'), 'Estimate/GraduationCeremony/tab巾着バッグ_着物レンタル/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab巾着バッグ_着物持込/getters.ts'), 'Estimate/GraduationCeremony/tab巾着バッグ_着物持込/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab巾着バッグ_着物持込/mutations.ts'), 'Estimate/GraduationCeremony/tab巾着バッグ_着物持込/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab巾着バッグ_着物持込/state.ts'), 'Estimate/GraduationCeremony/tab巾着バッグ_着物持込/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab振袖/getters.ts'), 'Estimate/GraduationCeremony/tab振袖/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab振袖/mutations.ts'), 'Estimate/GraduationCeremony/tab振袖/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab振袖/state.ts'), 'Estimate/GraduationCeremony/tab振袖/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab草履ブーツ_着物レンタル/getters.ts'), 'Estimate/GraduationCeremony/tab草履ブーツ_着物レンタル/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab草履ブーツ_着物レンタル/mutations.ts'), 'Estimate/GraduationCeremony/tab草履ブーツ_着物レンタル/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab草履ブーツ_着物レンタル/state.ts'), 'Estimate/GraduationCeremony/tab草履ブーツ_着物レンタル/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab草履ブーツ_着物持込/getters.ts'), 'Estimate/GraduationCeremony/tab草履ブーツ_着物持込/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab草履ブーツ_着物持込/mutations.ts'), 'Estimate/GraduationCeremony/tab草履ブーツ_着物持込/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab草履ブーツ_着物持込/state.ts'), 'Estimate/GraduationCeremony/tab草履ブーツ_着物持込/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab袴/getters.ts'), 'Estimate/GraduationCeremony/tab袴/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab袴/mutations.ts'), 'Estimate/GraduationCeremony/tab袴/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab袴/state.ts'), 'Estimate/GraduationCeremony/tab袴/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab見積一覧/getters.ts'), 'Estimate/GraduationCeremony/tab見積一覧/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab見積一覧/mutations.ts'), 'Estimate/GraduationCeremony/tab見積一覧/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab見積一覧/state.ts'), 'Estimate/GraduationCeremony/tab見積一覧/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab試着写真/getters.ts'), 'Estimate/GraduationCeremony/tab試着写真/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab試着写真/mutations.ts'), 'Estimate/GraduationCeremony/tab試着写真/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab試着写真/state.ts'), 'Estimate/GraduationCeremony/tab試着写真/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab重ね衿_着物レンタル1/getters.ts'), 'Estimate/GraduationCeremony/tab重ね衿_着物レンタル1/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab重ね衿_着物レンタル1/mutations.ts'), 'Estimate/GraduationCeremony/tab重ね衿_着物レンタル1/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab重ね衿_着物レンタル1/state.ts'), 'Estimate/GraduationCeremony/tab重ね衿_着物レンタル1/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab重ね衿_着物レンタル2/getters.ts'), 'Estimate/GraduationCeremony/tab重ね衿_着物レンタル2/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab重ね衿_着物レンタル2/mutations.ts'), 'Estimate/GraduationCeremony/tab重ね衿_着物レンタル2/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab重ね衿_着物レンタル2/state.ts'), 'Estimate/GraduationCeremony/tab重ね衿_着物レンタル2/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab重ね衿_着物レンタル3/getters.ts'), 'Estimate/GraduationCeremony/tab重ね衿_着物レンタル3/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab重ね衿_着物レンタル3/mutations.ts'), 'Estimate/GraduationCeremony/tab重ね衿_着物レンタル3/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab重ね衿_着物レンタル3/state.ts'), 'Estimate/GraduationCeremony/tab重ね衿_着物レンタル3/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab重ね衿_着物持込1/getters.ts'), 'Estimate/GraduationCeremony/tab重ね衿_着物持込1/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab重ね衿_着物持込1/mutations.ts'), 'Estimate/GraduationCeremony/tab重ね衿_着物持込1/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab重ね衿_着物持込1/state.ts'), 'Estimate/GraduationCeremony/tab重ね衿_着物持込1/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab重ね衿_着物持込2/getters.ts'), 'Estimate/GraduationCeremony/tab重ね衿_着物持込2/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab重ね衿_着物持込2/mutations.ts'), 'Estimate/GraduationCeremony/tab重ね衿_着物持込2/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab重ね衿_着物持込2/state.ts'), 'Estimate/GraduationCeremony/tab重ね衿_着物持込2/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab重ね衿_着物持込3/getters.ts'), 'Estimate/GraduationCeremony/tab重ね衿_着物持込3/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab重ね衿_着物持込3/mutations.ts'), 'Estimate/GraduationCeremony/tab重ね衿_着物持込3/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab重ね衿_着物持込3/state.ts'), 'Estimate/GraduationCeremony/tab重ね衿_着物持込3/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab髪飾り1/getters.ts'), 'Estimate/GraduationCeremony/tab髪飾り1/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab髪飾り1/mutations.ts'), 'Estimate/GraduationCeremony/tab髪飾り1/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab髪飾り1/state.ts'), 'Estimate/GraduationCeremony/tab髪飾り1/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab髪飾り2/getters.ts'), 'Estimate/GraduationCeremony/tab髪飾り2/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab髪飾り2/mutations.ts'), 'Estimate/GraduationCeremony/tab髪飾り2/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab髪飾り2/state.ts'), 'Estimate/GraduationCeremony/tab髪飾り2/state.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab髪飾り3/getters.ts'), 'Estimate/GraduationCeremony/tab髪飾り3/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab髪飾り3/mutations.ts'), 'Estimate/GraduationCeremony/tab髪飾り3/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/GraduationCeremony/tab髪飾り3/state.ts'), 'Estimate/GraduationCeremony/tab髪飾り3/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoSetting/index.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoSetting/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoProducts/index.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoProducts/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiRental/index.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiRental/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObijimeRental/index.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObijimeRental/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObijimeBring/index.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObijimeBring/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiBring/index.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiBring/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiageRental/index.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiageRental/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiageBring/index.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiageBring/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiage3/index.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiage3/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiage2/index.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiage2/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeriRental/index.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeriRental/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeriBring/index.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeriBring/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeri3/index.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeri3/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeri2/index.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeri2/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoFurisodes/index.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoFurisodes/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoBetsueriRental/index.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoBetsueriRental/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoBetsueriBring/index.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoBetsueriBring/index.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoBetsueriBring/actions.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoBetsueriBring/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoBetsueriBring/getters.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoBetsueriBring/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoBetsueriBring/mutations.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoBetsueriBring/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoBetsueriBring/state.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoBetsueriBring/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoBetsueriRental/actions.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoBetsueriRental/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoBetsueriRental/getters.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoBetsueriRental/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoBetsueriRental/mutations.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoBetsueriRental/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoBetsueriRental/state.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoBetsueriRental/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoFurisodes/actions.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoFurisodes/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoFurisodes/getters.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoFurisodes/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoFurisodes/mutations.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoFurisodes/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoFurisodes/state.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoFurisodes/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeri2/actions.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeri2/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeri2/getters.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeri2/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeri2/mutations.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeri2/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeri2/state.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeri2/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeri3/actions.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeri3/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeri3/getters.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeri3/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeri3/mutations.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeri3/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeri3/state.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeri3/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeriBring/actions.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeriBring/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeriBring/getters.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeriBring/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeriBring/mutations.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeriBring/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeriBring/state.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeriBring/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeriRental/actions.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeriRental/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeriRental/getters.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeriRental/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeriRental/mutations.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeriRental/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeriRental/state.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeriRental/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiage2/actions.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiage2/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiage2/getters.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiage2/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiage2/mutations.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiage2/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiage2/state.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiage2/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiage3/actions.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiage3/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiage3/getters.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiage3/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiage3/mutations.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiage3/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiage3/state.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiage3/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiageBring/actions.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiageBring/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiageBring/getters.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiageBring/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiageBring/mutations.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiageBring/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiageBring/state.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiageBring/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiageRental/actions.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiageRental/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiageRental/getters.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiageRental/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiageRental/mutations.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiageRental/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiageRental/state.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiageRental/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiBring/actions.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiBring/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiBring/getters.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiBring/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiBring/mutations.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiBring/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiBring/state.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiBring/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObijimeBring/actions.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObijimeBring/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObijimeBring/getters.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObijimeBring/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObijimeBring/mutations.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObijimeBring/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObijimeBring/state.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObijimeBring/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObijimeRental/actions.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObijimeRental/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObijimeRental/getters.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObijimeRental/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObijimeRental/mutations.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObijimeRental/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObijimeRental/state.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObijimeRental/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiRental/actions.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiRental/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiRental/getters.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiRental/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiRental/mutations.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiRental/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiRental/state.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiRental/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoProducts/actions.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoProducts/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoProducts/getters.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoProducts/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoProducts/mutations.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoProducts/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoProducts/state.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoProducts/state.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoSetting/actions.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoSetting/actions.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoSetting/getters.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoSetting/getters.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoSetting/mutations.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoSetting/mutations.ts')
  resolveStoreModules(require('../src/store/Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoSetting/state.ts'), 'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoSetting/state.ts')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
