















































import {
  computed,
  defineComponent,
  PropType,
  ref,
  useRouter,
  watch,
} from '@nuxtjs/composition-api'
import { usePrice } from '~/composable/general/Price'
import AppPinkButton from '~/components/_graduationCeremony/atoms/AppPinkButton.vue'
import IconArrowRight from '~/components/icons/IconArrowRight.vue'
import TheDialogBeforeEstimateSave from '~/components/TheDialogBeforeEstimateSave.vue'
import { useInitializeState } from '~/composable/general/useInitializeState'
export type BackButton = {
  label: string
  url?: string // 渡さない場合はルーティングしない
  onClick?: Function
  takeArrowOff?: boolean // ボタンの矢印を外すか
}
export type NextButton = {
  label: string
  url?: string // 渡さない場合はルーティングしない
  totalPrice?: number
  // NOTE: 処理が成功してルーティングOKのフラグを返すこと
  onClick?: () => Promise<boolean> | boolean
  disabled?: boolean
}
/*
 * 見積もりの最終確認ページ群で表示するフッターコンポーネント
 *
 * backButton: 左端に表示される戻るボタンのprops
 * nextButton: 右端に表示される次のフローに進むボタンのprops
 * totalPriceを渡すと合計金額を表示するタイプのスタイルになる
 */
export default defineComponent({
  components: {
    AppPinkButton,
    IconArrowRight,
    TheDialogBeforeEstimateSave,
  },
  props: {
    backButton: {
      type: Object as PropType<BackButton>,
      default: undefined,
    },
    nextButton: {
      type: Object as PropType<NextButton>,
      default: undefined,
    },
    showEstimateReflectionBtn: {
      type: Boolean,
      default: false,
    },
    nextButtonDisabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
  },
  emits: ['isLoading'],
  setup(props, { emit }) {
    const router = useRouter()
    const { initEstimateStore } = useInitializeState()
    const { toString } = usePrice()

    const disabled = computed(() => {
      if (props?.nextButtonDisabled !== undefined) {
        return props?.nextButtonDisabled
      }
      return props?.nextButton?.disabled
    })
    const isLoading = ref(false)
    // NOTE: ローディング中のスクリーンを画面最上部のz-indexで表示するため、上のコンポーネントで呼び出す必要がある。
    watch(isLoading, () => emit('isLoading', isLoading.value))
    const formattedPrice = computed(() => {
      if (!props.nextButton || props.nextButton.totalPrice === undefined) {
        return 'no price'
      }
      return toString(props.nextButton.totalPrice)
    })
    const clickBack = () => {
      if (props.backButton.url) {
        router.push(props.backButton.url)
      }
      if (props.backButton?.onClick) {
        props.backButton.onClick()
      }
    }
    const clickNext = async () => {
      const isSuccessed = ref(true)
      if (props.nextButton?.onClick) {
        try {
          isLoading.value = true
          isSuccessed.value = await props.nextButton.onClick()
        } catch {
          throw new Error('エラー')
        } finally {
          isLoading.value = false
        }
      }
      if (isSuccessed.value && props.nextButton.url) {
        router.push(props.nextButton.url)
      }
    }

    // 子コンポーネントアクセス用ref
    const dialogBeforeEstimateSave =
      ref<InstanceType<typeof TheDialogBeforeEstimateSave>>()

    const openDialogBeforeEstimateSave = () => {
      dialogBeforeEstimateSave.value?.openModal()
    }

    return {
      clickBack,
      clickNext,
      formattedPrice,
      disabled,
      dialogBeforeEstimateSave,
      openDialogBeforeEstimateSave,
      isLoading,
    }
  },
})
