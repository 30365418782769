



































































import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  data() {
    return {
      width: 274.97,
      height: 40.59,
    }
  },
})
