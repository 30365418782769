import {
  CatalogFurisodeTypeResult,
  CatalogOtherTypeResult,
  FinishedTypeResult,
} from '~/store/types'

/**
 * 契約機能において、見積機能で入力した内容を保持するストア
 * products: 着物や小物の情報
 * optionalPrices: 追加金額・着付代系・プレミアムパックとかアルバム代
 * discounts: 割引・特典
 * photoPlan: お写真プラン
 * wPhoto: Wフォトプラン内のproducts,optionalPrices,discounts
 */
export const getInitialStore = (): ExtendsEstimateState => {
  return {
    products: [],
    optionalPrices: [],
    discounts: [],
    photoPlan: [],
    wPhoto: {
      products: [],
      optionalPrices: [],
      discounts: [],
    },
  }
}

const state = (): { fields: ExtendsEstimateState } => ({
  fields: getInitialStore(),
})

export default state

export type ExtendsEstimateState = {
  products: ProductItem[]
  optionalPrices: OptionalItem[]
  discounts: DiscountItem[]
  photoPlan: PhotoPlanItem[]
  wPhoto: {
    products: ProductItem[]
    optionalPrices: OptionalItem[]
    discounts: DiscountItem[]
  }
}

export type BasePriceItem = {
  label: string
  price: number | null
  detail: string | null
}

export type dailyReportdetails = {
  name: string
  price: number
}

export type DiscountItem = {
  discountType?: number // API用のtypeIdがある要素用
  isInsertDailyReport?: boolean // 日報用
  apiProps?: {
    // discountTypeを持つタイプの要素のAPIに送るあ値
    type: number
    rate?: number
    amount?: number
  }
} & BasePriceItem
// API用のtypeIdがある要素にはOptionalItemTypeにそれを載せる
export type OptionalItem = {
  additionType?: number
  isInsertDailyReport?: boolean // 日報用
} & BasePriceItem

type Product =
  | {
      type: 'catalogHurisode'
      result: CatalogFurisodeTypeResult
    }
  | {
      type: 'catalogOther'
      result: CatalogOtherTypeResult
    }
  | {
      type: 'finished'
      result: FinishedTypeResult
    }

export type ProductItem = BasePriceItem & {
  productTypeId: number | null // 品種区分
  rankUpName: string | null // お持込含む小物プランのテキスト
  rankUpId: number | null // =planId 小物プランのID
  priceType?: number // 単品購入・単品レンタルのid
  priceWithPP?: number
  productName?: string | null
  productId?: string | number | null
  catalogCode?: string | number | null
  product?: Product // 商品検索結果
  isInsertDailyReport?: boolean // 日報用
}

export type PhotoPlanItem =
  | (Album & BasePhotoPlanItem)
  | (WithDaishi & BasePhotoPlanItem)
  | (NoDaishi & BasePhotoPlanItem)
  | (AcrylStand & BasePhotoPlanItem)
  | (USB & BasePhotoPlanItem)

type BasePhotoPlanItem = {
  label: string
  photoPlanId: number | null
  price: number
}
type Album = {
  type: 'アルバム'
  pose_number: number
  number_of_books: number
  referral_bonus_pose_number: number
}
type WithDaishi = {
  type: '台紙あり'
  pose_number: number
  number_of_books: number
}
type NoDaishi = {
  type: '台紙なし'
  pose_number: number
}
type AcrylStand = {
  type: 'アクリルスタンド'
  size: number // 1: A4 2: A5
  number_of_pieces: number
}
type USB = {
  type: 'USB'
  is_buying_final_photo_data: boolean
}
