import { computed, watch } from '@nuxtjs/composition-api'
import { useCalculator } from './Calculators'
import { useEventHandlers } from './EventHandlers'
import { useEstimateListItems } from './formatters'
import {
  convertWPhotoParamsByPlan,
  convertParamsByPlan,
} from './formatters/utilities'
import { useEstimateWPhotoListItems } from './formatters/wPhoto'
import { useStoreSetter } from './StoreSetters'
import { Item, ItemWPhoto } from './types'
import { EstimateListState } from '~/store/types'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { useState振袖 } from '~/composable/estimate/repository/comingOfAge/states/StateWフォト/State振袖'
import { useStateWPhoto } from '~/composable/estimate/repository/comingOfAge/states/StateWフォト'
import { useScreenTypeFlags } from '~/composable/estimate/domain/ComingOfAge/ScreenTypeFlags'

/**
 * 見積一覧ページで必要な値、クリックイベントにより発火する関数などを取りまとめた関数
 * マウント時と表示内容が変更された時に、その時決定されている内容をストアに保存する処理も行なっている
 */
export const useEstimateList = () => {
  const listItems = useEstimateListItems()
  const wPhotolistItems = useEstimateWPhotoListItems()
  const calculator = useCalculator(listItems, wPhotolistItems)
  const storeSetter = useStoreSetter(listItems, wPhotolistItems)
  const eventHandler = useEventHandlers()
  const { saveAllData, saveAllWPhotoData } = useSaveAllData()

  const values見積一覧 =
    useComingStore<EstimateListState['fields']>('EstimateList').getAll()
  const stateWPhoto = useStateWPhoto()
  // NOTE: 表示されている内容をストアに保存する
  const batchSave = () => {
    if (values見積一覧.value.isEstimateCreated) return
    saveAllData(listItems.value)
    if (stateWPhoto.value.firstPage.isWPhoto適用) {
      saveAllWPhotoData(wPhotolistItems.value)
    }
    storeSetter.storeToContract()
  }
  watch([wPhotolistItems, listItems], batchSave, {
    immediate: true,
    deep: true,
  })

  return {
    ...storeSetter,
    calculator: { ...calculator },
    ...eventHandler,
    listItems,
    wPhotolistItems,
    selectedPlan: computed(() => values見積一覧.value.selectedPlan),
    selectedWPPlan: computed(() => values見積一覧.value.selectedWPPlan),
    hasWPhoto: stateWPhoto.value.firstPage.isWPhoto適用,
    isWPhoto振袖レンタル: stateWPhoto.value.振袖.flags.isレンタル,
  }
}

const useSaveAllData = () => {
  const store見積一覧 =
    useComingStore<EstimateListState['fields']>('EstimateList')
  const { showTypeフルセット, showType写真のみレンタル, showType持ち込み } =
    useScreenTypeFlags()
  const state振袖 = useState振袖()

  const saveAllData = (listItems: Item[]) => {
    const flags = {
      isフルセット: showTypeフルセット.value,
      is写のみレンタル: showType写真のみレンタル.value,
      is持ち込み: showType持ち込み.value,
    }
    const formattedList = listItems.map((item) =>
      convertParamsByPlan(item, flags)
    )
    store見積一覧.set<'allData'>('allData', formattedList)
  }

  const saveAllWPhotoData = (wPhotolistItems: ItemWPhoto[]) => {
    const formattedList = wPhotolistItems.map((item) =>
      convertWPhotoParamsByPlan(item, state振袖.value.flags.isレンタル)
    )
    store見積一覧.set<'wPhotoAllData'>('wPhotoAllData', formattedList)
  }

  return { saveAllData, saveAllWPhotoData }
}
