import { Restate } from '~/composable/estimate/repository/graduation/EstimateRestate'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { FormValuesその他商品 } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsその他商品'

export const useRestoreその他商品 = () => {
  const store = useGraduateStore<FormValuesその他商品>('tabその他商品')

  const restoreその他商品 = (restate: Restate) => {
    const otherItems = restate.items.その他商品
    if (otherItems) store.setAll(otherItems)
  }
  return { restoreその他商品 }
}
