import { GetterTree } from 'vuex'
import { RootState } from '../GraduationCeremony/types'
import { OldData } from './state'

const getters: GetterTree<OldData, RootState> = {
  getContractId: (state: OldData): OldData['contractId'] => {
    return state.contractId
  },

  getServiceType: (state: OldData): OldData['serviceType'] => {
    return state.serviceType
  },

  getIsDigitCorrection: (state: OldData): OldData['isDigitCorrection'] => {
    return state.isDigitCorrection
  },

  getIsSleeveCorrection: (state: OldData): OldData['isSleeveCorrection'] => {
    return state.isSleeveCorrection
  },

  getFurisodeCategory: (state: OldData): OldData['furisodeCategory'] => {
    return state.furisodeCategory
  },

  getFurisodeCatalogCode: (state: OldData): OldData['furisodeCatalogCode'] => {
    return state.furisodeCatalogCode
  },
}

export default getters
