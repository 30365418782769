import { AccessoriesFullsetState } from '../../types'

const state = (): AccessoriesFullsetState => ({
  resultSelectionsJK: [],
  resultSelectionsComing: [],
  resultSelectionsWphoto: [],
  resultSelectionsMotherAndDaughterPlan: [],
  tabiColor: '',
})

export default state
