import { computed } from '@nuxtjs/composition-api'
import {
  SelectedPlanListType,
  getBadgeText,
  getItemPlan,
  getItemPlanFlags,
  getItemPrices,
  getPriceTypeText,
} from '~/composable/estimate/repository/comingOfAge/states/utilities'
import { AccessoryPlanState, EstimateListState } from '~/store/types'
import { useComingStore } from '../../ComingOfAgeStore'
import { useScreenTypeFlags } from '~/composable/estimate/domain/ComingOfAge/ScreenTypeFlags'
import { BaseItemState, PlanFlags } from './types'
import ProductClassification from '~/constants/enums/productClassification'
import { useState商品設定 } from './StateOtherTabs'
import { PLAN } from '~/constants/estimate/comingOfAge'
import { useSelectedPlanListType } from '~/composable/estimate/domain/ComingOfAge/SelectedPlanListType'

type Obiage = BaseItemState & {
  品種区分: 4
}

export const useState帯揚げ = () => {
  const values帯揚げ1 = useComingStore<AccessoryPlanState>('Obiage').getAll()
  const values帯揚げ2 = useComingStore<AccessoryPlanState>('Obiage2').getAll()
  const values帯揚げ3 = useComingStore<AccessoryPlanState>('Obiage3').getAll()
  const values見積一覧 =
    useComingStore<EstimateListState['fields']>('EstimateList').getAll()
  const state商品設定 = useState商品設定()
  const { showTypeフルセット, showType写真のみレンタル, showType持ち込み } =
    useScreenTypeFlags()
  const selectedPlanListType = useSelectedPlanListType()

  return computed(() => {
    const flags = {
      isフルセット: showTypeフルセット.value,
      is写のみレンタル: showType写真のみレンタル.value,
      is持ち込み: showType持ち込み.value,
    }
    const values帯揚げ = [
      values帯揚げ1.value,
      values帯揚げ2.value,
      values帯揚げ3.value,
    ]
    /**
     * NOTE: isObiageSecondRankUpはラジオボタンの値であり、初期値がtrueのため
     * isPPDiscountが有効かどうかも判定している
     */
    const isRankUp =
      !!values帯揚げ2.value.isPPDiscount &&
      values見積一覧.value.isObiageSecondRankUp

    const selectedPlanListTypeFlags = {
      isレンタルプラン: selectedPlanListType.isレンタルプラン.value,
      is購入プラン: selectedPlanListType.is購入プラン.value,
      is未決定: selectedPlanListType.is未決定.value,
    }
    return getState帯揚げ(
      values帯揚げ,
      state商品設定.value.帯揚げ,
      flags,
      selectedPlanListTypeFlags,
      isRankUp
    )
  })
}

export const getState帯揚げ = (
  values帯揚げ: AccessoryPlanState[],
  count帯揚げ: number,
  planFlags: PlanFlags,
  selectedPlanListType: SelectedPlanListType,
  isRankUp: boolean
) => {
  const filledStoreValues = values帯揚げ.slice(0, count帯揚げ)
  return filledStoreValues.map((value, i) => {
    const { selections, input, isPPDiscount } = value
    const itemFlags = getItemPlanFlags(selections)
    const itemPlan = getItemPlan(selections)
    const isRankupApplied = i === 1 && isRankUp
    const numberText = i > 0 ? ' - ' + (i + 1) : ''
    const obiage: Obiage = {
      品目名:
        ProductClassification.toWord(ProductClassification.obiage) + numberText,
      品種区分: ProductClassification.obiage,
      商品名: input.productName ?? '',
      商品id: !!selections[2]
        ? selections[2] === PLAN.ACTUAL_PRODUCT
          ? null
          : selections[2]
        : null,
      小物プラン: isRankupApplied ? 3 : itemPlan,
      レンタル価格: 0,
      購入価格: 0,
      プレミアムレンタル価格: isRankupApplied ? 0 : input.premiumRentalPrice,
      プレミアム購入価格: isRankupApplied ? 0 : input.premiumBuyPrice,
      見積表示: null,
      表示価格: null,
      label: getBadgeText(itemPlan),
      cellLabel: getPriceTypeText(selections),
      flags: {
        isランクアップ:
          itemFlags.isランクアップPP内 || itemFlags.isランクアップPP外,
        isPPDiscount: isPPDiscount,
      },
    }
    const updatedPrices = getItemPrices(
      value,
      obiage,
      planFlags,
      i,
      planFlags.is持ち込み ? undefined : selectedPlanListType
    )
    obiage.レンタル価格 = updatedPrices.レンタル価格
    obiage.購入価格 = updatedPrices.購入価格
    obiage.プレミアムレンタル価格 = updatedPrices.プレミアムレンタル価格
    obiage.プレミアム購入価格 = updatedPrices.プレミアム購入価格
    obiage.見積表示 = updatedPrices.見積表示
    obiage.表示価格 = updatedPrices.表示価格
    return obiage
  })
}
