import { GetterTree } from 'vuex'
import { RootState } from '~/store/Estimate/GraduationCeremony/types'
import { FrontStoreInfo } from '~/store/types'

const getters: GetterTree<FrontStoreInfo, RootState> = {
  getState: (state) => (fieldId: keyof FrontStoreInfo) => {
    return state[fieldId]
  },
  getAllState: (state) => {
    return state
  },
}

export default getters
