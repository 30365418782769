























































import { defineComponent, ref } from '@nuxtjs/composition-api'
import TermsSelectionModal from '~/components/graduationCeremony/organisms/TermsSelectionModal.vue'

export default defineComponent({
  components: {
    TermsSelectionModal,
  },
  setup() {
    const showTermsSelectionModal = ref(false)

    const toggleTermsSelectionModal = () => {
      showTermsSelectionModal.value = !showTermsSelectionModal.value
    }

    return {
      showTermsSelectionModal,
      toggleTermsSelectionModal,
    }
  },
})
