import { ImportantMatterState } from '../../types'

const state = (): ImportantMatterState => ({
  input: {
    coolingOffPeriod: '',
    paymentDeadline: '',
    payee: null,
    payeeInfo: '',
    loginShopTransferPlaceId: null, // ログイン時店舗の振込先ID
  },
  isValidate: null,
})

export default state
