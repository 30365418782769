import { OTHER_ITEMS } from '~/constants/estimate/config'
import { OtherItems } from '~/store/types'

export const initialState: OtherItems = {
  apply: OTHER_ITEMS.種別.しない,
  items: [],
  isValidate: false,
}

export const getInitialStore = (): OtherItems => ({
  ...initialState,
})

const state = () => getInitialStore()

export default state
