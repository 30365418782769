



















import {
  defineComponent,
  ref,
  PropType,
  useStore,
  computed,
  watch,
  ComputedRef,
} from '@nuxtjs/composition-api'
import AppIconCircle from './AppIconCircle.vue'
import IconCircleSave from './icons/IconCircleSave.vue'
import IconCircleMemo from './icons/IconCircleMemo.vue'
import TheDialogTempSave from './TheDialogTempSave.vue'
import EstimateDialogMemo from './EstimateDialogMemo.vue'

/** composable */
import {
  EstimateType,
  useTemporarilySaved,
} from '~/composable/api/useTemporarilySaved'

/** types */
import { FrontStoreInfo } from '~/store/types'
import { State, Tab } from '~/store/Estimate/Attendance/Main/state'
import {
  State as SingleItemServiceState,
  Tab as SingleItemServiceTab,
} from '~/store/SingleItemService/Main/state'

type SaveFunc = () => void

export default defineComponent({
  components: {
    AppIconCircle,
    IconCircleSave,
    IconCircleMemo,
    TheDialogTempSave,
    EstimateDialogMemo,
  },
  props: {
    saveFunc: {
      type: Function as PropType<SaveFunc>,
      required: false,
    },
    isSaveButton: {
      type: Boolean,
      default: true,
    },
    estimateType: {
      type: Number as PropType<EstimateType | undefined>,
      required: false,
    },
    storedPageListData: {
      type: Array as PropType<Tab[]>,
      required: false,
    },
    storeTabListData: {
      type: Array as PropType<State['tabList']>,
      required: false,
    },
    singleItemStoredPageListData: {
      type: Array as PropType<SingleItemServiceTab[]>,
      required: false,
    },
    singleItemStoreTabListData: {
      type: Array as PropType<SingleItemServiceState['tabList']>,
      required: false,
    },
    routeUrl: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const store = useStore()
    const dialogTempSave = ref<InstanceType<typeof TheDialogTempSave> | null>()
    const dialogMemo = ref<InstanceType<typeof EstimateDialogMemo> | null>()

    /**
     * 仮保存処理の実装
     * 仮保存ボタンを押した時点でタブの中身はまだストアに保存されていないため、
     * タブ移動してストアに保存された後にfrontStoreFlg(一時保存APIを叩くためのフラグ)がtrueになる実装になっており、
     * そのフラグがtrueかどうか見て、実際に一時保存APIを叩いて保存を更新するための処理。
     * Notion仕様書: https://www.notion.so/arsagajp/4457ef50b63c4987ad84dc98a9daef7a
     */
    const { saveEstimateTemporarily } = useTemporarilySaved()
    const frontStoreInfo = computed<FrontStoreInfo>(
      () => store.getters['FrontStoreInfo/getAllState']
    )

    watch(
      () => [
        props.routeUrl,
        props.storeTabListData,
        props.storedPageListData,
        props.singleItemStoredPageListData,
        props.singleItemStoreTabListData,
      ],
      () => {
        if (frontStoreInfo.value.frontStoreFlg && props.estimateType) {
          const isTabMove = true
          saveEstimateTemporarily(props.estimateType, isTabMove)
        }
      }
    )

    const openTempSave = () => {
      if (props?.saveFunc) {
        props.saveFunc()
      }
      dialogTempSave.value?.openModal()
    }

    const openMemo = () => dialogMemo.value?.openModal()

    return { dialogTempSave, dialogMemo, openTempSave, openMemo }
  },
})
