import { ObiState } from '../../../../types'

const state = (): ObiState => ({
  /**
   * NOTE: selections = patternLine
   * ↓保持される選択肢のindex
   * [0]種別
   * [1]形態
   */
  selections: [0, 0],
  product: null,
  input: {
    productName: '',
    buyPrice: 0,
    rentalPrice: 0,
    rankUpRentalPrice: 0,
    rankUpBuyPrice: 0,
    premiumRentalPrice: 0,
    premiumBuyPrice: 0,
  },
  price: {
    buy: 0,
    rental: 0,
  },
  isFree: false,
  isValidate: null,
})

export default state
