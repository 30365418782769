








import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  data() {
    return {
      width: 24,
      height: 24,
    }
  },
})
