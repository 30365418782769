import { CustomerProfileAndReason } from '~/api/customerRepository'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { 卒業式見積一覧 } from '~/store/Estimate/GraduationCeremony/tab見積一覧/state'
import { AmountAdjustments } from '~/store/types'

export const useRestore見積一覧 = () => {
  const store = useGraduateStore<卒業式見積一覧>('tab見積一覧')

  const restore見積一覧 = (
    amountAdjustments: AmountAdjustments[],
    selectedPlan: number
  ) => {
    const amountAdjustmentValidation: boolean[][] = []
    if (amountAdjustments?.length) {
      amountAdjustments.forEach((a) => {
        const validations = [true]
        for (let i = 0; i < a.prices.length; i++) {
          validations.push(true)
        }
        amountAdjustmentValidation.push(validations)
      })
    }

    store.setAll({
      amount_adjustments: amountAdjustments,
      amount_adjustment_validation: amountAdjustmentValidation,
      selectedPlan,
      is_valid: false,
    })
  }
  return { restore見積一覧 }
}
