import { PhotoSettings } from '../../types'

const state = (): PhotoSettings => ({
  photoSetting: {
    id: 1,
    title: '小物写真',
    src: '',
  },
  wPhotoSetting: {
    id: 2,
    title: 'Wフォトプラン小物写真',
    src: '',
  },
  isValidate: null,
})

export default state
