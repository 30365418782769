import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { AxiosResponse, AxiosInstance } from 'axios'
import {
  CatalogFurisode,
  FurisodeParam,
  KimonoSearchQueryParams,
  KimonoSearchResult,
} from '~/composable/api/useSearchProduct'

/**
 * typeに入る値は着物金額と小物金額で違うので仕様書確認のこと
 * 振袖金額
 * https://docs.google.com/spreadsheets/d/1p0MxMw2VO6PmJCs4UzyM4rqfO6BqBypM/edit?pli=1#gid=1792570558z
 * 小物金額
 * https://docs.google.com/spreadsheets/d/1p0MxMw2VO6PmJCs4UzyM4rqfO6BqBypM/edit?pli=1#gid=588207140
 */
export type ProductPrice = {
  product_type: number // 品種ID
  price_informations: {
    type: number
    order_number: number // 表示順番
    prices: {
      price_type: number // 価格の種別
      price: string // 価格
    }[]
  }[]
}

export type Product = {
  items: {
    product_id: number
    name: string
    prices: {
      price_type: number // 価格の種別
      price: string // 価格
    }[]
    type?: number // 小物の種別
  }[]
}

export type SetOfProduct = {
  set_id: number
  prices: {
    price_type: number
    price: string
  }[]
  set_items: {
    product_id: number
    product_type_id: number
    name: string
  }[]
}

export type productRepository = {
  fetchKimonoPrices: (
    contentType: number
  ) => Promise<AxiosResponse<{ data: ProductPrice[] }>>
  fetchItemProductPrices: (
    contentType: number
  ) => Promise<AxiosResponse<{ data: ProductPrice[] }>>
  fetchProducts: (
    productTypeId: number
  ) => Promise<AxiosResponse<{ data: Product[] }>>
  fetchSetOfProducts: () => Promise<AxiosResponse<{ data: SetOfProduct[] }>>
  fetchCatalogFurisode: (
    params: FurisodeParam
  ) => Promise<AxiosResponse<CatalogFurisode>>
  fetchFinishedKimonoList: (
    params: KimonoSearchQueryParams
  ) => Promise<AxiosResponse<{ items: KimonoSearchResult[] }> | undefined>
}

const masterRepository = (
  client: NuxtAxiosInstance | AxiosInstance
): productRepository => {
  return {
    fetchKimonoPrices: (contentType: number) =>
      client.get(`kimono/price?content_type=${contentType}`),
    fetchItemProductPrices: (contentType: number) =>
      client.get(`products/price?content_type=${contentType}`),
    fetchProducts: (productTypeId: number) =>
      client.get(`products/items?product_type_id=${productTypeId}`),
    fetchSetOfProducts: () => client.get('set-products'),
    fetchCatalogFurisode: (params: FurisodeParam) =>
      client.get(
        `/products/furisode?catalog_code=${params.catalog_code}&use_date=${params?.use_date}&shop_id=${params?.shop_id}&is_change_contract=${params?.is_change_contract}`
      ),
    fetchFinishedKimonoList: (params: KimonoSearchQueryParams) =>
      client.get('products/kimono', { params }),
  }
}

export default masterRepository
