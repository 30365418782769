import { ref, computed } from '@nuxtjs/composition-api'
import { Enum袴 } from '~/constants/estimate/graduationOptions'
import { useEstimateState } from '~/composable/estimate/repository/graduation/EstimateState'
import { useUrlNavigator } from '~/composable/estimate/service/graduation/UrlNavigator'
import { usePrice } from '~/composable/general/Price'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'

export const use袴見積 = () => {
  const priceTable = useGraduateStore('').getPrices()
  const priceTable袴定額割引 = priceTable.estimate.袴定額割引
  const priceTable袴割引率 = priceTable.estimate.割引率.成人式ご利用割引袴
  const state = useEstimateState()
  const { getDiscounted, toString } = usePrice()
  const paths = useUrlNavigator()

  const 袴 = computed(() => {
    return {
      title: '袴',
      productTypeId: state.value.袴.品種区分,
      product: state.value.袴.flags.is袴保留
        ? '仮設定中'
        : state.value.袴.商品検索?.product_name,
      price: state.value.袴.レンタル価格,
      label: state.value.袴.ラベル,
      url: paths.tab袴,
    }
  })

  const 袴割引 = computed(() => {
    let discountedPrice: number | null = null
    let discountText = ''
    const is半額割引 = state.value.袴.袴割引 === Enum袴.袴割引.割引率
    const is固定額値引 = state.value.袴.袴割引 === Enum袴.袴割引.割引金額
    const price = state.value.袴.レンタル価格 || 0

    if (is半額割引) {
      discountedPrice = priceTable袴割引率
        ? getDiscounted(price, priceTable袴割引率)
        : null
      discountText = `${priceTable袴割引率}%引き`
    } else if (is固定額値引) {
      const discounted = price - (priceTable袴定額割引 ?? 0)
      discountedPrice =
        discounted >= 0 && priceTable袴定額割引 ? priceTable袴定額割引 : 0
      discountText = `${toString(priceTable袴定額割引)}引き`
    }

    return {
      title: `成人式ご利用袴割引 (${discountText})`,
      price: getDiscountedPrice(
        state.value.袴.袴割引,
        state.value.袴.レンタル価格,
        priceTable袴定額割引,
        priceTable袴割引率
      ),
      discount: !!discountedPrice,
      productTypeId: null,
      isInsertDailyReport: true,
    }
  })

  return { 袴, 袴割引 }
}

export const getDiscountedPrice = (
  id袴割引: number | null,
  price袴: number | null,
  priceTable袴定額割引: number | undefined,
  priceTable袴割引率: number | undefined
) => {
  const { getDiscounted } = usePrice()
  let discountedPrice: number | null = null
  const is半額割引 = id袴割引 === Enum袴.袴割引.割引率
  const is固定額値引 = id袴割引 === Enum袴.袴割引.割引金額
  const price = price袴 || 0

  if (is半額割引) {
    discountedPrice = priceTable袴割引率
      ? getDiscounted(price, priceTable袴割引率)
      : null
  } else if (is固定額値引) {
    const discounted = price - (priceTable袴定額割引 ?? 0)
    discountedPrice =
      discounted >= 0 && priceTable袴定額割引 ? priceTable袴定額割引 : 0
  } else {
    discountedPrice = null
  }
  return discountedPrice
}
