import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { ObiState } from '~/store/types'

export const useRestoreWPhoto帯 = () => {
  const storeお持込プラン = useComingStore<ObiState>(
    'WphotoPlan/WphotoObiBring'
  )
  const storeレンタル = useComingStore<ObiState>('WphotoPlan/WphotoObiRental')

  const restoreWPhoto帯 = (obi: Restate['w_photo_plan']['帯']) => {
    if (!obi) return
    /* 初期値を設定 ※必ず入力される値はここで設置 */
    const formValues: ObiState = {
      /**
       * NOTE: selections = patternLine
       * ↓保持される選択肢のindex
       * [0]種別
       * [1]形態 || 仕立上り帯か
       * [2]仕立上り帯か || 見積表示
       */
      selections: [obi.optionId種別, 0, 0],
      product: null,
      input: {
        productName: '',
        buyPrice: 0,
        rentalPrice: 0,
        rankUpRentalPrice: 0,
        rankUpBuyPrice: 0,
        premiumRentalPrice: 0,
        premiumBuyPrice: 0,
      },
      // NOTE: フリー入力された値はpricesではなくinputの方に格納される
      price: {
        buy: 0,
        rental: 0,
        id: 0, // フリー入力を選択
      },
      isFree: false,
      isValidate: null,
    }

    /* 以下で入力内容の条件に基づき、必要な項目を埋めていく */
    if (!obi.flags.is持込) {
      if (obi.flags.is振袖持込) {
        formValues.selections[1] = obi.optionId仕立て上りか
        formValues.selections[2] = obi.optionId見積選択
        formValues.input.rentalPrice = obi.レンタル価格
        formValues.input.buyPrice = obi.購入価格
      } else {
        formValues.selections[1] = obi.optionId形態
        formValues.selections[2] = obi.optionId仕立て上りか
      }

      if (obi.flags.is仕立て上り) {
        formValues.product = obi.仕立て上り検索結果
      } else {
        formValues.input.productName = obi.商品名
      }
    }

    if (obi.flags.isセット内) {
      formValues.price.rental = obi.レンタル価格
      formValues.input.rentalPrice = obi.レンタル価格
    }

    if (obi.flags.isランクアップPP内) {
      formValues.isFree = true
      formValues.input.rankUpRentalPrice = obi.レンタル価格
    }

    if (obi.flags.isランクアップPP外) {
      formValues.isFree = true
      formValues.input.rankUpRentalPrice = obi.レンタル価格
      formValues.input.premiumRentalPrice = obi.プレミアムレンタル価格
    }

    /**
     * NOTE: 振袖をレンタルした場合とお持ち込みの場合のstoreの場所が異なるため、入れ先をハンドリングしてます。
     * ストアに内容を保存する
     */
    if (obi.flags.is振袖持込) {
      storeお持込プラン.setOf<ObiState['selections']>(
        'setSelection',
        formValues.selections
      )
      storeお持込プラン.setOf<ObiState['product']>(
        'setProduct',
        formValues.product
      )
      storeお持込プラン.setOf<ObiState['input']>('setInput', formValues.input)
      storeお持込プラン.setOf<ObiState['price']>('setPrice', formValues.price)
      storeお持込プラン.setOf<ObiState['isFree']>(
        'setIsFree',
        formValues.isFree
      )
    } else {
      storeレンタル.setOf<ObiState['selections']>(
        'setSelection',
        formValues.selections
      )
      storeレンタル.setOf<ObiState['product']>('setProduct', formValues.product)
      storeレンタル.setOf<ObiState['input']>('setInput', formValues.input)
      storeレンタル.setOf<ObiState['price']>('setPrice', formValues.price)
      storeレンタル.setOf<ObiState['isFree']>('setIsFree', formValues.isFree)
    }
    storeお持込プラン.setIsValidate(false)
    storeレンタル.setIsValidate(false)
  }

  return { restoreWPhoto帯 }
}
