import { MutationTree } from 'vuex'
import { TabNaviState } from '~/store/Estimate/GraduationCeremony/types'
import { getInitialStore } from './state'

const mutations: MutationTree<TabNaviState> = {
  setState: (
    state,
    payload: { stateName: keyof TabNaviState; value: never }
  ) => {
    state[payload.stateName] = payload.value
  },

  initAllState: (state) => {
    const init = getInitialStore()
    state.isAllValidateTabs = init.isAllValidateTabs
    state.isBeforeConfirmation = init.isBeforeConfirmation
    state.isNextButton = init.isNextButton
    state.tabs = init.tabs
    state.validatedTabs = init.validatedTabs
  },
}

export default mutations
