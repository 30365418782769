import { MutationTree } from 'vuex'
import { WphotoState } from '../../../types'

const mutations: MutationTree<WphotoState> = {
  setSelection: (state, selection) => {
    state.selections = selection
  },

  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },

  setIsSubValidate: (state, isValidate) => {
    state.isSubValidate = isValidate
  },

  delete: (state) => {
    state.selections = [0]
    state.isValidate = null
    state.isSubValidate = null
  },
}

export default mutations
