import { Ref } from '@nuxtjs/composition-api'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { CustomerState, VisitMotive } from '~/store/types'
import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { EstimateDetail } from '~/api/estimateRepository'

export const useRestoreお客様検索 = () => {
  const store = useComingStore<CustomerState>('Customers')

  const restoreお客様検索 = (
    customerInfo: Restate['customer']['profile'],
    visitingReasons: EstimateDetail['visiting_reason'],
    visitMotives: Ref<VisitMotive[]>
  ) => {
    // 見積詳細から来店動機を抽出
    let pickUpVisitingReasons: VisitMotive[] = []
    if (!visitingReasons) {
      pickUpVisitingReasons = []
    } else if (!Array.isArray(visitingReasons)) {
      // TODO: API修正前のオブジェクトが来ていた際の対応用。リリース後は消してOK
      pickUpVisitingReasons = [visitingReasons]
    } else {
      pickUpVisitingReasons = visitingReasons.map((id) => {
        return (
          visitMotives.value.find((v) => v.id === id) ?? { id: null, name: '' }
        )
      })
    }

    store.setOf<CustomerState['customer']>('setCustomer', {
      customer_id: customerInfo.customer_id,
      last_name: customerInfo.last_name,
      first_name: customerInfo.first_name,
      last_name_kana: customerInfo.last_name_kana,
      first_name_kana: customerInfo.first_name_kana,
      birthdate: customerInfo.birthdate, //形式'1990-11-11'
      mobile_phone_number: customerInfo.mobile_phone_number,
      tomonokai_point: customerInfo.tomonokai_point,
      has_mypage: customerInfo.has_mypage,
    })
    store.setOf<CustomerState['isValidate']['number']>(
      'setIsValidateNumber',
      false
    )
    store.setOf<VisitMotive[]>('setVisitMotivese', pickUpVisitingReasons)
  }
  return { restoreお客様検索 }
}
