export class EstimatePhotoPlan {
  static readonly デザインアルバム = 1
  static readonly 六切り台紙あり = 2
  static readonly 六切り台紙なし = 3
  static readonly キャビネ台紙あり = 4
  static readonly キャビネ台紙なし = 5
  static readonly アクリルスタンド = 6

  static toWord = (val: number) => {
    switch (val) {
      case EstimatePhotoPlan.デザインアルバム:
        return 'デザインアルバム'
      case EstimatePhotoPlan.六切り台紙あり:
        return '六切り台紙あり'
      case EstimatePhotoPlan.六切り台紙なし:
        return '六切り台紙なし'
      case EstimatePhotoPlan.キャビネ台紙あり:
        return 'キャビネ台紙あり'
      case EstimatePhotoPlan.キャビネ台紙なし:
        return 'キャビネ台紙なし'
      case EstimatePhotoPlan.アクリルスタンド:
        return 'アクリルスタンド'
      default:
        return 'ー'
    }
  }
}
