import { cloneDeep } from 'lodash'

const initialState = {
  prices: {
    estimate: {
      裄補正: 11000,
      袖補正: 11000,
      帯仕立て代: 11000,
      別衿加工代: 5500,
      母娘プラン訪問着レンタル: 22000,
      Wフォト着付け代: 11000,
      足し布N: 99000,
      足し布B: 165000,
      従業員割: 10000,
      プレミアムパックレンタル: 99000,
      プレミアムパック購入: 110000,
      プレミアムパックWフォト: 99000,
      当日ヘアメイク着付け代Aコース: 55000,
      当日ヘアメイク着付け代Bコース: 16500,
      当日ヘアメイク着付け代フルセット: 0,
      前撮りヘアメイク着付け代: 0,
      Wフォトヘアメイク着付け代: 11000,
      JK前撮りヘアメイク着付け代: 0,
      JK前撮り振袖代: 0,
      写真アルバム代スタンダード5P: 55000,
      写真アルバム代プレミアム15P: 165000,
      写真アルバム代フルセット: 0,
      正絹長襦袢: 33000,
      高級手縫い: 77000,
      髪飾りフルセット割引: 5500,
      着付けなし割引: 10000,
      卒業式長期レンタル値引き: 5000,
      お写真プランデザインアルバムスタンダード: 55000,
      お写真プランデザインアルバムプレミアム: 165000,
      お写真プラン六つ切り台紙ありポーズ1: 11000,
      お写真プラン六つ切り台紙ありポーズ2以降: 5500,
      お写真プラン六つ切り台紙なし: 5500,
      お写真プランキャビネ台紙ありポーズ1: 5500,
      お写真プランキャビネ台紙ありポーズ2以降: 2750,
      お写真プランキャビネ台紙なし: 2750,
      お写真プランアクリルスタンドA5: 7700,
      お写真プランアクリルスタンドA4: 13200,
      お写真プランUSBデータ: 5500,
      袴定額割引: 11000,
      参列卒業式当日着付代: 11000,
      参列プレミアムパック: 99000,
      卒業式プレミアムパック: 33000,
      参列友の会割引振袖: 13200,
      割引率: {
        双子割: 10, // %
        妹様割: 10, // %
        振袖割引: 50, // %
        Wフォト振袖割引フルセット: 80, // %
        Wフォト振袖割引フルセット以外: 50, // %
        成人式ご利用割引振袖: 80, // %
        成人式ご利用割引袴: 50, // %
        初回利用割引: 50, // %
        参列友の会割引訪問着留袖モーニングレンタル: 30, // %
      },
    },
    contracts: {
      お送り代: 2200,
      延滞料: 1100,
      商品券: 3000,
    },
  },
}

export type State = typeof initialState

export const getInitialStore = (): State => {
  return cloneDeep(initialState)
}

const state = () => getInitialStore()

export default state
