import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { ObiState } from '~/store/types'

export const useRestoreWPhoto帯締め = () => {
  //* storeがwphoto振袖のプランによって別々のためプランごとに用意している */
  const storeお持込 = useComingStore<ObiState>('WphotoPlan/WphotoObijimeBring')
  const storeレンタル = useComingStore<ObiState>(
    'WphotoPlan/WphotoObijimeRental'
  )

  const restoreWPhoto帯締め = (
    obijime: Restate['w_photo_plan']['帯締め'] | undefined
  ) => {
    if (!obijime) return

    /* 初期値を設定 ※必ず入力される値はここで設置 */
    const formValues: ObiState = {
      /**
       * NOTE: selections = patternLine
       * ↓保持される選択肢のindex
       * [0]種別
       * [1]形態
       */
      selections: [obijime.optionId種別, 0],
      product: null,
      input: {
        productName: '',
        buyPrice: 0,
        rentalPrice: 0,
        rankUpRentalPrice: 0,
        rankUpBuyPrice: 0,
        premiumRentalPrice: 0,
        premiumBuyPrice: 0,
      },
      price: {
        buy: 0,
        rental: 0,
      },
      isFree: false,
      isValidate: null,
    }

    /* 以下で入力内容の条件に基づき、必要な項目を埋めていく */
    if (!obijime.flags.is持込) {
      formValues.selections[1] = obijime.optionId形態
      formValues.input.rentalPrice = obijime.レンタル価格
      formValues.input.buyPrice = obijime.購入価格
    }
    if (obijime.flags.is振袖持込) {
      formValues.selections[1] = obijime.optionId見積選択
    }

    if (obijime.flags.isセット内) {
      formValues.price.rental = obijime.レンタル価格
      formValues.input.rentalPrice = obijime.レンタル価格
    }

    if (obijime.flags.isランクアップPP内) {
      formValues.isFree = true
      formValues.input.rankUpRentalPrice = obijime.レンタル価格
    }

    if (obijime.flags.isランクアップPP外) {
      formValues.isFree = true
      formValues.input.rankUpRentalPrice = obijime.レンタル価格
      formValues.input.premiumRentalPrice = obijime.プレミアムレンタル価格
    }

    /* ストアに内容を保存する */
    if (obijime.flags.is振袖持込) {
      storeお持込.setOf<ObiState['selections']>(
        'setSelection',
        formValues.selections
      )
      storeお持込.setOf<ObiState['input']>('setInput', formValues.input)
      storeお持込.setOf<ObiState['price']>('setPrice', formValues.price)
      storeお持込.setOf<ObiState['isFree']>('setIsFree', formValues.isFree)
    } else {
      storeレンタル.setOf<ObiState['selections']>(
        'setSelection',
        formValues.selections
      )
      storeレンタル.setOf<ObiState['input']>('setInput', formValues.input)
      storeレンタル.setOf<ObiState['price']>('setPrice', formValues.price)
      storeレンタル.setOf<ObiState['isFree']>('setIsFree', formValues.isFree)
    }
    storeレンタル.setIsValidate(false)
    storeお持込.setIsValidate(false)
  }
  return { restoreWPhoto帯締め }
}
