import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { ErrorResponse } from '../utils/exception'
import { CreateCustodyPdfParams } from './custodyRepository'
import TanzakuPdfType from '~/constants/enums/tanzakuPdfType'

export type ApiResponse<T extends () => {}> = Awaited<ReturnType<T>>

export type PhotoInfo = { id: string; url: string }

export type ContractPdf = {
  multiple_contract_pdfs: {
    id: number
    contract_pdf_type: number
    pdf_url: string
  }[]
}

export type EstimatePdf = {
  id: number
  url: string
}

export type PdfResponse = EstimatePdf

export type CreatePdfParams = {
  id: number
  type: 1 | 2 | 3 //1: 見積書 2: 契約書 3: 領収書
  branch?: number
  isOnlyRecipt?: boolean
}

export type CreateAttendancePdfParams = {
  id: number
  type: 1 | 2 //1: 見積書 2: 契約書
}

export type CreateCleaningPdfParams = {
  id: number
  type: 2 // 2: 契約書
}

export type CreateSingleItemServicePdfParams = {
  id: number
  type: 1 | 2 //1: 見積書 2: 契約書
}

export type tanzakuPdfParams = {
  id: number // 契約書ID
  type: TanzakuPdfType // 1: 当日・前撮り 2: Wフォト 3: JK前撮り 4: 母娘プラン
  estimate_id?: number // 参列者 紐づく見積書ID（参列契約の場合に使用）
}

export type FileRepository = {
  /**
   * 画像のパスからバイナリーを取得する
   */
  getImage: (url: string) => Promise<string>
  /**
   * 画像のパスからバイナリーを取得する（cors回避のため、local環境で使用する）
   */
  getBinaryData: (url: string) => Promise<string>
  /**
   * @deprecated Ph1~ 削除予定なので使用しない
   * @returns {Promise<string>} PDF formatted to base64
   */
  getPdf: (url: string) => Promise<string>
  uploadPDF: (
    formData: FormData,
    config?: AxiosRequestConfig
  ) => Promise<AxiosResponse<{ id: number; url: string }[]>>
  /**
   * 見積書や契約書作成時にPDFを作成する用のエンドポイント
   */
  createPDF: <R>(
    params: CreatePdfParams
  ) => Promise<AxiosResponse<R | ErrorResponse>>
  createAttendancePDF: <R>(
    params: CreateAttendancePdfParams
  ) => Promise<AxiosResponse<R | ErrorResponse>>
  createCleaningPDF: <R>(
    params: CreateCleaningPdfParams
  ) => Promise<AxiosResponse<R | ErrorResponse>>
  createSingleItemServicePDF: <R>(
    params: CreateSingleItemServicePdfParams
  ) => Promise<AxiosResponse<R | ErrorResponse>>
  uploadPhoto: (formData: FormData) => Promise<AxiosResponse<PhotoInfo[]>>
  cancelFetch: () => void
  /**
   * @returns createObjectURLで生成したFileデータ
   */
  getFile: (url: string, params?: Record<string, unknown>) => Promise<string>
  getTanzaku: (param: tanzakuPdfParams) => Promise<string>
  generateCustodyPDF: (
    params: CreateCustodyPdfParams
  ) => Promise<AxiosResponse<{ id: number; url: string } | ErrorResponse>>
  generateReceiptPDF: (params: {
    id: number
    branch: number
    isOnlyRecipt: boolean
  }) => Promise<AxiosResponse<{ id: number; url: string } | ErrorResponse>>
}

export default (client: NuxtAxiosInstance | AxiosInstance): FileRepository => {
  const cancelToken = 'CancelToken' in client ? client.CancelToken : null
  const source = cancelToken ? cancelToken.source() : undefined
  const cancelTokenOption = {
    cancelToken: source?.token,
  }

  const getExtension = (path: string) => {
    const [ext] = path.split('.').reverse()
    return `image/${ext}`
  }

  const fetchBinaryData = async (
    url: string,
    params?: { remoteSrc: string }
  ) => {
    const type = getExtension(url)
    const res = await client.get(url, {
      params,
      headers: {
        'Content-Type': type,
      },
      responseType: 'arraybuffer',
      ...(cancelToken ? cancelTokenOption : {}),
    })

    const isClient = !!cancelToken
    if (!isClient) {
      return res.data
    }

    if (isClient) {
      const bolb = new Blob([res.data], { type })
      return URL.createObjectURL(bolb)
    }

    return ''
  }

  const cancelFetch = () => {
    source?.cancel()
  }

  return {
    cancelFetch,
    getImage: (url: string) => {
      if (!url) {
        throw new Error('binaryを取得するためのurlがありません')
      }
      return new Promise<string>((resolve, reject) => {
        fetchBinaryData(url).then(resolve).catch(reject)
      })
    },
    getBinaryData: (url: string) =>
      fetchBinaryData('/photo/getBinaryData', {
        remoteSrc: url,
      }),
    getPdf: async (url: string) => {
      if (client.defaults.baseURL?.includes('/proxy')) {
        const resp = await client.get('/pdf/getBinaryData', {
          params: { url },
        })

        return resp.data
      }

      const result = await client.get(url, {
        headers: {
          'Content-Type': 'application/pdf',
        },
        responseType: 'arraybuffer',
      })

      return Buffer.from(result.data, 'binary').toString('base64')
    },
    uploadPDF: (formData, config) =>
      client.post('pdf/upload', formData, config),
    createPDF: (params) => client.post('api/pdf', params),
    createAttendancePDF: (params) => client.post('api/attendance/pdf', params),
    createCleaningPDF: (params) => client.post('api/cleaning/pdf', params),
    createSingleItemServicePDF: (params) =>
      client.post('api/single-item/pdf', params),
    uploadPhoto: (formData) =>
      client.post<PhotoInfo[]>('photo/upload', formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }),
    getFile: async (url, params) => {
      if (client.defaults.baseURL?.includes('/proxy')) {
        // NOTE: Response-type base64
        const resp = await client.get('/pdf/getBinaryData', {
          params: { url },
        })

        const bin = atob(resp.data.replace(/^.*,/, ''))
        const buffer = new Uint8Array(bin.length)
        for (let i = 0; i < bin.length; i++) {
          buffer[i] = bin.charCodeAt(i)
        }
        // Blobを作成
        const blob = new Blob([buffer.buffer], {
          type: 'application/pdf',
        })
        return (window.URL || window.webkitURL).createObjectURL(blob)
      }

      const result = await client.get(url, {
        headers: {
          'Content-Type': 'application/pdf',
        },
        responseType: 'blob',
        params,
      })

      const blob = new Blob([result.data], {
        type: 'application/pdf',
      })
      return (window.URL || window.webkitURL).createObjectURL(blob)
    },
    getTanzaku: async (params) => {
      if (client.defaults.baseURL?.includes('/proxy')) {
        const resp = await client.get('pdf/strip', { params })
        const byteCharacters = atob(resp.data)
        const byteArrays = []
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
          const slice = byteCharacters.slice(offset, offset + 512)
          const byteNumbers = new Array(slice.length)
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
          }
          const byteArray = new Uint8Array(byteNumbers)
          byteArrays.push(byteArray)
        }
        const blob = new Blob(byteArrays, { type: 'application/pdf' })
        return (window.URL || window.webkitURL).createObjectURL(blob)
      }
      const result = await client.get('pdf/strip', {
        headers: {
          'Content-Type': 'application/pdf',
        },
        responseType: 'blob',
        params,
      })

      const blob = new Blob([result.data], {
        type: 'application/pdf',
      })

      return (window.URL || window.webkitURL).createObjectURL(blob)
    },
    generateCustodyPDF: (params) => client.post('api/custody_pdf', params),
    generateReceiptPDF: (params) =>
      client.post('api/pdf', {
        ...params,
        type: 3,
      }),
  }
}
