import { GetterTree } from 'vuex'
import { RootState, OnlySelectionPlanState } from '../../../types'

const getters: GetterTree<OnlySelectionPlanState, RootState> = {
  // NOTE: useComingStore用に追加
  getAllState: (state) => {
    return state
  },

  getSelections: (state: OnlySelectionPlanState) => {
    return state.selections
  },

  getIsValidate: (state: OnlySelectionPlanState) => {
    return state.isValidate
  },
}

export default getters
