import { GetterTree } from 'vuex'
import { RootState, MotherAndDaughterPlanState } from '../../types'

const getters: GetterTree<MotherAndDaughterPlanState, RootState> = {
  getPatternLine: (state: MotherAndDaughterPlanState) => {
    return state.patternLine
  },

  getIsClickByUsedPast: (state: MotherAndDaughterPlanState) => {
    return state.isClickByUsedPast
  },

  getProduct: (state: MotherAndDaughterPlanState) => {
    return state.product
  },

  getInput: (state: MotherAndDaughterPlanState) => {
    return state.input
  },

  getDate: (state: MotherAndDaughterPlanState) => {
    return state.date
  },

  getIsInputFinish: (state: MotherAndDaughterPlanState) => {
    return state.isInputFinish
  },

  getPhoto: (state: MotherAndDaughterPlanState) => {
    return state.settings[0].photos
  },

  getJkMotherAndDaughterPlan: (state: MotherAndDaughterPlanState) => {
    return state.jkMotherAndDaughterPlan
  },

  getJkProduct: (state: MotherAndDaughterPlanState) => {
    return state.jkMotherAndDaughterPlan.product
  },

  getTabList: (state: MotherAndDaughterPlanState) => {
    return state.motherAndDaughterTabList
  },

  getIsValidate: (state: MotherAndDaughterPlanState) => {
    return state.isValidate
  },
}

export default getters
