import { computed, Ref, watch } from '@nuxtjs/composition-api'
import { EstimateDetail, Hurisode } from '~/api/estimateRepository'
import {
  CatalogFurisode,
  KimonoSearchQueryParams,
  useSearchProduct,
} from '~/composable/api/useSearchProduct'
import { ProductCategory } from '~/constants/enums/productCategory'
import { FURISODE_PLAN } from '~/constants/estimate/comingOfAge'
import { useRestatePlan } from './RestatePlan'
import { usePrice } from '~/composable/general/Price'
import { IS_CHANGE_CONTRACT } from '~/constants/contract'

export const useRestateHurisode = (
  estimate: Ref<EstimateDetail | undefined>
) => {
  const { fetchProducts, catalogFurisode, finishedKimonoList } =
    useSearchedProducts()
  const restateプラン = useRestatePlan(estimate)

  // NOTE: computed内で取得処理をしようとすると無限ループが起きる
  watch(estimate, () => {
    if (!estimate.value?.hurisode) return
    fetchProducts(
      estimate.value.hurisode,
      estimate.value.shop_id,
      estimate.value.use_date
    )
  })

  return computed(() => {
    if (!estimate.value?.hurisode || !restateプラン.value) {
      return null
    }
    const { hurisode } = estimate.value
    const { is写のみレンタル } = restateプラン.value.flags
    const flags = getConditionalFlags(hurisode, is写のみレンタル)

    const {
      category,
      product_type_id,
      rental_price,
      purchase_price,
      product_name,
    } = hurisode

    return {
      optionIdOf商品指定方法選択: flags.isプロパー商品
        ? FURISODE_PLAN.PROPER
        : FURISODE_PLAN.SEARCH,
      optionId裄補正: getOptionIdOf補正s(hurisode).裄補正,
      optionId袖補正: getOptionIdOf補正s(hurisode).袖補正,
      optionIdどちらのレンタル: getOptionIdOfどちらのレンタル(category),
      商品分類: product_type_id,
      商品名: product_name,
      仕立て上り検索結果: finishedKimonoList.value,
      カタログ検索結果: getFormattedカタログ検索結果(hurisode, catalogFurisode),
      レンタル価格: Number(rental_price),
      購入価格: Number(purchase_price),
      プロパー商品ID: flags.isプロパー商品 ? hurisode.product_id : null,
      flags,
    }
  })
}

/**
 * 復元条件に関わる条件式を定義する
 */
const getConditionalFlags = (hurisode: Hurisode, is写のみレンタル: boolean) => {
  const { category, is_order_outside_target_year } = hurisode

  const selected仕立上がり = [
    ProductCategory.tailoredPurchase,
    ProductCategory.tailoredRental,
  ].includes(category)

  const selectedカタログ = [
    ProductCategory.customizeRental,
    ProductCategory.catalogPurchase,
    ProductCategory.orderRental,
  ].includes(category)

  const isプロパー商品 = [
    ProductCategory.properPurchase,
    ProductCategory.properRental,
  ].includes(category)
  const isお誂えレンタル = hurisode.category === ProductCategory.customizeRental
  const isカタログ購入 = hurisode.category === ProductCategory.catalogPurchase
  const is仕立て上り検索結果 = selected仕立上がり && hurisode.product_id
  const isカタログ検索結果 =
    selectedカタログ || (selected仕立上がり && !hurisode.product_id)

  return {
    is仕立て上り検索結果,
    isカタログ検索結果,
    isプロパー商品,
    isお誂えレンタル,
    isカタログ購入,
    // ↓見積一覧ページで使用
    selected手縫い: hurisode.sewing ?? 0,
    is対象年度外オーダーレンタル: is_order_outside_target_year,
  }
}

/**
 * 選択肢系の項目の初期値(選択されたoptionのid)を再計算する関数群
 */
const getOptionIdOf補正s = (hurisode: Hurisode) => {
  return {
    裄補正: hurisode.is_correct_for_yukitake
      ? FURISODE_PLAN.YUKI_CORRECTION
      : FURISODE_PLAN.NOT_YUKI_CORRECTION,
    袖補正: hurisode.is_correct_for_sodetake
      ? FURISODE_PLAN.SODE_CORRECTION
      : FURISODE_PLAN.NOT_SODE_CORRECTION,
  }
}

const getOptionIdOfどちらのレンタル = (category: Hurisode['category']) => {
  switch (category) {
    case ProductCategory.orderRental:
      return FURISODE_PLAN.ORDER_RENTAL
    case ProductCategory.customizeRental:
      return FURISODE_PLAN.CUSTOM_RENTAL
    /**
     * NOTE & TODO: 振袖購入プランを選択して見積作成した場合は、
     * 作成時のレンタルの選択情報が保持されないため、
     * 確認して一旦「購入金額だけ復元（＝検索結果に上書き）して、この選択肢は"お誂えレンタル"を自動選択」
     * という仕様になった。
     * 選ばなかったレンタルも復元が必要になった場合はパラメータとそれに伴う実装を行う必要あり。
     */
    case ProductCategory.catalogPurchase:
    case ProductCategory.未確定:
      return FURISODE_PLAN.CUSTOM_RENTAL
    /**
     * NOTE: カタログ検索結果で仕立て上がりレンタルを選んだ場合
     */
    case ProductCategory.tailoredRental:
      return FURISODE_PLAN.SHITATE_RENTAL
    default:
      return 0
  }
}

/**
 * 振袖と二尺袖の商品検索結果を取得する
 * NOTE: ひとまずパラメータにある値から仕立て上りとカタログ検索両方叩いて、
 * どちらを使うかはrestorer側で判定する。
 */
const useSearchedProducts = () => {
  const {
    fetchCatalogFurisode,
    catalogFurisode,
    fetchFinishedKimonoList,
    finishedKimonoList,
  } = useSearchProduct()

  const fetchProducts = (
    hurisode: Hurisode,
    shopId?: number,
    useDate?: string
  ) => {
    if (hurisode.catalog_code) {
      const params = {
        catalog_code: hurisode.catalog_code,
        use_date: useDate,
        shop_id: shopId,
        is_change_contract: IS_CHANGE_CONTRACT.契約変更,
      } as const
      fetchCatalogFurisode(params)
    }

    if (hurisode.product_id) {
      const params: KimonoSearchQueryParams = {
        keyword: hurisode.product_id,
        keyword_types: [0],
        product_type_id: hurisode.product_type_id as 1,
        has_purchased: 1,
      }
      fetchFinishedKimonoList(params)
    }
  }

  return { fetchProducts, catalogFurisode, finishedKimonoList }
}

const getFormattedカタログ検索結果 = (
  hurisode: Hurisode,
  catalogFurisode: Ref<CatalogFurisode | null>
) => {
  const { toString } = usePrice()
  if (!catalogFurisode.value) {
    return null
  }
  const 購入価格 = toString(Number(hurisode.purchase_price))
  const レンタル価格 = toString(Number(hurisode.rental_price))

  /*
   * NOTE: カタログ商品のproduct_idはカタログ商品の検索結果に載っているものではなく、
   * 契約時に仕立て上がりとして発番されたidを受渡する必要があるため、
   * 復元時には見積詳細に載っている方のidで上書きする。(未契約の見積詳細にはnullが返ってくる)
   */
  catalogFurisode.value.product_id = hurisode.product_id

  /**
   * NOTE & TODO: 振袖購入プランを選択して見積作成した場合は、
   * 作成時のレンタルの選択情報が保持されないため、
   * 確認して一旦「購入金額だけ復元（＝検索結果に上書き）して、選択肢は"お誂えレンタル"を自動選択」
   * という仕様になった。
   * 選ばなかったレンタルも復元が必要になった場合はパラメータとそれに伴う実装を行う必要あり。
   */
  catalogFurisode.value.sale_catalog_price = 購入価格

  // お誂えレンタル以外のカタログレンタルは店頭見本で仕立て上り済みで商品番号発行済みのため
  // 仕立て上がりとして復元する=ここにはお誂えレンタル以外は入ってこない想定
  catalogFurisode.value.rental_custom_order_price = レンタル価格
  return catalogFurisode.value
}
