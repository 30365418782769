import { render, staticRenderFns } from "./AppMenu.vue?vue&type=template&id=6f6a20ad&"
import script from "./AppMenu.vue?vue&type=script&lang=ts&"
export * from "./AppMenu.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppIcon: require('/home/ar-admin/development/weddingbox-client/src/components/AppIcon.vue').default,TestEnvironmentNotice: require('/home/ar-admin/development/weddingbox-client/src/components/TestEnvironmentNotice.vue').default,MenuUserInfo: require('/home/ar-admin/development/weddingbox-client/src/components/MenuUserInfo.vue').default,TheDialogTempSave: require('/home/ar-admin/development/weddingbox-client/src/components/TheDialogTempSave.vue').default})
