import { ESTIMATE } from '~/constants/estimate/config'
import { ref, useContext, useStore } from '@nuxtjs/composition-api'
import frontStoreRepository, {
  ContractTypeId,
  DocumentTypeId,
  FrontStoreContractState,
  FrontStoreEstimateState,
} from '~/api/frontStoreRepository'
import {
  TemporarilySavedItemByContract,
  TemporarilySavedItemByEstimate,
  useSaveListFormatter,
} from '~/composable/estimate/service/FrontstoreFormatter/saveList'
import frontStoreService, {
  FrontState,
} from '~/composable/general/FrontStoreService'
import { settingShop } from '~/store/types'
import { ContractType } from '~/constants/enums/contractType'

export type EstimateType = Exclude<ContractTypeId, typeof ContractType.cleaning>

type SaveList = (
  | TemporarilySavedItemByContract
  | TemporarilySavedItemByEstimate
)[]

/**
 * 仮保存関係の処理を行うcomposition関数
 */
export const useTemporarilySaved = () => {
  const store = useStore<FrontState>()
  const settingShopName: settingShop['shop'] =
    store.getters['SettingShop/getShop']

  const { $axios } = useContext()
  const repo = frontStoreRepository($axios)
  const service = frontStoreService(repo)

  const { getFormattedSaveListByEstimate, getFormattedSaveListByContract } =
    useSaveListFormatter()
  const data = ref<SaveList>([])
  const loading = ref(false)
  const errMsg = ref('')

  /**
   * 見積書保存
   * NOTE：見積変更時の一次保存は有効？？？
   * @param typeId 1: 成人式, 2: 卒業式, 3: 参列, 7: 単品
   * @param isTopPageOrTabAccess トップページまたはタブ移動による処理か否か
   */
  const saveEstimateTemporarily = async (
    typeId: EstimateType,
    isTopPageOrTabAccess?: boolean
  ) => {
    errMsg.value = ''
    loading.value = true

    const priceTableStore = store.getters['PriceTable/getPrices']

    const { ComingOfAgeCeremony, GraduationCeremony, Attendance } =
      store.state.Estimate
    const SingleItemService = store.state.SingleItemService
    // NOTE: 成人式や卒業式以外でも仮保存が必要な場合は以下を変更する必要あり
    //       見積復元時に成人式か卒業式か参列を判定するために片方のみ保存する
    const target = () => {
      switch (typeId) {
        case ESTIMATE.成人式.id:
          return { ComingOfAgeCeremony }
        case ESTIMATE.卒業式.id:
          return { GraduationCeremony }
        case ESTIMATE.参列.id:
          return { Attendance }
        case ESTIMATE.単品.id:
          return { SingleItemService }
      }
    }

    const result = await service.saveEstimateTemporarily(
      typeId,
      {
        Estimate: target(),
        PriceTable: priceTableStore,
      } as FrontStoreEstimateState,
      settingShopName?.id ?? 1
    )

    loading.value = false

    if (result instanceof Error) {
      errMsg.value = result.message
      return
    }

    if (isTopPageOrTabAccess) {
      store.commit(`FrontStoreInfo/setFrontStoreFlg`, false)
      return result
    }

    store.commit(`FrontStoreInfo/setEstimateId`, result)
    store.commit(`FrontStoreInfo/setFrontStoreType`, typeId)
    store.commit(`FrontStoreInfo/setFrontStoreFlg`, true)
    return result
  }

  /**
   * 見積書保存 + エラー時のアラートを出す関数
   * @param typeId 1: 成人式, 2: 卒業式, 3: 参列, 7: 単品
   */
  const saveEstimateTemporarilyWithAlert = async (
    typeId: EstimateType,
    postType: '作成' | '更新',
    message?: string
  ) => {
    const resultId = await saveEstimateTemporarily(typeId)
    const alertMessage = message ?? `見積書${postType}に失敗しました。`
    alert(
      `${alertMessage}${
        resultId
          ? `\n仮保存ID: ${resultId}\n恐れ入りますが、本画面のスクリーンショットとエラー内容を不具合・要望シートにご記入ください。`
          : ''
      }`
    )
  }

  /**
   * 契約書保存
   * @param typeId 1: 成人式, 2: 卒業, 3: 参列, 7:単品, 10: クリーニング
   * @param isTopPageOrTabAccess トップページまたはタブ移動による処理か否か
   *
   */
  const saveContractTemporarily = async (
    typeId: ContractTypeId,
    isTopPageOrTabAccess?: boolean
  ) => {
    errMsg.value = ''
    loading.value = true

    const priceTableStore = store.getters['PriceTable/getPrices']

    const result = await service.saveContractTemporarily(
      typeId,
      {
        ...store.state.Contracts,
        SingleItemService: store.state.SingleItemService,
        PriceTable: priceTableStore,
      } as FrontStoreContractState,
      settingShopName?.id ?? 1
    )

    loading.value = false

    if (result instanceof Error) {
      errMsg.value = result.message
      return
    }

    if (isTopPageOrTabAccess) {
      store.commit(`FrontStoreInfo/setFrontStoreFlg`, false)
      return result
    }

    store.commit(`FrontStoreInfo/setContractId`, result)
    store.commit(`FrontStoreInfo/setFrontStoreType`, typeId)
    store.commit(`FrontStoreInfo/setFrontStoreFlg`, true)
    return result
  }

  /**
   * 契約書保存 + エラー時のアラートを出す関数
   * @param typeId 1: 成人式, 2: 卒業式, 3: 参列, 7:単品, 10: クリーニング
   */
  const saveContractTemporarilyWithAlert = async (
    typeId: ContractTypeId,
    postType: '作成' | '更新',
    contractType: '領収書' | '契約書' = '契約書',
    message: string = ''
  ) => {
    const resultId = await saveContractTemporarily(typeId)
    const alertMessage = message ?? `${contractType}${postType}に失敗しました。`
    alert(
      `${alertMessage}${
        resultId
          ? `\n仮保存ID: ${resultId}\n恐れ入りますが、本画面のスクリーンショットとエラー内容を不具合・要望シートにご記入ください。`
          : ''
      }`
    )
  }

  /**
   * 仮保存一覧の取得
   * @param filter 取得する契約タイプの配列
   * @param documentType 契約書か見積書かのid
   */
  const fetchSaveList = async (
    filter: ContractTypeId[],
    documentType: DocumentTypeId
  ) => {
    if (!settingShopName?.id) {
      return
    }

    loading.value = true
    try {
      const response = await repo.getAll({
        contractTypes: filter,
        documentType,
        shopId: settingShopName.id,
      })

      if (documentType === 1) {
        data.value = getFormattedSaveListByEstimate(response)
      }

      if (documentType === 2) {
        data.value = getFormattedSaveListByContract(response)
      }
    } catch (error) {
      if (error instanceof Error || $axios.isAxiosError(error)) {
        errMsg.value = error.message
      }

      console.error(error)
    } finally {
      loading.value = false
    }
  }

  /**
   * 仮保存一覧の取得
   * @param id 取得する契約タイプの配列
   * @param documentType 契約書か見積書かのid
   */
  const deleteItem = async (id: number, documentType: DocumentTypeId) => {
    loading.value = false
    try {
      await repo.delete(id, documentType)
      data.value = data.value.filter((item) => item.id !== id)
    } catch (error) {
      if (error instanceof Error || $axios.isAxiosError(error)) {
        errMsg.value = error.message
      }

      console.error(error)
    } finally {
      loading.value = false
    }
  }

  const clearList = () => {
    data.value = []
  }

  return {
    loading,
    errMsg,
    data,
    saveEstimateTemporarily,
    saveEstimateTemporarilyWithAlert,
    saveContractTemporarily,
    saveContractTemporarilyWithAlert,
    fetchSaveList,
    deleteItem,
    clearList,
  }
}
