import { computed } from '@nuxtjs/composition-api'
import { usePrice } from '~/composable/general/Price'
import {
  Enum割引,
  Enum試着写真,
  Enumプラン,
  Enum髪飾り,
  卒業式長期レンタル値引適用開始日,
} from '~/constants/estimate/graduationOptions'
import { FormValuesお名前 } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsお名前'
import { FormValuesお客様検索 } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsお客様検索'
import { FormValuesプラン } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsプラン'
import { FormValues割引 } from '~/composable/estimate/viewModel/graduation/Fields/Fields割引'
import { FormValues商品設定 } from '~/composable/estimate/viewModel/graduation/Fields/Fields商品設定'
import { FormValues試着写真 } from '~/composable/estimate/viewModel/graduation/Fields/Fields試着写真'
import { FormValues髪飾り } from '~/composable/estimate/viewModel/graduation/Fields/Fields髪飾り'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { EstimateSinglePriceType } from '~/constants/enums/estimateSinglePriceType'
import { usePlanFlags } from '~/composable/estimate/domain/Graduation/PlanFlags'
import { EstimatePlan } from '~/constants/enums/estimatePlan'
import { 卒業式見積一覧 } from '~/store/Estimate/GraduationCeremony/tab見積一覧/state'

export const useState割引 = () => {
  const store割引 = useGraduateStore<FormValues割引>('tab割引')
  const values割引 = store割引.getAll()
  const { toNumber } = usePrice()

  return computed(() => {
    const 割引あり = values割引.value.waribiki適用 === Enum割引.適用.する
    return {
      割引あり,
      金額: 割引あり ? toNumber(values割引.value.waribiki金額入力 || '¥0') : 0,
    }
  })
}

export const useStateお客様検索 = () => {
  const storeお客様検索 =
    useGraduateStore<FormValuesお客様検索>('tabお客様検索')
  const customerInfo = storeお客様検索.get<'okyakusama検索'>('okyakusama検索')
  const visitingReasonInfo =
    storeお客様検索.get<'okyakusama来店動機'>('okyakusama来店動機')

  return computed(() => {
    if (!customerInfo.value) return null
    const { first_name, last_name } = customerInfo.value
    const visitingReasons = visitingReasonInfo.value.map((v) => v.id)
    return {
      ...customerInfo.value,
      fullName: `${last_name} ${first_name}`,
      visiting_reasonsIds: visitingReasons,
      visiting_reasons: visitingReasonInfo.value,
    }
  })
}

export const useStateお名前 = () => {
  const storeお名前 = useGraduateStore<FormValuesお名前>('tabお名前')
  const storeお客様検索 = useStateお客様検索()

  return computed(() => {
    return (
      storeお客様検索.value?.fullName ||
      storeお名前.get<'onamaeお名前'>('onamaeお名前').value ||
      ''
    )
  })
}

export const useState見積一覧 = () => {
  const store見積一覧 = useGraduateStore<卒業式見積一覧>('tab見積一覧').getAll()
  return computed((): 卒業式見積一覧 => store見積一覧.value)
}

export const useState試着写真 = () => {
  const store試着写真 = useGraduateStore<FormValues試着写真>('tab試着写真')
  const values試着写真 = store試着写真.getAll()

  return computed(() => {
    return {
      アップロードあり:
        values試着写真.value.shichakuアップロード ===
        Enum試着写真.アップロード.する,
      全体写真: values試着写真.value.shichaku全体写真,
      衿元写真: values試着写真.value.shichaku衿元写真,
    }
  })
}

export const useStateプラン = () => {
  const storeプラン = useGraduateStore<FormValuesプラン>('tabプラン')
  const valuesプラン = storeプラン.getAll()
  const planFlags = usePlanFlags()

  const 見積プラン = computed(() => {
    if (planFlags.isHakama.value) {
      return EstimatePlan.hakamaSingleRental
    } else if (planFlags.isNishakusode.value) {
      return EstimatePlan.twoShakuSleeveSingleRental
    } else if (planFlags.isFurisode.value) {
      return EstimatePlan.furisodeRental
    } else if (planFlags.isKimonoHakama.value) {
      return EstimatePlan.kimonoHakamaRental
    }
    return 0
  })

  return computed(() => {
    const 着物種類 =
      valuesプラン.value.plan着物種類 === Enumプラン.着物を選択.振袖
        ? '振袖'
        : '二尺袖'

    const 着物種別 =
      valuesプラン.value.plan着物種別 === Enumプラン.着物種別.レンタル
        ? 'レンタル'
        : valuesプラン.value.plan着物種別 === Enumプラン.着物種別.お持込
        ? 'お持込（その他）'
        : 'お持込（ふりそでMODE購入品・姉販）'

    const 袴種別 =
      valuesプラン.value.plan袴種別 === Enumプラン.袴種別.レンタル
        ? 'レンタル'
        : 'お持込'

    const 卒業式長期レンタル値引き =
      new Date() >= new Date(卒業式長期レンタル値引適用開始日) &&
      valuesプラン.value.plan着付け区分 === Enumプラン.着付け区分.着付けなし &&
      valuesプラン.value.plan着物種別 === Enumプラン.着物種別.レンタル &&
      valuesプラン.value.plan袴種別 === Enumプラン.袴種別.レンタル

    const is振袖レンタル =
      valuesプラン.value.plan着物種類 === Enumプラン.着物を選択.振袖 &&
      valuesプラン.value.plan着物種別 === Enumプラン.着物種別.レンタル

    const is袴レンタル =
      valuesプラン.value.plan袴種別 === Enumプラン.袴種別.レンタル

    const isDisplay友の会 = is振袖レンタル && is袴レンタル

    const has弊社成人式 =
      valuesプラン.value.plan弊社成人式 === Enumプラン.弊社成人式.はい

    const isBringInDiscount =
      valuesプラン.value.plan着物種別 === Enumプラン.着物種別.お持込 ||
      valuesプラン.value.plan着物種別 === Enumプラン.着物種別.姉販持込
    // NOTE: 振袖・二尺袖・袴のstateは各々のstateの方で担当する
    return {
      サービス区分: valuesプラン.value.plan着付け区分, // 1:弊社着付け, 4:着付けなし
      卒業式使用日: valuesプラン.value.plan卒業式使用日,
      見積プラン: 見積プラン.value,
      友の会: valuesプラン.value.plan友の会会員 === Enumプラン.友の会会員.はい,
      成人式契約: has弊社成人式 ? valuesプラン.value.plan成人式契約書 : null,
      袴種別,
      着物種類,
      着物種別,
      卒業式長期レンタル値引き,
      flags: {
        is弊社着付け:
          valuesプラン.value.plan着付け区分 ===
          Enumプラン.着付け区分.弊社着付け,
        has弊社成人式,
        is友の会:
          valuesプラン.value.plan友の会会員 === Enumプラン.友の会会員.はい,
        isDisplay友の会,
        isBringInDiscount,
      },
    }
  })
}

export const useState商品設定 = () => {
  const store商品設定 = useGraduateStore<FormValues商品設定>('tab商品設定')
  const values商品設定 = store商品設定.getAll()

  return computed(() => {
    return {
      重ね衿: values商品設定.value.shohinSettei重ね衿,
      髪飾り: values商品設定.value.shohinSettei髪飾り,
    }
  })
}

export const useState髪飾り = () => {
  const state商品設定 = useState商品設定()
  const { toNumber } = usePrice()
  const store髪飾り1 = useGraduateStore<FormValues髪飾り>('tab髪飾り1')
  const store髪飾り2 = useGraduateStore<FormValues髪飾り>('tab髪飾り2')
  const store髪飾り3 = useGraduateStore<FormValues髪飾り>('tab髪飾り3')
  const values髪飾り1 = store髪飾り1.getAll()
  const values髪飾り2 = store髪飾り2.getAll()
  const values髪飾り3 = store髪飾り3.getAll()
  const allValues = [values髪飾り1, values髪飾り2, values髪飾り3]

  return computed(() => {
    const number = state商品設定.value.髪飾り
    const selectedValues = allValues.slice(0, number)
    return selectedValues.map((values) => {
      const お持込 = values.value.kamikazari種別 === Enum髪飾り.種別.お持込
      const 購入価格 = values.value.kamikazari購入価格
        ? toNumber(values.value.kamikazari購入価格)
        : null
      return {
        品種区分: 25,
        小物プラン: values.value.kamikazari種別,
        購入価格: お持込 ? null : 購入価格,
        priceType: お持込
          ? undefined
          : EstimateSinglePriceType.singlePurchasePrice,
      }
    })
  })
}
