import { ActionTree } from 'vuex'
import { RootState, OnlyInputPlanState } from '../../../types'

const actions: ActionTree<OnlyInputPlanState, RootState> = {
  setSelection: ({ commit }, selection) => {
    commit('setSelection', selection)
  },

  setInput: ({ commit }, input) => {
    commit('setInput', input)
  },

  setIsValidate: ({ commit }, isValidate: boolean) => {
    commit('setIsValidate', isValidate)
  },

  delete: ({ commit }) => {
    commit('delete')
  },
}

export default actions
