import { GetterTree } from 'vuex'
import { RootState, ProductState } from '../../../types'

const getters: GetterTree<ProductState, RootState> = {
  // NOTE: useComingStore用に追加
  getAllState: (state) => {
    return state
  },

  size: (state: ProductState) => {
    return state.products.length
  },

  products: (state: ProductState) => {
    return state.products
  },

  getIsValidate: (state: ProductState) => {
    return state.isValidate
  },
}

export default getters
