import { Module } from 'vuex'
import { RootState, FurisodeState } from '../../../types'
import state from './state'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

export const Furisodes: Module<FurisodeState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
