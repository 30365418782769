import { GetterTree } from 'vuex'
import { RootState } from '~/store/Estimate/GraduationCeremony/types'

// ** 型 **
import type { State, ProductNameList } from './state'

const getters: GetterTree<State, RootState> = {
  getSingleItems: (state) => (productName: ProductNameList) => {
    return state[productName]
  },
}
export default getters
