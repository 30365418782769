import { GetterTree } from 'vuex'
import { FormValues振袖 } from '~/composable/estimate/viewModel/graduation/Fields/Fields振袖'
import { RootState } from '~/store/Estimate/GraduationCeremony/types'

const getters: GetterTree<{ fields: FormValues振袖 }, RootState> = {
  getState: (state) => (stateName: keyof FormValues振袖) => {
    return state.fields[stateName]
  },

  getAllState: (state) => {
    return state.fields
  },
}
export default getters
