









import {
  computed,
  defineComponent,
  useRoute,
  watch,
  ref,
  useRouter,
} from '@nuxtjs/composition-api'
import EstimateResultFooter, {
  BackButton,
  NextButton,
} from '~/components/graduationCeremony/molecules/EstimateResultFooter.vue'
import { urls } from '~/constants/urls'
import { usePageFlags } from '~/composable/estimate/domain/PageFlags'
import { useEstimateTotalPrice } from '~/composable/estimate/service/graduation/EstimatePrice'
import { useGraduateEstimateRequest } from '~/composable/estimate/service/graduation/EstimateRequest'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { useUrlNavigator } from '~/composable/estimate/service/graduation/UrlNavigator'
import { useState袴 } from '~/composable/estimate/repository/graduation/states/State袴'
import { MemoStateGraduate } from '~/store/types'
import { useContractExtends } from '~/composable/estimate/service/graduation/ContractExtends'
import { useTemporarilySaved } from '~/composable/api/useTemporarilySaved'
import { ESTIMATE } from '~/constants/estimate/config'
import { 卒業式見積一覧 } from '~/store/Estimate/GraduationCeremony/tab見積一覧/state'
import { COORDINATE_PLAN } from '~/constants/estimate/config'

/** enums */
import { Enum袴 } from '~/constants/estimate/graduationOptions'

export default defineComponent({
  components: {
    EstimateResultFooter,
  },
  setup() {
    const route = useRoute()
    const storeMemo = useGraduateStore<MemoStateGraduate>('memo')
    const { postGraduateEstimate, createEstimateReqBody } =
      useGraduateEstimateRequest()
    const { total: totalPrice, totalWithPP } = useEstimateTotalPrice()
    const { setContractExtendsEstimateStore } = useContractExtends()
    const router = useRouter()
    const paths = useUrlNavigator()
    const urls卒業式 = urls.見積書.卒業式
    const store見積一覧 = useGraduateStore<卒業式見積一覧>('tab見積一覧')
    const store見積一覧AllData = store見積一覧.getAll()
    const state袴 = useState袴()
    const { estimate_id } = route.value.params
    const nextButtonDisabled = ref(false)
    watch(store見積一覧AllData, () => {
      nextButtonDisabled.value = store見積一覧AllData.value.is_valid
    })

    if (!estimate_id) {
      throw new Error('estimate_idが存在しません in EstimateResultFooter')
    }
    const { inConfirmationPage, inEstimateListPage } = usePageFlags()

    const { saveEstimateTemporarilyWithAlert } = useTemporarilySaved()

    const createEstimate = async () => {
      try {
        let errorText = ''

        /** PP適用有無の選択がない場合 */
        if (!store見積一覧AllData.value.selectedPlan) {
          errorText =
            '各見積金額の下にある「こちらを選ぶ」をタップして、契約書を作成したい見積を選んでください'
          alert(errorText)
          return false
        }

        const estimateReqBody = createEstimateReqBody()

        const is袴割引を適用 =
          state袴.value.袴割引 === Enum袴.袴割引.割引率 ||
          state袴.value.袴割引 === Enum袴.袴割引.割引金額

        /**
         * 成人式利用と袴割引を選択している関わらず、契約書IDがない場合
         * planタブに戻し成人式 契約書を再度設定する
         * 契約書側のフローの時は表示しない。（branch番号がqueryに入る）
         * */
        if (
          !estimateReqBody?.coming_of_age_ceremony_contract_id &&
          is袴割引を適用 &&
          !router.currentRoute.query.branch
        ) {
          errorText =
            '成人式をご利用いただいているお客様情報に問題が発生いたしました。\nプランタブにて成人式をご利用いただいているお客様を再選択してください。'
          alert(errorText)
          router.push(paths.tabプラン)
          return false
        }

        const res = await postGraduateEstimate()
        if (typeof res === 'boolean') return res

        // NOTE: 見積した内容を契約側で使うための見積復元ストアにセット
        setContractExtendsEstimateStore()

        return true
      } catch (error: any) {
        saveEstimateTemporarilyWithAlert(
          ESTIMATE.卒業式.id,
          '作成',
          error.message
        )
        // NOTE: エラーがあった時はisSuccess = falseを返してルーティングを中止する
        return false
      }
    }

    /**
     * 2画面それぞれの左下「戻る」ボタンの設定 ※完了ページには不要
     */
    const backButton = computed<BackButton | undefined>(() => {
      // ⓪見積内容確認（confirmation）→ 卒業式見積タブ
      // Doc: https://docs.google.com/spreadsheets/d/1a0S69OxCebDzgFYg10rIppGzqBOncO5AKPtuzH6YmU4/edit#gid=318692601&range=E6:G7
      if (inConfirmationPage.value) {
        return {
          label: '入力画面に戻る',
          url: urls卒業式.tab備考(Number(estimate_id)),
        }
      }
      // ①見積一覧（estimateList）→ 見積内容確認（confirmation）
      // Doc: https://docs.google.com/spreadsheets/d/1a0S69OxCebDzgFYg10rIppGzqBOncO5AKPtuzH6YmU4/edit#gid=318692601&range=G6:I7
      if (inEstimateListPage.value) {
        return {
          label: '変更',
          url: urls卒業式.入力内容確認(Number(estimate_id)),
        }
      }
    })

    /**
     * 3画面それぞれの右下「進む」ボタンの設定
     */
    const nextButton = computed<NextButton | undefined>(() => {
      // ①見積内容確認（confirmation） → 見積一覧（estimateList）
      // Doc: https://docs.google.com/spreadsheets/d/1a0S69OxCebDzgFYg10rIppGzqBOncO5AKPtuzH6YmU4/edit#gid=318692601&range=G6:I7
      if (inConfirmationPage.value) {
        return {
          label: '見積一覧',
          url: urls卒業式.見積一覧(Number(estimate_id)),
        }
      }

      // ②見積一覧（estimateList） → 見積作成完了（completed）
      // Doc: https://docs.google.com/spreadsheets/d/1a0S69OxCebDzgFYg10rIppGzqBOncO5AKPtuzH6YmU4/edit#gid=318692601&range=I6:K7
      if (inEstimateListPage.value) {
        const branchNumber = 1 // MEMO:新規作成なので１にしている
        const estimateId = storeMemo.get<'estimate_id'>('estimate_id').value
        const disabled = store見積一覧.get<'is_valid'>('is_valid').value

        const memoId = storeMemo.get<'memo_id'>('memo_id').value ?? 0
        const customerId =
          storeMemo.get<'customer_id'>('customer_id').value ?? 0
        const completePath = computed(() => {
          return (
            urls卒業式.見積作成完了(Number(estimateId)) +
            `?estimate_id=${estimateId}&branch=${branchNumber}` +
            `&memo_id=${memoId}` +
            `&customer_id=${customerId}`
          )
        })

        const selectedTotalPrice = (() => {
          switch (store見積一覧AllData.value.selectedPlan) {
            case COORDINATE_PLAN.RENTAL:
              return totalPrice.value.number
            case COORDINATE_PLAN.RENTAL_PREMIUM:
              return totalWithPP.value.number
            default:
              return 0
          }
        })()

        return {
          label: '見積完了',
          url: completePath.value,
          totalPrice: selectedTotalPrice,
          onClick: createEstimate,
          disabled,
        }
      }

      //　NOTE: こちらには到達しない想定
      return {
        label: 'こちらが表示された場合は考慮もれの箇所です',
        url: '/',
        onClick: () => new Promise((resolve) => resolve(false)),
      }
    })

    return { backButton, nextButton, inEstimateListPage }
  },
})
