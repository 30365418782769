import { FormValues髪飾り } from '~/composable/estimate/viewModel/graduation/Fields/Fields髪飾り'

//不明な点がある場合、READMEをご覧ください
export const getInitialStore = (): FormValues髪飾り => {
  return {
    kamikazari種別: null,
    kamikazari購入価格: null,
  }
}

const state = (): { fields: FormValues髪飾り } => ({
  fields: getInitialStore(),
})

export default state
