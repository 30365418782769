/**
 * [支払い区分]
 */
 export class PaymentCategory {
    /** 内金 */
    static readonly partPayment = 1
    /** 支払 */
    static readonly payment = 2
    /** 返金 */
    static readonly refund = 3
  
    static toWord = (val: number) => {
      switch (val) {
        case PaymentCategory.partPayment:
          return '内金'
        case PaymentCategory.payment:
          return '支払'
        case PaymentCategory.refund:
          return '返金'
        default:
          return 'ー'
      }
    }
  }
  