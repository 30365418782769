class ProductClassification {
  static readonly hurisode = 1 // 振袖
  static readonly obi = 2 // 帯
  static readonly obijime = 3 // 帯締め
  static readonly obiage = 4 // 帯揚げ
  static readonly kasaneeri = 5 // 重ね衿
  static readonly betueri = 6 // 別衿
  static readonly shawl = 7 // ショール
  static readonly nisyakusode = 8 // 二尺袖
  static readonly hakama = 9 // 袴
  static readonly haumongi = 10 // 訪問着
  static readonly tomesode = 11 // 留袖
  static readonly partyDress = 12 // パーティドレス
  static readonly photo = 13 // 写真
  static readonly ubugi = 14 // 初着
  // NOTE: 15~16はなし
  static readonly service = 17 // サービス
  static readonly hanhabaobi = 18 // 半巾帯
  //参列で使用する場合は初着として扱われる
  static readonly gohuku = 19 // 呉服
  static readonly morning = 20 // モーニング
  static readonly cleaning = 21 // クリーニング
  static readonly weddingDress = 22 // ウェディングドレス
  static readonly tuxedo = 23 // タキシード
  static readonly accessories = 24 // アクセサリー
  static readonly kamikazari = 25 // 髪飾り
  static readonly zoriAndBag = 26 // 草履・バッグ
  static readonly zori = 27 // 草履
  static readonly bag = 28 // バッグ
  static readonly taxAdjustment = 29 // 税調整
  static readonly jkPhotoAdvance = 30 // JK前々撮り
  static readonly kintyaku = 31 // 巾着
  static readonly nagajyuban = 32 // 長襦袢
  static readonly obiita = 33 // 帯板
  static readonly datejime = 34 // 伊達〆
  static readonly kosihimo = 35 // 腰紐
  static readonly erisin = 36 // 衿芯
  static readonly miehomoAndMakura = 37 // 三重紐・枕
  static readonly korinBelt = 38 // コーリンベルト
  static readonly hadagi = 39 // 肌着
  static readonly towel = 40 // タオル
  static readonly tabi = 41 // 足袋
  static readonly boots = 42 // ブーツ
  static readonly otherItem = 43 // その他商品
  static readonly okimono = 44 // お着物
  static readonly obimakura = 45 // 帯枕
  static readonly sanjyuhimo = 46 // 三重紐
  static readonly nagajyubanPurchase = 47 //長襦袢購入（お誂え正絹）
  static readonly nagajyubanRental = 48 // 長襦袢レンタル（仕立て上がり交織）
  static readonly gohukubako = 49 // 呉服箱
  static readonly mohuku = 50 // 喪服
  static readonly suehiro = 51 // 末広
  static readonly tanzaku = 100 // 短冊（チェックリスト用）
  static readonly partner = 101 // 協力会社用控え（チェックリスト用）

  static toWord = (val?: number | null) => {
    switch (val) {
      case ProductClassification.hurisode:
        return '振袖'
      case ProductClassification.obi:
        return '帯'
      case ProductClassification.obijime:
        return '帯締め'
      case ProductClassification.obiage:
        return '帯揚げ'
      case ProductClassification.kasaneeri:
        return '重ね衿'
      case ProductClassification.betueri:
        return '別衿'
      case ProductClassification.shawl:
        return 'ショール'
      case ProductClassification.nisyakusode:
        return '二尺袖'
      case ProductClassification.hakama:
        return '袴'
      case ProductClassification.haumongi:
        return '訪問着'
      case ProductClassification.tomesode:
        return '留袖'
      case ProductClassification.partyDress:
        return 'パーティドレス'
      case ProductClassification.photo:
        return '写真'
      case ProductClassification.ubugi:
        return '初着'
      case ProductClassification.service:
        return 'サービス'
      case ProductClassification.hanhabaobi:
        return '半巾帯'
      case ProductClassification.gohuku:
        return '初着'
      case ProductClassification.morning:
        return 'モーニング'
      case ProductClassification.cleaning:
        return 'クリーニング'
      case ProductClassification.weddingDress:
        return 'ウェディングドレス'
      case ProductClassification.tuxedo:
        return 'タキシード'
      case ProductClassification.accessories:
        return 'アクセサリー'
      case ProductClassification.kamikazari:
        return '髪飾り'
      case ProductClassification.zoriAndBag:
        return '草履・バッグ'
      case ProductClassification.zori:
        return '草履'
      case ProductClassification.bag:
        return 'バッグ'
      case ProductClassification.taxAdjustment:
        return '税調整'
      case ProductClassification.jkPhotoAdvance:
        return 'JK前々撮り'
      case ProductClassification.kintyaku:
        return '巾着'
      case ProductClassification.nagajyuban:
        return '長襦袢'
      case ProductClassification.obiita:
        return '帯板'
      case ProductClassification.datejime:
        return '伊達〆'
      case ProductClassification.kosihimo:
        return '腰紐'
      case ProductClassification.erisin:
        return '衿芯'
      case ProductClassification.miehomoAndMakura:
        return '三重紐・枕'
      case ProductClassification.korinBelt:
        return 'コーリンベルト'
      case ProductClassification.hadagi:
        return '肌着'
      case ProductClassification.towel:
        return 'タオル'
      case ProductClassification.tabi:
        return '足袋'
      case ProductClassification.boots:
        return 'ブーツ'
      case ProductClassification.otherItem:
        return 'その他商品'
      case ProductClassification.okimono:
        return '着物'
      case ProductClassification.obimakura:
        return '帯枕'
      case ProductClassification.sanjyuhimo:
        return '三重紐'
      case ProductClassification.nagajyubanPurchase:
        return '長襦袢購入（お誂え正絹）'
      case ProductClassification.nagajyubanRental:
        return '長襦袢レンタル（仕立て上がり交織）'
      case ProductClassification.gohukubako:
        return '呉服箱'
      case ProductClassification.mohuku:
        return '喪服'
      case ProductClassification.suehiro:
        return '末広'
      case ProductClassification.tanzaku:
        return '短冊'
      case ProductClassification.partner:
        return '協力会社用控え'
      default:
        return '未設定'
    }
  }

  static getAccessories = () => [
    ProductClassification.nagajyuban, // 長襦袢
    ProductClassification.korinBelt, // コーリンベルト
    ProductClassification.kosihimo, // 腰紐
    ProductClassification.miehomoAndMakura, // 三重紐・枕
    ProductClassification.obiita, // 帯板
    ProductClassification.erisin, // 衿芯
    ProductClassification.towel, // タオル
    ProductClassification.tabi, // 足袋
    ProductClassification.hadagi, // 肌着
    ProductClassification.zori, // 草履
    ProductClassification.datejime, // 伊達〆
    ProductClassification.suehiro, // 末広
    ProductClassification.obimakura, // 帯枕
    ProductClassification.bag, // バッグ
    ProductClassification.kintyaku, // 巾着
  ]

  static getSingleItemAccessories = () => [
    ProductClassification.obimakura,
    ProductClassification.obiita,
    ProductClassification.erisin,
    ProductClassification.datejime,
    ProductClassification.korinBelt,
    ProductClassification.sanjyuhimo,
    ProductClassification.kosihimo,
    ProductClassification.hadagi,
    ProductClassification.tabi,
    ProductClassification.towel,
  ]

  static getOrderList = () => [
    ProductClassification.hurisode, // 振袖
    ProductClassification.nisyakusode, // 二尺袖
    ProductClassification.haumongi, // 訪問着
    ProductClassification.tomesode, // 留袖
    ProductClassification.morning, // モーニング
    ProductClassification.ubugi, // 初着
    ProductClassification.partyDress, // パーティドレス
    ProductClassification.okimono, // お着物
    ProductClassification.hakama, // 袴
    ProductClassification.hanhabaobi, // 半巾帯
    ProductClassification.obi, // 帯
    ProductClassification.obijime, // 帯締め
    ProductClassification.obiage, // 帯揚げ
    ProductClassification.kasaneeri, // 重ね衿
    ProductClassification.betueri, // 別衿
    ProductClassification.shawl, // ショール
    ProductClassification.kamikazari, // 髪飾り
    ProductClassification.zoriAndBag, // 草履・バッグ
    ProductClassification.zori, // 草履
    ProductClassification.boots, // ブーツ
    ProductClassification.kintyaku, // 巾着
    ProductClassification.bag, // バッグ
    ProductClassification.nagajyuban, // 長襦袢
    ProductClassification.nagajyubanPurchase, // 長襦袢購入
    ProductClassification.nagajyubanRental, // 長襦袢レンタル
    ProductClassification.gohukubako, // 呉服箱
    ProductClassification.mohuku, // 喪服
    ProductClassification.otherItem, // その他
    ProductClassification.tanzaku, // 短冊
    ProductClassification.partner, // 協力会社控え
  ]
}

export default ProductClassification
