/**
 * 卒業式の契約書側に必要な値に加工するための関数
 */
import { useStore } from '@nuxtjs/composition-api'
import { getBadgeTextForGraduationSginature } from '~/composable/estimate/repository/comingOfAge/states/utilities'
import { useExtendsEstimateStore } from '~/composable/estimate/repository/ExtendsEstimateStore'
import { useState振袖二尺袖 } from '~/composable/estimate/repository/graduation/states/State振袖二尺袖'
import { useState袴 } from '~/composable/estimate/repository/graduation/states/State袴'
import {
  ConclusionItem,
  useFields見積一覧,
} from '~/composable/estimate/viewModel/graduation/Fields/Fields見積一覧'
import { PhotoConclusionItem } from '~/composable/estimate/viewModel/graduation/Fields/Fields見積一覧/お写真プラン見積'
import { EstimateAddition } from '~/constants/enums/estimateAddition'
import {
  DiscountType,
  EstimateDiscount,
} from '~/constants/enums/estimateDiscount'
import { EstimateSinglePriceType } from '~/constants/enums/estimateSinglePriceType'
import ProductClassification from '~/constants/enums/productClassification'
import { COORDINATE_PLAN } from '~/constants/estimate/config'
import { 卒業式長期レンタル値引き表示名 } from '~/constants/estimate/graduationOptions'
import {
  DiscountItem,
  ExtendsEstimateState,
  ProductItem,
} from '~/store/Contracts/ExtendsEstimate/state'
import { BaseEstimateState } from '~/store/Contracts/types'
import { CatalogOtherTypeResult, FinishedTypeResult } from '~/store/types'

export const useContractExtends = () => {
  const store = useExtendsEstimateStore()
  const storeContract = useStore()
  const state振袖二尺袖 = useState振袖二尺袖()
  const state袴 = useState袴()
  const { total, photo } = useFields見積一覧()

  const setContractExtendsEstimateStore = () => {
    const totalList = Object.values(total.value).flat() as ConclusionItem[]
    const photoList = Object.values(photo.value).flat() as PhotoConclusionItem[]

    const totalData = makeExtendsEstimateData(totalList, {
      kimono: state振袖二尺袖.value.着物検索,
      hakama: state袴.value.商品検索,
    })
    const photoData = photoList
      .map((item) => {
        const label = item.param.label?.includes('アクリルスタンド')
          ? 'アクリルスタンド'
          : item.param.label
        return {
          ...item.param,
          label: label ?? '',
        }
      })
      .filter((item) => item.price)
    photoData.sort((a, b) => {
      if (a.photoPlanId === b.photoPlanId) return 0
      if (!a.photoPlanId) return 1
      if (!b.photoPlanId) return -1
      return a.photoPlanId > b.photoPlanId ? 1 : -1
    })

    // baseEstimateのSelectPlanを更新する処理
    const estimate: BaseEstimateState['estimate'] =
      storeContract.getters['Contracts/BaseEstimate/getEstimate']

    estimate.selected_plan = total.value.見積一覧.selectedPlan
    estimate.amount_adjustments = total.value.見積一覧.amount_adjustments

    storeContract.dispatch('Contracts/BaseEstimate/setEstimate', estimate)

    const fullData: ExtendsEstimateState = {
      ...totalData,
      photoPlan: photoData,
    }
    store.setAll(fullData)
  }
  return { setContractExtendsEstimateStore }
}

/**
 * 見積一覧から選択した見積情報を契約書側で参照する値にフォーマットする関数
 */
const makeExtendsEstimateData = (
  estimateList: ConclusionItem[],
  products: {
    kimono: FinishedTypeResult | CatalogOtherTypeResult | null
    hakama: FinishedTypeResult | CatalogOtherTypeResult | null
  }
) => {
  // 純粋に見積一覧から取得された情報を整形する
  const formattedListItems = formatListItems(estimateList)

  // NOTE: 項目名で並べ替える
  formattedListItems.optionalPrices.sort((a, b) => {
    if (a.label === b.label) return 0
    return a.label > b.label ? 1 : -1
  })
  formattedListItems.discounts.sort((a, b) => {
    if (a.label === b.label) return 0
    return a.label > b.label ? 1 : -1
  })

  // NOTE: 着物や袴を先頭のまま、小物は種別IDが小さい順にソートする並べ替えの処理
  const kimonoProds = formattedListItems.products.filter(
    (item) => !item.rankUpId
  )
  const itemsProds = formattedListItems.products.filter((item) => item.rankUpId)
  itemsProds.sort((a, b) => {
    if (a.productTypeId === b.productTypeId) return 0
    if (!a.productTypeId || !b.productTypeId) return 0
    return a.productTypeId > b.productTypeId ? 1 : -1
  })
  formattedListItems.products = [...kimonoProds, ...itemsProds]

  // 他のストアからの情報を追加する
  formattedListItems.products = formattedListItems.products.map<ProductItem>(
    (item) => {
      switch (item.productTypeId) {
        case ProductClassification.hurisode:
        case ProductClassification.nisyakusode: {
          if (!products.kimono) {
            return {
              ...item,
              rankUpName: 'お持込',
            }
          }
          if (products.kimono.type === 'finished') {
            return {
              ...item,
              product: {
                type: 'finished',
                result: products.kimono,
              },
            }
          }
          if (products.kimono.type === 'catalog_other') {
            return {
              ...item,
              product: {
                type: 'catalogOther',
                result: products.kimono,
              },
            }
          }
          return item
        }
        case ProductClassification.hakama: {
          if (!products.hakama) {
            return {
              ...item,
              rankUpName: 'お持込',
            }
          }
          if (products.hakama.type === 'finished') {
            return {
              ...item,
              product: {
                type: 'finished',
                result: products.hakama,
              },
            }
          }
          if (products.hakama.type === 'catalog_other') {
            return {
              ...item,
              product: {
                type: 'catalogOther',
                result: products.hakama,
              },
            }
          }
          return item
        }
        default:
          return item
      }
    }
  )

  formattedListItems.discounts = formattedListItems.discounts.map<DiscountItem>(
    (item) => {
      let discountType = undefined
      let apiProps: DiscountItem['apiProps'] = undefined
      if (item.label === 'その他割引') {
        discountType = EstimateDiscount.noType
        apiProps = {
          type: DiscountType.amount,
          amount: item.price ?? 0,
        }
      }
      return {
        ...item,
        discountType,
        apiProps,
      }
    }
  )

  formattedListItems.optionalPrices = formattedListItems.optionalPrices.map(
    (item) => {
      let additionType = undefined
      if (item.label.includes('別衿加工')) {
        additionType = EstimateAddition.separateCollarProcessing
      }
      return {
        ...item,
        additionType,
      }
    }
  )

  return formattedListItems
}

const formatListItems = (estimateList: ConclusionItem[]) => {
  // NOTE: 以下のreduceでこの形式にlistItemsの配列を仕分けていく
  const initialData: ExtendsEstimateState = {
    products: [], // 振袖や小物などの商品
    discounts: [], // 割引系
    optionalPrices: [], // 着付けや補正などの追加金額
    photoPlan: [],
    wPhoto: {
      products: [],
      discounts: [],
      optionalPrices: [],
    },
  }

  const isPP適用 =
    estimateList.find((e) => e?.hasOwnProperty('selectedPlan'))
      ?.selectedPlan === COORDINATE_PLAN.RENTAL_PREMIUM

  return estimateList.reduce<ExtendsEstimateState>((data, datum) => {
    let detail = datum.priceNote || '' // 単品購入・単品レンタル
    if (datum.product) {
      detail += detail ? '／' : ''
      detail += datum.product // 商品名
    }
    const item = {
      name: datum.title,
      detail: detail || 'ー',
      price: datum.price ?? 0,
      priceWithPP: datum?.priceWithPP ?? 0,
      productTypeId: datum.productTypeId,
      rankType: datum.isRankUp ? 'ランクアップ' : null,
      rankTypeId: datum.rankUpId ?? null,
      isInsertDailyReport: datum.isInsertDailyReport ?? false,
    }

    if (datum.addition && datum.title === 'プレミアムパック') {
      const premiumPackPrice =
        item.hasOwnProperty('priceWithPP') && isPP適用
          ? item.priceWithPP
          : item.price
      if (premiumPackPrice) {
        data.optionalPrices.push({
          label: item.name,
          price: premiumPackPrice,
          detail: item.detail,
        })
        return data
      }
    }
    // NOTE: 裄袖補正はつけてなければ契約側では表示しない
    const without補正 = datum?.title?.includes('補正') && datum.price === null
    if (datum.addition && !without補正) {
      data.optionalPrices.push({
        label: item.name,
        price: item.price,
        detail: item.detail,
      })
      return data
    }

    if (datum.discount) {
      // NOTE: 卒業式長期レンタル値引きが適用されない場合は表示しない
      if (!(item.name === 卒業式長期レンタル値引き表示名 && !item.price)) {
        data.discounts.push({
          label: item.name,
          price: -item.price,
          detail: item.detail,
          isInsertDailyReport: item.isInsertDailyReport,
        })
        return data
      }
    }

    if (datum.productTypeId) {
      const prodTypeName = item.productTypeId
        ? ProductClassification.toWord(item.productTypeId)
        : ''

      data.products.push({
        productTypeId: item.productTypeId,
        label: prodTypeName,
        price: item.price,
        priceWithPP: item?.priceWithPP ?? 0,
        rankUpName: getBadgeTextForGraduationSginature(
          item.rankTypeId ?? undefined
        ),
        rankUpId: item.rankTypeId,
        detail: item.detail,
        priceType: EstimateSinglePriceType.toId(datum.priceNote ?? ''),
      })
    }

    return data
  }, initialData)
}
