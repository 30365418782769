













import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  data() {
    return {
      width: 25,
      height: 25,
    }
  },
})
