import { GetterTree } from 'vuex'
import { RootState, WphotoState } from '../../../types'

const getters: GetterTree<WphotoState, RootState> = {
  // NOTE: useComingStore用に追加
  getAllState: (state) => {
    return state
  },

  getSelections: (state: WphotoState) => {
    return state.selections
  },

  getIsValidate: (state: WphotoState) => {
    return state.isValidate
  },

  getIsSubValidate: (state: WphotoState) => {
    return state.isSubValidate
  },
}

export default getters
