import { ref } from '@nuxtjs/composition-api'
import { Contract } from '~/api/contractRepository'
import { useContract } from '~/composable/api/useContract'
import { EstimatePlan } from '~/constants/enums/estimatePlan'
import { ESTIMATE } from '~/constants/estimate/config'

/**
 *お客様にフルセットプランの成人式契約があればその情報を返す
 */
export const useComingOfAgeRecord = () => {
  const { fetchContractList, patchPlanForOldData } = useContract()
  const comingOfAgeRecord = ref<Contract | null>(null)
  // NOTE: 旧システムの契約データはplanを持たないため、プランタブでフルセットレンタルか購入を指定して上書きする必要がある
  const nullRecode = ref<Contract[]>([])

  const getComingOfAgeRecord = async (
    customerId: number,
    isRestate?: boolean,
    contractId?: number
  ): Promise<Contract | null> => {
    const contractList = await fetchContractList({
      customer_id: customerId,
    })

    const record = contractList.find((contract: Contract) => {
      // 復元時はcancel_dateを条件に含めず、選択した契約書が格納される
      const isIncludesCancelDate = isRestate ?? contract.cancel_date === null
      const isMatchContractId = contractId
        ? contract.contract_id === contractId
        : true

      return (
        isIncludesCancelDate &&
        isMatchContractId &&
        contract.event_type === ESTIMATE.成人式.id &&
        [EstimatePlan.fullSet, EstimatePlan.fullSetPurchase].includes(
          contract?.plan ?? 0
        )
      )
    })

    nullRecode.value = contractList.filter(
      (contract) =>
        contract.event_type === ESTIMATE.成人式.id && contract.plan === null
    )

    if (!record) {
      comingOfAgeRecord.value = null
      return null
    }

    comingOfAgeRecord.value = record

    return record
  }

  // planを持たない成人式旧データがあった場合にplanだけを更新して、更新後の契約情報を再取得し表示する
  const updatePlanForOldData = async (contract_id: number, plan: number) => {
    try {
      await patchPlanForOldData({
        contract_id,
        plan,
      })

      const contractList = await fetchContractList({
        contract_id,
      })

      const record = contractList.find((contract: Contract) => {
        return (
          contract.event_type === ESTIMATE.成人式.id &&
          [EstimatePlan.fullSet, EstimatePlan.fullSetPurchase].includes(
            contract?.plan ?? 0
          )
        )
      })

      if (!record) {
        return null
      }
      comingOfAgeRecord.value = record
      nullRecode.value = []

      return record
    } catch (error) {
      alert(
        '契約書のプランを更新できませんでした。ご指定の契約書は旧システムからのデータ移行分ではない可能性があります'
      )
    }
  }

  return {
    getComingOfAgeRecord,
    updatePlanForOldData,
    comingOfAgeRecord,
    nullRecode,
  }
}
