import { ActionTree } from 'vuex'
import { RootState, FurisodeState } from '../../../types'

const actions: ActionTree<FurisodeState, RootState> = {
  setSelection: ({ commit }, selection) => {
    commit('setSelection', selection)
  },

  setProduct: ({ commit }, product) => {
    commit('setProduct', product)
  },

  setInput: ({ commit }, input) => {
    commit('setInput', input)
  },

  setPrice: ({ commit }, price) => {
    commit('setPrice', price)
  },

  setContractId: ({ commit }, contractId) => {
    commit('setContractId', contractId)
  },

  setIsFree: ({ commit }, isFree) => {
    commit('setIsFree', isFree)
  },

  setIsFurisodeOnHold: ({ commit }, isFurisodeOnHold: boolean) => {
    commit('setIsFurisodeOnHold', isFurisodeOnHold)
  },

  setIsValidate: ({ commit }, isValidate: boolean) => {
    commit('setIsValidate', isValidate)
  },

  setIsOrderOutsideTargetYear: (
    { commit },
    isOrderOutsideTargetYear: boolean
  ) => {
    commit('setIsOrderOutsideTargetYear', isOrderOutsideTargetYear)
  },

  setProperProductId: ({ commit }, properProductId: number | null) => {
    commit('setProperProductId', properProductId)
  },

  setInit: ({ commit }) => {
    commit('deleteProduct')
    commit('deleteInput')
    commit('deletePrice')
  },
}

export default actions
