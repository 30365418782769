import {
  FrontStoreContractState,
  FrontStoreEstimateState,
  GetAllResponseData,
  ContractTypeId,
} from '~/api/frontStoreRepository'

import { ContractType } from '~/constants/enums/contractType'
import { getCoolingoffLimit, getPaymentDeadline } from '~/utils/coolingoff'
import DateTime from '~/utils/dateTime'
import { customerName as getCustomerName } from '~/utils/customerSummary'

/*
 * NOTE:frontStoreAPIの取得に使う
 * 保存する際はuseFrontStoreの方を使用すること
 */
type TemporarilySavedItemBase = {
  id: number
  thumbnailUrl: string
  eventName: string
  customerName: string
  useDate: string
  savedDate: string
  body: FrontStoreEstimateState | FrontStoreContractState
}

export type TemporarilySavedItemByEstimate = {} & TemporarilySavedItemBase

export type TemporarilySavedItemByContract = {
  totalPrice: number
  contractId: number | null
} & TemporarilySavedItemBase

export const useSaveListFormatter = () => {
  const getFormattedSaveListByEstimate = (
    responseData: GetAllResponseData
  ): TemporarilySavedItemByEstimate[] => {
    return responseData.data
      .map(({ id, body, saved_at }) => {
        try {
          const storeValue: FrontStoreEstimateState = JSON.parse(body)

          // NOTE: 仮保存では'ComingOfAgeCeremony' or 'GraduationCeremony' or 'Attendance'を含む見積書のストア情報を保存しているので、通常ではここのチェックは通過する想定
          if (
            !storeValue.Estimate?.ComingOfAgeCeremony &&
            !storeValue.Estimate?.GraduationCeremony &&
            !storeValue.Estimate?.Attendance?.Main?.data &&
            !storeValue.Estimate?.SingleItemService?.Main?.data
          ) {
            return null
          }

          if (storeValue.Estimate.ComingOfAgeCeremony) {
            const { Customers, ServicePlans, Photos } =
              storeValue.Estimate.ComingOfAgeCeremony

            const 使用日 = ServicePlans.date
            const useDate = 使用日
              ? new DateTime(使用日).toJpStringWithWeek()
              : 'ー'

            return {
              id,
              thumbnailUrl:
                Photos.settings[0]?.photos?.find(
                  ({ isSelected }: any) => isSelected
                )?.remoteSrc || '',
              eventName: '成人式',
              customerName: Customers.name,
              useDate,
              savedDate: new DateTime(saved_at).toJpStringWithWeek(),
              body: JSON.parse(body),
            }
          }

          if (storeValue.Estimate.GraduationCeremony) {
            const { tabお名前, tab試着写真, tabプラン } =
              storeValue.Estimate.GraduationCeremony

            const 使用日 = tabプラン.fields.plan卒業式使用日
            const useDate = 使用日
              ? new DateTime(使用日).toJpStringWithWeek()
              : 'ー'

            return {
              id,
              thumbnailUrl:
                tab試着写真.fields.shichaku全体写真?.find(
                  (obj: any) => obj.isSelected
                )?.remoteSrc || '',
              eventName: '卒業式',
              customerName: tabお名前.fields.onamaeお名前,
              useDate,
              savedDate: new DateTime(saved_at).toJpStringWithWeek(),
              body: JSON.parse(body),
            }
          }

          // 参列仮保存データの整形
          if (storeValue.Estimate?.Attendance?.Main?.data) {
            const { baseSettings, tryOnPhotos } =
              storeValue.Estimate?.Attendance?.Main?.data

            const customer = baseSettings.customer ?? ''
            const 使用日 = baseSettings.useDate

            // 使用日の算出
            const useDate = 使用日
              ? new DateTime(使用日).toJpStringWithWeek()
              : 'ー'

            // 選択された画像があればURLを抽出
            const photoUrl = tryOnPhotos.entirePhoto.find(
              (obj: any) => obj.isSelected
            )?.remoteSrc

            // お客様名があれば、名前を整形
            const customerNameSetting = customer
              ? getCustomerName(customer)
              : ''

            return {
              id, // 参列id
              thumbnailUrl: photoUrl || '', // 画像
              eventName: '参列', // イベント名
              customerName: customerNameSetting, // お客様名
              useDate, // 使用日
              savedDate: new DateTime(saved_at).toJpStringWithWeek(), // 保存日
              body: JSON.parse(body), // データ
            }
          }

          if (storeValue.Estimate?.SingleItemService?.Main?.data) {
            const { customer, photos } =
              storeValue.Estimate?.SingleItemService?.Main?.data.productSetting

            return {
              id,
              thumbnailUrl:
                photos.find((obj: any) => obj.isSelected)?.remoteSrc || '',
              eventName: '単品',
              customerName: customer ? getCustomerName(customer) : '',
              useDate: '',
              savedDate: new DateTime(saved_at).toJpStringWithWeek(),
              body: JSON.parse(body),
            }
          }
          return null
        } catch (error) {
          // NOTE: parse出来ない形式のbodyパラメータが来るバグ対策。数が少ないかつ
          // 修正依頼を掛けているので一旦コンソールに流すだけで握り潰している
          const text = `frontstoreId: ${id}, error: ${error}`
          console.error(text)
          return false
        }
      })
      .filter((item): item is TemporarilySavedItemByEstimate => Boolean(item))
  }
  const getFormattedSaveListByContract = (responseData: GetAllResponseData) => {
    const res: (false | TemporarilySavedItemByContract)[] =
      responseData.data.map(({ id, body, saved_at, contract_type }) => {
        try {
          const storeValue: FrontStoreContractState = JSON.parse(body)
          // 契約書仮保存リストの初期データを格納
          let saveItem: TemporarilySavedItemByContract = {
            id: 0,
            thumbnailUrl: '',
            eventName: '',
            customerName: '',
            useDate: '',
            savedDate: '',
            body: JSON.parse(body),
            totalPrice: 0,
            contractId: null,
          }

          // NOTE: 仮保存では'BaseEstimate'を含む契約書のストア情報を保存しているので、通常ではここのチェックは通過する想定
          if (!('BaseEstimate' in storeValue)) {
            return false
          }

          const { customerProfile } = storeValue.BaseEstimate
          const customerName = customerProfile
            ? `${customerProfile.last_name} ${customerProfile.first_name} (${customerProfile.last_name_kana} ${customerProfile.first_name_kana})`
            : ''

          // 成人式、卒業式の場合の処理
          if (
            contract_type === ContractType.comingOfAgeCeremony ||
            contract_type === ContractType.graduationCeremony
          ) {
            const 使用日 = storeValue.BaseEstimate.estimate.use_date
            const useDate = 使用日
              ? new DateTime(使用日).toJpStringWithWeek()
              : 'ー'

            // NOTE: 仮保存日を基に一度設定したクーリングオフ期間や支払い期間をそのまま反映しないために
            // 入力再開した日に更新上書き処理をしつつ、タブ不合格にしてもう一度確認してもらう
            storeValue.ImportantMatter.input.coolingOffPeriod = new DateTime(
              getCoolingoffLimit(new Date()).toDate()
            ).toDateString()
            storeValue.ImportantMatter.input.paymentDeadline = new DateTime(
              getPaymentDeadline(new Date()).toDate()
            ).toDateString()
            storeValue.ImportantMatter.isValidate = true

            saveItem = {
              id,
              thumbnailUrl:
                storeValue.BaseEstimate.estimate.photos.find(
                  // eslint-disable-next-line camelcase
                  ({ is_main_picture }) => is_main_picture
                )?.url || '',
              eventName: ContractType.toWord(
                storeValue.BaseEstimate.estimate.type
              ),
              customerName,
              useDate,
              savedDate: new DateTime(saved_at).toJpStringWithWeek(),
              totalPrice:
                storeValue.BaseEstimate.estimate.contract_total_price ?? 0,
              body: storeValue,
              contractId: storeValue.BaseEstimate.contractId,
            }

            return saveItem
          }

          // 参列の場合の処理
          if (contract_type === ContractType.attendance) {
            const { baseSettings, payment, user } =
              storeValue.Attendance.Main.data

            const customer = baseSettings.selectedEstimate ?? null
            const totalPayment =
              storeValue.Attendance.Main.contractTotalPrice ?? 0
            const 使用日 = user[0]?.usageinfo.useDate ?? null

            // 使用日の算出
            const useDate = 使用日
              ? new DateTime(使用日).toJpStringWithWeek()
              : 'ー'

            // NOTE: 仮保存日を基に一度設定したクーリングオフ期間や支払い期間をそのまま反映しないために
            // 入力再開した日に更新上書き処理をしつつ、タブ不合格にしてもう一度確認してもらう
            storeValue.Attendance.Main.data.importantMatter.coolingOffPeriod =
              new DateTime(
                getCoolingoffLimit(new Date()).toDate()
              ).toDateString()
            storeValue.Attendance.Main.data.importantMatter.paymentDeadline =
              new DateTime(
                getPaymentDeadline(new Date()).toDate()
              ).toDateString()
            storeValue.Attendance.Main.tabList =
              storeValue.Attendance.Main.tabList.map((tab) => {
                if (tab.text === '重要事項') {
                  tab.isComplete = false
                }
                return tab
              })

            // 画像があればURLを抽出
            const photoUrl = baseSettings.selectedEstimate?.photo_url ?? ''

            // お客様名があれば、名前を整形
            const customerNameSetting = customer
              ? getCustomerName(customer)
              : ''

            saveItem = {
              id, // 参列id
              thumbnailUrl: photoUrl, // 画像（参列には画像が存在しない）
              eventName: '参列', // イベント名（参列）
              customerName: customerNameSetting, // お客様名
              useDate, // 使用日
              savedDate: new DateTime(saved_at).toJpStringWithWeek(), // 保存日
              totalPrice: totalPayment, // 合計金額
              body: storeValue, // データ
              contractId: storeValue.Attendance.Main.contractId,
            }

            return saveItem
          } else if (contract_type === ContractType.singleItemService) {
            //単品
            const { customer, photos } =
              storeValue.SingleItemService?.Main?.data.productSetting

            saveItem = {
              id,
              thumbnailUrl:
                photos.find((obj: any) => obj.isSelected)?.remoteSrc || '',
              eventName: '単品',
              customerName: customer ? getCustomerName(customer) : '',
              useDate: '',
              savedDate: new DateTime(saved_at).toJpStringWithWeek(),
              totalPrice:
                storeValue.SingleItemService.Main.data.contractTotalPrice,
              body: storeValue,
              contractId: storeValue.SingleItemService.Main.data.contractId,
            }

            return saveItem
          }

          // クリーニングの場合の処理
          if (contract_type === ContractType.cleaning) {
            const { baseSettings, productInformations } =
              storeValue.Cleaning.Main.data

            const 使用日 = baseSettings.deliveryDate
            const useDate = 使用日
              ? new DateTime(使用日).toJpStringWithWeek()
              : 'ー'

            const totalPayment =
              storeValue.Cleaning.Main.contractTotalPrice ?? 0

            const photoUrl = productInformations?.productPhoto ?? ''

            const customerNameSetting = baseSettings.contractor
              ? getCustomerName(baseSettings.contractor)
              : ''
            saveItem = {
              id, // クリーニングid
              thumbnailUrl: photoUrl, // 画像
              eventName: 'クリーニング', // イベント名（クリーニング）
              customerName: customerNameSetting, // お客様名
              useDate, // 使用日
              savedDate: new DateTime(saved_at).toJpStringWithWeek(), // 保存日
              totalPrice: totalPayment, // 合計金額
              body: storeValue, // データ
              contractId: storeValue.Cleaning.Main.contractId,
            }

            return saveItem
          }

          return false
        } catch (error) {
          // NOTE: parse出来ない形式のbodyパラメータが来るバグ対策。数が少ないかつ
          // 修正依頼を掛けているので一旦コンソールに流すだけで握り潰している
          const text = `frontstoreId: ${id}, error: ${error}`
          console.error(text)
          return false
        }
      })

    return res.filter((item): item is TemporarilySavedItemByContract =>
      Boolean(item)
    )
  }

  return {
    getFormattedSaveListByEstimate,
    getFormattedSaveListByContract,
  }
}
