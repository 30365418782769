import {
  computed,
  Ref,
  ref,
  useRouter,
  useStore,
} from '@nuxtjs/composition-api'
import { Store } from 'vuex'
import { CreateEstimateParams } from '~/api/estimateRepository'
import { useEstimate } from '~/composable/api/useEstimate'
import { useMemo } from '~/composable/api/useMemo'
import { EstimateListState, FrontStoreInfo, OtherItems } from '~/store/types'
import DateTime from '~/utils/dateTime'
import { useAdditionsFormatter } from './formatters/additions'
import { DOCUMENT, ESTIMATE } from '~/constants/estimate/config'
import { useDiscountsFormatter } from './formatters/discounts'
import { useHurisodeFormatter } from './formatters/hurisode'
import { useItemsFormatter } from './formatters/items'
import { usePhotoFormatter } from './formatters/photos'
import { useEstimateState } from '~/composable/estimate/repository/comingOfAge/EstimateState'
import { usePlanFormatter } from './formatters/plan'
import { useSistersFormatter } from './formatters/sisters'
import { useJkPhotosInAdvanceFormatter } from './formatters/jkPhotosInAdvance'
import { useWPhotoFormatter } from './formatters/wPhoto'
import { usePriceTableFormatter } from './formatters/priceTable'
import { useInitializeState } from '~/composable/general/useInitializeState'
import { useTemporarilySaved } from '~/composable/api/useTemporarilySaved'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { CopiedEstimateState } from '~/store/Estimate/ComingOfAgeCeremony/CopiedEstimate/state'
import { modifyCopyingProducts成人式 } from '../../EstimateFormatter/copyingProducts'
import { usePlanTypeFlags } from '~/composable/estimate/domain/ComingOfAge/PlanTypeFlags'
import { EstimateSinglePriceType } from '~/constants/enums/estimateSinglePriceType'
import ProductClassification from '~/constants/enums/productClassification'

export const useComingOfAgeEstimateRequest = () => {
  const estimateState = useEstimateState()
  const { deleteItem } = useTemporarilySaved()
  const store = useStore() as Store<any>
  const storedfrontStoreId = computed<FrontStoreInfo['frontStoreId']>(() =>
    store.getters['FrontStoreInfo/getState']('frontStoreId')
  )
  const その他商品info: OtherItems =
    store.getters['Estimate/ComingOfAgeCeremony/OtherItem/getAllState']
  const priceTable =
    useComingStore<EstimateListState>('EstimateList').getPrices() // Store価格テーブルの取得
  const copiedEstimate =
    useComingStore<CopiedEstimateState>('CopiedEstimate').getAll().value
      ?.estimate // 見積コピー時には元の見積詳細情報が入っている
  const { isフルセット } = usePlanTypeFlags() // その他商品袴有無の判定に使用
  const { postEstimate, patchEstimate, error } = useEstimate()
  const formattedPlan = usePlanFormatter(estimateState)
  const formattedPhotos = usePhotoFormatter(estimateState)
  const formattedHurisode = useHurisodeFormatter(estimateState)
  const formattedDiscounts = useDiscountsFormatter(estimateState)
  const formattedAdditions = useAdditionsFormatter(estimateState)
  const formattedSisters = useSistersFormatter(estimateState)
  const formattedJkPhotosInAdvance =
    useJkPhotosInAdvanceFormatter(estimateState)
  const formattedWphoto = useWPhotoFormatter(estimateState)
  const formattedPriceTabel = usePriceTableFormatter(priceTable)
  const { postMemo, memoState } = useMemo()
  const { redirectToNextPage } = useRedirectToNextPage()
  const { initEstimateStore } = useInitializeState()

  // 新規作成のために送信するリクエストを作成する関数
  const createEstimateReqBody = (): Ref<CreateEstimateParams | null> => {
    try {
      /** NOTE: 草履・バッグPP適用にてselectedPlanが振袖購入・プレミアムパックの場合に、
       * 小物プランを変更する必要があるため、createEstimateReqBody内でformattedItemsを呼び出している。
       */
      const formattedItems = useItemsFormatter(
        estimateState,
        その他商品info,
        formattedPlan.value.selectedPlan,
        isフルセット.value
      )
      const customerInfo = estimateState.value.お客様検索
      if (!customerInfo) throw new Error('お客様情報が取得されていません')

      // NOTE: 卒業式側と異なり、見積一覧ページで選択肢を変更したり、
      // noImage画像を取得したりしているため、computedを用いている
      const estimateReqBody = computed(() => {
        // NOTE: 見積コピーした場合かつ商品に変更があった場合、product_idを送らない必要あるパターンがあるためここで行う
        // 対象範囲・詳細についてはチケット参照
        // https://vitgear.backlog.com/view/WB-3216
        const { hurisode, items, w_photo_plan } = modifyCopyingProducts成人式(
          copiedEstimate,
          formattedHurisode.value,
          formattedItems.value,
          formattedWphoto.value
        )

        /**
         * その他商品でフルセットかつ袴なしを選んだ場合は、
         * 見積一覧で単品レンタルか単品購入が決定するため、price_typeを変更する必要がある
         */
        const convertedItems = items.map((item) => {
          if (
            item.product_type_id === ProductClassification.otherItem &&
            isフルセット.value &&
            !item.is_hakama
          ) {
            if (
              formattedPlan.value.selectedPlan === 1 ||
              formattedPlan.value.selectedPlan === 2
            ) {
              item.price_type = EstimateSinglePriceType.singleRentalPrice
            } else {
              item.price_type = EstimateSinglePriceType.singlePurchasePrice
            }
          }
          // NOTE: items内のselected_planは本来不要だが、最初の実装の都合上載せている
          return {
            ...item,
            selected_plan: formattedPlan.value.selectedPlan,
          }
        })

        return {
          customer_id: customerInfo.customer_id,
          staff_id: store.state.LoginUser.user?.staff_id,
          plan: formattedPlan.value.plan ?? 0,
          contract_service_type: formattedPlan.value.contractServiceType,
          type: ESTIMATE.成人式.id,
          use_date: formattedPlan.value.useDate ?? undefined,
          photos: formattedPhotos.value.formatted全体写真,
          neck_photo_url: formattedPhotos.value.formatted衿元写真,
          hurisode,
          // NOTE: items内のselected_planは本来不要だが、最初の実装の都合上載せている
          items: convertedItems,
          discounts: formattedDiscounts.value,
          remarks: estimateState.value.備考 ?? '',
          contract_total_price: formattedPlan.value.contractTotalPrice,
          selected_plan: formattedPlan.value.selectedPlan,
          estimate_date: new DateTime().toDateString(),
          shop_id: store.state.SettingShop.shop.id,
          additions: formattedAdditions.value,
          is_jk_photos_in_advance: formattedJkPhotosInAdvance.value,
          amount_adjustments: estimateState.value.見積一覧.amountAdjustments,
          w_photo_plan,
          sisters: formattedSisters.value,
          mother_and_daughter_plan: estimateState.value.母娘プラン.is適用,
          reference_price_information: formattedPriceTabel.priceTables,
          visiting_reason: customerInfo.visiting_reason,
          bust: estimateState.value.足し布.bust,
          hip: estimateState.value.足し布.hip,
        }
      })

      return estimateReqBody
    } catch (error) {
      alert('成人式見積作成に必要な入力が足りていません \n' + error)
      return ref(null)
    }
  }

  /*
   * 見積最終画面で見積作成完了した時に、見積作成関連APIを投げる処理
   */
  const postComingOfAgeEstimate = async () => {
    const estimateReqBody = createEstimateReqBody().value
    console.info('Request parameter >>', estimateReqBody)
    if (!estimateReqBody) {
      console.error('estimateReqBodyが取得できませんでした')
      error.value = '見積作成情報の生成に失敗しました。'
      return { error }
    }

    // NOTE: 仮保存データの場合は見積作成と同時に仮保存データの方を削除する
    if (storedfrontStoreId.value.estimate) {
      deleteItem(storedfrontStoreId.value.estimate, DOCUMENT.見積書.id)
    }

    const res = await postEstimate(estimateReqBody)

    if (!res?.estimate_id) {
      console.error('見積作成のレスポンスからestimate_idを取得できませんでした')
      throw new Error('見積書の作成に失敗しました。')
    }

    store.dispatch(
      'Estimate/ComingOfAgeCeremony/Memo/setCustomerId',
      estimateReqBody.customer_id
    )
    if (memoState.body) {
      const memoData = {
        ...memoState,
        estimate_id: res.estimate_id,
      }
      await postMemo(
        estimateReqBody.customer_id,
        memoData,
        '成人式見積書作成',
        'comingOfAge'
      )
    }

    return { error, estimateId: res.estimate_id }
  }

  /*
   * 見積の更新をする処理
   */
  const updateComingOfAgeEstimate = async (
    estimateId: number,
    redirect: boolean = true
  ) => {
    try {
      if (!estimateId) {
        error.value = '無効な見積番号です'
        return { error }
      }
      const body = createEstimateReqBody().value
      if (!body) {
        error.value = 'estimateReqBodyが取得できませんでした'
        return { error }
      }
      const newBody = {
        ...body,
        estimate_id: estimateId,
      }

      // 見積書更新
      await patchEstimate(estimateId, newBody)

      // メモ保存
      if (memoState.body) {
        const memoData = {
          ...memoState,
          estimate_id: estimateId,
        }
        await postMemo(
          body.customer_id,
          memoData,
          '成人式見積書更新',
          'comingOfAge'
        )
      }

      // 見積側のstoreをリセット
      initEstimateStore()
      if (redirect) await redirectToNextPage(estimateId)
      return { error }
    } catch (err) {
      error.value = '見積書の更新が失敗しました'
      if (err instanceof Error) {
        error.value = err.message
      }
      return { error }
    }
  }

  return {
    createEstimateReqBody,
    postComingOfAgeEstimate,
    updateComingOfAgeEstimate,
  }
}

/**
 * 見積更新時に契約書作成フローではなく見積完了ページに飛ばす処理
 */
const useRedirectToNextPage = () => {
  const { latesteBranchNumber } = useEstimate()
  const router = useRouter()

  const redirectToNextPage = async (estimateId: number) => {
    // 契約書作成に遷移するために登録した情報を取得
    try {
      const branchNumber = await latesteBranchNumber(estimateId)

      router.push({
        path: `/estimate/comingOfAgeCeremony/${estimateId}/creationCompleted`,
        query: {
          estimate_id: String(estimateId),
          branch: String(branchNumber),
        },
      })
    } catch (error) {
      console.error(error)
    }
  }
  return { redirectToNextPage }
}
