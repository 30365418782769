var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-selection-price"},[_c('p',{staticClass:"label"},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"list"},[_vm._l((_vm.selections),function(selection){return _c('div',{key:selection.id,staticClass:"item"},[_c('input',{staticClass:"radio",attrs:{"id":selection.id +
          selection.rental +
          selection.purchase +
          _vm.productTypeId +
          _vm.orderNumber,"name":("価格選択" + _vm.productTypeId + _vm.orderNumber),"type":"radio","disabled":_vm.isDisabled &&
          selection.purchase === _vm.PRODUCT_SETTING.PRICES.単品レンタル価格のみ},domProps:{"value":selection.id,"checked":selection.id === _vm.selectedId},on:{"change":function($event){return _vm.$emit('select', selection)}}}),_vm._v(" "),_c('label',{staticClass:"label",class:{
          '-disabled':
            _vm.isDisabled &&
            selection.rental === _vm.PRODUCT_SETTING.PRICES.単品レンタル価格のみ,
          '-single-value-label':
            selection.rental === _vm.PRODUCT_SETTING.PRICES.単品レンタル価格のみ,
        },attrs:{"for":selection.id +
          selection.rental +
          selection.purchase +
          _vm.productTypeId +
          _vm.orderNumber}},[(
            _vm.productTypeId === _vm.PRODUCT_SETTING.PRODUCT_ID.重ね衿 &&
            selection.rental === _vm.PRODUCT_SETTING.PRICES.単品レンタル価格のみ
          )?_c('div',[_c('div',{staticClass:"purchase -single-value"},[_c('p',{staticClass:"label"},[_vm._v("レンタル")]),_vm._v(" "),_c('p',{staticClass:"price"},[_vm._v(_vm._s(selection.rental))])])]):_c('div',[_c('div',{staticClass:"rental"},[_c('p',{staticClass:"label"},[_vm._v("レンタル")]),_vm._v(" "),_c('p',{staticClass:"price"},[_vm._v(_vm._s(selection.rental))])]),_vm._v(" "),_c('div',{staticClass:"purchase"},[_c('p',{staticClass:"label"},[_vm._v("購入")]),_vm._v(" "),_c('p',{staticClass:"price"},[_vm._v(_vm._s(selection.purchase))])])])])])}),_vm._v(" "),_c('div',{staticClass:"item"},[_c('input',{staticClass:"radio",attrs:{"id":_vm.FREE_INPUT.id +
          _vm.FREE_INPUT.rental +
          _vm.FREE_INPUT.purchase +
          _vm.productTypeId +
          _vm.orderNumber,"name":("価格選択" + _vm.productTypeId + _vm.orderNumber),"type":"radio"},domProps:{"value":_vm.FREE_INPUT.id,"checked":_vm.FREE_INPUT.id === _vm.selectedId},on:{"change":function($event){return _vm.$emit('select', _vm.FREE_INPUT)}}}),_vm._v(" "),_c('label',{staticClass:"label -free",attrs:{"for":_vm.FREE_INPUT.id +
          _vm.FREE_INPUT.rental +
          _vm.FREE_INPUT.purchase +
          _vm.productTypeId +
          _vm.orderNumber}},[_c('p',{staticClass:"text"},[_vm._v("フリー入力")])])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }