import { ActionTree } from 'vuex'
import { RootState, ObiState } from '../../../../types'

const actions: ActionTree<ObiState, RootState> = {
  setSelection: ({ commit }, selection) => {
    commit('setSelection', selection)
  },

  setInput: ({ commit }, input) => {
    commit('setInput', input)
  },

  setIsValidate: ({ commit }, isValidate: boolean) => {
    commit('setIsValidate', isValidate)
  },

  deleteAll: ({ commit }) => {
    commit('deleteAll')
  },

  deleteInput: ({ commit }) => {
    commit('deleteInput')
  },
}

export default actions
