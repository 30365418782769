import { computed, Ref } from '@nuxtjs/composition-api'
import { Hurisode } from '~/api/estimateRepository'
import { Enum見積振袖仕立て } from '~/constants/enums/estimateDetail'
import { EstimateState } from '~/composable/estimate/repository/comingOfAge/EstimateState'
import { ProductCategory } from '~/constants/enums/productCategory'

export const useHurisodeFormatter = (estimateState: Ref<EstimateState>) => {
  return computed(() => {
    const { 振袖, 見積一覧, 足し布 } = estimateState.value
    return getFormattedHurisode(振袖, 見積一覧, 足し布)
  })
}

export const getFormattedHurisode = (
  state振袖: EstimateState['振袖'],
  state見積一覧: EstimateState['見積一覧'],
  state足し布: EstimateState['足し布']
) => {
  const {
    着物検索,
    レンタル価格,
    購入価格,
    商品分類,
    品種区分,
    商品名,
    プロパー商品ID,
    flags,
  } = state振袖
  if (!品種区分) throw new Error('hurisode.品種の選択がありません')

  // コピーした振袖をそのまま変えずに再見積する場合はカタログでも契約済みで見積番号発番されている可能性があるのでそのまま送る
  const is復元振袖 = 着物検索 && 着物検索.restored
  // カタログ検索時に採番済みのオーダーレンタルを選んでいても振袖購入ならproduct_idは送らない
  const withProductId = !flags.isお誂えレンタル && !flags.isカタログ購入

  const isプロパー商品 = [
    ProductCategory.properPurchase,
    ProductCategory.properRental,
  ].includes(商品分類)

  const getProductId = () => {
    if (isプロパー商品) {
      return プロパー商品ID ?? undefined
    } else if (is復元振袖) {
      return 着物検索?.product_id ?? undefined
    } else if (withProductId && 着物検索?.product_id) {
      return 着物検索.product_id
    }
    return undefined
  }

  // NOTE: お姉様購入の振袖かどうかはここで情報を持っているので
  // 持込の場合もcategory以外空の内容で送る
  const hurisode: Hurisode = {
    product_id: getProductId(),
    catalog_code: 着物検索?.catalog_code ?? undefined,
    product_name: 商品名 || '-',
    is_correct_for_yukitake: flags.is裄補正,
    is_correct_for_sodetake: flags.is袖補正,
    rental_price: レンタル価格 ?? 0,
    purchase_price: 購入価格 ?? 0,
    category: 商品分類,
    product_type_id: 品種区分,
    // NOTE:足し布が選ばれたとき、高級手縫い込みの金額でadditionsに追加するため未選択扱いにする
    sewing:
      state見積一覧.is高級手縫い && !state足し布.is適用
        ? Enum見積振袖仕立て.高級縫い
        : Enum見積振袖仕立て.ハイテク仕立て,
    is_order_outside_target_year: flags.is対象年度外オーダーレンタル,
  }
  return hurisode
}
