import { FormValuesその他商品 } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsその他商品'
import { OTHER_ITEMS } from '~/constants/estimate/config'

//不明な点がある場合、READMEをご覧ください
export const getInitialStore = (): FormValuesその他商品 => {
  return {
    otherItemFlg: OTHER_ITEMS.種別.しない,
    otherItems: [],
  }
}

const state = (): { fields: FormValuesその他商品 } => ({
  fields: getInitialStore(),
})

export default state
