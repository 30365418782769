import { useStore, watch } from '@nuxtjs/composition-api'
import { useMasterData } from '~/composable/api/useMasterData'
import { useEstimateRestate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { useInitializeState } from '~/composable/general/useInitializeState'
import { useRestoreお名前 } from './restorers/restoreお名前'
import { useRestoreお客様検索 } from './restorers/restoreお客様検索'
import { useRestoreプラン } from './restorers/restoreプラン'
import { useRestore備考 } from './restorers/restore備考'
import { useRestore商品設定 } from './restorers/restore商品設定'
import { useRestore帯 } from './restorers/restore帯'
import { useRestore帯揚げ } from './restorers/restore帯揚げ'
import { useRestore帯締め } from './restorers/restore帯締め'
import { useRestore振袖 } from './restorers/restore振袖'
import { useRestore試着写真 } from './restorers/restore試着写真'
import { useRestore重ね衿 } from './restorers/restore重ね衿'
import { useRestore別衿 } from './restorers/restore別衿'
import { useRestore髪飾り } from './restorers/restore髪飾り'
import { useRestoreショール } from './restorers/restoreショール'
import { useRestoreWPhoto振袖 } from './restorers/wPhoto/restoreWPhoto振袖'
import { useRestoreJK前撮り } from './restorers/restoreJK前撮り'
import { useRestore母娘プラン } from './restorers/restore母娘プラン'
import { useRestoreWPhoto試着写真 } from './restorers/wPhoto/restoreWPhoto試着写真'
import { useRestoreバッグ1個目 } from './restorers/restore草履バッグ/バッグ1個目'
import { useRestore草履1個目 } from './restorers/restore草履バッグ/草履1個目'
import { useRestore草履複数個目 } from './restorers/restore草履複数個目'
import { useRestoreバッグ複数個目 } from './restorers/restoreバッグ複数個目'
import { useRestoreWPhoto帯締め } from './restorers/wPhoto/restoreWPhoto帯締め'
import { useRestoreWPhoto帯 } from './restorers/wPhoto/restoreWPhoto帯'
import { useRestoreWPhotoFirst } from './restorers/wPhoto/restoreWPhotoFirst'
import { useRestore妹様割 } from './restorers/restore妹様割'
import { useRestoreWPhoto帯揚げ } from './restorers/wPhoto/restoreWPhoto帯揚げ'
import { useRestoreWPhoto商品設定 } from './restorers/wPhoto/restoreWPhoto商品設定'
import { useRestore双子割 } from './restorers/restore双子割'
import { useRestore従業員割 } from './restorers/restore従業員割'
import { useRestoreWPhoto別衿 } from './restorers/wPhoto/restoreWPhoto別衿'
import { useRestore足し布 } from './restorers/restore足し布'
import { useRestore見積一覧 } from './restorers/restore見積一覧'
import { useRestoreWPhoto重ね衿 } from './restorers/wPhoto/restoreWPhoto重ね衿'
import { useRestoreBaseEstimate } from '../../../../contracts/service/ContractRestorer/restoreBaseEstimate'
import { useRestore割引 } from './restorers/restore割引'
import { useRestorePriceTable } from './restorers/restorePriceTable'
import { useRestoreOption } from './restorers/restoreOption'
import { BaseEstimateState } from '~/store/Contracts/types'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { CopiedEstimateState } from '~/store/Estimate/ComingOfAgeCeremony/CopiedEstimate/state'
import { useRestoreその他商品 } from './restorers/restoreその他商品'

/**
 * 見積詳細APIからの値をストアに復元する処理を行う
 * 詳細は notion を参照のこと
 * https://www.notion.so/arsagajp/1a60d3b1985840aa8cdf53ba20bfd633
 */
export const useEstimateRestorer = () => {
  const store = useStore()
  const { fetchInfoForEstimateRestate, restate, originalRes } =
    useEstimateRestate()
  const { initAllDocumentStore, initEstimateStore } = useInitializeState()
  const { fetchVisitMotives, visitMotives } = useMasterData()
  const storeコピー元見積 =
    useComingStore<CopiedEstimateState>('CopiedEstimate')

  // 契約更新時に新規で作成された見積かどうか
  const isNewEstimate: BaseEstimateState['isNewEstimate'] =
    store.getters['Contracts/BaseEstimate/getIsNewEstimate']

  const currentBaseEstimate: BaseEstimateState =
    store.getters['Contracts/BaseEstimate/getEstimate']

  /* restorers */
  const { setBaseEstimate } = useRestoreBaseEstimate()
  const { restoreお名前 } = useRestoreお名前()
  const { restoreプラン } = useRestoreプラン()
  const { restore試着写真 } = useRestore試着写真()
  const { restore振袖 } = useRestore振袖()
  const { restore帯 } = useRestore帯()
  const { restore帯締め } = useRestore帯締め()
  const { restore帯揚げ } = useRestore帯揚げ()
  const { restore重ね衿 } = useRestore重ね衿()
  const { restore別衿 } = useRestore別衿()
  const { restoreショール } = useRestoreショール()
  const { restore髪飾り } = useRestore髪飾り()
  const { restore商品設定 } = useRestore商品設定()
  const { restoreバッグ1個目 } = useRestoreバッグ1個目()
  const { restore草履1個目 } = useRestore草履1個目()
  const { restore草履複数個目 } = useRestore草履複数個目()
  const { restoreバッグ複数個目 } = useRestoreバッグ複数個目()
  const { restoreその他商品 } = useRestoreその他商品()
  const { restore足し布 } = useRestore足し布()
  const { restore備考 } = useRestore備考()
  const { restoreお客様検索 } = useRestoreお客様検索()
  const { restore母娘プラン } = useRestore母娘プラン()
  const { restore妹様割 } = useRestore妹様割()
  const { restore双子割 } = useRestore双子割()
  const { restore従業員割 } = useRestore従業員割()
  const { restore割引 } = useRestore割引()

  const { restoreJK前撮り } = useRestoreJK前撮り()
  const { restore見積一覧 } = useRestore見積一覧()
  const { restoreWPhotoFirst } = useRestoreWPhotoFirst()
  const { restoreWPhoto振袖 } = useRestoreWPhoto振袖()
  const { restoreWPhoto商品設定 } = useRestoreWPhoto商品設定()
  const { restoreWPhoto試着写真 } = useRestoreWPhoto試着写真()
  const { restoreWPhoto帯締め } = useRestoreWPhoto帯締め()
  const { restoreWPhoto帯 } = useRestoreWPhoto帯()
  const { restoreWPhoto別衿 } = useRestoreWPhoto別衿()
  const { restoreWPhoto帯揚げ } = useRestoreWPhoto帯揚げ()
  const { restoreWPhoto重ね衿 } = useRestoreWPhoto重ね衿()
  const { restorePriceTable } = useRestorePriceTable()
  const { restoreOption } = useRestoreOption()

  const restoreEstimate = async (
    estimateId: number,
    branch: number,
    initContract: boolean = true,
    contractId: number = 0
  ) => {
    await fetchInfoForEstimateRestate(estimateId, branch)
    await fetchVisitMotives()

    const restore = () => {
      restoreお名前(restate.value.customer.fullName)
      restoreプラン(restate.value)
      restore試着写真(restate.value.photos)
      restore振袖(restate.value.hurisode, restate.value.plan, contractId)
      restore帯(restate.value.items.帯, restate.value.plan)
      restore帯締め(restate.value.items.帯締め, restate.value.plan)
      restore帯揚げ(restate.value.items.帯揚げ, restate.value.plan)
      restore重ね衿(restate.value.items.重ね衿, restate.value.plan)
      restore別衿(restate.value.items.別衿, restate.value.plan)
      restoreショール(restate.value.items.ショール, restate.value.plan)
      restore髪飾り(restate.value.items.髪飾り)
      restoreバッグ1個目(restate.value.items.バッグ1個目, restate.value.plan)
      restore草履1個目(restate.value.items.草履1個目, restate.value.plan)
      restore草履複数個目(restate.value.items.草履複数個目)
      restoreバッグ複数個目(restate.value.items.バッグ複数個目)
      restoreその他商品(restate.value.items.その他商品)
      restore商品設定(restate.value.items)
      restore足し布(restate.value.additions)
      restoreWPhoto振袖(restate.value.w_photo_plan.振袖)
      restoreWPhoto試着写真(restate.value.w_photo_plan.試着写真)
      restoreWPhoto商品設定(restate.value.w_photo_plan.商品設定)
      restoreWPhoto帯締め(restate.value.w_photo_plan.帯締め)
      restoreWPhoto帯(restate.value.w_photo_plan.帯)
      restoreWPhoto帯揚げ(restate.value.w_photo_plan.帯揚げ)
      restoreWPhoto別衿(restate.value.w_photo_plan.別衿)
      restoreWPhoto重ね衿(restate.value.w_photo_plan.重ね衿)
      restoreWPhotoFirst(restate.value.w_photo_plan.firstPage)
      restore割引(restate.value.discounts.割引)
      restore備考(restate.value.remarks)
      restorePriceTable(restate.value.price_information)
      restoreお客様検索(
        restate.value.customer.profile,
        restate.value.estimateInfo?.visiting_reason ?? [],
        visitMotives
      )
      restore見積一覧(restate.value)
      restoreOption()

      if (restate.value.plan && restate.value.plan.flags.isフルセットプラン) {
        restoreJK前撮り(restate.value.jk_photos_in_advance)
        restore妹様割(restate.value.discounts.妹様割)
        restore双子割(restate.value.discounts.双子割)
        restore従業員割(restate.value.discounts.従業員割)
      }

      if (restate.value.plan && !restate.value.plan.flags.is当日Bコース) {
        restore母娘プラン(restate.value.mother_and_daughter_plan)
      }

      if (restate.value.estimateInfo && !isNewEstimate) {
        setBaseEstimate(
          restate.value.estimateInfo,
          restate.value.customer.profile
        ) // 見積詳細
      }

      // 別の見積を反映する場合は、再度baseEstimateにセットする
      if (isNewEstimate) {
        store.commit('Contracts/BaseEstimate/setEstimate', currentBaseEstimate)
        store.commit('Contracts/BaseEstimate/setIsNewEstimate', true)
      }
      storeコピー元見積.setAll({ estimate: originalRes.value ?? null })
    }

    // NOTE: 契約変更時に参照先の見積を変更するために新見積の見積一覧に変遷した場合は全てのストアをクリアする
    isNewEstimate || !initContract
      ? initEstimateStore()
      : initAllDocumentStore()

    restore()
    // NOTE: 非同期に取得した値に更新するためwatch
    // WARNING&FIXME: これがあることでrestate内でcomputedを使って不要な回数再計算をすると
    // 開かないほど多く再レンダリングするバグが発生したので
    // 余力がある時にasync/awaitを使った書き方に切り替える
    watch(restate, restore, { deep: true })
  }

  return { restoreEstimate, originalRes, restate }
}
