import { computed, Ref } from '@nuxtjs/composition-api'
import { EstimateDetail } from '~/api/estimateRepository'
import { useRestate重ね衿 } from './Restate重ね衿'
import { useRestate髪飾り } from './Restate髪飾り'
import { useRestate半巾帯 } from './Restate半巾帯'
import { useRestate巾着バッグ } from './Restate巾着バッグ'
import { useRestate別衿 } from './Restate別衿'
import { useRestate草履ブーツ } from './Restate草履ブーツ'
import { useRestateその他商品 } from './Restateその他商品'

export const useRestateItems = (estimate: Ref<EstimateDetail | undefined>) => {
  const restate重ね衿 = useRestate重ね衿(estimate)
  const restate髪飾り = useRestate髪飾り(estimate)
  const restate半巾帯 = useRestate半巾帯(estimate)
  const restate巾着バッグ = useRestate巾着バッグ(estimate)
  const restate別衿 = useRestate別衿(estimate)
  const restate草履ブーツ = useRestate草履ブーツ(estimate)
  const restateその他商品 = useRestateその他商品(estimate)

  return computed(() => {
    return {
      重ね衿: restate重ね衿.value,
      髪飾り: restate髪飾り.value,
      半巾帯: restate半巾帯.value,
      巾着バッグ: restate巾着バッグ.value,
      別衿: restate別衿.value,
      草履ブーツ: restate草履ブーツ.value,
      その他商品: restateその他商品.value,
    }
  })
}
