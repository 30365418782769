import {
  useStore,
  useRouter,
  useRoute,
  computed,
} from '@nuxtjs/composition-api'
import { Store } from 'vuex'
import { BaseEstimateState } from '~/store/Contracts/types'
import { EstimateDetail } from '~/api/estimateRepository'
import { useHurisodeReserveList } from '~/composable/estimate/domain/HurisodeReserveList'
import { useHasFutureReserveFlag } from '~/composable/estimate/domain/HasFutureReserveFlag'
import { sortDescByReserveList } from '~/utils/utils'
import { State as PricesState } from '~/store/PriceTable/state'
import { formatDateOrDash } from '~/utils/utils'

export const useFurisodeAlertData = () => {
  const { fetchHurisodeReserveList, reserveList } = useHurisodeReserveList()
  const {
    fetchHurisodeReserveList: fetchWPhotoHurisodeReserveList,
    reserveList: wPhotoReserveList,
  } = useHurisodeReserveList()
  const { getHasFutureReserveFlag } = useHasFutureReserveFlag()

  const store = useStore() as Store<any>
  const router = useRouter()
  const route = useRoute()

  const estimate: BaseEstimateState['estimate'] =
    store.getters['Contracts/BaseEstimate/getEstimate']
  const computedEstimate = computed<BaseEstimateState['estimate']>(
    () => store.getters['Contracts/BaseEstimate/getEstimate']
  )
  const contract: PricesState['prices'] = store.getters['PriceTable/getPrices']

  const 当日 = computed<string>(() => computedEstimate.value.use_date)
  const 納品日: string = store.getters['Contracts/DeliveryDate/getInput']?.date
  const 前撮り日: string =
    store.getters['Contracts/PhotoInAdvance/getInput']
      ?.dateOfTakingPhotoInAdvance
  const estimateFurisode = estimate?.hurisode as EstimateDetail['hurisode']
  const wPhotoFurisode = estimate?.w_photo_plan
    ?.hurisode as EstimateDetail['hurisode']

  /**
   * 振袖が補正可能かどうかを判定しモーダルを出す関数(契約書用)
   */
  const showFurisodeModal = async (
    useDate: string,
    isCheckWPhoto: boolean
  ): Promise<boolean> => {
    const contractId = Number(route.value.query.contract_id)

    const type = estimate.type

    const pass = `/userInfo/${estimate.customer_id}/estimate/${estimate.estimate_id}`

    let isOpenModal = false

    let message = `使用日以降に、選択している振袖が予約されているため、補正できません。\n振袖を選び直すか、${
      type === 1 ? '成人式の日付や前撮り日' : '卒業式の日付'
    }を見直してください。`

    // 通常の振袖が補正ありの場合(成人式、前撮り、卒業式)
    if (
      estimate.hurisode?.is_correct_for_yukitake ||
      estimate.hurisode?.is_correct_for_sodetake
    ) {
      await fetchHurisodeReserveList(estimate.hurisode.product_id).catch(
        (e) => {
          throw e
        }
      )

      // 未来の予約があればメッセージに追記
      const isFutureReserve = getHasFutureReserveFlag(
        reserveList,
        useDate,
        contractId
      )

      if (isFutureReserve.value) {
        // 予約リストを未来順へソート
        const sortedList = sortDescByReserveList(reserveList.value)

        message +=
          (type === 1 ? '\n※成人式' : '\n※卒業式') +
          `及び前撮りに利用する振袖の最終予約日：${formatDateOrDash(
            sortedList[0].end_date
          )}`

        isOpenModal = true
      }
    }

    // 契約書作成画面遷移時はWPhotoの判定は必要ないので打ち切り
    if (!isCheckWPhoto) {
      if (!isOpenModal) {
        return isOpenModal
      }

      const result = confirm(message)
      if (!result) {
        return isOpenModal
      }

      if (isOpenModal) {
        router.push(pass)
      }

      return isOpenModal
    }

    // Wフォトの振袖が補正ありの場合
    if (
      estimate.w_photo_plan.hurisode?.is_correct_for_yukitake ||
      estimate.w_photo_plan.hurisode?.is_correct_for_sodetake
    ) {
      await fetchWPhotoHurisodeReserveList(
        estimate.w_photo_plan.hurisode.product_id
      ).catch((e) => {
        throw e
      })

      // 未来の予約があればメッセージに追記
      const isFutureReserve = getHasFutureReserveFlag(
        wPhotoReserveList,
        useDate
      )
      if (isFutureReserve.value) {
        // 予約リストを未来順へソート
        const sortedList = sortDescByReserveList(wPhotoReserveList.value)

        message += `\n※Wフォトに利用する振袖の最終予約日：${formatDateOrDash(
          sortedList[0].end_date
        )}`

        isOpenModal = true
      }
    }

    if (!isOpenModal) {
      return isOpenModal
    }

    const result = confirm(message)
    if (!result) {
      return isOpenModal
    }

    if (isOpenModal) {
      router.push(pass)
    }

    return isOpenModal
  }

  return {
    estimate,
    contract,
    当日,
    納品日,
    前撮り日,
    estimateFurisode,
    wPhotoFurisode,
    showFurisodeModal,
  }
}

/**
 * @note  納期までにかかる日数
 */
export const DELIVERY_DATE_PRODUCTS = {
  カタログ: 1, // 購入
  プロパー: 50, // 購入 または レンタル
  仕立て上がり: 50, // 購入
  補正: 30, // 購入 またはレンタル
  お誂え: 1, // レンタル  (カタログレンタルをお誂え)
  クーリングオフ: 7, // 仕立て上がりレンタル
  トールサイズ: 155, // トールサイズ商品
  仕立て上がり予約なし: 9, // 仕立て上り予約なし
} as const
