import { computed } from '@nuxtjs/composition-api'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { FormValuesプラン } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsプラン'
import { Enumプラン } from '~/constants/estimate/graduationOptions'
import { EstimatePlan } from '~/constants/enums/estimatePlan'

/**
 * @note 成人式の契約履歴があり、かつフルセット(購入/レンタル/どちらでも)のフラグを返す
 */
export const use成人式フルセットFlags = () => {
  const store = useGraduateStore<FormValuesプラン>('tabプラン')
  const contract = store.get<'plan成人式契約書'>('plan成人式契約書')
  const 弊社成人式 = store.get<'plan弊社成人式'>('plan弊社成人式')

  const is成人式フルセット購入 = computed(() => {
    const has弊社成人式 = 弊社成人式.value === Enumプラン.弊社成人式.はい
    const hasフルセット購入履歴 =
      contract.value?.plan === EstimatePlan.fullSetPurchase
    return has弊社成人式 && hasフルセット購入履歴
  })

  const is成人式フルセットレンタル = computed(() => {
    const has弊社成人式 = 弊社成人式.value === Enumプラン.弊社成人式.はい
    const hasフルセットレンタル履歴 =
      contract.value?.plan === EstimatePlan.fullSet
    return has弊社成人式 && hasフルセットレンタル履歴
  })

  const is成人式フルセット = computed(() => {
    return is成人式フルセット購入.value || is成人式フルセットレンタル.value
  })

  return {
    is成人式フルセット購入,
    is成人式フルセットレンタル,
    is成人式フルセット,
  }
}
