import { computed } from '@nuxtjs/composition-api'
import {
  getBadgeText,
  getItemPlan,
  getItemPlanFlags,
  getPriceTypeText,
  getWPhotoItemPrices,
} from '~/composable/estimate/repository/comingOfAge/states/utilities'
import { ObiState } from '~/store/types'
import ProductClassification from '~/constants/enums/productClassification'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { WPhotoBaseItemState } from '../types'
import { useState振袖 } from './State振袖'
import { PLAN } from '~/constants/estimate/comingOfAge'

type Betsueri = WPhotoBaseItemState & {
  品種区分: 6
  別衿加工必要: boolean
}

export const useState別衿 = () => {
  const valuesお持込 = useComingStore<ObiState>(
    'WphotoPlan/WphotoBetsueriBring'
  ).getAll()
  const valuesレンタル = useComingStore<ObiState>(
    'WphotoPlan/WphotoBetsueriRental'
  ).getAll()

  const state振袖 = useState振袖()

  return computed(() => {
    if (state振袖.value.flags.isレンタル) {
      return getState別衿(valuesレンタル.value, true)
    } else {
      return getState別衿(valuesお持込.value, false)
    }
  })
}

export const getState別衿 = (values別衿: ObiState, is振袖レンタル: boolean) => {
  const { selections, input } = values別衿
  const itemFlags = getItemPlanFlags(selections)
  const itemPlan = getItemPlan(selections)
  /**
   * 着物レンタルまたは購入の場合 & 別衿持ち込みの場合、別衿加工代が必ず発生
   * 着物持込の場合は、長襦袢に別衿が縫い付けられていない場合に別衿加工代が発生
   */
  const is別衿加工代必要 = is振袖レンタル
    ? selections.includes(PLAN.BRING_IN)
    : selections.includes(PLAN.BETSUERI_NO)

  const betsueri: Betsueri = {
    品目名: ProductClassification.toWord(ProductClassification.betueri),
    品種区分: ProductClassification.betueri,
    商品名: '',
    小物プラン: itemPlan,
    レンタル価格: 0,
    購入価格: 0,
    プレミアムレンタル価格: input.premiumRentalPrice,
    見積表示: null,
    表示価格: null,
    label: getBadgeText(itemPlan),
    cellLabel: getPriceTypeText(selections),
    別衿加工必要: is別衿加工代必要,
    flags: {
      isランクアップ:
        itemFlags.isランクアップPP内 || itemFlags.isランクアップPP外,
    },
  }

  const updatedPrices = getWPhotoItemPrices(
    values別衿,
    betsueri,
    is振袖レンタル
  )
  betsueri.レンタル価格 = updatedPrices.レンタル価格
  betsueri.購入価格 = updatedPrices.購入価格
  betsueri.プレミアムレンタル価格 = updatedPrices.プレミアムレンタル価格
  betsueri.見積表示 = updatedPrices.見積表示
  betsueri.表示価格 = updatedPrices.表示価格

  return betsueri
}
