import { ref, useContext } from '@nuxtjs/composition-api'
import { FamilyInfo } from '~/store/types'
import { CustomError } from '~/utils/exception'
import { FamilyData } from './useFamily'
import { Profile } from './usePersonal'
import FamilyRole from '~/constants/enums/familyRole'

export type Customer = {
  customer_id: number
  last_name: string
  first_name: string
  last_name_kana: string | null
  first_name_kana: string | null
  birthdate: string | null //形式'1990-11-11'
  mobile_phone_number: string
  tomonokai_point: number | null // 会員ではない場合null
  family_tomonokai_point: number | null // 会員ではない場合null
  has_mypage: number
  zip_code: string
  prefectures: string
  address1: string
  address2: string
  has_contract: number // 0: 契約なし 1: 契約あり
}

// APIから取得するご家族情報
type FamilyParam = {
  id: number
  customer_id: number
  type: number
  last_name: string
  first_name: string
  last_name_kana: string
  first_name_kana: string
  mobile_phone_number: string
  phone_number: string
  birthdate: string
  zip_code: string
  prefectures: string
  address1: string
  address2: string
}

// 顧客検索API request query params
export interface CustomerSearchQueryParams {
  customer_name?: string
  mobile_phone_number?: string | number
  customer_id?: string | number
  customer_name_kana?: string
  shop_ids?: number[]
  conditions?: number[]
}

const initData: Profile = {
  customer_id: 0,
  last_name: '',
  first_name: '',
  last_name_kana: '',
  first_name_kana: '',
  birthdate: '',
  zip_code: '',
  prefectures: '',
  prefecture_id: 0,
  address1: '',
  address2: '',
  phone_number: '',
  mobile_phone_number: '',
  home_zip_code: '',
  home_prefectures: '',
  home_prefecture_id: 0,
  home_address1: '',
  home_address2: '',
  home_phone_number: '',
  mail_address: '',
  family_role: '',
  occupation: '',
  school_name: '',
  graduate: '',
  notification: 0,
  height: '',
  foot_size: '',
  bust: '',
  hip: '',
  special_mentions: [],
  special_mentions_updated_at: '',
  body_information_input_date: '',
  is_send_mail: 0,
  remarks: [],
  tomonokai_point: null,
  family_tomonokai_point: 0,
  has_mypage: 0,
  fetched_at: '',
}

export const useCustomer = () => {
  const loading = ref(false)
  const error = ref('')
  const { $axios } = useContext()
  const customerList = ref<Customer[]>([])
  const customerProfile = ref<Profile>({ ...initData })
  const createdCustomerProfile = ref<Profile>()

  // fetched_atはサーバー側で更新の排他制御用のフラグ。取得したものを更新時にそのまま返す
  const fetchedAt = ref<string>('')

  // お客様登録API
  const createCustomer = async (params: {
    last_name: string
    first_name: string
    last_name_kana: string
    first_name_kana: string
    birthdate: string
    shop_id: number
    mobile_phone_number?: string
    phone_number?: string
    mail_address?: string
  }) => {
    try {
      loading.value = true
      const { data } = await $axios.post('customers', params)
      if (typeof data.id !== 'number')
        throw new CustomError(404, '顧客番号が発行されませんでした。', {
          stack: [],
        })
      const res = await $axios.get<Profile>('customers/' + data.id)

      createdCustomerProfile.value = res.data

      loading.value = false
      fetchedAt.value = res.data.fetched_at
      return res.data
    } catch (err) {
      console.error('API error', err)
      alert(
        '顧客の登録に失敗しました。既に登録されているメールアドレスを入力している可能性がございます。'
      )
    } finally {
      loading.value = false
    }
  }

  // お客様検索API
  const fetchCustomerList = async (params: {
    customer_name?: string
    mobile_phone_number?: string | number
    customer_id?: string | number
    customer_name_kana?: string
    conditions?: number[]
  }) => {
    customerList.value = []
    try {
      loading.value = true
      const res = await $axios.get('customers', { params })

      // NOTE: 契約有でソート
      const sortCustomerList = res.data.customers.sort(
        (a: Customer, b: Customer) => b.has_contract - a.has_contract
      )
      customerList.value = sortCustomerList
    } catch (err) {
      console.log('API error', error)
    } finally {
      loading.value = false
    }
  }

  // お客様情報API
  const fetchCustomerProfile = async (params: { customer_id: number }) => {
    error.value = ''

    try {
      loading.value = true
      const res = await $axios.get<Profile>('customers/' + params.customer_id)

      // NOTE: データに差分がある場合の対処療法
      customerProfile.value = {
        ...customerProfile.value,
        ...res.data,
      }
      loading.value = false
      fetchedAt.value = res.data.fetched_at
      return res.data
    } catch (err) {
      console.error(err)
      error.value = 'データが取得できませんでした。'
      return
    } finally {
      loading.value = false
    }
  }

  const customerFamily = ref<FamilyInfo[]>()

  // ご家族情報API
  const fetchCustomerFamily = async (params: { customer_id: number }) => {
    loading.value = true
    try {
      const res = await $axios.get<FamilyData>(
        '/customers/' + params.customer_id + '/family'
      )
      const familyArray = res.data.family
      customerFamily.value = await familyArray.map((family) => {
        return {
          customer_family_id: family.id,
          customer_id: family.customer_id,
          last_name: family.last_name,
          first_name: family.first_name,
          last_name_kana: family.last_name_kana,
          first_name_kana: family.first_name_kana,
          family_role: String(family.type),
          birthdate: family.birthdate,
          mobile_phone_number: family.mobile_phone_number,
          is_parent: FamilyRole.Guardian.includes(Number(family.type)),
          is_contractor: false,
        }
      })
      fetchedAt.value = res.data.fetched_at

      return customerFamily.value
    } catch (error) {
      console.log('API error', error)
    } finally {
      loading.value = false
    }
  }

  return {
    loading,
    error,
    createCustomer,
    fetchCustomerFamily,
    fetchCustomerProfile,
    fetchCustomerList,
    customerList,
    customerProfile,
    createdCustomerProfile,
    customerFamily,
    fetchedAt,
  }
}
