import { FormValuesお名前 } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsお名前'

//不明な点がある場合、READMEをご覧ください
export const getInitialStore = (): FormValuesお名前 => {
  return {
    onamaeお名前: null,
  }
}
const state = (): { fields: FormValuesお名前 } => ({
  fields: getInitialStore(),
})

export default state
