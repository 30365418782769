import { MutationTree } from 'vuex'
import { ObiState } from '../../../../types'

const mutations: MutationTree<ObiState> = {
  setSelection: (state, selection) => {
    state.selections = selection
  },

  setProduct: (state, product) => {
    state.product = product
  },

  setInput: (state, input) => {
    state.input = input
  },

  setPrice: (state, price) => {
    state.price = price
  },

  //　【通常】レンタル・購入
  setRentalPrice: (state, rentalPrice) => {
    state.input.rentalPrice = rentalPrice
  },
  setBuyPrice: (state, buyPrice) => {
    state.input.buyPrice = buyPrice
  },

  //【ランクアップ】レンタル・購入
  setRankUpRentalPrice: (state, rankUpRentalPrice) => {
    state.input.rankUpRentalPrice = rankUpRentalPrice
  },
  setRankUpBuyPrice: (state, rankUpBuyPrice) => {
    state.input.rankUpBuyPrice = rankUpBuyPrice
  },

  //【プレミアムパック】レンタル・購入
  setPremiumRentalPrice: (state, premiumRentalPrice) => {
    state.input.premiumRentalPrice = premiumRentalPrice
  },
  setPremiumBuyPrice: (state, premiumBuyPrice) => {
    state.input.premiumBuyPrice = premiumBuyPrice
  },

  setIsFree: (state, isFree) => {
    state.isFree = isFree
  },

  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },

  deleteProduct: (state) => {
    state.product = null
  },

  deleteInput: (state) => {
    state.input = {
      productName: '',
      buyPrice: 0,
      rentalPrice: 0,
      rankUpRentalPrice: 0,
      rankUpBuyPrice: 0,
      premiumRentalPrice: 0,
      premiumBuyPrice: 0,
    }
  },

  deletePrice: (state) => {
    state.price = {
      buy: 0,
      rental: 0,
    }
  },

  deleteIsFree: (state) => {
    state.isFree = false
  },
  delete: (state) => {
    state.selections = [0, 0, 0]
    state.product = null
    state.input = {
      productName: '',
      buyPrice: 0,
      rentalPrice: 0,
      rankUpRentalPrice: 0,
      rankUpBuyPrice: 0,
      premiumRentalPrice: 0,
      premiumBuyPrice: 0,
    }
    state.price = {
      buy: 0,
      rental: 0,
    }
    state.isFree = false
    state.isValidate = null
  },
}

export default mutations
