import { GetterTree } from 'vuex'
import { RootState, ImportantMatterState } from '../../types'

const getters: GetterTree<ImportantMatterState, RootState> = {
  getInput: (state: ImportantMatterState) => {
    return state.input
  },

  getIsValidate: (state: ImportantMatterState) => {
    return state.isValidate
  },
}

export default getters
