import { Module } from 'vuex'
import { RootState, AccessoryPlanState } from '../../../types'
import state from './state'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

export const Obiage2: Module<AccessoryPlanState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
