import {
  SingleItemServiceContractDetail,
  SingleItemServiceEstimateDetail,
} from '~/api/singleItemRepository'
import ProductClassification from '~/constants/enums/productClassification'
import { Accessories, State } from '~/store/SingleItemService/Main/state'
import { ACCESSORIES } from '../../accessories/enums'

/**
 * @note
 * 単品契約詳細or単品見積詳細APIから取得した場合にデータを復元する処理
 */
export const restateAccessory = (
  data:
    | SingleItemServiceContractDetail
    | SingleItemServiceEstimateDetail
    | undefined
): State['data']['accessories'] => {
  const TABIKOHAZE = 'コハゼ'
  const products =
    data?.products
      .filter((product) =>
        ProductClassification.getSingleItemAccessories().includes(
          product.product_type_id
        )
      )
      .map((product) => {
        return {
          ...product,
          measurement: {
            foot_size: product.measurement?.foot_size
              ? String(product.measurement?.foot_size)
              : null,
            bust: product.measurement?.bust
              ? String(product.measurement?.bust)
              : null,
            hip: product.measurement?.hip
              ? String(product.measurement?.hip)
              : null,
          },
          has_delivery: 2 as Accessories['products'][number]['has_delivery'],
          corrections: [],
        }
      }) ?? []

  const hasOldTypeTabi = products.some(
    (p) =>
      p.product_type_id === ProductClassification.tabi &&
      p.product_name?.includes(TABIKOHAZE)
  )
  const isValidateAccessories = products.map((p) => {
    return { [p.product_name]: true }
  })

  const accessoriesType = products.length
    ? ACCESSORIES.APPLY.はい
    : ACCESSORIES.APPLY.いいえ

  return {
    products,
    accessoriesType,
    isValidateAccessories,
    hasOldTypeTabi,
  }
}
