export class EstimateDiscount {
  /** 妹割り */
  static readonly sisterdiscount = 1
  /** 双子割 */
  static readonly twinsDiscount = 2
  /** 従業員割 */
  static readonly employeeDiscount = 3
  /** 種別なし */
  static readonly noType = 4
  /** 友の会割引（初着レンタル代無料）*/
  static readonly ubugiTomonokai = 5
  /** 友の会割引（訪問着・留袖・モーニングレンタル代30%引き）*/
  static readonly kimonoRentalTomonokai = 6
  /** 成人式利用なし割引（振袖レンタル代50%引き）*/
  static readonly noUseComingOfAge = 7
  /** フルセットプラン利用割引（振袖レンタル代80%引き）*/
  static readonly usedFullset = 8

  static toWord = (val: number) => {
    switch (val) {
      case EstimateDiscount.sisterdiscount:
        return '妹様割'
      case EstimateDiscount.twinsDiscount:
        return '双子割'
      case EstimateDiscount.employeeDiscount:
        return '従業員割'
      case EstimateDiscount.noType:
        return '種別なし'
      case EstimateDiscount.ubugiTomonokai:
        return '友の会割引'
      case EstimateDiscount.kimonoRentalTomonokai:
        return '友の会割引'
      case EstimateDiscount.noUseComingOfAge:
        return '成人式利用なし割引'
      case EstimateDiscount.usedFullset:
        return 'フルセットプラン利用割引'
      default:
        return ''
    }
  }
}

export class DiscountType {
  /** 割合で値引き */
  static readonly rate = 1
  /** 固定金額で値引き */
  static readonly amount = 2
}

/**
 * 適用されている割引を表示用に整形
 */
export const checkDiscount = (discounts: object[]) => {
  if (discounts === undefined) return

  const sister = discounts
    .map((obj: any) => {
      if (obj.discount_type === EstimateDiscount.sisterdiscount) return obj.rate
    })
    .filter(Boolean)

  const twins = discounts
    .map((obj: any) => {
      if (obj.discount_type === EstimateDiscount.twinsDiscount) return obj.rate
    })
    .filter(Boolean)

  const employee = discounts
    .map((obj: any) => {
      if (obj.discount_type === EstimateDiscount.employeeDiscount)
        return obj.amount
    })
    .filter(Boolean)

  return {
    sister: Number(sister[0]),
    twins: Number(twins[0]),
    employee: Number(employee[0]),
  }
}
