import { computed, Ref } from '@nuxtjs/composition-api'
import { EstimateDetail } from '~/api/estimateRepository'
import { Photo } from '~/store/Estimate/GraduationCeremony/types'
import { PLAN } from '~/constants/estimate/comingOfAge'

export const useRestatePhotos = (estimate: Ref<EstimateDetail | undefined>) => {
  return computed(() => {
    if (!estimate.value) return null

    const { photos, neck_photo_url } = estimate.value

    return {
      OptionIdOfアップロード:
        photos.length || !!neck_photo_url ? PLAN.YES : PLAN.NO,
      全体写真: format全体写真(photos),
      襟元写真: format襟元写真(neck_photo_url),
    }
  })
}

/**
 * 選択肢系の項目の初期値(選択されたoptionのid)を再計算する関数群
 */
const format全体写真 = (photos: EstimateDetail['photos']): Photo[] => {
  if (!photos) return []
  return photos.map((photo) => {
    return {
      remoteSrc: photo.url,
      isSelected: photo.is_main_picture,
    }
  })
}
const format襟元写真 = (url: EstimateDetail['neck_photo_url']): Photo[] => {
  if (!url) return []
  return [
    {
      remoteSrc: url,
      isSelected: false,
    },
  ]
}
