import { Ref, ref, useContext } from '@nuxtjs/composition-api'
import masterRepository, {
  Product,
  ProductPrice,
  SetOfProduct,
} from '~/api/productRepository'
import { usePrice } from '~/composable/general/Price'
import ProductClassification from '~/constants/enums/productClassification'

export const useProductData = () => {
  const { $axios } = useContext()
  const { paramToString } = usePrice()
  const kimonoPrices = ref<ProductPrice[]>([])
  const itemPrices = ref<ProductPrice[]>([])
  const itemProducts = ref<Product[]>([])
  const setOfProducts = ref<SetOfProduct[]>([])
  const loading = ref(false)

  const masterRepo = masterRepository($axios)

  // 着物価格一覧
  const fetchKimonoPrices = async (contentType: number) => {
    try {
      const { data } = await masterRepo.fetchKimonoPrices(contentType)
      kimonoPrices.value = data.data
    } catch (error) {
      console.log('API error', error)
    } finally {
      loading.value = false
    }
  }

  // 小物価格一覧
  const fetchItemProductPrices = async (contentType: number) => {
    try {
      const { data } = await masterRepo.fetchItemProductPrices(contentType)
      itemPrices.value = data.data
    } catch (error) {
      console.log('API error', error)
    } finally {
      loading.value = false
    }
  }

  /**
   * 引数で指定した属性の必要な金額情報をソートして、選択肢コンポーネントに渡す形に整形する
   * @param productType 品種種別
   * @param infoType price_informations.type
   * @param priceType price_informations.prices[0].price_type
   */
  type PriceOption = {
    id: number
    value: string
  }
  const sortPriceOptions =
    (fetchedData: Ref<ProductPrice[]>) =>
    (productType: number, infoType: number, priceType: number) => {
      const results: PriceOption[] = []

      if (!fetchedData.value.length) return results

      const priceInfo = fetchedData.value.find(
        (data) => data.product_type === productType
      )?.price_informations

      if (!priceInfo?.length) return results

      const selectedTypeItem = priceInfo.filter(
        (data) => data.type === infoType
      )
      if (!selectedTypeItem.length) return results

      return selectedTypeItem.map((data) => {
        const priceData = data.prices.find(
          (price) => price.price_type === priceType
        )
        const price = priceData ? priceData.price : ''
        return { id: data.order_number, value: paramToString(price) }
      })
    }

  const sortKimonoPrices = sortPriceOptions(kimonoPrices)
  const sortItemPrices = sortPriceOptions(itemPrices)

  // 小物商品情報一覧
  const fetchItemProducts = async (productTypeId: number) => {
    try {
      const { data } = await masterRepo.fetchProducts(productTypeId)
      // NOTE: ショール限定で並び替えを行う処理を追加（CRM上での実装までの一時的な対応）
      if (productTypeId === ProductClassification.shawl) {
        itemProducts.value = data.data.map((array) => {
          const sortedItems = array.items
            .map((item) => {
              return {
                ...item,
                sequence: calcShawlSequence(item.name),
              }
            })
            .sort((a, b) => a.sequence - b.sequence)
            .map((item) => {
              return {
                product_id: item.product_id,
                name: item.name,
                prices: item.prices,
              }
            })
          return { items: sortedItems }
        })
      } else {
        itemProducts.value = data.data
      }
    } catch (error) {
      console.log('API error', error)
    } finally {
      loading.value = false
    }
  }

  const calcShawlSequence = (productName: string) => {
    if (productName.match('白')) return 1
    if (productName.match('ピンク')) return 2
    if (productName.match('ブルーFOX')) return 3
    if (productName.match('5連FOX')) return 4
    return 5
  }

  /**
   * 引数で指定した属性の情報をソートして、選択肢コンポーネントに渡す形に整形する
   * @param itemType data[0].type ※TODO: 必要だがVV社都合で未実装。対応協議中？
   * @param itemName data[0].items[0].name 現状はショールなどはこれを使って検索する
   * @param priceType data[0].items[0].prices[0].price_type
   */
  type ProductOption = {
    id: number
    text: string
    value: string
    type?: number
    disabled?: boolean
  }
  const sortItemProductOptions = (
    itemName: string | null,
    priceType: number
  ) => {
    const results: ProductOption[] = []
    if (!itemProducts.value?.length) return results

    return itemProducts.value.reduce((acc1, data) => {
      const priceInfoList = data.items.reduce((acc2, item) => {
        // NOTE: 名前を指定していた場合は、違う名称の商品を弾く
        if (itemName && item.name !== itemName) return acc2

        // NOTE: 残った配列内のうち指定したprice_typeのものを整形して返す
        const selectedPrice = item.prices.find(
          (price) => price.price_type === priceType
        )
        if (!selectedPrice?.price) return acc2
        acc2.push({
          id: item.product_id,
          text: item.name,
          value: paramToString(selectedPrice.price),
          type: item.type,
        })
        return acc2
      }, [] as ProductOption[])

      acc1.push(...priceInfoList)
      return acc1
    }, [] as ProductOption[])
  }

  // セット商品一覧
  const fetchSetOfProducts = async () => {
    try {
      const { data } = await masterRepo.fetchSetOfProducts()
      setOfProducts.value = data.data
    } catch (error) {
      console.log('API error', error)
    } finally {
      loading.value = false
    }
  }

  return {
    kimonoPrices,
    fetchKimonoPrices,
    itemPrices,
    fetchItemProductPrices,
    itemProducts,
    fetchItemProducts,
    setOfProducts,
    fetchSetOfProducts,
    sortPriceOptions,
    sortKimonoPrices,
    sortItemPrices,
    sortItemProductOptions,
  }
}
