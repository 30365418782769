import { computed } from '@nuxtjs/composition-api'
import { useScreenTypeFlags } from '~/composable/estimate/domain/ComingOfAge/ScreenTypeFlags'
import { EstimateState } from '~/composable/estimate/repository/comingOfAge/EstimateState'
import { PlanFlags } from '~/composable/estimate/repository/comingOfAge/states/types'
import { Item, ItemWPhoto } from '../types'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { State as PricesState } from '~/store/PriceTable/state'
import { EstimateListState } from '~/store/types'

export const useListItem割引Formatter = (state: EstimateState['割引']) => {
  const { showTypeフルセット, showType写真のみレンタル, showType持ち込み } =
    useScreenTypeFlags()

  return computed(() => {
    const flags = {
      isフルセット: showTypeフルセット.value,
      is写のみレンタル: showType写真のみレンタル.value,
      is持ち込み: showType持ち込み.value,
    }
    return getListItem割引(state, flags)
  })
}

export const getListItem割引 = (
  values割引: EstimateState['割引'],
  { is写のみレンタル }: PlanFlags
) => {
  const 妹様割: Item = {
    item: {
      name: '妹様割',
      discount: true,
      rate: values割引.妹様割.割引.率,
      isInsertDailyReport: true,
    },
    column1: {
      price: -values割引.妹様割.着物割引額.レンタル,
    },
    column2: {
      price: -values割引.妹様割.着物割引額.レンタル,
    },
    column3: {
      price: -values割引.妹様割.着物割引額.購入,
    },
    column4: {
      price: -values割引.妹様割.着物割引額.購入,
    },
  }
  const 双子割: Item = {
    item: {
      name: '双子割',
      discount: true,
      rate: values割引.双子割.割引.率,
      isInsertDailyReport: true,
    },
    column1: {
      price: -values割引.双子割.着物割引額.レンタル,
    },
    column2: {
      price: -values割引.双子割.着物割引額.レンタル,
    },
    column3: {
      price: -values割引.双子割.着物割引額.購入,
    },
    column4: {
      price: -values割引.双子割.着物割引額.購入,
    },
  }
  const 従業員割: Item = {
    item: {
      name: '従業員割引',
      discount: true,
    },
    column1: {
      price: -values割引.従業員割.割引.金額,
    },
    column2: {
      price: -values割引.従業員割.割引.金額,
    },
    column3: {
      price: -values割引.従業員割.割引.金額,
    },
    column4: {
      price: -values割引.従業員割.割引.金額,
    },
  }

  const 割引: Item = {
    item: {
      name: 'その他割引',
      discount: true,
    },
    column1: {
      price: -values割引.割引.割引.金額,
    },
    column2: {
      price: -values割引.割引.割引.金額,
    },
    column3: {
      price: -values割引.割引.割引.金額,
    },
    column4: {
      price: -values割引.割引.割引.金額,
    },
  }

  const 振袖割引: Item = {
    item: {
      name: '振袖割引',
      discount: true,
      rate: values割引.振袖割引.割引.率,
      isInsertDailyReport: true,
    },
    column1: {
      price: -values割引.振袖割引.着物割引額.レンタル,
    },
    column2: {
      price: -values割引.振袖割引.着物割引額.レンタル,
    },
    column3: {
      price: -values割引.振袖割引.着物割引額.レンタル,
    },
    column4: {
      price: -values割引.振袖割引.着物割引額.レンタル,
    },
  }

  return {
    // NOTE:この3つはフルセット時のみ適用かどうかのタブが出現する
    妹様割: values割引.妹様割.is適用 ? 妹様割 : null,
    双子割: values割引.双子割.is適用 ? 双子割 : null,
    従業員割: values割引.従業員割.is適用 ? 従業員割 : null,
    割引: values割引.割引.is適用 ? 割引 : null,
    振袖割引: is写のみレンタル ? 振袖割引 : null,
  }
}

export const useListItem着付けなし割引Formatter = (
  state: EstimateState['プラン']
) => {
  const priceTable =
    useComingStore<EstimateListState>('EstimateList').getPrices() // Store価格テーブルの取得

  return computed(() => {
    return getListItem着付けなし割引(state, priceTable)
  })
}
export const getListItem着付けなし割引 = (
  valuesプラン: EstimateState['プラン'],
  priceTable: PricesState['prices']
): Item | null => {
  if (!valuesプラン.flags.is着付けなし) return null

  return {
    item: {
      name: '着付けなし割引',
      discount: true,
      isInsertDailyReport: true,
    },
    column1: {
      price: -priceTable.estimate.着付けなし割引,
    },
    column2: {
      price: -priceTable.estimate.着付けなし割引,
    },
    column3: {
      price: -priceTable.estimate.着付けなし割引,
    },
    column4: {
      price: -priceTable.estimate.着付けなし割引,
    },
  }
}

export const useListItemWPhoto振袖割引Formatter = (
  state: EstimateState['Wフォト']['振袖']
) => {
  return computed(() => {
    return getListItemWPhoto振袖割引(state)
  })
}

export const getListItemWPhoto振袖割引 = (
  state: EstimateState['Wフォト']['振袖']
): ItemWPhoto | null => {
  if (!state) return null
  if (!state.flags.isレンタル) return null

  return {
    item: {
      name: '振袖割引',
      discount: true,
      rate: state.割引.rate,
      isInsertDailyReport: true,
    },
    column1: {
      price: -state.割引.price,
    },
    column2: {
      price: -state.割引.price,
    },
  }
}
