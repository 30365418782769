import { MutationTree } from 'vuex'
import { FormValues足し布 } from '~/composable/estimate/viewModel/comingOfAge/Fields/Fields足し布'
import { getInitialStore, State } from './state'

const mutations: MutationTree<State> = {
  setState: (
    state,
    payload: { stateName: keyof FormValues足し布; value: any }
  ) => {
    state.fields[payload.stateName] = payload.value
  },

  setIsValidate: (state, valid: boolean | null) => {
    state.isValidate = valid
  },

  setAllState: (state, values: FormValues足し布) => {
    state.fields = values
  },

  initState: (state, fieldId: keyof FormValues足し布) => {
    const initialStore = getInitialStore()
    state.fields[fieldId] = initialStore[fieldId] as any
  },

  initAllState: (state) => {
    state.fields = getInitialStore()
    state.isValidate = null
  },

  delete: (state) => {
    state.fields = getInitialStore()
    state.isValidate = null
  },
}

export default mutations
