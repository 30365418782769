import { computed, ref } from '@nuxtjs/composition-api'
import { Enumお写真プラン } from '~/constants/estimate/graduationOptions'
import { useEstimateState } from '~/composable/estimate/repository/graduation/EstimateState'
import { useUrlNavigator } from '~/composable/estimate/service/graduation/UrlNavigator'
import { ConclusionItem } from '.'
import { PhotoPlanItem } from '~/store/Contracts/ExtendsEstimate/state'
import { Enum見積アクリルスタンド } from '~/constants/enums/estimateDetail'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'

export type PhotoConclusionItem = ConclusionItem & {
  param: PhotoPlanItem
}

export type Fieldsお写真見積 = {
  デザインアルバム: PhotoConclusionItem
  六つ切り_台紙あり: PhotoConclusionItem[]
  六つ切り_台紙なし: PhotoConclusionItem
  キャビネ_台紙あり: PhotoConclusionItem[]
  キャビネ_台紙なし: PhotoConclusionItem
  アクリルスタンド: PhotoConclusionItem[]
  USB: PhotoConclusionItem
}

export const useお写真見積 = () => {
  const priceTable = useGraduateStore('').getPrices()
  const state = useEstimateState()
  const paths = useUrlNavigator()

  const デザインアルバム = useデザインアルバム()
  const アクリルスタンド = useアクリルスタンド見積()
  const 六つ切り_台紙あり = use六つ切り_台紙あり()
  const 六つ切り_台紙なし = use六つ切り_台紙なし()
  const キャビネ_台紙あり = useキャビネ_台紙あり()
  const キャビネ_台紙なし = useキャビネ_台紙なし()

  return computed<Fieldsお写真見積>(() => {
    const { お写真プラン: photoPlan } = state.value
    const USB = makeConclusionItem(
      paths.tabお写真プラン,
      {
        label: 'USBデータ',
        type: 'USB',
        is_buying_final_photo_data: photoPlan.hasUSB,
        photoPlanId: null,
        price: photoPlan.hasUSB ? priceTable.estimate.お写真プランUSBデータ : 0,
      },
      {
        product: photoPlan.hasUSB ? '購入する' : '', // しない時は非表示
      }
    )

    const items: Fieldsお写真見積 = {
      アクリルスタンド: アクリルスタンド.value,
      USB: USB,
      デザインアルバム: デザインアルバム.value,
      六つ切り_台紙あり: 六つ切り_台紙あり.value,
      六つ切り_台紙なし: 六つ切り_台紙なし.value,
      キャビネ_台紙あり: キャビネ_台紙あり.value,
      キャビネ_台紙なし: キャビネ_台紙なし.value,
    }

    return items
  })
}

const useアクリルスタンド見積 = () => {
  const priceTable = useGraduateStore('').getPrices()
  const state = useEstimateState()
  const paths = useUrlNavigator()

  return computed<PhotoConclusionItem[]>(() => {
    const { アクリルスタンド: stand } = state.value.お写真プラン
    const stands = ref<PhotoConclusionItem[]>([])
    const A5Num = stand?.A5 ?? 0
    const A4Num = stand?.A4 ?? 0
    const アクリルスタンドA5金額 = ref<null | number>()
    const アクリルスタンドA4金額 = ref<null | number>()

    if (state.value.お写真プラン.選択商品番号) {
      if (
        state.value.お写真プラン.選択商品番号.includes(
          Enumお写真プラン.商品選択.アクリルスタンド
        )
      ) {
        アクリルスタンドA5金額.value =
          A5Num * priceTable.estimate.お写真プランアクリルスタンドA5 || null

        アクリルスタンドA4金額.value =
          A4Num * priceTable.estimate.お写真プランアクリルスタンドA4 || null
      }
    }

    stands.value.push(
      makeConclusionItem(
        paths.tabお写真プラン,
        {
          label: 'アクリルスタンド A4',
          type: 'アクリルスタンド',
          size: Enum見積アクリルスタンド.A4,
          number_of_pieces: A4Num,
          photoPlanId: Enumお写真プラン.商品選択.アクリルスタンド,
          price: アクリルスタンドA4金額.value ?? 0,
        },
        { product: A4Num + 'ポーズ' }
      )
    )

    stands.value.push(
      makeConclusionItem(
        paths.tabお写真プラン,
        {
          label: 'アクリルスタンド A5',
          type: 'アクリルスタンド',
          size: Enum見積アクリルスタンド.A5,
          number_of_pieces: A5Num,
          photoPlanId: Enumお写真プラン.商品選択.アクリルスタンド,
          price: アクリルスタンドA5金額.value ?? 0,
        },
        { product: A5Num + 'ポーズ' }
      )
    )

    return stands.value
  })
}

const useデザインアルバム = () => {
  const priceTable = useGraduateStore('').getPrices()
  const state = useEstimateState()
  const paths = useUrlNavigator()

  const getDefaultItem = () => {
    return {
      title: 'デザインアルバム',
      price: 0,
      product: '',
      photoPlanId: Enumお写真プラン.商品選択.デザインアルバム,
    }
  }

  return computed<PhotoConclusionItem>(() => {
    const { デザインアルバム: album } = state.value.お写真プラン
    const item = ref(getDefaultItem())

    if (!album) {
      return makeConclusionItem(
        paths.tabお写真プラン,
        {
          label: 'デザインアルバム',
          type: 'アルバム',
          pose_number: 0,
          number_of_books: 0,
          referral_bonus_pose_number: 0,
          photoPlanId: Enumお写真プラン.商品選択.デザインアルバム,
          price: 0,
        },
        { product: item.value.product }
      )
    }

    // デザインアルバムの合計金額 = コース金額
    // NOTE: 紹介者特典の追加ポーズは無料枠
    let totalPrice =
      album.スタンダードORプレミアム === Enumお写真プラン.ポーズ数.スタンダード
        ? priceTable.estimate.お写真プランデザインアルバムスタンダード
        : priceTable.estimate.お写真プランデザインアルバムプレミアム

    return makeConclusionItem(
      paths.tabお写真プラン,
      {
        label: 'デザインアルバム',
        type: 'アルバム',
        pose_number: album.基本ポーズ数,
        number_of_books: album.基本冊数,
        referral_bonus_pose_number: album.紹介者特典ポーズ数,
        photoPlanId: Enumお写真プラン.商品選択.デザインアルバム,
        price: totalPrice ?? 0,
      },
      { product: `${album.合計ポーズ数}ポーズ×${album.合計冊数}冊` }
    )
  })
}

const use六つ切り_台紙あり = () => {
  const priceTable = useGraduateStore('').getPrices()
  const state = useEstimateState()
  const paths = useUrlNavigator()

  return computed<PhotoConclusionItem[]>(() => {
    const stand = state.value.お写真プラン.六つ切り台紙あり
    const stands = ref<PhotoConclusionItem[]>([])
    const 金額 = ref<null | number>(null)

    stands.value = stand.map((o, index) => {
      const ポーズ数 = o.pose ?? 0
      const 冊数 = o.book ?? 0

      const has六つ切り台紙あり =
        state.value.お写真プラン.選択商品番号.includes(
          Enumお写真プラン.商品選択.六切り台紙あり
        )
      const price =
        (priceTable.estimate.お写真プラン六つ切り台紙ありポーズ1 +
          (ポーズ数 - 1) *
            priceTable.estimate.お写真プラン六つ切り台紙ありポーズ2以降) *
        冊数

      金額.value = has六つ切り台紙あり ? price : null

      // PP適用の際は1種類目の1ポーズ目が無料になるため
      const priceWithPP = !index
        ? price - priceTable.estimate.お写真プラン六つ切り台紙ありポーズ1
        : null

      const priceNoteWithPP = !index ? '1ポーズ目無料' : null

      return makeConclusionItem(
        paths.tabお写真プラン,
        {
          label: `六切り/台紙あり  ${index + 1}種類目`,
          type: '台紙あり',
          pose_number: ポーズ数,
          number_of_books: 冊数,
          photoPlanId: Enumお写真プラン.商品選択.六切り台紙あり,
          price: 金額.value ?? 0,
        },
        {
          product: `${ポーズ数}ポーズ×${冊数}冊`,
          priceWithPP,
          priceNoteWithPP,
        }
      )
    })

    return stands.value
  })
}

const use六つ切り_台紙なし = () => {
  const priceTable = useGraduateStore('').getPrices()
  const state = useEstimateState()
  const 金額 = ref<null | number>(null)
  const paths = useUrlNavigator()

  return computed<PhotoConclusionItem>(() => {
    const { 六つ切り台紙なし: stand } = state.value.お写真プラン
    const ポーズ数 = stand ?? 0

    金額.value = state.value.お写真プラン.選択商品番号.includes(
      Enumお写真プラン.商品選択.六切り台紙なし
    )
      ? priceTable.estimate.お写真プラン六つ切り台紙なし * ポーズ数
      : null

    return makeConclusionItem(
      paths.tabお写真プラン,
      {
        label: '六切り/台紙なし',
        type: '台紙なし',
        pose_number: ポーズ数,
        photoPlanId: Enumお写真プラン.商品選択.六切り台紙なし,
        price: 金額.value ?? 0,
      },
      { product: ポーズ数 + 'ポーズ' }
    )
  })
}

const useキャビネ_台紙あり = () => {
  const priceTable = useGraduateStore('').getPrices()
  const state = useEstimateState()
  const paths = useUrlNavigator()

  return computed((): PhotoConclusionItem[] => {
    const stand = state.value.お写真プラン.キャビネ台紙あり

    return stand.map((o, i) => {
      const ポーズ数 = o.pose ?? 0
      const 冊数 = o.book ?? 0
      const hasキャビネ台紙あり =
        state.value.お写真プラン.選択商品番号.includes(
          Enumお写真プラン.商品選択.キャビネ台紙あり
        )
      const price =
        (priceTable.estimate.お写真プランキャビネ台紙ありポーズ1 +
          (ポーズ数 - 1) *
            priceTable.estimate.お写真プランキャビネ台紙ありポーズ2以降) *
        冊数
      return makeConclusionItem(
        paths.tabお写真プラン,
        {
          label: `キャビネ/台紙あり  ${i + 1}種類目`,
          type: '台紙あり',
          pose_number: ポーズ数,
          number_of_books: 冊数,
          photoPlanId: Enumお写真プラン.商品選択.キャビネ台紙あり,
          price: (hasキャビネ台紙あり ? price : null) ?? 0,
        },
        { product: `${ポーズ数}ポーズ×${冊数}冊` }
      )
    })
  })
}

const useキャビネ_台紙なし = () => {
  const priceTable = useGraduateStore('').getPrices()
  const state = useEstimateState()
  const paths = useUrlNavigator()
  const 金額 = ref<null | number>(null)

  return computed<PhotoConclusionItem>(() => {
    const { キャビネ台紙なし: stand } = state.value.お写真プラン
    const ポーズ数 = stand ?? 0
    金額.value = state.value.お写真プラン.選択商品番号.includes(
      Enumお写真プラン.商品選択.キャビネ台紙なし
    )
      ? priceTable.estimate.お写真プランキャビネ台紙なし * ポーズ数
      : null

    return makeConclusionItem(
      paths.tabお写真プラン,
      {
        label: 'キャビネ/台紙なし',
        type: '台紙なし',
        pose_number: ポーズ数,
        photoPlanId: Enumお写真プラン.商品選択.キャビネ台紙なし,
        price: 金額.value ?? 0,
      },
      { product: stand + 'ポーズ' }
    )
  })
}

type makeConclusionItemOptions = Pick<
  ConclusionItem,
  'product' | 'priceNote' | 'priceWithPP' | 'priceNoteWithPP'
>

const makeConclusionItem = (
  path: string,
  param: PhotoPlanItem,
  options?: makeConclusionItemOptions
): PhotoConclusionItem => {
  return {
    title: param.label,
    price: param.price,
    priceWithPP: options?.priceWithPP,
    product: options?.product,
    priceNote: options?.priceNote,
    priceNoteWithPP: options?.priceNoteWithPP,
    url: path,
    productTypeId: param.photoPlanId,
    param: param,
  }
}
