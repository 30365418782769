import { Module } from 'vuex'
import { RootState, settingShop } from '../types'
import state from './state'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

export const SettingShop: Module<settingShop, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
