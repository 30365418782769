/**
 * [見積書ステータス]
 */
export class EstimateStatus {
  /** 契約書未作成 */
  static uncreated = 0
  /** 契約書作成済み */
  static done = 1
  /** 仮保存中 */
  static saved = 2

  static toWord = (val: number) => {
    switch (val) {
      case EstimateStatus.uncreated:
        return '契約書未作成'
      case EstimateStatus.done:
        return '契約書作成済み'
      case EstimateStatus.saved:
        return '仮保存中'
      default:
        return '未設定'
    }
  }
}
