import { computed, Ref } from '@nuxtjs/composition-api'
import { useRestatePlan } from '../RestatePlan'
import { EstimateDetail, Items } from '~/api/estimateRepository'
import { usePrice } from '~/composable/general/Price'
import {
  Enum別衿_着物持込,
  Enum別衿_着物レンタル,
} from '~/constants/estimate/graduationOptions'
import { ItemPlan } from '~/constants/enums/itemPlan'
import ProductId from '~/constants/enums/productClassification'
import { EstimateSinglePriceType } from '~/constants/enums/estimateSinglePriceType'
import { EstimateAddition } from '~/constants/enums/estimateAddition'
import { EstimatePlan } from '~/constants/enums/estimatePlan'

type Betsueri = KimonoRental | KimonoBringIn

type KimonoRental = {
  type: '着物レンタル'
  optionId種別: number | null
  optionId形態: number | null
  optionId長襦袢: number | null
  レンタル価格: string | null
  flags: Flags
}

type KimonoBringIn = {
  type: '着物持込'
  optionId種別: number | null
  optionId長襦袢: number | null
  optionId見積表示: number | null
  レンタル価格: string | null
  購入価格: string | null
  flags: Flags
}

type Flags = ReturnType<typeof getConditionalFlags>

export const useRestate別衿 = (estimate: Ref<EstimateDetail | undefined>) => {
  const restatePlan = useRestatePlan(estimate)

  return computed((): Betsueri | undefined => {
    if (!estimate.value?.items) {
      return
    }
    let betsueri長襦袢: number | null = null
    const is長襦袢 = estimate.value?.additions?.some((a) => {
      return a.addition_type === EstimateAddition.separateCollarProcessing
    })

    // 振袖がレンタルかどうか
    const is着物レンタル = EstimatePlan.is着物レンタル(estimate.value.plan)

    // 振袖が持込の場合のみ、長襦袢を復元する
    if (!is着物レンタル) {
      betsueri長襦袢 = is長襦袢
        ? Enum別衿_着物持込.長襦袢.いいえ
        : Enum別衿_着物持込.長襦袢.はい
    }

    const betueri = estimate.value.items.find(
      (item) => item.product_type_id === ProductId.betueri
    )

    if (!betueri) {
      return
    }

    if (restatePlan.value?.flags.has着物レンタル) {
      //* 着物レンタル時のデータ作成関数
      return formatKimonoRental(betueri, betsueri長襦袢)
    } else {
      //* 着物持込のプラン時のデータ作成関数
      return formatKimonoBringIn(betueri, betsueri長襦袢)
    }
  })
}

/**
 * 復元条件に関わる条件式を定義する
 */
const getConditionalFlags = (item: Items) => {
  const is持込 = item.plan === Enum別衿_着物レンタル.種別.お持込
  const isランクアップ = item.plan === ItemPlan.rankUpOutPremium
  const isセット内 = item.plan === ItemPlan.inSet
  const is単品レンタル =
    item.price_type === EstimateSinglePriceType.singleRentalPrice
  const is単品購入 =
    item.price_type === EstimateSinglePriceType.singlePurchasePrice

  return {
    is持込,
    isセット内,
    is単品レンタル,
    is単品購入,
    isランクアップ,
  }
}

const formatKimonoBringIn = (item: Items, betsueri長襦袢: number | null) => {
  const { paramToString } = usePrice()
  const flags = getConditionalFlags(item)
  const 種別 = flags.is持込
    ? Enum別衿_着物持込.種別.お持込
    : Enum別衿_着物持込.種別.単品レンタル単品購入

  const items: KimonoBringIn = {
    type: '着物持込',
    optionId種別: 種別,
    optionId長襦袢: betsueri長襦袢,
    optionId見積表示: flags.is単品レンタル
      ? Enum別衿_着物持込.見積表示.単品レンタル価格
      : Enum別衿_着物持込.見積表示.単品購入価格,
    レンタル価格: paramToString(item.rental_price),
    購入価格: paramToString(String(item.purchase_price)),
    flags,
  }

  return items
}

const formatKimonoRental = (item: Items, betsueri長襦袢: number | null) => {
  const { paramToString } = usePrice()
  const flags = getConditionalFlags(item)

  const items: KimonoRental = {
    type: '着物レンタル',
    optionId種別: flags.is持込
      ? Enum別衿_着物レンタル.種別.お持込
      : Enum別衿_着物レンタル.種別.レンタル,
    optionId形態: flags.isランクアップ
      ? Enum別衿_着物レンタル.形態.ランクアップ
      : Enum別衿_着物レンタル.形態.セット内,
    optionId長襦袢: betsueri長襦袢,
    レンタル価格: paramToString(item.rental_price),
    flags,
  }

  return items
}
