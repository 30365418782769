import { ActionTree } from 'vuex'
import { RootState, AccessoriesFullsetState, Accessory } from '../../types'

const actions: ActionTree<AccessoriesFullsetState, RootState> = {
  setResultSelectionsJK: ({ commit }, resultSelectionsJK) => {
    commit('setResultSelectionsJK', resultSelectionsJK)
  },

  setResultSelectionsWphoto: ({ commit }, resultSelectionsJK) => {
    commit('setResultSelectionsWphoto', resultSelectionsJK)
  },

  setResultSelectionsMotherAndDaughterPlan: (
    { commit },
    resultSelectionsMotherAndDaughterPlan
  ) => {
    commit(
      'setResultSelectionsMotherAndDaughterPlan',
      resultSelectionsMotherAndDaughterPlan
    )
  },

  setTabiColor: ({ commit }, selectedColor) => {
    commit('setTabiColor', selectedColor)
  },
}

export default actions
