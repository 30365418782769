import {
  ref,
  watch,
  onMounted,
  nextTick,
  onBeforeUnmount,
} from '@nuxtjs/composition-api'

export const useOnline = () => {
  const isOnline = ref(true)

  const handleOnline = () => (isOnline.value = true)
  const handleOffline = () => (isOnline.value = false)

  onMounted(() => {
    nextTick(() => (isOnline.value = navigator.onLine))
    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('online', handleOnline)
    window.removeEventListener('offline', handleOffline)
  })

  watch(
    isOnline,
    () => {
      if (isOnline.value) {
        // オンラインなので無視
      } else {
        window.location.reload()
      }
    },
    { immediate: true }
  )
}
