import { MutationTree } from 'vuex'
import { EstimateListState } from '../../../types'
import { getInitialFields } from './state'

const mutations: MutationTree<EstimateListState> = {
  // NOTE: useComingStore用に追加
  setState: (
    state,
    payload: { stateName: keyof EstimateListState['fields']; value: any }
  ) => {
    let fields = state.fields as any
    fields[payload.stateName] = payload.value
  },

  setAllState: (state, values: EstimateListState['fields']) => {
    state.fields = values
  },

  initAllState: (state) => {
    state.fields = getInitialFields()
  },

  initIsZoribagPriceCalculated: (state) => {
    state.fields.isZoriBagPriceCalculated = false
  },

  initIsZoriBagSetPrice: (state) => {
    state.fields.isZoriBagSetPrice = false
  },
}

export default mutations
