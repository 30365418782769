import { computed } from '@nuxtjs/composition-api'
import {
  getBadgeText,
  getItemPlan,
  getItemPlanFlags,
  getItemPrices,
  getPriceTypeText,
} from '~/composable/estimate/repository/comingOfAge/states/utilities'
import { ObiState } from '~/store/types'
import { useComingStore } from '../../ComingOfAgeStore'
import { useScreenTypeFlags } from '~/composable/estimate/domain/ComingOfAge/ScreenTypeFlags'
import { BaseItemState, PlanFlags } from './types'
import { PLAN } from '~/constants/estimate/comingOfAge'
import ProductClassification from '~/constants/enums/productClassification'
import { KimonoSearchResult } from '~/composable/api/useSearchProduct'
import { CopiedEstimateState } from '~/store/Estimate/ComingOfAgeCeremony/CopiedEstimate/state'
import { EstimateAddition } from '~/constants/enums/estimateAddition'

type Obi = BaseItemState & {
  品種区分: 2
  仕立て上り商品: KimonoSearchResult | null
  帯仕立て代: number
  flags: {
    is仕立て上り: boolean
    is帯仕立て代: boolean
  }
}

export const useState帯 = () => {
  const store = useComingStore<ObiState>('Obi')
  const values帯 = store.getAll()
  const priceTable = store.getPrices()
  const { showTypeフルセット, showType写真のみレンタル, showType持ち込み } =
    useScreenTypeFlags()
  const valuesコピー元見積 =
    useComingStore<CopiedEstimateState>('CopiedEstimate').getAll()

  return computed(() => {
    const flags = {
      isフルセット: showTypeフルセット.value,
      is写のみレンタル: showType写真のみレンタル.value,
      is持ち込み: showType持ち込み.value,
    }
    return getState帯(
      values帯.value,
      flags,
      priceTable.estimate.帯仕立て代,
      valuesコピー元見積.value ? valuesコピー元見積.value.estimate : null
    )
  })
}

export const getState帯 = (
  values帯: ObiState,
  planFlags: PlanFlags,
  帯仕立て代: number,
  copiedEstimate: CopiedEstimateState['estimate']
) => {
  const { selections, input, product } = values帯
  const itemFlags = getItemPlanFlags(selections)
  const is仕立て上り = selections.includes(PLAN.YES)
  const itemPlan = getItemPlan(selections)

  // NOTE: プロパー（非仕立て上り）で見積した帯でも、契約後はproduct_idが割り当てられて仕立て上り帯として復元されてしまうため、
  // 復元見積かつ、現在選ばれている帯のproduct_idと元の見積に乗っているproduct_idが一致する場合（=商品変更されていない）は
  // 帯仕立て代をそのまま乗せるための条件式
  const had帯仕立て代 = copiedEstimate?.additions?.some(
    (a) => a.addition_type === EstimateAddition.obiShitate
  )
  const copied帯 = copiedEstimate?.items?.find(
    (item) => item.product_type_id === ProductClassification.obi
  )
  // コピー後も帯仕立て代がつく条件を維持しているか
  const stillHas帯仕立て代 =
    !!copied帯 &&
    copied帯.product_id === product?.product_id &&
    selections[2] === PLAN.SINGLE_BUY_PRICE
  const has帯仕立てInCopied見積 = !!had帯仕立て代 && stillHas帯仕立て代

  const has帯仕立てInNew見積 =
    !is仕立て上り && selections[2] === PLAN.SINGLE_BUY_PRICE
  const is帯仕立て代 =
    has帯仕立てInNew見積 || (copiedEstimate && has帯仕立てInCopied見積)

  const obi: Obi = {
    品目名: ProductClassification.toWord(ProductClassification.obi),
    品種区分: ProductClassification.obi,
    商品名: '',
    小物プラン: itemPlan,
    レンタル価格: 0,
    購入価格: 0,
    プレミアムレンタル価格: input.premiumRentalPrice,
    プレミアム購入価格: input.premiumBuyPrice,
    見積表示: null,
    表示価格: null,
    label: getBadgeText(itemPlan),
    cellLabel: getPriceTypeText(selections),
    仕立て上り商品: null,
    帯仕立て代,
    flags: {
      isランクアップ:
        itemFlags.isランクアップPP内 || itemFlags.isランクアップPP外,
      is仕立て上り: is仕立て上り,
      is帯仕立て代,
    },
  }

  if (!itemFlags.isお持込) {
    if (is仕立て上り && product) {
      obi.仕立て上り商品 = product
      obi.商品名 = product?.product_name
    }
    if (!is仕立て上り && input.productName) {
      obi.商品名 = input.productName
    }
  }

  const updatedPrices = getItemPrices(values帯, obi, planFlags)
  obi.レンタル価格 = updatedPrices.レンタル価格
  obi.購入価格 = updatedPrices.購入価格
  obi.プレミアムレンタル価格 = updatedPrices.プレミアムレンタル価格
  obi.プレミアム購入価格 = updatedPrices.プレミアム購入価格
  obi.見積表示 = updatedPrices.見積表示
  obi.表示価格 = updatedPrices.表示価格

  return obi
}
