import Vue from 'vue'
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import ja from 'vee-validate/dist/locale/ja.json'
import { usePrice } from '~/composable/general/Price'
import dayjs from 'dayjs'

// 金額整形
const priceClass = usePrice()

localize('ja', ja)

// 必要なルールをインポート
extend('email', { ...email, message: 'メールアドレス形式で入力してください' })

extend('required', {
  ...required,
  message: 'この入力項目は必須です',
})

extend('requiredArray', {
  validate(value) {
    if (value.length) {
      return true
    }
    return 'この入力項目は必須です'
  },
})

extend('requiredItemDesc', {
  ...required,
  message: '特徴を入力してください。',
})

extend('maxLength', {
  validate(value, max) {
    if (value.length <= max) {
      return true
    }
    return `${max}文字以下で入力してください`
  },
})

// 入力された金額が8桁以下かどうかのバリデーション
extend('8digitsOrLess', {
  validate(value) {
    const formattedValue = priceClass.toNumber(value)
    if (formattedValue > 100000000) {
      return `¥100,000,000 未満で入力してください。`
    }
    return true
  },
})

//フリー入力つき商品選択用のバリデーション
extend('selectPriceWithFreeInput', {
  validate(value) {
    if (typeof value === 'string') {
      const formattedValue = priceClass.toNumber(value)
      if (formattedValue > 100000000) {
        return `¥100,000,000 未満で入力してください。`
      }
    }
    return true
  },
})

// 郵便番号のバリデーション
extend('postalCode', {
  validate(value) {
    if (value.length > 7) {
      return `郵便番号（ハイフンなし）を入力してください。`
    }
    return true
  },
})

// カナ判定
extend('kana', {
  validate(value) {
    const regex = /^([ァ-ヶ]|ー)+$/
    if (regex.test(value)) {
      return true
    }
    return `カタカナで入力してください`
  },
})

extend('JapaneseOrEnglishOnly', {
  validate(value) {
    const regex =
      /^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf\a-zA-Z]+$/
    if (regex.test(value)) {
      return true
    }
    return '半角、記号、数字、スペースは入力できません。'
  },
})

// 行頭、行末のスペースのチェック
extend('spaceCheck', {
  validate(value) {
    const regex = /^\s+|\s+$/g
    if (regex.test(value)) {
      return '半角、全角スペースを除いて入力してください。'
    }
    return true
  },
})

extend('birthDateCheck', {
  validate(value) {
    const today = dayjs()
    const inputDate = dayjs(value)
    if (inputDate.isBefore(today)) {
      return true
    }
    return '今日の日付を超えています。'
  },
})

extend('phoneNumber', {
  validate(value) {
    const regex = /^0\d{9,10}$/
    if (regex.test(value)) {
      return true
    }
    return '正しい電話番号を入力してください'
  },
})

extend('bustOrHip', {
  validate(value) {
    if (value.length > 1) {
      return true
    }
    return '2桁以上入力してください'
  },
})

extend('requiredSelect', {
  validate(value) {
    if (!value) return '選択必須です。'
    return true
  },
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
