import { ProductCategory } from './productCategory'

/**
 * [プラン]小物
 * 成人式・卒業式APIで使用
 * ※参列側ではDB側の都合で送っているが、フロント側での判定にはItemPriceTypeを使うこと
 */
export class ItemPlan {
  /** セット内 */
  static readonly inSet = 1
  /** レンタルのみランクアップ（購入はセット内） */
  static readonly onlyRentalRankUp = 2
  /** ランクアップ（プレミアム内） */
  static readonly rankUpInPremium = 3
  /** ランクアップ（プレミアム外） */
  static readonly rankUpOutPremium = 4
  /** 未定 */
  static readonly toBeDecided = 5
  /** お持込 */
  static readonly bringIn = 6
  /** なし（帯・帯締め・帯揚げ用） */
  static readonly noUse = 9

  static toWord = (val: number) => {
    switch (val) {
      case ItemPlan.inSet:
        return 'セット内'
      case ItemPlan.onlyRentalRankUp:
        return 'レンタルのみランクアップ（購入はセット内）'
      case ItemPlan.rankUpInPremium:
        return 'ランクアップ（プレミアム内）'
      case ItemPlan.rankUpOutPremium:
        return 'ランクアップ（プレミアム外）'
      case ItemPlan.toBeDecided:
        return '未定'
      case ItemPlan.bringIn:
        return 'お持込'
      case ItemPlan.noUse:
        return 'なし'
      default:
        return 'ー'
    }
  }

  static toWordForCheckList = (val: number | null) => {
    switch (val) {
      case ItemPlan.inSet:
        return 'セット内'
      case ItemPlan.onlyRentalRankUp:
        return 'レンタルのみランクアップ'
      case ItemPlan.rankUpInPremium:
      case ItemPlan.rankUpOutPremium:
        return 'ランクアップ'
      case ItemPlan.bringIn:
        return 'お持込'
      default:
        return ''
    }
  }
}

/**
 * 小物金額取得APIで使用
 */
//  1:セット内 2:レンタルのみランクアップ 3:ランクアップ（プレミアム内）4:ランクアップ（プレミアム外）（補足：卒業式のランクアップ含む） 5: 現品
export class ItemPriceInfoType {
  /** セット内 */
  static readonly inSet = 1
  /** レンタルのみランクアップ */
  static readonly onlyRentalRankUp = 2
  /** ランクアップ（プレミアム内） */
  static readonly rankUpInPremium = 3
  /** ランクアップ（プレミアム外）（補足：卒業式のランクアップ含む */
  static readonly rankUpOutPremium = 4
  /** 現品 */
  static readonly genpin = 5

  static toWord = (val: number | null) => {
    switch (val) {
      case ItemPriceInfoType.inSet:
        return 'セット内'
      case ItemPriceInfoType.onlyRentalRankUp:
        return 'レンタルのみランクアップ'
      case ItemPriceInfoType.rankUpInPremium:
        return 'プレミアム内'
      case ItemPriceInfoType.rankUpOutPremium:
        return 'プレミアム外'
      case ItemPriceInfoType.genpin:
        return '現品'
      default:
        return 'ー'
    }
  }
}

/**
 * 小物のprices内price_type
 * 参列見積作成・詳細で使用
 */
export class ItemPriceType {
  /** レンタル */
  static readonly rental = 1
  /** 購入 */
  static readonly purchase = 2
  /** ランクアップレンタル（PP内） */
  static readonly rankUpInRental = 3
  /** ランクアップ購入（PP内） */
  static readonly rankUpPurchase = 4
  /** 単品レンタル */
  static readonly rentalTanpin = 5
  /** 単品購入 */
  static readonly buyTanpin = 6
  /** プレミアムパックレンタル（PP外） */
  static readonly PremiumRental = 7
  /** プレミアムパック購入（PP外） */
  static readonly PremiumPurchace = 8
  /** お持ち込み ※APIには存在しないが、上記と並ぶ選択肢のIDで使う用 */
  static readonly bringIn = 9

  static toWord = (val: number | null) => {
    switch (val) {
      case ItemPriceType.rental:
        return 'レンタル'
      case ItemPriceType.purchase:
        return '購入'
      case ItemPriceType.rankUpInRental:
        return 'ランクアップレンタル|プレミアム内'
      case ItemPriceType.rankUpPurchase:
        return 'ランクアップ購入|プレミアム内'
      case ItemPriceType.rentalTanpin:
        return '単品レンタル'
      case ItemPriceType.buyTanpin:
        return '単品購入'
      case ItemPriceType.PremiumRental:
        return 'ランクアップレンタル|プレミアム外'
      case ItemPriceType.PremiumPurchace:
        return 'ランクアップ購入|プレミアム外'
      case ItemPriceType.bringIn:
        return 'お持込'
      default:
        return 'ー'
    }
  }

  static toWordForKitsukeKomono = (
    planType: number | null,
    priceType: number | null,
    productClassificationId?: number | null
  ) => {
    const isInSetOrRankup = [
      ItemPlan.inSet,
      ItemPlan.onlyRentalRankUp,
      ItemPlan.rankUpInPremium,
      ItemPlan.rankUpOutPremium,
    ].includes(planType ?? 0)

    if (isInSetOrRankup) {
      switch (priceType) {
        case ItemPriceType.rental:
          return 'レンタル'
        case ItemPriceType.purchase:
          return '購入'
        default:
          if (
            productClassificationId === ProductCategory.購入 ||
            productClassificationId === ProductCategory.rankupPurchase
          ) {
            return '購入'
          }

          return ''
      }
    } else {
      switch (priceType) {
        case ItemPriceType.rental:
          return '単品レンタル'
        case ItemPriceType.purchase:
          return '単品購入'
        default:
          return ''
      }
    }
  }

  static toWordForAttendence = (type: number | undefined) => {
    switch (type) {
      case ItemPriceType.rental:
        return 'レンタル'
      case ItemPriceType.rankUpInRental:
      case ItemPriceType.rankUpPurchase:
        return 'ランクアップ | プレミアムパック内'
      case ItemPriceType.rentalTanpin:
        return '単品レンタル'
      case ItemPriceType.buyTanpin:
        return '単品購入'
      default:
        return 'お持込'
    }
  }

  static isRental = (val: number | null) => {
    if (!val) return false
    return [
      ItemPriceType.rental,
      ItemPriceType.rankUpInRental,
      ItemPriceType.rentalTanpin,
      ItemPriceType.PremiumRental,
    ].includes(val)
  }

  static isPurchase = (val: number | null) => {
    if (!val) return false
    return [
      ItemPriceType.purchase,
      ItemPriceType.rankUpPurchase,
      ItemPriceType.buyTanpin,
      ItemPriceType.PremiumPurchace,
    ].includes(val)
  }

  static isBringin = (val: number | null) => {
    if (!val) return false
    return [ItemPriceType.bringIn].includes(val)
  }
}

export const RANKUP = 'ランクアップ'

export const SINGLE_SERVICE_TYPE = {
  RENTAL: '単品レンタル',
  BUY: '単品購入',
} as const

export type PriceTypeList =
  | typeof ItemPriceType.rental
  | typeof ItemPriceType.purchase
  | typeof ItemPriceType.rankUpInRental
  | typeof ItemPriceType.rankUpPurchase
  | typeof ItemPriceType.rentalTanpin
  | typeof ItemPriceType.buyTanpin
  | typeof ItemPriceType.PremiumRental
  | typeof ItemPriceType.PremiumPurchace
  | typeof ItemPriceType.bringIn

export type PriceTypeListBySingleItem =
  | typeof ItemPriceType.rentalTanpin
  | typeof ItemPriceType.buyTanpin

/**
 * 小物商品一覧取得APIで使用（半巾帯）
 */
export class HanhabaObiPlan {
  /** セット内 */
  static readonly inSet = 1
  /** ランクアップ */
  static readonly rankUp = 2

  static toWord = (val: number) => {
    switch (val) {
      case HanhabaObiPlan.inSet:
        return 'セット内'
      case HanhabaObiPlan.rankUp:
        return 'ランクアップ'
      default:
        return 'ー'
    }
  }
}
