import { Photo as PhotoPayload } from '~/api/contractRepository'
import { Photo } from '~/store/Estimate/GraduationCeremony/types'

type PhotoState = {
  アップロードあり: boolean
  全体写真: Photo[]
  衿元写真: Photo[]
}
export const getFormattedPhotos = (photoState: PhotoState) => {
  let formatted全体写真: PhotoPayload[] = []
  let formatted衿元写真: string = ''

  const has全体写真URL = photoState.全体写真.some((photo) => !!photo.remoteSrc)
  const has襟元写真URL = photoState.衿元写真.some((photo) => !!photo.remoteSrc)
  if (photoState.アップロードあり) {
    if (has全体写真URL) {
      formatted全体写真 = photoState.全体写真.map((photo) => {
        return {
          url: photo.remoteSrc,
          is_main_picture: photo.isSelected,
        }
      })
    }
    if (has襟元写真URL) {
      formatted衿元写真 = photoState.衿元写真[0].remoteSrc
    }
  }

  return { formatted全体写真, formatted衿元写真 }
}
