import { MutationTree } from 'vuex'
import { getInitialStore, State } from './state'

const mutations: MutationTree<State> = {
  // ** All Input Data **
  setData: (state, data) => {
    state.data = data
  },

  // ** 見積選択 **
  setSelectEstimate: (state, selectEstimate) => {
    state.selectEstimate = selectEstimate
  },

  // ** タブ **
  setTabList: (state, tabList) => {
    state.tabList = tabList
  },

  // ** ご利用者タブ内サブタブ **
  setUserTabList: (state, userTabList) => {
    state.userTabList = userTabList
  },

  // ** ご利用者のindex **
  setCurrentIndex: (state, index) => {
    state.data.currentUserIndex = index
  },

  // ** 友の会割引(2) **
  setDiscountList: (state, discountList) => {
    state.discountList = discountList
  },

  // ** 契約書に紐づいている見積書ID **
  setEstimateIdList: (state, estimateIdList) => {
    state.estimateIdList = estimateIdList
  },

  // ** 最終ページ **
  setFinalPageList: (state, finalPageList) => {
    state.finalPageList = finalPageList
  },

  // ** 契約日 **
  setContractDate: (state, contractDate) => {
    state.data.contractDate = contractDate
  },

  initAll: (state) => {
    const initial = getInitialStore()
    state.data = initial.data
    state.selectEstimate = initial.selectEstimate
    state.tabList = initial.tabList
    state.userTabList = initial.userTabList
    state.data.currentUserIndex = initial.data.currentUserIndex
    state.finalPageList = initial.finalPageList
    state.discountList = initial.discountList
    state.estimateIdList = initial.estimateIdList
    state.contractId = initial.contractId
    state.contractTotalPrice = initial.contractTotalPrice
  },
  setContractId: (state, contractId) => {
    state.contractId = contractId
  },

  setContractTotalPrice: (state, contractTotalPrice) => {
    state.contractTotalPrice = contractTotalPrice
  },

  setPaymentDeadline: (state, paymentDeadline) => {
    state.data.importantMatter.paymentDeadline = paymentDeadline
  },

  setCoolingOffPeriod: (state, coolingOffPeriod) => {
    state.data.importantMatter.coolingOffPeriod = coolingOffPeriod
  },
}

export default mutations
