import { createLogger, ActionContext } from 'vuex'

const debug = process.env.NODE_ENV !== 'production'

// Vuex厳格モードをオフ（配列の更新が上手くいかないので追加しています><）
export const strict = false

export const store = {
  state: {
    version: '1.0.0',
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
}

export const actions = {
  nuxtClientInit({ commit }: ActionContext<any, any>) {
    const storeData = sessionStorage.getItem('vuex-persistedstate')
    const data = storeData ? JSON.parse(storeData) : null
    if (data) {
      commit('initialize', data)
    }

    // 別タブで開いた場合のためshopデータだけlocalStorageで保持
    const settingShop = (() => {
      const json = localStorage.getItem('vuex-persistedstate')
      return json ? JSON.parse(json).settingShop : null
    })()
    if (settingShop) commit('setShop', settingShop)
  },
}
