import { computed, Ref } from '@nuxtjs/composition-api'
import { EstimateDetail, Items } from '~/api/estimateRepository'
import { ItemPlan } from '~/constants/enums/itemPlan'
import ProductId from '~/constants/enums/productClassification'
import { PLAN } from '~/constants/estimate/comingOfAge'
import { useRestatePlan, PlanFlags } from '../RestatePlan'
import { EstimateSinglePriceType } from '~/constants/enums/estimateSinglePriceType'
import { EstimateAddition } from '~/constants/enums/estimateAddition'
import { EstimatePlan } from '~/constants/enums/estimatePlan'

export const useRestate別衿 = (estimate: Ref<EstimateDetail | undefined>) => {
  const restateプラン = useRestatePlan(estimate)

  return computed(() => {
    if (!estimate.value?.items || !restateプラン.value) {
      return null
    }

    const betsueriItem = estimate.value.items.find(
      (item) => item.product_type_id === ProductId.betueri
    )

    if (!betsueriItem) {
      return null
    }

    let betsueri長襦袢: number = 0
    const is長襦袢 = !!estimate.value?.additions?.some((a) => {
      return a.addition_type === EstimateAddition.separateCollarProcessing
    })

    // 着物がレンタルかどうか
    const is着物レンタル = EstimatePlan.is着物レンタル(estimate.value.plan)

    // 振袖が持込の場合のみ、長襦袢を復元する
    if (!is着物レンタル) {
      betsueri長襦袢 = is長襦袢 ? PLAN.BETSUERI_NO : PLAN.BETSUERI_YES
    }

    const {
      rental_price,
      purchase_price,
      plan,
      price_type,
      product_name,
      premium_rental_price,
      premium_purchase_price,
    } = betsueriItem

    return {
      optionId種別: getOptionIdOf種別(plan, restateプラン.value.flags),
      optionId形態: getOptionIdOf形態(plan),
      optionId見積選択: getOptionIdOf見積表示(price_type),
      optionId長襦袢: betsueri長襦袢,
      商品名: product_name ?? '',
      レンタル価格: Number(rental_price),
      プレミアムレンタル価格: Number(premium_rental_price ?? 0),
      購入価格: Number(purchase_price ?? 0),
      プレミアム購入価格: Number(premium_purchase_price ?? 0),
      flags: getConditionalFlags(betsueriItem, restateプラン.value.flags),
    }
  })
}

/**
 * 復元条件に関わる条件式を定義する
 */
const getConditionalFlags = (item: Items, planFlags: PlanFlags) => {
  const { is振袖持込, isフルセットプラン } = planFlags
  const is持込 = item.plan === ItemPlan.bringIn

  const isセット内 = !is振袖持込 && !is持込 && item.plan === ItemPlan.inSet
  const isレンタルのみランクアップ =
    isフルセットプラン && !is持込 && item.plan === ItemPlan.onlyRentalRankUp
  const isランクアップPP内 =
    !is振袖持込 && !is持込 && item.plan === ItemPlan.rankUpInPremium
  const isランクアップPP外 =
    !is振袖持込 && !is持込 && item.plan === ItemPlan.rankUpOutPremium
  return {
    is持込,
    isセット内,
    isレンタルのみランクアップ,
    isランクアップPP内,
    isランクアップPP外,
    is仕立て上り: !!item.product_id,
  }
}

/**
 * 選択肢系の項目の初期値(選択されたoptionのid)を再計算する関数群
 */
const getOptionIdOf種別 = (itemPlan: Items['plan'], planFlags: PlanFlags) => {
  // お持込は全パターン共通ID、その他は振袖のプランによって変動する
  if (itemPlan === ItemPlan.bringIn) {
    return PLAN.BRING_IN
  } else if (itemPlan === ItemPlan.toBeDecided) {
    return PLAN.SINGLE_RENTAL
  } else if (planFlags.isフルセットプラン) {
    return PLAN.SET_OR_RENTAL
  } else if (planFlags.is振袖持込) {
    return PLAN.SINGLE_RENTAL
  } else if (planFlags.is写のみレンタル) {
    return PLAN.RENTAL
  } else {
    return 0
  }
}

const getOptionIdOf形態 = (itemPlan: Items['plan']) => {
  switch (itemPlan) {
    case ItemPlan.inSet:
      return PLAN.IN_THE_SET
    case ItemPlan.onlyRentalRankUp:
      return PLAN.RANK_UP_ONLY_RENTAL
    case ItemPlan.rankUpInPremium:
      return PLAN.RANK_UP_IN_PREMIUM
    case ItemPlan.rankUpOutPremium:
      return PLAN.RANK_UP_OUT_PREMIUM
    default:
      return 0
  }
}

const getOptionIdOf見積表示 = (priceType: Items['price_type']) => {
  switch (priceType) {
    case EstimateSinglePriceType.singleRentalPrice:
      return PLAN.SINGLE_RENTAL_PRICE
    case EstimateSinglePriceType.singlePurchasePrice:
      return PLAN.SINGLE_BUY_PRICE
    default:
      return 0
  }
}
