import { ContractRemarkState } from '../../types'

const state = (): ContractRemarkState => ({
  input: {
    text: '',
  },
  isValidate: null,
})

export default state
