import { computed, Ref } from '@nuxtjs/composition-api'
import { usePrice } from 'storybook/utils/utils'
import { EstimateDetail } from '~/api/estimateRepository'
import { Enum割引 } from '~/constants/estimate/graduationOptions'
import { DISCOUNT_TYPE } from '~/constants/estimate/config'

export const useRestateDiscount = (
  estimate: Ref<EstimateDetail | undefined>
) => {
  const { paramToString } = usePrice()

  return computed(() => {
    if (!estimate.value) return null

    const discount = estimate.value?.discounts?.find((d) => {
      return d.discount_type === DISCOUNT_TYPE.GENERAL.id
    })

    return {
      optionId割引: discount ? Enum割引.適用.する : Enum割引.適用.しない,
      割引額: discount ? paramToString(Number(discount.amount)) : null,
    }
  })
}
