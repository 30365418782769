import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { FurisodeState, ObiState } from '~/store/types'

export const useRestoreWPhoto振袖 = () => {
  const store = useComingStore<ObiState>('WphotoPlan/WphotoFurisodes')

  const restoreWPhoto振袖 = (hurisode: Restate['w_photo_plan']['振袖']) => {
    if (!hurisode) return

    /* 初期値を設定 ※必ず入力される値はここで設置 */
    const formValues: FurisodeState = {
      /**
       * NOTE: selections = patternLine
       * ↓保持される選択肢のindex
       * [0]種別
       * [1]裄補正
       * [2]袖補正
       */
      selections: [0, 0, 0],
      product: null,
      input: {
        productName: '',
        rentalPrice: 0,
      },
      price: {
        buy: 0,
        rental: 0,
        id: 0, // フリー入力を選択
      },
      isFree: true, // フリー入力を選択
      isFurisodeOnHold: false, // 保留状態では見積作成できない仕様のため、復元時においては振袖保留のFlagは常にfalse
      isValidate: null,
    }

    /** NOTE: Wフォトは仕立て上がりプランしか存在しないため条件がない  */
    formValues.selections[0] = hurisode.optionsIdレンタルorお持込
    if (!hurisode.isお持込) {
      formValues.input.rentalPrice = hurisode.レンタル価格
      formValues.product = {
        type: '仕立上り',
        ...hurisode.仕立て上り検索結果[0],
      }
      formValues.selections[1] = hurisode.optionId裄補正
      formValues.selections[2] = hurisode.optionId袖補正
    }

    /* ストアに内容を保存する */
    store.setOf<FurisodeState['selections']>(
      'setSelection',
      formValues.selections
    )
    store.setOf<FurisodeState['product']>('setProduct', formValues.product)
    store.setOf<FurisodeState['input']>('setInput', formValues.input)
    store.setOf<FurisodeState['price']>('setPrice', formValues.price)
    store.setOf<FurisodeState['isFree']>('setIsFree', formValues.isFree)
    store.setIsValidate(false)
  }
  return { restoreWPhoto振袖 }
}
