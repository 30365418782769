import { photoPlan } from '~/api/estimateRepository'
import { Enumお写真プラン } from '~/constants/estimate/graduationOptions'
import { EstimateState } from '~/composable/estimate/repository/graduation/EstimateState'

export const usePhotoPlanFormatter = (estimateState: EstimateState) => {
  const getFormattedPhotoPlan = () => {
    const {
      写真撮影あり,
      選択商品番号,
      デザインアルバム,
      アクリルスタンド,
      六つ切り台紙あり,
      六つ切り台紙なし,
      キャビネ台紙あり,
      キャビネ台紙なし,
      hasUSB,
    } = estimateState.value.お写真プラン
    const { 商品選択 } = Enumお写真プラン

    if (!写真撮影あり) return
    const photoPlan: photoPlan = {
      mutsugiri: {
        with_daishi: [],
        no_daishi: [],
      },
      cabinet: {
        with_daishi: [],
        no_daishi: [],
      },
      album: {
        pose_number: null,
        number_of_books: null,

        referral_bonus_pose_number: null,
      },
      acryl_stand: [],
      is_buying_final_photo_data: false,
    }

    photoPlan.is_buying_final_photo_data = hasUSB

    if (選択商品番号.includes(商品選択.デザインアルバム)) {
      if (デザインアルバム) {
        photoPlan.album = {
          pose_number: デザインアルバム.基本ポーズ数,
          number_of_books: デザインアルバム.基本冊数,
          referral_bonus_pose_number: デザインアルバム.紹介者特典ポーズ数,
        }
      }
    }

    if (選択商品番号.includes(商品選択.アクリルスタンド)) {
      if (アクリルスタンド) {
        photoPlan.acryl_stand = [
          {
            size: 1,
            number_of_pieces: アクリルスタンド.A4,
          },
          {
            size: 2,
            number_of_pieces: アクリルスタンド.A5,
          },
        ]
      }
    }

    if (選択商品番号.includes(商品選択.六切り台紙あり)) {
      六つ切り台紙あり.map((o, i) => {
        if (photoPlan?.mutsugiri?.with_daishi) {
          photoPlan.mutsugiri.with_daishi.push({
            pose_number: o.pose,
            number_of_books: o.book,
          })
        }
      })
    }

    if (選択商品番号.includes(商品選択.六切り台紙なし)) {
      if (photoPlan?.mutsugiri?.no_daishi) {
        photoPlan.mutsugiri.no_daishi.push({ pose_number: 六つ切り台紙なし })
      }
    }

    if (選択商品番号.includes(商品選択.キャビネ台紙あり)) {
      キャビネ台紙あり.map((o, i) => {
        if (photoPlan?.cabinet?.with_daishi) {
          photoPlan.cabinet.with_daishi.push({
            pose_number: o.pose,
            number_of_books: o.book,
          })
        }
      })
    }

    if (選択商品番号.includes(商品選択.キャビネ台紙なし)) {
      if (photoPlan?.cabinet?.no_daishi) {
        photoPlan.cabinet.no_daishi.push({ pose_number: キャビネ台紙なし })
      }
    }

    return photoPlan
  }
  return { getFormattedPhotoPlan }
}
