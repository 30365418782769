import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { ObiState } from '~/store/types'

export const useRestoreWPhoto重ね衿 = () => {
  const storeレンタル = useComingStore<ObiState>(
    'WphotoPlan/WphotoKasaneeriRental'
  )
  const storeお持込 = useComingStore<ObiState>(
    'WphotoPlan/WphotoKasaneeriBring'
  )
  const store2 = useComingStore<ObiState>('WphotoPlan/WphotoKasaneeri2')
  const store3 = useComingStore<ObiState>('WphotoPlan/WphotoKasaneeri3')

  const setFirstStore = (
    target: typeof storeレンタル,
    formValues: ObiState
  ) => {
    target.setOf<ObiState['selections']>('setSelection', formValues.selections)
    target.setOf<ObiState['input']>('setInput', formValues.input)
    target.setOf<ObiState['price']>('setPrice', formValues.price)
    target.setOf<ObiState['isFree']>('setIsFree', formValues.isFree)
    setIsValidate()
  }
  const setAdditionalStore = (
    target: typeof storeお持込,
    formValues: ObiState
  ) => {
    target.setOf<ObiState['selections']>('setSelection', formValues.selections)
    target.setOf<ObiState['input']>('setInput', formValues.input)
    setIsValidate()
  }

  const setIsValidate = () => {
    storeレンタル.setIsValidate(false)
    storeお持込.setIsValidate(false)
    store2.setIsValidate(false)
    store3.setIsValidate(false)
  }

  const restoreWPhoto重ね衿 = (
    kasaneeris: Restate['w_photo_plan']['重ね衿']
  ) => {
    if (!kasaneeris) return

    kasaneeris.forEach((item, i) => {
      /* 初期値を設定 ※必ず入力される値はここで設置 */
      const formValues: ObiState = {
        /**
         * NOTE: selections = patternLine
         * ↓保持される選択肢のindex
         * [0]種別
         * [1]形態 || 見積表示
         */
        selections: [item.optionId種別, 0],
        product: null,
        input: {
          productName: '',
          buyPrice: 0,
          rentalPrice: 0,
          rankUpRentalPrice: 0,
          rankUpBuyPrice: 0,
          premiumRentalPrice: 0,
          premiumBuyPrice: 0,
        },
        // NOTE: フリー入力された値はpricesではなくinputの方に格納される
        price: {
          buy: 0,
          rental: 0,
          id: 0, // フリー入力を選択
        },
        isFree: false,
        isValidate: null,
      }

      /* 以下で入力内容の条件に基づき、必要な項目を埋めていく */
      if (item.type === 'firstOf振袖レンタル購入') {
        if (!item.flags.is持込) {
          formValues.selections[1] = item.optionId形態
        }

        if (item.flags.isセット内) {
          formValues.price.rental = item.レンタル価格
          formValues.input.rentalPrice = item.レンタル価格
        }

        if (item.flags.isランクアップPP内) {
          formValues.isFree = true
          formValues.input.rankUpRentalPrice = item.レンタル価格
        }

        if (item.flags.isランクアップPP外) {
          formValues.input.rankUpRentalPrice = item.レンタル価格
          formValues.input.premiumRentalPrice = item.プレミアムレンタル価格
        }

        /* ストアに内容を保存する */
        setFirstStore(storeレンタル, formValues)
      } else if (item.type === 'others') {
        if (!item.flags.is持込) {
          formValues.selections[1] = item.optionId見積表示
          formValues.input.rentalPrice = item.レンタル価格
          formValues.input.buyPrice = item.購入価格
        }

        /* ストアに内容を保存する */
        if (i === 0) setFirstStore(storeお持込, formValues)
        if (i === 1) setAdditionalStore(store2, formValues)
        if (i === 2) setAdditionalStore(store3, formValues)
      }
    })
  }

  return { restoreWPhoto重ね衿 }
}
