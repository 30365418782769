import { computed, useRoute } from '@nuxtjs/composition-api'
import { usePlanFlags } from '../../domain/Graduation/PlanFlags'
import { useEstimateState } from '../../repository/graduation/EstimateState'
import { urls } from '../../../../constants/urls'
import { useState袴 } from '~/composable/estimate/repository/graduation/states/State袴'
import { useState振袖二尺袖 } from '~/composable/estimate/repository/graduation/states/State振袖二尺袖'

/*
 * 卒業式側の入力内容確認・見積一覧ページなどで、今表示されているpathを取得するための関数
 */
export const useUrlNavigator = () => {
  const route = useRoute()
  const { hasHakamaRental, hasKimonoRental } = usePlanFlags()
  const { flags } = useState振袖二尺袖().value
  const state袴 = useState袴()
  const is袴持込 = computed(() => state袴.value.flags.isお持込)

  const estimateId = Number(route.value.params.estimate_id ?? 0)

  return {
    tabお名前: urls.見積書.卒業式.tabお名前(estimateId),
    tabプラン: urls.見積書.卒業式.tabプラン(estimateId),
    tab試着写真: urls.見積書.卒業式.tab試着写真(estimateId),
    tab商品設定: urls.見積書.卒業式.tab商品設定(estimateId),
    // NOTE: 持込時はタブが表示されないのでプランに飛ばす
    tab振袖二尺袖: flags.isお持込
      ? urls.見積書.卒業式.tabプラン(estimateId)
      : flags.is振袖
      ? urls.見積書.卒業式.tab振袖(estimateId)
      : urls.見積書.卒業式.tab二尺袖(estimateId),
    tab袴: is袴持込.value
      ? urls.見積書.卒業式.tabプラン(estimateId)
      : urls.見積書.卒業式.tab袴(estimateId),
    tab半幅帯: hasHakamaRental.value
      ? urls.見積書.卒業式.tab半巾帯_袴レンタル(estimateId)
      : urls.見積書.卒業式.tab半巾帯_袴持込(estimateId),
    tabs重ね衿: useTabs重ね衿(estimateId),
    tab別衿: hasKimonoRental.value
      ? urls.見積書.卒業式.tab別衿_着物レンタル(estimateId)
      : urls.見積書.卒業式.tab別衿_着物持込(estimateId),
    tab髪飾り: useTabs髪飾り(estimateId),
    tab草履ブーツ: hasKimonoRental.value
      ? urls.見積書.卒業式.tab草履ブーツ_着物レンタル(estimateId)
      : urls.見積書.卒業式.tab草履ブーツ_着物持込(estimateId),
    tab巾着バッグ: hasKimonoRental.value
      ? urls.見積書.卒業式.tab巾着バッグ_着物レンタル(estimateId)
      : urls.見積書.卒業式.tab巾着バッグ_着物持込(estimateId),
    tabお写真プラン: urls.見積書.卒業式.tabお写真プラン(estimateId),
    tabその他商品: urls.見積書.卒業式.tabその他商品(estimateId),
    tab割引: urls.見積書.卒業式.tab割引(estimateId),
    tab備考: urls.見積書.卒業式.tab備考(estimateId),
    tabお客様検索: urls.見積書.卒業式.tabお客様検索(estimateId),
  }
}

const useTabs重ね衿 = (estimateId: number) => {
  const state = useEstimateState()
  const { hasKimonoRental } = usePlanFlags()

  return state.value.重ね衿.map((_, i) => {
    const number = (i + 1) as 1 | 2 | 3
    if (number === 1) {
      return hasKimonoRental.value
        ? urls.見積書.卒業式.tab重ね衿_着物レンタル_1個目(estimateId)
        : urls.見積書.卒業式.tab重ね衿_着物持込(estimateId, number)
    } else {
      return hasKimonoRental.value
        ? urls.見積書.卒業式.tab重ね衿_着物レンタル_複数個目(estimateId, number)
        : urls.見積書.卒業式.tab重ね衿_着物持込(estimateId, number)
    }
  })
}

const useTabs髪飾り = (estimateId: number) => {
  const state = useEstimateState()

  return state.value.髪飾り.map((_, i) => {
    const number = (i + 1) as 1 | 2 | 3
    return urls.見積書.卒業式.tab髪飾り(estimateId, number)
  })
}
