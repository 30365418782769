import { GetterTree } from 'vuex'
import { FormValues半巾帯_袴持込 } from '~/composable/estimate/viewModel/graduation/Fields/Fields半巾帯_袴持込'
import { RootState } from '~/store/Estimate/GraduationCeremony/types'

const getters: GetterTree<{ fields: FormValues半巾帯_袴持込 }, RootState> = {
  getState: (state) => (stateName: keyof FormValues半巾帯_袴持込) => {
    return state.fields[stateName]
  },

  getAllState: (state) => {
    return state.fields
  },
}
export default getters
