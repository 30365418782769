import { MutationTree } from 'vuex'
import { PDFStoreInfo } from '../types'

const mutations: MutationTree<PDFStoreInfo> = {
  setPDFFailureFlg: (state, isPDFFailure) => {
    state.createPDFFailureFlg = isPDFFailure
  },

  delete: (state) => {
    state.createPDFFailureFlg = false
  },
}

export default mutations
