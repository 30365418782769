/** types */
import { EstimateDetail } from '~/api/estimateRepository'
import { ItemPlan } from '~/constants/enums/itemPlan'
import ProductClassification from '~/constants/enums/productClassification'

/**
 * PP適用時の重ね衿商品判定用
 * @returns
 */
export const useKasaneeriCondition = () => {
  /**
   * NOTE: 重ね衿の復元時に使用
   * プレミアムパックを選択している場合に、２品目のplanでランクアップかどうかを判定していたが
   * プレミアムパックを選択してない場合は、２品目のplanは未定のため見積一覧でプレミアムパックで
   * ランクアップを選択しているかの判別は不可
   * → １品目がセット内でプレミアム価格に金額が入力されていれば、２品目をランクアップとするよう判定を修正
   */
  const getSecondKasaneeriRankUp = (estimate: EstimateDetail): boolean => {
    if (!estimate) {
      return false
    }

    const isPPApplied = estimate.items?.some(
      (item) =>
        item.product_type_id === ProductClassification.kasaneeri &&
        item.plan === ItemPlan.inSet &&
        item.order_number === 1 &&
        (typeof item.premium_rental_price === 'string'
          ? parseInt(item.premium_rental_price) || false
          : item.premium_rental_price)
    )

    return isPPApplied ?? false
  }

  return {
    getSecondKasaneeriRankUp,
  }
}
