import { ActionTree } from 'vuex'
import { RootState, AccessoryPlanState } from '../../../types'

const actions: ActionTree<AccessoryPlanState, RootState> = {
  setSelection: ({ commit }, selection) => {
    commit('setSelection', selection)
  },

  setInput: ({ commit }, input) => {
    commit('setInput', input)
  },

  setPrice: ({ commit }, price) => {
    commit('setPrice', price)
  },

  setIsFree: ({ commit }, isFree) => {
    commit('setIsFree', isFree)
  },

  setIsValidate: ({ commit }, isValidate: boolean) => {
    commit('setIsValidate', isValidate)
  },
}

export default actions
