import { computed } from '@nuxtjs/composition-api'
import {
  getBadgeText,
  getItemPlan,
  getItemPlanFlags,
  getPriceTypeText,
  getWPhotoItemPrices,
} from '~/composable/estimate/repository/comingOfAge/states/utilities'
import { ObiState } from '~/store/types'
import ProductClassification from '~/constants/enums/productClassification'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { WPhotoBaseItemState } from '../types'
import { useState振袖 } from './State振袖'

type Obijime = WPhotoBaseItemState & {
  品種区分: 3
}

export const useState帯締め = () => {
  const valuesお持込 = useComingStore<ObiState>(
    'WphotoPlan/WphotoObijimeBring'
  ).getAll()
  const valuesレンタル = useComingStore<ObiState>(
    'WphotoPlan/WphotoObijimeRental'
  ).getAll()

  const state振袖 = useState振袖()

  return computed(() => {
    if (state振袖.value.flags.isレンタル) {
      return getState帯締め(valuesレンタル.value, true)
    } else {
      return getState帯締め(valuesお持込.value, false)
    }
  })
}

export const getState帯締め = (
  values帯締め: ObiState,
  is振袖レンタル: boolean
) => {
  const { selections, input } = values帯締め
  const itemFlags = getItemPlanFlags(selections)
  const itemPlan = getItemPlan(selections)

  const obijime: Obijime = {
    品目名: ProductClassification.toWord(ProductClassification.obijime),
    品種区分: ProductClassification.obijime,
    商品名: '',
    小物プラン: itemPlan,
    レンタル価格: 0,
    購入価格: 0,
    プレミアムレンタル価格: input.premiumRentalPrice,
    見積表示: null,
    表示価格: null,
    label: getBadgeText(itemPlan),
    cellLabel: getPriceTypeText(selections),
    flags: {
      isランクアップ:
        itemFlags.isランクアップPP内 || itemFlags.isランクアップPP外,
    },
  }

  const updatedPrices = getWPhotoItemPrices(
    values帯締め,
    obijime,
    is振袖レンタル
  )
  obijime.レンタル価格 = updatedPrices.レンタル価格
  obijime.購入価格 = updatedPrices.購入価格
  obijime.プレミアムレンタル価格 = updatedPrices.プレミアムレンタル価格
  obijime.見積表示 = updatedPrices.見積表示
  obijime.表示価格 = updatedPrices.表示価格

  return obijime
}
