import { BaseInformationState } from '../../types'

const state = (): BaseInformationState => ({
  patternLine: null,
  subStaff: null,
  isValidate: null,
  comingStoreType: 0,
  contractReason: [], // 契約動機
})

export default state
