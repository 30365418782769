import { ref, useContext } from '@nuxtjs/composition-api'
import { Category } from '~/constants/enums/productCategory'
import ProductClassification from '~/constants/enums/productClassification'
import { isAxiosError } from '~/utils/exception'

// カタログ検索（振袖）
export interface CatalogFurisode {
  catalog_product_id: number | null // カタログ商品番号（仕立て上がり商品に記載されている「商品番号」とは異なる）
  product_id?: number | null // 商品番号 （仕立て上がり商品に記載されている「商品番号」）
  product_name: string
  catalog_code: string
  rental_custom_order_price: string
  rental_order_price: string
  sale_catalog_price: string
  delivery_period: number | null
  is_available_order: boolean | null
  fatest_delivery_date: string
  digit_shaku: number | null
  digit_sun: number | null
  digit_bu: number | null
  sleeve_shaku: number | null
  sleeve_sun: number | null
  sleeve_bu: number | null
  is_available_shitate: boolean | null
  order_available_year: number | null
  size: FurisodeSizeType | ''
  photo_url: string | null
}

export const FurisodeSize = {
  T: 'T', // トールサイズ
  N: 'N', // 通常商品
} as const

export interface FurisodeParam {
  catalog_code: string
  shop_id?: number
  use_date?: string
  contract_id?: number
  is_change_contract?: 0 | 1 // 0: 新規契約 1: 契約変更
}

export type FurisodeSizeType = typeof FurisodeSize[keyof typeof FurisodeSize]

// カタログ検索（二尺袖・袴・半巾帯）
export interface CatalogOtherKimono {
  product_id?: number
  catalog_code: string
  product_name: string
  rental_catalog_price: string
  orderable_deadline: string | null
  available_order_quantity: number | null
  photo_url: string | null
}

// 小物情報
interface Item {
  product_id: number | null
  product_type_id: number
  product_name: string
  post_number_code: string | null
}

// 仕立て上がり検索の絞り込みの keyword_types
// 0: 商品番号 1: カタログ番号 2: 商品名
export const KEYWORD_TYPES = {
  PRODUCT_ID: 0,
  CATALOG_CODE: 1,
  PRODUCT_NAME: 2,
} as const

export type KeyWordType = typeof KEYWORD_TYPES[keyof typeof KEYWORD_TYPES]

// 着物検索API request query params
export interface KimonoSearchQueryParams {
  keyword?: string | number
  keyword_types?: KeyWordType[]
  start_date?: string
  end_date?: string
  shop_ids?: number[]
  product_type_id: 1 | 2 | 8 | 9
  contract_id?: number
  has_purchased?: 0 | 1
}

// 仕立て上り着物検索API response
// FIXME:
// 至るところにKimono系の型が乱立している
// 整理が必要
export interface KimonoSearchResult {
  product_id?: number
  product_name: string
  catalog_code?: string | null
  shop_name: string
  start_date: string | null //YYYY-MM-DD
  end_date: string | null //YYYY-MM-DD
  contract_type: 0 | 1 | 2 //0: 成人式 1: 卒業式
  plan: 0 | 1 | null //0: 当日 1: 前撮り
  category: Category | null
  digit_shaku?: number | null
  digit_sun?: number | null
  digit_bu?: number | null
  sleeve_shaku?: number | null
  sleeve_sun?: number | null
  sleeve_bu?: number | null
  other?: string | null
  contract_id?: number | null
  fatest_delivery_date?: string
  photo_url?: string | null
  files: any[] // 来ているが何の値かわからない...
}

// カタログ着物（振袖以外）検索のidのEnum
export const catalogKimonoSearchId = {
  nishakusode: 2,
  hakama: 3,
  hanhabaobi: 4,
} as const
export type KimonoTypeId =
  | typeof ProductClassification.hanhabaobi
  | typeof ProductClassification.nisyakusode
  | typeof ProductClassification.hakama

export const useSearchProduct = () => {
  const { $axios } = useContext()
  const itemList = ref<Item[]>([])
  const loading = ref(false)
  const error = ref('')
  const finishedKimonoList = ref<KimonoSearchResult[]>([])
  const catalogOtherKimono = ref<CatalogOtherKimono | null>(null)
  const catalogFurisode = ref<CatalogFurisode | null>(null)

  // 着物検索API（仕立て上がり）
  // FIXME:
  // 関数の返り値としてAPIのresponseを受け取る箇所がいくつかあるので
  // ref を使う形に統一する or ここで ref 定義するのをやめる
  const fetchFinishedKimonoList = async (
    params: KimonoSearchQueryParams
  ): Promise<KimonoSearchResult[] | undefined> => {
    try {
      loading.value = true
      // キーが違っても200で返ってくる
      if (Object.keys(params)[0] !== 'keyword') {
        throw new Error('paramsのキーが不正です。')
      }

      const response = await $axios.get<{ items: KimonoSearchResult[] }>(
        'products/kimono',
        {
          params,
        }
      )
      finishedKimonoList.value = response.data.items
      loading.value = false
      return response.data.items
    } catch (error) {
      console.log('API error', error)
      finishedKimonoList.value = []
      loading.value = false
    }
  }

  // 小物検索API
  const fetchItemList = async (params: object) => {
    error.value = ''
    loading.value = true
    try {
      const response = await $axios.get('products/items', { params })
      const result = response.data?.items ?? []
      // NOTE: 本来であれば返り値で結果を返すのではなく、refに値を代入しコンポーネントからはrefを参照したい。
      itemList.value = result
      loading.value = false

      return result
    } catch (err) {
      loading.value = false

      if (isAxiosError(err)) {
        const error_code = err.response?.data.error_code
        const errors = err.response?.data.errors
        const message = err.response?.data.message

        console.error('API error', errors)

        switch (error_code) {
          case 422:
            const errorMessage = errors
              ? Object.entries(errors).reduce<string>((str, [_, values]) => {
                  str += values.join() + '\n'
                  return str
                }, '')
              : ''
            error.value = errorMessage
            break
          case 401:
            error.value = 'ログイン情報が無効です'
            break
          case 400:
            error.value = '対象の商品が見つかりませんでした'
            break
          default:
            error.value = 'エラーが発生しました。\n' + message
            break
        }

        // NOTE: try側のコメントに同じ
        return []
      }

      error.value = 'サーバーエラー。'
      console.error('useSearchProduct.ts/fetchItemList', err)

      // NOTE: try側のコメントに同じ
      return []
    }
  }

  /**
   * カタログ検索API（二尺袖・袴・半巾帯）
   */
  const fetchCatalogOtherKimono = async (params: {
    catalog_code: string // カタログ番号
    product_type_id: KimonoTypeId // カタログマスタ
    use_date?: string // 使用日
    contract_id?: number
  }) => {
    loading.value = true
    try {
      // このパラメータだけ商品種別と違う独自のidのため、ここで変換する
      const id =
        params.product_type_id === ProductClassification.nisyakusode
          ? catalogKimonoSearchId.nishakusode
          : params.product_type_id === ProductClassification.hakama
          ? catalogKimonoSearchId.hakama
          : params.product_type_id === ProductClassification.hanhabaobi
          ? catalogKimonoSearchId.hanhabaobi
          : undefined
      if (!id) throw new Error('カタログ検索できない商品種別が指定されました')

      const response = await $axios.get('/products/other-kimono', {
        params: {
          catalog_code: params.catalog_code,
          product_type_id: id,
          use_date: params.use_date,
        },
      })
      // NOTE: 検索結果がなかった時には空のオブジェクトが返る
      const is空オブジェクト = !Object.keys(response.data || {}).length
      if (is空オブジェクト) return null

      catalogOtherKimono.value = response.data
    } catch (err) {
      if (isAxiosError(err)) {
        console.log('API error', err.response?.data.message)
      }
      catalogOtherKimono.value = null
    } finally {
      loading.value = false
    }
  }

  /*  カタログ検索API（振袖）
   */
  const fetchCatalogFurisode = async (params: FurisodeParam) => {
    error.value = ''
    loading.value = true
    try {
      const response = await $axios.get('/products/furisode', {
        params,
      })

      catalogFurisode.value = response.data
    } catch (err) {
      if (isAxiosError(err)) {
        console.log('API error', err.response?.data.message)
        error.value = err.response?.data.message as string
      }
      catalogFurisode.value = null
    } finally {
      loading.value = false
    }
  }

  return {
    catalogOtherKimono,
    fetchFinishedKimonoList,
    fetchCatalogOtherKimono,
    fetchCatalogFurisode,
    itemList,
    loading,
    fetchItemList,
    finishedKimonoList,
    catalogFurisode,
    error,
  }
}
