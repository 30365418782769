import { MutationTree } from 'vuex'
import { SignatureState } from '../../types'

const mutations: MutationTree<SignatureState> = {
  setChecked: (state, { ...checked }) => {
    state.checked.first = checked.first
    state.checked.second = checked.second
    state.checked.third = checked.third
    state.checked.fourth = checked.fourth
    state.checked.fifth = checked.fifth
    state.checked.sixth = checked.sixth
  },

  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },

  setSignatureUrl: (state, signatureUrl) => {
    state.signature_url = signatureUrl
  },

  setParentalSignatureUrl: (state, parentalSignatureUrl) => {
    state.parental_signature_url = parentalSignatureUrl
  },

  setContractTotalPrice: (state, contractTotalPrice: number) => {
    state.contractTotalPrice = contractTotalPrice
  },

  setFetchedAt: (state, fetchedAt: string | undefined) => {
    state.fetchedAt = fetchedAt
  },

  delete: (state) => {
    state.checked.first = false
    state.checked.second = false
    state.checked.third = false
    state.checked.fourth = false
    state.checked.fifth = false
    state.checked.sixth = false
    state.isValidate = null
    state.signature_url = ''
    state.parental_signature_url = ''
    state.contractTotalPrice = 0
    state.fetchedAt = undefined
  },
}

export default mutations
