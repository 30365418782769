import { GetterTree } from 'vuex'
import { RootState } from '~/store/Estimate/GraduationCeremony/types'
import { ReferralBonusState } from '~/store/types'

const getters: GetterTree<ReferralBonusState, RootState> = {
  getState: (state) => (stateName: keyof ReferralBonusState) => {
    return state[stateName]
  },

  getAllState: (state) => {
    return state
  },

  getIsValidate: (state: ReferralBonusState) => {
    return state.isValidate
  },
}
export default getters
