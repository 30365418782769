import { ActionTree } from 'vuex'
import { RootState, EstimatePhotoSettings } from '../../../types'

const actions: ActionTree<EstimatePhotoSettings, RootState> = {
  setIsValidate: ({ commit }, isValidate: boolean) => {
    commit('setIsValidate', isValidate)
  },
}

export default actions
