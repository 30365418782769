












import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  emits: ['click'],
  setup(_, { emit }) {
    const handleClick = (e: Event) => emit('click', e)

    return { handleClick }
  },
})
