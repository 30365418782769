import { FormValues半巾帯_袴レンタル } from '~/composable/estimate/viewModel/graduation/Fields/Fields半巾帯_袴レンタル'

//不明な点がある場合、READMEをご覧ください
export const getInitialStore = (): FormValues半巾帯_袴レンタル => {
  return {
    hanhabaobi種別: null,
    hanhabaobi商品選択: null,
    hanhabaobi四色帯レンタル: null,
    hanhabaobi形態: null,
    hanhabaobi現品レンタル: null,
    hanhabaobi商品情報: null,
    hanhabaobiランクアップレンタル: null,
    hanhabaobiカタログ検索: null,
    hanhabaobiカタログレンタル価格: null,
  }
}

const state = (): { fields: FormValues半巾帯_袴レンタル } => ({
  fields: getInitialStore(),
})

export default state
