import { Middleware, NuxtAppOptions } from '@nuxt/types'

type LoginUser = {
  staff_id: number
  last_name: string
  first_name: string
  shop_id: number | null
  shop_name: string
}

const routing: Middleware = async ({
  store,
  route,
  $axios,
}: NuxtAppOptions) => {
  if (route.name === 'login') {
    return
  }

  if (process.server) {
    // ログイン状態かつ別タブで開いた場合ログイン情報をストアに復元する
    if (!store?.getters['LoginUser/getLoginDate']) {
      const res = await $axios.get<LoginUser>('/me').catch((e) => {
        console.error(e)
      })
      if (!res?.data) {
        return
      }

      const now = new Date()
      const loginDate = (() => {
        const year = now.getFullYear()
        const month = ('0' + (now.getMonth() + 1)).slice(-2)
        const date = now.getDate()
        return year + month + date
      })()

      store?.commit('LoginUser/setUser', res.data)
      store?.commit('LoginUser/setLoginDateAndTime', {
        login_date: isNaN(Number(loginDate)) ? 0 : Number(loginDate),
      })
    }
    return
  }
}

export default routing
