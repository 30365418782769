import { computed, useRoute, useStore } from '@nuxtjs/composition-api'
import { ESTIMATE } from '~/constants/estimate/config'

/*
 * 今いる契約ページの種類を判定するフラグを提供する関数
 */
export const useContractTypeFlags = () => {
  const route = useRoute()
  const store = useStore()

  const estimateType = store.getters['Contracts/BaseEstimate/getEstimateType']

  const in成人式契約 = estimateType === ESTIMATE.成人式.id

  const in卒業式成人式契約 = computed(() => {
    return (
      route.value.path.includes('contracts/') &&
      !route.value.path.includes('contracts/attendance') &&
      !route.value.path.includes('contracts/singleItemService') &&
      !route.value.path.includes('contracts/cleaning')
    )
  })

  const in参列契約 = computed(() => {
    return route.value.path.includes('contracts/attendance')
  })

  const in単品サービス契約 = computed(() => {
    return route.value.path.includes('contracts/singleItemService')
  })

  const inクリーニング契約 = computed(() => {
    return route.value.path.includes('contracts/cleaning')
  })

  return {
    in卒業式成人式契約,
    in参列契約,
    in単品サービス契約,
    inクリーニング契約,
    in成人式契約,
  }
}
