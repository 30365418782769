import { ref, Ref, useRouter, useStore } from '@nuxtjs/composition-api'
import { Store } from 'vuex'
import { useComingOfAgeEstimateRequest } from '../EstimateRequest'
import { useScreenTypeFlags } from '~/composable/estimate/domain/ComingOfAge/ScreenTypeFlags'
import {
  useValidateZoriBagAndKimono,
  useValidateEstimatePost,
} from '~/composable/estimate/domain/ComingOfAge/ValidateEstimatePost'
import { urls } from '~/constants/urls'
import {
  TabList,
  useEstimateTabList,
} from '~/composable/headerTab/useEstimateTabList'
import { useInitializeState } from '~/composable/general/useInitializeState'
import { EstimateListState } from '~/store/types'
import { BaseEstimateState } from '~/store/Contracts/types'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'

import { useTemporarilySaved } from '~/composable/api/useTemporarilySaved'
import { ESTIMATE } from '~/constants/estimate/config'
import { useState振袖 } from '~/composable/estimate/repository/comingOfAge/states/State振袖'

type Router = ReturnType<typeof useRouter>
export const useEventHandlers = () => {
  const router = useRouter()
  const store = useStore()
  const { comingOfAgeCeremonyTabList } = useEstimateTabList()
  const { clickCompleted, isLoading, createEstimateReqBody } =
    useCompleteClick()

  // 契約更新時に新規で作成された見積かどうか
  const isNewEstimate: BaseEstimateState['isNewEstimate'] =
    store.getters['Contracts/BaseEstimate/getIsNewEstimate']

  return {
    clickLink: handleClickLink(
      router,
      comingOfAgeCeremonyTabList,
      isNewEstimate
    ),
    clickLinkWPhoto: handleClickLinkWPhoto(router),
    clickCompleted,
    isLoading,
    // 見積完了ボタンを押さずに、実際送られる値をconsoleに流す用
    createEstimateReqBody,
  }
}

/**
 * 見積一覧の項目名エリアをタップで当該画面に遷移
 */
const handleClickLink =
  (router: Router, tabList: Ref<TabList[]>, isNewEstimate: boolean) =>
  (text: string) => {
    // NOTE: 契約フローの最初に表示される見積一覧からはタブに遷移できないようにする
    const inContract = !router.currentRoute.path.includes(
      '/estimateListContract'
    )

    if (!inContract) return
    if (!text || isNewEstimate) {
      return
    }
    const estimateId = Number(router.currentRoute.params?.estimate_id ?? 0)
    if (text === '草履' || text === 'バッグ') {
      router.push(urls.見積書.成人式.tab草履バッグ(estimateId))
      return
    }
    const selectedTab = tabList.value.find(
      (list: TabList) => list.text === text
    )
    if (selectedTab) {
      router.push(selectedTab.url)
    }
  }

/**
 * 項目名エリアをタップで当該画面に遷移（Wフォト）
 * MEMO: コンポーネントで表示を切り替えているため該当の画面に遷移まで実装できていない
 */
const handleClickLinkWPhoto = (router: Router) => () => {
  const estimateId = Number(router.currentRoute.params?.estimate_id ?? 0)
  router.push(urls.見積書.成人式.tabWフォト(estimateId))
}

/**
 * 見積完了ボタン押下時の処理
 */
const useCompleteClick = () => {
  const router = useRouter()
  const store = useStore() as Store<any>
  const { checkCanPostEstimate } = useValidateEstimatePost()
  const { checkZoriBagAndKimonoValid } = useValidateZoriBagAndKimono(true)
  const { postComingOfAgeEstimate, createEstimateReqBody } =
    useComingOfAgeEstimateRequest()
  const { showTypeフルセット } = useScreenTypeFlags()
  const { initEstimateStore } = useInitializeState()
  const isLoading = ref(false)
  const store見積一覧 =
    useComingStore<EstimateListState['fields']>('EstimateList')
  const state振袖 = useState振袖()

  const { saveEstimateTemporarilyWithAlert } = useTemporarilySaved()

  // 「契約書作成」ボタン押下時
  const clickCompleted = async (isMypage: boolean = false) => {
    // フルセット時の見積作成可能バリデーション
    if (showTypeフルセット.value && !checkZoriBagAndKimonoValid()) return

    // 共通パターンの見積作成可能バリデーション
    if (!checkCanPostEstimate(isMypage)) return

    // NOTE:WB-3226「hurisodeの中身が不正な見積が作成（あるいは取得）される」ことが原因のバグの発生原因を調査・防止するため、
    // categoryが0の場合は見積完了ボタンからは見積作成出来ないようにしている。
    if (!state振袖.value.商品分類) {
      const errorText =
        '振袖のcategoryが0、あるいは取得できないため見積作成失敗しました。'
      await saveEstimateTemporarilyWithAlert(
        ESTIMATE.成人式.id,
        '作成',
        errorText
      )
      return
    }

    try {
      isLoading.value = true
      const branchNumber = 1 // MEMO:新規作成なので１にしている

      const { estimateId } = await postComingOfAgeEstimate()
      if (!estimateId) {
        throw new Error('estimateIdが取得できませんでした')
      }
      store見積一覧.set<'isEstimateCreated'>('isEstimateCreated', true)
      const memoId =
        store.getters['Estimate/ComingOfAgeCeremony/Memo/getMemoId'] ?? 0
      const customerId =
        store.getters['Estimate/ComingOfAgeCeremony/Memo/getCustomerId'] ?? 0
      // 作成後、見積側のstoreをリセット
      initEstimateStore()
      router.push(
        `/estimate/comingOfAgeCeremony/${estimateId}/creationCompleted?estimate_id=${estimateId}&branch=${branchNumber}&memo_id=${memoId}&customer_id=${customerId}`
      )
    } catch (error) {
      // UAT環境でのバグの原因突き止め時間を短縮するため、失敗時は一時保存する
      saveEstimateTemporarilyWithAlert(ESTIMATE.成人式.id, '作成')
    } finally {
      isLoading.value = false
    }
  }

  return { isLoading, clickCompleted, createEstimateReqBody }
}
