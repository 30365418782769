



















import {
  defineComponent,
  ref,
  PropType,
  watch,
  useRoute,
  computed,
  useStore,
} from '@nuxtjs/composition-api'

/** component */
import AppIconCircle from './AppIconCircle.vue'
import IconCircleSave from './icons/IconCircleSave.vue'
import IconCircleMemo from './icons/IconCircleMemo.vue'
import TheDialogTempSave from './TheDialogTempSave.vue'
import ContractDialogMemo from './ContractDialogMemo.vue'

/** composable */
import { useTemporarilySaved } from '~/composable/api/useTemporarilySaved'

/** types */
import { FrontStoreInfo } from '~/store/types'
import {
  State as AttendanceState,
  Tab as AttendanceTab,
} from '~/store/Contracts/Attendance/Main/state'
import {
  State as CleaningState,
  Tab as CleaningTab,
} from '~/store/Contracts/Cleaning/Main/state'
import {
  State as SingleItemServiceState,
  Tab as SingleItemServiceTab,
} from '~/store/SingleItemService/Main/state'
import { ContractTypeId } from '~/api/frontStoreRepository'

type SaveFunc = () => void

export default defineComponent({
  components: {
    AppIconCircle,
    IconCircleSave,
    IconCircleMemo,
    TheDialogTempSave,
    ContractDialogMemo,
  },
  props: {
    saveFunc: {
      type: Function as PropType<SaveFunc>,
      required: false,
    },
    isSaveButton: {
      type: Boolean,
      default: true,
    },
    estimateType: {
      type: Number as PropType<ContractTypeId | null>,
      required: false,
    },
  },
  setup(props) {
    const store = useStore()
    const route = useRoute()
    const dialogTempSave = ref<InstanceType<typeof TheDialogTempSave> | null>()
    const dialogMemo = ref<InstanceType<typeof ContractDialogMemo> | null>()

    /**
     * 仮保存処理の実装
     * 仮保存ボタンを押した時点でタブの中身はまだストアに保存されていないため、
     * タブ移動してストアに保存された後にfrontStoreFlg(一時保存APIを叩くためのフラグ)がtrueになる実装になっており、
     * そのフラグがtrueかどうか見て、実際に一時保存APIを叩いて保存を更新するための処理。
     * Notion仕様書: https://www.notion.so/arsagajp/4457ef50b63c4987ad84dc98a9daef7a
     */
    const { saveContractTemporarily } = useTemporarilySaved()
    const frontStoreInfo = computed<FrontStoreInfo>(
      () => store.getters['FrontStoreInfo/getAllState']
    )

    const storeAttendanceTabList = computed<AttendanceState['tabList']>(
      () => store.getters['Contracts/Attendance/Main/getTabList']
    )
    const storedAttendancePageList = computed<CleaningTab[]>(
      () => store.getters['Contracts/Attendance/Main/getFinalPageList']
    )
    const storeCleaningTabList = computed<CleaningState['tabList']>(
      () => store.getters['Contracts/Cleaning/Main/getTabList']
    )
    const storedCleaningPageList = computed<AttendanceTab[]>(
      () => store.getters['Contracts/Cleaning/Main/getFinalPageList']
    )
    const storeSingleItemServiceTabList = computed<
      SingleItemServiceState['tabList']
    >(() => store.getters['SingleItemService/Main/getTabList'])

    const storedSingleItemSeervicePageList = computed<SingleItemServiceTab[]>(
      () => store.getters['SingleItemService/Main/getFinalPageList']
    )

    watch(
      () => [
        route.value.path,
        storeAttendanceTabList.value,
        storedAttendancePageList.value,
        storeCleaningTabList.value,
        storedCleaningPageList.value,
        storeSingleItemServiceTabList.value,
        storedSingleItemSeervicePageList.value,
      ],
      () => {
        const isTabMove = true
        if (frontStoreInfo.value.frontStoreFlg && props.estimateType) {
          saveContractTemporarily(props.estimateType, isTabMove)
        }
      }
    )

    const openTempSave = async () => {
      if (props.saveFunc) {
        props.saveFunc()
      }
      dialogTempSave.value?.openModal()
    }

    const openMemo = () => dialogMemo.value?.openModal()

    return { dialogTempSave, dialogMemo, openTempSave, openMemo }
  },
})
