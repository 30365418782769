import { GetterTree } from 'vuex'
import { RootState } from '~/store/Contracts/types'
import { State } from './state'

/** helpers */
import { selectChecklist } from '~/features/checklist/helpers'

const getters: GetterTree<State, RootState> = {
  // ** All Input Data **
  getState: (state: State) => {
    return state.data
  },

  // ** 選択されたタブのId **
  getSelectedChecklistId: (state: State) => {
    return state.selectedChecklistId
  },

  // ** 選択されたタブのチェックリスト **
  getSelectedChecklist: (state: State) => selectChecklist(state),

  // ** チェックリストステータス **
  getChecklistCompletion: (state: State) => {
    const selectedChecklist = selectChecklist(state)
    return selectedChecklist?.checklistCompletion
  },

  // ** タブ **
  getTabList: (state: State) => {
    return state.tabList
  },
}

export default getters
