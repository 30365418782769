import { DoublePhotoSettings } from '../../types'

const state = (): DoublePhotoSettings => ({
  photoUpload: null,
  input: {
    timeOfEnteringDressPlace: '',
    remark: '',
  },
  settings: [
    {
      id: 1,
      title: '全体写真',
      limit: 3,
      isPossibleCheck: true,
      getEstimateThumbnail: false,
      photos: [],
    },
    {
      id: 2,
      title: '衿元写真',
      limit: 1,
      isPossibleCheck: false,
      getEstimateThumbnail: false,
      photos: [],
    },
  ],
  isValidate: null,
})

export default state
