import { Customer } from '~/composable/api/useCustomer'
import { Contract } from '~/api/contractRepository'
import { KimonoSearchResult } from '@/composable/api/useSearchProduct'
import { Photo } from '~/store/Estimate/GraduationCeremony/types'
import { HoumongiData } from '~/api/houmongiRepository'
import { TomesodeData } from '~/api/tomesodeRepository'
import { priceTable } from '~/constants/priceTable'
import { usePrice } from 'storybook/utils/utils'
import { PartyDressSearchData } from '~/composable/api/usePartyDressSearch'
import { AmountAdjustments } from '~/store/types'
import { DISCOUNT_DATA } from '~/features/estimate/attendance/discount/enums'

type valueOf<T> = T[keyof T]
export type Price = {
  id: number | null
  value: string // ¥付き金額文字列
}
export type Product = {
  id: number | null
  text: string | null // 商品名
  value: string | null // ¥付き金額文字列
}

// ** 基本設定 **
export type BaseSettings = {
  useDate: string // 使用日
  customer: Customer | null // お客様情報
  tomonokaiCustomer: Customer | null // お客様情報（二親等以内の友の会会員）
  isRelativeWithinTomonokai: number | null // 二親等以内に友の会会員がいるかどうか
  nullRecodeList: Contract[] | null // plan未設定契約書リスト (旧システムの契約データはplanを持たないため)
  nullRecode: Contract | null // plan未設定契約書 (旧システムの契約データはplanを持たないため)
  fullSetPlan: number | null // フルセットプラン（レンタルまたは購入）
  guidanceEmail: string | null // メールアドレス
  contractInfo: Contract | null // 契約情報
  service: number | null // 利用するサービス
  kimonoType: number | null // 着物種別
  whoBought: number | null // 着物購入者
  dressing: number | null // 着付有無
  height: string // 身長
  footSize: string // 足のサイズ
  bust: string // バスト
  hip: string // ヒップ
  waist: string // ウエスト
  lastName: string // お子様のお名前(姓)
  firstName: string // お子様のお名前(名)
  lastNameKana: string // お子様のお名前(セイ)
  firstNameKana: string // お子様のお名前(メイ)
  birthDate: string | null // お子様の生年月日
  gender: string // お子様の性別
}
export type BaseSettingsKeys = keyof BaseSettings
export type BaseSettingsValues = valueOf<BaseSettings>

//  ** 試着写真 **
export type TryOnPhotos = {
  photoUpload: number | null //　写真アップロード
  entirePhoto: Photo[] //　全体写真
  collarPhoto: Photo[] //　衿元写真
}
export type TryOnPhotosKeys = keyof TryOnPhotos
export type TryOnPhotosValues = valueOf<TryOnPhotos>

//  ** 髪飾り **
export type Kamikazari = {
  shubetsu: number | null //　髪飾り種別
  buyTanpinPrice: string //　髪飾り購入価格
}
export type KamikazariKeys = keyof Kamikazari
export type KamikazariValues = valueOf<Kamikazari>

//  ** 髪飾り2 **
export type Kamikazari2 = {
  shubetsu: number | null //　髪飾り種別
  buyTanpinPrice: string //　髪飾り購入価格
}
export type Kamikazari2Keys = keyof Kamikazari2
export type Kamikazari2Values = valueOf<Kamikazari2>

//  ** 髪飾り3 **
export type Kamikazari3 = {
  shubetsu: number | null //　髪飾り種別
  buyTanpinPrice: string //　髪飾り購入価格
}
export type Kamikazari3Keys = keyof Kamikazari3
export type Kamikazari3Values = valueOf<Kamikazari3>

// ** ショール **
export type Shawl = {
  shubetsu: number | null //　種別
  product: Product //　商品
  keitai: number | null //　形態
  rentalPrice: string // レンタル価格
  rankUpRentalPrice: string // ランクアップレンタル価格
  rankUpGenpinRentalPrice: string // ランクアップレンタル価格(現品)
  rentalPpPrice: string // プレミアムパックを選んだ場合のレンタル価格
  tanpinRentalPrice: string // 単品レンタル価格
  tanpinBuyPrice: string // 単品購入価格
  genpinTanpinRentalPrice: string // 現品単品レンタル価格
  genpinTanpinBuyPrice: string // 現品単品購入価格
}
export type ShawlKeys = keyof Shawl
export type ShawlValues = valueOf<Shawl>

//  ** 足し布 **
export type Tashinuno = {
  apply: number | null //　足し布適用
  price: number | null //　足し布金額確認
}
export type TashinunoKeys = keyof Tashinuno
export type TashinunoValues = valueOf<Tashinuno>

//  ** その他割引 **
export type DiscountData = {
  discount: number | null //割引適用
  discountPrice: string //割引額
}
export type DiscountKeys = keyof DiscountData
export type DiscountValues = valueOf<DiscountData>

// ** 振袖 **
export type Furisode = {
  furisodeInfo: KimonoSearchResult | null // 振袖情報
  yukiHosei: number | null // 裄補正
  sodeHosei: number | null // 袖補正
  furisodePrice: Price // 振袖金額
  furisodeInputPrice: string | null // 振袖金額(フリー入力)
  isHurisodeOnHold: boolean // 振袖を保留にするか否か
}
export type FurisodeKeys = keyof Furisode
export type FurisodeValues = valueOf<Furisode>

// ** 訪問着 ** NOTE: 既にHoumongiDataという変数が使われていたのでtabを入れている
export type HoumongiTabData = {
  selectingFrom: number | null // 写真からOR現品から商品選択
  selectedProduct: HoumongiData | null //写真から選択した商品情報
  rentalPrice: string | null // レンタル価格
}
export type HoumongiKeys = keyof HoumongiTabData
export type HoumongiValues = valueOf<HoumongiTabData>

// ** 留袖 **
export type Tomesode = {
  selectingFrom: number | null // 写真からOR現品から商品選択
  selectedProduct: TomesodeData | null //写真から選択した商品情報
  freeInputPrice: string | null // レンタル価格フリー入力時
}
export type TomesodeKeys = keyof Tomesode
export type TomesodeValues = valueOf<Tomesode>

// ** 帯 **
export type ObiData = {
  shubetsu: number | null // 帯の種別
  keitai: number | null // 帯の形態
  shitate: number | null // 仕立て上がりか
  obiInfo: KimonoSearchResult | null // 帯情報
  obiName: string | null // 帯の名前(仕立て上がりではない場合)
  rentalPrice: string | null // レンタル価格
  rentalTanpinPrice: string | null // 単品レンタル価格
  buyTanpinPrice: string | null // 単品購入価格
  rankUpPrice: Price // ランクアップPP内の選択価格
  rankUpFreePrice: string | null // ランクアップPP内のフリー入力価格
  rankUpInputPrice: string | null // ランクアップPP外の価格
  rankUpInputPpPrice: string | null // ランクアップPP外のPP選択時の価格
}
export type ObiDataKeys = keyof ObiData
export type ObiDataValues = valueOf<ObiData>

//  ** 別衿 **
export type Betsueri = {
  shubetsu: number | null //　種別
  sewing: number | null //　縫い付け
  keitai: number | null //　別衿の形態
  rentalPrice: string | null // レンタル価格
  rentalTanpinPrice: string | null // 単品レンタル価格
  buyTanpinPrice: string | null // 単品購入価格
  rankUpPrice: Price // ランクアップPP内の選択価格
  rankUpFreePrice: string | null // ランクアップPP内のフリー入力価格
  rankUpInputPrice: string | null // ランクアップPP外の価格
  rankUpInputPpPrice: string | null // ランクアップPP外のPP選択時の価格
}
export type BetsueriKeys = keyof Betsueri
export type BetsueriValues = valueOf<Betsueri>

// ** 帯締め **
export type ObijimeData = {
  shubetsu: number | null // 帯の種別
  keitai: number | null // 帯の形態
  rentalPrice: string | null // レンタル価格
  rentalTanpinPrice: string | null // 単品レンタル価格
  buyTanpinPrice: string | null // 単品購入価格
  rankUpSelectPriceInPp: Price // ランクアップPP内の選択価格
  rankUpFreePriceInPp: string | null // ランクアップPP内のフリー入力価格
  rankUpSelectPrice: Price // ランクアップPP外の選択価格
  rankUpFreePrice: string | null // ランクアップPP外のフリー入力価格
  rankUpInputPpPrice: string | null // ランクアップPP外のPP選択時の価格
}
export type ObijimeDataKeys = keyof ObijimeData
export type ObijimeDataValues = valueOf<ObijimeData>

// ** 帯揚げ **
export type ObiageData = {
  shubetsu: number | null // 帯の種別
  keitai: number | null // 帯の形態
  selectedProduct: Product // 選択商品
  rentalPrice: string | null // レンタル価格
  rentalTanpinPrice: string | null // 単品レンタル価格
  buyTanpinPrice: string | null // 単品購入価格
  rankUpSelectPriceInPp: Price // ランクアップPP内の選択価格
  rankUpFreePriceInPp: string | null // ランクアップPP内のフリー入力価格
  rankUpFreePrice: string | null // ランクアップPP外のフリー入力価格
  rankUpInputPpPrice: string | null // ランクアップPP外のPP選択時の価格
}
export type ObiageDataKeys = keyof ObiageData
export type ObiageDataValues = valueOf<ObiageData>

// ** 帯揚げ2 **
export type Obiage2Data = {
  shubetsu: number | null // 帯の種別
  rentalTanpinPrice: string | null // 単品レンタル価格
  buyTanpinPrice: string | null // 単品購入価格
}
export type Obiage2DataKeys = keyof Obiage2Data
export type Obiage2DataValues = valueOf<Obiage2Data>

// ** 帯揚げ3 **
export type Obiage3Data = {
  shubetsu: number | null // 帯の種別
  rentalTanpinPrice: string | null // 単品レンタル価格
  buyTanpinPrice: string | null // 単品購入価格
}
export type Obiage3DataKeys = keyof Obiage3Data
export type Obiage3DataValues = valueOf<Obiage3Data>

// ** 重ね衿 **
export type KasaneeriData = {
  shubetsu: number | null // 重ね衿の種別
  keitai: number | null // 重ね衿の形態
  rentalPrice: string | null // レンタル価格
  rentalTanpinPrice: string | null // 単品レンタル価格
  buyTanpinPrice: string | null // 単品購入価格
  rankUpSelectPriceInPp: Price // ランクアップPP内の選択価格
  rankUpFreePriceInPp: string | null // ランクアップPP内のフリー入力価格
  rankUpFreePrice: string | null // ランクアップPP外のフリー入力価格
  rankUpInputPpPrice: string | null // ランクアップPP外のPP選択時の価格
}
export type KasaneeriDataKeys = keyof KasaneeriData
export type KasaneeriDataValues = valueOf<KasaneeriData>

// ** 重ね衿2と3 **
export type AdditionalKasaneeriData = {
  shubetsu: number | null // 重ね衿の種別
  rentalTanpinPrice: string | null // 単品レンタル価格
  buyTanpinPrice: string | null // 単品購入価格
}
export type AdditionalKasaneeriDataKeys = keyof AdditionalKasaneeriData
export type AdditionalKasaneeriDataValues = valueOf<AdditionalKasaneeriData>

//  ** 草履 **
export type Zori = {
  shubetsu: number | null //種別
  keitai: number | null //形態
  selectedProduct: Product //商品選択肢
  rentalPrice: string | null // レンタル価格
  rankupPrice: string | null // ランクアップレンタル価格
  premiumPrice: string | null // プレミアムパック時レンタル価格
  rentalTanpinPrice: string | null // 単品レンタル価格
  buyTanpinPrice: string | null // 単品購入価格
}
export type ZoriKeys = keyof Zori
export type ZoriValues = valueOf<Zori>

//  ** バッグ **
export type Bag = {
  shubetsu: number | null //種別
  keitai: number | null //形態
  selectedProduct: Product //商品選択肢
  rentalPrice: string | null // レンタル価格
  rankupPrice: string | null // ランクアップレンタル価格
  premiumPrice: string | null // プレミアムパック時レンタル価格
  rentalTanpinPrice: string | null // 単品レンタル価格
  buyTanpinPrice: string | null // 単品購入価格
}
export type BagKeys = keyof Bag
export type BagValues = valueOf<Bag>

//  ** 草履バッグ **  ※訪問着留袖持込の場合
export type ZoriBag = {
  shubetsu: number | null //種別
  selectedProduct: Product //商品選択肢
  rentalTanpinPrice: string | null // 単品レンタル価格
}
export type ZoriBagKeys = keyof ZoriBag
export type ZoriBagValues = valueOf<ZoriBag>

// ** 備考 **
export type RemarksData = {
  text: string
}

// ** モーニング **
//モーニング検索APIのResponseと同じparameterを保持している
export type MorningProduct = {
  product_id: number | null //商品番号
  size: string | null // A1 ~ K10
}
export type MorningData = {
  product: MorningProduct
  price: number | null
}

// ** パーティドレス **
export type PartyDress = {
  product: PartyDressSearchData
  freeInputPrice: string | null // レンタル価格フリー入力時
}
export type PartyDressKeys = keyof PartyDress
export type PartyDressValues = valueOf<PartyDress>

// ** タブ **
export type Tab = {
  text: string
  isComplete: boolean | null
  isShow: boolean
  isCurrent: boolean
}

//** 初着 **/
export type UbugiProduct = {
  product_id: number
  product_name: string
  photo_url: string | null
}

export type UbugiData = {
  product: UbugiProduct | null
  price: number | null
}

export type UbugiKeys = keyof UbugiData
export type UbugiValues = valueOf<UbugiData>

// ** その他商品 **
export type OtherItem = {
  product_name: string
  price_type: number
  price: number | null
}

export type OtherItems = {
  apply: number
  items: OtherItem[]
}

// ** オプション **
export type OptionItems = {
  apply: number
}

// ** 見積一覧（振袖レンタルの場合のみ必要） **
export type EstimateList = {
  selectedPlan: number | null // こちらを選ぶ
  amount_adjustments: AmountAdjustments[] //金額調整
  amount_adjustment_validation: boolean[][] //見積一覧ページにて金額入力のバリデーションを保持するdata
  estimateId: number | null
}

export type EstimateListKeys = keyof EstimateList
export type EstimateListValues = valueOf<EstimateList>

export type State = {
  data: {
    baseSettings: BaseSettings
    tryOnPhotos: TryOnPhotos
    betsueri: Betsueri
    kamikazari: Kamikazari
    kamikazari2: Kamikazari2
    kamikazari3: Kamikazari3
    shawl: Shawl
    tashinuno: Tashinuno
    discount: DiscountData
    furisode: Furisode
    houmongi: HoumongiTabData
    obi: ObiData
    obijime: ObijimeData
    obiage: ObiageData
    obiage2: Obiage2Data
    obiage3: Obiage3Data
    kasaneeri: KasaneeriData
    kasaneeri2: AdditionalKasaneeriData
    kasaneeri3: AdditionalKasaneeriData
    tomesode: Tomesode
    zori: Zori
    bag: Bag
    zoriBag: ZoriBag
    remarks: RemarksData
    ubugi: UbugiData
    morning: MorningData
    otherItem: OtherItems
    optionItems: OptionItems
    partyDress: PartyDress
    estimateList: EstimateList
    pdfUrl: string | null
  }
  tabList: Tab[]
  finalPageList: Tab[]
  contractId: number | null
}

export const getInitialStore = (): State => {
  const { toString } = usePrice()

  return {
    data: {
      // ** 基本設定 **
      baseSettings: {
        useDate: '', // 使用日
        customer: null, // お客様情報
        contractInfo: null, // 契約情報
        tomonokaiCustomer: null, // お客様情報（二親等以内の友の会会員）
        isRelativeWithinTomonokai: null, // 二親等以内に友の会会員がいるかどうか
        nullRecodeList: null, // plan未設定契約書リスト (旧システムの契約データはplanを持たないため)
        nullRecode: null, // plan未設定契約書 (旧システムの契約データはplanを持たないため)
        fullSetPlan: null, // フルセットプラン（レンタルまたは購入）
        guidanceEmail: null, // メールアドレス
        service: null, // 利用するサービス
        kimonoType: null, // 着物種別
        whoBought: null, // 着物購入者
        dressing: null, // 着付有無
        height: '', // 身長
        footSize: '', // 足のサイズ
        bust: '', // バスト
        hip: '', // ヒップ
        waist: '', // ウエスト
        lastName: '', // お子様のお名前(姓)
        firstName: '', // お子様のお名前(名)
        lastNameKana: '', // お子様のお名前(セイ)
        firstNameKana: '', // お子様のお名前(メイ)
        birthDate: '', // お子様の生年月日
        gender: '', // お子様の性別
      },
      // ** 試着写真 **
      tryOnPhotos: {
        photoUpload: null,
        entirePhoto: [],
        collarPhoto: [],
      },
      // ** 髪飾り **
      kamikazari: {
        shubetsu: null,
        buyTanpinPrice: '',
      },
      // ** 髪飾り2 **
      kamikazari2: {
        shubetsu: null,
        buyTanpinPrice: '',
      },
      // ** 髪飾り3 **
      kamikazari3: {
        shubetsu: null,
        buyTanpinPrice: '',
      },
      // ** ショール **
      shawl: {
        shubetsu: null, //　種別
        product: {
          id: null,
          text: '',
          value: '',
        }, //　商品
        keitai: null, //　形態
        rentalPrice: '¥0', // レンタル価格
        rankUpRentalPrice: '¥0', // ランクアップレンタル価格
        rankUpGenpinRentalPrice: '', // ランクアップレンタル価格(現品)
        rentalPpPrice: '', // プレミアムパックを選んだ場合のレンタル価格
        tanpinRentalPrice: '¥0', // 単品レンタル価格
        tanpinBuyPrice: '¥0', // 単品購入価格
        genpinTanpinRentalPrice: '', // 現品単品レンタル価格
        genpinTanpinBuyPrice: '', // 現品単品購入価格
      },
      // ** 足し布 **
      tashinuno: {
        apply: null,
        price: null,
      },
      // ** その他割引 **
      discount: {
        discount: DISCOUNT_DATA.DISCOUNT.いいえ, //割引適用
        discountPrice: '', //割引額
      },
      // ** 振袖 **
      furisode: {
        furisodeInfo: null, // 振袖情報
        yukiHosei: null, // 裄補正
        sodeHosei: null, // 袖補正
        furisodePrice: {
          id: null,
          value: '',
        }, // 振袖金額
        furisodeInputPrice: null, // 振袖金額(フリー入力)
        isHurisodeOnHold: false, // 振袖を保留にするか否か
      },
      // ** 訪問着 **
      houmongi: {
        selectingFrom: null,
        selectedProduct: null,
        rentalPrice: toString(priceTable.参列.訪問着.初期値レンタル価格), // NOTE: 初期値金額
      },
      // ** 訪問着 **
      ubugi: {
        product: null,
        price: null,
      },
      // ** 帯 **
      obi: {
        shubetsu: null, // 帯の種別
        keitai: null, // 帯の形態
        shitate: null, // 仕立て上がりか
        obiInfo: null, // 帯情報
        obiName: null, // 帯の名前(仕立て上がりではない場合)
        rentalPrice: '¥0', // レンタル価格
        rentalTanpinPrice: null, // 単品レンタル価格
        buyTanpinPrice: null, // 単品購入価格
        rankUpPrice: {
          id: null,
          value: '',
        }, // ランクアップPP内の選択価格
        rankUpFreePrice: null, // ランクアップPP内のフリー入力価格
        rankUpInputPrice: null, // ランクアップPP外の価格
        rankUpInputPpPrice: null, // ランクアップPP外のPP選択時の価格
      },
      // ** 別衿 **
      betsueri: {
        shubetsu: null, //　持込時の別衿の種別
        keitai: null, //　別衿の形態
        rentalPrice: '¥0', // レンタル価格
        rentalTanpinPrice: null, // 単品レンタル価格
        buyTanpinPrice: null, // 単品購入価格
        rankUpPrice: {
          id: null,
          value: '',
        }, // ランクアップPP内の選択価格
        rankUpFreePrice: null, // ランクアップPP内のフリー入力価格
        rankUpInputPrice: null, // ランクアップPP外の価格
        rankUpInputPpPrice: null, // ランクアップPP外のPP選択時の価格
        sewing: null, //　縫い付け
      },
      // ** 帯締め **
      obijime: {
        shubetsu: null, // 帯の種別
        keitai: null, // 帯の形態
        rentalPrice: '¥0', // レンタル価格
        rentalTanpinPrice: null, // 単品レンタル価格
        buyTanpinPrice: null, // 単品購入価格
        rankUpSelectPriceInPp: {
          id: null,
          value: '',
        }, // ランクアップPP内の選択価格
        rankUpFreePriceInPp: null, // ランクアップPP内のフリー入力価格
        rankUpSelectPrice: {
          id: null,
          value: '',
        }, // ランクアップPP外の選択価格
        rankUpFreePrice: null, // ランクアップPP外のフリー入力価格
        rankUpInputPpPrice: null, // ランクアップPP外のPP選択時の価格
      },
      // ** 帯揚げ **
      obiage: {
        shubetsu: null, // 帯の種別
        keitai: null, // 帯の形態
        selectedProduct: {
          id: null,
          text: '',
          value: '',
        }, // 選択商品
        rentalPrice: '¥0', // レンタル価格
        rentalTanpinPrice: null, // 単品レンタル価格
        buyTanpinPrice: null, // 単品購入価格
        rankUpSelectPriceInPp: {
          id: null,
          value: '',
        },
        rankUpFreePriceInPp: null, // ランクアップPP内のフリー入力価格
        rankUpFreePrice: null, // ランクアップPP外のフリー入力価格
        rankUpInputPpPrice: null, // ランクアップPP外のPP選択時の価格
      },
      // ** 帯揚げ2 **
      obiage2: {
        shubetsu: null, // 帯の種別
        rentalTanpinPrice: null, // 単品レンタル価格
        buyTanpinPrice: null, // 単品購入価格
      },
      // ** 帯揚げ3 **
      obiage3: {
        shubetsu: null, // 帯の種別
        rentalTanpinPrice: null, // 単品レンタル価格
        buyTanpinPrice: null, // 単品購入価格
      },
      // ** 重ね衿 **
      kasaneeri: {
        shubetsu: null, // 重ね衿の種別
        keitai: null, // 重ね衿の形態
        rentalPrice: '¥0', // レンタル価格
        rentalTanpinPrice: null, // 単品レンタル価格
        buyTanpinPrice: null, // 単品購入価格
        rankUpSelectPriceInPp: {
          id: null,
          value: '',
        }, // ランクアップPP内の選択価格
        rankUpFreePriceInPp: null, // ランクアップPP内のフリー入力価格
        rankUpFreePrice: null, // ランクアップPP外のフリー入力価格
        rankUpInputPpPrice: null, // ランクアップPP外のPP選択時の価格
      },
      // ** 重ね衿2 **
      kasaneeri2: {
        shubetsu: null, // 重ね衿の種別
        rentalTanpinPrice: null, // 単品レンタル価格
        buyTanpinPrice: null, // 単品購入価格
      },
      // ** 重ね衿3 **
      kasaneeri3: {
        shubetsu: null, // 重ね衿の種別
        rentalTanpinPrice: null, // 単品レンタル価格
        buyTanpinPrice: null, // 単品購入価格
      },
      // ** 留袖 **
      tomesode: {
        selectingFrom: null,
        selectedProduct: null,
        freeInputPrice: null,
      },
      // ** 草履 **
      zori: {
        shubetsu: null,
        keitai: null,
        selectedProduct: {
          id: null,
          text: '',
          value: '',
        }, //　商品
        rentalPrice: null, // レンタル価格
        rankupPrice: null, // ランクアップレンタル価格
        premiumPrice: null, // プレミアムパック時レンタル価格
        rentalTanpinPrice: null, // 単品レンタル価格
        buyTanpinPrice: null, // 単品購入価格
      },
      // ** バッグ **
      bag: {
        shubetsu: null,
        keitai: null,
        selectedProduct: {
          id: null,
          text: '',
          value: '',
        }, //　商品
        rentalPrice: null, // レンタル価格
        rankupPrice: null, // ランクアップレンタル価格
        premiumPrice: null, // プレミアムパック時レンタル価格
        rentalTanpinPrice: null, // 単品レンタル価格
        buyTanpinPrice: null, // 単品購入価格
      },
      // ** 草履バッグ **
      zoriBag: {
        shubetsu: null,
        selectedProduct: {
          id: null,
          text: '',
          value: '',
        }, //　商品
        rentalTanpinPrice: null, // 単品レンタル価格
      },
      // ** 備考 **
      remarks: {
        text: '',
      },
      // ** モーニング **
      morning: {
        product: {
          product_id: null,
          size: null,
        },
        price: null,
      },
      // ** パーティドレス **
      partyDress: {
        product: {
          product_id: null,
          product_name: null,
          shop_name: null,
          price: null,
        },
        freeInputPrice: null, // レンタル価格フリー入力時
      },
      // ** その他商品 **
      otherItem: {
        apply: 0,
        items: [],
      },
      // ** オプション **
      optionItems: {
        apply: 4,
      },
      // ** 見積一覧 **
      estimateList: {
        selectedPlan: null,
        amount_adjustments: [],
        amount_adjustment_validation: [],
        estimateId: null,
      },
      pdfUrl: null,
    },
    // ** タブ **
    tabList: [
      {
        text: '基本設定',
        isComplete: null,
        isShow: true,
        isCurrent: true,
      },
      {
        text: '振袖',
        isComplete: null,
        isShow: true,
        isCurrent: false,
      },
      {
        text: '訪問着',
        isComplete: null,
        isShow: false,
        isCurrent: false,
      },
      {
        text: '留袖',
        isComplete: null,
        isShow: false,
        isCurrent: false,
      },
      {
        text: 'モーニング',
        isComplete: null,
        isShow: false,
        isCurrent: false,
      },
      {
        text: '初着',
        isComplete: null,
        isShow: false,
        isCurrent: false,
      },
      {
        text: 'パーティドレス',
        isComplete: null,
        isShow: false,
        isCurrent: false,
      },
      {
        text: '帯',
        isComplete: null,
        isShow: false,
        isCurrent: false,
      },
      {
        text: '帯締め',
        isComplete: null,
        isShow: false,
        isCurrent: false,
      },
      {
        text: '商品設定',
        isComplete: null,
        isShow: true,
        isCurrent: false,
      },
      {
        text: '帯揚げ',
        isComplete: null,
        isShow: false,
        isCurrent: false,
      },
      {
        text: '帯揚げ - 2',
        isComplete: null,
        isShow: false,
        isCurrent: false,
      },
      {
        text: '帯揚げ - 3',
        isComplete: null,
        isShow: false,
        isCurrent: false,
      },
      {
        text: '重ね衿',
        isComplete: null,
        isShow: false,
        isCurrent: false,
      },
      {
        text: '重ね衿 - 2',
        isComplete: null,
        isShow: false,
        isCurrent: false,
      },
      {
        text: '重ね衿 - 3',
        isComplete: null,
        isShow: false,
        isCurrent: false,
      },
      {
        text: '別衿',
        isComplete: null,
        isShow: false,
        isCurrent: false,
      },
      {
        text: 'ショール',
        isComplete: null,
        isShow: false,
        isCurrent: false,
      },
      {
        text: '髪飾り',
        isComplete: null,
        isShow: false,
        isCurrent: false,
      },
      {
        text: '髪飾り - 2',
        isComplete: null,
        isShow: false,
        isCurrent: false,
      },
      {
        text: '髪飾り - 3',
        isComplete: null,
        isShow: false,
        isCurrent: false,
      },
      {
        text: '草履・バッグ',
        isComplete: null,
        isShow: true,
        isCurrent: false,
      },
      {
        text: '草履',
        isComplete: null,
        isShow: false,
        isCurrent: false,
      },
      {
        text: 'バッグ',
        isComplete: null,
        isShow: false,
        isCurrent: false,
      },
      {
        text: '試着写真',
        isComplete: null,
        isShow: true,
        isCurrent: false,
      },
      {
        text: '足し布',
        isComplete: null,
        isShow: false,
        isCurrent: false,
      },
      {
        text: 'その他商品',
        isComplete: null,
        isShow: true,
        isCurrent: false,
      },
      {
        text: 'その他割引',
        isComplete: true,
        isShow: true,
        isCurrent: false,
      },
      {
        text: 'オプション',
        isComplete: true,
        isShow: true,
        isCurrent: false,
      },
      {
        text: '備考',
        isComplete: null,
        isShow: true,
        isCurrent: false,
      },
    ],
    // ** 最終ページ **
    finalPageList: [
      {
        text: '入力内容確認',
        isComplete: null,
        isShow: false,
        isCurrent: true,
      },
      {
        text: '見積一覧',
        isComplete: null,
        isShow: true,
        isCurrent: false,
      },
      {
        text: '見積作成完了',
        isComplete: null,
        isShow: true,
        isCurrent: false,
      },
    ],
    contractId: null,
  }
}

export const initObi = {
  shubetsu: null, // 帯の種別
  keitai: null, // 帯の形態
  shitate: null, // 仕立て上がりか
  obiInfo: null, // 帯情報
  obiName: null, // 帯の名前(仕立て上がりではない場合)
  rentalPrice: '¥0', // レンタル価格
  rentalTanpinPrice: null, // 単品レンタル価格
  buyTanpinPrice: null, // 単品購入価格
  rankUpPrice: {
    id: null,
    value: '',
  }, // ランクアップPP内の選択価格
  rankUpFreePrice: null, // ランクアップPP内のフリー入力価格
  rankUpInputPrice: null, // ランクアップPP外の価格
  rankUpInputPpPrice: null, // ランクアップPP外のPP選択時の価格
}

const state = (): State => getInitialStore()

export default state
