import { GetterTree } from 'vuex'
import { RootState, PhotoSettings } from '../../types'

const getters: GetterTree<PhotoSettings, RootState> = {
  getIsValidate: (state: PhotoSettings) => {
    return state.isValidate
  },
  getMainPhoto: (state: PhotoSettings) => {
    return state.photoSetting
  },
  getWPhoto: (state: PhotoSettings) => {
    return state.wPhotoSetting
  },
}

export default getters
