import { computed } from '@nuxtjs/composition-api'
import { ShowPriceType } from './types'
import { usePlanFlags } from '~/composable/estimate/domain/Graduation/PlanFlags'
import {
  Enum草履ブーツ_着物持込,
  Enum草履ブーツ_着物レンタル,
} from '~/constants/estimate/graduationOptions'
import { FormValues草履ブーツ_着物持込 } from '~/composable/estimate/viewModel/graduation/Fields/Fields草履ブーツ_着物持込'
import { FormValues草履ブーツ_着物レンタル } from '~/composable/estimate/viewModel/graduation/Fields/Fields草履ブーツ_着物レンタル'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import {
  getPriceFromEditPrice,
  getPriceFromSelectPriceWithFreeInput,
  getShowPrice,
} from '~/composable/estimate/repository/graduation/states/utilities'

type ZoriBoots = {
  品種区分: 27 | 42 // 草履: 27/ブーツ: 42
  商品名: string | null
  商品id: number | null
  小物プラン: number | null
  レンタル価格: number | null
  購入価格: number | null
  見積表示: ShowPriceType | null
  表示価格: number | null
  isランクアップ: boolean
  isセット内: boolean
  label: string
}

export const useState草履ブーツ = () => {
  const store草履ブーツ_着物持込 =
    useGraduateStore<FormValues草履ブーツ_着物持込>('tab草履ブーツ_着物持込')
  const store草履ブーツ_着物レンタル =
    useGraduateStore<FormValues草履ブーツ_着物レンタル>(
      'tab草履ブーツ_着物レンタル'
    )
  const values草履ブーツ_着物持込 = store草履ブーツ_着物持込.getAll()
  const values草履ブーツ_着物レンタル = store草履ブーツ_着物レンタル.getAll()
  const { hasKimonoRental } = usePlanFlags()

  return computed(() => {
    const zoriBoots: ZoriBoots = {
      品種区分: 27,
      商品名: null,
      商品id: null,
      小物プラン: null,
      レンタル価格: null,
      購入価格: null,
      見積表示: null,
      表示価格: null,
      isランクアップ: false,
      isセット内: false,
      label: '',
    }

    if (!hasKimonoRental.value) {
      /*
       * プランで着物持込を選んだ際の草履ブーツタブ選択内容
       */
      const 品種選択 = values草履ブーツ_着物持込.value.zoriboots商品
      const 種別選択 = values草履ブーツ_着物持込.value.zoriboots種別
      const 商品選択 = values草履ブーツ_着物持込.value.zoriboots商品選択
      const ブーツを選択 = 品種選択 === Enum草履ブーツ_着物持込.品目.ブーツ
      const お持込を選択 = 種別選択 === Enum草履ブーツ_着物持込.種別.お持込
      const 現品を選択 = 商品選択?.id === Enum草履ブーツ_着物持込.草履商品.現品

      if (ブーツを選択) {
        zoriBoots.品種区分 = Enum草履ブーツ_着物持込.品目.ブーツ
        zoriBoots.小物プラン = Enum草履ブーツ_着物持込.種別.お持込
        zoriBoots.label = 'お持込'
        zoriBoots.商品名 = 'ブーツ'
        return zoriBoots
      }
      if (お持込を選択) {
        zoriBoots.小物プラン = Enum草履ブーツ_着物持込.種別.お持込
        zoriBoots.label = 'お持込'
        zoriBoots.商品名 = null
        return zoriBoots
      }

      if (現品を選択) {
        zoriBoots.レンタル価格 = getPriceFromSelectPriceWithFreeInput(
          values草履ブーツ_着物持込.value.zoriboots現品単品レンタル,
          values草履ブーツ_着物持込.value.zoriboots現品単品レンタルフリー入力
        )
        zoriBoots.購入価格 = getPriceFromSelectPriceWithFreeInput(
          values草履ブーツ_着物持込.value.zoriboots現品単品購入,
          values草履ブーツ_着物持込.value.zoriboots現品単品購入フリー入力
        )
        zoriBoots.商品名 = '現品'
      } else {
        // 現品以外の商品を選択した場合
        zoriBoots.レンタル価格 = getPriceFromEditPrice(
          values草履ブーツ_着物持込.value.zoriboots単品レンタル
        )
        zoriBoots.購入価格 = getPriceFromEditPrice(
          values草履ブーツ_着物持込.value.zoriboots単品購入
        )
        if (商品選択?.name) zoriBoots.商品名 = 商品選択?.name
        if (商品選択?.id) zoriBoots.商品id = 商品選択.id
      }
      const { showType, showPrice } = getShowPrice(
        values草履ブーツ_着物持込.value.zoriboots見積選択,
        zoriBoots.レンタル価格,
        zoriBoots.購入価格,
        Enum草履ブーツ_着物持込.見積表示
      )
      zoriBoots.小物プラン = Enum草履ブーツ_着物持込.種別.単品レンタル単品購入
      zoriBoots.見積表示 = showType
      zoriBoots.表示価格 = showPrice

      return zoriBoots
    } else {
      /*
       * プランで着物レンタルを選んだ際の草履ブーツタブ選択内容
       */
      const 品種選択 = values草履ブーツ_着物レンタル.value.zoriboots商品
      const 種別選択 = values草履ブーツ_着物レンタル.value.zoriboots種別
      const 形態選択 = values草履ブーツ_着物レンタル.value.zoriboots形態
      const 商品選択 = values草履ブーツ_着物レンタル.value.zoribootsランクアップ
      const 購入方法選択 =
        values草履ブーツ_着物レンタル.value.zoriboots規定商品購入方法

      const ブーツを選択 = 品種選択 === Enum草履ブーツ_着物レンタル.品目.ブーツ
      const お持込を選択 = 種別選択 === Enum草履ブーツ_着物レンタル.種別.お持込
      const セット内を選択 =
        形態選択 === Enum草履ブーツ_着物レンタル.形態.セット内
      const 現品を選択 =
        商品選択?.id === Enum草履ブーツ_着物レンタル.草履商品.現品
      const 単品購入を選択 =
        購入方法選択 === Enum草履ブーツ_着物レンタル.レンタルOR購入.単品購入

      if (ブーツを選択) {
        zoriBoots.品種区分 = Enum草履ブーツ_着物レンタル.品目.ブーツ
        zoriBoots.小物プラン = Enum草履ブーツ_着物レンタル.種別.お持込
        zoriBoots.商品名 = null
        zoriBoots.label = 'お持込'
        return zoriBoots
      }
      if (お持込を選択) {
        zoriBoots.小物プラン = Enum草履ブーツ_着物レンタル.種別.お持込
        zoriBoots.label = 'お持込'
        zoriBoots.商品名 = null
        return zoriBoots
      }
      if (セット内を選択) {
        zoriBoots.小物プラン = Enum草履ブーツ_着物レンタル.形態.セット内
        zoriBoots.レンタル価格 = getPriceFromEditPrice(
          values草履ブーツ_着物レンタル.value.zoribootsセット内レンタル価格
        )
        zoriBoots.表示価格 = zoriBoots.レンタル価格
        zoriBoots.label = 'セット内'
        zoriBoots.商品名 = null
        zoriBoots.isセット内 = true
        return zoriBoots
      }

      if (現品を選択) {
        zoriBoots.レンタル価格 = getPriceFromSelectPriceWithFreeInput(
          values草履ブーツ_着物レンタル.value.zoriboots現品レンタル価格,
          values草履ブーツ_着物レンタル.value
            .zoriboots現品レンタル価格フリー入力
        )
        zoriBoots.購入価格 = getPriceFromSelectPriceWithFreeInput(
          values草履ブーツ_着物レンタル.value.zoriboots現品単品購入,
          values草履ブーツ_着物レンタル.value.zoriboots現品単品購入フリー入力
        )
        zoriBoots.商品名 = '現品'
      }
      if (!現品を選択) {
        // 現品以外の商品を選択した場合
        if (単品購入を選択) {
          zoriBoots.購入価格 = getPriceFromEditPrice(
            values草履ブーツ_着物レンタル.value.zoriboots規定商品単品購入
          )
        } else {
          zoriBoots.レンタル価格 = getPriceFromEditPrice(
            values草履ブーツ_着物レンタル.value.zoriboots規定商品
          )
        }
        zoriBoots.商品名 =
          values草履ブーツ_着物レンタル.value.zoribootsランクアップ?.name ??
          null
        zoriBoots.商品id = 商品選択?.id ?? null
      }

      const showInfo = getShowPriceInfoFromレンタルか単品購入(
        values草履ブーツ_着物レンタル.value.zoriboots規定商品購入方法,
        zoriBoots.レンタル価格,
        zoriBoots.購入価格,
        Enum草履ブーツ_着物レンタル.レンタルOR購入
      )

      if (showInfo.isRankUp) {
        zoriBoots.小物プラン =
          Enum草履ブーツ_着物レンタル.レンタルOR購入.ランクアップレンタル
        zoriBoots.label = 'ランクアップ'
      } else {
        zoriBoots.小物プラン =
          Enum草履ブーツ_着物レンタル.レンタルOR購入.単品購入
      }
      zoriBoots.isランクアップ = showInfo.isRankUp
      zoriBoots.見積表示 = showInfo.showType
      zoriBoots.表示価格 = showInfo.showPrice

      return zoriBoots
    }
  })
}

const getShowPriceInfoFromレンタルか単品購入 = (
  レンタルOR購入: number | null,
  レンタル価格: number | null,
  購入価格: number | null,
  optionEnum: typeof Enum草履ブーツ_着物レンタル['レンタルOR購入']
) => {
  const 単品購入を選択 = レンタルOR購入 === optionEnum.単品購入
  const showType: ShowPriceType = {
    id: 単品購入を選択 ? 2 : null,
    value: 単品購入を選択 ? '単品購入' : '',
  }
  const showPrice = 単品購入を選択 ? 購入価格 : レンタル価格
  const isRankUp = !単品購入を選択
  return { showType, showPrice, isRankUp }
}
