import { MutationTree } from 'vuex'
import { getInitialStore } from './state'
import { FormValues別衿_着物レンタル } from '~/composable/estimate/viewModel/graduation/Fields/Fields別衿_着物レンタル'

const mutations: MutationTree<{ fields: FormValues別衿_着物レンタル }> = {
  setState: (
    state,
    payload: { stateName: keyof FormValues別衿_着物レンタル; value: any }
  ) => {
    state.fields[payload.stateName] = payload.value
  },

  setAllState: (state, values: FormValues別衿_着物レンタル) => {
    state.fields = values
  },

  initState: (state, fieldId: keyof FormValues別衿_着物レンタル) => {
    const initialStore = getInitialStore()
    state.fields[fieldId] = initialStore[fieldId] as any
  },

  initAllState: (state) => {
    state.fields = getInitialStore()
  },
}

export default mutations
