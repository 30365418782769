import dayjs from 'dayjs'

/** restorers */
import { useStore, watch } from '@nuxtjs/composition-api'
import { useRestoreBaseEstimate } from '../../../../contracts/service/ContractRestorer/restoreBaseEstimate'
import { useRestoreお名前 } from './restorers/restoreお名前'
import { useRestoreお客様検索 } from './restorers/restoreお客様検索'
import { useRestoreプラン } from './restorers/restoreプラン'
import { useRestore備考 } from './restorers/restore備考'
import { useRestore割引 } from './restorers/restore割引'
import { useRestore半巾帯 } from './restorers/restore半巾帯'
import { useRestore商品設定 } from './restorers/restore商品設定'
import { useRestoreお写真プラン } from './restorers/restoreお写真プラン'
import { useRestore巾着バッグ } from './restorers/restore巾着バッグ'
import { useRestore草履ブーツ } from './restorers/restore草履ブーツ'
import { useRestore袴 } from './restorers/restore袴'
import { useRestore試着写真 } from './restorers/restore試着写真'
import { useRestore重ね衿 } from './restorers/restore重ね衿'
import { useRestore別衿 } from './restorers/restore別衿'
import { useRestore髪飾り } from './restorers/restore髪飾り'
import { useRestore振袖 } from './restorers/restore振袖'
import { useRestore二尺袖 } from './restorers/restore二尺袖'
import { useEstimateRestate } from '~/composable/estimate/repository/graduation/EstimateRestate'
import { useInitializeState } from '~/composable/general/useInitializeState'
import { useRestore見積一覧 } from './restorers/restore見積一覧'
import { useRestorePriceTable } from './restorers/restorePriceTable'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'

/** tyes */
import { BaseEstimateState } from '~/store/Contracts/types'
import { CopiedEstimateState } from '~/store/Estimate/GraduationCeremony/CopiedEstimate/state'
import { Enum振袖二尺袖 } from '~/constants/estimate/graduationOptions'
import ProductClassification from '~/constants/enums/productClassification'
import { 卒業式二尺袖レンタル袴持ち込み値引適用開始日 } from '~/constants/estimate/graduationOptions'
import { useRestoreその他商品 } from './restorers/restoreその他商品'

/**
 * 見積詳細APIからの値をストアに復元する処理を行う
 * 詳細は notion を参照のこと
 * https://www.notion.so/arsagajp/1a60d3b1985840aa8cdf53ba20bfd633
 */
export const useEstimateRestorer = () => {
  const store = useStore()
  const { fetchInfoForEstimateRestate, restate, originalRes } =
    useEstimateRestate()
  const { initAllDocumentStore, initEstimateStore } = useInitializeState()
  const storeコピー元見積 =
    useGraduateStore<CopiedEstimateState>('CopiedEstimate')

  // 契約更新時に新規で作成された見積かどうか
  const isNewEstimate: BaseEstimateState['isNewEstimate'] =
    store.getters['Contracts/BaseEstimate/getIsNewEstimate']

  const currentBaseEstimate: BaseEstimateState =
    store.getters['Contracts/BaseEstimate/getEstimate']

  /* restorers */
  const { restoreお名前 } = useRestoreお名前()
  const { restoreプラン } = useRestoreプラン()
  const { restore振袖 } = useRestore振袖()
  const { restore二尺袖 } = useRestore二尺袖()
  const { restore試着写真 } = useRestore試着写真()
  const { restore袴 } = useRestore袴()
  const { restore重ね衿 } = useRestore重ね衿()
  const { restore髪飾り } = useRestore髪飾り()
  const { restore巾着バッグ } = useRestore巾着バッグ()
  const { restore半巾帯 } = useRestore半巾帯()
  const { restore商品設定 } = useRestore商品設定()
  const { restoreお写真プラン } = useRestoreお写真プラン()
  const { restore備考 } = useRestore備考()
  const { restore別衿 } = useRestore別衿()
  const { restore割引 } = useRestore割引()
  const { restoreお客様検索 } = useRestoreお客様検索()
  const { restore草履ブーツ } = useRestore草履ブーツ()
  const { restoreその他商品 } = useRestoreその他商品()
  const { restore見積一覧 } = useRestore見積一覧()
  const { restorePriceTable } = useRestorePriceTable()
  const { setBaseEstimate } = useRestoreBaseEstimate()

  const restoreEstimate = async (
    estimateId: number,
    branch: number,
    initContract: boolean = true,
    contractId?: number,
    isContractFrow?: boolean
  ) => {
    await fetchInfoForEstimateRestate(estimateId, branch)

    const restore = () => {
      restoreお名前(restate.value.customer?.fullName)
      restoreプラン(restate.value)
      restore振袖(restate.value.hurisode, contractId)
      restore二尺袖(restate.value.hurisode)
      restore試着写真(restate.value.photos)
      restore袴(restate.value.hakama)
      restore半巾帯(restate.value)
      restore商品設定(restate.value.items)
      restore重ね衿(restate.value)
      restore別衿(restate.value)
      restore草履ブーツ(restate.value)
      restore髪飾り(restate.value)
      restore割引(restate.value.discounts)
      restore半巾帯(restate.value)
      restore巾着バッグ(restate.value)
      restoreその他商品(restate.value)
      restore商品設定(restate.value.items)
      restoreお写真プラン(restate.value)
      restore備考(restate.value.remarks)
      restoreお客様検索(restate.value.customer)
      restore見積一覧(
        restate.value.amount_adjustments,
        restate.value.selectedPlan
      )
      restorePriceTable(restate.value.price_information)

      if (restate.value.estimateInfo && !isNewEstimate) {
        setBaseEstimate(
          restate.value.estimateInfo,
          restate.value.customer?.profile
        ) // 見積詳細
      }

      // 別の見積を反映する場合は、再度baseEstimateにセットする
      if (isNewEstimate) {
        store.commit('Contracts/BaseEstimate/setEstimate', currentBaseEstimate)
        store.commit('Contracts/BaseEstimate/setIsNewEstimate', true)
      }

      const isNishakusodeカタログ商品 =
        originalRes.value?.hurisode?.category ===
          Enum振袖二尺袖.カタログ商品OR現品.カタログ商品 &&
        originalRes.value?.hurisode?.product_type_id ===
          ProductClassification.nisyakusode

      const isHakamaカタログ商品 =
        originalRes.value?.hakama?.category ===
        Enum振袖二尺袖.カタログ商品OR現品.カタログ商品

      // 二尺袖レンタルが着付け無料になるのは対象機能リリース後のため、リリース前の見積もりは既存の動きをする
      const isEstimateBeforeRelease = dayjs(
        restate.value.estimateInfo?.estimate_date
      ).isBefore(卒業式二尺袖レンタル袴持ち込み値引適用開始日)

      storeコピー元見積.setAll({
        estimate: originalRes.value ?? null,
        isCopyEstimateFlag: {
          flagForNishakusode: isNishakusodeカタログ商品,
          flagForHakama: isHakamaカタログ商品,
          isEstimateBeforeReleaseForNishakusode:
            (isContractFrow && isEstimateBeforeRelease) ?? false, // 契約書フローかどうか
        },
      })
    }
    // NOTE: 契約変更時に参照先の見積を変更するために新見積の見積一覧に変遷した場合は全てのストアをクリアする
    isNewEstimate || !initContract
      ? initEstimateStore()
      : initAllDocumentStore()

    restore()
    // NOTE: 非同期に取得した値に更新するためwatch
    watch(restate, restore, { deep: true })
  }

  return { restoreEstimate, originalRes, restate }
}
