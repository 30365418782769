import { computed } from '@nuxtjs/composition-api'
import { usePrice } from '~/composable/general/Price'
import {
  Enumプラン,
  Enum振袖二尺袖,
} from '~/constants/estimate/graduationOptions'
import { WithFreeInput } from '~/store/Estimate/GraduationCeremony/types'
import { State as PricesState } from '~/store/PriceTable/state'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { useTomonokaiFlag } from '~/composable/estimate/domain/Graduation/TomonokaiFlag'
import { use成人式フルセットFlags } from '~/composable/estimate/domain/Graduation/成人式フルセットFlags'
import { FormValuesプラン } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsプラン'
import { FormValues二尺袖 } from '~/composable/estimate/viewModel/graduation/Fields/Fields二尺袖'
import { FormValues振袖 } from '~/composable/estimate/viewModel/graduation/Fields/Fields振袖'

type Flags = Partial<{
  isお持込: boolean
  is振袖: boolean
  is二尺袖保留: boolean
  is振袖保留: boolean
  is二尺袖: boolean
  isお姉様購入: boolean
  isレンタル: boolean
  isカタログレンタル: boolean
  is振袖価格表示あり: boolean
}>

/**
 * 振袖・二尺袖タブは別のタブだが、どちらか片方の選択しかないので、
 * 表示先で扱いやすい様にEstimateStateとしては一つの扱いにしている
 */
export const useState振袖二尺袖 = () => {
  const values振袖 = useGraduateStore<FormValues振袖>('tab振袖').getAll()
  const values二尺袖 = useGraduateStore<FormValues二尺袖>('tab二尺袖').getAll()
  const valuesプラン = useGraduateStore<FormValuesプラン>('tabプラン').getAll()
  const storePriceTable =
    useGraduateStore<FormValues振袖>('tab振袖').getPrices()
  const { isTomonokai } = useTomonokaiFlag()
  const { is成人式フルセット } = use成人式フルセットFlags()

  return computed(() => {
    const { 着物種別 } = Enumプラン
    const isお持込 = [着物種別.お持込, 着物種別.姉販持込].some(
      (i) => i === valuesプラン.value.plan着物種別
    )
    const is振袖 =
      valuesプラン.value.plan着物種類 === Enumプラン.着物を選択.振袖
    const is二尺袖 =
      valuesプラン.value.plan着物種類 === Enumプラン.着物を選択.二尺袖
    const isお姉様購入 =
      valuesプラン.value.plan着物種別 === Enumプラン.着物種別.姉販持込
    const is振袖価格表示あり =
      !isお持込 && !isTomonokai.value && is成人式フルセット.value

    const category = getCategory(values二尺袖.value, {
      isお持込,
      is二尺袖,
      is振袖,
      isお姉様購入,
    })

    const isレンタル = category === Enum振袖二尺袖.レンタルORカタログ.レンタル
    const isカタログレンタル =
      category === Enum振袖二尺袖.カタログ商品OR現品.カタログ商品
    const is振袖保留 = !!values振袖.value.furisode保留 && is振袖 && !isお持込
    const is二尺袖保留 =
      isレンタル && !!values二尺袖.value.nishaku保留 && !isお持込

    const kemonoTypeFlags = {
      is振袖,
      is二尺袖,
      isレンタル,
      isカタログレンタル,
      is二尺袖保留,
    }
    const prices = getPrices(
      values振袖.value,
      values二尺袖.value,
      kemonoTypeFlags,
      storePriceTable
    )
    const 着物検索 = getSearchedKimono(
      values振袖.value,
      values二尺袖.value,
      kemonoTypeFlags
    )
    const 選択着物名 = is二尺袖 ? '二尺袖' : '振袖'
    const カタログ在庫 =
      values二尺袖.value.nishakuカタログ検索?.available_order_quantity
    const 価格選択 = values振袖.value.furisode商品情報_価格選択?.id
    const is友の会割 = 価格選択 === Enum振袖二尺袖.価格選択.振袖友の会成人式フル

    return {
      // レンタル:1, お持ち込み:3, カタログレンタル:15 お姉様購入のお持込: 4
      商品分類: category,
      レンタル価格: isお持込 ? null : prices.レンタル価格,
      振袖本体価格: is振袖価格表示あり ? prices.振袖本体価格 : null,
      品種区分: valuesプラン.value.plan着物種類, // 振袖:1, 二尺袖: 8
      選択着物名: valuesプラン.value.plan着物種類 ? 選択着物名 : '未選択',
      着物検索: isお持込 ? null : 着物検索,
      カタログ在庫: isカタログレンタル ? カタログ在庫 : null,
      ラベル: isお持込 ? 'お持込' : '',
      discountType: getDiscountType(values振袖.value, { is二尺袖 }),
      // NOTE: 真偽値判定するフラグはここで定義してstateからアクセスできる様にする
      flags: {
        isお持込,
        is振袖,
        is二尺袖,
        isお姉様購入,
        isレンタル,
        isカタログレンタル,
        is振袖価格表示あり,
        is二尺袖保留,
        is友の会割,
        is振袖保留,
        is80Off:
          values振袖.value.furisode商品情報_価格選択?.id ===
          Enum振袖二尺袖.価格選択.振袖成人式フル1,
        is振袖MODE購入品: isお持込 ? isお姉様購入 : null,
        is裄補正:
          is振袖 && !isお持込
            ? values振袖.value.furisode裄補正 === Enum振袖二尺袖.裄補正.する
            : false,
        is袖補正:
          is振袖 && !isお持込
            ? values振袖.value.furisode袖補正 === Enum振袖二尺袖.袖補正.する
            : false,
      },
    }
  })
}

const getPriceFromSelectPriceWithFreeInput = (
  selectedOption: WithFreeInput | null,
  inputPrice: string | null,
  hontaiPrice: string | null = null,
  storePriceTable: PricesState['prices']
) => {
  if (selectedOption === null) return null
  const { toNumber } = usePrice()
  const フリー入力選択 = selectedOption.id === 0
  const 割引80OFF選択 =
    selectedOption.id === Enum振袖二尺袖.価格選択.振袖成人式フル1

  if (フリー入力選択) {
    if (inputPrice === null) return null
    return toNumber(inputPrice)
  } else if (割引80OFF選択) {
    if (hontaiPrice === null) return null
    const rate = storePriceTable.estimate.割引率.成人式ご利用割引振袖
    return toNumber(hontaiPrice) * ((100 - rate) / 100)
  } else {
    return toNumber(selectedOption.value)
  }
}

const getCategory = (values二尺袖: FormValues二尺袖, flags: Flags) => {
  // NOTE: 振袖の時はお持込かレンタル・二尺袖はそれプラスカタログの場合がある
  if (flags.isお姉様購入) {
    return Enumプラン.着物種別.姉販持込
  }
  if (flags.isお持込) {
    return Enumプラン.着物種別.お持込
  }
  if (flags.is振袖) {
    return Enumプラン.着物種別.レンタル
  } else if (flags.is二尺袖) {
    if (
      values二尺袖.nishakuIsカタログ商品 ===
      Enum振袖二尺袖.カタログ商品OR現品.現品
    ) {
      return Enum振袖二尺袖.レンタルORカタログ.レンタル
    } else if (
      values二尺袖.nishakuIsカタログ商品 ===
      Enum振袖二尺袖.カタログ商品OR現品.カタログ商品
    ) {
      return Enum振袖二尺袖.レンタルORカタログ.カタログレンタル
    }
  }
  return null
}

const getPrices = (
  values振袖: FormValues振袖,
  values二尺袖: FormValues二尺袖,
  flags: Flags,
  storePriceTable: PricesState['prices']
) => {
  const { toNumber } = usePrice()
  const prices = {
    レンタル価格: null as number | null,
    振袖本体価格: null as number | null,
  }

  const is二尺袖レンタル = flags.is二尺袖 && flags.isレンタル

  if (flags.is振袖) {
    prices.レンタル価格 = getPriceFromSelectPriceWithFreeInput(
      values振袖.furisode商品情報_価格選択,
      values振袖.furisode商品情報_価格入力,
      values振袖.furisode商品情報_振袖本体,
      storePriceTable
    )
    prices.振袖本体価格 = values振袖.furisode商品情報_振袖本体
      ? toNumber(values振袖.furisode商品情報_振袖本体)
      : null
  }
  if (is二尺袖レンタル || flags.is二尺袖保留) {
    prices.レンタル価格 = getPriceFromSelectPriceWithFreeInput(
      values二尺袖.nishaku商品情報_価格選択,
      values二尺袖.nishaku商品情報_価格入力,
      null,
      storePriceTable
    )
  }
  if (flags.is二尺袖 && flags.isカタログレンタル) {
    const カタログレンタル価格 = values二尺袖.nishakuカタログ_価格編集
    prices.レンタル価格 = toNumber(カタログレンタル価格 || '')
  }
  return prices
}

const getSearchedKimono = (
  values振袖: FormValues振袖,
  values二尺袖: FormValues二尺袖,
  flags: Flags
) => {
  if (flags.is振袖) {
    return values振袖.furisode振袖検索
  }
  if (flags.is二尺袖 && flags.isレンタル) {
    return values二尺袖.nishaku仕立上り検索
  }
  if (flags.is二尺袖 && flags.isカタログレンタル) {
    return values二尺袖.nishakuカタログ検索
  }
  return null
}

const getDiscountType = (values振袖: FormValues振袖, flags: Flags) => {
  if (flags.is二尺袖) return undefined
  const 価格選択 = values振袖.furisode商品情報_価格選択?.id
  const is友の会割 = 価格選択 === Enum振袖二尺袖.価格選択.振袖友の会成人式フル
  const is80Off = 価格選択 === Enum振袖二尺袖.価格選択.振袖成人式フル1
  const is固定金額 = 価格選択 === Enum振袖二尺袖.価格選択.振袖成人式フル2
  if (is80Off) return 2
  if (is固定金額) return 3
  if (is友の会割) return 4
  return undefined
}
