/** types */
import { ProductTypeIdList } from '~/api/checklistRepository'
import type { CheckboxType } from '~/constants/checklist/checkbox'
import type { CheckboxStatus } from '~/constants/checklist/checkbox'
import type {
  ChecklistContractType,
  ContractChecklistType,
} from '~/constants/checklist/contract'

// ** チェックリスト情報 **
export type CheckboxState = {
  checklistId: number // checklistテーブルの採番ID（DB識別用）
  checkType: CheckboxType // 1:メンテナンス・仕立て出し、2:到着チェック、3: 前撮り済み戻し・お渡し
  checkStatus: CheckboxStatus // 1: チェック未、2: 済み、3: チェック無効（disabled）、9: チェック対象外
  checkDate: string // // チェック完了日
  staffId?: number // チェック者
}

/** 商品情報 */
export type Product = {
  productTypeId: ProductTypeIdList | null // 品種ID
  productName: string | null // 商品名
  productId: number | null // 商品ID
  catalogCode: string | null // カタログコード
  productClassificationId: number | null // 商品分類
  plan: number | null // プラン
  priceType: number | null // 単品価格の種類
  orderNumber: number | null // 商品の順番
  size: string | null // サイズ
  color: string | null // 色
  footSize: number | null // 足サイズ
  number: number | null // 数量
}

// ** タブ **
export type Tab = {
  id: number
  label: string
}

export type State = {
  data: {
    customerId: number | null // 顧客ID
    contractId: number | null // チェックリストに紐づく契約書ID
    plan: number | null // プラン
    type: ChecklistContractType | null // 契約区分
    checklistSetting: {
      contractType: ContractChecklistType // サービスタイプ
      contractServiceType: number // サービス区分
      estimateId: number | null // 見積ID
      checklist: {
        branchNumber: number // DBのデータ特定に必要な値（contract_detailsのbranch_number）
        checklistNumber: number
        checklistProduct: Product // 商品情報
        checkboxInfo: CheckboxState[] // チェックリスト情報
      }[]
      checklistCompletion: {
        maintenanceTailoring: {
          status: CheckboxStatus // メンテナンス・仕立て出し
          checkCompletedDate?: string // チェック完了日
          staffId: number | null // スタッフID
        }
        arrival: {
          status: CheckboxStatus // 到着チェック
          checkCompletedDate?: string //  チェック完了日
          staffId: number | null // スタッフID
        }
        prePhotosInAdvance: {
          status: CheckboxStatus // 前撮り済み戻し・お渡し
          checkCompletedDate?: string // チェック完了日
          staffId: number | null // スタッフID
        }
      }
    }[]
  }
  selectedChecklistId: number // checklistのid(参列以外はcontractType、参列の場合はestimateId)
  tabList: Tab[] // タブ
}

export type ChecklistRecord = State['data']
export type ChecklistRow =
  ChecklistRecord['checklistSetting'][number]['checklist'][number]
export type ChecklistCompletion =
  ChecklistRecord['checklistSetting'][number]['checklistCompletion']

export const getInitialStore = (): State => {
  return {
    data: {
      customerId: null,
      contractId: null,
      plan: null,
      type: null,
      // ** 商品・チェックリスト情報 **
      checklistSetting: [],
    },
    // ** タブ **
    tabList: [],
    selectedChecklistId: -1,
  }
}

const state = (): State => getInitialStore()

export default state
