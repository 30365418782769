import { GetterTree } from 'vuex'
import { RootState, OnlyInputPlanState } from '../../../types'

const getters: GetterTree<OnlyInputPlanState, RootState> = {
  // NOTE: useComingStore用に追加
  getAllState: (state) => {
    return state
  },
  getState: (state: OnlyInputPlanState) => {
    return state
  },

  getSelections: (state: OnlyInputPlanState) => {
    return state.selections
  },

  getInput: (state: OnlyInputPlanState) => {
    return state.input
  },

  getIsValidate: (state: OnlyInputPlanState) => {
    return state.isValidate
  },
}

export default getters
