import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.locale('ja')
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

/**
 * クーリングオフ期限日を返却する
 * 契約した日 = 契約書面を受領した日を初日（1 日目）として数えます。
 *
 * 【例】 平成28 年5 月18 日（水）に契約書面を受け取った場合、 → 平成28 年5 月25 日（水）まで
 * @param contractDate 契約日
 * @returns
 */
export const getCoolingoffLimit = (contractDate: string | Date | dayjs.Dayjs) =>
  dayjs(contractDate).add(7, 'd')

/**
 * 残金のお支払期限を返却する
 * 契約した日 = 契約書面を受領した日を初日（1 日目）として数えます。
 *
 * 【例】 平成28 年5 月18 日（水）に契約書面を受け取った場合、 → 平成28 年5 月27 日（金）まで
 * @param contractDate 契約日
 * @returns
 */
export const getPaymentDeadline = (contractDate: string | Date | dayjs.Dayjs) =>
  dayjs(contractDate).add(9, 'd')
