import { Restate } from '~/composable/estimate/repository/graduation/EstimateRestate'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { FormValues振袖 } from '~/composable/estimate/viewModel/graduation/Fields/Fields振袖'
import { Enum振袖二尺袖 } from '~/constants/estimate/graduationOptions'

export const useRestore振袖 = () => {
  const store = useGraduateStore<FormValues振袖>('tab振袖')

  const restore振袖 = (hurisode: Restate['hurisode'], contractId?: number) => {
    if (!hurisode || !hurisode.flags.is振袖 || hurisode.flags.is持込) {
      return
    }

    /* 初期値を設定 ※必ず入力される値はここで設置 */
    const formValues: FormValues振袖 = {
      furisode振袖検索: null,
      furisode裄補正: null,
      furisode袖補正: null,
      furisode商品情報_振袖本体: null,
      furisode商品情報_価格選択: { id: 0, value: '' }, // フリー入力を選択
      furisode商品情報_価格入力: null,
      furisode保留: false, // 保留状態では見積作成できない仕様のため、復元時においては振袖保留のFlagは常にfalse
      furisode契約番号: 0,
    }

    if (contractId) {
      formValues.furisode契約番号 = contractId
    }

    /* 以下で入力内容の条件に基づき、必要な項目を埋めていく */
    if (hurisode.flags.is仕立て上り振袖 && hurisode.仕立て上り検索結果) {
      formValues.furisode振袖検索 = hurisode.仕立て上り検索結果
      formValues.furisode裄補正 = hurisode.optionId裄補正
      formValues.furisode袖補正 = hurisode.optionId袖補正
    }

    if (hurisode.flags.is80Off) {
      formValues.furisode商品情報_振袖本体 = hurisode.レンタル価格
      formValues.furisode商品情報_価格入力 = hurisode.値引き後価格
      formValues.furisode商品情報_価格選択 = {
        disabled: false,
        id: Enum振袖二尺袖.価格選択.振袖成人式フル1,
        label: 'レンタル\n正規価格',
        value: '80%引き',
      }
    } else {
      formValues.furisode商品情報_価格入力 = hurisode.レンタル価格
    }

    /* ストアに内容を保存する */
    store.setAll(formValues)
  }
  return { restore振袖 }
}
