import { GetterTree } from 'vuex'
import { RootState, GraduationCeremonyState } from '../../types'

const getters: GetterTree<GraduationCeremonyState, RootState> = {
  getState: (state: GraduationCeremonyState) => {
    return state
  },

  getIsValidate: (state: GraduationCeremonyState) => {
    return state.isValidate
  },

  getCeremonyInfo: (state: GraduationCeremonyState) => {
    return state.ceremonyInfo
  },

  getPass: (state: GraduationCeremonyState) => {
    return state.pass
  },

  getDelivery: (state: GraduationCeremonyState) => {
    return state.delivery
  },

  getReturnInfo: (state: GraduationCeremonyState) => {
    return state.returnInfo
  },
  getDressingPlacesInfo: (state: GraduationCeremonyState) => {
    return state.dressingPlaces
  },
}

export default getters
