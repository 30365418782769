import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { SisterDiscountState } from '~/store/types'

export const useRestore妹様割 = () => {
  const store = useComingStore<SisterDiscountState>('SisterDiscount')

  const restore妹様割 = (arg: Restate['discounts']['妹様割']) => {
    store.setOf<SisterDiscountState['selections']>(
      'setSelection',
      arg.selections
    )
    store.setOf<SisterDiscountState['customer']>('setCustomer', arg.customer)
    store.setIsValidate(false)
  }

  return {
    restore妹様割,
  }
}
