export class ACCESSORIES {
  // 割引を行いますか。
  static readonly APPLY = {
    はい: 1,
    いいえ: 2,
  }

  static readonly MAX = 100
  static readonly MIN = 1
}

export type AccessoriesOption =
  | typeof ACCESSORIES.APPLY.はい
  | typeof ACCESSORIES.APPLY.いいえ
