import { FormValues商品設定 } from '~/composable/estimate/viewModel/graduation/Fields/Fields商品設定'

//不明な点がある場合、READMEをご覧ください
export const getInitialStore = (): FormValues商品設定 => {
  return {
    shohinSettei重ね衿: 1,
    shohinSettei髪飾り: 0,
  }
}

const state = (): { fields: FormValues商品設定 } => ({
  fields: getInitialStore(),
})

export default state
