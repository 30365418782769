import { computed } from '@nuxtjs/composition-api'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { ServicePlanState } from '~/store/types'
import { SERVICE_PLAN } from '~/constants/estimate/comingOfAge'

/**
 * @note プランタブで選択された選択肢に合わせてお姉様購入かどうかのフラグを返す
 */
export const useElderSisterFlag = () => {
  const storeプラン = useComingStore<ServicePlanState>('ServicePlans')
  const values = storeプラン.getAll()
  const { PURCHASE_AT_OUR, NOT_PURCHASE_AT_OUR } = SERVICE_PLAN

  const isお姉様購入 = computed(() => {
    return values.value.selections.includes(PURCHASE_AT_OUR)
  })

  const isNOTお姉様購入 = computed(() => {
    return values.value.selections.includes(NOT_PURCHASE_AT_OUR)
  })

  return {
    isお姉様購入,
    isNOTお姉様購入,
  }
}
