import { useお写真見積 } from './お写真プラン見積'
import { use卒業式全体見積 } from './卒業式全体見積'

export type ConclusionItem = {
  title: string // 項目名
  productTypeId: number | null // 品種区分
  rankUpName?: string // 品種区分
  rankUpId?: number | null // 品種区分
  isRankUp?: boolean // ランクアップか
  product?: string | null
  price?: number | null
  priceWithPP?: number | null
  priceNote?: string | null // 金額の上部のテキスト
  priceNoteWithPP?: string | null
  discount?: boolean // 割引金額か
  noPrice?: boolean // 金額を表示する必要がない項目か
  url?: string // タブへのリンクテキストのリンク
  label?: string // 緑バッジに表示する文字列
  addition?: boolean // 商品以外のオプション追加金額か
  selectedPlan?: number //プレミアムパック適用か否か
  isInsertDailyReport?: boolean
}

export const useFields見積一覧 = () => {
  const total = use卒業式全体見積()
  const photo = useお写真見積()

  return { total, photo }
}
