import { GetterTree } from 'vuex'
import { RootState } from '~/store/Estimate/GraduationCeremony/types'
import { FormValues足し布 } from '~/composable/estimate/viewModel/comingOfAge/Fields/Fields足し布'
import { State } from './state'

const getters: GetterTree<State, RootState> = {
  getState: (state) => (stateName: keyof FormValues足し布) => {
    return state.fields[stateName]
  },

  getIsValidate: (state) => {
    return state.isValidate
  },

  getAllState: (state) => {
    return state.fields
  },
}
export default getters
