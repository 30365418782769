import { computed, ref, Ref, watch } from '@nuxtjs/composition-api'
import { EstimateDetail } from '~/api/estimateRepository'
import { useCustomer } from '~/composable/api/useCustomer'
import { DISCOUNT_TYPE } from '~/constants/estimate/config'
import { PLAN } from '~/constants/estimate/comingOfAge'
import { hasEnabledDiscount } from '~/utils/utils'

export const useRestateSisterDiscount = (
  estimate: Ref<EstimateDetail | undefined>
) => {
  const isEnableSisterDiscount = ref(false)
  const { fetchCustomerList, customerList } = useCustomer()

  // NOTE: computed内で取得処理をしようとすると無限ループが起きる
  watch(estimate, () => {
    const sisterId = estimate.value?.sisters?.customer_id
    const discounts = estimate.value?.discounts ?? []
    if (!sisterId || discounts.length === 0) {
      return
    }

    isEnableSisterDiscount.value = hasEnabledDiscount(
      discounts,
      DISCOUNT_TYPE.SISTER.id
    )
    if (isEnableSisterDiscount.value) {
      fetchCustomerList({ customer_id: sisterId })
    }
  })

  return computed(() => {
    // NOTE: 通常では通過しない
    if (!estimate.value) {
      return {
        selections: [PLAN.UN_SET, PLAN.UN_SET],
        customer: null,
      }
    }

    // 妹様割が選択されていない場合
    // NOTE: お姉様の検索結果が存在しない場合も考慮しているが、通常ではあり得ないはず
    if (!isEnableSisterDiscount.value || customerList.value.length === 0) {
      return {
        selections: [PLAN.NO, PLAN.UN_SET],
        customer: null,
      }
    }

    return {
      selections: [PLAN.YES, PLAN.APPLY],
      customer: customerList.value[0],
    }
  })
}
