import { Restate } from '~/composable/estimate/repository/graduation/EstimateRestate'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { FormValues試着写真 } from '~/composable/estimate/viewModel/graduation/Fields/Fields試着写真'

export const useRestore試着写真 = () => {
  const store = useGraduateStore<FormValues試着写真>('tab試着写真')

  const restore試着写真 = (photos: Restate['photos']) => {
    if (!photos) {
      return
    }

    const formValues: FormValues試着写真 = {
      shichakuアップロード: photos.写真アップロード,
      shichaku全体写真: photos.全体写真,
      shichaku衿元写真: photos.襟元写真,
    }

    store.setAll(formValues)
  }
  return { restore試着写真 }
}
