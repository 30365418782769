import { computed, useStore } from '@nuxtjs/composition-api'
import { State as PricesState } from '~/store/PriceTable/state'

/**
 * 卒業式見積もり用のstoreを操作するComposition関数
 * @interface R 指定したストアの中身の型（「FormValuesページ名」系のやつ）
 * これを指定すると引数や返り値に型がつくようになる
 */
export const useGraduateStore = <R extends Record<string, unknown>>(
  storeName: string
) => {
  /*
   * constructor
   */
  const store = useStore()
  const storePath = `Estimate/GraduationCeremony/${storeName}`

  /*
   * public getters and methods
   */
  /**
   * @interface S 指定したstate名の文字列を指定すると返り値に型がつく
   */
  const get = <S extends string>(fieldId: keyof R) => {
    const storeFullPath = `${storePath}/getState`
    const state = computed(() => store.getters[storeFullPath](fieldId) as R[S])
    if (state.value === undefined) {
      const errorMsg =
        'storeから返された値がundefinedでした。\npath:' + storeFullPath
      console.error(errorMsg)
    }
    return state
  }

  const getAll = () => {
    const storeFullPath = `${storePath}/getAllState`
    const state = computed(() => store.getters[storeFullPath] as R)
    return state
  }

  // 価格テーブルの取得
  const getPrices = () => {
    const path = 'PriceTable/getPrices'
    const state: PricesState['prices'] = store.getters[path]
    return state
  }

  // 価格テーブルの更新
  const setPrices = <P>(setter: string, value: P) => {
    const path = `PriceTable/${setter}`
    store.commit(path, value)
  }

  /**
   * @interface S 指定したstate名の文字列を指定すると渡す値に型チェックが入る
   */
  const set = <S extends keyof R>(fieldId: keyof R, value: R[S]) => {
    const storeFullPath = `${storePath}/setState`
    store.commit(storeFullPath, { stateName: fieldId, value })
  }
  const setAll = (values: R) => {
    const storeFullPath = `${storePath}/setAllState`
    store.commit(storeFullPath, values)
  }

  const init = (fieldId: keyof R) => {
    const storeFullPath = `${storePath}/initState`
    store.commit(storeFullPath, fieldId)
  }
  const initAll = () => {
    const storeFullPath = `${storePath}/initAllState`
    store.commit(storeFullPath)
  }

  return {
    get,
    getAll,
    getPrices,
    setPrices,
    set,
    setAll,
    init,
    initAll,
  }
}
