import { render, staticRenderFns } from "./EstimateResultFooter.vue?vue&type=template&id=325b1a22&scoped=true&"
import script from "./EstimateResultFooter.vue?vue&type=script&lang=ts&"
export * from "./EstimateResultFooter.vue?vue&type=script&lang=ts&"
import style0 from "./EstimateResultFooter.vue?vue&type=style&index=0&id=325b1a22&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "325b1a22",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppIcon: require('/home/ar-admin/development/weddingbox-client/src/components/AppIcon.vue').default,AppButton: require('/home/ar-admin/development/weddingbox-client/src/components/AppButton.vue').default,TheDialogBeforeEstimateSave: require('/home/ar-admin/development/weddingbox-client/src/components/TheDialogBeforeEstimateSave.vue').default})
