import { computed } from '@nuxtjs/composition-api'
import { useGraduateStore } from '~/composable/estimate/repository/GraduationStore'
import { FormValuesその他商品 } from '~/composable/estimate/viewModel/graduation/Fields/Fieldsその他商品'

export const useStateその他商品 = () => {
  const storeその他商品 =
    useGraduateStore<FormValuesその他商品>('tabその他商品')
  const valuesその他商品 = storeその他商品.getAll()

  return computed(() => {
    return {
      title: 'その他商品',
      その他の商品を追加しますか: valuesその他商品.value.otherItemFlg,
      その他商品追加: valuesその他商品.value.otherItems,
    }
  })
}
