/*
 * 見積最終画面で見積作成完了ボタンを押せるかどうかの制御ロジック
 */
import { computed, ref, useRouter } from '@nuxtjs/composition-api'
import { useSelectedPlanListType } from './SelectedPlanListType'
import { urls } from '~/constants/urls'

/** useState */
import { useStateバッグ1個目 } from '~/composable/estimate/repository/comingOfAge/states/Stateバッグ1個目'
import { useState草履1個目 } from '~/composable/estimate/repository/comingOfAge/states/State草履1個目'
import { useState振袖 } from '~/composable/estimate/repository/comingOfAge/states/State振袖'
import { useState振袖 as wPhotoUseState振袖 } from '~/composable/estimate/repository/comingOfAge/states/StateWフォト/State振袖'

export const useValidateEstimatePost = () => {
  const { is未決定, isWフォト未決定 } = useSelectedPlanListType()
  const state振袖 = useState振袖()
  const wPhotoState振袖 = wPhotoUseState振袖()

  const checkCanPostEstimate = (isMypage: boolean) => {
    let errorText = ref<string>('')
    // マイページに反映の場合は制御しない
    if (!isMypage) {
      // planが選択されていなかったらアラートを出す
      if (is未決定.value || isWフォト未決定.value) {
        errorText.value =
          '各見積金額の下にある「こちらを選ぶ」をタップして、契約書を作成したい見積を選んでください'
        alert(errorText.value)
        return false
      }
    }

    const is振袖保留 = state振袖.value.flags.is振袖保留
    const isWPhoto振袖保留 = wPhotoState振袖.value.flags.is振袖保留
    // 振袖が保留の場合はアラートを出して、振袖選択を促す
    if (is振袖保留 || isWPhoto振袖保留) {
      errorText.value =
        '振袖を仮設定中のため、振袖タブで商品を選択してください。'
      alert(errorText.value)
      return false
    }
    return true
  }
  return { checkCanPostEstimate }
}

export const useValidateZoriBagAndKimono = (isEstimateCreating = false) => {
  const router = useRouter()
  const state草履 = useState草履1個目()
  const stateバッグ = useStateバッグ1個目()
  const { is購入プラン, isレンタルプラン } = useSelectedPlanListType()

  const validation = computed(() => {
    const isU系商品選択 =
      state草履.value.flags.selectedU系商品 ||
      stateバッグ.value.flags.selectedU系商品

    if (isレンタルプラン.value && isU系商品選択) {
      return {
        isValid: false,
        alertText:
          '草履・バッグにU系商品が選択されています。U系商品はレンタル出来ないため選び直しをお願いします。',
      }
    }

    const isShowこちらでお選びボタン =
      (state草履.value.flags.selectedレンタルこちらでお選びボタン &&
        !state草履.value.flags.is持込) ||
      (stateバッグ.value.flags.selectedレンタルこちらでお選びボタン &&
        !stateバッグ.value.flags.is持込)

    if (is購入プラン.value && isShowこちらでお選びボタン) {
      return {
        isValid: false,
        alertText:
          '草履・バッグで「レンタル こちらでお選びします」が選択されているため、購入プランに変更する場合は草履・バッグを選択し直してください。',
      }
    }

    return {
      isValid: true,
      alertText: '',
    }
  })

  const checkZoriBagAndKimonoValid = () => {
    if (validation.value.isValid) return true
    alert(validation.value.alertText)

    if (!isEstimateCreating) {
      const estimateId = Number(router.currentRoute.params?.estimate_id)
      router.push(
        urls.見積書.成人式.tab草履バッグ(isNaN(estimateId) ? 0 : estimateId)
      )
    }

    return false
  }

  return { checkZoriBagAndKimonoValid }
}
