import { GetterTree } from 'vuex'
import { RootState } from '~/store/Estimate/GraduationCeremony/types'
import { FormValues別衿_着物レンタル } from '~/composable/estimate/viewModel/graduation/Fields/Fields別衿_着物レンタル'

const getters: GetterTree<{ fields: FormValues別衿_着物レンタル }, RootState> =
  {
    getState: (state) => (stateName: keyof FormValues別衿_着物レンタル) => {
      return state.fields[stateName]
    },

    getAllState: (state) => {
      return state.fields
    },
  }
export default getters
