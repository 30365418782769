import { FormValues備考 } from '~/composable/estimate/viewModel/graduation/Fields/Fields備考'

//不明な点がある場合、READMEをご覧ください
export const getInitialStore = (): FormValues備考 => {
  return {
    bikou備考: null,
  }
}

const state = (): { fields: FormValues備考 } => ({
  fields: getInitialStore(),
})

export default state
