import { computed, Ref } from '@nuxtjs/composition-api'
import { EstimateDetail } from '~/api/estimateRepository'
import { useRestateショール } from './Restateショール'
import { useRestateバッグ1個目 } from './Restateバッグ1個目'
import { useRestate別衿 } from './Restate別衿'
import { useRestate帯 } from './Restate帯'
import { useRestate帯揚げ } from './Restate帯揚げ'
import { useRestate帯締め } from './Restate帯締め'
import { useRestate草履1個目 } from './Restate草履1個目'
import { useRestate草履複数個目 } from './Restate草履複数個目'
import { useRestateバッグ複数個目 } from './Restateバッグ複数個目'
import { useRestate重ね衿 } from './Restate重ね衿'
import { useRestate髪飾り } from './Restate髪飾り'
import { useRestateその他商品 } from './Restateその他商品'
import { usePPZoriBagPlan } from '~/composable/general/PPDiscount/usePPZoriBagPlan'
import { usePPObiageKasaneeriPlan } from '~/composable/general/PPDiscount/usePPObiageKasaneeriPlan'

export const useRestateItems = (estimate: Ref<EstimateDetail | undefined>) => {
  const { getZoriBagPPDiscount } = usePPZoriBagPlan()
  const { getObiageKasaneeriPPDiscount } = usePPObiageKasaneeriPlan()
  const isPP = getObiageKasaneeriPPDiscount(estimate)
  const isPPDiscount = getZoriBagPPDiscount(estimate)
  const restate帯 = useRestate帯(estimate)
  const restate帯締め = useRestate帯締め(estimate)
  const restate帯揚げ = useRestate帯揚げ(estimate, isPP)
  const restate重ね衿 = useRestate重ね衿(estimate, isPP)
  const restate別衿 = useRestate別衿(estimate)
  const restate髪飾り = useRestate髪飾り(estimate)
  const restateショール = useRestateショール(estimate)
  const restateバッグ1個目 = useRestateバッグ1個目(estimate, isPPDiscount)
  const restate草履1個目 = useRestate草履1個目(estimate, isPPDiscount)
  const restate草履複数個目 = useRestate草履複数個目(estimate, isPPDiscount)
  const restateバッグ複数個目 = useRestateバッグ複数個目(estimate, isPPDiscount)
  const restateその他商品 = useRestateその他商品(estimate)

  return computed(() => {
    return {
      帯: restate帯.value,
      帯締め: restate帯締め.value,
      帯揚げ: restate帯揚げ.value,
      重ね衿: restate重ね衿.value,
      別衿: restate別衿.value,
      髪飾り: restate髪飾り.value,
      ショール: restateショール.value,
      バッグ1個目: restateバッグ1個目.value,
      草履1個目: restate草履1個目.value,
      草履複数個目: restate草履複数個目.value,
      バッグ複数個目: restateバッグ複数個目.value,
      その他商品: restateその他商品.value,
    }
  })
}
