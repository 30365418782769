import { computed, Ref } from '@nuxtjs/composition-api'
import { Profile } from '~/composable/api/usePersonal'

export const useRestateCustomer = (profile: Ref<Profile>) => {
  return computed(() => {
    return {
      profile: profile.value,
      fullName: `${profile.value.last_name}${profile.value.first_name}`,
    }
  })
}
