import { computed, ref, Ref, watch } from '@nuxtjs/composition-api'
import { EstimateDetail, Hakama } from '~/api/estimateRepository'
import { usePrice } from '~/composable/general/Price'
import { Enumプラン, Enum袴 } from '~/constants/estimate/graduationOptions'
import {
  CatalogOtherKimono,
  useSearchProduct,
} from '~/composable/api/useSearchProduct'
import { ProductCategory } from '~/constants/enums/productCategory'
import ProductClassification from '~/constants/enums/productClassification'
import { CatalogOtherTypeResult, FinishedTypeResult } from '~/store/types'

export const useRestateHakama = (estimate: Ref<EstimateDetail | undefined>) => {
  const { paramToString } = usePrice()
  const { fetchProducts, catalogOtherKimono, finishedKimonoList } =
    useSearchedProducts(estimate)

  // NOTE: computed内で取得処理をしようとすると無限ループが起きる
  watch(estimate, () => {
    if (!estimate.value?.hakama) {
      return
    }
    fetchProducts(estimate.value.hakama)
  })

  /*
   * NOTE: カタログ商品のproduct_idはカタログ商品の検索結果に載っているものではなく、
   * 契約時に仕立て上がりとして発番されたidを受渡する必要があるため、
   * 復元時には見積詳細に載っている方のidで上書きする。(未契約の見積詳細にはnullが返ってくる)
   */
  const formattedCatalogHakama = ref<CatalogOtherKimono | null>(null)
  watch(catalogOtherKimono, () => {
    if (!catalogOtherKimono.value) {
      return
    }
    formattedCatalogHakama.value = {
      ...catalogOtherKimono.value,
      product_id: estimate.value?.hakama?.product_id,
    }
  })

  return computed(() => {
    if (!estimate.value?.hakama) {
      return null
    }
    const { hakama } = estimate.value
    const { rental_price, discount_type } = hakama

    return {
      仕立て上り検索結果: {
        type: 'finished',
        ...finishedKimonoList.value[0],
      } as FinishedTypeResult,
      カタログ検索結果: {
        type: 'catalog_other',
        ...formattedCatalogHakama.value,
      } as CatalogOtherTypeResult,
      レンタル価格: paramToString(rental_price),
      optionId誂えるか: getOptionIdOf誂えるか(catalogOtherKimono),
      optionId割引適用: discount_type,
      flags: getConditionalFlags(hakama),
    }
  })
}

/**
 * 選択肢系の項目の初期値(選択されたoptionのid)を再計算する関数群
 */
const getOptionIdOf種別 = (category: Hakama['category']) => {
  const is持込 = [ProductCategory.bringIn].includes(category)
  return is持込 ? Enumプラン.袴種別.お持込 : Enumプラン.袴種別.レンタル
}

/**
 * 誂えるか
 */
const getOptionIdOf誂えるか = (
  catalogOtherKimono: Ref<CatalogOtherKimono | null>
) => {
  const { 誂える } = Enum袴.誂えますか
  return computed(() => {
    const has在庫 = catalogOtherKimono.value?.available_order_quantity
    if (!catalogOtherKimono) {
      return null
    }
    return has在庫 ? null : 誂える
  })
}

/**
 * 商品検索結果
 */
const useSearchedProducts = (estimate: Ref<EstimateDetail | undefined>) => {
  const {
    fetchCatalogOtherKimono,
    catalogOtherKimono,
    fetchFinishedKimonoList,
    finishedKimonoList,
  } = useSearchProduct()

  const fetchProducts = (hakama: Hakama) => {
    // 仕立て上がりレンタル検索
    if (hakama.product_id) {
      fetchFinishedKimonoList({
        keyword: hakama.product_id,
        keyword_types: [0], // 0: 商品番号を指定
        product_type_id: ProductClassification.hakama,
        has_purchased: 1,
      })
    }
    // カタログレンタル
    if (hakama.catalog_code) {
      fetchCatalogOtherKimono({
        catalog_code: hakama.catalog_code,
        product_type_id: ProductClassification.hakama,
        use_date: estimate.value?.use_date ?? '',
      })
    }
  }
  return { fetchProducts, catalogOtherKimono, finishedKimonoList }
}

/**
 * 復元条件に関わる条件式を定義する
 */
const getConditionalFlags = (hakama: Hakama) => {
  const { category } = hakama
  const isカタログ商品 = category === Enum袴.レンタルORカタログ.カタログレンタル
  const is持込 = getOptionIdOf種別(category) === Enum袴.種別.お持込
  const isレンタル = category === Enum袴.レンタルORカタログ.レンタル && !is持込
  const isカタログレンタル =
    category === Enum袴.レンタルORカタログ.カタログレンタル && !is持込

  return {
    is持込,
    isレンタル,
    isカタログレンタル,
    isカタログ商品,
  }
}
