import { ProductState } from '../../../types'

const state = (): ProductState => ({
  products: [
    {
      id: 1,
      name: '帯揚げ',
      count: 1,
    },
    {
      id: 2,
      name: '重ね衿',
      count: 1,
    },
    {
      id: 3,
      name: '髪飾り',
      count: 1,
    },
    {
      id: 4,
      name: '草履',
      count: 1,
    },
    {
      id: 5,
      name: 'バッグ',
      count: 1,
    },
  ],
  isValidate: null,
})

export default state
