




























import { defineComponent } from '@nuxtjs/composition-api'
import AppModalWindow from 'storybook/components/atoms/AppModalWindow.vue'

export default defineComponent({
  components: { AppModalWindow },
  setup() {
    const openTermsPDF = (fineName: string) => {
      window.open(`./${fineName}.pdf`, '_blank')
    }

    return {
      openTermsPDF,
    }
  },
})
