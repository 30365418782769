export const initialState = {
  data: { changePriceType: 2 },
}

export type State = typeof initialState

export const getInitialStore = (): State => initialState

const state = () => getInitialStore()

export default state
