








import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  data() {
    return {
      width: 17.82,
      height: 10.411,
    }
  },
})
