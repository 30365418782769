import { GetterTree } from 'vuex'
import { RootState, ObiState } from '../../../types'

const getters: GetterTree<ObiState, RootState> = {
  // NOTE: useComingStore用に追加
  getAllState: (state) => {
    return state
  },

  getState: (state: ObiState) => {
    return state
  },

  getSelections: (state: ObiState) => {
    return state.selections
  },

  getProduct: (state: ObiState) => {
    return state.product
  },

  getInput: (state: ObiState) => {
    return state.input
  },

  getPrice: (state: ObiState) => {
    return state.price
  },

  getRentalPrice: (state: ObiState) => {
    return state.price.rental
  },

  getBuyPrice: (state: ObiState) => {
    return state.price.buy
  },

  getIsFree: (state: ObiState) => {
    return state.isFree
  },

  getIsValidate: (state: ObiState) => {
    return state.isValidate
  },
}

export default getters
