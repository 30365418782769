import { MutationTree } from 'vuex'
import { BaseInformationState, Staff, ContractReason } from '../../types'

const mutations: MutationTree<BaseInformationState> = {
  setPatternLine: (state, patternLine) => {
    state.patternLine = patternLine
  },

  setSubStaff: (state, subStaff: Staff) => {
    state.subStaff = subStaff
  },

  setComingStoreType: (state, comingStoreType: number) => {
    state.comingStoreType = comingStoreType
  },

  setIsValidate: (state, isValidate) => {
    state.isValidate = isValidate
  },

  // 契約動機
  setContractReason: (state, contractReasons: ContractReason[]) => {
    state.contractReason = contractReasons
  },

  delete: (state) => {
    state.patternLine = null
    state.subStaff = null
    state.isValidate = null
    state.contractReason = []
    state.comingStoreType = 0
  },
}

export default mutations
