import { FormValues草履ブーツ_着物持込 } from '~/composable/estimate/viewModel/graduation/Fields/Fields草履ブーツ_着物持込'

//不明な点がある場合、READMEをご覧ください
export const getInitialStore = (): FormValues草履ブーツ_着物持込 => {
  return {
    zoriboots商品: null,
    zoriboots種別: null,
    zoriboots商品選択: null,
    zoriboots見積選択: null,
    zoriboots単品レンタル: null,
    zoriboots単品購入: null,
    zoriboots現品単品レンタル: null,
    zoriboots現品単品レンタルフリー入力: null,
    zoriboots現品単品購入: null,
    zoriboots現品単品購入フリー入力: null,
  }
}

const state = (): { fields: FormValues草履ブーツ_着物持込 } => ({
  fields: getInitialStore(),
})

export default state
