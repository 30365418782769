import { computed, useRouter, useStore } from '@nuxtjs/composition-api'
import { PLAN, SERVICE_PLAN } from '~/constants/estimate/comingOfAge'
import { isAdapt } from '~/utils/utils'
import { urls } from '~/constants/urls'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { FormValues足し布 } from '~/composable/estimate/viewModel/comingOfAge/Fields/Fields足し布'
import { FormValues割引 } from '~/composable/estimate/viewModel/comingOfAge/Fields/Fields割引'
import { FurisodeSize } from '~/composable/api/useSearchProduct'

export type TabList = {
  id: string
  text: string
  url: string
  isValidate: boolean
  isHidden?: boolean
  storePath?: string
}

type WPhotoTab = {
  id: string
  name: string
  url: string
  isValidate: boolean
  isHidden?: boolean
}

export const useEstimateTabList = () => {
  const router = useRouter()
  const estimateId = router.currentRoute.params?.estimate_id
    ? Number(router.currentRoute.params?.estimate_id)
    : 0

  const store = useStore()
  const store足し布 = useComingStore<FormValues足し布>('Tashinuno')
  const store割引 = useComingStore<FormValues割引>('Discount')

  // isHiddenがtrueとなったタブのstoreを初期化するために必要(wphotoのstoreは消えない)
  const deleteHiddenTabStore = () => {
    comingOfAgeCeremonyTabList.value.forEach((tab) => {
      if (tab.isHidden && tab.storePath) {
        store.commit(`Estimate/ComingOfAgeCeremony/${tab.storePath}/delete`)
      }
    })
  }

  const comingOfAgeCeremonyTabList = computed<TabList[]>(() => {
    // 商品設定によってタブを増やす
    const obiageCount =
      store.getters['Estimate/ComingOfAgeCeremony/Products/products'][0].count
    const kasaneeriCount =
      store.getters['Estimate/ComingOfAgeCeremony/Products/products'][1].count
    const kamikazariCount =
      store.getters['Estimate/ComingOfAgeCeremony/Products/products'][2].count
    const zoriCount =
      store.getters['Estimate/ComingOfAgeCeremony/Products/products'][3].count
    const bagCount =
      store.getters['Estimate/ComingOfAgeCeremony/Products/products'][4].count

    // 妹割、双子割、従業員割タブの制御
    const isSisterDiscount = isAdapt(
      store.getters['Estimate/ComingOfAgeCeremony/SisterDiscount/getSelections']
    )
    const isTwinsDiscount = isAdapt(
      store.getters['Estimate/ComingOfAgeCeremony/TwinsDiscount/getSelections']
    )
    const isEmployeeDiscount = isAdapt(
      store.getters[
        'Estimate/ComingOfAgeCeremony/EmployeeDiscount/getSelections'
      ]
    )

    // 写真のみプラン（レンタル）かどうか
    const isOnlyPhotoRental = store.getters[
      'Estimate/ComingOfAgeCeremony/ServicePlans/getSelections'
    ].includes(SERVICE_PLAN.RENTAL)
    const isOnlyPhotoBringIn = store.getters[
      'Estimate/ComingOfAgeCeremony/ServicePlans/getSelections'
    ].includes(SERVICE_PLAN.BRING_IN_ONLY_PHOTO)

    // お持ち込みプランかどうか
    const isBringIn =
      store.getters[
        'Estimate/ComingOfAgeCeremony/ServicePlans/getSelections'
      ].includes(SERVICE_PLAN.BRING_IN) || isOnlyPhotoBringIn

    const isFurisodeTallSize =
      store.getters['Estimate/ComingOfAgeCeremony/Furisodes/getProduct']
        ?.size === FurisodeSize.T

    // お持込プラン（成人式当日B）かどうか
    const isBCourseOnlyComingOfAgeCeremony = store.getters[
      'Estimate/ComingOfAgeCeremony/ServicePlans/getSelections'
    ].includes(SERVICE_PLAN.ONLY_COMIMG_OF_AGE)

    // フルセットかどうか
    const isFullSet = store.getters[
      'Estimate/ComingOfAgeCeremony/ServicePlans/getSelections'
    ].includes(SERVICE_PLAN.FULL_SET)

    return <TabList[]>[
      {
        text: 'お客様情報',
        url: urls.見積書.成人式.tabお客様検索(estimateId),
        isValidate:
          store.getters['Estimate/ComingOfAgeCeremony/Customers/getIsValidate']
            .number,
      },
      {
        text: 'プラン',
        url: urls.見積書.成人式.tabプラン(estimateId),
        isValidate:
          store.getters[
            'Estimate/ComingOfAgeCeremony/ServicePlans/getIsValidate'
          ],
      },

      {
        text: '振袖',
        url: urls.見積書.成人式.tab振袖(estimateId),
        isValidate:
          store.getters['Estimate/ComingOfAgeCeremony/Furisodes/getIsValidate'],
        isHidden: isBringIn,
        storePath: 'Furisodes',
      },
      {
        text: '足し布',
        url: urls.見積書.成人式.tab足し布(estimateId),
        isValidate: store足し布.getIsValidate().value,
        isHidden: isBringIn || isFurisodeTallSize,
        storePath: 'Tashinuno',
      },
      {
        text: '帯',
        url: urls.見積書.成人式.tab帯(estimateId),
        isValidate:
          store.getters['Estimate/ComingOfAgeCeremony/Obi/getIsValidate'],
      },
      {
        text: '帯締め',
        url: urls.見積書.成人式.tab帯締め(estimateId),
        isValidate:
          store.getters['Estimate/ComingOfAgeCeremony/Obijime/getIsValidate'],
      },
      {
        text: '商品設定',
        url: urls.見積書.成人式.tab商品設定(estimateId),
        isValidate:
          store.getters['Estimate/ComingOfAgeCeremony/Products/getIsValidate'],
      },
      {
        text: '帯揚げ',
        url: urls.見積書.成人式.tab帯揚げ(estimateId),
        isValidate:
          store.getters['Estimate/ComingOfAgeCeremony/Obiage/getIsValidate'],
      },
      {
        text: '帯揚げ - 2',
        url: urls.見積書.成人式.tab帯揚げ2(estimateId),
        isValidate:
          store.getters['Estimate/ComingOfAgeCeremony/Obiage2/getIsValidate'],
        isHidden: obiageCount < 2,
        storePath: 'Obiage2',
      },
      {
        text: '帯揚げ - 3',
        url: urls.見積書.成人式.tab帯揚げ3(estimateId),
        isValidate:
          store.getters['Estimate/ComingOfAgeCeremony/Obiage3/getIsValidate'],
        isHidden: obiageCount < 3,
        storePath: 'Obiage3',
      },
      {
        text: '重ね衿',
        url: urls.見積書.成人式.tab重ね衿(estimateId),
        isValidate:
          store.getters['Estimate/ComingOfAgeCeremony/Kasaneeri/getIsValidate'],
      },
      {
        text: '重ね衿 - 2',
        url: urls.見積書.成人式.tab重ね衿2(estimateId),
        isValidate:
          store.getters[
            'Estimate/ComingOfAgeCeremony/Kasaneeri2/getIsValidate'
          ],
        isHidden: kasaneeriCount < 2,
        storePath: 'Kasaneeri2',
      },
      {
        text: '重ね衿 - 3',
        url: urls.見積書.成人式.tab重ね衿3(estimateId),
        isValidate:
          store.getters[
            'Estimate/ComingOfAgeCeremony/Kasaneeri3/getIsValidate'
          ],
        isHidden: kasaneeriCount < 3,
        storePath: 'Kasaneeri3',
      },
      {
        text: '別衿',
        url: urls.見積書.成人式.tab別衿(estimateId),
        isValidate:
          store.getters['Estimate/ComingOfAgeCeremony/Betsueri/getIsValidate'],
      },
      {
        text: 'ショール',
        url: urls.見積書.成人式.tabショール(estimateId),
        isValidate:
          store.getters['Estimate/ComingOfAgeCeremony/Shawl/getIsValidate'],
        isHidden: isOnlyPhotoRental,
        storePath: 'Shawl',
      },
      {
        text: '髪飾り',
        url: urls.見積書.成人式.tab髪飾り(estimateId),
        isValidate:
          store.getters[
            'Estimate/ComingOfAgeCeremony/Kamikazari/getIsValidate'
          ],
        isHidden: kamikazariCount < 1,
        storePath: 'Kamikazari',
      },
      {
        text: '髪飾り - 2',
        url: urls.見積書.成人式.tab髪飾り2(estimateId),
        isValidate:
          store.getters[
            'Estimate/ComingOfAgeCeremony/Kamikazari2/getIsValidate'
          ],
        isHidden: kamikazariCount < 2,
        storePath: 'Kamikazari2',
      },
      {
        text: '髪飾り - 3',
        url: urls.見積書.成人式.tab髪飾り3(estimateId),
        isValidate:
          store.getters[
            'Estimate/ComingOfAgeCeremony/Kamikazari3/getIsValidate'
          ],
        isHidden: kamikazariCount < 3,
        storePath: 'Kamikazari3',
      },
      {
        text: '草履・バッグ',
        url: urls.見積書.成人式.tab草履バッグ(estimateId),
        isValidate:
          store.getters['Estimate/ComingOfAgeCeremony/ZoriBag/getIsValidate'],
      },
      {
        text: '草履 - 2',
        url: urls.見積書.成人式.tab草履2(estimateId),
        isValidate:
          store.getters['Estimate/ComingOfAgeCeremony/Zori2/getIsValidate'],
        isHidden: zoriCount < 2,
        storePath: 'Zori2',
      },
      {
        text: '草履 - 3',
        url: urls.見積書.成人式.tab草履3(estimateId),
        isValidate:
          store.getters['Estimate/ComingOfAgeCeremony/Zori3/getIsValidate'],
        isHidden: zoriCount < 3,
        storePath: 'Zori3',
      },
      {
        text: 'バッグ - 2',
        url: urls.見積書.成人式.tabバッグ2(estimateId),
        isValidate:
          store.getters['Estimate/ComingOfAgeCeremony/Bag2/getIsValidate'],
        isHidden: bagCount < 2,
        storePath: 'Bag2',
      },
      {
        text: 'バッグ - 3',
        url: urls.見積書.成人式.tabバッグ3(estimateId),
        isValidate:
          store.getters['Estimate/ComingOfAgeCeremony/Bag3/getIsValidate'],
        isHidden: bagCount < 3,
        storePath: 'Bag3',
      },
      {
        text: 'その他商品',
        url: urls.見積書.成人式.tabその他商品(estimateId),
        isValidate:
          store.getters['Estimate/ComingOfAgeCeremony/OtherItem/getIsValidate'],
      },
      {
        text: '試着写真',
        url: urls.見積書.成人式.tab試着写真(estimateId),
        isValidate:
          store.getters['Estimate/ComingOfAgeCeremony/Photos/getIsValidate'],
      },
      {
        text: '母娘プラン',
        url: urls.見積書.成人式.tab母娘プラン(estimateId),
        isValidate:
          store.getters[
            'Estimate/ComingOfAgeCeremony/MotherAndDaughterPlan/getIsValidate'
          ],
        isHidden: isBCourseOnlyComingOfAgeCeremony,
        storePath: 'MotherAndDaughterPlan',
      },
      {
        text: 'JK前撮り',
        url: urls.見積書.成人式.tabJK前撮り(estimateId),
        isValidate:
          store.getters[
            'Estimate/ComingOfAgeCeremony/JkPhotoInAdvance/getIsValidate'
          ],
        isHidden: !isFullSet,
        storePath: 'JkPhotoInAdvance',
      },
      {
        text: 'Wフォト',
        url: urls.見積書.成人式.tabWフォト(estimateId),
        isValidate:
          store.getters[
            'Estimate/ComingOfAgeCeremony/WphotoPlan/getIsValidate'
          ],
        isHidden: isBCourseOnlyComingOfAgeCeremony,
        storePath: 'WphotoPlan',
      },
      {
        text: '双子割',
        url: urls.見積書.成人式.tab双子割(estimateId),
        isValidate:
          store.getters[
            'Estimate/ComingOfAgeCeremony/TwinsDiscount/getIsValidate'
          ],
        isHidden: isSisterDiscount || isEmployeeDiscount || !isFullSet,
        storePath: 'TwinsDiscount',
      },
      {
        text: '妹様割',
        url: urls.見積書.成人式.tab妹様割(estimateId),
        isValidate:
          store.getters[
            'Estimate/ComingOfAgeCeremony/SisterDiscount/getIsValidate'
          ],
        isHidden: isTwinsDiscount || isEmployeeDiscount || !isFullSet,
        storePath: 'SisterDiscount',
      },
      {
        text: '従業員割引',
        url: urls.見積書.成人式.tab従業員割(estimateId),
        isValidate:
          store.getters[
            'Estimate/ComingOfAgeCeremony/EmployeeDiscount/getIsValidate'
          ],
        isHidden: isSisterDiscount || isTwinsDiscount || !isFullSet,
        storePath: 'EmployeeDiscount',
      },
      {
        text: 'その他割引',
        url: urls.見積書.成人式.tab割引(estimateId),
        isValidate: store割引.getIsValidate().value,
      },
      {
        text: 'オプション',
        url: urls.見積書.成人式.tabオプション(estimateId),
        isValidate:
          store.getters['Estimate/ComingOfAgeCeremony/Option/getIsValidate'],
      },
      {
        text: '備考',
        url: urls.見積書.成人式.tab備考(estimateId),
        isValidate:
          store.getters['Estimate/ComingOfAgeCeremony/Remarks/getIsValidate'],
      },
    ]
  })

  const wPhotoTabList = computed<WPhotoTab[]>(() => {
    // 商品設定によってタブを増やす
    const obiageCount =
      store.getters[
        'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoProducts/getProducts'
      ][0].count
    const kasaneeriCount =
      store.getters[
        'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoProducts/getProducts'
      ][1].count

    // Wフォトが適用されているか
    const isWphotoSelected =
      store.getters[
        'Estimate/ComingOfAgeCeremony/WphotoPlan/getSelections'
      ][0] === PLAN.APPLY

    // 振袖のレンタル or 持ち込みで画面が別なため他メニューを表示するトリガーにしている
    const isFurisodeSelected =
      store.getters[
        'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoFurisodes/getSelections'
      ][0]

    return <WPhotoTab[]>[
      {
        id: 'wphoto-plan',
        name: 'Wフォトプラン',
        isValidate:
          store.getters[
            'Estimate/ComingOfAgeCeremony/WphotoPlan/getIsSubValidate'
          ],
      },
      {
        id: 'photo',
        name: '写真',
        isValidate:
          store.getters[
            'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoSetting/getIsValidate'
          ],
        isHidden: !isWphotoSelected,
      },
      {
        id: 'furisode',
        name: '振袖',
        isValidate:
          store.getters[
            'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoFurisodes/getIsValidate'
          ],
        isHidden: !isWphotoSelected,
      },
      {
        id: 'obi',
        name: '帯',
        isValidate:
          store.getters[
            'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiRental/getIsValidate'
          ],
        isHidden: !isFurisodeSelected || !isWphotoSelected,
      },
      {
        id: 'obijime',
        name: '帯締め',
        isValidate:
          store.getters[
            'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObijimeRental/getIsValidate'
          ],
        isHidden: !isFurisodeSelected || !isWphotoSelected,
      },
      {
        id: 'product-setting',
        name: '商品設定',
        isValidate:
          store.getters[
            'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoProducts/getIsValidate'
          ],
        isHidden: !isWphotoSelected,
      },
      {
        id: 'obiage',
        name: '帯揚げ',
        isValidate:
          store.getters[
            'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiageRental/getIsValidate'
          ],
        isHidden: !isFurisodeSelected || !isWphotoSelected,
      },
      {
        id: 'obiage2',
        name: '帯揚げ - 2',
        isValidate:
          store.getters[
            'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiage2/getIsValidate'
          ],
        isHidden: !isFurisodeSelected || !isWphotoSelected || obiageCount < 2,
      },
      {
        id: 'obiage3',
        name: '帯揚げ - 3',
        isValidate:
          store.getters[
            'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoObiage3/getIsValidate'
          ],
        isHidden: !isFurisodeSelected || !isWphotoSelected || obiageCount < 3,
      },
      {
        id: 'kasaneeri',
        name: '重ね衿',
        isValidate:
          store.getters[
            'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeriRental/getIsValidate'
          ],
        isHidden: !isFurisodeSelected || !isWphotoSelected,
      },
      {
        id: 'kasaneeri2',
        name: '重ね衿 - 2',
        isValidate:
          store.getters[
            'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeri2/getIsValidate'
          ],
        isHidden:
          !isFurisodeSelected || !isWphotoSelected || kasaneeriCount < 2,
      },
      {
        id: 'kasaneeri3',
        name: '重ね衿 - 3',
        isValidate:
          store.getters[
            'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoKasaneeri3/getIsValidate'
          ],
        isHidden:
          !isFurisodeSelected || !isWphotoSelected || kasaneeriCount < 3,
      },
      {
        id: 'betsueri',
        name: '別衿',
        isValidate:
          store.getters[
            'Estimate/ComingOfAgeCeremony/WphotoPlan/WphotoBetsueriRental/getIsValidate'
          ],
        isHidden: !isFurisodeSelected || !isWphotoSelected,
      },
    ]
  })

  /**
   * バリデーションエラーのタブを取得する
   */
  const findInvalidTab = () => {
    return comingOfAgeCeremonyTabList.value.filter(
      ({ isHidden, isValidate }) => {
        // 非表示のタブは弾く
        if (typeof isHidden === 'boolean' && isHidden) {
          return false
        }
        // 未入力のタブの場合
        if (isValidate === null) {
          return true
        }

        return isValidate
      }
    )
  }

  return {
    comingOfAgeCeremonyTabList,
    wPhotoTabList,
    findInvalidTab,
    deleteHiddenTabStore,
  }
}
