import { ref, useContext, useStore } from '@nuxtjs/composition-api'
import axios from 'axios'
import paymentRepository, { PAYMENT_METHOD } from '~/api/paymentRepository'

// ** types **
import type {
  SingleItem,
  FetchSingleItemsParams,
  SingleItemServiceContractDetail,
  CreateSingleItemServiceContractParams,
  SingleItemServiceEstimateDetail,
  CreateSingleItemServiceEstimateParams,
  UpdateSingleItemServiceContractParams,
} from '~/api/singleItemRepository'

// ** repository **
import { singleItemRepository } from '~/api/singleItemRepository'

// ** enums **
import { DOCUMENT } from '~/constants/estimate/config'
import { IS_PAYMENT, PAYMENT_CATEGORY } from '~/features/contracts/paymentEnums'

import { SINGLE_ITEMS } from '~/features/singleItemService/masterData'
import { Payment } from '~/store/SingleItemService/Main/state'
import paymentService from './PaymentService'
import fileRepository from '~/api/fileRepository'

// ** composable **
import { notifyError } from '~/composable/general/SlackErrorLog'

export const useSingleItem = () => {
  const { $axios, $config } = useContext()
  const singleItemRepo = singleItemRepository($axios)
  const singleItemRepoPdf = singleItemRepository(
    axios.create({
      headers: $axios.defaults.headers,
      baseURL: $config.BASE_URL,
    })
  )
  const fileRepo = fileRepository(
    axios.create({
      headers: $axios.defaults.headers,
      baseURL: $config.BASE_URL,
    })
  )
  const paymentRepo = paymentRepository($axios)
  const paymentServ = paymentService(fileRepo, paymentRepo)
  const store = useStore()
  const payment: Payment = store.getters['SingleItemService/Main/getPayment'] // 支払い情報の取得

  const singleItems = ref<SingleItem[]>([])
  const singleItemServiceContractInfo = ref<SingleItemServiceContractDetail>()
  const singleItemServiceEstimateInfo = ref<SingleItemServiceEstimateDetail>()
  const loading = ref(false)

  const fetchSingleItems = async (params: FetchSingleItemsParams) => {
    try {
      loading.value = true
      // const { data } = await singleItemRepo.fetchSingleItems(productTypeId)

      // TODO: api出来次第削除
      const data = await new Promise<SingleItem[]>((resolve) => {
        const singleItem = SINGLE_ITEMS.filter(({ product_type_id }) =>
          params.productTypeIds.includes(product_type_id)
        )

        // NOTE: readonly付与によるエラーかつapi出来次第削除するので一旦放置
        setTimeout(() => {
          resolve(singleItem)
        }, 1000)
      })

      singleItems.value = data
    } catch (error) {
      console.log('API error', error)
    } finally {
      loading.value = false
    }
  }

  /**
   * 単品サービス契約詳細API
   * @param {path} contractId
   */
  const fetchSingleItemServiceContractInfo = async (contractId: number) => {
    loading.value = true
    try {
      const { data } = await singleItemRepo.showSingleItemServiceContract(
        contractId
      )

      if (!data) {
        throw new Error('指定された契約書IDの契約書が取得できませんでした')
      }

      singleItemServiceContractInfo.value = data
    } catch (err) {
      if (axios.isAxiosError(err)) {
        throw new Error('指定された契約書IDの契約書が取得できませんでした')
      }

      if (err instanceof Error) {
        throw new Error(err.message)
      }
    } finally {
      loading.value = false
    }
  }

  /**
   * 単品サービス契約書作成
   */

  const postSingleItemServiceContract = async (
    params: CreateSingleItemServiceContractParams
  ) => {
    let resId: number | undefined
    try {
      loading.value = true

      const res = await singleItemRepo.createSingleItemServiceContract(params)
      resId = res.data.contract_id
    } catch (error) {
      loading.value = false
      if (axios.isAxiosError(error)) {
        console.log('API error: ', error.message)
        await notifyError(error, '単品サービス契約書作成', params)
        throw new Error('契約書作成API失敗')
      }

      if (error instanceof Error) {
        throw new Error(error.message)
      }
    }

    if (resId) {
      try {
        //NOTE: 領収書のみの場合は署名を行わないため契約書のPDFは発行しない
        //TODO: PDF発行は今後VV社側で発行するため、修正が完了し次第削除する
        if (!!params.signature_url) {
          await singleItemRepoPdf.createPDF({
            id: resId,
            type: DOCUMENT.契約書.id,
          })
        }

        await singleItemRepoPdf.createReceiptPDF({
          id: resId,
          type: DOCUMENT.領収書.id,
        })
      } catch {
        changePDFFailureFlgStatus()
      } finally {
        loading.value = false
      }
    }

    return resId
  }

  /**
   * 単品サービス契約書更新
   */

  const patchSingleItemServiceContract = async (
    id: number,
    params: UpdateSingleItemServiceContractParams
  ) => {
    let resId: number | undefined
    const isPayment = payment.isPayment === IS_PAYMENT.YES

    try {
      loading.value = true

      /** 契約書更新処理 */
      const res = await singleItemRepo.updateSingleItemServiceContract(
        id,
        params
      )
      resId = res.data.contract_id

      /** 支払いをする場合は、支払い更新処理を行う */
      if (isPayment) {
        await updatePayment(resId, payment)
      }
    } catch (error) {
      loading.value = false
      if (axios.isAxiosError(error)) {
        console.log('API error: ', error.message)
        await notifyError(error, '単品サービス契約書更新', params)
        throw new Error('契約書更新API失敗')
      }

      if (error instanceof Error) {
        throw new Error(error.message)
      }
    }

    if (resId) {
      try {
        //NOTE: 領収書のみの場合は署名を行わないため契約書のPDFは発行しない
        //TODO: PDF発行は今後VV社側で発行するため、修正が完了し次第削除する
        if (!!params.signature_url) {
          await singleItemRepoPdf.createPDF({
            id: resId,
            type: DOCUMENT.契約書.id,
          })

          /** 支払いをする場合は、領収書を発行する */
          if (isPayment) {
            await updateReceipt(resId)
          }

          console.info('--- 領収書PDF完了 ---')
        } else {
          await singleItemRepoPdf.createReceiptPDF({
            id: resId,
            type: DOCUMENT.領収書.id,
          })
        }
      } catch {
        changePDFFailureFlgStatus()
      } finally {
        loading.value = false
      }
    }

    return resId
  }

  /**
   * 単品サービス見積詳細API
   * @param {path} estimateId
   */
  const fetchSingleItemServiceEstimateInfo = async (estimateId: number) => {
    loading.value = true
    try {
      const { data } = await singleItemRepo.showSingleItemServiceEstimate(
        estimateId
      )

      if (!data) {
        throw new Error('指定された見積書IDの見積書が取得できませんでした')
      }

      singleItemServiceEstimateInfo.value = data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error('指定された見積書IDの見積書が取得できませんでした')
      }

      if (error instanceof Error) {
        throw new Error(error.message)
      }
    } finally {
      loading.value = false
    }
  }

  /**
   * 単品サービス見積作成
   */
  const postSingleItemServiceEstimate = async (
    params: CreateSingleItemServiceEstimateParams
  ) => {
    try {
      loading.value = true

      const {
        data: { estimate_id },
      } = await singleItemRepo.createSingleItemServiceEstimate(params)

      await singleItemRepoPdf.createPDF({
        id: estimate_id,
        type: DOCUMENT.見積書.id,
      })

      return estimate_id
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('API error: ', error.message)
        await notifyError(error, '単品サービス見積作成', params)
        throw new Error('見積書作成API失敗')
      }

      if (error instanceof Error) {
        throw new Error(error.message)
      }
    } finally {
      loading.value = false
    }
  }

  /**
   * 支払い情報更新処理API
   * @param contractId
   * @param payment
   */
  const updatePayment = async (contractId: number, payment: Payment) => {
    const paymentDetails = payment.details
      .filter(({ isActive }) => isActive)
      .map((detail) => {
        if (
          detail.methods === PAYMENT_METHOD.POINT ||
          detail.methods === PAYMENT_METHOD.FAMILY_POINT
        ) {
          // 友の会ポイント選択時
          return {
            methods: detail.methods,
            tomonokai_points: detail.amount,
            amount: detail.amount * 1000,
          }
        }

        return {
          methods: detail.methods,
          tomonokai_points: 0,
          amount: detail.amount,
        }
      })

    const category = payment.category || 1

    // TODO: 一時的に契約更新時の内金を支払に変更してリクエストしている。UI修正とともに本実装予定。
    const { data } = await paymentRepository($axios).register({
      contract_id: contractId,
      due_date: payment.dueDate ?? '',
      staff_id: payment.staff?.staff_id ?? 0,
      category:
        category === PAYMENT_CATEGORY.PART_PAYMENT
          ? PAYMENT_CATEGORY.PAYMENT
          : category,
      details: paymentDetails,
      total: payment.total ?? 0,
      notes: payment.notes || '-',
    })

    return data
  }

  const updateReceipt = async (contractId: number) => {
    try {
      const { data: payData } = await paymentServ.fetchPayments(contractId)
      const latestBranch = paymentServ.latestBranchNumber(payData.payments)
      await paymentServ.createRecipentPdf(contractId, latestBranch)

      const beforeLatestPaymentBranchNumber = latestBranch - 1
      if (beforeLatestPaymentBranchNumber) {
        await paymentServ.createRecipentPdf(
          contractId,
          beforeLatestPaymentBranchNumber,
          true
        )
      }
    } catch (error) {
      if (error instanceof Error) {
        console.log('--- 領収書のPDF生成失敗 ---', error.message)
      }
    }
  }

  /** PDF生成に失敗した場合はPDFFailureFlgを立てて完了画面でアラートを表示させる */
  const changePDFFailureFlgStatus = () => {
    store.commit(`PdfStoreInfo/setPDFFailureFlg`, true)
  }

  return {
    fetchSingleItemServiceContractInfo,
    fetchSingleItemServiceEstimateInfo,
    singleItemServiceContractInfo,
    singleItemServiceEstimateInfo,
    postSingleItemServiceContract,
    postSingleItemServiceEstimate,
    patchSingleItemServiceContract,
    fetchSingleItems,
    singleItems,
    loading,
  }
}
