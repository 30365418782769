import { computed } from '@nuxtjs/composition-api'
import { useEstimateState } from '~/composable/estimate/repository/comingOfAge/EstimateState'
import {
  useListItemプレミアムパックFormatter,
  useListItem仕立てFormatter,
  useListItem写真アルバム代Formatter,
  useListItem別衿加工Formatter,
  useListItem補正Formatter,
  useListItem足し布Formatter,
  useListItem長襦袢Formatter,
  useListItem帯仕立て代Formatter,
} from './ListItemオプション'
import {
  useListItem割引Formatter,
  useListItem着付けなし割引Formatter,
} from './ListItem割引'
import {
  useListItemFormatter,
  useMultiListItemFormatter,
  useListItemショールFormatter,
  getMultiListItems,
} from './ListItem小物'
import {
  useListItemJK前撮り振袖Formatter,
  useListItem振袖Formatter,
} from './ListItem振袖'
import {
  useListItem母娘プラン訪問着Formatter,
  useListItem着付代Formatter,
} from './ListItem着付代'
import { useListItemその他商品Formatter } from './ListItemその他商品'
import { Item } from '../types'
import { ItemPlan } from '~/constants/enums/itemPlan'
import { useScreenTypeFlags } from '~/composable/estimate/domain/ComingOfAge/ScreenTypeFlags'

/**
 * 見積一覧に表示する内容をストアの値から加工して配列で生成する関数。
 */
export const useEstimateListItems = () => {
  const estimateState = useEstimateState()
  const { showTypeフルセット, showType写真のみレンタル, showType持ち込み } =
    useScreenTypeFlags()
  const flags = {
    isフルセット: showTypeフルセット.value,
    is写のみレンタル: showType写真のみレンタル.value,
    is持ち込み: showType持ち込み.value,
  }
  /**
   * NOTE: ここでestimateStateを展開している関数は、ストア内の値へのリアクティブ性は失われている
   * 長襦袢や仕立ては見積一覧内の操作があるため、展開せずに渡している
   */

  // 帯・帯締め・帯揚げ「なし」の場合はnullで返却
  const is帯なし = estimateState.value.帯.小物プラン === ItemPlan.noUse
  const is帯締めなし = estimateState.value.帯締め.小物プラン === ItemPlan.noUse
  const is帯揚げなし =
    estimateState.value.帯揚げ[0]?.小物プラン === ItemPlan.noUse

  const item振袖 = useListItem振袖Formatter(estimateState.value.振袖)
  const item割引 = useListItem割引Formatter(estimateState.value.割引)
  const item帯 = is帯なし ? null : useListItemFormatter(estimateState.value.帯)
  const item帯締め = is帯締めなし
    ? null
    : useListItemFormatter(estimateState.value.帯締め)
  const item別衿 = useListItemFormatter(estimateState.value.別衿)
  const itemショール = useListItemショールFormatter(
    estimateState.value.ショール
  )
  const item髪飾り = useMultiListItemFormatter(estimateState.value.髪飾り)
  const item長襦袢 = useListItem長襦袢Formatter(estimateState)
  const item仕立て = useListItem仕立てFormatter(estimateState)
  const item足し布 = useListItem足し布Formatter(estimateState.value.足し布)
  const item補正 = useListItem補正Formatter(estimateState.value.振袖)
  const item帯仕立て代 = useListItem帯仕立て代Formatter(estimateState.value.帯)
  const item別衿加工 = useListItem別衿加工Formatter(estimateState.value.別衿)
  const item着付代 = useListItem着付代Formatter(estimateState.value.プラン)
  const item写真アルバム代 = useListItem写真アルバム代Formatter()

  const itemJK前撮り振袖 = useListItemJK前撮り振袖Formatter(
    estimateState.value.JK前撮り
  )
  const item母娘プラン = useListItem母娘プラン訪問着Formatter(
    estimateState.value.母娘プラン
  )
  const item着付けなし割引 = useListItem着付けなし割引Formatter(
    estimateState.value.プラン
  )
  const itemプレミアムパック = useListItemプレミアムパックFormatter()
  const itemその他商品 = useListItemその他商品Formatter(
    estimateState.value.その他商品
  )

  return computed<Item[]>(() => {
    const list: Item[] = []
    /**
     * NOTE: 帯揚げ・重ね衿・草履・バッグは見積り一覧で価格操作があるため、Formatterに渡さず直接getメソッドを呼んでいる
     * （ストア情報はsetup()内で呼ぶ必要があるため、ここでFormatterを使用することは不可）
     */
    const item帯揚げ = is帯揚げなし
      ? null
      : getMultiListItems(estimateState.value.帯揚げ, flags)
    const item重ね衿 = getMultiListItems(estimateState.value.重ね衿, flags)
    const item草履 = getMultiListItems(
      [estimateState.value.草履1個目, ...estimateState.value.草履複数個目],
      flags
    )
    const itemバッグ = getMultiListItems(
      [estimateState.value.バッグ1個目, ...estimateState.value.バッグ複数個目],
      flags
    )

    if (item振袖.value) list.push(item振袖.value)
    if (item割引.value.妹様割) list.push(item割引.value.妹様割)
    if (item割引.value.双子割) list.push(item割引.value.双子割)
    if (item割引.value.振袖割引) list.push(item割引.value.振袖割引)
    if (item帯 && item帯.value) list.push(item帯.value)
    if (item帯締め && item帯締め.value) list.push(item帯締め.value)
    if (item帯揚げ && item帯揚げ.length) list.push(...item帯揚げ)
    if (item重ね衿.length) list.push(...item重ね衿)
    if (item別衿.value) list.push(item別衿.value)
    if (itemショール.value) list.push(itemショール.value)
    if (item髪飾り.value.length) list.push(...item髪飾り.value)
    if (item草履.length) list.push(...item草履)
    if (itemバッグ) list.push(...itemバッグ)
    if (item長襦袢.value) list.push(item長襦袢.value)
    if (item仕立て.value) list.push(item仕立て.value)
    if (item足し布.value) list.push(item足し布.value)
    if (item補正.value.裄補正) list.push(item補正.value.裄補正)
    if (item補正.value.袖補正) list.push(item補正.value.袖補正)
    if (item帯仕立て代.value) list.push(item帯仕立て代.value)
    if (item別衿加工.value) list.push(item別衿加工.value)
    if (item着付代.value.当日) list.push(item着付代.value.当日)
    if (item着付代.value.前撮り) list.push(item着付代.value.前撮り)
    if (item写真アルバム代.value) list.push(item写真アルバム代.value)
    if (itemJK前撮り振袖.value) list.push(...itemJK前撮り振袖.value)
    if (item母娘プラン.value) list.push(item母娘プラン.value)
    if (item着付けなし割引.value) list.push(item着付けなし割引.value)
    if (item割引.value.従業員割) list.push(item割引.value.従業員割)
    if (item割引.value.割引) list.push(item割引.value.割引)
    if (itemプレミアムパック.value) list.push(itemプレミアムパック.value)
    if (itemその他商品.value) {
      itemその他商品.value.forEach((item) => {
        list.push(item)
      })
    }

    return list
  })
}
