import { MutationTree } from 'vuex'
import { getInitialStore, State } from './state'

const mutations: MutationTree<State> = {
  // ** All Input Data **
  setData: (state, data) => {
    state.data = data
  },

  // ** タブ **
  setTabList: (state, tabList) => {
    state.tabList = tabList
  },

  // ** 最終ページ **
  setFinalPageList: (state, finalPageList) => {
    state.finalPageList = finalPageList
  },

  initAll: (state) => {
    const initial = getInitialStore()
    state.data = initial.data
    state.tabList = initial.tabList
    state.finalPageList = initial.finalPageList
  },
}

export default mutations
