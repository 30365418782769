import { computed } from '@nuxtjs/composition-api'
import { useCustomer } from '~/composable/api/useCustomer'
import { useEstimate } from '~/composable/api/useEstimate'
import { useRestateCustomer } from './restates/RestateCustomer'
import { useRestateHurisode } from './restates/RestateHurisode'
import { useRestateItems } from './restates/RestateItems'
import { useRestatePhotos } from './restates/RestatePhotos'
import { useRestatePlan } from './restates/RestatePlan'
import { useRestateJkPhotoInAdvance } from './restates/RestateJkPhotoInAdvance'
import { useRestateWPhoto } from './restates/wPhoto'
import { useRestateSisterDiscount } from './restates/RestateSisterDiscount'
import { useRestateAdditions } from './restates/RestateAdditions'
import { useRestateDiscount } from './restates/RestateDiscount'
import { useRestatePriceTable } from './restates/RestatePriceTable'

type EstimateRestate = ReturnType<typeof useEstimateRestate>
export type Restate = EstimateRestate['restate']['value']

/**
 * 作成済みの見積書詳細APIから取得したパラメータを再びストアに保存するEstimateRestorerで使いやすい値や形式に加工する
 */
export const useEstimateRestate = () => {
  const { fetchEstimateInfo, estimateInfo } = useEstimate()
  const { fetchCustomerProfile, customerProfile } = useCustomer()

  /* restates */
  const restateCustomer = useRestateCustomer(customerProfile)
  const restatePlan = useRestatePlan(estimateInfo)
  const restatePhotos = useRestatePhotos(estimateInfo)
  const restateHurisode = useRestateHurisode(estimateInfo)
  const restateItems = useRestateItems(estimateInfo)
  const restateJkPhotoInAdvance = useRestateJkPhotoInAdvance(estimateInfo)
  const restateWPhoto = useRestateWPhoto(estimateInfo)
  const restateSisterDiscount = useRestateSisterDiscount(estimateInfo)
  const restateDiscount = useRestateDiscount(estimateInfo)
  const restateAdditions = useRestateAdditions(estimateInfo)

  /**
   * 整形前の情報をAPIから取得する。取得された情報は加工されてrestateに自動で反映される
   */
  const fetchInfoForEstimateRestate = async (
    estimateId: number,
    branch: number
  ) => {
    await fetchEstimateInfo(estimateId, branch)
    if (!estimateInfo.value) {
      const text = `以下の見積を取得できませんでした 見積番号:${estimateId}, 枝番号:${branch} `
      alert(text)
      return
    }
    await fetchCustomerProfile({
      customer_id: estimateInfo.value.customer_id,
    })
  }

  /**
   * 加工後の情報を束ねた変数
   */
  const restate = computed(() => {
    return {
      customer: restateCustomer.value,
      plan: restatePlan.value,
      selected_plan: estimateInfo.value?.selected_plan ?? 0,
      contract_service_type: estimateInfo.value?.contract_service_type ?? 0,
      photos: restatePhotos.value,
      hurisode: restateHurisode.value,
      items: restateItems.value,
      discounts: {
        ...restateDiscount.value,
        妹様割: restateSisterDiscount.value,
      },
      remarks: estimateInfo.value?.remarks ?? '',
      contract_total_price:
        Number(estimateInfo.value?.contract_total_price) ?? 0,
      additions: restateAdditions.value,
      memo: null,
      jk_photos_in_advance: restateJkPhotoInAdvance.value,
      mother_and_daughter_plan: Number(
        estimateInfo.value?.mother_and_daughter_plan
      ),
      w_photo_plan: restateWPhoto.value,
      price_information: useRestatePriceTable(estimateInfo).value,
      estimateInfo: estimateInfo.value,
      amount_adjustments: estimateInfo.value?.amount_adjustments ?? [],
    }
  })

  return { fetchInfoForEstimateRestate, restate, originalRes: estimateInfo }
}
