import { Restate } from '~/composable/estimate/repository/comingOfAge/EstimateRestate'
import { useComingStore } from '~/composable/estimate/repository/ComingOfAgeStore'
import { ObiState } from '~/store/types'

export const useRestore帯 = () => {
  const store = useComingStore<ObiState>('Obi')

  const restore帯 = (obi: Restate['items']['帯'], plan: Restate['plan']) => {
    if (!plan || !obi) return

    /* 初期値を設定 ※必ず入力される値はここで設置 */
    const formValues: ObiState = {
      /**
       * NOTE: selections = patternLine
       * ↓保持される選択肢のindex
       * [0]種別
       * [1]形態 || 仕立上り帯か
       * [2]仕立上り帯か || 見積表示
       */
      selections: [obi.optionId種別, 0, 0],
      product: null,
      input: {
        productName: '',
        buyPrice: 0,
        rentalPrice: 0,
        rankUpRentalPrice: 0,
        rankUpBuyPrice: 0,
        premiumRentalPrice: 0,
        premiumBuyPrice: 0,
      },
      // NOTE: フリー入力された値はpricesではなくinputの方に格納される
      price: {
        buy: 0,
        rental: 0,
        id: 0, // フリー入力を選択
      },
      isFree: false,
      isValidate: null,
    }

    /* 以下で入力内容の条件に基づき、必要な項目を埋めていく */
    if (plan.flags.is振袖持込 && !obi.flags.is持込) {
      formValues.selections[1] = obi.optionId仕立て上りか
      formValues.selections[2] = obi.optionId見積選択
      formValues.input.rentalPrice = obi.レンタル価格
      formValues.input.buyPrice = obi.購入価格
    } else if (!plan.flags.is振袖持込 && !obi.flags.is持込) {
      formValues.selections[1] = obi.optionId形態
      formValues.selections[2] = obi.optionId仕立て上りか
    }

    if (obi.flags.is仕立て上り) {
      formValues.product = obi.仕立て上り検索結果
    } else {
      formValues.input.productName = obi.商品名
    }

    if (obi.flags.isセット内) {
      formValues.price.rental = obi.レンタル価格
      formValues.input.rentalPrice = obi.レンタル価格
      if (plan.flags.isフルセットプラン) {
        formValues.price.buy = obi.購入価格
        formValues.input.buyPrice = obi.購入価格
      }
    }

    if (obi.flags.isレンタルのみランクアップ) {
      formValues.isFree = true
      formValues.input.rankUpRentalPrice = obi.レンタル価格
    }

    if (obi.flags.isランクアップPP内) {
      formValues.isFree = true
      formValues.input.rankUpRentalPrice = obi.レンタル価格
      if (plan.flags.isフルセットプラン) {
        formValues.input.rankUpBuyPrice = obi.購入価格
      }
    }

    if (obi.flags.isランクアップPP外) {
      formValues.input.rankUpRentalPrice = obi.レンタル価格
      formValues.input.premiumRentalPrice = obi.プレミアムレンタル価格
      if (plan.flags.isフルセットプラン) {
        formValues.input.rankUpBuyPrice = obi.購入価格
        formValues.input.premiumBuyPrice = obi.プレミアム購入価格
      }
    }
    /* ストアに内容を保存する */
    store.setOf<ObiState['selections']>('setSelection', formValues.selections)
    store.setOf<ObiState['product']>('setProduct', formValues.product)
    store.setOf<ObiState['input']>('setInput', formValues.input)
    store.setOf<ObiState['price']>('setPrice', formValues.price)
    store.setOf<ObiState['isFree']>('setIsFree', formValues.isFree)
    store.setIsValidate(false)
  }

  return { restore帯 }
}
