import dayjs from 'dayjs'
import DateTime from './dateTime'

// 氏名を整形
export const customerName = (customer: any) => {
  return `${customer.last_name}${customer.first_name}（${customer.last_name_kana}${customer.first_name_kana}）`
}

// プラン選択関連
export const eventTypeToStr = (eventId: number) => {
  if (eventId === 1) return '成人式'
  if (eventId === 2) return '卒業式'
  if (eventId === 3) return '参列'
  if (eventId === 6) return 'クリーニング'
  if (eventId === 7) return '単品'
}

export const usePrice = () => {
  /*
   * 金額を"¥0,000"の形式にフォーマットする関数
   */
  const toString = (price: number) => {
    if (!price) return '¥0'
    return '¥' + Number(price).toLocaleString()
  }
  /*
   * 金額をnumberにする関数
   */
  const toNumber = (price: string) => {
    const isParamString = Number(price) || Number(price) === 0
    if (!price.match(/¥/) && !isParamString)
      throw new Error('正しい値を引数にしてください')
    const convertPrice = price.replace(/¥|,/g, '')
    return Number(convertPrice)
  }
  /*
   * 値引き後の金額を計算して返す。例：80%引きならrateに0.8を渡す
   */
  const getDiscounted = (price: number | string, rate: number): number => {
    const priceNum = typeof price === 'number' ? price : toNumber(price)
    return Math.ceil(priceNum * rate)
  }

  /*
   * レスポンスで受け取る金額intまたは"0000"形式のstringどちらも"¥0,000"にフォーマットする
   */
  const paramToString = (price: string | number): string => {
    if (typeof price === 'number') return toString(price)
    return toString(toNumber(price))
  }

  return { toString, toNumber, getDiscounted, paramToString }
}

export class EnumEstimatePlan {
  static readonly フルセットレンタル = 1
  static readonly 成人式Aand写真プラン = 2
  static readonly 成人式Band写真プラン = 3
  static readonly 写のみ = 4
  static readonly 写のみ持込 = 5
  static readonly フルセット購入 = 6
  static readonly 成人式B = 7
  static readonly 袴単品レンタル = 8
  static readonly 二尺袖単品レンタル = 9

  static toWord = (val: number) => {
    switch (val) {
      case EnumEstimatePlan.フルセットレンタル:
        return 'フルセット（レンタル）'
      case EnumEstimatePlan.成人式Aand写真プラン:
        return '成人式A＋写真プラン'
      case EnumEstimatePlan.成人式Band写真プラン:
        return '成人式B＋写真プラン'
      case EnumEstimatePlan.写のみ:
        return '写のみ(レンタル)'
      case EnumEstimatePlan.写のみ持込:
        return '写のみ(持込)'
      case EnumEstimatePlan.フルセット購入:
        return 'フルセット購入'
      case EnumEstimatePlan.成人式B:
        return '成人式B'
      case EnumEstimatePlan.袴単品レンタル:
        return '袴単品レンタル'
      case EnumEstimatePlan.二尺袖単品レンタル:
        return '二尺袖単品レンタル'
      default:
        return '未設定'
    }
  }
}

/**
 * 年齢を算出し（〜歳）のフォーマットにする
 * birthdayは次のような値で入る「2000-07-30」
 */
export const formatAge = (date?: string | null) => {
  if (!date) {
    return ''
  }
  const today = dayjs()
  const birthDate = dayjs(date)
  const age = today.diff(birthDate, 'year')
  return `（${age}歳）`
}

/**
 * @note 引数として渡された日付が存在しない場合にハイフンを出力する関数
 */
export const formatDateOrDash = (date?: string | null) => {
  if (!date) {
    return 'ー'
  }
  return new DateTime(date).toJpString()
}

/**
 * @note 引数として渡された電話暗号が存在しない場合にハイフンを出力する関数
 */
export const formatPhoneNumber = (number?: string | null) => {
  if (!number) {
    return 'ー'
  }
  return number.replaceAll('-', '').toString()
}

/**
 * 郵便番号のフォーマット
 */
export const formatZipCord = (arg?: string | null) => {
  if (!arg) {
    return '-'
  }

  return '〒' + arg.slice(0, 3) + '-' + arg.slice(3, arg.length)
}

export const formatZipCodeOrDash = (arg?: string | null) => {
  if (!arg) {
    return 'ー'
  }

  return '〒' + arg.slice(0, 3) + '-' + arg.slice(3, arg.length)
}
