import { computed, useStore } from '@nuxtjs/composition-api'
import { restateDiscount } from '../restate/restateDiscount'
import { restateRemarks } from '../restate/restateRemarks'
import { useSingleItem } from '~/composable/api/useSingleItem'
import { State, getInitialStore } from '~/store/SingleItemService/Main/state'
import { restateAccessory } from '../restate/restateAccessory'
import { useRestateProductSetting } from '../restate/restateProductSetting'
import { useRestatePayment } from '../restate/restatePayments'
import { ProductTypeIdList, SingleItem } from '~/api/singleItemRepository'
import ProductClassification from '~/constants/enums/productClassification'

export type RestoreType = 'estimate' | 'contract' | 'contractForEstimate'

/**
 * @note
 * この関数は3つのトリガーによって単一のstoreを復元する処理を行います。
 * estimate: 見積詳細APIから単品サービスのstoreを復元する
 * contract: 契約詳細APIから単品サービスのstoreを復元する
 * contractForEstimate: 見積詳細APIから単品サービスのstoreを復元する。ただし、タブやデータの格納場所などが通常の復元と異なる。(見積反映機能と近しい処理)
 *
 * 設計上見積と契約のデータ構造が似ているため一つの関数で見積と契約の復元ロジックをまとめています。
 * 詳しくはAPI仕様書(2023/05/24時点)をご確認ください
 * https://docs.google.com/spreadsheets/d/1squ0uolJXm0i2LS4-JMBqQCxZfoHEgEKgpd7Iec8SYw/edit#gid=721026106
 */
export const useRestore = (
  restoreType: RestoreType,
  isEstimateComplete: boolean = false
) => {
  const store = useStore()

  // * fetch API
  const { singleItems, fetchSingleItems } = useSingleItem()
  /**
   * 商品のマスターデータを取得しstoreに格納する関数
   */
  const fetchSingleItemAfterCommit = async (
    productTypeId: ProductTypeIdList
  ) => {
    const productNameStore = (() => {
      switch (productTypeId) {
        case ProductClassification.zoriAndBag:
          return '草履バッグ'
        case ProductClassification.nagajyubanPurchase:
          return '長襦袢購入お誂え正絹'
        case ProductClassification.nagajyubanRental:
          return '長襦袢レンタル仕立て上がり交織'
        default:
          return ProductClassification.toWord(productTypeId)
      }
    })()

    const itemsState =
      (store.getters[`SingleItemService/MasterData/getSingleItems`](
        productNameStore
      ) as SingleItem[]) ?? []

    if (!itemsState.length) {
      await fetchSingleItems({
        productTypeIds: [productTypeId],
      })

      store.commit(`SingleItemService/MasterData/setSingleItems`, {
        items: singleItems.value,
        productName: productNameStore,
      })
    }
  }
  const {
    singleItemServiceContractInfo,
    singleItemServiceEstimateInfo,
    fetchSingleItemServiceContractInfo,
    fetchSingleItemServiceEstimateInfo,
  } = useSingleItem()

  //* 復元するstoreの元となるAPIから取得したデータを識別する関数
  const getTargetServiceInfo = () => {
    if (restoreType === 'contract') {
      return singleItemServiceContractInfo
    } else if (['contractForEstimate', 'estimate'].includes(restoreType)) {
      return singleItemServiceEstimateInfo
    } else {
      throw new Error('復元するデータが見つかりませんでした。')
    }
  }

  const singleItemServiceInfo = getTargetServiceInfo()

  //* restate (復元ロジック)
  const {
    restateProductSetting,
    fetchShopList,
    fetchObiProducts,
    fetchCustomerListById,
    fetchContractorListById,
  } = useRestateProductSetting(singleItemServiceInfo, restoreType)
  const {
    restatePayment,
    getPaymentStaff,
    getTransferPlacesinfo,
    fetchPayments,
  } = useRestatePayment(singleItemServiceContractInfo)
  const initStoreData: State = getInitialStore()
  const tabs = initStoreData.tabList.map((t) => {
    if (isEstimateComplete) {
      t.isCurrent = false
      return t
    }
    switch (restoreType) {
      case 'contract':
      case 'estimate':
        t.isComplete = true
        break
      case 'contractForEstimate':
        t.isComplete = null
        break
    }

    return t
  })

  //* storeのオブジェクト
  const restateData = computed((): State | null => {
    if (!singleItemServiceInfo.value) return null
    const discount = restateDiscount(singleItemServiceInfo.value)
    const remarks = restateRemarks(singleItemServiceInfo.value)
    const accessories = restateAccessory(singleItemServiceInfo.value)
    const productSetting = restateProductSetting()
    const payments = restatePayment(initStoreData) // 支払い情報
    const pdfUrl =
      'pdf_url' in singleItemServiceInfo.value
        ? singleItemServiceInfo.value.pdf_url
        : ''
    return {
      data: {
        ...initStoreData.data,
        contractDate: singleItemServiceContractInfo.value?.contract_date
          ? singleItemServiceContractInfo.value?.contract_date
          : '',
        productSetting,
        accessories,
        discount,
        remarks,
        payment: payments.state,
        contractTotalPrice: null,
        pdfUrl,
      },
      tabList: tabs,
      finalPageList: [],
    }
  })

  /*
   * restateDataをstoreに書き込む
   */
  const restore = async (id: number) => {
    //* 単品サービスのStoreの復元を実行する関数
    const isEstimateRestore = ['contractForEstimate', 'estimate'].includes(
      restoreType
    )
    const serviceName = isEstimateRestore ? '見積書' : '契約書'
    const storePath = 'SingleItemService/Main/'

    // NOTE：TABが初期化されることで、見積画面に遷移し表示崩れが発生する為、見積完了画面から遷移した場合はdataとfinalPageListのみ初期化する。
    if (isEstimateComplete) {
      store.commit(`${storePath}setData`, getInitialStore().data)
      store.commit(
        `${storePath}setFinalPageList`,
        getInitialStore().finalPageList
      )
    } else {
      store.commit(`${storePath}initAll`, getInitialStore())
    }
    store.commit(`SingleItemService/Memo/initAll`)

    try {
      if (restoreType === 'contract') {
        await fetchSingleItemServiceContractInfo(id)
        await fetchPayments(id)
      } else if (isEstimateRestore) {
        await fetchSingleItemServiceEstimateInfo(id)
      } else {
        throw new Error('復元するデータが見つかりませんでした。')
      }

      if (!singleItemServiceInfo.value) {
        throw new Error('APIからデータを取得できませんでした。')
      }

      const productTypeIdList = singleItemServiceInfo.value.products.map(
        ({ product_type_id }) => product_type_id
      )

      const promises = productTypeIdList.map((id) =>
        fetchSingleItemAfterCommit(id)
      )
      //NOTE: 復元時にAPIからのデータ取得が必要な場合はpromise allで取得する
      await Promise.all([
        ...promises,
        fetchShopList(),
        fetchObiProducts(),
        fetchCustomerListById(),
        fetchContractorListById(),
        getPaymentStaff(),
        getTransferPlacesinfo(),
      ])

      store.commit(`${storePath}setData`, restateData.value?.data)
      store.commit(`${storePath}setTabList`, restateData.value?.tabList)
      return true
    } catch (error) {
      if (error instanceof Error) {
        alert(`${serviceName}の復元に失敗しました。\n${error.message}`)
      }
      return false
    }
  }

  return { restore }
}
