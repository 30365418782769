import { EstimateListState } from '../../../types'

type valueOf<T> = T[keyof T]

export type 成人式見積一覧Keys = keyof EstimateListState['fields']
export type 成人式見積一覧Values = valueOf<EstimateListState['fields']>

export const getInitialFields = (): EstimateListState['fields'] => {
  return {
    selectedPlan: 0,
    selectedSewings: [1, 1],
    selectedNagajubans: [1, 1],
    totalPrice: 0,
    selectedWPPlan: 0,
    photoTotalPrice: 0,
    allData: null,
    wPhotoAllData: null,
    isEstimateCreated: false,
    amount_adjustments: [], //金額調整
    amount_adjustment_validation: [], //見積一覧ページにて金額入力のバリデーションを保持するdata
    amount_adjustments_for_wphoto: [], //金額調整
    amount_adjustment_validation_for_wphoto: [], //見積一覧ページにて金額入力のバリデーションを保持するdata
    isObiageSecondRankUp: true, // 帯揚げ 二個目ランクアップ適用かどうか
    isKasaneeriSecondRankUp: true, // 重ね衿 二個目ランクアップ適用かどうか
    isZoriBagPriceCalculated: false, // PP適用時の草履・バッグ価格を見積時に再計算するかどうか
    isZoriBagSetPrice: false, // PP適用時に草履・バッグがセット価格かどうか
    isInputValid: true, // PP適用時の金額入力が有効かどうか
  }
}

const state = (): EstimateListState => ({
  // 成人式のestimateListページの選択内容を保持するstate
  fields: getInitialFields(),
})

export default state
