import { FileRepository, PdfResponse } from '~/api/fileRepository'
import {
  CreatePaymentInputParams,
  EditPaymentInputParams,
  Payment,
  PaymentRepository,
} from '~/api/paymentRepository'
import { DOCUMENT } from '~/constants/estimate/config'

const paymentService = (
  fileRepo: FileRepository,
  paymentRepo: PaymentRepository
) => {
  const groupByPaymentBranchNumber = (data: Payment[]): Payment[][] =>
    [
      ...new Set(
        data.map(({ payment_branch_number }) => payment_branch_number)
      ),
    ].map((branchNum) =>
      data.filter(
        ({ payment_branch_number }) => payment_branch_number === branchNum
      )
    )

  const latestBranchNumber = (data: Payment[]) => {
    if (data.length === 0) {
      return 0
    }

    return [
      ...new Set(
        data.map(({ payment_branch_number }) => payment_branch_number ?? 0)
      ),
    ].sort((a, b) => (a < b ? 1 : -1))[0]
  }

  const getLatestRecipientPdf = (params: { id: number; branch?: number }) =>
    fileRepo.getFile('/api/receipt_pdf', {
      id: params.id,
      branch: params.branch ?? 0,
    })

  const getOldRecipientPdf = (pdfUrl: string) => fileRepo.getFile(pdfUrl)

  const createRecipentPdf = (
    contractId: number,
    brance: number,
    isOnlyRecipt: boolean = false
  ) =>
    fileRepo.createPDF<PdfResponse>({
      id: contractId,
      type: DOCUMENT.領収書.id,
      branch: brance,
      isOnlyRecipt,
    })

  const createRecipent = (arg: CreatePaymentInputParams) =>
    paymentRepo.register(arg)

  const updateRecipent = (contractId: number, arg: EditPaymentInputParams) =>
    paymentRepo.edit(contractId, arg)

  const fetchPayments = (contractId: number) => paymentRepo.get(contractId)

  return {
    groupByPaymentBranchNumber,
    getLatestRecipientPdf,
    getOldRecipientPdf,
    fetchPayments,
    createRecipent,
    updateRecipent,
    latestBranchNumber,
    createRecipentPdf,
  }
}

export default paymentService
