import { GetterTree } from 'vuex'
import { RootState, FamilyInformationSetting } from '../../types'

const getters: GetterTree<FamilyInformationSetting, RootState> = {
  getFamily: (state: FamilyInformationSetting) => {
    return state.family
  },
  getMypageMailAddress: (state: FamilyInformationSetting) =>
    state.mypageMailAddress,
  getParents: (state: FamilyInformationSetting) => {
    return state.family[0].profile
  },
  getChildren: (state: FamilyInformationSetting) => {
    return state.family[1].profile
  },
  getOtherCustomer: (state: FamilyInformationSetting) => {
    return state.family[2].profile
  },
  getAddress: (state: FamilyInformationSetting) => {
    return state.address
  },
  getPatternLine: (state: FamilyInformationSetting) => {
    return state.patternLine
  },
  getIsClickByUsedPast: (state: FamilyInformationSetting) => {
    return state.isClickByUsedPast
  },
  getFamilyAddressType: (state: FamilyInformationSetting) => {
    return state.familyAddressType
  },
  getIsValidate: (state: FamilyInformationSetting) => {
    return state.isValidate
  },
  getScreenType: (state: FamilyInformationSetting) => {
    return state.screenType
  },
  getCustomerProfile: (state: FamilyInformationSetting) => {
    return state.customerProfile
  },
  getTwinsProfile: (state: FamilyInformationSetting) => {
    return state.twinsProfile
  },
}

export default getters
