
















import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  useStore,
  computed,
  useRouter,
} from '@nuxtjs/composition-api'
import { Store } from 'vuex'
import { prohibitExpansion, reloadEvent } from '@/utils/screenControl'
import { useCustomer } from '@/composable/api/useCustomer'
import { useTemporarilySaved } from '~/composable/api/useTemporarilySaved'
import { BaseEstimateState } from '~/store/Contracts/types'
import { useFurisodeAlertData } from '~/composable/estimate/repository/comingOfAge/FurisodeAlertData'
import { useContractTypeFlags } from '~/composable/contracts/ContractTypeFlags'
import { useAuth } from '~/composable/api/useAuth'
import {
  State,
  Tab as AttendanceTab,
} from '~/store/Contracts/Attendance/Main/state'
import { Tab as CleaningTab } from '~/store/Contracts/Cleaning/Main/state'
import { ContractType } from '~/constants/enums/contractType'
import { ContractTypeId } from '~/api/frontStoreRepository'

export default defineComponent({
  name: 'contract',
  setup() {
    const store = useStore() as Store<any>
    const {
      in卒業式成人式契約,
      in参列契約,
      in単品サービス契約,
      inクリーニング契約,
    } = useContractTypeFlags()
    const router = useRouter()
    const { fetchCustomerList } = useCustomer()
    const { saveContractTemporarily } = useTemporarilySaved()
    const { showFurisodeModal } = useFurisodeAlertData()

    const estimateType = computed<ContractTypeId | null>(() => {
      if (in卒業式成人式契約.value) {
        return store.getters['Contracts/BaseEstimate/getEstimateType']
      } else if (in参列契約.value) {
        return ContractType.attendance
      } else if (inクリーニング契約.value) {
        return ContractType.cleaning
      } else if (in単品サービス契約.value) {
        return ContractType.singleItemService
      } else {
        return null
      }
    })

    const estimate: BaseEstimateState['estimate'] =
      store.getters['Contracts/BaseEstimate/getEstimate']

    const { validateSession } = useAuth()

    const path = router.currentRoute.fullPath
    // 契約更新中かどうか
    const isEditMode = in卒業式成人式契約.value
      ? path.includes('contract_id')
      : !path.includes('/0/')

    const storedSelectEstimateData = computed<State['selectEstimate']>(
      () => store.getters['Contracts/Attendance/Main/getSelectEstimate']
    )

    const storedAttendanceTabListData = computed<AttendanceTab[]>(
      () => store.getters['Contracts/Attendance/Main/getFinalPageList']
    )

    const storedCleaningTabListData = computed<CleaningTab[]>(
      () => store.getters['Contracts/Cleaning/Main/getFinalPageList']
    )

    // 参列の見積選択中フラグ
    const isSelectingEstimate = computed(
      () => storedSelectEstimateData.value.isSelectingEstimate
    )

    const is参列署名OR参列契約完了 = computed(() => {
      const tabText = storedAttendanceTabListData.value.find(
        (tab) => tab.isCurrent
      )?.text
      return tabText === '署名' || tabText === '契約作成完了'
    })

    const isクリーニング署名ORクリーニング契約完了 = computed(() => {
      const tabText = storedCleaningTabListData.value.find(
        (tab) => tab.isCurrent
      )?.text
      return tabText === '署名' || tabText === '契約作成完了'
    })

    const isHiddenMemoSaveButton = computed(() => {
      const isHiddenIn参列 =
        in参列契約.value &&
        (isSelectingEstimate.value || is参列署名OR参列契約完了.value)

      const isHiddenInクリーニング =
        inクリーニング契約.value &&
        isクリーニング署名ORクリーニング契約完了.value

      return isHiddenIn参列 || isHiddenInクリーニング
    })

    // 保存ボタン押下時に発火
    const save = () => {
      if (estimateType.value) {
        saveContractTemporarily(estimateType.value)
      }
    }

    const { addReloadEvent, removeReloadEvent } = reloadEvent()

    onMounted(async () => {
      validateSession()
      prohibitExpansion()
      addReloadEvent()
      if (in卒業式成人式契約.value) {
        // 見積番号から該当のお客様を取得
        const customerId =
          store.state.Contracts.BaseEstimate.estimate.customer_id
        fetchCustomerList({ customer_id: customerId })

        // 振袖補正可能かどうかの判定
        await showFurisodeModal(estimate.use_date, false)
      }
    })

    onBeforeUnmount(() => {
      removeReloadEvent()
    })

    return {
      save,
      estimateType,

      in卒業式成人式契約,
      inクリーニング契約,
      in参列契約,
      isSelectingEstimate,
      isHiddenMemoSaveButton,
      isEditMode,
    }
  },
})
