











import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  useStore,
} from '@nuxtjs/composition-api'
import { prohibitExpansion, reloadEvent } from '@/utils/screenControl'
import { useCustomer } from '@/composable/api/useCustomer'
import { useTemporarilySaved } from '~/composable/api/useTemporarilySaved'
import { Store } from 'vuex'
import { BaseEstimateState } from '~/store/Contracts/types'
import { useAuth } from '~/composable/api/useAuth'

export default defineComponent({
  setup() {
    const store = useStore() as Store<any>
    const { fetchCustomerList } = useCustomer()
    const { saveContractTemporarily } = useTemporarilySaved()
    // 成人式・卒業式でしか使っていないlayoutのため、estimateTypeはBaseEstimateStateを見るだけでOK
    // 他の契約サービスや見積で使うならロジックを追加する必要がある
    const estimateType: BaseEstimateState['estimate']['type'] =
      store.getters['Contracts/BaseEstimate/getEstimateType']
    const { validateSession } = useAuth()

    // 保存ボタン押下時に発火
    // NOTE: 成人式と卒業式のみ仮保存するようにしている
    const save = () => {
      saveContractTemporarily(estimateType)
    }

    const { addReloadEvent, removeReloadEvent } = reloadEvent()

    onMounted(() => {
      validateSession()
      prohibitExpansion()
      addReloadEvent()

      // 見積番号から該当のお客様を取得
      const customerId = store.state.Contracts.BaseEstimate.estimate.customer_id
      fetchCustomerList({ customer_id: customerId })
    })

    onBeforeUnmount(() => {
      removeReloadEvent()
    })

    return { save, estimateType }
  },
})
