import { ActionTree } from 'vuex'
import { RootState, PhotoSettings } from '../../types'

const actions: ActionTree<PhotoSettings, RootState> = {
  setIsValidate: ({ commit }, isValidate: boolean) => {
    commit('setIsValidate', isValidate)
  },

  mutationPhotoUrl: ({ commit }, { url, settingId = 1 }) => {
    commit('setPhotoUrl', { settingId, url })
  },

  mutationRemovePhotoUrl: ({ commit }, settingId) => {
    commit('removePhotoUrl', { settingId })
  },

  mutationRestore: ({ commit }) => {
    commit('removePhotoUrl', { settingId: 1 })
    commit('removePhotoUrl', { settingId: 2 })
    commit('setIsValidate', null)
  },
}

export default actions
