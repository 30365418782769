import { GetterTree } from 'vuex'
import { RootState, FurisodeState } from '../../../types'

const getters: GetterTree<FurisodeState, RootState> = {
  getState: (state) => {
    return state
  },

  // NOTE: useComingStore用に追加
  getAllState: (state) => {
    return state
  },

  getSelections: (state) => {
    return state.selections
  },

  getProduct: (state) => {
    return state.product
  },

  getInput: (state) => {
    return state.input
  },

  getContractId: (state) => {
    return state.contractId
  },

  getPrice: (state) => {
    return state.price
  },

  getIsFree: (state) => {
    return state.isFree
  },

  getIsFurisodeOnHold: (state) => {
    return state.isFurisodeOnHold
  },

  getIsValidate: (state) => {
    return state.isValidate
  },

  getCatalogType: (state) => {
    return state.catalogType
  },

  getIsOrderOutsideTargetYear: (state) => {
    return state.isOrderOutsideTargetYear
  },

  getProperProductId: (state) => {
    return state.properProductId
  },
}

export default getters
